import React from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
//import { Header, HeaderLogout } from "../../components/Headers";
//import { Footer, FooterLink } from "../../components/Footers";
import Switch from "react-switch";
import StripeCheckout from "react-stripe-checkout";
import axios from "../../config/axios";
//import Axios from 'axios'
import "../../assets/css/style.css";

import { toast } from "react-toastify";
//import "react-toastify/dist/ReactToastify.css";
import Package from './packages'
import {
  tick,
  tickGray,
} from "../../assets/images";
import message from '../../utils/mesaages';
import { Link } from "react-router-dom";
import {curencyFilter,curencyRates} from '../../utils/currencyConvert';
import UpgradeDowngradeModal from "../manage-subscription/UpgradeDowngradeModal";
import { parseInt } from "lodash";
//import 'bootstrap/dist/css/bootstrap.min.css';
toast.configure();
var _ = require('lodash');


class PriceTableNew extends React.Component {
  constructor(props){
    super(props)
    this.token = localStorage.getItem("authToken");
    this.userdata = localStorage.getItem("userdata");

    this.state = { 
      showUpgradeDowngradeModal: false,
      checked: true ,
      allplan:'',
      planList:'',
      planDesc:'',
      yearlydiscount:0,
      planPrice:0,
      currentPlanPrice:0,
      planFor:'MONTHLY',
      apiCall:0,
      selectedToUpgradePlanDetail: null,
      hrefVal: "",
      rates:''
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async componentWillMount() {

    let rates = await curencyRates()
    this.setState({rates})
  }
  componentDidMount(){
    console.log("window.location.href",window.location.href)
    if(window.location.href) {
      let hrefEndPoint = window.location.href.split("/").pop()
      this.setState({hrefVal: hrefEndPoint}, () => {
        console.log("hrefEndpoint", this.state.hrefVal)
      })
    }
    this.getAllPlan()
    this.getPlan()
   
  }

  async componentWillReceiveProps (newProps,prevProps) {
    if( prevProps.currentPlan !== newProps.currentPlan ){
      if(newProps.currentPlan!='')
      {
        await this.setState({currentPlanPrice:newProps.currentPlan.plan_price})
        console.log("I am here", this.state.currentPlanPrice)
      }
    }
  }

  getPlan() {
    let userData = this.userdata != null ? JSON.parse(this.userdata) : null;
    let userDataType,coachId;
    if(userData) {
      userDataType = userData.account_type
      coachId = userDataType === "coach" ? userData.uid : "0"
    }
    else {
      coachId = "0"
    }    
  
    axios
      .get(`/users/get-allcoach-plan/${coachId}/${this.state.planFor}`, {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          console.log('plan.....',res.data.value)
          let plan = res.data.value.coachPlan;
          if (plan.length > 0) {
            this.setState({planList: plan, allplan: plan,planDesc:res.data.value.coachDesc,yearlydiscount:res.data.value.discount.plan_rate });
            this.setState({apiCall:1})
            //  let yearly= plan.map((descVal)=>{
            //     let totalYearly=descVal.plan_price*12
            //     return this.calculatePercent(res.data.value.discount.plan_rate, totalYearly)
                  
            //     } )

            //     console.log('yearly',yearly)
        
              }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getAllPlan() {
   
  
    axios
      .get(`/users/get-allcoach-plan`, {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          console.log('plan.....',res.data.value)
          let plan = res.data.value.coachPlan;
          if (plan.length > 0) {
            this.setState({ planList: plan});
            
            
        
              }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
  handleChange(checked) {
    console.log('chacked....',checked)
    this.setState({apiCall:0})
    
    if(checked)
    {
      this.setState({planFor:'MONTHLY'},()=>{
        this.getPlan()
      })
    }
    else
    {
      this.setState({planFor:'ANNUALLY'},()=>{
        this.getPlan()
      })
    }
    
    //console.log('percentage',this.calculatePercent(30, 2400)); //Result: 2
    this.setState({ checked });
  }


  calculatePercent(percent, num){
    //return (percent / 100) * num;
    return Math.round(((100-percent) * num) /100)
  }
  discountPercent(price1,price2)
  {

    console.log('prices',price1,price2)
    
    console.log('percent',Math.round((price1-price2)*100/price1).toFixed(2))
    // return  Math.round((price1-price2)*100/price1).toFixed(2)
    return  ((price1-price2)*100/price1).toFixed(2)
  }
   
  handlePremiumPlus=(token,addresses) =>{
      console.log(token,addresses)
        axios.post("/payment/checkout",{ token,Package:{ "type":"coach", "name": "Premium Plus",
        "price": "149",uid:this.props.location.state.user_t}})
            .then(response =>{
              const { status } = response.data;
              console.log("Response:", response.data);
              if (status === "success") {
                message.success("Success! Check email for details");
              } else {
                message.error("Something went wrong");
              }
            })
        
    }

    handlePremiumcoach=(token,addresses) =>{
  
      console.log(token,addresses)
        axios.post("/payment/checkout",{ token,Package:{ "type":"coach","name": "Premium coach",
        "price": "129",uid:this.props.location.state.user_t}})
            .then(response =>{
              const { status } = response.data;
              console.log("Response:", response.data);
              if (status === "success") {
                message.success("Success! Check email for details");
              } else {
                message.error("Something went wrong");
              }
            })
        
    }


    handlecoach=(token,addresses) =>{
  
      console.log(token,addresses)
        axios.post("/payment/checkout",{ token,Package:{"type":"coach", "name": "Coach",
        "price": "99",uid:this.props.location.state.user_t}})
            .then(response =>{
              const { status } = response.data;
              console.log("Response:", response.data);
              if (status === "success") {
                message.success("Success! Check email for details");
              } else {
                message.error("Something went wrong");
              }
            })
        
    }

    getSubscription= (planID,isUpgradeBoolean)=>{
      this.handleCloseUpgradeDowngradeModal()
      this.props.getSubscription(planID,isUpgradeBoolean)

    }
    

 render() {
 // console.log("CURR ENV : ", process.env.NODE_ENV)
  //console.log("user uid",this.props.location.state.user_t)
  return (

    <div className="pricing_container" id="subscription-plans">
      <Container>
      <div className="priceHolder">
       
        <div className="heading text-center">
          <h3>Subscription Plans</h3>
          <span className="R_divider div_dark"></span>
        </div>
          
        <Row className="mt-5 mb-5 justify-content-center yearly_monthly">
          {/* <div>Yearly ({this.state.yearlydiscount}% off)</div> */}
          <div className="mr-3">Yearly
            {/* <span style={{fontSize: "16px", marginLeft: "4px"}}>({this.state.yearlydiscount}% saving)</span> */}
            </div>
          <div className="ml-2 mr-2 switcher d-flex align-items-center">
            {/* <Switch onChange={this.handleChange} checked={this.state.checked}  onHandleColor="#82bd51" offColor="#d7d7d7" onColor="#d7d7d7" /> */}
            <Switch onChange={this.handleChange} checked={this.state.checked} checkedIcon="" uncheckedIcon="" offHandleColor="#00e1ba" onHandleColor="#00e1ba" onColor="#d7d7d7" offColor="#d7d7d7"/>
          </div>
          <div className="ml-3"> Monthly</div>
        </Row>

        <Row className="justify-content-between package_list ml-0 mr-0">

        {this.state.allplan.length > 0  && this.state.planList.length > 0 &&  this.state.allplan.map(  (planVal, i)  =>{
          let calculateVal=planVal.plan_price
          let duration=planVal.plan_duration
          let planPrice_be= 0
          let totalYearly =0
          let percentage = 0
         if(this.state.checked || this.state.apiCall==0)
         {
          calculateVal=planVal.plan_price
          duration=planVal.plan_duration
         }else{
            
            let p = _.find(this.state.planList, function(o) { return o.plan_name == planVal.plan_name && o.plan_duration==1 }) //1  means monthly
            console.log('planVal.plan_name',planVal.plan_name)
            if(p!=undefined)  planPrice_be = p.plan_price*12 //this plan price is for 12 month 
            else  planPrice_be = 0
            
             calculateVal=planVal.plan_price //but lifeguru give it in
          percentage=  this.discountPercent(planPrice_be, calculateVal)
          
         }
         
          
          
           let detailsStatus='' 
    return(
          // <Col xs="12" sm="10" md="8" lg="4"  className={`package_item mb-5 ${parseInt(planVal.plan_price) === parseInt(this.state.currentPlanPrice) && 'active'}`}>
          <Col xs="12" sm="10" md="8" lg="4"  className={`package_item mb-5 ${i === 0 && this.state.planFor === 'MONTHLY' && this.userdata && this.state.hrefVal === 'profile-coach' && 'active'}`}>
            <div className="package_card">
              <div className="packge_head">
                <h3>{planVal.plan_name}</h3>
                {console.log("rates",this.state.rates)}
                {i === 0 && this.state.planFor === 'MONTHLY' && this.state.hrefVal === 'profile-coach' && (<p style={{textAlign: "center", fontSize: "10px", marginBottom: "10px"}}>90 day free trial</p>)}
                {this.state.checked || planPrice_be<=calculateVal?(<h2><b>{curencyFilter(`USD##${calculateVal}`,'',this.state.rates)}</b></h2>)
                :
                (<>
                <div className="d-flex text-center flex-column" style={{lineHeight:'10px'}}>
                <del><small>{curencyFilter(`USD##${planPrice_be}`,'',this.state.rates)}</small></del>
                <h3 className="d-flex text-center w-100 justify-content-center">
                <b>{curencyFilter(`USD##${calculateVal}`,'',this.state.rates)}</b>
                
                </h3>
                <small>( {percentage} % saving)</small>

                </div>
                
                
                

                </>)}
              
              </div>

              <ul className="package_oofers">

              {this.state.planDesc.length > 0 && this.state.planDesc.map((descVal)=>{

              ////console.log('dddddddddsss',planVal,descVal.plan_id)
                  //detailsStatus= _.find(planVal, {'id': descVal.plan_id})

                  detailsStatus= _.find(planVal, function(o) { return (planVal.id == descVal.plan_id); });
                  //console.log('DetailsStatus',detailsStatus)
                
                 return(
                   detailsStatus!==undefined?
                   
                <li>{descVal.status==1?<svg id="Capa_1" enable-background="new 0 0 515.556 515.556" height="20" viewBox="0 0 515.556 515.556" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"/></svg>:<svg height="20px" viewBox="0 0 329.26933 329" width="20px" xmlns="http://www.w3.org/2000/svg" className="pk_cross"><g fill="#f44336"><path d="m21.339844 329.398438c-5.460938 0-10.925782-2.089844-15.082032-6.25-8.34375-8.339844-8.34375-21.824219 0-30.164063l286.589844-286.59375c8.339844-8.339844 21.824219-8.339844 30.164063 0 8.34375 8.339844 8.34375 21.824219 0 30.164063l-286.589844 286.59375c-4.183594 4.179687-9.621094 6.25-15.082031 6.25zm0 0"/><path d="m307.929688 329.398438c-5.460938 0-10.921876-2.089844-15.082032-6.25l-286.589844-286.59375c-8.34375-8.339844-8.34375-21.824219 0-30.164063 8.339844-8.339844 21.820313-8.339844 30.164063 0l286.589844 286.59375c8.34375 8.339844 8.34375 21.824219 0 30.164063-4.160157 4.179687-9.621094 6.25-15.082031 6.25zm0 0"/></g></svg>} {descVal.description}</li>
              :""
                )
            } ) }
              </ul>

              {this.userdata === null  ?


              <Link 
              // style={{pointerEvents: "none"}} 
              onClick={(e) => {
                e.preventDefault()
                this.handleOpenUpgradeDowngradeModal()
              }}
              to="#"
              // to={{
              //   pathname: "registration",
              //   state: {
              //     accountType: 'coach'
              //     //test: 'test1'
              //   }
              // }}
              className="btn btn-primary packege_buynow" >
              Select
              </Link>
              
            : this.state.currentPlanPrice==0?
            (
              <Link 
              // style={{pointerEvents: "none"}}
              onClick={(e) => {
              // if(this.props.membershipID != null && this.props.site==undefined) {
                  e.preventDefault()
                  this.setState({selectedToUpgradePlanDetail: planVal})
                  this.handleOpenUpgradeDowngradeModal()
                // }
              }} 
               className="btn btn-primary packege_buynow"   
               to="#"
              //  to={{
              //   // pathname: `/`,
              //   pathname: `/buy-subscription/${planVal.id}`,
               
              //       state: { 
              //         amount:calculateVal,
              //         duration:duration,
              //         membershipID:this.props.membershipID,
                      

              //       }
              //     }}
                >
                  Select
                  </Link>
            ) 
            : parseInt(planVal.plan_price) != parseInt(this.state.currentPlanPrice) ?
                  (
                    <Link 
                    // style={{pointerEvents: "none"}}
                      onClick={(e) => {
                        // if(this.props.membershipID != null && this.props.site==undefined) {
                          e.preventDefault()
                          this.setState({selectedToUpgradePlanDetail: planVal})
                          this.handleOpenUpgradeDowngradeModal()
                        // }
                      }} 
                      className="btn btn-primary packege_buynow"   
                      to="#"
                      // to={{
                      //   // pathname: `/`,
                      //   pathname: `/buy-subscription/${planVal.id}`,
                  
                      //   state: { 
                      //     amount:calculateVal,
                      //     duration:duration,
                      //     membershipID:this.props.membershipID,
                      //     currentPlanPrice:this.state.currentPlanPrice,
                          

                      //   }
                      // }}
                      >
                      Select
                    </Link> 
                  )
                :
                '' 

            }
              
              

                        

              {/* <Button href={"/buy-subscription/"+planVal.id} className="btn packege_buynow">Buy Now</Button> */}

            </div>
          </Col>


        )
        
        })}
        
          {/* <Col  xs="10" sm="10" md="8" lg="4" className="package_item  mb-5">
            <div className="package_card">
              <div className="packge_head">
                <h3>Premier</h3>
                <h2>$2.99</h2>
              </div>

              <ul className="package_oofers">
                <li><svg id="Capa_1" enable-background="new 0 0 515.556 515.556" height="20" viewBox="0 0 515.556 515.556" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"/></svg> Free Trial with LG</li>

                <li><svg id="Capa_1" enable-background="new 0 0 515.556 515.556" height="20" viewBox="0 0 515.556 515.556" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"/></svg> Can create profile</li>

                <li><svg id="Capa_1" enable-background="new 0 0 515.556 515.556" height="20" viewBox="0 0 515.556 515.556" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"/></svg> Can upload personal introducary video (2 mins long max)</li>

                <li><svg id="Capa_1" enable-background="new 0 0 515.556 515.556" height="20" viewBox="0 0 515.556 515.556" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"/></svg> Uploads photos, videos PDFs &media</li>

                <li><svg id="Capa_1" enable-background="new 0 0 515.556 515.556" height="20" viewBox="0 0 515.556 515.556" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"/></svg> Can receive 1:1 coaching booking requests</li>

                <li><svg id="Capa_1" enable-background="new 0 0 515.556 515.556" height="20" viewBox="0 0 515.556 515.556" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"/></svg> Can schedule 1:1 coaching sessions Can set own pricing </li>
              </ul>

              <Button href="#" className="btn packege_buynow">Buy Now</Button>

            </div>
          </Col> */}

          {/* <Col  xs="10" sm="10" md="8" lg="4" className="package_item  mb-5">
            <div className="package_card">
              <div className="packge_head">
                <h3>Premier</h3>
                <h2>$2.99</h2>
              </div>

              <ul className="package_oofers">
                <li><svg id="Capa_1" enable-background="new 0 0 515.556 515.556" height="20" viewBox="0 0 515.556 515.556" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"/></svg> Free Trial with LG</li>

                <li><svg id="Capa_1" enable-background="new 0 0 515.556 515.556" height="20" viewBox="0 0 515.556 515.556" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"/></svg> Can create profile</li>

                <li><svg id="Capa_1" enable-background="new 0 0 515.556 515.556" height="20" viewBox="0 0 515.556 515.556" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"/></svg> Can upload personal introducary video (2 mins long max)</li>

                <li><svg id="Capa_1" enable-background="new 0 0 515.556 515.556" height="20" viewBox="0 0 515.556 515.556" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"/></svg> Uploads photos, videos PDFs &media</li>

                <li><svg id="Capa_1" enable-background="new 0 0 515.556 515.556" height="20" viewBox="0 0 515.556 515.556" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"/></svg> Can receive 1:1 coaching booking requests</li>

                <li><svg id="Capa_1" enable-background="new 0 0 515.556 515.556" height="20" viewBox="0 0 515.556 515.556" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"/></svg> Can schedule 1:1 coaching sessions Can set own pricing </li>
              </ul>

              <Button href="#" className="btn packege_buynow">Buy Now</Button>

            </div>
          </Col> */}

        </Row>




        </div>
      </Container>

        <UpgradeDowngradeModal 
          isUpgradeBoolean={ this.isUpgradingCurrentPlan() } 
          subscriptionDetails={this.state.selectedToUpgradePlanDetail} 
          show={this.state.showUpgradeDowngradeModal} 
          handleClose={this.handleCloseUpgradeDowngradeModal} 
          getSubscription={this.getSubscription}
          currentPlanPrice={this.state.currentPlanPrice}
        />

    </div>

  );
  }


  isUpgradingCurrentPlan = () => {
    let isUpgrading = false

    try {
      
      isUpgrading = parseInt(this.state.currentPlanPrice) < parseInt(this.state.selectedToUpgradePlanDetail?.plan_price)
      console.log(isUpgrading);
      console.log(this.state.currentPlanPrice, this.state.selectedToUpgradePlanDetail?.plan_price);
    } catch (error) {
      
    }

    return isUpgrading
  }
  
 
  

  handleOpenUpgradeDowngradeModal = () => {
    message.info("Subscription plan coming soon",5)
    // this.setState({showUpgradeDowngradeModal: true})
  }

  handleCloseUpgradeDowngradeModal = () => {
    this.setState({showUpgradeDowngradeModal: false})
    this.setState({selectedToUpgradePlanDetail: null})
  }

}
export default PriceTableNew;
