
import React, { Component } from "react";
import axios from "../../../config/axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ProgressBar } from "react-bootstrap";
// import _ from "lodash";

import { Header, HeaderLogout, HeaderTop } from "../../../components/headers";
import { FooterLink } from "../../../components/footers";
import {
  Bio,
  Blog,
  Contact,
  Home,
  Resources,
  Testimonial,
} from "../../../views/coach";

import { avatar } from "../../../assets/img";
import {
  mailSvg,
  profileAddressSvg,
  flagSvg,
  clockSvg,
} from "../../../assets/images";
import {
  UpdateImage,
  UpdatePersonal,
  UpdateUserName,
} from "../../../views/coach/edit";
import CommunicationUsers from "../../../layouts/Communication/CommunicationUsers";

class CoachProfileWrap extends Component {
  constructor(props, context) {
    

    super(props, context);
    console.log('ssssss',this.props.location)
    this.token = localStorage.getItem("authToken");

    // if (localStorage.getItem("userdata")) {
    //   this.userdata = JSON.parse(localStorage.getItem("userdata"));
    // } else {
    //   if (this.props.location.state !== "undefined") {
    //     this.userdata = {
    //       account_type: "user",
    //       uid: this.props.location.state.coachData.uid,
    //     };
    //   } else {
    //     alert("please Login for more details.");
    //   }
    // }

    if (localStorage.getItem("user")) {
      this.userdata = JSON.parse(localStorage.getItem("user"));
    }

    else if (this.props.location !== undefined) {
      this.userdata = {
        account_type: "user",
        uid: this.props.location.state.coachData.uid,
      };

    }

    else if (localStorage.getItem("userdata")) {
      this.userdata = JSON.parse(localStorage.getItem("userdata"));


    }


    else {
      // #temprary if page will refereshed by mistake
      this.userdata = {
        account_type: "user",
        uid: "44abb6bc-8361-4a56-a88a-3b4c8b10eea2",
      };
    }

    // this.coachingType = [];

    this.state = {
      freeTrialDaysleft: "",
      renderSubComponent: "Home",
      avatar: "",
      avatar_path: avatar,
      name: "",
      coach_title: "",
      user_title: "",
      // coach_category: "",
      // coaching_category: "",
      city: "",
      email: "",
      country: "",
      location: "",
      userImage: "",
      coach_uid: "",
      short_bio: "",
      long_bio: "",
      videofile: "",
      tags: "",
      skills: "",
      isLoaded: "",
      coachprofile_id: "",
      check_account_type: "",
      // dounat chart value using this___start
      chartValue: [],
      labels_data: [],
      data_data: [],
      color_data: [],
      // dounat chart value using this____end
      // coaching_category_list: [],
      language: [{ value: "English", label: "English" }],
      timezone: "",
      HoursofExp: "",
      HourlyRate: "",
      membership: 0,
      alertMsg: "",

      baseCurrency: "USD",
      convertToCurrency: "USD",
      baseAmount: 100,
      rates: [],
      currencies: [],
      findcurrency: 1,
      totalprofileupdate: "",
      tag_update_profile: 0,
      avatar_path_update_profile: 0,
      user_title_update_profile: 0,
      short_bio_update_profile: 0,
      packages_update_profile: 0,
      coachingCategory: null,
      coachUid: "",
      coachEmail: "",
      Consumer_name: "",
      Consumer_email: "",
      SocialMediaInfo: [{ SocialMedia: "", URL: "" }],
    };
  }



  componentDidMount() {
   
    this.callAPI(this.state.baseCurrency);
    if (this.userdata.account_type === "coach") {
      this.setState({ check_account_type: "coach" });
      this.coachMode();
      this.handleFunction();
    } else if (this.props.location.state !== undefined) {
      if (this.props.location.state.coachData.account_type === "coach") {
        this.setState({ check_account_type: "consumer" });
        this.consumerMode();
      } else {
        console.log("visitor");
      }
    } else {
      console.log("visitor, localstorage data can not read");
    }
  }

  coachMode() {
    this.profileUpdateStatus();
    this.fetchUserName();
    this.fetchCoachData();
    this.handleFunction();
    this.getPackages();
  }

  consumerMode() {
    const rawdata = this.props.location.state.coachData;
    let Consumer_name = this.props.location.state.Consumer_name
    console.log(rawdata);
    this.setState({
      name: rawdata.name,
      coachEmail: rawdata.email,
      coaching_category: rawdata.coaching_category,
      coach_title: rawdata.coach_title,
      coachUid: rawdata.uid,
      Consumer_uid: this.props.location.state.Consumer_uid,
      Consumer_name: Consumer_name,
      Consumer_email: this.props.location.state.Consumer_email
    });
    this.updateStateData(rawdata);
  }

  updateStateData = (rawdata) => {
    const rawaddress = rawdata.address ? JSON.parse(rawdata.address) : "";
    // const rawskills = rawdata.skills ? JSON.parse(rawdata.skills) : "";

    this.setState({
      userImage: rawdata.avatar_path,
      coach_uid: rawdata.coach_uid,
      short_bio: rawdata.short_bio,
      // long_bio: rawdata.long_bio,
      long_bio:
        rawdata.long_bio !== undefined ? rawdata.long_bio : "loading...",

      videofile: rawdata.profile_video,
      avatar_path: rawdata.avatar_path ? rawdata.avatar_path : avatar,
      country: rawaddress.country,
      location: rawaddress.location,
      city: rawaddress.city,
      coachprofile_id: rawdata.uid,
      tags: rawdata.tags,
      skills: rawdata.skills ? JSON.parse(rawdata.skills) : "",
      // tag_update_profile: 0,
      // avatar_path_update_profile: 0,
      // short_bio_update_profile: 0,
      // packages_update_profile: 0,

      language: rawdata.language ? JSON.parse(rawdata.language) : "",
      timezone: rawdata.timezone,
      HoursofExp: rawdata.hours_of_experience,
      HourlyRate: rawdata.hourly_rate,
      membership: rawdata.has_membership,
      wordcount:
        rawdata.short_bio && parseInt(rawdata.short_bio.split(" ").length),
      SocialMediaInfo: rawdata.social_media !== null ? (JSON.parse(rawdata.social_media)) : ([{ SocialMedia: "", URL: "" }])
    });
  };

  // ____________________________Fetch_data___________________________________

  fetchUserName() {
    let self = this;
    let param = {
      url: "/users/username",
      method: "GET",
      headers: {
        "X-Auth": this.token,
      },
    };
    axios(param)
      .then(async (res) => {
        if (res.data.value) {
          const rawdata = res.data.value;
          self.setState({
            name: rawdata.name,
            // coaching_category: rawdata.coaching_category,
            user_title: rawdata.user_title,
            coachingCategory: rawdata.coaching_category
              ? JSON.parse(rawdata.coaching_category)
              : "",
          });
          this.setState({
            user_title_update_profile: 0,
          });
          if (this.state.user_title && this.state.user_title.length > 0) {
            this.setState({
              user_title_update_profile: 20,
            });
          }
          this.profileUpdateStatus();
        }
      })
      .catch((error) => {
        console.log(error);
        toast("Error getting User data", { type: "error" });
      });
  }

  fetchCoachData() {
    const id = this.userdata.uid;

    let param = {
      url: `/users/coachprofile/${id}`,
      method: "GET",
      headers: {
        "X-Auth": this.token
      }
    }
    axios(param)
      .then(async (res) => {
        console.log("-------------------------------------------res-socail-------------", res.data.value)
        if (res.data.value) {
          const rawdata = res.data.value;
          // const rawaddress = rawdata.address ? JSON.parse(rawdata.address) : "";
          const rawskills = rawdata.skills ? JSON.parse(rawdata.skills) : "";
          this.setState({ chartValue: rawskills });

          if (rawskills.length > 1) {
            await this.setState({
              chartValue: rawskills,
              isLoaded: true,
            });
          } else {
            this.setState({ isLoaded: false });
          }
          this.updateStateData(rawdata);

          if (this.state) {
            if (this.state.avatar_path !== avatar) {
              this.setState({ avatar_path_update_profile: 20 });
            }
            if (this.state.short_bio.length > 2) {
              this.setState({ short_bio_update_profile: 20 });
            }

            // if (this.state.short_bio.length > 2) {
            //   this.setState({ packages_update_profile: 20 });
            // }

            if (this.state.tags.length > 2) {
              this.setState({ tag_update_profile: 20 });
            }
          }

          this.profileUpdateStatus();
          this.dountChartUpdated();
        }
      })
      .catch((error) => {
        console.log("fetch error");
        console.log(error);
        // toast("Error getting profile data", { type: "error" });
      });
  }

  getPackages() {
    const id = this.userdata.uid;
    axios
      .get(`/users/packages/${id}`, {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          let pack = res.data.value;
          if (pack.length > 0) {
            this.setState({ packages_update_profile: 20 });
            this.profileUpdateStatus();
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  packagefound = () => {
    this.setState({ packages_update_profile: 20 });
    this.profileUpdateStatus();
  };
  // ____________________________Handle_Function_______________________________

  handleChangeMultiSkills = (e) => {
    const index =
      this.state.chartValue.length > 0
        ? this.state.chartValue.indexOf(e.target.id)
        : -1;

    if (e.currentTarget.checked) {
      if (index === -1) {
        if (this.state.chartValue.length < 4) {
          this.setState({
            chartValue: [...this.state.chartValue, e.target.id],
          });
        } else {
          toast("You can add maximum 4 styles", { type: "error" });
        }
      }
    }
    if (!e.currentTarget.checked) {
      this.setState({
        chartValue: [
          ...this.state.chartValue.filter((element) => element !== e.target.id),
        ],
      });
    }
  };

  handleSubComponent = (cname) => {
    this.setState({ renderSubComponent: cname });
  };

  handleChange = (e) => {
    e.persist();
    if (e.target.name === "short_bio") {
      let limit = 50 - parseInt(e.target.value.split(" ").length);
      let ro = e.target.value.split(" ").length > 50 ? true : false;
      let msg =
        e.target.value.split(" ").length > 50
          ? "Short bio word limit exceeded"
          : "";
      this.setState({ wordcount: limit, alertMsg: msg, readonly: ro });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // this is for long bio text
  handleOnChange = (event, editor) => {
    const data = editor.getData();
    this.setState({
      long_bio: data,
    });
  };

  handlegetProfileVideo = () => {
    this.fetchCoachData();
  };
  handlegetProfileImage = () => {
    this.fetchCoachData();
  };
  handleSubmitProfileVideo = () => {
    this.fetchCoachData();
  };
  onChangeHandler = (e) => {
    this.setState({
      avatar_path_temp: e.target.files[0],
      loaded: 0,
    });
  };

  handleAddressSelected = (address, country, city) => {
    this.setState({ country: country, city: city, location: address });
  };

  handleLanguageChange = (language) => {
    this.setState({ language: language });
  };

  handleCategoryChange = (data) => {
    this.setState({ coachingCategory: data });
  };

  handleCheckbox = (e) => {
    if (e.target.value == 0) {
      this.setState({ membership: 1 });
    } else if (e.target.value == 1) {
      this.setState({ membership: 0 });
    }
  };

  changeConvertToCurrency = (e) => {
    this.setState({
      convertToCurrency: e.target.value,
    });
    const ps = this.state.rates[this.state.convertToCurrency];
    console.log("currency----", this.state.rates)
    console.log("currency----", this.state.rates[this.state.convertToCurrency])
    console.log("psssss---------", ps)
    this.setState({ findcurrency: ps });
  };

  handleFunction = () => {
    const accontCreated = new Date(this.userdata.created);
    const finalDate = new Date(accontCreated);
    finalDate.setDate(accontCreated.getDate() + 90);
    var countDownDate = new Date(finalDate);
    var now = new Date();
    var timeleft = countDownDate - now;
    var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    this.setState({
      freeTrialDaysleft: days >= 0 ? days : 0,
    });
  };

  profileUpdateStatus() {
    this.setState({ totalprofileupdate: 0 });
    const totalProfile =
      parseInt(this.state.avatar_path_update_profile) +
      parseInt(this.state.user_title_update_profile) +
      parseInt(this.state.short_bio_update_profile) +
      parseInt(this.state.tag_update_profile) +
      parseInt(this.state.packages_update_profile);
    this.setState({ totalprofileupdate: totalProfile });
  }

  callAPI(base) {
    const api = `https://api.exchangeratesapi.io/latest?base=${base}`;

    fetch(api)
      .then((results) => {
        return results.json();
      })
      .then((data) =>
        this.setState({
          rates: data["rates"],
          currencies: Object.keys(data["rates"]).sort(),
        })
      );
  }

  dountChartUpdated() {
    var skillName = [];
    var skillvalue = [];
    var skillcolor = [];

    // ["red##25##redSkill2", "yellow##25##yelloSkill3"]

    if (this.state.chartValue.length > 0) {
      this.state.chartValue.map((item) => {
        skillcolor.push(item.split("##")[0]);
        skillvalue.push(parseInt(item.split("##")[1]));
        skillName.push(item.split("##")[2]);
      });
    }

    this.setState({
      labels_data: skillName,
      data_data: skillvalue,
      color_data: skillcolor,
    });
  }

  // ____________________________Handle_Function_submit______________________________

  // handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (this.state.short_bio && this.state.short_bio.split(" ").length > 50) {
  //     toast("Word limit exceeded for short bio", {
  //       type: "error",
  //     });
  //   } else {
  //     const addressData = {
  //       country: this.state.country,
  //       city: this.state.city,
  //       location: this.state.location,
  //     };
  //     const formData = {
  //       address: JSON.stringify(addressData),
  //       short_bio: this.state.short_bio,
  //       coach_uid: this.state.coach_uid,
  //       long_bio: this.state.long_bio,
  //       skills: JSON.stringify(this.state.chartValue),
  //       language: this.state.language,
  //       timezone: this.state.timezone,
  //       HoursofExp: this.state.HoursofExp,
  //       HourlyRate: this.state.HourlyRate,
  //       membership: this.state.membership,
  //     };
  //     let param;
  //     if (this.state.coachprofile_id !== "") {
  //       formData["uid"] = this.state.coachprofile_id;
  //       param = {
  //         url: "/users/coachprofile",
  //         method: "PUT",
  //         data: formData,
  //         headers: {
  //           "X-Auth": this.token,
  //         },
  //       };
  //     } else {
  //       param = {
  //         url: "/users/coachprofile",
  //         method: "POST",
  //         data: formData,
  //         headers: {
  //           "X-Auth": this.token,
  //         },
  //       };
  //     }

  //     axios(param)
  //       .then((response) => {
  //         toast("Personal details updated", {
  //           type: "success",
  //         });

  //         const rawdata = response.data.profilePost;
  //         const rawaddress = rawdata.address ? JSON.parse(rawdata.address) : "";
  //         const rawskills = rawdata.skills ? JSON.parse(rawdata.skills) : "";

  //         if (rawskills.length > 0) {
  //           this.setState({
  //             chartValue: rawskills,
  //             isLoaded: true,
  //           });
  //         } else {
  //           this.setState({
  //             isLoaded: false,
  //           });
  //         }

  //         this.setState({
  //           userImage: rawdata.avatar_path,
  //           coach_uid: rawdata.coach_uid,
  //           short_bio: rawdata.short_bio,

  //           long_bio:
  //             rawdata.long_bio !== undefined
  //               ? rawdata.long_bio
  //               : this.state.responseData.profilePost.long_bio,

  //           videofile: rawdata.profile_video,
  //           country: rawaddress.country,
  //           location: rawaddress.location,
  //           city: rawaddress.city,
  //           coachprofile_id: rawdata.uid,
  //           isUpdate: true,
  //           showLoader: false,

  //           language: rawdata.language,
  //           timezone: rawdata.timezone,
  //           HoursofExp: rawdata.hours_of_experience,
  //           HourlyRate: rawdata.hourly_rate,
  //           membership: rawdata.has_membership,
  //         });
  //         this.setState({
  //           short_bio_update_profile: 0,
  //         });

  //         if (this.state.short_bio.length > 5) {
  //           this.setState({
  //             short_bio_update_profile: 20,
  //           });
  //         }

  //         this.setState({
  //           user_title_update_profile: 0,
  //         });
  //         if (this.state.user_title && this.state.user_title.length > 0) {
  //           this.setState({
  //             user_title_update_profile: 20,
  //           });
  //         }

  //         this.profileUpdateStatus();

  //         toast("Personal details updated", {
  //           type: "success",
  //         });
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         toast("Error in updating details", {
  //           type: "error",
  //         });
  //       });
  //   }
  // };

  handleResourceSubmit = (flag) => {
    this.setState({ showLoader: flag });
  };

  handleSubmitSkill = (e) => {
    e.preventDefault();

    const addressData = {
      country: this.state.country,
      city: this.state.city,
      location: this.state.location,
    };

    const formData = {
      address: JSON.stringify(addressData),
      short_bio: this.state.short_bio,
      coach_uid: this.state.coach_uid,
      long_bio: this.state.long_bio,
      skills: JSON.stringify(this.state.chartValue),
      name: this.state.name,

      language: this.state.language,
      timezone: this.state.timezone,
      HoursofExp: this.state.HoursofExp,
      HourlyRate: this.state.HourlyRate,
      membership: this.state.membership,
    };

    let param;
    if (this.state.coachprofile_id !== "") {
      formData["uid"] = this.state.coachprofile_id;
      param = {
        url: "/users/coachprofile",
        method: "PUT",
        data: formData,
        headers: {
          "X-Auth": this.token,
        },
      };
    } else {
      param = {
        url: "/users/coachprofile",
        method: "POST",
        data: formData,
        headers: { "X-Auth": this.token },
      };
    }

    axios(param)
      .then((response) => {
        toast("Coaching style added successfully", { type: "success" });

        this.fetchCoachData();
        this.dountChartUpdated();
      })
      .catch((error) => {
        console.log(error);
        toast("Error in updating", { type: "error" });
      });
  };

  handleSubmitProfileImage = (e) => {
    this.setState({ showLoader: true });
    e.preventDefault();
    const formData = new FormData();
    formData.append("avatar_path", this.state.avatar_path_temp);

    let param;
    if (this.state.coachprofile_id !== "") {
      formData["uid"] = this.state.coachprofile_id;
      param = {
        url: "/users/profileimage",
        method: "PUT",
        data: formData,
        headers: {
          "X-Auth": this.token, //this.props.location.state.token,
          "Content-type": "multipart/form-data",
        },
      };
    } else {
      param = {
        url: "/users/profileimage",
        method: "POST",
        data: formData,
        headers: {
          "X-Auth": this.token, //this.props.location.state.token,
          "Content-type": "multipart/form-data",
        },
      };
    }

    axios(param)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            avatar_path: response.data.profileImagePost.avatar_path,
            showLoader: false,
            avatar_path_temp: null,
            avatar_path_update_profile: 20,
          });
          toast("Image updated", { type: "success" });
          this.profileUpdateStatus();
          this.fetchCoachData();
        }
      })
      .catch((error) => {
        console.log("photo update error");
        console.log(error);
        toast("Error in updaing profile image", { type: "error" });
        this.setState({
          showLoader: false,
        });
      });
  };

  handleUpdateTags = (formData) => {
    let param;
    if (this.state.coachprofile_id !== "") {
      param = {
        url: "/users/tags",
        method: "PUT",
        data: formData,
        headers: {
          "X-Auth": this.token,
        },
      };
    } else {
      param = {
        url: "/users/coachprofile-edit",
        method: "POST",
        data: formData,
        headers: { "X-Auth": this.token },
      };
    }
    axios(param)
      .then((res) => {
        if (res.data.success) {
          toast(res.data.msg, { type: "success" });
        } else if (!res.data.success) {
          toast(res.data.err, { type: "error" });
        }

        this.setState({ tag_update_profile: 0 });
        if (this.state.tags.length > 2) {
          this.setState({ tag_update_profile: 20 });
        }
        this.fetchCoachData();
        this.profileUpdateStatus();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSubmitUserName = (e) => {
    e.preventDefault();
    const formData = {
      user_title: this.state.user_title,
      name: this.state.name,
      coaching_category: this.state.coachingCategory,
      // coaching_category: this.state.coaching_category_list,
    };

    let param;

    param = {
      url: "/users/username",
      method: "PUT",
      data: formData,
      headers: {
        "X-Auth": this.token,
      },
    };

    axios(param)
      .then((response) => {
        this.fetchUserName();
        toast("Details updated", { type: "success" });
      })
      .catch((error) => {
        console.log("update error");
        console.log(error);
        toast("Error in updating details", { type: "error" });
      });
  };

  handleSubmitPersonal = (e, Data) => {
    console.log("Data-----9", Data)
    e.preventDefault()
    if (this.state.short_bio && this.state.short_bio.split(" ").length > 50) {
      toast("Word limit exceeded for short bio", {
        type: "error",
      });
    } else {
      const addressData = {
        country: this.state.country,
        city: this.state.city,
        location: this.state.location,
      };

      const formData = {
        user_title: this.state.user_title,
        name: this.state.name,
        coaching_category: this.state.coachingCategory,
        Uid: this.state.coachprofile_id,

        address: JSON.stringify(addressData),
        short_bio: this.state.short_bio,
        coach_uid: this.state.coach_uid,
        long_bio: this.state.long_bio,
        skills: JSON.stringify(this.state.chartValue),
        language: this.state.language,
        timezone: this.state.timezone,
        HoursofExp: this.state.HoursofExp,
        HourlyRate: this.state.HourlyRate,
        membership: this.state.membership,
        tags: this.state.tags,
        SocialMediaInfo: Data !== undefined ? Data : this.state.SocialMediaInfo
      }

      axios.post('/users/coachprofile-edit', formData, {
        headers: { 'x-auth': this.token }
      })
        .then(res => {
          if (res.data.success) {
            const rawdata = res.data.profilePost;
            this.setState({
              short_bio: rawdata.short_bio,
              SocialMedia: JSON.parse(rawdata.social_media)
            })

            toast("Sucessfully updated profile details", { type: "success" });

          }
          this.setState({
            short_bio_update_profile: 0,
          });

          if (this.state.short_bio.length > 5) {
            this.setState({
              short_bio_update_profile: 20,
            });
          }

          this.setState({
            user_title_update_profile: 0,
          });
          if (this.state.user_title && this.state.user_title.length > 0) {
            this.setState({
              user_title_update_profile: 20,
            });
          }

          this.profileUpdateStatus();
          console.log("---------personal--res------", res)
        })
        .catch((error) => {
          console.log(error);
          toast("Error in updating details", {
            type: "error",
          });
        });
    }
  }

  render() {

    const {
      avatar_path,
      name,
      coaching_category,
      city,
      email,
      country,
      short_bio,
      tags,
      timezone,
      user_title,
    } = this.state;

    var subComponentContent;
    if (this.state.renderSubComponent === "Home") {
      subComponentContent = (
        <Home
          token={this.token}
          account_type={this.userdata.account_type}
          tags={tags}
          handleUpdateTags={this.handleUpdateTags}
          packagefound={this.packagefound}
          convertToCurrency={this.state.convertToCurrency}
          coaching_category={coaching_category}
          findcurrency={this.state.findcurrency}
          baseCurrency={this.state.baseCurrency}
          check_account_type={this.state.check_account_type}
          userdata={this.userdata}
          uid={this.userdata.uid}
          videofile={this.state.videofile}
          coachName={this.state.name}
          coachEmail={this.state.coachEmail}
          coachUid={this.state.coachUid}
          Consumer_uid={this.state.Consumer_uid}
          Consumer_name={this.state.Consumer_name}
          Consumer_email={this.state.Consumer_email}
          coachImage={this.state.avatar_path}
        />
      );
    } else if (this.state.renderSubComponent === "Bio") {
      subComponentContent = (
        <Bio
          logoImg={this.state.logoview}
          pdata={this.state}
          token={this.token}
          handleChange={this.handleChange}
          handleSubmitPersonal={this.handleSubmitPersonal}
          handleOnChange={this.handleOnChange}
          check_account_type={this.state.check_account_type}
          userdata={this.userdata}
        />
      );
    } else if (this.state.renderSubComponent === "Resources") {
      subComponentContent = (
        <Resources
          token={this.token}
          handleSubmit={this.handleResourceSubmit}
          data={this.state}
          handlegetProfileVideo={this.handlegetProfileVideo}
          handlegetProfileImage={this.handlegetProfileImage}
          check_account_type={this.state.check_account_type}
          userdata={this.userdata}
        />
      );
    } else if (this.state.renderSubComponent === "Contacts") {
      subComponentContent = (
        <Contact
          email={email}
          token={this.token}
          check_account_type={this.state.check_account_type}
        />
      );
    } else if (this.state.renderSubComponent === "Testimonial") {
      subComponentContent = (
        <Testimonial
          token={this.token}
          chartValue={this.state.chartValue}
          handleChangeMultiSkills={this.handleChangeMultiSkills}
          labels_data={this.state.labels_data}
          data_data={this.state.data_data}
          color_data={this.state.color_data}
          handleSubmitSkill={this.handleSubmitSkill}
          check_account_type={this.state.check_account_type}
        />
      );
    } else if (this.state.renderSubComponent === "Blog") {
      subComponentContent = (
        <Blog check_account_type={this.state.check_account_type} />
      );
    }

    return (
      <React.Fragment>
     

      
      


        <section className="coachsProfileWrap">
          <div className="container">
            <div className="row align-items-start">
              

              <div className="col-sm-3  pro_img_block">
                <div className="f_sidebar">
                  <div className="coachImg">

                    {this.state.check_account_type === "coach" ? (
                      <div className="freeTrialDays">
                        <button
                          type="button"
                          className={`btn ${this.state.freeTrialDaysleft < 30
                              ? "btn-danger"
                              : "btn-secondary"
                            } btn-circle btn-xl`}
                        >
                          {this.state.freeTrialDaysleft + " "}
                          <span>
                            days of <br /> free trial remaining
                          </span>
                        </button>
                      </div>
                    ) : (
                        ""
                      )}


                    <img src={avatar_path} alt="LG-Img" className="img-fluid" />
                    {/* {this.state.check_account_type === "coach" ? (
                      this.state.renderSubComponent === "Home" ? (
                        <UpdateImage
                          avatar={avatar}
                          userImage2={avatar_path}
                          onChangeHandler={this.onChangeHandler}
                          handleSubmitProfileImage={this.handleSubmitProfileImage}
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}{" "} */}
                  </div>
                  <div className="fs_cont">
                    {this.state.check_account_type === "coach" ? (

                      <div>
                        <small style={{ color: "black" }}>Profile Complete</small>

                        <ProgressBar
                          now={this.state.totalprofileupdate}
                          label={`${this.state.totalprofileupdate}%`}
                          // data-toggle="tooltip"
                          // data-placement="top"
                          title={`Your Profile is ${this.state.totalprofileupdate}% completed`}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="col-sm-9">
                {this.state.check_account_type === "coach" &&
                  this.state.freeTrialDaysleft == 0 ? (
                    <div className="freetrial-over mb-4">
                      <span>
                        Your 90 days free trial is over and account has been
                      deactivated.{" "}
                      </span>
                      <br></br>
                      <span>
                        Please
                      <Link
                          to={{
                            pathname: "/price_table",
                            state: {
                              user_t: this.userdata.uid,
                            },
                          }}
                        >
                          {" "}
                        pay here{" "}
                        </Link>
                      to activate your account
                    </span>
                    </div>
                  ) : (
                    <div></div>
                  )}

                <div className="coachInfoWrap">
                  <div className="coachInfo">
                    <div className="cDetails">
                      <h3>
                        {name} {""}
                        {/* {this.state.check_account_type === "coach" ? (
                          this.state.renderSubComponent === "Home" ? (
                            <UpdateUserName
                              name={this.state.name}
                              handleChange={this.handleChange}
                              handleSubmitUserName={this.handleSubmitUserName}
                              handleCategoryChange={this.handleCategoryChange}
                              pdata={this.state}
                            />
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )} */}
                      </h3>
                      <h4> {user_title}</h4>
                      <h5>
                        {this.state.coachingCategory &&
                          this.state.coachingCategory.map((category) => {
                            return (
                              <span className="badge badge-light even-larger-badge">
                                {" "}
                                {category.value}
                              </span>
                            );
                          })}
                      </h5>
                    </div>
                    <div className="contactDetails">
                      <p style={{ display: "none" }}>
                        <img width="20px" alt="LG-Img" src={mailSvg} />
                        {email}
                      </p>

                      {city && (
                        <p>
                          <img
                            width="20px"
                            alt="LG-Img"
                            src={profileAddressSvg}
                          />
                          {city}
                        </p>
                      )}
                      {country && (
                        <p>
                          <img width="20px" alt="LG-Img" src={flagSvg} />{" "}
                          {country}
                        </p>
                      )}
                      {timezone && (
                        <p>
                          <img width="20px" alt="LG-Img" src={clockSvg} />{" "}
                          {timezone}
                        </p>
                      )}
                    </div>
                    <p>{short_bio && short_bio}</p>
                  </div>

                  <ul>
                    <li
                      className={`${this.state.renderSubComponent === "Home"
                          ? "active"
                          : " "
                        }`}
                    >
                      <Link
                        to={
                          this.state.check_account_type === "coach"
                            ? "/profile-coach"
                            : "/profile-coach-view"
                        }
                        onClick={() => this.handleSubComponent("Home")}
                      >
                        <i className="fa fa-home" aria-hidden="true"></i> HOME
                      </Link>
                    </li>

                    <li
                      className={`${this.state.renderSubComponent === "Bio" ? "active" : " "
                        }`}
                    >
                      <Link
                        to={
                          this.state.check_account_type === "coach"
                            ? "/profile-coach"
                            : "/profile-coach-view"
                        }
                        onClick={() => this.handleSubComponent("Bio")}
                      >
                        <i className="fa fa-info" aria-hidden="true"></i> BIO
                      </Link>
                    </li>

                    <li
                      className={`${this.state.renderSubComponent === "Resources"
                          ? "active"
                          : " "
                        }`}
                    >
                      <Link
                        to={
                          this.state.check_account_type === "coach"
                            ? "/profile-coach"
                            : "/profile-coach-view"
                        }
                        onClick={() => this.handleSubComponent("Resources")}
                      >
                        <i className="fa fa-camera" aria-hidden="true"></i>
                        RESOURCES
                      </Link>
                    </li>
                    <li
                      className={`${this.state.renderSubComponent === "Contacts"
                          ? "active"
                          : " "
                        }`}
                    >
                      <Link
                        to={
                          this.state.check_account_type === "coach"
                            ? "/profile-coach"
                            : "/profile-coach-view"
                        }
                        onClick={() => this.handleSubComponent("Contacts")}
                      >
                        <i className="fa fa-globe" aria-hidden="true"></i>
                        CONTACT
                      </Link>
                    </li>

                    <li
                      className={`${this.state.renderSubComponent === "Testimonial"
                          ? "active"
                          : " "
                        }`}
                    >
                      <Link
                        to={
                          this.state.check_account_type === "coach"
                            ? "/profile-coach"
                            : "/profile-coach-view"
                        }
                        onClick={() => this.handleSubComponent("Testimonial")}
                      >
                        <i className="fa fa-comments-o" aria-hidden="true"></i>
                        TESTIMONIALS
                      </Link>
                    </li>
                    <li
                      className={`${this.state.renderSubComponent === "Blog"
                          ? "active"
                          : " "
                        }`}
                    >
                      <Link
                        to={
                          this.state.check_account_type === "coach"
                            ? "/profile-coach"
                            : "/profile-coach-view"
                        }
                        onClick={() => this.handleSubComponent("Blog")}
                      >
                        <i className="fa fa-th-large" aria-hidden="true"></i>
                        BLOG
                      </Link>
                    </li>
                  </ul>
                  <div style={{ position: "absolute", right: "20px", top: 0 }}>
                    {this.state.check_account_type === "coach" ? (
                      this.state.renderSubComponent === "Home" ? (
                        <UpdatePersonal
                          pdata={this.state}
                          handleChange={this.handleChange}
                          handleSubmit={this.handleSubmit}
                          handleAddressSelected={this.handleAddressSelected}
                          handleCheckbox={this.handleCheckbox}
                          handleLanguageChange={this.handleLanguageChange}

                          avatar={avatar}
                          userImage2={avatar_path}
                          onChangeHandler={this.onChangeHandler}
                          handleSubmitProfileImage={this.handleSubmitProfileImage}

                          handleCategoryChange={this.handleCategoryChange}

                          handleSubmitPersonal={this.handleSubmitPersonal}
                        />
                      ) : (
                          ""
                        )
                    ) : (
                        <Link to="profile-consumer">
                          <button className="btn btn-sm btn-info">Back</button>
                        </Link>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {subComponentContent}

       
      </React.Fragment>
    );
  }
}

export default CoachProfileWrap;
