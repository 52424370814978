import React from 'react'
import {dArrowblack } from '../../assets/images'

class ContactUS extends React.Component{
  constructor(props) {
    super(props);
   
  }
    render(){
        return(
            <div>
                    <section className="contactWrap" >
  <div className="container">
    <div className="row">

      <div className="col-md-6 p-0">
        <iframe title="title" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24207.768181049603!2d-103.2172937229882!3d40.67460742577896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8771f298fcd3ef39%3A0x63ce5177c74f861d!2sLogan%20County!5e0!3m2!1sen!2sin!4v1595776513231!5m2!1sen!2sin" height="580" frameborder="0" style={{border:"0"}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </div>
      <div className="col-md-6">
          <div className="heading text-center">
             <h3>Contact Us</h3>
             <span className="R_divider"></span>
          </div>

          <form className="pr-4 mb-4" action="" method="get" accept-charset="utf-8">
              <div className="form-group">
                <input type="text" name="" value="" placeholder="Full Name" className="form-control"/>
              </div>
              <div className="form-group">
                <input type="number" name="" value="" placeholder="Phone Number" className="form-control"/>
              </div>
              <div className="form-group">
                <input type="Email" name="" value="" placeholder="Email Address" className="form-control"/>
              </div>
              <div className="form-group">
                <div className="selectBox">
                  <select name="" className="form-control">
                    <option value="" selected="selected">Select Category</option>
                    <option value="">Career Coaching</option>
                    <option value="">Executive &amp; Business  Coaching </option>
                    <option value="">Life Coaching</option>
                    <option value="">Transformational  Coaching</option>
                    <option value="">Wellbeing  Coaching</option>
                  </select>
                  <span>
                      <img src={dArrowblack} alt=""/>
                   </span>
                </div>
              </div>
              <div className="form-group">
                <textarea placeholder="Your messages" name="" rows="3" className="form-control"></textarea>
              </div>
              <button className="defaultBtn" type="button">SEND</button>
          </form>
      </div>
    </div>
  </div>
</section>
            </div>
        )
    }
}
export default ContactUS