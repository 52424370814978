import React from 'react'
import moment from "moment";
import axios from '../../../config/axios'
import { toast } from "react-toastify";
import message from '../../../utils/mesaages';
const _ = require("lodash");

class UpdateWeekList extends React.Component{
    constructor(props){
        super(props)
        this.state={
            SundayTimings: [],
            MondayTimings: [],
            TuesdayTimings: [],
            WednesdayTimings: [],
            ThursdayTimings: [],
            FridayTimings: [],
            SaturdayTimings: [],

            currDate: moment().format('DD-MM-YYYY'),
        }
    }

    componentDidMount(){
        this.getAvailability()
        
      }

    componentDidUpdate(prevProps) {
      // console.log("weeek set--date",this.props.WeekSetdata)
        if(prevProps.WeekSetdata !== this.props.WeekSetdata) {
          this.setState({
            SundayTimings:this.props.WeekSetdata[0].Sundays.length !==0 ?this.props.WeekSetdata[0].Sundays:[{Day:"Sunday",From: "", To: ""}],
            MondayTimings:this.props.WeekSetdata[1].Mondays.length !==0 ?this.props.WeekSetdata[1].Mondays:[{Day:"Monday",From: "", To: ""}],
            TuesdayTimings:this.props.WeekSetdata[2].Tuesdays.length !==0 ?this.props.WeekSetdata[2].Tuesdays:[{Day:"Tuesday",From: "", To: ""}],
            WednesdayTimings:this.props.WeekSetdata[3].Wednesdays.length !==0 ?this.props.WeekSetdata[3].Wednesdays:[{Day:"Wednesday",From: "", To: ""}],
            ThursdayTimings:this.props.WeekSetdata[4].Thursdays.length !==0 ?this.props.WeekSetdata[4].Thursdays:[{Day:"Thursday",From: "", To: ""}],
            FridayTimings:this.props.WeekSetdata[5].Fridays.length !==0 ?this.props.WeekSetdata[5].Fridays:[{Day:"Friday",From: "", To: ""}],
            SaturdayTimings:this.props.WeekSetdata[6].Saturdays.length !==0 ?this.props.WeekSetdata[6].Saturdays:[{Day:"Saturday",From: "", To: ""}],
         })
  
        }
      }
    
      getAvailability(){
        const id=this.props.coachUid
        axios.get(`/coach/setavailability/${id}`,{
          headers:{'x-auth':this.props.token}
        })
        .then(res =>{
        //   console.log("ress------",res)
          if(res.data.success && res.data.value.length > 0){
           
           
          let dataset=res.data.value[0]
          let data=JSON.parse(dataset.schedule_details)
          //  console.log("datatat----11--",data)
          let weekdata
         
            weekdata=data.availableDays
    
                this.setState({
                  SundayTimings:weekdata[0].Sundays.length !==0 ?weekdata[0].Sundays:[{Day:"Sunday",From: "", To: ""}],
                  MondayTimings:weekdata[1].Mondays.length !==0 ?weekdata[1].Mondays:[{Day:"Monday",From: "", To: ""}],
                  TuesdayTimings:weekdata[2].Tuesdays.length !==0 ?weekdata[2].Tuesdays:[{Day:"Tuesday",From: "", To: ""}],
                  WednesdayTimings:weekdata[3].Wednesdays.length !==0 ?weekdata[3].Wednesdays:[{Day:"Wednesday",From: "", To: ""}],
                  ThursdayTimings:weekdata[4].Thursdays.length !==0 ?weekdata[4].Thursdays:[{Day:"Thursday",From: "", To: ""}],
                  FridayTimings:weekdata[5].Fridays.length !==0 ?weekdata[5].Fridays:[{Day:"Friday",From: "", To: ""}],
                  SaturdayTimings:weekdata[6].Saturdays.length !==0 ?weekdata[6].Saturdays:[{Day:"Saturday",From: "", To: ""}],
               })
         
          }
        })
        .catch(err =>{
          console.log(err)
        })
      }


      handleInputChange = (e, index,state) => {
        const { name, value } = e.target;
        const list = [...state];
        list[index][name] = value;
        // console.log(this.state[list[0].Day+'Timings'][0].From)
        // console.log(this.state[list[0].Day+'Timings'][0].To)
        // console.log(this.state[list[0].Day+'Timings'][0].From < this.state[list[0].Day+'Timings'][0].To)
        let from = this.state[list[0].Day+'Timings'][0].From 
        let to = this.state[list[0].Day+'Timings'][0].To
         if(from < to || to =='')
         {
          this.setState({state: list });

          this.handleweekListChange()

          if(from != '' && to != '')
          message.success(`From ${this.state[list[0].Day+'Timings'][0].From} - To  ${this.state[list[0].Day+'Timings'][0].To} Selected`);


         }
         else
         {
          message.error("To time slot should be Greater than From time slot");
         }
       
      };

      handleweekListChange=()=>{
        let Firstweek=[this.state.SundayTimings,this.state.MondayTimings,this.state.TuesdayTimings,
            this.state.WednesdayTimings,this.state.ThursdayTimings,this.state.FridayTimings,this.state.SaturdayTimings]
      
          let ScheduledList
          ScheduledList=[Firstweek]
          this.props.handleWeekSchedule(ScheduledList)
      }

      handleSubmitWeekSchedule=(e) =>{
        this.handleweekListChange()
       
        setTimeout(() => {   this.props.handleSubmit(e) }, 500);
      }



    render(){
        return(
            <div>

      <div>

<div className="availabilityTime mb-4 col-sm-12">
           <div className="card-header">
            <a data-toggle="collapse" href="#CollapseAvailability1" aria-expanded="true" aria-controls="multiCollapseExample1">Select Time Slot <i className="fa fa-angle-up" aria-hidden="true"></i></a>
          </div>

            <div className="availabilityTimeList collapse show" id="CollapseAvailability1">

{(_.find(this.props.statedata.availableDays,"Sundays")!==undefined && this.props.statedata.availableDays[0].Sundays.length >0 )
||this.props.statedata.Days.includes("Sundays") ? (
<div>
<div className="availabilityTimeItem">
              <h5>Sunday
              {this.state.SundayTimings.map((x, i) => {
                  return (
                    <>
                      {this.state.SundayTimings.length - 1 === i && (
                        <i
                          className="fa fa-plus-square"
                          style={{
                            color: "green",
                            fontSize: "30px",
                            float: "right",
                          }}
                          // onClick={() => this.handleAddClick(i,this.state.SundayTimings)}
                          onClick={() =>{
                            this.setState(prevState => ({ 
                                  SundayTimings: [...prevState.SundayTimings,{Day:"Sunday",From: "", To: ""}]
                                }))
                          }}
                        />
                      )}
                    </>
                  );
                })}</h5>
              <hr/>
              <div id="content"></div>

              {this.state.SundayTimings.map((x,i) =>(
               
               <div className="row">

                <div className="col-sm-5">
                  <div className="form-group">
                    <label>From</label>
                    <input type="time"
                    style={{color:"black"}} 
                    name="From" 
                    value={x.From} 
                    onChange={(e) => this.handleInputChange(e, i,this.state.SundayTimings)}
                    className="form-control"/>
                  </div>
                </div>
                <div className="col-sm-5 col-9">
                  <div className="form-group">
                    <label>To</label>
                    <input type="time" 
                    style={{color:"black"}}
                    name="To" 
                    value={x.To}
                    onChange={(e) => this.handleInputChange(e, i,this.state.SundayTimings)} 
                    className="form-control"/>
                  </div>
                </div>
                <div className="col-sm-2 col-3">
                {this.state.SundayTimings.length > 1 && (
                        <i
                          className="fa fa-minus-square"
                          style={{
                            color: "green",
                            fontSize: "30px",
                            float: "right",
                          }}
                          onClick={() =>{
                              let SundayTimings = [...this.state.SundayTimings];
                              SundayTimings.splice(i, 1);
                              this.setState({ SundayTimings });
                              setTimeout(() => {  this.handleweekListChange() }, 500);
                          }}
                        ></i>
                      )}
                  </div>
              </div>

              ))}
         </div>


</div>):(<div></div>)}

{(_.find(this.props.statedata.availableDays,"Mondays")!==undefined && this.props.statedata.availableDays[1].Mondays.length >0 )
||this.props.statedata.Days.includes("Mondays")?(
<div>
<div className="availabilityTimeItem">
              <h5>Monday
              {this.state.MondayTimings.map((x, i) => {
                  return (
                    <>
                      {this.state.MondayTimings.length - 1 === i && (
                        <i
                          className="fa fa-plus-square"
                          style={{
                            color: "green",
                            fontSize: "30px",
                            float: "right",
                          }}
                          onClick={() =>{
                            this.setState(prevState => ({ 
                                  MondayTimings: [...prevState.MondayTimings,{Day:"Monday",From: "", To: ""}]
                                }))
                          }}
                        />
                      )}
                    </>
                  );
                })}</h5>
              <hr/>
              <div id="content"></div>

              {this.state.MondayTimings.map((x,i) =>(
               
               <div className="row">

                <div className="col-sm-5">
                  <div className="form-group">
                    <label>From</label>
                    <input type="time"
                    style={{color:"black"}} 
                    name="From" 
                    value={x.From} 
                    onChange={(e) => this.handleInputChange(e, i,this.state.MondayTimings)}
                    className="form-control"/>
                  </div>
                </div>
                <div className="col-sm-5 col-9">
                  <div className="form-group">
                    <label>To</label>
                    <input type="time" 
                    style={{color:"black"}}
                    name="To" 
                    value={x.To}
                    onChange={(e) => this.handleInputChange(e, i,this.state.MondayTimings)} 
                    className="form-control"/>
                  </div>
                </div>
                <div className="col-sm-2 col-3">
                {this.state.MondayTimings.length > 1 && (
                        <i
                          className="fa fa-minus-square"
                          style={{
                            color: "green",
                            fontSize: "30px",
                            float: "right",
                          }}
                          onClick={() =>{
                            let MondayTimings = [...this.state.MondayTimings];
                            MondayTimings.splice(i, 1);
                            this.setState({ MondayTimings });
                            setTimeout(() => {  this.handleweekListChange() }, 500);
                        }}
                        ></i>
                      )}
                  </div>
              </div>

              ))}
         </div>
</div>):(<div></div>)}
            
{(_.find(this.props.statedata.availableDays,"Tuesdays")!==undefined && this.props.statedata.availableDays[2].Tuesdays.length >0 )
||this.props.statedata.Days.includes("Tuesdays") ? (
<div>
<div className="availabilityTimeItem">
              <h5>Tuesday
              {this.state.TuesdayTimings.map((x, i) => {
                  return (
                    <>
                      {this.state.TuesdayTimings.length - 1 === i && (
                        <i
                          className="fa fa-plus-square"
                          style={{
                            color: "green",
                            fontSize: "30px",
                            float: "right",
                          }}
                          onClick={() =>{
                            this.setState(prevState => ({ 
                                  TuesdayTimings: [...prevState.TuesdayTimings,{Day:"Tuesday",From: "", To: ""}]
                                }))
                          }}
                        />
                      )}
                    </>
                  );
                })}</h5>
              <hr/>
              <div id="content"></div>

              {this.state.TuesdayTimings.map((x,i) =>(
               
               <div className="row">

                <div className="col-sm-5">
                  <div className="form-group">
                    <label>From</label>
                    <input type="time" 
                    style={{color:"black"}}
                    name="From" 
                    value={x.From} 
                    onChange={(e) => this.handleInputChange(e, i,this.state.TuesdayTimings)}
                    className="form-control"/>
                  </div>
                </div>
                <div className="col-sm-5 col-9">
                  <div className="form-group">
                    <label>To</label>
                    <input type="time" 
                    style={{color:"black"}}
                    name="To" 
                    value={x.To}
                    onChange={(e) => this.handleInputChange(e, i,this.state.TuesdayTimings)} 
                    className="form-control"/>
                  </div>
                </div>
                <div className="col-sm-2 col-3">
                {this.state.TuesdayTimings.length > 1 && (
                        <i
                          className="fa fa-minus-square"
                          style={{
                            color: "green",
                            fontSize: "30px",
                            float: "right",
                          }}
                          onClick={() =>{
                            let TuesdayTimings = [...this.state.TuesdayTimings];
                            TuesdayTimings.splice(i, 1);
                            this.setState({ TuesdayTimings });
                            setTimeout(() => {  this.handleweekListChange() }, 500);
                        }}
                        ></i>
                      )}
                  </div>
              </div>

              ))}
         </div>
</div>):(<div></div>)}
         
{(_.find(this.props.statedata.availableDays,"Wednesdays")!==undefined && this.props.statedata.availableDays[3].Wednesdays.length >0 )
||this.props.statedata.Days.includes("Wednesdays")? (
<div>
<div className="availabilityTimeItem">
              <h5>Wednesday
              {this.state.WednesdayTimings.map((x, i) => {
                  return (
                    <>
                      {this.state.WednesdayTimings.length - 1 === i && (
                        <i
                          className="fa fa-plus-square"
                          style={{
                            color: "green",
                            fontSize: "30px",
                            float: "right",
                          }}
                          onClick={() =>{
                            this.setState(prevState => ({ 
                                  WednesdayTimings: [...prevState.WednesdayTimings,{Day:"Wednesday",From: "", To: ""}]
                                }))
                          }}
                        />
                      )}
                    </>
                  );
                })}</h5>
              <hr/>
              <div id="content"></div>

              {this.state.WednesdayTimings.map((x,i) =>(
               
               <div className="row">

                <div className="col-sm-5">
                  <div className="form-group">
                    <label>From</label>
                    <input type="time"
                    style={{color:"black"}} 
                    name="From" 
                    value={x.From} 
                    onChange={(e) => this.handleInputChange(e, i,this.state.WednesdayTimings)}
                    className="form-control"/>
                  </div>
                </div>
                <div className="col-sm-5 col-9">
                  <div className="form-group">
                    <label>To</label>
                    <input type="time"
                    style={{color:"black"}} 
                    name="To" 
                    value={x.To}
                    onChange={(e) => this.handleInputChange(e, i,this.state.WednesdayTimings)} 
                    className="form-control"/>
                  </div>
                </div>
                <div className="col-sm-2 col-3">
                {this.state.WednesdayTimings.length > 1 && (
                        <i
                          className="fa fa-minus-square"
                          style={{
                            color: "green",
                            fontSize: "30px",
                            float: "right",
                          }}
                          onClick={() =>{
                            let WednesdayTimings = [...this.state.WednesdayTimings];
                            WednesdayTimings.splice(i, 1);
                            this.setState({ WednesdayTimings });
                            setTimeout(() => {  this.handleweekListChange() }, 500);
                        }}
                        ></i>
                      )}
                  </div>
              </div>

              ))}
         </div>
</div>):(<div></div>)}
        
{(_.find(this.props.statedata.availableDays,"Thursdays")!==undefined && this.props.statedata.availableDays[4].Thursdays.length >0 )
||this.props.statedata.Days.includes("Thursdays")?(
     <div className="availabilityTimeItem">
     <h5>Thursday
     {this.state.ThursdayTimings.map((x, i) => {
         return (
           <>
             {this.state.ThursdayTimings.length - 1 === i && (
               <i
                 className="fa fa-plus-square"
                 style={{
                   color: "green",
                   fontSize: "30px",
                   float: "right",
                 }}
                 onClick={() =>{
                   this.setState(prevState => ({ 
                         ThursdayTimings: [...prevState.ThursdayTimings,{Day:"Thursday",From: "", To: ""}]
                       }))
                 }}
               />
             )}
           </>
         );
       })}</h5>
     <hr/>
     <div id="content"></div>

     {this.state.ThursdayTimings.map((x,i) =>(
      
      <div className="row">

       <div className="col-sm-5">
         <div className="form-group">
           <label>From</label>
           <input type="time"
           style={{color:"black"}} 
           name="From" 
           value={x.From} 
           onChange={(e) => this.handleInputChange(e, i,this.state.ThursdayTimings)}
           className="form-control"/>
         </div>
       </div>
       <div className="col-sm-5 col-9">
         <div className="form-group">
           <label>To</label>
           <input type="time"
           style={{color:"black"}} 
           name="To" 
           value={x.To}
           onChange={(e) => this.handleInputChange(e, i,this.state.ThursdayTimings)} 
           className="form-control"/>
         </div>
       </div>
       <div className="col-sm-2 col-3">
       {this.state.ThursdayTimings.length > 1 && (
               <i
                 className="fa fa-minus-square"
                 style={{
                   color: "green",
                   fontSize: "30px",
                   float: "right",
                 }}
                 onClick={() =>{
                   let ThursdayTimings = [...this.state.ThursdayTimings];
                   ThursdayTimings.splice(i, 1);
                   this.setState({ ThursdayTimings });
                   setTimeout(() => {  this.handleweekListChange() }, 500);
               }}
               ></i>
             )}
         </div>
     </div>
    ))}
</div>
):(<div></div>)}
        
{(_.find(this.props.statedata.availableDays,"Fridays")!==undefined && this.props.statedata.availableDays[5].Fridays.length >0 )
||this.props.statedata.Days.includes("Fridays")?(
    <div className="availabilityTimeItem">
    <h5>Friday
    {this.state.FridayTimings.map((x, i) => {
        return (
          <>
            {this.state.FridayTimings.length - 1 === i && (
              <i
                className="fa fa-plus-square"
                style={{
                  color: "green",
                  fontSize: "30px",
                  float: "right",
                }}
                onClick={() =>{
                  this.setState(prevState => ({ 
                        FridayTimings: [...prevState.FridayTimings,{Day:"Friday",From: "", To: ""}]
                      }))
                }}
              />
            )}
          </>
        );
      })}</h5>
    <hr/>
    <div id="content"></div>

    {this.state.FridayTimings.map((x,i) =>(
     
     <div className="row">

      <div className="col-sm-5">
        <div className="form-group">
          <label>From</label>
          <input type="time" 
          style={{color:"black"}}
          name="From" 
          value={x.From} 
          onChange={(e) => this.handleInputChange(e, i,this.state.FridayTimings)}
          className="form-control"/>
        </div>
      </div>
      <div className="col-sm-5 col-9">
        <div className="form-group">
          <label>To</label>
          <input type="time" 
          style={{color:"black"}}
          name="To" 
          value={x.To}
          onChange={(e) => this.handleInputChange(e, i,this.state.FridayTimings)} 
          className="form-control"/>
        </div>
      </div>
      <div className="col-sm-2 col-3">
      {this.state.FridayTimings.length > 1 && (
              <i
                className="fa fa-minus-square"
                style={{
                  color: "green",
                  fontSize: "30px",
                  float: "right",
                }}
                onClick={() =>{
                  let FridayTimings = [...this.state.FridayTimings];
                  FridayTimings.splice(i, 1);
                  this.setState({ FridayTimings });
                  setTimeout(() => {  this.handleweekListChange() }, 500);
              }}
              ></i>
            )}
        </div>
    </div>

    ))}
</div>
):(<div></div>)}
         
{(_.find(this.props.statedata.availableDays,"Saturdays")!==undefined && this.props.statedata.availableDays[6].Saturdays.length >0 )
||this.props.statedata.Days.includes("Saturdays")?(
      <div className="availabilityTimeItem">
      <h5>Saturday
      {this.state.SaturdayTimings.map((x, i) => {
          return (
            <>
              {this.state.SaturdayTimings.length - 1 === i && (
                <i
                  className="fa fa-plus-square"
                  style={{
                    color: "green",
                    fontSize: "30px",
                    float: "right",
                  }}
                  onClick={() =>{
                    this.setState(prevState => ({ 
                          SaturdayTimings: [...prevState.SaturdayTimings,{Day:"Saturday",From: "", To: ""}]
                        }))
                  }}
                />
              )}
            </>
          );
        })}</h5>
      <hr/>
      <div id="content"></div>

      {this.state.SaturdayTimings.map((x,i) =>(
       
       <div className="row">

        <div className="col-sm-5">
          <div className="form-group">
            <label>From</label>
            <input type="time"
            style={{color:"black"}} 
            name="From" 
            value={x.From} 
            onChange={(e) => this.handleInputChange(e, i,this.state.SaturdayTimings)}
            className="form-control"/>
          </div>
        </div>
        <div className="col-sm-5 col-9">
          <div className="form-group">
            <label>To</label>
            <input type="time" 
            style={{color:"black"}}
            name="To" 
            value={x.To}
            onChange={(e) => this.handleInputChange(e, i,this.state.SaturdayTimings)} 
            className="form-control"/>
          </div>
        </div>
        <div className="col-sm-2 col-3">
        {this.state.SaturdayTimings.length > 1 && (
                <i
                  className="fa fa-minus-square"
                  style={{
                    color: "green",
                    fontSize: "30px",
                    float: "right",
                  }}
                    onClick={() =>{
                        let SaturdayTimings = [...this.state.SaturdayTimings];
                        SaturdayTimings.splice(i, 1);
                        this.setState({ SaturdayTimings });
                        setTimeout(() => {  this.handleweekListChange() }, 500);
                    }}
                    
                ></i>
              )}
          </div>
      </div>

      ))}
 </div>
):(<div></div>)}
         

            <div className="availabilityTimeNote">
              <label>Note</label>
              <textarea style={{color:"black"}}
              className="form-control"  defaultValue={this.props.statedata.note} onChange={this.props.handleChange} name="note" rows="2" placeholder="Write your Note"></textarea>
            </div>
          </div>

        </div>

      </div>
    

    <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn btn-success" data-dismiss="modal" onClick={this.handleSubmitWeekSchedule}>Save</button>
    </div>

            </div>
        )
    }
}
export default UpdateWeekList