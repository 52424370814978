import React from 'react'
import { FooterLink } from '../components/footers';
import { Header, HeaderLogout, HeaderTop } from '../components/headers';
import { dArrowblack } from '../assets/images';
import { toast } from "react-toastify";
import axios from '../config/axios';
import message from '../utils/mesaages';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import validator from 'validator'
import HeaderSecondTo from "../components/corporate/Header/HeaderSecondTo";
import {CaptchaKey,social_link} from "../config/custom";

import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

class ContactUS extends React.Component {
  constructor(props) {
    super()
    this.state = {
      name: "",
      phone_number: "",
      email: "",
      category: "",
      message: "",
      countryName: '',
      errorEmail: '',
      errorName: '',
      errorMessage: '',
      recapcha : false,

    }
  }

  componentDidMount = () => {
    //window.scrollTo(0,0);

    //console.log('dddddyy', this.props)

    if (this.props.location && this.props.location.hasOwnProperty('state') && this.props.location.state) {
      if (this.props.location.state.fullname === undefined || this.props.location.state.fullname === null) return
      if (this.props.location.state.email === undefined || this.props.location.state.email === null) return
      if (this.props.location.state.number === undefined || this.props.location.state.number === null) return

      // //console.log('dddd')

      this.setState({
        name: this.props.location.state.fullname,
        email: this.props.location.state.email,
        phone_number: this.props.location.state.number
      })
    }

    this.getGeoInfo()
  }

  handleChange = (e) => {
    console.log("user typed ----", e.target.value)
    e.persist();
    if (e.target.name == 'email') {
      if (validator.isEmail(e.target.value)) {
        this.setState({ email: e.target.value, errorEmail: '' })
      } else {
        this.setState({ errorEmail: 'Please enter valid email address' })
      }
    }
    this.setState({
      [e.target.name]: e.target.value
    })
  };

  resetField() {

    this.setState({
      name: "",
      email: "",
      // phone_number: "",
      category: "",
      message: "",
      errorEmail: '',
      errorName: '',
      errorMessage: '',
      errorTexts: [],
      // loading: false,
    }, () => {

      this.getGeoInfo()
    });
  }

  capchaChange=(value)=>{
    this.setState({
      recapcha : value
    });
  }

  handleSubmitContact = (e) => {
    e.preventDefault();
    // this.setState({ loading: true });

    const formData = {
      name: this.state.name,
      email: this.state.email,
      phone_number: this.state.phone_number,
      message: this.state.message,
      //category:this.state.category
    };
    console.log("formdata---", formData)

    if (
      this.state.name.length > 0 &&
      this.state.message.length > 0 &&
      this.state.email.length > 0 &&
      this.state.recapcha
    ) {
      let self = this
      axios.post("/admin/contactUs", formData)
        .then((res) => {
          if (res.data.result) {
            self.resetField();
            // toast("Your mail has been sent successfully", {
            //   type: "success",
            // });
            message.success("Your mail has been sent successfully!");
          } else {
            message.warning("Not able to send your mail !");
          }
        })
        .catch((error) => {
          message.error("there was an error !!!");
        });
    } else if (!this.state.recapcha){
      message.error("Please solve the captcha");
    }else {
      message.error("Please fill the form correctly");
    }
  };

  getGeoInfo = () => {
    // axios.get('https://ipapi.co/json/').then((response) => {
    //   let data = response.data;
      this.setState({
        countryName: "us"
        //data.country.toLowerCase(),
      });
    // }).catch((error) => {
    //   console.log(error);
    // });
  };

  render() {
    return (
      <div>
        {
          !localStorage.getItem("subdomain") && (localStorage.getItem("authToken") == null ? (<HeaderTop />) : (<HeaderLogout />))
        }
        {
          localStorage.getItem("subdomain") ? (<HeaderSecondTo />) : (<Header />)
        }


        <section className="contactWrap">
          <div className="container">
            <div className='contact_card'>
              <div className="row mx-0">
                <div className="col-md-6 cn_map px-0">
                  <iframe
                    title="title"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d495.9493538293388!2d-73.84622909374914!3d41.16387584916394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2c0abd91875b3%3A0xaf5834065a4fc0e5!2s1858%20Pleasantville%20Rd%2C%20Briarcliff%20Manor%2C%20NY%2010510%2C%20USA!5e0!3m2!1sen!2sin!4v1685969153048!5m2!1sen!2sin"
                    height="580"
                    frameborder="0"
                    style={{ border: "0" }}
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </div>
                <div className="col-md-6 cn_form">
                  <div className="heading">
                    <h3>Contact Us</h3>
                    <p>Reach out to us with any questions. We’d love to hear from you!</p>
                  </div>
                  
                  <div className='row cnc_info'>
                    <div className='col-md-6'>
                      <div className='cnci_item'>
                        <h4>Email</h4>
                        <label className='mb-0'><Link to={"mailto:support@coachmaster.io"} id="emailhover">support@coachmaster.io</Link></label>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='cnci_item'>
                        <h4>Social Links</h4>
                        <ul className="socialIcon mb-0">
                        {social_link.map((item,i)=>{
                          return (<li key={i}>
                            <a href={item.link} target="_blank">
                              <i className={item.class_name} aria-hidden="true"></i>
                            </a>
                          </li>)
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <form
                    className="mb-4"
                    action=""
                    method="get"
                    accept-charset="utf-8"
                    onSubmit={this.handleSubmitContact}
                  >
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className="form-group">
                          <input
                              type="text"
                              placeholder="Full Name"
                              className="form-control"
                              onChange={this.handleChange}
                              value={this.state.name}
                              required={true}
                              name="name"
                            />
                        </div>
                        <p style={{ color: 'red' }}>{this.state.errorName==''? '' : this.state.errorName }</p>
                      </div>
                      <div className='col-md-6'>
                        <div className="form-group">                  
                            <PhoneInput
                              // country={this.state.countryName}
                              //countryCodeEditable={true}
                              country={this.state.countryName}
                              countryCodeEditable={false}
                              defaultCountry="RU"
                              inputClass="form-control"
                              placeholder="Phone Number(Optional)..."
                              value={this.state.phone_number}
                              onChange={phone => this.setState({ phone_number:phone })}
                            />
                        </div> 
                      </div>
                      <div className='col-md-6'>
                        <div className="form-group">
                          <input
                            type="email"
                            placeholder="Email Address"
                            className="form-control"
                            name="email"
                            onChange={this.handleChange}
                            value={this.state.email}
                            //required={true}
                          />
                          
                        </div>
                        <p style={{ color: 'red' }}>{this.state.errorEmail==''? '' : this.state.errorEmail }</p>
                      </div>
                      <div className='col-md-12'>
                        <div className="form-group">
                          <textarea className="form-control"
                            placeholder="Enter your message"
                            name="message"
                            onChange={this.handleChange}
                            value={this.state.message}
                            required={true}
                          > 
                          </textarea>
                        </div>
                        <p style={{ color: 'red' }}>{this.state.errorMessage==''? '' : this.state.errorMessage }</p>
                      </div>
                      <div className='col-md-12'>

                      <ReCAPTCHA
                        sitekey={CaptchaKey}
                        onChange={this.capchaChange}
                      />
                      <br />
                      <br />

                        <button className="defaultBtn" type="submit">SEND</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterLink/>
   

        </div>
      )
    }
}

export default ContactUS