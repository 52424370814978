import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import "./articles.css";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";
import { blog1, LGspinner } from "../../../assets/images";
import Axios from "axios";

export default class Articles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      loader: true,
    };
  }

  componentDidMount = () => {
    Axios.get(
      "https://blog.coachmaster.io/wp-json/wp/v2/posts?order=desc&orderby=date&per_page=6&_embed"
    )
      .then((res) => {
        this.setState({ loader: false });
        this.setState({ articles: res.data });
      })
      .catch((err) => {
        this.setState({ loader: false });
      });
  };

  createMarkup = (data) => {
    return { __html: data };
  };

  render() {
    return (
      <LoadingOverlay
        active={this.state.loader}
        className="webiner_loader"
        spinner={<CustomImageLoader image={LGspinner} animationType={"none"} />}
      >
        <div className="content_wrap">
          <Row>
            {this.state.articles.map((blog, key) => {
              return (
                <Col md={6} lg={4} className="mb-4 article_grid" key={key}>
                  <div className="blogContent">
                    <a
                      href={blog.link}
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      <img
                        alt="LG-Img"
                        // src="https://blog.coachmaster.io/wp-content/uploads/2022/05/bigstock-159675557-700x441.jpg"
                        // className="article_image"

                        src={
                          blog._embedded["wp:featuredmedia"] === undefined
                            ? blog1
                            : blog._embedded["wp:featuredmedia"][0].source_url
                        }
                      />
                    </a>
                    <div className="bInfo">
                      <div className="article_date">
                        {blog.date.slice(0, blog.date.indexOf("T"))}
                      </div>
                      <h5 className="article_title">
                        <a
                          href={blog.link}
                          style={{ textDecoration: "none" }}
                          target="_blank"
                        >
                          {blog?.title?.rendered.substring(0, 40)}...
                        </a>
                      </h5>
                      <div
                        className="article_text"
                        dangerouslySetInnerHTML={this.createMarkup(
                          `${blog.excerpt.rendered.substring(0, 85)}...`
                        )}
                      />
                    </div>
                  </div>
                </Col>
              );
            })}

            {/* <Col md={6} lg={4} className="mb-4 article_grid">
              <div className="blogContent">
                <a href="/" style={{ textDecoration: "none" }} target="_blank">
                  <img
                    alt="LG-Img"
                    src="https://blog.coachmaster.io/wp-content/uploads/2022/05/bigstock-159675557-700x441.jpg"
                    className="article_image"
                  />
                </a>
                <div className="bInfo">
                  <div className="article_date">May 3rd, 2022</div>
                  <h5 className="article_title">
                    <a
                      href="/"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      Screen Fatigue And Online Coaching
                    </a>
                  </h5>
                  <div className="article_text">
                    <p>
                      Following the pandemic, video conferencing has become a
                      daily part of life for many people. Video conferencing has
                      allowed people to work from
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4} className="mb-4 article_grid">
              <div className="blogContent">
                <a href="/" style={{ textDecoration: "none" }} target="_blank">
                  <img
                    alt="LG-Img"
                    src="https://blog.coachmaster.io/wp-content/uploads/2022/05/bigstock-159675557-700x441.jpg"
                    className="article_image"
                  />
                </a>
                <div className="bInfo">
                  <div className="article_date">May 3rd, 2022</div>
                  <h5 className="article_title">
                    <a
                      href="/"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      Screen Fatigue And Online Coaching
                    </a>
                  </h5>
                  <div className="article_text">
                    <p>
                      Following the pandemic, video conferencing has become a
                      daily part of life for many people. Video conferencing has
                      allowed people to work from
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4} className="mb-4 article_grid">
              <div className="blogContent">
                <a href="/" style={{ textDecoration: "none" }} target="_blank">
                  <img
                    alt="LG-Img"
                    src="https://blog.coachmaster.io/wp-content/uploads/2022/05/bigstock-159675557-700x441.jpg"
                    className="article_image"
                  />
                </a>
                <div className="bInfo">
                  <div className="article_date">May 3rd, 2022</div>
                  <h5 className="article_title">
                    <a
                      href="/"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      Screen Fatigue And Online Coaching
                    </a>
                  </h5>
                  <div className="article_text">
                    <p>
                      Following the pandemic, video conferencing has become a
                      daily part of life for many people. Video conferencing has
                      allowed people to work from
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4} className="mb-4 article_grid">
              <div className="blogContent">
                <a href="/" style={{ textDecoration: "none" }} target="_blank">
                  <img
                    alt="LG-Img"
                    src="https://blog.coachmaster.io/wp-content/uploads/2022/05/bigstock-159675557-700x441.jpg"
                    className="article_image"
                  />
                </a>
                <div className="bInfo">
                  <div className="article_date">May 3rd, 2022</div>
                  <h5 className="article_title">
                    <a
                      href="/"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      Screen Fatigue And Online Coaching
                    </a>
                  </h5>
                  <div className="article_text">
                    <p>
                      Following the pandemic, video conferencing has become a
                      daily part of life for many people. Video conferencing has
                      allowed people to work from
                    </p>
                  </div>
                </div>
              </div>
            </Col> */}
          </Row>
        </div>
      </LoadingOverlay>
    );
  }
}
