import React from 'react'
import { Dropdown } from "react-bootstrap";
import { user } from "../../../assets/images";
import './Header.css'
import { message } from 'antd';
import axios from '../../../config/axios';
import { LogoLight } from "./../images/index";
class Header extends React.Component {
    constructor(props) {
        super(props)

        this.token = localStorage.getItem("corporate_token")
        this.userdeta = localStorage.getItem("corporate_userdata")
        this.usertoken = localStorage.getItem("corporate_user_token")
        this.userDeta = localStorage.getItem("corporate_user_userdata")

        this.state = {
            loader: false,
            userdeta: {}
        }
    }

    componentDidMount() {
        if ((!this.token && !this.userdeta) && (!this.userDeta && !this.usertoken)) {
            window.location = "/"
        }
        setInterval(() => {
            this.setState({ userdeta: JSON.parse(localStorage.getItem("corporate_userdata")) })
        }, 2000);
    }

    // getProfile = async () => {
    //     this.setState({ loader: true });
    //     axios.get("/api/corprate_user_profile", { headers: { "X-Auth": localStorage.getItem("corporate_token") || localStorage.getItem("corporate_user_token"), }, }).then((res) => {
    //         if (res.data.success) {
    //             this.setState({ loader: false });
    //             this.setState({ userdeta: res.data.data });
    //         }
    //     }).catch((err) => {
    //         console.log("err", err);
    //         this.setState({ loader: false });
    //         message.error("Something went wrong");
    //     });
    // };

    logoutHandaler = (e) => {
        const hideMessage = message.loading("Please wait while logout...", 0);
        this.setState({ loader: true });
        e.preventDefault();

        axios.get(`/api/corporate_logout/${this.token || this.usertoken}`).then(async (response) => {
            hideMessage();
            if (response.data.success) {
                localStorage.clear()
                message.success(response.data.msg)
                this.setState({ loader: false });
                window.location = "/"
            }
        }).catch((error) => {
            hideMessage();
            message.error(error.response.data.msg);
            this.setState({ loader: false });
        });
    }

    render() {
        return (
            <header className="coach_header corporate d-flex justify-content-between fixed-top">
                <button className="toggleSidebar btn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 48 48">
                        <path fill="#6f7380" d="M41 14H7a2 2 0 0 1 0-4h34a2 2 0 0 1 0 4ZM41 26H7a2 2 0 0 1 0-4h34a2 2 0 0 1 0 4ZM41 38H7a2 2 0 0 1 0-4h34a2 2 0 0 1 0 4Z" />
                    </svg>
                </button>

                <div className="header_logo">
                    <img src={LogoLight} width="90" height="51" className="img-fluid" alt='Coach master' />
                </div>
                {
                    this.state.userdeta.company_name && (
                        <div className='organization_name'>
                            <span>Organization Name : - <strong>{this.state.userdeta.company_name}</strong></span>
                        </div>
                    )
                }
                <div className="header_tools d-flex">
                    <Dropdown className="user_dropdown">
                        <Dropdown.Toggle variant="default" className="rounded-0 header_img">
                            <img src={this.state.userdeta.company_logo || user} width={100} height={100} className="img-fluid rounded rounded-circle" alt='userimage' />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="javascript:void(0)" onClick={this.logoutHandaler}>
                                Logout
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </header>
        )
    }
}

export default Header