import React from "react";
import { Helmet } from "react-helmet";
import axios from "../../config/axios";

class HelmetMetaData extends React.Component {
    constructor(props){
        super()
        this.state = {
            metadata: [],
        }
    }
    componentDidMount() {
        this.getMetadata()
    }
    getMetadata = () => {
        let self = this
        axios.get('/meta/metacontents')
        .then(async (response) => {
          if(response.status === 200){
            self.setState({
              metadata: JSON.parse(response.data.value.meta_content),
            })
          }
        })
        .catch((e) => {
          console.log(e)
        })
        }
    render() {
        return (
            <Helmet>
                {this.state.metadata === [] ? "" : 
                    this.state.metadata.map((each, index) => {
                        return <meta key={index} name={each.name} content={each.content} />
                    })
                }
                {/* <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta name="description" content="Web site created using create-react-app" /> */}
            </Helmet>
        );
    }
}
export default HelmetMetaData