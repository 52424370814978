import React from "react";
import { Button, Modal } from "react-bootstrap";

class PaymentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHide: false,
      CardNumber: "",
      Nameoncard: "",
      ExpirationDate: "",
      SecurityCode: "",
      // modal: true,
    };
    this.baseState = this.state;
  }

  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
  }

  handleReset = (e) => {
    this.setState({ Nameoncard: "" });
    this.setState({ ExpirationDate: "" });
    this.setState({ CardNumber: "" });
    this.setState({ SecurityCode: "" });

    // this.setState(this.baseState)
  };

  handleChange = (e) => {
    e.persist();
    this.setState({
      [e.target.name]: e.target.value,
    });
    console.log("value", e.target.value);
  };

  toggle = () => {
    this.props.history.push("/price_table");
  };

  render() {
    const { Nameoncard, ExpirationDate, CardNumber, SecurityCode } = this.state;

    return (
      <div>
        <Button variant="success" onClick={() => this.handleModalShowHide()}>
          BUY NOW
        </Button>

        <Modal show={this.state.showHide}>
          <div className="pop_up">
            <div className="payment-online-form-left">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={() => this.handleModalShowHide()}
              >
                <span aria-hidden="true">&times;</span>
              </button>

              <form action="/login">
                <ul className="payment-type">
                  <h4>
                    <span className="payment"> </span> Payments
                  </h4>
                  <li>
                    <span className="col_checkbox">
                      <input id="3" className="css-checkbox1" type="checkbox" />
                      <label for="3" name="demo_lbl_1" className="css-label1">
                        {" "}
                      </label>
                      <a className="visa" href="/#">
                        {" "}
                      </a>
                    </span>
                  </li>
                  <li>
                    <span className="col_checkbox">
                      <input id="4" className="css-checkbox2" type="checkbox" />
                      <label for="4" name="demo_lbl_2" className="css-label2">
                        {" "}
                      </label>
                      <a className="paypal" href="/#">
                        {" "}
                      </a>
                    </span>
                  </li>
                  <div className="clear"> </div>
                </ul>
                <ul>
                  <li>
                    <input
                      className="text-box-dark"
                      type="text"
                      placeholder="Card Number"
                      value={CardNumber}
                      onChange={this.handleChange}
                      name="CardNumber"
                      onfocus="this.value = '';"
                      onblur="if (this.value == '') {this.value = 'Card Number';}"
                    />
                  </li>
                  <li>
                    <input
                      className="text-box-dark"
                      type="text"
                      placeholder="Name on card"
                      value={Nameoncard}
                      onChange={this.handleChange}
                      name="Nameoncard"
                      onfocus="this.value = '';"
                      onblur="if (this.value == '') {this.value = 'Name on card';}"
                    />
                  </li>
                  <div className="clear"> </div>
                </ul>
                <ul>
                  <li>
                    <input
                      className="text-box-light hasDatepicker"
                      type="text"
                      id="datepicker"
                      placeholder="Expiration Date"
                      value={ExpirationDate}
                      onChange={this.handleChange}
                      name="ExpirationDate"
                      onfocus="this.value = '';"
                      onblur="if (this.value == '') {this.value = 'Expiration Date';}"
                    />
                    <em className="pay-date"> </em>
                  </li>
                  <li>
                    <input
                      className="text-box-dark"
                      type="text"
                      placeholder="Security Code"
                      value={SecurityCode}
                      onChange={this.handleChange}
                      name="SecurityCode"
                      onfocus="this.value = '';"
                      onblur="if (this.value == '') {this.value = 'Security Code';}"
                    />
                  </li>
                  <div className="clear"> </div>
                </ul>
                <ul className="payment-sendbtns">
                  <li>
                    <input
                      type="reset"
                      onClick={this.handleReset}
                      value="Reset"
                    />
                  </li>{" "}
                  <vr />
                  <li>
                    <input type="submit" value="Process order" />
                  </li>
                </ul>
                <div className="clear"> </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default PaymentModal;
