import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import validation from '../../Validate/validator';
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from 'react-custom-image-loader.'
import { LGspinner } from "../../assets/images";
import axios from "../../config/axios";
import message,{getHelpText} from '../../utils/mesaages';

class Changepasswordmodal extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("authToken");
    this.state = {
      changePasswordModal: false,
      conPass: "",
      newPass: "",
      FromDataError: {
        conPass: "false",
        newPass: "false",
      },
      loader:false
    };
  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;
    

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {
    

        if (value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }

        valid = false;
      }
    }

    return valid;
  };


  handleChange = (e) => {
   
   let {FromDataError} = this.state
   e.persist();
   var valid_obj = {
     value:e.target.value,
     rules:e.target.getAttribute("validaterule"),
     message:e.target.getAttribute("validatemsg")
   }
   validation(valid_obj).then((error)=>{

      
     console.log('aaaaaaaaaaa',error)
     FromDataError[e.target.name] = error
     this.setState({ FromDataError })
     
     if(e.target.name === "newPass" && e.target.value !== this.state.conPass && this.state.conPass != ""){
                FromDataError['conPass'] = "Password and confirm password do not match"
                this.setState({FromDataError})
            }
            else if(e.target.name === "newPass" && e.target.value === this.state.conPass && this.state.conPass != "") {
                FromDataError['conPass'] = ""
                this.setState({FromDataError})
            }
            else if(e.target.name === "conPass" && e.target.value !== this.state.newPass && this.state.newPass != "") {
                FromDataError['conPass'] = "Password and confirm password do not match"
                this.setState({FromDataError})
     }
     
    
     setTimeout(() => {
       this.setState({
         [e.target.name]: e.target.value,
       });
       
     }, 100);
     

   })
  
 };


  submit = (e) => {
    e.persist();
     
    if (this.validateForm(this.state.FromDataError))
    {
        this.setState({loader:true})

        axios.post('/consumer/changePassword',{password:this.state.newPass},{
            headers:{"x-auth":this.token}
          }).then((res)=>{
        this.setState({loader:false})

              if(res.data.success)
              {
                message.success("Password has been changed successfully");
                this.hamdalechangePasswordModal(e)
                
              }
              else
              {
                message.error("Failed to Change Password");
              }

          }).catch((err)=>{
        this.setState({loader:false})

              console.log(err)
              message.error("Failed to Change Password");

          })

    }

  };
  hamdalechangePasswordModal = (e) => {
    e.preventDefault();
    this.setState({ changePasswordModal: !this.state.changePasswordModal });
    this.setState({conPass:'',newPass:''})
    let FromDataError = this.state.FromDataError
    FromDataError.conPass = 'false'
    FromDataError.newPass = 'false'
    this.setState({FromDataError})
  };
  render() {
    return (
      <>
        <button
          className="defaultBtn rc ml-2"
          onClick={this.hamdalechangePasswordModal}
        >
          Change Password
        </button>

        <Modal
          show={this.state.changePasswordModal}
          onHide={this.hamdalechangePasswordModal}
          keyboard={false}
          className="modal  reviewModalWrap"
        >
          <div className="modal-content accept_modal">
          <LoadingOverlay        
                  active={this.state.loader}
                  // spinner={<BounceLoader />}
                  spinner={<div><CustomImageLoader image={LGspinner} animationType={'pulse'}/><br/></div>}
                  text='Loading your profile...'
                >
            <Modal.Header>
              <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>

            <div className="modal-body">
              {/* <form onSubmit={this.handleSubmit}> */}
                <div className="row">
                  <div className="col-md-12 ">
                    <div className="form-group">
                      <label>New Password</label>
                      <input
                        type="password"
                        style={{ color: "black" }}
                        name="newPass"
                        defaultValue={this.state.newPass}
                        onChange={this.handleChange}
                        placeholder="New Password"
                        className="form-control"
                        validaterule={['required','password','passpattern']}
                            validatemsg={['Password field is required','Password length must be at least 6 characters','Password must contain at least one special character and one numeric digit']}
                      />

                      <p style={{ color: "red" }}>
                        {this.state.FromDataError.newPass == "false"
                          ? ""
                          : this.state.FromDataError.newPass}
                      </p>
                    </div>
                  </div>
                  {/* last name section */}
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Confirm Password</label>
                      <input
                        type="password"
                        style={{ color: "black" }}
                        name="conPass"
                        defaultValue={this.state.conPass}
                        onChange={this.handleChange}
                        placeholder="Confirm Pasword"
                        className="form-control"
                        validaterule={['required','password','same|'+this.state.newPass]}
                            validatemsg={['Confirm your password field','Password length must be at least 6 characters','Password and confirm password do not match']}
                      />
                      <p style={{ color: "red" }}>
                        {this.state.FromDataError.conPass == "false"
                          ? ""
                          : this.state.FromDataError.conPass}
                      </p>
                    </div>
                  </div>
                </div>
              {/* </form> */}
            </div>

            <div className="modal-footer">
              <Button
                className="btn btn-secondary"
                onClick={this.hamdalechangePasswordModal}
              >
                Close
              </Button>
              <Button className="btn btn-success" onClick={this.submit}>
                Save
              </Button>
            </div>
            </LoadingOverlay>
          </div>
        </Modal>
      </>
    );
  }
}

export default Changepasswordmodal;
