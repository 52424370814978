import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

import { deleteSvg, moreSvg, docSvg, pdfSvg, xlsSvg, pptSvg, pictureSvg } from "../../assets/images";
import { VideoPlayer } from "../media";
import SocialMediaButtons from "./SocialMediaButtons";
import PDFViewer from 'mgr-pdf-viewer-react'

class ListModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      URL: "",
      shareType: "",
    };
  }

  handleDelete = (type, id) => {
    if (id) {
      this.props.handleDelete(id);
    }
  };
  render() {
    return (
      <div>
        <Modal size="lg"
          show={this.props.showState}
          className="modal fade"
          id="CommonModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          //aria-hidden="true"
        >
          {/* <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          > */}
            <div className="modal-content">
              <div className="modal-header">
                {this.props.viewMore === "certificates" && (
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Files
                  </h5>
                )}
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.handleViewMoreModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row justify-content-center lightBox">
                  {this.props.viewMore === "certificates" &&
                    this.props.dataSet.reverse().map((certificate) => {
                      let array = certificate.certificate_path.split("/").pop()
                      let filetype = array.split(".").pop().toLowerCase()
                      return (
                        <div className="col-lg-3 col-md-4 col-6 my-3">
                          {/* <a
                            href={certificate.certificate_path}
                            target="_blank"
                            //data-toggle="lightbox"
                            //data-gallery="example-gallery"
                            className="boxWrap"
                          > */}
                            {/* <img
                              src={certificate.certificate_path}
                              className="img-fluid"
                              alt="LG-Img"
                            /> */}
                            {filetype == "pdf" ? 
                      //   <img
                      //   alt="LG-Img"
                      //   src={pdfSvg}
                      //   className="img-fluid"
                      // />
                      (
                        <div className="iframepdf">
                          {/* <iframe
                        height="200px"
                        alt="LG-Img"
                        //src="https://lifeguru.s3-us-east-2.amazonaws.com/assets/doc_placeholder.png"
                        src={certificate.certificate_path}
                        //width="100%"
                      /> */}
                      <PDFViewer document={{
                        url: `${certificate.certificate_path}`
                      }}
                      scale={0.25} 
                      hideNavbar={true}
                      />
                      <a
                       href={certificate.certificate_path}
                       target="_blank"
                       className="boxWrap"
                     ></a>
                        </div>
                      )
                    //    : filetype == "doc" || filetype == "docx" ? 
                    //    <img
                    //    alt="LG-Img"
                    //    src={docSvg}
                    //    className="img-fluid"
                    //  />
                    //  : filetype == "xls" ? 
                    //     <img
                    //     alt="LG-Img"
                    //     src={xlsSvg}
                    //     className="img-fluid"
                    //   />
                    //   : filetype == "ppt" ? 
                    //     <img
                    //     alt="LG-Img"
                    //     src={pptSvg}
                    //     className="img-fluid"
                    //   />
                       : filetype == "jpg" || filetype == "jpeg" || filetype == "png" ? 
                       (
                        <a
                        href={certificate.certificate_path}
                        target="_blank"
                       //  data-toggle="lightbox"
                       //  data-gallery="example-gallery"
                        className="boxWrap"
                      >
                          <img
                          alt="LG-Img"
                          src={certificate.certificate_path}
                          //src={pictureSvg}
                          className="img-fluid"
                        />
                        </a>
                       )
                       : 
                    //    <img
                    //    alt="LG-Img"
                    //    src={docSvg}
                    //    className="img-fluid"
                    //  />
                    ''
                       }
                          {/* </a> */}
                          {console.log("check account type", )}
                          {this.props.accountType && this.props.accountType === 'coach' ? (
                            <Link
                            to="profile-coach"
                            className="removeLightbox"
                            onClick={() => {
                              this.handleDelete(
                                "certificates",
                                certificate.uid
                              );
                            }}
                          >
                            <img width="25" src={deleteSvg} alt="LG-Img" />
                          </Link>
                          ) : ""}
                        </div>
                      );
                    })}
                </div>

                {this.props.viewMore !== "certificates" ? (
                  <div className="container">
                    <div className="heading text-center">
                      <h3>{this.props.viewMore} List</h3>

                      <span className="R_divider"></span>
                    </div>
                    {this.props.dataSet.length === 0 && (
                      <div
                        className="pf__video__photo"
                        style={{ textAlign: "center", color: "black" }}
                      >
                        <h4 className="">No {this.props.viewMore} found</h4>
                      </div>
                    )}
                    <div className="row justify-content-center lightBox">
                      {this.props.dataSet &&
                        this.props.dataSet.map((eachItem) => {
                          return (
                            <div className="col-lg-4 col-md-4 col-6 my-3">
                              {this.props.viewMore === "Video" && (
                                <>
                                  <VideoPlayer
                                    id={eachItem.id}
                                    src={eachItem.path}
                                    poster={
                                      "https://markipo.com/Markipo_Reportages_Cyclisme_13_10/res/video.poster.jpg"
                                    }
                                  />

                                  <Link
                                    to="coach-profile"
                                    className="removeLightbox"
                                    onClick={() => {
                                      this.handleDelete(
                                        "resource",
                                        eachItem.uid
                                      );
                                    }}
                                  >
                                    <img
                                      width="25"
                                      src={deleteSvg}
                                      alt="LG-Img"
                                    />
                                  </Link>

                                  <p className="resource-title-modal">
                                    {eachItem.file_name}
                                  </p>
                                </>
                              )}

                              {this.props.viewMore === "Photo" && (
                                <>
                                  <Link
                                    to={eachItem.path}
                                    data-toggle="lightbox"
                                    data-gallery="example-gallery"
                                    className="boxWrap"
                                  >
                                    <img
                                      src={eachItem.path}
                                      className="img-fluid"
                                      alt="LG-Img"
                                    />
                                  </Link>
                                  <Link
                                    to="profile-coach"
                                    className="removeLightbox"
                                    onClick={() => {
                                      this.handleDelete(
                                        "resource",
                                        eachItem.uid
                                      );
                                    }}
                                  >
                                    <img
                                      width="25"
                                      src={deleteSvg}
                                      alt="LG-Img"
                                    />
                                  </Link>

                                  <p className="resource-title-modal">
                                    {eachItem.file_name}
                                  </p>
                                </>
                              )}

                              {this.props.viewMore === "Document" && (
                                <div className="pf__pdf">
                                  <Link to={eachItem.path}>
                                    <img
                                      alt="LG-Img"
                                      src="https://lifeguru.s3-us-west-2.amazonaws.com/assets/doc_placeholder.png"
                                    />
                                  </Link>

                                  <Link
                                    to="profile-coach"
                                    className="removeLightbox"
                                    onClick={() => {
                                      this.handleDelete(
                                        "resource",
                                        eachItem.uid
                                      );
                                    }}
                                  >
                                    <img
                                      width="25"
                                      src={deleteSvg}
                                      alt="LG-Img"
                                    />
                                  </Link>
                                  <p className="resource-title-modal">
                                    {eachItem.file_name}
                                  </p>
                                </div>
                              )}

                              {this.props.viewMore === "Audio" && (
                                <div className="pf__audio">
                                  <img
                                    alt="LG-Img"
                                    src="https://marketplace.bantu.my/assets/audio-placeholder-304b4c582a7bc94e6bfeefa1cde5582dd56ab86affa79b6cc9d70e3027926ee8.png"
                                  />

                                  <audio controls>
                                    <source
                                      src={eachItem.path}
                                      type="audio/ogg"
                                    />
                                    <source
                                      src={eachItem.path}
                                      type="audio/mpeg"
                                    />
                                    Your browser does not support the audio
                                    element.
                                  </audio>

                                  <Link
                                    to="profile-coach"
                                    className="removeLightbox"
                                    onClick={() => {
                                      this.handleDelete(
                                        "resource",
                                        eachItem.uid
                                      );
                                    }}
                                  >
                                    <img
                                      width="25"
                                      alt="LG-Img"
                                      src={deleteSvg}
                                    />
                                  </Link>
                                  <p className="resource-title-modal">
                                    {eachItem.file_name}
                                  </p>
                                </div>
                              )}

                              <div className="dropdown show">
                                <Link
                                  to="profile-coach"
                                  className="dropdown-toggle"
                                  // href="javascript:void(0)"
                                  // role="Link"
                                  id="dropdownMenuLink"
                                  data-toggle="dropdown"
                                  ariaHaspopup="true"
                                  aria-expanded="false"
                                >
                                  <img
                                    src={moreSvg}
                                    width="25px"
                                    alt="LG-Img"
                                  />
                                </Link>

                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuLink"
                                >
                                  {this.props.viewMore === "Video" && (
                                    <li>
                                      <Link
                                        to="profile-coach"
                                        className="dropdown-item"
                                        // href="javascript:void(0)"
                                        onClick={() => {
                                          const data = {
                                            id: eachItem.uid,
                                            profileVideo_path: eachItem.path,
                                          };
                                          this.props.handlePromotionalVideo(
                                            data
                                          );
                                        }}
                                      >
                                        Make Promotional Video
                                      </Link>
                                    </li>
                                  )}

                                  {this.props.viewMore === "Photo" && (
                                    <li>
                                      <Link
                                        to="profile-coach"
                                        className="dropdown-item"
                                        // href="javascript:void(0)"
                                        onClick={() => {
                                          const data = {
                                            id: eachItem.uid,
                                            avatar_path: eachItem.path,
                                          };
                                          this.props.handlePromotionalImage(
                                            data
                                          );
                                        }}
                                      >
                                       Make Main Profile Image
                                      </Link>
                                    </li>
                                  )}

                                  <li>
                                    <Link
                                      to="profile-coach"
                                      className="dropdown-item"
                                      // href="javascript:void(0)"
                                      onClick={() => {
                                        this.handleDelete(
                                          "resource",
                                          eachItem.uid
                                        );
                                      }}
                                    >
                                      Delete
                                    </Link>
                                  </li>

                                  <li>
                                    <Link
                                      to="profile-coach"
                                      className="dropdown-item"
                                      // href="javascript:void(0)"
                                      data-toggle="modal"
                                      data-target="#share"
                                      data-dismiss="modal"
                                      onClick={() => {
                                        const data = {
                                          ResourceType: eachItem.resource_type,
                                          path: eachItem.path,
                                        };
                                        this.props.handleShare(data);
                                      }}
                                    >
                                      Share to Social Media{" "}
                                    </Link>
                                  </li>
                                  <li>
                                    <div>
                                      <Link
                                        to="profile-coach"
                                        className="dropdown-item"
                                        // href="javascript:void(0)"
                                        onClick={() =>
                                          this.props.handleStatus(
                                            eachItem.visibility,
                                            eachItem.uid
                                          )
                                        }
                                      >
                                        {eachItem.visibility === "public" ? (
                                          <div>Client View Only</div>
                                        ) : (
                                          <div>Public View</div>
                                        )}
                                      </Link>
                                    </div>
                                  </li>

                                  <li>
                                    <Link
                                      to="profile-coach"
                                      className="dropdown-item"
                                      // href="javascript:void(0)"
                                      data-toggle="modal"
                                      data-target="#editfileName"
                                      onClick={() => {
                                        const data = {
                                          type: this.props.viewMore,
                                          id: eachItem.uid,
                                          path: eachItem.path,
                                          filename: eachItem.file_name,
                                        };
                                        this.props.handleEditfileName(data);
                                      }}
                                    >
                                      Edit File Title
                                    </Link>
                                  </li>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="modal-footer">
                {/* <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={this.props.handleViewMoreModal}
                >
                  Close
                </button> */}
              </div>
            </div>
          {/* </div> */}
        </Modal>

        <Modal show={this.props.shareModal} className="modal fade" id="share">
          {/* <div className="modal-dialog modal-sm modal-dialog-centered"> */}
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="exampleModalCenterTitle">
                  <b>Share</b>
                </h4>
                <button
                  type="button"
                  className="close"
                  // data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.handleShareModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <ul className="share__option">
                  <SocialMediaButtons data={this.state} />
                </ul>
              </div>
            </div>
          {/* </div> */}
        </Modal>
      </div>
    );
  }
}
export default ListModal;
