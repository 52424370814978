import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import masterlogo from "../../../assets/images/mastercardlogo.png";
import  mc  from "../../../assets/images/mc.png";
import  visa  from "../../../assets/images/visa.png";
import  ae  from "../../../assets/images/ae.png";
import { PriceTableNew } from "../../../components/pricetable";
import axios from '../../../config/axios';
import { getHelpText } from '../../../utils/mesaages';
import message from '../../../utils/mesaages';
import ManageSubscriptionModal from "../view/ManageSubscriptionModal";

const _ = require("lodash");  

class ManageSubscription extends React.Component {
  constructor(props) {
    super(props)
    this.token = localStorage.getItem("authToken");
    this.userdata = JSON.parse(localStorage.getItem('userdata'))
    this.state = {
      city:'',
      country:'',
      ownerState:'',
      paymentDetails: [],
      loading: true,
      has_membership:0,
      membershipID:null,
      subscriptionId:null,
      membershipExpire:'',
      membershipStarted: '',
      allplan:[],
      currentPlan:'',
      acc_delete_text:'',
      freeTrialDaysleft: 0,
      createdDate:"",
      selectedOption:"first",
      carddetails: [],
      selectedCardForSubscription: null,
      deletSubscription:false,
      upgradeBlock:false,
      accountCreatedDate: null,
      membershipIDExpired:"",
      coachHasAnySubscriptionHistory: ""
  }

}

 async componentDidMount()
  {
    let acc_delete_text = await getHelpText('acc_delete_text')
    this.setState({acc_delete_text})
    this.fetchCoachData()
    this.fetchcards()
  }
  fetchCoachData=()=> {
    this.setState({loader: true})
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    const id = userdata.uid;

                let param = {
                url: `/users/coachdatajoin/${id}`,
                method: "GET",
                headers: {
                    "X-Auth": this.token
                }
                }
    axios(param)
      .then(async (res) => {
        this.setState({loader: false})
   
        if (res.data.value) {

          //console.clear();
        
          const rawdata = res.data.value;
          const rawaddress = rawdata.address ? JSON.parse(rawdata.address) : "";
          console.log("-------------------------------------------res-socail-------------", rawaddress)
          this.setState({city:rawaddress.city,
           country:rawaddress.country,
           ownerState:rawaddress.location
         })

          this.setState({has_membership:rawdata.has_membership, coachHasAnySubscriptionHistory: rawdata.coachHasAnySubscriptionHistory})
          this.setState({
            membershipID:rawdata.individual_gateway_mode === "sandbox" ? rawdata.membership_id : rawdata.membership_id_live,
            subscriptionId:rawdata.individual_gateway_mode === "sandbox" ? rawdata.subscriptionId : rawdata.subscriptionId_live, 
            membershipIDExpired:rawdata.membership_id, 
            membershipStarted: rawdata.individual_gateway_mode === "sandbox" ? rawdata.membership_start_date : rawdata.live_membership_start_date
          })
          this.setState({membershipExpire:rawdata.individual_gateway_mode === "sandbox" ? rawdata.membership_exp_date : rawdata.live_membership_end_date},()=>{
           this.getPlan()
           this.currentPlan()
          })


          const accontCreated = new Date(rawdata.created);
          const finalDate = new Date(accontCreated);
          finalDate.setDate(accontCreated.getDate() + 90);
          var countDownDate = new Date(finalDate);
          var now = new Date();
          var timeleft = countDownDate - now;
          var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
          //var days = 0
        await this.setState({
            freeTrialDaysleft: days >= 0 ? days : -1,
          });

          this.setState({accountCreatedDate: accontCreated})
        

      
        }
      })
      .catch((error) => {
        this.setState({loader: false})
        // toast("Error getting profile data", { type: "error" });
      });
  }
  getPlan=()=> {
  
    axios
      .get(`/users/get-allcoach-plan`, {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          let plan = res.data.value.coachPlan;
          if (plan.length > 0) {
            this.setState({ allplan: plan,
                // planDesc:res.data.value.coachDesc,
                // yearlydiscount:res.data.value.discount.plan_rate 
            },()=>{
               
            });
            
            //  let yearly= plan.map((descVal)=>{
            //     let totalYearly=descVal.plan_price*12
            //     return this.calculatePercent(res.data.value.discount.plan_rate, totalYearly)
                  
            //     } )

        
              }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  upgradeBlock=(status)=>
    {
      this.setState({upgradeBlock:status})
    }

  currentPlan=()=>{
    let currentId= this.state.membershipID;

    axios
          .get(`/users/get-one-plan/${currentId}`, {
            headers: {
              "X-Auth": this.token,
            },
          })
          .then((res) => {
            if (res.data.success) {
            
              let plan = res.data.value.coachPlan[0];
              //if (plan.length > 0) {
                this.setState({ currentPlan: plan});
                
               
              //}
            }
          })
          .catch((error) => {
            console.error(error);
          });
    
    // let subscription =_.find(this.state.allplan, function(o) { return o.id == currentId });

    // this.setState({currentPlan:subscription},
    //     ()=>{
    //     })
      
  }


  fetchcards = () => {
    let param = {
        url: "/consumer/getpaymentmethodcard",
        method: "GET",
        headers: {
            "X-Auth": localStorage.getItem('authToken'),
        },
    };

    axios(param)
        .then(async (res) => {
            if (res.data.success) {
              
              let primary = _.find(res.data.cards, function(o) { return o.primarycard == 1 });
              console.log('primary',primary)
              if(primary!=undefined)
              this.handleChangeOfSelectedCardForSubscription(primary.id,null)
              // this.setState({selectedCardForSubscription:primary.id})
              this.setState({ carddetails: res.data.cards })
            }
        })
        .catch((error) => {
            //toast("Error getting profile data", { type: "error" });
        });
  }

 

  onActivatingSubscription = () => {
    this.fetchCoachData()
  }
  getOnePlan(planId) {

    let promise = new Promise((resolve, reject) => {
      axios
      .get(`/users/get-one-plan/${planId}`, {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          console.log('plan.....',res.data.value.coachPlan[0])
          let plan = res.data.value.coachPlan[0];
         
           
            resolve(plan)
            
           
         
        }
        else
        {
          reject(null)
        }
      })
      .catch((error) => {
        console.error(error);
        reject(null)
      });

    })
  
   return promise
     
  }


  getSubscription=async (planID,isUpgradeBoolean)=>{
    let {selectedCardForSubscription} = this.state
    let carduid = selectedCardForSubscription
    console.log('carduid',carduid)
    
    
    if(carduid!=null)
    {
      const hidemsg = message.loading(`Please wait ..`,0)
      let cardData =    [...this.state.carddetails].find(d=>d.id===carduid);
          cardData = {
              "creditCardToken": cardData.strip_card,
              "customer": cardData.strip_cus,
              "transactionAmount":1,
              "cardExpMonth": cardData.expirymonth,
              "cardExpYear": cardData.expiryyear,
              "cVV":`${cardData.cvc}`,
              "cardcompany":cardData.cardcompany,
              "cardnumber":cardData.cardnumber
            }
        
        
        cardData['ownerName']=''
        cardData['ownerCity']=this.state.city
        cardData['ownerCountry']=this.state.country
        cardData['ownerState']=this.state.ownerState
        let plan = await this.getOnePlan(planID)
        console.log('plan',plan)
        let packageprice=plan.plan_price
      let authinfoData 
      // let packageprice=1 //need to change

     

            let payload = {
              card_id:cardData.creditCardToken,
              customer:cardData.customer,
              planId:plan.plan_id,
              plan_chargefrequency: plan.plan_chargefrequency,
              // planId:"price_1JSFy9CbNXb8vkbAE3ibXnzJ",
              cardType:cardData.cardcompany,
              cardLastFourDigits:cardData.cardnumber.split(" ")[3],
              coach_uid:this.userdata.uid,
              coach_name:this.userdata.name,
              description: `Coach: ${this.userdata.name} Plan: ${plan.plan_name}`
            }


        if(this.state.membershipID==null)
        {
              let SubscriptionUrl = '/strip/create-subscription'
            
                  
            
            axios
            // .post(AdminApi+"/1stPay/cardAuth", cardData)
            .post(SubscriptionUrl, payload)
            .then(async (authinfo) => {
             
              // console.log('charge',authinfo.data.data)
              if(authinfo && authinfo.data.success){
                authinfoData=authinfo.data.data
                let chargeInfo = authinfoData
                    
                    let paymentPost = {
                      cardData,
                      'chargeInfo':chargeInfo,
                      DateTime:moment().format('YYYY-MM-DD HH:mm:ss'),
                      Package:{ "type":"coach"
                      , "name": `${plan.plan_name}`,
                      "price": packageprice,
                      coach_uid:this.userdata.uid,
                      coach_email:this.userdata.email,
                      coach_name:this.userdata.name,
                      planid:planID,
                      duration:plan.plan_duration,
                      plan_chargefrequency:plan.plan_chargefrequency,
                        
                     
                    },
      
                    }
                    console.log('paymentPost',paymentPost)
                    
                    axios.post("/payment/checkout",paymentPost)
                    .then(response =>{
                      hidemsg()
                      const { success } = response.data;
                      console.log("Response payyyyy:", response.data);
                      if (success) {
                        
                        message.loading(response.data.msg)
                        setTimeout(() => {
                         window.location.reload();
                           
                         }, 4000);
                        
                      } else {
                       
                        message.error(response.data.msg)
      
                       
                      }
                    })
                    .catch(()=>{
                     
                      hidemsg()
                    })
      
      
      
      
                  
               
             }
             else
             {
              message.error(authinfo.data.err)
             }
            }).catch(e=>{
              console.log(e)
              hidemsg()
                message.error('Error cardAuth.')
                
          })
        }
        else//upgrade
        {
         
                
                    
                    let paymentPost = {
                      cardData,
                      DateTime:moment().format('YYYY-MM-DD HH:mm:ss'),
                      subscriptionId:this.state.subscriptionId,
                      Package:{ "type":"coach"
                      , "name": `${plan.plan_name}`,
                      "price": packageprice,
                      coach_uid:this.userdata.uid,
                      coach_email:this.userdata.email,
                      coach_name:this.userdata.name,
                      planid:planID,
                      duration:plan.plan_duration,
                      plan_chargefrequency:plan.plan_chargefrequency,
                      packageUpgrade:true,
                      isUpgradeBoolean,
                      payload,
                      
                     
                        
                     
                    },
      
                    }
                    console.log('paymentPost',paymentPost)
                    
                    axios.post("/payment/checkout",paymentPost)
                    .then(response =>{
                      hidemsg()
                      const { success } = response.data;
                      console.log("Response payyyyy:", response.data);
                      if (success) {
                        
                        message.loading(response.data.msg)
                        setTimeout(() => {
                          window.location.reload();
                           
                         }, 4000);
                        
                      } else {
                     
                        message.error(response.data.msg)
      
                       
                      }
                    })
                    .catch(()=>{
                  
                      hidemsg()
                    })
      
      
      
      
                  
               
             
           
        }//upgrade
        
          
     
  }
    else
    {
     // message.error("Please fill the form correctly")
      if(this.state.carddetails.length>0){
        message.error("Please select card")
      }
      else
      {
        message.error("Please select card")
      }
    }
  }


  

  render() {

   return (<></>);

    return (
       
      <div className="col-md-14 subscriptionSmall">

        
                    {/* <div className="heading text-center">
                            <h3>Manage Subscription</h3>
                            <span className="R_divider"></span>
                    </div> */}

                    <Row className="justify-content-center">
                      <Col lg={8} md={12} sm={12} xs={12} className="d-flex justify-content-center mb-3">
                        <div className="card subscription_card">
                          <ul style={{color:'black'}} className="subscription_list mb-0">
                                    <li><strong><b>Subscription Plan :</b> </strong>Free 90 day trial (Standard)</li>
                                    <li><strong><b>Start Date :</b> </strong>Free Trial - Coming Soon</li>
                                    {/* <li><strong>Subscription Expire : </strong>{this.state.membershipExpire}</li>*/}
                                    <li><strong><b>Duration :</b> </strong>90 day</li>                       
                                </ul>
                          {/* {this.state.coachHasAnySubscriptionHistory? "" : (
                            this.state.freeTrialDaysleft >0 && this.state.membershipIDExpired==null ?
                            (
                              <div className="d-flex justify-content-center">
                              <div className="freeSubscription">
                              <span>Free Trial</span>
                            </div>
                            </div>
  
                            ):(
                                this.state.membershipIDExpired==null?
                                <div className="d-flex justify-content-center">
                                <div className="freeSubscription">
                                <span>Free Trial Expired</span>
                              </div>
                              </div>
                              :""
  
                            )
                          )
                        }
                         
                            {this.state.subscriptionId!==null && this.state.membershipID!==null ? (
                              <ul style={{color:'black'}} className="subscription_list">
                                  <li><strong><b>Subscription Plan :</b> </strong>{this.state.currentPlan!=''?this.state.currentPlan.plan_name:''}</li>
                                  <li><strong><b>Start Date :</b> </strong>{moment(this.state.membershipStarted).utc().format("dddd, MMMM Do YYYY")}</li>
                                  
                                  <li><strong><b>Duration :</b> </strong>{this.state.currentPlan!=''?
                                  this.state.currentPlan.plan_chargefrequency=='MONTHLY'? 'Monthly' : 'Yearly'
                                  :''}</li>                       
                              </ul>
                            ):(
                              // ELSE MEANS this.state.membershipID == null
                              this.state.freeTrialDaysleft <= 0 || !this.state.accountCreatedDate || this.state.coachHasAnySubscriptionHistory ?
                                <ul style={{color:'black'}} className="subscription_list mb-0">
                                    <li><strong><b>Subscription Plan :</b> </strong>N/A</li>
                                    <li><strong><b>Start Date :</b> </strong>N/A</li>
                                    
                                    <li><strong><b>Duration :</b> </strong>N/A</li>                       
                                </ul> :
                                <ul style={{color:'black'}} className="subscription_list">
                                  <li><strong><b>Subscription Plan :</b> </strong>Standard</li>
                                  <li><strong><b>Start Date :</b> </strong>{moment(this.state.accountCreatedDate).utc().format("dddd, MMMM Do YYYY")}</li>
                                  
                                  <li><strong><b>Duration :</b> </strong>90 days</li>
                                </ul> 


                            )                              
                            } */}
                            {/* {this.state.membershipID==null && (this.state.freeTrialDaysleft <= 0 || this.state.coachHasAnySubscriptionHistory) &&(
                              <ul style={{color:'black'}} className="subscription_list my-0">
                                  <li><strong><p style={{fontSize:"11px"}}>Hi your subscription has been expired, we would love to get you on board. Please renew.</p></strong></li>
                              </ul>
                            )} */}
                            
                        </div>
                      </Col>
                      <Col lg={4} md={12} sm={12} xs={12}>
                        <Row className="my_savecard_div mr-0 ml-0 ">
                          <div className="my_savecard d-flex flex-column justify-content-start align-items-center w-100">

                           {/* <input type="radio" name="select" id="option-1" checked/>
                           <input type="radio" name="select" id="option-2"/> */}
                           {/* <input type="radio" name="select" id="option-3"/> */}
                           {this.state.carddetails.length>0 && (
                              this.loadSavedCards()
                           )}
                         
                          
                          </div>
                          </Row>
                      </Col>
 
                      <Col md="12 mt-3">
                            <div className="remove_ac mt-auto">
                              <ManageSubscriptionModal
                                onActivatingSubscription={this.onActivatingSubscription}
                                token={this.token}
                                fun_name={"Delete"}
                                icon={"fa fa-trash"}
                                //pack_id={this.props.packagedata.uid}
                                handleClick={this.handleClick}
                                infoText={this.state.acc_delete_text}
                                deletSubscription={this.state.deletSubscription}
                                upgradeBlock={this.upgradeBlock}
                                membershipExpire={this.state.membershipExpire}
                              />
                            </div>

                      </Col>
                        {/* <Col md={8}>
                          {this.state.membershipID!==null && (
                            <ul style={{color:'black'}} className="subscription_list">
                                <li><strong>Subscription Name : </strong>{this.state.currentPlan.plan_name}</li>
                                <li><strong>Subscription Started : </strong>{moment(this.state.membershipStarted).format("dddd, MMMM Do YYYY, h:mm:ss a")}</li>
                                <li><strong>Subscription Expire : </strong>{moment(this.state.membershipExpire).format("dddd, MMMM Do YYYY, h:mm:ss a")}</li>                       
                            </ul>
                          )}
                           {this.state.membershipID==null && this.state.freeTrialDaysleft==0 &&(
                            <ul style={{color:'black'}} className="subscription_list">
            
                                <li><strong>Hi your subscription has been expired, we would love to get you on board. Please renew.</strong></li>
                            </ul>
                          )}
                        </Col>
                        <Col md={4} className="d-flex align-items-center">
                            <ConfirmModal
                              fun_name={"Delete"}
                              icon={"fa fa-trash"}
                              handleClick={this.handleClick}
                              infoText={this.state.acc_delete_text}
                            />
                        </Col> */}
                    </Row>
                    
                    <PriceTableNew 
                      numberOfCards={this.state.carddetails.length}
                      header={false} 
                      currentPlan={this.state.currentPlan} 
                      membershipID={this.state.membershipID} 
                      upgradeBlock={this.state.upgradeBlock}
                      getSubscription={this.getSubscription}
                    />


        </div>

                    
      

    )



  
}

  loadSavedCards = () => {

    let cards = [];

    cards = this.state.carddetails.map((card, index) => {
      let checked = false;
      // if(card.primarycard === '1') {
      //   // this.handleChangeOfSelectedCardForSubscription(card.id)
      //   checked = true;
      // }
      if(card.id === this.state.selectedCardForSubscription) {
        checked = true
      }
      index++;
      return (
        <input key={index}
        type="radio" name="select" 
        id={`option-${index}`} 
        checked={checked} 
        onClick={() => this.handleChangeOfSelectedCardForSubscription(card.id,card)}
        />
      )
    })

    cards.push(this.state.carddetails.map( (card, index) => {
      
      index++;

      return (
        <label key={index} for={"option-" + index} className={"option mt-2 option-" + index} >
          <div className="card savecard">
            <Row className="justify-content-between m-0">
              <Col md="8">
              {(card.cardcompany.toUpperCase() =='VISA')?(<img  src={visa}/> ):
                               (card.cardcompany.toUpperCase() == 'MASTERCARD')?(<img  src={mc}/>):
                               (<img  src={ae}/>)}
              </Col>
              <Col md="4" className="select_circle"><div className="dot ml-auto"></div></Col>
            </Row>
            <Row className="justify-content-between m-0">
              <Col md="12">{card.cardnumber}</Col>
            </Row>
            <Row className="justify-content-between m-0">
              <Col md="8" xs="8" sm="8">{card.name}</Col>
              <Col md="4" xs="4" sm="4" className="text-right">{card.expirymonth}/{card.expiryyear && card.expiryyear.slice(2)}</Col>
            </Row>
          </div>
        </label>
      )      
      }
    ))

    return cards;
  }

  handleChangeOfSelectedCardForSubscription = (cardId,carddata=null) => {
    this.setState({
      selectedCardForSubscription: cardId
    })
    if(carddata!=null)
    {
      const hidemsg = message.loading(`Please wait ..`,0)
      axios.post('/consumer/set-primary-card', { card: carddata, user: localStorage.getItem('userdata') }).then((res)=>{
        if (res.data.success) {
          hidemsg()
        message.success('Your card is selected.')
        this.fetchcards()
          this.setState({
            selectedCardForSubscription: cardId
          })
        }
        else
        {
          hidemsg()
        message.error('Card selectoin failed')
        }
      }).catch((err) => {
        console.log('err', err)
        hidemsg()
        message.error('Card selectoin failed')
        
      })
    }
    else
    {
      

    }
    

  }

  

  
}

export default ManageSubscription