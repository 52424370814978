import React from 'react'
import moment from "moment";
import axios from '../../../config/axios'
import { toast } from "react-toastify";
import { Form} from "react-bootstrap";
const _ = require("lodash");

class WeekList extends React.Component{
    constructor(props){
        super(props)
    this.state={
      SundayTimings: [{Day:"Sunday",From: "", To: ""}],
      MondayTimings: [{Day:"Monday",From: "", To: ""}],
      TuesdayTimings: [{Day:"Tuesday",From: "", To: ""}],
      WednesdayTimings: [{Day:"Wednesday",From: "", To: ""}],
      ThursdayTimings: [{Day:"Thursday",From: "", To: ""}],
      FridayTimings: [{Day:"Friday",From: "", To: ""}],
      SaturdayTimings: [{Day:"Saturday",From: "", To: ""}],
      
      unavailableTime: [{}],
      UnDay:"",
      // unavailableTime: [{Day:"Sunday",From: "", To: ""}],
      // unavailableTime: [{Day:"Monday",From: "", To: ""}],
      // unavailableTime: [{Day:"Tuesday",From: "", To: ""}],
      // unavailableTime: [{Day:"Wednesday",From: "", To: ""}],
      // unavailableTime: [{Day:"Thursday",From: "", To: ""}],
      // unavailableTime: [{Day:"Friday",From: "", To: ""}],
      // unavailableTime: [{Day:"Saturday",From: "", To: ""}],

      availability_id:null,

      currDate: moment().format('DD-MM-YYYY'),
      }
    }

    componentDidMount(){
      this.handleGetAvailability()
    }
    
    handleGetAvailability(){
      const id=this.props.coachUid
      axios.get(`/coach/setavailability/${id}`,{
        headers:{'x-auth':this.props.token}
      })
      .then(res =>{
        if(res.data.success && res.data.value.length > 0){
          let dataset=res.data.value[0]
          this.setState({availability_id:dataset.uid})
          let data=JSON.parse(dataset.schedule_details)
            let weekdata
        
            weekdata=data.availableDays
            let unavailableDays
            if(data.unavailableDays.length!=0)
            {
             
              unavailableDays=data.unavailableDays
            }else{
              unavailableDays=[{}]
            }
           // console.log("-------fist1--",data.unavailableDays)
            this.setState({
              SundayTimings:weekdata[0].Sundays.length !==0 ?weekdata[0].Sundays:[{Day:"Sunday",From: "", To: ""}],
              MondayTimings:weekdata[1].Mondays.length !==0 ?weekdata[1].Mondays:[{Day:"Monday",From: "", To: ""}],
              TuesdayTimings:weekdata[2].Tuesdays.length !==0 ?weekdata[2].Tuesdays:[{Day:"Tuesday",From: "", To: ""}],
              WednesdayTimings:weekdata[3].Wednesdays.length !==0 ?weekdata[3].Wednesdays:[{Day:"Wednesday",From: "", To: ""}],
              ThursdayTimings:weekdata[4].Thursdays.length !==0 ?weekdata[4].Thursdays:[{Day:"Thursday",From: "", To: ""}],
              FridayTimings:weekdata[5].Fridays.length !==0 ?weekdata[5].Fridays:[{Day:"Friday",From: "", To: ""}],
              SaturdayTimings:weekdata[6].Saturdays.length !==0 ?weekdata[6].Saturdays:[{Day:"Saturday",From: "", To: ""}],
              unavailableTime:unavailableDays,
           })
          
          
         
        }
      })
      .catch(err =>{
        console.log(err)
      })
    }

    handleInputChange = (e, index,state) => {
      const { name, value } = e.target;
      const list = [...state];
      let listLength=list.length;
       listLength=listLength
      list[index][name] = value;
      console.log(list)
    

      let from = this.state[list[index].Day+'Timings'][index].From 
      let to = this.state[list[index].Day+'Timings'][index].To
       if(from < to || to =='')
       {
        //console.log('from ', from,'to ',to)
         let incLength=1;
       let status=true
         //console.log('list lenght ',listLength)
        list.map( (items, key) => {

          if(incLength<listLength)
          {
          
         
            if((items.From<=from && items.To>from) || (items.From<to && items.To>=to))
            {
              //console.log('ttttt')
              status=false
              toast("Slot already Choosen", { type: "error" });
                return 0;
              
            }else{
              status=true
              
            }
        
            // console.log('key ',key)
            // console.log('status ',status)
            // console.log('list from ',items.From, 'from ',from)
            if(status && incLength==listLength-1)
            {
              this.setState({state: list });
              let Firstweek=[this.state.SundayTimings,this.state.MondayTimings,this.state.TuesdayTimings,
                this.state.WednesdayTimings,this.state.ThursdayTimings,this.state.FridayTimings,this.state.SaturdayTimings]
          
              let ScheduledList
              ScheduledList=[Firstweek]
              
              this.props.handleWeekSchedule(ScheduledList)
              // if(from != '' && to != '')
              // toast(`From ${this.state[list[index].Day+'Timings'][index].From} - To  ${this.state[list[index].Day+'Timings'][index].To} Selected`, { type: "success" });
    
            }
          }else
          {

           
              this.setState({state: list });
              let Firstweek=[this.state.SundayTimings,this.state.MondayTimings,this.state.TuesdayTimings,
                this.state.WednesdayTimings,this.state.ThursdayTimings,this.state.FridayTimings,this.state.SaturdayTimings]
          
              let ScheduledList
              ScheduledList=[Firstweek]
              
              this.props.handleWeekSchedule(ScheduledList)
              // if(from != '' && to != '')
              // toast(`From ${this.state[list[index].Day+'Timings'][index].From} - To  ${this.state[list[index].Day+'Timings'][index].To} Selected`, { type: "success" });
    
            

          }
         

        
        incLength=incLength+1;


        })

        

        

       

       }
       else
       {
        toast("To time slot should be Greater than From time slot", { type: "error" });
       }

      

      // if(list[index][name])
      
    };

      handleInputChangeUnavailable = (e, index,state) => {
        const { name, value } = e.target;
        const list = [...state];
       
        list[index][name] = value;
        this.state.UnDay=list[index][name]

        console.log('un ',list)
       // console.log('undate ',this.state.UnDay)

        let from = list[index].From 
        let to = list[index].To 
        if(from < to || to =='')
        {
          //console.log('from', from)
          //console.log('to', to)
          this.setState({state: list });
      
          this.props.handleWeekScheduleUnavailable(list)
          // if(from != '' && to != '')
          // toast(`From ${from} - To  ${to} Selected`, { type: "success" });
    

        }else{
          toast("To time slot should be Greater than From time slot", { type: "error" });
         }

      

        
     
        
      };

      handleSubmitSchedule=(e)=>{
        this.props.handleSubmit(e)
        this.handleGetAvailability()
      }

      handleUpdateweekset=()=>{
        this.handleGetAvailability()
      }

      handleResetweekset=()=>{
        this.setState({
      SundayTimings: [{Day:"Sunday",From: "", To: ""}],
      MondayTimings: [{Day:"Monday",From: "", To: ""}],
      TuesdayTimings: [{Day:"Tuesday",From: "", To: ""}],
      WednesdayTimings: [{Day:"Wednesday",From: "", To: ""}],
      ThursdayTimings: [{Day:"Thursday",From: "", To: ""}],
      FridayTimings: [{Day:"Friday",From: "", To: ""}],
      SaturdayTimings: [{Day:"Saturday",From: "", To: ""}],
    
          availability_id:null
        })
      }


      handleweekListChange=()=>{
        let Firstweek=[this.state.SundayTimings,this.state.MondayTimings,this.state.TuesdayTimings,
            this.state.WednesdayTimings,this.state.ThursdayTimings,this.state.FridayTimings,this.state.SaturdayTimings]
      
          let ScheduledList
          ScheduledList=[Firstweek]
          this.props.handleWeekSchedule(ScheduledList)
      }

      handleweekListUnavailableChange=()=>{
     
          let ScheduledList
          ScheduledList=this.state.unavailableTime
          this.props.handleWeekScheduleUnavailable(ScheduledList)
      }

      handleSubmitWeekSchedule=(e) =>{
        this.handleweekListChange()
        this.handleweekListUnavailableChange()
       
        setTimeout(() => {   this.props.handleSubmit(e) }, 500);
      }


    render(){
      // console.log("curr---date",this.state.currDate.split("-")[0])
        return(
   
   <div>


            <div className="row">
              <div className="col-md-3 col-4">
                <label>Weekly Hours</label>
              </div>
              <div className="col-md-4 col-4">
                <label>Select Start Time</label>
              </div>
              <div className="col-md-4 col-4">
                <label>Select End Time</label>
              </div>
              <div className="col-md-12">
                <hr/>
              </div>            
            </div>    

            <div className="row">            
                <div className="col-md-12">  
                      <div className="row"> 
                        <div className="col-md-2 col-sm-4  text-center ml-auto">             
               
                            {this.state.SundayTimings.map((x, i) => {
                                
                              return (
                                <>
                                  {this.state.SundayTimings.length - 1 === i && (
                                    <i
                                      className="fa fa-plus-square"
                                      style={{
                                        color: "green",
                                        fontSize: "30px",
                                        float: "right",
                                      }}
                                      // onClick={() => this.handleAddClick(i,this.state.SundayTimings)}
                                      onClick={() =>{
                                        this.setState(prevState => ({ 
                                              SundayTimings: [...prevState.SundayTimings,{Day:"Sunday",From: "", To: ""}]
                                            }))
                                      }}
                                    />
                                  )}
                                </>
                              );
                            })}

                        </div> 
                      </div>                               
                </div>                                
                <div className="col-md-3 col-4">
                  <div className="form-group">
                  
                    <input type="text"
                    style={{color:"black"}} 
                    name="Day" 
                    value="Sunday"
                    className="form-control"/>
                    </div>
                </div>     
                <div className="col-md-9 col-8">
                    {this.state.SundayTimings.map((x,i) =>(
                        <div className="row">
                          <div className="col-md-5 col-6 col-sm-4">
                                <div className="form-group">                      
                                    <input type="time"
                                    style={{color:"black"}} 
                                    name="From" 
                                    value={x.From}                             
                                    onChange={(e) => this.handleInputChange(e, i,this.state.SundayTimings)}
                                    className="form-control"/>
                                </div>
                          </div>
                          <div className="col-md-5 col-6 col-sm-4">                          
                                <div className="form-group">                      
                                  <input type="time" 
                                  style={{color:"black"}}
                                  name="To" 
                                  value={x.To}
                                  onChange={(e) => this.handleInputChange(e, i,this.state.SundayTimings)} 
                                  className="form-control"/>
                                </div>
                          </div>
                          <div className="col-md-2 col-sm-4  text-center">
                                {this.state.SundayTimings.length > 1 && (
                                  <i
                                    className="fa fa-minus-square"
                                    style={{
                                      color: "green",
                                      fontSize: "30px",
                                      float: "right",
                                    }}
                                    onClick={() =>{
                                        let SundayTimings = [...this.state.SundayTimings];
                                        SundayTimings.splice(i, 1);
                                        this.setState({ SundayTimings });
                                        setTimeout(() => {  this.handleweekListChange() }, 500);
                                    }}
                                  ></i>
                                )}                          
                          </div>
                        </div>
                        ))}
                </div>
                <div className="col-md-12">
                  <hr/>
                </div>                
            </div>




            <div className="row">            
                <div className="col-md-12">                
                    <div className="row"> 
                      <div className="col-md-2 col-sm-4  text-center ml-auto">                 
                        {this.state.MondayTimings.map((x, i) => {
                            
                          return (
                            <>
                              {this.state.MondayTimings.length - 1 === i && (
                                <i
                                  className="fa fa-plus-square"
                                  style={{
                                    color: "green",
                                    fontSize: "30px",
                                    float: "right",
                                  }}
                                  // onClick={() => this.handleAddClick(i,this.state.MondayTimings)}
                                  onClick={() =>{
                                    this.setState(prevState => ({ 
                                          MondayTimings: [...prevState.MondayTimings,{Day:"Monday",From: "", To: ""}]
                                        }))
                                  }}
                                />
                              )}
                            </>
                          );
                        })}
                      </div>
                    </div>
                </div>                                
                <div className="col-md-3 col-4">
                  <div className="form-group">
                  
                    <input type="text"
                    style={{color:"black"}} 
                    name="Day" 
                    value="Monday"
                    className="form-control"/>
                    </div>
                </div>     
                <div className="col-md-9 col-8">
                    {this.state.MondayTimings.map((x,i) =>(
                        <div className="row">
                          <div className="col-md-5 col-6 col-sm-4">
                                <div className="form-group">                      
                                    <input type="time"
                                    style={{color:"black"}} 
                                    name="From" 
                                    value={x.From}                             
                                    onChange={(e) => this.handleInputChange(e, i,this.state.MondayTimings)}
                                    className="form-control"/>
                                </div>
                          </div>
                          <div className="col-md-5 col-6 col-sm-4">                          
                                <div className="form-group">                      
                                  <input type="time" 
                                  style={{color:"black"}}
                                  name="To" 
                                  value={x.To}
                                  onChange={(e) => this.handleInputChange(e, i,this.state.MondayTimings)} 
                                  className="form-control"/>
                                </div>
                          </div>
                          <div className="col-md-2 col-sm-4  text-center">
                                {this.state.MondayTimings.length > 1 && (
                                  <i
                                    className="fa fa-minus-square"
                                    style={{
                                      color: "green",
                                      fontSize: "30px",
                                      float: "right",
                                    }}
                                    onClick={() =>{
                                        let MondayTimings = [...this.state.MondayTimings];
                                        MondayTimings.splice(i, 1);
                                        this.setState({ MondayTimings });
                                        setTimeout(() => {  this.handleweekListChange() }, 500);
                                    }}
                                  ></i>
                                )}                          
                          </div>
                        </div>
                        ))}
                </div>
                <div className="col-md-12">
                  <hr/>
                </div>                
            </div>





            <div className="row">            
                <div className="col-md-12">                
                    <div className="row"> 
                      <div className="col-md-2 col-sm-4  text-center ml-auto">                 
                        {this.state.TuesdayTimings.map((x, i) => {
                            
                          return (
                            <>
                              {this.state.TuesdayTimings.length - 1 === i && (
                                <i
                                  className="fa fa-plus-square"
                                  style={{
                                    color: "green",
                                    fontSize: "30px",
                                    float: "right",
                                  }}
                                  // onClick={() => this.handleAddClick(i,this.state.TuesdayTimings)}
                                  onClick={() =>{
                                    this.setState(prevState => ({ 
                                          TuesdayTimings: [...prevState.TuesdayTimings,{Day:"Tuesday",From: "", To: ""}]
                                        }))
                                  }}
                                />
                              )}
                            </>
                          );
                        })}
                      </div>
                    </div>
                </div>                                
                <div className="col-md-3 col-4">
                  <div className="form-group">
                  
                    <input type="text"
                    style={{color:"black"}} 
                    name="Day" 
                    value="Tuesday"
                    className="form-control"/>
                    </div>
                </div>     
                <div className="col-md-9 col-8">
                    {this.state.TuesdayTimings.map((x,i) =>(
                        <div className="row">
                          <div className="col-md-5 col-6 col-sm-4">
                                <div className="form-group">                      
                                    <input type="time"
                                    style={{color:"black"}} 
                                    name="From" 
                                    value={x.From}                             
                                    onChange={(e) => this.handleInputChange(e, i,this.state.TuesdayTimings)}
                                    className="form-control"/>
                                </div>
                          </div>
                          <div className="col-md-5 col-6 col-sm-4">                          
                                <div className="form-group">                      
                                  <input type="time" 
                                  style={{color:"black"}}
                                  name="To" 
                                  value={x.To}
                                  onChange={(e) => this.handleInputChange(e, i,this.state.TuesdayTimings)} 
                                  className="form-control"/>
                                </div>
                          </div>
                          <div className="col-md-2 col-sm-4  text-center">
                                {this.state.TuesdayTimings.length > 1 && (
                                  <i
                                    className="fa fa-minus-square"
                                    style={{
                                      color: "green",
                                      fontSize: "30px",
                                      float: "right",
                                    }}
                                    onClick={() =>{
                                        let TuesdayTimings = [...this.state.TuesdayTimings];
                                        TuesdayTimings.splice(i, 1);
                                        this.setState({ TuesdayTimings });
                                        setTimeout(() => {  this.handleweekListChange() }, 500);
                                    }}
                                  ></i>
                                )}                          
                          </div>
                        </div>
                        ))}
                </div>
                <div className="col-md-12">
                  <hr/>
                </div>                
            </div>




            <div className="row">            
                <div className="col-md-12">                
                    <div className="row"> 
                      <div className="col-md-2 col-sm-4  text-center ml-auto">                 
                        {this.state.WednesdayTimings.map((x, i) => {
                            
                          return (
                            <>
                              {this.state.WednesdayTimings.length - 1 === i && (
                                <i
                                  className="fa fa-plus-square"
                                  style={{
                                    color: "green",
                                    fontSize: "30px",
                                    float: "right",
                                  }}
                                  // onClick={() => this.handleAddClick(i,this.state.WednesdayTimings)}
                                  onClick={() =>{
                                    this.setState(prevState => ({ 
                                          WednesdayTimings: [...prevState.WednesdayTimings,{Day:"Wednesday",From: "", To: ""}]
                                        }))
                                  }}
                                />
                              )}
                            </>
                          );
                        })}
                      </div>
                    </div>
                </div>                                
                <div className="col-md-3 col-4">
                  <div className="form-group">
                  
                    <input type="text"
                    style={{color:"black"}} 
                    name="Day" 
                    value="Wednesday"
                    className="form-control"/>
                    </div>
                </div>     
                <div className="col-md-9 col-8">
                    {this.state.WednesdayTimings.map((x,i) =>(
                        <div className="row">
                          <div className="col-md-5 col-6 col-sm-4">
                                <div className="form-group">                      
                                    <input type="time"
                                    style={{color:"black"}} 
                                    name="From" 
                                    value={x.From}                             
                                    onChange={(e) => this.handleInputChange(e, i,this.state.WednesdayTimings)}
                                    className="form-control"/>
                                </div>
                          </div>
                          <div className="col-md-5 col-6 col-sm-4">                          
                                <div className="form-group">                      
                                  <input type="time" 
                                  style={{color:"black"}}
                                  name="To" 
                                  value={x.To}
                                  onChange={(e) => this.handleInputChange(e, i,this.state.WednesdayTimings)} 
                                  className="form-control"/>
                                </div>
                          </div>
                          <div className="col-md-2 col-sm-4  text-center">
                                {this.state.WednesdayTimings.length > 1 && (
                                  <i
                                    className="fa fa-minus-square"
                                    style={{
                                      color: "green",
                                      fontSize: "30px",
                                      float: "right",
                                    }}
                                    onClick={() =>{
                                        let WednesdayTimings = [...this.state.WednesdayTimings];
                                        WednesdayTimings.splice(i, 1);
                                        this.setState({ WednesdayTimings });
                                        setTimeout(() => {  this.handleweekListChange() }, 500);
                                    }}
                                  ></i>
                                )}                          
                          </div>
                        </div>
                        ))}
                </div>
                <div className="col-md-12">
                  <hr/>
                </div>                
            </div>




            <div className="row">            
                <div className="col-md-12">                
                    <div className="row"> 
                      <div className="col-md-2 col-sm-4  text-center ml-auto">                 
                        {this.state.ThursdayTimings.map((x, i) => {
                            
                          return (
                            <>
                              {this.state.ThursdayTimings.length - 1 === i && (
                                <i
                                  className="fa fa-plus-square"
                                  style={{
                                    color: "green",
                                    fontSize: "30px",
                                    float: "right",
                                  }}
                                  // onClick={() => this.handleAddClick(i,this.state.ThursdayTimings)}
                                  onClick={() =>{
                                    this.setState(prevState => ({ 
                                          ThursdayTimings: [...prevState.ThursdayTimings,{Day:"Thursday",From: "", To: ""}]
                                        }))
                                  }}
                                />
                              )}
                            </>
                          );
                        })}
                      </div>
                    </div>
                </div>                                
                <div className="col-md-3 col-4">
                  <div className="form-group">
                  
                    <input type="text"
                    style={{color:"black"}} 
                    name="Day" 
                    value="Thursday"
                    className="form-control"/>
                    </div>
                </div>     
                <div className="col-md-9 col-8">
                    {this.state.ThursdayTimings.map((x,i) =>(
                        <div className="row">
                          <div className="col-md-5 col-6 col-sm-4">
                                <div className="form-group">                      
                                    <input type="time"
                                    style={{color:"black"}} 
                                    name="From" 
                                    value={x.From}                             
                                    onChange={(e) => this.handleInputChange(e, i,this.state.ThursdayTimings)}
                                    className="form-control"/>
                                </div>
                          </div>
                          <div className="col-md-5 col-6 col-sm-4">                          
                                <div className="form-group">                      
                                  <input type="time" 
                                  style={{color:"black"}}
                                  name="To" 
                                  value={x.To}
                                  onChange={(e) => this.handleInputChange(e, i,this.state.ThursdayTimings)} 
                                  className="form-control"/>
                                </div>
                          </div>
                          <div className="col-md-2 col-sm-4  text-center">
                                {this.state.ThursdayTimings.length > 1 && (
                                  <i
                                    className="fa fa-minus-square"
                                    style={{
                                      color: "green",
                                      fontSize: "30px",
                                      float: "right",
                                    }}
                                    onClick={() =>{
                                        let ThursdayTimings = [...this.state.ThursdayTimings];
                                        ThursdayTimings.splice(i, 1);
                                        this.setState({ ThursdayTimings });
                                        setTimeout(() => {  this.handleweekListChange() }, 500);
                                    }}
                                  ></i>
                                )}                          
                          </div>
                        </div>
                        ))}
                </div>
                <div className="col-md-12">
                  <hr/>
                </div>                
            </div>




            <div className="row">            
                <div className="col-md-12">                
                    <div className="row"> 
                      <div className="col-md-2 col-sm-4  text-center ml-auto">                 
                        {this.state.FridayTimings.map((x, i) => {
                            
                          return (
                            <>
                              {this.state.FridayTimings.length - 1 === i && (
                                <i
                                  className="fa fa-plus-square"
                                  style={{
                                    color: "green",
                                    fontSize: "30px",
                                    float: "right",
                                  }}
                                  // onClick={() => this.handleAddClick(i,this.state.FridayTimings)}
                                  onClick={() =>{
                                    this.setState(prevState => ({ 
                                          FridayTimings: [...prevState.FridayTimings,{Day:"Friday",From: "", To: ""}]
                                        }))
                                  }}
                                />
                              )}
                            </>
                          );
                        })}
                      </div>
                    </div>
                </div>                                
                <div className="col-md-3 col-4">
                  <div className="form-group">
                  
                    <input type="text"
                    style={{color:"black"}} 
                    name="Day" 
                    value="Friday"
                    className="form-control"/>
                    </div>
                </div>     
                <div className="col-md-9 col-8">
                    {this.state.FridayTimings.map((x,i) =>(
                        <div className="row">
                          <div className="col-md-5 col-6 col-sm-4">
                                <div className="form-group">                      
                                    <input type="time"
                                    style={{color:"black"}} 
                                    name="From" 
                                    value={x.From}                             
                                    onChange={(e) => this.handleInputChange(e, i,this.state.FridayTimings)}
                                    className="form-control"/>
                                </div>
                          </div>
                          <div className="col-md-5 col-6 col-sm-4">                          
                                <div className="form-group">                      
                                  <input type="time" 
                                  style={{color:"black"}}
                                  name="To" 
                                  value={x.To}
                                  onChange={(e) => this.handleInputChange(e, i,this.state.FridayTimings)} 
                                  className="form-control"/>
                                </div>
                          </div>
                          <div className="col-md-2 col-sm-4  text-center">
                                {this.state.FridayTimings.length > 1 && (
                                  <i
                                    className="fa fa-minus-square"
                                    style={{
                                      color: "green",
                                      fontSize: "30px",
                                      float: "right",
                                    }}
                                    onClick={() =>{
                                        let FridayTimings = [...this.state.FridayTimings];
                                        FridayTimings.splice(i, 1);
                                        this.setState({ FridayTimings });
                                        setTimeout(() => {  this.handleweekListChange() }, 500);
                                    }}
                                  ></i>
                                )}                          
                          </div>
                        </div>
                        ))}
                </div>
                <div className="col-md-12">
                  <hr/>
                </div>                
            </div>





            <div className="row">            
                <div className="col-md-12">                
                    <div className="row"> 
                      <div className="col-md-2 col-sm-4  text-center ml-auto">                 
                        {this.state.SaturdayTimings.map((x, i) => {
                            
                          return (
                            <>
                              {this.state.SaturdayTimings.length - 1 === i && (
                                <i
                                  className="fa fa-plus-square"
                                  style={{
                                    color: "green",
                                    fontSize: "30px",
                                    float: "right",
                                  }}
                                  // onClick={() => this.handleAddClick(i,this.state.SaturdayTimings)}
                                  onClick={() =>{
                                    this.setState(prevState => ({ 
                                          SaturdayTimings: [...prevState.SaturdayTimings,{Day:"Saturday",From: "", To: ""}]
                                        }))
                                  }}
                                />
                              )}
                            </>
                          );
                        })}
                      </div>
                    </div>
                </div>                                
                <div className="col-md-3 col-4">
                  <div className="form-group">
                  
                    <input type="text"
                    style={{color:"black"}} 
                    name="Day" 
                    value="Saturday"
                    className="form-control"/>
                    </div>
                </div>     
                <div className="col-md-9 col-8">
                    {this.state.SaturdayTimings.map((x,i) =>(
                        <div className="row">
                          <div className="col-md-5 col-6 col-sm-4">
                                <div className="form-group">                      
                                    <input type="time"
                                    style={{color:"black"}} 
                                    name="From" 
                                    value={x.From}                             
                                    onChange={(e) => this.handleInputChange(e, i,this.state.SaturdayTimings)}
                                    className="form-control"/>
                                </div>
                          </div>
                          <div className="col-md-5 col-6 col-sm-4">                          
                                <div className="form-group">                      
                                  <input type="time" 
                                  style={{color:"black"}}
                                  name="To" 
                                  value={x.To}
                                  onChange={(e) => this.handleInputChange(e, i,this.state.SaturdayTimings)} 
                                  className="form-control"/>
                                </div>
                          </div>
                          <div className="col-md-2 col-sm-4  text-center">
                                {this.state.SaturdayTimings.length > 1 && (
                                  <i
                                    className="fa fa-minus-square"
                                    style={{
                                      color: "green",
                                      fontSize: "30px",
                                      float: "right",
                                    }}
                                    onClick={() =>{
                                        let SaturdayTimings = [...this.state.SaturdayTimings];
                                        SaturdayTimings.splice(i, 1);
                                        this.setState({ SaturdayTimings });
                                        setTimeout(() => {  this.handleweekListChange() }, 500);
                                    }}
                                  ></i>
                                )}                          
                          </div>
                        </div>
                        ))}
                </div>
                <div className="col-md-12">
                  <hr/>
                </div>                
            </div>
















            <div className="row mt-4">            
                <div className="col-md-12">                
                    <div className="row"> 
                      <h6 className="pl-3"><strong>Unavailable</strong></h6>
                      <div className="col-md-2 col-sm-4  text-center ml-auto">                 
                          {this.state.unavailableTime.map((x, i) => {
                            return (
                              <>
                                {this.state.unavailableTime.length - 1 === i && (
                                  <i
                                    className="fa fa-plus-square"
                                    style={{
                                      color: "green",
                                      fontSize: "30px",
                                      float: "right",
                                    }}
                                    onClick={() =>{
                                      this.setState(prevState => ({ 
                                        unavailableTime: [...prevState.unavailableTime,{From: "", To: ""}]
                                          }))
                                    }}
                                  />
                                )}
                              </>
                            );
                          })}
                      </div>
                    </div>
                </div>
            </div>  




            {this.state.unavailableTime.map((x,i) =>(
            <div className="row">                              
                <div className="col-md-3 col-4">
                  <div className="form-group">
                      <input style={{color:"black"}} 
                      className="form-control" 
                      type="date" name="date" 
                      placeholder="dd-mm-yyyy"
                      value={x.date}
                      onChange={(e) => this.handleInputChangeUnavailable(e, i,this.state.unavailableTime)}
                      />
                  </div>
                </div>     
                <div className="col-md-9 col-8">
                        <div className="row">
                          <div className="col-md-5 col-6 col-sm-4">
                                <div className="form-group">                      
                                    <input type="time"
                                    style={{color:"black"}} 
                                    name="From" 
                                    value={x.From} 
                                    onChange={(e) => this.handleInputChangeUnavailable(e, i,this.state.unavailableTime)}
                                    className="form-control"/>
                                </div>
                          </div>
                          <div className="col-md-5 col-6 col-sm-4">                          
                                <div className="form-group">                      
                                    <input type="time" 
                                    style={{color:"black"}}
                                    name="To" 
                                    value={x.To}
                                    onChange={(e) => this.handleInputChangeUnavailable(e, i,this.state.unavailableTime)} 
                                    className="form-control"/>
                                </div>
                          </div>
                          <div className="col-md-2 col-sm-4  text-center">
                                {this.state.unavailableTime.length > 1 && (
                                    <i
                                      className="fa fa-minus-square"
                                      style={{
                                        color: "green",
                                        fontSize: "30px",
                                        float: "right",
                                      }}
                                        onClick={() =>{
                                            let unavailableTime = [...this.state.unavailableTime];
                                            unavailableTime.splice(i, 1);
                                            this.setState({ unavailableTime });
                                            setTimeout(() => {  this.handleweekListChange() }, 500);
                                        }}
                                        
                                    ></i>
                                  )}                          
                          </div>
                        </div>                     
                </div>                
            </div>
          ))}
    
   


    

        <div className="text-right mt-4 timeEntryAction">              
            <button className="defaultBtn" type="button" onClick={this.handleSubmitWeekSchedule} >Submit</button>
        </div>
    
        </div>
        )
    }
}
export default WeekList