import React, { Component } from "react";
import axios from "../../../config/axios";
import { Container, Row, Col, Modal, ModalDialog, Button, Form } from "react-bootstrap";

import { Link } from "react-router-dom";
import { Header, HeaderTop } from "../../../components/headers";
import { FooterLink } from "../../../components/footers";
import { Google, Facebook } from "../../auth/social";
// import { dArrowblack } from "../../../assets/images";
import validation from '../../../Validate/validator';
import message from '../../../utils/mesaages';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-input-2'
import Select from "react-select";
import { convertOffsetInMinutesToString, findTimeZone, TimeZoneSelectDialog } from 'react-timezone-map-select';
import ReCAPTCHA from "react-google-recaptcha";
import { CaptchaKey } from "../../../config/custom";

class CoachRegistration extends Component {
  constructor(props, context) {
    super(props, context);
    this.token = localStorage.getItem("authToken")
    this.userdata = localStorage.getItem("userdata")
    this.state = {
      recapcha : "",
      timeZonesAr : [],
      email: "",
      fastname: "",
      lastname: "",
      phone_number: "",
      password: "",
      RepeatPassword: "",
      coach_type: "",
      area_of_work: null,
      location: "",
      bio: "",
      socialLink: { facebook: "", twitter: "", linkedIn: "", instagram: "" },
      country: [],
      user_type: 'coach',
      trialtext: 1,
      getNoti: false,
      category: [],
      selectCategory: [],
      session_duraction: "",
      FromDataError: {
        recapcha : "false",
        fastname: "false",
        lastname: "false",
        email: 'false',
        phone_number: 'false',
        password: 'false',
        RepeatPassword: 'false',
        user_type: '',
        location: "",
        bio: "",
        selectCategory: "",
        coach_type: "",
        area_of_work: "",
        session_duraction: ""
      },
      socialState: true,
      countryName: 'us',
      acceptTerms: false,
      termsModal: false,
      visibility: true,
      termsdata: [],
      content: '',
      cont: '',
      link_text: '',
      inputArr: [{ type: "text", id: "facebook", name: "socialLink" }, { type: "text", id: "twitter", name: "socialLink" }, { type: "text", id: "linkedIn", name: "socialLink" }, { type: "text", id: "instagram", name: "socialLink" },],
      DEFAULT_TIME_ZONE_NAME: 'America/Los_Angeles (GMT-7:00)',
      open: false,
      timezone: "America/Los_Angeles (GMT-7:00)"
    };
  }


  capchaChange = (value) => {
    let { FromDataError } = this.state;
    FromDataError.recapcha = "";
    this.setState({ recapcha : value, FromDataError }, () => {
      this.validateForm(FromDataError);
    });

    let th = this;
      setTimeout(function() {
              FromDataError.recapcha = "false";
              th.setState({ recapcha : "", FromDataError }, () => {
              th.validateForm(FromDataError);
          });
      }, 120000); 
    
  };
  componentDidMount() {
    if (this.token && this.userdata) {
      if (JSON.parse(this.userdata).account_type !== "jobseeker") {
        window.location = '/profile-coach'
      } else if (JSON.parse(this.userdata).account_type === "jobseeker") {
        window.location = "/webinars"
      }
    }
    this.GetWebinarCategory()
    var queryString = window.location.search
    if (queryString) {
      localStorage.setItem('tracking_link', window.location.href)
    }
    else {
      console.log('notfound')
    }
    this.getGeoInfo()
  }

  async componentWillMount() {
    if (this.props.location.state !== undefined) {
      await this.setState({ user_type: this.props.location.state.accountType, trialtext: 1 })
    }
    this.getTermsContent()
  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {

        if (key !== 'user_type' && value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }
        else if (key === 'user_type' && value == 'false') {
          FromDataError[key] = 'Please select any user type to sign up';
          sta.setState({ FromDataError })
        }

        valid = false;
      }
    }

    return valid;
  };

  termsModal = (show) => {
    this.setState({ termsModal: show })
  }

  getTermsContent = () => {
    let self = this
    axios.get('/cmsfooter/footerContent')
      .then(async (response) => {
        if (response.status === 200) {
          self.setState({
            termsdata: response.data.value.terms_conditions[0],
            content: response.data.value.terms_conditions[0].content_json,
            cont: response.data.terms_conditionscont
          })

        }
      })
      .catch((e) => {
        console.log(e)
      })
    axios.get('/admin/getTimezone')
      .then(async (response) => {
        if (response.status === 200) {
          let timeZonesAr = [];
            for(let i=0;i < response.data.length; i++){
              timeZonesAr.push({ value: response.data[i].value, label: response.data[i].label, name: "timezone", visibility: true })
          }
          self.setState({
            timeZonesAr: timeZonesAr
          })

        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  AccetTerms = (term) => {
    this.setState({ acceptTerms: term })

    this.socialStateChange()
    this.termsModal(false)
  }

  handleChange = (e) => {
    let { FromDataError } = this.state
    e.persist();
    var valid_obj = {
      value: e.target.value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg")
    }
    validation(valid_obj).then((error) => {

      FromDataError[e.target.name] = error
      this.setState({ FromDataError })

      if (e.target.name === "password" || e.target.name === "RepeatPassword") {
        if (e.target.name === "password" && e.target.value !== this.state.RepeatPassword && this.state.RepeatPassword !== "") {
          FromDataError['RepeatPassword'] = "Password and confirm password do not match"
          this.setState({ FromDataError })
        }
        else if (e.target.name === "password" && e.target.value === this.state.RepeatPassword && this.state.RepeatPassword !== "") {
          FromDataError['RepeatPassword'] = ""
          this.setState({ FromDataError })
        }
        else if (e.target.name === "RepeatPassword" && e.target.value !== this.state.password && this.state.password !== "") {
          FromDataError['RepeatPassword'] = "Password and confirm password do not match"
          this.setState({ FromDataError })
        }
      }

      setTimeout(() => {
        if (e.target.name === 'user_type' && e.target.value === 'coach') {
          FromDataError['user_type'] = ""
          this.setState({ FromDataError })
          window.location.hash = "coach"
          this.setState({
            trialtext: 1,
          });
        } else if (e.target.name === 'user_type' && e.target.value === 'user') {
          FromDataError['user_type'] = ""
          this.setState({ FromDataError })
          window.location.hash = "user"
          this.setState({
            trialtext: 0,
          });
        }
        else {
          this.setState({
            [e.target.name]: e.target.value,
          });
        }

      }, 100);


    })



  };

  handleChangephone = (e, data, event, formattedValue) => {
    let FromDataError = this.state.FromDataError

    if ((e.length - data.dialCode.length) >= 10) {
      console.log('true')
      FromDataError.phone_number = ''
    } else {
      console.log('false')
      FromDataError.phone_number = 'Enter valid phone number'
    }

    this.setState({
      FromDataError
    });



    setTimeout(() => {
      this.setState({
        ['phone_number']: e,
      });

    }, 100);


  };

  handleSubmit = (e) => {

    e.preventDefault();
    if (this.validateForm(this.state.FromDataError)) {
      if (this.state.selectCategory.length === 0) {
        this.setState({ FromDataError: { ...this.state.FromDataError, selectCategory: "Please select atleast one talk category" } })
        return
      } else if (this.state.coach_type === "") {
        this.setState({ FromDataError: { ...this.state.FromDataError, coach_type: "Please select your coaching type" } })
        return
      } else if (this.state.area_of_work === null) {
        this.setState({ FromDataError: { ...this.state.FromDataError, area_of_work: "Please select your working area" } })
        return
      } 
      // else if (((parseInt(this.state.coach_type) === 2) || (parseInt(this.state.coach_type) === 3)) && (this.state.session_duraction === "")) {
      //   this.setState({ FromDataError: { ...this.state.FromDataError, session_duraction: "Please select your duration time" } })
      //   return
      // }
      this.setState({ loader: true });
      const hideMessage = message.loading('Please wait, we are saving your data ...', 0)
      var tracking_link = '';
      if (localStorage.getItem('tracking_link') !== undefined || localStorage.getItem('tracking_link') != null) {
        tracking_link = localStorage.getItem('tracking_link')
      }
      const formData = {
        fullname: `${this.state.fastname.trim() + " " + this.state.lastname.trim()}`,
        email: this.state.email,
        phone_number: this.state.phone_number,
        password: this.state.password,
        RepeatPassword: this.state.RepeatPassword,
        account_type: this.state.user_type,
        getNoti: this.state.user_type === 'user' ? this.state.getNoti : false,
        tracking_link: tracking_link,
        coaching_category: JSON.stringify(this.state.selectCategory),
        coach_type: this.state.coach_type,
        area_of_work: this.state.area_of_work.value,
        location: this.state.location,
        bio: this.state.bio,
        social_link: (JSON.stringify(this.state.socialLink) === "{}") ? "" : JSON.stringify(this.state.socialLink),
        duration: this.state.session_duraction,
        timezone: this.state.timezone
      };
      console.log(formData)
      axios.post("/signup", formData).then((response) => {
        hideMessage()
        console.log("responsee", response.data);
        if (response.data.success) {
          this.setState({ loader: false })
          let Uuid = response.data.userdata.uid;
          message.success(response.data.message);
          localStorage.removeItem('tracking_link')
          this.props.history.push({ pathname: "/thankyou", state: { user_t: Uuid, userData: response.data.userdata, }, });
        }
      })
        .catch((error) => {
          hideMessage()
          message.error(error.response.data.message)
          this.setState({ loader: false })
        });
    }
  }

  SetNotification(e) {
    if (this.state.getNoti) {
      this.setState({ getNoti: false })
    }
    else {
      this.setState({ getNoti: true })
    }
  }

  socialStateChange = (e) => {
    if (this.state.socialState === true) {
      this.setState({ socialState: false })
    } else {
      this.setState({ socialState: true })
    }
  }

  termsAndcondition = async () => {
    let { socialState, user_type, FromDataError } = this.state
    if (socialState === true) {
      message.error('Please select terms & conditions first');
    } else {
      if (user_type === -1) {
        FromDataError['user_type'] = 'Please select any user type to sign up';
        this.setState({ FromDataError })
        message.error('Please select any user type to sign up');
      }
      else {
        console.log('terms')
        await this.setState({ socialState: false })
      }
    }
  }

  getGeoInfo = () => {
    axios.get('https://ipapi.co/json/').then((response) => {
      let data = response.data;
      //console.log('dataaaaaaaaaaa cuntry', data)
      this.setState({
        countryName: data.country.toLowerCase(),
      });
    }).catch((error) => {
      console.log(error);
    });
  };

  handleCheckChange = async (e) => {
    let { FromDataError } = this.state
    var userdiv = document.getElementById("user_div");
    var coachdiv = document.getElementById("coach_div");
    if (e.target.name === 'user_type' && e.target.value === 'coach') {
      FromDataError['user_type'] = ""
      this.setState({ FromDataError })
      window.location.hash = "coach"
      await this.setState({
        trialtext: 1,
        link_text: 'Not a Coach?'
      });

      console.log('Link text 1', this.state.link_text)
      console.log('handle change 1', this.state.user_type)
    }
    else {
      FromDataError['user_type'] = ""
      this.setState({ FromDataError })
      window.location.hash = "user"
      await this.setState({
        trialtext: 0,
        link_text: 'Are you a Coach?'

      });
      console.log('Link text 2', this.state.link_text)
      console.log('handle change 2', this.state.user_type)
    }
    console.log("userType After checkboxChange", this.state.user_type)

  }

  GetWebinarCategory = async (e) => {
    await axios.get(`/webinar_categorie`).then((response) => {
      if (response.data.success) {
        this.setState({ category: response.data.deta })
      }
    }).catch((error) => {
      message.error(error.response.data.message)
    })
  }

  WebinarCategorySelect = (e) => {
    this.setState({ selectCategory: e, FromDataError: { ...this.state.FromDataError, selectCategory: "" } })
  }

  HandleSelectChange = (e) => {
    if (e.name === "coach_type") {
      this.setState({ visibility: e.visibility, session_duraction: "" })
      this.clearFilters()
    }
    this.setState({ [e.name]: e.value, FromDataError: { ...this.state.FromDataError, [e.name]: "" } });
  }

  selectAreaOfWork = (selectedarea) => {
    this.setState({ area_of_work: selectedarea, FromDataError: { ...this.state.FromDataError, area_of_work: "" } });
  }

  clearFilters() {
    this.setState({ area_of_work: null, })
  }

  addSocalLink = (e) => {
    this.setState({ [e.target.name]: { ...this.state.socialLink, [e.target.id]: e.target.value } }, () => {
      console.log(this.state.socialLink)
    })
  };

  handleOpen = () => {
    this.setState({ open: true })
    // setOpen(true);
  }

  handleClose = (newTimeZoneName) => {
    this.setState({ timezone: newTimeZoneName, open: false })
  };

  render() {
    const areaOfWork = [{ value: "0", label: "Coach Master (individual users)" }, { value: "1", label: "Corporate Coach Master" }, { value: "2", label: "Both" },]

    return (
      <div>
        <HeaderTop />
        <Header />
        <section className="registrationWrap">
          <div className="container">
            {/* <div className="row justify-content-center">
              <div className="col-lg-8">
                {this.state.trialtext === 1 ? (
                  <div className="freeTrial heading">
                    <h3>You are registering for a 90 day free trial</h3>
                  </div>
                ) : ''}
                <p className="text-center">
                  {this.state.user_type === 'user' ? (
                    <>Register today to join as a User. It's free to sign up. Your account will be created and we will notify you once Coach Master launches.</>) : this.state.user_type === 'coach' ? (<>Join today as a coach and create your profile page. It takes less than two minutes to sign up and create an account (no card details required)</>) : ""}
                </p>
              </div>
            </div> */}
            <div className="registrationContent">
              <div className="row ">
                <div className="col-md-4 otherRegistration">
                  {/* <div className="otherRegistrationFilds">
                    <h3 className="text-center">
                      Sign Up with social media
                    </h3>
                    <span className="R_divider mx-auto d-block"></span>
                    <ul className="text-center">
                      <Facebook usertype={this.state.user_type} termsandcondition={this.termsAndcondition} buttonstatus={this.state.socialState} />
                      <br />
                      <Google usertype={this.state.user_type} termsandcondition={this.termsAndcondition} buttonstatus={this.state.socialState} />
                    </ul>
                  </div> */}
                </div>
                <div className="col-md-8">
                  <div className="registrationForm">
                    <h3>Sign Up</h3>
                    <div className="form-group mb-4">
                      <div className="areas d-flex">
                        <div className="custom-control custom-checkbox checkbox-inline mx-2">
                          <input checked={this.state.user_type === 'coach' ? true : false} type="radio" className="custom-control-input" id="customCheck7"
                            value="coach" onChange={this.handleCheckChange} name="user_type" />
                          <label className="custom-control-label" for="customCheck7"> Sign up as Coach</label>
                        </div>
                      </div>
                    </div>

                    <Form onSubmit={this.handleSubmit} hidden={this.state.user_type === 'coach' ? false : true}>
                      <Row>
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label>First Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" onChange={this.handleChange} name="fastname" required={true} validaterule={['required']} validatemsg={['Please enter your first name']} pattern="[a-zA-Z]*" />
                            <p style={{ color: 'red' }}>{this.state.FromDataError.fastname === 'false' ? '' : this.state.FromDataError.fastname}</p>
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label>Last Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" onChange={this.handleChange} name="lastname" required={true} validaterule={['required']} validatemsg={['Please enter your last name']} pattern="[a-zA-Z]*" />
                            <p style={{ color: 'red' }}>{this.state.FromDataError.lastname === 'false' ? '' : this.state.FromDataError.lastname}</p>
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group>
                            <Form.Label>Email address <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="email" onChange={this.handleChange} required={true} name="email" validaterule={['required', 'isEmail']} validatemsg={['Your email address is required', 'Enter a valid email address']} />
                            <p style={{ color: 'red' }}>{this.state.FromDataError.email === 'false' ? '' : this.state.FromDataError.email}</p>
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                          <Form.Group>
                            <Form.Label>Phone Number <span className="text-danger">*</span></Form.Label>
                            <PhoneInput name="phone_number" specialLabel={''} required={true} country={this.state.countryName} countryCodeEditable={false} inputClassName="form-control phone-reg" placeholder="Phone Number" onChange={this.handleChangephone} validaterule={['required', 'phone']} validatemsg={['Phone number is required', 'Please enter a valid phone number']} />
                            <p style={{ color: 'red' }}>{this.state.FromDataError.phone_number === 'false' ? '' : this.state.FromDataError.phone_number}</p>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <div className="d-flex justify-content-between align-items-center text-center">
                              <Form.Label>Time Zone <span className="text-danger">*</span></Form.Label>
                              <span style={{ "fontSize": "10px" }} className="text-warning">Want to change your time zone?  click on input box</span>
                            </div>

                            <Select className="col px-0" options={this.state.timeZonesAr} onChange={this.HandleSelectChange} />
                            {/* <Form.Control type="text" onClick={this.handleOpen} value={this.state.timezone} /> */}
                            {/* <Button onClick={this.handleOpen} id="location"/> */}
                            {/* <p style={{ color: 'red' }}>{this.state.FromDataError.phone_number === 'false' ? '' : this.state.FromDataError.phone_number}</p> */}
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                          <Row>
                            <Col md={6}>
                              <Form.Group>
                                <Form.Label>Available For <span className="text-danger">*</span></Form.Label>
                                <Select className="col px-0" options={[
                                  { value: "1", label: "Webinars (1:many)", name: "coach_type", visibility: true },
                                  { value: "2", label: "Sessions (1:1)", name: "coach_type", visibility: true },
                                  { value: "3", label: "Both", name: "coach_type", visibility: true },
                                  // { value: "2", label: "Sessions (1:1)", name: "coach_type", visibility: false },
                                  // { value: "3", label: "Both", name: "coach_type", visibility: false },
                                ]} onChange={this.HandleSelectChange} />
                              </Form.Group>
                              <p style={{ color: 'red' }}>{this.state.FromDataError.coach_type !== '' && this.state.FromDataError.coach_type}</p>
                            </Col>
                            <Col md={6} hidden={this.state.visibility}>
                              <Form.Group>
                                <Form.Label>Session Duration (in minutes) <span className="text-danger">*</span></Form.Label>
                                <Select className="col px-0" options={[
                                  { value: "15", label: "15", name: "session_duraction" },
                                  { value: "30", label: "30", name: "session_duraction" },
                                  { value: "45", label: "45", name: "session_duraction" },
                                  { value: "60", label: "60", name: "session_duraction" },
                                ]} onChange={this.HandleSelectChange} />
                                <p style={{ color: 'red' }}>{this.state.FromDataError.session_duraction !== '' && this.state.FromDataError.session_duraction}</p>
                                {/* <Form.Control type="text" onChange={this.handleChange} name="session_duraction" required={!this.state.visibility} /> */}
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={12}>
                          <Form.Group>
                            <Form.Label>Categories<span className="text-danger">*</span></Form.Label>
                            <Select isMulti className="col px-0" options={this.state.category} onChange={this.WebinarCategorySelect} />
                          </Form.Group>
                          <p style={{ color: 'red' }}>{this.state.FromDataError.selectCategory !== "" && this.state.FromDataError.selectCategory}</p>
                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>Registering For <span className="text-danger">*</span></Form.Label>
                            {
                              (parseInt(this.state.coach_type) === 2) ? (
                                <Select value={this.state.area_of_work} className="col px-0" options={[
                                  { value: "0", label: "Coach Master", name: "area_of_work" },
                                ]} onChange={this.selectAreaOfWork} placeholder='Select ...' />
                              ) : (
                                <Select value={this.state.area_of_work} onChange={this.selectAreaOfWork} className="col px-0" options={areaOfWork} placeholder='Select ...' />
                              )
                            }
                          </Form.Group>
                          <p style={{ color: 'red' }}>{this.state.FromDataError.area_of_work !== "" && this.state.FromDataError.area_of_work}</p>
                          <span style={{color : "blue"}}>{
                            +this.state.area_of_work?.value == 0 ? 
                            "For individuals seeking personal development"
                            // "Personalized one-on-one coaching for job seekers and individuals seeking personal development"
                            : 
                            +this.state.area_of_work?.value == 1 ? "Empower organizations and their workforce through your tailored webinars and coaching programs" : ""
                          }
                          </span>  
                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>Location <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" onChange={this.handleChange} name='location' required={true} validaterule={['required']} validatemsg={['Please enter your location']} />
                            <p style={{ color: 'red' }}>{this.state.FromDataError.location !== '' && this.state.FromDataError.location}</p>
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                          <Form.Group>
                            <Form.Label>Bio <span className="text-danger">*</span></Form.Label>
                            <Form.Control as="textarea" rows={3} onChange={this.handleChange} name="bio" required={true} validaterule={['required']} validatemsg={['please tell us something about you']} />
                          </Form.Group>
                          <p style={{ color: 'red' }}>{this.state.FromDataError.bio !== '' && this.state.FromDataError.bio}</p>
                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>Create password <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="password" onChange={this.handleChange} required={true} name="password" validaterule={['required', 'password']} validatemsg={['Password field is required', 'Password length must be at least 6 characters']} />
                            <p style={{ color: 'red' }}>{this.state.FromDataError.password === 'false' ? '' : this.state.FromDataError.password}</p>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>Repeat password <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="password" onChange={this.handleChange} required={true} name="RepeatPassword" validaterule={['required', 'same|' + this.state.password]} validatemsg={['Confirm your password field', 'Password and confirmed password do not match']} />
                            <p style={{ color: 'red' }}>{this.state.FromDataError.RepeatPassword === 'false' ? '' : this.state.FromDataError.RepeatPassword}</p>
                          </Form.Group>
                        </Col>
                      </Row>

                      <div className='form_item mt-4'>
                        <h4 className="regf_title">Social Links</h4>
                        <Row>
                          {
                            this.state.inputArr.map((element) => (
                              <Col md={4}>
                                <Form.Group>
                                  <Form.Label className="text-capitalize">{element.id}</Form.Label>
                                  <div className='sc_input'>
                                    <Form.Control type={element.type} id={element.id} name={element.name} onChange={this.addSocalLink} />
                                  </div>
                                </Form.Group>
                              </Col>

                            ))
                          }
                        </Row>
                      </div>

                      <Form.Group className="my-4">
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="terms" name="terms" required={true} checked={this.state.acceptTerms} onClick={() => this.AccetTerms(!this.state.acceptTerms)} />
                          <label className="custom-control-label" for="terms">
                            I accept the{" "}
                            <Link to='/#' onClick={(e) => { e.preventDefault(); this.termsModal(true) }} data-toggle="modal" data-target="#bannerformmodal">
                              Terms & Conditions
                            </Link>.
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="notification" name="notification" value={this.state.getNoti} onChange={() => { this.SetNotification(this) }} />
                          <label className="custom-control-label" for="notification">Receive free Coach Master newsletters</label>
                        </div>
                      </Form.Group>

                      <Form.Row className="col-12">
                      <Form.Group
                        controlId="formBasicEmail"
                        as={Col}
                        className="mt-2"
                      >
                        <ReCAPTCHA
                              sitekey={CaptchaKey}
                              onChange={this.capchaChange}
                            />

                        <p style={{ color: "red" }}>
                          {this.state.FromDataError.recapcha == "false"
                            ? ""
                            : this.state.FromDataError.recapcha}
                        </p>
                      </Form.Group>
                    </Form.Row>

                      <Form.Group>
                        <Button variant="default" disabled={this.state.loader} className="defaultBtn" type="Submit">Create Account</Button>
                      </Form.Group>

                      <p className="mt-3 loginLink">
                        Have an account?&nbsp;&nbsp;
                        <Link to="/login">
                          <b>LOGIN</b>
                        </Link>
                      </p>

                    </Form>

                    <Form hidden={this.state.user_type === 'user' ? false : true}>
                      <p className="text-center font-weight-bold" style={{ "fontSize": "26px", "color": "#00e1ba" }}>Coming Soon</p>
                    </Form>
                  </div>
                  {/* <div className="logreg_or">OR</div> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="modal fade bannerformmodal" id="bannerformmodal" tabIndex="-1" role="dialog" aria-labelledby="bannerformmodal" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenterTitle" style={{ color: "black" }}>
                  Terms and Conditions
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <ol className="list-unstyled">
                  <li>
                    <small style={{ color: "black" }}>
                      <span className="text-primary text-small">1. </span>
                      Accept terms and Condition
                    </small>
                  </li>
                  <li>
                    <small style={{ color: "black" }}>
                      <span className="text-primary text-small">2. </span>
                      Accept terms and Condition
                    </small>
                  </li>
                </ol>
              </div>
              <div className="modal-footer">
                <button type="button" disabled={this.state.loader} className="btn btn-secondary" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <Modal aria-labelledby="example-custom-modal-styling-title" show={this.state.termsModal} onHide={() => this.termsModal(false)} dialogClassName="modal-60w" backdrop="static">
          <div className="modal-content" style={{ background: "#f5f5f5", padding: "10px" }}>
            <div className="modal-header" style={{ paddingLeft: "0px" }}>
              <h5 className="modal-title" style={{ color: "black" }}>
                Terms & Conditions
              </h5>
            </div>
            <div className="modal-body">  <div className="text-left" style={{ color: 'black' }} dangerouslySetInnerHTML={{ __html: this.state.cont }} /></div>
            <div className="modal-footer">
              <Button className="btn btn-success" onClick={() => this.AccetTerms(true)}>Accept</Button>
              <Button className="btn btn-danger" onClick={() => this.termsModal(false)}>Decline</Button>
            </div>
          </div>
        </Modal>
        <TimeZoneSelectDialog open={this.state.open} timeZoneName={this.state.timezone} onClose={this.handleClose} />
        <FooterLink />
      </div>
    );
  }
}
export default CoachRegistration;
