import React from "react";
import { Header, HeaderLogout, HeaderTop } from "../components/headers";
import { FooterLink } from "../components/footers";

class Testfile extends React.Component {
  constructor() {
      super()
      this.token = localStorage.getItem("authToken");

      this.state = {
      }
  }

  
  

  render() {
    return (
      <React.Fragment>
            {localStorage.getItem("authToken") == null ? (
            <HeaderTop />
            ) : (
            <HeaderLogout
            currencies={this.state.currencies}
            convertToCurrency={this.state.convertToCurrency}
            changeConvertToCurrency={this.changeConvertToCurrency}
            />
            )}

            <Header />        
                <section className="thankYouWrap">
                    <div className="container">
                        <div className="row justify-content-center">
                            <h1 style={{color: "#28a745"}}>ff</h1>                            
                        </div>
                        
                    </div>
                </section>
          
          <FooterLink />
      </React.Fragment>
    );
  }
}

export default Testfile;
