import React, { Component } from "react";
import axios from "../config/axios";



class TagsInput extends React.Component {

    constructor(props) {
        super();
        this.token = localStorage.getItem("authToken");
        this.state = {
            tags: [],
            input: "",
            suggestions: []

        };
    }

    componentWillMount() {
        //console.clear();
        console.log(this.props)
        if (this.props.hasOwnProperty('setinputtagg') && this.props.setinputtagg != undefined &&  this.props.setinputtagg !== null) {
           
        
                console.log('enter' )

            this.setState({ tags: this.props.setinputtagg })
        }

    }

    handleChange = e => {
        this.gettagsdatafromapi(e.target.value)
        const { value } = e.target;
        this.setState({
            input: value
        });
        console.log('kkkkk',this.state.input)
    };

    gettagsdatafromapi = async (key) => {
        let self = this
        return new Promise(resolve => {
            axios.post("/consumer/filterSkills", { tags: key })
                .then(res => {
                    console.log("Res-", res)
                    let tags = res.data.map((d, i) => ({ id: i, text: d }));
                    tags = tags.slice(0, 10);
                    this.handleSuggestion(tags)
                })
                .catch(err => {
                    console.log(err)
                })

        });
    }

    handleKeyDown = e => {
        console.log("I m here", e.keyCode)
        if (e.keyCode === 9 || e.keyCode === 13) {
            e.preventDefault();
        }
        const { tags, input, suggestions } = this.state;
        const text = suggestions.length ? suggestions[0].text : input;
        if ([9, 13].includes(e.keyCode) && text) {
            this.setState({
                tags: [...tags, text],
                input: ""
            });
            this.props.setinputtag(text)
        }
    };

    handleSuggestion = (d) => {
        const { input, tags } = this.state;

        console.log('ee1')



        const suggestFilterInput = d.filter(suggest =>
            suggest.text.toLowerCase().includes(input.toLowerCase())
        );

        const suggestFilterTags = suggestFilterInput.filter(
            suggest => !tags.includes(suggest.text)
        );

        this.setState({
            suggestions: suggestFilterTags
        });
    };

    handleDelete = i => {
        const { tags } = this.state;
        const newTags = tags.filter((tag, j) => i !== j);
        this.setState({
            tags: newTags
        });
        this.props.deleteinputtag(i)
    };

    AddTags = text => {
        this.setState({
            tags: [...this.state.tags, text],
            input: ""
        });
        this.props.setinputtag(text)

    };

    render() {
        const { tags, input, suggestions } = this.state;


        return (



            <div className="tags-content">

                {/* {tags !== undefined ? 
            <div>
                   {tags.map((tag, i) => (
                    <div key={i} className="tag">
                        {tag}
                        <div className="remove-tag" onClick={() => this.handleDelete(i)}>
                            ×
            </div>
                    </div>
                ))}
                </div>
           
             :''} */}
                {tags.map((tag, i) => (
                    <div key={i} className="tag">
                        {tag}
                        <div className="remove-tag" onClick={() => this.handleDelete(i)}>
                            ×
            </div>
                    </div>
                ))}

                <div className="tags-input">
                    <input
                        type="text"
                        value={input}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        placeholder="Type your keywords"
                        
                    />
                    {input && Boolean(suggestions.length) && (
                        <div className="tags-suggestions">
                            {suggestions.map(suggest => (
                                <div
                                    className="suggestion-item"
                                    onClick={() => this.AddTags(suggest.text)}
                                >
                                    {suggest.text}
                                </div>
                            ))}
                        </div>
                    )}
                </div>



            </div>


        );
    }
}

export default TagsInput;
