import React,{ Component } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";
import axios from '../../../config/axios';
import Calendar from 'react-calendar';

import "../../../assets/css/style.css";


class UpdateSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: "",
      setShow: true,
      tags: "",
         // avatar_path: avatar,
          selectedSlot:'',
          sessionDate:"2021-01-28",
          slots:[],
          currMonth: moment().format('MMMM'),
          currDate: moment().format('YYYY-MM-DD'),
          date: new Date()
    };
  }

  componentDidMount(){

   //console.clear();
    //console.log('ddddddd ', moment(this.props.sessionDate).format('YYYY-MM-DD'))
   
  
  //this.getAvailability()
  }

  // componentDidUpdate()
  // {
  //   this.setState({date:new Date(this.props.sessionDate),
  //     currDate:moment(this.props.sessionDate).format('YYYY-MM-DD')
  //   },()=>{
  //     setTimeout(this.getAvailability(), 1200);
  //   })

  // }
  

  async getAvailability(callstate=null){
    
    if(callstate!=='change')
    {
      await this.setState({date:new Date(this.props.sessionDate),
          currDate:moment(this.props.sessionDate).format('YYYY-MM-DD')
        },()=>{
        // setTimeout(this.getAvailability(), 1200);
        })
    }
  
     console.log('hh')
    let id=this.props.coachUid
    let from={coach_id:id,pack_id:this.props.package_uid,date:this.state.currDate,sessionType:this.props.sessionType,duration:this.props.duration}
    console.log('form data ',from)
    axios.post(`/coach/setavailability/filtered`,from)
    
          .then(res =>{
            console.log("availabity---res--",res)
            if(res.data.success){
             this.setState({slots:res.data.value})
             //toast("Slots Found", { type: "success" });

            }
            if(res.data.value.length<=0)
            {
              toast(`No slots avalable on ${this.state.currDate}`, { type: "error" });

            }else
            {
              toast("Slots Found", { type: "success" });
            }
            // console.log("resss---vail",res)
          })
          .catch(err =>{
            console.log(err)
            toast("Failed! Something Went Wrong", { type: "error" });

          })
        }

    

  

  dateChange = (date)=>{
      
    this.setState({date,currDate:moment(date).format('YYYY-MM-DD')},
      ()=>{
        console.log('date',date)
        console.log('formatdate',moment(date).format('YYYY-MM-DD'))
        this.getAvailability('change')
      })

  }

  handleClose(){
    //this.props.close=this.props.close;
  }

  chooseSlot = (e) =>{
    console.log(e.target.value.from)
    let v = e.target.value
    let from = v.split(' ')[0]
    let to = v.split(' ')[1]
    console.log(v)
    console.log(from)
    console.log(to)
    if(from != undefined && to != undefined)
    {
      let selectedSlot = {date:this.state.currDate,from:from,to:to}
      this.setState({selectedSlot})
    }

  }


  handleSubmit = (e) => {
    e.preventDefault();
   console.log('save ',this.state.selectedSlot)
   let id=this.props.coachUid
   let from={coach_id:id,pack_id:this.props.package_uid,date:this.state.currDate,sessionType:this.props.sessionType,
    user_uid:this.props.user_uid,selectedSlot:this.state.selectedSlot
  }
   console.log(from)
   axios.post(`/users/session/update-booking`,from)
   
         .then(res =>{
         
           if(res.data.success){
           
            toast("Slots update successfully", { type: "success" });
            setTimeout(() => {
              window.location.reload()
              
            }, 3000);

           }
           
           // console.log("resss---vail",res)
         })
         .catch(err =>{
           console.log(err)
           toast("Failed! Something Went Wrong", { type: "error" });

         })
    
  };

  render() {
    return (
      <>
        <h3
          className="popup-with-zoom-anim edit_item"
          data-toggle="tooltip"
          data-placement="top"
          title="Add/Update New Tag"
          onClick={this.handleShow}
          style={{color: "#734d9d"}}
        >
          {/* MANAGE TAGS<img width="18px" src={writingSvg} alt="LG-Img" /> */}
        </h3>

        <Modal
          show={this.props.show}
          onHide={this.props.close}
          backdrop="static"
          keyboard={false}
          onShow={()=>this.getAvailability()}
        >
          <Form onSubmit={this.props.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title style={{color:"black"}}>Change Date & Time Schedule <br/>
             </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group style={{color:"black"}}>
                
                
                <div className="md-form">
            <Calendar
            onChange={this.dateChange}
            value={this.state.date}
            
            />
                </div>

                <div className="">
          <div className="areas areasNew">
            {this.state.slots.length>0? (
              this.state.slots.map((slot,index)=>{

                return(<>
                    <div className="custom-control custom-checkbox checkbox-inline mx-1" >
                      <input type="radio" onChange={this.chooseSlot} value={slot.from.split(" ")[1]+' '+slot.to.split(" ")[1]} disabled={slot.booked !== undefined? slot.booked : false}  className="custom-control-input" id={index} name="Sundays"/>
                      <label className="custom-control-label" for={index}>{slot.from.split(" ")[1]} - {slot.to.split(" ")[1]}</label>
                    </div>
                    </>
                )
              })

            ):(<> <div className="custom-control custom-checkbox checkbox-inline mx-1">
            
            Unavalable
          </div></>)}
           
          </div>
        </div>

              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
            <Button variant="primary" onClick={this.props.close}>
                Close
              </Button>
              
              <Button
                variant="success"
                type="submit"
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}
export default UpdateSchedule;
