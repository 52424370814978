import React from "react";
import { Link } from "react-router-dom";
import { t } from "../../assets/images";

class LandingPageTestimonial extends React.Component {
  constructor(props) {
    super(props);
   
  }
  render() {
    return (
      <div>
        <section className="testimonialWrap" style={{ display: "none" }}>
          <div className="container">
            <div
              id="testimonial"
              className="carousel slide carousel-fade"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-md-5">
                      <div className="heading text-lef">
                        <h2>Testimonials</h2>
                        <span className="R_divider"></span>
                      </div>
                      <div className="aboutText">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida. Risus commodo viverra maecenas accumsan lacus
                          vel facilisis. Quis ipsum suspendisse ultrices
                          gravida.
                          <br />
                          <br />
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida.
                        </p>
                        <Link to="/comming-soon" className="defaultBtn">
                          Read More
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-5 text-center">
                      <img alt="LG-Img" src={t} className="img-fluid" />
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-md-5">
                      <div className="heading text-left">
                        <h2>Testimonials</h2>
                        <span className="R_divider"></span>
                      </div>
                      <div className="aboutText">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida. Risus commodo viverra maecenas accumsan lacus
                          vel facilisis. Quis ipsum suspendisse ultrices
                          gravida.
                          <br />
                          <br />
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida.
                        </p>
                        <Link to="/comming-soon" className="defaultBtn">
                          Read More
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-5 text-center">
                      <img alt="LG-Img" src={t} className="img-fluid" />
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-md-5">
                      <div className="heading text-lef">
                        <h2>Testimonials</h2>
                        <span className="R_divider"></span>
                      </div>
                      <div className="aboutText">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida. Risus commodo viverra maecenas accumsan lacus
                          vel facilisis. Quis ipsum suspendisse ultrices
                          gravida.
                          <br />
                          <br />
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida.
                        </p>
                        <Link to="/comming-soon" className="defaultBtn">
                          Read More
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-5 text-center">
                      <img alt="LG-Img" src={t} className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
              {/* <Link
                to="javascript:void(0);"
                className="carousel-control-prev"
                data-target="#testimonial"
                // href="javascript:void(0);"
                data-slide="prev"
              >
                <img src={sArrow} alt="LG-Img" />
              </Link>
              <Link
                to="javascript:void(0);"
                className="carousel-control-next"
                data-target="#testimonial"
                data-slide="next"
              >
                <img src={sArrow} alt="LG-Img" />
              </Link> */}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default LandingPageTestimonial;
