import React from "react";
import { Link } from "react-router-dom";
import { search, p1, p2, p3 } from "../../assets/images";

class PodoCasts extends React.Component {
  constructor(props) {
    super(props);
   
  }
  render() {
    return (
      <div>
        <section className="podcastsWrap">
          <div className="container">
            <div className="heading text-center">
              <h3>podcasts</h3>
              <p className="">
                Anytime, anywhere access to life-enriching podcasts from the
                world’s best coaches and leading experts
              </p>
              <span className="R_divider"></span>
            </div>

            <div className="podcastsSearch">
              <input
                className="form-control"
                type="search"
                name=""
                placeholder="Search Podcasts"
              />
              <img width="25px" alt="LG-Img" src={search} />
            </div> 
            <div className="clearfix"></div>
            <div className="podcastsItem">
              <div className="owl owl-carousel podcasts-carousel owl-theme">
                <div className="item">
                  <div className="podcastsContent">
                    <img src={p1} alt="LG-Img" className="img-fluid" />
                    <h4>
                      <Link to="/comming-soon">How To Charge More?</Link>
                    </h4>
                    <span>
                      October 1st, 2019 | Podcast, Transformational Coaching
                    </span>
                    <p>
                      Also Available On Podcast Transcript Lorem ipsum dolor sit
                      amet, consectetur adipiscing elit. Sed nec pellentesque
                      purus. Nunc finibus urna eget est molestie, non dignissim
                      nulla cursus. Proin hendrerit.
                      <Link to="/comming-soon">More...</Link>
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="podcastsContent">
                    <img src={p2} alt="LG-Img" className="img-fluid" />
                    <h4>
                      <Link to="/comming-soon">How To Charge More?</Link>
                    </h4>
                    <span>
                      October 1st, 2019 | Podcast, Transformational Coaching
                    </span>
                    <p>
                      Also Available On Podcast Transcript Lorem ipsum dolor sit
                      amet, consectetur adipiscing elit. Sed nec pellentesque
                      purus. Nunc finibus urna eget est molestie, non dignissim
                      nulla cursus. Proin hendrerit.
                      <Link to="/comming-soon">More...</Link>
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="podcastsContent">
                    <img src={p3} alt="LG-Img" className="img-fluid" />
                    <h4>
                      <Link to="/comming-soon">How To Charge More?</Link>
                    </h4>
                    <span>
                      October 1st, 2019 | Podcast, Transformational Coaching
                    </span>
                    <p>
                      Also Available On Podcast Transcript Lorem ipsum dolor sit
                      amet, consectetur adipiscing elit. Sed nec pellentesque
                      purus. Nunc finibus urna eget est molestie, non dignissim
                      nulla cursus. Proin hendrerit.
                      <Link to="/comming-soon">More...</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default PodoCasts;
