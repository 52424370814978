import React from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import CardSection from './CardSection';
import axios from '../../../config/axios';
import { message } from 'antd';

export default function CheckoutForm(props) {
    
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        
        if(Object.keys(props.selectedPlan).length === 0 && props.selectedPlan.constructor === Object){
            message.error("Please select the a plan first");
            return;
        }
        
        let  userdata = localStorage.getItem('userdata');
        userdata = JSON.parse(userdata);
        let user_id = userdata.id;
        props.setShowLoader(true);
        await stripe.createToken(elements.getElement(CardElement)).then(async (result) => {
            props.setShowLoader(false);
            if (result) {
                // console.log("result",result);
                if (!document.querySelector(".StripeElement").classList.contains("StripeElement--empty") || !document.querySelector(".StripeElement").classList.contains("StripeElement--invalid")) {
                    document.getElementById("submit_button").setAttribute("disabled", true)
                    const re = await stripe.createToken(elements.getElement(CardElement));
                    await axios.post(`/users/charge-jobseeker`, { user_id : user_id , email: userdata.email, name: userdata.name, token: result.token,token2: re.token,amount : props.totalAmount,product_id : props.selectedPlan.product_id}, {headers: { "X-Auth": localStorage.getItem("authToken")}}).then((response) => {
                        if (response.data.success) {
                            message.success(response.data.message)
                            document.getElementById("submit_button").removeAttribute("disabled")
                            localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
                            setTimeout(function(){
                                window.location.href ="/webinars";
                            },2000)
                        }else{
                            document.getElementById("submit_button").removeAttribute("disabled")
                            message.error(response.data.message)
                        }
                    }).catch((error) => {
                        props.setShowLoader(false);
                        message.error(error?.response?.data?.error?.raw.message)
                    })
                }
            }
        }).catch((error) => {
            props.setShowLoader(false);
            console.log(error)
            message.error("Something Wrong")
        })
    };

    return (
        <>
        {
            Object.keys(props.selectedPlan).length > 0 && props.selectedPlan.constructor === Object  && 
            <><p style={{"color":"orange"}}>You have selected <b>{props.selectedPlan.type}</b> plan and you will be charged <b>${props.selectedPlan.offer_price} </b></p>
            <p>{props.selectedPlan.description}</p></>
        }
        <form onSubmit={handleSubmit}>
            <CardSection />
            <div className='text-center'>
            <Button
  variant="default"
  id="submit_button"
  type="Submit"
  style={{
    "background-color": "#FF8C00",
    "border": "navajowhite",
    "color": "#fff"
  }}
  disabled={Object.keys(props.selectedPlan).length === 0 && props.selectedPlan.constructor === Object }
>
  Subscribe
</Button>
            </div>
        </form>
        </>
    );
}