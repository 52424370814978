import React from "react";
import OwlCarousel from "react-owl-carousel";
import { closeSvg, user1 } from "../../../assets/images/index";
import feedback from "./feedback";
import axios from "../../../config/axios";
import { toast } from "react-toastify";
import { avatar } from "../../../assets/img";
import FeedbackModal from './FeedbackModal'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};


class ClientFeedback extends React.Component {

  constructor(props)
  {
    super(props)
    this.state = {
      coachID:'',
      feedbacks:[],
      FeedbackModal:false,
      details:'',
      carouselLoop:false,
      responsive :{
        0: {
            items: 1,
        },
        450: {
            items: 2,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 3,
        },
     }


    }
  }
  componentDidMount()
  {
    console.log('props',this.props)

    if(this.props.coachUid)
    {
      let userdata = localStorage.getItem('userdata')

    console.log('aaaaaaaaaaa',userdata)
    
    this.setState({coachID:this.props.coachUid},()=>{

      if(this.props.show==2)
      {
        let responsive ={
                        0: {
                            items: 1,
                        },
                        450: {
                            items: 1,
                        },
                        600: {
                            items: 2,
                        },
                        1000: {
                            items: 2,
                        },
                    }
                    this.setState({responsive})
      }


      this.feedbacks()
    })

    }
    

  }

  componentWillReceiveProps(newProps,prevProps)
  {
     console.log('sanyalsanyalsanyalsanyal',newProps.coachUid)

    if(newProps.coachUid)
    {
      let userdata = localStorage.getItem('userdata')

    console.log('aaaaaaaaaaa',userdata)
    
    this.setState({coachID:newProps.coachUid},()=>{
      if(this.props.show==2)
      {
        let responsive ={
                        0: {
                            items: 1,
                        },
                        450: {
                            items: 1,
                        },
                        600: {
                            items: 2,
                        },
                        1000: {
                            items: 2,
                        },
                    }
                    this.setState({responsive})
      }


      this.feedbacks()
    })

    }
    

  }

  feedbacks()
  {
    // ${this.props.coachUid}
      axios.get(`/users/feedbackList/${this.props.coachUid}`).then((res)=>{
        if(res.data.success)
        {
          // console.log('feedback data',res.data.value)
            this.setState({feedbacks:res.data.value})
            if(res.data.value && res.data.value.length>3)
            {
              this.setState({carouselLoop:true})
              
            }
        }
  
      }).catch((err)=>{
  
      })

    

   
    

  }

  stars(star)
  {
   
    let html = ''
    let y = [1,2,3]
  
        
  
       

      

    

    

  }


  handaleModal(details)
  {
    if(this.state.FeedbackModal)
    {
      this.setState({FeedbackModal:false,details})
    }
    else{
      this.setState({FeedbackModal:true,details})

    }

  }


  responsive ={
                0: {
                    items: 1,
                },
                450: {
                    items: 2,
                },
                600: {
                    items: 3,
                },
                1000: {
                    items: 3,
                },
             }

  render() {
    let {feedbacks} = this.state
    return (
      <div>
        <section className="feedbackWrap">
          <div className="container">
            {feedbacks.length > 0 ? (
              <div className="heading text-center">
              <h3>Client Feedback</h3>
              <span className="R_divider"></span>
            </div>
            ) : ""}

            {feedbacks.length>0?(<>

             <OwlCarousel className="owl-carousel feedback owl-theme"
             loop={this.state.carouselLoop}
             margin={10}
             nav={true}
             dots={false}
             autoplay={true}
             autoplayTimeout={2000}
             items={2}
             responsive={this.state.responsive}
             >
             
              {feedbacks.length>0 && feedbacks.map((fbs,index) => {

                

                let star = fbs.rating
                let arr = [1,2,3]
                return (
                  <div key={index} className="item">
                    <div className="feedbackContent">
                      <div className="fRating">
                        
                      {arr.map((a,index)=>{

                        return(
                          <i key={index} className={star>=a?('fa fa-star active'):('fa fa-star')} aria-hidden="true"></i>

                        )

                         
                      })}

                  

                     
                      
                       
                       
                      </div>
                      <p>
                        {fbs.comment.substring(0, 100)}....
                        <span
                          data-toggle="modal"
                          data-target="#reviewModal"                          
                          onClick={()=>{this.handaleModal(fbs)}}
                          style={{cursor:'pointer'}}
                        >
                          
                          Read More...
                        </span>
                      </p>
                      <div className="fUser">
                        <img src={(fbs.avatar_path!=null)?(fbs.avatar_path):(avatar)} alt="LG-Img" />
                        <h4>
                          {fbs.name!=null?fbs.name:"Anonymous"}
                        
                        </h4>
                      </div>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
            </>):(<></>)}
          </div>
        </section>

              {this.state.details!=''?(<>
                <FeedbackModal details={this.state.details} show={this.state.FeedbackModal} handale={()=>{this.handaleModal('')}}/>

              </>):(<></>)}

        {/* <div
          className="modal fade reviewModalWrap"
          id="reviewModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img width="30px" src={closeSvg} alt="LG-Img" />
                </button>
                <div className="heading text-center">
                  <h3 className="text-capitalize">Review Details</h3>
                  <span className="R_divider"></span>
                </div>
                <div className="feedbackContent">
                  <div className="fUser">
                    <img src={user1} alt="LG-Img" />
                    <h4>
                      Melisa hover<span>hr</span>
                    </h4>
                  </div>
                  <div className="fRating my-3">
                    <i className="fa fa-star active" aria-hidden="true"></i>
                    <i className="fa fa-star active" aria-hidden="true"></i>
                    <i className="fa fa-star active" aria-hidden="true"></i>
                    <i className="fa fa-star active" aria-hidden="true"></i>
                    <i className="fa fa-star" aria-hidden="true"></i>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.Lorem ipsum dolor sit amet, consectetur adipisicing
                    elit, sed do eiusmod tempor incididunt ut labore et dolore
                    magna aliqua.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>*/}
      </div> 
    );
  }
}

export default ClientFeedback;
