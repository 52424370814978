import React from "react";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { DatePicker } from "antd";
import moment from "moment-timezone";
import axios from "../../../config/axios";
import { changeCard } from "../../../assets/images";
import validation from "../../../Validate/validator";
import mc from "../../../assets/images/mc.png";
import visa from "../../../assets/images/visa.png";
import ae from "../../../assets/images/ae.png";
import message from "../../../utils/mesaages";

const _ = require("lodash");

class ReplaceCardModal extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
      fieldDisable: false,
      carddetails: [],
      cardnumber: "",
      name: "",
      month: moment().format("MM"),
      year: moment().format("YYYY"),
      cvc: "",
      expiryDate: moment(),
      address: "",
      saveCard: "",
      carduid: "",
      gatewayDetails: {},
      strip_cus: "",
      pfToken: "",
      loaderToken: false,
      saveLoader: false,
      filteredArray: [],
      errors: {
        cvc: "",
        cardnumber: "",
        saveCard: "false",
      },
      FromDataError: {
        cardnumber: "false",
        cvc: "false",
        name: "false",
        expiryDate: "",
        saveCard: "false",
      },
    };
  }

  componentWillMount() {
    this.loadStripeJS();
  }

  componentDidMount() {
    this.fetchcards();
    // this.fetchPaymentSettings()
  }

  handleShow = () => {
    if(this.state.show === true) {
      this.resetForm()
    }
    this.setState({ show: !this.state.show });
  };

  disabledDate = (value) => {
    // const form = this.props.form;
    // Can not select days before today and today
    return value < moment();
  };

  // _________________________________________ FETCH/LOAD & PROCESS _________________________________________ //

  fetchcards = () => {
    console.log("enter fetch cards");
    let param = {
      url: "/consumer/getpaymentmethodcard",
      method: "GET",
      headers: {
        "X-Auth": localStorage.getItem("authToken"),
      },
    };

    console.log("param", param);
    axios(param)
      .then(async (res) => {
        this.setState({ paymentloader: false });
        if (res.data.success) {
          let cardIndex = _.findIndex(res.data.cards, function (o) {
            return o.primarycard == "1";
          });
          if (cardIndex >= 0) {
            this.choseCard(cardIndex);
          }

          if (res.data.cards.length > 0) {
            // this.setState({ strip_cus: res.data.cards[0].strip_cus }, () => {
            //   console.log("strip_cus", this.state.strip_cus);
            // });
          }

          this.setState({ carddetails: res.data.cards, strip_cus: res.data.customer_id === null ? "" : res.data.customer_id });
          console.log("getpaymentmethodcard res", cardIndex);
        }
      })
      .catch((error) => {
        console.log("fetch error");
        console.log(error);
        //toast("Error getting profile data", { type: "error" });
      });
  };

  loadCard = () => {
    return new Promise((resolve, reject) => {
      let self = this;

      let FromDataError = this.state.FromDataError;

      window.Stripe.card.createToken(
        {
          number: this.state.cardnumber,
          cvc: this.state.cvc,
          exp_month: this.state.month,
          exp_year: this.state.year,
        },
        function (status, response) {
          if (response.error) {
            // Show appropriate error to user
            console.log("stripe err", response);
            if (response.error.param == "number") {
              FromDataError.cardnumber = response.error.message;
            } else if (response.error.param == "cvc") {
              FromDataError.cvc = response.error.message;
            } else if (response.error.param == "exp_month") {
              FromDataError.expiryDate = response.error.message;
            } else if (response.error.param == "exp_year") {
              FromDataError.expiryDate = response.error.message;
            }
            self.setState({ FromDataError });
            reject();
          } else {
            // Get the token ID:
            var token = response.id;
            console.log("stripe response", response);
            resolve(response);
            // Save token mapping it to customer for all future payment activities
          }
        }
      );
    });
  };

  processCard = async (hidemsg) => {
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    let { name, saveCard } = this.state;

    let currencyVal = localStorage.getItem("currency");
    currencyVal = currencyVal || "USD";

    return new Promise((resolve, reject) => {
      this.loadCard()
        .then((resCard) => {
          console.log("resCard", resCard);

          let cardExpYear = this.state.year;
          let cardExpMonth = this.state.month;

          let cardData = {
            cardNumber: this.state.cardnumber,
            cardExpYear,
            cardExpMonth,
            // "cVV" : cvc,
            email: userdata.email,
            firstName: this.state.name,
            strip_token: resCard.id,
            uid: userdata.uid,
          };
          let url = "";
          if (this.state.strip_cus == "") {
            url = "/strip/customerCreate";
          } else {
            url = "/strip/customerUpdate";
            cardData['customer'] = this.state.strip_cus;
          }

          axios
            .post(url, cardData)
            .then(async (response) => {
              let { data, success, err } = response.data;
              let d1 = [];
              d1["cvc"] = 0; //cvc
              console.log(".......isSuccess", success);
              if (!success) {
                // hidemsg();
                message.destroy();
                message.error("Error processing your card.");
                reject("Error processing your card.");
              } else {
                if (saveCard === 1) {
                  d1["name"] = name;
                  d1["uuid"] = userdata.uid;
                  d1["primarycard"] = false;
                  d1["currency"] = currencyVal;
                  Object.assign(data, d1);
                  console.log(".......addpaymentmethod", data);
                  axios.post("/consumer/addpaymentmethod", data);
                }
                console.log(".......now returning", success);
                let authTorizationdata = {
                  creditCardToken: data.creditCardToken,
                  customer: data.customer,
                  transactionAmount: 1,
                  cardExpYear,
                  cardExpMonth,
                  // "cVV":`${cvc}`,
                  name: name,
                  cardcompany: data.cardType,
                  cardnumber: data.maskedPan,
                };
                message.destroy();

                resolve(authTorizationdata);
              }
            })
            .catch((e) => {
              // hidemsg();
              console.log(e);
              message
                .error("Error processing your card.")
                .then(() => message.destroy());
              reject("Error processing your card.dfgdfgdfgdfgdfgd");
            })
            .finally(() => {
              setTimeout(() => {
                this.setState({ fieldDisable: false });
              }, 500);
            });
        })
        .catch((err) => {
          // hidemsg();
          this.setState({ fieldDisable: false });
          console.log("pftoken error", err);
          message.error("Error in processing your card. Please recheck the details.");
        });
    }); //promis close
  };

  loadStripeJS() {
    let userdata = JSON.parse(localStorage.getItem('userdata'));
    const id = userdata.uid;
    let self = this;
    let param = {
      url: `/admin/settings/stripe/${id}`,
      method: "GET",
    };
    axios(param).then(async (res) => {
      if (res.data.hasOwnProperty("stripe")) {
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v2/";
        script.async = true;
        script.onload = () => {
          if (res.data.stripe.mode == "live")
            window.Stripe.setPublishableKey(
              res.data.stripe.live.publishable_key
            );
          else
            window.Stripe.setPublishableKey(
              res.data.stripe.sandbox.publishable_key
            );
        };
        document.body.appendChild(script);

        self.setState({ gatewayDetails: res.data.stripe });
      }
    });
  }

  // _______________________________________ VALIDATION & SET _______________________________________ //

  isValidCardNumberInput = (value) => {
    let isValid = true;
    if (!value || value === "") return isValid;

    try {
      if (value.length > value.trim().length) isValid = false;
      else if (value.length > 16 || value.includes(".")) isValid = false;
      else {
        let n = Number(value);
        if (!n) isValid = false;
      }
    } catch (error) {
      console.log("Invalid Card Number Input");
      isValid = false;
    }

    return isValid;
  };

  isValidCVC = (value) => {
    let isValid = true;
    if (!value || value === "") return isValid;

    try {
      if (value.length > value.trim().length) isValid = false;
      else if (value.length > 4 || value.includes(".")) isValid = false;
      else {
        let n = Number(value);
        if (!n) isValid = false;
      }
    } catch (error) {
      console.log("Invalid CVC");
      isValid = false;
    }

    return isValid;
  };

  setvalue = (e, value, field) => {
    if (field == "expiryDate") {
      this.setState({ expiryDate: e });
      if (e != null) {
        // console.log('expiryDate',e.format('MM'))
        // let month=
        // let year=

        if (field === "expiryDate") this.setState({ month: e.format("MM") });
        if (field === "expiryDate") this.setState({ year: e.format("YYYY") });
      }
    } else {
      console.log("aaaaaaaaaaaa", e.target);
      let f = field;
      // this.handleError(field, value);

      if (field == "cardnumber" && !this.isValidCardNumberInput(value)) {
        value = this.state.cardnumber;
      }

      if (field === "saveCard") {
        let saveCard = this.state.saveCard === 1 ? "" : 1;
        this.setState({ saveCard }, () => {
          console.log("saveCard", this.state.saveCard);
          // this.handleError(field, this.state.saveCard);
        });
        value = saveCard;
      }

      let { FromDataError } = this.state;
      var valid_obj = {
        value: value,
        rules: e.target.getAttribute("validaterule"),
        message: e.target.getAttribute("validatemsg"),
      };
      console.log("valid_obj", valid_obj);

      validation(valid_obj).then((error) => {
        console.log("aaaaaaaaaaa", error);
        console.log("field", field);
        FromDataError[field] = error;
        this.setState({ FromDataError }, () => {
          console.log("kkkk", this.state.FromDataError);
        });
      });
    }

    if (field === "cardnumber") this.setState({ cardnumber: value });
    if (field === "name") this.setState({ name: value });

    if (field === "cvc" && this.isValidCVC(value)) {
      this.setState({ cvc: value });
    } else if (field === "cvc") {
      this.setState({ cvc: this.state.cvc });
    }

    // if(field === 'cvc' && value.length>4)
    // {

    //   this.setState({ cvc: this.state.cvc })
    // }
    // else
    // {
    //   if (field === 'cvc') this.setState({ cvc: value })
    // }
    if (field === "primarycard") this.setState({ primarycard: value });
  };

  validateForm(errors) {
    let { carduid, name, cardnumber, month, year, cvc } = this.state;

    if (carduid == "") {
      console.log("in validate form")
      let valid = true;
      let sta = this;
      let FromDataError = this.state.FromDataError;

      for (const [key, value] of Object.entries(errors)) {
        if (value.length > 0) {
          if (key !== "expiryDate" && value == "false") {
            FromDataError[key] = "This field is required";
            sta.setState({ FromDataError });
          }
          else if(key === "expiryDate" && value === ""){
            FromDataError[key] = "This field is required";
            sta.setState({ FromDataError });
          }

          valid = false;
        }
      }
      console.log("valid", valid)
      return valid;
    }
  }

  setFormFieldEmpty = async () => {
    try {
      let FromDataError = this.state.FromDataError;
      let sta = this;

      for (const [key, value] of Object.entries(FromDataError)) {
        FromDataError[key] = key === "expiryDate" ? "" : "false";
        sta.setState({ FromDataError });
      }
      await this.setState({
        cardnumber: "",
        name: "",
        expiryDate: moment(),
        cvc: "",
        saveCard: ""
      })
      return true
    }
    catch(err) {
      return false
    }
  }

  resetForm = async () => {
      this.fetchcards()
    // let carddetails = [...this.state.carddetails];
    // carddetails = carddetails.map((card, i) => {
    //   if(card.hasOwnProperty("checked")) {
    //     delete card["checked"];
    //   }
    //   return card
    // })
    // this.setState({carddetails, fieldDisable: false, carduid: ""})
    this.setState({fieldDisable: false})
    this.setFormFieldEmpty()
  }

  choseCard = async (index) => {
    await this.setState({ carduid: "" });
    await this.setFormFieldEmpty()
    let carddetails = [...this.state.carddetails];
    let card = { ...carddetails[index] };
    console.log("carddetails value", carddetails);
    if (card.hasOwnProperty("checked")) {
      delete card["checked"];
    } else {
      await this.setState({ carduid: card.id });
      // await this.setState({carduid:card.strip_card})
      card["checked"] = true;
      console.log("carduid", this.state.carduid);
    }
    carddetails = carddetails.map((d) => {
      delete d.checked;
      return d;
    });
    carddetails[index] = card;
    //let carduid = this.state.carduid===e.target.value? '': e.target.value
    console.log("this.state.carduid===e.target.value", card);
    //console.log('carddetails',carddetails)
    var filteredArray = carddetails.filter(function (itm) {
      return itm.checked;
    });
    console.log("carddetailsfilter", filteredArray);

    this.setState({ carddetails, filteredArray });
    if (filteredArray.length > 0) {
      this.setState({ fieldDisable: true });
    } else {
      this.setState({ fieldDisable: false });
    }
  };

  // ___________________________________________ SUBMIT ____________________________________________ //
  handleConfirm = async () => {
    let { carduid, name, cardnumber, month, year, cvc } = this.state;

    const {booking_card_uid, getBooking,type} = this.props

    console.log(
      "validate from",
      this.validateForm(this.state.FromDataError),
      this.state.FromDataError
    );

    if (this.validateForm(this.state.FromDataError) || carduid != "") {
      // const hidemsg = message.loading(
      //   `Please wait , we are booking your session`,
      //   0
      // );
      this.setState({ fieldDisable: true });

      console.log("state", this.state);

      if (carduid != "" || name != "") {
        this.setState({saveLoader: true})
        let cardData = [...this.state.carddetails].find(
          (d) => d.id === carduid
        );
        if (!carduid) {
          cardData = await this.processCard();
        } else {
          cardData = {
            creditCardToken: cardData.strip_card,
            customer: cardData.strip_cus,
            transactionAmount: 1,
            cardExpMonth: cardData.expirymonth,
            cardExpYear: cardData.expiryyear,
            // "cVV":`${cardData.cvc}`,
            name: cardData.name,
            cardcompany: cardData.cardcompany,
            cardnumber: cardData.cardnumber,
          };
        }
        console.log("card data", cardData)
        axios.post(`/consumer/replace-card`, {cardData, booking_card_uid,type})
        .then((response) => {
          console.log(response.data)
          this.setState({saveLoader: false})
          if(response.data.success) {  
            this.handleShow()
            message.success("Card has been replaced successfully")
            getBooking()
          } else {
            message.error("Error while replacing the card")
            this.handleShow()
          }

        })
        .catch((err) => {
          this.setState({saveLoader: false})
          // console.log("replace card error", err)
          message.error("Something went wrong!")
          this.handleShow()
        })
      } else {
        message.warning("Please add payment details");
        // hidemsg();
        if (this.state.filteredArray.length === 0) {
          this.setState({ fieldDisable: false });
        }
        // toast("Add payment details", { type: "error" });
      }
    } else {
      if (this.state.carddetails.length > 0) {
        message.error("Please select card or enter your card details");
      }
    }
  };

  render() {
    const { show, saveLoader } = this.state;
    return (
      <>
        <span className="changeCard" onClick={this.handleShow}>
          <img src={changeCard} className="img-fluid" />
        </span>
        <Modal
          size="lg"
          title="Title"
          show={show}
          // onHide={this.handleShow}
          keyboard={false}
          style={{ color: "black" }}
        >
          {/* <Modal.Header closeButton>
            <Modal.Title>Replace Card</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
            <Row>
              <Col>
                <div className="saveCardList">
                  {/* {this.state.carddetails.length > 0 ? (
                    
                  ) : (
                    ""
                  )} */}
                  <h3>Saved Card(s)</h3>

                  <ul>
                    {this.state.carddetails.length > 0 ?
                      this.state.carddetails.map((cards, index) => {
                        return (
                          <li key={index}>
                            <div className="custom-control custom-checkbox checkbox-inline mx-2">
                              <span>
                                <input
                                  style={{ marginTop: "10px", zIndex: "1" }}
                                  onClick={() => this.choseCard(index)}
                                  checked={
                                    cards.primarycard == "1" ? true : false
                                  }
                                  type="checkbox"
                                  checked={
                                    cards.hasOwnProperty("checked")
                                      ? true
                                      : false
                                  }
                                  className="custom-control-input"
                                  id={index}
                                  value={cards.id}
                                  name="saveCard"
                                />
                                <label
                                  className="custom-control-label d-flex align-items-center"
                                  for={index}
                                >
                                  {cards.cardcompany.toUpperCase() == "VISA" ? (
                                    <img src={visa} />
                                  ) : cards.cardcompany.toUpperCase() ==
                                    "MASTERCARD" ? (
                                    <img src={mc} />
                                  ) : (
                                    <img src={ae} />
                                  )}

                                  <p>
                                    <span>{cards.name} {cards.primarycard == "1" ? (
                                      <small style={{color: "#afedaf", fontSize: "15px"}}><b>(Primary Card)</b></small>
                                    ): ""}</span>
                                    {cards.cardnumber} - {cards.cardtype} card
                                  </p>
                                </label>
                              </span>
                            </div>
                          </li>
                        );
                      })
                      : (
                        <li>
                          <p>No Cards Found</p>
                        </li>
                      )
                      }
                  </ul>
                </div>
              </Col>
              <Col>
                <h3>Add new card</h3>
                
                  <div className="form-group">
                    <input
                      type="text"
                      name="cardnumber"
                      disabled={this.state.fieldDisable}
                      validaterule={["required", "min|15", "max|16"]}
                      validatemsg={[
                        "Enter your card number",
                        "Card number minimum 15 digits!",
                        "Card number maximum 16 digits!",
                      ]}
                      value={this.state.cardnumber}
                      onChange={(e) =>
                        this.setvalue(e, e.target.value, "cardnumber")
                      }
                      placeholder="Card Number"
                      className="form-control"
                      style={{ color: "black" }}
                    />
                    <p style={{ color: "red" }}>
                      {this.state.FromDataError.cardnumber == "false"
                        ? ""
                        : this.state.FromDataError.cardnumber}
                    </p>
                  </div>
                  {/* card name */}
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      value={this.state.name}
                      disabled={this.state.fieldDisable}
                      validaterule={["required"]}
                      validatemsg={["Enter your name"]}
                      onChange={(e) => this.setvalue(e, e.target.value, "name")}
                      style={{ color: "black" }}
                      placeholder="Name Of Card Holder"
                      className="form-control"
                    />
                    <p style={{ color: "red" }}>
                      {this.state.FromDataError.name == "false"
                        ? ""
                        : this.state.FromDataError.name}
                    </p>
                  </div>

                  {/* Card exp and cvv */}
                  <div className="row">
                    {/* Card exp*/}
                    <div className="col-sm-8">
                      <DatePicker
                        disabledDate={this.disabledDate}
                        disabled={this.state.fieldDisable}
                        onChange={(e) => this.setvalue(e, "", "expiryDate")}
                        value={this.state.expiryDate}
                        picker="month"
                        size="large"
                        defaultValue={moment()}
                        format={"MM-YYYY"}
                      />
                      <p style={{ color: "red" }}>
                      {this.state.FromDataError.expiryDate == "false"
                        ? ""
                        : this.state.FromDataError.expiryDate}
                    </p>
                    </div>

                    {/* Card cvc*/}
                    <div className="col-sm-4">
                      <div className="form-group">
                        <input
                          type="text"
                          name="cvc"
                          disabled={this.state.fieldDisable}
                          validaterule={["required", "min|3"]}
                          validatemsg={["Enter CVV / CVC", "CVC required"]}
                          onChange={(e) =>
                            this.setvalue(e, e.target.value, "cvc")
                          }
                          placeholder="CVC"
                          className="form-control"
                          value={this.state.cvc}
                          style={{ color: "black" }}
                        />
                        <p style={{ color: "red" }}>
                          {this.state.FromDataError.cvc == "false"
                            ? ""
                            : this.state.FromDataError.cvc}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{marginLeft: "0px"}}>
                    <div className="areas mb-3">
                      <div className="custom-control custom-checkbox checkbox-inline">
                        <input
                          checked={this.state.saveCard === 1 ? true : false}
                          type="checkbox"
                          validaterule={["required"]}
                          validatemsg={["This field is required."]}
                          disabled={this.state.fieldDisable}
                          className="custom-control-input"
                          onChange={(e) =>
                            this.setvalue(e, e.target, "saveCard")
                          }
                          id="customCheck9"
                          name="saveCard"
                          value={this.state.saveCard}
                        />
                        <label
                          className="custom-control-label"
                          for="customCheck9"
                        >
                          Save this card
                        </label>
                        <p style={{ color: "red" }}>
                          {this.state.FromDataError.saveCard == "false"
                            ? ""
                            : this.state.FromDataError.saveCard}
                        </p>
                      </div>
                    </div>
                  </div>
                
              </Col>
            </Row>
          </Modal.Body>
          <br />

          <Modal.Footer>
            {/* {this.state.savecardloader === true ? (
              <Spinner animation="grow" variant="primary" />
            ) : (
              ""
            )} */}
            <Button variant="secondary" onClick={this.handleShow} disabled={saveLoader}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.handleConfirm} disabled={saveLoader}>
            {saveLoader ? (
                  <>
                    <Spinner
                      animation="border"
                      variant="light"
                      as="span"
                      size="sm"
                      aria-hidden="true"
                    />{" "}
                    Loading...
                  </>
                ) : (
                  "Save Card"
                )}
              
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ReplaceCardModal;
