import React, { useState,useEffect } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import message,{getHelpText} from '../../../utils/mesaages';
import TourSite from '../../../utils/TourSite';
import {photograph, plusnewSvg } from "../../../assets/images";

import { Tooltip } from 'antd';
const AddPackage=(props)=> {
  
  const [show, setShow] = useState(false);
  // const [Intromessage, setIntromessage] = useState(props.Intromessage);
  const [Messsage, setMesssage] = useState('');
  const handleClose = () => {
    

    //for Tour section
    if(localStorage.getItem('tourActive')=='true')
    {
      props.handelAddPackageModalTour(false)
      props.handleIncreaseTour('.tour-intoVideo')
    }
    //
    setShow(false);
    props.handleResetPackage()
    setInputList([{id: 0, sessionName: "", sessionDescription: "", Length: ""}])
    setInputListVal([{ id: 0, sessionName: "false", sessionDescription: "false", Length: "false" }]);
  }

  //for Tour section
  useEffect(() => {
    setShow(props.AddPackageModalTour);
  }, [props.AddPackageModalTour]);
//

  const handleShow = () => {
    setShow(true)
    
    //for Tour section
    if(localStorage.getItem('tourActive')=='true')
    {
      props.handelAddPackageModalTour(true)
      props.handleIncreaseTour('.tour-createpackages-session-details')
    }
    //
    
   
  
  };
  const pdata = props.pacdata;
  const sessionLength = props.sessionlength
  console.log("DYNAMIC SESSION LENGTH",sessionLength)
  const ref = React.createRef();
  const handleCheck = () => {
    if (
      pdata.title.length > 0 &&
      pdata.price.length > 0 &&
      pdata.description.length > 0
    ) {
      // setShow(false);
    }
  };

  const [inputList, setInputList] = useState([
    { id: 0, sessionName: "", sessionDescription: "", Length: "" },
  ]);
 

  const [inputListVal, setInputListVal] = useState([
    { id: 0, sessionName: "false", sessionDescription: "false", Length: "false" },
  ]);

  const validateForm=(errors) =>{
    let valid = true;
    let sta = this;
    let ListVal = [...inputListVal];
    valid = props.validateForm()

    
    for (const [key, value] of Object.entries(errors)) {
      for (const [k, v] of Object.entries(value)) {
        if (v.length > 0 && k!='id') {
          console.log('valid',v,k)
          if (v == 'false') {
            ListVal[key][k] = 'This field is required';
            setInputListVal(ListVal)
          }
  
          valid = false;
        }
      }
      
    }
    console.log('vvvvvvvv',valid)
    return valid;
  };

  

  const handleInputChange =async (e, index) => {
    console.log('enterrr',e)
    const { name, value } = e.target;
    const list = [...inputList];
    const listVal = [...inputListVal];
    list[index][name] = value;
   await setInputList(list);

    if(value=='')
    {
      listVal[index][name] = 'This field is required'
      setInputListVal(listVal)
    }
    else
    {
      listVal[index][name] = ''
      setInputListVal(listVal)

    }
  };

  const handleAddClick = (i) => {
    // console.log('iiiii',i, inputList)
    setInputList([
      ...inputList,
      { id: i+1 , sessionName: "", sessionDescription: "", Length: "" },
    ]);
    

    setInputListVal([
      ...inputListVal,
      { id: i+1 , sessionName: "false", sessionDescription: "false", Length: "false" },
    ]);
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    // console.log("## input list", inputList)
    // console.log("@@ sob kaj baki", inputList[i].id)
    // let el = document.getElementById(`${inputList[i].id}`)
    // el.scrollIntoView(true);
  };

  const handleAddRemove = (i) => {
    const hero = inputList;
    const updateList = hero.filter((item) => item.id !== i);
    setInputList(updateList);

    console.log('inputListVal',inputListVal)
    const hero1 = inputListVal;
    const updateList1 = hero1.filter((it) => it.id != i);
    setInputListVal(updateList1);
    console.log('inputListVal',inputListVal)


    
  };

  const handleReset=() =>{
    props.handleResetPackage()
     setInputList([{id: 0, sessionName: "", sessionDescription: "", Length: ""}])
     setInputListVal([{ id: 0, sessionName: "false", sessionDescription: "false", Length: "false" }]);
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    //for Tour section
    if(localStorage.getItem('tourActive')=='true')
    {
      props.hideTour('.tour-intoVideo')
    }
    
    //
    if(pdata.package_type === "Multi Session")
    {
      if(validateForm(inputListVal))
      {
        console.log('subval..',inputList)
       // return
        if(props.handleSubmit(e, inputList)!=false)
        {
          setShow(false);
          handleReset()
        }

      }
      else{
        console.log('errrrrrrr',inputListVal)
        setMesssage("Please fill the form correctly");
      }
      
    }
    else
    {

      if(props.handleSubmit(e, inputList)!=false)
      {
        setShow(false);
        handleReset()
      }
    }
    
    
    
   
  };



 

  return (
    <>
      <div className="clearfix col-12">
        <Tooltip title={'Please Add Package from Here'} placement="right" >
          <span className="popup-with-zoom-anim mb-3 add_new_testi addTag float-right" onClick={handleShow} style={{cursor: 'pointer'}}>
            <img width="25px" alt="LG-Img" src={plusnewSvg} />
          </span>
        </Tooltip>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Create Sessions</Modal.Title>
          </Modal.Header>
          <Modal.Body className="tour-createpackages-session-details">
            <Form.Group>
              <Form.Label>Session Type</Form.Label>
              <div className="selectBox">
                <select
                  // style={{color:"black"}}
                  // style={{ backgroundColor: "#8d8d8d"}}
                  className="form-control"
                  // style={{ backgroundColor: "#8d8d8d"}}
                  value={pdata.package_type}
                  name="package_type"
                  placeholder="Enter package type"
                  onChange={props.handlePackType}
                  validaterule={['required']}
                  validatemsg={['Select Session Type']}
                >
                  <option selected="">Select Session</option>
                  <option selected="Single Session">Single Session</option>
                  <option selected="Multi Session">Multi Session</option>
                </select>
                <p style={{ color: 'red' }}>{pdata.FromDataError.package_type == 'false' ? '' :pdata.FromDataError.package_type }</p>

              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label> Name</Form.Label>
              <Form.Control
                // style={{color:"black"}}
                // id="inputID"
                type="text"
                placeholder="Enter name of package or program or multi sessions"
                name="title"
                value={pdata.title}
                onChange={props.handleChange}
                // required={true}
                validaterule={['required']}
                validatemsg={['Enter Session Name']}
              />
                <p style={{ color: 'red' }}>{pdata.FromDataError.title == 'false' ? '' :pdata.FromDataError.title }</p>

            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Description</Form.Label>
              <Form.Control
                // style={{color:"black"}}
                // id="inputID"
                as="textarea"
                rows="4"
                placeholder="Enter full description of package or program or multi sessions"
                value={pdata.description}
                onChange={props.handleChange}
                name="description"
                // required={true}
                validaterule={['required']}
                validatemsg={['Enter Session Description']}
              />
                <p style={{ color: 'red' }}>{pdata.FromDataError.description == 'false' ? '' :pdata.FromDataError.description }</p>

            </Form.Group>
            {pdata.package_type === "Single Session" ? (
              <div>
                
                 <Form.Group>
                  <Form.Label>Introductory Session <small>(session time: length in minutes)</small><Tooltip title={props.Intromessage} placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip></Form.Label>
                  <select
                  // style={{color:"black"}}
                  className="form-control"
                  value={pdata.introDuration}
                  name="introDuration"
                  placeholder="Enter intro duration of session"
                  onChange={props.handleChange}
                  validaterule={['required']}
                  validatemsg={['Select Intro Duration']}
                >
                  <option value="">Select duration</option>
                  {/* <option value="15">15 Minute</option>
                  <option value="30">30 Minute</option>
                  <option value="45">45 Minute</option>
                  <option value="60">60 Minute</option> */}
                  {sessionLength && sessionLength.split(",").map((each, i) => {
                    return (
                      <option value={each.trim()}>{each.trim()} Minute</option>
                    )
                  })}
                </select>
                <p style={{ color: 'red' }}>{pdata.FromDataError.introDuration == 'false' ? '' : pdata.FromDataError.introDuration }</p>

                  
                </Form.Group>

                <Form.Group>
                  <Form.Label>Single session length</Form.Label>
                  <select
                  // style={{color:"black"}}
                  className="form-control"
                  value={pdata.duration}
                  name="duration"
                  placeholder="Enter  duration of session"
                  onChange={props.handleChange}
                  validaterule={['required']}
                  validatemsg={['Select Duration']}
                >
                  <option value="">Select duration</option>
                  {/* <option value="15">15 Minute</option>
                  <option value="30">30 Minute</option>
                  <option value="45">45 Minute</option>
                  <option value="60">60 Minute</option> */}
                  {sessionLength && sessionLength.split(",").map((each, i) => {
                    return (
                      <option value={each.trim()}>{each.trim()} Minute</option>
                    )
                  })}
                </select>
                <p style={{ color: 'red' }}>{pdata.FromDataError.duration == 'false' ? '' : pdata.FromDataError.duration }</p>

                  
                </Form.Group>
              </div>
            ) : (
              ""
            )}

             {pdata.package_type === "Multi Session" ? (

                      <Form.Group>
                            
                      <Form.Label>Introductory Session <small>(session time: length in minutes)</small><Tooltip title={props.Intromessage} placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip></Form.Label>
                      <select
                      // style={{color:"black"}}
                      className="form-control"
                      value={pdata.introDuration}
                      name="introDuration"
                      placeholder="Enter intro duration of session"
                      onChange={props.handleChange}
                      validaterule={['required']}
                      validatemsg={['Select Intro Duration']}
                      >
                      <option value="">Select duration</option>
                      {/* <option value="15">15 Minute</option>
                      <option value="30">30 Minute</option>
                      <option value="45">45 Minute</option>
                      <option value="60">60 Minute</option> */}
                      {sessionLength && sessionLength.split(",").map((each, i) => {
                      return (
                      <option value={each.trim()}>{each.trim()} Minute</option>
                      )
                      })}
                      </select>
                <p style={{ color: 'red' }}>{pdata.FromDataError.introDuration == 'false' ? '' : pdata.FromDataError.introDuration }</p>


                      </Form.Group>
              // <Form.Group>
              //   <Form.Label>Total Sessions</Form.Label>
              //   <Form.Control
              //     style={{color:"black"}}
              //     // id="inputID"
              //     type="number"
              //     placeholder="Enter total number of Sessions"
              //     name="total_sessions"
              //     value={pdata.total_sessions}
              //     onChange={props.handleChange}
              //     required={true}
              //   />
              // </Form.Group>
            ) : (
              ""
            )} 

            {/* <Form.Group>
              <Form.Label>Total Sessions</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter total number of Sessions"
                name="total_sessions"
                value={pdata.total_sessions}
                onChange={props.handleChange}
                required={true}
              />
            </Form.Group>
            */}
            <Form.Group>
              <Form.Label>Cost</Form.Label>
              <Row>
                <Col>
                  <div className="selectBox">
                    <select
                      // style={{color:"black"}}
                      // style={{ backgroundColor: "#8d8d8d"}}
                      className="form-control"
                      // style={{ backgroundColor: "#8d8d8d"}}
                      name="cost"
                      value={pdata.cost}
                      onChange={props.handleChange}
                      validaterule={['required']}
                      validatemsg={['Select Any Currency']}
                    >
                      <option value="">Select Currency</option>
                      <option value="USD">USD</option>
                      <option value="EUR">EUR</option>
                      <option value="GBP">GBP</option>
                    </select>
                <p style={{ color: 'red' }}>{pdata.FromDataError.cost == 'false' ? '' : pdata.FromDataError.cost }</p>

                  </div>
                </Col>
                <Col>
                  <Form.Control
                    // style={{color:"black"}}
                    type="number"
                    // id="inputID"
                    placeholder="Enter amount"
                    name="price"
                    value={pdata.price}
                    onChange={props.handleChange}
                    validaterule={['required','lessThanOne']}
                    validatemsg={['Enter a Amount','Please provide value greater than one']}
                  />
                <p style={{ color: 'red' }}>{pdata.FromDataError.price == 'false' ? '' : pdata.FromDataError.price }</p>

                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
                <Form.Label> Sorting Order<Tooltip title={props.sortingHelpText}> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip></Form.Label>
                <Form.Control
                  // style={{color:"black"}}
                  type="number"
                  placeholder="Enter your sorting order"
                  name="sortingOrder"
                  value={pdata.sortingOrder}
                  onChange={props.handleChange}
                  //required={true}
                  validaterule={['required', `maxSortingOrderValue|${pdata.packageCount+1}`]}
                  validatemsg={['This field is required', `maximum order limit ${pdata.packageCount+1}`]}
                />
                <p style={{ color: 'red' }}>{pdata.FromDataError.sortingOrder == 'false' ? '' :pdata.FromDataError.sortingOrder }</p>
              </Form.Group>

            <hr />

            {pdata.package_type === "Multi Session" ? (
              <div className="App">
                <h4 style={{ textAlign: "center" }}> Session Details</h4>
                {inputList.map((x, i) => {
                  return (
                    <>
                      {inputList.length - 1 === i && (
                        <i
                          className="fa fa-plus-square"
                          style={{
                            color: "green",
                            fontSize: "25px",
                            float: "right",
                            cursor:'pointer'
                          }}
                          onClick={() => handleAddClick(i)}
                        />
                      )}
                    </>
                  );
                })}
                <br />

                <hr />
                {inputList.map((x, i) => {
                 let validate=  inputListVal.filter((item) => item.id == x.id);
                 console.log('validate',validate,i)
                 validate = validate[0]
                  
                  return (
                     <div key={x.id} ref={ref}>
                    
                      {inputList.length > 1 && (
                        <i
                          className="fa fa-minus-square"
                          style={{
                            color: "green",
                            fontSize: "25px",
                            float: "right",
                            cursor:'pointer'
                          }}
                          onClick={() => handleAddRemove(x.id)}
                        ></i>
                      )}

                      <Form.Group>
                        {/* <Form.Control
                          type="text"
                          placeholder="Enter session name"
                          name="id"
                          value={i}
                          // onChange={(e) => handleInputChange(e, i)}
                          // required={true}
                        /> */}

                        <Form.Label>Session Name</Form.Label>
                        <Form.Control
                          // style={{color:"black"}}
                          // id="inputID"
                          type="text"
                          placeholder="Enter session name"
                          name="sessionName"
                          value={x.sessionName}
                          onChange={(e) => handleInputChange(e, i)}
                          // required={true}
                        />
                <p style={{ color: 'red' }}>{validate.sessionName == 'false' ? '' : validate.sessionName }</p>

                      </Form.Group>
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          // style={{color:"black"}}
                          // id="inputID"
                          as="textarea"
                          rows="4"
                          placeholder="Enter session description"
                          value={x.sessionDescription}
                          onChange={(e) => handleInputChange(e, i)}
                          name="sessionDescription"
                          // required={true}
                        />
                <p style={{ color: 'red' }}>{validate.sessionDescription == 'false' ? '' : validate.sessionDescription }</p>

                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Length (in Minute)</Form.Label>
                        {/* <Form.Control
                          style={{color:"black"}}
                         
                          type="number"
                          placeholder="Enter amount"
                          name="Length"
                          value={x.Length}
                          onChange={(e) => handleInputChange(e, i)}
                          required={true}
                        /> */}
                <select
                  // style={{color:"black"}}
                  className="form-control"
                  value={x.Length}
                  name="Length"
                  placeholder="Enter duration of session"
                  onChange={(e) => handleInputChange(e, i)}
                  // required={true}
                >
                  <option value="">Select duration</option>
                  {/* <option value="15">15 Minute</option>
                  <option value="30">30 Minute</option>
                  <option value="45">45 Minute</option>
                  <option value="60">60 Minute</option> */}
                  {sessionLength && sessionLength.split(",").map((each, i) => {
                    return (
                      <option value={each.trim()}>{each.trim()} Minute</option>
                    )
                  })}
                  
                </select>
                <p style={{ color: 'red' }}>{validate.Length == 'false' ? '' : validate.Length }</p>

                      </Form.Group>
                      <hr />

                      {/* <hr /> */}
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}

              {/* <Form.Group>
                <Form.Label> Sorting Order<Tooltip title={props.sortingHelpText}> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip></Form.Label>
                <Form.Control
                  style={{color:"black"}}
                  type="number"
                  placeholder="Enter your sorting order"
                  name="sortingOrder"
                  value={pdata.sortingOrder}
                  onChange={props.handleChange}
                  //required={true}
                  validaterule={['required', `maxSortingOrderValue|${pdata.packageCount+1}`]}
                  validatemsg={['This field is required', `maximum order limit ${pdata.packageCount+1}`]}
                />
                <p style={{ color: 'red' }}>{pdata.FromDataError.sortingOrder == 'false' ? '' :pdata.FromDataError.sortingOrder }</p>
              </Form.Group> */}
          </Modal.Body>

          <Modal.Footer>
            {Messsage}
            {/* <Button variant="primary" onClick={handleClose}>
              Close
            </Button> */}
            <Button className="defaultBtndng" variant="default" onClick={handleReset}>
              Reset</Button>
            <Button className="tour-createpackages-session-submit defaultBtn" variant="default" type="submit" onClick={handleCheck}>
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
export default AddPackage;
