import React from "react";
import Select from "react-select";
import { Button, Modal, Form, Col } from "react-bootstrap";
import { writingSvg, closeSvg, photograph } from "../../../assets/images";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { Link } from "react-router-dom";
import { avatar } from "../../../assets/img";
import UpdateImage from "./UpdateImage";
const _ = require("lodash");
const ct = require("countries-and-timezones");

class UpdatePersonal extends React.Component {
  constructor() {
    super()
    this.state = {
      show: false,
      setShow: false,
      address: "",
      country: "",
      city: "",
      tzOptions: [],
      canwrite: false,
      check: 0,
      options: [],
  
      SocialMediaInfo: [{SocialMedia:"",URL: ""}],
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.pdata !== this.props.pdata) {
      this.setState({
            SocialMediaInfo:this.props.pdata.SocialMediaInfo,
           
      });

    }
  }

  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };

  handleCheck = () => {
    if (this.state.check === 1) {
      this.handleClose();
      this.setState({check:0})
    }
  };

  handleCheckbox = () => {
    this.setState({ check: 1 });
  };

  onCero = async (e) => {
    if (e.key === "Delete" || e.key === "Backspace") {
      await this.setState({ canwrite: true });
    } else {
      await this.setState({ canwrite: false });
    }
  };

  handleAddressChange = (address) => {
    this.setState({ address });
  };
  handleSelect = async (address) => {
    this.setState({ address });
    var that = this;
    await geocodeByAddress(address)
      .then((results) => {
        _.map(results[0].address_components, async (eachComp) => {
          if (_.includes(eachComp.types, "country")) {
            const timezones = ct.getTimezonesForCountry(eachComp.short_name);

            let tzOptionsFormatted = _.map(timezones, (eachTZ) => {
              return eachTZ.name + " (GMT " + eachTZ.utcOffsetStr + ")";
            });

            await that.setState({
              country: eachComp.long_name,
              tzOptions: tzOptionsFormatted,
            });
          }

          if (_.includes(eachComp.types, "locality")) {
            console.log("setting city...");
            await that.setState({ city: eachComp.long_name });
          }

          return eachComp;
        });
      })
      .catch((error) => console.error("Error", error));

    this.props.handleAddressSelected(
      address,
      this.state.country,
      this.state.city
    );
  };

  handleLanguageChange = (e, option) => {
    if (option.removedValue && option.removedValue.isFixed) return;
    this.props.handleLanguageChange(e);
  };

  handleSubmit=(e)=>{
    let Data=this.state.SocialMediaInfo
    this.props.handleSubmitPersonal(e,Data)
    this.handleClose()
    
  }

  handleInputChange = (e, index,state) => {
    const { name, value } = e.target;
    const list = [...state];
    list[index][name] = value;
    this.setState({SocialMediaInfo: list });
  
  };

  render() {
    console.log("this.props.pdata.name-------",this.state.SocialMediaInfo)

    const Categoryoptions = [
      { value: 'Career Coaching', label: 'Career Coaching'},
      { value: 'Executive Coaching', label: 'Executive Coaching' },
      { value: 'Business Coaching', label: 'Business Coaching'  },
      { value: 'Life Coaching', label: 'Life Coaching'},
      { value: 'Transformational Coaching', label: 'Transformational Coaching' },
      { value: 'Health & Wellbeing Coaching', label: 'Health & Wellbeing Coaching'}
    ];

    const options = [
      { value: "English", label: "English", isFixed: true },
      { value: "Spanish", label: "Spanish", isFixed: false },
      { value: "French", label: "French", isFixed: false },
      { value: "Portigues", label: "Portigues", isFixed: false },
      { value: "Hindi", label: "Hindi", isFixed: false },
      { value: "Russian", label: "Russian", isFixed: false },
      { value: "Germans", label: "Germans", isFixed: false },
    ];
    // const { language } = this.props.pdata;
    const styles = {
      multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
      },
      multiValueLabel: (base, state) => {
        return state.data.isFixed
          ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
          : base;
      },
      multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: "none" } : base;
      },
      control: (base) => {
        return {
          ...base,
          background: "#fff",
        };
      },
    };
    const pdata = this.props.pdata;
    return (
      <>
        <div className="edit_personal_details">
          <Link
            to="profile-coach"
            className="popup-with-zoom-anim edit_item "
            data-toggle="tooltip"
            data-placement="top"
            title="Update you Profile"
            onClick={this.handleShow}
          >
            <img width="40px" src={writingSvg} alt="LG-Img" />
          </Link>
        </div>

        <Modal
          dialogClassName="my-modal-UpdatePersonal"
          show={this.state.show}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Form onSubmit={this.props.handleSubmit}>
            {/* <Modal.Header >
              <Modal.Title>Update Coach Profile Information</Modal.Title>
              <button
                type="button"
                className="close"
                data-dismiss="modals"
                aria-label="Close"
                onClick={this.handleClose}
              >
                <img width="30px" src={closeSvg} alt="ss" />
              </button>
            </Modal.Header><br/> */}
            <Modal.Body style={{background:"rgb(250, 246, 246)",borderRadius: "6px"}}>
            <section className="coachEditWrap">
            
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-19">
      <button
            style={{backgroundColor:"black",borderRadius:"12px"}}
                type="button"
                className="close"
                data-dismiss="modals"
                aria-label="Close"
                onClick={this.handleClose}
              >
                <img width="30px" src={closeSvg} alt="ss" />
              </button>
        <div className="coachProfileFormEdit">
          <form>
           <div className="row">
            <div className="col-12">
              <div className="coachImg mb-4">
                <img id="item-img-output" src={this.props.userImage2} className="img-fluid"/>
                <div className="picEdit">
                  {/* <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Edit your Picture">
                    <img  width="35px" src={photograph} alt="LG-Img"/>
                    <input type="file" className="item-img file center-block" name="file_photo"/>
                  </a> */}
                  <UpdateImage
                  avatar={this.props.avatar}
                  userImage2={this.props.avatar_path}
                  onChangeHandler={this.props.onChangeHandler}
                  handleSubmitProfileImage={this.props.handleSubmitProfileImage}/>
                </div>
              </div>
            </div>

            <div className="col-12">
              <h4>Personal Information</h4>
              <hr/>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label>First Name</label>
              <input type="text" 
              style={{color:"black"}}
              name="name" 
              defaultValue={this.props.pdata.name}
              onChange={this.props.handleChange} 
              placeholder="Name" 
              className="form-control"/>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label>Coach Title</label>
              <input type="text"
                style={{color:"black"}}
                placeholder="Add a title"
                value={this.props.pdata.user_title}
                onChange={this.props.handleChange}
                name="user_title" 
                className="form-control"/>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label>Select Coach categories</label>
                <Select  className="languageSelect" placeholder='Select Category' isMulti 
                value={this.props.pdata.coachingCategory} 
                onChange={this.props.handleCategoryChange} 
                options={Categoryoptions}
                styles={styles} />
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className="form-group">
                <label>Last Name</label>
              <input type="text" name="" value="Holmes" placeholder="Last Name" className="form-control"/>
              </div>
            </div> 
            <div className="col-md-6">
              <div className="form-group">
                <label>Email</label>
              <input type="email" name="" value="example@example.com" placeholder="Email" className="form-control"/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Password</label>
              <input type="password" name="" value="*********" placeholder="Password" className="form-control"/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Phone No</label>
              <input type="tel" name="" value="477-046-1827" placeholder="Phone No" className="form-control"/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Year of Birth</label>
                <div className="selectBox searchFilter">
                  <select className="selectpicker"  data-live-search="true">
                    <option>Nothing Selected</option>
                    <option>2020</option>
                    <option>2019</option>
                    <option>2018</option>
                    <option>2017</option>
                    <option>2016</option>
                    <option>2015</option>
                    <option>2014</option>
                    <option>2013</option>
                    <option>2012</option>
                    <option>2011</option>
                    <option>2010</option>
                    <option>2009</option>
                    <option>2008</option>
                    <option>2007</option>
                    <option>2006</option>
                  </select>
                  <span>
                      <img src="images/dArrowblack.png" alt=""/>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
              <label>Gender</label>
              <div className="selectBox searchFilter">
                  <select className="selectpicker"  data-live-search="true">
                    <option>Nothing Selected</option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Prefer not to answer</option>
                  </select>
                  <span>
                      <img src="images/dArrowblack.png" alt=""/>
                  </span>
                </div>
              </div>
            </div> */}
            <div className="col-md-12">
              <div className="form-group">
                <label>Language</label>
                <div className="selectBox searchFilter">
                <Select
                  className="languageSelect"
                  placeholder="Select language"
                  isMulti
                  value={pdata.language}
                  onChange={this.handleLanguageChange}
                  isClearable={!this.state.options.some((opt) => opt.isFixed)}
                  options={options}
                  styles={styles}
                />
                  </div>
                </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label>Short Bio (Word limit {pdata.wordcount} )</label>
                <label style={{ color: "red" }}>
                  {this.props.pdata.alertMsg}
                </label>
                <Form.Control
                  style={{color:"black"}}
                  as="textarea"
                  rows="6"
                  value={pdata.short_bio}
                  onChange={this.props.handleChange}
                  name="short_bio"
                  // readOnly={pdata.readonly}
                  onKeyDown={(e) => this.onCero(e)}
                  readOnly={
                    this.state.canwrite
                      ? false
                      : pdata.short_bio && pdata.short_bio.split(" ").length > 50
                      ? true
                      : false
                  }
                />
                </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Hours of coaching experience</label>
                <div className="selectBox searchFilter">
                <select
                  style={{color:"black"}}
                  className="form-control"
                  value={pdata.HoursofExp}
                  onChange={this.props.handleChange}
                  name="HoursofExp"
                >
                  <option
                    defaultValue={pdata.HoursofExp ? pdata.HoursofExp : 0}
                  >
                    {pdata.HoursofExp ? pdata.HoursofExp : 0}
                  </option>
                  <option value="0-100hrs">0-100hrs</option>
                  <option value="100hrs+">100hrs+</option>
                  <option value="500hrs+">500hrs+</option>
                  <option value="2,500hrs+">2,500hrs+</option>
                </select>
                  </div>
                </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Hourly Rate ($)</label>
                <Form.Control
                  style={{color:"black"}}
                  type="text"
                  placeholder="Hourly Rate"
                  value={pdata.HourlyRate}
                  onChange={this.props.handleChange}
                  name="HourlyRate"
                />
                </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label style={{ marginRight: "1.2rem" }}>
                  Membership of Professional Organizations</label>
                <input
                    type="checkbox"
                    defaultChecked={pdata.membership=== 1 ? true : false}
                    value={pdata.membership}
                    onClick={this.props.handleCheckbox}
                  />
                </div>
            </div>

            {/* <div className="col-md-12">
              <div className="form-group">
              <label style={{ marginRight: "1.2rem" }}>
                    I declare the information above is accurate
                  </label>
                  <input
                    type="checkbox"
                    required={true}
                    value={this.state.check}
                    onClick={this.handleCheckbox}
                  />
                </div>
            </div> */}


            <div className="col-12">
              <h4>Address Information</h4>
              <hr/>
            </div>

            <div className="col-md-12">
            <div className="form-group">
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleAddressChange}
                onSelect={this.handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <Form.Group>
                    <Form.Label>Location</Form.Label>
                    <Form.Control
                      style={{color:"black"}}
                      type="text"
                      {...getInputProps({
                        placeholder: this.state.address
                          ? this.state.address
                          : pdata.location
                          ? pdata.location
                          : "Search Location ...",
                        className: "location-search-input",
                      })}
                    />

                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}

                      {suggestions.length > 0 && (
                        <div className="autocomplete-suggestion-box">
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#ccc",
                                  cursor: "pointer",
                                  borderBottom: "1px solid #abacad",
                                  paddingBottom: "2px",
                                }
                              : {
                                  cursor: "pointer",
                                  borderBottom: "1px solid #abacad",
                                  paddingBottom: "2px",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </Form.Group>
                )}
              </PlacesAutocomplete>
              
            </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Country</label>
                <Form.Control
                    style={{color:"black"}}
                    type="text"
                    placeholder="Country"
                    value={
                      this.state.country ? this.state.country : pdata.country
                    }
                    disabled="true"
                    name="country"
                  />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>City</label>
                <Form.Control
                    style={{color:"black"}}
                    type="text"
                    placeholder="City"
                    value={this.state.city ? this.state.city : pdata.city}
                    name="city"
                  />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label>Timezone</label>
                <div className="selectBox">
                  <select
                    style={{color:"black"}}
                    className="form-control"
                    id="timezone-offset"
                    // style={{ color: "white" }}
                    value={pdata.timezone}
                    onChange={this.props.handleChange}
                    name="timezone"
                  >
                    <option defaultValue={pdata.timezone ? pdata.timezone : ""}>
                      {pdata.timezone ? pdata.timezone : ""}
                    </option>

                    {this.state.tzOptions &&
                      this.state.tzOptions.map((tz) => {
                        return <option value={tz}>{tz}</option>;
                      })}
                  </select>
                  <span>
                    <img src="images/dArrowblack.png" alt="" />
                  </span>
                </div>
              </div>
            </div>


            {/* <div className="col-md-12">
              <div className="form-group">
                <label>Address</label>
                <textarea name="" placeholder="" rows="3" type="text" className="form-control">116 Jaskolski Stravenue Suito 883</textarea> 
              </div>
            </div> */}




            {/* <div className="col-12">
              <h4>Social Media Information</h4>
              <hr/>
               <label>Social Media</label>
               <div className="availabilityTimeItem">
    <h5 style={{display:"inline",marginRight:"20px"}}>
    
      {this.state.SocialMediaInfo.map((x, i) => {
                  return (
                    <>
                      {this.state.SocialMediaInfo.length - 1 === i && (
                        <button
                          className="defaultBtn"
                          style={{
                            color: "green",
                            fontSize: "15px",
                            float: "right",
                          }}
                         
                          onClick={() =>{
                            this.setState(prevState => ({ 
                                  SocialMediaInfo: [...prevState.SocialMediaInfo,{SocialMedia:"",URL: ""}]
                                }))
                          }}
                        > Add </button>
                      )}
                    </>
                  );
                })}
    
            </h5>
              <hr/>
              <div id="content"></div>

              {this.state.SocialMediaInfo.map((x,i) =>(
               
               <div className="row">

                <div className="col-sm-4">
                  <div className="form-group">
                    <input type="text" 
                    style={{color:"black"}}
                    placeholder="Social Media"
                    name="SocialMedia" 
                    value={x.SocialMedia} 
                    onChange={(e) => this.handleInputChange(e, i,this.state.SocialMediaInfo)}
                    className="form-control"/>
                  </div>
                </div>
                <div className="col-sm-5 col-9">
                  <div className="form-group">
                    <input type="text"
                    style={{color:"black"}} 
                    placeholder="Social Media URL"
                    name="URL" 
                    value={x.URL}
                    onChange={(e) => this.handleInputChange(e, i,this.state.SocialMediaInfo)} 
                    className="form-control"/>
                  </div>
                </div>
                <div className="col-sm-2 col-3">

                   {this.state.SocialMediaInfo.length > 1 && (
                        <button
                          className="defaultBtn"
                          style={{
                            color:"green",
                            fontSize:"15px",
                            
                          }}
                          onClick={() =>{
                              let SocialMediaInfo = [...this.state.SocialMediaInfo];
                              SocialMediaInfo.splice(i, 1);
                              this.setState({ SocialMediaInfo });
                          }}
                        >Remove</button>
                      )}             

                  </div>
              </div>

              ))}
         </div>
            </div> */}

            <div className="col-md-12">
              <div className="form-group">
              <label style={{ marginRight: "1.2rem" }}>
                    I declare the information above is accurate
                  </label>
                  <input
                    type="checkbox"
                    required={true}
                    value={this.state.check}
                    onClick={this.handleCheckbox}
                  />
                </div>
            </div>
            
        
            <div className="col-12">
              <hr/>
              <button className="defaultBtn" type="submit" onClick={this.handleSubmit}>save</button>{" "}
              <button className="defaultBtn" onClick={this.handleClose}>Close</button>
            </div>

           </div>
         </form>
        </div>
      </div>
    </div>
  </div>
</section>
            </Modal.Body>
            {/* <Modal.Footer>
              <Button variant="primary" onClick={this.handleClose}>
                Close
              </Button>
             
              <Button
                variant="success"
                type="submit"
                onClick={this.handleCheck}
              >
                Submit
              </Button>
            </Modal.Footer> */}
          </Form>
        </Modal>
      </>
    );
  }
}

export default UpdatePersonal;
