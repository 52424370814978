import React,{useState} from 'react'
import TokenVerify, { FlashMess, GET_API, POST_API, Loader } from "../../helpers";
import { Container, Row, Col, Modal, Button, Form } from "react-bootstrap";
import axios from "../../config/axios";

const  Supportform = (props)=>{
    const [cateGory, setCateGory] = useState("");
    const [message, setMessage] = useState("");
    const [res, setRes] = useState("");
    const [isDisabled, setIsDisabled] = useState(false);
    const [status, setStatus] = useState("danger");
    const submitSupportForm = (e)=>{
      e.preventDefault();
      setIsDisabled(true);
      
      axios
      .post("webinar/submit-support-form", { cateGory, message }, {
        headers: {
          "X-Auth": localStorage.getItem("authToken")
        },
      })
      .then((res) => {
        setIsDisabled(false);
        if (res.data.success) {
            setRes("Thank you for submitting your query. Our Support team will get in touch with you shorlty.");
            setStatus("success");
            setCateGory("");
            setMessage("");
            setTimeout(function(){
              setRes("");
            },3000);
        } else if (res.data.errors && res.data.errors.length > 0) {
          let errors = [];
          console.log(res.data.errors);
          message.error("Failed to update profile");
          res.data.errors.map((item) => {
            errors[item.param] = item.msg;
          });
         
          console.log("errors", errors);
        } else {
            setRes(res.data.msg);
            setStatus("danger");
            setTimeout(function(){
              setRes("");
              setStatus("");
            },2000);
        }
      })
      .catch((err) => {
        setIsDisabled(false);
        setRes("There is some error while submitting the form, please try again later");
          setStatus("danger");
          setTimeout(function(){
            setRes("");
            setStatus("");
          },200);
        console.log("err", err);
      });
    }
  
    return (
      <>
      <Container className='mt-4'>
        <Row>
          <Col md={1}></Col>
          <Col md={8}>
          {res != "" && <div className={"alert alert-"+status}>{res}</div>  }
              <h2 className='modal_jobseeker_title'>How Can We Help ?</h2>
              <div className="seperator"></div>
              <form onSubmit={submitSupportForm} >
                <div className="form-group">
                  <label>Please Select <span className="text-danger">*</span></label> 
                  <select value={cateGory} onChange={(e)=>{
                    setCateGory(e.target.value);
                  }} className="form-control" required>
                      <option value="">Please Select</option>
                      <option value="Password Reset/Recovery">Password Reset/Recovery</option>
                      <option value="Update Profile Information">Update Profile Information</option>
                      <option value="Difficulty Accessing Certain Features">Difficulty Accessing Certain Features</option>
                      <option value="Report a Bug or System Error">Report a Bug or System Error</option>
                      <option value="Browser Compatibility Issues">Browser Compatibility Issues</option>
                      <option value="Need Help with User Interface Navigation">Need Help with User Interface Navigation</option>
                      <option value="General Inquiry/Other Issues">General Inquiry/Other Issues</option>
                  </select>  
                </div>
                <div className="form-group">
                  <label>Message <span className="text-danger">*</span></label> 
                    <textarea rows="6" maxlength="1000" value={message} onChange={(e)=>{
                    setMessage(e.target.value);
                  }}  required className="form-control"></textarea>
                </div>
  
                <div className="form-group">
                  <button className='btn btn-primary btn-md tabformbutton btnfix btn_total btn btn-primary' disabled={isDisabled}>Send Message</button>
                </div>
              </form>
              </Col>
              </Row>
              </Container>
              </>
    );
  }

  export default Supportform;
