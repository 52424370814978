import React from "react";
//import { Header, HeaderLogout } from "../../components/Headers";
//import { Footer, FooterLink } from "../../components/Footers";
import StripeCheckout from "react-stripe-checkout";
import axios from "../../config/axios";
//import Axios from 'axios'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Package from './packages'
import {
  tick,
  tickGray,
} from "../../assets/images";
import message from '../../utils/mesaages';
import { Link } from "react-router-dom";
toast.configure();


class PriceTable extends React.Component {
  constructor(props){
    super(props)
  }
   
  handlePremiumPlus=(token,addresses) =>{
      console.log(token,addresses)
        axios.post("/payment/checkout",{ token,Package:{ "type":"coach", "name": "Premium Plus",
        "price": "149",uid:this.props.location.state.user_t}})
            .then(response =>{
              const { status } = response.data;
              console.log("Response:", response.data);
              if (status === "success") {
                message.success("Success! Check email for details");
              } else {
                message.error("Something went wrong");
              }
            })
        
    }

    handlePremiumcoach=(token,addresses) =>{
  
      console.log(token,addresses)
        axios.post("/payment/checkout",{ token,Package:{ "type":"coach","name": "Premium coach",
        "price": "129",uid:this.props.location.state.user_t}})
            .then(response =>{
              const { status } = response.data;
              console.log("Response:", response.data);
              if (status === "success") {
                message.success("Success! Check email for details");
              } else {
                message.error("Something went wrong");
              }
            })
        
    }


    handlecoach=(token,addresses) =>{
  
      console.log(token,addresses)
        axios.post("/payment/checkout",{ token,Package:{"type":"coach", "name": "Coach",
        "price": "99",uid:this.props.location.state.user_t}})
            .then(response =>{
              const { status } = response.data;
              console.log("Response:", response.data);
              if (status === "success") {
                message.success("Success! Check email for details");
              } else {
                message.error("Something went wrong");
              }
            })
        
    }

 render() {
  console.log("CURR ENV : ", process.env.NODE_ENV)
  console.log("user uid",this.props.location.state.user_t)
    return (
      <div>
        
        
<section className="priceWrap">
          <div className="container">
            <div className="priceHolder">
              <div className="heading text-center mb-4">
                <h3>package options</h3>
                <span className="R_divider"></span>
              </div>
              <div className="priceTable">
                <ul className="priceHeading">
                  <li>Facilities</li>
                  <li>Premier Plus</li>
                  <li>Premier Coach</li>
                  <li>Coach</li>
                </ul>
                <ul className="priceItem">
                  <li>Free Trial with LG</li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                </ul>
                <ul className="priceItem">
                  <li>Can create profile</li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                </ul>
                <ul className="priceItem">
                  <li>
                    Can upload personal introducary video (2 mins long max){" "}
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                </ul>
                <ul className="priceItem">
                  <li>Uploads photos, videos PDFs &media </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                </ul>
                <ul className="priceItem">
                  <li>Can receive 1:1 coaching booking requests </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                </ul>
                <ul className="priceItem">
                  <li>Can schedule 1:1 coaching sessions </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                </ul>
                <ul className="priceItem">
                  <li>Can set own pricing </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                </ul>
                <ul className="priceItem">
                  <li>Ratings from clients from 1:1 private sessions </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                </ul>
                <ul className="priceItem">
                  <li>Receive client testimonials</li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                </ul>
                <ul className="priceItem">
                  <li>
                    Member of professional association badge on profile (be
                    found in seaches){" "}
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                </ul>
                <ul className="priceItem">
                  <li>Display logos of membership organisations on profile </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                </ul>
                <ul className="priceItem">
                  <li>
                    Submit material to feature in LG email marketing (LG News)
                    (written content){" "}
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                </ul>
                <ul className="priceItem">
                  <li>Receive exclusive offers & promotional offers </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                </ul>
                <ul className="priceItem">
                  <li>Be a featured Master Coach webinar speaker </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tickGray} alt="LG-Img"/>
                  </li>
                </ul>
                <ul className="priceItem">
                  <li>Upload webinars hosted</li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tickGray} alt="LG-Img"/>
                  </li>
                </ul>
                <ul className="priceItem">
                  <li>
                    Be a "Featured Coach" - LG website HP exposure - 'expanded
                    profile' feature (on rotation with other premium coaches)
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tickGray} alt="LG-Img"/>
                  </li>
                </ul>
                <ul className="priceItem">
                  <li>Reduced commision fees</li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tickGray} alt="LG-Img"/>
                  </li>
                </ul>
                <ul className="priceItem">
                  <li>
                    "Matched with coachees" service - leads sent directly to
                    inbox{" "}
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tickGray} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tickGray} alt="LG-Img"/>
                  </li>
                </ul>
                <ul className="priceItem">
                  <li>
                    Premium listing - preferential directory listing - top of
                    the listing/front page placings{" "}
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tickGray} alt="LG-Img"/>
                  </li>
                </ul>
                <ul className="priceItem">
                  <li>"X times hired" feature</li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                </ul>
                <ul className="priceItem">
                  <li>
                    Live (short) interview with Coach - front page exposure
                    (scheduled paid service - like advertorial) $500? one off
                    fee per time{" "}
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                  <li>
                    <img src={tick} alt="LG-Img"/>
                  </li>
                </ul>

                <ul className="priceItem">
                  <li>
                   
                  </li>
                  <li>
                        <Link className="defaultBtn"   to={{
                          pathname: "/buy-subscription",
                          state: { 
                            amount:Package[0].price,
                            name:Package[0].name,
                            duration:Package[0].duration
                            
                          }
                        }}>
                        BUY
                        </Link>
                  </li>
                  <li>
                        <Link className="defaultBtn"   to={{
                          pathname: "/buy-subscription",
                          state: { 
                            amount:Package[1].price,
                            name:Package[1].name,
                            duration:Package[1].duration
                            
                          }
                        }}>
                        BUY
                        </Link>
                  </li>
                  <li >
                        <Link className="defaultBtn"   to={{
                          pathname: "/buy-subscription",
                          state: { 
                            amount:Package[2].price,
                            name:Package[2].name,
                            duration:Package[2].duration
                            
                          }
                        }}>
                        BUY
                        </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
  
        
      </div>
    );
  }
}
export default PriceTable;
