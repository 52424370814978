import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios, { AdminApi } from "../config/axios";

import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import { Glogin, Facebooklogin } from "../views/auth/social";
import { toast } from "react-toastify";
import { Header, HeaderTop } from "../components/headers";
import { FooterLink } from "../components/footers";
import validation from "../Validate/validator";
import message from "../utils/mesaages";
import { SSO_LOGOUT } from '../utils/Sso';

import {
  getClientTimezone,
  timezoneUtc,
  UTCtoTimezone,
} from "../utils/timeZoneConvert";
import { SSO_LOGIN } from "../utils/Sso";

class RedirectLogin extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      username: "",
      password: "",
      isChecked: false,
      loader: false,
      FromDataError: {
        username: "false",
        password: "false",
      },
    };
  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {
        if (value == "false") {
          FromDataError[key] = "This field is required";
          sta.setState({ FromDataError });
        }

        valid = false;
      }
    }

    return valid;
  }

  authorizebyvideotoken(token) {
    let { params } = this.props.match;

    const hideMessage = message.loading(
      "Please wait while we are checking...",
      0
    );

    axios
      .put(`/authorizebyvideotoken/${token}/${params.type}`, {})
      .then((response) => {
        hideMessage();
        console.clear();
        console.log("res", response);
        if (response.data.success) {
          if (response.data.usertype === "coach") {
            // this.props.history.push('/profile-coach')

            localStorage.setItem("authToken", response.data.token);
            localStorage.setItem(
              "coachingCategory",
              response.data.userdata.coaching_category
            );

            localStorage.setItem(
              "userdata",
              JSON.stringify(response.data.userdata)
            );
            getClientTimezone();

            this.props.history.push({
              pathname: "/profile-coach",
              state: {
                loginType: "login mode",
                user: JSON.parse(localStorage.getItem("userdata")),
                token: localStorage.getItem("authToken"),
              },
            });
          }
          if (response.data.usertype === "user") {
            // console.log('rrrrrrrrrrrr',this.props)

            if (this.props.location.pathname.search("/rejected") > -1) {
              axios.post("/dataRecived", {
                session_id: token,
                status: "rejected",
              });

              this.props.history.push({
                pathname: "/profile-consumer",
                state: {
                  loginType: "login mode",
                  user: JSON.parse(localStorage.getItem("userdata")),
                  token: localStorage.getItem("authToken"),
                },
              });
            } else {
              axios.post("/dataRecived", { session_id: token, status: "end" });

              if (response.data.sessionType == "intro") {
                this.props.history.push({
                  pathname: "/profile-consumer",
                  state: {
                    loginType: "login mode",
                    user: JSON.parse(localStorage.getItem("userdata")),
                    token: localStorage.getItem("authToken"),
                  },
                });
              } else {
                this.props.history.push({
                  pathname: `/feedback/${params.sessionID}`,
                });
              }
            }
          }

          if (response.data.usertype === "jobseeker") {
            // console.log('rrrrrrrrrrrr',this.props)
            this.props.history.push({
              pathname: "/webinars",
              // state: {
              //   loginType: "login mode",
              //   user: JSON.parse(localStorage.getItem('userdata')),
              //   token: localStorage.getItem('authToken'),
              // },
            });
          }
          // message.success("Congratulations! Your account has been activated successfully!");
        } else {
          message.error("invalid video token");
        }
      })
      .catch((err) => {
        console.log(err);
        hideMessage();
        // message.error("Your account could not be activated.");
      });
  }

  activateAccount(token) {
    const hideMessage = message.loading(
      "Please wait while we are activating your account.",
      0
    );

    axios
      .put(`/activate/${token}`, {})
      .then((response) => {
        hideMessage();
        if (response.data.success) {
          message.success(
            "Congratulations! Your account has been activated successfully!"
          );

          axios
            .get(`${AdminApi}/signup/${token}`, {})
            .then(function (response) {
              console.log(response);
            })
            .catch(function (error) {
              console.log(error);
            });
          //
        } else {
          message.error("Your account could not be activated.");
        }
      })
      .catch((err) => {
        console.log(err);
        hideMessage();
        message.error("Your account could not be activated.");
      });
  }

  async componentDidMount() {
    console.log("pp", this.props);
    const token2 = localStorage.getItem("authToken");
    await SSO_LOGOUT();
    localStorage.clear();
    if (token2 != undefined) {
        let param = {
          url: "/users/logout",
          method: "POST",
          data: { token: token2 },
          headers: { "X-Auth": token2 },
        };
        await axios(param);
    }
    
    if (window.location.hash) {
      message.success("Logged out successfully.");
      var uri = window.location.toString();
      if (uri.indexOf("#") > 0) {
        var clean_uri = uri.substring(0, uri.indexOf("#"));
        window.history.replaceState({}, document.title, clean_uri);
      }
    }
    // else if(this.props.location.state !== undefined) {
    //   console.log("external url",this.props.location.state.externalUrl)
    //   let hrefValue = this.props.location.state.externalUrl
    //   if(localStorage.getItem('afterLoginHref') == null)
    //   localStorage.setItem('externalHref', hrefValue)
    // }
    else {
      let { params } = this.props.match;
      if (params.hasOwnProperty("token") && params.token.length > 30) {
        this.activateAccount(params.token);
      }

      if (params.hasOwnProperty("sessionID")) {
        this.authorizebyvideotoken(params.sessionID);
      }
      if (
        new URLSearchParams(this.props.location.search).get("acode") != null &&
        new URLSearchParams(this.props.location.search).get("acode") != ""
      ) {
        //SSO login
        let res = await SSO_LOGIN(
          new URLSearchParams(this.props.location.search).get("acode")
        );
        if (res == false) {
          message.error("User Not Found");
          setTimeout(() => {
            this.props.history.push({ pathname: "/login" });
          }, 3000);
        } else {
          if (res.data.result) {
            // after auto login from GWI jobseekers
            console.log("Jobseekers login");

            localStorage.setItem("authToken", res.data.token);
            localStorage.setItem(
              "coachingCategory",
              res.data.userdata.coaching_category
            );
            localStorage.setItem("userdata", JSON.stringify(res.data.userdata));
            localStorage.setItem("ML_TOKEN", res.data.ML_token);
            getClientTimezone();
            this.setState({ loader: false });
            if (res.data.userdata.account_type === "jobseeker") {
              let pathname = "/webinars";
              localStorage.setItem("sessions", "N");
              if (new URLSearchParams(this.props.location.search).get("slug") == "sessions") {
                // pathname = "/webinars/sessions"
                pathname = "/webinars/careermaster-videos";
                localStorage.setItem("sessions", "Y");
              }
              this.props.history.push({
                pathname: pathname,
                state: {
                  user: res.data.userdata,
                  token: res.data.token,
                },
              });
            }
          } else {
            message.error("User Not Found");
            setTimeout(() => {
              this.props.history.push({ pathname: "/login" });
            }, 3000);
          }
        }
        //SSO login
      }
    }
  }

  // componentWillReceiveProps() {
  //   AOS.refresh();
  // }

  handleChange = (e) => {
    e.persist();
    let { FromDataError } = this.state;
    var valid_obj = {
      value: e.target.value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((error) => {
      console.log("aaaaaaaaaaa", error);
      FromDataError[e.target.name] = error;
      this.setState({ FromDataError });

      setTimeout(() => {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }, 100);
    });
  };

  handleCheckbox = (e) => {
    this.setState({
      isChecked: e.target.checked,
    });
  };

  getUserIdusingRoomID = (roomId) => {
    let dataReceived = axios
      .get(`/fetchUserId/${roomId}`)
      .then((res) => {
        return res.data.fetchedUserid;
      })
      .catch((err) => {
        return null;
      });
    return dataReceived;
  };

  handleSubmit = (e) => {
    console.log("hi there");
    const hideMessage = message.loading("Please wait while logging in ...", 0);
    if (this.validateForm(this.state.FromDataError)) {
      this.setState({ loader: true });
      e.preventDefault();
      const formData = {
        username: this.state.username,
        password: this.state.password,
        isChecked: this.state.isChecked,
      };

      axios
        .post("/login", formData)
        .then(async (response) => {
          hideMessage();
          console.log("res", response.data);
          if (
            response.data.token !== undefined &&
            response.data.result === true
          ) {
            localStorage.setItem("authToken", response.data.token);
            localStorage.setItem(
              "coachingCategory",
              response.data.userdata.coaching_category
            );

            localStorage.setItem(
              "userdata",
              JSON.stringify(response.data.userdata)
            );
            localStorage.setItem("currency", response.data.userdata.currency);
            getClientTimezone();
            this.setState({ loader: false });
            if (response.data.userdata.account_type === "coach") {
              localStorage.removeItem("feedbackHref");
              const accontCreated = new Date(response.data.userdata.created);
              const finalDate = new Date(accontCreated);
              finalDate.setDate(accontCreated.getDate() + 90);
              var countDownDate = new Date(finalDate);
              var now = new Date();
              var timeleft = countDownDate - now;
              var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
              var freeTrialDaysleft = days >= 0 ? days : -1;

              if (
                freeTrialDaysleft == -1 &&
                response.data.valueCoach.membership_id === null
              ) {
                localStorage.setItem("membershipExpCheck", true);
              }
              let redirectUrl = localStorage.getItem("afterLoginHref");
              let externalHref = localStorage.getItem("externalHref");
              if (localStorage.getItem("afterLoginHref") != null) {
                localStorage.removeItem("afterLoginHref");
                this.props.history.push({
                  pathname: `/profile-coach-view/${redirectUrl}`,
                  state: {
                    user: response.data.userdata,
                    token: response.data.token,
                  },
                });
              } else if (localStorage.getItem("externalHref") != null) {
                localStorage.removeItem("externalHref");
                window.location = externalHref;
              } else {
                this.props.history.push({
                  pathname: "/profile-coach",
                  state: {
                    loginType: "login mode",
                    user: response.data.userdata,
                    token: response.data.token,
                  },
                });
              }
            } else if (response.data.userdata.account_type === "user") {
              let redirectUrl = localStorage.getItem("afterLoginHref");
              let externalHref = localStorage.getItem("externalHref");
              if (localStorage.getItem("afterLoginHref") != null) {
                localStorage.removeItem("afterLoginHref");
                this.props.history.push({
                  pathname: `/profile-coach-view/${redirectUrl}`,
                  state: {
                    user: response.data.userdata,
                    token: response.data.token,
                  },
                });
              } else if (localStorage.getItem("externalHref") != null) {
                localStorage.removeItem("externalHref");
                window.location = externalHref;
              } else if (localStorage.getItem("feedbackHref") != null) {
                let theUrl = localStorage.getItem("feedbackHref");
                let roomId = theUrl.split("/").pop();
                let fetchedUserID = await this.getUserIdusingRoomID(roomId);
                if (
                  fetchedUserID !== null &&
                  fetchedUserID !== "deleted" &&
                  fetchedUserID === response.data.userdata.uid
                ) {
                  window.location = theUrl;
                } else {
                  localStorage.removeItem("feedbackHref");
                  this.props.history.push({
                    pathname: "/profile-consumer",
                    state: {
                      user: response.data.userdata,
                      token: response.data.token,
                    },
                  });
                }
              } else {
                this.props.history.push({
                  pathname: "/profile-consumer",
                  state: {
                    user: response.data.userdata,
                    token: response.data.token,
                  },
                });
              }
            }
          } else if (response.data.result === false) {
            message.error(response.data.msg);
            this.setState({ loader: false });
          } else {
            this.setState({ loader: false });
          }
        })
        .catch((error) => {
          hideMessage();
          console.log(error);
          message.error("Error loggin in.");
          this.setState({ loader: false });
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="d-flex align-items-center justify-content-center h-100">
          <h4>You are being redirected to Coach Master, Please wait......</h4>
        </div>
      </React.Fragment>
    );
  }
}

export default RedirectLogin;
