import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { writingSvg } from "../../../assets/images";

class AddTag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: "",
      setShow: false,
      tags: "",
    };
  }

  handleShow = (e) => {
    this.setState({ setShow: !this.state.setShow });
  };

  handleChage = (e) => {
    e.persist();
    this.setState({ tags: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      tags: this.state.tags,
    };
    this.props.handleUpdateTags(formData);
    this.handleShow();
  };

  render() {
    return (
      <>
        <h3
          className="popup-with-zoom-anim edit_item"
          data-toggle="tooltip"
          data-placement="top"
          title="Add/Update New Tag"
          onClick={this.handleShow}
          style={{color: "#734d9d"}}
        >
          MANAGE TAGSgggg<img width="18px" src={writingSvg} alt="LG-Img" />
        </h3>

        <Modal
          show={this.state.setShow}
          onHide={this.handleShow}
          backdrop="static"
          keyboard={false}
        >
          <Form onSubmit={this.props.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title style={{color:"black"}}>Input your niche, specialties, experience & training etc<br/>
              <small>These tags will be used in search criteria</small></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group style={{color:"black"}}>
                <label for="form7">Tag Name</label>
                <br />
                <small>
                  <b>*</b>Add multiple tags separated by comma (,)
                </small>
                <div className="md-form">
                  <textarea
                    style={{color:"black"}}
                    id="form7"
                    className="md-textarea form-control"
                    rows="3"
                    placeholder="Enter tag name here...."
                    defaultValue={this.props.tags}
                    onChange={this.handleChage}
                    required={true}
                  ></textarea>
                </div>
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={this.handleShow}>
                Close
              </Button>
              <Button
                variant="success"
                type="submit"
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}
export default AddTag;
