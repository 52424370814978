import React from 'react'
import LoadingOverlay from "react-loading-overlay";
import { blog1, dIcon, LGspinner } from '../../assets/images'
import articles from './articlesList'
import CustomImageLoader from 'react-custom-image-loader.'
import axios from '../../config/axios';



class articles_aboutus extends React.Component{
  constructor(props, context) {
    super(props, context);
    this.state = {
      articlesData: [],
      loader: false,
    }
  }

  createMarkup = (data) => {
    return {__html: data};
  }
//https://blog.lifeguru.me/wp-json/wp/v2/posts?order=desc&orderby=date&per_page=4&_embed
  componentDidMount() {
    this.setState({loader: true})

    const URI = `/cmshome/articles/featured`
    axios.get(URI)
      .then(response => {
        // console.clear()
        // console.log(response.data);
        const data = response.data
        if(data.success) {
          const articles = data.articles
          // console.log(articles);
          
          let getPostsDetailsInfoURI = `https://blog.lifeguru.me/wp-json/wp/v2/posts?`

          if(articles && articles.length > 0) {

            articles.forEach(featuredArticle => {
              getPostsDetailsInfoURI += `include[]=${featuredArticle.post_id}&`
            })
            getPostsDetailsInfoURI += "_embed"
  
            // console.log(getPostsDetailsInfoURI);
            fetch(getPostsDetailsInfoURI)
            .then(res => res.json())
            .then((data) => {
              // console.log("Data in here: ==> "+data);
              this.setState({ articlesData: data, loader: false })
              //console.log(this.state.articlesData)
            })
            .catch((e) => {
              console.log("ARTICLES FETCH ERROR",e)
              this.setState({loader: false})
            })

          } else {
            console.log("No articles feched");
            this.setState({loader: false})
          }

          

        }
      })
      .catch(e => {
        console.log("featured ARTICLES fetching from Coach Master ERROR",e)
        this.setState({loader: false})
      })


    // fetch('https://blog.lifeguru.me/wp-json/wp/v2/posts?order=desc&orderby=date&_embed')
    // fetch('https://blog.lifeguru.me/wp-json/wp/v2/posts?order=desc&orderby=date&per_page=4&categories=11&_embed')
    // .then(res => res.json())
    // .then((data) => {
    //   this.setState({ articlesData: data, loader: false })
    //   //console.log(this.state.articlesData)
    // })
    // .catch((e) => {
    //   console.log("ARTICLES FETCH ERROR",e)
    //   this.setState({loader: false})
    // })
  }

    render(){
        return(
            <div>
                <section className="blogWrap">
                <LoadingOverlay 
                active={this.state.loader} 
                //spinner
                spinner={<CustomImageLoader image={LGspinner} animationType={'pulse'}/>}
                >
  <div className="container text-center">
    <h3 className="article">Articles</h3>
    <span className="R_divider"></span>

    <div className="row">
        {/* {articles.map(article =>{
            return(
      <div className="col-lg-3 col-sm-6">
        <div className="blogContent">
          <img src={blog1} alt="LG-Img" className="img-fluid"/>
          <div className="bInfo">
        <h5 style={{color:"black"}}>{article.name}</h5>
            <img className="my-2" src={dIcon} alt="LG-Img"/>
            <p>{article.short_bio}</p>
          </div>
          <div className="bFooter">
            <p>
              <i className="color-main fa fa-user "></i>
              <span>Emma</span>
            </p>
            <p>
              <i className="color-main fa fa-calendar"></i>
              <span>August 7, 2019</span>
            </p>
            <p>
              <i className="color-main fa fa-tag"></i>
              <span>Post</span>
            </p>
          </div>
        </div>
      </div>
            )
        })} */}
        {this.state.articlesData.slice(0,4).map(article =>{
          // console.log("json data", article._embedded["wp:featuredmedia"])
          // console.log("json data author name", article._embedded.author[0].name)
            return(
              <div key={article.id} className="col-lg-3 col-sm-6 mb-3">
                <a href={article.link} style={{textDecoration: "none"}} target="_blank">
                <div className="blogContent">
                
                  <img src={article._embedded["wp:featuredmedia"] === undefined ? blog1 : article._embedded["wp:featuredmedia"][0].source_url} alt="LG-Img" className="img-fluid" style={{height:"160px", width:"280px"}}/>
                  <div className="bInfo">
                {/* <h5 style={{color:"black"}}>{article.title.rendered}</h5> */}
                <h5 style={{color:"black"}}>{`${article.title.rendered.substring(0, 40)}...`}</h5>
                    <img className="my-2" src={dIcon} alt="LG-Img"/>
                    {/* <p>{article.excerpt.rendered}</p> */}
                    <div dangerouslySetInnerHTML={this.createMarkup(`${article.excerpt.rendered.substring(0,85)}...`)}/>
                  </div>
                  <div className="bFooter">
                    <p>
                      <i className="color-main fa fa-user "></i>
                      <span>{article._embedded.author[0].name}</span>
                    </p>
                    <p>
                      <i className="color-main fa fa-calendar"></i>
                      <span>{article.date.slice(0,article.date.indexOf('T'))}</span>
                    </p>
                    <p>
                      <i className="color-main fa fa-tag"></i>
                      <span>Post</span>
                    </p>
                  </div>
                </div>
                </a>
              </div>
            )
        })}


    </div>
  </div>
  </LoadingOverlay>
</section>

            </div>
        )
    }
}

export default articles_aboutus