import axios from "./config/axios";
import { APPS } from "./constants"

export const getApp = () => {
    let subdomain = window.location.hostname;
    //const subdomain = getSubdomain(window.location.hostname)

    const main = APPS.find((app) => app.main);
    if (!main) throw new Error("Must have main app")
    if (subdomain === "") {
        axios.defaults.headers.common['corporate'] = false
        return main.app;
    } 
    const app = APPS.find(app => subdomain === app.subdomain);
    if (app) axios.defaults.headers.common['corporate'] = true
    if (!app) {
        axios.defaults.headers.common['corporate'] = false
        return main.app
    }
    return app.app
}

const getSubdomain = (location) => {
    const locationParts = location.split(".")

    let sliceTill = -3
    const isLocalHost = locationParts.slice(-2)[0] === "coachmaster";


    if (isLocalHost) sliceTill = -2
    return locationParts.slice(0, sliceTill).join("");

}