module.exports=[
    {
        "name":'Franki goes to… The Philippines & Indonesia',
        "short_bio":"In this blog series titled ‘Franki goes to…’ we follow her travels around the world…"
    },
    {
        "name":'Franki goes to… The Philippines & Indonesia',
        "short_bio":"In this blog series titled ‘Franki goes to…’ we follow her travels around the world…"
    },
    {
        "name":'Franki goes to… The Philippines & Indonesia',
        "short_bio":"In this blog series titled ‘Franki goes to…’ we follow her travels around the world…"
    },
    {
        "name":'Franki goes to… The Philippines & Indonesia',
        "short_bio":"In this blog series titled ‘Franki goes to…’ we follow her travels around the world…"
    }
]