import React, { useEffect, useState } from "react";

// import { loginImg } from "../../assets/images";
import { NavLink } from "react-router-dom";
import { loginImg, personImg } from "../../assets/images";
import axios from "../../config/axios";
import message from '../../utils/mesaages';
import { SSO_LOGOUT } from '../../utils/Sso'

function HeaderLogout(props) {

  useEffect(() => {
    if (localStorage.getItem("userdata") !== null && JSON.parse(localStorage.getItem("userdata")).account_type === "coach") {
      axios.post(`/validateuser`, { id: JSON.parse(localStorage.getItem("userdata")).id, uid: JSON.parse(localStorage.getItem("userdata")).uid }).then(({data}) => {
        if (data.success) {
          if ((data.deta.status === 0) || (data.deta.is_active === 0)) {
            message.error("Your account has been deactivated")
            logOutUser()
          }
        }
      }).catch((error) => {
        console.log("Validate user error: ",error)
      })
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem("userdata") !== null && JSON.parse(localStorage.getItem("userdata")).account_type === "user" && JSON.parse(localStorage.getItem("userdata")).is_approved !== "1") {
      let path = window.location.href.split('/');
      let url = path.pop()
      let includes = ["", 'about_us', "for-coaches", "packages_list", "findmy_coach", "matchmy_coach", "faq", "contact_us", "webinar", "terms", "privacy-policy", "gdpr", "california-privacy-policy"]
      if (!includes.includes(url))
        window.location = '/coming-soon'
    }
    fetchUserName()
  }, [])

  let currencyVal = localStorage.getItem('currency')
  let token = localStorage.getItem('authToken')
  const [bgColour, setBgColour] = useState("#fafafa")

  const dashBoardButtonStyles = {
    cursor: "pointer",
    color: `${bgColour}`
  }
  const list = ["USD", "EUR", "GBP"];
  // console.log("currency val", currencyVal)

  const currencyChoice = list.map((currency) => (
    <option key={currency} value={currency} selected={currency == currencyVal}>
      {currency}
    </option>
  ));

  let logOutUser = async () => {
    const hideMessage = message.loading('Please wait while logging you out', 0)

    const token = localStorage.getItem("authToken");
    await SSO_LOGOUT()
    localStorage.clear();
    if (token !== undefined) {
      let param = {
        url: "/users/logout",
        method: "POST",
        data: { token: token },
        headers: { "X-Auth": token },
      };
      await axios(param)
    }
    hideMessage()
    window.location = '/login#logout'
  }

  let [currency, setCurrency] = useState(currencyVal)
  let [userName, setUserName] = useState('')

  let fetchUserName = () => {
    let param = {
      url: "/users/username",
      method: "GET",
      headers: {
        "X-Auth": token
      },
    };
    axios(param)
      .then(async (res) => {
        if (res.data.value) {
          const rawdata = res.data.value;
          const fullname = rawdata.name
          setUserName(fullname);
        }
      })
      .catch((error) => {
        console.log(error);
        message.error("Error getting User data");
      });
  }

  let updateCurrency = (e) => {
    let selectedCurrency = e.target.value
    // this axios request for both user or coach
    axios.post(`/consumer/set-currency`, { currency: e.target.value }, {
      headers: { "x-auth": token }
    }).then(async function (res) {
      if (res.data.success) {
        localStorage.setItem('currency', selectedCurrency)
        setCurrency(selectedCurrency)

        setTimeout(() => {
          window.location.reload()
        }, 2000);
      }
    }).catch(function (err) {
      console.log("err", err)
    })
  }

  let handleRedirect = (userType) => {
    if (userType == 'profileCoach') {
      let path = window.location.href.split('/');
      let url = path.pop()
      //console.log("url", url)
      if (url == 'profile-coach') {
        //props.userDashboard("UserDashboard")
        window.location.reload()
      } else {
        window.location = 'profile-coach'
      }
    } else {
      let path = window.location.href.split('/');
      let url = path.pop()
      //console.log("url", url)
      if (url == 'profile-consumer') {
        //props.userDashboard("UserDashboard")
        window.location.reload()
      } else {
        if((localStorage.getItem("sessions") != undefined && localStorage.getItem("sessions") == "Y" ) ){
          window.location = '/webinars/recordings'
        }else{
          window.location = '/webinars'
        } 
      }
    }
  }

  return (

    <section className="headerTopWrap">
      <div className="container">
        <div className="row">

          <div className="col-sm-4">
            <ul className="socialIcon" style={{"display":"none"}}>


              {localStorage.getItem("userdata") === null ? "" : (
                <li className="nav-item">
                  {/* Welcome, {JSON.parse(localStorage.getItem("userdata")).name} */}
                  Welcome, {userName}
                </li>
              )}

            </ul>
          </div>

          <div className="col-sm-8">

            <div className="topbarAction text-right margin-logout">
              
              {/* <select className="form-control-sm" style={{ display: 'inline-block', width: '80px', minHeight: '28px', height: 'unset' }}
                value={currency} onChange={(e) => updateCurrency(e)}>
                {currencyChoice}
              </select>  */}

              {localStorage.getItem("authToken") !== null ? (
                <>
                  {localStorage.getItem("userdata") !== null &&
                    JSON.parse(localStorage.getItem("userdata")).account_type === "coach" ? (

                    <NavLink to="/profile-coach"><i className="fa fa-window-maximize"></i> Dashboard</NavLink>
                    // <a 
                    // onClick={() => handleRedirect("profileCoach")} 
                    // style={dashBoardButtonStyles}
                    // onMouseEnter={() => setBgColour("#82BD51")}
                    // onMouseLeave={() => setBgColour("#fafafa")}
                    // >
                    //   Dashboard
                    // </a>
                  ) : (
                    // <NavLink to="/profile-consumer">Dashboard</NavLink>
                    // <a onClick={() => props.userDashboard("UserDashboard")} style={{cursor: "pointer"}}>Dashboard</a>
                    <span className="top_nav_link" onClick={() => handleRedirect("profileConsumer")}
                    //style={dashBoardButtonStyles}
                    ><i className="fa fa-window-maximize"></i> Dashboard</span>
                  )}
                </>

                ) : ("")
              }
              <span onClick={() => logOutUser()} className="top_nav_link"><i className="fa fa-sign-out"></i> Logout</span>             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default (HeaderLogout);
