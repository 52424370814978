import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from '../../config/axios'
import { Tooltip } from 'antd';
import message from '../../utils/mesaages';
import {curencyFilter,curencyRates} from '../../utils/currencyConvert';
export default class CancelConfirmModal extends React.Component {
    constructor(props) {
        super(props)
        this.state={
          rates:''
        }
    }
    async componentWillMount() {

      let rates = await curencyRates()
      this.setState({rates})
    }
  

//   if (props.fun_name === "Delete") {
//     func_button = (
//       <li className="alert__confirm">
//         <button
//           className="btn btn-success"
//         //   onClick={() => {
//         //     props.handleDeleteIntroVideo();
//         //   }}
//         >
//           Confirm
//         </button>
//       </li>
//     );
//   }

 cancelDelete = (e) => {
   
    var self=this;
       
    this.props.close()
   
    const hidemsg = message.loading(`Please wait.`,0)
    let from={coach_id:e.coach_uid,
              pack_id:e.package_uid,
              user_uid:e.user_uid,
              uid:e.uid,
              package_name:this.props.package_title,
              sessionDate:this.props.IntroDate,
              sessionTime:this.props.IntroTime,
            }
    console.log('ccccc',from)
         
        axios.post(`/users/session/cancel-intro-booking`,from)
        .then( async(res) =>{
            //console.log('get-unavailable',res.data);
            if(res.data.success){
                hidemsg()
            
                message.success("Your purchase has been cancelled");  
                this.props.getBooking()
               
            }
            else
            {
                hidemsg()
                message.error(res.data.msg);  
                this.props.close()
            }
        })
        // .catch(err =>{
        //     console.log(err)
        //     message.error(err.data.msg);   
        // })

   

    }
render() {
  return (
    <>
      {/* <button className="btn btn-sm" onClick={handleShow}>
        <i className={props.icon}></i>{" "}
        {props.fun_name}
      </button> */}
     {/* <Tooltip title={props.helpText} placement="right" className="float-right"> <button className="defaultBtn rc" onClick={handleShow}>Remove Account</button></Tooltip> */}
        {/* <div
          data-toggle="tooltip"
          data-placement="top"
          title="Delete Personal Introductory Video"
          className="intro-video-delete-icon"
          onClick={handleShow}
        >
          <i className="fa fa-trash" aria-hidden="true"></i>
        </div> */}
      <Modal
        show={this.props.show}
        onHide={this.props.close}
        keyboard={false}
        // className="modal  confirmation"
      >
        {/* <div className="modal-content"> */}
        <Modal.Body style={{
          paddingTop: 20,
          marginLeft: 10,
          marginTop: 10
        }}>
         
          <p style={{color:"black",fontSize:"15px" , marginBottom:"0em", textAlign:"left"}}><b style={{ fontSize: 18 }}>Package Name:</b> {this.props.package_title}</p>
          <p style={{color:"black",fontSize:"15px", marginBottom:"0em", textAlign:"left"}}><b style={{ fontSize: 18 }}>Session:</b> {this.props.introbookingDetails.package_type=="Single Session"?this.props.introbookingDetails.package_type:this.props.introbookingDetails.package_type+ ' ('+this.props.introbookingDetails.totalSession+')'}</p>
          <p style={{color:"black",fontSize:"15px", marginBottom:"0em", textAlign:"left"}}><b style={{ fontSize: 18 }}>Price:</b> {curencyFilter(this.props.introbookingDetails.package_price,'',this.state.rates)}</p>
          <br/>
          <p style={{color:"black",fontSize:"15px", marginBottom:"0em", textAlign:"left"}}>Yes I want to cancel this purchase</p>
          {/* <ul className="d-flex justify-content-between">
            <li className="alert__cancel">
            <button
                className="btn btn-secondary"
                // href="javascript:void(0)"
                //data-dismiss="modal"
                 onClick={this.props.close}
              >
                Exit
              </button>
            </li>
           
            <li ><button
                className="btn btn-success"
                
                 onClick={()=>this.cancelDelete(this.props.introbookingDetails)}
              >
                Confirm Cancellation
              </button></li>

          </ul> */}
       </Modal.Body>
       <Modal.Footer>

          <button
                className="btn btn-secondary"
                // href="javascript:void(0)"
                //data-dismiss="modal"
                 onClick={this.props.close}
              >
                Exit
          </button>
            
          <button
            className="btn btn-success"                
            onClick={()=>this.cancelDelete(this.props.introbookingDetails)}
          >
            Confirm Cancellation
          </button>
         
       </Modal.Footer>
      </Modal>
    </>
  );
}
}

