import React from "react";
import { Link } from "react-router-dom";
import { FooterLink } from "../components/footers";
import { Header, HeaderLogout, HeaderTop } from "../components/headers";
import axios from "../config/axios";
import { Subscribe } from "../views/landing/Index";


class Terms extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
        termsdata: []
    }
  }

  createMarkup = (data) => {
    return {__html: data};
  }

  componentDidMount() {
    //window.scrollTo(0,0);
    this.getTermsContent()
  }

  getTermsContent = () => {
    let self = this
    axios.get('/cmsfooter/footerContent')
    .then(async (response) => {
      if(response.status === 200){
        self.setState({
          termsdata: response.data.value.terms_conditions[0],
          content:response.data.value.terms_conditions[0].content_json,
          termval:response.data.terms_conditionscont
        })
        console.log('uu',this.state.content)
      }
    })
    .catch((e) => {
      console.log(e)
    })
  }

  render() {
    return (
        
      <div>
        {localStorage.getItem('authToken')==null ?(<HeaderTop/>):(<HeaderLogout/>)}
        <Header />
        <section className="upcomingWrap">
          <div className="container">
            <div className="webinnerContent text-center">
              <div
                className="heading text-center"
              >
                <h3>Terms and Conditions</h3>
                <br />                
                <span className="R_divider"></span>
              </div>
                <div className="text-left" dangerouslySetInnerHTML={{__html: this.state.termval}}/>
              <br />
              <br />
              
            </div>
          </div>
        </section>
        <FooterLink/>
      </div>
      
    );
  }
}

export default Terms;
