import React,{useEffect,useState} from 'react'
import { Col, Row } from 'react-bootstrap'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import axios from "../../../config/axios";
import {STRIPE_KEY} from "../../../config/custom";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from 'react-custom-image-loader.'
import { LGspinner } from '../../../assets/images';

export default function PaymentModule(props) {
    const [showLoader,setShowLoader] = useState(false);
    const [plans,setPlans] = useState([]);
    const [selectedPlan,setSelectedPlan] = useState({});
    const [user,setUser] = useState({});
    // let userdata = JSON.parse(localStorage.getItem('userdata'));
   // const yourDate = moment(userdata.created);
    // const isInDecember = yourDate.month() <= 3; 
   // const isInDecember = (yourDate.month() <= 3 && (userdata.stripe_customer_id == "" || userdata.stripe_customer_id == null) && userdata.registration_source != "NEXGEN"); 
    // const isInDecember = (yourDate.month() <= 3 && userdata.has_trial_used == 0); 
    
    const stripePromise = loadStripe(STRIPE_KEY);
    const getPlans = ()=>{
        setShowLoader(true)
		axios.post('/webinar/get-plan-for-users', {}, { headers: { "x-auth": localStorage.getItem("authToken")}, }).then((res) => {
		    setShowLoader(false)
            if(res.data.success){
                    setPlans(res.data.data);
                    setUser(res.data.user);
                    let p = res.data.data.find((item)=>item.id == res.data.user.plan_id)
                    if(p){
                        setSelectedPlan(p);
                    }else{
                        setSelectedPlan(res.data.data[0]);
                    }
                }else{
                    setPlans([]);
                    setUser({});
                }
            }).catch((err) => {
                setShowLoader(false)
                console.log("Error while fetching the plans",err)
            })
    }

    useEffect(()=>{
        getPlans();
    },[])
    return (
        <>
        <LoadingOverlay        
                  active={showLoader}
                  // spinner={<BounceLoader />}
                  spinner={<div><CustomImageLoader image={LGspinner} animationType={'pulse'}/><br/></div>}
                  text='Loading your Plans...'
                >
            <Row>
                <Col md={2}></Col>
                <Col md={9}>
                    <br />
                    <br />
                    <h2>Pricing</h2>
                    
                    <ul style={{'list-style' : "none"}}>
                        <li><i className="fa fa-check" aria-hidden="true"></i> &nbsp;Join all webinar events</li>
                        <li> <i className="fa fa-check" aria-hidden="true"></i> &nbsp;Access all on-demand videos </li>
                        <li> <i className="fa fa-check" aria-hidden="true"></i> &nbsp;New live webinar events being scheduled every week </li>
                        <li><i className="fa fa-check" aria-hidden="true"></i> &nbsp;Explore inspiring speakers and life-enhancing coaches </li>
                        <li><i className="fa fa-check" aria-hidden="true"></i> &nbsp;Cancel your subscription any time </li>
                    </ul>
                    
                    {   plans.length > 0 && plans.map((item,index)=>{
                            return <>
                            <Row>
                                <Col md="8">
                                <h4 key={index}>{item.plan_name} 
                                </h4>
                            </Col>
                            <Col md={4}> <span className='btn btn-light' style={selectedPlan.id == item.id ? {"border": "3px solid orange","margin-left": "1rem"} : {"margin-left": "1rem"}}  onClick={()=>{
                                    setSelectedPlan(item);
                                }}>{selectedPlan.id == item.id ? "Select" : "Select"}</span></Col>
                            </Row>
                            </>
                        })
                    }
                    
                <br />
                <div className='new_card' >
                    <div style={{"width":"500px"}}>
                    <Elements stripe={stripePromise}>
                        <CheckoutForm totalAmount={selectedPlan?.offer_price} selectedPlan={selectedPlan} setShowLoader = {setShowLoader}/>
                    </Elements>
                    </div>
                </div>

                
                </Col>
                </Row>
                </LoadingOverlay>
                </>
    )
}
