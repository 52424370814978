import React from 'react'
import {Link} from 'react-router-dom'
import axios from '../../config/axios';
import RSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import TagsInput from "../../layouts/TagsInput";


const customStyles =  {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333"
      };
    }
  };

class Skills extends React.Component{
    constructor() {
        super()
        this.state = {
            tags: [],
            //selectedValue:[],
            setinputtagg: [],
        }
         //this.fetchTagsFromApi =  this.fetchTagsFromApi.bind(this)
         //this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
      //this.fetchInitialtags()
    }

    // fetchInitialtags = inputValue => {
    //   let self = this
    //   return new Promise(resolve => {
    //     axios.post("/consumer/filterSkills", { tags: 'a' })
    //       .then(res => {
    //         console.log("Res-", res)
    //         let tags = res.data.map(d => ({ value: d, label: d }))
    //         // return resolve(tags);
    //         self.setState({ selectedValue: tags.slice(0,20) })
    //         //console.log(self.state.tags)
    //       })
    //       .catch(err => {
    //         console.log(err)
    //       })
  
    //   });
  
    // }

    // fetchTagsFromApi = inputValue =>
    // {
    //     let self = this
    //     return   new Promise(resolve => {
    //         axios.post("/consumer/filterSkills", {tags: inputValue})
    //         .then(res =>{
    //         console.log("Res-",res)
    //          let tags = res.data.map(d=>({ value: d, label: d }))
    //          return  resolve(tags);
    //         //  self.setState({tags})
    //           //console.log(self.state.tags)
    //         })
    //         .catch(err =>{
    //           console.log(err)
    //         })
        
    //       });

    // }

    // handleChange(value, { action, removedValue }) {
    //     switch (action) {
    //       case 'remove-value':
    //       case 'pop-value':
    //         if (removedValue.isFixed !== undefined) {
    //           return;
    //         }
    //         break;
    //       case 'clear':
    //         value = []
    //         break;
    //     }
    
 
    //     this.setState({ selectedValue: value });
    //     this.props.data.skills = value
    //   }


      setinputtag = async(t) => {
        let arrayValue = [...this.state.setinputtagg, t]
        await this.setState({ setinputtagg: arrayValue })
        this.props.data.skills = this.state.setinputtagg
      }

      deleteinputtag = async(i) => {
        const { setinputtagg } = this.state;
        const newTags = setinputtagg.filter((tag, j) => i !== j);
        await this.setState({
          setinputtagg: newTags
        });
        this.props.data.skills = this.state.setinputtagg
    
      }

    render(){
        return(
            <div>
    <fieldset>
        <div className="heading text-center">
          <h3 className="fs-subtitle">What specialist skills are required?</h3>
          <span className="R_divider"></span>
        </div>
                <div className="requiredSkills searchFilter ">
          <div className="selectBox w-75 mx-auto skillField">
              {/* <input type="text"
              placeholder="Enter Skill Required"
               className="form-control"
               //value={this.props.data.skills} 
               //onChange={this.props.handleChange}
               onChange={this.fetchTagsFromApi}  
               name="skills"/> */}
                {/* <AsyncSelect
        value={this.props.data.skills}
        isMulti
        styles={customStyles}
        isClearable={true}
        name="colors"
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={this.handleChange}
        defaultOptions={this.state.selectedValue}
        loadOptions={this.fetchTagsFromApi}
        //onMenuOpen={this.fetchInitialtags}
                /> */}
                <TagsInput
                      setinputtag={this.setinputtag}
                      deleteinputtag={this.deleteinputtag}
                      setinputtagg={this.state.setinputtagg}
                    >
                    </TagsInput>
                
            </div>
        </div>
        <Link
        to="#"
          onClick={() =>
          this.props.handleField("CoachingArea")
          }
         className="defaultBtn">PREVIOUS
        </Link> {" "}
       
        <Link
        to="#"
          onClick={() =>
          this.props.handleField("TimeZone")
          //console.log("select box value", this.props.data.skills)
          }
         className="defaultBtn">Next
        </Link>
    </fieldset>
            </div>
        )
    }
}
export default Skills