import React from 'react'
import {  Form, Col, Row } from "react-bootstrap";
import moment from "moment";
import axios from '../../../config/axios';
import { toast } from "react-toastify";
import WeekList from './WeekAvalabilityList';
import { UpdateSetAvailability } from '../edit/index';
import ConfirmModal from '../view/ConfirmModal';
const _ = require("lodash");  


class SetAvailability extends React.Component{
  constructor(props){
    super(props)
    this.state={
      // startDate:"",
      // endDate:"",
      Days:[],
      availableDays: [],
      FirstWeek:"",
      note:"",
      availability_details:"",
      availability_id:"",
      daysAvailable:[],
      displayCurrMonth: false,
      currMonth: moment().format('MMMM'),
      currDate: moment().format('DD-MM-YYYY'),
      WeekSetdata:[],
      unavailableDays:[],
    }
  }

  componentDidMount(){
    this.getAvailability()
  }

  getAvailability(){
    const id=this.props.coachUid
    axios.get(`/coach/setavailability/${id}`,{
      headers:{'x-auth':this.props.token}
    })
    .then(res =>{
       //console.log("ress set availaibility------",res)
      if(res.data.success && res.data.value.length > 0){
        this.refs.weekchild.handleUpdateweekset();
        let data=res.data.value[0]
        const dataset=JSON.parse(data.schedule_details)
        // console.log("iiiiiiiiiiiiiiiiii6---",dataset)
        // this.setState({availability_details:dataset,
        //   availableDays:dataset.availableDays,
        //   daysAvailable:dataset.availableDays,
        //   availability_id:data.uid,
        //   note:dataset.Weeks[0].note,
        //   WeekSetdata:dataset.Weeks})
        //console.log("ress set availaibility------",dataset.unavailableDays)
        this.setState({
          availableDays:dataset.availableDays,
          daysAvailable:dataset.availableDays,
          availability_id:data.uid,
          note:dataset.note,
          WeekSetdata:dataset.availableDays,
          unavailableDays:dataset.unavailableDays
        })
        
      }
    })
    .catch(err =>{
      console.log(err)
    })
  }

  handleWeekSchedule=(data) =>{
    
     this.setState({FirstWeek:data[0]})
    
  }

  handleWeekScheduleUnavailable=(data) =>{
    
    this.setState({unavailableDays:data})
   
 }

  handleChange= async (e) =>{
    e.persist()
    console.log("Target Name ", e.target.name,e.target.value )
    await this.setState({
      [e.target.name]:e.target.value
    })

    if(e.target.name == "repeatForEverymonth" && e.target.value == "No"){
      await this.setState({displayCurrMonth : true})
    } else if (e.target.name == "repeatForEverymonth" && e.target.value == "Yes"){
      await this.setState({displayCurrMonth : false})
    }

    console.log("Curr month ", this.state.currDate )
  }

  HandleGetDays = (e) => {
    const index =
      this.state.Days.length > 0 ? this.state.Days.indexOf(e.target.id) : -1;
    if (e.currentTarget.checked) {
      if (index === -1) {
        this.setState({ Days: [...this.state.Days, e.target.id] });
      }
    }
    if (!e.currentTarget.checked) {
      this.setState({
        Days: [...this.state.Days.filter((element) => element !== e.target.id)],
      });
    }
  };

  HandleScheduleDays = (e) => {
    const index =
      this.state.diffrentScheduleDays.length > 0 ? this.state.diffrentScheduleDays.indexOf(e.target.id) : -1;
    if (e.currentTarget.checked) {
      if (index === -1) {
        this.setState({diffrentScheduleDays: [...this.state.diffrentScheduleDays, e.target.id] });
      }
    }
    if (!e.currentTarget.checked) {
      this.setState({
         diffrentScheduleDays: [...this.state.diffrentScheduleDays.filter((element) => element !== e.target.id)],
      });
    }
  };

  
  handleUpdateAvailability=()=>{
    this.getAvailability()
  }

  // handletest=() =>{
  //   console.log("executed---test")
  //   this.refs.weekchild.handleUpdateweekset();
  // }


  handleSubmit=(e) =>{
    let sameschedule
   
    
     sameschedule=[
      {Sundays:this.state.FirstWeek[0][0].From !=="" ? this.state.FirstWeek[0]:[]},
      {Mondays:this.state.FirstWeek[1][0].From !=="" ? this.state.FirstWeek[1] :[] },
      {Tuesdays:this.state.FirstWeek[2][0].From !=="" ? this.state.FirstWeek[2] :[] },
      {Wednesdays:this.state.FirstWeek[3][0].From !=="" ? this.state.FirstWeek[3] :[] },
      {Thursdays:this.state.FirstWeek[4][0].From !=="" ? this.state.FirstWeek[4] :[] },
      {Fridays:this.state.FirstWeek[5][0].From !=="" ? this.state.FirstWeek[5] :[] },
      {Saturdays:this.state.FirstWeek[6][0].From !=="" ? this.state.FirstWeek[6] :[] }
      ]
    
    
  


    let Schedule 
   
      Schedule={
        availableDays:sameschedule,
        unavailableDays:this.state.unavailableDays
       };
    
      
    e.preventDefault()
    const formData={
      
      schedule_details:Schedule,
      uid:this.state.availability_id,
      reminder: 2
    }
  
    axios.post('/coach/setavailability',formData,{
      headers:{'x-auth':this.props.token}
    })
    .then(res =>{
      console.log("-------------date---",res)
      if(res.data.success){
        toast("Successfully added availabilty", { type: "success" });
        let data=JSON.parse(res.data.addAvailableData.schedule_details)
            this.setState({availability_details:data})
            this.getAvailability()
      }else{
        toast("Error in setting availabilty", { type: "error" });
      }
    })
    .catch(err =>{
      console.log(err)
    })
  }

  handleDelete=(id) =>{
    // const id=this.state.availability_id
    axios.delete(`/coach/setavailability/${id}`,{
      headers:{"x-auth":this.props.token}
    })
    .then(res =>{
      if(res.data.success){
        toast("Successfully deleted availabilty", { type: "success" });
       this.setState({
        availableDays: [],
        note:"",
        availability_id:"",
        daysAvailable:[],
       })
       this.refs.weekchild.handleResetweekset()
      }else{
        toast("Error in deleting availabilty", { type: "error" });
      }
    })
    .catch(err =>{
      console.log(err)
    })
  }

    render(){
        return(
            <div>
{/* <section className="coacActionhWrap">
  <div className="container" >
    <div className="row justify-content-center">
      <div className="col-md-8 col-lg-12"> */}

        <div className="heading text-center">
          <h3>Coach's Availability Settings</h3>
          <span className="R_divider"></span>
        </div>

        {/* <div className="btn-group dropleft" style={{float:"right"}}>
                  <button type="button" className="btn btn-secondary dropdown-toggle"  
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
              </button>
            <div className="dropdown-menu">
              <li data-toggle="modal" 
                data-target="#updateavailability" className="fa fa-edit"
                style={{position:"relative", right:"-8px"}}>Edit
              </li>
              <li>
                <ConfirmModal
                      fun_name={"Delete"}
                      icon={"fa fa-trash"}
                      pack_id={this.state.availability_id}
                      handleClick={this.handleDelete}
                    />
              </li> 
            </div>
          </div> */}









          {/* <div className="availabilityViewWrap availabilityWrap mb-5">
          

            <div className="row">
              <div className="col-md-3 col-4">
                <label>Weekly Hours</label>
              </div>
              <div className="col-md-4 col-4">
                <label>Select Start Time</label>
              </div>
              <div className="col-md-4 col-4">
                  <label>Select End Time</label>
              </div>
              <div className="col-md-12">
                <hr/>
              </div>
            </div>
            
          
            <div className="row">
              <div className="col-md-3 col-4">
                <div className="form-group">
                  
                  <div className="selectBox searchFilter">
                    <select className="selectpicker">
                      <option>Sunday</option>
                      <option>Unavailable</option>
                    </select>
                    <span>
                        <img src="images/dArrowblack.png" alt=""/>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-9 col-8">
                <div id="content1"></div>
                <div className="row">
                  <div className="col-md-5 col-6 col-sm-4">
                    <div className="form-group">
                      
                      <input type="time" name="" value="17/10/2020" className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-5 col-6 col-sm-4">
                    <div className="form-group">
                      
                      <input type="time" name="" value="17/10/2020" className="form-control"/>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-4 text-center">
                    
                    <p className="m-0">
                      <a className="addAvailabilityTime addAvailabilityTimeNew" href="javascript:void(0);"><i className="fa fa-plus" aria-hidden="true"></i></a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <hr/>
              </div>
            </div>


      
          
            <div className="row">
              <div className="col-md-3 col-4">
                <div className="form-group">
                  
                  <div className="selectBox searchFilter">
                    <select className="selectpicker">
                      <option>Unavailable</option>
                      <option>Saturday</option>
                    </select>
                    <span>
                        <img src="images/dArrowblack.png" alt=""/>
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-9 col-8">
                <div id="content2"></div>
                <div className="row align-items-center">
                  <div className="col-md-5 col-6 col-sm-4 text-center">
                    <div className="form-group">
                      <b  className="text-danger">Unavailable</b>
                    </div>
                  </div>
                  <div className="col-md-5 col-6 col-sm-4 text-center">
                    <div className="form-group">
                      <b  className="text-danger">Unavailable</b>
                    </div>
                  </div>
                  <div className="col-md-2 text-center">
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <hr/>
              </div>
            </div>

            <div className="text-right mt-4 timeEntryAction">
              <button className="defaultBtn mr-2" type="button">Cancel</button>
              <button className="defaultBtn" type="button">Submit</button>
            </div>
          
      </div> */}














        <div className="availabilityViewWrap availabilityWrap mb-5">
          <Form>
            {/* { !this.state.availability_id ?(
              <h5 style={{"textAlign":"center"}}>Add Availability</h5>
            ):(
              <h5 style={{"textAlign":"center"}}>Your Availability Setting</h5>
            )} */}
          <div className="row">
            {/* <div className="col-sm-6">
              <div className="form-group">
                <label>Select Start Date</label>
                <input className="form-control" type="date" id="startDate"  name="startDate"  />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Select End Date</label>
                <input className="form-control" type="date" id="endDate"   name="endDate"  />
              </div>
            </div> */}

    


          </div>

          
   
          <WeekList
            ref="weekchild"
            handleWeekSchedule={this.handleWeekSchedule}
            handleWeekScheduleUnavailable={this.handleWeekScheduleUnavailable}
            handleChange={this.handleChange}
            statedata={this.state}
            availability_details={this.state.availability_details}

            token={this.props.token}
            handleSubmit={this.handleSubmit}
            coachUid={this.props.coachUid}/>

       </Form>
      </div>

    {/* </div>
  </div>
  </div>
</section> */}

<UpdateSetAvailability
token={this.props.token}
dataset={this.state}
daysAvailable={this.state.daysAvailable}
availability_id={this.state.availability_id}
handleUpdateAvailability={this.handleUpdateAvailability}
coachUid={this.props.coachUid}

WeekSetdata={this.state.WeekSetdata}/>

            </div>
        )
    }
}

export default SetAvailability