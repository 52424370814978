import React, { Component,useState } from 'react';
import StripeCheckout from "react-stripe-checkout";
import { toast } from "react-toastify";
// import moment from "moment";
import moment from "moment-timezone";

import axios,{AdminApi} from '../../../config/axios';

import Calendar from 'react-calendar';
import CoachProfileWrap from './CoachProfileWrap';
import { Header, HeaderLogout, HeaderTop } from "../../../components/headers";
import { FooterLink } from "../../../components/footers";
import CommunicationUsers,{SessionBookedNotify} from "../../../layouts/Communication/CommunicationUsers";
import { avatar } from "../../../assets/img";
import  mc  from "../../../assets/images/mc.png";
import  visa  from "../../../assets/images/visa.png";
import  ae  from "../../../assets/images/ae.png";
import  {LGspinner}  from "../../../assets/images";
import message from '../../../utils/mesaages';
import validation from '../../../Validate/validator';
import { TimePicker, DatePicker } from 'antd';
import {getClientTimezone,timezoneUtc,UTCtoTimezone} from '../../../utils/timeZoneConvert';
import {curencyFilter,curencyRates} from '../../../utils/currencyConvert';
import { Tooltip } from 'antd';
import { set } from 'js-cookie';
import bluesnapForm from '../../../assets/js/bluesnapgateway'
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";




const _ = require("lodash");  

let planId=0
class Buysubscription extends Component {

    constructor(props){
        super(props)
         this.userdata = JSON.parse(localStorage.getItem('userdata'))
        this.state={
          avatar_path: avatar,
          selectedSlot:'',
          sessionDate:"2021-01-28",
          slots:[],
          currMonth: moment().format('MMMM'),
          currDate: moment().format('DD-MM-YYYY'),
          date: new Date(),
          carddetails:[],
          cardnumber: '',
          name: '',
          month: moment().format('MM'),
          year: moment().format('YYYY'),
          cvc: '',
          expiryDate:moment(),
          address: '',
          saveCard:'',
          carduid:'',
          errors: {
            cardnumber: 'false',
            cvc: 'false',
            name:'false',
            expiryDate:'',
            saveCard:'false'
          },
          FromDataError: {
            cardnumber: 'false',
            cvc: 'false',
            name:'false',
            expiryDate:'false',
            saveCard:'false'
            // month:'false',
            // year:'false',

          },
          city:'',
          country:'',
          ownerState:'',
          gatewayDetails:{},
          coachTimezone:'',
          timezone:localStorage.getItem('timezone'),
          allplan:'',
          planDesc:'',
          filteredArray:[],
          fieldDisable:false,
          strip_cus:'',
          pfToken:'',
          loaderToken:false,
          rates:""

        }

       
      
      }

    

      disabledDate = (value) =>{
        // const form = this.props.form;
       // Can not select days before today and today
        return  value < moment();
      }; 
        

      
      async componentWillMount()
      {
        let rates = await curencyRates()
        this.setState({rates})
        this.loadStripeJS()

      }
      isUpgradingCurrentPlan2 = () => {
        let isUpgrading = false
    
        try {
          
          isUpgrading = parseInt(this.props.location.state.currentPlanPrice) < parseInt(this.props.location.state.amount)
          console.log(isUpgrading);
          console.log(this.props.location.state.currentPlanPrice, this.props.location.state.amount);
        } catch (error) {
          
        }
    
        return isUpgrading
      }
      

      componentDidMount()
      {
        console.log('payment proprs ',this.props)
        console.log('userdata  ',this.userdata)
        if(this.props.match.params.hasOwnProperty('planId'))
        {
          planId = this.props.match.params.planId
          console.log('plan id',planId)
        } 
        
        this.fetchCoachData()
        this.getOnePlan()
        this.fetchcards()
        // this.fetchCustomerId()
      }

      // fetchCustomerId = () => {
      //   let userEmail = JSON.parse(localStorage.getItem("userdata")).email;
      //   axios.post(`/strip/get-customer-list`, {email: userEmail})
      //   .then((res) => {
      //     if(res.data.success) {
      //       this.setState({strip_cus: res.data.cus_id})
      //     }
      //   })
      //   .catch((err) => {
      //     console.log("fetch customer id error", err)
      //   })
      // }


      getOnePlan() {
  
        axios
          .get(`/users/get-one-plan/${planId}`, {
            headers: {
              "X-Auth": this.token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              console.log('plan.....',res.data.value.coachPlan[0])
              let plan = res.data.value.coachPlan[0];
              //if (plan.length > 0) {
                this.setState({ allplan: plan,planDesc:res.data.value.coachDesc });
                
               
              //}
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }

      fetchCoachData() {
        //this.setState({loader: true});
       let userdata = JSON.parse(localStorage.getItem('userdata'))
        const id = userdata.uid;
    
        let param = {
          url: `/users/coachprofile/${id}`,
          method: "GET",
          headers: {
            "X-Auth": this.token
          }
        }
        axios(param)
          .then(async (res) => {
            
            if (res.data.value) {
              const rawdata = res.data.value;
               const rawaddress = rawdata.address ? JSON.parse(rawdata.address) : "";
               console.log("-------------------------------------------res-socail-------------", rawaddress)
               this.setState({city:rawaddress.city,
                country:rawaddress.country,
                ownerState:rawaddress.location
              })
              this.setState({loader: false});
    
            }
          })
          .catch((error) => {
            console.log("fetch error");
            console.log(error);
            this.setState({loader: false});
           
          });
      }

      fetchcards = () => {
        console.log('enter fetch cards')
        let self = this;
        let param = {
            url: "/consumer/getpaymentmethodcard",
            method: "GET",
            headers: {
                "X-Auth": localStorage.getItem('authToken'),
            },
        };

        console.log('param', param)
        axios(param)
            .then(async (res) => {
                this.setState({ paymentloader: false })
                if (res.data.success) {
                  let cardIndex =_.findIndex(res.data.cards, function(o) { return o.primarycard == '1'; });
                  if(cardIndex >= 0)
                  {
                    this.chosseCard(cardIndex)
                  }

                  if(res.data.cards.length>0)
                  {
                    // this.setState({strip_cus:res.data.cards[0].strip_cus},()=>{
                    //   console.log('strip_cus',this.state.strip_cus)
                    // })

                  }
                  
                    this.setState({ carddetails: res.data.cards, strip_cus: res.data.customer_id === null ? "" : res.data.customer_id })
                    console.log('getpaymentmethodcard res', cardIndex)
                }
            })
            .catch((error) => {
                console.log("fetch error");
                console.log(error);
                //toast("Error getting profile data", { type: "error" });
            });
      }

   
    loadStripeJS(){

      let userdata = JSON.parse(localStorage.getItem('userdata'))
      const id = userdata.uid;

      let self = this;
      let param = {
        url: `/admin/settings/stripe/${id}`,
        method: "GET",
      };
      axios(param)
      .then(async (res) => {
  
          if(res.data.hasOwnProperty('stripe')){
  
            const script = document.createElement("script");
            script.src = "https://js.stripe.com/v2/";
            script.async = true;
            script.onload = () => {
              if(res.data.stripe.mode=='live')
              window.Stripe.setPublishableKey(res.data.stripe.live.publishable_key);
              else
              window.Stripe.setPublishableKey(res.data.stripe.sandbox.publishable_key);
            }
            document.body.appendChild(script);
  
               self.setState({gatewayDetails: res.data.stripe})
          }
  
      })
     
    }
    loadCard=()=> {
      return new Promise((resolve, reject) => {
        let self = this
    
      let errors = this.state.FromDataError
    
      window.Stripe.card.createToken({
                  number: this.state.cardnumber,
                  cvc: this.state.cvc,
                  exp_month: this.state.month,
                  exp_year: this.state.year
                },function(status, response) {
                  if (response.error) { 
                    // Show appropriate error to user
                    console.log('stripe err',response)
                    if(response.error.param=='number')
                    {
                      errors.cardnumber = response.error.message
                    }
                    else if(response.error.param=='cvc')
                    {
                      errors.cvc = response.error.message
                    }
                    else if(response.error.param=='exp_month')
                    {
                      errors.expiryDate = response.error.message
                    }
                    else if(response.error.param=='exp_year')
                    {
                      errors.expiryDate = response.error.message
                    }
                    self.setState({errors})
                    reject()
                    
                  } else {
                    // Get the token ID:
                    var token = response.id;
                    console.log('stripe response',response)
                    resolve(response)
                    // Save token mapping it to customer for all future payment activities
                  }
                });
        })
    };

    setvalue = async(e,value, field) => {
      let numbers = /^[0-9]*$/;

      this.handleError(field, value);

      if(field=='expiryDate')

      {
        this.setState({expiryDate:e})
        if(e!=null)
        {
          // console.log('expiryDate',e.format('MM'))
          // let month=
          // let year=
          

          if (field === 'expiryDate') this.setState({ month: e.format('MM') })
          if (field === 'expiryDate') this.setState({ year: e.format('YYYY') })

        }
        

      }
      // else{

      //   console.log('aaaaaaaaaaaa',e.target)
      // let f = field
      // // this.handleError(field, value);

      // let { FromDataError } = this.state
      // var valid_obj = {
      //   value: e.target.value,
      //   rules: e.target.getAttribute("validaterule"),
      //   message: e.target.getAttribute("validatemsg")
      // }
      // console.log('valid_obj',valid_obj)
  
      // validation(valid_obj).then((error) => {
  
  
      //   console.log('aaaaaaaaaaa', error)
      //   console.log('field',field)
      //   FromDataError[field] = error
      //   this.setState({ FromDataError },()=>{
      //     console.log('kkkk',this.state.FromDataError)
      //   })
  
       
        
  
  
      // })



      // }

           

     
      // if (field === 'cardnumber') this.setState({ cardnumber: value })
      if(field === 'cardnumber' && value.length>16)
    {
      
      this.setState({ cardnumber: this.state.cardnumber })
    }
    else
    {
      if (field === 'cardnumber' && numbers.test(value)) {
        this.setState({ cardnumber: value })
      }
      else {
        this.setState({ cardnumber: this.state.cardnumber })
      }
    }
      if (field === 'name') this.setState({ name: value })
    
      // if (field === 'cvc') this.setState({ cvc: value })
      if(field === 'cvc' && value.length>4)
    {
      await this.setState({ cvc: this.state.cvc })
    }
    else
    {
      if (field === 'cvc') this.setState({ cvc: value })
    }
      if (field === 'primarycard') this.setState({ primarycard: value })
      if (field === 'saveCard') {
        let saveCard = this.state.saveCard === 1? '' : 1
        this.setState({ saveCard },()=>{
      this.handleError(field, this.state.saveCard);

        })
      }

  }

  

  // handleError = (name, value) => {
  //   console.log('name' , name)
  //   console.log('value' , value)

  //   let errors = this.state.errors;
  //   switch (name) {
  //     case 'cardnumber':
  //       // errors.cardnumber = value.length >= 15 ? '' : 'Card number minimum 15 digits!';
  //       // errors.cardnumber = value === '' ? 'This Field is required':''
  //       errors.cardnumber = errors.cardnumber === 'false' ? 'This Field is required':errors.cardnumber
  //       break;
  //     case 'name':
  //       if (value === '') {
  //         errors.name = 'This Field is required'
  //       } else {
  //         errors.name = ''
  //       }
  //       break;

  //     case 'expiryDate':
  //       // if (value === null) {
  //       //   errors.expiryDate = 'This Field is required'
  //       // } else {
  //       //   errors.expiryDate = ''
  //       // }
  //       errors.expiryDate = errors.expiryDate === 'false' ? 'This Field is required':errors.expiryDate
  //       break;

  //     case 'cvc':
  //       // errors.cvc =
  //       //   value.length >=3
  //       //     ? ''
  //       //     : 'CVC accepts minimum 3 digits!';

  //       // if (value === '') {
  //       //   errors.cvc = 'CVC Required'
  //       // }
  //       errors.cvc = errors.cvc === 'false' ? 'This Field is required':errors.cvc
  //       break;
  //       case 'saveCard':
  //         if (value === '') {
  //           errors.saveCard = 'This Field is required'
  //         } else {
  //           errors.saveCard = ''
  //         }
  //         break;
        

  //     default:
  //       break;
  //   }

  //   this.setState({ errors, [name]: value }, () => {
  //     console.log(errors)
  //   })
  // }
  handleError = (name, value) => {
    console.log('name' , name)
    console.log('value' , value)

    let errors = this.state.errors;
    switch (name) {
      case 'cardnumber':
        errors.cardnumber =
          value.length >= 15
            ? ''
            : 'Card number minimum 15 digits!';
        if (value === '') {
          errors.cardnumber = 'This Field is required'
        }
        break;
      case 'name':
        if (value === '') {
          errors.name = 'This Field is required'
        } else {
          errors.name = ''
        }
        break;

      case 'expiryDate':
        if (value === null) {
          errors.expiryDate = 'This Field is required'
        } else {
          errors.expiryDate = ''
        }
        break;

      case 'cvc':
        errors.cvc =
          value.length >=3
            ? ''
            : 'CVC accepts minimum 3 digits!';

        if (value === '') {
          errors.cvc = 'CVC Required'
        }
        break;
        case 'saveCard':
          if (value === '') {
            errors.saveCard = 'This Field is required'
          } else {
            errors.saveCard = ''
          }
          break;
        

      default:
        break;
    }

    this.setState({ errors, [name]: value }, () => {
      console.log(errors)
    })
  }


  checkrequiredfields = () => {
    let {carduid} = this.state
    let valid = false;
    if(carduid=='')
    {
      if (this.state.cardnumber !== '' &&
      this.state.name !== '' &&
      this.state.cvc !== '' && this.state.saveCard !== ''
      ) {
        valid = true
      } else {
      
        this.handleError('cardnumber' ,this.state.cardnumber)
        this.handleError('name' ,this.state.name)
        this.handleError('cvc' ,this.state.cvc)
        // this.handleError('expiryDate' ,this.state.expiryDate)
        this.handleError('saveCard' ,this.state.saveCard)

      }

      return valid
    }
  }

  setPrimaryCard = (cardId,carddata=null) => {
    // this.setState({
    //   selectedCardForSubscription: cardId
    // })
    if(carddata!=null)
    {
      const hidemsg = message.loading(`Please wait ..`,0)
      axios.post('/consumer/set-primary-card', { card: carddata, user: localStorage.getItem('userdata') }).then((res)=>{
        if (res.data.success) {
          hidemsg()
        message.success('Your card is selected.')
        // this.fetchcards()
        //   this.setState({
        //     selectedCardForSubscription: cardId
        //   })
        }
        else
        {
          hidemsg()
        message.error('Card selectoin failed')
        }
      }).catch((err) => {
        console.log('err', err)
        hidemsg()
        message.error('Card selectoin failed')
        
      })
    }
    else
    {
      

    }
    

  };

  chosseCard = async (index)=>{
    await this.setState({carduid:''})
    let carddetails = [...this.state.carddetails];
    let card  = {...carddetails[index]}
    if(card.hasOwnProperty('checked')){
      delete card['checked']
    }else{
      await this.setState({carduid:card.id})
      // await this.setState({carduid:card.strip_card})
      card['checked'] = true
    }
    carddetails = carddetails.map(d=>{
      delete d.checked
      return d
    })
    carddetails[index] = card
    //let carduid = this.state.carduid===e.target.value? '': e.target.value
    console.log("this.state.carduid===e.target.value",card)
     //console.log('carddetails',carddetails)
     var filteredArray = carddetails.filter(function(itm){
      return itm.checked;
    });
    console.log('carddetailsfilter',filteredArray)
     
    this.setState({carddetails,filteredArray})
      if(filteredArray.length>0)
      {
        this.setState({fieldDisable:true})
      }else
      {
        this.setState({fieldDisable:false})
      }

  }

  processCard = async (hidemsg) => {
    
    let {name, saveCard} = this.state
   
    let currencyVal = localStorage.getItem('currency')
    currencyVal = currencyVal || 'USD'

    return new Promise((resolve, reject)=>{

      this.setState({fieldDisable:true})

      this.loadCard().then((resCard)=>{

        console.log('resCard',resCard)

        let cardExpYear = this.state.year
        let cardExpMonth = this.state.month

        let cardData = {
          "cardNumber": this.state.cardnumber,
          cardExpYear,
          cardExpMonth,
          // "cVV" : cvc,
          email:this.userdata.email,
          firstName:this.state.name,
          strip_token:resCard.id,
          uid:this.userdata.uid

        }

                let url = ''

                if(this.state.strip_cus=='')
                {
                  url = "/strip/customerCreate"
                }
                else
                {
                  url = "/strip/customerUpdate"
                  cardData.customer=this.state.strip_cus

                }
     
                axios
                .post(url, cardData)
                .then(async (response) => {
                
                  let {data, success,err} = response.data;
                  let d1= []
                  d1['cvc'] = 0
                  console.log(".......isSuccess",success)
                  if(!success){
                    hidemsg()
                    message.error(err)
                    reject("Error processing your card.")
                    
                  }
                  else
                  {
                            if(saveCard===1){
                                    d1['name'] = name
                                    d1['uuid'] = this.userdata.uid
                                    d1['primarycard'] = true
                                    d1['currency'] = currencyVal

                                    Object.assign(data, d1)
                                    
                                    
                                    console.log(".......addpaymentmethod",data)
                                    axios.post('/consumer/addpaymentmethod', data)
                              
                              }
                        console.log(".......now returning",success)
                        let authTorizationdata= {
                          "creditCardToken": data.creditCardToken,
                          "customer": data.customer,
                          "transactionAmount":1,
                          cardExpYear,
                          cardExpMonth,
                          "cVV":0,
                          "name":name,
                          "cardcompany":data.cardType,
                          "cardnumber":data.maskedPan
                        }

                        resolve(authTorizationdata)

                  }
                  

                }).catch(e=>{
                  console.log(e)
                  hidemsg()
                      message.error('Error processing your card.').then(() => message.destroy())
                      reject("Error processing your card.dfgdfgdfgdfgdfgd")
                }).finally(() => {
                  setTimeout(() => {
                    this.setState({fieldDisable: false})
                  }, 0);
                  
                  
                })

      })
      .catch((err)=>{
        hidemsg()
        this.setState({fieldDisable:false})
        console.log('pftoken error',err)
        message.error('Error in processing your card. Please recheck the details.')
  
      })


      
    })

   
    
  }

     handleConfirm= async () =>{
      let {carduid,name,cardnumber,month,year,cvc} = this.state

      //if(this.validateForm(this.state.FromDataError) || carduid!='')
      if((this.checkrequiredfields()) || carduid!='')
      {
        
      
      const hidemsg = message.loading(`Please wait ..`,0)

      
       
        let {gateway_id, processor_id} = this.state.gatewayDetails
        console.log('state',this.state)

        if(carduid!='' || (name!=''))
        { 
          let cardData =    [...this.state.carddetails].find(d=>d.id===carduid);
          if(!carduid){
            cardData = await this.processCard(hidemsg)
          }else{
            cardData = {
                // "merchantKey":gateway_id,
                // "processorId":processor_id,
                "creditCardToken": cardData.strip_card,
                "customer": cardData.strip_cus,
                "transactionAmount":1,
                "cardExpMonth": cardData.expirymonth,
                "cardExpYear": cardData.expiryyear,
                "cVV":`${cardData.cvc}`,
                "cardcompany":cardData.cardcompany,
                "cardnumber":cardData.cardnumber
              }
              if(cardData.primarycard === "0") {
                let setPrimaryCard = await setPrimaryCard(this.state.carduid, {uuid: cardData.uuid, user_uuid: this.userdata.uid, primarycard:cardData.primarycard})
              }
          }
          
          cardData['ownerName']=this.state.name
          cardData['ownerCity']=this.state.city
          cardData['ownerCountry']=this.state.country
          cardData['ownerState']=this.state.ownerState
          let packageprice=this.props.location.state.amount
        let authinfoData 
        // let packageprice=1 //need to change
       

              let payload = {
                card_id:cardData.creditCardToken,
                customer:cardData.customer,
                planId:this.state.allplan.plan_id,
                coach_uid: this.userdata.uid
                // planId:"price_1JSFy9CbNXb8vkbAE3ibXnzJ"
              }

              console.log('payload',payload)
              
          if(this.props.location.state.membershipID==null)
          {
                let SubscriptionUrl = '/strip/create-subscription'
              
                    
              
              axios
              // .post(AdminApi+"/1stPay/cardAuth", cardData)
              .post(SubscriptionUrl, payload)
              .then(async (authinfo) => {
               
                // console.log('charge',authinfo.data.data)
                if(authinfo && authinfo.data.success){
                  authinfoData=authinfo.data.data
                  let chargeInfo = authinfoData
                      
                      let paymentPost = {
                        cardData,
                        'chargeInfo':chargeInfo,
                        DateTime:moment().format('YYYY-MM-DD HH:mm:ss'),
                        Package:{ "type":"coach"
                        , "name": `${this.state.allplan.plan_name}`,
                        "price": packageprice,
                        coach_uid:this.userdata.uid,
                        coach_email:this.userdata.email,
                        coach_name:this.userdata.name,
                        planid:planId,
                        duration:this.props.location.state.duration,
                        plan_chargefrequency:this.state.allplan.plan_chargefrequency,
                          
                       
                      },
        
                      }
                      console.log('paymentPost',paymentPost)
                      
                      axios.post("/payment/checkout",paymentPost)
                      .then(response =>{
                        hidemsg()
                        const { success } = response.data;
                        console.log("Response payyyyy:", response.data);
                        if (success) {
                          if(this.state.filteredArray.length===0){
                            this.setState({fieldDisable:false})
                        }
                          message.loading(response.data.msg)
                          setTimeout(() => {
                            this.props.history.push({
                              pathname: '/profile-coach',
                              
                            })
                             
                           }, 4000);
                          
                        } else {
                          this.setState({fieldDisable:false})
                          hidemsg()
                          message.error(response.data.msg)
        
                         
                        }
                      })
                      .catch(()=>{
                        this.setState({fieldDisable:false})
                        hidemsg()
                      })
        
        
        
        
                    
                 
               }
               else
               {
                hidemsg()
                message.error(authinfo.data.message)
               }
              }).catch(e=>{
                console.log(e)
                hidemsg()
                  message.error('Error cardAuth.')
                  
            })
          }
          else//upgrade
          {
           
                  
                      
                      let paymentPost = {
                        cardData,
                        DateTime:moment().format('YYYY-MM-DD HH:mm:ss'),
                       
                        Package:{ "type":"coach"
                        , "name": `${this.state.allplan.plan_name}`,
                        "price": packageprice,
                        coach_uid:this.userdata.uid,
                        coach_email:this.userdata.email,
                        coach_name:this.userdata.name,
                        planid:planId,
                        duration:this.props.location.state.duration,
                        plan_chargefrequency:this.state.allplan.plan_chargefrequency,
                        packageUpgrade:true,
                        isUpgradeBoolean:this.isUpgradingCurrentPlan2(),
                        payload,
                       
                          
                       
                      },
        
                      }
                      console.log('paymentPost',paymentPost)
                      
                      axios.post("/payment/checkout",paymentPost)
                      .then(response =>{
                        hidemsg()
                        const { success } = response.data;
                        console.log("Response payyyyy:", response.data);
                        if (success) {
                          if(this.state.filteredArray.length===0){
                            this.setState({fieldDisable:false})
                        }
                          message.loading(response.data.msg)
                          setTimeout(() => {
                            this.props.history.push({
                              pathname: '/profile-coach',
                              
                            })
                             
                           }, 4000);
                          
                        } else {
                          this.setState({fieldDisable:false})
                          message.error(response.data.msg)
        
                         
                        }
                      })
                      .catch(()=>{
                        this.setState({fieldDisable:false})
                        hidemsg()
                      })
        
        
        
        
                    
                 
               
             
          }//upgrade
          
            
           // return
           
              
             

          

        }
        else
        {
          message.loading("Please add payment details")

          // toast("Add payment details", { type: "error" });
        }

      }
      else
      {
       // message.error("Please fill the form correctly")
        if(this.state.carddetails.length>0){
          message.error("Please select card or enter your card details")
        }
      }
          
      }
    
      handleClose() {
        // window.$('#bookpackagemodal').modal('hide');
    }
    
     intervals(startString, endString) {
      var start = moment(startString, 'YYYY-MM-DD hh:mm a');
      var end = moment(endString, 'YYYY-MM-DD hh:mm a');
    
      
      start.minutes(Math.ceil(start.minutes() / 15) * 15);
    
      var result = [];
    
    
    
      var current = moment(start);
      result.push(current.format('YYYY-MM-DD HH:mm'));
      result.push(end.format('YYYY-MM-DD HH:mm'));
      // while (current <= end) {
      //     result.push(current.format('YYYY-MM-DD HH:mm'));
      //     current.add(15, 'minutes');
      // }
      //tanmay change
      console.log(result)
    
      return result;
    }

   

    render() {

      let momentdate = ''
      let momentfrom = ''
      let momentto = ''
        return (
            <React.Fragment>
            {/* { this.token  } */}
            {/* <HeaderTop /> */}

      {localStorage.getItem("authToken") == null ? (
        <HeaderTop />
      ) : (
          <HeaderLogout
            currencies={this.state.currencies}
            convertToCurrency={this.state.convertToCurrency}
            changeConvertToCurrency={this.changeConvertToCurrency}
          />
        )}

      <Header />
      <CommunicationUsers avatar_path={this.state.avatar_path}></CommunicationUsers>
      <section className="coacActionhWrap coacBookingWrap pt-5" >
        <div className="container ">
          <div className="heading text-center">
            <h3>Buy subscription</h3>

           
            <span className="R_divider"></span>
          </div>
          <div className="row justify-content-md-center">


          <div className="col-md-4">
          <h3>Package Details</h3>
          <p>
            <b>Package:</b>
           
           {this.state.allplan.plan_name}
          </p>
          <p>
            <b>Total amount: </b>
            {curencyFilter(`USD##${this.props.location.state.amount}`,"",this.state.rates) }
           {/* $ {this.props.location.state.amount} */}
            
          </p>
          <p>
            <b>Duration: </b>
            {this.props.location.state.duration>1?this.props.location.state.duration+" Months":"Monthly"} 
            
          </p>
          {/* <p>
            <b>Package Payments:</b>
            
            Pay monthly: total amount is debited each month while account is active<br/>
            Pay yearly: total amount is a one-off purchase. Renewal invitation sent end of 12 month period
          </p> */}
          <p>
            <b>Package Payments:</b>
            
            Pay monthly: total amount is debited each month while account is active and will auto-renew until subscription is cancelled.<br/>
            Pay yearly: total amount is a one-off purchase for 12 month subscription. A renewal invitation will be sent out 14 days prior to renewal date.
          </p>
         
         
          
        </div>
  
        <div className="col-md-4">
         
          <div className="editProfileForm confirmBooking">
                <form className="" action="" method="get" accept-charset="utf-8">
                 
                <div className="saveCardList">
                  
                  {this.state.carddetails.length>0?
                  <h3>Saved Card(s)</h3>:''
                  }
                    
                     <ul>
                       {this.state.carddetails.length>0 && this.state.carddetails.map((cards,index)=>{

                          // console.log('carddddd',cards)

                         return(
                           <li key={index}>
                           <div className="custom-control custom-checkbox checkbox-inline mx-2">
                             <input onClick={()=>this.chosseCard(index)} checked={cards.primarycard=='1'? true : false} type="checkbox" checked={cards.hasOwnProperty('checked')?true:false} className="custom-control-input" id={index} value={cards.id} name="saveCard"/>
                             <label className="custom-control-label d-flex align-items-center" for={index}>

                               {(cards.cardcompany.toUpperCase() =='VISA')?(<img  src={visa}/> ):
                               (cards.cardcompany.toUpperCase() == 'MASTERCARD')?(<img  src={mc}/>):
                               (<img  src={ae}/>)}
                               
                               <p><span>{cards.name}</span>{cards.cardnumber} - {cards.cardtype} card</p>
                             </label>
                           </div>
                          </li>
                          )


                       })}
                     
                       
                       
                       
                      
                     </ul> 

                  </div>

                  <h3>Enter your card details</h3>
                  <LoadingOverlay
                    active={this.state.loaderToken}
                    // spinner={<BounceLoader />}
                    spinner={
                      <div>
                        <CustomImageLoader image={LGspinner} animationType={"pulse"} />
                        <br />
                      </div>
                    }
                    text=""
                  >
                  {/* <div className={this.state.fieldDisable?'disableCardSection':''}> */}
                  
                  {/* card no */}
                  <div className="form-group" >

                  <input type="text" name="cardnumber" 
                     disabled ={this.state.fieldDisable}
                     
                     validaterule={['required', 'min|15','max|16']}
                     validatemsg={['Enter your card number', 'Card number minimum 15 digits!','Card number maximum 16 digits!']}
                    value={this.state.cardnumber} onChange={(e) => this.setvalue(e,e.target.value, 'cardnumber')}  placeholder="Card Number" className="form-control"/>
                       
                      <p style={{ color: 'red' }}>{this.state.errors.cardnumber == 'false' ? '' : this.state.errors.cardnumber}</p>
                  </div>
                  {/* card name */}
                  <div className="form-group">
                    <input type="text" name="name"
                     disabled ={this.state.fieldDisable}
                      // validaterule={['required']}
                      // validatemsg={['Enter your name']}
                      onChange={(e) => this.setvalue(e,e.target.value, 'name')} style={{ color: 'black' }}  placeholder='Name Of Card Holder' className="form-control"/>
                        <p style={{ color: 'red' }}>{this.state.errors.name == 'false' ? '' : this.state.errors.name}</p>
                  
                  </div>
                  {/* Card exp and cvv */}
                  <div className="row">
                      {/* Card exp*/}
                    <div className="col-sm-8" >
                    <DatePicker disabledDate={this.disabledDate}  disabled ={this.state.fieldDisable}  onChange={(e) => this.setvalue(e,'','expiryDate')} value={this.state.expiryDate} picker="month"    size="large" defaultValue={moment()}  format={"MM-YYYY"}   />

                        
                    </div>
                    
                    {/* Card cvc*/}
                   <div className="col-sm-4" >
                      <div className="form-group">
                      <input type="text" name="cvc" 
                         disabled ={this.state.fieldDisable}
                        validaterule={['required',  'min|3']}
                        validatemsg={['Enter CVV / CVC', 'CVC required']}
                          onChange={(e) => this.setvalue(e,e.target.value, 'cvc')} placeholder='CVC' className="form-control"
                          value={this.state.cvc}/>
                       
                          <p style={{ color: 'red' }}>{this.state.errors.cvc == 'false' ? '' : this.state.errors.cvc}</p>
                        </div>
                    </div>
                  </div>

                  <div className="areas mb-3">
                    <div className="custom-control custom-checkbox checkbox-inline">
                      <input type="checkbox"  validaterule={['required']}
                        validatemsg={['This field is required.']} disabled={this.state.fieldDisable} className="custom-control-input" onChange={(e) => this.setvalue(e,e.target, 'saveCard')}  id="customCheck9" name="example1"/>
                      <label className="custom-control-label" for="customCheck9">Save this card</label>
                      <p style={{ color: 'red' }}>{this.state.errors.saveCard == 'false' ? '' : this.state.errors.saveCard}</p>

                    </div>
                  </div>

                  {/* </div> */}
                  </LoadingOverlay>
                  <button className="defaultBtn" style={{marginTop:'56px'}} onClick={this.handleConfirm} type="button">Purchase Now</button>
                 
                </form>

              </div>
        </div>

             
             </div>
          


          
         
     
         

          
        </div>

    
      </section>

           <FooterLink />
    </React.Fragment>
        );
    }
}

export default Buysubscription;