import React from "react";
import axios from "../../config/axios";
import { toast } from "react-toastify";
import { Tooltip } from 'antd';
import { SRLWrapper } from "simple-react-lightbox";
import { Header, HeaderLogout, HeaderTop } from "../../components/headers";
import { FooterLink } from "../../components/footers";
import { deleteSvg, moreSvg, documentSvg, upload, mclose, docSvg, pdfSvg, xlsSvg, pptSvg, videoBanner, pictureSvg } from "../../assets/images";
import { Dropdown, Modal } from 'react-bootstrap';
import {
  EditFileName,
  ListModal,
  SocialMediaButtons,
} from "../../components/resourcefilemodal";
import { VideoPlayer } from "../../components/media";
import { Link } from "react-router-dom";
import message,{getHelpText} from '../../utils/mesaages';
import Pagination from '../../components/pagination/pagination';
// let userId = 'secret';
// try {
//   let User=JSON.parse(localStorage.getItem('userdata'))
//   userId = User.uid
// } catch (error) {
//   console.log(error)
// }
let User=JSON.parse(localStorage.getItem('userdata'))
let viewType=''
class ResourcesList extends React.Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("authToken");
    
    this.state = {
      resource: null,
      is_featured: 0,
      videos: [],
      photos: [],
      pdfs: [],
      audios: [],
      isLoaded: false,
      visibility: "private",
      currentVideo: "",
      currentDocument: "",
      URL: "",
      shareType: "",
      fileName: "",
      viewMoreType: "",
      currentResourceDataSet: [],
      resourceId: "",
      resourcePath: "",
      resourceName: "",
      VideoLimit:3,
      PhotoLimit:4,
      DocumentLimit:4,
      AudioLimit:4,
      visitorId:"",
      resource_text: "Text is unavailable",
      file_Name: "",
      invalid: '',
      show: false,
      editTitleModal: false,
      shareModal: false,
      imageTotalcount:0,
      limit:4,
      offset:0,
      renderimage:0,
      limitAudio:4,
      offsetAudio:0,
      audioTotalcount:0,
      limitDocument:4,
      offsetDocument:0,
      DocumentTotalcount:0,
      limitVideo:3,
      offsetVideo:0,
      VideoTotalcount:0,
      pageNumber: 1,
      //listPerPage: 4,

    };
  }
  async componentDidMount() {
    
    if(this.props.match.params.hasOwnProperty('type'))
    {
        viewType = this.props.match.params.type
    }   
    let User=JSON.parse(localStorage.getItem('userdata'))
    this.setState({visitorId: User.uid})
    let resource_text = await getHelpText('resource_text')
    this.setState({resource_text})
   this.getResource();
   this.getResourceImage()
   this.getResourceAudio()
   this.getResourceDocument()
   this.getResourceVideo()
   console.log('userdetails',User)
  }

  getResource() {
    const id= User.uid
    axios
      .get(`/users/resources/${id}`, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        console.log('resorces',res.data.value)
        this.setState({
          videos: [],
          photos: [],
          pdfs: [],
          audios: [],
        });
        const resourcetypes = res.data.value;
        resourcetypes.filter((resourcetype) => {
          if (resourcetype.resource_type.includes("image")) {
            //this.state.photos.push(resourcetype);
          } else if (resourcetype.resource_type.includes("video")) {
           // this.state.videos.push(resourcetype);
          } else if (resourcetype.resource_type.includes("audio")) {
            //this.state.audios.push(resourcetype);
          } else {
           // this.state.pdfs.push(resourcetype);
          }
          return true;
        });
        this.setState({ isLoaded: true });
      })
      .catch((error) => {
        message.error("Error in fetching resources");
      });
  }

  getResourceImage() {
    let {limit,offset} = this.state;
    const id= User.uid
    axios
      .get(`/users/resourcesimage/?limit=${limit}&offset=${offset}&id=${id}`, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        console.log('resorces image',res.data.value)
        console.log('resorces count image',res.data.value.c[0].c)
       
        this.setState({
         
          photos: [],
         
        });
        const resourcetypes = res.data.value.rows;
        this.setState({imageTotalcount: res.data.value.c[0].c})
        resourcetypes.filter((resourcetype) => {
          if (resourcetype.resource_type.includes("image")) {
            this.state.photos.push(resourcetype);
          
          } 
          return true;
        });
        
        this.setState({ isLoaded: true });
      })
      .catch((error) => {
        message.error("Error in fetching resources");
      });
  }


  getResourceAudio() {
    let {limitAudio,offsetAudio} = this.state;
    const id=User.uid
    axios
      .get(`/users/resourcesaudio/?limit=${limitAudio}&offset=${offsetAudio}&id=${id}`, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        console.log('resorces audio',res.data.value)
        console.log('resorces count audio',res.data.value.c[0].c)
       
        this.setState({
         
          audios: [],
         
        });
        const resourcetypes = res.data.value.rows;
        this.setState({audioTotalcount: res.data.value.c[0].c})
        resourcetypes.filter((resourcetype) => {
          if (resourcetype.resource_type.includes("audio")) {
            this.state.audios.push(resourcetype);
          
          } 
          return true;
        });
        
        this.setState({ isLoaded: true });
      })
      .catch((error) => {
        message.error("Error in fetching resources");
      });
  }


  getResourceDocument() {
    let {limitDocument,offsetDocument} = this.state;
    const id= User.uid
    axios
      .get(`/users/resourcesdocument/?limit=${limitDocument}&offset=${offsetDocument}&id=${id}`, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
       
       
        this.setState({
         
          pdfs: [],
         
        });
        const resourcetypes = res.data.value.rows;
        this.setState({DocumentTotalcount: res.data.value.c[0].c})
        resourcetypes.filter((resourcetype) => {
         
          this.state.pdfs.push(resourcetype);
          
          
          return true;
        });
        
        this.setState({ isLoaded: true });
      })
      .catch((error) => {
        message.error("Error in fetching resources");
      });
  }

  getResourceVideo() {
    let {limitVideo,offsetVideo} = this.state;
    const id= User.uid
    axios
      .get(`/users/resourcesvideo/?limit=${limitVideo}&offset=${offsetVideo}&id=${id}`, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
       
       
        this.setState({
         
          videos: [],
         
        });
        const resourcetypes = res.data.value.rows;
        this.setState({VideoTotalcount: res.data.value.c[0].c})
        resourcetypes.filter((resourcetype) => {
         
          if (resourcetype.resource_type.includes("video")) {
            this.state.videos.push(resourcetype);
          
          } 
          
          
          return true;
        });
        
        this.setState({ isLoaded: true });
      })
      .catch((error) => {
        message.error("Error in fetching resources");
      });
  }


  handlegetResource = () => {
    this.getResource();
    this.getResourceImage()
    this.getResourceAudio()
    this.getResourceDocument()
    this.getResourceVideo()
  };

  onChangeHandler = async(e) => {
    console.log("e",  e.target.files)
    let inputType = e.target.files[0] != undefined ? e.target.files[0].type.split('/'): ''
    let filename = e.target.files[0] != undefined ? e.target.files[0].name.split('.'): ''
    let ext = filename !='' ? filename.pop().toLowerCase(): ''
    console.log("file type", inputType,filename,ext)
    if(inputType[0] != undefined && 
      (inputType[0] == 'image' || inputType[0] == 'application' || inputType[0] == 'audio') && 
      (ext == 'pdf' || ext == 'doc' || ext == 'docx' || ext == 'xls' || ext == 'ppt' || ext == 'jpeg' || ext == 'jpg' || ext == 'png' || ext == 'gif' || ext == 'mp3' || ext == 'wav' || ext == 'ogg')) {
        if(e.target.files[0].size>4000000) {
          await this.setState({invalid: "Please select file upto 4MB size.", file_Name: "", resource: null})
        } else {
          await this.setState({
            resource: e.target.files[0],
            file_Name: e.target.files[0] != undefined ? e.target.files[0].name : "",
            loaded: 0,
            invalid: '',
          });
        }

    } else if(inputType[0] == 'video' || (ext == 'mp4' || ext == 'webm' || ext == 'ogv')) {
      if(e.target.files[0].size>300000000) {
        await this.setState({invalid: "Please select file upto 300MB size.", file_Name: "", resource: null})
      } else {
        await this.setState({
          resource: e.target.files[0],
          file_Name: e.target.files[0] != undefined ? e.target.files[0].name : "",
          loaded: 0,
          invalid: '',
        });
      }
    }    
    else {
      await this.setState({invalid: "Please select correct file format", file_Name: "", resource: null})
    }
    
  };

  handleCheckbox = (e) => {
    if (e.target.value == 0) {
      this.setState({ is_featured: 1 });
    } else if (e.target.value == 1) {
      this.setState({ is_featured: 0 });
    }
  };

  handleChange = (e) => {
    e.persist();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleVisibiltyChange = (e) => {
    this.setState({ visibility: e.target.value });
  };
  onClickHandler = () => {
    if(this.state.resource != null) {
      this.props.handleSubmit(true);
    let formData = new FormData();
    formData.append("resource", this.state.resource);
    formData.append("is_featured", 1);
    formData.append("visibility", this.state.visibility);
    formData.append("fileName", this.state.fileName);
    const hideMessage = message.loading('Please Wait while we are uploading the resource.', 0)

    axios
      .post("/users/resources", formData, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        hideMessage()
        this.onClose()
        message.success("Resource uploaded successfully");
        this.props.handleSubmit(false);
        this.getResource();
        this.getResourceImage()
        this.getResourceAudio()
        this.getResourceDocument()
        this.getResourceVideo()
        //toast("Resource added", { type: "success" });
      })
      .catch((error) => {
        hideMessage()
        message.error("Resource upload failed");
        this.props.handleSubmit(false);
        //toast("Error in uploading file", { type: "error" });
      });
    } else {
      message.error("No file choosen");
    }
    
  };

  handleOpenSettings = (e) => {
    let id = e.currentTarget.id;
    this.setState({ currentVideo: id });
  };

  handlePromotionalVideo = (data) => {
    const formData = {
      path: data.profileVideo_path,
      uuid: data.id,
    };
    axios
      .put("users/resources/promotionalvideo", formData, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.msg);
        } else if (!res.data.success) {
          message.error(res.data.err);
        }
        this.props.handlegetProfileVideo();
      });
  };

  handlePromotionalImage = (data) => {
    const formData = {
      path: data.avatar_path,
      uuid: data.id,
    };

    axios
      .put("users/resources/promotionalImage", formData, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.msg);
        } else if (!res.data.success) {
          message.error(res.data.err);
        }
        this.props.handlegetProfileImage();
        console.log("promotionalres", res);
      });
  };

  handleDeleteResource = (id) => {
    const hideMessage = message.loading('Please Wait while we are deleting the resource.', 0)
    axios
      .delete(`/users/resources/deleteresource/${id}`, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        hideMessage()
        if (res.data.success) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
        this.getResource();
        this.getResourceImage();
        this.getResourceAudio();
        this.getResourceDocument();
        this.getResourceVideo();
      });
  };

  handleStatus = (visibility, id) => {
    const formData = {
      visibility: visibility === "public" ? "private" : "public",
    };
    axios
      .put(`/users/resources/updateresourceStatus/${id}`, formData, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
        this.getResource();
        this.getResourceImage();
        this.getResourceAudio();
        this.getResourceDocument();
        this.getResourceVideo();
      });
  };

  handleShare = (data) => {
    this.setState({ URL: data.path, shareType: data.ResourceType });
  };

  // handleViewMore = (type) => {
  //   let resourceDataSet;
  //   if (type === "Video") {
  //     resourceDataSet = this.state.videos;
  //   }
  //   if (type === "Audio") {
  //     resourceDataSet = this.state.audios;
  //   }
  //   if (type === "Document") {
  //     resourceDataSet = this.state.pdfs;
  //   }
  //   if (type === "Photo") {
  //     resourceDataSet = this.state.photos;
  //   }
  //   this.setState({
  //     viewMoreType: type,
  //     currentResourceDataSet: resourceDataSet,
  //   });
  // };

  handleEditfileName = (data) => {
    this.setState({
      viewMoreType: data.type,
      resourceId: data.id,
      resourcePath: data.path,
      resourceName: data.filename,
    });
  };

  handleViewMore= async (type)=>{
    if (viewType === "video") {
     
        const indexOfLast = this.state.pageNumber * this.state.VideoLimit
        const indexOfFirst = indexOfLast - this.state.VideoLimit
        let limitVideo=this.state.VideoLimit
        let offsetVideo=indexOfFirst

      await this.setState({limitVideo:limitVideo,offsetVideo:offsetVideo})
        
       this.getResourceVideo()
    }
    if (viewType === "photo") {
    
     const indexOfLast = this.state.pageNumber * this.state.PhotoLimit
     const indexOfFirst = indexOfLast - this.state.PhotoLimit
     let limit=this.state.PhotoLimit
     let  offset=indexOfFirst
    await this.setState({limit:limit,offset:offset})
      console.log('viewmore click')

     this.getResourceImage()
    

    }
    if (viewType === "doc") {
     
        const indexOfLast = this.state.pageNumber * this.state.DocumentLimit
        const indexOfFirst = indexOfLast - this.state.DocumentLimit
        let limitDocument=this.state.DocumentLimit
        let  offsetDocument=indexOfFirst

      await this.setState({limitDocument:limitDocument,offsetDocument:offsetDocument})
        
       this.getResourceDocument()
    }
    if (viewType === "audio") {
        const indexOfLast = this.state.pageNumber * this.state.AudioLimit
        const indexOfFirst = indexOfLast - this.state.AudioLimit
        let limitAudio=this.state.AudioLimit
        let offsetAudio=indexOfFirst

      await this.setState({limitAudio:limitAudio,offsetAudio:offsetAudio})
        
       this.getResourceAudio()
    }
  }

  onClose = async() => {
    await this.setState({file_Name: "", resource: null, fileName: "", invalid: '', show: false})
  }

  handleShow = () => {
    this.setState({show: true})
  }

  onCloseModal = () => {
    this.setState({show: false})
    this.onClickHandler()
  }

  handleEditTitleModal = () => {
    this.setState({ editTitleModal: !this.state.editTitleModal })
  }

  handleShareModal = () => {
    this.setState({ shareModal: !this.state.shareModal })
  }
  countRenderdValue = (index) => {
    this.setState({ renderimage:index+1 })
  }

  paginate = async(pagenumber) =>{
     // console.log('call paginate',pagenumber)
   await this.setState({pageNumber: pagenumber})
    this.handleViewMore('call')
  }
  

  render() {
    //console.log('resorces count image photo',this.state.photos)
    // console.log("1-----------------",this.state.photos.length)
    let {visitorId} = this.state
    let {userdata} = this.props
  
    
    //console.log("1-----------------",visitorId ,  userdata, actionClass, visitorId === userdata.uid)


    const pdfData =
      this.state.pdfs &&
      this.state.pdfs.map((pdf,i) => {
        let array = pdf.path.split("/").pop()
        let filetype = array.split(".").pop().toLowerCase()
        //this.countRenderdValue(i)
        if (pdf.is_featured === 1)
          return (
            <div className="col-lg-3 col-md-4 col-6 my-3">
              <div className="pf__pdf">
                <a href={pdf.path} target="_blank">
                  {/* <iframe
                    alt="LG-Img"
                    //src="https://lifeguru.s3-us-east-2.amazonaws.com/assets/doc_placeholder.png"
                    src={pdf.path}
                  /> */}
                  {/* <img
                  alt="LG-Img"
                  src={documentSvg}
                  className="img-fluid"
                  width="35px"
                  /> */}
                  {filetype == "doc" || filetype == "docx" ? 
                        <img
                        alt="LG-Img"
                        src={docSvg}
                        className="img-fluid"
                        width="35px"
                      />
                       : filetype == "pdf" ? 
                       <img
                       alt="LG-Img"
                       src={pdfSvg}
                       className="img-fluid"
                       width="35px"
                     />
                      : filetype == "xls" ? 
                        <img
                        alt="LG-Img"
                        src={xlsSvg}
                        className="img-fluid"
                        width="35px"
                      />
                      : filetype == "ppt" ? 
                        <img
                        alt="LG-Img"
                        src={pptSvg}
                        className="img-fluid"
                        width="35px"
                      />
                       : filetype == "jpg" || filetype == "jpeg" || filetype == "png" || filetype == "gif" ? 
                       <img
                       alt="LG-Img"
                       src={pdf.path}
                       className="img-fluid"
                       width="35px"
                     />
                       : 
                       <img
                       alt="LG-Img"
                       src={docSvg}
                       className="img-fluid"
                     />
                       }
                </a>

                {/* <Link
                  to="profile-coach"
                  className={`deletIcon2 ${actionClass}`}
                  onClick={() => {
                    this.handleDeleteResource(pdf.uid);
                  }}
                >
                  <img
                    
                    className="deletIcon"
                    alt="LG-Img"
                    src={deleteSvg}
                  />
                </Link> */}
                <p className="resource-title">{pdf.file_name}</p>



<Dropdown className="dropdown_action">
  <Dropdown.Toggle variant="success" id="dropdown-basic">
    <img src={moreSvg} alt="LG-Img" width="25px" />
  </Dropdown.Toggle>

  <Dropdown.Menu>
    <Dropdown.Item 
    //href="profile-coach" 
    onClick={() => {  this.handleDeleteResource(pdf.uid);}}>Delete Document</Dropdown.Item>
    <Dropdown.Item 
      // data-toggle="modal"  
      // data-target="#share"
      onClick={() => {
        const data = {
          ResourceType: pdf.resource_type,
          path: pdf.path,
        };
        this.handleShare(data);
        this.handleShareModal()
      }}
    >
      Share to Social Media
    </Dropdown.Item>
    <Dropdown.Item onClick={() => this.handleStatus(pdf.visibility, pdf.uid)}>
        {pdf.visibility === "public" ? (
          <>Client View Only</>
        ) : (
          <>Public View</>
        )}    
    </Dropdown.Item>
    <Dropdown.Item
      // data-toggle="modal"
      // data-target="#editfileName"
      onClick={() => {
        const data = {
          type: "Document",
          id: pdf.uid,
          path: pdf.path,
          filename: pdf.file_name,
        };
        this.handleEditfileName(data);
        this.handleEditTitleModal()
      }}
    >
      Edit Title
    </Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>














              </div>
            </div>
          );
        else {
          return <p>...</p>;
        }
      });

    const audiosData =
      this.state.audios &&
      this.state.audios.map((audio) => {
        if (audio.is_featured === 1)
          return (
            <div className="col-md-3 pdf_item photo_item">
              <div className="pf__pdf">
                <img
                  alt="LG-Img"
                  src="https://marketplace.bantu.my/assets/audio-placeholder-304b4c582a7bc94e6bfeefa1cde5582dd56ab86affa79b6cc9d70e3027926ee8.png"
                />

                <audio controls>
                  <source src={audio.path} type="audio/ogg" />
                  <source src={audio.path} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>

                {/* <Link
                  to="profile-coach"
                  className={`deletIcon2 ${actionClass}`}
                  onClick={() => {
                    this.handleDeleteResource(audio.uid);
                  }}
                >
                  <img
                    className="deletIcon"
                    alt="LG-Img"
                    src={deleteSvg}
                  />
                </Link> */}
                <p className="resource-title">{audio.file_name}</p>
                


<Dropdown className="dropdown_action">
  <Dropdown.Toggle variant="success" id="dropdown-basic">
    <img src={moreSvg} alt="LG-Img" width="25px" />
  </Dropdown.Toggle>

  <Dropdown.Menu>
    <Dropdown.Item 
    //href="profile-coach" 
      onClick={() => {
         this.handleDeleteResource(audio.uid);
      }}
    > 
      Delete Audio
    </Dropdown.Item>
    <Dropdown.Item 
      // data-toggle="modal"
      // data-target="#share"
      onClick={() => {
        const data = {
          ResourceType: audio.resource_type,
          path: audio.path,
        };
        this.handleShare(data);
        this.handleShareModal()
      }}
    >
      Share to Social Media{" "}
    </Dropdown.Item>
    <Dropdown.Item     
      onClick={() =>
        this.handleStatus(audio.visibility, audio.uid)
      }
    >
      {audio.visibility === "public" ? (
        <>Client View Only</>
      ) : (
        <>Public View</>
      )}
    </Dropdown.Item>
    <Dropdown.Item 
      // data-toggle="modal"
      // data-target="#editfileName"
      onClick={() => {
        const data = {
          type: "Audio",
          id: audio.uid,
          path: audio.path,
          filename: audio.file_name,
        };
        this.handleEditfileName(data);
        this.handleEditTitleModal()
      }}
    >
      Edit Title
    </Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>







              </div>
            </div>
          );
        else {
          return <p>...</p>;
        }
      });

    const videoData =
      this.state.videos &&
      this.state.videos.map((video) => {
        if (video.is_featured === 1)
          return (
            <div className="col-lg-4 col-md-4 col-6 my-3">
              <VideoPlayer
                id={video.id}
                src={video.path}
                poster={
                  videoBanner
                }
              />

              {/* <Link
                to="profile-coach"
                className={`deletIcon2 ${actionClass}`}
                onClick={() => {
                  this.handleDeleteResource(video.uid);
                }}
              >
                <img
                  className="deletIcon"
                  alt="LG-Img"
                  src={deleteSvg}
                />
              </Link> */}
              <p className="resource-title">{video.file_name}</p>
              

<Dropdown className="dropdown_action">
  <Dropdown.Toggle variant="success" id="dropdown-basic">
    <img src={moreSvg} alt="LG-Img" width="25px" />
  </Dropdown.Toggle>

  <Dropdown.Menu>
    <Dropdown.Item 
    //href="profile-coach"
      onClick={() => {
      const data = {
        id: video.uid,
        profileVideo_path: video.path,
      };
      this.handlePromotionalVideo(data);
      }}
    >
      Make Promotional Video
    </Dropdown.Item>
    <Dropdown.Item 
    //href="profile-coach"
      onClick={() => {
        this.handleDeleteResource(video.uid);
      }}
    >
      Delete video
    </Dropdown.Item>
    <Dropdown.Item 
    //href="profile-coach"
      // data-toggle="modal"
      // data-target="#share"
      onClick={() => {
        const data = {
          ResourceType: video.resource_type,
          path: video.path,
        };
        this.handleShare(data);
        this.handleShareModal()
      }}
    >
      Share to Social Media{" "}
    </Dropdown.Item>
    <Dropdown.Item 
    //href="profile-coach"
      onClick={() =>
        this.handleStatus(video.visibility, video.uid)
      }
    >
      {video.visibility === "public" ? (
        <>Client View Only</>
      ) : (
        <>Public View</>
      )}
    </Dropdown.Item>
    <Dropdown.Item 
    //href="profile-coach"
      // data-toggle="modal"
      // data-target="#editfileName"
      onClick={() => {
        const data = {
          type: "Video",
          id: video.uid,
          path: video.path,
          filename: video.file_name,
        };
        this.handleEditfileName(data);
        this.handleEditTitleModal()
      }}
    >
      Edit Title
    </Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>









            </div>
          );
        else {
          return <p>...</p>;
        }
      });

    const photoData =
      this.state.photos &&
      this.state.photos.map((photo) => {
        if (photo.is_featured === 1)
          return (
            <div className="col-lg-3 col-md-4 col-6 my-3">
              <a
                href={photo.path}
                target="_blank"
                // data-toggle="lightbox"
                // data-gallery="example-gallery"
                // className="boxWrap"
              >
                <img src={photo.path} alt="Img-lg" className="img-fluid" />
              </a>
              {/* <Link
                to="profile-coach"
                className={`deletIcon2 ${actionClass}`}
                onClick={() => {
                  this.handleDeleteResource(photo.uid);
                }}
              >
                <img
                  className="deletIcon"
                  alt="Img-lg"
                  src={deleteSvg}
                />
              </Link> */}

              <p className="resource-title">{photo.file_name}</p>
              

<Dropdown className="dropdown_action">
  <Dropdown.Toggle variant="success" id="dropdown-basic">
    <img src={moreSvg} alt="LG-Img" width="25px" />
  </Dropdown.Toggle>

  <Dropdown.Menu>
    <Dropdown.Item 
    //href="profile-coach"
      onClick={() => {
        const data = {
          id: photo.uid,
          avatar_path: photo.path,
        };
        this.handlePromotionalImage(data);
      }}
    >
      Make Main Profile Image
    </Dropdown.Item>
    <Dropdown.Item 
    //href="profile-coach"
      onClick={() => {
        this.handleDeleteResource(photo.uid);
      }}
    >
      Delete Photo
    </Dropdown.Item>
    <Dropdown.Item 
    //href="profile-coach"
      // data-toggle="modal"
      //   data-target="#share"
        onClick={() => {
          const data = {
            ResourceType: photo.resource_type,
            path: photo.path,
          };
          this.handleShare(data);
          this.handleShareModal()
        }}
      >
      Share to Social Media{" "}
    </Dropdown.Item>
    <Dropdown.Item 
    //href="profile-coach"
      onClick={() =>
        this.handleStatus(photo.visibility, photo.uid)
      }
    >
      {photo.visibility === "public" ? (
        <>Client View Only</>
      ) : (
        <>Public View</>
      )}
    </Dropdown.Item>
    <Dropdown.Item 
    //href="profile-coach"
      // data-toggle="modal"
      // data-target="#editfileName"
      onClick={() => {
        const data = {
          type: "Photo",
          id: photo.uid,
          path: photo.path,
          filename: photo.file_name,
        };
        this.handleEditfileName(data);
        this.handleEditTitleModal()
      }}
    >
      Edit Title
    </Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>







            </div>
          );
        else {
          return <></>;
        }
      });

    return (
      <React.Fragment>
        <div>
        {localStorage.getItem("authToken") == null ? (
          <HeaderTop />
        ) : (
            <HeaderLogout
              currencies={this.state.currencies}
              convertToCurrency={this.state.convertToCurrency}
              changeConvertToCurrency={this.changeConvertToCurrency}
            />
          )}
        <Header />

          {this.props.check_account_type === "coach" ? (
            <div>
              <section className="resourceWrap">
                <div
                  className="container"
                  // data-toggle="modal"
                  // data-target="#exampleModalCenter"
                  onClick={this.handleShow}
                >
                  <div className="row justify-content-center">
                    <div className="col-5 resourceUpload">
                      <form className="text-center">
                        <div className="avatar">
                          <img src={upload} alt="lg-img" width="85px" />
                          <p>
                            Click to Upload{" "}
                            <span>Video, Audio, Image, Documents</span>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </section>

              <Modal
              show={this.state.show}
                className="modal fade"
                id="exampleModalCenter"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                {/* <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                > */}
                  <div className="modal-content">
                    {/* <div className="modal-header">
                      <h5 
                      style={{color:"black"}}
                      className="modal-title" id="exampleModalLongTitle">
                        Upload File
                        <Tooltip title={this.state.resource_text} placement="right"> <i className="fa fa-info-circle" aria-hidden="false"></i></Tooltip>
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div> */}
                    <div className="modal-body" style={{color:"black"}}>

                          <button type="button" className="close" aria-label="Close" data-dismiss="modal" 
                          onClick={this.onClose}>
                              <img src={mclose} alt="" width="30px"/>
                          </button>

                          <div className="heading text-center aos-init aos-animate">
                            <h3 className="text-capitalize">Upload File <Tooltip title={this.state.resource_text} placement="right"> <i className="fa fa-info-circle" aria-hidden="false"></i></Tooltip></h3>                
                            <span className="R_divider"></span>
                          </div>



                      <div
                        className="file_up"
                        style={{ marginBottom: ".8rem" }}
                      >
                          <label for="imageUpload" className="custom-file-upload">
                            <img src={upload} width="60px"/>
                          </label>
                          <p>Click to Upload </p>
                          {/* <p style={{color: "#28a745", wordBreak: 'break-word'}}>{this.state.file_Name != '' ? this.state.file_Name : ""}</p> */}
                          {this.state.invalid == '' ? (
                            <p style={{color: "#28a745", wordBreak: 'break-word', fontSize:"15px"}}>{this.state.file_Name}</p>
                          ) : (
                            <p style={{color: "red", fontSize:"15px"}}>{this.state.invalid}</p>
                          )}

                        <input
                          type="file"
                          name="resource"
                          id="imageUpload"
                          className="hide"
                          onChange={this.onChangeHandler}
                        />
                      </div>

                      <div className="modal-item modal-item-title ">
                        <label style={{ marginRight: "1.2rem" }}>Title</label>
                        <input
                          type="text"
                          value={this.state.fileName}
                          onChange={this.handleChange}
                          name="fileName"
                        />
                      </div>

                      <div className="modal-item">
                        <label style={{ marginRight: "1.2rem" }}>
                        Who can see this file{" "}
                        </label>
                        <select
                          value={this.state.visibility}
                          onChange={this.handleVisibiltyChange}
                        >
                          <option value="public">Public View</option>
                          <option value="private">Client View only</option>
                        </select>
                      </div>

                      {/* <div className="modal-item">
                        <label style={{ marginRight: "1.2rem" }}>
                        Make this my Homepage Video{" "}
                        </label>
                        <input
                          type="checkbox"
                          value={this.state.is_featured}
                          onClick={this.handleCheckbox}
                        />
                      </div> */}

                        {/* <div className="text-center mt-3">
                            <button className="defaultBtn" type="button" variant="success"
                              type="button"
                              onClick={this.onClickHandler}>Save</button>
                        </div> */}


                        {this.state.resource != null ? 
                          <div className="text-center mt-3">
                          <button className="defaultBtn" type="button" variant="success"
                            data-dismiss="modal"
                            onClick={this.onCloseModal}>Upload</button>
                        </div>
                        : ""}



                    </div>
                    {/* <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-success"
                        data-dismiss="modal"
                        onClick={this.onClickHandler}
                      >
                        Upload
                      </button>
                    </div> */}
                  </div>
                {/* </div> */}
              </Modal>
            </div>
          ) : (
            ""
          )}

     {this.state.videos && viewType=='video' && this.state.videos.length > 0 ?(
        <section className="biokWrap">
        <div className="container">
          <div className="heading text-center" style={{paddingTop:'10px'}}>
            <h3>Videos</h3>
            <span className="R_divider"></span>
          </div>
          {/* {this.state.videos.length === 0 && (
            <div
              className="pf__video__photo"
              style={{ textAlign: "center", color: "black" }}
            >
              <h4 className="">No featured video found</h4>
            </div>
          )} */}
          <div className="row justify-content-center lightBox">
            {videoData}
          </div>
          {(this.state.VideoTotalcount > this.state.VideoLimit) ?(      
   
   <Pagination postsPerPage={this.state.VideoLimit} totalPosts={this.state.VideoTotalcount} paginate={this.paginate} currentPage={this.state.pageNumber}/>
    ):(<div></div>)}
          
        </div><br/>
      </section>

     ):(<div></div>)}

     {this.state.photos && viewType=='photo' &&  this.state.photos.length > 0 ?(
       <section className="biokWrap">
       <div className="container">
         <div className="heading text-center" style={{paddingTop:'10px'}}>
           <h3>Photos</h3>
           <span className="R_divider"></span>
         </div>
         {/* {this.state.photos.length === 0 && (
           <div
             className="pf__video__photo"
             style={{ textAlign: "center", color: "black" }}
           >
             <h4 className="">No featured photo found</h4>
           </div>
         )} */}
         <div className="row justify-content-center lightBox">
           {photoData}
         </div>
         {/* <SRLWrapper>
            <div className="row justify-content-center lightBox">
              {photoData}
            </div>
         </SRLWrapper> */}
    
    
    {(this.state.imageTotalcount > this.state.PhotoLimit) ?(
   
        <Pagination postsPerPage={this.state.PhotoLimit} totalPosts={this.state.imageTotalcount} paginate={this.paginate} currentPage={this.state.pageNumber}/>
   
    ):(<div></div>)}
         
         {/* ):(<div></div>)} */}
       </div><br/>
     </section>
     ):(<div></div>)}
         
          
   {this.state.pdfs && viewType=='doc' && this.state.pdfs.length > 0 ?(
      <section className="biokWrap">
      <div className="container">
        <div className="heading text-center" style={{paddingTop:'10px'}}>
          <h3>Document</h3>
          <span className="R_divider"></span>
        </div>
        {/* {this.state.pdfs.length === 0 && (
          <div
            className="pf__video__photo"
            style={{ textAlign: "center", color: "black" }}
          >
            <h4 className="">No featured documents found</h4>
          </div>
        )} */}
        <div className="row justify-content-center lightBox">
          {pdfData}
        </div>
        {(this.state.DocumentTotalcount > this.state.DocumentLimit) ?(    
      
      <Pagination postsPerPage={this.state.DocumentLimit} totalPosts={this.state.DocumentTotalcount} paginate={this.paginate} currentPage={this.state.pageNumber}/>
      ):(<div></div>)}
        
      </div><br/>
    </section>
   ):(<div></div>)}
         
      {this.state.audios && viewType=='audio' && this.state.audios.length > 0 ?(
         <section className="biokWrap">
         <div className="container">
           <div className="heading text-center" style={{paddingTop:'10px'}}>
             <h3>Audios</h3>
             <span className="R_divider"></span>
           </div>
           {/* {this.state.audios.length === 0 && (
             <div
               className="pf__video__photo"
               style={{ textAlign: "center", color: "black" }}
             >
               <h4 className="">No featured audio found</h4>
             </div>
           )} */}
           <div className="row justify-content-center lightBox">
             {audiosData}
           </div>
           {(this.state.audioTotalcount > this.state.AudioLimit) ?(    
     
     <Pagination postsPerPage={this.state.AudioLimit} totalPosts={this.state.audioTotalcount} paginate={this.paginate} currentPage={this.state.pageNumber}/>
      ):(<div></div>)}
           
         </div><br/>
       </section>
      ):(<div></div>)}
         

          <div className="modal fade" id="share">
            <div className="modal-dialog modal-sm modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="exampleModalCenterTitle">
                    <b>Share</b>
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <ul className="share__option">
                    <SocialMediaButtons data={this.state} />
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <FooterLink />
        </div>
        <ListModal
          shareModal={this.state.shareModal}
          viewMore={this.state.viewMoreType}
          dataSet={this.state.currentResourceDataSet}
          handlePromotionalVideo={this.handlePromotionalVideo}
          handlePromotionalImage={this.handlePromotionalImage}
          handleDelete={this.handleDeleteResource}
          handleStatus={this.handleStatus}
          handleShare={this.handleShare}
          handleEditfileName={this.handleEditfileName}
          handleShareModal={this.handleShareModal}
        />

        <EditFileName
          editTitleModal={this.state.editTitleModal}
          viewMore={this.state.viewMoreType}
          resourceId={this.state.resourceId}
          resourcePath={this.state.resourcePath}
          resourceName={this.state.resourceName}
          token={this.props.token}
          handlegetResource={this.handlegetResource}
          handleEditTitleModal={this.handleEditTitleModal}
        />
      </React.Fragment>
    );
  }
}
export default ResourcesList;