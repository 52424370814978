import React from 'react'
import { dArrowblack,teamSvg,sortSvg } from '../../../assets/images'

import axios from '../../../config/axios'

class CoachTransactionhistory extends React.Component{
  constructor(props){
    super(props)
    this.state={
        transactionDetails:[],
        limit:10,
        offset:1,
        totalrow:0,
        sort:''
    }
  }
  componentDidMount(){

    this.ApiCall()
   
}

ApiCall =()=>{
  let {limit,offset,sort} = this.state;
  axios.get(`/payment/coachTransactionhistory?limit=${limit}&offset=${offset}&sort=${sort}`,{
    headers:{"x-auth":this.props.token}
  })
  .then(res =>{
    // console.log("transaction---res",res)
    if(res.data.success){
      const data=res.data.value
      this.setState({transactionDetails:data})
      this.setState({totalrow:res.data.totalrow})
    }
  })
  .catch(err =>{
    console.log(err)
  })
}

handlePrevious=()=>{
  console.log("clickesss")
  if(this.state.offset > 0){
    this.setState({offset:this.state.offset-1},()=>{
      this.ApiCall()
    })
  }
  
}

handleNext=()=>{
  
    this.setState({offset:this.state.offset+1},()=>{
      this.ApiCall()
    })
    
  
 
}
handleClick=(e)=>{

  console.log('e', e.target.getAttribute("id"))
  this.setState({offset:e.target.getAttribute("id")},()=>{
    this.ApiCall()
  })
}
handaleChange=(e)=>{

  this.setState({sort:e.target.value},()=>{
    this.ApiCall()
  })

}

    render(){
      // let pageNumbers = [1,2,3,4,5]
      const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(this.state.totalrow / this.state.limit); i++) {
        pageNumbers.push(i);
      }
      console.log("this.state.-----",this.state.transactionDetails)
        return(
          <div className="col-md-12">
          <div className="searchHead">
            <div className="searchHeading">
              <h3>
                <img width="22px" src={teamSvg} alt="LG-Img" />Transaction History
              </h3>
            </div>
            <div className="searchShortBy">
              <img width="15px" src={sortSvg} alt="LG_Img" />
              <select onChange={this.handaleChange}>
                <option>Sort by </option>
                <option value="asc">A-Z</option>
                <option value="desc">Z-A</option>
                <option value="date_asc">Date</option>
              </select>
              <span>
                <img src={dArrowblack} alt="LG_Img" />
              </span>
            </div>
          </div>
  
          <div className="userBookingList">
        {this.state.transactionDetails.map(transaction =>{
           const userDetails=JSON.parse(transaction.package_details)
          return(
          <div className="bookingCocachDetails">
            <ul>
              {/* <li><b>Order Id</b> <span>#001</span></li> */}
              <li><b>Package name</b> <span>{userDetails.session_name}</span></li>
              <li><b>Booking Date</b> <span>{transaction.created.split("T")[0]} {transaction.created.split("T")[1]}</span></li>
              <li><b>User Name</b> <span>{userDetails.consumer_name}</span></li>
              <li><b>Price</b> <span>${transaction.amount/100}</span></li>
              <li><b>Payment Type</b> <span>{transaction.payment_type}</span></li>
              {transaction.payment_status==="succeeded"?(
                <li><b>Status</b> <span className="badge text-white  badge-success">Success</span></li>
              ):(<li><b>Status</b> <span className="badge text-white text-  badge-danger">Canceled</span></li>)}
              
            </ul>
          </div>
          )
        })}


        <div className="text-center">
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center my-5">
              <li className="page-item">
                <a className="page-link"  aria-label="Previous" onClick={this.handlePrevious}>
                  <i className="fa fa-angle-left" aria-hidden="true" ></i>
                </a>
              </li>
              {pageNumbers.map(number =>{
                return(
                  <li className="page-item"><a className={this.state.offset==number? 'page-link  active' : 'page-link '} 
                  key={number}
                  id={number} 
                  onClick={this.handleClick}>{number}</a></li>
                )
              })}
             
              
              <li className="page-item">
                <a className="page-link" aria-label="Next" onClick={this.handleNext}>
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
            
  
          </div>
  
  
        </div>
        )
    }
}

export default CoachTransactionhistory