import React from "react";
import { message, Modal, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

class AntdModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  showPromiseConfirm = () => {
    //const { cancelButtonDisable } = this.state;
    const { modalTitle, modalContent, callAsyncFunction } = this.props;
    const self = this;
    confirm({
      title: modalTitle,
      icon: <ExclamationCircleOutlined />,
      centered: true,
      content: modalContent,
      onOk() {
        callAsyncFunction();
      },
      onCancel() {},
    });
  };

  render() {
    console.log('abcd',this.props)
    const { btnClassName, btnText, pendingStatus, kycStatus } = this.props;
    return (
      <>
      {kycStatus !== "1" ? (
        <Tooltip placement="top" title="Please complete your customer details (KYC) in Payment Management">
        <span style={{cursor: "not-allowed"}}>
        <button className={btnClassName} onClick={this.showPromiseConfirm} disabled={pendingStatus} style={{pointerEvents: "none"}}>
          {btnText}
        </button>
        </span>
      </Tooltip>
      ) : pendingStatus ? (
        <Tooltip placement="top" title="Previous request is pending">
          <span style={{cursor: "not-allowed"}}>
          <button className={btnClassName} onClick={this.showPromiseConfirm} disabled={pendingStatus} style={{pointerEvents: "none"}}>
            {btnText}
          </button>
          </span>
        </Tooltip>
      ) : (
        <button className={btnClassName} onClick={this.showPromiseConfirm}>
          {btnText}
        </button>
      )}
        
      </>
    );
  }
}

export default AntdModal;
