import React from "react";
import { Link } from "react-router-dom";
import ConfirmModal from "../view/ConfirmModal";
import { curencyFilter } from "../../../utils/currencyConvert";
import { Collapse, Button } from "react-bootstrap";
import { Tooltip } from "antd";
import message, { getHelpText } from "../../../utils/mesaages";

class Package extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      session_details_list: [],
      open: false,
      coachBookTooltip: "",
    };

    this.acType =
      localStorage.getItem("userdata") != null
        ? JSON.parse(localStorage.getItem("userdata")).account_type
        : "user";
  }

  async componentDidMount() {
    if (this.props.packagedata.session_details !== "") {
      var list_data = JSON.parse(this.props.packagedata.session_details);
      this.setState({ session_details_list: list_data });
    }
    let coachBookTooltip = await getHelpText("coach_book_package");
    this.setState({ coachBookTooltip });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.packagedata.session_details !==
      this.props.packagedata.session_details
    ) {
      var list_data = JSON.parse(this.props.packagedata.session_details);
      this.setState({ session_details_list: list_data });
    }
  }

  handleClick = (id) => {
    this.props.handleDeletePackage(id);
  };

  handleActivateStatus = (id) => {
    this.props.handleActivatePackageStatus(id);
  };

  handleInactivateStatus = (id) => {
    this.props.handleInactivatePackageStatus(id);
  };

  handlebook = (data) => {
    this.props.handlePackageBook(data);
  };

  render() {
    // console.log("bbbbb-----,",Number.parseFloat(10 * 1
    // ).toFixed(3))
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    console.log('IPHONE',isMobile)
    console.log("bbbbb-----,", Number.parseFloat(10 * 1.19).toFixed(3));
    console.log("SESSION DETAILS LIST :=====");
    console.log(this.props.packagedata);
    console.log(this.state.session_details_list);

    const duration = this.props.packagedata.duration;

    var hours = duration / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    const duration_time =
      rhours + (rhours > 1 ? " hours " : " hour ") + rminutes + " minutes";

    let statusButton;
    if (this.props.packagedata.isactive === 1) {
      statusButton = (
        <p
          className="dropdown-packgae"
          onClick={() => {
            this.handleInactivateStatus(this.props.packagedata.uid);
          }}
        >
          <i className="fa fa-times-circle"></i> Deactivate
          {/* <p style={{ color: "Red" }}> Inactive</p> */}
        </p>
      );
    } else if (this.props.packagedata.isactive === 0) {
      statusButton = (
        <p
          // href="/#"
          className="dropdown-packgae"
          onClick={() => {
            this.handleActivateStatus(this.props.packagedata.uid);
          }}
        >
          <i className="fa fa-check-circle"></i> Activate
          {/* <p style={{ color: "green" }}> Active</p> */}
        </p>
      );
    }
    return (
      <React.Fragment>
        <div className={this.props.packagedata.isactive ? "packborder" : ""}>
          <div className="packageList">
            <div className="packageInfo">
              <h3>
                {this.props.packagedata.title}
                <br />
              </h3>
              <p>
                <i className="fa fa-certificate" aria-hidden="true"></i>{" "}
                {/* {this.props.packagedata.total_sessions}   */}
                {this.state.session_details_list.length}
                {this.props.packagedata.total_sessions > 1
                  ? " Sessions "
                  : " Session "}
                <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                {duration_time}{" "}
              </p>
              {/* <Link
                to="profile-coach"
                data-toggle="collapse"
                data-target={`#package_collapse_${this.props.packagedata.uid}`}
              >
                Description <i className="fa fa-angle-down"></i>
              </Link> */}
              <Button
                variant="default"
                onClick={() => this.setState({ open: !this.state.open })}
                aria-controls={`package_collapse_${this.props.packagedata.uid}`}
                aria-expanded={this.state.open}
              >
                Description <i className="fa fa-angle-down"></i>
              </Button>
              <Collapse in={this.state.open}>
                <div
                  id={`package_collapse_${this.props.packagedata.uid}`}
                  //className="collapse"
                >
                  <div className="short__desc__caption">
                    <p>{this.props.packagedata.description}</p>

                    <div className="container_pk_item">
                        {this.state.session_details_list &&
                          this.state.session_details_list.map((item) => (
                            <>
                              {item.sessionName && (
                                <div className="packageListInner">
                                  <div className="packageList">
                                    <div className="packageInfo">
                                      <h5>
                                        {item.sessionName && item.sessionName}
                                      </h5>
                                      <p>{item.sessionDescription}</p>

                                      <div className="packagedropdownbutton">
                                        <div className="dropdown dropleft testi_options">
                                          <span className="badge badge-Light">
                                            {" "}
                                            {item.Length && (
                                              <h6 style={{ color: "black" }}>
                                                <i
                                                  className="fa fa-clock-o"
                                                  aria-hidden="true"
                                                ></i>
                                                &nbsp;
                                                {item.Length} minutes
                                              </h6>
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              </>
                          ))}
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>

            <div className="packageActon">
              <h4>
                &nbsp;
                {this.props.packagedata.filterCurrency}
                {/* {this.props.baseCurrency &&
                this.props.baseCurrency === this.props.convertToCurrency ? (
                  <>
                    {this.props.packagedata.price.split("##")[0] === "USD" ? (
                      <>
                        ${" "}
                        {Number.parseFloat(
                          this.props.packagedata.price.split("##")[1]
                        ).toFixed(2)}
                      </>
                    ) : (
                      
                      <>
                      {this.props.packagedata.price.split("##")[0] === "EUR" ?(
                        <>
                         ${" "}
                        {Number.parseFloat(
                          this.props.packagedata.price.split("##")[1] * 1.18
                        ).toFixed(2)}
                        </>
                      ) :(
                      <>
                         ${" "}
                        {Number.parseFloat(
                          this.props.packagedata.price.split("##")[1] * 1.32
                        ).toFixed(2)}
                      </>)}
                       
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {this.props.convertToCurrency === "USD" ? (
                      <>
                        {this.props.packagedata.price.split("##")[0] ===
                        "USD" ? (
                          <>
                            ${" "}
                            {Number.parseFloat(
                              this.props.packagedata.price.split("##")[1]
                            ).toFixed(2)}
                          </>
                        ) :(
                          <>
                          {this.props.packagedata.price.split("##")[0] ==="EUR" ? (
                          <>
                           ${" "}
                            {Number.parseFloat(
                              this.props.packagedata.price.split("##")[1] * 1.18
                            ).toFixed(2)}
                          </>):(
                          <>
                           ${" "}
                            {Number.parseFloat(
                              this.props.packagedata.price.split("##")[1] * 1.32
                            ).toFixed(2)}
                          </>)}
                           
                          </>
                        )}
                      </>
                    ) : (
                      <>
                      {this.props.convertToCurrency === "GBP" ?(
                      <>
                         {this.props.packagedata.price.split("##")[0] ===
                        "GBP" ? (
                          <>
                            £{" "}
                            {Number.parseFloat(
                              this.props.packagedata.price.split("##")[1]
                            ).toFixed(2)}
                          </>
                        ) : (
                          <>
                          {this.props.packagedata.price.split("##")[0] ==="EUR" ? (
                          <>
                           £{" "}
                            {Number.parseFloat(
                              this.props.packagedata.price.split("##")[1] / 1.12
                            ).toFixed(2)}
                          </>):(
                          <>
                           £{" "}
                            {Number.parseFloat(
                              this.props.packagedata.price.split("##")[1] / 1.31
                            ).toFixed(2)}
                          </>)}
                           
                          </>
                        )}
                      </>):(
                      <>
                       {Number.parseFloat(
                          this.props.packagedata.price.split("##")[1] *
                            this.props.findcurrency
                        ).toFixed(2)}{" "}
                        {this.props.convertToCurrency}
                      </>)}
                       
                      </>
                    )}
                  </>
                )}{" "} */}
              </h4>

              {this.props.account_type === "coach" ? (
                <div>
                  {this.props.packagedata.isactive ? (
                    <button
                      disabled={true}
                      className="defaultBtn"
                      to="/comming-soon"
                    >
                      Book
                    </button>
                  ) : (
                    <Link className="defaultBtn" to="/comming-soon">
                      Not Available
                    </Link>
                  )}
                </div>
              ) : (
                <div></div>
              )}

              {this.props.account_type === "user" && this.acType == "user" ? (
                <div>
                  {this.props.packagedata.isactive ? (
                    <div>
                      {localStorage.getItem("authToken") == null ? (
                        <div>
                          <Link className="defaultBtn" to="/registration">
                            Book
                          </Link>
                        </div>
                      ) : (
                        <button
                          className="package_button"
                          onClick={() => {
                            const data = {
                              packname: this.props.packagedata.title,
                              packUid: this.props.packagedata.uid,
                              packprice:
                                this.props.packagedata.price.split("##")[0] ===
                                "USD"
                                  ? this.props.packagedata.price.split("##")[1]
                                  : this.props.packagedata.price.split(
                                      "##"
                                    )[0] === "EUR"
                                  ? this.props.packagedata.price.split(
                                      "##"
                                    )[1] * 1.19
                                  : this.props.packagedata.price.split(
                                      "##"
                                    )[1] * 1.32,
                            };
                            this.handlebook(data);
                          }}
                          //  data-toggle="modal"
                          //  data-target="#bookpackagemodal"
                        >
                          {console.log("ppprop", this.props)}
                          <Link
                            className="defaultBtn"
                            to={{
                              pathname: `/intro-session/${this.props.coachUid}/${this.props.packagedata.uid}`,
                              state: {
                                baseCurrency: this.props.baseCurrency,
                                findcurrency: this.props.findcurrency,
                                convertToCurrency: this.props.convertToCurrency,
                              },
                            }}
                          >
                            Book
                          </Link>
                        </button>
                      )}
                    </div>
                  ) : (
                    <Link className="defaultBtn">Not Available</Link>
                  )}
                </div>
              ) : (
                <div>
                {/* {console.log('IOS TEST',isMobile)} */}
                  <Tooltip title={this.state.coachBookTooltip} placement="top" globalEventOff={ isMobile ? 'touchstart' : undefined }>
                    <span style={{ cursor: "pointer",display:"inline-block" }}>
                      <button
                        disabled={true}
                        className="defaultBtn"
                        to="/registration"
                        // onInvalid={e => e.target.setCustomValidity(this.state.coachBookTooltip)}
                        style={{ pointerEvents: "none" }}
                      >
                        Book
                      </button>
                    </span>
                  </Tooltip>
                </div>
              )}
            </div>

            {/* {this.props.account_type === "coach" && (
            <div>
              <div className="packagedropdownbutton">
                <div className="dropdown dropleft testi_options">
                  <button
                    type="button"
                    className="btn dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                  </button>

                  <div
                    className="dropdown-menu"
                    // style={{ backgroundColor: "white" }}
                  >
                    <div className="col-sm-8">
                    </div>
                    <br />

                   <li><ConfirmModal
                      fun_name={"Delete"}
                      icon={"fa fa-trash"}
                      pack_id={this.props.packagedata.uid}
                      handleClick={this.handleClick}
                    /></li> 

                    <li>{statusButton}</li>
                  </div>
                </div>

                {!this.props.packagedata.isactive ? (
                  <div className="packstatus">
                    <button type="button" className="btn btn-danger btn-circle">
                      Inactive
                    </button>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            )} */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Package;
