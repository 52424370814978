import React, { useEffect, useState, useCallback } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import axios from '../../../config/axios';
import { message } from 'antd';

import {STRIPE_KEY} from "../../../config/custom";

export default function TransactionModal(props) {

    const [Show, setShow] = useState(true)
    const [CardDetails, setCardDetails] = useState([])
    const [CardId, setCardId] = useState("")
    const [description, setdescription] = useState("")

    let userdeta = JSON.parse(localStorage.getItem("corporate_userdata"))
    let token = localStorage.getItem("corporate_token")
    
    const stripePromise = loadStripe(STRIPE_KEY);
    const ToogleChange = useCallback(() => {
        setShow(!Show)
    }, [])

    const GetAllCard = useCallback(() => {
        axios.post(`/api/corporate_card_details`, { user_id: userdeta.id }, { headers: { "X-Auth": token, } }).then((responce) => {
            if (responce.data.success) {
                if (responce.data.cardDetails.length !== 0) {
                    setShow(true)
                    document.getElementById("default_card_button").removeAttribute("disabled")
                    setCardDetails(responce.data.cardDetails)
                }
            }
        }).catch((error) => {
            message.error(error.response.msg)
        })
    }, [token, userdeta.id])

    const CompletePayment = (e) => {
        e.preventDefault()
        if (CardId === "") {
            message.error("Please Choose a card or add card to complete this payment")
            return
        }
        document.getElementById("submit_button").setAttribute("disabled", true)
        axios.post(`/api/corporate_create_charge`, { totalEmployee: props.totalEmployee, customer_id: userdeta.customer_id, card_token: CardId, amount: parseInt(props.totalAmount), users_userID: userdeta.id, user_uid: userdeta.uid }, { headers: { "X-Auth": token, } }).then((response) => {
            if (response.data.success) {
                props.handleClose()
                message.success(response.data.msg)
                props.getBalance()
            }
        }).catch((error) => {
            console.log(error)
            message.error("Something Wrong !")
        })
    }

    useEffect(() => {
        GetAllCard()
        if (props.flag === false) {
            ToogleChange()
            document.getElementById("default_card_button").setAttribute("disabled", true)
        }
    }, [GetAllCard])


    return (
        <Modal show={props.show} onHide={props.handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <p className='card-title h5'>Payment</p>
                    <p className='card-text' style={{ "fontSize": "1rem" }}>We use stripe for payment</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Button variant='default' id='default_card_button' className='defaultBtn mb-4 btn-block' onClick={() => setShow(!Show)}>{(Show === true) ? "Add Card" : "Show My Cards"}</Button>
                <div className='amp'>
                    <Form.Label>Amount Payable</Form.Label>
                    <h2>${parseInt(props.totalAmount).toFixed(2)}</h2>
                </div>
                <Form id='save_card' hidden={!Show} onSubmit={(e) => CompletePayment(e)}>
                    <div className='saved_card mb-4'>
                        <Form.Label>Pay Using Saved Card</Form.Label>
                        {
                            (CardDetails?.length === 0) && (
                                <p> No Card Found </p>
                            )
                        }
                        {
                            CardDetails?.map((cards, index) => (
                                <div className='saved_card_item'>
                                    <Form.Control type='radio' name="saved_card" value={cards.card_id} onClick={(e) => setCardId(e.target.value)} id={`saved_card${index}`} className='custom-control-input' />
                                    <Form.Label for={`saved_card${index}`}>
                                        <div className='saved_card_number'>**** **** **** {cards.last4}</div>
                                        <div className='saved_card_expiry'><small>{cards.exp_month + "/" + cards.exp_year}</small></div>
                                    </Form.Label>
                                </div>
                            ))
                        }
                        {/* <Form.Control placeholder='Enter transaction description ...' value={description} onChange={(e) => setdescription(e.target.value)} /> */}
                    </div>
                    <Button variant='default' type="submit" className='defaultBtn btn-block' id="paynowbutton" hidden />
                </Form>
                <div className='new_card' hidden={Show}>
                    <Elements stripe={stripePromise}>
                        <CheckoutForm show={ToogleChange} cardDetails={GetAllCard} />
                    </Elements>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}> Close </Button>
                <Button variant='default' id='submit_button' className='defaultBtn btn-block' style={{ "width": "31%" }} onClick={(e) => { e.preventDefault(); document.getElementById(`${(Show === true) ? "paynowbutton" : "add_card"}`).click() }}> {(Show === true) ? "PAY NOW" : "ADD CARD"} </Button>
            </Modal.Footer>
        </Modal>
    )
}
