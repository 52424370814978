import React, { Component, useEffect, useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';


const axios = require('axios');
const SessionRow = (props) => {
  console.log('props', props)

  return (
    <div>
      <Row>
        <Col>
          <div>Session Name: {props.session.sessionName}</div>
          <div>Package Time: {props.session.Length}</div>
          <div>Package Decription: {props.session.sessionDescription}</div>
        </Col>

        <Col>
        {/* {props.session.visible === true ? 
          <Form.Check
          type="checkbox"
          id="Select"
          label="Select"
          name="Select"
          checked={props.session.checked}
          disabled={props.session.disabled}
          onChange={(e) => props.handlesessioncheck(e, props.session)}
        /> 
        :''} */}
        
        </Col>

        {/* <Col>{props.session.statuss}</Col> */}
      </Row>

      <br />
    </div>


  )

}


export default SessionRow