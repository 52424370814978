module.exports=[
        {
        "name": "Premium Plus",
        "price": "149",
        "duration": "1 month"
    },
    {
        
        "name": "Premium coach",
        "price": "129",
        "duration": "1 month"
    },
    {
        "name": "Coach",
        "price": "99",
        "duration": "1 month"   
    }
]


