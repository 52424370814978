import React, { Component } from "react";
import { Link, useParams } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";

import axios from "../../../config/axios";

import "../../../assets/css/style.css";
import Calendar from "react-calendar";
import moment from "moment-timezone";
import message from "../../../utils/mesaages";
import {
  getClientTimezone,
  timezoneUtc,
  UTCtoTimezone,
  convertToTimezone,
} from "../../../utils/timeZoneConvert";

import SessionModal from "./sessionModal";

const _ = require("lodash");

export default class sessionDetails extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("authToken") || localStorage.getItem("corporate_user_token");

    this.state = {

      date: new Date(),
      currMonth: moment().format("MMMM"),
      currDate: moment().format("YYYY-MM-DD"),
      slots: [],
      duration: 0,
      coach_uid: this.props.match.params.coach_uid,
      coachTimezone: "",
      // timezone:localStorage.getItem('timezone'),
      timezone: moment.tz.guess(),
      show: false,
      data: {},
      book: true,
      selectedSlot: "",
      coach_name: "",
      coach_cetagory: ""
    };
  }

  getCoachName = () => {
    axios.post(`/webinar/get-coach-name`, { uid: this.state.coach_uid }, { headers: { "x-auth": this.token, }, }).then(({ data }) => {
      if (data.success) {
        this.setState({ coach_name: data.data.name, coach_cetagory: data.data.coaching_category })
      }
    }).catch((err) => {
      console.log(err)
      message.error("Something went wrong");
    })
  }

  getAvailability = () => {
    const hidemsg = message.loading(
      "Please wait, we are finding availability",
      0
    );

    let data = {
      coach_uid: this.state.coach_uid,
      date: this.state.currDate,
      userTimezone: moment.tz.guess(),
    };

    // console.log(data);
    axios
      .post(`/webinar/get-available-session`, data)
      .then((res) => {
        hidemsg();
        // console.log("availabity res", res.data);

        if (res.data.success) {
          this.setState({
            slots: res.data.value,
            coachTimezone: res.data.coachTimezone,
            duration: res.data.duration,
          });
          if (res.data.value.length <= 0) {
            message.loading(`No slots available on ${moment(this.state.currDate).format("DD-MM-YYYY")}`);
          }
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        hidemsg();
        console.log(err);
        message.error("Failed! Something Went Wrong");
      });
  };

  dateChange = (date) => {
    if (moment(date).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")) {
      message.error("Please Choose a Future date");
    } else {
      this.setState(
        {
          date,
          currDate: moment(date).format("YYYY-MM-DD"),
          book: true
        },
        () => {
          this.getAvailability();
        }
      );
    }
  };

  chooseSlot = (e) => {
    // console.log(e.target.value.from)
    let v = e.target.value;
    let date = e.target.getAttribute("ddate");
    console.log("date", date, v);
    let from = v.split(" ")[0];
    let to = v.split(" ")[1];
    if (from != undefined && to != undefined) {
      let selectedSlot = { date, from: from, to: to };
      this.setState({ selectedSlot, book: false }, () => {
        console.log("selectedSlot", selectedSlot);
      });
    }
  };

  bookSession = () => {
    let data = {
      slot: this.state.selectedSlot,
      coach_uid: this.props.match.params.coach_uid,
      duration: this.state.duration,
      coach_tz: this.state.coachTimezone,
    };
    console.log("data", data)
    this.setState({ show: true, data: data });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleBook = () => {
    this.setState({ book: true });
    this.getAvailability();
  };

  componentDidMount() {
    this.getCoachName();
    this.getAvailability();
  }

  render() {
    return (
      <>
        <SessionModal
          data={this.state.data}
          show={this.state.show}
          handleBook={this.handleBook}
          handleClose={this.handleClose}
          categoty={this.state.coach_cetagory}
        />

        <div className="content_wrap">
          <div className="card_style_1 border-0">
            <div className="card-header d-flex justify-content-between">
              <h4 className="card-title">Select an available time to book a coaching session</h4>
              <h4 className="card-title"> Coach Name : - {this.state.coach_name} </h4>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="bookingCalendar">
                  <Calendar
                    onChange={this.dateChange}
                    value={this.state.date}
                    className="calendar"
                    minDate={new Date()}
                  />
                </div>
              </div>
            </div>
            <div className="card-body">
              <ul className="bs_legend list-inline">
                <li className="legend_item list-inline-item">
                  <span className="legend_box"></span> Unavailable
                </li>
                <li className="legend_item list-inline-item">
                  <span className="legend_box available"></span> Available
                </li>
              </ul>
              <div className="areas areasNew">
                {this.state.slots.length > 0 ? (
                  this.state.slots.map((slot, index) => {
                    return (
                      <>
                        <div className="slot_item">
                          <div
                            className={
                              slot.booked !== undefined
                                ? slot.booked == true
                                  ? "custom-control custom-checkbox checkbox-inline disabled"
                                  : "custom-control custom-checkbox checkbox-inline"
                                : "custom-control custom-checkbox checkbox-inline "
                            }
                          >
                            <input
                              type="radio"
                              onChange={this.chooseSlot}
                              ddate={slot.from.split(" ")[0]}
                              value={
                                slot.from.split(" ")[1] +
                                " " +
                                slot.to.split(" ")[1]
                              }
                              disabled={
                                slot.booked !== undefined ? slot.booked : false
                              }
                              className="custom-control-input"
                              id={index}
                              name="Sundays"
                            />

                            <label className="custom-control-label" for={index}>
                              {UTCtoTimezone(
                                this.state.timezone,
                                timezoneUtc(this.state.coachTimezone, slot.from),
                                "HH:mm"
                              )}{" "}
                              -{" "}
                              {UTCtoTimezone(
                                this.state.timezone,
                                timezoneUtc(this.state.coachTimezone, slot.to),
                                "HH:mm"
                              )}
                            </label>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <>
                    <div className="unavailable_block text-center p-3 mt-4 w-50 ml-auto mr-auto">
                      {/* Slots unavailable on {this.state.currDate} */}
                      Please select date to view all available time slots
                    </div>
                  </>
                )}
              </div>

              <div className="text-center mt-4">
                <button
                  className="btn defaultBtn"
                  onClick={this.bookSession}
                  disabled={this.state.book}
                >
                  Book
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
