import React, { useState } from 'react'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import LoadingOverlay from 'react-loading-overlay'
import { LGspinner } from '../../../assets/images'
import CustomImageLoader from "react-custom-image-loader.";
import moment from 'moment'
import { Button, Card, Col, Form, InputGroup, Row, Table } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

export default function AccountTransfare() {
    const [loader, setloader] = useState(false)
    const [search, setsearch] = useState("")
    const { account_id } = useParams()
    console.log(account_id)

    return (
        <div className="content_main bulk_upload">
            <Header />
            <LoadingOverlay active={loader} className="webiner_loader" spinner={<CustomImageLoader image={LGspinner} animationType={"none"} />}>
                <div className="content_wrap">

                    <h4 className="page-title mb-3">Transfar Account</h4>

                    <Row>
                        <Col md={12} className="my-2">
                            <Button variant='default' className="defaultBtn mr-2 rounded-lg w-100"> Transfar Account </Button>
                        </Col>
                        <Col md={12}>
                            <Card className="crCard border-0 mb-4">
                                <Card.Header className="bg-white ch-tool">
                                    <Row className="align-items-center">
                                        <Col md={6}>
                                            <Card.Title className="mb-0 h4">Account Transfar List</Card.Title>
                                        </Col>
                                        <Col md={6}>
                                            <InputGroup className="search_webiner">
                                                <Form.Control className="border-0 m-0" placeholder="Type here..." value={search} onChange={(e) => setsearch(e.target.value)} />
                                                <InputGroup.Text><i className="fa fa-search" /></InputGroup.Text>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    {
                                        <Table responsive className="mb-0 text-center">
                                            <thead>
                                                <tr>
                                                    <th className="border-top-0">SL. No.</th>
                                                    <th className="border-top-0">Name</th>
                                                    <th className="border-top-0">Email</th>
                                                    <th className="border-top-0">Phone</th>
                                                    <th className="border-top-0">Mail Send</th>
                                                    <th className="border-top-0">Joined</th>
                                                    <th className="border-top-0">Group Name</th>
                                                    <th className="border-top-0">Status</th>
                                                    <th className="border-top-0">Created At</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            </tbody>
                                        </Table>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </LoadingOverlay>
            <Sidebar />
        </div>
    )
}
