import React, { useState } from "react";
//import { Player, ControlBar, PlaybackRateMenuButton, VolumeMenuButton  } from "video-react";
import ReactPlayer from 'react-player'

function VideoPlayer(props) {
  const [thumbnailDelay, setThumbnailDelay] = useState(false)
  const [durationValue, setDurationValue] = useState('')


  const getvideoDuration = (value) => {
    let ret = calculateDuration(value)

    console.log("duration value", value)

    if(value) {
      setThumbnailDelay(props.poster)
      setDurationValue(ret)
    }
  }

  const calculateDuration = (val) => {
    let duration = Math.floor(val)
        let hrs = ~~(duration / 3600)
        let mins = ~~((duration % 3600) / 60)
        let secs = (duration % 60)
        var ret = "";
        if (hrs > 0) {
          ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
  
        ret += "" + (mins < 10 ? "0" : "") + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;

        return ret
  }

  return (
    // <video
    //   id={props.id}
    //   width="100%"
    //   height="200"
    //   style={{ width: "100%;" }}
    //   poster={props.poster}
    //   preload="none"
    //   controls={props.flag == '0' ? false : true}
    //   playsInline
    //   webkit-playsinline
    // >
    //   <source src={props.src} type="video/mp4" />
    //   <track srcLang="en" kind="subtitles" src="mediaelement.vtt" />
    //   <track srcLang="en" kind="chapters" src="chapters.vtt" />
    // </video>

    <ReactPlayer 
    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
    url={`${props.src}`}
    id={props.id} 
    controls={true} 
    // muted={true} 
    playing={true} 
    // loop={true}
    width="100%"
    height="200px"
    //playsinline
    light={thumbnailDelay}
    onDuration={(duration) => getvideoDuration(duration)}
    //playIcon={<p className='deletIcon2 show'>{props.time}<br/>Play</p>} 
    playIcon={<div className="playVideoIcon"><i className="fa fa-play fa-3x" aria-hidden="true"></i><br/><b>{durationValue}</b></div>} 
    />
    // <Player
    //     id={props.id}
    //     playsInline
    //     fluid={false}         
       
    //     src={props.src} 
    //     className="m-auto"
    // />   
    



    
  );
}

export default VideoPlayer;
