import React, { Component } from "react";
import { Header, HeaderTop } from "../../../components/headers";
import { FooterLink } from "../../../components/footers";
import axios,{AdminApi} from "../../../config/axios";
import { Redirect } from "react-router-dom";

import { toast } from "react-toastify";
import message from '../../../utils/mesaages';

class AccountActivation extends Component {
  constructor() {
    super()
    this.state = {
      activation_status: "Wait a minute...",
      redirect: false,
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
 async  componentWillMount() {
    const token = this.props.match.params.token;

    // var settings = {
    //   "async": true,
    //   "crossDomain": true,
    //   "url": "https://video.lifeguru.io/b/api/v1/user_signup",
    //   "method": "POST",
    //   "headers": {
    //     "content-type": "application/x-www-form-urlencoded",
    //     "cache-control": "no-cache",
    //     "postman-token": "4ef22174-6e45-2b6b-cd36-f4f6311729bb"
    //   },
    //   "data": {
    //     "email": "zcdfsdf@sdfsd.com"
    //   }
    // }

    // axios(settings).then(function (response) {
    //   console.log(response);
    // }).catch(e=>{
    //   console.log("addsad")
    // });
   

    if (token) {
      axios
        .put(`/activate/${token}`, {})
        .then((response) => {
          if (response.data.success) {
            message.success("Account is activated successfully!");
            this.setState({
              activation_status:
                "Congratulations! Your account is activated successfully!",
              redirect: true,
            });
            //Signup 
            axios.get(`${AdminApi}/signup/${token}`, {})
            .then(function (response) {
            console.log(response);
            })
            .catch(function (error) {
            console.log(error);
            });
            //
          } else {
            message.error("Error in activating account");
            this.setState({
              activation_status:
                "There has been some problem! Please contact admin.",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          message.error("Error in activating account");
          this.setState({
            activation_status:
              "There has been some problem! Please contact admin.",
          });
        });
    }
  }

  render() {
    const { redirect } = this.state;

     if (redirect) {
       return <Redirect to='/login'/>;
     }
    return (
      <div>
        <HeaderTop />
        <Header />
        <div className="container padding-bottom-3x mb-2 mt-5">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              {this.state.activation_status}
            </div>
          </div>
        </div>

        <FooterLink />
      </div>
    );
  }
}
export default AccountActivation;
