import React, { Component } from "react";
import { Link } from "react-router-dom";
import {Container, Row, Col} from "react-bootstrap";
import { FooterLink } from "../components/footers";
import {
  Business_banner,
  Business_banner_bg,
  Hehb_left,
  Hehb1,
  Hehb2,
  Hehb3,
  Hehb4,
  Bbi,
  BbIcon1,
  BbIcon2,
  BbIcon3,
  Hs,
  SOLUTIONS_FOR_YOU,
} from "../assets/images";
import axios from '../config/axios';
import HeaderSecondTo from "../components/corporate/Header/HeaderSecondTo";
import { message } from 'antd';
import CustomImageLoader from "react-custom-image-loader.";
import LoadingOverlay from 'react-loading-overlay';
import { LGspinner } from '../assets/images';
import OurPartner from "./ourPartner.js";

class ForBusiness extends Component {
  constructor() {
    super();   
    
    this.state = {
      loader: false,
      firstRow: [],
    }
  }

  GetContaint = () => {
    this.setState({ loader: true });
    axios.post(`/api/corporate_row_one_contect/`, { "row_id": "9" }).then(async (response) => {
        if (response.data.success) {
            this.setState({ firstRow: response.data.deta }, () => {
                console.log(this.state.firstRow)
            })
            this.setState({ loader: false });
        }
    }).catch((error) => {
        message.error(error.response.data.msg);
        this.setState({ loader: false });
    });
}

  componentDidMount() {
    this.GetContaint();
  }
  render() {
   
    return (
      <React.Fragment>
        <LoadingOverlay active={this.state.loader}
          spinner={<CustomImageLoader image={LGspinner} animationType={'pulse'} />}
          styles={{
          wrapper: {
            overflow: this.state.loader ? 'hidden' : ''
          }
          }}
        >

        
        {/* {localStorage.getItem('authToken')==null ?(<HeaderTop/>):(<HeaderLogout/>)} */}
        {/* <Header/> */}
        <HeaderSecondTo />


        <section className="tc_banner">
          <div className="tcb_back d-flex">
            <div className="tcb_img col" style={{background: 'url('+Business_banner_bg+')'}}></div>
            <div className="tcb_img col" style={{background: 'url('+Business_banner+')'}}></div>
          </div>
          <Container>
            <Row>
              <Col md={6}>
                <div className="tcb_text pl-0">
                  <div className="heading mb-4">
                    <h3 className="text-uppercase">Empower your workforce to thrive and perform at their best</h3>
                  </div>
                  <p>Coach Master Workplace Wellness provides solutions that work for employers and employees</p>
                  <Link to="/contact_us" className="btn btn-default defaultBtn mt-4">Request a demo</Link>
                </div>
              </Col>
              <Col md={6}></Col>
            </Row>
          </Container>
        </section>

        <section className="sfy">
          <Container>
            <Row className="align-items-center justify-content-between">
              <Col md={5}>
                <img src={SOLUTIONS_FOR_YOU} className="img-fluid" />
              </Col>
              <Col md={7}>
                <div className="heading  mb-4">
                  <h3 className="text-uppercase">Solutions for you</h3>
                </div>
                <p className="mb-0">Simply create an account and invite as many of your employees you would like to gain access to our wellness platform. Once your team members have access, they simply show up to the webinars. Many of our expert coaches also offer private 1:1 coaching. Programs can be booked separately to accelerate and enhance employee wellness, performance and happiness.</p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="hehb">
          <div className="hehb_back d-flex">
            <div className="hehb_back_left" style={{background: 'url('+Hehb_left+') center center no-repeat', backgroundSize: 'cover'}}></div>
            <div className="hehb_back_right"></div>
          </div>
          <Container>
            <Row>
              <Col md={5}></Col>
              <Col md={7} className="hehb_right">
                <div>
                  <div className="heading mb-4">
                    <h6 className="text-uppercase">Healthy employees Healthy business</h6>
                    <h3 className="text-uppercase">Bespoke programs tailored to meet your objectives</h3>
                  </div>
                  <div className="behbRow">
                    <Row>
                      <Col md={6} className="behbCol">
                        <div className="behbCard py-3">
                          <div className="Hehb_icon">
                            <img src={Hehb1} width={38} height={38} />
                          </div>
                          <h4>Comprehensive Support</h4>
                          <p className="mb-0">Your account manager will work with you to create a wellness program that works for your organisation.</p>
                        </div>
                      </Col>
                      <Col md={6} className="behbCol">
                        <div className="behbCard py-3">
                          <div className="Hehb_icon">
                            <img src={Hehb2} width={38} height={38} />
                          </div>
                          <h4>Personalised approach</h4>
                          <p className="mb-0">From set up and throughout the program your account manager is available to support your organisational needs</p>
                        </div>
                      </Col>
                      <Col md={6} className="behbCol">
                        <div className="behbCard py-3">
                          <div className="Hehb_icon">
                            <img src={Hehb3} width={38} height={38} />
                          </div>
                          <h4>Monitor impact</h4>
                          <p className="mb-0">Easy to navigate dashboard ensures you can track your employees wellness progress and make adjustments where necessary</p>
                        </div>
                      </Col>
                      <Col md={6} className="behbCol">
                        <div className="behbCard py-3">
                          <div className="Hehb_icon">
                            <img src={Hehb4} width={38} height={38} />
                          </div>
                          <h4>Make a difference</h4>
                          <p className="mb-0">We create purposeful wellness programs that support your business growth and create a happy, healthy workforce</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="bbi">
          <Container>
            <Row className="justify-content-center">
              <Col md={7}>
                <div className="heading text-center text-uppercase mb-4">
                  <h3>The business benefits of investing in your workforce</h3>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col md={6}>
                <div className="bbi_card">
                  <Row>
                    <Col sm="auto">
                      <img src={BbIcon1} width={65} height={65} />
                    </Col>
                    <Col>
                      <h2>93<sub>%</sub></h2>
                      <p className="mb-0">Of workers who reported feeling valued said that they are motivated to do their best at work.</p>
                    </Col>
                  </Row>
                </div>
                <div className="bbi_card">
                  <Row>
                    <Col sm="auto">
                      <img src={BbIcon2} width={65} height={65} />
                    </Col>
                    <Col>
                      <h2>91<sub>%</sub></h2>
                      <p className="mb-0">Of workers with access to wellness programs feel motivated to do more.</p>
                    </Col>
                  </Row>
                </div>
                <div className="bbi_card">
                  <Row>
                    <Col sm="auto">
                      <img src={BbIcon3} width={65} height={65} />
                    </Col>
                    <Col>
                      <h2>89<sub>%</sub></h2>
                      <p className="mb-0">Of workers at companies that support well-being efforts are more likely to recommend their company as a good place to work.</p>
                    </Col>
                  </Row>
                </div>
                <div><i>American Psychological Association</i></div>
              </Col>
              <Col md={6}>
                <img src={Bbi} className="img-fluid" />
              </Col>
            </Row>
          </Container>
        </section>
        

        <section className="hs">
          <Container>
            <Row className="align-items-center">
              <Col md={6}>
                <img src={Hs} className="img-fluid" />
              </Col>
              <Col md={6}>
                <h4>A Harvard study concluded that for each $1 spent on a wellness program the employer saved $3 in healthcare costs and another $3 in reduced absenteeism</h4>
              </Col>
            </Row>
          </Container>
        </section>


        <section className="tot">
          <Container>
            <Row className="align-items-center">
              <Col md={8}>
                <div className="heading">
                  <h6 className="text-uppercase">Need help?</h6>
                  <h3 className="text-uppercase">talk to our team</h3>
                </div>
              </Col>
              <Col md={4} className="text-right">
                <Link className="btn defaultBtn" to="/contact_us">talk to our team</Link>
              </Col>
            </Row>
          </Container>
        </section>

        <OurPartner firstRow = {this.state.firstRow} />
        
         <FooterLink/>
        </LoadingOverlay>
       
      </React.Fragment>
    );
  }
}

export default ForBusiness;
