import React from "react";
import _ from "lodash";
import { Redirect } from "react-router-dom";
import GoogleLogin from "react-google-login";
import axios,{AdminApi} from "../../../config/axios";
import { google } from "../../../assets/images";
import message from '../../../utils/mesaages';

class Google extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      Uuid: "",
      accessToken: " ",
      loader: false,
    };
  }

  responseGoogle = (response) => {
    if (response && response.profileObj) {
      this.setState({
        accessToken: response.accessToken,
      });
      const hideMessage = message.loading('Please wait, we are saving your data ....', 0)
      var tracking_link = '';
      if (localStorage.getItem('tracking_link') != undefined || localStorage.getItem('tracking_link') != null) {
        // this.setState({tracking_link:localStorage.getItem('tracking_link')})
        tracking_link = localStorage.getItem('tracking_link')
      }
      const formData = {
        fullname: response.profileObj.name,
        email: response.profileObj.email,
        account_type: this.props.usertype,
        coaching_category: this.props.categorytype,
        status: 1,
        tracking_link: tracking_link
      };
      console.log("google formData", formData);
      //let token = this.state.accessToken
      axios
        .post("/signup", formData)
        .then((response) => {
          hideMessage()
          console.log("gresponse", response);
          if (
            response.data.msg === "Registered Successfully" &&
            response.data.result === true
          ) {
            localStorage.removeItem('tracking_link')
            this.setState({
              isLoggedIn: response.data.result,
              Uuid: response.data.userdata.uid,
            });
            message.success(" Registered Successfully");
            //Signup 
            let token = response.data.userdata.uid
            axios.get(`${AdminApi}/signup/${token}`, {})
              .then(function (response) {
                hideMessage()
                console.log(response);
              })
              .catch(function (error) {
                hideMessage()
                console.log(error);
              });
            //
          } else if (
            
            response.data.result === false ||
            response.data.msg === "User already exists!"
          ) {
            hideMessage()
            message.success(response.data.msg);
            
          }
        })
        .catch((err) => {
          hideMessage()
          console.log(err);
          message.success("Error signing up using google.");
        });
    } else {
      if (response.error === 'popup_closed_by_user') {
        let tooltipTitle = React.createElement("p", null, "Please enable cookies in your browser. You can check this document for ", React.createElement("a", { href: 'https://support.google.com/accounts/answer/61416?hl=en&co=GENIE.Platform%3DDesktop', target: '_blank' }, "more information"));
        message.info(tooltipTitle);
      }
    }
  };

  render() {
    let googleContent;

    if (this.state.isLoggedIn && !_.isEmpty(this.state.accessToken)) {
      googleContent = (
        <div>
          return{" "}
          <Redirect
            to={{
              pathname: "/login",
              state: {
                user_t: this.state.Uuid,
              },
            }}
          />
        </div>
      );
    } else {

    if(this.props.buttonstatus===true || this.props.usertype ===-1)
    {
      googleContent = (
        <button className="googlebutton" onClick={this.props.termsandcondition}><img src={google} /> Sign up with Google</button>
      );
     }else{
      googleContent = (
        <GoogleLogin
          clientId="839591354107-ut5lnt2j9oqa0phboum3u7i0jk410ine.apps.googleusercontent.com"
          // ButtonText="Log in with Google"
          buttonText="Sign up with Google"
          onSuccess={this.responseGoogle}
          onFailure={this.responseGoogle}
          cookiePolicy={"single_host_origin"}
        />
      );

      }
    
    }

    return (
      <div>
        <li className="google-button">{googleContent}</li>
      </div>
    );
  }
}
export default Google;
