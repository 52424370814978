import React from "react";
import { Link } from 'react-router-dom'
import { dArrowblack } from "../../assets/images";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import AsyncSelect from 'react-select/async';
import { Row } from "react-bootstrap";
import axios from "../../config/axios";
// import Typical from 'react-typical'
import TagsInput from "../../layouts/TagsInput";

const customStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    console.log({ data, isDisabled, isFocused, isSelected });
    return {
      ...styles,
      backgroundColor: isFocused ? "#999999" : null,
      color: "#333333"
    };
  }
};
class MasterCoach extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      category: "",
      tags: [],
      optionTags: [],
      selectedValue: [],
      setinputtagg: []
    }
    this.fetchTagsFromApi = this.fetchTagsFromApi.bind(this)
    this.handleChangeTags = this.handleChangeTags.bind(this)
  }
  componentWillMount() {
    this.fetchInitialtags()
  }

  fetchInitialtags = inputValue => {
    let self = this
    return new Promise(resolve => {
      axios.post("/consumer/filterSkills", { tags: 'a' })
        .then(res => {
          console.log("Res-", res)
          let tags = res.data.map(d => ({ value: d, label: d }))
          // return resolve(tags);
          self.setState({ optionTags: tags })
          //console.log(self.state.tags)
        })
        .catch(err => {
          console.log(err)
        })

    });

  }

  fetchTagsFromApi = inputValue => {
    console.log('input value', inputValue)
    let self = this
    return new Promise(resolve => {
      axios.post("/consumer/filterSkills", { tags: inputValue })
        .then(res => {
          console.log("Res-", res)
          let tags = res.data.map(d => ({ value: d, label: d }))
          return resolve(tags);
          //  self.setState({tags})
          //console.log(self.state.tags)
        })
        .catch(err => {
          console.log(err)
        })

    });

  }

  handleChange = (e) => {
    console.log("eee", e.target.value)
    this.setState({
      [e.target.name]: e.target.value
    })
  }


  handleSubmit = (e) => {
    e.preventDefault()
    const formData = {
      category: this.state.category,
      tags: this.state.tags
    }
    console.log('formdata', formData)

  }
  options = [

    { key: 'Career Coaching', label: 'Career Coaching' },
    { key: 'Executive Coaching', label: 'Executive Coaching' },
    { key: 'Life Coaching', label: 'Life Coaching' },
    { key: 'Transformational Coaching', label: 'Transformational Coaching' },
    { key: 'Health & Wellbeing Coaching', label: 'Health & Wellbeing Coaching' },
    { key: 'Business Coaching', label: 'Business Coaching' },

  ]


  check = () => {
    // if(this.state.selectedValue !== undefined && this.state.selectedValue !== null &&  this.state.selectedValue.length > 0){
    //   tagValues = this.state.selectedValue.map((each) => {
    //     return each.label
    //   })
    // }
    // console.log('tags' , tagValues);
    return this.state.setinputtagg;
  }

  check2 = () => {

    console.log('cat', this.state.setinputtagg);
  }

  setinputtag = (t) => {
    this.setState({ setinputtagg: [...this.state.setinputtagg, t] })
  }

  deleteinputtag = (i) => {
    const { setinputtagg } = this.state;
    const newTags = setinputtagg.filter((tag, j) => i !== j);
    this.setState({
      setinputtagg: newTags
    });

  }

  handleChangeTags(value, { action, removedValue }) {
    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.isFixed !== undefined) {
          return;
        }
        break;
      case 'clear':
        value = []
        break;
    }


    this.setState({ selectedValue: value });
    //console.log("ORIGINAL TAGS VALUE", this.state.tags)
    //this.props.data.skills = value //[...this.state.favorites, favorite.coach_uid]
  }

  render() {
    return (
      <div>
        <section className="searchWrap">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="heading text-center">
                  <h3 className="cd-headline clip is-full-width">

                    <span className="cd-words-wrapper" style={{ width: "100px" }}>
                      <b className="is-visible">Career</b>
                      <b className="is-hidden"> Executive</b>
                      <b className="is-hidden">Life</b>
                      <b className="is-hidden">Transformational</b>
                      <b className="is-hidden">Health & Wellbeing</b>
                      <b className="is-hidden"> Business</b>
                    </span>
                    {/* <Typical
                      steps={['Career', 5000, 'Executive', 5000, 'Life', 5000, 'Transformational', 5000, 'Health & Wellbeing', 6000, 'Business', 5000]}
                      loop={Infinity}
                      wrapper="span"
                    /> */}

                    <span>Coaching </span>
                  </h3>
                  <span className="R_divider"></span>
                </div>
              </div>







              <div className="col-md-9">
                <div className="searchPart">
                  <div className="searchList">
                    <div className="selectBox w-100 mx-auto ">
                      <DropdownMultiselect placeholder="Select coaching categories" options={this.options} name="category" className="Dip"
                        handleOnChange={(selected) => {
                          this.setState({ category: selected });
                        }}
                      />
                    </div>
                  </div>
                  <div className="searchList">

                    <TagsInput
                      setinputtag={this.setinputtag}
                      deleteinputtag={this.deleteinputtag}
                      setinputtagg={this.state.setinputtag}
                      
                    >
                    </TagsInput>
                    {/* <input type="text" placeholder="Select Keyword..." className="form-control" value={this.state.tags}  onChange={this.handleChange} name="tags"/> */}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <Link
                  to={{
                    pathname: "findmy_coach",
                    state: {
                      Category: this.state.category,
                      Tags: this.check(),
                      Loginstate: false,
                      test: 'test1'
                    }
                  }}><button className="defaultBtn" type="button">
                    Find my coach
                  <span>
                      <svg
                        width="15px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        fill="#fff"
                      >
                        <path d="M508.875 248.458l-160-160c-4.167-4.167-10.917-4.167-15.083 0-4.167 4.167-4.167 10.917 0 15.083l141.792 141.792H10.667C4.771 245.333 0 250.104 0 256s4.771 10.667 10.667 10.667h464.917L333.792 408.458c-4.167 4.167-4.167 10.917 0 15.083a10.634 10.634 0 007.542 3.125c2.729 0 5.458-1.042 7.542-3.125l160-160c4.166-4.166 4.166-10.916-.001-15.083z" />
                      </svg>
                      <svg
                        width="15px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        fill="#fff"
                      >
                        <path d="M508.875 248.458l-160-160c-4.167-4.167-10.917-4.167-15.083 0-4.167 4.167-4.167 10.917 0 15.083l141.792 141.792H10.667C4.771 245.333 0 250.104 0 256s4.771 10.667 10.667 10.667h464.917L333.792 408.458c-4.167 4.167-4.167 10.917 0 15.083a10.634 10.634 0 007.542 3.125c2.729 0 5.458-1.042 7.542-3.125l160-160c4.166-4.166 4.166-10.916-.001-15.083z" />
                      </svg>
                    </span>
                  </button></Link>
              </div>

              <div className="col-md-12 text-center mt-3">
                <p>OR</p>
                <Link to="/matchmy_coach">
                  <button className="defaultBtn w-25" type="button">
                    Match my coach
                  <span>
                      <svg
                        width="15px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        fill="#fff"
                      >
                        <path d="M508.875 248.458l-160-160c-4.167-4.167-10.917-4.167-15.083 0-4.167 4.167-4.167 10.917 0 15.083l141.792 141.792H10.667C4.771 245.333 0 250.104 0 256s4.771 10.667 10.667 10.667h464.917L333.792 408.458c-4.167 4.167-4.167 10.917 0 15.083a10.634 10.634 0 007.542 3.125c2.729 0 5.458-1.042 7.542-3.125l160-160c4.166-4.166 4.166-10.916-.001-15.083z" />
                      </svg>
                      <svg
                        width="15px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        fill="#fff"
                      >
                        <path d="M508.875 248.458l-160-160c-4.167-4.167-10.917-4.167-15.083 0-4.167 4.167-4.167 10.917 0 15.083l141.792 141.792H10.667C4.771 245.333 0 250.104 0 256s4.771 10.667 10.667 10.667h464.917L333.792 408.458c-4.167 4.167-4.167 10.917 0 15.083a10.634 10.634 0 007.542 3.125c2.729 0 5.458-1.042 7.542-3.125l160-160c4.166-4.166 4.166-10.916-.001-15.083z" />
                      </svg>
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default MasterCoach;
