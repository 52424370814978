import React from 'react';
import { BrowserRouter } from 'react-router-dom'
import { getApp } from './helpers';

function App() {
  const CurrentApp = getApp()
  return (
    <BrowserRouter>
      <CurrentApp />
    </BrowserRouter>
  );
}

export default App;
