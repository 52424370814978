module.exports=[
    {
        "value":"(GMT -12:00) Eniwetok, Kwajalein",
        "data":"-12:00"
    },
    {
        "value":"(GMT -11:00) Midway Island, Samoa",
        "data":"-11:00"
    },
    {
        "value":"(GMT -10:00) Hawaii",
        "data":"-10:00"
    },
    {
        "value":"(GMT -9:30) Taiohae",
        "data":"-09:50"
    },
    {
        "value":"(GMT -9:00) Alaska",
        "data":"-09:00"
    },
    {
        "value":"(GMT -8:00) Pacific Time (US &amp; Canada)",
        "data":"-08:00"
    },
    {
        "value":"(GMT -7:00) Mountain Time (US &amp; Canada)",
        "data":"-07:00"
    },
    {
        "value":"(GMT -6:00) Central Time (US &amp; Canada), Mexico City",
        "data":"-06:00"
    },
    {
        "value":"(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima",
        "data":"-05:00"
    },
    {
        "value":"(GMT -4:30) Caracas",
        "data":"-04:50"
    },
    {
        "value":"(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz",
        "data":"-04:00"
    },
    {
        "value":"(GMT -3:30) Newfoundland",
        "data":"-03:50"
    },
    {
        "value":"(GMT -3:00) Brazil, Buenos Aires, Georgetown",
        "data":"-03:00"
    },
    {
        "value":"(GMT -2:00) Mid-Atlantic",
        "data":"-02:00"
    },
    {
        "value":"(GMT -1:00) Azores, Cape Verde Islands",
        "data":"-01:00"
    },
    {
        "value":"(GMT) Western Europe Time, London, Lisbon, Casablanca",
        "data":"+00:00"
    },
    {
        "value":"(GMT +1:00) Brussels, Copenhagen, Madrid, Paris",
        "data":"+01:00"
    },
    {
        "value":"(GMT +2:00) Kaliningrad, South Africa",
        "data":"+02:00"
    },
    {
        "value":"(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg",
        "data":"+03:00"
    },
    {
        "value":"(GMT +3:30) Tehran",
        "data":"+03:50"
    },
    {
        "value":"(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi",
        "data":"+04:00"
    },
    {
        "value":"(GMT +4:30) Kabul",
        "data":"+04:50"
    },
    {
        "value":"(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
        "data":"+05:00"
    },
    {
        "value":"(GMT +5:30) Bombay, Calcutta, Madras, New Delhi",
        "data":"+05:50"
    },
    {
        "value":"(GMT +5:45) Kathmandu, Pokhara",
        "data":"+05:75"
    },
    {
        "value":"(GMT +6:00) Almaty, Dhaka, Colombo",
        "data":"+06:00"
    },
    {
        "value":"(GMT +6:30) Yangon, Mandalay",
        "data":"+06:50"
    },
    {
        "value":"(GMT +7:00) Bangkok, Hanoi, Jakarta",
        "data":"+07:00"
    },
    {
        "value":"(GMT +8:00) Beijing, Perth, Singapore, Hong Kong",
        "data":"+08:00"
    },
    {
        "value":"(GMT +8:45) Eucla",
        "data":"+08:75"
    },
    {
        "value":"(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
        "data":"+09:00"
    },
    {
        "value":"(GMT +9:30) Adelaide, Darwin",
        "data":"+09:50"
    },
    {
        "value":"(GMT +10:00) Eastern Australia, Guam, Vladivostok",
        "data":"+10:00"
    },
    {
        "value":"(GMT +10:30) Lord Howe Island",
        "data":"+10:50"
    },
    {
        "value":"(GMT +11:00) Magadan, Solomon Islands, New Caledonia",
        "data":"+11:00"
    },
    {
        "value":"(GMT +11:30) Norfolk Island",
        "data":"+11:50"
    },
    {
        "value":"(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka",
        "data":"+12:00"
    },
    {
        "value":"(GMT +12:45) Chatham Islands",
        "data":"+12:75"
    },
    {
        "value":"(GMT +13:00) Apia, Nukualofa",
        "data":"+13:00"
    },
    {
        "value":"(GMT +14:00) Line Islands, Tokelau",
        "data":"+14:00"
    }
]