import React from "react";

import { FacebookShareButton, LinkedinShareButton } from "react-share";
import { FacebookIcon, LinkedinIcon } from "react-share";

let text = "Check out this";
const SocialMediaButtons = (props) => (
  <div>
    <ul>
      <FacebookShareButton url={props.data.URL} quote={text}>
        <button className="btn">
          <FacebookIcon size={42} round={true} />{" "}
          <b style={{ color: "rgb(52, 67, 151)" }}>Facebook</b>{" "}
        </button>
      </FacebookShareButton>
      <br />

      {!(
        props.data.shareType.includes("audio") ||
        props.data.shareType.includes("video")
      ) ? (
        <LinkedinShareButton url={props.data.URL} quote={text}>
          <button className="btn">
            <LinkedinIcon size={42} round={true} />{" "}
            <b style={{ color: "rgb(52, 141, 214)" }}>Linkedin</b>{" "}
          </button>
        </LinkedinShareButton>
      ) : (
        <div></div>
      )}
    </ul>
  </div>
);

export default SocialMediaButtons;
