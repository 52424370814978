import React from "react";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import LinkedIn from "react-linkedin-login-oauth2";
import axios from "../../../config/axios";

class LinkedInLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      Uuid: "",
      // userId:"",
      // name:"",
      // email:"",
      access_Token: " ",
      code: "",
      errormsg: "",
    };
  }

  responseFailed = (response) => {
    console.log("Failed", response);
  };

  responseLinked = (response) => {
    axios
      .post(
        // `https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&code=${response.code}&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Flinkedin&client_id=86x3pl84s4l7n9&client_secret=KmWh3ZU5RDWNy8Za`
        `https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&code=${response.code}&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Flinkedin&client_id=86x3pl84s4l7n9&client_secret=KmWh3ZU5RDWNy8Za`
      )
      .then((res) => {
        this.setState({ access_Token: res.data.access_token });

        axios
          .get("https://api.linkedin.com/v2/me", {
            headers: {
              Authorization: "Bearer " + res.data.access_token,
              // Authorization: "Bearer " + res.data.access_token,
            },
          })
          .then((res) => {
            // console.log("________________1_______________", res.data);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    let linkedinContent;
    // console.log("ENV : ", process.env.NODE_ENV);
    let rDirectUrl =
      process.env.NODE_ENV === "production"
        ? "https://lifeguru.io/linkedin"
        : "http://localhost:3000/linkedin";

    //console.log("Redirect url ", rDirectUrl)
    if (this.state.isLoggedIn && !_.isEmpty(this.state.code)) {
      linkedinContent = (
        <div>
          return{" "}
          <Redirect
            to={{
              pathname: "/login",
              state: {
                user_t: this.state.Uuid,
              },
            }}
          />
        </div>
      );
    } else {
      linkedinContent = (
        <LinkedIn
          clientId="86x3pl84s4l7n9"
          redirectUri={rDirectUrl}
          scope="r_liteprofile,r_emailaddress"
          onFailure={this.responseFailed}
          onSuccess={this.responseLinked}
          className="my-linkedin-button-class"
          redirectPath="/linkedin"
        >
          {/* <a className="social-signin twitter">Log in with Linkedin</a> */}
        </LinkedIn>
      );
    }
    return <div>{linkedinContent}</div>;
  }
}
export default LinkedInLogin;
