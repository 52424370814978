import React, { Component } from "react";
import axios from "../../../config/axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Header, HeaderTop } from "../../../components/headers";
import { FooterLink } from "../../../components/footers";
import message from '../../../utils/mesaages';

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      email: this.state.email,
    };
    console.log("formdata", formData);
    axios
      .post("/forgotpassword", formData)
      .then((response) => {
        console.log('response',response)
        if (response.data === "recovery email sent") {
          message.success("Password reset link has been sent to your email")
          this.setState({ email: "" });
        } else if (response.data === "email not in db") {
          message.error("This Email Id does not exist. Please provide a Correct Email Id");
          this.setState({ email: "" });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <div>
        <HeaderTop />
        <Header />

        <div className="container padding-bottom-3x mb-2 mt-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div className="forgot">
                <h2>Forgot your password?</h2>
                <p>
                  Change your password in three easy steps. This will help you
                  to secure your password!
                </p>
                <ol className="list-unstyled">
                  <li>
                    <small>
                      <span className="text-primary text-small">1. </span>Enter
                      your email address below.
                    </small>
                  </li>
                  <li>
                    <small>
                      <span className="text-primary text-small">2. </span>Our
                      system will send you a temporary link
                    </small>
                  </li>
                  <li>
                    <small>
                      <span className="text-primary text-small">3. </span>Use
                      the link to reset your password
                    </small>
                  </li>
                </ol>
              </div>
              <form className="card mt-4" onSubmit={this.handleSubmit}>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="email-for-pass">
                      Enter your email address
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter your email Id"
                      value={this.state.email}
                      onChange={this.handleChange}
                      name="email"
                      required
                    />
                    <small className="form-text text-muted">
                      Enter the email address you used during the registration
                      on <b>CoachMaster.io</b> . Then we'll email a link to this
                      address.
                    </small>{" "}
                  </div>
                </div>
                <div className="card-footer">
                  <button className="btn btn-success ps-1" type="submit">
                    Get New Password
                  </button>{" "}
                  <Link to="/login">
                    <button className="btn btn-danger ps-1">
                      Back to Login
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
          <br />
        </div>
        <hr />
        <FooterLink />
      </div>
    );
  }
}
export default ForgetPassword;
