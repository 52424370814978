import { message } from "antd";
import axios from '../config/axios'
import '../assets/css/antd.css';

message.config({
  top: 100,
});

let getHelpText = (help_key)=>{
    return new Promise((resolve, reject)=>{
          axios.post('/admin/info-text',{
            help_key
          })
          .then(res =>{
               resolve(res.data)
          }).catch(e=>resolve("No help available at this moment"))
    })
}
export {
    getHelpText
}
export default message