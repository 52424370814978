import React from 'react'
import { dArrowblack, teamSvg, sortSvg } from '../../../assets/images'

import axios from '../../../config/axios'
import Loader from 'react-loader-spinner'
import message,{getHelpText} from '../../../utils/mesaages';
import { avatar } from "../../../assets/img";
import Pagination from '../../../components/pagination/pagination';

import ToggleButton from 'react-toggle-button'

class ReviewList extends React.Component {
    constructor() {
        super()
        this.token = localStorage.getItem("authToken");
  
        // if (localStorage.getItem("user")) {
        //   this.userdata = JSON.parse(localStorage.getItem("user"));
        // }
    
    
        // else if (localStorage.getItem("userdata")) {
        //   this.userdata = JSON.parse(localStorage.getItem("userdata"));
        // }
    
    
        // else {
        //   // #temprary if page will refereshed by mistake
        //   this.userdata = {
        //     account_type: "user",
        //     uid: "44abb6bc-8361-4a56-a88a-3b4c8b10eea2",
        //   };
        // }
  
        this.state = {
          feedbacks: [],
          reviewsPerPage: 10,
          currentPage: 1,
          loading: false,
          sort: 'date_desc',
        };
    }

    async componentDidMount() {
        // console.log("@@@@@@@@@@@@ userdata @@@@@@@@@@@@@", this.props.token)
        // console.log("@@@@@@@@@@@@ userdata @@@@@@@@@@@@@", this.props.coachUid)
        // let public_profile_text = await getHelpText('public_profile_text')
        // this.setState({public_profile_text})
        this.feedbacks()
      }

      feedbacks()
  {
    const {sort} = this.state
    // ${this.props.coachUid}
      axios.get(`/users/feedbackListCoach/${this.props.coachUid}?sort=${sort}`).then((res)=>{
        if(res.data.success)
        {
            this.setState({feedbacks:res.data.value})
        }
  
      }).catch((err)=>{
  
      })
  }

  paginate = (pageNumber) =>{
    this.setState({currentPage: pageNumber})
  }

  handleChange(checked,index) {
    let feedbacks = this.state.feedbacks

    feedbacks[index].publicCoach = checked==true? 'no' : 'yes'

    this.setState({feedbacks})

    axios.post('/consumer/feedbackPublic',{videoTokenId:feedbacks[index].videoTokenId,publicCoach:feedbacks[index].publicCoach})
  
  }

  handleSorting = (e) => {
    this.setState({ sort: e.target.value }, () => {
      this.feedbacks()
    });
  }

  render() {
    const {feedbacks} = this.state
    
    console.log("..................feedbacks................", feedbacks)
    const indexOfLastCoachDetails = this.state.currentPage * this.state.reviewsPerPage
    const indexOfFirstCoachDetails = indexOfLastCoachDetails - this.state.reviewsPerPage



    return (
      <div className="col-md-12">

        {this.state.loading ?
          <Loader style={{ marginLeft: '30%' }}
            type="Oval"
            color="#00BFFF"
            height={100}
            width={100}
          //  timeout={3000} //3 secs

          />
          :
          <div>
            <div className="searchHead">
              <div className="searchHeading">
                <h3>
                  <img width="22px" src={teamSvg} alt="LG-Img" />Reviews & Ratings
              </h3>
              </div>
              <div className="searchShortBy">
                <img width="15px" src={sortSvg} alt="LG_Img" />
                <select onChange={this.handleSorting}>
                  <option>---Sort by--- </option>
                  <option selected = {this.state.sort === 'date_desc' ? true : false} value='date_desc'>New-Old</option>
                  <option value='date_asc' selected = {this.state.sort === 'date_asc' ? true : false}>Old-New</option>
                  <option value='rating_desc' selected = {this.state.sort === 'rating_desc' ? true : false}>High-low</option>
                  <option value='rating_asc' selected = {this.state.sort === 'reating_asc' ? true : false}>Low-high</option>
                </select>
                <span>
                  <img src={dArrowblack} alt="LG_Img" />
                </span>
              </div>
            </div>

            <div className="userBookingList">
                {feedbacks.length > 0 ? (
                  feedbacks.slice(indexOfFirstCoachDetails, indexOfLastCoachDetails).map((each, i) => {
                    let star = each.rating
                    let arr = [1,2,3]
                    return (
                      <div className="bookingCocachDetails" style={{color: "black"}}>
                          <ul>
                              <li style={{width:"100%"}}>
                              <div className="fUser" style={{justifyContent: "left"}}>
                          <img src={(each.avatar_path!=null)?(each.avatar_path):(avatar)} alt="LG-Img" />
                          <h4>{each.name!=null?each.name:"Anonymous"}</h4>
                          </div>
                          <div className="fRating">
                        
                            {arr.map((a,index)=>{
                              return(
                                <i key={index} className={star>=a?('fa fa-star active'):('fa fa-star')} aria-hidden="true"></i>
                              )                              
                            })} 
                            </div>
                          <p style={{display:'inline'}}>{each.comment}</p>
                          {each.publicUser=='yes' && (
                          <div className="ml-2 mr-2 switcher d-flex" style={{float:'right'}}>
                            <span className="mr-2" style={{ fontSize: 13 }}>Make Public</span>
                            <ToggleButton
                                // value={this.state.isMakePublicEnabled}
                                inactiveLabel={''}
                                activeLabel={''}
                                value={each.publicCoach === 'yes'? true : false} 
                                onToggle={(e)=>this.handleChange(e,i)}
                                colors={{
                                  activeThumb: {
                                    base: 'rgb(250,250,250)',
                                  },
                                  inactiveThumb: {
                                    base: '#f2efef',
                                  },
                                  active: {
                                    base: '#82bd51',
                                    hover: '#82bd51',
                                  },
                                  inactive: {
                                    base: '#d7d7d7',
                                    hover: '#d7d7d7',
                                  }
                                }}
                              />
                            {/* <BootstrapSwitchButton onlabel='Admin User' offlabel='Regular User' checked={false}/> */}
                          {/* <Switch onChange={(e)=>this.handleChange(e,i)} checked={each.publicCoach === 'yes'? true : false} 
                          checkedIcon="" uncheckedIcon="" offHandleColor="#f2efef" onHandleColor="#fff" onColor="#82bd51" offColor="#d7d7d7" /> */}
                          </div>
                          )}
                              </li>
                          </ul>
                      </div>
                    )
                  })
                ) : (
                <div className="bookingCocachDetails" style={{color: "black"}}>
                  <ul style={{justifyContent:"center"}}>
                    <li style={{width:"100%"}}>
                    <h6>No Reviews Yet</h6>
                    </li>
                  </ul>
                </div>)}
                <Pagination postsPerPage={this.state.reviewsPerPage} totalPosts={feedbacks.length} paginate={this.paginate} currentPage={this.state.currentPage}/>


            </div>
          </div>

        }



      </div>

      


    )



  }
}



export default ReviewList