import React, { useState } from "react";
import {
  Button,
  Modal,
  Form,
  Col,
  Container,
  Row,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { photograph } from "../../../assets/images";

function UpdateImage(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="edit_personal_details">
        <Link
          to="profile-coach"
          className="popup-with-zoom-anim edit_item"
          data-toggle="tooltip"
          data-placement="top"
          title="Edit your Picture"
          onClick={handleShow}
        >
          <img width="35px" alt="LG-Img" src={photograph} />
        </Link>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Form onSubmit={props.handleSubmitProfileImage}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Profile Picture</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col xs={4} md={4}>
                  {props.userImage2 && (
                    <Image
                      src={props.userImage2}
                      style={{ width: "150px" }}
                      thumbnail
                    />
                  )}
                </Col>
                <Col>
                  <br />
                  <br />

                  <input
                    type="file"
                    label="Select Image file"
                    name="avatar_path"
                    onChange={props.onChangeHandler}
                  />
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="btn btn-success btn-block"
              type="submit"
              onClick={handleClose}
            >
              Upload
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default UpdateImage;
