import React from 'react'
import { Row, Col, Card, Button } from 'react-bootstrap'
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import './WebinarDetails.css'
import ReactHtmlParser from "react-html-parser";

import webinar3 from './../images/webinar3.png'
import {
    sw_clock,
    
    pc_facebook,
    pc_twitter,
    pc_linkedin,
    webiner1,
    Webiner_coach1,
    LGspinner,
    single_webiner,
} from "../../../assets/images";
import Practitioner from "../../../assets/images/practitioner.png";
import moment from "moment";
import axios from '../../../config/axios';
import { message } from 'antd';
import CustomImageLoader from "react-custom-image-loader.";
import LoadingOverlay from 'react-loading-overlay';

const timeFormat = "DD-MMMM-YYYY, hh:mm:ss a";

const settings = {
    dots: false,
    nav: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 820,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

class WebinarDetails extends React.Component {
    constructor(props) {
        super(props)

        this.token = localStorage.getItem("corporate_token");
        this.userdeta = JSON.parse(localStorage.getItem("corporate_userdata"))

        this.state = {
            loader: false,
            coach_uid: "",
            id: "",
            webinerDetails: null,
            coachDetails: null,

            // SIMILAR WEBINAR
            similarWebinarLoader: false,
            similarWebinars: [],
        };
    }

    componentDidMount = async () => {
        let { coach_uid, id } = this.props.match.params;
        if (coach_uid && id) {
            await this.setState({ coach_uid, id });
            this.getWebinerDetails();
        }
    };

    componentDidUpdate = async () => {
        let { coach_uid, id } = this.props.match.params;
        if (coach_uid && id) {
            if (id !== this.state.id) {
                await this.setState({ coach_uid, id });
                this.getWebinerDetails();
            }
        }
    }

    getWebinerDetails = () => {
        this.setState({ loader: true });
        axios
            .get(
                `/api/get-webiners-details?id=${this.state.id}&coach_uid=${this.state.coach_uid}`,
                {
                    headers: {
                        "X-Auth": this.token,
                    },
                }
            )
            .then((res) => {
                this.setState({ loader: false });
                // console.log(res.data.data)
                if (res.data.success) {
                    let { webinerDetails, coachDetails } = res.data.data;
                    if (webinerDetails) {
                        this.getSimilarWebinae(webinerDetails.webinar_category_id);

                        let deadline = moment(webinerDetails.date_time).format(timeFormat);
                        let times = this.getTime(deadline);
                        let isStart =
                            times &&
                                times.days <= 0 &&
                                times.hours <= 0 &&
                                times.minutes <= 0 &&
                                times.seconds <= 0
                                ? true
                                : false;
                        webinerDetails.deadline = deadline.split(",");
                        webinerDetails.isStart = isStart;
                    }
                    if (coachDetails) {
                        let location = coachDetails.address
                            ? JSON.parse(coachDetails.address)
                            : null;
                        let social_media = coachDetails.social_media
                            ? JSON.parse(coachDetails.social_media)
                            : null;
                        let coaching_category = coachDetails.coaching_category
                            ? JSON.parse(coachDetails.coaching_category)
                            : null;

                        coachDetails.location = location;
                        coachDetails.social_media = social_media;
                        coachDetails.coaching_category = coaching_category;
                    }
                    this.setState({ webinerDetails, coachDetails });
                    if (!webinerDetails) {
                        message.warning(res.data.msg);
                    }
                }
            })
            .catch((err) => {
                this.setState({ loader: false });
                console.log(err);
                message.error("Something Went Wrong");
            });
    };

    getSimilarWebinae = (topic) => {
        this.setState({ similarWebinarLoader: true });
        axios
            .get(`/webinar/get-similar-webiners?topic=${topic}&id=${this.state.id}`, {
                headers: {
                    "X-Auth": this.token,
                },
            })
            .then((res) => {
                this.setState({ similarWebinarLoader: false });
                if (res.data.success) {
                    this.setState({ similarWebinars: res.data.data.webiners });
                }
            })
            .catch((err) => {
                this.setState({ similarWebinarLoader: false });
                console.log(err);
                message.error("Something Went Wrong");
            });
    };

    getTime = (deadline) => {
        const time = Date.parse(deadline) - Date.now();
        const days = Math.floor(time / (1000 * 60 * 60 * 24));
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((time / 1000 / 60) % 60);
        const seconds = Math.floor((time / 1000) % 60);
        return {
            days,
            hours,
            minutes,
            seconds,
        };
    };

    addWebinarUser = (webinar_id) => {
        try {
            this.setState({ loader: true });
            const fromdata = { webinar_id, company_id: this.userdeta.company_id, hr_id: this.userdeta.uid }
            axios.post(`/api/corporate_schedule_webinar`, fromdata, { headers: { "X-Auth": this.token, }, }).then((res) => {
                this.setState({ loader: false });
                if (res.data.success) {
                    message.success(res.data.msg);
                    this.getWebinerDetails()
                }
            }).catch((error) => {
                this.setState({ loader: false });
                console.log(error.response);
                message.error(error.response.msg || error.response.data.msg);
            });
        } catch (error) {
            this.setState({ loader: false });
            console.log(error);
            message.error(error.response.msg);
        }
    }

    render() {
        let { webinerDetails, coachDetails, similarWebinars } = this.state;

        return (
            <div className="content_main">
                <Header />

                <LoadingOverlay active={this.state.loader} spinner={<CustomImageLoader image={LGspinner} animationType={"pulse"} />} className="webiner_loader" >
                    {webinerDetails && (
                        <div className="content_wrap p-0">
                            <div className="sw_banner">
                                <div className="row">
                                    <div className="col-md-12 col-lg-6 sw_image">
                                        <img src={webinerDetails.image || single_webiner} className="img-fluid w-100 h-100" alt={webinerDetails.webinar_topic} />
                                    </div>
                                    <div className="col-md-12 col-lg-6 sw_details">
                                        <h1 className="sw_title">{webinerDetails.webinar_topic}</h1>
                                        <p>{webinerDetails.script}</p>

                                        {!webinerDetails.isStart && (
                                            <>
                                                <h5>Upcoming Webinar :</h5>
                                                <ul className="list-unstyled inc_list mb-4">
                                                    <li className="inc_list_text">
                                                        <strong>Time</strong>
                                                        <div className="text-uppercase"><small>({webinerDetails.deadline[0]})</small></div>
                                                    </li>
                                                    <li className="inc_list_text">
                                                        <strong>Date</strong>
                                                        <div className="text-uppercase"><small>{webinerDetails.deadline[1]} {new Date().toLocaleString(Intl.LocalesArgument, { day: '2-digit', timeZoneName: 'short' }).slice(4)}</small></div>
                                                    </li>
                                                </ul>
                                            </>
                                        )}
                                        <div className="swb_btns">
                                            {webinerDetails.isSchedule ? (
                                                <Button variant="secondary" className="mr-3" disabled>ADDED</Button>
                                            ) : (
                                                <Button variant='default' className="mr-3 defaultBtn" onClick={() => { this.addWebinarUser(this.state.id); }} >ADD</Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {coachDetails && (
                                <div className="practitioner">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-10 col-md-12">
                                            <h3 className="text-uppercase">
                                                <strong>Practitioner</strong>
                                            </h3>
                                            <div className="pc_card">
                                                <div className="row">
                                                    <div className="col-md-4 pc_image">
                                                        <img src={coachDetails.avatar_path || Practitioner} className="img-fluid rounded" alt={coachDetails.name} />
                                                    </div>
                                                    <div className="col-md-8 pc_text">
                                                        <h2 className="pc_name">{coachDetails.name}</h2>
                                                        <div className="row mb-4">
                                                            {coachDetails.coaching_category?.length > 0 && (
                                                                <dl className="col-md-8 mb-0">
                                                                    <dd className="mb-0">Areas of expertise</dd>
                                                                    <dt>
                                                                        {coachDetails.coaching_category?.map(
                                                                            (category, key) => {
                                                                                return (
                                                                                    <span className="badge badge_green" key={key} >
                                                                                        {category.label}
                                                                                    </span>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </dt>
                                                                </dl>
                                                            )}

                                                            {coachDetails.location?.country && (
                                                                <dl className="col-md-4 mb-0">
                                                                    <dd className="mb-0">Location</dd>
                                                                    <dt>{coachDetails.location?.country}</dt>
                                                                </dl>
                                                            )}
                                                        </div>
                                                        <p>
                                                            {ReactHtmlParser(coachDetails.long_bio || coachDetails.short_bio)}
                                                        </p>
                                                        <ul className="list-inline pc_share">
                                                            {coachDetails.social_media?.map((media, key) => {
                                                                return (
                                                                    <>
                                                                        {media.URL && (
                                                                            <li className="list-inline-item" key={key}>
                                                                                <a href={media.URL} target="_blank">
                                                                                    <img src={media.URL?.includes("facebook") ? pc_facebook : media.URL?.includes("linkedin") ? pc_linkedin : pc_twitter} alt={media.url} />
                                                                                </a>
                                                                            </li>
                                                                        )}
                                                                    </>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="similar_wbnr">
                                {similarWebinars.length > 0 && (
                                    <h4 className='page-title'>Similar Webinars</h4>
                                )}
                                <LoadingOverlay
                                    active={this.state.similarWebinarLoader}
                                    //spinner
                                    spinner={
                                        <CustomImageLoader
                                            image={LGspinner}
                                            animationType={"pulse"}
                                        />
                                    }
                                >
                                    <Slider {...settings} className="" centerMode={false}>
                                        {similarWebinars.map((webinar, key) => {
                                            return (
                                                <div className="carousel-item " key={key}>
                                                    <div className="webiner_card">
                                                        <img src={webinar.image || webiner1} className="img-fluid" alt={webinar.webinar_topic} />
                                                        <span className='cw_new'>NEW</span>
                                                        <div className="webiner_card_content text-light">
                                                            <img
                                                                width={60}
                                                                height={60}
                                                                src={webinar.coach_image || Webiner_coach1}
                                                                className="img-fluid rounded-circle" alt={webinar.coach_name}
                                                            />
                                                            <h4 className="webiner_coach_name mt-2">{webinar.coach_name}</h4>
                                                            <p className="webiner_coach_details">{webinar.coach_bio}</p>
                                                            <Link to={`/webinar-details/${webinar.coach_uid}/${webinar.id}`}>
                                                                <h3 className='webiner_name'>{webinar.webinar_topic}</h3>
                                                            </Link>
                                                            <p className="webinar_description">{webinar.script}</p>
                                                            <div className="cw_type">Webinar</div>
                                                            <div className="text-uppercase"><small style={{ fontSize: '0.7rem' }}>{moment(webinar.date_time).format("DD MMM, YYYY - hh:mm A")}</small></div>
                                                            {/* <div className='wb_links'>
                                                                <Button variant='default' className="defaultBtn" >ADD</Button>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </Slider>
                                </LoadingOverlay>
                            </div>
                        </div>
                    )}
                </LoadingOverlay>

                <Sidebar />
            </div>
        )
    }
}

export default WebinarDetails