import React, { Component } from 'react'
import { Button, Col, Dropdown, Form, Modal, Row, Spinner } from 'react-bootstrap'
import axios,{AdminApi} from '../config/axios'
import message from '../utils/mesaages';
import moment from "moment";
import  {LGspinner}  from "../assets/images";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";
import { curencyFilter, currencySign } from "../utils/currencyConvert";

export default class PaymentDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
          showmodal: false,
          
        }
      
      }
      componentDidMount() {
      
      }

   
    render() {
      console.log('transaction',this.props.detials,this.props.detials.long_description)
      let transaction = this.props.detials
      let longDescription = this.props.detials.long_description!= null ? JSON.parse(`${this.props.detials.long_description}`) :""

      let rateOfExchange = longDescription != "" && longDescription.exchange_rate !== null ? longDescription.exchange_rate : 0.00;
      let coachUsdAmount = Number(transaction.user_commision_amount);
      let inWalletAmount = rateOfExchange !== 0.00 ? (coachUsdAmount * rateOfExchange) : coachUsdAmount;
      // inWalletAmount = `${currencySign(longDescription.currency.toUpperCase())}${parseFloat(inWalletAmount).toFixed(2)}`;
      inWalletAmount = longDescription != "" ? 
                        `${currencySign(longDescription.currency.toUpperCase())}${parseFloat(inWalletAmount).toFixed(2)}` 
                          :
                        `${currencySign(transaction.currency.toUpperCase())}${parseFloat(inWalletAmount).toFixed(2)}`;
      let exchangeRateCurrencySign = longDescription != "" ? currencySign(longDescription.currency.toUpperCase()) : currencySign(transaction.currency.toUpperCase());
        return (
            <>
            
              {/* <button onClick={this.modalShow} className="defaultBtn">
                 View
              </button>
           */}
        
        <Modal
            //    size="xl"
              show={this.props.showmodal}
              onHide={this.props.closemodal}
              // backdrop="static"
              // keyboard={false}
              style={{ color: 'black' }}
            >
              <LoadingOverlay
                    active={this.state.loaderToken}
                    // spinner={<BounceLoader />}
                    spinner={
                      <div>
                        <CustomImageLoader image={LGspinner} animationType={"pulse"} />
                        <br />
                      </div>
                    }
                    text=""
                  >
              <Modal.Header closeButton>
                <Modal.Title>
                  <div style={{ color: '#9b59b6' }}>
                    <h3 >Payment Details</h3>
                  </div>

                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <div className="horizontalTable subscription_row">
                <div style={{display: "flex", justifyContent: "space-between", marginBottom: "0"}}>
                <p style={{fontSize:"10px" , fontWeight:"bold" , marginBottom: "0", color: "red"}}>{transaction.gatewayName === "bluesnap" ? `**BlueSnap Data**` : ""}</p>
                <p style={{fontSize:"10px" , fontWeight:"bold", marginBottom: "0" , textAlign: "right"}}>Exchange Rate: {exchangeRateCurrencySign}{longDescription.exchange_rate != null ? longDescription.exchange_rate : "0.00"}</p>
                </div>
                        <ul className="paymentDetailsView">
                            <li>
                                <b>Session Fee: </b>
                                {/* <span>{currencySign(transaction.paid_currency)}{transaction.paid_value}</span> */}
                                <span>{transaction.paid_value!=null?(
                                          `${currencySign(transaction.paid_currency)}${transaction.paid_value}`
                                        ):(
                                          `${currencySign(transaction.currency)}${transaction.total_amount}`
                                        )}</span>
                            </li>
                            <li>
                                <b>LifeGuru Commission: </b>
                                <span>{currencySign(transaction.paid_currency)}{parseFloat(transaction.admin_commission_amount).toFixed(2)}</span>
                            </li>

                            {/* <li>
                                <b>USD Amount: </b> 
                                <span>{currencySign(transaction.paid_currency)}{parseFloat(transaction.amount).toFixed(2)}</span>
                            </li> */}
                            <li>
                                <b>Total Received in Wallet: </b>
                                <span>{inWalletAmount}</span>
                            </li>
                            {/* <li>
                                <b>Transaction Fee: </b> 
                                <span>{curencyFilter(
                                  `${longDescription.currency.toUpperCase()}##${longDescription.fee/100}`, "USD"
                                )}</span>
                            </li> */}
                            
                           

                           


                        </ul>
                        
                    
                    </div>


              </Modal.Body>
              <br />

              <Modal.Footer>
                    
                       
                            <Button variant="secondary" onClick={this.props.closemodal}>
                                Exit
                            </Button>
                           
                      
                </Modal.Footer>
              </LoadingOverlay>
            </Modal>

 
                
            </>
        )
    }
}
