import React from "react";
import { videoIcon, m1, m2 } from "../../assets/images";
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};



class MasterCoachWebinars extends React.Component {
  constructor(props) {
    super(props);
   
  }
  render() {
    console.log("webinar data from home page",this.props.webinardata)
    return (
      <div>
        <section className="previousWrap">
          <div className="container">
            <div className="heading text-center">
              <h3>Previous Master Coach Webinars</h3>
              <span className="R_divider"></span>
            </div>
            <div className="previousList mt-5">
              {this.props.webinardata && this.props.webinardata.length && (
                <Slider {...settings} className="owl-theme">
                  {this.props.webinardata.map((each, i) => {
                    let webinar = JSON.parse(each.content_json)
                    return (
                      <div className="item">
                  <a className="previousItem" href="javascrip:void(0);">
                    <div className="pImgPart">
                    
                    <video style={{border:"0"}} height="200" width="300" controls poster={webinar.thumbnail_url} src={webinar.video_url}>
                    </video>
                     
                    </div>
                    <h5>-{webinar.author}</h5>
                 <p>{webinar.bio}</p>
                  </a>
                </div>
                    )
                  })}
                </Slider>
              )}




            {/* <OwlCarousel className="owl-theme" loop margin={10} nav items={4}> */}
                {/* {this.props.webinardata ? (
                  
                  this.props.webinardata.map((each, i) => {
                    let webinar = JSON.parse(each.content_json) 
                    return (
                      <div className="item">
                  <a className="previousItem" href="javascrip:void(0);">
                    <div className="pImgPart">
                    <iframe height="250" src="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      <img src={m1} alt="LG-Img" className="img-fluid" />
                      <span>Conscious & subconscious mind,<br/> Personal Development</span>

                      <i>
                        <img alt="LG-Img" width="40px" src={videoIcon} />
                      </i>
                    </div>
                    <h5>-hello</h5>
                 <p>hello</p>
                  </a>
                </div>
                    )
                  })
                )
                 : "hello"} */}
                {/* <div className="item">
                  <a className="previousItem" href="javascrip:void(0);">
                    <div className="pImgPart">
                    <iframe height="250" src="https://www.youtube.com/embed/V73RiKm1jeo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      <img src={m2} alt="LG-Img" className="img-fluid" />
                      <span>Create a podcast for your business, <br/> Marketing Coaching</span>

                      <i>
                        <img alt="LG-Img" width="40px" src={videoIcon} />
                      </i>
                    </div>
                    <h5>-Brandon Devere</h5>
                    <p>How to create a podcast for your business in less than 40 minutes. Part 1: Anchor Introduction</p>

                  </a>
                </div>
                <div className="item">
                  <a className="previousItem" href="javascrip:void(0);">
                    <div className="pImgPart">
                    <iframe height="250" src="https://www.youtube.com/embed/V73RiKm1jeo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      <img src={m1} alt="LG-Img" className="img-fluid" />
                      <span>7 Strategies on Removing Blindspots from Your Business, <br/> Business  Coaching</span>

                      <i>
                        <img alt="LG-Img" width="40px" src={videoIcon} />
                      </i>
                    </div>
                    <h5>-Ivan Alvarez </h5>
                    <p>How to remove blindspots from your business. Part 1: history</p>

                  </a>
                </div>
                <div className="item">
                  <a className="previousItem" href="javascrip:void(0);">
                    <div className="pImgPart">
                    <iframe height="250" src="https://www.youtube.com/embed/V73RiKm1jeo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      <img src={m2} alt="LG-Img" className="img-fluid" />
                      <span>Instagram Mastery - Maximize Instagram to grow your business, <br/> Marketing  Coaching</span>

                      <i>
                        <img alt="LG-Img" width="40px" src={videoIcon} />
                      </i>
                    </div>
                    <h5>-Ali Mehdaoui</h5>
                    <p>Why Use Instagram? Part 1: Instagram is the fastest growing platform and here is why!</p>

                  </a>
                </div> */}
              {/* </OwlCarousel> */}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default MasterCoachWebinars;
