import React, { Component } from "react";
import { Pie } from "react-chartjs-2";

class Donut extends Component {
  constructor(props) {
    super(props);
   
  }
  render() {
    // console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC", this.props)
    const data = {
      labels: this.props.labels_data,
      datasets: [
        {
          data: this.props.data_data,
          backgroundColor: this.props.color_data,
          hoverBackgroundColor: this.props.color_data,
          
          borderWidth: 0,
        },
      ],
    };
    console.log(data)

    if (this.props.chartValue.length > 0) {
      return <Pie data={data} />;
    } 
  }
}
export default Donut;
