import React, { Component } from "react";
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";
import { Form, Row, Col, InputGroup, Button, Tab, Tabs } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import "./sessions.css";

import { LGspinner, Webiner_coach1, user } from "../../../assets/images";
import axios from "../../../config/axios";
import { message } from "antd";
import ReactPaginate from "react-paginate";

import Calendar from "tui-calendar";
import "tui-calendar/dist/tui-calendar.css";

import moment from "moment";
import RecordingModal from "../schedule/RecordingModal";

let dcalender;
export default class Sessions extends Component {
  constructor(props) {
    super(props);

    this.token = localStorage.getItem("authToken") || localStorage.getItem("corporate_user_token");

    this.state = {
      limit: 8,
      page: 1,
      totalPages: "",

      session_limit: 4,
      session_page: 1,
      session_totalPages: "",

      coaches: [],
      loading: false,
      pagination: [],
      upcoming: [],
      totalCompleted: "",
      completed: [],
      schedules: [],
      offset: moment().local().utcOffset(),
      curr_date: moment().format(),
      currentDate: "",
      show: false,
      modal: ''
    };
  }

  getCareerCounsellors = () => {
    this.setState({ loading: true });

    axios
      .get(
        `/webinar/career-counsellors?limit=${this.state.limit}&page=${this.state.page}`,
        {
          headers: {
            "x-auth": this.token,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          this.setState({
            coaches: res.data.coaches,
            totalPages: res.data.pagination.total_pages,
            page: res.data.pagination.current_page,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          message.error("server error");
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("server error");
        console.log("err", err);
      });
  };

  getSessionsList = () => {
    this.setState({ loading: true });
    axios
      .get(`/webinar/my-sessions-list`, {
        headers: {
          "x-auth": this.token,
        },
      })
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success) {
          this.setState({
            upcoming: res.data.upcoming,
          });
        } else {
          message.error("Sessions fetch failed");
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Sessions fetch failed");
      });
  };

  getCompletedSessionsList = () => {
    this.setState({ loading: true });

    axios
      .get(
        `/webinar/my-completed-sessions-list`,
        {
          headers: {
            "x-auth": this.token,
          },
        }
      ).then((res) => {
        this.setState({ loading: false });
        if (res.data.success) {
          this.setState({
            totalCompleted: res.data.total_rows
          });
        } else {
          message.error("Sessions fetch failed");
        }
      })

    axios
      .get(
        `/webinar/my-completed-sessions-list?limit=${this.state.session_limit}&page=${this.state.session_page}`,
        {
          headers: {
            "x-auth": this.token,
          },
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.success) {
          this.setState({
            completed: res.data.completed,
            session_totalPages: res.data.total_pages,
            sessions_page: res.data.current_page,
          });
        } else {
          message.error("Sessions fetch failed");
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        message.error("Sessions fetch failed");
      });
  };

  onPageChange = (page) => {
    let page1 = page.selected + 1;
    this.setState({ page: page1 }, () => this.getCareerCounsellors());
  };

  onSessionsPageChange = (page) => {
    let page1 = page.selected + 1;
    this.setState({ session_page: page1 }, () =>
      this.getCompletedSessionsList()
    );
  };

  joinWebinar = (session_id) => {
    // console.log("session_id", session_id);
    this.setState({ loading: true });
    axios
      .post(
        `/webinar/join-session`,
        {
          session_id: session_id,
        },
        {
          headers: {
            "x-auth": this.token,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          console.log("bbb response", res.data);
          this.setState({ loading: false });
          let url = res.data.joiningLink;
          window.open(url, "_blank");
        } else {
          this.setState({ loading: false });
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log("err", err);
      });
  };

  getScheduledSessions = (date) => {
    this.setState({ loader: true });
    axios
      .get(`/webinar/user-session-schedule?date=${date}`, {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        this.setState({ loader: false });
        if (res.data.success) {
          let calendarId = "1";
          let { sessions } = res.data;
          let schedules = sessions?.map((value) => {
            let date = moment().utc();
            if (new Date(value.date_time) > date) {
              calendarId = "2";
            } else {
              calendarId = "1";
            }
            return {
              id: value.id,
              calendarId: calendarId,
              category: "time",
              title: value.session_name,
              start: moment(value.date_time)
                .add(this.state.offset, "m")
                .format(),
              isReadOnly: true,
            };
          });

          this.setState({ schedules });
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        message.error("Something went wrong");
      });
  };

  displayCalendar = () => {
    let div = document.getElementById("calendar");

    dcalender = new Calendar(div, {
      defaultView: "month",
      taskView: true, // Can be also ['milestone', 'task']
      scheduleView: true, // Can be also ['allday', 'time']
      useCreationPopup: false,
      useDetailPopup: true,
      isReadOnly: true,
      calendars: [
        {
          id: "1",
          name: "Archived",
          bgColor: "#9e5fff",
          dragBgColor: "#9e5fff",
          borderColor: "#9e5fff",
        },
        {
          id: "2",
          name: "Upcoming",
          bgColor: "#00e1ba",
          borderColor: "#00e1ba",
          dragBgColor: "#00e1ba",
        },
      ],
    });

    this.setState({
      currentDate: moment(dcalender.getDate()).format("MMMM-DD"),
    });
  };

  moveToNextOrPrevRange = async (val) => {
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    if (val === -1) {
      dcalender.prev();
      let date = dcalender.getDate().toDate();
      const qDate = moment(date).format("YYYY-MM-DD");

      this.setState({
        currentDate: `${months[date.getMonth()]}-${date.getFullYear()}`,
        date: qDate,
      });
      this.getScheduledSessions(qDate);
    } else if (val === 1) {
      dcalender.next();
      let date = dcalender.getDate().toDate();
      const qDate = moment(date).format("YYYY-MM-DD");
      this.setState({
        currentDate: `${months[date.getMonth()]}-${date.getFullYear()}`,
        date: qDate,
      });
      this.getScheduledSessions(qDate);
    }
  };

  componentDidMount() {
    this.getCareerCounsellors();
    this.getSessionsList();
    this.getCompletedSessionsList();
    this.getScheduledSessions(moment().format("YYYY-MM-DD"));
    this.displayCalendar();
  }

  componentDidUpdate = () => {
    dcalender.clear();
    dcalender.createSchedules(this.state.schedules);
  };
  // componentDidUpdate = () => {
  //   if (this.state.schedules.length > 0) {
  //     dcalender.clear();
  //     dcalender.createSchedules(this.state.schedules);
  //   }
  // };

  handleShow = (meetingID) => {
    this.setState({ show: true })
    axios.post('/webinar/get-recording', { meetingID: meetingID }, { headers: { "x-auth": this.token, }, }).then((res) => {
      if (res.data.success) {
        this.setState({ modal: <RecordingModal show={true} handleClose={this.handleClose} Recordings={res.data.data ? res.data.data : []} /> })
      }
    }).catch((err) => {
      console.log(err)
      message.error("Something went wrong");
    })
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  // handleSelect = (key) => {
  //   if (key === "Calendar") {
  //     document.getElementById("calendar").innerHTML = ""
  //     this.getScheduledSessions(moment().format("YYYY-MM-DD"));
  //     this.displayCalendar()
  //   } else if (this.state.schedules.length > 0) {
  //     document.getElementById("calendar").innerHTML = ""
  //     dcalender.clear();
  //     dcalender.createSchedules(this.state.schedules);
  //   }
  // }

  cancelSession = async (session_id, session_uid) => {
    this.setState({ loader: true })
    axios.get(`/webinar/cancle_session/?session_id=${session_id}&session_uid=${session_uid}`, { headers: { "x-auth": this.token } }).then((response) => {
      if (response.data.success) {
        message.success(response.data.msg)
        this.getSessionsList();
        this.getCompletedSessionsList();
        this.getScheduledSessions(moment().format("YYYY-MM-DD"));
      }
    }).catch((error) => {
      this.setState({ loader: false })
      message.error(error.response.data.msg)
    })
  }

  render() {
    let {
      coaches,
      totalPages,
      page,
      upcoming,
      completed,
      offset,
      curr_date,
      session_page,
      totalCompleted,
      session_totalPages,
    } = this.state;

    return (
      <LoadingOverlay active={this.state.loading} spinner={<CustomImageLoader image={LGspinner} animationType={"none"} />}>
        <div className="content_wrap webiner_tab">
          <div className="session_wrap upcoming">
            <div className="d-flex justify-content-between align-items-center text-center">
              <h4 className="page-title mt-3">Scheduled Coaching Sessions</h4>
              {upcoming?.length > 0 && (
                <div className="session_type_title mb-3">Upcoming ({upcoming?.length})</div>
              )}
            </div>
            {
              (upcoming.length === 0) && (<p style={{ "fontSize": "20px", "fontWeight": "bold", "textAlign": "center" }}> Currently don't have any upcoming session </p>)
            }

            {upcoming?.map((item, i) => (
              <div to="/webinars/webinars-details">
                <div className="session_item d-flex flex-wrap align-items-center" style={{ "overflow": "scroll"}}>
                  <div className="session_user_image">
                    <img width={55} height={55} src={item.avatar_path ? item.avatar_path : user} alt="" className="img-fluid rounded-circle" />
                  </div>
                  <div className="session_user">
                    <div className="session_label">Counsellor</div>
                    <div className="session_text">{item.name}</div>
                  </div>
                  <div className="session_name">
                    <div className="session_label">Session name</div>
                    <div className="session_text">{item.session_name}</div>
                  </div>
                  <div className="session_date">
                    <div className="session_label">Date</div>
                    <div className="session_text"> {moment(item.date_time).add(offset, "m").format("DD-MM-YYYY")} </div>
                  </div>
                  <div className="session_time">
                    <div className="session_label">Time</div>
                    <div className="session_text"> {moment(item.date_time).add(offset, "m").format("hh:mm a")} </div>
                  </div>
                  <div className="session_type d-flex justify-content-between">
                    {item.date_time > curr_date ? (
                      <div className="webinar_inner">
                        <span>
                          <button type="button" className="btn btn-primary" id={item.id} disabled>
                            Upcoming
                          </button>
                        </span>
                      </div>
                    ) : (
                      <div className="webinar_inner">
                        <span>
                          <button type="button" className="join_button" id={item.id} onClick={() => this.joinWebinar(item.id)}>
                            Start
                          </button>
                        </span>
                      </div>
                    )}
                    &nbsp;
                    &nbsp;
                    <div className="webinar_inner">
                      <span>
                        <button type="button" className="join_button btn-sm btn-dark" id={item.uid} onClick={() => this.cancelSession(item.id, item.uid)}>
                          Cancel
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <h4 className="page-title mt-5">Coaching Sessions Schedule</h4>
          <div className="booking__calendar">
            <div className="ml-3 pt-3">
              <span id="menu-navi">
                <button type="button" className="btn btn-default btn-sm move-day" data-action="move-prev" onClick={() => { this.moveToNextOrPrevRange(-1); }}>
                  <i className="calendar-icon ic-arrow-line-left" data-action="move-prev"></i>
                </button>
                <button type="button" className="btn btn-default btn-sm move-day" data-action="move-next" onClick={() => { this.moveToNextOrPrevRange(1); }}>
                  <i className="calendar-icon ic-arrow-line-right" data-action="move-next"></i>
                </button>
              </span>
              <span id="renderRange" className="render-range">
                {this.state.currentDate}
              </span>
            </div>

            <div id="calendar" className="mb-3"></div>
          </div>

          <div className="d-flex justify-content-between align-items-center text-center">
            <h4 className="page-title mt-3">Coaching Completed Sessions</h4>
            {completed.length > 0 && (
              <div className="session_type_title mb-3">Completed ({totalCompleted}) </div>
            )}
          </div>

          {
            (completed.length === 0) && (<p style={{ "fontSize": "20px", "fontWeight": "bold", "textAlign": "center" }}> Currently don't have any complete session </p>)
          }

          <div className="session_wrap completed">

            {completed?.map((item, i) => (
              <div to="/webinars/webinars-details">
                <div className="session_item d-flex flex-wrap align-items-center">
                  <div className="session_user_image">
                    <img width={55} alt="" height={55} src={item.avatar_path ? item.avatar_path : user} className="img-fluid rounded-circle" />
                  </div>
                  <div className="session_user">
                    <div className="session_label">Counsellor</div>
                    <div className="session_text">{item.name}</div>
                  </div>
                  <div className="session_name">
                    <div className="session_label">Session name</div>
                    <div className="session_text">{item.session_name}</div>
                  </div>
                  <div className="session_date">
                    <div className="session_label">Date</div>
                    <div className="session_text">
                      {moment(item.date_time)
                        .add(offset, "m")
                        .format("DD-MM-YYYY")}
                    </div>
                  </div>
                  <div className="session_time">
                    <div className="session_label">Time</div>
                    <div className="session_text">
                      {moment(item.date_time)
                        .add(offset, "m")
                        .format("hh:mm a")}
                    </div>
                  </div>
                  <div className="session_type">
                    <div className="webinar_inner p-0">
                      <span>
                        <button type="button" className="join_button" id={item.id} style={{ "whiteSpace": "nowrap" }} onClick={() => this.handleShow(item.meetingID)}>
                          Get Recordings
                        </button>
                      </span>
                    </div>
                  </div>
                  {/* <div className='webinar_inner'>
                      <span>
                        <button 
                            type='button' 
                            className='btn btn-secondary'
                            id={item.id}
                            // onClick={this.showMessage}
                            disabled
                        >
                          Completed
                        </button>
                      </span>
                    </div> */}
                </div>
              </div>
            ))}
          </div>

          {completed?.length > 0 && (
            <div className="result_pagination mt-4">
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={this.onSessionsPageChange}
                pageRangeDisplayed={6}
                pageCount={session_totalPages}
                previousLabel="<"
                activeClassName="active"
                disabledClassName="disable"
                renderOnZeroPageCount={null}
              />
            </div>
          )}

          {/* <Tabs defaultActiveKey="Session_list" id="uncontrolled-tab-example" className="mb-3" onSelect={this.handleSelect}>
            <Tab eventKey="Session_list" title="Session List">
            </Tab>
            <Tab eventKey="Calendar" title="Calendar">
            </Tab>
          </Tabs> */}

          {coaches.length > 0 && (
            <h4 className="page-title mb-3">Career Coaches</h4>
          )}
          <div className="row coaches_row">
            {coaches?.map((item, i) => (
              <div className="col-md-3 my-2" key={i}>
                <div className="coaches_card card text-center border-0">
                  <div className="card-body">
                    <img
                      width={55}
                      height={55}
                      src={item.avatar_path ? item.avatar_path : user}
                      className="img-fluid rounded-circle"
                    />
                    <h4 title={item.name}>{item.name}</h4>
                    <div className="coaching_cat">Career Counsellor</div>
                    <Link
                      to={`/webinars/sessions/${item.uid}`}
                      className="defaultBtn btn btn-default"
                    >
                      Book
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {coaches.length > 0 && (
            <div className="result_pagination mt-4">
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={this.onPageChange}
                pageRangeDisplayed={6}
                pageCount={totalPages}
                previousLabel="<"
                activeClassName="active"
                disabledClassName="disable"
                renderOnZeroPageCount={null}
              />
            </div>
          )}
        </div>
        {
          (this.state.show === true) ? this.state.modal : ''
        }
      </LoadingOverlay>
    );
  }
}
