import React from "react";
import { Link } from "react-router-dom";
import { u1, u2, videoIcon } from "../../assets/images";

class UpcomingMasterCoachWebinars extends React.Component {
  constructor(props) {
    super(props);
   
  }
  render() {
    return (
      <div>
        <section className="upcomingWrap">
          <div className="container">
            <div className="webinnerContent text-center">
              <div
                className="heading text-center"
              >
                <h3>
                  UPCOMING Master Coach <span>webinar</span>
                </h3>
                <p className="">
                  Learn from world-class master coaches who share their
                  expertise in our "Master Coach” live webinars
                </p>
                <span className="R_divider"></span>
              </div>

              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="upcomingList">
                    <img src={u1} alt="LG-IMG" className="img-fluid" />
                    <div className="upcomingText d-flex">
                      <img alt="LG-IMG" src={videoIcon} width="40px" />
                      <Link to="/comming-soon">
                        <h4>Upcoming Master Coach Webinar 1</h4>
                        <p>August 19, 2021 @ 8:00am</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="upcomingList">
                    <img alt="LG-IMG" src={u2} className="img-fluid" />
                    <div className="upcomingText d-flex">
                      <img alt="LG-IMG" src={videoIcon} width="40px" />
                      <Link to="/comming-soon">
                        <h4>Upcoming Master Coach Webinar 1</h4>
                        <p>August 19, 2021 @ 8:00am</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="upcomingList">
                    <img alt="LG-IMG" src={u1} className="img-fluid" />
                    <div className="upcomingText d-flex">
                      <img alt="LG-IMG" src={videoIcon} width="40px" />
                      <Link to="/comming-soon">
                        <h4>Upcoming Master Coach Webinar 1</h4>
                        <p>August 19, 2021 @ 8:00am</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="upcomingList">
                    <img alt="LG-IMG" src={u2} className="img-fluid" />
                    <div className="upcomingText d-flex">
                      <img alt="LG-IMG" src={videoIcon} width="40px" />
                      <Link to="/comming-soon">
                        <h4>Upcoming Master Coach Webinar 1</h4>
                        <p>August 19, 2021 @ 8:00am</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="upcomingList">
                    <img alt="LG-IMG" src={u1} className="img-fluid" />
                    <div className="upcomingText d-flex">
                      <img alt="LG-IMG" src={videoIcon} width="40px" />
                      <Link to="/comming-soon">
                        <h4>Upcoming Master Coach Webinar 1</h4>
                        <p>August 19, 2021 @ 8:00am</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="upcomingList">
                    <img alt="LG-IMG" src={u2} className="img-fluid" />
                    <div className="upcomingText d-flex">
                      <img alt="LG-IMG" src={videoIcon} width="40px" />
                      <Link to="/comming-soon">
                        <h4>Upcoming Master Coach Webinar 1</h4>
                        <p>August 19, 2021 @ 8:00am</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <Link to="/registration" className="defaultBtn">
                <span>
                  <svg
                    id="Capa_1"
                    fill="#fff"
                    enableBackground="new 0 0 512 512"
                    height="25"
                    viewBox="0 0 512 512"
                    width="30"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path d="m143.5 398.5c35.099 0 67.2-12.599 91.8-33.6-13.5-15.901-23.699-34.2-30.3-54-15 16.8-36.9 27.6-61.5 27.6-45.601 0-83.5-36.899-83.5-82.5s37.899-82.5 83.5-82.5c45.888 0 82.5 37.502 82.5 82.5 0 78.082 63.246 142.5 142.5 142.5 78.6 0 143.5-63.9 143.5-142.5s-64.9-142.5-143.5-142.5c-35.099 0-67.2 12.599-91.8 33.6 13.5 15.901 23.699 34.2 30.3 54 15-16.8 36.899-27.599 61.5-27.599 45.601 0 83.5 36.899 83.5 82.5s-37.899 82.5-83.5 82.5c-45.888 0-82.5-37.502-82.5-82.5 0-78.082-63.246-142.5-142.5-142.5-78.6-.001-143.5 63.899-143.5 142.499s64.9 142.5 143.5 142.5z" />
                    </g>
                  </svg>
                  <svg
                    id="Capa_1"
                    fill="#fff"
                    enableBackground="new 0 0 512 512"
                    height="25"
                    viewBox="0 0 512 512"
                    width="30"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path d="m143.5 398.5c35.099 0 67.2-12.599 91.8-33.6-13.5-15.901-23.699-34.2-30.3-54-15 16.8-36.9 27.6-61.5 27.6-45.601 0-83.5-36.899-83.5-82.5s37.899-82.5 83.5-82.5c45.888 0 82.5 37.502 82.5 82.5 0 78.082 63.246 142.5 142.5 142.5 78.6 0 143.5-63.9 143.5-142.5s-64.9-142.5-143.5-142.5c-35.099 0-67.2 12.599-91.8 33.6 13.5 15.901 23.699 34.2 30.3 54 15-16.8 36.899-27.599 61.5-27.599 45.601 0 83.5 36.899 83.5 82.5s-37.899 82.5-83.5 82.5c-45.888 0-82.5-37.502-82.5-82.5 0-78.082-63.246-142.5-142.5-142.5-78.6-.001-143.5 63.899-143.5 142.499s64.9 142.5 143.5 142.5z" />
                    </g>
                  </svg>
                </span>
                Join Now for free
              </Link>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default UpcomingMasterCoachWebinars;
