import React, { Component } from 'react'
import message from "../../../utils/mesaages";
import axios from '../../../config/axios';
import { getClientTimezone } from '../../../utils/timeZoneConvert';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import validation from '../../../Validate/validator';
import { join_logo, reg_left,Reg_no_overlay } from '../../../assets/images';

import FooterLink from '../../footers/FooterLink';
import Header from './../../headers/Header'
import HeaderSecondTo from '../Header/HeaderSecondTo';
 

export default class CorporateLogin extends Component {
    constructor(props, context) {
        super(props, context)
        this.token = localStorage.getItem("corporate_token")
        this.userdeta = localStorage.getItem("corporate_userdata")
        this.usertoken = localStorage.getItem("corporate_user_token")
        this.userDeta = localStorage.getItem("corporate_user_userdata")

        this.state = {
            email: "",
            password: "",
            loader: false,
            FromDataError: {
                email: "false",
                password: "false",
            },
            errors: []
        }
    }

    componentDidMount() {
        if ((this.token && this.userdeta) || (this.userDeta && this.usertoken)) {
            this.props.history.push('/dashboard')
        }
        if (new URLSearchParams(this.props.location.search).get("token")) {
            axios.get(`/api/corporate_activate_user?token=${new URLSearchParams(this.props.location.search).get("token")}`).then((response) => {
                if (response.data.token !== "" && response.data.success) {
                    localStorage.setItem("corporate_token", response.data.token);
                    localStorage.setItem("corporate_userdata", JSON.stringify(response.data.deta));
                    message.success(response.data.msg)

                    this.setState({ loader: false });
                    this.props.history.push('/dashboard')
                }
            }).catch((error) => {
                message.error(error.response.data.msg);
                this.setState({ loader: false });
            })
        }
    }

    validateForm(errors) {
        let valid = true;
        let sta = this;
        let FromDataError = this.state.FromDataError;

        for (const [key, value] of Object.entries(errors)) {
            if (value.length > 0) {
                if (value === "false") {
                    FromDataError[key] = "This field is required";
                    sta.setState({ FromDataError });
                }
                valid = false;
            }
        }
        return valid;
    }

    handleSubmit = (e) => {
        const hideMessage = message.loading("Please wait while logging in ...", 0);
        if (this.validateForm(this.state.FromDataError)) {
            this.setState({ loader: true });
            e.preventDefault();
            const formData = {
                email: this.state.email,
                password: this.state.password,
            };

            axios.post("/api/corporate_login", formData).then(async (response) => {
                hideMessage();
                if (response.data.token !== "" && response.data.success) {
                    localStorage.setItem("corporate_token", response.data.token);
                    localStorage.setItem("corporate_userdata", JSON.stringify(response.data.deta));
                    message.success(response.data.msg)
                    //localStorage.setItem("coachingCategory", response.data.userdata.coaching_category);
                    // getClientTimezone();
                    this.setState({ loader: false });
                    this.props.history.push('/dashboard')
                }
            }).catch((error) => {
                hideMessage();
                message.error(error.response.data.msg);
                this.setState({ loader: false });
            });
        }
    };

    handleChange = (e) => {
        e.persist();
        let { FromDataError } = this.state;
        var valid_obj = {
            value: e.target.value,
            rules: e.target.getAttribute("validaterule"),
            message: e.target.getAttribute("validatemsg"),
        };

        validation(valid_obj).then((error) => {
            FromDataError[e.target.name] = error;
            this.setState({ FromDataError });

            setTimeout(() => {
                this.setState({
                    [e.target.name]: e.target.value,
                });
            }, 100);
        });
    };

    render() {
        return (
            <>
                <HeaderSecondTo />
                <div className="reg">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className='reg_head text-center'>
                                    <h2>Company Login</h2>
                                    <p className="mb-0">Please login to your account</p>
                                </div>
                            </Col>
                            {/* <Col md={6}>
                            <div className='reg_logo text-right'>
                                <img src={join_logo} alt='join_logo' />
                            </div>
                        </Col> */}
                        </Row>

                        <Row className='justify-content-center'>
                            <Col md={9}>
                                <Card className='reg_card mt-4 border-0'>
                                    <Row className='mx-0'>
                                        <Col md={6} className="reg_image" style={{background: 'url('+Reg_no_overlay+') center center no-repeat', backgroundSize: 'cover'}}>
                                            {/* <img src={reg_left} className="img-fluid" alt='reg_left' /> */}
                                        </Col>
                                        <Col md={6} className="reg_form d-flex align-items-center">
                                            <Col className='px-0'>
                                                <Form onSubmit={this.handleSubmit}>
                                                    <div className='form_item'>
                                                        {/* <h4 className="regf_title">Personal Info</h4> */}
                                                        <Form.Group>
                                                            <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                                                            <Form.Control type="email" name='email' required validaterule={["required", "isEmail"]} validatemsg={["Enter your email address", "Enter a valid email address",]} onChange={(e) => this.handleChange(e)} />
                                                            <div style={{ color: "red" }}>
                                                                {this.state.FromDataError.email === "false" ? "" : this.state.FromDataError.email}
                                                            </div>
                                                        </Form.Group>

                                                        <Form.Group>
                                                            <Form.Label>Password <span className="text-danger">*</span></Form.Label>
                                                            <Form.Control type="Password" name='password' required validaterule={["required"]} validatemsg={["Password field is required"]} onChange={(e) => this.handleChange(e)} />
                                                            <div style={{ color: "red" }}>
                                                                {this.state.FromDataError.password === "false" ? "" : this.state.FromDataError.password}
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                    <Button variant='default' type="submit" disabled={this.state.loader} className="defaultBtn btn-block mt-3">Submit</Button>
                                                </Form>
                                                <p className="text-center mt-3 loginLink">New user? <a href="/signup"><strong>Sign Up</strong></a></p>
                                            </Col>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <FooterLink />
            </>
        )
    }
}
