import React from 'react'
import {Link} from 'react-router-dom'
import { toast } from "react-toastify";
import message from '../../utils/mesaages';

class CoachingArea extends React.Component{
  constructor(props) {
    super(props);
   
  }
    render(){
        return(
            <div>
                 <fieldset>
         <div className="heading text-center">
           <h3 className="fs-subtitle">Select your Coaching Areas </h3>
           <span className="R_divider"></span>
         </div>
         <div className="areas coaching_area d-flex mb-4 flex-wrap justify-content-start text-left">
            <div className="custom-control custom-checkbox checkbox-inline mx-2">
              <input type="checkbox" className="custom-control-input" id="Career Coaching" 
              onChange={this.props.handleCategories} value="Career Coaching" name='CoachingStyle' 
              checked={this.props.coachingstyledata.includes("Career Coaching")}/>
              <label className="custom-control-label" for="Career Coaching">Career Coaching</label>
            </div>

            <div className="custom-control custom-checkbox checkbox-inline mx-2">
              <input type="checkbox" className="custom-control-input" id="Executive Coaching"
                onChange={this.props.handleCategories} value="Executive Coaching" name='CoachingStyle'
                checked={this.props.coachingstyledata.includes("Executive Coaching")}/>
              <label className="custom-control-label" for="Executive Coaching">Executive Coaching</label>
            </div>
           
            <div className="custom-control custom-checkbox checkbox-inline mx-2">
              <input type="checkbox" className="custom-control-input" id="Life Coaching"
                onChange={this.props.handleCategories} value="Life Coaching" name='CoachingStyle'
                checked={this.props.coachingstyledata.includes("Life Coaching")}/>
              <label className="custom-control-label" for="Life Coaching">Life Coaching</label>
            </div>

            <div className="custom-control custom-checkbox checkbox-inline mx-2">
              <input type="checkbox" className="custom-control-input" id="Business Coaching"
                onChange={this.props.handleCategories} value="Business Coaching" name='CoachingStyle'
                checked={this.props.coachingstyledata.includes("Business Coaching")}/>
              <label className="custom-control-label" for="Business Coaching">Business Coaching</label>
            </div>

            <div className="custom-control custom-checkbox checkbox-inline mx-2">
              <input type="checkbox" className="custom-control-input" id="Transformational Coaching"
                onChange={this.props.handleCategories} value="Transformational Coaching" name='CoachingStyle'
                checked={this.props.coachingstyledata.includes("Transformational Coaching")}/>
              <label className="custom-control-label" for="Transformational Coaching">Transformational Coaching</label>
            </div>

            <div className="custom-control custom-checkbox checkbox-inline mx-2">
              <input type="checkbox" className="custom-control-input" id="Wellbeing Coaching"
                onChange={this.props.handleCategories} value="Wellbeing Coaching" name='CoachingStyle'
                checked={this.props.coachingstyledata.includes("Wellbeing Coaching")}/>
              <label className="custom-control-label" for="Wellbeing Coaching">Health & Wellbeing Coaching</label>
            </div>
         </div>
         {/* <input type="button" name="next" className="next action-button" value="Next" /> */}
         {/* <Link
          onClick={() =>
          this.props.handleField("Skills")
          }
         className="defaultBtn">Next
        </Link> */}
        <Link
        to="#"
        onClick={(e) => {
          if(this.props.coachingstyledata.length > 0){
            this.props.handleField("Skills")
          } else{
            e.preventDefault()
            //toast("Please select any category", { type: "error" });
            message.error("Please select any category.");
          }
        }
        }
        className="defaultBtn">Next
        </Link>
       </fieldset>
            </div>

        )
    }
}
export default CoachingArea