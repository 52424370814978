import React from 'react'
import {Link} from 'react-router-dom'
import { dArrowblack } from '../../assets/images'

class MessageBox extends React.Component{
    constructor(props) {
        super(props);
       
      }
    render(){
        return(
            <div>
                <fieldset>
                <div className="heading text-center">
                <h4 className="fs-subtitle" style={{fontSize: "17px"}}>Please provide specific details of your enquiry. This information will be used to match your requirements with coach profiles. This information will be sent to all matched prospective coaches. You will be sent an email with matched coach profiles.</h4>
                <span className="R_divider"></span>
                </div>
                <textarea value={this.props.data.messagebox} 
                onChange={this.props.handleChange} 
                className="form-control w-75 mx-auto" rows="3" name="messagebox" placeholder="Enter your Message..."></textarea>
                <p className="info text-center">Note : Maximum limitation 300 characters</p>

                    <Link
                    to="#"
                    onClick={() =>
                    this.props.handleField("Membership")
                    }
                    className="defaultBtn">PREVIOUS
                    </Link>{" "}
                
                    <Link
                    to="#"
                    onClick={() =>
                    this.props.handleField("MatchMyCoach")
                    //console.log("message box text", this.props.data.messagebox)
                    }
                    className="defaultBtn">Next
                    </Link>

       
                </fieldset>
            </div>
        )
    }
}
export default MessageBox