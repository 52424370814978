import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios, { AdminApi } from "../config/axios";
import { Glogin, Facebooklogin } from "../views/auth/social";
import { Header, HeaderTop } from "../components/headers";
import { FooterLink } from "../components/footers";
import validation from "../Validate/validator";
import message from "../utils/mesaages";
import { getClientTimezone, } from "../utils/timeZoneConvert";
import { SSO_LOGIN } from "../utils/Sso";
import { CaptchaKey } from "../config/custom";
import ReCAPTCHA from "react-google-recaptcha";
import SocilaLogin from "./SocilaLogin";

class Login extends Component {
  constructor(props, context) {
    super(props, context);
    this.token = localStorage.getItem("authToken")
    this.userdata = localStorage.getItem("userdata")
    this.state = {
      recapcha : "",
      username: "",
      password: "",
      isChecked: false,
      loader: false,
      FromDataError: {
        recapcha : "false",
        username: "false",
        password: "false",
      },
    };
  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {
        if (value === "false") {
          FromDataError[key] = "This field is required";
          sta.setState({ FromDataError });
        }

        valid = false;
      }
    }

    return valid;
  }

  authorizebyvideotoken(token) {
    let { params } = this.props.match;

    const hideMessage = message.loading(
      "Please wait while we are checking...",
      0
    );

    axios
      .put(`/authorizebyvideotoken/${token}/${params.type}`, {})
      .then((response) => {
        hideMessage();
        console.clear();
        console.log("res", response);
        if (response.data.success) {
          if (response.data.usertype === "coach") {
            // this.props.history.push('/profile-coach')

            localStorage.setItem("authToken", response.data.token);
            localStorage.setItem(
              "coachingCategory",
              response.data.userdata.coaching_category
            );

            localStorage.setItem(
              "userdata",
              JSON.stringify(response.data.userdata)
            );
            getClientTimezone();

            this.props.history.push({
              pathname: "/profile-coach",
              state: {
                loginType: "login mode",
                user: JSON.parse(localStorage.getItem("userdata")),
                token: localStorage.getItem("authToken"),
              },
            });
          }
          if (response.data.usertype === "user") {
            // console.log('rrrrrrrrrrrr',this.props)

            if (this.props.location.pathname.search("/rejected") > -1) {
              axios.post("/dataRecived", {
                session_id: token,
                status: "rejected",
              });

              this.props.history.push({
                pathname: "/profile-consumer",
                state: {
                  loginType: "login mode",
                  user: JSON.parse(localStorage.getItem("userdata")),
                  token: localStorage.getItem("authToken"),
                },
              });
            } else {
              axios.post("/dataRecived", { session_id: token, status: "end" });

              if (response.data.sessionType === "intro") {
                this.props.history.push({
                  pathname: "/profile-consumer",
                  state: {
                    loginType: "login mode",
                    user: JSON.parse(localStorage.getItem("userdata")),
                    token: localStorage.getItem("authToken"),
                  },
                });
              } else {
                this.props.history.push({
                  pathname: `/feedback/${params.sessionID}`,
                });
              }
            }
          }

          if (response.data.usertype === "jobseeker") {
            // console.log('rrrrrrrrrrrr',this.props)
            this.props.history.push({
              pathname: "/webinars",
              // state: {
              //   loginType: "login mode",
              //   user: JSON.parse(localStorage.getItem('userdata')),
              //   token: localStorage.getItem('authToken'),
              // },
            });
          }
          // message.success("Congratulations! Your account has been activated successfully!");
        } else {
          message.error("invalid video token");
        }
      })
      .catch((err) => {
        console.log(err);
        hideMessage();
        // message.error("Your account could not be activated.");
      });
  }

  activateAccount(token) {
    const hideMessage = message.loading(
      "Please wait while we are activating your account.",
      0
    );

    axios
      .put(`/activate/${token}`, {})
      .then((response) => {
        hideMessage();
        if (response.data.success) {
          message.success(
            "Congratulations! Your account has been activated successfully!"
          );

          axios
            .get(`${AdminApi}/signup/${token}`, {})
            .then(function (response) {
              console.log(response);
            })
            .catch(function (error) {
              console.log(error);
            });
            setTimeout(function(){
              window.location = "/login";
            },1000);
          //
        } else {
          message.error("Your account could not be activated.");
        }
      })
      .catch((err) => {
        console.log(err);
        hideMessage();
        message.error("Your account could not be activated.");
      });
  }

  async componentDidMount() {
    if (this.token && this.userdata) {
      if (JSON.parse(this.userdata).account_type !== "jobseeker") {
        window.location = '/profile-coach'
      } else if (JSON.parse(this.userdata).account_type === "jobseeker") {
        window.location = "/webinars"
      }
    }
    if (window.location.hash) {
      message.success("Logged out successfully.");
      var uri = window.location.toString();
      if (uri.indexOf("#") > 0) {
        var clean_uri = uri.substring(0, uri.indexOf("#"));
        window.history.replaceState({}, document.title, clean_uri);
      }
    }
    // else if(this.props.location.state !== undefined) {
    //   console.log("external url",this.props.location.state.externalUrl)
    //   let hrefValue = this.props.location.state.externalUrl
    //   if(localStorage.getItem('afterLoginHref') == null)
    //   localStorage.setItem('externalHref', hrefValue)
    // }
    else {
      let { params } = this.props.match;
      if (params.hasOwnProperty("token") && params.token.length > 30) {
        this.activateAccount(params.token);
      }

      if (params.hasOwnProperty("sessionID")) {
        this.authorizebyvideotoken(params.sessionID);
      }
      if (
        new URLSearchParams(this.props.location.search).get("acode") != null &&
        new URLSearchParams(this.props.location.search).get("acode") != ""
      ) {
        //SSO login
        let res = await SSO_LOGIN(
          new URLSearchParams(this.props.location.search).get("acode")
        );
        if (res === false) {
          message.error("User Not Found");
          setTimeout(() => {
            this.props.history.push({ pathname: "/login" });
          }, 3000);
        } else {
          if (res.data.result) {
            // after auto login from GWI jobseekers

            console.log("Jobseekers login");

            localStorage.setItem("authToken", res.data.token);
            localStorage.setItem(
              "coachingCategory",
              res.data.userdata.coaching_category
            );
            localStorage.setItem("userdata", JSON.stringify(res.data.userdata));
            localStorage.setItem("ML_TOKEN", res.data.ML_token);
            getClientTimezone();
            this.setState({ loader: false });
            if (res.data.userdata.account_type === "jobseeker") {
              this.props.history.push({
                pathname: "/webinars",
                state: {
                  user: res.data.userdata,
                  token: res.data.token,
                },
              });
            }
          } else {
            message.error("User Not Found");
            setTimeout(() => {
              this.props.history.push({ pathname: "/login" });
            }, 3000);
          }
        }
        //SSO login
      }
    }

  }

  // componentWillReceiveProps() {
  //   AOS.refresh();
  // }

  handleChange = (e) => {
    e.persist();
    let { FromDataError } = this.state;
    var valid_obj = {
      value: e.target.value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((error) => {
      console.log("aaaaaaaaaaa", error);
      FromDataError[e.target.name] = error;
      this.setState({ FromDataError });

      setTimeout(() => {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }, 100);
    });
  };

  handleCheckbox = (e) => {
    this.setState({
      isChecked: e.target.checked,
    });
  };

  getUserIdusingRoomID = (roomId) => {
    let dataReceived = axios
      .get(`/fetchUserId/${roomId}`)
      .then((res) => {
        return res.data.fetchedUserid;
      })
      .catch((err) => {
        return null;
      });
    return dataReceived;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm(this.state.FromDataError)) {
      const hideMessage = message.loading("Please wait while logging in ...", 0);
      this.setState({ loader: true });
      e.preventDefault();
      const formData = {
        username: this.state.username,
        password: this.state.password,
        isChecked: this.state.isChecked,
      };

      axios
        .post("/login", formData)
        .then(async (response) => {
          hideMessage();
          console.log("res", response.data);
          if (
            response.data.token !== undefined &&
            response.data.result === true
          ) {
            localStorage.setItem("authToken", response.data.token);
            localStorage.setItem(
              "coachingCategory",
              response.data.userdata.coaching_category
            );

            localStorage.setItem(
              "userdata",
              JSON.stringify(response.data.userdata)
            );
            localStorage.setItem("currency", response.data.userdata.currency);
            getClientTimezone();
            this.setState({ loader: false });
            if (response.data.userdata.account_type === "coach") {
              localStorage.removeItem("feedbackHref");
              const accontCreated = new Date(response.data.userdata.created);
              const finalDate = new Date(accontCreated);
              finalDate.setDate(accontCreated.getDate() + 90);
              var countDownDate = new Date(finalDate);
              var now = new Date();
              var timeleft = countDownDate - now;
              var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
              var freeTrialDaysleft = days >= 0 ? days : -1;

              if (
                freeTrialDaysleft === -1 &&
                response.data.valueCoach.membership_id === null
              ) {
                localStorage.setItem("membershipExpCheck", true);
              }
              let redirectUrl = localStorage.getItem("afterLoginHref");
              let externalHref = localStorage.getItem("externalHref");
              if (localStorage.getItem("afterLoginHref") != null) {
                localStorage.removeItem("afterLoginHref");
                this.props.history.push({
                  pathname: `/profile-coach-view/${redirectUrl}`,
                  state: {
                    user: response.data.userdata,
                    token: response.data.token,
                  },
                });
              } else if (localStorage.getItem("externalHref") != null) {
                localStorage.removeItem("externalHref");
                window.location = externalHref;
              } else {
                this.props.history.push({
                  pathname: "/profile-coach",
                  state: {
                    loginType: "login mode",
                    user: response.data.userdata,
                    token: response.data.token,
                  },
                });
              }
            } else if (response.data.userdata.account_type === "user" || response.data.userdata.account_type === "jobseeker") {
              // let redirectUrl = localStorage.getItem("afterLoginHref");
              // let externalHref = localStorage.getItem("externalHref");
              // if (localStorage.getItem("afterLoginHref") != null) {
              //   localStorage.removeItem("afterLoginHref");
              //   this.props.history.push({
              //     pathname: `/profile-coach-view/${redirectUrl}`,
              //     state: {
              //       user: response.data.userdata,
              //       token: response.data.token,
              //     },
              //   });
              // } else if (localStorage.getItem("externalHref") != null) {
              //   localStorage.removeItem("externalHref");
              //   window.location = externalHref;
              // } else if (localStorage.getItem("feedbackHref") != null) {
              //   let theUrl = localStorage.getItem("feedbackHref");
              //   let roomId = theUrl.split("/").pop();
              //   let fetchedUserID = await this.getUserIdusingRoomID(roomId);
              //   if (
              //     fetchedUserID !== null &&
              //     fetchedUserID !== "deleted" &&
              //     fetchedUserID === response.data.userdata.uid
              //   ) {
              //     window.location = theUrl;
              //   } else {
              //     localStorage.removeItem("feedbackHref");
              //     this.props.history.push({
              //       pathname: "/profile-consumer",
              //       state: {
              //         user: response.data.userdata,
              //         token: response.data.token,
              //       },
              //     });
              //   }
              // } else {
              //   this.props.history.push({
              //     pathname: "/profile-consumer",
              //     state: {
              //       user: response.data.userdata,
              //       token: response.data.token,
              //     },
              //   });
              // }
              this.props.history.push({ pathname: "webinars", state: { user: response.data.userdata, token: response.data.token } })
            }
          } else if (response.data.result === false) {
            message.error(response.data.msg);
            this.setState({ loader: false });
          } else {
            this.setState({ loader: false });
          }
        })
        .catch((error) => {
          hideMessage();
          console.log(error);
          message.error("Error loggin in.");
          this.setState({ loader: false });
        });
    }
  };
  capchaChange = (value) => {
    console.log("Captcha value:", value);
    let {  FromDataError } = this.state;
    FromDataError.recapcha = "";
    this.setState({ recapcha : value, FromDataError }, () => {
      this.validateForm(FromDataError);
    });

    let th = this;
        setTimeout(function() {
                FromDataError.recapcha = "false";
                th.setState({ recapcha : "", FromDataError }, () => {
                th.validateForm(FromDataError);
            });
        }, 120000); 
  };

  render() {
    return (
      <React.Fragment>
        <HeaderTop />
        <Header />

        <section className="registrationWrap">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 registrationContent">
                <div className="row ">
                  <div className="col-md-6 otherRegistration">
                    <div className="otherRegistrationFilds">
                      <h3 className="text-center">
                        Login with
                      </h3>
                      <span className="R_divider mx-auto d-block"></span>
                      <ul className="text-center">
                        {/* <Facebooklogin /> */}
                        <br />
                        {/* <Glogin /> */}
                        <SocilaLogin isLogin={true} txt="Google"/>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="registrationForm loginForm">
                      <h3>LOGIN</h3>
                      <div className="text-center">
                        <span className="R_divider div_dark"></span>
                      </div>
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-group ">
                          <input
                            // value={this.state.username}
                            onChange={this.handleChange}
                            required={true}
                            className="form-control"
                            placeholder="Email Address"
                            type="email"
                            name="username"
                            validaterule={["required", "isEmail"]}
                            validatemsg={[
                              "Enter your email address",
                              "Enter a valid email address",
                            ]}
                          />
                          <p style={{ color: "red" }}>
                            {this.state.FromDataError.username === "false"
                              ? ""
                              : this.state.FromDataError.username}
                          </p>
                        </div>

                        <div className="form-group">
                          <input
                            className="form-control"
                            placeholder=" Password"
                            type="password"
                            // value={this.state.password}
                            onChange={this.handleChange}
                            required={true}
                            name="password"
                            validaterule={["required"]}
                            validatemsg={["Password field is required"]}
                          />
                          <p style={{ color: "red" }}>
                            {this.state.FromDataError.password === "false"
                              ? ""
                              : this.state.FromDataError.password}
                          </p>
                        </div>

                      
                        <ReCAPTCHA
                              sitekey={CaptchaKey}
                              onChange={this.capchaChange}
                            />

                        <p style={{ color: "red" }}>
                          {this.state.FromDataError.recapcha == "false"
                            ? ""
                            : this.state.FromDataError.recapcha}
                        </p>
                      

                        <div className="form-group mt-4">
                          <button
                            disabled={this.state.loader}
                            className="defaultBtn btn-block"
                            type="submit"
                          >Click here to Login</button>
                        </div>
                        <p className="text-center mt-3 loginLink" style={{"font-size": "15px"}}>
                        New User ? &nbsp; 
                          <Link to="/user_registration">
                            <b>SIGN UP</b>
                            </Link>
                        </p>
<p className="text-center">Or</p>
                        <p className="text-center loginLink">
                                New Coach ?  <Link to="/registration"> <b>SIGN UP</b></Link>
                        </p>
                      </form>

                      <div className="text-center">
                        <Link
                          to="/forgotpassword"
                          className="form-recovery"
                        >
                          <small> Forgot Password?</small>
                        </Link>
                      </div>
                    </div>

                    {/* <div className="logreg_or">OR</div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer>
          <FooterLink />
        </footer>
        {/* <a href="/#" id="button"></a> */}
      </React.Fragment>
    );
  }
}

export default Login;
