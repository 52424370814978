import React from 'react'
import ReactPaginate from "react-paginate";
import { Row, Col, Card, Button, Form, InputGroup, FormControl } from 'react-bootstrap'

import { Link } from 'react-router-dom';
import Select from "react-select";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

import './Dashboard.css'
import axios from '../../../config/axios';
import { message } from 'antd';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { LGspinner, Practitioner } from '../../../assets/images';
import CustomImageLoader from "react-custom-image-loader.";
import {
    Webiner_coach1,
    webiner1,
} from "../../../assets/images";



class CorporateDashboard extends React.Component {
    constructor(props) {
        super(props)

        this.token = localStorage.getItem("corporate_token")
        this.userdeta = JSON.parse(localStorage.getItem("corporate_userdata"))
        this.usertoken = localStorage.getItem("corporate_user_token")
        this.userDeta = localStorage.getItem("corporate_user_userdata")

        this.state = {
            per_page: 10,
            loader: false,
            webiners: [],
            webinar_id: [],
            current_webinars: [],
            current_session: [],
            month_wise_webinars: [],
            totalItems: 0,
            page: 1,
            archived_webinar: [],
            attendance: {},
            percent: 0,

            // FILTER
            webinar_name: "",
            coach_name: "",
            webinar_date: "",
            show: false,
            all_coach: [],
            coach: {},
            isClicked: false,

            // TIMER
            timerOne: {},
            timerTwo: {},

            offset: moment().local().utcOffset(),

            currentDate: "",
            date: "",

            // Archive
            totalWebiner: "",
            JoinWebinerList: []
        };

    }

    componentDidMount() {
        if ((!this.token && !this.userdeta) && (!this.userDeta && !this.usertoken)) {
            this.props.history.push('/')
        }
        this.getAllUpcomingWebiners(1);
    }

    getAllUpcomingWebiners = (page) => {
        this.setState({ loader: true });

        axios.get(`/api/corporate_get_all_upcoming_webinar?page=${page}&webinar_name=${this.state.webinar_name}&coach_name=${this.state.coach_name}&webinar_date=${this.state.webinar_date}`,
            {
                headers: {
                    "X-Auth": this.token,
                    "corporate": true
                },
            }
        ).then((res) => {
            // console.log(res.data.data)
            if (res.data.success) {
                let { webiners, total, per_page, page, webinar_id } = res.data.data;
                this.setState({ webiners, totalItems: total, per_page, page: parseInt(page), loader: false });
            } else {
                message.error(res.data.msg);
            }
        })
            .catch((err) => {
                this.setState({ loader: false });
                console.log(err);
                message.error("Something Went Wrong");
            });
    };

    onPageChange = async (page) => {
        let currentPage = page.selected + 1;
        await this.setState({ currentPage });
        this.getAllUpcomingWebiners(currentPage);
    };


    addWebinarUser = (webinar_id) => {
        try {
            this.setState({ loader: true });
            const fromdata = { webinar_id, company_id: this.userdeta.company_id, hr_id: this.userdeta.uid }
            axios.post(`/api/corporate_schedule_webinar`, fromdata, { headers: { "X-Auth": this.token, }, }).then((res) => {
                this.setState({ loader: false });
                console.log(res.data)
                if (res.data.success) {
                    message.success(res.data.msg);
                    this.getAllUpcomingWebiners(1);
                }
            }).catch((error) => {
                this.setState({ loader: false });
                console.log(error.response);
                message.error(error.response.msg || error.response.data.msg);
            });
        } catch (error) {
            this.setState({ loader: false });
            console.log(error);
            message.error(error.response.msg);
        }
    }

    render() {
        const { webiners, totalItems, per_page, page, current_webinars, timerOne, timerTwo, archived_webinar, attendance, percent, current_session, webinar_id } = this.state;
        const options = [
            { value: "Webinar", label: "Webinar" },
            { value: "Session", label: "Session" },
            { value: "Newest", label: "Newest" },
            { value: "Added", label: "Added" }
        ]
        return (
            <div className="content_main">
                <Header />

                <LoadingOverlay active={this.state.loader} className="webiner_loader" spinner={<CustomImageLoader image={LGspinner} animationType={"none"} />}>
                    <div className="content_wrap">

                        <div className="cWbiners">
                            <Row>
                                <Col md={5}>
                                    <h4 className='page-title mb-3'>Webinars</h4>
                                </Col>
                                <Col md={4}>
                                    <InputGroup className="search_webiner">
                                        <Form.Control
                                            className="border-0 m-0"
                                            placeholder="Type here..."
                                        />
                                        <InputGroup.Text
                                        >
                                            <i className="fa fa-search" />
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col md={3}>
                                    <Form.Group className="d-flex align-items-center filter_webiner">
                                        <label className='mr-2 mb-0'>Filter By</label>
                                        <Select className="col px-0" options={options} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {(webiners.length === 0) ? (
                                    <Col md={12} className="text-center mt-5">
                                        <p>No upcoming webinars currently</p>
                                    </Col>
                                ) : webiners.map((items) => (
                                    <Col md={4}>
                                        <div className="webiner_card">
                                            <img src={items.image || webiner1} className="img-fluid" alt="webinar" />
                                            <span className='cw_new'>NEW</span>
                                            <div className="webiner_card_content text-light">
                                                <img width={60} height={60} src={items.coach_image || Practitioner} className="img-fluid rounded-circle" alt={items.coach_name} />
                                                <h4 className='webiner_coach_name mt-2'>{items.coach_name}</h4>

                                                <p className='webiner_coach_details'></p>
                                                <Link to={`/webinar-details/${items.coach_uid}/${items.id}`}>
                                                    <h3 className='webiner_name' title={items.webinar_topic}>{items.webinar_topic}</h3>
                                                </Link>
                                                <p className="webinar_description">{items.script}</p>
                                                <div className="cw_type">Webinar</div>
                                                <div className="text-uppercase">
                                                <small style={{ fontSize: '0.7rem' }}> {moment.utc(items.date_string).tz("America/New_York").format("D MMM, YYYY - h:mm A")}  <b>EST</b> </small>
                                                    {/* <small style={{ fontSize: '0.7rem' }}>{moment(items.date_time).format("DD MMM, YYYY - hh:mm A")}</small> */}
                                                </div>
                                                <div className='wb_links'>
                                                    {
                                                        (items.isSchedule) ? (
                                                            <Button className="btn tabformbutton" variant="secondary" disabled>ADDED</Button>
                                                        ) : (
                                                            <Button variant='default' className="defaultBtn" onClick={() => this.addWebinarUser(items.id)}>ADD</Button>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                ))
                                }
                            </Row>
                        </div>

                        <div className="result_pagination mt-4">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={this.onPageChange}
                                pageRangeDisplayed={3}
                                pageCount={Math.ceil(totalItems / per_page)}
                                previousLabel="<"
                                activeClassName="active"
                                disabledClassName="disable"
                                renderOnZeroPageCount={null}
                            />
                        </div>

                    </div>
                </LoadingOverlay>

                <Sidebar />
            </div>
        )
    }
}

export default CorporateDashboard