import React from 'react'
import { Link } from 'react-router-dom'
import axios from '../../config/axios'

class CoachSideBar extends React.Component {

  constructor(props) {
    super(props)
    this.uid = JSON.parse(localStorage.getItem('userdata')).uid
    this.token = localStorage.getItem('authToken')
    this.state = { couchtype: {} }
  }

  getCouchType = () => {
    this.setState({ loader: true });

    axios.post(`/webinar/couchtype`, { couch_uid: this.uid }, { headers: { "X-Auth": this.token, }, }).then((res) => {
      this.setState({ loader: false });
      if (res.data.success) {
        console.log("User Type Response :", res.data.data.userType.coach_type);
        this.setState({ couchtype: res.data.data.userType.coach_type })
      }

    }).catch((err) => {
      this.setState({ loader: false });
      console.log(err);
    })
  }

  componentDidMount() {
    this.getCouchType();
  }

  render() {
    const { couchtype } = this.state
    return (
      <div>
        <ul>
          {/* <li className={`${this.props.renderSideBarComponent === "CreateSession" ? "active" : " "}`}>
            <Link onClick={() => this.props.handleSideBarComponent("CreateSession")}>
              <i className="fa fa-tachometer" aria-hidden="true"></i>{" "} Create Session
            </Link>
          </li> */}
          <div className="tour-profile">

            <li>
              <Link to="/profile-coach-edit" id="first_button">
                <i className="fa fa-book" aria-hidden="true"></i>{" "}
                {/* Profile & Private Details */}
                Profile & Personal Details
              </Link>
            </li>
            {/* <li
                      className={`${
                        this.props.renderSideBarComponent === "UpcomingSessions"
                          ? "active"
                          : " "
                      }`}
                    >
                      <Link
                        onClick={() => this.props.handleSideBarComponent("UpcomingSessions")}
                      >
                        <i  className="fa fa-book" aria-hidden="true"></i>{" "}
                        Upcoming Sessions
                      </Link>
                </li> */}

            {/* {
              ((parseInt(couchtype) === 2) || (parseInt(couchtype) === 3)) && (
                <li className={`${this.props.renderSideBarComponent === "SetAvailability" ? "active" : " "}`}>
                  <Link to="#" className="tour-SetAvailability" onClick={() => this.props.handleSideBarComponent("SetAvailability")}>
                    <i className="fa fa-book" aria-hidden="true"></i>{" "} Set Availability
                  </Link>
                </li>
              )
            } */}

            {/* <li className={`${this.props.renderSideBarComponent === "Packages" ? "active" : " "}`}>
              <Link to="#" className="tour-createpackages" onClick={() => this.props.handleSideBarComponent("Packages")}>
                <i className="fa fa-tachometer" aria-hidden="true"></i>{" "} Create & Manage sessions
              </Link>
            </li> */}

            {
              ((parseInt(couchtype) === 1) || (parseInt(couchtype) === 3)) && (
                <li className={`${this.props.renderSideBarComponent === "Webinars" ? "active" : " "}`}>
                  <Link to="#" className="tour-createpackages" onClick={() => this.props.handleSideBarComponent("Webinars")} >
                    <i className="fa fa-tachometer" aria-hidden="true"></i>{" "}
                    Webinars
                  </Link>
                </li>
              )
            }
            {/* {
              ((parseInt(couchtype) === 2) || (parseInt(couchtype) === 3)) && (
                <li className={`${this.props.renderSideBarComponent === "Sessions" ? "active" : " "}`}>
                  <Link to="#" className="tour-createpackages" onClick={() => this.props.handleSideBarComponent("Sessions")} >
                    <i className="fa fa-tachometer" aria-hidden="true"></i>{" "}
                    Sessions
                  </Link>
                </li>
              )
            } */}
           <li className={`${this.props.renderSideBarComponent === "MySchedule" ? "active" : " "}`}>
            <Link to="#" className="tour-mySchedule" onClick={() => this.props.handleSideBarComponent("MySchedule")} >
              <i className="fa fa-book" aria-hidden="true"></i>{" "}
              My Schedule
            </Link>
          </li>
          </div>
         

          {/* <li
                      className={`${
                        this.props.renderSideBarComponent === "PaymentsReceived"
                          ? "active"
                          : " "
                      }`}
                    >
                      <Link
                      to="#"
                        onClick={() => this.props.handleSideBarComponent("PaymentsReceived")}
                      >
                        <i  className="fa fa-credit-card" aria-hidden="true"></i>{" "}
                        Payments Received 
                      </Link>
                </li> */}

          {/* <li className={`${this.props.renderSideBarComponent === "ManagePayments" ? "active" : " "}`}>
            <Link to="#" className="tour-payment-management" onClick={() => this.props.handleSideBarComponent("ManagePayments")} >
              <i className="fa fa-history" aria-hidden="true"></i>{" "}
              Payment Management
            </Link>
          </li>

          <li className={`${this.props.renderSideBarComponent === "ManageSubscription" ? "active" : " "}`} >
            <Link to="#" onClick={() => this.props.handleSideBarComponent("ManageSubscription")} >
              <i className="fa fa-credit-card" aria-hidden="true"></i>{" "}
              Manage Subscription
            </Link>
          </li> */}

          {/* <li
                      className={`${
                        this.props.renderSideBarComponent === "TransactionHistory"
                          ? "active"
                          : " "
                      }`}
                    >
                      <Link
                      to="#"
                      className="tour-transaction-history"
                        onClick={() => this.props.handleSideBarComponent("TransactionHistory")}
                      >
                        <i  className="fa fa-calendar-check-o" aria-hidden="true"></i>{" "}
                        Transaction History 
                      </Link>
                </li> */}

          {/* <li
                      className={`${
                        this.props.renderSideBarComponent === "JoinSession"
                          ? "active"
                          : " "
                      }`}
                    >
                      <Link
                      to="#"
                        onClick={() => this.props.handleSideBarComponent("JoinSession")}
                      >
                        <i  className="fa fa-tachometer" aria-hidden="true"></i>{" "}
                        Session Meeting Room
                      </Link>
                </li> */}

          {/* <li
                      className={`${
                        this.props.renderSideBarComponent === "Reviews"
                          ? "active"
                          : " "
                      }`}
                    >
                      <Link
                      to="#"
                      className="tour-manage-testimonials"
                        onClick={() => this.props.handleSideBarComponent("Reviews")}
                      >
                        <i  className="fa fa-tachometer" aria-hidden="true"></i>{" "}
                        Manage Testimonials
                      </Link>
                </li> */}

        </ul>

      </div>


    )
  }
}

export default CoachSideBar