import React, { Component } from "react";
import { Modal, Form, Col, Row, Button, Spinner } from "react-bootstrap";
import moment from "moment";
import axios from "../../../config/axios";
import message from "../../../utils/mesaages";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import isAlphanumeric from "validator/lib/isAlphanumeric";
import validator from 'validator';
import { DatePicker, Alert } from "antd";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";
import  {LGspinner}  from "../../../assets/images";

const _ = require("lodash");
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

class UpdateBankDetailsModal extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      vendorName: "",
      vendorAddress: "",
      vendorCountry: "",
      vendorState: "",
      vendorCity: "",
      vendorZip: "",
      phoneNumber: "",
      vendorDOB: "",
      identificationType: "",
      identificationNumber: "",
      accountNumber: "",
      accountName: "",
      accountBankname: "",
      bankId: "",
      vendorIBAN: "",
      accountEmail: "",
      bankAddress: "",
      bankCountry: "",
      bankState: "",
      bankCity: "",
      bankZip: "",
      bankAccType: "",
      bankAccClass: "",
      payoutType: "",
      payoutCurrency: "",
      minimalPayoutAmount: "",
      //payoutFrequency: "",
      dataAccurate: false,
      showUpdateModal: false,
      alertMessage: [],
      alertVisible: false,
      overlayLoader: false,
      bankerrors: {
        vendorName: "",
        phoneNumber: "",
        accountNumber: "",
        accountName: "",
        bankId: "", // bank identifier / routing number / sort code / BSB / IFSC code
        accountBankname: "",
        accountEmail: "",
        vendorAddress: "",
        vendorCountry: "",
        vendorState: "",
        vendorZip: "",
        vendorDOB: "",
        identificationType: "",
        identificationNumber: "",
        bankAddress: "",
        bankCountry: "",
        bankState: "",
        bankCity: "",
        bankZip: "",
        bankAccType: "",
        bankAccClass: "",
        payoutType: "",
        vendorIBAN: "",
      },
    };
  }

  componentDidMount = () => {
    this.retrieveVendorDetails();
  };

  retrieveVendorDetails = async () => {
    const { vendorID } = this.props;
    this.setState({overlayLoader: true})
    axios
      .get(`/bluesnap/retrieveVendor/${vendorID}`)
      .then(async (response) => {
        if (response.data.success) {
          let vendorDetails = response.data.receivedData;
          let isUpdatedState = await this.updateStateData(vendorDetails);
          if(isUpdatedState) {
            this.setState({overlayLoader: false})
          } else {
            this.setState({overlayLoader: false})
            message.error("error while rendering details")
          }
        } else {
          console.log(response.data.err);
          message.error("Error while retrieving Details");
          this.setState({overlayLoader: false})
        }
      })
      .catch((error) => {
        console.log("error", error);
        message.error("Something went wrong!");
        this.setState({overlayLoader: false})
      });
  };

  updateStateData = async (vendorDetails) => {
    try {
      console.log("vendor details", vendorDetails);
    let vendorPrincipal = vendorDetails.vendorPrincipal;
    let payoutInfo = vendorDetails.payoutInfo[0];
    await this.setState({
      vendorName: vendorDetails.firstName + " " + vendorDetails.lastName,
      vendorAddress: vendorDetails.address,
      vendorCountry: vendorDetails.country.toUpperCase(),
      vendorState: vendorDetails.state,
      vendorCity: vendorDetails.city,
      vendorZip: vendorDetails.zip,
      phoneNumber: vendorDetails.phone,
      vendorDOB: vendorPrincipal.dob,
      identificationType: vendorPrincipal.personalIdentificationNumber
        ? "pin"
        : vendorPrincipal.passportNumber
        ? "passport"
        : "drivingLicense",
      identificationNumber: vendorPrincipal.personalIdentificationNumber
        ? vendorPrincipal.personalIdentificationNumber
        : vendorPrincipal.passportNumber
        ? vendorPrincipal.passportNumber
        : vendorPrincipal.driverLicenseNumber,
      accountNumber: payoutInfo.bankAccountId,
      accountName: payoutInfo.nameOnAccount,
      accountBankname: payoutInfo.bankName,
      bankId: payoutInfo.bankId,
      vendorIBAN: payoutInfo.iban,
      accountEmail: vendorDetails.email,
      bankAddress: payoutInfo.address,
      bankCountry: payoutInfo.country.toUpperCase(),
      bankState: payoutInfo.state,
      bankCity: payoutInfo.city,
      bankZip: payoutInfo.zip,
      bankAccType: payoutInfo.bankAccountType,
      bankAccClass: payoutInfo.bankAccountClass,
      payoutType: payoutInfo.payoutType,
      payoutCurrency: payoutInfo.baseCurrency,
      minimalPayoutAmount: payoutInfo.minimalPayoutAmount,
    });
      return true
    }
    catch(err) {
      console.log("error while updating state")
      return false
    }
  };

  handleShowUpdateModal = async () => {
    let {bankerrors} = this.state
    Object.keys(bankerrors).forEach((each) =>{
      bankerrors[each]= ""
    } )
    this.setState({ bankerrors, showUpdateModal: true, alertVisible: false, alertMessage: [] });
    await this.retrieveVendorDetails()
  };

  handleHideUpdateModal = () => {
    this.setState({ showUpdateModal: false });
  }

  // ___________________________________ HANDLE CHANGE VENDOR ADDRESS______________________________________
  handleAddressChange = (address) => {
    this.setState({ vendorAddress: address });

    if (address != "" && address != null) {
    } else {
      //validate
      let { bankerrors } = this.state;
      bankerrors["vendorAddress"] = "This field is required";
      this.setState({ bankerrors });

      //validate
    }
  };

  // ___________________________________ HANDLE CHANGE BANK ADDRESS______________________________________
  handleBankAddressChange = (address) => {
    this.setState({ bankAddress: address });

    if (address != "" && address != null) {
    } else {
      //validate
      let { bankerrors } = this.state;
      bankerrors["bankAddress"] = "This field is required";
      this.setState({ bankerrors });

      //validate
    }
  };

  // ___________________________________ HANDLE SELECT VENDOR ADDRESS______________________________________
  handleSelect = async (address) => {
    this.setState({ vendorAddress: address });
    var that = this;
    await geocodeByAddress(address)
      .then((results) => {
        console.log("RESULT FO GEOCODEBYADDRESS", results);
        _.map(results[0].address_components, async (eachComp) => {
          console.log("EACH COMPONENT", eachComp);
          if (_.includes(eachComp.types, "country")) {
            await that.setState(
              {
                vendorCountry:
                  eachComp.short_name === "GB" ? "UK" : eachComp.short_name,
              },
              () => {
                if (eachComp.long_name != "") {
                  //validate
                  let { bankerrors } = this.state;
                  if (
                    this.state.payoutType === "ACH" &&
                    this.state.vendorCountry === "US"
                  ) {
                    bankerrors["vendorAddress"] = "";
                    bankerrors["vendorCountry"] = "";
                    bankerrors["vendorState"] = "";
                    this.setState({ bankerrors });
                  } else if (
                    (this.state.payoutType === "FAST" ||
                      this.state.payoutType === "CHAPS") &&
                    this.state.vendorCountry === "UK"
                  ) {
                    bankerrors["vendorAddress"] = "";
                    bankerrors["vendorCountry"] = "";
                    this.setState({ bankerrors });
                  } else if (this.state.payoutType === "WIRE") {
                    bankerrors["vendorAddress"] = "";
                    bankerrors["vendorCountry"] = "";
                    this.setState({ bankerrors });
                  } else {
                    bankerrors[
                      "vendorAddress"
                    ] = `Payout type ${this.state.payoutType} is not supported in this Address`;
                    this.setState({ bankerrors });
                  }

                  //validate
                }
              }
            );
          }
          if (_.includes(eachComp.types, "administrative_area_level_1") || _.includes(eachComp.types, "administrative_area_level_2")) {
            //console.log("EACHCOMP.TYPES",timezones[0].name)
            await that.setState({
              vendorState: eachComp.short_name,
            });
          }

          if (_.includes(eachComp.types, "locality")) {
            await that.setState({ vendorCity: eachComp.long_name });
          }

          if (_.includes(eachComp.types, "neighborhood")) {
            if (this.state.vendorCity != "") {
              await that.setState({ vendorCity: eachComp.long_name });
            }
          }

          return eachComp;
        });
      })
      .catch((error) => console.error("Error", error));
  };

  // ___________________________________ HANDLE SELECT BANK ADDRESS______________________________________
  handleSelectBankAddress = async (address) => {
    this.setState({ bankAddress: address });
    var that = this;
    await geocodeByAddress(address)
      .then((results) => {
        console.log("RESULT FO GEOCODEBYADDRESS", results);
        _.map(results[0].address_components, async (eachComp) => {
          console.log("EACH COMPONENT", eachComp);
          if (_.includes(eachComp.types, "country")) {
            await that.setState(
              {
                bankCountry:
                  eachComp.short_name === "GB" ? "UK" : eachComp.short_name,
              },
              () => {
                if (eachComp.long_name != "") {
                  let { bankerrors } = this.state;
                  if (
                    this.state.payoutType === "ACH" &&
                    this.state.bankCountry === "US"
                  ) {
                    bankerrors["bankAddress"] = "";
                    bankerrors["bankCountry"] = "";
                    bankerrors["bankState"] = "";
                    this.setState({ bankerrors });
                  } else if (
                    (this.state.payoutType === "FAST" ||
                      this.state.payoutType === "CHAPS") &&
                    this.state.bankCountry === "UK"
                  ) {
                    bankerrors["bankAddress"] = "";
                    bankerrors["bankCountry"] = "";
                    this.setState({ bankerrors });
                  } else if (this.state.payoutType === "WIRE") {
                    bankerrors["bankAddress"] = "";
                    bankerrors["bankCountry"] = "";
                    this.setState({ bankerrors });
                  } else {
                    bankerrors[
                      "bankAddress"
                    ] = `Payout type ${this.state.payoutType} is not supported in this Address`;
                    this.setState({ bankerrors });
                  }

                  //validate
                }
              }
            );
          }
          if (_.includes(eachComp.types, "administrative_area_level_1")) {
            //console.log("EACHCOMP.TYPES",timezones[0].name)
            await that.setState({
              bankState: eachComp.short_name,
            });
          }

          if (_.includes(eachComp.types, "locality")) {
            await that.setState({ bankCity: eachComp.long_name });
          }

          if (_.includes(eachComp.types, "neighborhood")) {
            if (this.state.bankCity != "") {
              await that.setState({ bankCity: eachComp.long_name });
            }
          }

          return eachComp;
        });
      })
      .catch((error) => console.error("Error", error));
  };

  // __________________________________ SET STATE ON CHANGE ____________________________________
  setvalueBankDetails = async (value, field) => {
    let numbers = /^[0-9]*$/;
    let f = field;
    let { bankerrors } = this.state;
    this.handleBankError(field, value);
    switch (field) {
      case "payoutType":
        this.setState({
          payoutType: value,
          payoutCurrency:
            value === "ACH"
              ? "USD"
              : value === "CHAPS" || value === "FAST"
              ? "GBP"
              : this.state.payoutCurrency,
          vendorAddress: "",
          vendorCountry: "",
          vendorState: "",
          vendorCity: "",
          bankAddress: "",
          bankCountry: "",
          bankState: "",
          bankCity: "",
          vendorIBAN: "",
          identificationType: "",
          //identificationNumber: "",
        });

        bankerrors.vendorAddress = "";
        bankerrors.bankAddress = "";
        bankerrors.payoutCurrency = "";
        this.setState({ bankerrors });

        break;

      case "payoutCurrency":
        this.setState({ payoutCurrency: value });
        break;

      case "vendorName":
        this.setState({ vendorName: value });
        break;

      case "accountEmail":
        this.setState({ accountEmail: value });
        break;

      // set phone number field
      case "phoneNumber":
        if (numbers.test(value)) {
          if (value.length > 15) {
            this.setState({ phoneNumber: this.state.phoneNumber });
          } else {
            this.setState({ phoneNumber: value });
          }
        } else {
          this.setState({ phoneNumber: this.state.phoneNumber });
        }
        break;

      case "vendorCity":
        this.setState({ vendorCity: value });
        break;

      case "bankCity":
        this.setState({ bankCity: value });
        break;

      // set zip code field
      case "vendorZip":
       
        if (validator.matches(value,/^[a-z0-9 ]+$/i) || value=='') {
          if (value.length > 10) {
            this.setState({ vendorZip: this.state.vendorZip });
          } else {
            this.setState({ vendorZip: value });
          }
        } else {
          this.setState({ vendorZip: this.state.vendorZip });
        }
        break;

      case "bankZip":
        if (validator.matches(value,/^[a-z0-9 ]+$/i) || value=='') {
          if (value.length > 10) {
            this.setState({ bankZip: this.state.bankZip });
          } else {
            this.setState({ bankZip: value });
          }
        } else {
          this.setState({ bankZip: this.state.bankZip });
        }
        break;

      case "vendorDOB":
        this.setState({ vendorDOB: value });
        break;

      case "identificationType":
        this.setState({ identificationType: value });
        break;
      case "identificationNumber":
        if (numbers.test(value)) {
          this.setState({ identificationNumber: value });
        } else {
          this.setState({
            identificationNumber: this.state.identificationNumber,
          });
        }
        break;

      case "accountNumber":
        if (numbers.test(value)) {
          this.setState({ accountNumber: value });
        } else {
          this.setState({ accountNumber: this.state.accountNumber });
        }
        break;

      case "vendorIBAN":
        if (isAlphanumeric(value) || value == "") {
          this.setState({ vendorIBAN: value });
        } else {
          this.setState({ vendorIBAN: this.state.vendorIBAN });
        }
        break;

      case "accountName":
        this.setState({ accountName: value });
        break;

      case "bankId":
        this.setState({ bankId: value });
        break;

      case "accountBankname":
        this.setState({ accountBankname: value });
        break;

      // case "payoutFrequency":
      //   this.setState({ payoutFrequency: value });
      //   break;

      case "bankAccType":
        
        this.setState({ bankAccType: value});

        break;

      case "bankAccClass":
        this.setState({ bankAccClass: value, accountNumber: "", vendorIBAN: "" });
        bankerrors.vendorIBAN = "";
        bankerrors.accountNumber = "";
        this.setState({ bankerrors });
        break;

      case "dataAccurate":
        this.setState({ dataAccurate: value });
        break;

      default:
        break;
    }
  };

  // ______________________________ ONCHANGE ERROR TEXT VALIDATION_____________________________
  handleBankError = (name, value) => {
    console.log("name1", name);
    console.log("value1", value);

    let bankerrors = this.state.bankerrors;
    switch (name) {
      case "payoutType":
        if (value === "") {
          bankerrors.payoutType = "Payout type is required";
        } else {
          bankerrors.payoutType = "";
        }
        break;

      case "payoutCurrency":
        if (value === "") {
          bankerrors.payoutCurrency = "Payout Currency is required";
        } else {
          bankerrors.payoutCurrency = "";
        }
        break;

      case "vendorName":
        if (value === "") {
          bankerrors.vendorName = "Vendor name is required";
        } else {
          bankerrors.vendorName = "";
        }
        break;

      case "vendorDOB":
        if (value === "") {
          bankerrors.vendorDOB = "Date of Birth is required";
        } else {
          bankerrors.vendorDOB = "";
        }
        break;

      case "identificationType":
        if (value === "") {
          bankerrors.identificationType = "Identification type is required";
        } else {
          bankerrors.identificationType = "";
        }
        break;

      case "identificationNumber":
        if (value === "") {
          bankerrors.identificationNumber = "Identification number is required";
        } else {
          bankerrors.identificationNumber = "";
        }
        break;

      case "accountEmail":
        if (value === "") {
          bankerrors.accountEmail = "Email id required";
        } else if (!validEmailRegex.test(value)) {
          bankerrors.accountEmail = "Invalid email id";
        } else {
          bankerrors.accountEmail = "";
        }
        break;

      case "vendorAddress":
        if (value === "") {
          bankerrors.vendorAddress = "Address is required";
          bankerrors.vendorCountry = "Country is required";
          bankerrors.vendorState =
            this.state.payoutType === "ACH" ? "State is required" : "";
        } else {
          bankerrors.vendorAddress = "";
          bankerrors.vendorCountry = "";
          bankerrors.vendorState = "";
        }
        break;

      case "bankAddress":
        if (value === "") {
          bankerrors.bankAddress = "Bank address is required";
          bankerrors.bankCountry = "Bank country is required";
          bankerrors.bankState =
            this.state.payoutType === "ACH" ? "State is required" : "";
        } else {
          bankerrors.bankAddress = "";
          bankerrors.bankCountry = "";
          bankerrors.bankState = "";
        }
        break;

      case "phoneNumber":
        bankerrors.phoneNumber = value != null && value.length >= 10 ? "" : "Minimum 10 digits";
        if (value === "") {
          bankerrors.phoneNumber = "Phone number required";
        }
        break;

      case "vendorZip":
        if (value === "") {
          bankerrors.vendorZip = "zip code required";
        } else {
          bankerrors.vendorZip = "";
        }
        break;

      case "bankZip":
        if (value === "") {
          bankerrors.bankZip = "Bank zip code required";
        } else {
          bankerrors.bankZip = "";
        }
        break;

      case "accountNumber":
        if (value === "") {
          bankerrors.accountNumber = "Account number required";
        } else {
          bankerrors.accountNumber = "";
        }
        break;

      case "vendorIBAN":
        if (value === "") {
          bankerrors.vendorIBAN = "IBAN is required";
        } else {
          bankerrors.vendorIBAN = "";
        }
        break;

      case "accountName":
        if (value === "") {
          bankerrors.accountName = "Account holder name required";
        } else {
          bankerrors.accountName = "";
        }
        break;

      case "bankId":
        if (value === "") {
          bankerrors.bankId = "Bank id Required";
        } else {
          bankerrors.bankId = "";
        }
        break;

      case "accountBankname":
        if (value === "") {
          bankerrors.accountBankname = "Bank name is Required";
        } else {
          bankerrors.accountBankname = "";
        }
        break;

      case "bankAccClass":
        if (value === "") {
          bankerrors.bankAccClass = "Bank account class is Required";
        } else {
          bankerrors.bankAccClass = "";
        }
        break;

      case "bankAccType":
        if (value === "") {
          bankerrors.bankAccType = "Bank account type is Required";
        } else {
          bankerrors.bankAccType = "";
        }
        break;

      default:
        break;
    }

    this.setState({ bankerrors, [name]: value }, () => {
      console.log(bankerrors);
    });
  };

  // ________________________________ VALIDATE FORM ____________________________________
  validateForm = (errors) => {
    console.log("I am here", errors);
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    console.log("inside validateForm", valid);
    return valid;
  };

  // _______________________________ WHEN CLICK ON SUBMIT WITHOUT ANY CHANGE ______________________________
  checkrequiredfields = () => {
    let valid = false;
    if (
      this.state.payoutType !== "" &&
      this.state.payoutCurrency !== "" &&
      this.state.vendorName !== "" &&
      this.state.vendorDOB !== "" &&
      this.state.identificationType !== "" &&
      this.state.identificationNumber !== "" &&
      this.state.phoneNumber !== "" &&
      this.state.vendorAddress !== "" &&
      this.state.bankAddress !== "" &&
      this.state.vendorZip !== "" &&
      this.state.bankZip !== "" &&
      (this.state.accountNumber !== "" || this.state.vendorIBAN !== "") &&
      this.state.accountName !== "" &&
      this.state.bankId !== "" &&
      this.state.accountBankname !== "" &&
      this.state.bankAccClass !== "" &&
      this.state.bankAccType !== "" &&
      this.state.accountEmail !== ""
      //&& this.state.country !== ''
    ) {
      valid = true;
      console.log("inside checkrequired field success", valid);
    } else {
      console.log("inside checkrequired fiedls else", this.state);
      this.handleBankError("payoutType", this.state.payoutType);
      this.handleBankError("payoutCurrency", this.state.payoutCurrency);
      this.handleBankError("vendorName", this.state.vendorName);
      this.handleBankError("vendorDOB", this.state.vendorDOB);
      this.handleBankError("phoneNumber", this.state.phoneNumber);
      this.handleBankError(
        "identificationNumber",
        this.state.identificationNumber
      );
      this.handleBankError("identificationType", this.state.identificationType);
      this.handleBankError("vendorAddress", this.state.vendorAddress);
      this.handleBankError("bankAddress", this.state.bankAddress);
      this.handleBankError("vendorZip", this.state.vendorZip);
      this.handleBankError("bankZip", this.state.bankZip);

      
      this.handleBankError("accountName", this.state.accountName);
      this.handleBankError("bankId", this.state.bankId);
      this.handleBankError("accountBankname", this.state.accountBankname);
      this.handleBankError("bankAccClass", this.state.bankAccClass);
      this.handleBankError("bankAccType", this.state.bankAccType);
      this.handleBankError("accountEmail", this.state.accountEmail);
      //this.handleBankError('country' ,this.state.country)
      
      this.state.bankAccClass === 'INTERNATIONAL' ? 
      this.handleBankError("vendorIBAN", this.state.vendorIBAN)
      :
      this.handleBankError("accountNumber", this.state.accountNumber);
    }

    return valid;
  };

  //____________________________ CHECK BEFORE UPDATE VENDOR REQUEST _______________________________
  checkBeforeUpdateVendorRequest = (event) => {
    event.preventDefault();
    if (this.validateForm(this.state.bankerrors)) {
      console.log("success validateForm");
      if (this.checkrequiredfields()) {
        console.log("Now you can do create vendor request", this.state)
        this.updateVendorRequest();
      }
    } else {
      console.error("Invalid Form");
      message.error("Please enter all required fields");
    }
  };

  // ___________________________________ UPDATE VENDOR REQUEST _____________________________________
  updateVendorRequest = async () => {
    const { vendorID } = this.props;
    let uID = JSON.parse(localStorage.getItem("userdata")).uid;

    let formData = {
      vendorName: this.state.vendorName,
      vendorAddress: this.state.vendorAddress,
      vendorCountry: this.state.vendorCountry,
      vendorState:
        this.state.vendorCountry === "US" || this.state.vendorCountry === "CA"
          ? this.state.vendorState
          : "",
      vendorCity: this.state.vendorCity,
      vendorZip: this.state.vendorZip,
      phoneNumber: this.state.phoneNumber,
      vendorDOB: this.state.vendorDOB,
      identificationType: this.state.identificationType,
      identificationNumber: this.state.identificationNumber,
      ac_number: this.state.accountNumber,
      vendorIBAN: this.state.vendorIBAN,
      ac_holder_name: this.state.accountName,
      bank_name: this.state.accountBankname,
      bank_id: this.state.bankId,
      accountEmail: this.state.accountEmail,
      bankAddress: this.state.bankAddress,
      bankCountry: this.state.bankCountry,
      bankState:
        this.state.vendorCountry === "US" || this.state.vendorCountry === "CA"
          ? this.state.bankState
          : "",
      bankCity: this.state.bankCity,
      bankZip: this.state.bankZip,
      bankAccType: this.state.bankAccType==='CURRENT'?"CHECKING":this.state.bankAccType ,
      bankAccClass: this.state.bankAccClass,
      payoutType: this.state.payoutType,
      payoutCurrency: this.state.payoutCurrency,

      minimalPayoutAmount: this.state.minimalPayoutAmount,
      uuid: uID,
      vendorID: vendorID,
      //dataAccurate: this.state.dataAccurate,
    };

    console.log("after submit", formData);
    this.setState({ saveLoader: true });

    axios
      .post("/bluesnap/updateKYC", formData)
      .then(async (response) => {
        //hideMessage();
        console.log(response);

        if (response.data.success) {
          this.setState({ saveLoader: false });
          message.success("Customer Details (KYC) updated successfully.");
          this.handleHideUpdateModal();
          this.props.fetchVendorId();
        } else {
          this.setState({
            saveLoader: false,
            alertMessage: response.data.message,
            alertVisible: true,
          });
          this.ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          //message.error("Error saving your account.");
        }
      })
      .catch((err) => {
        console.log(err)
        this.setState({
          saveLoader: false,
          alertMessage: [{description: "Something went wrong!"}],
          alertVisible: true,
        });
        this.ref.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        })
      });
  };

  render() {
    const { 
      showUpdateModal, 
      vendorName,
      payoutType,
      bankerrors,
      payoutCurrency,
      accountEmail,
      phoneNumber,
      vendorAddress,
      vendorCountry,
      vendorState,
      vendorCity,
      vendorZip,
      vendorDOB,
      identificationType,
      identificationNumber,
      accountNumber,
      accountName,
      accountBankname,
      bankId,
      vendorIBAN,
      bankAddress,
      bankCountry,
      bankState,
      bankCity,
      bankZip,
      bankAccType,
      bankAccClass,
      saveLoader,
      alertMessage,

    } = 
    this.state;
    return (
      <>
        <button onClick={this.handleShowUpdateModal} className="defaultBtn">
          Update Your Vendor Details
        </button>
        <Modal
          size="lg"
          show={showUpdateModal}
          onHide={this.handleHideUpdateModal}
          backdrop="static"
          style={{ color: "black" }}
        >
          <LoadingOverlay
                    active={this.state.overlayLoader}
                    // spinner={<BounceLoader />}
                    spinner={
                      <div>
                        <CustomImageLoader image={LGspinner} animationType={"pulse"} />
                        <br />
                      </div>
                    }
                    text=""
                  >
          <Form onSubmit={this.checkBeforeUpdateVendorRequest}>
          <Modal.Header closeButton ref={this.ref}>
            <Modal.Title>Update Vendor Details</Modal.Title>
          </Modal.Header>

          <Modal.Body>
          {this.state.alertVisible && alertMessage.map((messages)=> {
            return (
              <Alert
                message={messages.description}
                type="error"
                showIcon
                closable
                onClose={() => this.setState({ alertVisible: false })}
              />
            )
          }) }
            {/* payout type and currency info */}

            <Row>
              <Col xs={12} sm={6} md={6}>
                <Form.Group>
                  <Form.Label>
                    Payout Type <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  {/* <Form.Control
                      style={{ color: "black" }}
                      value={this.state.payoutType}
                      placeholder="ACH"
                      readOnly
                    /> */}
                  <Form.Control
                    as="select"
                    size="lg"
                    value={payoutType}
                    onChange={(e) =>
                      this.setvalueBankDetails(e.target.value, "payoutType")
                    }
                    style={{ color: "black" }}
                  >
                    <option value="">Select payout type</option>
                    <option value="ACH">ACH/ECP</option>
                    <option value="CHAPS">CHAPS</option>
                    <option value="FAST">FAST BANK TRANSFER</option>
                    <option value="WIRE">WIRE</option>
                  </Form.Control>
                  <small style={{ color: "red" }}>
                    {bankerrors.payoutType === ""
                      ? ""
                      : bankerrors.payoutType}
                  </small>
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={6}>
                <Form.Group>
                  <Form.Label>
                    Payout Currency <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    style={{ color: "black" }}
                    onChange={(e) =>
                      this.setvalueBankDetails(e.target.value, "payoutCurrency")
                    }
                    value={payoutCurrency}
                    placeholder="Payout currency"
                    disabled={payoutType !== "WIRE"}
                  >
                    <option value="">Select payout Currency</option>
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                    <option value="CAD">CAD</option>
                  </Form.Control>
                  <small style={{ color: "red" }}>
                    {bankerrors.payoutCurrency === ""
                      ? ""
                      : bankerrors.payoutCurrency}
                  </small>
                </Form.Group>
              </Col>
            </Row>

            {/* Personal information */}
            <Row>
              <Col sm={12} md={4}>
                <Form.Group controlId="formBasicVendorName">
                  <Form.Label>
                    Name <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      this.setvalueBankDetails(e.target.value, "vendorName")
                    }
                    style={{ color: "black" }}
                    type="text"
                    value={vendorName}
                    placeholder="Name"
                  />
                  <small style={{ color: "red" }}>
                    {bankerrors.vendorName === ""
                      ? ""
                      : bankerrors.vendorName}
                  </small>
                </Form.Group>
              </Col>
              <Col sm={6} md={4}>
                <Form.Group controlId="formBasicVendorEmail">
                  <Form.Label>
                    Email Address <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      this.setvalueBankDetails(e.target.value, "accountEmail")
                    }
                    style={{ color: "black" }}
                    type="text"
                    value={accountEmail}
                    placeholder="Email Id"
                  />
                  <small style={{ color: "red" }}>
                    {bankerrors.accountEmail === ""
                      ? ""
                      : bankerrors.accountEmail}
                  </small>
                </Form.Group>
              </Col>
              <Col sm={6} md={4}>
                <Form.Group controlId="formBasicVendorPhoneNumber">
                  <Form.Label>
                    Phone Number <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      this.setvalueBankDetails(e.target.value, "phoneNumber")
                    }
                    style={{ color: "black" }}
                    type="text"
                    value={phoneNumber}
                    placeholder="Phone number"
                  />
                  <small style={{ color: "red" }}>
                    {bankerrors.phoneNumber === ""
                      ? ""
                      : bankerrors.phoneNumber}
                  </small>
                </Form.Group>
              </Col>
            </Row>
            {/* Personal address information */}
            <Row>
                <Col xs={12} sm={8} md={8}>
                  <PlacesAutocomplete
                    value={vendorAddress}
                    onChange={this.handleAddressChange}
                    onSelect={this.handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <Form.Group>
                        <Form.Label>
                          Address <span style={{ color: "red" }}>*</span>
                          {payoutType === "ACH" ? (
                            <small style={{ color: "#9e9e9e" }}>
                              (Address must be from US)
                            </small>
                          ) : payoutType === "FAST" ||
                            payoutType === "CHAPS" ? (
                            <small style={{ color: "#9e9e9e" }}>
                              (Address must be from UK)
                            </small>
                          ) : payoutType === "WIRE" ? (
                            <small style={{ color: "#9e9e9e" }}>
                              (Address can be from UK/US or outside of UK/US)
                            </small>
                          ) : (
                            ""
                          )}
                        </Form.Label>
                        <Form.Control
                          style={{ color: "black" }}
                          type="text"
                          {...getInputProps({
                            placeholder: this.state.address
                              ? this.state.address
                              : // : pdata.location
                                // ? pdata.location
                                "Search Location ...",
                            className: "location-search-input",
                          })}
                          readOnly={payoutType === ""}
                        />

                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}

                          {suggestions.length > 0 && (
                            <div className="autocomplete-suggestion-box">
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#ccc",
                                      cursor: "pointer",
                                      borderBottom: "1px solid #abacad",
                                      paddingBottom: "2px",
                                    }
                                  : {
                                      cursor: "pointer",
                                      borderBottom: "1px solid #abacad",
                                      paddingBottom: "2px",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </Form.Group>
                    )}
                  </PlacesAutocomplete>
                  <small style={{ color: "red" }}>
                    {bankerrors.vendorAddress === ""
                      ? ""
                      : bankerrors.vendorAddress}
                  </small>
                </Col>
                <Col xs={12} sm={4} md={4}>
                  <Form.Group controlId="formBasicVendorCountry">
                    <Form.Label>
                      Country <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      style={{ color: "black" }}
                      type="text"
                      //value={this.state.country}
                      value={vendorCountry}
                      placeholder="Country code"
                      readOnly
                    />
                    <small style={{ color: "red" }}>
                      {bankerrors.vendorCountry === ""
                        ? ""
                        : bankerrors.vendorCountry}
                    </small>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {payoutType === "ACH" ? (
                  <Col sm={12} md={12}>
                    <Form.Group controlId="formGridState">
                      <Form.Label>
                        State <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        style={{ color: "black" }}
                        value={vendorState}
                        placeholder="State code"
                        readOnly
                      />
                      <small style={{ color: "red" }}>
                        {bankerrors.vendorState === ""
                          ? ""
                          : bankerrors.vendorState}
                      </small>
                    </Form.Group>
                  </Col>
                ) : (
                  ""
                )}

                <Col xs={12} sm={6} md={6}>
                  <Form.Group controlId="formGridCity">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      style={{ color: "black" }}
                      value={vendorCity}
                      onChange={(e) =>
                        this.setvalueBankDetails(e.target.value, "vendorCity")
                      }
                      placeholder="City / Street Name"
                      //readOnly
                    />
                  </Form.Group>
                </Col>

                <Col xs={12} sm={6} md={6}>
                  <Form.Group controlId="formGridZip">
                    <Form.Label>
                    Zip Code/ Postal Code <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      style={{ color: "black" }}
                      onChange={(e) =>
                        this.setvalueBankDetails(e.target.value, "vendorZip")
                      }
                      type="text"
                      placeholder="Zip Code/Postal Code"
                      value={vendorZip}
                    />
                    <small style={{ color: "red" }}>
                      {bankerrors.vendorZip === ""
                        ? ""
                        : bankerrors.vendorZip}
                    </small>
                  </Form.Group>
                </Col>
              </Row>

              {/* DATE OF BIRTH AND IDENTIFICATION NUMBER */}

              <Row>
                <Col sm={12} md={4}>
                  <Form.Group>
                    <Form.Label>
                      Date Of Birth <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <DatePicker
                      onChange={(date, dateString) =>
                        this.setvalueBankDetails(dateString, "vendorDOB")
                      }
                      value={
                        vendorDOB === ""
                          ? ""
                          : moment(vendorDOB, "DD-MM-YYYY")
                      }
                      format="DD-MM-YYYY"
                    />
                    <small style={{ color: "red" }}>
                      {bankerrors.vendorDOB === ""
                        ? ""
                        : bankerrors.vendorDOB}
                    </small>
                  </Form.Group>
                </Col>
                <Col sm={12} md={4}>
                  <Form.Group>
                    <Form.Label>Personal Identification Type</Form.Label>
                    <Form.Control
                      as="select"
                      size="lg"
                      style={{ color: "black" }}
                      value={identificationType}
                      onChange={(e) =>
                        this.setvalueBankDetails(
                          e.target.value,
                          "identificationType"
                        )
                      }
                      disabled={vendorAddress === ""}
                    >
                      <option value="">Select Identification type</option>
                      <option value="pin" disabled={vendorCountry !== "US"}>
                      Personal Identification Number
                      </option>
                      <option
                        value="passport"
                        disabled={vendorCountry === "US"}
                      >
                        Passport Number
                      </option>
                      <option
                        value="drivingLicense"
                        disabled={vendorCountry === "US"}
                      >
                        Driver License Number
                      </option>
                    </Form.Control>
                    <small style={{ color: "red" }}>
                      {bankerrors.identificationType === ""
                        ? ""
                        : bankerrors.identificationType}
                    </small>
                  </Form.Group>
                </Col>
                <Col sm={12} md={4}>
                  <Form.Group>
                    <Form.Label>
                      Identification Number{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      style={{ color: "black" }}
                      onChange={(e) =>
                        this.setvalueBankDetails(
                          e.target.value,
                          "identificationNumber"
                        )
                      }
                      type="text"
                      // value={
                      //   identificationType !== ""
                      //     ? identificationNumber
                      //     : ""
                      // }
                      value={identificationNumber}
                      readOnly={identificationType === ""}
                    />
                    <small style={{ color: "red" }}>
                      {bankerrors.identificationNumber === ""
                        ? ""
                        : bankerrors.identificationNumber}
                    </small>
                  </Form.Group>
                </Col>
              </Row>
              
              {/* BANK DETAILS */}

              <Row>
                <Col xs={12} sm={12} md={12}>
                  <Form.Group controlId="formBasicHolderName">
                    <Form.Label>
                      Account Holder Name{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        this.setvalueBankDetails(e.target.value, "accountName")
                      }
                      style={{ color: "black" }}
                      type="text"
                      placeholder="Name Of Account Holder"
                      value={accountName}
                    />
                    <small style={{ color: "red" }}>
                      {bankerrors.accountName === ""
                        ? ""
                        : bankerrors.accountName}
                    </small>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} md={6}>
                  <Form.Group controlId="formBasicBankName">
                    <Form.Label>
                      Bank Name <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        this.setvalueBankDetails(
                          e.target.value,
                          "accountBankname"
                        )
                      }
                      style={{ color: "black" }}
                      type="text"
                      placeholder="Name Of Bank"
                      value={accountBankname}
                    />

                    <small style={{ color: "red" }}>
                      {bankerrors.accountBankname === ""
                        ? ""
                        : bankerrors.accountBankname}
                    </small>
                  </Form.Group>
                </Col>

                <Col xs={12} sm={6} md={6}>
                  <Form.Group controlId="formBasicBankId">
                    <Form.Label>
                    Bank Id/ Sort Code <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        this.setvalueBankDetails(e.target.value, "bankId")
                      }
                      placeholder="Enter Bank Id/Sort Code"
                      style={{ color: "black" }}
                      type="text"
                      value={bankId}
                    />
                    <small style={{ color: "red" }}>
                      {bankerrors.bankId === ""
                        ? ""
                        : bankerrors.bankId}
                    </small>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={6} md={6}>
                  <Form.Group>
                    <Form.Label>
                      Bank Account Class <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      size="lg"
                      style={{ color: "black" }}
                      value={bankAccClass}
                      onChange={(e) =>
                        this.setvalueBankDetails(e.target.value, "bankAccClass")
                      }
                    >
                      <option value="">Select bank account class</option>
                      <option value="PERSONAL">PERSONAL</option>
                      <option value="CORPORATE">CORPORATE</option>
                      <option value="INTERNATIONAL">INTERNATIONAL</option>
                    </Form.Control>
                    <small style={{ color: "red" }}>
                      {bankerrors.bankAccClass === ""
                        ? ""
                        : bankerrors.bankAccClass}
                    </small>
                  </Form.Group>
                </Col>
                {bankAccClass === "INTERNATIONAL" ? (
                  <Col xs={12} sm={6} md={6}>
                    <Form.Group controlId="formBasicIban">
                      <Form.Label>
                        International Bank Account Number{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        onChange={(e) =>
                          this.setvalueBankDetails(e.target.value, "vendorIBAN")
                        }
                        placeholder="Enter International bank acc number"
                        style={{ color: "black" }}
                        type="text"
                        value={vendorIBAN}
                      />
                      <small style={{ color: "red" }}>
                        {bankerrors.vendorIBAN === ""
                          ? ""
                          : bankerrors.vendorIBAN}
                      </small>
                    </Form.Group>
                  </Col>
                ) : (
                  <Col xs={12} sm={6} md={6}>
                    <Form.Group controlId="formBasicAccountNumber">
                      <Form.Label>
                        Account Number <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        onChange={(e) =>
                          this.setvalueBankDetails(
                            e.target.value,
                            "accountNumber"
                          )
                        }
                        style={{ color: "black" }}
                        type="text"
                        value={accountNumber}
                        placeholder="Account Number"
                      />
                      <small style={{ color: "red" }}>
                        {bankerrors.accountNumber === ""
                          ? ""
                          : bankerrors.accountNumber}
                      </small>
                    </Form.Group>
                  </Col>
                )}
              </Row>

              <Row>
                <Col xs={12} sm={12} md={12}>
                  <Form.Group>
                    <Form.Label>
                      Account Type <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      size="lg"
                      style={{ color: "black" }}
                      value={bankAccType}
                      onChange={(e) =>
                        this.setvalueBankDetails(e.target.value, "bankAccType")
                      }
                    >
                      <option value="">Select Account Type</option>
                      <option value="CHECKING">CHECKING</option>
                      <option value="SAVINGS">SAVINGS</option>
                      <option value="CURRENT">CURRENT</option>
                    </Form.Control>
                    <small style={{ color: "red" }}>
                      {bankerrors.bankAccType === ""
                        ? ""
                        : bankerrors.bankAccType}
                    </small>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={6} md={8}>
                  <PlacesAutocomplete
                    value={bankAddress}
                    onChange={this.handleBankAddressChange}
                    onSelect={this.handleSelectBankAddress}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <Form.Group>
                        <Form.Label>
                          Bank Address <span style={{ color: "red" }}>*</span>{" "}
                          {payoutType === "ACH" ? (
                            <small style={{ color: "#9e9e9e" }}>
                              (Address must be from US)
                            </small>
                          ) : payoutType === "FAST" ||
                            payoutType === "CHAPS" ? (
                            <small style={{ color: "#9e9e9e" }}>
                              (Address must be from UK)
                            </small>
                          ) : payoutType === "WIRE" ? (
                            <small style={{ color: "#9e9e9e" }}>
                              (Address can be from UK/US or outside of UK/US)
                            </small>
                          ) : (
                            ""
                          )}
                        </Form.Label>
                        <Form.Control
                          style={{ color: "black" }}
                          type="text"
                          {...getInputProps({
                            placeholder: this.state.address
                              ? this.state.address
                              : // : pdata.location
                                // ? pdata.location
                                "Search Location ...",
                            className: "location-search-input",
                          })}
                          readOnly={payoutType === ""}
                        />

                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}

                          {suggestions.length > 0 && (
                            <div className="autocomplete-suggestion-box">
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#ccc",
                                      cursor: "pointer",
                                      borderBottom: "1px solid #abacad",
                                      paddingBottom: "2px",
                                    }
                                  : {
                                      cursor: "pointer",
                                      borderBottom: "1px solid #abacad",
                                      paddingBottom: "2px",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </Form.Group>
                    )}
                  </PlacesAutocomplete>
                  <small style={{ color: "red" }}>
                    {bankerrors.bankAddress === ""
                      ? ""
                      : bankerrors.bankAddress}
                  </small>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <Form.Group controlId="formBasicBankCountry">
                    <Form.Label>
                      Bank Country <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      style={{ color: "black" }}
                      type="text"
                      //value={this.state.country}
                      value={bankCountry}
                      placeholder="Country code"
                      readOnly
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {payoutType === "ACH" ? (
                  <Col xs={12} sm={12} md={12}>
                    <Form.Group controlId="formGridStateTwo">
                      <Form.Label>
                        Bank State <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        style={{ color: "black" }}
                        value={bankState}
                        placeholder="State code"
                        readOnly
                      />
                      <small style={{ color: "red" }}>
                        {bankerrors.bankState === ""
                          ? ""
                          : bankerrors.bankState}
                      </small>
                    </Form.Group>
                  </Col>
                ) : (
                  ""
                )}

                <Col xs={12} sm={6} md={6}>
                  <Form.Group controlId="formGridCityTwo">
                    <Form.Label>Bank City</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        this.setvalueBankDetails(e.target.value, "bankCity")
                      }
                      style={{ color: "black" }}
                      value={bankCity}
                      placeholder="City / Street Name"
                      //readOnly
                    />
                  </Form.Group>
                </Col>

                <Col xs={12} sm={6} md={6}>
                  <Form.Group controlId="formGridZipTwo">
                    <Form.Label>
                    Bank Zip/ Postal Code <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      style={{ color: "black" }}
                      onChange={(e) =>
                        this.setvalueBankDetails(e.target.value, "bankZip")
                      }
                      type="text"
                      placeholder="Bank Zip/Postal Code"
                      value={bankZip}
                    />
                    <small style={{ color: "red" }}>
                      {bankerrors.bankZip === ""
                        ? ""
                        : bankerrors.bankZip}
                    </small>
                  </Form.Group>
                </Col>
              </Row>
          </Modal.Body>
          <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                disabled={saveLoader}
              >
                {saveLoader ? (
                  <>
                    <Spinner
                      animation="border"
                      variant="light"
                      as="span"
                      size="sm"
                      aria-hidden="true"
                    />{" "}
                    Loading...
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
            </Modal.Footer>
          </Form>
          </LoadingOverlay>
        </Modal>
      </>
    );
  }
}

export default UpdateBankDetailsModal;
