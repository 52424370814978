import React from "react";
import { Link } from "react-router-dom";

function HeaderTop(props) {
    return (
        <section className="headerTopWrap corporate">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6">
                        <ul className="socialIcon" style={{"display":"none"}}>
                            <li>
                                <a href="/#">
                                    <i className="fa fa-facebook" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="/#">
                                    <i className="fa fa-twitter" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="/#">
                                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="/#">
                                    <i className="fa fa-pinterest-p" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="/#">
                                    <i className="fa fa-google-plus" aria-hidden="true"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-6">
                        <div className="topbarAction text-right">
                            {
                                localStorage.getItem("corporate_token") ? (localStorage.getItem("corporate_userdata") === null) ? "" : (
                                    <Link to="/dashboard">
                                        Welcome, {JSON.parse(localStorage.getItem("corporate_userdata")).firstname}
                                    </Link >
                                ) : localStorage.getItem("corporate_user_token") ? (!localStorage.getItem("corporate_user_userdata")) ? "" : (
                                        <Link to="/webinars">
                                        Welcome, {JSON.parse(localStorage.getItem("corporate_user_userdata")).firstname}
                                    </Link >
                                ) : (
                                    <>
                                        <Link  exact={true} to="/for-coaches">
                                        <i className="fa fa-id-badge" ></i> For Coaches
                                        </Link>
                                        <Link to="/employee-login"><i className="fa fa-user" ></i> Employee Login</Link>
                                        <Link to="/signin"><i className="fa fa-briefcase"></i> Company Login</Link>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
}
export default HeaderTop;