import React from "react";
import { Modal, Form, Row, Col, Button, ListGroup, Tab } from "react-bootstrap";

class SelectSkills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      open: false,

      skillOrange: [
        "Spirited",
        "Encouraging",
        "Friendly",
        "Positive",
        "optimistic",
        "warm",
      ],
      // skillRed's actual color is PURPLE
      skillRed: [
        "Considerate",
        "Empathetic",
        "Supportive",
        "Patient",
        "Calm",
        "Intuitive",
      ],
      // skillBlue's actual color is RED
      skillBlue: ["Direct", "No nonsense", "Firm", "Challenging", "Pushes"],
      // skillPurple actual color is BLUE
      skillPurple: ["Systematic", "Structured"],

      days: [],
      value: [],
    };
  }

  handleCollapse = () => {
    if (this.state.open === false) {
      this.setState({ open: true });
    } else {
      this.setState({ open: false });
    }
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  // handleChecked = (item) => {
  //   const arrayData = this.state.skillValueCollection;
  // };

  render() {
    const skillName = [];

    this.props.chartValue.length > 0 &&
      this.props.chartValue.map((item) => {
        return skillName.push(item.split("##")[2]);
      });

    return (
      <>
        <p
          className="popup-with-zoom-anim ml-auto mb-3 add_new_testi"
          onClick={this.handleShow}
        >
          <i
            className="fa fa-plus-square"
            aria-hidden="true"
            style={{ color: "green", fontSize: "28px" }}
            title="Add Coaching Style from Here"
          ></i>
        </p>

        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Form onSubmit={this.props.handleSubmitSkill}>
            <Modal.Header closeButton>
              <Modal.Title>Select Your Coaching Style</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tab.Container
                id="list-group-tabs-example"
                defaultActiveKey="#orange"
              >
                <Row style={{ paddingLeft: "15px" }}>
                  <small>
                    Select from the list below upto 4 words that best describe
                    your lead coaching style
                  </small>
                </Row>
                <br />
                <Row>
                  <Col sm={2}>
                    <ListGroup>
                      <ListGroup.Item
                        action
                        href="#orange"
                        style={{ backgroundColor: "#ff8533" }}
                      >
                        +
                      </ListGroup.Item>
                      <ListGroup.Item
                        action
                        href="#purple"
                        style={{ backgroundColor: "#a68ed4" }}
                      >
                        +
                      </ListGroup.Item>
                      <ListGroup.Item
                        action
                        href="#red"
                        style={{ backgroundColor: "#ff6666" }}
                      >
                        +
                      </ListGroup.Item>
                      <ListGroup.Item
                        action
                        href="#blue"
                        style={{ backgroundColor: "#1E90FF" }}
                      >
                        +
                      </ListGroup.Item>
                    </ListGroup>
                  </Col>
                  <Col>
                    <Tab.Content>
                      <Tab.Pane eventKey="#orange">
                        <ul className="list-group">
                          {this.state.skillOrange.map((opt, i) => (
                            <>
                              <li className="list-group-item" key={i}>
                                <Form.Check
                                  style={{ color: "black" }}
                                  type="checkbox"
                                  checked={
                                    skillName.indexOf(opt) >= 0 && "true"
                                  }
                                  id={`#ff8533##25##${opt}`}
                                  label={opt}
                                  name={`#ff8533##25##${opt}`}
                                  value={`#ff8533##25##${opt}`}
                                  onChange={this.props.handleChangeMultiSkills}
                                />
                              </li>
                            </>
                          ))}
                        </ul>
                      </Tab.Pane>
                      <Tab.Pane eventKey="#purple">
                        <ul className="list-group">
                          {this.state.skillRed.map((opt, i) => (
                            <>
                              <li className="list-group-item" key={i}>
                                <Form.Check
                                  style={{ color: "black" }}
                                  type="checkbox"
                                  id={`#a68ed4##25##${opt}`}
                                  checked={
                                    skillName.indexOf(opt) >= 0 && "true"
                                  }
                                  label={opt}
                                  name={`#a68ed4##25##${opt}`}
                                  value={`#a68ed4##25##${opt}`}
                                  onChange={this.props.handleChangeMultiSkills}
                                />
                              </li>{" "}
                            </>
                          ))}
                        </ul>
                      </Tab.Pane>

                      <Tab.Pane eventKey="#red">
                        <ul className="list-group">
                          {this.state.skillBlue.map((opt, i) => (
                            <>
                              <li className="list-group-item" key={i}>
                                <Form.Check
                                  style={{ color: "black" }}
                                  type="checkbox"
                                  checked={
                                    skillName.indexOf(opt) >= 0 && "true"
                                  }
                                  id={`#ff6666##25##${opt}`}
                                  label={opt}
                                  name={`#ff6666##25##${opt}`}
                                  value={`#ff6666##25##${opt}`}
                                  onChange={this.props.handleChangeMultiSkills}
                                />
                              </li>{" "}
                            </>
                          ))}
                        </ul>
                      </Tab.Pane>
                      <Tab.Pane eventKey="#blue">
                        <ul className="list-group">
                          {this.state.skillPurple.map((opt, i) => (
                            <>
                              <li className="list-group-item" key={i}>
                                <Form.Check
                                  style={{ color: "black" }}
                                  type="checkbox"
                                  checked={
                                    skillName.indexOf(opt) >= 0 && "true"
                                  }
                                  id={`#1E90FF##25##${opt}`}
                                  label={opt}
                                  name={`#1E90FF##25##${opt}`}
                                  value={`#1E90FF##25##${opt}`}
                                  onChange={this.props.handleChangeMultiSkills}
                                />
                              </li>{" "}
                            </>
                          ))}
                        </ul>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
                <br />
                <Row style={{ paddingLeft: "15px" }}>
                  {skillName.length > 0 ? (
                    skillName.map((e) => (
                      <>
                        <span className="badge badge-light">{e}</span>
                        &nbsp; &nbsp;
                      </>
                    ))
                  ) : (
                    <></>
                  )}
                </Row>
              </Tab.Container>
            </Modal.Body>
            <small style={{ paddingLeft: "15px" }}>
              Note: You can select maximum 4 coaching style
            </small>
            <Modal.Footer>
              <Button variant="primary" onClick={this.handleClose}>
                Close
              </Button>
              <Button
                variant="success"
                type="Submit"
                onClick={this.handleClose}
              >
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}
export default SelectSkills;
