import React from 'react'
import {Link} from 'react-router-dom'

class CoachSideBar extends React.Component{
    render(){
        return(
         <div>
          <ul>
                {/* <li
                      className={`${
                        this.props.renderSideBarComponent === "CreateSession"
                          ? "active"
                          : " "
                      }`}
                    >
                      <Link
                        onClick={() => this.props.handleSideBarComponent("CreateSession")}
                      >
                        <i  className="fa fa-tachometer" aria-hidden="true"></i>{" "}
                        Create Session
                      </Link>
                </li> */}
                <li>
                <Link to="/profile-coach-edit">
                        <i  className="fa fa-book" aria-hidden="true"></i>{" "}
                        My Profile
                      </Link>
                </li>
                <li
                      className={`${
                        this.props.renderSideBarComponent === "UpcomingSessions"
                          ? "active"
                          : " "
                      }`}
                    >
                      <Link
                        onClick={() => this.props.handleSideBarComponent("UpcomingSessions")}
                      >
                        <i  className="fa fa-book" aria-hidden="true"></i>{" "}
                        Upcoming Sessions
                      </Link>
                </li>

                <li
                      className={`${
                        this.props.renderSideBarComponent === "SetAvailability"
                          ? "active"
                          : " "
                      }`}
                    >
                      <Link
                        onClick={() => this.props.handleSideBarComponent("SetAvailability")}
                      >
                        <i  className="fa fa-book" aria-hidden="true"></i>{" "}
                        Set Availability
                      </Link>
                </li>

                <li
                      className={`${
                        this.props.renderSideBarComponent === "MySchedule"
                          ? "active"
                          : " "
                      }`}
                    >
                      <Link
                        onClick={() => this.props.handleSideBarComponent("MySchedule")}
                      >
                        <i  className="fa fa-book" aria-hidden="true"></i>{" "}
                        My Schedule
                      </Link>
                </li>

                <li
                      className={`${
                        this.props.renderSideBarComponent === "PaymentsReceived"
                          ? "active"
                          : " "
                      }`}
                    >
                      <Link
                        onClick={() => this.props.handleSideBarComponent("PaymentsReceived")}
                      >
                        <i  className="fa fa-credit-card" aria-hidden="true"></i>{" "}
                        Payments Received 
                      </Link>
                </li>

                <li
                      className={`${
                        this.props.renderSideBarComponent === "ManagePayments"
                          ? "active"
                          : " "
                      }`}
                    >
                      <Link
                        onClick={() => this.props.handleSideBarComponent("ManagePayments")}
                      >
                        <i  className="fa fa-history" aria-hidden="true"></i>{" "}
                        Payment Management
                      </Link>
                </li>

                <li
                      className={`${
                        this.props.renderSideBarComponent === "TransactionHistory"
                          ? "active"
                          : " "
                      }`}
                    >
                      <Link
                        onClick={() => this.props.handleSideBarComponent("TransactionHistory")}
                      >
                        <i  className="fa fa-calendar-check-o" aria-hidden="true"></i>{" "}
                        Transaction History 
                      </Link>
                </li>

                <li
                      className={`${
                        this.props.renderSideBarComponent === "JoinSession"
                          ? "active"
                          : " "
                      }`}
                    >
                      <Link
                        onClick={() => this.props.handleSideBarComponent("JoinSession")}
                      >
                        <i  className="fa fa-tachometer" aria-hidden="true"></i>{" "}
                        Join Session
                      </Link>
                </li>

          </ul>
         
        </div>
  
            
        )
    }
}

export default CoachSideBar