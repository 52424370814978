
import React from 'react'
import { teamSvg } from '../../../assets/images'
import UpcomingSessionCalender from '../../../components/calender/upcomingsessionCalender'
import axios from '../../../config/axios'
import message,{getHelpText} from '../../../utils/mesaages';
import validation from '../../../Validate/validator';
import { Package, UpdatePackageContent } from "../edit";
import { AddPackage, AddTag } from "../add";
import { Link } from "react-router-dom";
import { Tooltip } from 'antd';







class Packages extends React.Component{
  constructor(props){
    super(props)
    this.token = localStorage.getItem("authToken");
    this.state={
      packagescollection: [],
      packageCount: '',

      isPackageContent: "",
      packageContent: "",

      packages_help_text:'',
      Intromessage:'',

      title: "",
      description: "",
      price: "",
      isactive: "1",
      introDuration: "0", //HH:MM in MM
      duration: "0", //HH:MM in MM
    
      package_type: "",
      total_sessions: "",
      session_details: [],
      cost: "",
      sessionDescription: "",
      Length: "",
     
      packageName:"",
      packageUid:"",
      packPrice:"",

      sortingOrder: "",
      sorting_help_text: '',
      request_edit_help_text: '',     
     
     
      FromDataError: {
        package_type: 'false',
        title: 'false',
        description:'false',
        introDuration:'false',
        duration:'',
        cost:'false',
        price:'false',
        sortingOrder: 'false'
      },
    }
  }

  async componentDidMount(){
    this.getPackages();
    this.getPackageContent()
    let packages_help_text = await getHelpText('packages_text')
     this.setState({packages_help_text})
     let Intromessage = await getHelpText('intro_field_text')
     this.setState({Intromessage})
     //sorting order help text
     let sorting_help_text = await getHelpText('sorting_help_text')
     this.setState({sorting_help_text})
     let request_edit_help_text = await getHelpText('request_edit_help_text')
     this.setState({request_edit_help_text})
      
  }

  getPackages() {
    console.log('fetch public package')
    const id = this.props.coachUid;

    axios
      .get(`/users/packages/${id}`, {
        // headers: {
        //   "X-Auth": this.token,
        // },
      })
      .then(async(res) => {
        await this.setState({ packagescollection: res.data.value, packageCount: res.data.count[0].totalpackage });
        console.log("found new data" )
        console.log(res.data)
        console.log(this.state.packagescollection)
        this.packageCheck();
      })
      .catch((error) => {
        console.error(error);
      });
  }
  packageCheck() {
    if (this.state.packagescollection.length > 0) {
      this.props.packagefound();
    } else {
      this.props.packageNotfound();
    }
  }
  getPackageContent() {

    //console.log('jfjfjjfjfjfjjfjfjjfjfjjf content')
    axios
      .get("/users/packagecontent-coach", {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        console.log('hfhfhhfhhfhfhfhhsbbdnndhdhdfnnfnfppp ',res.data.value.content)
        if (res.data.success && res.data.value.content!=undefined) {
          const pacdata = res.data && res.data.value && res.data.value.content;
          this.setState({ isPackageContent: true, packageContent: pacdata });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

handleChange = async(e) => {
    e.persist();

    if(e.target.name == 'sortingOrder'){

      var numbers = /^[1-9][0-9]*$/;

      console.log("Yes I am here",e.target.value)
      if(numbers.test(e.target.value)){
        console.log("Yes correct")
        await this.setState({sortingOrder: e.target.value})
        console.log("sorting order value", this.state.sortingOrder)
      } else {
        console.log("not a number")
        await this.setState({sortingOrder: ''})
      }

    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }


    var valid_obj = {
      value: e.target.value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg")
    }
    let {FromDataError} =this.state


    validation(valid_obj).then((error) => {


    
      FromDataError[e.target.name] = error
      this.setState({ FromDataError })

    })


  };

  handlePackType = (e) => {
    e.persist();
    this.setState({
      package_type: e.target.value,
    });
    
    if(e.target.value=='Single Session')
    {
      let {FromDataError} = this.state
      FromDataError['duration'] = 'false'
      this.setState({FromDataError})
    }
    else
    {
      let {FromDataError} = this.state
      FromDataError['duration'] = ''
      this.setState({FromDataError})

    }

    if(e.target.value=='')
    {
      let {FromDataError} = this.state
      FromDataError['package_type'] = 'Select Session Type'
      this.setState({FromDataError})
    }
    else{
      let {FromDataError} = this.state
      FromDataError['package_type'] = ''
      this.setState({FromDataError})

    }
  };

  handlePackageContentSubmit = (e) => {
    const hideMessage = message.loading('Please wait while we are updating', 0)
    e.preventDefault();

    
    const formData = {
      content: this.state.packageContent,
    };

    let param;
    // if (this.state.isPackageContent) {
    //   param = {
    //     url: "/users/packages/packagecontent",
    //     method: "PUT",
    //     data: formData,
    //     headers: { "X-Auth": this.token },
    //   };
    // } else {
      param = {
        url: "/users/packages/packagecontent",
        method: "POST",
        data: formData,
        headers: {
          "X-Auth": this.token,
        },
      };
    //}

   console.log('hiiiiiiiiiiiii',param)

    axios(param)
      .then((response) => {
        hideMessage()
        console.log(response);
        if (response.data.success) {
          message.success("successfully updated");
        } else {
          message.error("can not updated");
        }
        this.getPackageContent();
        this.packageCheck();
      })
      .catch((error) => {
        hideMessage()
        message.error("Error while updating package");
        console.error(error);
      });
  };
  validateForm=(errors=this.state.FromDataError)=> {
    console.log('vvvvvvvvvvvvvvvvvvvvv')
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {

        if (value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }

        valid = false;
      }
    }

    return valid;
  };

  handleSubmitPackage = (e, data) => {
     e.preventDefault();
   console.log("package ---data---",data)
    // const durationMin =
    //   this.state.session_HH * 60 + parseInt(this.state.session_MM);

    if(this.validateForm(this.state.FromDataError))
    {
            var raw_session = JSON.stringify(data);

          var list_data = JSON.parse(raw_session);
          var total_session_time = 1;
          var introDuration = this.state.introDuration
          let total_session_count = 0;

          if (this.state.package_type === "Single Session") {
            var total_session_time = this.state.duration;
          
          } else if (this.state.package_type === "Multi Session") {
            var total_session_time = 0;

            if (list_data) {
              
              list_data.map(
                (time) =>
                  {total_session_time = total_session_time + parseInt(time.Length)
                    total_session_count++
                  }
              );
              this.setState({total_sessions:total_session_count})
              
            }
          } else {
            var total_session_time = this.state.duration;
          }

          let singleSessionObject=[{
            id:"0",
            sessionName:this.state.title,
            sessionDescription:this.state.description,
            Length:total_session_time
          }]

          const formData = {
            title: this.state.title,
            description: this.state.description,
            price: this.state.cost + "##" + this.state.price,
            introDuration: introDuration,
            duration: total_session_time,
            isactive: this.state.isactive,
            package_type: this.state.package_type,
            total_sessions: this.state.package_type === "Single Session" ? 1 : total_session_count,
            // session_details: JSON.stringify(data),
            session_details:this.state.package_type=== "Single Session"? (JSON.stringify(singleSessionObject)):(JSON.stringify(data)),
            sortingOrder: this.state.sortingOrder,
          };

          console.log("package-----formadata----",formData)
         // return ;
          const hideMessage = message.loading('Please wait while we are adding package', 0)

          axios
            .post("/users/packages", formData, {
              headers: {
                "X-Auth": this.token,
              },
            })
            .then((res) => {
              hideMessage()
              if (res.data.success) {
                message.success(res.data.msg);
                //for Tour section
                if(localStorage.getItem('tourActive')=='true')
                {
                  window.location.replace('/profile-coach')
                }
                //
              } else {
                message.error("Adding package failed");
              }
              this.getPackages();
              this.packageCheck();
            })
            .catch((error) => {
              message.error("Error while adding package");
              hideMessage()
              console.error(error);
            });

    }
    else
    {
      console.log('er',this.state.FromDataError)
      message.error("Please fill the form correctly");
      return false
    }

    
  };

  handleResetPackage=() =>{
    this.setState({
      title: "",
      description: "",
      price: "",
      package_type: "",
      introDuration: 0,
      duration:0,
      cost:"",
      total_sessions: "",
      session_details: [],
      sessionDescription:"",
      sortingOrder: "",
      FromDataError: {
        package_type: 'false',
        title: 'false',
        description:'false',
        introDuration:'false',
        duration:'',
        cost:'false',
        price:'false',
        sortingOrder: 'false'
      },
    })
  }

  handleEditPackage = (formData) => {
    const hideMessage = message.loading('Please wait while we are updating', 0)
    const id = formData.id;
    axios
      .put(`/users/packages/updatepackage/${id}`, formData, {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        hideMessage()
        if (res.data.success) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
        this.getPackages();
      })
      .catch((error) => {
        hideMessage()
        console.error(error);
        message.error("Failed! please try again later");
      });
  };

  handlePackageBook=(data)=>{
    this.setState({ packageName:data.packname,
      packPrice:data.packprice,
      packageUid:data.packUid})
  }
  handleDeletePackage = (id) => {
    const hideMessage = message.loading('Please wait while we are deleting package', 0)
    axios
      .delete(`/users/packages/updatepackage/${id}`, {
        headers: { "X-Auth": this.token },
      })
      .then((res) => {
        hideMessage()
        if (res.data.success) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
        this.getPackages();
      }).catch((error) => {
        hideMessage()
        console.log(error)
        message.error("Some error occured.")
      })
  };

  handleActivatePackageStatus = (id) => {
    const formData = {
      isactive: 1,
    };
    axios
      .put(`/users/packages/updatepackageStatus/${id}`, formData, {
        headers: { "X-Auth": this.token },
      })
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
        this.getPackages();
      });
  };

  handleInactivatePackageStatus = (id) => {
    const formData = {
      isactive: 0,
    };
    axios
      .put(`/users/packages/updatepackageStatus/${id}`, formData, {
        headers: { "X-Auth": this.token },
      })
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
        this.getPackages();
      });
  };


    render(){
      let {account_type}=this.props
      //console.log("sessionBooking----",this.state.sessionBookingDetails)
        return(
  <div>
      <section className="packagesWrap subPackage">
          <div className="">
            <div className="heading text-center">            
              <h3>Coaching Programs
{/* 
              {account_type === "coach" && (
                
            <Tooltip title={this.state.packages_help_text}> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>
              )} */}

              </h3>

              <div className="row justify-content-md-center">
                {this.props.check_account_type === "coach" ? (
                  <div className="col-12 ">
                    <p>
                      {this.state.packageContent
                        ? this.state.packageContent
                        : "Create Sessions, Packages & Programs here"}
                    </p>

                    {account_type === "coach" && (
                      <UpdatePackageContent
                        packageContent={this.state.packageContent}
                        packageshelptext={this.state.packages_help_text}
                        handleChange={this.handleChange}
                        handlePackageContentSubmit={
                          this.handlePackageContentSubmit
                        }
                      />
                    )}
                  </div>
                ) : (
                  // <div className="col-8">
                  //   <p>&nbsp;</p>
                  // </div>
                  ''
                )}
              </div>
              <span className="R_divider div_dark"></span>
            </div>
            {this.props.check_account_type === "coach" ? (
              <div className="col-12">
                <div className="row no-gutters tour-createpackages-addPackage">
                  {account_type === "coach" && (
                    <AddPackage
                      pacdata={this.state}
                      handleChange={this.handleChange}
                      handleSubmit={this.handleSubmitPackage}
                      handlePackType={this.handlePackType}
                      handleResetPackage={this.handleResetPackage}
                      sessionlength={this.props.sessionLength}
                      validateForm={this.validateForm}
                      Intromessage={this.state.Intromessage}
                      sortingHelpText={this.state.sorting_help_text}
                      totalPackageCount={this.state.packageCount}
                      //handlerSortingOrder={this.handlerSortingOrder}
                      //for Tour section
                      handleIncreaseTour={this.props.handleIncreaseTour}
                      hideTour={this.props.hideTour}
                      AddPackageModalTour={this.props.AddPackageModalTour}
                      handelAddPackageModalTour={this.props.handelAddPackageModalTour}
                      //
                    />
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="profilePakage">
              {/* {this.state.packagescollection &&
                this.state.packagescollection.length > 0 &&
                this.state.packagescollection.map((element) => ( */}
                  <Package
                    coachUid={this.props.uid}
                    coachImage={this.props.coachImage}
                    coachName={this.props.coachName}
                    coachData={this.props.coachData}
                    ConsumerD={this.props.ConsumerD}
                    account_type={this.props.account_type}
                    token={this.token}
                    packagescollection={this.state.packagescollection}
                    totalPackageCount={this.state.packageCount}
                    findcurrency={this.props.findcurrency}
                    convertToCurrency={this.props.convertToCurrency}
                    baseCurrency={this.props.baseCurrency}
                    handleEditPackage={this.handleEditPackage}
                    handleDeletePackage={this.handleDeletePackage}
                    handleActivatePackageStatus={
                      this.handleActivatePackageStatus
                    }
                    handleInactivatePackageStatus={
                      this.handleInactivatePackageStatus
                    }
                    handlePackageBook={this.handlePackageBook}
                    sessionlength={this.props.sessionLength}
                    sortingHelpText={this.state.sorting_help_text}
                    editPackageRequestHelpText={this.state.request_edit_help_text}
                    getPackages={this.getPackages}
                  />
                 {/* ))} */}
            </div>

            {this.state.packageCount > 5 ? (
              <div className="text-center mt-5">
                <Link
                  className="defaultBtn"
                  to={{
                    pathname: "/packages_list",
                    state: {
                      token: this.token,
                      coaching_category: this.props.coaching_category,
                      uid: this.props.userdata.uid,
                      account_type:this.props.account_type,
                      coachName:this.props.coachName,
                      coachUid:this.props.coachUid,
                      Consumer_uid:this.props.Consumer_uid,
                      coachData: this.props.coachData,
                      Consumer_name:this.props.Consumer_name,
                      Consumer_email:this.props.Consumer_email,
                      findcurrency:this.props.findcurrency,
                      convertToCurrency:this.props.convertToCurrency,
                      baseCurrency:this.props.baseCurrency,
                      coachData:this.props.coachData,
                      coachImage:this.props.coachImage,
                      coachName:this.props.coachName,
                      sessionlength:this.props.sessionLength,
                      totalPackageCount:this.state.packageCount,
                      sortingHelpText:this.state.sorting_help_text,
                      editPackageRequestHelpText:this.state.request_edit_help_text
                    },
                  }}
                >
                  View more Packages
                </Link>
              </div>
            ) : (
              <div></div>
            )}
          </div>


          {account_type !== "coach" && (<div>
                    {/* <BookPackage
                    packageName={this.state.packageName}
                    packageUid={this.state.packageUid}
                    packPrice={this.state.packPrice}
                    coachName={this.props.coachName}
                    coachUid={this.props.uid}
                    Consumer_uid={this.props.Consumer_uid}
                    Consumer_name={this.props.Consumer_name}
                    Consumer_email={this.props.Consumer_email}
                    check_account_type={this.props.check_account_type}
                    coachEmail={this.props.coachEmail}
                    coachImage={this.props.coachImage}/> */}
                    
                 </div>)}

          
        
        </section>

    
            </div>
        )
    }
}

export default Packages