import React, { useEffect } from "react";
import { Header, HeaderLogout, HeaderTop } from "../components/headers";
import { FooterLink } from "../components/footers";
import { useHistory } from 'react-router-dom';
import { message } from "antd";

const ThankYou = (props) => {
  const history = useHistory();
  const token = localStorage.getItem("authToken");
  const { userData,provider } = props.location.state;

  useEffect(() => {
    if (provider) {
      const hidemsg =  message.loading("Please wait, we are redirecting you to the dashboard.")
      setTimeout(function(){
        hidemsg();
        history.push('/webinars');
      },3000)
      
    }
  }, []);

  return (
    <React.Fragment>
      {token == null ? (
        <HeaderTop />
      ) : (
        <HeaderLogout />
      )}

      <Header />
      <section className="thankYouWrap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <h1 style={{ color: "#28a745" }}>Thank you for joining</h1>
            </div>
          </div>
          <div className="row justify-content-center">
            {userData.account_type === "jobseeker" ? (
              <div className="col-md-12 text-center">
                <p style={{ color: "#5a5a5a" }}>
                  You can now <a href="/login">login here</a> to access your account.
                  <br />
                  <b>Your user email and password have been sent to your inbox.</b> Please also check your junk mail.
                </p>
              </div>
            ) : (
              <div className="col-md-12 text-center">
                <p style={{ color: "#5a5a5a" }}>
                  Please check your inbox and spam folder to get the activation link.
                </p>
              </div>
            )}
          </div>
        </div>
      </section>

      <FooterLink />
    </React.Fragment>
  );
};

export default ThankYou;
