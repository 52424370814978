import React from "react";
import Select from 'react-select'
import axios from "../../../config/axios";
import  Changepasswordmodal from "../../../components/commonmodal/Changepasswordmodal";
import { toast } from "react-toastify";
import { Button, Modal, Form, Col } from "react-bootstrap";
import { writingSvg, closeSvg, editnew, mclose ,photograph, LGspinner} from "../../../assets/images";
import { Header, HeaderLogout, HeaderTop } from "../../../components/headers";
import { FooterLink } from "../../../components/footers";
import { avatar } from "../../../assets/img";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { Link, Redirect } from "react-router-dom";
import message,{getHelpText} from '../../../utils/mesaages';
import { Tooltip } from 'antd';
import validation from '../../../Validate/validator';
import {getClientTimezone,timezoneUtc,UTCtoTimezone} from '../../../utils/timeZoneConvert';
import ConfirmModal from "../../coach/view/AccDeleteConfirmModal";
import {SSO_UPDATE} from '../../../utils/Sso'
import PhoneInput from 'react-phone-input-2'
import CustomImageLoader from 'react-custom-image-loader.'
import {MyAutosuggest} from "../../../components/commonprofile"
import ConfirmModalProfilepic from '../../../components/commonmodal/ConfirmModalProfilepic'
import moment from "moment";
const _ = require("lodash");
// const ct = require("countries-and-timezones");

class UpdateUserPersonalnew extends React.Component {
    constructor() {
        super()
        this.token = localStorage.getItem("authToken");
        this.state = {
      show: false,
      setShow: false,
      address: "",
      location:"",
      country: "",
      city: "",
      timezone:"",
      tzOptions: [],
      stateAddress: "",

      name: "",
      user_title: "",
      Coaching_category: [],
      renderSubComponent: "UserDashboard",
      suggestedCoaches: [],
      user_uid:"",
      avatar_path: avatar,
      fname: "",
      lname: "",
      phone:'',
      email:'',
      alerttype:'',
      alertmessage:'',
      public_profile_text:'',
      public_profile_image_text:'',
      FromDataError :{
                    fname : 'false',
                    lname : 'false',
                    phone :'false',
                    coachingCategory:'false',
                    country:'false',      
                             
                    },
                    redirect: false,

    user_backbutton_text:'',            
    user_remove_acount:'',
    countryName: '',
    userImage:null,
      
    };
}


async componentDidMount() {
    let public_profile_text = await getHelpText('user_profile_help')
    this.setState({public_profile_text})
    let public_profile_image_text = await getHelpText('user_profile_image_help')
    this.setState({public_profile_image_text})
    let acc_delete_text = await getHelpText('acc_delete_text')
    this.setState({acc_delete_text})
    let user_backbutton_text = await getHelpText('user_backbutton_text')
    this.setState({user_backbutton_text})
    let user_remove_acount = await getHelpText('user_remove_acount')
    this.setState({user_remove_acount})

    this.fetchUserName();
    this.fetchUserData();
    this.getGeoInfo();
  }

  getGeoInfo = () => {
    axios.get('https://ipapi.co/json/').then((response) => {
        let data = response.data;
        //console.log('dataaaaaaaaaaa cuntry',data)
        this.setState({
            countryName: data.country.toLowerCase(),
        });
    }).catch((error) => {
        console.log(error);
    });
};

handleChangephone = (e, data, event, formattedValue) => {
  //console.log("dialCode",e,data, event,e.slice(data.dialCode.length))
  console.log("phone input value", e)
 let FromDataError = this.state.FromDataError
 
//  let totalLength=e.slice(data.dialCode.length)
//  console.log('phone no',e.length,totalLength)
//  let totalLength=data.format.replace(" ","")
//  totalLength=totalLength.replace("-","")
//  totalLength=totalLength.replace("+","")
//  totalLength=totalLength.split(" ").join("")
//  console.log('jjjgjg',totalLength,totalLength.length)
  if(e.length>=10)
  {
    console.log('true')
    FromDataError.phone=''
  }else{
    console.log('false')
    FromDataError.phone='Enter valid phone number'
  }

  this.setState({
    FromDataError
  });
  

  
   setTimeout(() => {
     this.setState({
       ['phone']: e,
     });
     
   }, 100);
   console.log("phone input value state", this.state.phone)
   
  
};



  fetchUserName() {
    let self = this;
    let FromDataError = this.state.FromDataError;
    let param = {
      url: "/users/username",
      method: "GET",
      headers: {
        "X-Auth": this.token,
      },
    };
    axios(param)
      .then(async (res) => {
        if (res.data.value) {
            this.setState({ loader: false });
          const rawdata = res.data.value;
          const fullname = rawdata.name.split(" ")
          
          const fname = fullname[0]!=undefined? fullname[0] : ''
          fullname.shift();
          const lname = fullname[0]!=undefined? fullname.join(" ") : ''
          const phone = rawdata.phone_number
         console.log('userdetails ',rawdata)
          self.setState({
            fname: fname, 
            lname: lname,
            email:rawdata.email,
            phone:rawdata.phone_number,
            user_title: rawdata.user_title,
            Coaching_category: rawdata.coaching_category ? JSON.parse(rawdata.coaching_category):"",
            Consumer_uid:rawdata.uid,
            //user_uid: rawdata.uid,
          });
          // FromDataError['fname'] = (fname != null)?'': 'false'
          // FromDataError['lname'] = (lname != null)?'': 'false'
          // FromDataError['phone'] = (phone != null)?'': 'false'
          // self.setState({FromDataError})
           //validating
              
           let {FromDataError} = this.state
           FromDataError.fname = (this.state.fname!='' && this.state.fname!=undefined)? '' : 'false'
           FromDataError.lname = (this.state.lname!='' && this.state.lname!=undefined)? '' : 'false'
           FromDataError.phone = (this.state.phone!='' && this.state.phone!=undefined)? '' : 'false'
           FromDataError.coachingCategory = (this.state.Coaching_category!='' && this.state.Coaching_category!=undefined)? '' : 'false'
           FromDataError.country = (this.state.address!='' && this.state.address!=undefined)? '' : 'false'
           self.setState({FromDataError})

       //validating

          this.handleSetCategory(this.state.Coaching_category);
         // this.getCoaches();
        }
      })
      .catch((error) => {
        this.setState({ loader: false });
        console.log(error);
        toast("Error getting User data", { type: "error" });
      });
  }

  fetchUserData() {
    let self = this;
    let param = {
      url: "/consumer/userprofile",
      method: "GET",
      headers: {
        "X-Auth": this.token,
      },
    };
    this.setState({ loader: true });
    axios(param)
      .then(async (res) => {
        this.setState({ loader: false });
        if (res.data.success) {
           
          const rawdata = res.data.value;
          const rawaddress = rawdata.address ? JSON.parse(rawdata.address) : "";
           console.log('rawaddress',rawaddress)
          self.setState({
            user_uid: rawdata.uid,
            country: rawaddress.country,
            location: rawaddress.location,
            stateAddress: rawaddress.state,
            city: rawaddress.city,
            address: rawaddress.location,
            timezone: rawdata.timezone,
            avatar_path:rawdata.avatar_path ? rawdata.avatar_path : avatar,
            userImage:rawdata.avatar_path
          });
          let {FromDataError} = this.state
         
          FromDataError.country = (this.state.address!='' && this.state.address!=undefined)? '' : 'false'
          self.setState({FromDataError})
          

        }
      })
      .catch((error) => {
        this.setState({ loader: false });
        console.log("fetch error");
        console.log(error);
        //toast("Error getting profile data", { type: "error" });
      });
  }


    handleClose = () => {
      this.setState({ show: false });
    };
    handleShow = () => {
      this.setState({ show: true });
    };
  
    // handleChange = (e) => {
    //   e.persist();
    //   this.setState({
    //     [e.target.name]: e.target.value,
    //   });
    // };
    handleChange = (e) => {
        e.persist();

        this.setState({
          [e.target.name]: e.target.value,
        });

        let { FromDataError } = this.state
        var valid_obj = {
          value: e.target.value,
          rules: e.target.getAttribute("validaterule"),
          message: e.target.getAttribute("validatemsg")
        }
    
        validation(valid_obj).then((error) => {
    
    
          console.log('aaaaaaaaaaa', error)
          FromDataError[e.target.name] = error
          this.setState({ FromDataError })
    
         
    
    
        })
       
           
    
         
    
    
    
    
    
    
      };

    handleAddressChange = (address) => {
        this.setState({ address });
        if(address!='' && address!=null)
          {
            
          }
          else
          {
            //validate
            let {FromDataError} = this.state
            FromDataError['country'] = 'This field is required'
            this.setState({FromDataError})

            //validate

          }
      };
      handleCategoryChange = (data) => {
        let {FromDataError} =this.state
        //console.log('ddddddddd ',this.state.Coaching_category)

        this.setState({ Coaching_category: data });


            if(data!=null)
            {
              
              FromDataError['coachingCategory'] = ''
              this.setState({ FromDataError });
            }
            else
            {
              FromDataError['coachingCategory'] = 'Please select coaching category'
              this.setState({ FromDataError });
            }
       
      };

      handleSelect = async (address) => {
        this.setState({ address, city: "", stateAddress: "" });
        var that = this;
        await geocodeByAddress(address)
          .then((results) => {
            _.map(results[0].address_components, async (eachComp) => {
              if (_.includes(eachComp.types, "country")) {
                const timezones = moment.tz.zonesForCountry(eachComp.short_name, true);
                const timezoneLocal = moment().tz(timezones[0].name).format('Z');
    
                let tzOptionsFormatted = _.map(timezones, (eachTZ) => {
                  const eachTZLocal = moment().tz(eachTZ.name).format('Z');
                  return eachTZ.name + " (GMT " + eachTZLocal + ")";
                });
    
                await that.setState({
                  country: eachComp.long_name,
                  tzOptions: tzOptionsFormatted,
                  timezone: timezones[0].name + " (GMT " + timezoneLocal+ ")",
                
                 } ,()=>{

                    if(eachComp.long_name!='')
                    {
                       //validate
                       let {FromDataError} = this.state
                       FromDataError['country'] = ''
                       this.setState({FromDataError})
      
                       //validate
      
                    }
                
                
                });
              }

              if (_.includes(eachComp.types, "administrative_area_level_2")) {
                //console.log("EACHCOMP.TYPES",timezones[0].name)
                await that.setState({
                  stateAddress: eachComp.long_name,              
                });
              }

              if (_.includes(eachComp.types, "administrative_area_level_1")) {
                //console.log("EACHCOMP.TYPES",timezones[0].name)
                if(this.state.stateAddress === "")
                {
                  await that.setState({
                    stateAddress: eachComp.long_name,              
                  });
                }
              }
    
              if (_.includes(eachComp.types, "locality") || _.includes(eachComp.types, "postal_town")) {
                console.log("setting city...");
                await that.setState({ city: eachComp.long_name });
              }

              if (_.includes(eachComp.types, "neighborhood")) {
                console.log("setting city...");
                if(this.state.city != "") {
                  await that.setState({ city: eachComp.long_name });
                }
              }
    
              return eachComp;
            });
          })
          .catch((error) => console.error("Error", error));
    
          this.handleAddressSelected(address, this.state.country, this.state.city);
      };
  
    handleAddressSelected = (address, country, city) => {
      this.setState({ country: country, city: city, location: address });
    };


    handleSetCategory = (value) => {
        this.setState({Coaching_category: value})
       // this.fetchUserName()
      }

      handleAlert =(alertType,alertMessage) => {

        console.log('set alert',alertType,'--',alertMessage)
        
        this.setState({alerttype:alertType,alertmessage:alertMessage});
        
    };

    onClose = () =>{
        this.setState({alerttype:'',alertmessage:''});
    }

    validateForm  (errors) {
        let valid = true;
        let sta = this;
        let FromDataError = this.state.FromDataError;
    
        for (const [key, value] of Object.entries(errors)) {
          if(value.length > 0 )
          {
    
            if(value=='false')
            {
              FromDataError[key] = 'This field is required';
              sta.setState({FromDataError})
            }
            
            valid = false;
          }
        }
      
        return valid;
    };

  handleSubmitProfileImage = (e) => {

    e.preventDefault();
    let inputType = e.target.files[0] != undefined ? e.target.files[0].type.split('/'): ''
    let filename = e.target.files[0].name.split('.')
    let ext = filename.pop().toLowerCase()
    if(inputType[0] != undefined && inputType[0] == 'image' && (ext == 'jpeg' || ext == 'jpg' || ext == 'png' )) {
      if(e.target.files[0].size>=4000000)
    {
      message.error('Please select file upto 4MB size')
    }
    else
    {
      

    
      console.log("I am here",e.target.files[0])
    //this.setState({ loader: true });
    const hideMessage = message.loading('Please wait while we are uploading image.', 0)
    const formData = new FormData();
    formData.append("avatar_path", e.target.files[0]);

    let param;
    if (this.state.user_uid !== "") {
        param = {
          url: "/consumer/profileimage",
          method: "PUT",
          data: formData,
          headers: {
            "X-Auth": this.token,
          },
        };
      } else {
        param = {
          url: "/consumer/profileimage",
          method: "POST",
          data: formData,
          headers: { "X-Auth": this.token },
        };
      }
      axios(param)
        .then((res) => {
          if (res.data.success) {
            this.fetchUserName();
            this.fetchUserData();
            hideMessage()
            message.success('Image uploaded');
          }
        })
        .catch((error) => {
          console.log(error);
          hideMessage()
         
          message.error('Error in updating image')
        });
    
    
    }
    } else {
      message.error('Please select correct file format upto 4 MB size')
    }
  };


  
    handleSubmit = (e) => {
      e.preventDefault();
     
      const fullName = this.state.fname + " " + this.state.lname
      const addressData = {
        country: this.state.country
          ? this.state.country
          : this.state.country,
          state: this.state.stateAddress
          ? this.state.stateAddress
          : this.state.stateAddress,
        city: this.state.city ? this.state.city : this.state.city,
        location: this.state.location
          ? this.state.location
          :this.state.location,
      };
      console.log("address----user--",addressData)
    let self = this
    if (this.validateForm(this.state.FromDataError)) 
    {
        this.setState({ loader: true });
      const formData = {
        name: fullName,
        email:this.state.email,
        phone_number:this.state.phone,
        address: JSON.stringify(addressData),
        uid: this.state.user_uid ? this.state.user_uid : "",
        timezone: this.state.timezone
          ? this.state.timezone
          : this.state.timezone,
          coaching_category: this.state.Coaching_category
      };
      console.log("formdata----",formData)
      console.log("I am here")
     // return
      let param;
      param = {
        url: "/consumer/userprofile",
        method: "POST",
        data: formData,
        headers: {
          "X-Auth": this.token,
        },
      };
      const hideMessage = message.loading('Please wait, while we are uploading details.', 0)

      console.log("param", param)
  
      axios(param)
        .then(async(res) => {
          console.log("response from apii", res)
          hideMessage()
          self.setState({ loader: false });
          await SSO_UPDATE({fname:this.state.fname,lname:this.state.lname,phone:this.state.phone})

          if(res.data.success) {
            message.success('Profile Details Successfully Updated');
            getClientTimezone()
          }
          else {
            message.error("Something went wrong!")
          }

          // if (res.statusText) {
          //   console.log("res.statusText", res.statusText)
          //  // this.props.updatePersonal();
          // }          
        })
        .catch((error) => {
          hideMessage()
          console.log("update error");
          console.log(error);
          self.setState({ loader: false });
          message.error('Error in updating details')
        });

    }
    else
    {
      message.error("Please fill the form correctly");
    }

    };

    handleClick = (e) => {
      //console.log("Yes clicked", this.token)
  
      //e.preventDefault()
      axios.post(`/users/requestdeleteaccount`,{amount: "hello"},{
        headers:{"x-auth":this.token}
      }).then((res) => {
        console.log("### response",res)
        if(res.data.success) {
          localStorage.clear();
          this.setState({redirect: true})
          // this.props.history.push({
          //   pathname: "/login",
          //   token: "",
          // });
          message.success("Your request for removing account is in progress")
        }
      }).catch(() => {
        message.error("Some error occured")
      })
      
    }

    handleStateCity = async (id, newValue) => {
      console.log(`${id} changed to ${newValue}`);
      if(id == "state-input"){
        await this.setState({
          stateAddress: newValue
      });
      }
      else if(id == "city-input"){
        await this.setState({
          city: newValue
      });
      }
      console.log("state value", this.state.stateAddress)
      console.log("city value", this.state.city)
    }


    handleDeleteprofilePic = (e) => {
      //console.log("Yes clicked", this.token)
      let imageUrl = this.state.avatar_path
  
      //e.preventDefault()
      axios.post(`/users/delete-profilepicuser`,{imageUrl},{
        headers:{"x-auth":this.token}
      }).then((res) => {
        console.log("### response",res)
        if(res.data.success) {
        this.fetchUserData()
        message.success("Your profile image deleted successfully");
        }
        else {
          message.error("Error while deleteing profile image")
        }
      }).catch((err) => {
        message.error("Some error occured")
      })
      
    }

  
    render() {
        var aletClass ="alert alert-"+this.state.alerttype+" alert-dismissible fade show";
        const options = [
            { value: 'Career Coaching', label: 'Career Coaching'},
            { value: 'Executive Coaching', label: 'Executive Coaching' },
            { value: 'Business Coaching', label: 'Business Coaching'  },
            { value: 'Life Coaching', label: 'Life Coaching'},
            { value: 'Transformational Coaching', label: 'Transformational Coaching' },
            { value: 'Health & Wellbeing Coaching', label: 'Health & Wellbeing Coaching'}
          ];
         
          const styles = {
            
            control: (base)=>{
              return { ...base, background: "#fff"}
            }
          };
          const { redirect } = this.state;

          if (redirect) {
            return <Redirect to='/login'/>;
          }
     // const pdata = this.props.pdata;
      return (
        <React.Fragment>
             {localStorage.getItem("authToken") == null ? (
            <HeaderTop />
            ) : (
            <HeaderLogout
            currencies={this.state.currencies}
            convertToCurrency={this.state.convertToCurrency}
            changeConvertToCurrency={this.changeConvertToCurrency}
            />
            )}

            <Header />  
          {/* <div style={{ position: "absolute", right: "20px", top: "0" }}>
            <span
              to="profile-consumer"
              className="addTag"
              data-toggle="tooltip"
              data-placement="top"
              title="Update you Profile"
              onClick={this.handleShow}
            >
              <img width="25px" alt="LG-Img" src={editnew} />
            </span>
          </div> */}
  
         <section className="coachEditWrap">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8 col-lg-7">
                <LoadingOverlay        
                  active={this.state.loader}
                  //spinner={<BounceLoader />}
                  spinner={<CustomImageLoader image={LGspinner} animationType={'pulse'}/>}
                >
                <div className="coachProfileFormEdit">
            <Form >
              
           

              {/* <div className="heading text-center">
                <h3 className="text-capitalize">Update Personal Details</h3>                
                <span className="R_divider"></span>
              </div> */}
              <div className="col-12">
                        <div className="coachImg mb-4">
                          <img id="item-img-output" src={this.state.avatar_path} className="img-fluid"/>
                          <div className="picEdit">
                            {/* <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Edit your Picture">
                              <img  width="35px" src={photograph} alt="LG-Img"/>
                              <input type="file" className="item-img file center-block" name="file_photo"/>
                            </a> */}
                            {/* <UpdateImage
                            avatar={this.state.avatar}
                            userImage2={this.state.avatar_path}
                            onChangeHandler={this.onChangeHandler}
                            handleSubmitProfileImage={this.handleSubmitProfileImage}/> */}
                            
                            <span  style={{cursor: 'pointer'}}><Tooltip title={this.state.public_profile_image_text} placement="right"> <img width="35px" alt="LG-Img" src={photograph} />

                           
                            <input
                            className="item-img file center-block"
                            type="file"
                            label="Select Image file"
                            name="avatar_path"
                            onChange={this.handleSubmitProfileImage}
                            accept=".jpg,.jpeg,.png"
                            />
                              </Tooltip>
                            </span>
                            {this.state.userImage !=null ? (
                            <ConfirmModalProfilepic
                              fun_name={"Delete"}
                              handleDeleteprofilePic={this.handleDeleteprofilePic}
                              infoText="Are you sure you want to delete your profile image"
                              //helpText={this.state.coach_remove_acount}
                             
                              />

                              ) : ''}

                          </div>
                          
                        </div>
                        {/* <div>
                        <Tooltip title={this.state.public_profile_image_text}> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>
                        </div> */}
                      </div>

                     
                      <h4>Personal Details
            <Tooltip title={this.state.public_profile_text} placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>

                        </h4>
                        <hr/>           
                {/* <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    style={{color:"black"}}
                    type="name"
                    placeholder="Name"
                    defaultValue={this.state.name}
                    onChange={this.handleChange}
                    name="name"
                  />
                </Form.Group> */}
               <Form.Row>
                 <div className="col-md-6">
                        <div className="form-group">
                          <label>First Name</label>
                        <input type="text" 
                        style={{color:"black"}}
                        name="fname" 
                        defaultValue={this.state.fname}
                        onChange={this.handleChange} 
                        placeholder="First name" 
                        className="form-control"
                        validaterule={['required']}
                        validatemsg={['Enter your first name']}
                        />
                        </div>
                        <p style={{ color: 'red' }}>{this.state.FromDataError.fname=='false'?'': this.state.FromDataError.fname}</p>
                      </div>
                            {/* last name section */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Last Name</label>
                        <input type="text" 
                        style={{color:"black"}}
                        name="lname" 
                        //defaultValue={this.state.lname}
                        value={this.state.lname}
                        onChange={this.handleChange} 
                        placeholder="Last name" 
                        className="form-control"
                        validaterule={['required']}
                        validatemsg={['Enter your last name']}
                        
                        />
                        </div>
                        <p style={{ color: 'red' }}>{this.state.FromDataError.lname=='false'?'': this.state.FromDataError.lname}</p>
                    </div>
                </Form.Row> 
                <Form.Row> 
                <div className="col-md-6">
                        <div className="form-group">
                          <label>Email</label>
                        
                          <Form.Control
                          style={{color:"black"}}
                          // placeholder="Add a title"
                          value={this.state.email}
                          // onChange={this.handleChange}
                          readOnly={true}
                          name="email" 
                      />
                      
                          </div>
                      </div>
          
                      <div className="col-md-6">
                        <div className="form-group" style={{color:'black'}}>
                          <label>Phone</label>
                          {/* <Form.Control
                            style={{color:"black"}}
                            type="text"
                            placeholder="Phone NO."
                            value={this.state.phone}
                            onChange={this.handleChange}
                            name="phone"
                            maxLength="15"
                            validaterule={['required','phone','numeric']}
                            validatemsg={['This field accept digits only','Phone no must be between 10 to 15 digit']}
                           
                          /> */}
                          <PhoneInput
                         style={{color:'black'}}
                         name="phone"
                         value={this.state.phone}
                         required={true}
                         //country={this.state.countryName}
                         countryCodeEditable={false}
                         inputClass="phone-reg"
                         placeholder="Phone Number"
                         onChange={this.handleChangephone}
                         validaterule={['required','phone']}
                         validatemsg={['Phone number is required','Please enter a valid phone number']}
                       />
                          </div>
                          <p style={{ color: 'red' }}>{this.state.FromDataError.phone=='false'? '' : this.state.FromDataError.phone }</p>

                      </div>
                </Form.Row> 
                <Form.Group>
                <Form.Label>Select categories you would like coaching in</Form.Label>
                <Select   style={{color:"black"}} className="languageSelect" placeholder='Select Category' isMulti 
                
                value={this.state.Coaching_category} 

                onChange={this.handleCategoryChange} 
                options={options}
                styles={styles} />
                 <p style={{ color: 'red' }}>{this.state.FromDataError.coachingCategory == 'false' ? '' : this.state.FromDataError.coachingCategory}</p>
              </Form.Group>
  
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleAddressChange}
                onSelect={this.handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <Form.Group>
                    <Form.Label>Location</Form.Label>
                    <Form.Control
                      style={{color:"black"}}
                      type="text"
                      {...getInputProps({
                        placeholder: this.state.address
                          ? this.state.address
                          // : this.state.location
                          // ? this.state.location
                          : "Search Location ...",
                        className: "location-search-input",
                      })}
                    />

                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}

                      {suggestions.length > 0 && (
                        <div className="autocomplete-suggestion-box">
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#ccc",
                                  cursor: "pointer",
                                  borderBottom: "1px solid #abacad",
                                  paddingBottom: "2px",
                                }
                              : {
                                  cursor: "pointer",
                                  borderBottom: "1px solid #abacad",
                                  paddingBottom: "2px",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </Form.Group>
                )}
              </PlacesAutocomplete>
              <p style={{ color: 'red' }}>{this.state.FromDataError.country == 'false' ? '' : this.state.FromDataError.country}</p>
              
  
              <Form.Row>
                <Col md={4} lg={4} sm={12}>
                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Form.Control 
                   style={{color:"black"}}
                   type="text" placeholder="Country" 
                   value={this.state.country ? this.state.country : this.state.country}
                   onChange={this.handleChange}
                   disabled="true"
                     name="country" 
                   />

                </Form.Group>
                </Col>
                <Col md={4} lg={4} sm={12}>
                <Form.Group>
                  <Form.Label>State/Province</Form.Label>
                  {/* <Form.Control
                    style={{color:"black"}}
                    type="text"
                    placeholder="State"
                    value={this.state.city ? this.state.stateAddress : this.state.stateAddress}
                    onChange={this.handleChange}
                    name="city"
                    disabled="true"
                  /> */}
                  <MyAutosuggest
                    id="state-input"
                    placeholder="State"
                    handleStateCity={this.handleStateCity}
                    defaultFieldValue={this.state.stateAddress}
                  />
                </Form.Group>
                </Col>

                <Col md={4} lg={4} sm={12}> 
                <Form.Group>
                  <Form.Label>City</Form.Label>
                  {/* <Form.Control
                    style={{color:"black"}}
                    type="text"
                    placeholder="City"
                    value={this.state.city ? this.state.city : this.state.city}
                    onChange={this.handleChange}
                    name="city"
                    disabled="true"
                  /> */}
                  <MyAutosuggest
                    id="city-input"
                    placeholder="City"
                    handleStateCity={this.handleStateCity}
                    defaultFieldValue={this.state.city}
                  />
                </Form.Group>
                </Col>
                
              </Form.Row>              

              <Form.Group>
                  <Form.Label></Form.Label>
                  <Form.Control as="select"
                    style={{color:"black"}}
                    className="form-control"
                    id="timezone-offset"
                    value={this.state.timezone}
                    onChange={this.handleChange}
                    name="timezone"
                  > 
                        <option value={this.state.timezone ? this.state.timezone : ""}>
                        {this.state.timezone ? this.state.timezone : ""}
                        </option>

                        {this.state.tzOptions &&
                        this.state.tzOptions.map((tz) => {
                            return <option value={tz}>{tz}</option>;
                        })}
                    
                    <span>
                        <img src="images/dArrowblack.png" alt="" />
                    </span>
                    
                  </Form.Control>
              </Form.Group>
              </Form>
  
              <div className="mt-3 btn_set_left">
                <button className="defaultBtn mr-2" type="button" variant="success"
                   onClick={this.handleSubmit}
                 >Save</button>
                  <Link to="/profile-consumer">
                  <Tooltip title={this.state.user_backbutton_text} placement="right">
                        <button className="defaultBtn test" onClick={this.handleBack}>Exit</button></Tooltip>
                 </Link>
                 
                       
                        <Changepasswordmodal />
                 <ConfirmModal
                    fun_name={"Delete"}
                    icon={"fa fa-trash"}
                    //pack_id={this.props.packagedata.uid}
                    handleClick={this.handleClick}
                    infoText={this.state.acc_delete_text}
                    helpText={this.state.user_remove_acount}
                  />
                  <div className="clearfix"></div>

              </div>

            
                 </div> 
                 </LoadingOverlay>      
                </div>
              </div>
            </div>
          </section>

            <FooterLink />

        </React.Fragment>
              
              
       
      );
    }
  }
  
  export default UpdateUserPersonalnew;