import React from "react";
import Select from "react-select";
import axios from "../../../config/axios";
import { toast } from "react-toastify";
import { Button, Modal, Form, Col, } from "react-bootstrap";
import { writingSvg, closeSvg, photograph, deleteSvg, documentSvg } from "../../../assets/images";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { Link } from "react-router-dom";
import { avatar } from "../../../assets/img";
import UpdateImage from "./UpdateImage";
import { Header, HeaderLogout, HeaderTop } from "../../../components/headers";
import { FooterLink } from "../../../components/footers";
import { ListModal } from "../../../components/resourcefilemodal";
import MyLoader from "../../../components/loader/loader"
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
const _ = require("lodash");
const ct = require("countries-and-timezones");


class UpdatePersonal extends React.Component {
  constructor() {
      super()
      this.token = localStorage.getItem("authToken");

      if (localStorage.getItem("user")) {
        this.userdata = JSON.parse(localStorage.getItem("user"));
      }
  
  
      else if (localStorage.getItem("userdata")) {
        this.userdata = JSON.parse(localStorage.getItem("userdata"));
      }
  
  
      else {
        // #temprary if page will refereshed by mistake
        this.userdata = {
          account_type: "user",
          uid: "44abb6bc-8361-4a56-a88a-3b4c8b10eea2",
        };
      }


      this.state = {
        freeTrialDaysleft: "",
        renderSubComponent: "Home",
        avatar: "",
        avatar_path: avatar,
        name: "",
        coach_title: "",
        user_title: "",
        // coach_category: "",
        // coaching_category: "",
        city: "",
        email: "",
        country: "",
        location: "",
        userImage: "",
        coach_uid: "",
        short_bio: "",
        long_bio: "",
        videofile: "",
        tags: "",
        skills: "",
        isLoaded: "",
        coachprofile_id: "",
        check_account_type: "",
        // dounat chart value using this___start
        chartValue: [],
        labels_data: [],
        data_data: [],
        color_data: [],
        // dounat chart value using this____end
        // coaching_category_list: [],
        language: [{ value: "English", label: "English" }],
        timezone: "",
        HoursofExp: "",
        HourlyRate: "",
        membership: 0,
        alertMsg: "",
        baseCurrency: "USD",
        convertToCurrency: "USD",
        baseAmount: 100,
        rates: [],
        currencies: [],
        findcurrency: 1,
        totalprofileupdate: "",
        tag_update_profile: 0,
        avatar_path_update_profile: 0,
        user_title_update_profile: 0,
        short_bio_update_profile: 0,
        packages_update_profile: 0,
        coachingCategory: null,
        coachUid: "",
        coachEmail: "",
        Consumer_name: "",
        Consumer_email: "",
        SocialMediaInfo: [{ SocialMedia: "", URL: "" }],
        //
        show: false,
        setShow: false,
        address: "",
        city: "",
        tzOptions: [],
        canwrite: false,
        check: 0,
        options: [],
        CertificateType:"",
        certificate_path: null,
        certificates: [],
        loader: false,
      };
  }

  componentDidMount() {
    this.profileUpdateStatus();
    this.fetchUserName();
    this.fetchCoachData();
    this.getBioData();
  }

//   componentDidUpdate(prevProps) {
//     if(prevProps.pdata !== this.props.pdata) {
//       this.setState({
//             SocialMediaInfo:this.props.pdata.SocialMediaInfo,
           
//       });

//     }
//   }

  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };

  handleCheck = () => {
    if (this.state.check === 1) {
      this.handleClose();
      this.setState({check:0})
    }
  };

  handleCheckbox = () => {
    if(this.state.check === 0) {
      this.setState({ check: 1 });
    } else {
      this.setState({ check: 0 });
    }
  };
  handleBack = () => {
    this.setState({ check: 1 });
  };

  handleCheckboxMembership = (e) => {
    if (e.target.value == 0) {
      this.setState({ membership: 1 });
    } else if (e.target.value == 1) {
      this.setState({ membership: 0 });
    }
  };


  onCero = async (e) => {
    if (e.key === "Delete" || e.key === "Backspace") {
      await this.setState({ canwrite: true });
    } else {
      await this.setState({ canwrite: false });
    }
  };

  handleAddressChange = (address) => {
    this.setState({ address });
  };
  handleSelect = async (address) => {
    this.setState({ address });
    var that = this;
    await geocodeByAddress(address)
      .then((results) => {
        _.map(results[0].address_components, async (eachComp) => {
          if (_.includes(eachComp.types, "country")) {
            const timezones = ct.getTimezonesForCountry(eachComp.short_name);
            //console.log("EACHCOMP.TYPES",timezones[0].name)
            const timezoneLocal = ct.getTimezone(timezones[0].name);
            let tzOptionsFormatted = _.map(timezones, (eachTZ) => {
              return eachTZ.name + " (GMT " + eachTZ.utcOffsetStr + ")";
            });

            await that.setState({
              country: eachComp.long_name,
              tzOptions: tzOptionsFormatted,
              timezone: timezoneLocal.name + " (GMT " + timezoneLocal.utcOffsetStr + ")",
            });
          }

          if (_.includes(eachComp.types, "locality")) {
            console.log("setting city...");
            await that.setState({ city: eachComp.long_name });
          }

          return eachComp;
        });
      })
      .catch((error) => console.error("Error", error));

    this.handleAddressSelected(
      address,
      this.state.country,
      this.state.city,
      this.state.timezone
    );
  };
  handleAddressSelected = (address, country, city, timezone) => {
    this.setState({ country: country, city: city, address: address, timezone: timezone });
  };

  handleLanguageChange = (e, option) => {
    if (option.removedValue && option.removedValue.isFixed) return;
    this.handleLanguageChangeF(e);
  };

  handleLanguageChangeF = (language) => {
    this.setState({ language: language });
  };

  handleSubmit=(e)=>{
    let Data=this.state.SocialMediaInfo
    if(this.state.check) {
      this.handleSubmitPersonal(e,Data)
    }
    else {
      toast("Please Select the checkbox", {
        type: "error",
      });
    }
    //this.handleClose()
    
  }

  handleSubmitPersonal = (e, Data) => {
    this.setState({ loader: true });
    console.log("Data-----9", Data)
    e.preventDefault()
    if (this.state.short_bio && this.state.short_bio.split(" ").length > 50) {
      toast("Word limit exceeded for short bio", {
        type: "error",
      });
    } else {
      const addressData = {
        country: this.state.country,
        city: this.state.city,
        location: this.state.location,
      };

      const formData = {
        user_title: this.state.user_title,
        name: this.state.name,
        coaching_category: this.state.coachingCategory,
        Uid: this.state.coachprofile_id,

        address: JSON.stringify(addressData),
        short_bio: this.state.short_bio,
        coach_uid: this.state.coach_uid,
        long_bio: this.state.long_bio,
        skills: JSON.stringify(this.state.chartValue),
        language: this.state.language,
        timezone: this.state.timezone,
        HoursofExp: this.state.HoursofExp,
        HourlyRate: this.state.HourlyRate,
        membership: this.state.membership,
        tags: this.state.tags,
        SocialMediaInfo: Data !== undefined ? Data : this.state.SocialMediaInfo
      }

      axios.post('/users/coachprofile-edit', formData, {
        headers: { 'x-auth': this.token }
      })
        .then(res => {
          if (res.data.success) {
            const rawdata = res.data.profilePost;
            this.setState({
              short_bio: rawdata.short_bio,
              SocialMedia: JSON.parse(rawdata.social_media)
            })

            toast("Sucessfully updated profile details", { type: "success" });
            this.setState({ loader: false });

          }
          this.setState({
            short_bio_update_profile: 0,
          });

          if (this.state.short_bio.length > 5) {
            this.setState({
              short_bio_update_profile: 20,
            });
          }

          this.setState({
            user_title_update_profile: 0,
          });
          if (this.state.user_title && this.state.user_title.length > 0) {
            this.setState({
              user_title_update_profile: 20,
            });
          }

          this.profileUpdateStatus();
          console.log("---------personal--res------", res)
        })
        .catch((error) => {
          console.log(error);
          toast("Error in updating details", {
            type: "error",
          });
          this.setState({ loader: false });
        });
    }
  }

  //_______ Certificate upload & view & delete _________

  handleCertificatechange=(e)=>{
    e.persist()
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  onChangeHandler = (e) => {
    this.setState({
      certificate_path: e.target.files[0],
      loaded: 0,
    });
    console.log(e.target.files[0]);
  };

  getBioData() {
    const id=this.userdata.uid
    axios
      .get(`/users/certificate/${id}`, {
        headers: { "x-auth": this.token },
      })
      .then((res) => {
        console.log("certificate", res);
        if (res.data.msg === "success") {
          const data = res.data.value;
          this.setState({ certificates: data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onClickHandler = (e) => {
    this.setState({ loader: true });
    e.preventDefault();
    let formData = new FormData();
    formData.append("certificate_path", this.state.certificate_path);
    formData.append("CertificateType", this.state.CertificateType);

    console.log("certificate --formdata",formData)
    axios
      .post("/users/certificate", formData, {
        headers: { "x-auth": this.token },
      })
      .then((res) => {
        if (res.data.success) {
          // toast("Successfully added file", { type: "success" });
          toast("Successfully added file, Waiting for admin approval", { type: "success" });
          this.getBioData();
          this.setState({ loader: false });
        } else {
          this.setState({ loader: false });
          toast("Certificate Upload failed", { type: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
        toast("Some error occured. Please try again later.", { type: "error" });
        this.setState({ loader: false });
      });
  };

  handleDeleteCertificate = (id) => {
    this.setState({ loader: true });
    console.log("clicked certi--", id);
    axios
      .delete(`/users/certificate/${id}`, {
        headers: { "x-auth": this.token },
      })
      .then((res) => {
        if (res.data.success) {
          toast("Successfully deleted file", { type: "success" });
          this.setState({ loader: false });
        } else {
          toast("Certificate delete failed", { type: "error" });
          this.setState({ loader: false });
        }
        this.getBioData();
      })
      .catch((err) => {
        console.log(err);
        toast("Some error occured. Please try again later.", { type: "error" });
        this.setState({ loader: false });
      });
  };

  //_______ Profile Image upload _________

  // onChangeHandler = (e) => {
  //     console.log("I am here")
  //   this.setState({
  //     avatar_path_temp: e.target.files[0],
  //     loaded: 0,
  //   });
  // };

  handleSubmitProfileImage = (e) => {
      console.log("I am here",e.target.files[0])
    this.setState({ loader: true });
    e.preventDefault();
    const formData = new FormData();
    formData.append("avatar_path", e.target.files[0]);

    let param;
    if (this.state.coachprofile_id !== "") {
      formData["uid"] = this.state.coachprofile_id;
      param = {
        url: "/users/profileimage",
        method: "PUT",
        data: formData,
        headers: {
          "X-Auth": this.token, //this.props.location.state.token,
          "Content-type": "multipart/form-data",
        },
      };
    } else {
      param = {
        url: "/users/profileimage",
        method: "POST",
        data: formData,
        headers: {
          "X-Auth": this.token, //this.props.location.state.token,
          "Content-type": "multipart/form-data",
        },
      };
    }

        axios(param)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            avatar_path: response.data.profileImagePost.avatar_path,
            loader: false,
            avatar_path_temp: null,
            avatar_path_update_profile: 20,
          });
          toast("Image updated", { type: "success" });
          this.profileUpdateStatus();
          this.fetchCoachData();
        }
      })
      .catch((error) => {
        console.log("photo update error");
        console.log(error);
        toast("Error in updaing profile image", { type: "error" });
        this.setState({
          loader: false,
        });
      });
  };

  handleInputChange = (e, index,state) => {
    const { name, value } = e.target;
    const list = [...state];
    list[index][name] = value;
    this.setState({SocialMediaInfo: list });
  
  };

  handleChange = (e) => {
    e.persist();
    if (e.target.name === "short_bio") {
      let limit = 50 - parseInt(e.target.value.split(" ").length);
      let ro = e.target.value.split(" ").length > 50 ? true : false;
      let msg =
        e.target.value.split(" ").length > 50
          ? "Short bio word limit exceeded"
          : "";
      this.setState({ wordcount: limit, alertMsg: msg, readonly: ro });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCategoryChange = (data) => {
    this.setState({ coachingCategory: data });
  };

  //____________________________Fetch data_______________________________

  fetchUserName() {
    let self = this;
    let param = {
      url: "/users/username",
      method: "GET",
      headers: {
        "X-Auth": this.token,
      },
    };
    axios(param)
      .then(async (res) => {
        if (res.data.value) {
          const rawdata = res.data.value;
          self.setState({
            name: rawdata.name,
            // coaching_category: rawdata.coaching_category,
            user_title: rawdata.user_title,
            coachingCategory: rawdata.coaching_category
              ? JSON.parse(rawdata.coaching_category)
              : "",
          });
          this.setState({
            user_title_update_profile: 0,
          });
          if (this.state.user_title && this.state.user_title.length > 0) {
            this.setState({
              user_title_update_profile: 20,
            });
          }
          this.profileUpdateStatus();
        }
      })
      .catch((error) => {
        console.log(error);
        toast("Error getting User data", { type: "error" });
      });
  }

  fetchCoachData() {
    //this.setState({loader: true});
    const id = this.userdata.uid;

    let param = {
      url: `/users/coachprofile/${id}`,
      method: "GET",
      headers: {
        "X-Auth": this.token
      }
    }
    axios(param)
      .then(async (res) => {
        console.log("-------------------------------------------res-socail-------------", res.data.value)
        if (res.data.value) {
          const rawdata = res.data.value;
          // const rawaddress = rawdata.address ? JSON.parse(rawdata.address) : "";
          const rawskills = rawdata.skills ? JSON.parse(rawdata.skills) : "";
          this.setState({ chartValue: rawskills });

          if (rawskills.length > 1) {
            await this.setState({
              chartValue: rawskills,
              isLoaded: true,
            });
          } else {
            this.setState({ isLoaded: false });
          }
          this.updateStateData(rawdata);
          this.setState({loader: false});

          if (this.state) {
            if (this.state.avatar_path !== avatar) {
              this.setState({ avatar_path_update_profile: 20 });
            }
            if (this.state.short_bio.length > 2) {
              this.setState({ short_bio_update_profile: 20 });
            }

            // if (this.state.short_bio.length > 2) {
            //   this.setState({ packages_update_profile: 20 });
            // }

            if (this.state.tags.length > 2) {
              this.setState({ tag_update_profile: 20 });
            }
          }

          this.profileUpdateStatus();
          this.setState({loader: false});
          //this.dountChartUpdated();
        }
      })
      .catch((error) => {
        console.log("fetch error");
        console.log(error);
        this.setState({loader: false});
        // toast("Error getting profile data", { type: "error" });
      });
  }

  updateStateData = (rawdata) => {
    const rawaddress = rawdata.address ? JSON.parse(rawdata.address) : "";
    // const rawskills = rawdata.skills ? JSON.parse(rawdata.skills) : "";

    this.setState({
      userImage: rawdata.avatar_path,
      coach_uid: rawdata.coach_uid,
      short_bio: rawdata.short_bio,
      // long_bio: rawdata.long_bio,
      long_bio:
        rawdata.long_bio !== undefined ? rawdata.long_bio : "loading...",

      videofile: rawdata.profile_video,
      avatar_path: rawdata.avatar_path ? rawdata.avatar_path : avatar,
      country: rawaddress.country,
      location: rawaddress.location,
      city: rawaddress.city,
      coachprofile_id: rawdata.uid,
      tags: rawdata.tags,
      skills: rawdata.skills ? JSON.parse(rawdata.skills) : "",
      // tag_update_profile: 0,
      // avatar_path_update_profile: 0,
      // short_bio_update_profile: 0,
      // packages_update_profile: 0,

      language: rawdata.language ? JSON.parse(rawdata.language) : "",
      timezone: rawdata.timezone,
      HoursofExp: rawdata.hours_of_experience,
      HourlyRate: rawdata.hourly_rate,
      membership: rawdata.has_membership,
      wordcount:
        rawdata.short_bio && parseInt(rawdata.short_bio.split(" ").length),
      SocialMediaInfo: rawdata.social_media !== null ? (JSON.parse(rawdata.social_media)) : ([{ SocialMedia: "", URL: "" }])
    });
  };

  profileUpdateStatus() {
    this.setState({ totalprofileupdate: 0 });
    const totalProfile =
      parseInt(this.state.avatar_path_update_profile) +
      parseInt(this.state.user_title_update_profile) +
      parseInt(this.state.short_bio_update_profile) +
      parseInt(this.state.tag_update_profile) +
      parseInt(this.state.packages_update_profile);
    this.setState({ totalprofileupdate: totalProfile });
    this.totalProfileUpdateStatus()
  }

  totalProfileUpdateStatus() {
    const id = this.userdata.uid;
    let formData = {percentData: this.state.totalprofileupdate}

    let param = {
      url: `/users/complete-status/${id}`,
      method: "POST",
      data: formData,
      headers: {
        "X-Auth": this.token
      }
    }
    axios(param)
    .then((response) => {
      //toast("Coaching style added successfully", { type: "success" });
      console.log("response")
    })
    .catch((error) => {
      console.log(error);
      //toast("Error in updating", { type: "error" });
    });
  }

  render() {
    //console.log("this.props.pdata.name-------",this.state.SocialMediaInfo)

    const Categoryoptions = [
      { value: 'Career Coaching', label: 'Career Coaching'},
      { value: 'Executive Coaching', label: 'Executive Coaching' },
      { value: 'Business Coaching', label: 'Business Coaching'  },
      { value: 'Life Coaching', label: 'Life Coaching'},
      { value: 'Transformational Coaching', label: 'Transformational Coaching' },
      { value: 'Health & Wellbeing Coaching', label: 'Health & Wellbeing Coaching'}
    ];

    const options = [
      { value: "English", label: "English", isFixed: true },
      { value: "Spanish", label: "Spanish", isFixed: false },
      { value: "French", label: "French", isFixed: false },
      { value: "Portigues", label: "Portigues", isFixed: false },
      { value: "Hindi", label: "Hindi", isFixed: false },
      { value: "Russian", label: "Russian", isFixed: false },
      { value: "Germans", label: "Germans", isFixed: false },
    ];
    // const { language } = this.props.pdata;
    const styles = {
      multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
      },
      multiValueLabel: (base, state) => {
        return state.data.isFixed
          ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
          : base;
      },
      multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: "none" } : base;
      },
      control: (base) => {
        return {
          ...base,
          background: "#fff"
        };
      },
    };
    const pdata = this.state;
    return (
      <React.Fragment>
            {localStorage.getItem("authToken") == null ? (
            <HeaderTop />
            ) : (
            <HeaderLogout
            currencies={this.state.currencies}
            convertToCurrency={this.state.convertToCurrency}
            changeConvertToCurrency={this.changeConvertToCurrency}
            />
            )}

            <Header />        
          <section className="coachEditWrap">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-7">
                {/* <MyLoader active={this.state.loaderforprofileimage}/> */}
                <LoadingOverlay        
                  active={this.state.loader}
                  spinner={<BounceLoader />}
                >
                  <div className="coachProfileFormEdit">
                    <form >
                     <div className="row">
                      <div className="col-12">
                        <div className="coachImg mb-4">
                          <img id="item-img-output" src={this.state.avatar_path} className="img-fluid"/>
                          <div className="picEdit">
                            {/* <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Edit your Picture">
                              <img  width="35px" src={photograph} alt="LG-Img"/>
                              <input type="file" className="item-img file center-block" name="file_photo"/>
                            </a> */}
                            {/* <UpdateImage
                            avatar={this.state.avatar}
                            userImage2={this.state.avatar_path}
                            onChangeHandler={this.onChangeHandler}
                            handleSubmitProfileImage={this.handleSubmitProfileImage}/> */}
                            <a
                            href="javascript:void(0);"
                            data-toggle="tooltip"
                            title="Edit your Picture"
                            >
                            <img width="35px" alt="LG-Img" src={photograph} />
                            <input
                            className="item-img file center-block"
                            type="file"
                            label="Select Image file"
                            name="avatar_path"
                            onChange={this.handleSubmitProfileImage}
                            />
                            </a>
                          </div>
                        </div>
                      </div>
          
                      <div className="col-12">
                        <h4>Personal Information</h4>
                        <hr/>
                      </div>
          
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>First Name</label>
                        <input type="text" 
                        style={{color:"black"}}
                        name="name" 
                        defaultValue={this.state.name}
                        onChange={this.handleChange} 
                        placeholder="Name" 
                        className="form-control"/>
                        </div>
                      </div>
          
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Coach Title</label>
                        <input type="text"
                          style={{color:"black"}}
                          placeholder="Add a title"
                          value={this.state.user_title}
                          onChange={this.handleChange}
                          name="user_title" 
                          className="form-control"/>
                        </div>
                      </div>
          
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Select Coach categories</label>
                          <Select  className="languageSelect" placeholder='Select Category' isMulti 
                          value={this.state.coachingCategory} 
                          onChange={this.handleCategoryChange} 
                          options={Categoryoptions}
                          styles={styles} />
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label>Last Name</label>
                        <input type="text" name="" value="Holmes" placeholder="Last Name" className="form-control"/>
                        </div>
                      </div> 
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Email</label>
                        <input type="email" name="" value="example@example.com" placeholder="Email" className="form-control"/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Password</label>
                        <input type="password" name="" value="*********" placeholder="Password" className="form-control"/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Phone No</label>
                        <input type="tel" name="" value="477-046-1827" placeholder="Phone No" className="form-control"/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Year of Birth</label>
                          <div className="selectBox searchFilter">
                            <select className="selectpicker"  data-live-search="true">
                              <option>Nothing Selected</option>
                              <option>2020</option>
                              <option>2019</option>
                              <option>2018</option>
                              <option>2017</option>
                              <option>2016</option>
                              <option>2015</option>
                              <option>2014</option>
                              <option>2013</option>
                              <option>2012</option>
                              <option>2011</option>
                              <option>2010</option>
                              <option>2009</option>
                              <option>2008</option>
                              <option>2007</option>
                              <option>2006</option>
                            </select>
                            <span>
                                <img src="images/dArrowblack.png" alt=""/>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                        <label>Gender</label>
                        <div className="selectBox searchFilter">
                            <select className="selectpicker"  data-live-search="true">
                              <option>Nothing Selected</option>
                              <option>Male</option>
                              <option>Female</option>
                              <option>Prefer not to answer</option>
                            </select>
                            <span>
                                <img src="images/dArrowblack.png" alt=""/>
                            </span>
                          </div>
                        </div>
                      </div> */}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Language</label>
                          <div className="selectBox searchFilter">
                          <Select
                            className="languageSelect"
                            placeholder="Select language"
                            isMulti
                            value={pdata.language}
                            onChange={this.handleLanguageChange}
                            isClearable={!this.state.options.some((opt) => opt.isFixed)}
                            options={options}
                            styles={styles}
                          />
                            </div>
                          </div>
                      </div>
          
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Short Bio (Word limit {pdata.wordcount} )</label>
                          <label style={{ color: "red" }}>
                            {this.state.alertMsg}
                          </label>
                          <Form.Control
                            style={{color:"black"}}
                            as="textarea"
                            rows="6"
                            value={pdata.short_bio}
                            onChange={this.handleChange}
                            name="short_bio"
                            // readOnly={pdata.readonly}
                            onKeyDown={(e) => this.onCero(e)}
                            readOnly={
                              this.state.canwrite
                                ? false
                                : pdata.short_bio && pdata.short_bio.split(" ").length > 50
                                ? true
                                : false
                            }
                          />
                          </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Hours of coaching experience</label>
                          <div className="selectBox searchFilter">
                          <select
                            style={{color:"black"}}
                            className="form-control"
                            value={pdata.HoursofExp}
                            onChange={this.handleChange}
                            name="HoursofExp"
                          >
                            <option
                              defaultValue={pdata.HoursofExp ? pdata.HoursofExp : 0}
                            >
                              {pdata.HoursofExp ? pdata.HoursofExp : 0}
                            </option>
                            <option value="0-100hrs">0-100hrs</option>
                            <option value="100hrs+">100hrs+</option>
                            <option value="500hrs+">500hrs+</option>
                            <option value="2,500hrs+">2,500hrs+</option>
                          </select>
                            </div>
                          </div>
                      </div>
          
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Hourly Rate ($)</label>
                          <Form.Control
                            style={{color:"black"}}
                            type="text"
                            placeholder="Hourly Rate"
                            value={pdata.HourlyRate}
                            onChange={this.handleChange}
                            name="HourlyRate"
                          />
                          </div>
                      </div>
          
                      <div className="col-md-12">
                        <div className="form-group">
                          <label style={{ marginRight: "1.2rem" }}>
                            Membership of Professional Organizations</label>
                          <input
                              type="checkbox"
                              defaultChecked={pdata.membership=== 1 ? true : false}
                              value={pdata.membership}
                              onClick={this.handleCheckboxMembership}
                            />
                          </div>
                      </div>
          
                      <div className="col-12">
                        <h4>Address Information</h4>
                        <hr/>
                      </div>
          
                      <div className="col-md-12">
                      <div className="form-group">
                      <PlacesAutocomplete
                          value={this.state.address}
                          onChange={this.handleAddressChange}
                          onSelect={this.handleSelect}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <Form.Group>
                              <Form.Label>Location</Form.Label>
                              <Form.Control
                                style={{color:"black"}}
                                type="text"
                                {...getInputProps({
                                  placeholder: this.state.address
                                    ? this.state.address
                                    : pdata.location
                                    ? pdata.location
                                    : "Search Location ...",
                                  className: "location-search-input",
                                })}
                              />
          
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
          
                                {suggestions.length > 0 && (
                                  <div className="autocomplete-suggestion-box">
                                    {suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: "#ccc",
                                            cursor: "pointer",
                                            borderBottom: "1px solid #abacad",
                                            paddingBottom: "2px",
                                          }
                                        : {
                                            cursor: "pointer",
                                            borderBottom: "1px solid #abacad",
                                            paddingBottom: "2px",
                                          };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                          })}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            </Form.Group>
                          )}
                        </PlacesAutocomplete>
                        
                      </div>
                      </div>
          
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Country</label>
                          <Form.Control
                              style={{color:"black"}}
                              type="text"
                              placeholder="Country"
                              value={
                                this.state.country ? this.state.country : pdata.country
                              }
                              disabled="true"
                              name="country"
                            />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>City</label>
                          <Form.Control
                              style={{color:"black"}}
                              type="text"
                              placeholder="City"
                              value={this.state.city ? this.state.city : pdata.city}
                              name="city"
                            />
                        </div>
                      </div>
          
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Timezone</label>
                          <div className="selectBox">
                            <select
                              style={{color:"black"}}
                              className="form-control"
                              id="timezone-offset"
                              // style={{ color: "white" }}
                              value={pdata.timezone}
                              onChange={this.handleChange}
                              name="timezone"
                            >
                              <option defaultValue={pdata.timezone ? pdata.timezone : ""}>
                                {pdata.timezone ? pdata.timezone : ""}
                              </option>
          
                              {this.state.tzOptions &&
                                this.state.tzOptions.map((tz) => {
                                  return <option value={tz}>{tz}</option>;
                                })}
                            </select>
                            <span>
                              <img src="images/dArrowblack.png" alt="" />
                            </span>
                          </div>
                        </div>
                      </div>
          
          
                      {/* <div className="col-md-12">
                        <div className="form-group">
                          <label>Address</label>
                          <textarea name="" placeholder="" rows="3" type="text" className="form-control">116 Jaskolski Stravenue Suito 883</textarea> 
                        </div>
                      </div> */}
          
          
          
          
                      {/* <div className="col-12">
                        <h4>Social Media Information</h4>
                        <hr/>
                         <label>Social Media</label>
                         <div className="availabilityTimeItem">
              <h5 style={{display:"inline",marginRight:"20px"}}>
              
                {this.state.SocialMediaInfo.map((x, i) => {
                            return (
                              <>
                                {this.state.SocialMediaInfo.length - 1 === i && (
                                  <button
                                    className="defaultBtn"
                                    style={{
                                      color: "green",
                                      fontSize: "15px",
                                      float: "right",
                                    }}
                                   
                                    onClick={() =>{
                                      this.setState(prevState => ({ 
                                            SocialMediaInfo: [...prevState.SocialMediaInfo,{SocialMedia:"",URL: ""}]
                                          }))
                                    }}
                                  > Add </button>
                                )}
                              </>
                            );
                          })}
              
                      </h5>
                        <hr/>
                        <div id="content"></div>
          
                        {this.state.SocialMediaInfo.map((x,i) =>(
                         
                         <div className="row">
          
                          <div className="col-sm-4">
                            <div className="form-group">
                              <input type="text" 
                              style={{color:"black"}}
                              placeholder="Social Media"
                              name="SocialMedia" 
                              value={x.SocialMedia} 
                              onChange={(e) => this.handleInputChange(e, i,this.state.SocialMediaInfo)}
                              className="form-control"/>
                            </div>
                          </div>
                          <div className="col-sm-5 col-9">
                            <div className="form-group">
                              <input type="text"
                              style={{color:"black"}} 
                              placeholder="Social Media URL"
                              name="URL" 
                              value={x.URL}
                              onChange={(e) => this.handleInputChange(e, i,this.state.SocialMediaInfo)} 
                              className="form-control"/>
                            </div>
                          </div>
                          <div className="col-sm-2 col-3">
          
                             {this.state.SocialMediaInfo.length > 1 && (
                                  <button
                                    className="defaultBtn"
                                    style={{
                                      color:"green",
                                      fontSize:"15px",
                                      
                                    }}
                                    onClick={() =>{
                                        let SocialMediaInfo = [...this.state.SocialMediaInfo];
                                        SocialMediaInfo.splice(i, 1);
                                        this.setState({ SocialMediaInfo });
                                    }}
                                  >Remove</button>
                                )}             
          
                            </div>
                        </div>
          
                        ))}
                   </div>
                      </div> */}
                      <div className="col-md-12">
                        <h4>Submit Training & Insurance Certifications <a className="text-dark" data-toggle="tooltip" title="To be listed on LifeGuru as a coach we will need to see your training certifications and certificate of insurance. Once verified your profile will go live on the LifeGuru platform." href="#"><i className="fa fa-info-circle" aria-hidden="true"></i></a></h4>
                        <hr style={{color: "rgba(0,0,0,.1)"}}/>
                      </div>

                      <div className="col-12">
                        <div className="editProfileForm text-center">
                        {/* <select
                           style={{color:"black",margin: "auto",
                            display: "block"}}
                 
                             value={this.state.CertificateType}
                             name="CertificateType"
                            placeholder="Enter duration of session"
                            onChange={this.handleCertificatechange}
                            >
                          <option value="">Select Certificate Type</option>
                          <option value="Professional Certificate">Professional Certificate</option>
                          <option value="Training Certificate">Training Certificate</option>
                          <option value="Membership association logos">Membership association logos</option>
                          <option value="Company logos">Company logos</option>
                       </select> */}
                            <div className="avatar">
                              <img src={documentSvg} width="75px"/>
                              <p>Upload certifications & insurance proof</p>
                              {/* <input type="file" name="avatar"/> */}
                              <input
                              className="file-upload"
                              type="file"
                              name="certificate_path"
                              id="image"
                              onChange={this.onChangeHandler}
                            />
                            </div>
                            <button className="defaultBtn" type="button" onClick={this.onClickHandler}>Upload</button>
                        </div>

                        <div className="uploadFile">
                          <ul>
                            {this.state.certificates.map((certificate, i) => {
                              return (
                                <li key={i}>
                                  
                                  <a
                                    href={certificate.certificate_path}
                                    data-toggle="lightbox"
                                    data-gallery="example-gallery"
                                    className="boxWrap"
                                  >
                                    {/* <img src="images/document.svg" width="70px"/> */}
                                    <img
                                      alt="LG-Img"
                                      src={documentSvg}
                                      className="img-fluid"
                                      width="70px"
                                    />
                                  </a>
                                      <Link
                                        className="removeLightbox"
                                        //  href="javascript:void(0)"
                                        to="/profile-coach-edit"
                                        onClick={() => {
                                          this.handleDeleteCertificate(certificate.uid);
                                        }}
                                      >
                                        {/* <img width="2" src={deleteSvg} alt="LG-Img" /> */}
                                        <i className="fa fa-minus-circle" aria-hidden="true"></i>
                                      </Link>

                                  {<b style={{textAlign: "center",color:"black"}}>{certificate.media_type}</b>}
                                  {certificate.status==0 ?(<p style={{textAlign: "center",color:"red"}}>In Review</p>):("")}
                                </li>
                              )
                            })}
                          </ul>
                          {/* {this.state.certificates && this.state.certificates.length > 4 ? (
                            <div className="text-center col-12 mt-4">
                              <button
                                className="defaultBtn"
                                data-toggle="modal"
                                data-target="#CommonModal"
                              >
                                View More
                              </button>
                            </div>
                          ) : (
                            <div></div>
                          )}
                          <ListModal
                            dataSet={this.state.certificates}
                            handleDelete={this.handleDeleteCertificate}
                            viewMore="certificates"
                          /> */}
                        </div>
                      </div>
          
                      <div className="col-md-12">
                        <div className="form-group">
                        <label style={{ marginRight: "1.2rem" }}>
                              I declare the information above is accurate
                            </label>
                            <input
                              type="checkbox"
                              required
                              value={this.state.check}
                              onClick={this.handleCheckbox}
                            />
                          </div>
                      </div>
                      
                  
                      <div className="col-md-12">
                        <hr/>
                        <button className="defaultBtn" type="submit" onClick={this.handleSubmit}>save</button>{" "}
                        <Link to="/profile-coach">
                        <button className="defaultBtn" onClick={this.handleBack}>Back</button>
                        </Link>
                      </div>
          
                     </div>
                   </form>
                  </div>
                  </LoadingOverlay>
                </div>
              </div>
            </div>
          </section>
          
          <FooterLink />
      </React.Fragment>
    );
  }
}

export default UpdatePersonal;
