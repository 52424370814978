import React from "react";
import Select from "react-select";
import axios from "../../config/axios";
import { toast } from "react-toastify";
import { Button, Modal, Form, Col, } from "react-bootstrap";
import { writingSvg, closeSvg, photograph, deleteSvg, documentSvg,upload } from "../../assets/images";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { Link } from "react-router-dom";
import { avatar } from "../../assets/img";
import { Header, HeaderLogout, HeaderTop } from "../../components/headers";
import { FooterLink } from "../../components/footers";
import { ListModal } from "../../components/resourcefilemodal";
import MyLoader from "../../components/loader/loader"
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import { Tooltip } from 'antd';
import message,{getHelpText} from '../../utils/mesaages';
import validation from '../../Validate/validator';
import Pagination from '../../components/pagination/pagination';


class ReviewList extends React.Component {
  constructor() {
      super()
      this.token = localStorage.getItem("authToken");

      if (localStorage.getItem("user")) {
        this.userdata = JSON.parse(localStorage.getItem("user"));
      }
  
  
      else if (localStorage.getItem("userdata")) {
        this.userdata = JSON.parse(localStorage.getItem("userdata"));
      }
  
  
      else {
        // #temprary if page will refereshed by mistake
        this.userdata = {
          account_type: "user",
          uid: "44abb6bc-8361-4a56-a88a-3b4c8b10eea2",
        };
      }

      this.state = {
        feedbacks: [],
        reviewsPerPage: 1,
        currentPage: 1,
      };
  }

  async componentDidMount() {
    console.log("@@@@@@@@@@@@ userdata @@@@@@@@@@@@@", this.userdata)
    let public_profile_text = await getHelpText('public_profile_text')
    this.setState({public_profile_text})
    this.feedbacks()
  }


  feedbacks()
  {
    // ${this.props.coachUid}
      axios.get(`/users/feedbackList/${this.userdata.uid}`).then((res)=>{
        if(res.data.success)
        {
            this.setState({feedbacks:res.data.value})
        }
  
      }).catch((err)=>{
  
      })
  }

  paginate = (pageNumber) =>{
    this.setState({currentPage: pageNumber})
  }


  render() {
    const {feedbacks} = this.state
    
    console.log("..................feedbacks................", feedbacks)
    const indexOfLastCoachDetails = this.state.currentPage * this.state.reviewsPerPage
    const indexOfFirstCoachDetails = indexOfLastCoachDetails - this.state.reviewsPerPage
    return (
      <React.Fragment>
            {localStorage.getItem("authToken") == null ? (
            <HeaderTop />
            ) : (
            <HeaderLogout
            currencies={this.state.currencies}
            convertToCurrency={this.state.convertToCurrency}
            changeConvertToCurrency={this.changeConvertToCurrency}
            />
            )}

            <Header />
            <section className="coacActionhWrap pt-5">
              <div className="container" style={{color: "black"}}>
                
                <div className="row">
                  <div className="col-md-12">
                              <div className="heading text-center">
                              <h3>Reviews & Ratings</h3>                    
                                <span className="R_divider"></span>
                              </div>
                  </div>

                  <div className="col-8 mx-auto">
                  {feedbacks.length > 0 ? (
                  feedbacks.slice(indexOfFirstCoachDetails, indexOfLastCoachDetails).map((each, i) => {
                    let star = each.rating
                    let arr = [1,2,3]
                    return (
                      <div className="row">
                        <div className="col-12">
                          <div className="fUser" style={{justifyContent: "left"}}>
                          <img src={(each.avatar_path!=null)?(each.avatar_path):(avatar)} alt="LG-Img" />
                          <h4>{each.name!=null?each.name:"Anonymous"}</h4>
                          </div>
                          <div className="fRating">
                        
                            {arr.map((a,index)=>{

                              return(
                                <i key={index} className={star>=a?('fa fa-star active'):('fa fa-star')} aria-hidden="true"></i>

                              )

                              
                            })} 
                            </div>
                          <p>{each.comment}</p>
                        </div>
                      </div>
                    )
                  })
                ) : (<div><h6>No Reviews Yet </h6></div>)}
                <Pagination postsPerPage={this.state.reviewsPerPage} totalPosts={feedbacks.length} paginate={this.paginate} currentPage={this.state.currentPage}/>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-12">
                    <h3>Test review heading 2</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis at expedita quam eius itaque? Incidunt fugiat a nulla rerum exercitationem corrupti fugit quasi repellendus ad dolorem, laborum architecto minima molestiae!</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <h3>Test review heading 3</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis at expedita quam eius itaque? Incidunt fugiat a nulla rerum exercitationem corrupti fugit quasi repellendus ad dolorem, laborum architecto minima molestiae!</p>
                  </div>
                </div> */}
              
              </div>
            </section>
            
          <FooterLink />
      </React.Fragment>
    );
  }
}

export default ReviewList;
