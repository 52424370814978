import React, { Component } from "react";
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import { Header, HeaderLogout, HeaderTop } from "../components/headers";
import { LifeguruArticle } from "../views/about";
import {
  CoachingAreas,
  FrequentQuestion,
  LandingPageTestimonial,
  MasterCoachWebinars,
  MasterCoach,
  NextMasterCoachWebinars,
  // Subscribe,
  Testimonials,
  Coachesarea,
  PodoCasts,
} from "../views/landing/Index";
import axios from "../config/axios";
import { FooterLink } from "../components/footers";
import message from "../utils/mesaages";
import "../assets/css/style.css?v=1";
import classnames from "classnames";
import ReactPlayer from "react-player";
import { LGspinner, videoCall } from "../assets/images";
import CustomImageLoader from "react-custom-image-loader.";
import { Player } from "video-react";
import { Col, Container, Row } from "react-bootstrap";
import PopupComponent from "../components/headers/PopupComponent";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Webiner_coach1 } from "../assets/images";
import ReCAPTCHA from "react-google-recaptcha";
import { CaptchaKey,SHOW_FEED } from "../config/custom";
import SocilaLogin from "./SocilaLogin";
import { generatePassword } from "../config/custom";

class LandingLayout extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show_submit_button : true,
      submit_message : "",
      submit_message_class : "",
      first_name : "",
      last_name : "",
      email : "",
      first_name_error : "false",
      last_name_error : "false",
      email_error : "false",
      recaptcha : "",
      recaptcha_error : "false",
      banner: [],
      about_video: [],
      coaches_item: [],
      featured_coaches_item: [],
      faqs: [],
      testimonials: [],
      homepage_videos : [],
      loader: false,
      prevScrollpos: 500,
      visible: true,
      category_content  : "",
      height: 0,
      coaches: [],
      livestream : ""
    };

    this.submitForm = this.submitForm.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.capchaChange = this.capchaChange.bind(this);
    
  }

  createMarkup = (data) => {
    return { __html: data };
  };

  capchaChange = (value) => {
    this.setState({ recaptcha : value, recaptcha_error : "" }, () => {
      this.validateForm();
    });
    let th = this;
    setTimeout(function() {
            th.setState({ recaptcha : "", recaptcha_error :"false" }, () => {
            th.validateForm();
        });
    }, 120000); 
  };
  componentDidMount() {
    this.getHomeContent();
    this.getHomeCoaches();
    window.addEventListener("scroll", this.handleScroll);
  }
  // Remove the event listener when the component is unmount.
  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);

    // const height = this.divElement.clientHeight;
    // this.setState({ height });
  };

  handleScroll = () => {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    // console.log("dddd", currentScrollPos);
    const visible = prevScrollpos > currentScrollPos && currentScrollPos < 500;
    // console.log("visible", visible);
    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
    });
  };

  getHomeContent = () => {
    this.setState({ loader: true });
    let self = this;
    axios
      .get("/cmshome/homeContent")
      .then(async (response) => {
        console.log("response landing", response.data.value.webinar);
        if (response.status === 200) {
          // console.log("ajay",response.data.value.homepage_videos);
          self.setState({
            // banner: JSON.parse(response.data.value.banner[0].content_json),
            // about_video: JSON.parse(response.data.value.about_video[0].content_json),
            // coaches_item: response.data.value.coaches_item, 
            featured_coaches_item : response.data.value.feature_coach ? response.data.value.feature_coach : [],
            homepage_videos : response.data.value.homepage_videos,
            livestream : JSON.parse(response.data.value.livestream),
            faqs: response.data.value.faqs,
            testimonials: response.data.value.testimonials,
            webinar: response.data.value.webinar,
            loader: false,
            about_video: JSON.parse(response.data.about_video),
            banner: JSON.parse(response.data.banner),
            coaches_item: response.data.coaches_item,
            category_content : response.data.category_content
          });
        } else {
          self.setState({ loader: false });
          message.error("Error response");
          console.log("error response", response);
        }
      })
      .catch((e) => {
        message.error("Error while fetching data");
        console.log(e);
        self.setState({ loader: false });
      });
  };

  getHomeCoaches = () => {
    let self = this;
    axios
      .post("/consumer/fetch-home-coaches")
      .then(async (response) => {
        if (response.status === 200) {
          self.setState({
            coaches: response.data.value,
          });
        } else {
          console.log("error response", response);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  changeFormField(e,field){
    e.preventDefault();
    if (e.target.value === "") {
      this.setState({ [`${field}`]: "", [`${field}_error`]: "Please enter the field" });
    } else {
      this.setState({ [`${field}`]: e.target.value, [`${field}_error`]: false });
    }
  }

  validateForm(){
    // if(this.state.first_name == ""){
    //   this.setState({first_name_error : "Please enter First Name"});
    // }else{
    //   this.setState({first_name_error : "false"});
    // }
    // if(this.state.last_name == ""){
    //   this.setState({last_name_error : "Please enter Last Name"});
    // }else{
    //   this.setState({last_name_error : "false"});
    // }
    if(this.state.email == ""){
      this.setState({email_error : "Please enter Email"});
    }else{
      this.setState({email_error : "false"});
    }
    if(this.state.recaptcha == ""){
      this.setState({recaptcha_error : "Invalid Captcha"});
    }else{
      this.setState({recaptcha_error : "false"});
    }
  }

  submitForm(){
      this.validateForm();
      if(this.state.email !== "" && this.state.recaptcha != "" ){
        this.setState({submit_message : "We are processing your request",submit_message_class : "info",show_submit_button : false})
        axios.post("/check-email",{email : this.state.email}).then(async (response) => {
        if (response.status === 200) {
          if(response.data.success){
            if(response.data.proceed){
              const formData = {
                // fullname: "",
                fullname: this.state.email.split('@')[0],
                email: this.state.email,
                phone_number: "",
                password: generatePassword(),
                RepeatPassword: "",
                account_type: "jobseeker",
                getNoti: false,
                tracking_link: window.location.href,
                registration_source : "",
                acode :"",
                package : "",
                coupon : "",
            };

            axios.post("/signup", formData).then((response) => {
                console.log("responsee", response.data);
                if (response.data.success) {
                    this.setState({submit_message : "User registered successfully",submit_message_class : "success",show_submit_button : true})
                    let Uuid = response.data.userdata.uid;
                    this.props.history.push({ pathname: "/thankyou", state: { user_t: Uuid, userData: response.data.userdata, }, });
                }
            }).catch((error) => {
              console.log(error)
              this.setState({submit_message : "There is some issue while processing your request",submit_message_class : "danger",show_submit_button : true})
            });
            }else{
              this.setState({submit_message : "Please enter a valid email",submit_message_class : "danger",show_submit_button : true})
            }
            
          }else{
            this.setState({submit_message : response.data.message,submit_message_class : "danger",show_submit_button : true})
            console.log("error response", response.data.message);  
          }
        } else {
          this.setState({submit_message : "There is some issue while processing your request",submit_message_class : "danger",show_submit_button : true})
          console.log("error response", response);
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({submit_message : "There is some issue while processing your request",submit_message_class : "danger",show_submit_button : true})
      });
  
      }else{
        return false;	
      }
  

  //     axios
  //     .post("/consumer/fetch-home-coaches")
  //     .then(async (response) => {
  //       if (response.status === 200) {
  //         self.setState({
  //           coaches: response.data.value,
  //         });
  //       } else {
  //         console.log("error response", response);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

      // if(this.state.first_name !== "" && this.state.last_name !== "" && this.state.email !== ""){
      //   window.location.href = `/user_registration?firstname=${this.state.first_name}&lastname=${this.state.last_name}&email=${encodeURIComponent(this.state.email)}`
      // }else{
      //   return false;	
      // }
  }

  render() {
    //console.log("webinar content", this.state.webinar)
    let homepage_videos =  this.state.homepage_videos.length > 0 ? JSON.parse(this.state.homepage_videos[0]?.content_json) : [];
    return (
      <div>
        <PopupComponent />
        {localStorage.getItem("authToken") == null ? (
          <HeaderTop />
        ) : (
          <HeaderLogout />
        )}
        <Header />

        

        <LoadingOverlay
          // style={{ width: 70, height: 70}}
          active={this.state.loader}
          spinner={
            <CustomImageLoader image={LGspinner} animationType={"none"} />
          }
        >
          <section className="bannerWrap">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-5 mb-3">
                  <div className="bannerText">
                    <div
                      dangerouslySetInnerHTML={this.createMarkup(
                        this.state.banner.video_text
                      )}
                    >
                      </div>
                   
                      <div className="p-3 starttoday" style={{"backgroundColor":"#00e1ba","borderRadius":"7px"}}>
                          <Row>
                            <Col md={12} className="text-center">
                              <h4 style={{"color" : "#fff"}}>Get Started Today For FREE</h4>
                              </Col>
                              {/* <span style={{"color" : "#fff"}} className="mb-1"> <small>No Credit Card Required</small></span> */}
                            

                            {/* <Col md={6}>
                              <div className="form-group">
                                <input type="text" className="form-control" onChange={(e)=>{this.changeFormField(e,"first_name")}} placeholder="First Name"/>
                                {this.state.first_name_error !== "false" && <span className="text-danger">{this.state.first_name_error}</span> }
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form-group">
                                <input type="text" className="form-control" onChange={(e)=>{this.changeFormField(e,"last_name")}} placeholder="Last Name"/>
                                {this.state.last_name_error !== "false" && <span className="text-danger">{this.state.last_name_error}</span> }
                              </div>
                            </Col> */}
                          
                                {this.state.submit_message != "" &&
                                  <><br /><span className={`text-${this.state.submit_message_class}`}>{this.state.submit_message}</span></>
                                }

                                  <Col md={12} className="mt-3">
                                    <input type="email" className="form-control" onChange={(e)=>{this.changeFormField(e,"email")}} placeholder="Email"/>
                                    {this.state.email_error !== "false" && <span className="text-danger">{this.state.email_error}</span> }
                                  </Col>
                                  <Col md={6} className="mt-3">
                                  <div className="custom-recaptcha-container">
                                    <ReCAPTCHA
                                      sitekey={CaptchaKey}
                                      onChange={this.capchaChange}
                                    />

                                  <p style={{ color: "red" }}>
                                    {this.state.recaptcha_error == "false"
                                      ? ""
                                      : this.state.recaptcha_error}
                                  </p>
                                  
                                  </div>
                                  
                                  </Col>
                                  <Col md={6} className="mt-3">
                                  {this.state.show_submit_button && <button className="w-100 defaultBtn orange p-3" onClick={this.submitForm} >Sign Up</button> } 
                                  </Col>
                                  
                                    
                                  <Col md={2}></Col>  
                                  <Col md={1} style={{"color":"#fff"}} className="mt-2">OR</Col>  
                                  <Col md={7}>
                                    <SocilaLogin isLogin={false} txt="Sign Up with Google"/>
                                  </Col>
                                  <Col md={2}></Col>  
                                
                              </Row>
                            </div>

                    
                    
                  </div>
                </div>
                <div className="col-md-7" >
                  <div className="bannerVideo">
                    {/* <iframe height="470" 
                //title="u1"
                poster={this.state.banner.thumbnail_url}
                src={`${this.state.banner.video_url}?autoplay=1&loop=1&muted=1`} 
                frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                    <ReactPlayer
                      style={{ width: '100%' }}
                      url={`${this.state.banner.video_url}`}
                      controls={true}
                      muted={true}
                      playing={true}
                      loop={true}
                    //light={this.state.banner.thumbnail_url}
                    />
                  </div>
                </div>
               
              </div>
            </div>
          </section>
        </LoadingOverlay>

        {/* <MasterCoach /> */}
        {/* <section className="aboutWrap">
          <div className="container">
            <div className="row">
              <div className="col-md-4" >
                <Player
      
                    id="player1"
                    width="100%"
                    height="380px"
                    playsInline
                    poster={this.state.about_video.thumbnail_url}
                    src={this.state.about_video.video_url}
                  />
              </div>
              <div className="col-md-8" >
                <div className="heading text-lef">
                  <h3>WHAT IS LIFEGURU?</h3>
                  <span className="R_divider"></span>
                </div>
                <div className="aboutText">
                  <p dangerouslySetInnerHTML={this.createMarkup(this.state.about_video.video_text)}/>
                  <a href="/#" className="defaultBtn">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="rl_event">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6 rl_event_image">
              <Row>
              {
              [1, 2, 3, 4].map((item) => (
                homepage_videos?.[`video_url${item}`] ? (
                  <Col md={6}>
                    <div className="player-wrapper" style={{ width: '100%' }}>
                      <ReactPlayer
                        style={{ width: '100%' }}
                        url={homepage_videos[`video_url${item}`]}
                        controls={true}
                        muted={true}
                        playing={true}
                        loop={true}
                      />
                    </div>
                  </Col>
                ) : (
                  <Col md={6}>
                    <img src={videoCall} className="img-fluid" />
                  </Col>
                )
              ))
            }
          </Row>
                
              </div>
              <div className="col-md-5 rl_event_text">
                <div className="heading mb-4">
                  <h3 className="text-uppercase">Remote <span>live events</span></h3>
                </div>
                <h4 className="text-uppercase">JOIN NOW AND START WATCHING TODAY!</h4>
                <p><strong>Enjoy unlimited access to:</strong></p>
                <ul className="list-unstyled">
                <li><i className="fa fa-check" aria-hidden="true"></i>&nbsp;<b>Engaging Live Webinars:</b> Interact with world-class coaches and receive expert guidance in real-time.</li>
                <li><i className="fa fa-check" aria-hidden="true"></i>&nbsp;<b>Diverse Categories:</b> Explore a wide range of topics in health & wellness, personal growth and career development.</li>
                <li><i className="fa fa-check" aria-hidden="true"></i>&nbsp;<b>Inspirational On-Demand Library:</b> Enjoy the flexibility and convenience of accessing a vast collection of classes anytime, anywhere.</li>
                <li><i className="fa fa-check" aria-hidden="true"></i>&nbsp;<b>Life-Empowering Resources:</b> Stay motivated and empowered with continuous support and tools to transform your life.</li>
                </ul>
                <div className="mt-4">
                  <a href="/user_registration" className="defaultBtn">Join Today</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="aboutWrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-lg-6">
                <div className="hac">
                  <div className="heading text-lef">
                    <h3>WHAT IS COACH MASTER?</h3>
                    <span className="R_divider div_dark"></span>
                  </div>
                  <div className="aboutText">
                    <div
                      dangerouslySetInnerHTML={this.createMarkup(
                        this.state.about_video.video_text_home
                      )}
                    />
                    <div className="mt-4">
                      <a href="/how-it-works" className="defaultBtn">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="aboutVideo">
                  {/* <iframe
                    height="320"
                    //title="u2"
                    poster={this.state.banner.thumbnail_url}
                    // src={this.state.about_video.video_url}
                    src="https://vimeo.com/790945871"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  /> */}
                  <iframe src="https://player.vimeo.com/video/790945871?loop=1&muted=true" height="350" frameBorder="0" allow="fullscreen;"></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
        
       
        
         {
         SHOW_FEED && <Container className="custom_widget">
            <iframe src='https://widgets.sociablekit.com/instagram-feed/iframe/25432476' frameborder='0' width='100%' height='1050'></iframe>
            <iframe src='https://widgets.sociablekit.com/linkedin-page-posts/iframe/25433461' frameborder='0' width='100%' height='700'></iframe>
          </Container>
        }
        
        <NextMasterCoachWebinars />
        {this.state.livestream != "" && this.state.livestream.status == "Y" &&
          <section className="aboutWrap">
          <div className="container">
            <div className="row align-items-center">
             
              <div className="col-md-6 col-lg-6">
                <div className="aboutVideo">
                <iframe src={this.state.livestream.video_link} height="350" frameBorder="0" allow="fullscreen;"></iframe>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="hac">
                  <div className="heading text-lef">
                    <h3><span style={{"color":this.state.livestream.title_one_color}}>{this.state.livestream.title_one_text}</span> &nbsp;<span style={{"color":this.state.livestream.title_two_color}}>{this.state.livestream.title_two_text}</span></h3>
                    <span className="R_divider div_dark"></span>
                  </div>
                  <div className="aboutText">
                    <p dangerouslySetInnerHTML={{__html: this.state.livestream.description}} />
                    
                    <div className="mt-4">
                      <a href={this.state.livestream.button_link} className="defaultBtn">
                      {this.state.livestream.button_text}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  }
        {/* <UpcomingMasterCoachWebinars /> */}
        {/* <MasterCoachWebinars webinardata={this.state.webinar}/> */}
        {/* <PodoCasts /> */}
        {/* <div className="programs"> */}
        <FeaturedCoaches item={this.state.featured_coaches_item}/> 
        <section className="rl_event pt-0">
          <Container>
            <div className="heading text-center">
              <h3 className="text-uppercase">Categories </h3>
              <span className="R_divider div_dark"></span>

              <p>{this.state.category_content}</p>
            </div>
            <CoachingAreas coachingareadata={this.state.coaches_item} />
          </Container>
        </section>
        {/* </div> */}
        {/* <LandingPageTestimonial /> */}
        {/* <Coachesarea coachesdata={this.state.coaches} /> */}
        {/* <Testimonials testimonialdata={this.state.testimonials} /> */}

        {/* <LifeguruArticle /> */}
        <FrequentQuestion faqdata={this.state.faqs} />
        {/* <Subscribe /> */}
        <FooterLink />      
      </div>
    );
  }
}
const FeaturedCoaches = (props) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  // return null;
  if (!props.item || props.item.show_feature_coach !== "Y") {
    return null; // or any other fallback UI
  }

  return (
    <section className="p-5 custom_carousel">
      <div className="heading mb-4">
        <h3 className="text-uppercase text-center mb-3" style={{ color: "#00e1ba" }}>{props.item.heading}</h3>
      </div>      
      <p className="text-center">{props.item.description}</p>
      <Carousel  responsive={responsive}>
        {props.item.coaches_list != undefined && props.item.coaches_list.map((i, index) => (
          <div className={`card shadow mr-5 h-100`} key={index} style={{"border-radius":"9","max-height" : "400px","min-height" : "400px","overflow-y":"auto"}}>
            <div className="card-header" style={{"background-img":i.avatar_path}}>
              {i.avatar_path ? (
                <img src={i.avatar_path} alt="Avatar" className="img-fluid"  />
              ) : (
                <img src={Webiner_coach1} alt="Default Avatar" className="img-fluid"  />
              )}
            </div>

{/* {i.avatar_path ? (
<div className="card-header" style={{ background: `url(${i.avatar_path})`,height: "340px" ,backgroundPosition: "top" }}>
          </div>) : 
          <div className="card-header" style={{ background: `url(${Webiner_coach1})`,height: "340px" ,backgroundPosition: "top" }}>
          </div>
} */}
            <div className="card-body p-4 p-xxl-5">
              <h2 className="h4 mb-2">{i.name}</h2>
              <p className="text-secondary mb-0">{i.user_title}</p>
              <div className="d-grid gap-2 my-4">
                <p>{i.short_bio}</p>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </section>
  );
}
export default LandingLayout;
//
