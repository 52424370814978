import React, { Component } from 'react'
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { Header, HeaderLogout, HeaderTop } from "../components/headers";
import { FooterLink } from "../components/footers";
import ReactPlayer from 'react-player';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "../config/axios";
import message from '../utils/mesaages';
import { Link } from 'react-router-dom';
import '../assets/css/about.css';
import LoadingOverlay from "react-loading-overlay";
import { blog1, dIcon, LGspinner } from '../assets/images'
import CustomImageLoader from 'react-custom-image-loader.'
// import { ArticlesAboutus } from "../views/about";
import { AboutImg, Professinal } from '../assets/images';
import { HowIcon, HowBanner } from '../assets/images';
import HeaderSecondTo from '../components/corporate/Header/HeaderSecondTo';
import { hww } from '../components/corporate/images';
// import { Icon1,Icon2,Icon3,Icon4,Icon5,Icon6} from '../assets/images';
// import { MissionBanner} from '../assets/images';
// import { Art1,Art2,Art3} from '../assets/images';
// import { jane} from '../assets/images';



class AboutUsNew extends Component {
  constructor(props) {
    super(props)
    this.state = {
      about_video: [],
      features: [],
      team: [],
      mission: [],
      loader: false,
    }
  }

  createMarkup = (data) => {
    return { __html: data };
  }

  componentDidMount() {
    this.getAboutContent()
  }

  getAboutContent = () => {
    this.setState({ loader: true })
    let self = this
    axios.get('/cmsabout/aboutContent')
      .then(async (response) => {
        if (response.status === 200) {
          self.setState({ features: response.data.value.features, team: response.data.value.team, about_video: JSON.parse(response.data.about_video), mission: response.data.mission, how_lifeguru_works: response.data.how_lifeguru_works, loader: false, })
        } else {
          this.setState({ loader: false })
          message.error("Error response");
        }
      })
      .catch((e) => {
        message.error("Error while fetching data");
        console.log(e)
        this.setState({ loader: false })
      })
  }

  render() {
    return (

      <>
        {
          !localStorage.getItem("subdomain") && (localStorage.getItem("authToken") == null ? (<HeaderTop />) : (<HeaderLogout />))
        }
        {
          localStorage.getItem("subdomain") ? (<HeaderSecondTo />) : (<Header />)
        }


        <LoadingOverlay active={this.state.loader} spinner={<CustomImageLoader image={LGspinner} animationType={'none'} />} styles={{ wrapper: { overflow: this.state.loader ? 'hidden' : '' } }}>
          <div className="home" style={{ backgroundColor: "white" }}>
            <AboutBanner />
            {
              localStorage.getItem("subdomain") && (
                <div className="hww">
                  <Container>
                    <Row className='align-items-center'>
                      <Col md={6} className="hww_img">
                        <img src={hww} className="img-fluid w-100" alt='hww_image' />
                      </Col>
                      <Col md={6} className="hww_text">
                        <div className='cs_heading mb-4'>
                          <h5 className="css_title text-uppercase">ALL OF YOUR REMOTE WELLNESS & WELLBEING SPEND IN ONE PLACE</h5>
                          <h2 className="cs_title">How we work</h2>
                        </div>
                        <ul className="list-unstyled listTypeBox mb-5">
                          <li>We specialize in remote wellness live webinars and programs for teams of all sizes</li>
                          <li>Monthly subscription gives each employee access to all of our remote live webinars</li>
                          <li>Book 1:1 programs with our experienced coaches and practitioners</li>
                          <li>Invite your employees to the platform to access live remote talks and “on demand” library of resources. Al your team has to do is show up to the events displayed on their dashboard</li>
                        </ul>
                        <Link to="/signup" className="btn btn-default defaultBtn">Sign Up</Link>
                      </Col>
                    </Row>
                  </Container>
                </div>
              )
            }
            <LifeguruBannerBottom loader={this.state.loader} about_video={this.state.about_video} />
            {
              !localStorage.getItem("subdomain") && 
              <LifeguruWorks how_lifeguru_works={this.state.how_lifeguru_works} />
            }
            <LifeguruKeyFeatures featuresdata={this.state.features} />
            {/* <LifeguruMission mission={this.state.mission}/> */}
            {/* <LifeguruArticle /> */}
          </div>
        </LoadingOverlay>
        <FooterLink />
      </>

    );
  }


}


class AboutBanner extends Component {
  render() {
    return (
      <div className="about_banner">
        <img src={AboutImg} className="img-fluid" />

        <div className="about_inner_text flex-column">
          <h2>How it works</h2>
          {/* <span className="R_divider"></span> */}
        </div>
      </div>
    );
  }
}

class LifeguruBannerBottom extends Component {
  constructor(props) {
    super(props)
  }
  createMarkup = (data) => {
    return { __html: data };
  }
  render() {
    return (
      <section className="lifeguru_banner_bottom">

        <Container>
          <h2>Coach Master</h2>
          {/* <span className="R_divider" style={{marginLeft: "504px",width: "195px",background: "black"}} ></span> */}
        </Container>
        <div className="lifeguru_video">
          <div className="video_left d-flex align-items-center">
            <div className="video_left_text">
              <div dangerouslySetInnerHTML={this.createMarkup(this.props.about_video.video_text)} /></div>
          </div>
          <div className="video_right">
            <ReactPlayer className='react-player'
              url={this.props.about_video.video_url}
              // url={"https://player.vimeo.com/video/790945871"}
              controls={true}
              playing
              muted={true}
              loop={true}
              playsinline
              width="100%"
              height="370px"
            />
            {/* <iframe src="https://player.vimeo.com/video/790945871?loop=1&muted=true" height="320" frameborder="0" allow="fullscreen;" allowfullscreen></iframe> */}
          </div>
        </div>

      </section>
    );
  }
}


class LifeguruWorks extends Component {
  constructor(props) {
    super(props)
  }
  createMarkup = (data) => {
    return { __html: data };
  }
  render() {
    let content, points
    if (this.props.how_lifeguru_works != undefined) {
      content = JSON.parse(this.props.how_lifeguru_works)
      points = content.points
    }
    // return <></>;
    return (
      <section className="lifeguru_works">
        <Container>
          <Row className="work_row">
            <Col lg="6" md="6" sm="12">
              <div className="work_left">
                <h2>How <span>Coach Master</span> works:</h2>
                {/* <span className="R_divider" style={{marginLeft: "144px",width: "227px",background: "black"}} ></span> */}
                <div className="how_works_bottom">

                  {points && points.map((each, index) => {
                    return (<div className="how_work_inner">
                      <div className="how__icon"><img src={HowIcon} /></div>
                      <p>{index + 1}.<>&nbsp;</>{each}</p>
                    </div>
                    )
                  })
                  }
                </div>
                <div className="how_bottom__content">
                  {content ? <div dangerouslySetInnerHTML={this.createMarkup(content.description)} /> : ''}
                </div>
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div className="how_banner">
                <img src={content ? content.image : Professinal} className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}


class LifeguruKeyFeatures extends Component {

  createMarkup = (data) => {
    return { __html: data };
  }
  render() {
    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    return (
      <section className="abfeaturesWrap">
        <Container>
          <h2>Coach Master Key Features</h2>

          <Row className="feature_bottom">
            {
              this.props.featuresdata?.map((items) => (
                <Col md={4}>
                  <div className="featuresItem">
                    <div className='fi_content'>
                      <div className="featuresImg">
                        <img src={items.p_image} alt={items.heading} />
                      </div>
                      <h4>{items.heading}</h4>
                      {renderHTML(items.description)}
                    </div>
                  </div>
                </Col>
              ))
            }
          </Row>
        </Container>
      </section>
    );
  }
}


class LifeguruMission extends Component {

  createMarkup = (data) => {
    return { __html: data };
  }

  render() {
    let content, image, mission
    if (this.props.mission.length > 0) {
      if (this.props.mission) {
        content = JSON.parse(this.props.mission)
        mission = content.content
        image = content.image
      }

    }
    return (
      <section className="abmissionWrap">
        <Container>
          <Row className="missionRow">
            <Col lg="6" md="6" sm="12">
              <div className="mission_banner">
                <img src={image ? content.image : 'https://lifeguru-prod.s3.us-east-2.amazonaws.com/lifeguruadmin/RLvAC0uBduhJxayCONG96wPO0Tx0hhRLhTUjcUrX.png'} className="img-fluid" />
              </div>
            </Col>
            <Col lg="6" md="6" sm="12">
              <div className="mission_text">
                <h3>Coach Master <span>Mission</span></h3>
                {/* <span className="R_divider" style={{marginLeft: "150px",width: "262px",background: "black"}} ></span> */}
                {this.props.mission.length > 0 ? <div dangerouslySetInnerHTML={this.createMarkup(mission)} /> : ''}

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}


class LifeguruArticle extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      articlesData: [],
      loader: false,
    }
  }

  createMarkup = (data) => {
    return { __html: data };
  }
  componentDidMount() {
    this.setState({ loader: true })

    const URI = `/cmshome/articles/featured`
    axios.get(URI)
      .then(response => {
        const data = response.data
        if (data.success) {
          const articles = data.articles

          let getPostsDetailsInfoURI = `https://blog.lifeguru.me/wp-json/wp/v2/posts?`

          if (articles && articles.length > 0) {

            articles.forEach(featuredArticle => {
              getPostsDetailsInfoURI += `include[]=${featuredArticle.post_id}&`
            })
            getPostsDetailsInfoURI += "_embed"

            fetch(getPostsDetailsInfoURI)
              .then(res => res.json())
              .then((data) => {
                this.setState({ articlesData: data, loader: false })
              })
              .catch((e) => {
                console.log("ARTICLES FETCH ERROR", e)
                this.setState({ loader: false })
              })

          } else {
            console.log("No articles feched");
            this.setState({ loader: false })
          }



        }
      })
      .catch(e => {
        console.log("featured ARTICLES fetching from Coach Master ERROR", e)
        this.setState({ loader: false })
      })
  }


  render() {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };
    return (
      <section className="abblogWrap">
        <LoadingOverlay
          active={this.state.loader}
          //spinner
          spinner={<CustomImageLoader image={LGspinner} animationType={'none'} />}
        >
          <Container>
            <h3>Articles</h3>
            {/* <span className="R_divider" style={{marginLeft: "483px",width: "213px",background: "black"}} ></span> */}
          </Container>
          <div className="blog_slider">
            <Carousel responsive={responsive} centerMode={false} className="slider__carousel" infinite="false">
              {this.state.articlesData.slice(0, 4).map(article => {
                return (<div className="art_box" style={{ height: "430px" }}>
                  <a href={article.link} style={{ textDecoration: "none" }} target="_blank">
                    <img src={article._embedded["wp:featuredmedia"] === undefined ? blog1 : article._embedded["wp:featuredmedia"][0].source_url} className="img-fluid" alt="#" />
                    <div className="blog_text">
                      <h4>{`${article.title.rendered.substring(0, 40)}...`}</h4>
                      <div dangerouslySetInnerHTML={this.createMarkup(`${article.excerpt.rendered.substring(0, 85)}...`)} />
                    </div>
                  </a>
                </div>
                )
              })}
              {/* <div className="art_box">
                        <img src={Art2} className="img-fluid" alt="#"/>
                        <div className="blog_text">
                        <h4>How to Kick Depression into<br/> Touch in 3 Quick & Easy Steps</h4>
                        </div>
                    </div>
                    <div className="art_box"><img src={Art3} className="img-fluid" alt="#"/>
                        <div className="blog_text">
                        <h4>What is the Tapping<br /> Technique?...</h4>
                        </div>
                    </div>
                    <div className="art_box"><img src={Art2} className="img-fluid" alt="#"/>
                    <div className="blog_text">
                        <h4>How to Kick Depression into<br/> Touch in 3 Quick & Easy Steps</h4>
                        </div>
                    </div> */}
            </Carousel>
          </div>
        </LoadingOverlay>
      </section>
    );
  }
}

export default AboutUsNew