import React from 'react'
import { Row, Col, Card, Button, Table, Dropdown, InputGroup, FormControl, Form } from 'react-bootstrap'
import ReactPaginate from "react-paginate";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import './BulkContacts.css'
import axios from '../../../config/axios';
import { message } from 'antd';
import moment from 'moment';
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";
import { LGspinner } from '../../../assets/images';
import EditGroupUser from './EditGroupUser';

class BulkContactsDetails extends React.Component {
    constructor(props) {
        super(props)

        this.token = localStorage.getItem("corporate_token")
        this.userdeta = JSON.parse(localStorage.getItem("corporate_userdata"))

        this.state = {
            show: false,
            modal: '',
            loading: false,
            limit: 10,
            page: 1,
            total_rows: "",
            users: [],
            total_pages: "",
            message: "",
            group_name: "",
            offset: "",
            search: ""
        }
    }

    getGroupUser = () => {
        this.setState({ loader: true });
        axios.get(`/api/corporate_group_user?limit=${this.state.limit}&page=${this.state.page}&group_id=${this.props.match.params.group_id}&user_id=${this.userdeta.uid}`, { headers: { "X-Auth": this.token, }, }).then((res) => {
            this.setState({ loader: false });
            console.log(res)
            if (res.data.success) {
                this.setState({ group_name: res.data.users[0].group_name, message: res.data.message, users: res.data.users, total_pages: res.data.total_pages, total_rows: res.data.total_rows, offset: res.data.offset })
            }
        }).catch((err) => {
            this.setState({ loader: false });
            message.error(err.responce.msg);
        });
    }

    componentDidMount() {
        this.getGroupUser()
    }

    onSessionsPageChange = (page) => {
        let page1 = page.selected + 1;
        this.setState({ page: page1 }, () =>
            this.getGroupUser()
        );
    };

    deleteGroupUser = (user_id) => {
        this.setState({ loader: true });
        axios.post(`/api/corporate_delete_group_user`, { group_id: this.props.match.params.group_id, id: user_id, user_id: this.userdeta.uid }, { headers: { "X-Auth": this.token, }, }).then((res) => {
            this.setState({ loader: false });
            if (res.data.success) {
                message.success(res.data.msg)
                this.getGroupUser()
            }
        }).catch((err) => {
            this.setState({ loader: false });
            message.error(err.responce.msg);
        });
    }

    handleShow = (index) => {
        this.setState({ show: true })
        this.setState({ modal: <EditGroupUser show={true} isSubmit={false} handleClose={this.handleClose} userDetails={this.state.users[index]} getGroupUser={this.getGroupUser} /> })
    }

    handleClose = () => {
        this.setState({ show: false });
    };

    updateStatus = (status, id) => {
        if (status === "1") {
            axios.put(`/api/corporate_user_activate_or_deactivate`, { status: "0", id, user_id: this.userdeta.uid }, { headers: { "X-Auth": this.token, }, }).then((res) => {
                if (res.data.success) {
                    message.success(res.data.msg)
                    axios.get(`/api/corporate_group_user?limit=${this.state.limit}&page=${this.state.page}&group_id=${this.props.match.params.group_id}&user_id=${this.userdeta.uid}`, { headers: { "X-Auth": this.token, }, }).then((res) => {
                        if (res.data.success) {
                            this.setState({ group_name: res.data.users[0].group_name, message: res.data.message, users: res.data.users, total_pages: res.data.total_pages, total_rows: res.data.total_rows })
                        }
                    }).catch((err) => {
                        message.error(err.responce.msg);
                    });
                }
            }).catch((err) => {
                message.error(err.responce.msg);
            });
        } else {
            axios.put(`/api/corporate_user_activate_or_deactivate`, { status: "1", id, user_id: this.userdeta.uid }, { headers: { "X-Auth": this.token, }, }).then((res) => {
                if (res.data.success) {
                    message.success(res.data.msg)
                    axios.get(`/api/corporate_group_user?limit=${this.state.limit}&page=${this.state.page}&group_id=${this.props.match.params.group_id}&user_id=${this.userdeta.uid}`, { headers: { "X-Auth": this.token, }, }).then((res) => {
                        if (res.data.success) {
                            this.setState({ group_name: res.data.users[0].group_name, message: res.data.message, users: res.data.users, total_pages: res.data.total_pages, total_rows: res.data.total_rows })
                        }
                    }).catch((err) => {
                        message.error(err.responce.msg);
                    });
                }
            }).catch((err) => {
                message.error(err.responce.msg);
            });
        }
    }

    onHandleChange = (e) => {
        this.setState({ search: e.target.value })
    }

    render() {
        const { total_pages, users, search } = this.state
        return (
            <div className="content_main bulk_upload">
                <Header />
                <LoadingOverlay active={this.state.loader} className="webiner_loader" spinner={<CustomImageLoader image={LGspinner} animationType={"none"} />}>
                    <div className="content_wrap">
                        <h4 className="page-title mb-3">Employee Lists of {this.state.group_name}</h4>
                        <p>Access the contact details of invited employees belonging to {this.state.group_name}</p>
                        <div className="btn_group mb-5 d-flex"></div>

                        <Card className="crCard border-0 mb-4">
                            <Card.Header className="bg-white ch-tool">
                                <Row className="align-items-center">
                                    <Col md={8}>
                                        <Card.Title className="mb-0 h4">Employee List</Card.Title>
                                    </Col>
                                    <Col md={4}>
                                        <InputGroup className="table_search">
                                            <InputGroup.Prepend className="mr-0">
                                                <InputGroup.Text className="border-0 bg-white"><i className="fa fa-search"></i></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl className="border-0" placeholder="Search" onChange={this.onHandleChange} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Table responsive className="mb-0 text-center">
                                    <thead>
                                        <tr>
                                            <th className="border-top-0">SL. No.</th>
                                            <th className="border-top-0">Name</th>
                                            <th className="border-top-0">Email</th>
                                            <th className="border-top-0">Phone</th>
                                            <th className="border-top-0">Mail Send</th>
                                            <th className="border-top-0">Joined</th>
                                            <th className="border-top-0">Status</th>
                                            <th className="border-top-0">Created At</th>
                                            <th className="border-top-0">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (users.length === 0) ? <p> {this.state.message} </p> :
                                                users.filter(items => {
                                                    if (search === '') {
                                                        return items;
                                                    } else if (items.email.toLowerCase().includes(search.toLowerCase()) || items.firstname.toLowerCase().includes(search.toLowerCase()) || items.lastname.toLowerCase().includes(search.toLowerCase())) {
                                                        return items;
                                                    }
                                                }).map((items, index) => (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{items.firstname}{" "}{items.lastname}</td>
                                                        <td>{items.email}</td>
                                                        <td>{items.contact_no}</td>
                                                        <td>{parseInt(items.has_send) === 0 ? (<i className="fa fa-times" aria-hidden="true"></i>) : (<i className="fa fa-check" aria-hidden="true"></i>)}</td>
                                                        <td>{parseInt(items.has_joined) === 0 ? (<i className="fa fa-times" aria-hidden="true"></i>) : (<i className="fa fa-check" aria-hidden="true"></i>)}</td>
                                                        <td><Form> <Form.Check type="switch" id={items.id} onChange={() => this.updateStatus(items.status, items.id)} label={(items.status === "1") ? "Active" : "Inactive"} checked={(items.status === "1") ? true : false} /> </Form></td>
                                                        <td>{moment(items.created_at).format("YYYY-MM-DD")}</td>
                                                        <td>
                                                            <Dropdown className="ac_drop">
                                                                <Dropdown.Toggle variant="default"><i className="fa fa-ellipsis-v"></i></Dropdown.Toggle>
                                                                <Dropdown.Menu className="border-0">
                                                                    <Dropdown.Item className='blukDropdown' onClick={() => this.handleShow(index)}> <Button className='dropdownButton'><i className="fa fa-pencil" aria-hidden="true"></i>{" "} Edit</Button> </Dropdown.Item>
                                                                    <Dropdown.Item className='blukDropdown' onClick={() => this.deleteGroupUser(items.id)}> <Button className='dropdownButton'><i className="fa fa-trash-o" aria-hidden="true"></i>{" "} Delete</Button> </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                ))
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                            <Card.Footer className="bg-white">
                                <Row className="align-items-center">
                                    <Col md="4">
                                        <label className='mb-0'>{this.state.offset + 1} - {this.state.total_rows} of {this.state.total_rows}</label>
                                    </Col>
                                    <Col md="8" className='result_pagination'>
                                        <ReactPaginate
                                            className="mb-0 justify-content-end"
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={this.onSessionsPageChange}
                                            pageRangeDisplayed={6}
                                            pageCount={total_pages}
                                            previousLabel="<"
                                            activeClassName="active"
                                            disabledClassName="disable"
                                            renderOnZeroPageCount={null}
                                        />
                                    </Col>
                                </Row>
                            </Card.Footer>
                        </Card>
                    </div>
                </LoadingOverlay>
                <Sidebar />
                {
                    (this.state.show === true) ? this.state.modal : ''
                }
            </div>
        )
    }
}

export default BulkContactsDetails