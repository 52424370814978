import React, { Component,useState } from 'react';


import StripeCheckout from "react-stripe-checkout";
import { toast } from "react-toastify";
// import moment from "moment";
import moment from "moment-timezone";

import axios,{AdminApi} from '../../../config/axios';
import {dateConfig} from '../../../config/dateFormats';

import Calendar from 'react-calendar';
import CoachProfileWrap from './CoachProfileWrap';
import { Header, HeaderLogout, HeaderTop } from "../../../components/headers";
import { FooterLink } from "../../../components/footers";
import CommunicationUsers,{SessionBookedNotify} from "../../../layouts/Communication/CommunicationUsers";
import { avatar } from "../../../assets/img";
import  mc  from "../../../assets/images/mc.png";
import  visa  from "../../../assets/images/visa.png";
import  ae  from "../../../assets/images/ae.png";
import  {LGspinner}  from "../../../assets/images";
import message from '../../../utils/mesaages';
import validation from '../../../Validate/validator';
import { TimePicker, DatePicker } from 'antd';
import {getClientTimezone,timezoneUtc,UTCtoTimezone} from '../../../utils/timeZoneConvert';
import {curencyFilter,curencyRates} from '../../../utils/currencyConvert';
import bluesnapForm from '../../../assets/js/bluesnapgateway'
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";





const _ = require("lodash");  
let userdata = JSON.parse(localStorage.getItem('userdata'))

class BookingPayment extends Component {

    constructor(props){
        super(props)
        
        this.state={
          avatar_path: avatar,
          selectedSlot:'',
          sessionDate:"2021-01-28",
          slots:[],
          currMonth: moment().format('MMMM'),
          currDate: moment().format('DD-MM-YYYY'),
          date: new Date(),
          carddetails:[],
          cardnumber: '',
          name: '',
          month: moment().format('MM'),
          year: moment().format('YYYY'),
          cvc: '',
          expiryDate:moment(),
          address: '',
          saveCard:'',
          carduid:'',
          errors: {
            cvc: '',
            cardnumber: '',
            saveCard:''
          },
          FromDataError: {
            cardnumber: 'false',
            cvc: 'false',
            name:'false',
            expiryDate:'',
            saveCard:''
            // month:'false',
            // year:'false',

          },
          gatewayDetails:{},
          coachTimezone:this.props.location.state.coachData.timezone,
          timezone:localStorage.getItem('timezone'),
          filteredArray:[],
          fieldDisable:false,
          strip_cus:'',
          pfToken:'',
          loaderToken:false,
          rates:""
        }

        
      }

      validateForm(errors) {
      let {carduid,name,cardnumber,month,year,cvc} = this.state

      if(carduid=='')
      {
        let valid = true;
        let sta = this;
        let FromDataError = this.state.FromDataError;
    
        for (const [key, value] of Object.entries(errors)) {
          if (value.length > 0) {
    
            if (value == 'false') {
              FromDataError[key] = 'This field is required';
              sta.setState({ FromDataError })
            }
    
            valid = false;
          }
        }
    
        return valid;

      }

       
      };

      disabledDate = (value) =>{
        // const form = this.props.form;
       // Can not select days before today and today
        return  value < moment();
      }; 

      
      async componentWillMount()
      {
        let rates = await curencyRates()
        this.setState({rates})
        this.loadStripeJS()

      }
    

      componentDidMount()
      {
        console.log(this.props)
        this.fetchcards()
        // this.fetchPaymentSettings()
      }

      fetchcards = () => {
        console.log('enter fetch cards')
        let self = this;
        let param = {
            url: "/consumer/getpaymentmethodcard",
            method: "GET",
            headers: {
                "X-Auth": localStorage.getItem('authToken'),
            },
        };

        console.log('param', param)
        axios(param)
            .then(async (res) => {
                this.setState({ paymentloader: false })
                if (res.data.success) {
                  let cardIndex =_.findIndex(res.data.cards, function(o) { return o.primarycard == '1'; });
                  if(cardIndex >= 0)
                  {
                    this.chosseCard(cardIndex)
                  }

                  if(res.data.cards.length>0)
                  {
                    // this.setState({strip_cus:res.data.cards[0].strip_cus},()=>{
                    //   console.log('strip_cus',this.state.strip_cus)
                    // })

                  }
                  
                    this.setState({ carddetails: res.data.cards, strip_cus: res.data.customer_id === null ? "" : res.data.customer_id })
                    console.log('getpaymentmethodcard res', cardIndex)
                }
            })
            .catch((error) => {
                console.log("fetch error");
                console.log(error);
                //toast("Error getting profile data", { type: "error" });
            });
    }

    

    setvalue = (e,value, field) => {

      if(field=='expiryDate')

      {
        this.setState({expiryDate:e})
        if(e!=null)
        {
          // console.log('expiryDate',e.format('MM'))
          // let month=
          // let year=
          

          if (field === 'expiryDate') this.setState({ month: e.format('MM') })
          if (field === 'expiryDate') this.setState({ year: e.format('YYYY') })

        }
        

      }
      else{

        console.log('aaaaaaaaaaaa',e.target)
      let f = field
      // this.handleError(field, value);

     

      if(field=='cardnumber' && !this.isValidCardNumberInput(value) )
      {
          value = this.state.cardnumber
      }

      if (field === 'saveCard') {
                let saveCard = this.state.saveCard === 1? '' : 1
                this.setState({ saveCard },()=>{
                  console.log('saveCard',this.state.saveCard)
                  // this.handleError(field, this.state.saveCard);
                })
                value = saveCard
        
              }

      let { FromDataError } = this.state
      var valid_obj = {
        value: value,
        rules: e.target.getAttribute("validaterule"),
        message: e.target.getAttribute("validatemsg")
      }
      console.log('valid_obj',valid_obj)
  
      validation(valid_obj).then((error) => {
  
  
        console.log('aaaaaaaaaaa', error)
        console.log('field',field)
        FromDataError[field] = error
        this.setState({ FromDataError },()=>{
          console.log('kkkk',this.state.FromDataError)
        })
  
       
        
  
  
      })



      }

           

     
      if (field === 'cardnumber') this.setState({ cardnumber: value })
      if (field === 'name') this.setState({ name: value })

      if(field === 'cvc' && this.isValidCVC(value))
      {
        this.setState({ cvc: value })      
      }
      else if(field === 'cvc')
      {
        this.setState({ cvc: this.state.cvc })
      }
    
      // if(field === 'cvc' && value.length>4)
      // {
        
      //   this.setState({ cvc: this.state.cvc })
      // }
      // else
      // {
      //   if (field === 'cvc') this.setState({ cvc: value })
      // }
      if (field === 'primarycard') this.setState({ primarycard: value })

  }

  isValidCardNumberInput = (value) => {
      
    let isValid = true;
    if(!value || value === "") return isValid;

    try {
      if(value.length > value.trim().length) isValid = false;
      else if(value.length > 16 || value.includes('.')) isValid = false;
      else {

        let n = Number(value)
        if(!n) isValid = false;
  
      }

    } catch (error) {
      console.log("Invalid Card Number Input");
      isValid = false;
    }

    return isValid;
  }

  isValidCVC = (value) => {
      
    let isValid = true;
    if(!value || value === "") return isValid;

    try {
      if(value.length > value.trim().length) isValid = false;
      else if(value.length > 4 || value.includes('.')) isValid = false;
      else {

        let n = Number(value)
        if(!n) isValid = false;
  
      }

    } catch (error) {
      console.log("Invalid CVC");
      isValid = false;
    }

    return isValid;
  }

  

  chosseCard = async (index)=>{
    await this.setState({carduid:''})
    let carddetails = [...this.state.carddetails];
    let card  = {...carddetails[index]}
    if(card.hasOwnProperty('checked')){
      delete card['checked']
    }else{
      await this.setState({carduid:card.id})
      // await this.setState({carduid:card.strip_card})
      card['checked'] = true
      console.log('carduid',this.state.carduid)
    }
    carddetails = carddetails.map(d=>{
      delete d.checked
      return d
    })
    carddetails[index] = card
    //let carduid = this.state.carduid===e.target.value? '': e.target.value
    console.log("this.state.carduid===e.target.value",card)
     //console.log('carddetails',carddetails)
     var filteredArray = carddetails.filter(function(itm){
      return itm.checked;
    });
    console.log('carddetailsfilter',filteredArray)
     
    this.setState({carddetails,filteredArray})
      if(filteredArray.length>0)
      {
        this.setState({fieldDisable:true})
      }else
      {
        this.setState({fieldDisable:false})
      }
  }
  loadStripeJS(){
    let userdata = JSON.parse(localStorage.getItem('userdata'));
    const id = userdata.uid;

    let self = this;
    let param = {
      url: `/admin/settings/stripe/${id}`,
      method: "GET",
    };
    axios(param)
    .then(async (res) => {

        if(res.data.hasOwnProperty('stripe')){

          const script = document.createElement("script");
          script.src = "https://js.stripe.com/v2/";
          script.async = true;
          script.onload = () => {
            if(res.data.stripe.mode=='live')
            window.Stripe.setPublishableKey(res.data.stripe.live.publishable_key);
            else
            window.Stripe.setPublishableKey(res.data.stripe.sandbox.publishable_key);
          }
          document.body.appendChild(script);

             self.setState({gatewayDetails: res.data.stripe})
        }

    })
   
  }
  loadCard=()=> {
    return new Promise((resolve, reject) => {
      let self = this
  
    let FromDataError = this.state.FromDataError
  
    window.Stripe.card.createToken({
                number: this.state.cardnumber,
                cvc: this.state.cvc,
                exp_month: this.state.month,
                exp_year: this.state.year
              },function(status, response) {
                if (response.error) { 
                  // Show appropriate error to user
                  console.log('stripe err',response)
                  if(response.error.param=='number')
                  {
                    FromDataError.cardnumber = response.error.message
                  }
                  else if(response.error.param=='cvc')
                  {
                    FromDataError.cvc = response.error.message
                  }
                  else if(response.error.param=='exp_month')
                  {
                    FromDataError.expiryDate = response.error.message
                  }
                  else if(response.error.param=='exp_year')
                  {
                    FromDataError.expiryDate = response.error.message
                  }
                  self.setState({FromDataError})
                  reject()
                  
                } else {
                  // Get the token ID:
                  var token = response.id;
                  console.log('stripe response',response)
                  resolve(response)
                  // Save token mapping it to customer for all future payment activities
                }
              });
      })
  };
  


  processCard = async (hidemsg) => {
    let userdata=JSON.parse(localStorage.getItem('userdata'))
    let {name,saveCard} = this.state
    
    let currencyVal = localStorage.getItem('currency')
    currencyVal = currencyVal || 'USD'

    return new Promise((resolve, reject)=>{
      this.loadCard().then((resCard)=>{

              console.log('resCard',resCard)
             
      
        let cardExpYear = this.state.year
        let cardExpMonth = this.state.month

              let cardData = {
                "cardNumber": this.state.cardnumber,
                cardExpYear,
                cardExpMonth,
                // "cVV" : cvc,
                email:userdata.email,
                firstName:this.state.name,
                strip_token:resCard.id,
                uid:userdata.uid

              }
                let url =''
                if(this.state.strip_cus=='')
                {
                  url = "/strip/customerCreate"
                }
                else
                {
                  url = "/strip/customerUpdate"
                  cardData.customer=this.state.strip_cus

                }
                axios
                .post(url, cardData)
                .then(async (response) => {
                
                  let {data, success ,err} = response.data;
                  let d1 = []
                  d1['cvc'] = 0//cvc
                  console.log(".......isSuccess",success)
                  if(!success){
                    hidemsg()
                    message.destroy()
                    message.error(err)
                    reject("Error processing your card.")
                  }
                  else
                  {
                    if(saveCard===1){
                      d1['name'] = name
                      d1['uuid'] = userdata.uid
                      d1['primarycard'] = false
                      d1['currency'] = currencyVal
                      Object.assign(data, d1)
                      console.log(".......addpaymentmethod",data)
                      axios.post('/consumer/addpaymentmethod', data)
                      
                }
                console.log(".......now returning",success)
                let authTorizationdata= {
                  "creditCardToken": data.creditCardToken,
                  "customer": data.customer,
                  "transactionAmount":1,
                  cardExpYear,
                  cardExpMonth,
                  // "cVV":`${cvc}`,
                  "name":name,
                  "cardcompany":data.cardType,
                  "cardnumber":data.maskedPan
                }
                message.destroy()

                resolve(authTorizationdata)

                  }
                  

                }).catch(e=>{
                  hidemsg()
                  console.log(e)
                      message.error('Error processing your card.').then(() => message.destroy())
                      reject("Error processing your card.dfgdfgdfgdfgdfgd")
                }).finally(() => {
                  setTimeout(() => {
                    this.setState({fieldDisable: false})
                  }, 500);
                })
        }).catch((err)=>{
          hidemsg()
          this.setState({fieldDisable:false})
          console.log('pftoken error',err)
          message.error('Error in processing your card. Please recheck the details.')
        })
    })//promis close

   
    
  }

     handleConfirm= async () =>{
      let {carduid,name,cardnumber,month,year,cvc} = this.state

     
      // let isCVCValid = true;
      // if(!cvc || cvc.length < 3) {
      //   isCVCValid = false
      //   this.setState({FromDataError: {...this.state.FromDataError, cvc: "CVC Required"}})
      // }
      console.log('validate from',this.validateForm(this.state.FromDataError),this.state.FromDataError)

      if((this.validateForm(this.state.FromDataError) || carduid!=''))
      {
        
      const hidemsg = message.loading(`Please wait , we are booking your session`,0)
        this.setState({fieldDisable:true})
      
       
      
        console.log('state',this.state)

        if(carduid!='' || (name!=''))
        { 
          let cardData =    [...this.state.carddetails].find(d=>d.id===carduid);
          if(!carduid){
            cardData = await this.processCard(hidemsg)
          }else{
            cardData = {
                "creditCardToken": cardData.strip_card,
                "customer": cardData.strip_cus,
                "transactionAmount":1,
                "cardExpMonth": cardData.expirymonth,
                "cardExpYear": cardData.expiryyear,
                // "cVV":`${cardData.cvc}`,
                "name":cardData.name,
                "cardcompany":cardData.cardcompany,
                "cardnumber":cardData.cardnumber

              }
          }
      
         
        let fromData = 
          { 
            cardData:cardData,
            Package:{ "type":"consumer"
                  , "name": `${this.props.location.state.packageName}`,
                  "price": `${this.props.location.state.packPrice}`,
                  consumer_uid:this.props.location.state.ConsumerD.uid,
                    coach_uid:this.props.location.state.coachData.coach_uid,
                    package_uid:this.props.location.state.packageUid,
                    coach_email:this.props.location.state.coachData.email,
                  coachName:this.props.location.state.coachName,
                  consumer_email:this.props.location.state.ConsumerD.email,
                  consumer_name:this.props.location.state.ConsumerD.name,
                  coachImage:this.props.location.state.coachImage,
                  sessionDate:moment(this.props.location.state.selectedSlot.date).format('YYYY-MM-DD'),
                  slotTime:`${this.props.location.state.selectedSlot.from}-${this.props.location.state.selectedSlot.to}`,
                },
              session:{
                session0:{
                          name:this.props.location.state.packageName,
                        date:moment(this.props.location.state.selectedSlot.date).format('YYYY-MM-DD'),
                        from:this.props.location.state.selectedSlot.from,
                        to:this.props.location.state.selectedSlot.to
                      }
                      }
              
              
              }
              console.log('fromData',fromData)
          axios
          .post("/payment/checkout",fromData)
              .then(response =>{
                hidemsg()
                // if(this.state.filteredArray.length===0){
                // this.setState({fieldDisable:false})
                // }
                const { success } = response.data;
                console.log("Response:", response.data);
                if (success) {
                  message.loading(response.data.msg)

                  // toast(response.data.msg, { type: "success" });

                  SessionBookedNotify(response.data.data)
                  // this.handleClose()
                 setTimeout(() => {
                  this.props.history.push({
                    pathname: '/profile-consumer',
                    state: { tab: 'UserupcomingSession' }
                  })
                   
                 }, 4000);
                  
                } else {
                  hidemsg()
                   if(this.state.filteredArray.length===0){
                    this.setState({fieldDisable:false})
                }

                  message.error(response.data.msg)

                  // toast(response.data.msg, { type: "error" });
                }
              })
              .catch(()=>{
                hidemsg()
                if(this.state.filteredArray.length===0){
                  this.setState({fieldDisable:false})
                  }
              })

        }
        else
        {
          message.loading("Please add payment details")
          hidemsg()
          if(this.state.filteredArray.length===0){
            this.setState({fieldDisable:false})
            }
          // toast("Add payment details", { type: "error" });
        }

      }
      else
      {
          if(this.state.carddetails.length>0){
            message.error("Please select card or enter your card details")
          }
      }
          
      }
    
      handleClose() {
        // window.$('#bookpackagemodal').modal('hide');
    }
    
     intervals(startString, endString) {
      var start = moment(startString, 'YYYY-MM-DD hh:mm a');
      var end = moment(endString, 'YYYY-MM-DD hh:mm a');
    
      
      start.minutes(Math.ceil(start.minutes() / 15) * 15);
    
      var result = [];
    
    
    
      var current = moment(start);
      result.push(current.format('YYYY-MM-DD HH:mm'));
      result.push(end.format('YYYY-MM-DD HH:mm'));
      // while (current <= end) {
      //     result.push(current.format('YYYY-MM-DD HH:mm'));
      //     current.add(15, 'minutes');
      // }
      //tanmay change
      console.log(result)
    
      return result;
    }

    render() {

      let momentdate = UTCtoTimezone(this.state.timezone,timezoneUtc(this.state.coachTimezone,`${this.props.location.state.selectedSlot.date} ${this.props.location.state.selectedSlot.from}`),dateConfig.dateFormat)
      let momentfrom = UTCtoTimezone(this.state.timezone,timezoneUtc(this.state.coachTimezone,`${this.props.location.state.selectedSlot.date} ${this.props.location.state.selectedSlot.from}`),'HH:mm')
      let momentto = UTCtoTimezone(this.state.timezone,timezoneUtc(this.state.coachTimezone,`${this.props.location.state.selectedSlot.date} ${this.props.location.state.selectedSlot.to}`),'HH:mm')
        return (
            <React.Fragment>
            {/* { this.token  } */}
            {/* <HeaderTop /> */}

      {localStorage.getItem("authToken") == null ? (
        <HeaderTop />
      ) : (
          <HeaderLogout
            currencies={this.state.currencies}
            convertToCurrency={this.state.convertToCurrency}
            changeConvertToCurrency={this.changeConvertToCurrency}
          />
        )}

      <Header />
      <CommunicationUsers avatar_path={this.state.avatar_path}></CommunicationUsers>
      <section className="coacActionhWrap coacBookingWrap pt-5" >
        <div className="container ">
          <div className="heading text-center">
            <h3 >Review & Confirm Booking Details</h3>

           
            <span className="R_divider"></span>
          </div>
          <div className="row justify-content-md-center">
          <div className="col-md-4">
          <h3 style={{
            marginBottom: 10
          }}>Intro Call Scheduled:</h3>
          <p style={{
            marginBottom: 30
          }}>
            <b >Date & Time:</b>
           <b style={{textTransform: 'capitalize'}}></b>{momentdate} {momentfrom} - {momentto}
          </p>

          <h3 style={{
            marginBottom: 10
          }}>Booking Details:</h3>
          <p>
            <b>Session(s) booked:</b>
           <b style={{textTransform: 'capitalize'}}></b> {this.props.location.state.packageName}
          </p>          
          <p>
            <b>Total N.o. Sessions: </b>
            {this.props.location.state.total_sessions}
            
          </p>
          <p>
            <b>Total Cost: </b>
            {curencyFilter(this.props.location.state.packPrice,"",this.state.rates)}
            {/* {this.props.location.state.packPrice.split("##")[0]=='USD'? '$' : this.props.location.state.packPrice.split("##")[0]=='GBP'?'£':'€'}
           {` ${this.props.location.state.packPrice.split("##")[1]}`} */}
          
          </p>
          {/* <!-- <p>
            <b>Free intro call date & time:</b>
            Option 1
            Option 2
          </p> --> */}
          <p><small>
            <strong className="mt-0">Note – Intro Session is non chargeable. When you click confirm booking you are entering into an agreement to purchase the total cost shown. No payment will be taken at this stage. Following an intro session call, if you are happy to proceed with your coach your coaching date/s may be scheduled. Payment is processed once a session is scheduled.
            <br/>
            <br/>
            Single sessions: Full amount processed 24 hrs prior to session start time.
            <br/>
            Multi-session packages: Total package cost is divided by number of sessions and per session amount processed 24 hrs prior to session start time.
            <br/>
            Following an intro call if you do not wish to proceed with your booking, it can be cancelled and no payment will be taken. Coaching sessions may be scheduled from your dashboard.
          </strong></small></p>
          
        </div>
        <div className="col-md-4">
         
          <div className="editProfileForm confirmBooking">
                <form className="" action="" method="get" accept-charset="utf-8">
                  {/* <div className="form-group text-left">
                    <div className="areas d-flex mb-4 justify-content-start">
                      <div className="custom-control custom-checkbox checkbox-inline mx-2">
                        <input  type="radio" className="custom-control-input" id="customCheck6" name="example1"/>
                        <label className="custom-control-label" for="customCheck6">Debit Card</label>
                      </div>
                      <div className="custom-control custom-checkbox checkbox-inline mx-2">
                        <input  type="radio" className="custom-control-input" id="customCheck7" name="example1"/>
                        <label className="custom-control-label" for="customCheck7">Credit Card</label>
                      </div>
                      <div className="custom-control custom-checkbox checkbox-inline mx-2">
                        <input type="radio" className="custom-control-input" id="customCheck8" name="example1"/>
                        <label className="custom-control-label" for="customCheck8">Saved Cards</label>
                      </div>
                    </div>
                  </div>
                   */}
                  <div className="saveCardList">
                  {this.state.carddetails.length>0?
                  <h3>Saved Card(s)</h3>:''
                  }
                    
                     <ul>
                       {this.state.carddetails.length>0 && this.state.carddetails.map((cards,index)=>{

                        

                         return(
                           <li key={index}>
                           <div className="custom-control custom-checkbox checkbox-inline mx-2">
                             <input onClick={()=>this.chosseCard(index)} checked={cards.primarycard=='1'? true : false} type="checkbox" checked={cards.hasOwnProperty('checked')?true:false} className="custom-control-input" id={index} value={cards.id} name="saveCard"/>
                             <label className="custom-control-label d-flex align-items-center" for={index}>

                               {(cards.cardcompany.toUpperCase() =='VISA')?(<img  src={visa}/> ):
                               (cards.cardcompany.toUpperCase() == 'MASTERCARD')?(<img  src={mc}/>):
                               (<img  src={ae}/>)}
                               
                               <p><span>{cards.name}</span>{cards.cardnumber} - {cards.cardtype} card</p>
                             </label>
                           </div>
                          </li>
                          )


                       })}
                     
                       
                       
                       
                      
                     </ul> 
                  </div>
                  <h3>Enter your card details</h3>
                  <LoadingOverlay
                    active={this.state.loaderToken}
                    // spinner={<BounceLoader />}
                    spinner={
                      <div>
                        <CustomImageLoader image={LGspinner} animationType={"pulse"} />
                        <br />
                      </div>
                    }
                    text=""
                  >
                  
                   {/* card no */}
                   <div className="form-group" >
                   <input type="text" name="cardnumber" 
                     disabled ={this.state.fieldDisable}
                     
                     validaterule={['required', 'min|15','max|16']}
                     validatemsg={['Enter your card number', 'Card number minimum 15 digits!','Card number maximum 16 digits!']}
                    value={this.state.cardnumber} onChange={(e) => this.setvalue(e,e.target.value, 'cardnumber')}  placeholder="Card Number" className="form-control"/>
                        <p style={{ color: 'red' }}>{this.state.FromDataError.cardnumber == 'false' ? '' : this.state.FromDataError.cardnumber}</p>
                  
                       </div>
                    {/* card name */}
                  <div className="form-group">
                    <input type="text" name="name"
                    disabled ={this.state.fieldDisable}
                      validaterule={['required']}
                      validatemsg={['Enter your name']}
                      onChange={(e) => this.setvalue(e,e.target.value, 'name')} style={{ color: 'black' }}  placeholder='Name Of Card Holder' className="form-control"/>
                        <p style={{ color: 'red' }}>{this.state.FromDataError.name == 'false' ? '' : this.state.FromDataError.name}</p>
                  
                  </div>
                  
                  {/* Card exp and cvv */}
                  <div className="row">
                      {/* Card exp*/}
                    <div className="col-sm-8" >
                    <DatePicker disabledDate={this.disabledDate}  disabled ={this.state.fieldDisable}  onChange={(e) => this.setvalue(e,'','expiryDate')} value={this.state.expiryDate} picker="month"    size="large" defaultValue={moment()}  format={"MM-YYYY"} allowClear={false}   />
                       
                       </div>
                    
                    {/* Card cvc*/}
                   <div className="col-sm-4" >
                      <div className="form-group">
                      <input type="text" name="cvc" 
                         disabled ={this.state.fieldDisable}
                        validaterule={['required',  'min|3']}
                        validatemsg={['Enter CVV / CVC', 'CVC required']}
                          onChange={(e) => this.setvalue(e,e.target.value, 'cvc')} placeholder='CVC' className="form-control"
                          value={this.state.cvc}/>
                        <p style={{ color: 'red' }}>{this.state.FromDataError.cvc == 'false' ? '' : this.state.FromDataError.cvc}</p>

                    </div>
                  </div>
                    </div>
                    <div className="row" style={{marginLeft: "0px"}}>
                  <div className="areas mb-3">
                    <div className="custom-control custom-checkbox checkbox-inline">
                      <input type="checkbox" validaterule={['required']}
                        validatemsg={['This field is required.']} disabled={this.state.fieldDisable} className="custom-control-input" onChange={(e) => this.setvalue(e,e.target, 'saveCard')}  id="customCheck9" name="example1"/>
                      <label className="custom-control-label" for="customCheck9">Save this card</label>
                      <p style={{ color: 'red' }}>{this.state.FromDataError.saveCard == 'false' ? '' : this.state.FromDataError.saveCard}</p>
                    </div>
                  </div>
                  </div>
                  </LoadingOverlay>
                  <button className="defaultBtn" onClick={this.handleConfirm} type="button">Confirm Booking</button>
                 
                </form>
              </div>
        </div>

             
             </div>
          


          
         
     
         

          
        </div>

    
      </section>

           <FooterLink />
    </React.Fragment>
        );
    }
}

export default BookingPayment;