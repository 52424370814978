import React from 'react'
import { Link } from 'react-router-dom'
import { donutSvg, quality, tickSvg, new_coach } from '../../assets/images'
import { avatar } from "../../assets/img";
import Pagination from '../pagination/pagination';
import { toast } from "react-toastify";
import axios from "../../config/axios";
import { Redirect } from "react-router-dom";
import message from '../../utils/mesaages';

class CoachProfileList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      coachListPerPage: 9,
      favorites: [],
      favCoachList: [],
      ispublicmode:true,
      redirect: false,
      //mergedCoachList: [],
    }
    this.addToFavorite = this.addToFavorite.bind(this)
    this.fetchFavoriteCoaches = this.fetchFavoriteCoaches.bind(this)
    //this.mergedCoachList = this.mergedCoachList.bind(this)
  }

  // addToFavorite =(favorite, uid) => {
  //   console.log("ADD TO FAVORITE",favorite)
  //   //console.log("cosumer id", uid)
  //   const { favorites } = this.state;

  //   if (!favorites.includes(favorite.coach_uid)) {
  //     let newArray = favorites.push(favorite.coach_uid)
  //     this.setState({favorites: newArray})
  //   }
  //   console.log("Favorite coach list", favorites)
  //   //this.setState({iconClass: "in-wishlist"}) //[...this.state.favorites, favorite.coach_uid]
  // }
  componentDidMount() {
    window.scrollTo(0,0);
    this.handlepublicview()
    let self = this
    setTimeout(() => {
      self.fetchFavoriteCoaches()
    }, 800)
  }

  componentWillUnmount()
  {
    window.scrollTo(0,0);
  }

  handlepublicview = () =>{
    let userdata = JSON.parse(localStorage.getItem('userdata'));
    if (userdata == undefined) {
      this.setState({ispublicmode:true})
      // this.props.history.push('/login')
    } else {
      if (userdata !== null && userdata !== undefined) {

        if (userdata.account_type === 'user') {
          this.setState({ispublicmode:false})
        }
      }
    }

  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.ListCoaches !== prevProps.ListCoaches) {
      this.setState({currentPage: 1})
      //this.mergedCoachList(this.props.ListCoaches);
    }
  }

  // mergedCoachList (ListCoaches) {
  //   console.log("Iam hereeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",ListCoaches)
  //   if(ListCoaches && this.state.favCoachList) {
  //     let allFav = this.state.favorites
  //     let CoachLists = ListCoaches
  //     CoachLists = CoachLists.filter(a => {
  //       return !allFav.includes(a.coach_uid)
  //     })
  //     let mergedList = this.state.favCoachList.concat(CoachLists)
  //     this.setState({mergedCoachList: mergedList})
  //   } else {
  //     this.setState({mergedCoachList: this.state.favCoachList})
  //   }
  //   //console.log("OOOOO", this.state.mergedCoachList)
  // }

  async addToFavorite(favorite, uid)
    {
      
      if(uid===undefined)
      {
        this.setState({redirect:true})
        return
      }
       
         let marketinterest = this.state.favorites;
        
         
         let check= marketinterest.includes(favorite.coach_uid)
        if(!check){
         
            marketinterest.push(favorite.coach_uid);
            await this.handleSubmitFavorite(favorite.coach_uid, uid)
        }else{
            
            Array.prototype.remove = function() {
                var what, a = arguments, L = a.length, ax;
                while (L && this.length) {
                    what = a[--L];
                    while ((ax = this.indexOf(what)) !== -1) {
                        this.splice(ax, 1);
                    }
                }
                return this;
            };
        
            marketinterest.remove(favorite.coach_uid);
            await this.handleRemoveFromFavorite(favorite.coach_uid, uid)

           
        }
        this.setState({favorites: marketinterest})
        //console.log("arrya value of add to favorite", this.state.favorites)
    }

    //_______Fetch favorite coaches________
    fetchFavoriteCoaches() {
      let self = this
      //console.log("dhurrrrrrrrrrrrrrrrrrrrr", this.props.Consumer_uid);
      axios.get(`/consumer/fetch-content/${this.props.Consumer_uid}`)
        .then((res) => {
          if (res.data.success) {
            //toast("Successfully added to favorites", { type: "success" });
            console.log(res.data)
            self.setState({favorites: res.data.value.arrayCoachid, favCoachList: res.data.value.favCoachDetails})
          } else {
            //toast("unable to load favorite coaches", { type: "error" });
            console.log("Your fav coachlist is empty")
          }
          //self.mergedCoachList(self.props.ListCoaches)
          //console.log("LIST OF FAV COACH",self.state.favCoachList)
        })
        .catch((err) => {
          console.log(err);
        });
    };

    //_______Store favorite coach in db_______
    handleSubmitFavorite = (coach_uid, user_id) => {
      console.log("clicked certi--", coach_uid);
      let coachid = {coach_uid: coach_uid}
      axios
        .post(`/consumer/favorite-coach/${user_id}`, {
          headers: { "x-auth": this.props.consumer_token },
          data: coachid,
        })
        .then((res) => {
          if (res.data.success) {
            message.success("Successfully added to favorites");
          } else {
            message.error("failed to add in favorites");
          }
          this.fetchFavoriteCoaches()
        })
        .catch((err) => {
          console.log(err);
        });
    };

    //_______Remove from favorite_______

    handleRemoveFromFavorite = (coach_uid, user_id) => {
      //console.log("clicked certi--", user_id);
      let coachid = {coach_uid}
      axios
        .post(`/consumer/remove-favorite/${user_id}`, {
          headers: { "x-auth": this.props.consumer_token },
          data: coachid,
        })
        .then((res) => {
          if (res.data.success) {
            message.success("Successfully removed from favorites");
          } else {
            message.error("failed to remove from favorites");
          }
          this.fetchFavoriteCoaches()
        })
        .catch((err) => {
          console.log(err);
        });
    };

  paginate = (pageNumber) =>{
    this.setState({currentPage: pageNumber})
  }
    render(){
      const { redirect } = this.state;

      if (redirect) {
        return <Redirect to='/login'/>;
      }
      const indexOfLastCoachDetails = this.state.currentPage * this.state.coachListPerPage
      const indexOfFirstCoachDetails = indexOfLastCoachDetails - this.state.coachListPerPage
      //console.log("fav coach list", this.state.favCoachList)
      console.log("props coach list", this.props.ListCoaches)
        return(
           <div>
              <div className="row">

              {/* BEFORE SEARCH FAVORITE COACH LIST */}
              {this.props.ListCoaches.length===0 && this.state.favCoachList && this.state.favCoachList.slice(indexOfFirstCoachDetails, indexOfLastCoachDetails).map((coach, i) =>{
                let rawaddress=JSON.parse(coach.address)
                return(
                    
                      <div className="col-sm-6 col-lg-4 mt-4">
                <div className="searchPeopleList">
                {/* <img src={donutSvg} alt="LG-Img" width="25px" className="verified"/> */}
                    <div 
                    key={i}
                    onClick={(e) => this.addToFavorite(coach, this.props.Consumer_uid)} 
                    className={this.state.favorites.includes(coach.coach_uid) ? "icon-wishlist in-wishlist" : "icon-wishlist"}></div>
                    <div className="sImgHolder">
                    <img className="sUserImg"  alt="LG-Img" src={coach.avatar_path!=null?coach.avatar_path:avatar}/>
                      {coach.has_membership ?( <img className="sbi" alt="LG-Img" width="35px" src={quality}/>):("")}
                      </div>
                    <h4>{coach.name}</h4>
                    {coach.user_title!=null &&(
                     <h6 className="color-dark-grey">{coach.user_title}</h6>

                  )}
                    {JSON.parse(coach.coaching_category).slice(0,2).map(cat =>{
                          return(
                           <h6 className="category-bold"><span>{cat.value}</span></h6>
                          )
                      })}
                    <h5>{coach.city_public===1?rawaddress && rawaddress.city && rawaddress.city+',':''} 
                        {rawaddress && rawaddress.country}</h5>
                    <div className="ratingView">
                      <i className="fa fa-star rActive" aria-hidden="true"></i>
                      <i className="fa fa-star rActive" aria-hidden="true"></i>
                      <i className="fa fa-star rActive" aria-hidden="true"></i>
                      {/* <i className="fa fa-star" aria-hidden="true"></i>
                      <i className="fa fa-star" aria-hidden="true"></i> */}
                    </div>
                    <p className="color-grey">{coach.short_bio && coach.short_bio.substring(0,120)}...</p>
                    <Link
                              to={{
                                 pathname: "/profile-coach-view",
                                
                                state: { 
                                  coachData: coach,
                                  ConsumerD: {uid:this.props.Consumer_uid, name:this.props.Consumer_name, email:this.props.Consumer_email },

                                  Consumer_uid: this.props.Consumer_uid,
                                  Consumer_name:this.props.Consumer_name,
                                  Consumer_email:this.props.Consumer_email
                                }
                              }}
                              className="defaultBtn"
                            >
                              {/* <img
                                width="20px"
                                alt="LG-Img"
                                className="mr-1"
                                src={tickSvg}
                              />{" "} */}
                              View Details
                            </Link>
                  </div>
                  </div>
                  )
                  })}
              



              {/* AFTER SEARCH COACH LIST WITH FAVORITE COACHES */}

                {this.props.ListCoaches && this.props.ListCoaches.slice(indexOfFirstCoachDetails, indexOfLastCoachDetails).map((coach, i) =>{
           let rawaddress=JSON.parse(coach.address)

           let star = coach.avg_rating
                let arr = [1,2,3]
          return(
              
                 <div className="col-sm-6 col-lg-4 mt-4">
          <div className="searchPeopleList">
          {/* <img src={donutSvg} alt="LG-Img" width="25px" className="verified"/> */}
               <div 
               key={i}
               onClick={(e) => this.addToFavorite(coach, this.props.Consumer_uid)}
              
                 

               className={this.state.favorites.includes(coach.coach_uid) ? "icon-wishlist in-wishlist" : "icon-wishlist"}></div>

                  {coach.newCoach===0?
                      <div className="justJoined"><span>Just Joined</span></div>
                    :''
                    }
               <div className="sImgHolder">
                 
                <img className="sUserImg"  alt="LG-Img" src={coach.avatar_path!=null?coach.avatar_path:avatar}/>
                {coach.has_membership ?( <img className="sbi" alt="LG-Img" width="35px" src={quality}/>):("")}
                </div>
               <h4>{coach.name}</h4>
               {coach.user_title!=null &&(
                     <h6 className="color-dark-grey">{coach.user_title}</h6>

                  )}
               {JSON.parse(coach.coaching_category)&&JSON.parse(coach.coaching_category).slice(0,2).map(cat =>{
                    return(
                    <h6 className="category-bold"><span>{cat.value}</span></h6>
                    )
                })}
               <h5>{coach.city_public===1?rawaddress && rawaddress.city && rawaddress.city+',':''} 
                   {rawaddress && rawaddress.country}</h5>

               {console.log('rates',coach.name,star)}
               <div className="ratingView">
               {arr.map((a,index2)=>{
                 

                      return(
                        <i key={index2} className={star>=a?('fa fa-star rActive'):('fa fa-star')} aria-hidden="true"></i>

                      )

                      
                      })}
                {/* <i className="fa fa-star" aria-hidden="true"></i>
                <i className="fa fa-star" aria-hidden="true"></i> */}
               </div>
               <p className="color-grey">{coach.short_bio && coach.short_bio.substring(0,120)}...</p>
               <Link
                        to={{
                          //  pathname: "/profile-coach-view",
                          pathname: this.state.ispublicmode === true ?  `/coach-profile/${coach.name}/${coach.coach_uid}`:`/profile-coach-view/${coach.coach_uid}`,

                          state: { 
                            coachData: coach,
                            ConsumerD: {uid:this.props.Consumer_uid, name:this.props.Consumer_name, email:this.props.Consumer_email },
                          
                          }
                        }}
                        className="defaultBtn"
                      >
                        {/* <img
                          width="20px"
                          alt="LG-Img"
                          className="mr-1"
                          src={tickSvg}
                        />{" "} */}
                        View Details
                      </Link>
                         
            </div>
            </div>
             )
            })}
            
            </div>
            {this.props.ListCoaches.length === 0 ? (
              <Pagination postsPerPage={this.state.coachListPerPage} totalPosts={this.state.favCoachList.length} paginate={this.paginate} currentPage={this.state.currentPage}/>
            ) : (
              <Pagination postsPerPage={this.state.coachListPerPage} totalPosts={this.props.ListCoaches.length} paginate={this.paginate} currentPage={this.state.currentPage}/>
            )}
           </div>
        )
    }
}
export default CoachProfileList