import React from "react";
import { Link } from "react-router-dom";
import { Header } from "../headers";


class CommingSoon extends React.Component {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    return (
      <div>
        <Header />
        <br />
        <br />
        <br />
        <br />
        <section className="upcomingWrap">
          <div className="container">
            <div className="webinnerContent text-center">
              <div
                className="heading text-center"
                
                data-aos-duration="3000"
              >
                <h3>Comming Soon....</h3>
                <br />
                <p className="">This page is under development. </p>
                <p>Will Update soon</p>
                <span className="R_divider"></span>
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <Link to="/" className="defaultBtn">
                <span>
                  <svg
                    id="Capa_1"
                    fill="#fff"
                    enableBackground="new 0 0 512 512"
                    height="25"
                    viewBox="0 0 512 512"
                    width="30"
                    // xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path d="m143.5 398.5c35.099 0 67.2-12.599 91.8-33.6-13.5-15.901-23.699-34.2-30.3-54-15 16.8-36.9 27.6-61.5 27.6-45.601 0-83.5-36.899-83.5-82.5s37.899-82.5 83.5-82.5c45.888 0 82.5 37.502 82.5 82.5 0 78.082 63.246 142.5 142.5 142.5 78.6 0 143.5-63.9 143.5-142.5s-64.9-142.5-143.5-142.5c-35.099 0-67.2 12.599-91.8 33.6 13.5 15.901 23.699 34.2 30.3 54 15-16.8 36.899-27.599 61.5-27.599 45.601 0 83.5 36.899 83.5 82.5s-37.899 82.5-83.5 82.5c-45.888 0-82.5-37.502-82.5-82.5 0-78.082-63.246-142.5-142.5-142.5-78.6-.001-143.5 63.899-143.5 142.499s64.9 142.5 143.5 142.5z" />
                    </g>
                  </svg>
                  <svg>
                    <g>
                      <path d="m143.5 398.5c35.099 0 67.2-12.599 91.8-33.6-13.5-15.901-23.699-34.2-30.3-54-15 16.8-36.9 27.6-61.5 27.6-45.601 0-83.5-36.899-83.5-82.5s37.899-82.5 83.5-82.5c45.888 0 82.5 37.502 82.5 82.5 0 78.082 63.246 142.5 142.5 142.5 78.6 0 143.5-63.9 143.5-142.5s-64.9-142.5-143.5-142.5c-35.099 0-67.2 12.599-91.8 33.6 13.5 15.901 23.699 34.2 30.3 54 15-16.8 36.899-27.599 61.5-27.599 45.601 0 83.5 36.899 83.5 82.5s-37.899 82.5-83.5 82.5c-45.888 0-82.5-37.502-82.5-82.5 0-78.082-63.246-142.5-142.5-142.5-78.6-.001-143.5 63.899-143.5 142.499s64.9 142.5 143.5 142.5z" />
                    </g>
                  </svg>
                </span>
                LifeGuru
              </Link>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default CommingSoon;
