import React from "react";
import { Link} from "react-router-dom";
import ConfirmModal from "../view/ConfirmModal";
import UpdatePackage from "./UpdatePackage";
import EditPackageRequest from "./EditPackageRequest";
import {curencyFilter,curencyRates} from '../../../utils/currencyConvert';
import {Accordion, Card, Collapse, Button, Dropdown, DropdownButton} from "react-bootstrap";
import message,{getHelpText} from '../../../utils/mesaages';
import validation from '../../../Validate/validator';
import axios from '../../../config/axios'
import { AddPackage, AddTag } from "../add";
import CustomImageLoader from "react-custom-image-loader.";
import LoadingOverlay from "react-loading-overlay";
import {LGspinner} from "../../../assets/images";
class Package extends React.Component {
  constructor(props, context) {
    super(props);
    this.token = localStorage.getItem("authToken");
    this.state = {
      session_details_list: [],
      open: false,
      updateShow: false,
      ActivePackId:'',
      editRequestShow: false,

      packagescollection: [],
      packageCount: '',
      ActivePack:[],
      packageLoader: true,
      rates:''
    };
  }

  handleUpdateShow = (e,pack) => {
    this.setState({ updateShow: !this.state.updateShow , ActivePack:pack});
  };

  handleEditRequestShow = async (e, pack) => {
    await this.setState({ editRequestShow: !this.state.editRequestShow , ActivePack:pack});
  };
  async componentWillMount(){
    let rates = await curencyRates();
    this.setState({rates})
  }


  componentDidMount() {
    // if (this.props.packagedata.session_details !== "") {
    //   var list_data = JSON.parse(this.props.packagedata.session_details);
    //   this.setState({ session_details_list: list_data });
    // }

    if (this.props.packagescollection !== "") {
      let value = this.props.packagescollection
        value = value.map((collection)=>{
          collection["filterCurrency"] = curencyFilter(collection.price,'',this.state.rates)
          return collection;
        })
     
      this.setState({ packagescollection: value });
    }

    //this.getPackages();
  }

  componentDidUpdate(prevProps) {
    // if(prevProps.packagedata.session_details !== this.props.packagedata.session_details) {
    //   var list_data = JSON.parse(this.props.packagedata.session_details);
    //   this.setState({ session_details_list: list_data });
    // }
    if(prevProps.packagescollection !== this.props.packagescollection) {
      let value = this.props.packagescollection
        value = value.map((collection)=>{
          collection["filterCurrency"] = curencyFilter(collection.price,'',this.state.rates)
          return collection;
        })
      this.setState({ packagescollection: value, packageLoader: false });
    }
  }


  async getPackages() {
    console.log('fetch public package')
    const id = this.props.coachUid;



    axios
      .get(`/users/packages/${id}`, {
        // headers: {
        //   "X-Auth": this.token,
        // },
      })
      .then(async(res) => {
        let value = res.data.value
        value = value.map((collection)=>{
          collection["filterCurrency"] = curencyFilter(collection.price,'',this.state.rates)
          return collection;
        })

        await this.setState({ packagescollection: value, packageCount: res.data.count[0].totalpackage });
        console.log("found new data" )
        console.log(res.data)
        console.log(this.state.packagescollection)
        //this.packageCheck();
      })
      .catch((error) => {
        console.error(error);
      });
  }
  


  handleClick = (id) => {
    //this.props.handleDeletePackage(id);
    const hideMessage = message.loading('Please wait while we are deleting package', 0)
    axios
      .post(`/users/packages/updatepackage/${id}`,{}, {
        headers: { "X-Auth": localStorage.getItem("authToken") },
      })
      .then((res) => {
        hideMessage()
        if (res.data.success) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
        this.getPackages();
      });
  };

  handleActivateStatus = (id) => {
    //this.props.handleActivatePackageStatus(id);
    const formData = {
      isactive: 1,
    };
    axios
      .put(`/users/packages/updatepackageStatus/${id}`, formData, {
        headers: { "X-Auth": this.token },
      })
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
        this.getPackages();
      });
  };

  handleInactivateStatus = (id) => {
    //this.props.handleInactivatePackageStatus(id);
    const formData = {
      isactive: 0,
    };
    axios
      .put(`/users/packages/updatepackageStatus/${id}`, formData, {
        headers: { "X-Auth": this.token },
      })
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
        this.getPackages();
      });
  };

  handlebook=(data) =>{
    this.props.handlePackageBook(data)
  }

  handleEditPackage = (formData) => {
    const id = formData.id;
    console.log('update packag....',formData)
    const hideMessage = message.loading('Please wait while we are updating', 0)
   // return false;
    axios
      .put(`/users/packages/updatepackage/${id}`, formData, {
        headers: {
          "X-Auth":this.token,
        },
      })
      .then((res) => {
        hideMessage()
        console.log("ress----,",res)
        if (res.data.success) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
        this.getPackages();
      })
      .catch((error) => {});
  };



  render() {
    // console.log("bbbbb-----,",Number.parseFloat(10 * 1
    // ).toFixed(3))
    console.log("bbbbb-----,",Number.parseFloat(10 * 1.19
      ).toFixed(3))
    console.log("SESSION DETAILS LIST :=====")
    //console.log(this.props.packagedata)
    console.log(this.state.session_details_list)

    // const duration = 15;

    // var hours = duration / 60;
    // var rhours = Math.floor(hours);
    // var minutes = (hours - rhours) * 60;
    // var rminutes = Math.round(minutes);
    // const duration_time = rhours + (rhours > 1 ? " hours " : " hour ") + rminutes + " minutes";

    // let statusButton;
    // if (1 === 1) {
    //   statusButton = (
    //     <p
    //       className="dropdown-packgae" style={{cursor:'pointer'}}
    //       onClick={() => {
    //         this.handleInactivateStatus(this.props.packagedata.uid);
    //       }}
    //     >
    //       <i className="fa fa-times-circle"></i>{" "}Deactivate
        
    //     </p>
    //   );
    // } else if (0 === 0) {
    //   statusButton = (
    //     <p
         
    //       className="dropdown-packgae"
    //       onClick={() => {
    //         this.handleActivateStatus(this.props.packagedata.uid);
    //       }}
    //     >
    //       <i className="fa fa-check-circle"></i>{" "}Activate
         
    //     </p>
    //   );
    // }

    // let packPack = this.props.packagedata
    return (
      <React.Fragment>

{this.props.account_type === "coach" && this.state.updateShow && (  <UpdatePackage                        
                        // icon={"fa fa-edit"}
                        isEdit={true}
                        pacdata={this.state.ActivePack}
                        handleEditPackage={this.handleEditPackage}
                        pack_id={this.state.ActivePack.uid}
                        sessionlength={this.props.sessionlength}
                        updateShow={this.state.updateShow}
                        handleUpdateShow={this.handleUpdateShow}
                        sortingHelpText={this.props.sortingHelpText}
                        totalPackageCount={this.props.totalPackageCount}
                        token={this.props.token}
                        
                      />
)}

{this.props.account_type === "coach" && this.state.editRequestShow && (  
                            <EditPackageRequest                        
                            // icon={"fa fa-edit"}
                           // isEdit={true}
                            pacdata={this.state.ActivePack}
                           // handleEditPackage={this.props.handleEditPackage}
                            pack_id={this.state.ActivePack.uid}
                            sessionlength={this.props.sessionlength}
                            editRequestShow={this.state.editRequestShow}
                            handleEditRequestShow={this.handleEditRequestShow}
                            editPackageRequestHelpText={this.props.editPackageRequestHelpText}
                            totalPackageCount={this.props.totalPackageCount}
                            token={this.props.token}
                            
                            />
                          )}



       {/* {this.props.account_type === "coach" ? (
              <div className="col-12">
                <div className="row no-gutters">
                  {this.props.account_type === "coach" && (
                    <AddPackage
                      pacdata={this.state}
                      handleChange={this.handleChange}
                      handleSubmit={this.handleSubmitPackage}
                      handlePackType={this.handlePackType}
                      handleResetPackage={this.handleResetPackage}
                      sessionlength={this.props.sessionLength}
                      validateForm={this.validateForm}
                      Intromessage={this.state.Intromessage}
                      sortingHelpText={this.state.sorting_help_text}
                      totalPackageCount={this.state.packageCount}
                      //handlerSortingOrder={this.handlerSortingOrder}
                    />
                  )}
                </div>
              </div>
            ) : (
              ""
            )} */}
            <LoadingOverlay
        // spinner={<BounceLoader />}
        active={this.state.packageLoader}
        spinner={
          <div>
            <CustomImageLoader image={LGspinner} animationType={"pulse"} />
            <br />
          </div>
        }
        // text="Loading your packages..."
      >

        <Accordion>
        {this.state.packagescollection &&
                this.state.packagescollection.length > 0 &&
                this.state.packagescollection.map((element) => { 

                var sub_sessions = JSON.parse(element.session_details);
                const duration = element.duration;

                var hours = duration / 60;
                var rhours = Math.floor(hours);
                var minutes = (hours - rhours) * 60;
                var rminutes = Math.round(minutes);
                const duration_time = rhours + (rhours > 1 ? " hours " : " hour ") + rminutes + " minutes";

      return ( 
        <div className={element.isactive ? "packborder" : ""}>
          <Card>
          <div className="packageList">
            <div className="packageInfo">
              <h3>
                {element.title}
                <br />
              </h3>
              <p>
                <i className="fa fa-certificate" aria-hidden="true"></i>{" "}
                {/* {this.props.packagedata.total_sessions}   */}
                { sub_sessions.length}  
               
                {element.total_sessions > 1 ? ( " Sessions " ) : ( " Session ")} 
                
                <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                {duration_time}{" "}
              </p>
              {/* <Link
                to="profile-coach"
                data-toggle="collapse"
                data-target={`#package_collapse_${this.props.packagedata.uid}`}
              >
                Description <i className="fa fa-angle-down"></i>
              </Link> */}
              {/* <Button
              variant="outline-info"
                onClick={() => this.setState({open: !this.state.open})}
                aria-controls={`package_collapse_${element.uid}`}
                aria-expanded={this.state.open}
              >
                Description <i className="fa fa-angle-down"></i>
              </Button> */}
            <Accordion.Toggle as={Button} variant="outline-info" eventKey={element.uid} style={{textDecoration: "none"}}
               >
                  Description <i className="fa fa-angle-down"></i>
               </Accordion.Toggle>

              {/* <Collapse in={this.state.open}> */}
              <Accordion.Collapse eventKey={element.uid}>
              {/* <div
                id={`package_collapse_${element.uid}`}
                className="collapse"
              > */}
                <div className="short__desc__caption">
                  <p>{element.description}</p>

                  <div className="container_pk_item">
                    <div>
                      {sub_sessions &&
                        sub_sessions.map((item) => (
                          <div>
                            {item.sessionName && (
                                <div className="packageList" style={{ margin: "10px" }}>
                                  <div className="packageInfo">
                                    <h5>
                                      {item.sessionName && item.sessionName}
                                    </h5>
                                    <p>{item.sessionDescription}</p>

                                    <div className="packagedropdownbutton">
                                      <div className="dropdown dropleft testi_options">
                                        <span className="badge badge-Light">
                                          {" "}
                                          {item.Length && (
                                            <h6 style={{color: "black"}}>
                                              <i
                                                className="fa fa-clock-o"
                                                aria-hidden="true"
                                              ></i>
                                              &nbsp;
                                              {item.Length} minutes
                                            </h6>
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                 
                </div>
               
              {/* </div> */}
              </Accordion.Collapse>
              {/* </Collapse> */}
            </div>

            <div className="packageActon">
              <h4>
                {element.filterCurrency}
                {/* {this.props.baseCurrency &&
                this.props.baseCurrency === this.props.convertToCurrency ? (
                  <>
                    {this.props.packagedata.price.split("##")[0] === "USD" ? (
                      <>
                        ${" "}
                        {Number.parseFloat(
                          this.props.packagedata.price.split("##")[1]
                        ).toFixed(2)}
                      </>
                    ) : (
                      
                      <>
                      {this.props.packagedata.price.split("##")[0] === "EUR" ?(
                        <>
                         ${" "}
                        {Number.parseFloat(
                          this.props.packagedata.price.split("##")[1] * 1.18
                        ).toFixed(2)}
                        </>
                      ) :(
                      <>
                         ${" "}
                        {Number.parseFloat(
                          this.props.packagedata.price.split("##")[1] * 1.32
                        ).toFixed(2)}
                      </>)}
                       
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {this.props.convertToCurrency === "USD" ? (
                      <>
                        {this.props.packagedata.price.split("##")[0] ===
                        "USD" ? (
                          <>
                            ${" "}
                            {Number.parseFloat(
                              this.props.packagedata.price.split("##")[1]
                            ).toFixed(2)}
                          </>
                        ) :(
                          <>
                          {this.props.packagedata.price.split("##")[0] ==="EUR" ? (
                          <>
                           ${" "}
                            {Number.parseFloat(
                              this.props.packagedata.price.split("##")[1] * 1.18
                            ).toFixed(2)}
                          </>):(
                          <>
                           ${" "}
                            {Number.parseFloat(
                              this.props.packagedata.price.split("##")[1] * 1.32
                            ).toFixed(2)}
                          </>)}
                           
                          </>
                        )}
                      </>
                    ) : (
                      <>
                      {this.props.convertToCurrency === "GBP" ?(
                      <>
                         {this.props.packagedata.price.split("##")[0] ===
                        "GBP" ? (
                          <>
                            £{" "}
                            {Number.parseFloat(
                              this.props.packagedata.price.split("##")[1]
                            ).toFixed(2)}
                          </>
                        ) : (
                          <>
                          {this.props.packagedata.price.split("##")[0] ==="EUR" ? (
                          <>
                           £{" "}
                            {Number.parseFloat(
                              this.props.packagedata.price.split("##")[1] / 1.12
                            ).toFixed(2)}
                          </>):(
                          <>
                           £{" "}
                            {Number.parseFloat(
                              this.props.packagedata.price.split("##")[1] / 1.31
                            ).toFixed(2)}
                          </>)}
                           
                          </>
                        )}
                      </>):(
                      <>
                       {Number.parseFloat(
                          this.props.packagedata.price.split("##")[1] *
                            this.props.findcurrency
                        ).toFixed(2)}{" "}
                        {this.props.convertToCurrency}
                      </>)}
                       
                      </>
                    )}
                  </>
                )}{" "} */}
              </h4>

              {this.props.account_type === "coach" ? (<div>
                {/* {this.props.packagedata.isactive ?(<Link className="defaultBtn" to="/comming-soon">
                Book
              </Link>):(<Link className="defaultBtn" to="/comming-soon">
                Not Available
              </Link>)} */}
              {!element.isactive ? (
                  <button type="button" className="btn btn-danger" style={{marginLeft: "10px"}}>
                    Inactive
                  </button>
                ) : (
                  ''
                )}
                </div>
                
              ):(<div></div>)}
              

            {this.props.account_type === "user" ?(<div>
                {element.isactive ? (
            
                   <div>
                     {localStorage.getItem("authToken") ==null ?(<div>
                            <Link className="defaultBtn" to="/registration" >
                            Book
                            </Link>
                          </div>
                     ):( <button className="package_button"
                    
                          onClick={()=>{
                            const data={
                              packname:element.title,
                              packUid:element.uid,
                              packprice:element.price.split("##")[0] ==='USD'? (element.price.split("##")[1])
                              :(element.price.split("##")[0] ==='EUR' ? (element.price.split("##")[1] * 1.19):(
                                element.price.split("##")[1] * 1.32
                              ))
                            }
                            this.handlebook(data)
                          }}
                          
                          >
                            <Link className="defaultBtn"   to={{
                                pathname: `/intro-session/${this.props.coachUid}/${element.uid}`,
                                state: { 
                                  
                                
                                  
                                
                                }
                              }}>
                                Book
                            </Link>
                      </button>)}
                     
                    </div>
                     ):(<Link className="defaultBtn">
                       Not Available
                      </Link>)}
                    </div>):(<div></div>)}

            </div>

            {this.props.account_type === "coach" && (
            <div>
              {/* <div className="packagedropdownbutton">
                <div className="dropdown dropleft testi_options">
                  <button
                    type="button"
                    className="btn dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                  </button>

                  <div
                    className="dropdown-menu"
                    // style={{ backgroundColor: "white" }}
                  >
                    <div className="col-sm-8">
                     
                   <li><UpdatePackage
                      
                        icon={"fa fa-edit"}
                        isEdit={true}
                        pacdata={this.props.packagedata}
                        handleEditPackage={this.props.handleEditPackage}
                        pack_id={this.props.packagedata.uid}
                      /></li> 
                    </div>
                    <br />

                   <li><ConfirmModal
                      fun_name={"Delete"}
                      icon={"fa fa-trash"}
                      pack_id={this.props.packagedata.uid}
                      handleClick={this.handleClick}
                    /></li> 

                    <li>{statusButton}</li>
                  </div>
                </div>

                
              </div> */}
              <div className="packagedropdownbutton">
                <div className="dropdown dropleft testi_options">
               
                
                  {/* <Dropdown>
                  <Dropdown.Toggle variant="outline-success">
                  <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                  </Dropdown.Toggle>
                      
                    <Dropdown.Menu className="dropdown-menu">
                      <Dropdown.Item onClick={(e)=>this.handleUpdateShow(e,element)}>
                      <i className="fa fa-edit" style={{padding:'0px 0px 4px 9px'}} ></i>{" "}Edit   
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <ConfirmModal
                          fun_name={"Delete"}
                          icon={"fa fa-trash"}
                          pack_id={element.uid}
                          handleClick={this.handleClick}
                        />
                      </Dropdown.Item>
                      <Dropdown.Item>{statusButton}</Dropdown.Item>
                      {element.packDetails ? (
                        <Dropdown.Item onClick={this.handleEditRequestShow}>
                          <i className="fa fa-edit" style={{padding:'0px 0px 4px 9px'}} ></i>
                          Request Changes
                        </Dropdown.Item>
                      ) : ''}
                    </Dropdown.Menu>
                     
                  </Dropdown> */}
                         <Dropdown className="dropdown dropleft testi_options">
                            <Dropdown.Toggle variant="outline-success">
                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                            </Dropdown.Toggle>
                                
                              <Dropdown.Menu className="dropdown-menu">
                               
                              {element.packDetails ? (
                                  <Dropdown.Item onClick={(e)=>this.handleUpdateShow(e,element)}>
                                    <i className="fa fa-edit" style={{padding:'0px 0px 4px 9px'}} ></i>
                                    {" "}Request Edit
                                    </Dropdown.Item>
                                ) : (
                              <Dropdown.Item onClick={(e)=>this.handleUpdateShow(e,element)}>
                              <i className="fa fa-edit" style={{padding:'0px 0px 4px 9px'}} ></i>{" "}Edit   
                              </Dropdown.Item>
                                )}

                        {element.dateExpired === null || element.dateExpired ? (
                                <Dropdown.Item>
                                <ConfirmModal
                                 fun_name={"Delete"}
                                 icon={"fa fa-trash"}
                                 pack_id={element.uid}
                                 handleClick={this.handleClick}
                               />
                                </Dropdown.Item>
                              ):''}
                                {element.isactive === 1 ? (
                                 <Dropdown.Item>
                                   <p
                                     className="dropdown-packgae"
                                     onClick={() => {
                                       this.handleInactivateStatus(element.uid);
                                     }}
                                   >
                                    <i className="fa fa-times-circle"></i>{" "}Deactivate
                                   </p>
                                 </Dropdown.Item>
                               ) : (
                                 <Dropdown.Item>
                                   <p
                                    className="dropdown-packgae"
                                     onClick={() => {
                                       this.handleActivateStatus(element.uid);
                                     }}
                                   >
                                      <i className="fa fa-check-circle"></i>{" "}Activate
                                   </p>
                                 </Dropdown.Item>
                               )}
                              
                              </Dropdown.Menu>
                              
                            </Dropdown>
                  </div>
              </div>
            </div>
            )}


                         
          </div>
          </Card> 
          <hr /> 
        </div>
       
            );


          })}
           </Accordion>
        
      </LoadingOverlay>
      </React.Fragment>
    );
  }
}
export default Package;
