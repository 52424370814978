import React from "react";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import LinkedIn from "react-linkedin-login-oauth2";
import axios from "../../../config/axios";
import { toast } from "react-toastify";
import message from '../../../utils/mesaages';

class LinkedLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      userType: "",
      userdata: "",
      token: "",
      // userId:"",
      // name:"",
      // email:"",
      accessToken: " ",
      code: "",
      errormsg: "",
    };
  }

  responseLinked = (response) => {
    console.log("linkedin", response);
    this.setState({
      code: response.code,
      errormsg: response.error,
    });
    const formData = {
      username: "",
      password: "",
    };

    axios
      .post("/login", formData)
      .then((response) => {
        if (response.data.token !== undefined && response.data.result === true) {
          this.setState({
            isLoggedIn: response.data.result,
            userType: response.data.userdata.account_type,
            userdata: response.data.userdata,
            token: response.data.token,
          });
          //this.props.history.push("/profile-coach");
          localStorage.setItem("authToken", response.data.token);
          localStorage.setItem(
            "coachingCategory",
            response.data.userdata.coaching_category
          );
        } else if (response.data.result === false) {
          //alert(response.data.msg);
          message.error(response.data.msg);
        }
      })
      .catch((error) => {
        message.error("Some error occured");
        console.log(error);
      });
  };

  render() {
    let linkedinContent;
    let rDirectUrl =
      process.env.NODE_ENV === "production" || process.env.NODE_ENV === "local"
        ? "https://lifeguru.io/linkedin"
        : "http://localhost:3000/linkedin";
    //console.log("Redirect url ", rDirectUrl)
    if (this.state.isLoggedIn && !_.isEmpty(this.state.code)) {
      linkedinContent = (
        <div>
          {this.state.userType === "coach" ? (
            <div>
              return{" "}
              <Redirect
                to={{
                  pathname: "/profile-coach",
                  state: {
                    user: this.state.userdata,
                    token: this.state.token,
                  },
                }}
              />
            </div>
          ) : (
            <div>
              return{" "}
              <Redirect
                to={{
                  pathname: "/profile-consumer",
                  state: {
                    user: this.state.userdata,
                    token: this.state.token,
                  },
                }}
              />
            </div>
          )}
        </div>
      );
    } else {
      linkedinContent = (
        <LinkedIn
          clientId="86x3pl84s4l7n9"
          redirectUri={rDirectUrl}
          scope="r_liteprofile,r_emailaddress"
          onFailure={this.responseLinked}
          onSuccess={this.responseLinked}
          className="my-linkedin-button-class"
          redirectPath="/linkedin"
        />
      );
    }
    return <div>{linkedinContent}</div>;
  }
}
export default LinkedLogin;
