import React from 'react'
import { trust, worldwide, clock1, leadership, sales } from '../../assets/images'

class  LifeGuruKeyFeatures extends React.Component{
  constructor(props) {
    super(props)    
  }
  createMarkup = (data) => {
    return {__html: data};
  }

  render(){
    return(
        <div>
            <section className="featuresWrap">
              <div className="container">
                <div className="heading text-center mb-3">
                  <h3>Coach Master key features</h3>
                  <span className="R_divider"></span>
                </div>

                <div className="row justify-content-center">
                  {this.props.featuresdata === [] ? "" : 
                    this.props.featuresdata.map((each, index) => {
                      return <div className="col-sm-6 col-md-4 mt-5 mb-5">
                        <div className="featuresItem">
                          <div className='fi_content'>
                            <div className="featuresImg">
                              <img width="75px" src={each.p_image} alt="LG-Img"/>
                            </div>
                            <hr/>
                            <div dangerouslySetInnerHTML={this.createMarkup(each.description)}/>
                          </div>
                        </div>
                    </div>
                    })
                  }
                  
                </div>
              </div>
            </section>
        </div>
    )
  }
}
export default LifeGuruKeyFeatures