import React from "react";
import { FooterLink } from "../components/footers";
import { Header, HeaderLogout, HeaderTop } from "../components/headers";
import axios from "../config/axios";
import { LGspinner, Webiner_coach1, } from "../assets/images";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { Button, Col, Row } from "react-bootstrap";
import HeaderSecondTo from "../components/corporate/Header/HeaderSecondTo";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";
import { message } from "antd";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

class Webinar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal :  false,
      title :  "",
      description :  "",
      loader: false,
      loader1: false,
      webinardata: [],
      upcomaingwebinarcategory: "",
      archivedwebinarcategory: "",
      completewebinarDeta: [],
      upcomingwebinarDeta: [],
      completemsg: "",
      upcomingmsg: "",
      totalCompletePages: "",
      totalCompleteRows: "",
      totalUpcomingPages: "",
      totalUpcomingRows: "",
      completewebinarpage: 1,
      upcomingWebinarpage: 1,
      filterCategory: [{ value: "2", tittle: "Career", selected: false, searcheable: "career" }, { value: "3", tittle: "Executive", selected: false, searcheable: "motivational" }, { value: "1", tittle: "Business", selected: false, searcheable: "business" }, { value: "5", tittle: "Life", selected: false, searcheable: "personal development" }, { value: "6", tittle: "Transformational", selected: false, searcheable: "transformational" }, { value: "4", tittle: "Health and Wellbeing", selected: false, searcheable: "health" }]
    }
    this.showHideModal = this.showHideModal.bind(this);
  }

  componentDidMount() {
    //window.scrollTo(0,0);
    this.getWebinarContent()
    if (new URLSearchParams(this.props.location.search).get("filter")) {
      this.state.filterCategory.map((item, index) => {
        if (item.searcheable.toLowerCase() === new URLSearchParams(this.props.location.search).get("filter").trim()) {
          this.state.filterCategory[index].selected = true
          this.setState({ upcomaingwebinarcategory: item.value, archivedwebinarcategory: item.value }, () => {
            this.getArchivedWebinar("")
            this.getUpcomingWebinar("")
          })
        }
      })
    } else {
      if (localStorage.getItem("subdomain")) {
        if ((localStorage.getItem("corporate_token") || localStorage.getItem("corporate_user_token"))) {
          this.getArchivedWebinar("")
          this.getUpcomingWebinar("")
        }
      } else {
        this.getArchivedWebinar("")
        this.getUpcomingWebinar("")
      }
    }
  }

  showHideModal(val,title,description){
    this.setState({showModal:val,title : title,description:description});
  }

  // componentDidUpdate() {
  //   if (new URLSearchParams(this.props.location.search).get("filter")) {
  //     this.state.filterCategory.map((item, index) => {
  //       if (item.tittle.toLowerCase() === new URLSearchParams(this.props.location.search).get("filter")) {
  //         this.state.filterCategory[index].selected = true
  //         this.setState({ upcomaingwebinarcategory: item.value, archivedwebinarcategory: item.value }, () => {
  //           this.getArchivedWebinar("")
  //           this.getUpcomingWebinar("")
  //         })
  //       }
  //     })
  //   } else {
  //     this.getArchivedWebinar("")
  //     this.getUpcomingWebinar("")
  //   }
  // }

  getWebinarContent = () => {
    let self = this
    axios.get('/cmshome/webinarContent')
      .then(async (response) => {
        if (response.status === 200) {
          self.setState({
            webinardata: response.data.value.webinarrow,
          })
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  getArchivedWebinar = (e) => {
    this.setState({ loader: true })
    if (localStorage.getItem("corporate_user_token")) {
      axios.get(`/webinar/all-archived-webinars?hr_id=${JSON.parse(localStorage.getItem("corporate_user_userdata")).referal_id}&page=${this.state.completewebinarpage}&limit=20&category=${this.state.archivedwebinarcategory}`).then(async (responce) => {
        if (responce.data.success) {
          this.setState({ loader: false, completemsg: responce.data.msg, completewebinarDeta: responce.data.webinars, totalCompletePages: responce.data.total_pages, totalCompleteRows: responce.data.total_rows })
        }
      }).catch((e) => {
        this.setState({ loader: false })
        console.log(e)
      })
    } else {
      axios.get(`/webinar/all-archived-webinars?page=${this.state.completewebinarpage}&limit=20&category=${this.state.archivedwebinarcategory}`).then(async (responce) => {
        if (responce.data.success) {
          this.setState({ loader: false, completemsg: responce.data.msg, completewebinarDeta: responce.data.webinars, totalCompletePages: responce.data.total_pages, totalCompleteRows: responce.data.total_rows })
        }
      }).catch((e) => {
        this.setState({ loader: false })
        console.log(e)
      })
    }
  }

  getUpcomingWebinar = (e) => {
    this.setState({ loader1: true })
    if (localStorage.getItem("corporate_user_token")) {
      axios.get(`/webinar/all-upcoming-webinars?hr_id=${JSON.parse(localStorage.getItem("corporate_user_userdata")).referal_id}&page=${this.state.upcomingWebinarpage}&limit=20&category=${this.state.upcomaingwebinarcategory}`).then(async (responce) => {
        if (responce.data.success) {
          this.setState({ loader1: false, upcomingmsg: responce.data.msg, upcomingwebinarDeta: responce.data.webinars, totalUpcomingPages: responce.data.total_pages, totalUpcomingRows: responce.data.total_rows })
        }
      }).catch((e) => {
        this.setState({ loader1: false })
        console.log(e)
      })
    } else {
      let url = `/webinar/all-upcoming-webinars?page=${this.state.upcomingWebinarpage}&limit=20&category=${this.state.upcomaingwebinarcategory}`;
      if(localStorage.getItem("ML_TOKEN")){
        url = `${url}&type=2`;
      }else{
          url = `${url}&type=1`;
      }
      axios.get(`${url}`).then(async (responce) => {
        if (responce.data.success) {
          this.setState({ loader1: false, upcomingmsg: responce.data.msg, upcomingwebinarDeta: responce.data.webinars, totalUpcomingPages: responce.data.total_pages, totalUpcomingRows: responce.data.total_rows })
        }
      }).catch((e) => {
        this.setState({ loader1: false })
        console.log(e)
      })
    }
  }

  onCategoryChange = (e) => {
    this.setState({ archivedwebinarcategory: e, completewebinarpage: 1 }, () => this.getArchivedWebinar())
  }

  onUpcomingCategoryChange = (e) => {
    this.setState({ upcomaingwebinarcategory: e, upcomingWebinarpage: 1 }, () => this.getUpcomingWebinar())
  }

  onSessionsPageChange = (page) => {
    let page1 = page.selected + 1;
    this.setState({ completewebinarpage: page1 }, () =>
      this.getArchivedWebinar()
    );
  };

  onUpcomingPageChange = (page) => {
    let page1 = page.selected + 1;
    this.setState({ upcomingWebinarpage: page1 }, () =>
      this.getUpcomingWebinar()
    );
  }

  joinWebinar = (webinar_id) => {
    this.setState({ loader: true });

    axios.post(`/webinar/join-webinar`, { webinar_id, }, { headers: { "x-auth": this.token, }, }).then((res) => {
      if (res.data.success) {
        let url = res.data.bbb_joining_link;
        this.setState({ loader: false });
        window.open(url, "_blank");
      } else {
        this.setState({ loader: false });
        message.error(res.data.msg);
      }
    }).catch((err) => {
      this.setState({ loader: false });
      console.log(err);
      message.error("Something Went Wrong");
    });
  };


  render() {
    const { totalCompletePages, totalUpcomingPages, loader, loader1 } = this.state
    //console.log("WEBINARRRR DATA", this.state.webinardata)
    return (
      <div>
        {this.state.showModal && <ShowDescription show={this.state.showModal} close={this.showHideModal} title={this.state.title} description ={this.state.description}/>}
        {
          !localStorage.getItem("subdomain") && (localStorage.getItem("authToken") == null ? (<HeaderTop />) : (<HeaderLogout />))
        }
        {
          localStorage.getItem("subdomain") ? (<HeaderSecondTo />) : (<Header />)
        }

        {
          localStorage.getItem("subdomain") ? (!localStorage.getItem("corporate_token") && !localStorage.getItem("corporate_user_token")) ? (
            <section className="upcomingWrap webinarSearchWrap " style={{ "minHeight": "50vh" }}>
              <div className="container">
                <div className="webinnerContent">
                  {/* Login Section Start */}
                  <div className="searchHead">
                    <h3>Please login to your account</h3>
                  </div>
                  <Row className="justify-content-center align-items-center text-center mt-2">
                    <Col md={6}>
                      <Link className="btn defaultBtn btn-lg w-100" style={{ "gap": "7px" }} to="/employee-login"><i className="fa fa-user" ></i>{" "} Employee Login</Link>
                    </Col>
                    <Col md={6}>
                      <Link to="/signin" className="btn defaultBtn btn-lg w-100" style={{ "gap": "7px" }}><i className="fa fa-briefcase"></i>{" "} Company Login</Link>
                    </Col>
                  </Row>
                </div>
              </div>
            </section>
          ) : (
            <section className="upcomingWrap webinarSearchWrap ">
              <div className="container">
                <div className="webinnerContent">
                  {/* Upcoming Webinar Section Start */}

                  <div className="searchHead">
                    <h3>Upcoming Webinars</h3>
                    <div className="searchShortBy">
                      <label>Filter by: </label>
                      <select onChange={(e) => this.onUpcomingCategoryChange(e.target.value)}>
                        <option value={""}>Category Name</option>
                        {
                          this.state.filterCategory.map((items) => (
                            <option value={items.value} selected={items.selected}>{items.tittle + " Coaching"}</option>
                          ))
                        }
                        {/* <option value={"2"}>Career Coaching</option>
                        <option value={"3"}>Executive Coaching</option>
                        <option value={"1"}>Business Coaching</option>
                        <option value={"5"}>Life Coaching</option>
                        <option value={"6"}>Transformational Coaching</option>
                        <option value={"4"}>Health and Wellbeing Coaching</option> */}
                      </select>
                    </div>
                  </div>
                  <LoadingOverlay active={loader1} spinner={<CustomImageLoader image={LGspinner} animationType={"pulse"} />} className="webiner_loader" >
                    <div className="row">
                      <Col md={12}>
                        {
                          this.state.upcomingwebinarDeta.length === 0 && (
                            <Row style={{ "minHeight": "100px", "alignItems": "center", "justifyContent": "center" }}>
                              {this.state.upcomingmsg}
                            </Row>
                          )
                        }
                        {
                          this.state.upcomingwebinarDeta.length > 0 && (
                            <Row style={{ "minHeight": "100px", "alignItems": "center" }}>
                              {
                                this.state.upcomingwebinarDeta.map((each) => (
                                  <div className="col-md-6 col-sm-6 col-lg-3" key={each.id}>
                                    <div className="webiner_card" style={{ "backgroundImage": "url(" + each.image + ")", "backgroundRepeat": "no-repeat", "backgroundSize": "cover", "backgroundAttachment": "local" }}>
                                      <div className="webiner_card_content text-light">
                                        <img width={60} height={60} src={each.coach_image || Webiner_coach1} className="img-fluid rounded-circle" alt={each.name} />
                                        <h4 className="webiner_coach_name mt-2"> {each.name} </h4>
                                        <p className="webiner_coach_details"> {each.coach_bio?.substring(0, 60)} </p>
                                        <h3 className="webiner_name" title={each.webinar_topic}> {each.webinar_topic} </h3>
                                        <p className="webinar_description"> {each.script?.substring(0, 60)} </p>
                                        <div className="text-uppercase">
                                        <small style={{ fontSize: '0.7rem' }}> {moment.utc(each.date_string).tz("America/New_York").format("D MMM, YYYY - h:mm A")}  <b>EST</b> </small>
                                          {/* <small style={{ fontSize: ".7rem" }}>
                                            {moment(each.date_time).local().format(
                                              "D MMM, YYYY - h:mm A"
                                            )}
                                          </small> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              }
                            </Row>
                          )
                        }
                      </Col>
                      <Col>
                        {this.state.completewebinarDeta.length > 0 && (
                          <div className="result_pagination mt-4">
                            <ReactPaginate
                              breakLabel="..."
                              nextLabel=">"
                              onPageChange={this.onUpcomingPageChange}
                              pageRangeDisplayed={6}
                              pageCount={totalUpcomingPages}
                              previousLabel="<"
                              activeClassName="active"
                              disabledClassName="disable"
                              renderOnZeroPageCount={null}
                            />
                          </div>
                        )}
                      </Col>
                    </div>
                  </LoadingOverlay>

                  {/* Upcoming Webinar Section End */}

                  {/* Archive Webinar Section Start */}

                  {
                    (localStorage.getItem("corporate_token") || localStorage.getItem("corporate_user_token")) ? (
                      <>
                        <div className="searchHead mt-4">
                          <h3>Archived Webinars</h3>
                          <div className="searchShortBy">
                            <label>Filter by: </label>
                            <select onChange={(e) => this.onCategoryChange(e.target.value)}>
                              <option value={""}>Category Name</option>
                              {
                                this.state.filterCategory.map((items) => (
                                  <option value={items.value}>{items.tittle + " Coaching"}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                        <LoadingOverlay active={loader} spinner={<CustomImageLoader image={LGspinner} animationType={"pulse"} />} className="webiner_loader" >
                          <div className="row">
                            <Col md={12}>
                              {
                                this.state.completewebinarDeta.length === 0 && (
                                  <Row style={{ "minHeight": "100px", "alignItems": "center", "justifyContent": "center" }}>
                                    {this.state.completemsg}
                                  </Row>
                                )
                              }
                              {
                                this.state.completewebinarDeta.length > 0 && (
                                  <Row style={{ "minHeight": "100px", "alignItems": "center" }}>
                                    {
                                      this.state.completewebinarDeta.map((each) => (
                                        <div className="col-md-6 col-sm-6 col-lg-3" key={each.id}>
                                          <div className="webiner_card" style={{ "backgroundImage": "url(" + each.image + ")", "backgroundRepeat": "no-repeat", "backgroundSize": "cover", "backgroundAttachment": "local" }}>
                                            <div className="webiner_card_content text-light">
                                              <img width={60} height={60} src={each.coach_image || Webiner_coach1} className="img-fluid rounded-circle" alt={each.coach_name} />
                                              <h4 className="webiner_coach_name mt-2"> {each.name} </h4>
                                              <p className="webiner_coach_details"> {each.coach_bio?.substring(0, 60)} </p>
                                              <h3 className="webiner_name" title={each.webinar_topic}> {each.webinar_topic} </h3>
                                              <p className="webinar_description"> {each.script?.substring(0, 60)} </p>
                                              <div className="text-uppercase">
                                                {/* <small style={{ fontSize: ".7rem" }}>
                                                  {moment(each.date_time).local().format(
                                                    "D MMM, YYYY - h:mm A"
                                                  )}
                                                </small> */}
                                                <small style={{ fontSize: '0.7rem' }}> {moment.utc(each.date_string).tz("America/New_York").format("D MMM, YYYY - h:mm A")}  <b>EST</b> </small>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                    }
                                  </Row>
                                )
                              }
                            </Col>
                            <Col>
                              {this.state.completewebinarDeta.length > 0 && (
                                <div className="result_pagination mt-4">
                                  <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={this.onSessionsPageChange}
                                    pageRangeDisplayed={6}
                                    pageCount={totalCompletePages}
                                    previousLabel="<"
                                    activeClassName="active"
                                    disabledClassName="disable"
                                    renderOnZeroPageCount={null} />
                                </div>
                              )}
                            </Col>
                          </div>
                        </LoadingOverlay>
                      </>
                    ) : (
                      // <div style={{ "minHeight": "300px" }}></div>
                      ""
                    )
                  }

                  {/* Archive Webinar Section End */}

                </div>
              </div>
            </section>
          ) : (

            <section className="upcomingWrap webinarSearchWrap ">
              <div className="container">
                <div className="webinnerContent">
                  {/* Upcoming Webinar Section Start */}

                  <div className="searchHead">
                    <h3>Upcoming Webinars</h3>
                    <div className="searchShortBy">
                      <label>Filter by: </label>
                      <select onChange={(e) => this.onUpcomingCategoryChange(e.target.value)}>
                        <option value={""}>Category Name</option>
                        {
                          this.state.filterCategory.map((items) => (
                            <option value={items.value} selected={items.selected}>{items.tittle + " Coaching"}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <LoadingOverlay active={loader1} spinner={<CustomImageLoader image={LGspinner} animationType={"pulse"} />} className="webiner_loader" >
                    <div className="row">
                      <Col md={12}>
                        {
                          this.state.upcomingwebinarDeta.length === 0 && (
                            <Row style={{ "minHeight": "100px", "alignItems": "center", "justifyContent": "center" }}>
                              {this.state.upcomingmsg}
                            </Row>
                          )
                        }
                        {
                          this.state.upcomingwebinarDeta.length > 0 && (
                            <Row style={{ "minHeight": "100px", "alignItems": "center" }}>
                              {
                                this.state.upcomingwebinarDeta.map((each) => (
                                  <div className="col-md-6 col-sm-6 col-lg-4" key={each.id} >
                                    <div className="webiner_card" style={{ "backgroundImage": "url(" + each.image + ")", "backgroundRepeat": "no-repeat", "backgroundSize": "cover", "backgroundAttachment": "local" }}>
                                      <div className="webiner_card_content text-light">
                                        <img width={60} height={60} src={each.coach_image || Webiner_coach1} className="img-fluid rounded-circle" alt={each.name} />
                                        <h4 className="webiner_coach_name mt-2"> {each.name} </h4>
                                        <p className="webiner_coach_details"> {each.coach_bio?.substring(0, 60)} </p>
                                        <h3 className="webiner_name" title={each.webinar_topic}> {each.webinar_topic} </h3>
                                        <p className="webinar_description">
    <span>
      {each.script.substring(0, 90)}
      <a href={`/webinars-details/${each.coach_uid}/${each.id}`} style={{ color: "#00e1ba" }}>[...]</a>
    </span>
 
</p>
                                        <div className="text-uppercase">
                                          {/* <small style={{ fontSize: ".7rem" }}>
                                            {moment(each.date_time).local().format(
                                              "D MMM, YYYY - h:mm A"
                                            )}
                                          </small> */}

                                          <small style={{ fontSize: '0.7rem' }}> {moment.utc(each.date_string).tz("America/New_York").format("D MMM, YYYY - h:mm A")}  <b>EST</b> </small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              }
                            </Row>
                          )
                        }
                      </Col>
                      <Col>
                        {this.state.completewebinarDeta.length > 0 && (
                          <div className="result_pagination mt-4">
                            <ReactPaginate
                              breakLabel="..."
                              nextLabel=">"
                              onPageChange={this.onUpcomingPageChange}
                              pageRangeDisplayed={6}
                              pageCount={totalUpcomingPages}
                              previousLabel="<"
                              activeClassName="active"
                              disabledClassName="disable"
                              renderOnZeroPageCount={null}
                            />
                          </div>
                        )}
                      </Col>
                    </div>
                  </LoadingOverlay>

                  {/* Upcoming Webinar Section End */}

                  {/* Archive Webinar Section Start */}

                  {
                    (localStorage.getItem("authToken")) ? (
                      <>
                        <div className="searchHead mt-4">
                          <h3>Archived Webinars</h3>
                          <div className="searchShortBy">
                            <label>Filter by: </label>
                            <select onChange={(e) => this.onCategoryChange(e.target.value)}>
                              <option value={""}>Category Name</option>
                              {
                                this.state.filterCategory.map((items) => (
                                  <option value={items.value} selected={items.selected}>{items.tittle + " Coaching"}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                        <LoadingOverlay active={loader} spinner={<CustomImageLoader image={LGspinner} animationType={"pulse"} />} className="webiner_loader" >
                          <div className="row">
                            <Col md={12}>
                              {
                                this.state.completewebinarDeta.length === 0 && (
                                  <Row style={{ "minHeight": "100px", "alignItems": "center", "justifyContent": "center" }}>
                                    {this.state.completemsg}
                                  </Row>
                                )
                              }
                              {
                                this.state.completewebinarDeta.length > 0 && (
                                  <Row style={{ "minHeight": "100px", "alignItems": "center" }}>
                                    {
                                      this.state.completewebinarDeta.map((each) => (
                                        <div className="col-md-6 col-sm-6 col-lg-3" key={each.id}>
                                          <div className="webiner_card" style={{ "backgroundImage": "url(" + each.image + ")", "backgroundRepeat": "no-repeat", "backgroundSize": "cover", "backgroundAttachment": "local" }}>
                                            <div className="webiner_card_content text-light">
                                              <img width={60} height={60} src={each.coach_image || Webiner_coach1} className="img-fluid rounded-circle" alt={each.coach_name} />
                                              <h4 className="webiner_coach_name mt-2"> {each.name} </h4>
                                              <p className="webiner_coach_details"> {each.coach_bio?.substring(0, 60)} </p>
                                              <h3 className="webiner_name" title={each.webinar_topic}> {each.webinar_topic} </h3>
                                              <p className="webinar_description">
  
    <span>
      {each.script.substring(0, 90)}
      <a href={`/webinars-details/${each.coach_uid}/${each.id}`} style={{ color: "#00e1ba" }}>[...]</a>
    </span>
  
</p>
                                              <div className="text-uppercase">
                                                <small style={{ fontSize: ".7rem" }}>
                                                  {/* {moment(each.date_time).local().format(
                                                    "D MMM, YYYY - h:mm A"
                                                  )} */}
                                                  <small style={{ fontSize: '0.7rem' }}> {moment.utc(each.date_string).tz("America/New_York").format("D MMM, YYYY - h:mm A")}  <b>EST</b> </small>
                                                </small>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                    }
                                  </Row>
                                )
                              }
                            </Col>
                            <Col>
                              {this.state.completewebinarDeta.length > 0 && (
                                <div className="result_pagination mt-4">
                                  <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={this.onSessionsPageChange}
                                    pageRangeDisplayed={6}
                                    pageCount={totalCompletePages}
                                    previousLabel="<"
                                    activeClassName="active"
                                    disabledClassName="disable"
                                    renderOnZeroPageCount={null} />
                                </div>
                              )}
                            </Col>
                          </div>
                        </LoadingOverlay>
                      </>
                    ) : (
                      // <div style={{ "minHeight": "300px" }}></div>
                      ""
                    )
                  }

                  {/* Archive Webinar Section End */}

                </div>
              </div>
            </section>
          )
        }

        <FooterLink />
      </div >
    );
  }
}

export default Webinar;


const ShowDescription = (props)=>{

  return (
    <>
     <Modal
        show={props.show}
        onHide={()=>{props.close(false,"","")}}
        keyboard={false}
      >
        <Modal.Body style={{
          paddingTop: 20,
          marginLeft: 10,
          marginTop: 10
        }}>
         
          <h3>{props.title}</h3>
          <br/>
          <p style={{color:"black",fontSize:"15px", marginBottom:"0em", textAlign:"left"}}>{props.description}</p>
       </Modal.Body>
       <Modal.Footer>
          <button className="btn btn-secondary" onClick={()=>{props.close(false,"","")}} >Close</button>
          <a className="btn btn-success" href="/user_registration">Sign Up For the webinar</a>
       </Modal.Footer>
      </Modal>
    </>
  );
}