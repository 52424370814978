import Axios from "axios";
import {API_URL,BACKEND_URL,BLOG_URL} from "./custom";

let token = localStorage.getItem("authToken");
const axios = Axios.create({
   baseURL: API_URL,
});

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    fetch(API_URL+"/consumer/get-update-users-record", {
      method: "GET",
      headers: {
        "X-Auth": token,
      },
    }).then((result) => {
      result
        .json()
        .then((resp) => {
          if (resp.success == false) {
            if (token != null) {
              localStorage.clear();
              window.location = "/login#logout";
            }
          }
        })
        .catch((error) => {
          console.log("ApiExp", error);
        });
    });

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with response data

    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

const AdminApi = BACKEND_URL+"/api";
// _____________ MOV FILE CONVERSION API URL _____________ //

// _____________ BLOG SITE URL ______________ //
const blogUrl = BLOG_URL;

export { AdminApi, blogUrl };
export default axios;
