import React from 'react'
import { filter, closeSvg, quality, dArrowblack, LGspinner } from "../../../assets/images";
import axios from '../../../config/axios';
import { Link } from 'react-router-dom'
import LoadingOverlay from "react-loading-overlay";
import StarRatings from 'react-star-ratings';
import { Modal } from "react-bootstrap";
import CustomImageLoader from 'react-custom-image-loader.'
class CoachFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: '',
      timezone: '',
      CoachingStyle: '',
      CoachingExpHrs: '',
      Hourlyrate: '',
      rating: 0,
      MemberOfPropessionalBody: 0,
      searchloader: false,
      show: false,
    }
  }



  handleChange = (e) => {


    console.log('radio button',e.target.name,e.target.value)


    e.persist()
    this.setState({
      [e.target.name]: e.target.value
    })

    if (e.target.checked && e.target.name == 'MemberOfPropessionalBody') {
      this.setState({ [e.target.name]: 1 }, () => {
        // console.log(this.state.MemberOfPropessionalBody)
      })
    }
    else if (e.target.name == 'MemberOfPropessionalBody' && !e.target.checked) {
      this.setState({ [e.target.name]: 0 }, () => {
        // console.log(this.state.MemberOfPropessionalBody)
      })
    }



  }


  handleSubmit = (e) => {
    this.handleShow()

    e.preventDefault()
    const formData = {
      language: this.state.language,
      timezone: this.state.timezone,
      CoachingStyle: this.state.CoachingStyle,
      CoachingExpHrs: this.state.CoachingExpHrs,
      Hourlyrate: this.state.Hourlyrate,
      rating: this.state.rating,
      MemberOfPropessionalBody: this.state.MemberOfPropessionalBody,
      Category: this.props.category,
      Tags: this.props.tags,

    }
    this.setState({ searchloader: true })
    axios.post("/consumer/findcoach-filterCoaches", formData, {
      headers: { "X-Auth": this.props.token }
    })
      .then(res => {
        console.log('res', res)
        this.setState({ searchloader: false })

        this.props.handle(res.data.value)
      })
  }

  onStarClick = (nextValue, prevValue, name)  =>{
    console.log('next' , nextValue)
    console.log('prevValue' , prevValue)
    this.setState({rating: nextValue});
}

  clearState = (e) => {
    this.setState({
      language: '',
      timezone: '',
      CoachingStyle: '',
      CoachingExpHrs: '',
      Hourlyrate: '',
      rating: 0,
      MemberOfPropessionalBody: 0,
    })
  }

  handleShow = () => {
    this.setState({show: !this.state.show})
    this.clearState()
  }

 



  render() {



    return (
      <div>
        <Link to="#" className="filterApply"
          onClick={this.handleShow}
          //data-toggle="modal" 
          //data-target="#filterModal"
          >
            <img width="18px"
            className="mr-2" src={filter} alt="LG-Img" /> 
            Advanced Filters
        </Link>

        <Modal
        centered
        show={this.state.show} 
        animation={true}
        onHide={this.handleShow}
        className="filtersModal" id="filterModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          {/* <div className="modal-dialog modal-dialog-centered" role="document"> */}
            <div className="modal-content">

              <LoadingOverlay
                active={this.state.searchloader}
                //spinner
                spinner={<CustomImageLoader image={LGspinner} animationType={'pulse'}/>}
              >

                <div className="modal-body">
                  <button type="button" className="close"
                  onClick={this.handleShow} 
                  data-dismiss="modal" 
                  aria-label="Close"
                    style={{ backgroundColor: "black", borderRadius: "14px" }}
                    >
                    <img width="30px" src={closeSvg} alt="" />
                  </button>

                  <div className="searchFilter mx-5 mt-3">
                    <h3 style={{ color: "black" }}><img width="18px" src={filter} alt="LG-Img" /> Apply Filters</h3>

                    <div className="form-group" >
                      <label style={{ color: "black" }}>Languages Talked :</label>
                      <div className="selectBox" style={{ color: 'black' }}>

                        <select className="form-control" value={this.state.language} onChange={this.handleChange} name='language'>
                          <option value="" selected="selected">Any Language</option>
                          <option value='ENGLISH'>ENGLISH</option>
                          <option value='SPANISH'>SPANISH</option>
                          <option value='FRENCH'>FRENCH</option>
                          <option value='PORTUGUESE'>PORTUGUESE</option>
                          <option value='HINDI'>HINDI</option>
                          <option value='RUSSIAN'>RUSSIAN</option>
                          <option value='GERMAN'>GERMAN</option>
                          <option value='CHINESE'>CHINESE</option>
                          <option value='JAPANESE'>JAPANESE</option>
                        </select>

                        <span>
                          <img src={dArrowblack} alt="LG-Img" />
                        </span>
                      </div>
                    </div>

                    <div className="form-group" style={{ color: "black" }}>
                      <label style={{ color: "black" }}>Timezone:</label>
                      <div className="selectBox">
                        <select className="form-control" id="timezone-offset" value={this.state.timezone} onChange={this.handleChange} name='timezone'>
                          <option value="" selected="selected">Any Timezone:</option>
                          <option value="-12:00">(GMT -12:00) Eniwetok, Kwajalein</option>
                          <option value="-11:00">(GMT -11:00) Midway Island, Samoa</option>
                          <option value="-10:00">(GMT -10:00) Hawaii</option>
                          <option value="-09:50">(GMT -9:30) Taiohae</option>
                          <option value="-09:00">(GMT -9:00) Alaska</option>
                          <option value="-08:00">(GMT -8:00) Pacific Time (US &amp; Canada)</option>
                          <option value="-07:00">(GMT -7:00) Mountain Time (US &amp; Canada)</option>
                          <option value="-06:00">(GMT -6:00) Central Time (US &amp; Canada), Mexico City</option>
                          <option value="-05:00">(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</option>
                          <option value="-04:50">(GMT -4:30) Caracas</option>
                          <option value="-04:00">(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</option>
                          <option value="-03:50">(GMT -3:30) Newfoundland</option>
                          <option value="-03:00">(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
                          <option value="-02:00">(GMT -2:00) Mid-Atlantic</option>
                          <option value="-01:00">(GMT -1:00) Azores, Cape Verde Islands</option>
                          <option value="+00:00">(GMT) Western Europe Time, London, Lisbon, Casablanca</option>
                          <option value="+01:00">(GMT +1:00) Brussels, Copenhagen, Madrid, Paris</option>
                          <option value="+02:00">(GMT +2:00) Kaliningrad, South Africa</option>
                          <option value="+03:00">(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
                          <option value="+03:50">(GMT +3:30) Tehran</option>
                          <option value="+04:00">(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
                          <option value="+04:50">(GMT +4:30) Kabul</option>
                          <option value="+05:00">(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</option>
                          <option value="+05:50">(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</option>
                          <option value="+05:75">(GMT +5:45) Kathmandu, Pokhara</option>
                          <option value="+06:00">(GMT +6:00) Almaty, Dhaka, Colombo</option>
                          <option value="+06:50">(GMT +6:30) Yangon, Mandalay</option>
                          <option value="+07:00">(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
                          <option value="+08:00">(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
                          <option value="+08:75">(GMT +8:45) Eucla</option>
                          <option value="+09:00">(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
                          <option value="+09:50">(GMT +9:30) Adelaide, Darwin</option>
                          <option value="+10:00">(GMT +10:00) Eastern Australia, Guam, Vladivostok</option>
                          <option value="+10:50">(GMT +10:30) Lord Howe Island</option>
                          <option value="+11:00">(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
                          <option value="+11:50">(GMT +11:30) Norfolk Island</option>
                          <option value="+12:00">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
                          <option value="+12:75">(GMT +12:45) Chatham Islands</option>
                          <option value="+13:00">(GMT +13:00) Apia, Nukualofa</option>
                          <option value="+14:00">(GMT +14:00) Line Islands, Tokelau</option>
                        </select>
                        <span>
                          <img src={dArrowblack} alt="LG-Img" />
                        </span>
                      </div>
                    </div>


                    {/* <div className="form-group">
            <label style={{color:"black"}}>Coaching Style :</label>
            <div className="selectBox">
              <select  className="form-control"  value={this.state.CoachingStyle} onChange={this.handleChange} name='CoachingStyle'>
                <option value="" selected="selected">Select coaching style</option>
                <option value="Career Coaching">Career Coaching</option>
                <option value="Executive Coaching">Executive Coaching </option>
                <option value="Business Coaching">Business Coaching </option>
                <option value="Life Coaching">Life Coaching</option>
                <option value="Transformational Coaching">Transformational  Coaching</option>
                <option value="Wellbeing Coaching">Wellbeing  Coaching</option>
              </select>
              <span>
                  <img src={dArrowblack} alt="LG-Img"/>
               </span>
            </div>
          </div> */}

                    <div className="form-group">
                      <label style={{ color: "black" }}>Coaching Experience(hrs):</label>
                      <div className="selectBox">
                        <select className="form-control" value={this.state.CoachingExpHrs} onChange={this.handleChange} name='CoachingExpHrs'>
                          <option value="" selected="selected">Any hours of experience </option>
                          <option value="0-100hrs">0-100hrs</option>
                          <option value="100hrs+">100hrs+</option>
                          <option value="500hrs+">500hrs+</option>
                          <option value="2,500hrs+">2,500hrs+</option>
                          {/* <option value="1 - 20">1 hours - 20 hours</option>
                <option value="21 - 40">21 hours - 40 hours</option>
                <option value="41 - 60">41 hours - 60 hours</option>
                <option value="61 - 80">61 hours - 80 hours</option>
                <option value="81 - 100">81 hours - 100 hours</option> */}
                        </select>
                        <span>
                          <img src={dArrowblack} alt="LG-Img" />
                        </span>
                      </div>
                    </div>

                    <div className="form-group">
                      <label style={{ color: "black" }}>Hourly Rate: </label>

                      <div className="custom-control custom-checkbox">
                        <input type="radio" className="custom-control-input" id="customCheck" name="Hourlyrate" value='0 - 50' onChange={this.handleChange} />
                        <label className="custom-control-label" for="customCheck" style={{ color: "black" }}>Up to 50</label>
                      </div>
                      <div className="custom-control custom-checkbox">
                        <input type="radio" className="custom-control-input" id="customCheck1" name="Hourlyrate" value="51 - 100" onChange={this.handleChange} />
                        <label className="custom-control-label" for="customCheck1" style={{ color: "black" }}>51 to 100</label>
                      </div>
                      <div className="custom-control custom-checkbox">
                        <input type="radio" className="custom-control-input" id="customCheck2" name="Hourlyrate" value="101 - 150" onChange={this.handleChange} />
                        <label className="custom-control-label" for="customCheck2" style={{ color: "black" }}>101 to 150</label>
                      </div>

                      <div className="custom-control custom-checkbox">
                        <input type="radio" className="custom-control-input" id="customCheck3" name="Hourlyrate" value='151 - 200' onChange={this.handleChange} />
                        <label className="custom-control-label" for="customCheck3" style={{ color: "black" }}>151 to 200</label>
                      </div>
                      <div className="custom-control custom-checkbox">
                        <input type="radio" className="custom-control-input" id="customCheck4" name="Hourlyrate" value="200 - 300" onChange={this.handleChange} />
                        <label className="custom-control-label" for="customCheck4" style={{ color: "black" }}>200 to 300</label>
                      </div>
                      <div className="custom-control custom-checkbox">
                        <input type="radio" className="custom-control-input" id="customCheck5" name="Hourlyrate" value="300+" onChange={this.handleChange} />
                        <label className="custom-control-label" for="customCheck5" style={{ color: "black" }}>300+</label>
                      </div>
                    </div>

                    <div className="form-group">
                      <label style={{ color: "black" }}>Rating: </label>

                     
                      <div className="ratingWrap">

                      <StarRatings
                      starDimension={28}
                      starSpacing={20}
                      starHoverColor="orange"
                      rating={this.state.rating}
                      starRatedColor="orange"
                      changeRating={this.onStarClick}
                      numberOfStars={3}
                      name='rating'
                    />
                      </div>
                    </div>

                    <div className="form-group">
                      {/* <label style={{ color: "black" }}>Hourly Rate:1 </label> */}
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="customCheck6" name="MemberOfPropessionalBody" onChange={this.handleChange} value="1" />
                        <label className="custom-control-label" for="customCheck6" style={{ color: "black" }}>
                          Member of professional body <img width="25px" alt="LG-Img" src={quality} />
                        </label>
                      </div>
                    </div>

                    <div className="form-group text-center mt-4">
                      <button className="defaultBtn" 
                      //data-dismiss="modal" 
                      type="submit" onClick={this.handleSubmit} >Apply Filters
          </button>
                    </div>


                  </div>







                </div>


              </LoadingOverlay>


            </div>
          {/* </div> */}
        </Modal>
      </div>
    )
  }
}
export default CoachFilter