import React, { Component } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Modal, Button } from "react-bootstrap";

import { writingSvg, plusnewSvg, mclose, editnew } from "../../assets/images";

class TextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      open: false,
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  handleBioSubmit = (e) => {
    this.props.bioHandleSubmitPersonal(e);
    this.handleClose();
  };
  render() {
    return (
      <>
        {/* <button onClick={this.handleShow}>
          <img width="35px" src={writingSvg} alt="LG-I" />
        </button> */}

        <span className="addTag" onClick={this.handleShow} style={{cursor: "pointer"}}>
            <img src={editnew} width="25px"/>
        </span>

        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
          size="md"
        >
          {/* <Modal.Header closeButton>
            <Modal.Title style={{color:"black"}}>Update Bio</Modal.Title>
          </Modal.Header> */}

          <Modal.Body>

              <button type="button" className="close" aria-label="Close" onClick={this.handleClose}>
                <img src={mclose} alt="" width="30px"/>
              </button>

              <div className="heading text-center aos-init aos-animate">
                <h3 className="text-capitalize">Update Bio</h3>                
                <span className="R_divider"></span>
              </div>


            <div style={{ color: "black" }}>
              <CKEditor
                id="ckeditor"
                editor={ClassicEditor}
                data={this.props.pdata}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                  ],
                }}
                onChange={this.props.handleOnChange}
              />
            </div>
              <div className="text-center mt-3">
                <button className="defaultBtn" type="button" variant="success"
                  onClick={this.handleBioSubmit}>Save</button>
              </div>
          </Modal.Body>

          {/* <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose}>
              Close
            </Button>

            <button
              className="btn btn-success"              
              onClick={this.handleBioSubmit}
            >
              Save
            </button>
          </Modal.Footer> */}
        </Modal>
      </>
    );
  }
}

export default TextEditor;
