import React from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useDownloader from 'react-use-downloader';
import { Container, Row, Col, Card,Button} from 'react-bootstrap';
import moment from "moment";
import { VideoPlayer } from "../../../components/media";

export default function RecordingModal({ show, handleClose, Recordings }) {

    const { download } = useDownloader()

    // const saveZip = (urls) => {
    //     urls.map((url) => {
    //         download(url, url.substring(url.lastIndexOf('/') + 1))
    //     })
    // }

    const DownlodaFile = (fileUrl) => {
        download(fileUrl, fileUrl.split("/")[fileUrl.split("/").length - 1])
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title> Recording List </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="session_wrap completed">
                        {
                            Recordings?.length === 0 && (
                                <div className="session_type_title mb-3 text-center"> No Recording Found </div>
                            )
                        }
                        <Row style={{"marginTop":"2rem"}}>
                    {Recordings.length >= 1 && Recordings?.map((recording,i) => (
                    <Col key={i} md={4} style={{"marginTop":"2rem"}}>
                        <Card className="bg-dark text-white">
                           
                            <VideoPlayer
                                id={recording.i}
                                src={recording.bbb_link}
                                poster={
                                    "https://markipo.com/Markipo_Reportages_Cyclisme_13_10/res/video.poster.jpg"
                                }
                                /> 
                                <Card.Body>
                                   <a href={recording.bbb_link} className='join_button' target="_blank" variant="btn btn-primary">Watch Now</a>
                                </Card.Body>
                            <Card.Footer className="text-muted">{ moment(recording.created_at).format("YYYY-MM-DD")}</Card.Footer>
                            </Card>
                    </Col>
                    ))}
                    </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <div className="webinar_inner p-0 justify-content-end d-flex">
                        <span>
                            <button type="button" className="join_button" style={{ "whiteSpace": "nowrap" }} onClick={() => saveZip(fileUrl)}>
                                <i className="fa fa-download" aria-hidden="true"></i> {" "} Download All
                            </button>
                        </span>
                    </div> */}
                </Modal.Footer>
            </Modal>
        </>
    );
}
