import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import axios from '../../../config/axios'
import { message } from 'antd'

export default function BulkUploadCsv(props) {

    const [ListName, setListName] = useState("")
    const [CsvFile, setCsvFile] = useState("")

    const HandleSubmit = (e) => {
        e.preventDefault()
        const FromData = new FormData()
        FromData.append("groupname", ListName)
        FromData.append("demo_image", CsvFile)
        FromData.append("user_id", JSON.parse(localStorage.getItem("corporate_userdata")).uid)
        FromData.append("group_type", "Jobseeker")
        axios.post(`/api/corporate_groupcreate`, FromData, { headers: { "X-Auth": localStorage.getItem("corporate_token"), }, }).then((res) => {
            if (res.data.success) {
                message.success(res.data.msg)
                props.getAllGroup()
                props.handleClose()
            }
        }).catch((error) => {
            console.log(error.response)
            message.error(error.response.msg || error.response.data.msg)
        })
    }

    const Reset = () => {
        setListName("")
        setCsvFile("")
    }

    return (
        <Modal className='cModal' centered show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title className='mb-0'>Upload Bulk CSV</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={(e) => HandleSubmit(e)} encType='multipart/form-data'>
                    <Form.Group>
                        <label className="form-label" htmlFor="formBasicEmail4"> Group Name<span className="text-danger">*</span> </label>
                        <input name="listname" placeholder="Enter group name" required validaterule="required" validatemsg="This field is required" type="text" id="formBasicEmail4" className="form-control" value={ListName} onChange={(e) => setListName(e.target.value)} />
                        <p style={{ color: "red" }} />
                    </Form.Group>
                    <Form.Group>
                        <label>Upload CSV File <span className="text-danger">*</span></label>
                        <Form.File type="file" accept=".csv" id="csv" validaterule="required" required validatemsg="This field is required" onChange={(e) => setCsvFile(e.target.files[0])} label={(CsvFile !== "") ? CsvFile.name : ("Choose File")} custom />
                    </Form.Group>
                    <div className="upload_action ">
                        <button type="reset" id='csvreset' onClick={() => Reset()} hidden> reset </button>
                        <button type="submit" id='csvsubmit' hidden> Submit </button>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className='btn tabformbutton' onClick={() => document.getElementById("csvreset").click()}>Reset</Button>
                <Button variant="default" className="defaultBtn rounded-lg" onClick={() => document.getElementById("csvsubmit").click()}>Submit</Button>
            </Modal.Footer>
        </Modal>
    )
}
