import React, { useCallback, useState } from 'react'

import {
  LoginSocialGoogle
} from 'reactjs-social-login'

// CUSTOMIZE ANY UI BUTTON
import {
  GoogleLoginButton
} from 'react-social-login-buttons'

import SocialLoginNow from './SocilaloginNow';
import { GOOGLE_CLIENT_ID } from '../config/custom';

import message from "../utils/mesaages";

const SocilaLogin = (props) => {
  const [provider, setProvider] = useState('')
  const [profile, setProfile] = useState(null)

  const onLoginStart = useCallback(() => {
    message.info('We are processing your request, please wait')
  }, [])

  return (
    <>
      {provider && profile && <SocialLoginNow isLogin={props.isLogin} provider = {provider} accessToken = {profile.access_token} 
      registration_source = {props.registration_source}
      acode = {props.acode}
      package = {props.package}
      coupon = {props.coupon}
      /> }
     
        <div className={`App ${provider && profile ? 'hide' : ''}`}>
          <LoginSocialGoogle
            isOnlyGetToken
            scope={"https://www.googleapis.com/auth/userinfo.email"}
            client_id={GOOGLE_CLIENT_ID}
            onLoginStart={onLoginStart}
            onResolve={({ provider, data }) => {
              setProvider(provider)
              setProfile(data)
            }}
            onReject={(err) => {
              console.log(err)
            }}
          >
            <GoogleLoginButton text={props.txt} />
          </LoginSocialGoogle>                 
        </div>
      
    </>
  )
}

export default SocilaLogin