import React from 'react'
import { m1, m2, user1,coach } from '../../assets/images'
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
class OurTeam extends React.Component{
  constructor(props) {
    super(props)    
  }
    render(){
        return(
            <div>

<section className="teamWrap">
  <div className="container">
    <div className="heading text-center">
         <h3>Meet Our team</h3>
         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
        <p>incididunt ut labore. suspendisse ultrices gravida.</p>
         <span className="R_divider"></span>
    </div>
    <div className="teamList mt-5">
    {/* {this.props.teamdata.length && (
          <OwlCarousel className="owl-theme" loop margin={10} nav items={4}>
            {this.props.teamdata.map((each, index) => {
              return (
                <div className="item">
              <div className="teamMember">
                 <div className="memberContent">
                  <img  src={each.p_image} alt="" className="img-fluid"/>
                  <h5>{each.name} | {each.location}</h5>
                  <p>{each.description}</p>
                  <ul>
                    <li style={{color:"green", padding: "10px"}} ><i className="fa fa-facebook" aria-hidden="true"></i></li>
                    <li style={{color:"green", padding: "10px"}}><i className="fa fa-twitter" aria-hidden="true"></i></li>
                    <li style={{color:"green", padding: "10px"}}><i className="fa fa-linkedin" aria-hidden="true"></i></li>
                  </ul>
                 </div>
              </div>
            </div>
              );
            })}
          </OwlCarousel>
        )} */}
    {/* <div className="owl owl-carousel master-carousel owl-theme">
            <div className="item">
              <div className="teamMember">
                 <div className="memberContent">
                  <img  src={m1} alt="" className="img-fluid"/>
                  <h5>Eilian Huisman | Netherlands</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                  <ul>
                    <li style={{color:"green", padding: "10px"}} ><i className="fa fa-facebook" aria-hidden="true"></i></li>
                    <li style={{color:"green", padding: "10px"}}><i className="fa fa-twitter" aria-hidden="true"></i></li>
                    <li style={{color:"green", padding: "10px"}}><i className="fa fa-linkedin" aria-hidden="true"></i></li>
                  </ul>
                 </div>
              </div>
            </div>

            <div className="item">
              <div className="teamMember">
                 <div className="memberContent">
                  <img  src={m2} alt="" className="img-fluid"/>
                  <h5>Eilian Huisman | Netherlands</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                  <ul>
                  <li style={{color:"green", padding: "10px"}} ><i className="fa fa-facebook" aria-hidden="true"></i></li>
                    <li style={{color:"green", padding: "10px"}}><i className="fa fa-twitter" aria-hidden="true"></i></li>
                    <li style={{color:"green", padding: "10px"}}><i className="fa fa-linkedin" aria-hidden="true"></i></li>
                  </ul>
                 </div>
              </div>
            </div>

            <div className="item">
              <div className="teamMember">
                 <div className="memberContent">
                  <img  src={coach} alt="" className="img-fluid"/>
                  <h5>Eilian Huisman | Netherlands</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                  <ul>
                  <li style={{color:"green", padding: "10px"}} ><i className="fa fa-facebook" aria-hidden="true"></i></li>
                    <li style={{color:"green", padding: "10px"}}><i className="fa fa-twitter" aria-hidden="true"></i></li>
                    <li style={{color:"green", padding: "10px"}}><i className="fa fa-linkedin" aria-hidden="true"></i></li>
                  </ul>
                 </div>
              </div>
            </div>

            <div className="item">
              <div className="teamMember">
                 <div className="memberContent">
                  <img  src={user1} alt="" className="img-fluid"/>
                  <h5>Eilian Huisman | Netherlands</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                  <ul>
                    <li style={{color:"green", padding: "10px"}} ><i className="fa fa-facebook" aria-hidden="true"></i></li>
                    <li style={{color:"green", padding: "10px"}}><i className="fa fa-twitter" aria-hidden="true"></i></li>
                    <li style={{color:"green", padding: "10px"}}><i className="fa fa-linkedin" aria-hidden="true"></i></li>
                  </ul>
                 </div>
              </div>
            </div> 
          </div> */}
          
    </div>
  </div>
</section>


            </div>
        )
    }
}
export default OurTeam