import React from 'react'
import { teamSvg } from '../../assets/images'
import UpcomingSessionCalender from '../../components/calender/upcomingsessionCalender'
import axios from '../../config/axios'


class UserupcomingSession extends React.Component{
  constructor(props){
    super(props)
    this.state={
      sessionBookingDetails:[]
    }
  }

  componentDidMount(){
      axios.get('/upcoming/user-session',{
        headers:{"x-auth":this.props.token}
      })
      .then(res =>{
        //console.log("seesion--booking--res",res)
        if(res.data.success){
          this.setState({sessionBookingDetails:res.data.value})
        }
      })
      .catch(err =>{
        console.log(err)
      })
  }

    render(){
      console.log("user----sessionBooking----",this.state.sessionBookingDetails)
        return(

          <div className="col-md-8 col-lg-9">
        <div className="searchHead">
          <div className="searchHeading">
            <h3>
              <img width="22px" src={teamSvg} alt="LG-Img" /> Upcoming Session
            </h3>
          </div>
          
        </div>
        
        <UpcomingSessionCalender
        sessionBookingDetails={this.state.sessionBookingDetails}/><hr/>

        <div className="userBookingList">
      
        {this.state.sessionBookingDetails.length > 0 && this.state.sessionBookingDetails.map(sessionBooking =>{
       
       let sessionDetails=JSON.parse(sessionBooking.session_details)
       let sessionID=sessionBooking.session_id
       let x=[]
           sessionDetails.filter(session =>{
              if(session.id==sessionID){
                x.push(session)
              }
           })

       return(
          <div className="bookingCocachDetails">
          <ul>
            <li>
              <b>Package Name</b> <span>{sessionBooking.title}</span>
            </li>
            <li>
              <b>Session Name</b> <span>{x[0].sessionName}</span>
            </li>
            <li>
              <b>Coach Name</b> <span>{sessionBooking.name}</span>
            </li>
            <li>
              <b>Scheduled</b> <span>{sessionBooking.date.split("T")[0]}<br/>
              {sessionBooking.start_time}-{sessionBooking.end_time}</span>
            </li>
            <li>
              <b>Status</b> <span>{sessionBooking.status==0 ?("Schedule Not Confirmed"):("Confirmed")}</span>
            </li>
  
            <li className="defaultBtn">Go To Room</li>
          </ul>
          
        </div>
        )
      })}

        </div>
      </div>
  
        )
    }
}

export default UserupcomingSession