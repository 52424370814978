import React, { useState } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";

function AddPackage(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const pdata = props.pacdata;
  const handleCheck = () => {
    if (
      pdata.title.length > 0 &&
      pdata.price.length > 0 &&
      pdata.description.length > 0
    ) {
      setShow(false);
    }
  };

  const [inputList, setInputList] = useState([
    { id: "0", sessionName: "", sessionDescription: "", Length: "" },
  ]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleAddClick = (i) => {
    setInputList([
      ...inputList,
      { id: i+1 , sessionName: "", sessionDescription: "", Length: "" },
    ]);
  };

  const handleAddRemove = (i) => {
    const hero = inputList;
    const updateList = hero.filter((item) => item.id !== i);
    setInputList(updateList);
  };

  const handleReset=() =>{
    props.handleResetPackage()
    setInputList([{sessionName: "", sessionDescription: "", Length: ""}])
  }

  const handleSubmit = (e) => {
    props.handleSubmit(e, inputList);
  };

  return (
    <>
      <p
        className="popup-with-zoom-anim ml-auto mb-3 add_new_testi"
        onClick={handleShow}
      >
        <i
          className="fa fa-plus-square"
          aria-hidden="true"
          style={{ color: "green", fontSize: "28px" }}
          title="Please Add Package from Here"
        ></i>
      </p>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title style={{color:"black"}}>Create Sessions</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{color:"black"}}>
            <Form.Group>
              <Form.Label>Session Type</Form.Label>
              <div className="selectBox">
                <select
                  style={{color:"black"}}
                  // style={{ backgroundColor: "#8d8d8d"}}
                  className="form-control"
                  // style={{ backgroundColor: "#8d8d8d"}}
                  value={pdata.package_type}
                  name="package_type"
                  placeholder="Enter package type"
                  onChange={props.handlePackType}
                >
                  <option selected="Single Session">Single Session</option>
                  <option selected="Multi Session">Multi Session</option>
                </select>
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label> Name</Form.Label>
              <Form.Control
                style={{color:"black"}}
                // id="inputID"
                type="text"
                placeholder="Enter name of package or program or multi sessions"
                name="title"
                value={pdata.title}
                onChange={props.handleChange}
                required={true}
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Description</Form.Label>
              <Form.Control
                style={{color:"black"}}
                // id="inputID"
                as="textarea"
                rows="4"
                placeholder="Enter full description of package or program or multi sessions"
                value={pdata.description}
                onChange={props.handleChange}
                name="description"
                required={true}
              />
            </Form.Group>
            {pdata.package_type === "Single Session" ? (
              <div>
                 <Form.Group>
                  <Form.Label>Intro Length (in Minute)</Form.Label>
                  <select
                  style={{color:"black"}}
                  className="form-control"
                  value={pdata.introDuration}
                  name="introDuration"
                  placeholder="Enter intro duration of session"
                  onChange={props.handleChange}
                >
                  <option value="">Select duration</option>
                  <option value="15">15 Minute</option>
                  <option value="30">30 Minute</option>
                  <option value="45">45 Minute</option>
                  <option value="60">60 Minute</option>
                </select>
                  
                </Form.Group>

                <Form.Group>
                  <Form.Label>Length (in Minute)</Form.Label>
                  <select
                  style={{color:"black"}}
                  className="form-control"
                  value={pdata.duration}
                  name="duration"
                  placeholder="Enter  duration of session"
                  onChange={props.handleChange}
                >
                  <option value="">Select duration</option>
                  <option value="15">15 Minute</option>
                  <option value="30">30 Minute</option>
                  <option value="45">45 Minute</option>
                  <option value="60">60 Minute</option>
                </select>
                  
                </Form.Group>
              </div>
            ) : (
              ""
            )}

             {pdata.package_type === "Multi Session" ? (

                      <Form.Group>
                      <Form.Label>Intro Length (in Minute)</Form.Label>
                      <select
                      style={{color:"black"}}
                      className="form-control"
                      value={pdata.introDuration}
                      name="introDuration"
                      placeholder="Enter intro duration of session"
                      onChange={props.handleChange}
                      >
                      <option value="">Select duration</option>
                      <option value="15">15 Minute</option>
                      <option value="30">30 Minute</option>
                      <option value="45">45 Minute</option>
                      <option value="60">60 Minute</option>
                      </select>

                      </Form.Group>
              // <Form.Group>
              //   <Form.Label>Total Sessions</Form.Label>
              //   <Form.Control
              //     style={{color:"black"}}
              //     // id="inputID"
              //     type="number"
              //     placeholder="Enter total number of Sessions"
              //     name="total_sessions"
              //     value={pdata.total_sessions}
              //     onChange={props.handleChange}
              //     required={true}
              //   />
              // </Form.Group>
            ) : (
              ""
            )} 

            {/* <Form.Group>
              <Form.Label>Total Sessions</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter total number of Sessions"
                name="total_sessions"
                value={pdata.total_sessions}
                onChange={props.handleChange}
                required={true}
              />
            </Form.Group>
            */}
            <Form.Group>
              <Form.Label>Cost</Form.Label>
              <Row>
                <Col>
                  <div className="selectBox">
                    <select
                      style={{color:"black"}}
                      // style={{ backgroundColor: "#8d8d8d"}}
                      className="form-control"
                      // style={{ backgroundColor: "#8d8d8d"}}
                      name="cost"
                      value={pdata.cost}
                      onChange={props.handleChange}
                    >
                      <option value="Select cost">Select Currency</option>
                      <option value="USD">USD</option>
                      <option value="EUR">EUR</option>
                      <option value="GBP">GBP</option>
                    </select>
                  </div>
                </Col>
                <Col>
                  <Form.Control
                    style={{color:"black"}}
                    // id="inputID"
                    placeholder="Enter amount"
                    name="price"
                    value={pdata.price}
                    onChange={props.handleChange}
                    required={true}
                  />
                </Col>
              </Row>
            </Form.Group>

            <hr />

            {pdata.package_type === "Multi Session" ? (
              <div className="App">
                <h4 style={{ textAlign: "center" }}> Create New Sessions</h4>
                {inputList.map((x, i) => {
                  return (
                    <>
                      {inputList.length - 1 === i && (
                        <i
                          className="fa fa-plus-square"
                          style={{
                            color: "black",
                            fontSize: "25px",
                            float: "right",
                          }}
                          onClick={() => handleAddClick(i)}
                        />
                      )}
                    </>
                  );
                })}
                <br />

                <hr />
                {inputList.map((x, i) => {
                  return (
                    <div>
                      {inputList.length > 1 && (
                        <i
                          className="fa fa-minus-square"
                          style={{
                            color: "black",
                            fontSize: "25px",
                            float: "right",
                          }}
                          onClick={() => handleAddRemove(x.id)}
                        ></i>
                      )}

                      <Form.Group>
                        {/* <Form.Control
                          type="text"
                          placeholder="Enter session name"
                          name="id"
                          value={i}
                          // onChange={(e) => handleInputChange(e, i)}
                          // required={true}
                        /> */}

                        <Form.Label>Session Name</Form.Label>
                        <Form.Control
                          style={{color:"black"}}
                          // id="inputID"
                          type="text"
                          placeholder="Enter session name"
                          name="sessionName"
                          value={x.sessionName}
                          onChange={(e) => handleInputChange(e, i)}
                          required={true}
                        />
                      </Form.Group>
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          style={{color:"black"}}
                          // id="inputID"
                          as="textarea"
                          rows="4"
                          placeholder="Enter session description"
                          value={x.sessionDescription}
                          onChange={(e) => handleInputChange(e, i)}
                          name="sessionDescription"
                          required={true}
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Length (in Minute)</Form.Label>
                        {/* <Form.Control
                          style={{color:"black"}}
                         
                          type="number"
                          placeholder="Enter amount"
                          name="Length"
                          value={x.Length}
                          onChange={(e) => handleInputChange(e, i)}
                          required={true}
                        /> */}
                <select
                  style={{color:"black"}}
                  className="form-control"
                  value={x.Length}
                  name="Length"
                  placeholder="Enter duration of session"
                  onChange={(e) => handleInputChange(e, i)}
                  required={true}
                >
                  <option value="">Select duration</option>
                  <option value="15">15 Minute</option>
                  <option value="30">30 Minute</option>
                  <option value="45">45 Minute</option>
                  <option value="60">60 Minute</option>
                  
                </select>
                      </Form.Group>
                      <hr />

                      <hr />
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="secondary" onClick={handleReset}>
              Reset</Button>
            <Button variant="success" type="submit" onClick={handleCheck}>
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
export default AddPackage;
