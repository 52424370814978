import React from 'react'
import { Link } from "react-router-dom";
import { Row, Col, Card, Button, Table, Dropdown, InputGroup, FormControl, Form, Container } from 'react-bootstrap'
import ReactPaginate from "react-paginate";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import './BulkContacts.css'
import BulkUploadCsv from './BulkUploadCsv';
import axios from '../../../config/axios';
import { message } from 'antd';
import moment from 'moment';
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";
import { LGspinner } from '../../../assets/images';
import { CSVLink } from "react-csv";
import EditModal from './EditModal';
import AddUser from './AddUser';
import EditGroupUser from './EditGroupUser';

class BulkContacts extends React.Component {
    constructor(props) {
        super(props)

        this.token = localStorage.getItem("corporate_token")
        this.userdeta = JSON.parse(localStorage.getItem("corporate_userdata"))

        this.state = {
            show: false,
            editshow: false,
            modal: '',
            editmodal: '',
            loading: false,
            limit: 10,
            page: 1,
            total_rows: "",
            groups: [],
            users: [],
            total_pages: "",
            offset: "",
            message: "",
            addUsershow: false,
            addUsermodal: "",
            viewType: "user_list",
            search: "",
            invitedetails: {}
        }
    }

    handleShow = () => {
        this.setState({ show: true })
        this.setState({ modal: <BulkUploadCsv show={true} handleClose={this.handleClose} getAllGroup={this.getAllGroup} /> })
    }

    handleClose = () => {
        this.setState({ show: false });
        this.setState({ editshow: false })
        this.setState({ addUsershow: false })

    };

    onSessionsPageChange = (page) => {
        let page1 = page.selected + 1;
        this.setState({ page: page1 }, () =>
            this.getAllGroup()
        );
    };

    handleChange = (value) => {
        this.setState({ viewType: value.target.value, search: "" }, () => {
            this.getAllGroup()
        })
    }

    getAllGroup = () => {
        this.setState({ loader: true });
        this.GetTransactionDetails()
        if (this.state.viewType === "group_list") {
            this.setState({ users: [], total_pages: "", total_rows: "", groups: [], offset: "" })
            axios.get(`/api/corporate_get_all_group?limit=${this.state.limit}&page=${this.state.page}&user_id=${this.userdeta.uid}`, { headers: { "X-Auth": this.token, }, }).then((res) => {
                this.setState({ loader: false });
                if (res.data.success) {
                    this.setState({ message: res.data.msg, groups: res.data.groups, total_pages: res.data.total_pages, total_rows: res.data.total_rows, offset: res.data.offset })
                }
            }).catch((err) => {
                this.setState({ loader: false });
                message.error(err.responce.msg);
            });
        } else {
            this.setState({ groups: [], total_pages: "", total_rows: "", users: [], offset: "" })
            axios.get(`/api/corporate_group_user?limit=${this.state.limit}&page=${this.state.page}&user_id=${this.userdeta.uid}`, { headers: { "X-Auth": this.token, }, }).then((res) => {
                this.setState({ loader: false });
                if (res.data.success) {
                    this.setState({ message: res.data.msg, users: res.data.users, total_pages: res.data.total_pages, total_rows: res.data.total_rows, offset: res.data.offset })
                }
            }).catch((err) => {
                this.setState({ loader: false });
                message.error(err.responce.msg);
            });
        }
    }

    GetTransactionDetails = () => {
        this.setState({ loader: true })
        axios.get(`/api/corporate_get_invite_details/?user_uid=${this.userdeta.uid}&id=${this.userdeta.id}`, { headers: { "x-Auth": this.token } }).then((response) => {
            if (response.data.success) {
                this.setState({ invitedetails: response.data.deta, loader: false })
            }
        }).catch((error) => {
            this.setState({ loader: false })
            message.error(error.response.data.msg)
        })
    }

    componentDidMount() {
        this.getAllGroup()
        // this.GetTransactionDetails()
    }

    showEditModal = (index) => {
        this.setState({ editshow: true })
        this.setState({ editmodal: <EditModal editshow={true} handleClose={this.handleClose} details={this.state.groups[index]} getAllGroup={this.getAllGroup} /> })
    }

    deleteGroup = (group_id) => {
        this.setState({ loader: true });
        axios.post(`/api/corporate_delete_group`, { group_id: group_id, user_id: this.userdeta.uid }, { headers: { "X-Auth": this.token, }, }).then((res) => {
            this.setState({ loader: false });
            if (res.data.success) {
                message.success(res.data.msg)
                this.getAllGroup()
            }
        }).catch((err) => {
            this.setState({ loader: false });
            message.error(err.responce.msg);
        });
    }

    sendBulkEmail = (group_id) => {
        this.setState({ loader: true });
        axios.post(`/api/corporate_send_bulk_email`, { group_id: group_id, user_id: this.userdeta.uid }, { headers: { "X-Auth": this.token, }, }).then((res) => {
            this.setState({ loader: false });
            if (res.data.success) {
                message.success(res.data.msg)
                this.getAllGroup()
            }
        }).catch((err) => {
            this.setState({ loader: false });
            message.error(err.responce.msg);
        });
    }

    addUserShow = () => {
        this.setState({ addUsershow: true })
        this.setState({ addUsermodal: <AddUser addUsershow={true} handleClose={this.handleClose} getAllGroup={this.getAllGroup} /> })
    }

    deleteUser = (id) => {
        this.setState({ loader: true });
        axios.post(`/api/corporate_delete_group_user`, { id: id, user_id: this.userdeta.uid }, { headers: { "X-Auth": this.token, }, }).then((res) => {
            this.setState({ loader: false });
            if (res.data.success) {
                message.success(res.data.msg)
                this.getAllGroup()
            }
        }).catch((err) => {
            this.setState({ loader: false });
            message.error(err.responce.msg);
        });
    }

    editUser = (id) => {
        this.setState({ show: true })
        this.setState({ modal: <EditGroupUser show={true} isSubmit={true} handleClose={this.handleClose} userDetails={this.state.users[id]} getGroupUser={this.getAllGroup} /> })
    }

    sendEmail = (id) => {
        this.setState({ loader: true });
        axios.post(`/api/coporate_user_mail_send`, { id: id, user_id: this.userdeta.uid }, { headers: { "X-Auth": this.token, }, }).then((res) => {
            this.setState({ loader: false });
            if (res.data.success) {
                message.success(res.data.msg)
                this.getAllGroup()
            }
        }).catch((err) => {
            this.setState({ loader: false });
            message.error(err.responce.data.msg);
        });
    }

    updateStatus = (status, id) => {
        if (status === "1") {
            axios.put(`/api/corporate_user_activate_or_deactivate`, { status: "0", id, user_id: this.userdeta.uid }, { headers: { "X-Auth": this.token, }, }).then((res) => {
                if (res.data.success) {
                    message.success(res.data.msg)
                    if (this.state.viewType === "group_list") {
                        this.setState({ users: [], total_pages: "", total_rows: "", groups: [] })
                        axios.get(`/api/corporate_get_all_group?limit=${this.state.limit}&page=${this.state.page}&user_id=${this.userdeta.uid}`, { headers: { "X-Auth": this.token, }, }).then((res) => {
                            if (res.data.success) {
                                this.setState({ message: res.data.msg, groups: res.data.groups, total_pages: res.data.total_pages, total_rows: res.data.total_rows })
                            }
                        }).catch((err) => {
                            this.setState({ loader: false });
                            message.error(err.responce.msg);
                        });
                    } else {
                        this.setState({ groups: [], total_pages: "", total_rows: "", users: [] })
                        axios.get(`/api/corporate_group_user?limit=${this.state.limit}&page=${this.state.page}&user_id=${this.userdeta.uid}`, { headers: { "X-Auth": this.token, }, }).then((res) => {
                            if (res.data.success) {
                                this.setState({ message: res.data.msg, users: res.data.users, total_pages: res.data.total_pages, total_rows: res.data.total_rows })
                            }
                        }).catch((err) => {
                            this.setState({ loader: false });
                            message.error(err.responce.msg);
                        });
                    }
                }
            }).catch((err) => {
                message.error(err.responce.msg);
            });
        } else {
            axios.put(`/api/corporate_user_activate_or_deactivate`, { status: "1", id, user_id: this.userdeta.uid }, { headers: { "X-Auth": this.token, }, }).then((res) => {
                if (res.data.success) {
                    message.success(res.data.msg)
                    if (this.state.viewType === "group_list") {
                        this.setState({ users: [], total_pages: "", total_rows: "", groups: [] })
                        axios.get(`/api/corporate_get_all_group?limit=${this.state.limit}&page=${this.state.page}&user_id=${this.userdeta.uid}`, { headers: { "X-Auth": this.token, }, }).then((res) => {
                            if (res.data.success) {
                                this.setState({ message: res.data.msg, groups: res.data.groups, total_pages: res.data.total_pages, total_rows: res.data.total_rows })
                            }
                        }).catch((err) => {
                            this.setState({ loader: false });
                            message.error(err.responce.msg);
                        });
                    } else {
                        this.setState({ groups: [], total_pages: "", total_rows: "", users: [] })
                        axios.get(`/api/corporate_group_user?limit=${this.state.limit}&page=${this.state.page}&user_id=${this.userdeta.uid}`, { headers: { "X-Auth": this.token, }, }).then((res) => {
                            if (res.data.success) {
                                this.setState({ message: res.data.msg, users: res.data.users, total_pages: res.data.total_pages, total_rows: res.data.total_rows })
                            }
                        }).catch((err) => {
                            this.setState({ loader: false });
                            message.error(err.responce.msg);
                        });
                    }
                }
            }).catch((err) => {
                message.error(err.responce.msg);
            });
        }
    }

    render() {
        const { total_pages, groups, users, search, invitedetails } = this.state
        const csvData = [
            ["firstname", "lastname", "email", "contact_no", "employee_id"],
            ["Ahmed", "Tomi", "ah@smthing.co.com", "2387423846", "WGT/165"],
            ["Raed", "Labes", "rl@smthing.co.com", "2387423847", "WGT/166"],
            ["Yezzi", "Min l3b", "ymin@cocococooo.com", "2387423849", "WGT/168"]
        ];
        return (
            <div className="content_main bulk_upload">
                <Header />
                <LoadingOverlay active={this.state.loader} className="webiner_loader" spinner={<CustomImageLoader image={LGspinner} animationType={"none"} />}>
                    <div className="content_wrap">

                        <h4 className="page-title mb-3">Invite Employee</h4>
                        <p>Add employees either by importing bulk contacts or adding them individually. You can send invitations for each employee separately.</p>

                        <Row>
                            <Col md={9}>
                                <div className="btn_group mb-5 d-flex">
                                    <CSVLink data={csvData} filename={"sample.csv"} className="defaultBtn mr-2 rounded-lg">Download sample CSV</CSVLink>
                                    <Button variant='default' className="defaultBtn mr-2 rounded-lg" onClick={() => this.handleShow()}>Upload bulk CSV</Button>
                                    <Button variant='default' className="defaultBtn rounded-lg" onClick={() => this.addUserShow()}> Add Individual Employee </Button>
                                </div>
                            </Col>
                            <Col md={3}>
                                <Form.Group className="d-flex align-items-center">
                                    <label className='mr-2 mb-0'>Filter By</label>
                                    <Form.Control as="select" style={{ width: '150px' }} onChange={this.handleChange}>
                                        <option value={"user_list"}>Employee List</option>
                                        <option value={"group_list"}>Group List</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className='invites_grid'>
                            <Row>
                                <Col>
                                    <Card className="crCard border-0">
                                        <Card.Body>
                                            <h2>{invitedetails.inviteAvalable}</h2>
                                            <h5>Invites Available</h5>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card className="crCard border-0">
                                        <Card.Body>
                                            <h2>{invitedetails.inviteAccept}</h2>
                                            <h5>Invites Accepted</h5>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card className="crCard border-0">
                                        <Card.Body>
                                            <h2>{invitedetails.invitePanding}</h2>
                                            <h5>Invites Pending</h5>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card className="crCard border-0">
                                        <Card.Body>
                                            <h2>{invitedetails.totalInvite}</h2>
                                            <h5>Total Invites</h5>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>

                        <Card className="crCard border-0 mb-4">
                            <Card.Header className="bg-white ch-tool">
                                <Row className="align-items-center">
                                    <Col md={4}>
                                        <Card.Title className="mb-0 h4">{(this.state.viewType === "user_list") ? "Employee List" : "Employee Group List"}</Card.Title>
                                    </Col>
                                    <Col md={4}>
                                        <Card.Title className="mb-0 h4">
                                            {
                                                (this.state.viewType === "user_list") ? (<p className="p-0 m-0" style={{ "fontSize": "15px", "fontWeight": "500" }}>Total Employees: {this.state.total_rows}</p>) : (<p className="p-0 m-0" style={{ "fontSize": "15px", "fontWeight": "500" }}>Total Groups: {this.state.total_rows}</p>)
                                            }
                                        </Card.Title>
                                    </Col>
                                    <Col md={4}>
                                        <InputGroup className="search_webiner">
                                            <Form.Control
                                                className="border-0 m-0"
                                                placeholder="Type here..."
                                                value={search} onChange={(e) => this.setState({ search: e.target.value })}
                                            />
                                            <InputGroup.Text><i className="fa fa-search" /></InputGroup.Text>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                {
                                    (groups.length === 0 && users.length === 0) ? (<p className="mb-0 text-center">{this.state.message}</p>) : (
                                        <Table responsive className="mb-0 text-center">
                                            <thead>
                                                {
                                                    (groups.length !== 0) && (
                                                        <tr>
                                                            <th className="border-top-0">SL. No.</th>
                                                            <th className="border-top-0">Group Name</th>
                                                            <th className="border-top-0">Total Contact</th>
                                                            <th className="border-top-0">Created At</th>
                                                            <th className="border-top-0">Actions</th>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    (users.length !== 0) && (
                                                        <tr>
                                                            <th className="border-top-0">SL. No.</th>
                                                            <th className="border-top-0">Name</th>
                                                            <th className="border-top-0">Email</th>
                                                            <th className="border-top-0">Phone</th>
                                                            <th className="border-top-0">Mail Send</th>
                                                            <th className="border-top-0">Joined</th>
                                                            <th className="border-top-0">Group Name</th>
                                                            <th className="border-top-0">Status</th>
                                                            <th className="border-top-0">Transfer Account</th>
                                                            <th className="border-top-0">Created At</th>
                                                            <th className="border-top-0">Actions</th>
                                                        </tr>
                                                    )
                                                }
                                            </thead>
                                            <tbody>
                                                {
                                                    (groups.length !== 0) &&
                                                    (
                                                        groups.filter(items => {
                                                            if (search === '') {
                                                                return items;
                                                            } else if (items.group_name.toLowerCase().includes(search.toLowerCase()) || items.created_at.toLowerCase().includes(search.toLowerCase())) {
                                                                return items;
                                                            }
                                                        }).map((items, index) => (
                                                            <tr>
                                                                {/* <td>{items.id}</td> */}
                                                                <td>{index + (this.state.offset ? this.state.offset + 1 : 1)}</td>
                                                                <td><Link to={`/employeedetails/${items.id}`}> {items.group_name} </Link></td>
                                                                <td>{items.total_user}</td>
                                                                <td>{moment(items.created_at).format("YYYY-MM-DD")}</td>
                                                                <td>
                                                                    <Dropdown className="ac_drop">
                                                                        <Dropdown.Toggle variant="default"><i className="fa fa-ellipsis-v"></i></Dropdown.Toggle>
                                                                        <Dropdown.Menu className="border-0">
                                                                            <Dropdown.Item className='blukDropdown' onClick={() => this.showEditModal(index)}> <Button className='dropdownButton'><i className="fa fa-pencil" aria-hidden="true"></i>{" "} Edit</Button> </Dropdown.Item>
                                                                            <Dropdown.Item className='blukDropdown' onClick={() => this.deleteGroup(items.id)}> <Button className='dropdownButton'><i className="fa fa-trash-o" aria-hidden="true"></i>{" "} Delete</Button> </Dropdown.Item>
                                                                            <Dropdown.Item className='blukDropdown' onClick={() => this.sendBulkEmail(items.id)}> <Button className='dropdownButton'><i className="fa fa-paper-plane-o" aria-hidden="true"></i>{" "} Send Bulk Referral</Button> </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    )
                                                }
                                                {
                                                    (users.length !== 0) && (
                                                        users.filter(items => {
                                                            if (search === '') {
                                                                return items;
                                                            } else if (items.email.toLowerCase().includes(search.toLowerCase()) || items.firstname.toLowerCase().includes(search.toLowerCase()) || items.lastname.toLowerCase().includes(search.toLowerCase())) {
                                                                return items;
                                                            }
                                                        }).map((items, index) => (
                                                            <tr>
                                                                {/* <td>{items.id}</td> */}
                                                                <td>{index + (this.state.offset ? this.state.offset + 1 : 1)}</td>
                                                                <td>{items.firstname}{" "}{items.lastname}</td>
                                                                <td>{items.email}</td>
                                                                <td>{items.contact_no}</td>
                                                                <td>{parseInt(items.has_send) === 0 ? (<i className="fa fa-times" aria-hidden="true"></i>) : (<i className="fa fa-check" aria-hidden="true"></i>)}</td>
                                                                <td>{parseInt(items.has_joined) === 0 ? (<i className="fa fa-times" aria-hidden="true"></i>) : (<i className="fa fa-check" aria-hidden="true"></i>)}</td>
                                                                <td> {items.group_name || "Individual"} </td>
                                                                <td><Form> <Form.Check type="switch" id={items.id} onChange={() => this.updateStatus(items.status, items.id)} label={(items.status === "1") ? "Active" : "Inactive"} checked={(items.status === "1") ? true : false} /> </Form></td>
                                                                <td>{
                                                                    items.isTransafer ? (
                                                                        <Link className={"btn-sm defaultBtn rounded-lg"} to={`/account_tranfare/${items.id}`}> <i className="fa fa-exchange" aria-hidden="true"></i> </Link>
                                                                    ) : (
                                                                        <Link className={"btn-sm defaultBtn rounded-lg"} to={"#"}> <i className="fa fa-exchange" aria-hidden="true"></i> </Link>
                                                                    )
                                                                }
                                                                </td>
                                                                <td>{moment(items.created_at).format("YYYY-MM-DD")}</td>
                                                                <td>
                                                                    <Dropdown className="ac_drop">
                                                                        <Dropdown.Toggle variant="default"><i className="fa fa-ellipsis-v"></i></Dropdown.Toggle>
                                                                        <Dropdown.Menu className="border-0">
                                                                            <Dropdown.Item className='blukDropdown' onClick={() => this.editUser(index)}> <Button className='dropdownButton'><i className="fa fa-pencil" aria-hidden="true"></i>{" "} Edit</Button> </Dropdown.Item>
                                                                            <Dropdown.Item className='blukDropdown' onClick={() => this.deleteUser(items.id)}> <Button className='dropdownButton'><i className="fa fa-trash-o" aria-hidden="true"></i>{" "} Delete</Button> </Dropdown.Item>
                                                                            {
                                                                                parseInt(items.has_joined) === 0 && (
                                                                                    <Dropdown.Item className='blukDropdown' onClick={() => this.sendEmail(items.id)}> <Button className='dropdownButton' disabled={(parseInt(items.has_send) === 2) ? true : false}><i className="fa fa-paper-plane-o" aria-hidden="true"></i>{" "} {parseInt(items.has_send) === 0 ? "Send Referral" : "Resend Referral"}</Button> </Dropdown.Item>
                                                                                )
                                                                            }
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    )
                                }
                            </Card.Body>
                            <Card.Footer className="bg-white">
                                <Row className="align-items-center">
                                    <Col md="4">
                                        <label className='mb-0'>{this.state.offset ? (this.state.offset + 1) : 1} - {this.state.total_rows} of {this.state.total_rows}</label>
                                    </Col>
                                    <Col md="8" className='result_pagination'>
                                        <ReactPaginate
                                            className="mb-0 justify-content-end"
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={this.onSessionsPageChange}
                                            pageRangeDisplayed={6}
                                            pageCount={total_pages}
                                            previousLabel="<"
                                            activeClassName="active"
                                            disabledClassName="disable"
                                            renderOnZeroPageCount={null}
                                        />
                                    </Col>
                                </Row>
                            </Card.Footer>
                        </Card>
                    </div>
                </LoadingOverlay >
                <Sidebar />
                {
                    (this.state.show === true) ? this.state.modal : ''
                }
                {
                    (this.state.editshow === true) ? this.state.editmodal : ''
                }
                {
                    (this.state.addUsershow === true) ? this.state.addUsermodal : ''
                }
            </div >
        )
    }
}

export default BulkContacts