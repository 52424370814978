import React, { Component } from "react";
import axios from "../../config/axios";
import { toast } from "react-toastify";
import { Package } from "./edit";
import { Link } from "react-router-dom";
import VideoView from "../../components/media/VideoView";
import { coachesVideo, watchPng } from "../../assets/images";
import ClientFeedback from "./view/ClientFeedback";
import BookPackage from "../../components/commonmodal/bookPackageModal";
import CoachSideBar from "./coachSideBar";
import ReactPlayer from 'react-player'
import { curencyFilter,curencyRates } from "../../utils/currencyConvert";


class Home extends Component {
  constructor(props, context) {
    super(props, context);
    console.log('aaa',this.props)
    this.token = localStorage.getItem("authToken");
    this.state = {
      packagescollection: [],
      testimonialcollection: [],
      isPackageContent: "",
      packageContent: "",

      title: "",
      description: "",
      price: "",
      isactive: "1",
      introDuration: "0", //HH:MM in MM
      duration: "0", //HH:MM in MM
      session_HH: "0",
      session_MM: "0",
      package_type: "Single Session",
      total_sessions: "",
      session_details: [],
      cost: "",
      sessionDescription: "",
      Length: "",
      videofile: "",
      packageName:"",
      packageUid:"",
      packPrice:"",
      renderSideBarComponent:"",
      banner: [],
      packageCount:'',
      rates:''
    };
  }

  async componentDidMount() {

    let rates = await curencyRates()
    this.setState({rates})
   
    this.getPackages();

    if (this.state.check_account_type === "coach") {
      this.getPackages();
      this.getTestimonial();
      this.getPackageContent();
      setTimeout(this.getTestimonial(), 1200);
    } else {
      if (this.state.check_account_type === "consumer") {
        this.consumerMode();
      this.getPackages();

      }
    }
    this.getHomeContent()
  }

  getHomeContent = () => {
    this.setState({loader: true})
    let self = this
    axios.get('/cmshome/homeContent')
    .then(async (response) => {
      //console.log("response landing", response.data.value.webinar)
      if(response.status === 200){
        self.setState({
          banner: JSON.parse(response.data.value.banner[0].content_json),
         
          loader: false,
        })
      } else {
        self.setState({loader: false})
        console.log("error response", response)
      }
    })
    .catch((e) => {
      console.log(e)
      self.setState({loader: false})
    })
  }

  consumerMode() {}

  // ________________________________________fetch_data________________________________
  getPackages() {
    console.log('fetch public package')
    const id = this.props.uid;
    let rates = this.state.rates

    axios
      .get(`/users/public-packages/${id}`, {
        // headers: {
        //   "X-Auth": this.token,
        // },
      })
      .then(async(res) => {
        let value = res.data.value.rows
        value = value.map((collection)=>{
          collection["filterCurrency"] = curencyFilter(collection.price,'',rates)
          return collection;
        })

        // await this.setState({ packagescollection: res.data.value.length > 0 ? res.data.value.filter((e) => { return e.isactive == 1 }): res.data.value });
        await this.setState({ packagescollection: value, packageCount: res.data.value.pc[0].totalpackage });
        console.log("found new data" )
        console.log(this.state.packagescollection)
        this.packageCheck();
      })
      .catch((error) => {
        console.error(error);
      });
  }



  getTestimonial() {
    axios
      .get("/users/testimonials", {
        // headers: {
        //   "X-Auth": this.token,
        // },
      })
      .then((res) => {
        this.setState({ testimonialcollection: res.data.value });
        // this.getList();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getPackageContent() {
    axios
      .get("/users/packages/packagecontent", {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          const pacdata = res.data && res.data.value && res.data.value.content;
          this.setState({ isPackageContent: true, packageContent: pacdata });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //___________________________________________Handle_function________________________

  handleChange = (e) => {
    e.persist();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handlePackType = (e) => {
    e.persist();
    this.setState({
      package_type: e.target.value,
    });
  };

  HandleEnabled = (e) => {
    if (e.currentTarget.checked) {
      this.setState({ break_enabled: true });
    } else {
      this.setState({ break_enabled: false });
    }
  };

  packageCheck() {
    if (this.state.packagescollection.length > 0) {
      this.props.packagefound();
    }
  }

  //_______________________________________Handle_submit_function________________________

  handlePackageContentSubmit = (e) => {
    e.preventDefault();
    const formData = {
      content: this.state.packageContent,
    };

    let param;
    if (this.state.isPackageContent) {
      param = {
        url: "/users/packages/packagecontent",
        method: "PUT",
        data: formData,
        headers: { "X-Auth": this.token },
      };
    } else {
      param = {
        url: "/users/packages/packagecontent",
        method: "POST",
        data: formData,
        headers: {
          "X-Auth": this.token,
        },
      };
    }

    axios(param)
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          toast("successfully updated", { type: "success" });
        } else {
          toast("can not updated", { type: "error" });
        }
        this.getPackageContent();
        this.packageCheck();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleSubmitPackage = (e, data) => {
     e.preventDefault();
   console.log("package ---data---",data)
    // const durationMin =
    //   this.state.session_HH * 60 + parseInt(this.state.session_MM);

    var raw_session = JSON.stringify(data);

    var list_data = JSON.parse(raw_session);
    var total_session_time = 1;
    var introDuration = this.state.introDuration

    if (this.state.package_type === "Single Session") {
      var total_session_time = this.state.duration;
     
    } else if (this.state.package_type === "Multi Session") {
      var total_session_time = 0;

      if (list_data) {
        list_data.map(
          (time) =>
            (total_session_time = total_session_time + parseInt(time.Length))
        );
      }
    } else {
      var total_session_time = this.state.duration;
    }

    let singleSessionObject=[{
      id:"0",
      sessionName:this.state.title,
      sessionDescription:this.state.description,
      Length:total_session_time
    }]

    const formData = {
      title: this.state.title,
      description: this.state.description,
      price: this.state.cost + "##" + this.state.price,
      introDuration: introDuration,
      duration: total_session_time,
      isactive: this.state.isactive,
      package_type: this.state.package_type,
      total_sessions: this.state.package_type === "Single Session" ? 1 : this.state.total_sessions,
      // session_details: JSON.stringify(data),
      session_details:this.state.package_type=== "Single Session"? (JSON.stringify(singleSessionObject)):(JSON.stringify(data)),
    };

    console.log("package-----formadata----",formData)

    axios
      .post("/users/packages", formData, {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          toast(res.data.msg, { type: "success" });
        } else {
          toast("Adding package failed", { type: "error" });
        }
        this.getPackages();
        this.packageCheck();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleEditPackage = (formData) => {
    const id = formData.id;
    axios
      .put(`/users/packages/updatepackage/${id}`, formData, {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          toast(res.data.msg, { type: "success" });
        } else {
          toast(res.data.msg, { type: "error" });
        }
        this.getPackages();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleDeletePackage = (id) => {
    axios
      .delete(`/users/packages/updatepackage/${id}`, {
        headers: { "X-Auth": this.token },
      })
      .then((res) => {
        if (res.data.success) {
          toast(res.data.msg, { type: "success" });
        } else {
          toast(res.data.msg, { type: "error" });
        }
        this.getPackages();
      });
  };

  handleActivatePackageStatus = (id) => {
    const formData = {
      isactive: 1,
    };
    axios
      .put(`/users/packages/updatepackageStatus/${id}`, formData, {
        headers: { "X-Auth": this.token },
      })
      .then((res) => {
        if (res.data.success) {
          toast(res.data.msg, { type: "success" });
        } else {
          toast(res.data.msg, { type: "error" });
        }
        this.getPackages();
      });
  };

  handleInactivatePackageStatus = (id) => {
    const formData = {
      isactive: 0,
    };
    axios
      .put(`/users/packages/updatepackageStatus/${id}`, formData, {
        headers: { "X-Auth": this.token },
      })
      .then((res) => {
        if (res.data.success) {
          toast(res.data.msg, { type: "success" });
        } else {
          toast(res.data.msg, { type: "error" });
        }
        this.getPackages();
      });
  };

  handleResetPackage=() =>{
    this.setState({
      title: "",
      description: "",
      price: "",
      package_type: "Single Session",
      duration:0,
      cost:"",
      total_sessions: "",
      session_details: [],
      sessionDescription:""
    })
  }

  handlePackageBook=(data)=>{
    this.setState({ packageName:data.packname,
      packPrice:data.packprice,
      packageUid:data.packUid})
  }

  handleSubmitProfileVideo = (response) => {
    if (response.data.success) {
      this.setState({ videofile: response.data.profilePost.profile_video });
      toast("successfully added profile video", { type: "success" });
    } else {
      toast("Error updating profile video", { type: "error" });
    }
  };

  handleSideBarComponent = (cname) => {
    this.setState({ renderSideBarComponent: cname });
  };


  render() {
 console.log("this.props.Consumer_uid-------------=================",this.props.Consumer_email)
 console.log("homejsx package-------------=================",this.state.packageContent)

    const { account_type } = this.props;

    let sideBarSubComponent

    return (
      <React.Fragment>
        <section className="coachWrap coacActionhWrap">
          <div className="container">
            <div className="row justify-content-center">
            {/* <div className="col-md-3">
              {account_type === "coach" ? (
                <div className="userDashboardLeftPart coachInfoWrap ">
                <CoachSideBar
                renderSideBarComponent={this.state.renderSideBarComponent}
                handleSideBarComponent={this.handleSideBarComponent}
                /><br/>
                </div>
              ):("")}
            
              </div> */}
                {this.state.renderSideBarComponent==="" ?(<div className="col-md-9 ml-auto">
                <div className="coachProfileVideo  text-center">
                  {/* <VideoView
                    poster={coachesVideo}
                    video={
                      this.state.videofile.length > 0
                        ? this.state.videofile
                        : this.props.videofile
                    }
                  /> */}
                  {console.log('hdfhhdsfhsdjhfjsd',this.props.videofile)}
                  {this.props.videofile!=undefined || this.props.videofile!=null ? 
                  <VideoView
                    poster={coachesVideo}
                    defaultvideo={this.state.banner.video_url}
                    video={
                      this.state.videofile.length > 0
                        ? this.state.videofile
                        : this.props.videofile
                    }
                    flag={this.props.processed}
                  />
                  :
                  <div className="aboutVideo">
                  {/* <iframe height="470"
                  poster={coachesVideo}
                  src={`${this.state.banner.video_url}`} 
                  frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                  <ReactPlayer 
                    url={`${this.state.banner.video_url}`} 
                    controls={true} 
                    muted={true} 
                    playing={true} 
                    loop={true}
                    height="470px"
                    width="100%"
                    //playsinline
                    //light={this.state.banner.thumbnail_url} 
                  />
              
                  </div>

              }

               
                </div>
                  </div>):(<div className="col-md-12">{sideBarSubComponent}</div>)}
              
{this.state.renderSideBarComponent==="" ?(<div className="col-md-7 text-center">
                <div className="coachTag">
                  <div className="coachTagItem">
                    {/* <center> */}
                      {this.props.tags &&
                        this.props.tags.split(",").map((e, i) => (
                          <span                                                        
                            key={i}
                          >
                            {e}
                          </span>
                        ))}
                    {/* </center> */}

                  
                  </div>
                </div>
              </div>):("")}
              

            </div>
          </div>
        </section>

{this.state.renderSideBarComponent==="" ?(<div>
  <section className="packagesWrap subPackage">
          <div className="container ">
            <div className="heading text-center">
              <h3>Coaching Programs</h3>

              <div className="row justify-content-md-center">
                {this.props.check_account_type === "coach" ? (
                  <div className="col-8">
                    <p>
                      {this.state.packageContent
                        ? this.state.packageContent
                        : ""}
                    </p>

                  
                  </div>
                ) : (
                  <div className="col-8">
                    <p>&nbsp;</p>
                  </div>
                )}
              </div>
              <span className="R_divider"></span>
            </div>
            {this.props.check_account_type === "coach" ? (
              <div className="col-12">
                <div className="row no-gutters">
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="profilePakage">
              {this.state.packagescollection &&
                this.state.packagescollection.length > 0 &&
                this.state.packagescollection.map((element) => (
                  <Package
                    coachUid={this.props.uid}
                    coachImage={this.props.coachImage}
                    coachName={this.props.coachName}
                    coachData={this.props.coachData}
                    account_type={account_type}
                    token={this.token}
                    status={element.isactive}
                    key={element.id}
                    packagedata={element}
                    findcurrency={this.props.findcurrency}
                    convertToCurrency={this.props.convertToCurrency}
                    baseCurrency={this.props.baseCurrency}
                    handleEditPackage={this.handleEditPackage}
                    handleDeletePackage={this.handleDeletePackage}
                    handleActivatePackageStatus={
                      this.handleActivatePackageStatus
                    }
                    handleInactivatePackageStatus={
                      this.handleInactivatePackageStatus
                    }
                    handlePackageBook={this.handlePackageBook}
                  />
                ))}
            </div>

            {this.state.packageCount > 5 ? (
              <div className="text-center mt-5">
                <Link
                  className="defaultBtn"
                  to={{
                    pathname: "/packages_list",
                    state: {
                      token: this.token,
                      coaching_category: this.props.coaching_category,
                      uid: this.props.userdata.uid,
                      account_type:this.props.account_type,
                      coachName:this.props.coachName,
                      coachUid:this.props.coachUid,
                      Consumer_uid:this.props.Consumer_uid,
                      coachData: this.props.coachData,
                      Consumer_name:this.props.Consumer_name,
                      Consumer_email:this.props.Consumer_email,
                      findcurrency:this.props.findcurrency,
                      convertToCurrency:this.props.convertToCurrency,
                      baseCurrency:this.props.baseCurrency,
                      coachData:this.props.coachData,
                      coachImage:this.props.coachImage,
                      coachName:this.props.coachName
                    },
                  }}
                >
                  View more Packages
                </Link>
              </div>
            ) : (
              <div></div>
            )}
          </div>


          {account_type !== "coach" && (<div>
                    <BookPackage
                    packageName={this.state.packageName}
                    packageUid={this.state.packageUid}
                    packPrice={this.state.packPrice}
                    coachName={this.props.coachName}
                    coachUid={this.props.uid}
                    Consumer_uid={this.props.Consumer_uid}
                    Consumer_name={this.props.Consumer_name}
                    Consumer_email={this.props.Consumer_email}
                    check_account_type={this.props.check_account_type}
                    coachEmail={this.props.coachEmail}
                    coachImage={this.props.coachImage}/>
                    
                 </div>)}

          
        
        </section>

        <ClientFeedback coachUid={this.props.uid}/>
</div>):("")}
        
      </React.Fragment>
    );
  }
}

export default Home;
