import React from "react";
import {
  dArrowblack,
  teamSvg,
  sortSvg,
  LGspinner,
} from "../../../assets/images";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

import axios from "../../../config/axios";
import {dateConfig} from "../../../config/dateFormats";
// import { Modal } from 'bootstrap';
// import { Form } from 'react-bootstrap';
import {
  Button,
  Col,
  Dropdown,
  Form,
  Modal,
  Row,
  Spinner,
  Tab,
  Nav,
} from "react-bootstrap";
import message,{getHelpText} from "../../../utils/mesaages";
import { curencyFilter,curencyFilterRates,currencySign,curencyRates } from "../../../utils/currencyConvert";
import {
  convertToTimezone,
  getCochTimezone,
} from "../../../utils/timeZoneConvert";
import PaymentDetails from "../../../layouts/PaymentDetails";

// import moment from "moment-timezone";
import moment from "moment";
import Pagination from "../../../components/pagination/pagination";
import CustomImageLoader from "react-custom-image-loader.";
import AntdModal from "../../../components/commonmodal/AntdModal";
import { Tooltip } from 'antd';
import {EyeOutlined} from '@ant-design/icons'

class CoachTransactionhistory extends React.Component {
  constructor(props) {
    super(props);
    if (localStorage.getItem("userdata")) {
      this.userdata = JSON.parse(localStorage.getItem("userdata"));
      this.currencyValue = localStorage.getItem("currency");
    }
    this.state = {
      loader1: false,
      loader2: false,
      transactionDetails: [],
      limit: 10,
      maxPageNumberLimit:4,
      minPageNumberLimit:0,
      pageNumberLimit:4,
      offset: 1,
      totalrow: 0,
      sort: "order_desc",
      wallet: 0,
      showmodal: false,
      withdrawAmount: "",
      valid: "",
      processedAmount: "",
      requestedAmount: "",
      pendingStatus: "",
      currencyBasedAmount: "",
      key: "booking",
      allWithdrawalList: [],
      pageNumber: 1,
      listPerPage: 10,
      tabOpend: "booking",
      gatewayMode: "",
      kycStatus: "",
      wallet_balance_info:'',
      payout_request_date_info:'',
      vendorId:'',
      coach_wallet_balance:0,
      coach_wallet_balance_currency:'USD',
      ShowTransactionDetails:'',
      modalshow:false,
      rates:""
    };

    this.currency = {
      USD:'$',
      GBP:'£',
      EUR:'€',
      INR:'₹'
    }
  }

   modalShow = ()=>{
       
    this.setState({modalshow:true})
}
modalclose = () =>{
    this.setState({modalshow:false})

}
ShowTransactionDetails=(ShowTransactionDetails)=>{
  this.setState({ShowTransactionDetails},()=>{
    this.modalShow()
  })

}
  tabsctive(j) {
    console.log("active tab", j)
    this.setState({ key: j }, () => {
      if(j == "booking") {
        this.ApiCall();
        this.ApiCallForReqestedPayout();
      }
      else if(j === "personal") this.ApiCallForReqestedPayout();
    });
  }

  async componentDidMount() {
    let wallet_balance_info = await getHelpText('wallet_balance_info')
    this.setState({wallet_balance_info})
    let payout_request_date_info = await getHelpText('payout_request_date_info')
    this.setState({payout_request_date_info})
    

    console.log("@@@@@@@ user id", this.userdata.uid);
    console.log("@@@@@@@ user id", this.currencyValue);
  
    let rates = await curencyRates()
    this.setState({rates})

    this.ApiCall();
    this.ApiCallForReqestedPayout();
    this.fetchVendorId()
  }
  fetchVendorId = () => {
    let userid = JSON.parse(localStorage.getItem("userdata")).uid;
    axios.get(`/users/getVendorId/${userid}`, {
      headers: {"x-auth": localStorage.getItem('authToken')}
    })
    .then(async (res) => {
      if(res.data.success) {
        console.log("vendorDetails",res.data.vendorDetails)
        let vendorDetails = JSON.parse(res.data.vendorDetails[0].all_details)
        this.setState({vendorId: res.data.value},()=>{
          this.fetchWalletBalance()
        })
        // await this.setVendorBankDetailsState(vendorDetails)
      }
    })
    .catch((err) => {
      console.log("error", err)
    })
  }
  fetchWalletBalance=()=>{
    let userUID = JSON.parse(localStorage.getItem("userdata")).uid;

    axios.post(`/strip/getBalance`, {
      acct_no: this.state.vendorId,
      uid:userUID
    })
    .then(async (res) => {
      if(res.data.success) {

        this.setState({coach_wallet_balance: res.data.balance})
        this.setState({coach_wallet_balance_currency: res.data.currency})
      }
    })
    .catch((err) => {
      console.log("error", err)
    })

  }

  ApiCall = () => {
    this.setState({ loader1: true });
    let { limit, offset, sort } = this.state;
    axios
      .get(
        `/payment/coachTransactionhistory?limit=${limit}&offset=${offset}&sort=${sort}`,
        {
          headers: { "x-auth": this.props.token },
        }
      )
      .then(async (res) => {
        // console.log("transaction---res",res)
        

        if (res.data.success) {
          const data = res.data.value;
          await this.setState({
            wallet: res.data.wallet != null ? res.data.wallet : 0,
          });
          await this.setState({ transactionDetails: data });
          await this.setState({ totalrow: res.data.totalrow });
          this.setState({ loader1: false });
        } else {
          this.setState({ loader1: false });
        }
      })
      .catch((err) => {
        this.setState({ loader1: false });

        console.log(err);
      });
  };

  handlePrevious = () => {
    console.log("clickesss");
    // if (this.state.offset > 0) {
    //   this.setState({ offset: this.state.offset - 1 }, () => {
    //     this.ApiCall();
    //   });
    // }
    let {offset,maxPageNumberLimit,minPageNumberLimit,limit,pageNumberLimit}=this.state
    if (this.state.offset > 0) {
          this.setState({ offset: parseInt(this.state.offset) - 1 }, () => {
            this.ApiCall();
          });
        }
    //await this.setState({offset:offset - 1});

    if ((parseInt(offset) - 1) % pageNumberLimit == 0) {
     
      this.setState({maxPageNumberLimit:maxPageNumberLimit - pageNumberLimit});
      this.setState({minPageNumberLimit:minPageNumberLimit - pageNumberLimit});
    }
  };

  handleNext = async () => {
    // this.setState({ offset: this.state.offset + 1 }, () => {
    //   this.ApiCall();
    // });
    let {offset,maxPageNumberLimit,minPageNumberLimit,limit,pageNumberLimit}=this.state
    let offsetChange=parseInt(this.state.offset)+1
    await this.setState({offset: offsetChange});
   
    if (parseInt(this.state.offset) + 1 > this.state.maxPageNumberLimit) {
        

        if(this.state.offset<Math.ceil(this.state.totalrow/this.state.limit)){
         
        this.setState({maxPageNumberLimit:maxPageNumberLimit + pageNumberLimit});
        this.setState({minPageNumberLimit:minPageNumberLimit + pageNumberLimit});
        }
      
    }
    this.ApiCall();

  };
  handleClick = (e) => {
    console.log("e", e.target.getAttribute("id"));
    this.setState({ offset: e.target.getAttribute("id") }, () => {
      this.ApiCall();
    });
  };
  handaleChange = (e) => {
    this.setState({ sort: e.target.value }, () => {
      this.ApiCall();
      this.ApiCallForReqestedPayout()
    });
  };
  showModal = () => {
    console.log("enter");
    this.setState({ showmodal: true });
  };

  closeModal = () => {
    this.setState({ showmodal: false, withdrawAmount: "", valid: "" });
  };

  ApiCallForReqestedPayout = () => {
    this.setState({ loader2: true });
    let { limit, offset, sort } = this.state;
    axios
      .get(`/payment/fetchwithdrawal?sort=${sort}`, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        console.log("transaction---res", res);

        if (res.data.success) {
          const data = res.data
          this.setState({ requestedAmount: data.requestedTotal });
          this.setState({
            processedAmount: data.hasprocessedTotal,
            allWithdrawalList: data.rows,
            pendingStatus: data.pendingStatus,
            gatewayMode: data.gatewayMode,
            kycStatus: data.kycStatus
          });
          this.setState({ loader2: false });
        } else {
          console.log(res.data.value)
          this.setState({ loader2: false });
        }
      })
      .catch((err) => {
        this.setState({ loader2: false });

        console.log(err);
      });
  };

  // handleWithdraw = async (e) => {
  //   //console.log("I am here" ,(this.state.wallet - this.state.requestedAmount) )
  //   if (this.currencyValue == "EUR") {
  //     console.log("Yes my currency is EUR");
  //     await this.setState({
  //       currencyBasedAmount: this.state.withdrawAmount * 1.21,
  //     });
  //   } else if (this.currencyValue == "GBP") {
  //     console.log("NO my currency is GBP");
  //     await this.setState({
  //       currencyBasedAmount: this.state.withdrawAmount * 1.4,
  //     });
  //   } else {
  //     console.log("default currency");
  //     await this.setState({ currencyBasedAmount: this.state.withdrawAmount });
  //   }
  //   e.preventDefault();
  //   let availableValue = this.state.wallet - this.state.requestedAmount;
  //   let after = parseFloat(availableValue.toFixed(2));
  //   let amount = Number(this.state.currencyBasedAmount);
  //   amount = parseFloat(amount.toFixed(2));

  //   // console.log("after",typeof after, after)
  //   // console.log("currencyBasedAmount",typeof amount, amount)
  //   // console.log("condition",after >= amount)

  //   if (after >= amount && amount > 0) {
  //     axios
  //       .post(
  //         `/payment/withdrawalrequest`,
  //         { amount },
  //         {
  //           headers: { "x-auth": this.props.token },
  //         }
  //       )
  //       .then((res) => {
  //         if (res.data.success) {
  //           //this.setState({withdrawAmount: '', valid: ''})
  //           this.ApiCallForReqestedPayout();
  //           //console.log("res data message", res.data.msg)
  //           this.closeModal();
  //           message.success(res.data.msg);
  //         } else {
  //           this.closeModal();
  //           message.error(res.data.msg);
  //         }
  //       })
  //       .catch((err) => {});
  //   } else {
  //     this.setState({ valid: "Invalid amount" });
  //   }
  // };

  handleWithdraw = async (handleWithdraw) => {
    const hideMessage = message.loading('Please wait...', 0)
    try {
      let coachuid = this.userdata.uid
      let gatewayMode = this.state.gatewayMode
      let returnedData = await axios.post(`/strip/payoutRequest`, {uid: coachuid, acct_no:this.state.vendorId,amount:handleWithdraw})
      hideMessage()
      if(returnedData) {
        if(returnedData.data.success){
          this.ApiCallForReqestedPayout();
          this.fetchVendorId()
          message.success("Withdrawal request created successfully!")
        }
        else{
          // message.error(returnedData.data.message)
          message.error("We could not process this request. Please visit Payment Management section and add bank details in order to withdraw money", 7)
        }
      }
      else {
        message.error("Some error occured")
      }
    }
    catch(err) {
      hideMessage()
      console.log(err)
      message.error("Something went wrong!")
    }
  }

  handleChange = (e) => {
    e.persist();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  paginate = (pagenumber) => {
    this.setState({ pageNumber: pagenumber });
  };

  render() {
    let calculatedBalance = this.state.processedAmount != null &&
      this.state.wallet != 0
        ? this.state.wallet - this.state.processedAmount
        : this.state.wallet

        console.log("after calculation", calculatedBalance)
    // let pageNumbers = [1,2,3,4,5]
    const pageNumbers = [];

    for (
      let i = 1;
      i <= Math.ceil(this.state.totalrow / this.state.limit);
      i++
    ) {
      pageNumbers.push(i);
    }
    console.log("this.state.-----", this.state.transactionDetails);
    //console.log("@@@@@@@@@@@@@@@@ processed value", this.state.processedAmount)
    // Pagination for personal transactions
    const indexOfLast = this.state.pageNumber * this.state.listPerPage;
    const indexOfFirst = indexOfLast - this.state.listPerPage;
    return (
      <LoadingOverlay
        active={this.state.tabOpend === 'booking' ? this.state.loader1 : this.state.loader2}
        // spinner={<BounceLoader />}
        spinner={
          <div>
            <CustomImageLoader image={LGspinner} animationType={"pulse"} />
            <br />
          </div>
        }
        text="Loading your Transactions..."
      >
         {this.state.ShowTransactionDetails!='' &&
        (
          <PaymentDetails 
         closemodal={this.modalclose}
         showmodal={this.state.modalshow}
         detials={this.state.ShowTransactionDetails}/>

        )}
        <div className="col-md-12">
          <div className="searchHead" style={{borderBottom: "none"}}>
            <div className="searchHeading">
              <h3>
                <img width="22px" src={teamSvg} alt="LG-Img" />
                Transaction History
              </h3>
            </div>
            <div
              className="walletBalance d-flex align-items-center tour-payout-request"
              style={{ color: "black", fontSize: "15px" }}
            >
              <div>
              <h5 style={{ marginBottom: "0", marginRight: "5px", fontSize: "15px" }}>
                {this.state.wallet != undefined ? (
                  <b>
                    Wallet Balance  
                    {/* <Tooltip title={this.state.wallet_balance_info} placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip> */}
                    :{" "}
                    {this.state.processedAmount != null &&
                    this.state.wallet != 0 ?
                      //  curencyFilter(
                      //     `USD##${
                      //       this.state.wallet - this.state.processedAmount
                      //     }`
                      //   )
                      `${this.currency[this.state.coach_wallet_balance_currency.toUpperCase()]} ${ Number.parseFloat(this.state.coach_wallet_balance).toFixed(2)}`
                      : 
                      // curencyFilter(`USD##${this.state.wallet}`)
                      `${this.currency[this.state.coach_wallet_balance_currency.toUpperCase()]} ${Number.parseFloat(this.state.coach_wallet_balance).toFixed(2) }`
                      }
                  </b>
                ) : (
                  <></>
                )}
              </h5>
              <h5 style={{ marginBottom: "0", marginRight: "5px", fontSize: "15px" }}>
                <b>
                  Pending amount: {this.currency[this.state.coach_wallet_balance_currency.toUpperCase()]} {this.state.status === "0" ? Number.parseFloat(this.state.coach_wallet_balance).toFixed(2) : Number.parseFloat(0).toFixed(2)}
                </b>
              </h5>
            </div>
              
              
            
              {parseFloat(this.state.coach_wallet_balance) > 0 ? (
                <AntdModal 
                modalTitle="Request For Withdrawal"
                modalContent="'Click Ok to confirm'"
                btnClassName="defaultBtn"
                btnText="Withdraw"
                callAsyncFunction={()=>this.handleWithdraw(this.state.coach_wallet_balance)}
                pendingStatus={this.state.pendingStatus}
                kycStatus={this.state.kycStatus}
              />
              ) : ""}
            </div>
            {this.state.key == "booking"? (
              <div className="searchShortBy" style={{ cursor: "pointer" }}>
                <img width="15px" src={sortSvg} alt="LG_Img" />
                <select
                  onChange={this.handaleChange}
                  style={{ cursor: "pointer" }}
                >
                  <option>--- Sort by ---</option>
                  <option
                    selected={this.state.sort == "asc" ? true : false}
                    value="asc"
                  >
                    A-Z
                  </option>
                  <option
                    selected={this.state.sort == "desc" ? true : false}
                    value="desc"
                  >
                    Z-A
                  </option>
                  <option
                    selected={this.state.sort == "date_asc" ? true : false}
                    value="date_asc"
                  >
                    Date(asc)
                  </option>
                  <option
                    selected={this.state.sort == "date_desc" ? true : false}
                    value="date_desc"
                  >
                    Date(desc)
                  </option>
                  <option
                    selected={this.state.sort == "order_asc" ? true : false}
                    value="order_asc"
                  >
                    Order No.(asc)
                  </option>
                  <option
                    selected={this.state.sort == "order_desc" ? true : false}
                    value="order_desc"
                  >
                    Order No.(desc)
                  </option>
                </select>
                <span>
                  <img
                    src={dArrowblack}
                    alt="LG_Img"
                    style={{ cursor: "pointer" }}
                  />
                </span>
              </div>
            ):
            (
              <div className="searchShortBy" style={{ cursor: "pointer" }}>
                <img width="15px" src={sortSvg} alt="LG_Img" />
                <select
                  onChange={this.handaleChange}
                  style={{ cursor: "pointer" }}
                >
                  <option>--- Sort by ---</option>
                  <option
                    selected={this.state.sort == "date_asc" ? true : false}
                    value="date_asc"
                  >
                    Date(asc)
                  </option>
                  <option
                    selected={this.state.sort == "date_desc" ? true : false}
                    value="date_desc"
                  >
                    Date(desc)
                  </option>
                </select>
                <span>
                  <img
                    src={dArrowblack}
                    alt="LG_Img"
                    style={{ cursor: "pointer" }}
                  />
                </span>
              </div>
            )}
          </div>

          {/* transaction list here */}

          {/* transaction list ends here */}
          <div className="userBookingList">
            <Tab.Container
              className="userbooking_tabs"
              id="left-tabs-example"
              defaultActiveKey="first"
              transition={false}
              id="noanim-tab-example"
              activeKey={this.state.key}
              onSelect={(k) => this.tabsctive(k)}
            >
              <Nav variant="tabs" defaultActiveKey="booking" className="navTab">
                <Nav.Item>
                  <Nav.Link eventKey="booking" onClick={() => {this.setState({tabOpend: 'booking'})}}>Booking History</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="personal" onClick={() => {this.setState({tabOpend: 'personal'})}}>Personal Transactions</Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="booking">
                  {this.state.key == "booking" ? (
                    <>
                     
                      {this.state.transactionDetails.map((transaction) => {
                        let longDescription = transaction.long_description!= null ? JSON.parse(`${transaction.long_description}`) :"";
                        let rateOfExchange = longDescription != "" && longDescription.exchange_rate !== null ? longDescription.exchange_rate : 0.00;

                        // IN WALLET AMOUNT CALCULATION
                        let coachCommissionAmount = Number(transaction.user_commision_amount)
                        let inWalletAmount = rateOfExchange !== 0.00 ? (coachCommissionAmount * rateOfExchange) : coachCommissionAmount;
                        // inWalletAmount = `${currencySign(longDescription.currency.toUpperCase())}${parseFloat(inWalletAmount).toFixed(2)}`;
                        inWalletAmount = longDescription != "" ? 
                          `${currencySign(longDescription.currency.toUpperCase())}${parseFloat(inWalletAmount).toFixed(2)}` 
                          :
                          `${currencySign(transaction.currency.toUpperCase())}${parseFloat(inWalletAmount).toFixed(2)}`;
                        
                        // STRIPE EXCHANGE RATE VALUE
                        let exchangeRate = rateOfExchange !== 0.00 ?
                        `${currencySign(longDescription.currency.toUpperCase())}${longDescription.exchange_rate}`                        
                        :
                        "0.00";

                        // LIFE GURU COMMISSION VALUE (CONVERTING TO USD VALUE)
                        // let lifeGuruCommission = transaction.paid_value!=null?(
                        //   curencyFilter(
                        //     `${transaction.paid_currency.toUpperCase()}##${transaction.admin_commission_amount}`, "USD"
                        //   )
                        // ):(
                        //   curencyFilter(
                        //     `${transaction.currency.toUpperCase()}##${transaction.admin_commission_amount}`, "USD"
                        //   )
                        // )
                        // LIFE GURU COMMISSION VALUE WITHOUT CONVERTING
                        let lifeGuruCommission = `${currencySign(transaction.paid_currency)}${parseFloat(transaction.admin_commission_amount).toFixed(2)}`

                        // CREATE A TOOLTIP TITLE WITH ABOVE THREE VALUES
                        let tooltipTitle = React.createElement("p", null, "LifeGuru Commission: "+lifeGuruCommission+ "", React.createElement("br", null), " Exchange Rate: "+exchangeRate+"");
                        

                        // CHECK PAYMENT GATEWAY
                        let chargeId = transaction.description ? transaction.description.split(" ").pop() : "";
                        let matchedRes = chargeId != "" ? chargeId.match(/ch_/gi) : null;
                        console.log("chargeId", chargeId)
                        transaction['gatewayName'] = matchedRes != null ? "stripe" : "bluesnap";


                        // const userDetails=JSON.parse(transaction.package_details)
                        let refDetails=transaction.description.split(".")
                        let UTCTimezone = "Europe/London (GMT +00:00)";
                        let coachTimezone = localStorage.getItem("timezone");
                        let bookingDate = transaction.paymentCreated; //moment(transaction.created).format('YYYY-MM-DD HH:mm')

                        let Coach = transaction.coachDetails;
                        let User = transaction.userDetails;
                        //console.log('users.............',User)
                        let packDetails = null;
                        let SessionName = "";
                        let packageDetails = null;
                        let purchaSession = null;
                        let sessionDetails = "";
                        let sessionDisplayName = "";
                        if (
                          transaction.packDetails !== null ||
                          transaction.packDetails !== undefined
                        ) {
                          packDetails = transaction.packDetails[0];
                          SessionName = packDetails.title;
                        } else {
                          packDetails = JSON.parse(transaction.package_details);
                          SessionName = packDetails.session_name;
                        }

                        if (
                          transaction.packageDetails !== null ||
                          transaction.packageDetails !== undefined
                        ) {
                          packageDetails = JSON.parse(
                            transaction.packageDetails
                          );
                          purchaSession = JSON.parse(
                            packageDetails[0].session_details
                          );
                          console.log("hiiiiiii", purchaSession);
                          let sessionfind =
                            transaction.sessionNo !== null
                              ? transaction.sessionNo.slice(7)
                              : null;

                          sessionDetails = purchaSession.filter((each, i) => {
                            return each.id == sessionfind;
                          });
                          sessionDisplayName =
                            sessionDetails != undefined &&
                            sessionDetails.length > 0
                              ? sessionDetails[0].sessionName
                              : "";

                          sessionDisplayName =
                            sessionDisplayName == SessionName
                              ? ""
                              : sessionDisplayName;
                          console.log("hiiiiiii nn", sessionDetails);
                          
                        }

                        return (
                          <>
                            {User !== null ? (
                              <div className="userBookingList trans_his">
                                <div className="bookingCocachDetails payment_details_coach">
                                  <ul>
                                    {/* <li><b>Order Id</b> <span>#001</span></li> */}
                                    <li>
                                      <b>Order No.</b>{" "}
                                      <span>
                                        {`${transaction.order_id}`.padStart(
                                          5,
                                          "0"
                                        )}
                                      </span>
                                     {/* <> <p style={{fontSize:"10px" , fontWeight:"bold" ,marginBottom:"4px",marginTop:"20px",whiteSpace: "nowrap"}}>({refDetails[0].trim()} :{refDetails[1].trim()})</p></> */}
                                    </li>
                                    <li>
                                      <b>Package & Session details</b>{" "}
                                      <span>
                                        {SessionName}{" "}
                                        {sessionDisplayName != ""
                                          ? "- " + sessionDisplayName
                                          : ""}
                                      </span>
                                    </li>
                                    <li>
                                      <b>Client Name</b>{" "}
                                      <span>
                                        {User.name !== undefined
                                          ? User.name
                                          : ""}
                                      </span>
                                    </li>
                                    <li>
                                      <b>Payment  Date</b>{" "}
                                      <span>
                                        {convertToTimezone(
                                          UTCTimezone,
                                          bookingDate,
                                          coachTimezone,
                                          dateConfig.dateTimeFormat
                                        )}
                                      </span>
                                    </li>
                                    <li>
                                      <b>Session Fee</b>{" "}
                                      <span>
                                        {/* {curencyFilterRates(
                                          `${transaction.currency}##${transaction.total_amount}`,
                                          transaction.currency_rates
                                        )} */}
                                        {transaction.paid_value!=null?(
                                          `${currencySign(transaction.paid_currency)}${transaction.paid_value}`
                                        ):(
                                          `${currencySign(transaction.currency)}${transaction.total_amount}`
                                        )}
                                       
                                      </span>
                                    </li>
                                    {/* <li>
                                      <b>In USD</b>{" "}
                                      <span>
                                       
                                      {currencySign(transaction.currency)}{parseFloat(transaction.total_amount).toFixed(2)}<Tooltip title={tooltipTitle}>
                                      <span><i className="fa fa-info-circle"></i></span>
                                      </Tooltip>
                                       
                                      </span>
                                    </li> */}
                                    <li>
                                      <b>Total Received</b>{" "}
                                      <span>
                                       
                                      {inWalletAmount}<Tooltip title={tooltipTitle}>
                                      <span><i className="fa fa-info-circle"></i></span>
                                      </Tooltip>
                                       
                                      </span>
                                    </li>
                                    
                                    {/* <li><b>In Wallet</b> <span>{(transaction.has_paid == '1')?'Yes':'No'}</span></li> */}
                                    {/* <li>
                                      <b>Payment Type</b>{" "}
                                      <span>{transaction.payment_type}</span>
                                    </li> */}
                                    {transaction.payment_status ===
                                      "succeeded" ||
                                    transaction.payment_status.search(
                                      "Approved"
                                    ) >= 0 ? (
                                      <li>
                                        <b>Status
                                        {/* {transaction.long_description!=null && (
                                          <EyeOutlined className="eyeicon" onClick={()=>this.ShowTransactionDetails(transaction)}/>
                                          )} */}
                                          <EyeOutlined className="eyeicon" onClick={()=>this.ShowTransactionDetails(transaction)}/>
                                        </b>{" "}
                                        <span className="badge text-white  badge-success">
                                          Success
                                        </span>
                                      </li>
                                    ) : (
                                      <li>
                                        <b>Status
                                        {/* {transaction.long_description!=null && (
                                          <EyeOutlined className="eyeicon" onClick={()=>this.ShowTransactionDetails(transaction)}/>
                                          )} */}
                                          <EyeOutlined className="eyeicon" onClick={()=>this.ShowTransactionDetails(transaction)}/>
                                          </b>{" "}
                                        <span className="badge text-white text-  badge-danger">
                                          Cancelled
                                        </span>
                                      </li>
                                    )}
                                    <li className="user-transaction-refNo">
                                    {refDetails!='' && (<>
                                      <p style={{fontSize:"10px" , fontWeight:"bold" ,marginBottom:"4px",whiteSpace: "nowrap"}}>({refDetails[0].trim()} :{refDetails[1].trim()})</p>
                                      </>)}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}

                      {this.state.transactionDetails.length <= 0 &&
                      this.state.loader1 == false ? (
                        <>
                          <div className="bookingCocachDetails">
                            <ul>
                              <li style={{ width: "100%", textAlign: "center" }}>
                                <b>No Records Found</b>
                              </li>
                            </ul>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      <div
                        className="text-center"
                        style={{ cursor: "pointer" }}
                      >
                        <nav aria-label="Page navigation example">
                          <ul className="pagination justify-content-center my-5 flex-wrap">
                            {pageNumbers.length > 1 && this.state.offset > 1 && (
                              <>
                                <li className="page-item">
                                  <a
                                    className="page-link"
                                    aria-label="Previous"
                                    onClick={this.handlePrevious}
                                  >
                                    <i
                                      className="fa fa-angle-left"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </li>
                              </>
                            )}
                            {pageNumbers.map((number) => {
                                 if (number < this.state.maxPageNumberLimit + 1 && number > this.state.minPageNumberLimit) {
                              return (
                                <li className="page-item">
                                  <a
                                    className={
                                      this.state.offset == number
                                        ? "page-link  active"
                                        : "page-link "
                                    }
                                    key={number}
                                    id={number}
                                    onClick={this.handleClick}
                                  >
                                    {number}
                                  </a>
                                </li>
                              );
                            } else {
                              return null;
                            }

                            })}

                            {pageNumbers.length > 1 &&
                              this.state.offset < pageNumbers.length && (
                                <>
                                 <div>{pageNumbers.length>this.state.limit?"..":""}</div>
                                  <li className="page-item">
                                    <a
                                      className="page-link"
                                      aria-label="Next"
                                      onClick={this.handleNext}
                                    >
                                      <i
                                        className="fa fa-angle-right"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  </li>
                                </>
                              )}
                          </ul>
                        </nav>
                      </div>
                      
                    </>
                    
                  ) : (
                    <></>
                  )}
                  
                </Tab.Pane>

                <Tab.Pane eventKey="personal">
                  {this.state.key == "personal" ? (
                    <>
                      {this.state.allWithdrawalList
                        .slice(indexOfFirst, indexOfLast)
                        .map((each, index) => {
                          let currency = localStorage.getItem("currency")
                          let UTCTimezone = "GMT (GMT +00:00)";
                          let coachTimezone = localStorage.getItem("timezone");
                          let requestedDate = moment(each.requestDate).utc().format(
                            "YYYY-MM-DD HH:mm"
                          );
                          let updatedDate = moment(each.completeDate).utc().format(
                            "YYYY-MM-DD HH:mm"
                          );
                          console.log("requirements",each.requestDate, typeof each.requestDate, UTCTimezone, requestedDate, coachTimezone)
                          return (
                            <div className="userBookingList trans_his">
                            <div className="bookingCocachDetails payment_details_coach">
                              <ul>
                                {/* <li><b>Order Id</b> <span>#001</span></li> */}
                                <li>
                                  <b>Sl.No.</b> <span>{index + 1}</span>
                                </li>
                                {/* <li style={{width: "10%"}}>
                                  <b>Balance</b> <span>  {each.amount !== null ?
                                    // curencyFilter(`USD##${each.amount}`)
                                    `$ ${Number.parseFloat(each.amount).toFixed(2)}`
                                    : 
                                    // (curencyFilter(`USD##${calculatedBalance}`))
                                    `$ ${Number.parseFloat(calculatedBalance).toFixed(2)}`
                                    }</span>
                                </li> */}
                                
                                <li style={{width: "15%"}}>
                                  <b>Payout Amount</b>{" "}
                                  <span>
                                    {/* {each.status == "1" &&(<> */}
                                        {each.amount !== null ?
                                        // curencyFilter(`USD##${each.amount}`)
                                        `${each.currency ==null? '$' : 
                                        this.currency[each.currency.toUpperCase()]
                                      } ${Number.parseFloat(each.amount).toFixed(2)}`
                                        : 
                                        // (curencyFilter(`USD##${calculatedBalance}`))
                                        `${each.currency ==null? '$' : 
                                        this.currency[each.currency.toUpperCase()]
                                      } ${Number.parseFloat(calculatedBalance).toFixed(2)}`
                                        }
                                    {/* </>)} */}
                                  </span>
                                </li>
                                {/* <li><b>Currency</b> <span>{each.currency}</span></li> */}
                                <li style={{width: "20%"}}>
                                  <b>Requested Date <Tooltip title={this.state.payout_request_date_info} placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip></b>{" "}
                                  <span>
                                    {convertToTimezone(
                                      UTCTimezone,
                                      requestedDate,
                                      coachTimezone,
                                      dateConfig.dateTimeFormat
                                    )}
                                  </span>
                                </li>
                                <li>
                                  <b>Processed Date</b>{" "}
                                  {updatedDate !== "Invalid date" ? convertToTimezone(
                                      UTCTimezone,
                                      updatedDate,
                                      coachTimezone,
                                      dateConfig.dateTimeFormat
                                    ): (
                                      <span className="badge text-white  badge-warning">
                                      Pending
                                    </span>
                                    )}
                                </li>
                                <li>
                                  <b>Status</b>{" "}
                                  {each.status == "1" ? (
                                    <span className="badge text-white  badge-success">
                                    Success
                                  </span>
                                  ) : each.status == "0" ? (
                                    <span className="badge text-white  badge-warning">
                                      Pending
                                    </span>
                                  ) : (
                                    <span className="badge text-white  badge-danger">
                                      Rejected
                                    </span>
                                  )}
                                </li>
                              </ul>
                            </div>
                            </div>
                          );
                        })}

                      {/* When no records found */}
                      {this.state.allWithdrawalList.length <= 0 ? (
                        <div className="bookingCocachDetails">
                          <ul>
                            <li
                              style={{ flex: "0 0 100%", textAlign: "center" }}
                            >
                              <b>No Records Found</b>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* end */}
                      {/* <div className="payoutBalance">
                          <p>
                            <b>Payout Balance: {" "}
                            {this.state.processedAmount !== null ? Number.parseFloat(this.state.processedAmount).toFixed(2) : Number.parseFloat(0).toFixed(2)}
                            </b>
                          </p>
                        </div> */}

                      {/* <div className="searchHead">
                        <div className="searchHeading">
                          <p>
                            Available amount:{" "}
                            {this.state.wallet - this.state.requestedAmount < 0
                              ? curencyFilter(`USD##${this.state.wallet}`)
                              : curencyFilter(
                                  `USD##${
                                    this.state.wallet -
                                    this.state.requestedAmount
                                  }`
                                )}
                          </p>
                        </div>
                        <div className="searchShortBy">
                          <p>
                            Payout Balance{" "}
                            {Number.parseFloat(this.state.processedAmount).toFixed(2)}
                          </p>
                        </div>
                      </div> */}
                    </>
                  ) : (
                    <></>
                  )}
                  {this.state.allWithdrawalList.length >
                  this.state.listPerPage ? (
                    <Pagination
                      postsPerPage={this.state.listPerPage}
                      totalPosts={this.state.allWithdrawalList.length}
                      paginate={this.paginate}
                      currentPage={this.state.pageNumber}
                    />
                  ) : (
                    ""
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default CoachTransactionhistory;
