import React from 'react';


const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
  console.log("PAGECOUNT", pageNumbers)
  console.log("page number", currentPage)
  return (
    // <nav>
    //   <ul className='pagination'>
    //     {pageNumbers.map(number => (
    //       <li key={number} className='page-item'>
    //         <a onClick={() => paginate(number)} href='!#' className='page-link'>
    //           {number}
    //         </a>
    //       </li>
    //     ))}
    //   </ul>
    // </nav>
    <div className="text-center">
          {totalPosts ? (
              <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center my-5 flex-wrap">
                <li className="page-item">
                  {currentPage > 1 ?
                    <a onClick={() => paginate(currentPage-1)} className="page-link"  aria-label="Previous" style={{color:"#0b0b0b", cursor:"pointer"}}>
                    <i className="fa fa-angle-left" aria-hidden="true" ></i>
                  </a>
                  : ""}
                </li>
                {pageNumbers.map(number =>{
                  return(
                      <li key={number} className='page-item'>
                          <a onClick={() => paginate(number)} 
                          className={currentPage==number? 'page-link  active' : 'page-link '} 
                          style={{color:"#0b0b0b", cursor:"pointer"}}>
                          {number}
                          </a>
                      </li>
                  )
                })}
               
                
                <li className="page-item">
                  {pageNumbers.length <= currentPage ? "" : <a onClick={() => paginate(currentPage+1)} className="page-link" aria-label="Next" style={{color:"#0b0b0b", cursor:"pointer"}}>
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </a>}
                </li>
              </ul>
            </nav>
          ) : ""}
        </div>
  );
};

export default Pagination;