import React from "react";
import axios from "../../config/axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import message from '../../utils/mesaages';
import { Modal } from "react-bootstrap";
import {  docSvg, pdfSvg, pptSvg, xlsSvg } from "../../assets/images";
class EditFileName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: "",
    };
  }

  componentDidMount() {
    console.log("this props value", this.props.resourceName)
    console.log("this state value", this.state.fileName)
  }

  componentDidUpdate(prevProps) {
    if(this.props.resourceName !== prevProps.resourceName) {
      this.setState({fileName: this.props.resourceName})
    }
  }

  handleChange = (e) => {
    e.persist();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.handleEditTitleModal()
    const uid = this.props.resourceId;
    const formData = {
      fileName: this.state.fileName,
    };
    axios
      .put(`/users/resources/updateresourceName/${uid}`, formData, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        this.setState({fileName: ""})
        if (res.data.success) {
          message.success("Title updated");
          this.setState({fileName: ""})
          this.props.handlegetResource();
        }
        console.log("@@@ i am here after submit", this.state.fileName)
      })
      .catch((err) => {
        this.setState({fileName: ""})
        message.error("Error in updating title");
        console.log(err);
      });
  };
  render() {
    const array = this.props.resourcePath.split("/").pop()
    const filetype = array.split(".").pop().toLowerCase()
    // console.log("@@@@@ props title @@@@@@@@@@", this.props.resourceName)
    // console.log("@@@@@ state title @@@@@@@@@@", this.state.fileName)
    return (
      <Modal className="modal fade" id="editfileName" show={this.props.editTitleModal}>
        {/* <div className="modal-dialog modal-dialog-centered"> */}
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalCenterTitle" style={{color:"black"}}>
                <b>Edit File Title</b>
              </h4>
              <button
                type="button"
                className="close"
                //data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.handleEditTitleModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {/* <label style={{ marginRight: "1.2rem",  color: "black" }}>File Title</label> */}
              <input
                className="fileName"
                type="text"
                placeholder="Enter title.."
                // defaultValue={this.state.fileName != '' ? this.state.fileName : this.props.resourceName}
                value={this.state.fileName}
                size="50"
                onChange={this.handleChange}
                name="fileName"
                style={{color:"black"}}
              />{" "}
              <hr />
              {this.props.viewMore === "Video" && (
                <>
                  <video
                    id={this.props.resourceId}
                    width="100%"
                    height="auto"
                    style={{ width: "100%;", height: '200px' }}
                    poster={
                      "https://markipo.com/Markipo_Reportages_Cyclisme_13_10/res/video.poster.jpg"
                    }
                    preload="none"
                    controls
                    playsInline
                    webkit-playsinline
                  >
                    <source src={this.props.resourcePath} type="video/mp4" />
                    <track
                      srcLang="en"
                      kind="subtitles"
                      src="mediaelement.vtt"
                    />
                    <track srcLang="en" kind="chapters" src="chapters.vtt" />
                  </video>
                </>
              )}
              {this.props.viewMore === "Photo" && (
                <>
                  <Link
                    to={this.props.resourcePath}
                    data-toggle="lightbox"
                    data-gallery="example-gallery"
                    className="boxWrap"
                  >
                    <img
                      src={this.props.resourcePath}
                      alt="LG-Img"
                      className="img-fluid"
                      style={{height: '200px'}}
                    />
                  </Link>
                </>
              )}
              {this.props.viewMore === "Document" && (
                <div className="pf__pdf">
                  {/* <Link to={this.props.resourcePath}>
                    <img
                      alt="LG-Img"
                      src="https://lifeguru.s3-us-west-2.amazonaws.com/assets/doc_placeholder.png"
                    />
                  </Link> */}
                  {/* <div className="iframepdf"> */}
                          {/* <iframe
                        height="200px"
                        alt="LG-Img"
                        //src="https://lifeguru.s3-us-east-2.amazonaws.com/assets/doc_placeholder.png"
                        src={this.props.resourcePath}
                      /> */}
                      <a
                       href={this.props.resourcePath}
                       target="_blank"
                       className="boxWrap"
                     >
                       <img
                        alt="LG-Img"
                        src={filetype == 'pdf' ? pdfSvg : filetype == 'doc' || filetype == 'docx' ? docSvg : filetype == 'xls' || filetype == 'xlsx' ? xlsSvg : filetype == 'ppt' || filetype == 'pptx' ? pptSvg : "https://lifeguru.s3-us-east-2.amazonaws.com/assets/doc_placeholder.png"}
                        className="img-fluid"
                        width="200px"
                        height="200px"
                        />
                     </a>
                        {/* </div> */}
                </div>
              )}
              {this.props.viewMore === "Audio" && (
                <div className="pf__audio">
                  <img
                    alt="LG-Img"
                    src="https://marketplace.bantu.my/assets/audio-placeholder-304b4c582a7bc94e6bfeefa1cde5582dd56ab86affa79b6cc9d70e3027926ee8.png"
                    style={{height: '180px'}}
                  />

                  <audio controls>
                    <source src={this.props.resourcePath} type="audio/ogg" />
                    <source src={this.props.resourcePath} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={this.handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        {/* </div> */}
      </Modal>
    );
  }
}
export default EditFileName;
