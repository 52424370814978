import React, { Component } from "react";
import axios from "../../config/axios";
import { toast } from "react-toastify";
import { Package, UpdatePackageContent } from "./edit";
import { AddPackage, AddTag } from "./add";
import { Link } from "react-router-dom";
import VideoView from "../../components/media/VideoView";
import { coachesVideo, watchPng, deleteSvg } from "../../assets/images";
import UpdateVideo from "./edit/UpdateVideo";
import ClientFeedback from "./view/ClientFeedback";
import BookPackage from "../../components/commonmodal/bookPackageModal";
import CoachSideBar from "./coachSideBar";
import { Tooltip } from 'antd';
import message,{getHelpText} from '../../utils/mesaages';
import { CoachTransactionhistory,ConsumerPayment,JoinSession,PaymentReceived,ManageSubscription,SetAvailability,UpcomingSession, ReviewList, Packages } from "./sidebarComponents";
import MySchedule from "./sidebarComponents/MySchedule";
import validation from '../../Validate/validator';
import ReactPlayer from 'react-player'
import ConfirmationModal from '../../components/commonmodal/ConfirmationModal'
import TourSite from '../../utils/TourSite'



class Home extends Component {
  constructor(props, context) {
    super(props, context);
  
    this.token = localStorage.getItem("authToken");
    console.log('aaa',this.token)
    this.state = {
      packagescollection: [],
      testimonialcollection: [],
      isPackageContent: "",
      packageContent: "",

      title: "",
      description: "",
      price: "",
      isactive: "1",
      introDuration: "0", //HH:MM in MM
      duration: "0", //HH:MM in MM
      session_HH: "0",
      session_MM: "0",
      package_type: "",
      total_sessions: "",
      session_details: [],
      cost: "",
      sessionDescription: "",
      Length: "",
      videofile: "",
      packageName:"",
      packageUid:"",
      packPrice:"",
      renderSideBarComponent:"",
      packages_help_text:'',
      Intromessage:'',
      banner: [],
      tags_help_text: '',
      packageCount: '',
      video_delete_confirmation: '',
      FromDataError: {
        package_type: 'false',
        title: 'false',
        description:'false',
        introDuration:'false',
        duration:'',
        cost:'false',
        price:'false',
      },
      //for Tour section
      increaseTour:false,
      hideTour:false,
      AddPackageModalTour:'',
      paymentManageTab:'',
      hideButton: false
      
      //

    };
  }
//for Tour section
  hideTour=(key)=>{
    if(this.state.hideTour==false)
    this.setState({hideTour:key})
  }
  handleIncreaseTour=(step)=>{
    this.setState({increaseTour:step})
  }
  handelAddPackageModalTour=(status)=>{
    this.setState({AddPackageModalTour:status})

  }
  handalPaymentManageTab=()=>{
    this.setState({paymentManageTab:'bank'})

  }
  //

  async componentDidMount() {

    if(localStorage.getItem("membershipExpCheck")=='true')
    {
      
      await this.setState({renderSideBarComponent:"ManageSubscription"})
      localStorage.removeItem("membershipExpCheck");
    }

    let packages_help_text = await getHelpText('packages_text')
     this.setState({packages_help_text})
     let tags_help_text = await getHelpText('tags_text')
     this.setState({tags_help_text})
     let Intromessage = await getHelpText('intro_field_text')
     this.setState({Intromessage})
     let introVideo_delete_confirmation = await getHelpText('introVideo_delete_confirmation')
     this.setState({introVideo_delete_confirmation})
   
    // if(this.props.publicView === true){
    //   console.log('is public view' , this.state);
    //   console.log('is public props' , this.props);


    //   // this.getPackagesforpublic()
    // this.getPackages();
      

    // }
    this.getPackages();
    this.getPackageContent();

    if (this.props.check_account_type === "coach") {
      console.log('component')
      this.getPackages();
      this.getTestimonial();
      this.getPackageContent();
      setTimeout(this.getTestimonial(), 1200);
    } else {
      if (this.props.check_account_type === "consumer") {
        this.consumerMode();
      this.getPackages();

      }
    }
    this.getHomeContent()
  }

  getHomeContent = () => {
    this.setState({loader: true})
    let self = this
    axios.get('/cmshome/homeContent')
    .then(async (response) => {
      //console.log("response landing", response.data.value.webinar)
      if(response.status === 200){
        self.setState({
          banner: JSON.parse(response.data.value.banner[0].content_json),
         
          loader: false,
        })
      } else {
        self.setState({loader: false})
        console.log("error response", response)
      }
    })
    .catch((e) => {
      console.log(e)
      self.setState({loader: false})
    })
  }

  consumerMode() {}

  // ________________________________________fetch_data________________________________
  getPackages() {
    console.log('fetch public package')
    const id = this.props.uid;

    axios
      .get(`/users/packages/${id}`, {
        // headers: {
        //   "X-Auth": this.token,
        // },
      })
      .then(async(res) => {
        await this.setState({ packagescollection: res.data.value, packageCount: res.data.count[0].totalpackage });
        console.log("found new data" )
        console.log(res.data)
        console.log(this.state.packagescollection)
        this.packageCheck();
      })
      .catch((error) => {
        console.error(error);
      });
  }



  getTestimonial() {
    axios
      .get("/users/testimonials", {
        // headers: {
        //   "X-Auth": this.token,
        // },
      })
      .then((res) => {
        this.setState({ testimonialcollection: res.data.value });
        // this.getList();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getPackageContent() {

    //console.log('jfjfjjfjfjfjjfjfjjfjfjjf content')
    axios
      .get("/users/packagecontent-coach", {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        console.log('hfhfhhfhhfhfhfhhsbbdnndhdhdfnnfnfppp ',res.data.value.content)
        if (res.data.success && res.data.value.content!=undefined) {
          const pacdata = res.data && res.data.value && res.data.value.content;
          this.setState({ isPackageContent: true, packageContent: pacdata });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //___________________________________________Handle_function________________________

  handleChange = (e) => {
    e.persist();
    this.setState({
      [e.target.name]: e.target.value,
    });

    var valid_obj = {
      value: e.target.value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg")
    }
    let {FromDataError} =this.state


    validation(valid_obj).then((error) => {


    
      FromDataError[e.target.name] = error
      this.setState({ FromDataError })

    })


  };

  handlePackType = (e) => {
    e.persist();
    this.setState({
      package_type: e.target.value,
    });
    
    if(e.target.value=='Single Session')
    {
      let {FromDataError} = this.state
      FromDataError['duration'] = 'false'
      this.setState({FromDataError})
    }
    else
    {
      let {FromDataError} = this.state
      FromDataError['duration'] = ''
      this.setState({FromDataError})

    }

    if(e.target.value=='')
    {
      let {FromDataError} = this.state
      FromDataError['package_type'] = 'Select Session Type'
      this.setState({FromDataError})
    }
    else{
      let {FromDataError} = this.state
      FromDataError['package_type'] = ''
      this.setState({FromDataError})

    }
  };

  HandleEnabled = (e) => {
    if (e.currentTarget.checked) {
      this.setState({ break_enabled: true });
    } else {
      this.setState({ break_enabled: false });
    }
  };

  packageCheck() {
    if (this.state.packagescollection.length > 0) {
      this.props.packagefound();
    } else {
      this.props.packageNotfound();
    }
  }

  //_______________________________________Handle_submit_function________________________

  handlePackageContentSubmit = (e) => {
    
    const hideMessage = message.loading('Please wait while we are updating', 0)
    e.preventDefault();
    const formData = {
      content: this.state.packageContent,
    };

    let param;
    if (this.state.isPackageContent) {
      param = {
        url: "/users/packages/packagecontent",
        method: "PUT",
        data: formData,
        headers: { "X-Auth": this.token },
      };
    } else {
      param = {
        url: "/users/packages/packagecontent",
        method: "POST",
        data: formData,
        headers: {
          "X-Auth": this.token,
        },
      };
    }

    axios(param)
      .then((response) => {
        hideMessage()
        console.log(response);
        if (response.data.success) {
          message.success("successfully updated");
        } else {
          message.error("can not updated");
        }
        this.getPackageContent();
        this.packageCheck();
      })
      .catch((error) => {
        hideMessage()
        message.error("Error while updating package");
        console.error(error);
      });
  };
  validateForm=(errors=this.state.FromDataError)=> {
    console.log('vvvvvvvvvvvvvvvvvvvvv')
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {

        if (value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }

        valid = false;
      }
    }

    return valid;
  };

  handleSubmitPackage = (e, data) => {
     e.preventDefault();
   console.log("package ---data---",data)
    // const durationMin =
    //   this.state.session_HH * 60 + parseInt(this.state.session_MM);

    if(this.validateForm(this.state.FromDataError))
    {
            var raw_session = JSON.stringify(data);

          var list_data = JSON.parse(raw_session);
          var total_session_time = 1;
          var introDuration = this.state.introDuration
          let total_session_count = 0;

          if (this.state.package_type === "Single Session") {
            var total_session_time = this.state.duration;
          
          } else if (this.state.package_type === "Multi Session") {
            var total_session_time = 0;

            if (list_data) {
              
              list_data.map(
                (time) =>
                  {total_session_time = total_session_time + parseInt(time.Length)
                    total_session_count++
                  }
              );
              this.setState({total_sessions:total_session_count})
              
            }
          } else {
            var total_session_time = this.state.duration;
          }

          let singleSessionObject=[{
            id:"0",
            sessionName:this.state.title,
            sessionDescription:this.state.description,
            Length:total_session_time
          }]

          const formData = {
            title: this.state.title,
            description: this.state.description,
            price: this.state.cost + "##" + this.state.price,
            introDuration: introDuration,
            duration: total_session_time,
            isactive: this.state.isactive,
            package_type: this.state.package_type,
            total_sessions: this.state.package_type === "Single Session" ? 1 : total_session_count,
            // session_details: JSON.stringify(data),
            session_details:this.state.package_type=== "Single Session"? (JSON.stringify(singleSessionObject)):(JSON.stringify(data)),
          };

          console.log("package-----formadata----",formData)
         // return ;
          const hideMessage = message.loading('Please wait while we are adding package', 0)

          axios
            .post("/users/packages", formData, {
              headers: {
                "X-Auth": this.token,
              },
            })
            .then((res) => {
              hideMessage()
              if (res.data.success) {
                message.success(res.data.msg);
              } else {
                message.error("Adding package failed");
              }
              this.getPackages();
              this.packageCheck();
            })
            .catch((error) => {
              message.error("Error while adding package");
              hideMessage()
              console.error(error);
            });

    }
    else
    {
      console.log('er',this.state.FromDataError)
      message.error("Please fill the form correctly");
      return false
    }

    
  };

  handleEditPackage = (formData) => {
    const hideMessage = message.loading('Please wait while we are updating', 0)
    const id = formData.id;
    axios
      .put(`/users/packages/updatepackage/${id}`, formData, {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        hideMessage()
        if (res.data.success) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
        this.getPackages();
      })
      .catch((error) => {
        hideMessage()
        console.error(error);
        message.error("Failed! please try again later");
      });
  };

  handleDeletePackage = (id) => {
    const hideMessage = message.loading('Please wait while we are deleting package', 0)
    axios
      .delete(`/users/packages/updatepackage/${id}`, {
        headers: { "X-Auth": this.token },
      })
      .then((res) => {
        hideMessage()
        if (res.data.success) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
        this.getPackages();
      }).catch((error) => {
        hideMessage()
        console.log(error)
        message.error("Some error occured.")
      })
  };

  handleActivatePackageStatus = (id) => {
    const formData = {
      isactive: 1,
    };
    axios
      .put(`/users/packages/updatepackageStatus/${id}`, formData, {
        headers: { "X-Auth": this.token },
      })
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
        this.getPackages();
      });
  };

  handleInactivatePackageStatus = (id) => {
    const formData = {
      isactive: 0,
    };
    axios
      .put(`/users/packages/updatepackageStatus/${id}`, formData, {
        headers: { "X-Auth": this.token },
      })
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
        this.getPackages();
      });
  };

  handleResetPackage=() =>{
    this.setState({
      title: "",
      description: "",
      price: "",
      package_type: "",
      introDuration: 0,
      duration:0,
      cost:"",
      total_sessions: "",
      session_details: [],
      sessionDescription:"",
      FromDataError: {
        package_type: 'false',
        title: 'false',
        description:'false',
        introDuration:'false',
        duration:'',
        cost:'false',
        price:'false',
      },
    })
  }
 
  handlePackageBook=(data)=>{
    this.setState({ packageName:data.packname,
      packPrice:data.packprice,
      packageUid:data.packUid})
  }

  handleSubmitProfileVideo = async(response, filetype) => {
    if (response.data.success) {
      await this.setState({ videofile: response.data.profilePost.profile_video });
      this.props.handlegetProfileVideo()
      if(filetype == 'mov'){
        message.info("Your profile video is being processed, Please check after sometime", 10);
      } 
      else {
        message.success("successfully added profile video");
      };
      
    } else {
      message.error("Error while updating profile video");
    }
  };

  handleSideBarComponent = (cname) => {
    console.log('lllllllllllllll',cname)
    this.setState({ renderSideBarComponent: cname });
  };

  changeHomePanel=()=>{
    //this.setState({ renderSideBarComponent: '' });
  }

  handleDeleteIntroVideo = (e) => {
    //console.log("Yes clicked", this.token)
    let videoUrl =  this.state.videofile && this.state.videofile.length > 0 ? this.state.videofile : this.props.videofile

    //e.preventDefault()
    axios.post(`/users/delete-introvideo`,{videoUrl},{
      headers:{"x-auth":this.token}
    }).then((res) => {
      console.log("### response",res)
      if(res.data.success) {
      this.props.handlegetProfileVideo()
      message.success("Your introductory video deleted successfully");
      }
      else {
        message.error("Error while deleteing introductory video")
      }
    }).catch((err) => {
      message.error("Some error occured")
    })
    
  }

  handleDisableButtons = (value) => {
    if(value) {
      this.setState({hideButton: value})
    }
    else {
      this.setState({hideButton: value})
    }
  }

  render() {
   
 console.log("this.props.Consumer_uid-------------=================",this.props.Consumer_email)
 console.log("homejsx package-------------=================",this.state.packageContent)
 console.log("======------------=========-----------=========FOUND",  this.props.check_account_type)

    const { account_type } = this.props;

    let sideBarSubComponent

        //  if(this.state.renderSideBarComponent==="UpcomingSessions"){
        //     sideBarSubComponent=(<UpcomingSession
        //       token={this.token}/>)
        // }else
         if(this.state.renderSideBarComponent==="SetAvailability"){
          sideBarSubComponent=(<SetAvailability
           status={this.props.setAvailabilityStatus}
           token={this.token}
           coachUid={this.props.uid}
           totalProfileUpdateStatus={this.props.totalProfileUpdateStatus}
           //for Tour section
           hideTour={this.hideTour}
           //
          />)
        }else if(this.state.renderSideBarComponent==="JoinSession"){
          sideBarSubComponent=(<JoinSession/>)

        }else if(this.state.renderSideBarComponent==="MySchedule"){
        sideBarSubComponent=(<MySchedule
          token={this.token}/>)
        } else if(this.state.renderSideBarComponent==="PaymentsReceived"){
          sideBarSubComponent=(<PaymentReceived   token={this.token}/>)
        } 
        else if(this.state.renderSideBarComponent==="ManagePayments"){
          sideBarSubComponent=(<ConsumerPayment
            tab={this.state.paymentManageTab}
            token={this.token}/>)
        } 
        else if(this.state.renderSideBarComponent==="ManageSubscription"){
          sideBarSubComponent=(<ManageSubscription
           token={this.token}/>)
       } 
        else if(this.state.renderSideBarComponent==="TransactionHistory"){
          sideBarSubComponent=(<CoachTransactionhistory
            token={this.token}/>)
        }
        else if(this.state.renderSideBarComponent==="Reviews"){
          sideBarSubComponent=(<ReviewList   token={this.token} coachUid={this.props.uid}/>)
        }
        else if(this.state.renderSideBarComponent==="Packages"){
          sideBarSubComponent=(<Packages   
            token={this.token} 
            coachUid={this.props.uid}
            uid={this.props.uid}
            coachImage={this.props.coachImage}
            coachName={this.props.coachName}
            coachData={this.props.coachData}
            ConsumerD={this.props.ConsumerD}
            account_type={this.props.account_type}
            findcurrency={this.props.findcurrency}
            convertToCurrency={this.props.convertToCurrency}
            baseCurrency={this.props.baseCurrency}
            sessionLength={this.props.sessionLength}
            userdata={this.props.userdata}
            check_account_type={this.props.check_account_type}
            packagefound={this.props.packagefound}
            packageNotfound={this.props.packageNotfound}
            //for Tour section
            handleIncreaseTour={this.handleIncreaseTour}
            hideTour={this.hideTour}
            AddPackageModalTour={this.state.AddPackageModalTour}
            handelAddPackageModalTour={this.handelAddPackageModalTour}
            //
            
            />)
        }
    return (
      <React.Fragment>
        <TourSite 
        handleSideBarComponent={this.handleSideBarComponent}
        handleSubComponent={this.props.handleSubComponent}
        TourResourceModal={this.props.TourResourceModal}
        handelAddPackageModalTour={this.handelAddPackageModalTour}
        increaseTour={this.state.increaseTour}
        hideTour={this.state.hideTour}
        handaleLoader={this.props.handaleLoader}
        handalPaymentManageTab={this.handalPaymentManageTab}
        // page="coach-dashboard"
        />
        {/* <section className="coachWrap coacActionhWrap">
          <div className="container">
            <div className="row justify-content-center"> */}
            {/* <div className="col-md-3">
              {account_type === "coach" ? (
                <div className="userDashboardLeftPart coachInfoWrap ">
                <CoachSideBar
                renderSideBarComponent={this.state.renderSideBarComponent}
                handleSideBarComponent={this.handleSideBarComponent}
                /><br/>
                </div>
              ):("")}
            
              </div> */}
              {this.state.renderSideBarComponent==="" ?(
              <div className="">
  
                <div className="coachProfileVideo text-center tour-intoVideo">
                {console.log('videooooooo ',this.state.videofile)}
                {this.props.videofile !=null ? 
                  <VideoView
                    poster={coachesVideo}
                    defaultvideo={this.state.banner.video_url}
                    video={
                      this.state.videofile && this.state.videofile.length > 0
                        ? this.state.videofile
                        : this.props.videofile
                    }
                    flag={this.props.processed}
                  />
                  :
                  <div className="aboutVideo">
                  {/* <iframe height="470" 
                  poster={coachesVideo}
                  src={`${this.state.banner.video_url}`} 
                  frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                  <ReactPlayer 
                    url={`${this.state.banner.video_url}`} 
                    controls={true} 
                    muted={true} 
                    playing={true} 
                    loop={true}
                    height="470px"
                    width="100%"
                    //playsinline
                    //light={this.state.banner.thumbnail_url} 
                  />
              
                  </div>

                }
              
          

                  <UpdateVideo
                    video={this.props.videofile}
                    handleSubmitProfileVideo={this.handleSubmitProfileVideo}
                    coachprofile_id={this.props.coachprofile_id}
                    processed={this.props.processed}
                    handleDisableButtons ={this.handleDisableButtons}
                  />
                  {/* <div
                  //to="profile-coach"
                  className="intro-video-delete-icon"
                  // onClick={() => {
                  //   this.handleDeleteResource(pdf.uid);
                  // }}
                >
                  <i className="fa fa-trash fa-3x" aria-hidden="true"></i>
                </div> */}
                {this.props.videofile !=null && this.props.processed === "1" ? (
                  <ConfirmationModal
                  fun_name={"Delete"}
                  //pack_id={this.props.packagedata.uid}
                  handleDeleteIntroVideo={this.handleDeleteIntroVideo}
                  infoText={this.state.introVideo_delete_confirmation}
                  handleDisableButtons={this.handleDisableButtons}
                  hideButton = {this.state.hideButton}
                  //helpText={this.state.coach_remove_acount}
                  />
                ) : ''}
                
                </div>
                {this.props.processed == '0' ? 
                <h5 className="resource-title mt-2">We are processing your video...</h5>
                : ''}
                  </div>):(<div className="">{sideBarSubComponent}</div>)}
              
                {this.state.renderSideBarComponent==="" ?(
                  <div className="row justify-content-center">
                    <div className="col-md-9 text-center tour-addTags">
                        <div className="coachTag" >
                          <div className="coachTagItem">
                          {/* {account_type === "coach" && (
                              <AddTag
                                token={this.token}
                                tags={this.props.tags}
                                handleUpdateTags={this.props.handleUpdateTags}
                                tagsHelpText={this.state.tags_help_text}
                              />
                            )} */}
                            
                            {/* <center> */}
                            
                            {/* {this.props.tags &&
                                this.props.tags.split(",").map((e, i) => (
                                  <span                                                        
                                    key={i}
                                  >
                                    {e}
                                  </span>
                                ))} */}
                            {/* </center> */}

                            {/* {account_type === "coach" && (
                              <AddTag
                                token={this.token}
                                tags={this.props.tags}
                                handleUpdateTags={this.props.handleUpdateTags}
                                tagsHelpText={this.state.tags_help_text}
                              />
                            )} */}
                          </div>
                        </div>
                    </div>
                  </div>
                  ):("")}
              

                {/* </div>
              </div>
            </section> */}


            {this.state.renderSideBarComponent===""  && 0==1 ?(
              <div>
                <section className="packagesWrap">
                        <div className="container">
                          <div className="heading text-center">            
                            <h3>Coaching Programs

                            {/* {account_type === "coach" && (
                              
                          <Tooltip title={this.state.packages_help_text}> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>
                            )} */}

                            </h3>

                            <div className="row justify-content-md-center">
                              {this.props.check_account_type === "coach" ? (
                                <div className="col-8 ">
                                  <p>
                                    {this.state.packageContent
                                      ? this.state.packageContent
                                      : "Create Sessions, Packages & Programs here"}
                                  </p>

                                  {account_type === "coach" && (
                                    <UpdatePackageContent
                                      packageContent={this.state.packageContent}
                                      packageshelptext={this.state.packages_help_text}
                                      handleChange={this.handleChange}
                                      handlePackageContentSubmit={
                                        this.handlePackageContentSubmit
                                      }
                                    />
                                  )}
                                </div>
                              ) : (
                                <div className="col-8">
                                  <p>&nbsp;</p>
                                </div>
                              )}
                            </div>
                            <span className="R_divider"></span>
                          </div>
                          {this.props.check_account_type === "coach" ? (
                            <div className="col-12">
                              <div className="row no-gutters">
                                {account_type === "coach" && (
                                  <AddPackage
                                    pacdata={this.state}
                                    handleChange={this.handleChange}
                                    handleSubmit={this.handleSubmitPackage}
                                    handlePackType={this.handlePackType}
                                    handleResetPackage={this.handleResetPackage}
                                    sessionlength={this.props.sessionLength}
                                    validateForm={this.validateForm}
                                    Intromessage={this.state.Intromessage}
                                    
                                  />
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="profilePakage">
                            {this.state.packagescollection &&
                              this.state.packagescollection.length > 0 &&
                              this.state.packagescollection.map((element) => (
                                <Package
                                  coachUid={this.props.uid}
                                  coachImage={this.props.coachImage}
                                  coachName={this.props.coachName}
                                  coachData={this.props.coachData}
                                  ConsumerD={this.props.ConsumerD}
                                  account_type={account_type}
                                  token={this.token}
                                  status={element.isactive}
                                  key={element.id}
                                  packagedata={element}
                                  findcurrency={this.props.findcurrency}
                                  convertToCurrency={this.props.convertToCurrency}
                                  baseCurrency={this.props.baseCurrency}
                                  handleEditPackage={this.handleEditPackage}
                                  handleDeletePackage={this.handleDeletePackage}
                                  handleActivatePackageStatus={
                                    this.handleActivatePackageStatus
                                  }
                                  handleInactivatePackageStatus={
                                    this.handleInactivatePackageStatus
                                  }
                                  handlePackageBook={this.handlePackageBook}
                                  sessionlength={this.props.sessionLength}
                                />
                              ))}
                          </div>

                          {this.state.packageCount > 5 ? (
                            <div className="text-center mt-5">
                              <Link
                                className="defaultBtn"
                                to={{
                                  pathname: "/packages_list",
                                  state: {
                                    token: this.token,
                                    coaching_category: this.props.coaching_category,
                                    uid: this.props.userdata.uid,
                                    account_type:this.props.account_type,
                                    coachName:this.props.coachName,
                                    coachUid:this.props.coachUid,
                                    Consumer_uid:this.props.Consumer_uid,
                                    coachData: this.props.coachData,
                                    Consumer_name:this.props.Consumer_name,
                                    Consumer_email:this.props.Consumer_email,
                                    findcurrency:this.props.findcurrency,
                                    convertToCurrency:this.props.convertToCurrency,
                                    baseCurrency:this.props.baseCurrency,
                                    coachData:this.props.coachData,
                                    coachImage:this.props.coachImage,
                                    coachName:this.props.coachName,
                                    sessionlength:this.props.sessionLength
                                  },
                                }}
                              >
                                View more Packages
                              </Link>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>


                        {account_type !== "coach" && (<div>
                                  {/* <BookPackage
                                  packageName={this.state.packageName}
                                  packageUid={this.state.packageUid}
                                  packPrice={this.state.packPrice}
                                  coachName={this.props.coachName}
                                  coachUid={this.props.uid}
                                  Consumer_uid={this.props.Consumer_uid}
                                  Consumer_name={this.props.Consumer_name}
                                  Consumer_email={this.props.Consumer_email}
                                  check_account_type={this.props.check_account_type}
                                  coachEmail={this.props.coachEmail}
                                  coachImage={this.props.coachImage}/> */}
                                  
                              </div>)}

                        
                      
                      {/* </div> */}

                                  {/* {this.props.coachData.coach_uid!=''?():(<></>)} */}
                                
                </section>
                      
              </div>
            ):(<></>)}
            {this.state.renderSideBarComponent==="" &&(
              <div className="col-md-12 text-center mt-5">
                <ClientFeedback   coachUid={this.props.coachData.coach_uid} />
              </div>
            )}
        
      </React.Fragment>
    );
  }
}

export default Home;
