import React from "react";
import { Link } from "react-router-dom";
import {Tab, Nav, Accordion, Card, Button} from "react-bootstrap";

class FrequentQuestion extends React.Component {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    return (
      <div>
        <section className="faqWrap">
          <div className="container">
            <div className="heading text-center">
              <h3>Frequently Asked Questions</h3>
              <span className="R_divider div_dark"></span>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-9">
                <Accordion>
                  {/* <h4>Free Trial</h4>
                  <div className="card">
                    <div className="card-header p-0" id="headingOne">
                      <button
                        type="button"
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="false"
                      >
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                        How does the free trial work?
                      </button>
                    </div>
                    <div
                      id="collapseOne"
                      className="collapse"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida. Risus commodo viverra maecenas accumsan lacus
                          vel facilisis. Quis ipsum suspendisse ultrices
                          gravida.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header p-0" id="headingOne">
                      <button
                        type="button"
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseOne1"
                      >
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                        Who is eligible to participate in gree trail?
                      </button>
                    </div>
                    <div
                      id="collapseOne1"
                      className="collapse"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida. Risus commodo viverra maecenas accumsan lacus
                          vel facilisis. Quis ipsum suspendisse ultrices
                          gravida.
                        </p>
                      </div>
                    </div>
                  </div> */}

                  {/* <h4>General</h4> */}
                  {this.props.faqdata === [] ? "" : 
                    this.props.faqdata.map((each, index) => {
                      return (
                        <div key={index}>
                          <Card >
                          <Card.Header className="p-0 faq_head" >
                            <Accordion.Toggle as={Button} variant="link" eventKey={`collapseOne${each.id+1}`}>
                            <h2 className="mb-0">                              
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                                {each.question}
                            </h2>
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={`collapseOne${each.id+1}`}>
                            <Card.Body>
                            
                              <p>
                                {each.answer}
                              </p>
                            
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        </div>
                      )
                    })
                  }
                </Accordion>
              </div>
            </div>
            <div className="text-center mt-5">
              <Link to="/faq" className="defaultBtn">
                More FAQ'S
              </Link>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default FrequentQuestion;
