import React from 'react'
import { toast } from "react-toastify";
import _ from "lodash";
import { teamSvg } from '../../../assets/images'
import { avatar } from '../../../assets/img'
import MyCalender from '../../../components/calender/calender'
import MyCalendernew from '../../../components/calender/calenderNew'
import axios from '../../../config/axios'
import AcceptModal from "../view/AcceptModal";
import message from '../../../utils/mesaages';
import Calendar from "tui-calendar";
import moment from 'moment';
import { SSO_LOGOUT } from '../../../utils/Sso';

let dcalenderCouch;
class MySchedule extends React.Component {
  constructor(props) {
    super(props)

    this.uid = JSON.parse(localStorage.getItem('userdata')).uid
    this.token = localStorage.getItem('authToken')

    this.state = {
      bookingDetails: [],
      acceptedBooking: [],
      introBookings: [],
      sessionBooking: [],
      unavailableData: [],
      schedules: [],
      schedulesSession: [],
      AcceptModal: false,
      BookingModal: '',
      currentDate: "",
      couchtype: ""
    }
    // this.acceptedBooking = []
  }
  componentDidMount() {
    this.displayCalendar();
    this.getBookingRequests();
    this.getUnavailableDetails();
    this.getScheduledWebinarsOrSession(moment().format("YYYY-MM-DD"));
  }

  logOutUser = async () => {
    const hideMessage = message.error("Your account has been deactivated")

    const token = localStorage.getItem("authToken");
    await SSO_LOGOUT()
    localStorage.clear();
    if (token !== undefined) {
      let param = {
        url: "/users/logout",
        method: "POST",
        data: { token: token },
        headers: { "X-Auth": token },
      };
      await axios(param)
    }
    hideMessage()
    window.location = '/login#logout'
  }

  getScheduledWebinarsOrSession = (date) => {

    this.setState({ loader: true });

    axios.post(`/webinar/couchtype`, { couch_uid: this.uid }, { headers: { "X-Auth": this.token, }, }).then((res) => {
      this.setState({ loader: false });
      if (res.data.success) {
        if (parseInt(res.data.data.userType.coach_type) === 3) {
          axios.get(`/webinar/get-scheduledwebiner-couch?date=${date}`, { headers: { "X-Auth": this.props.token, }, }).then((res) => {
            if (res.data.success) {
              let calendarId = "1";
              let { webinars, session } = res.data.data;
              let schedules = webinars?.map((value) => {
                let date = new Date();
                if (new Date(value.date_time) > date) {
                  calendarId = "2";
                } else {
                  calendarId = "1";
                }
                return {
                  id: value.id,
                  calendarId: calendarId,
                  category: "time",
                  title: value.webinar_topic || value.session_name,
                  start: new Date(value.date_time),
                  isReadOnly: true,
                };
              });

              let schedulesSession = session?.map((value) => {
                let date = new Date();
                if (new Date(value.date_time) > date) {
                  calendarId = "3";
                } else {
                  calendarId = "4";
                }
                return {
                  id: value.id,
                  calendarId: calendarId,
                  category: "time",
                  title: value.webinar_topic || value.session_name,
                  start: new Date(value.date_time),
                  isReadOnly: true,
                };
              });

              this.setState({ schedules, schedulesSession });
            }
          })
            .catch((err) => {
              this.setState({ loader: false });
              message.error("Something went wrong");
            });
        } else {
          axios.get(`/webinar/get-scheduledwebiner-couch?date=${date}`, { headers: { "X-Auth": this.props.token, }, }).then((res) => {
            this.setState({ loader: false });
            console.log('Response :', res.data)
            if (res.data.success) {
              let calendarId = "1";
              let { webinars } = res.data.data;
              let schedules = webinars?.map((value) => {
                let date = new Date();
                if (new Date(value.date_time) > date) {
                  calendarId = "2";
                } else {
                  calendarId = "1";
                }
                return {
                  id: value.id,
                  calendarId: calendarId,
                  category: "time",
                  title: value.webinar_topic || value.session_name,
                  start: new Date(value.date_time),
                  isReadOnly: true,
                };
              });

              this.setState({ schedules });
            }
          })
            .catch((err) => {
              this.setState({ loader: false });
              message.error("Something went wrong");
            });
        }

        this.setState({ couchtype: res.data.data.userType.coach_type })
      } else if (parseInt(res.data.status) === 600) {
        this.logOutUser()
      }

    }).catch((err) => {
      this.setState({ loader: false });
      console.log(err);
    })

  }

  displayCalendar = () => {
    let div = document.getElementById("calendarCouch");

    dcalenderCouch = new Calendar(div, {
      defaultView: "month",
      taskView: true, // Can be also ['milestone', 'task']
      scheduleView: true, // Can be also ['allday', 'time']
      useCreationPopup: false,
      useDetailPopup: true,
      isReadOnly: true,
      calendars: [
        {
          id: "1",
          name: "Archived",
          bgColor: "#9e5fff",
          dragBgColor: "#9e5fff",
          borderColor: "#9e5fff",
        },
        {
          id: "2",
          name: "Upcoming",
          bgColor: "#00e1ba",
          borderColor: "#00e1ba",
          dragBgColor: "#00e1ba",
        },
      ],
    });

    this.setState({
      currentDate: moment(dcalenderCouch.getDate()).format("MMMM-DD"),
    });
  };

  moveToNextOrPrevRange = async (val) => {
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    if (val === -1) {
      dcalenderCouch.prev();
      let date = dcalenderCouch.getDate().toDate();
      const qDate = moment(date).format("YYYY-MM-DD");

      await this.setState({
        currentDate: `${months[date.getMonth()]}-${date.getFullYear()}`,
        date: qDate,
      });
      this.getScheduledWebinarsOrSession(qDate);
    } else if (val === 1) {
      dcalenderCouch.next();
      let date = dcalenderCouch.getDate().toDate();
      const qDate = moment(date).format("YYYY-MM-DD");
      await this.setState({
        currentDate: `${months[date.getMonth()]}-${date.getFullYear()}`,
        date: qDate,
      });
      this.getScheduledWebinarsOrSession(qDate);
    }
  };

  getBookingRequests = () => {

    var self = this;

    axios.get("/users/session/booking-request", {
      headers: { "x-auth": this.props.token }
    })
      .then(async (res) => {
        console.log('booking-request', res.data);

        if (res.data.success) {
          console.log("booking list")
          const bookingRequestInitiations = res.data.value;
          const bookingRequestSessions = res.data.value2;

          self.setState({
            //bookingDetails : _.filter(bookingRequestInitiations, { 'status': 'Awaiting Confirmation' }), 
            //acceptedBooking : _.filter(bookingRequestInitiations, { 'status': 'Confirmed' }),
            introBookings: bookingRequestInitiations,
            sessionBooking: bookingRequestSessions
          }, () => {
            console.log("this booking status", self.state)
          })
          //self.acceptedBooking = _.filter(res.data.value, { 'status': 'Confirmed' })

        }
      })
      .catch(err => {
        console.log(err)
      })

  }

  getUnavailableDetails = () => {

    var self = this;

    axios.get("/users/session/get-unavailable", {
      headers: { "x-auth": this.props.token }
    })
      .then(async (res) => {
        console.log('get-unavailable', res.data);
        if (res.data.success) {

          const unavailableDetails = res.data.value

          self.setState({
            unavailableData: unavailableDetails
          }, () => {
            console.log("this booking status", self.state)
          })


        }
      })
      .catch(err => {
        console.log(err)
      })

  }

  handleAddToSchedule = (data) => {
    console.log('hndaleSchedule', data)
    this.refs.calendarcomponent.addToSchedule(data);

  }

  handleAcceptBooking = (data) => {
    console.log("Booking acepted : ", data)
    let formdata = { "booking_uid": data.uid, "package_details": data.package_details }

    axios.post('/users/session/accept-booking', formdata, {
      headers: { 'x-auth': this.props.token }
    })
      .then(res => {
        if (res.data.success) {

          message.success("Successfully accepted booking request");


          let da = _.filter(this.state.bookingDetails, function (o) { return o.uid != data.uid; });


          this.setState({ bookingDetails: da }, () => {
            console.log('what have', da)
            console.log('what have', this.state.bookingDetails)
          })

        } else {
          message.error(res.data.msg);
        }
      })
      .catch(err => {
        console.log("Error")
        console.log(err)
      })
  }

  handaleModal = (BookingModal) => {
    if (this.state.AcceptModal) {
      this.setState({ AcceptModal: false })
    }
    else {
      this.setState({ AcceptModal: true }, () => { })
    }
    this.setState({ BookingModal })
  }

  componentDidUpdate = () => {
    dcalenderCouch.clear();
    dcalenderCouch.createSchedules(this.state.schedules);
  };


  render() {
    return (
      <div>
        <div className="col-md-14">
          {/* <div className="searchHead">
          <div className="searchHeading">
            <h3>
              <img width="22px" src={teamSvg} alt="LG-Img" />Booking Requests
            </h3>
          </div>
          
        </div> */}

          {/* <div className="heading text-center">
          <h3>MY COACHING SCHEDULES</h3>
          <span className="R_divider"></span>
        </div> */}


          {/* <div className="userBookingList">
      {this.state.bookingDetails.length > 0 && this.state.bookingDetails.map(booking =>{
        let coachData=JSON.parse(booking.package_details)
     
        return(
          <div className="bookingCocachDetails">

          <ul>
            <li>
              {coachData.coachImage!==undefined ?(<div>
                <img
                alt="LG_Img"
                width="60px"
                className="userBookingCocachImg"
                src={avatar}
                
              />
              </div>):(<div>
                <img
                alt="LG_Img"
                width="60px"
                className="userBookingCocachImg"
                src={avatar}
                
              />
              </div>)}
             
            </li>
            <li>
              <b>Booking</b> <span>{booking.created.split("T")[0]}</span>
            </li>
            <li>
              <b>Scheduled</b> <span>{coachData!==null ?(coachData.sessionDate):("")}</span>
            </li>
            <li>
              <b>Slot Time</b> <span>{coachData!==null ?(coachData.slotTime):("")}</span>
            </li>
            <li>
              <b>User Name</b> <span>{coachData!==null ?(coachData.consumer_name):("")}</span>
            </li>
            <li>
              <b>Package</b> <span className="package">{coachData!==null ?(coachData.session_name):("")}</span>
            </li>
            <li>
              <b>Status</b><span className="package">{booking.status}</span>
            </li>
           
            <li className="defaultBtn" onClick={()=> {this.handaleModal(booking)}}>Accept</li>
          </ul>
          
          <div className="collapse" id={booking.uid}>
            <div className="BCDView">
              <p>
                <b>Package Details : </b>{" "}
                <span>{this.state.Description}</span>
              </p>
              <p>
                <b>Total Sessions : </b> <span>{this.state.TotalSession}</span>
              </p>
              <p>
                <b>Sessions left : </b> <span>{this.state.TotalSession}</span>
              </p>
              
            </div>
          </div>
        </div>
        )
      })}
      {(this.state.bookingDetails.length <= 0)?(<>
        <div className="bookingCocachDetails">
          <ul className=" text-center justify-content-center">
            <li>No Pending Requests</li>
          </ul>
          </div>

      </>):(<></>)}
      {(this.state.BookingModal != '')?(<>
        <AcceptModal booking={this.state.BookingModal} show={this.state.AcceptModal} confirm={this.handleAddToSchedule} handale={()=>this.handaleModal('')}/>
        </>):(<></>)}
        </div> */}
        </div>
        {/* <hr/> */}
        {/* <MyCalender 
        ref="calendarcomponent"
        handleAcceptBooking = {this.handleAcceptBooking}
        introBookings = {this.state.introBookings}
        sessionBooking = {this.state.sessionBooking}
        unavailableData={this.state.unavailableData}
        type="coachcalender"
      /> */}
        <div className="booking__calendar">
          <div className="ml-3 pt-3">
            <span id="menu-navi">
              <button type="button" className="btn btn-default btn-sm move-day" data-action="move-prev" onClick={() => { this.moveToNextOrPrevRange(-1); }}>
                <i className="calendar-icon ic-arrow-line-left" data-action="move-prev" ></i>
              </button>
              <button type="button" className="btn btn-default btn-sm move-day" data-action="move-next" onClick={() => { this.moveToNextOrPrevRange(1); }}>
                <i className="calendar-icon ic-arrow-line-right" data-action="move-next" ></i>
              </button>
            </span>
            <span id="renderRange" className="render-range"> {this.state.currentDate} </span>
          </div>

          <div id="calendarCouch" className="mb-3"></div>
        </div>
      </div>
    )
  }
}
export default MySchedule



