



import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";


 
const _ = require("lodash");  

class AcceptModal extends Component {
    constructor(props)
    {
        super(props)
        this.state ={
            selectedSlotTime:''
        }

    }

    handleChange = (e) => {
        this.setState({
          [e.target.name]: e.target.value,
        },()=>{
            console.log(this.state.selectedSlotTime)
        });
      };

      handaleSubmit=()=>{
          if(this.state.selectedSlotTime !='')
          {
            let booking = this.props.booking
            let package_details = booking.package_details
            let package_details_obj = JSON.parse(package_details)
            package_details_obj.slotTime = this.state.selectedSlotTime
            let package_details_json = JSON.stringify(package_details_obj)
            booking.package_details = package_details_json
            console.log(booking)
           this.props.confirm(booking)
          this.props.handale()

          }
          else
          {
            toast("Select a Time Slot", { type: "error" });
          }
         
      }

    intervals(startString, endString) {
        var start = moment(startString, 'YYYY-MM-DD hh:mm a');
        var end = moment(endString, 'YYYY-MM-DD hh:mm a');
      
        
        start.minutes(Math.ceil(start.minutes() / 15) * 15);
      
        var result = [];
      
        var current = moment(start);
        // result.push(current.format('YYYY-MM-DD HH:mm'));
        // result.push(end.format('YYYY-MM-DD HH:mm'));
        while (current <= end) {
            result.push(current.format('YYYY-MM-DD HH:mm'));
            current.add(15, 'minutes');
        }
        //tanmay change
        console.log(result)
      
        return result;
      }
    render() {
        let pack = this.props.booking.package_details
        let package_obj = JSON.parse(pack);
        let slot = package_obj.slotTime.split("-")
        let FromTime =`${package_obj.sessionDate} ${slot[0]}`
        let toTime =`${package_obj.sessionDate} ${slot[1]}`

        
        let FTIntervel=this.intervals(FromTime,toTime)
       
       
        
        return (
            <div>
                 <Modal
        show={this.props.show}
        onHide={this.props.handale}
        keyboard={false}
        className="modal  confirmation"
      >
        <div className="modal-content accept_modal">

        <Modal.Header closeButton>
          <Modal.Title>Confirmation Time Slot</Modal.Title>
        </Modal.Header>


        <div className="row justify-content-center">

                    

            <div className="row accept_modal">
                <div className="col-md-12">
                    <label>UserName :</label>
                   
                    <strong>{package_obj.consumer_name}</strong>
                </div>
                <div className="col-md-6">
                    <label>Package :</label>
                   
                    <strong>{package_obj.session_name}</strong>
                </div>
                <div className="col-md-6">
                    <label>Date :</label>
                    
                    <strong>{package_obj.sessionDate}</strong>
                </div>
           
                <div className="col-md-12 d-flex flex-wrap">
                <label>Time Slot :</label>
                  {FTIntervel.map((FT,index)=>{
                    let uniqueId = _.uniqueId();
                     const endTime=FTIntervel[index + 1]!==undefined ? FTIntervel[index + 1].split(" "):("")

                     return(
                       <div >
                         {FTIntervel[index + 1]!==undefined ? (
                           <div className="custom-control custom-checkbox checkbox-inline mx-1">
                              <input  type="radio" className="custom-control-input" id={uniqueId} onChange={this.handleChange} 
                     value={`${FT.split(" ")[1]}-${endTime!=="" ?(endTime[1]):("")}`} name="selectedSlotTime"/>
                     <label className="custom-control-label" for={uniqueId}>{FT.split(" ")[1]} - {endTime!=="" ?(endTime[1]):("")}</label>
                            </div>
                         ):("")}
                    
                   </div>
                     )
                     
                   })}
                   </div>
                   
                   
            </div>
         
              <button
                className="btn btn-success"
                onClick={this.handaleSubmit}
              
              >
                Confirm
              </button>
           
        </div>
        </div>
      </Modal>
    
                
            </div>
        );
    }
}

export default AcceptModal;

