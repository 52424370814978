import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging"

const firebaseConfig = {
    apiKey: "AIzaSyD3tt164Ir8sKo2PxWYPM0UjNE4CV7UZqo",
    authDomain: "push-notification-c1273.firebaseapp.com",
    projectId: "push-notification-c1273",
    storageBucket: "push-notification-c1273.appspot.com",
    messagingSenderId: "1092974562564",
    appId: "1:1092974562564:web:af9a47e78350de350d1451",
    measurementId: "G-DN7W55KC1J"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app)
// const analytics = getAnalytics(app);