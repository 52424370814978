import React, { Component } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";
import "./profile.css";
import makeAnimated from "react-select/animated";
import { user, LGspinner } from "../../../assets/images";
import axios from "../../../config/axios";
import { message } from "antd";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
//import bcrypt from 'bcryptjs'
import OpenEye from "../../../assets/openEye.jpeg";
import CloseEye from "../../../assets/closeEye.png";

export default class CorporateProfile extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("corporate_user_token") ? localStorage.getItem("corporate_user_token") : localStorage.getItem("corporate_token");
    this.state = {
      loader: false,
      profile: {
        password: "",
        npassword: "",
        cpassword: ""
      },
      errors: [],
      show_password : false,
      show_password1 : false,
      show_password2 : false
    };
  }

  handleInput = (e) => {
    e.persist();
    this.setState((prevState) => ({
      profile: {
        ...prevState.profile,
        [e.target.name]: e.target.value,
      },
    }));
    this.validateForm(e);
  };

  validateForm(e){
    
    let errors = [];
    
    if((typeof e.target.value === "undefined" || e.target.value == "")){
        errors[e.target.name] = "Please enter password";
        this.setState({ errors: errors });
        return false;
    }else{
        errors[e.target.name] = "";
        this.setState({ errors: errors });
    }
  }
  
  updateDetails = () => {
     let { password, cpassword, npassword } = this.state.profile;

     let errors = [];
    
    if(typeof password === "undefined" || password == ""){
        errors["password"] = "Please enter password";
        this.setState({ errors: errors });
        return false;
    }

    if(typeof npassword === "undefined" || npassword == ""){
        errors["npassword"] = "Please enter new password";
        this.setState({ errors: errors });
        return false;
    }else if(npassword.length < 6){
        errors["npassword"] = "Password must be atleast 6 digit";
        this.setState({ errors: errors });
        return false;
    }

    if(npassword !== cpassword){
        errors["cpassword"] = "Confirm password and new password does not match";
        this.setState({ errors: errors });
        return false;
    }

    if(this.state.errors.length > 0) return false;

//    const salt = bcrypt.genSaltSync(10)
    //let hashPass = bcrypt.hashSync(npassword, salt);
    const formData = new FormData();
    formData.append("password", password);
    formData.append("npassword", npassword);

    let data = {
        "password" : password,
        "npassword" :npassword
    }
    
    axios.post("/api/corporate_update_profile_pwd", data, { 
        headers: { "X-Auth": this.token,}, }).then((res) => {
      if (res.data.success) {
        message.success("Profile updated successfully");
        this.setState({ profile: {
            password : "",
            npassword : "",
            cpassword : ""
        } });
        // setTimeout(function(){
        //    // window.location.reload(false);
        // },100);
      } else if (res.data.errors && res.data.errors.length > 0) {
        let errors = [];
        console.log(res.data.errors);
        message.error("Failed to update profile");
        res.data.errors.map((item) => {
          errors[item.param] = item.msg;
        });
        this.setState({ errors: errors });
        console.log("errors", errors);
      } else {
        message.error(res.data.msg);
      }
    }).catch((err) => {
      message.error(err.message);
      console.log("err", err);
    });
  };

  render() {
    const { profile, errors , show_password,show_password1,show_password2} = this.state;

    return (
        <div className='content_main'>
                        <Header />
      <LoadingOverlay active={this.state.loader} className="webiner_loader" spinner={<CustomImageLoader image={LGspinner} animationType={"none"} />}>
        <div className="content_wrap">
          <div className="profile_wrap">
            <Form>
              <Row className="justify-content-center">
                <Col className="coachProfileFormEdit cpe_sm" md={6}>
                  <div className="coachImg mb-4">
                    <img id="item-img-output" src={user} className="img-fluid" alt="profile_image" />
                  </div>

                  <Form.Group>
                    <Form.Label>Current Password</Form.Label>
                    <Form.Control onChange={(e) => this.handleInput(e)} value={profile.password} type={show_password ? "text" : "password"} placeholder="Enter Current Password" name="password" />
                    <img style={{
                      "cursor": "pointer",
    "position": "relative",
    "top": "-36px",
    "width": "26px",
    "left": "19rem",
                    }} className="passwordImg" onClick={(e)=>{
                      this.setState({"show_password":!show_password})
                    }} src={show_password ? CloseEye : OpenEye}/>
                    <p style={{ color: "red" }}>
                          {errors["password"]}
                    </p>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>New Password</Form.Label>
                    <Form.Control onChange={(e) => this.handleInput(e)} value={profile.npassword} type={show_password1 ? "text" : "password"} name="npassword" placeholder="Enter New Password"  />
                    <img style={{
                      "cursor": "pointer",
    "position": "relative",
    "top": "-36px",
    "width": "26px",
    "left": "19rem",
                    }} className="passwordImg" onClick={(e)=>{
                      this.setState({"show_password1":!show_password1})
                    }} src={show_password1 ? CloseEye : OpenEye}/> 
                      
                    <p style={{ color: "red" }}>
                          {errors["npassword"]}
                        </p>
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Confirm New password <span className="text-danger">*</span></Form.Label>
                        <Form.Control type={show_password2 ? "text" : "password"} onChange={(e) => this.handleInput(e)} value={profile.cpassword} name="cpassword" placeholder="Enter Confirm Password" />
                        <img style={{
                          "cursor": "pointer",
                          "position": "relative",
                          "top": "-36px",
                          "width": "26px",
                          "left": "19rem",
                        }} className="passwordImg" onClick={(e)=>{
                      this.setState({"show_password2":!show_password2})
                    }} src={show_password2 ? CloseEye : OpenEye}/>
                        <p style={{ color: "red" }}>
                          {errors["cpassword"]}
                        </p>
                      </Form.Group>
                    </Col> 
                  </Row>
                  
                  <Row>
                    <Col className="d-flex justify-content-center">
                      <Button className="defaultBtn" variant="default" onClick={this.updateDetails}>
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </LoadingOverlay>
      <Sidebar />
      </div>
    );
  }
}
