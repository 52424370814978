//import moment from "moment"
const moment = require('moment')

const dateConfig = {
  dateFormat: "DD-MM-YYYY",
  databaseDateFormat: "YYYY-MM-DD",
  dateTimeFormat: "DD-MM-YYYY HH:mm",
  dateTimeFormatWithSec: "DD-MM-YYYY HH:mm:ss" 
  
}

const testFun = () => {
  console.log("global test fun")
}

const convertDateFormat = (receivedDate) => {
  // receivedDate CAN BE STRING OR OBJECT
  let convertedDate = moment(receivedDate, dateConfig.databaseDateFormat).format(dateConfig.dateFormat)
  return convertedDate
}

module.exports = {
  dateConfig, 
  testFun,
  convertDateFormat
}