import React, { Component } from "react";
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";
import defaultProfile from "../../../assets/images/defaultProfileImg.png";
import "./webinarHistory.css";
import { LGspinner } from "../../../assets/images";
import axios from "../../../config/axios";
import { message } from "antd";

export default class WebinarHistory extends Component {
  constructor(props) {
    super(props);

    this.token = localStorage.getItem("authToken") || localStorage.getItem("corporate_user_token");

    this.state = {
      loader: false,
      upcoming: [],
      ongoing: [],
      completed: [],
    };
  }

  getSessions = () => {
    this.setState({ loader: true });
    axios
      .get("/webinar/get-webiner-history", {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        this.setState({ loader: false });
        if (res.data.success) {
          let { upcoming, ongoing, completed } = res.data.webinar;
          this.setState({
            upcoming: upcoming,
            ongoing: ongoing,
            completed: completed,
          });
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        message.error("Server error");
      });
  };

  componentDidMount() {
    this.getSessions();
  }

  render() {
    const { upcoming, ongoing, completed } = this.state;
    return (
      <LoadingOverlay
        active={this.state.loader}
        spinner={<CustomImageLoader image={LGspinner} animationType={"none"} />}
      >
        <div className="content_wrap">
          <h4 className="page-title">Sessions</h4>
          <div className="session_wrap ongoing">
            <div className="session_type_title mb-3">Ongoing</div>
            {ongoing?.map((item) => {
              return (
                <Link to="/webinars/webinars-details">
                  <div className="session_item d-flex flex-wrap align-items-center">
                    <div className="session_user_image">
                      <img
                        width={55}
                        height={55}
                        src={defaultProfile}
                        className="img-fluid rounded-circle"
                      />
                    </div>
                    <div className="session_user">
                      <div className="session_label">Client name</div>
                      <div className="session_text">{item.name}</div>
                    </div>
                    <div className="session_name">
                      <div className="session_label">Session name</div>
                      <div className="session_text">{item.webinar_topic}</div>
                    </div>
                    <div className="session_date">
                      <div className="session_label">Date</div>
                      <div className="session_text">{item.date}</div>
                    </div>
                    <div className="session_time">
                      <div className="session_label">Time</div>
                      <div className="session_text">{item.webinar_time}</div>
                    </div>
                    <div className="session_type">
                      <div className="session_label">Session type</div>
                      <div className="session_text">One to Many</div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
          <div className="session_wrap upcoming">
            <div className="session_type_title mb-3">Upcoming</div>
            {upcoming?.map((item) => {
              return (
                <Link to="/webinars/webinars-details">
                  <div className="session_item d-flex flex-wrap align-items-center">
                    <div className="session_user_image">
                      <img
                        width={55}
                        height={55}
                        src={defaultProfile}
                        className="img-fluid rounded-circle"
                      />
                    </div>
                    <div className="session_user">
                      <div className="session_label">Client name</div>
                      <div className="session_text">{item.name}</div>
                    </div>
                    <div className="session_name">
                      <div className="session_label">Session name</div>
                      <div className="session_text">{item.webinar_topic}</div>
                    </div>
                    <div className="session_date">
                      <div className="session_label">Date</div>
                      <div className="session_text">{item.date}</div>
                    </div>
                    <div className="session_time">
                      <div className="session_label">Time</div>
                      <div className="session_text">{item.webinar_time}</div>
                    </div>
                    <div className="session_type">
                      <div className="session_label">Session type</div>
                      <div className="session_text">One to Many</div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
          <div className="session_wrap completed">
            <div className="session_type_title mb-3">Completed</div>
            {completed?.map((item) => {
              return (
                <Link to="/webinars/webinars-details">
                  <div className="session_item d-flex flex-wrap align-items-center">
                    <div className="session_user_image">
                      <img
                        width={55}
                        height={55}
                        src={defaultProfile}
                        className="img-fluid rounded-circle"
                      />
                    </div>
                    <div className="session_user">
                      <div className="session_label">Client name</div>
                      <div className="session_text">{item.name}</div>
                    </div>
                    <div className="session_name">
                      <div className="session_label">Session name</div>
                      <div className="session_text">{item.webinar_topic}</div>
                    </div>
                    <div className="session_date">
                      <div className="session_label">Date</div>
                      <div className="session_text">{item.date}</div>
                    </div>
                    <div className="session_time">
                      <div className="session_label">Time</div>
                      <div className="session_text">{item.webinar_time}</div>
                    </div>
                    <div className="session_type">
                      <div className="session_label">Session type</div>
                      <div className="session_text">One to Many</div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}
