import React from 'react'
import { toast } from "react-toastify";
import { FooterLink } from '../components/footers'
import { Header, HeaderLogout, HeaderTop } from '../components/headers'
import axios from '../config/axios';
import { CoachingArea, Language, Membership, Price, StarRating, TimeZone ,Skills, MessageBox} from '../components/matchmycoach/index';
import {CoachProfileList} from '../components/commonprofile/index'
import { Link } from 'react-router-dom';
// import { Subscribe } from "../views/landing/Index";
import message from '../utils/mesaages';
import isEmail from 'validator/es/lib/isEmail';
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from 'react-custom-image-loader.';
import {LGspinner} from "../assets/images";

class MatchMyCoach extends React.Component{
    constructor(props){
        super(props)
        this.state={
            renderField:"CoachingArea",
            CoachingStyle:[],
            skills:[],
            timezone:'',
            language:'',
            Hourlyrate:'',
            MemberOfPropessionalBody:"0",
            messagebox: "",
            Email:"",
            listCoaches:[],
            rating: "",
            header_matchMycoach:"",
            matchMycoach_description:"",
          mailValidateMessage: "",
          loader:false,
        }
    }

    componentDidMount()
    {
      this.getForCoachContent()

    }

  getForCoachContent = () => {
    this.setState({ loader: true });
      let self = this
      axios.get('/cmsforcoach/forcoachContent')
        .then(async (response) => {
          this.setState({ loader: false });
        if(response.status === 200){
         
          let headContent= response.data.value.matchMycoach[0].content_json
         
          console.log('cms perse',headContent.heading)
          self.setState({
           
            header_matchMycoach:headContent.heading,
            matchMycoach_description:headContent.description,
            
          })
        }
      })
        .catch((e) => {
          this.setState({ loader: false });
        console.log(e)
      })
    }

    handleField = (Field) => {
        this.setState({ renderField: Field })
      }
    ratingChanged = (newRating) => {
      console.log(newRating);
      this.setState({rating: newRating})
    };

    handleChange=(e) =>{
        e.persist()
        this.setState({
            [e.target.name]:e.target.value
        })
        
    }

    handleEmailChange=(e) =>{
      console.log("e.target.value", e.target.value)
      let emailInput = e.target.value
      if(isEmail(emailInput)) {
        this.setState({
          Email:emailInput,
          mailValidateMessage: ""
        })
      }
      else {
        this.setState({
          Email:emailInput,
          mailValidateMessage: "Please enter proper email"
        })
      }
  }

    createMarkup = (data) => {
      return {__html: data};
    }

    handleCategories = (e) => {
      const index =
        this.state.CoachingStyle.length > 0 ? this.state.CoachingStyle.indexOf(e.target.id) : -1;
      if (e.currentTarget.checked) {
        if (index === -1) {
          this.setState({ CoachingStyle: [...this.state.CoachingStyle, e.target.id] });
        }
      }
      if (!e.currentTarget.checked) {
        this.setState({
          CoachingStyle: [...this.state.CoachingStyle.filter((element) => element !== e.target.id)],
        });
      }
    };

    handleSubmit=(e)=>{

    
        e.preventDefault()
        const formData={
            language:this.state.language,
            tags:this.state.skills,
            timezone:this.state.timezone,
            Category:this.state.CoachingStyle,
            // CoachingExpHrs:this.state.CoachingExpHrs, 
            Hourlyrate:this.state.Hourlyrate,
            hourly_rate_currency:localStorage.getItem('currency')!=undefined?localStorage.getItem('currency'):'USD',
            rating:this.state.rating,
            Email:this.state.Email,
            MemberOfPropessionalBody:this.state.MemberOfPropessionalBody,
            
        }
        console.log('formdata--match--',formData)

          if(formData.Email !== "" && isEmail(formData.Email)) {
            const hideMessage = message.loading('Please Wait while finding your coaches.', 0)
            axios.post("/consumer/filterCoaches",formData,{
              headers:{"X-Auth":this.props.token}
            })
            .then(res =>{
              hideMessage()
            console.log("Res-",res)
            if(res.data.success){
              //  this.setState({listCoaches:res.data.value})
              console.log("FETCHED VALUE",res.data.value)
              if(res.data.value.length > 0) {
                message.success("Your matched coaches will be emailed to you.");
              } else {
                //toast("No data found for the search", { type: "error" });
                message.error("No match found.");
              }
              this.setState({
                renderField: "CoachingArea",
                CoachingStyle: [],
                skills:[],
                timezone:'',
                language:'',
                Hourlyrate:'',
                MemberOfPropessionalBody:"0",
                messagebox: "",
                Email:"",
                listCoaches:[],
                rating: "",
              })
    
            } else {
              message.error("Error while matching coach data");
            }
            })
            .catch(err =>{
              hideMessage()
              console.log(err)
            })
          } else {
            message.error("Please give a valid email address");
          }
         }

    render(){
        return(
          <div>
              <LoadingOverlay active={this.state.loader} 
        spinner={<CustomImageLoader image={LGspinner} animationType={'none'}/>}
        styles={{
          wrapper: {
            overflow: this.state.loader ? 'hidden' : ''
          }
        }}
        >
                 {localStorage.getItem('authToken')==null ?(<HeaderTop/>):(<HeaderLogout/>)}
                <Header/>

 <section className="questionsWrap">
   <div className="container">


    <div className="row justify-content-center">
      <div className="col-lg-9">
        <div className="heading text-center">
         <h3>{this.state.header_matchMycoach}</h3>
         <p dangerouslySetInnerHTML={this.createMarkup(this.state.matchMycoach_description)}/>
         <span className="R_divider"></span>
       </div>
      </div>
    </div>


  
    <form className="questionsForm">
    
      <ul id="questionsProgressbar" style={{ float: "right", width:"90%"}}>
        <li className={this.state.renderField==="CoachingArea" || this.state.renderField==="Skills" || this.state.renderField==="TimeZone"
         || this.state.renderField==="Language" || this.state.renderField==="Price" || 
         this.state.renderField==="Star" ||  this.state.renderField==="Membership"  || this.state.renderField==="MatchMyCoach" ? "active" :""}></li>
       
       <li className={this.state.renderField==="Skills" || this.state.renderField==="TimeZone" || this.state.renderField==="Language"
         || this.state.renderField==="Price" ||this.state.renderField==="Star" ||  this.state.renderField==="Membership"
          || this.state.renderField==="MatchMyCoach"? "active" :""}></li>

        <li className={this.state.renderField==="TimeZone" || this.state.renderField==="Language"
         || this.state.renderField==="Price" ||this.state.renderField==="Star" ||  this.state.renderField==="Membership"
         || this.state.renderField==="MatchMyCoach"? "active" :""}></li>

        <li className={this.state.renderField==="Language"
         || this.state.renderField==="Price" || this.state.renderField==="Star" || this.state.renderField==="Membership"
         || this.state.renderField==="MatchMyCoach"? "active" :""}></li>

        <li className={this.state.renderField==="Price" ||this.state.renderField==="Star" || 
         this.state.renderField==="Membership" 
        || this.state.renderField==="MatchMyCoach"? "active" :""}></li>

        <li className={this.state.renderField==="Star" || this.state.renderField==="Membership"  
        || this.state.renderField==="MatchMyCoach"? "active" :""}></li>
        
       
        <li className={this.state.renderField==="Membership" || this.state.renderField==="MatchMyCoach"? "active" :""}></li>

        {/* <li className={this.state.renderField==="MessageBox" || this.state.renderField==="MatchMyCoach"? "active" :""}></li> */}

        <li className={this.state.renderField==="MatchMyCoach" ? "active" :""}></li>
      </ul>
     
      {this.state.renderField==="CoachingArea" ?(
        <CoachingArea
        handleCategories={this.handleCategories}
        coachingstyledata={this.state.CoachingStyle}
        handleField={this.handleField}/>):("")}
      
      {this.state.renderField==="Skills" ?(
        <Skills
        data={this.state}
        handleChange={this.handleChange}
        handleField={this.handleField}/>):("")}

      {this.state.renderField==="TimeZone" ? (
        <TimeZone
        data={this.state}
        handleChange={this.handleChange}
        handleField={this.handleField}/>):("")}


      {this.state.renderField==="Language" ? (
      <Language
      data={this.state}
      handleChange={this.handleChange}
      handleField={this.handleField}/>):("")}


      {this.state.renderField==="Price" ? (
      <Price
      data={this.state}
      handleChange={this.handleChange}
      handleField={this.handleField}/>):("")}

      {this.state.renderField==="Star" ? (
      <StarRating
      data={this.state}
      handleRatingChange={this.ratingChanged}
      handleField={this.handleField}/>):("")}


      {this.state.renderField==="Membership" ? (
      <Membership
        data={this.state}
      handleChange={this.handleChange}
      handleField={this.handleField}/>):("")}

      {/* {this.state.renderField==="MessageBox" ? (
      <MessageBox
        data={this.state}
      handleChange={this.handleChange}
      handleField={this.handleField}/>):("")} */}

      {this.state.renderField==="MatchMyCoach" ? (<fieldset>
        <div className="heading text-center">
          <h3 className="fs-subtitle">Enter your email</h3>
          <span className="R_divider"></span>
        </div>
        <input type="email" placeholder="Email Address" 
        value={this.state.Email}
        name="Email"
        onChange={this.handleEmailChange} 
        className="form-control w-75 text-center mx-auto mb-2"/>
        <p style={{color: "red"}}>{this.state.mailValidateMessage}</p>
        <Link
        to="#"
          onClick={() =>
          this.handleField("Membership")
          }
         className="defaultBtn">PREVIOUS
        </Link>{" "}

        <button className="defaultBtn" onClick={this.handleSubmit}>Match My Coach</button>

        
      </fieldset>):("")}
    </form>
    <CoachProfileList
     ListCoaches={this.state.listCoaches}/>
   </div>
  
</section>
                {/* <Subscribe /> */}
                <FooterLink/>
              </LoadingOverlay>

            </div>
        )
    }
}

export default MatchMyCoach