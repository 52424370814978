import React from 'react'
import WeekList from './WeekAvalabilityListNew';
import { Tooltip } from 'antd';
import axios from '../../../config/axios'
import { getHelpText } from '../../../utils/mesaages';
import message from '../../../utils/mesaages';
import { SSO_LOGOUT } from '../../../utils/Sso';

class SetAvailability extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      help_text: 'Loading...',
      duraction: ""
    }
  }

  logOutUser = async () => {
    const hideMessage = message.error("Your account has been deactivated")

    const token = localStorage.getItem("authToken");
    await SSO_LOGOUT()
    localStorage.clear();
    if (token !== undefined) {
      let param = {
        url: "/users/logout",
        method: "POST",
        data: { token: token },
        headers: { "X-Auth": token },
      };
      await axios(param)
    }
    hideMessage()
    window.location = '/login#logout'
  }

  getTotalTimeDuraction = async() => {
    await axios.post(`/webinar/session-duraction-check`, { Id: JSON.parse(localStorage.getItem("userdata")).id }, { headers: { "X-Auth": localStorage.getItem('authToken') }, }).then((res) => {
      if (res.data.success) {
        this.setState({ duraction: res.data.data.duration })
      } else if (parseInt(res.data.status) === 600) {
        this.logOutUser()
      }
    }).catch((err) => {
      message.error("Something went wrong");
    })
  }

  componentWillMount() {
    console.log('propstt', this.props)
    if (localStorage.getItem('tourActive') === 'true') {
      localStorage.setItem('tourStep', 7)
    }
  }
  async componentDidMount() {
    this.getTotalTimeDuraction()
    let help_text = await getHelpText('availability')
    this.setState({ help_text })
  }


  render() {
    return (
      <div>

        <div className="heading text-center">

          {/* <h3>Coach's Availability Settings 
            <Tooltip title={this.state.help_text} placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>
              </h3> */}
          <h3>Set Availability
            <Tooltip title={this.state.help_text} placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>
          </h3>

          <span className="R_divider div_dark"></span>
        </div>

        <WeekList
          token={this.props.token}
          coachUid={this.props.coachUid}
          totalProfileUpdateStatus={this.props.totalProfileUpdateStatus}
          hideTour={this.props.hideTour}
        />

      </div>
    )
  }
}

export default SetAvailability