import React, { useEffect, useState } from 'react';
import axios from "../config/axios";
import { useHistory } from 'react-router-dom';
import { generatePassword } from '../config/custom';
import { getClientTimezone } from '../utils/timeZoneConvert';
import message from "../utils/mesaages";

const SocialLoginNow = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loginUser =  async (userDetails,isLogin)=>{
    const formData = {
      username: userDetails.email,
      social_id : userDetails.id,
      social_type : props.provider,
    };
    axios
      .post("/logingoogle", formData)
      .then(async (response) => {
        if (
          response.data.token !== undefined &&
          response.data.result === true
        ) {
          localStorage.setItem("authToken", response.data.token);
          localStorage.setItem(
            "coachingCategory",
            response.data.userdata.coaching_category
          );
          localStorage.setItem(
            "userdata",
            JSON.stringify(response.data.userdata)
          );
          localStorage.setItem("currency", response.data.userdata.currency);
          getClientTimezone();
          if(isLogin){
            history.push({ pathname: "webinars", state: { user: response.data.userdata, token: response.data.token } })
          }
        } else if (response.data.result === false) {
          message.error(response.data.msg)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
       
        const response = await fetch('https://www.googleapis.com/oauth2/v1/userinfo', {
            headers: {
              Authorization: `Bearer ${props.accessToken}`
            }
          });
  
          if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${response.statusText}`);
          }
  
        const userDetails = await response.json();
        if(props.isLogin){
          await loginUser(userDetails,props.isLogin);
        }else{
            const formData = {
                fullname: userDetails.name,
                email: userDetails.email,
                phone_number: "",
                password: generatePassword(),
                RepeatPassword: "",
                account_type: "jobseeker",
                getNoti: false,
                tracking_link: window.location.href,
                registration_source : props.registration_source,
                acode :props.acode,
                package : props.package,
                coupon : props.coupon,
                social_id2 : userDetails.id.toString(),
                social_type2 : props.provider
            };

            axios.post("/signup", formData).then(async (response) => {
                if (response.data.success) {
                    let Uuid = response.data.userdata.uid;
                    await loginUser(userDetails,props.isLogin);
                    history.push({ pathname: "/thankyou", state: { user_t: Uuid, userData: response.data.userdata, provider : true}, });
                }else{
                    message.error(response.data.message);
                }
            }).catch((error) => {
                console.log(error)
                message.error("There is some error while signup please try again later");
            });
        }
        
      } catch (err) {
        if (err.response) {
          // Server responded with a status other than 200 range
          setError(`Error: ${err.response.status} - ${err.response.data}`);
        } else if (err.request) {
          // Request was made but no response received
          setError('Error: No response received from the server.');
        } else {
          // Something happened in setting up the request
          setError(`Error: ${err.message}`);
        }
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [props.accessToken, history]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return null;
};

export default SocialLoginNow;
