import React from "react";
import { Link } from "react-router-dom";
import { teamSvg, quality, donutSvg, tickSvg, new_coach } from "../../assets/images";
import axios from "../../config/axios";
import { avatar } from "../../assets/img";
class CoachSuggest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
     
      favorites: [],
      favCoachList: [],
      finalCoachList: [],
     
    }
    
  }

  componentDidMount() {
  

    let self = this
    setTimeout(() => {
      self.fetchFavoriteCoaches()
    }, 2000);
  }

  async componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.suggestedCoaches !== prevProps.suggestedCoaches) {

      console.log('componentDidUpdate',prevProps.suggestedCoaches)
      await this.setState({finalCoachList: this.props.suggestedCoaches})
       this.fetchFavoriteCoaches()
      //this.mergedCoachList(this.props.ListCoaches);
    }
  }

    //_______Fetch favorite coaches________
    fetchFavoriteCoaches() {
      let self = this
      //console.log("dhurrrrrrrrrrrrrrrrrrrrr", this.props.Consumer_uid);
      axios.get(`/consumer/fetch-content/${this.props.Consumer_uid}`)
        .then((res) => {
          console.log('fffffffres',res.data)
          if (res.data.success) {
            //toast("Successfully added to favorites", { type: "success" });
            console.log('fffffff',res.data)
            self.setState({ favorites: res.data.value.arrayCoachid, favCoachList: res.data.value.favCoachDetails })
          } else {
            //toast("unable to load favorite coaches", { type: "error" });
            console.log("Your fav coachlist is empty")
            self.setState({ favorites: [], favCoachList: [] })
          }
          //self.mergedCoachList(self.props.ListCoaches)
          //console.log("LIST OF FAV COACH",self.state.favCoachList)
        })
        .catch((err) => {
          console.log(err);
        });
    };


    async addToFavorite(favorite, uid) {


      let marketinterest = this.state.favorites;
  
      //console.log('dddddddddddggggg',favorite,uid)
      let check = marketinterest.includes(favorite.coach_uid)
      if (!check) {
      
        marketinterest.push(favorite.coach_uid);
        await this.handleSubmitFavorite(favorite.coach_uid, uid)
      } else {
  
        Array.prototype.remove = function () {
          var what, a = arguments, L = a.length, ax;
          while (L && this.length) {
            what = a[--L];
            while ((ax = this.indexOf(what)) !== -1) {
              this.splice(ax, 1);
            }
          }
          return this;
        };
  
        marketinterest.remove(favorite.coach_uid);
        await this.handleRemoveFromFavorite(favorite.coach_uid, uid)
  
  
      }
      this.setState({ favorites: marketinterest })
      //console.log("arrya value of add to favorite", this.state.favorites)
    }


    handleSubmitFavorite = async(coach_uid, user_id) => {
      console.log("clicked certi--", coach_uid);
      let coachid = { coach_uid: coach_uid }
      await this.props.addToFavorites(coachid,user_id, "add")
   
    };
  
    //_______Remove from favorite_______
  
   
    handleRemoveFromFavorite = async(coach_uid, user_id) => {
      //console.log("clicked certi--", user_id);
      let coachid = { coach_uid }
      await this.props.addToFavorites(coachid,user_id, "remove")
     
    };

  render() {
    console.log('ss' ,this.props.Consumer_uid);

    // console.log("res---", this.props.suggestedCoaches);
    return (
      <div>
        <div className="searchHead">
          <div className="searchHeading">
            <h3>
              {/* <img width="22px" src={teamSvg} alt="LG-Img" /> SUGGESTED COACHES AND FAVOURITE COACHES */}
              <img width="22px" src={teamSvg} alt="LG-Img" /> Suggested Coaches And Favourite Coaches

            </h3>
          </div>
        </div>

        <div className="row mb-5">
          {/* {this.props.pdata.suggestedCoaches &&
            this.props.pdata.suggestedCoaches.map((coach) => { */}
            {this.state.finalCoachList &&
            this.state.finalCoachList.map((coach) => {
              let rawaddress = JSON.parse(coach.address);

              if (coach.account_type === "coach") {
                let star = coach.avg_rating
                let arr = [1,2,3]
                return (
                  <div key={coach} className="col-sm-6 col-lg-4 mt-4">
                    <div className="searchPeopleList">
                      {/* <img
                        src={donutSvg}
                        width="25px"
                        alt="LG-Img"
                        className="verified"
                      /> */}
                      
                      <div  onClick={(e) => this.addToFavorite(coach, this.props.Consumer_uid)} className={this.state.favorites.includes(coach.coach_uid) ? "icon-wishlist in-wishlist" : "icon-wishlist"}></div>
                      {coach.newCoach===0?
                       
                    <div className="justJoined"><span>Just Joined</span></div>
                    :''
                    }
                      <div className="sImgHolder">
                        <img
                          className="sUserImg"
                          alt="LG-Img"
                          src={coach.avatar_path!=null?coach.avatar_path:avatar}
                        />
                        {coach.has_membership ? (
                           <img
                           className="sbi"
                           width="35px"
                           alt="LG-Img"
                           src={quality}
                         />
                        ):("")}
                       
                      </div>
                      <h4>{coach.name}</h4>
                      {coach.user_title!=null &&(
                     <h6 className="color-dark-grey">{coach.user_title}</h6>

                  )}
                      {JSON.parse(coach.coaching_category).slice(0,2).map(cat =>{
                          return(
                          <h6 className="category-bold"><span>{cat.value}</span></h6>
                          )
                       })}
                      <h5>{coach.city_public===1?rawaddress && rawaddress.city && rawaddress.city+',':''} 
                   {rawaddress && rawaddress.country}</h5>
                   
                      <div className="ratingView">
                      {arr.map((a,index2)=>{
                 

                 return(
                   <i key={index2} className={star>=a?('fa fa-star rActive'):('fa fa-star')} aria-hidden="true"></i>

                 )

                 
                 })}
                        {/* <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i> */}
                      </div>
                      <p className="color-grey" style={{ color: "#878787" }}>
                        {coach.short_bio && coach.short_bio.substring(0, 120)}
                        ...
                      </p>
                      {/* <Link className="defaultBtn" to="/profile-coach-view"> */}
                      {/* <Link
                        to={{
                          pathname: "/profile-coach-view",
                          state: { coachData: coach,
                            ConsumerD: {uid:this.props.Consumer_uid, name:this.props.Consumer_name, email:this.props.Consumer_email },
                                   Consumer_uid: this.props.Consumer_uid,
                                   Consumer_email:this.props.Consumer_email},
                        }}
                        className="defaultBtn"
                      >
                        <img
                          width="20px"
                          alt="LG-Img"
                          className="mr-1"
                          src={tickSvg}
                        />{" "}
                        View Details
                      </Link> */}

                    <Link 
                    to={{
                      //  pathname: "/profile-coach-view",
                      pathname: `/profile-coach-view/${coach.coach_uid}`,

                      state: {
                        coachData: coach,
                        ConsumerD: 
                        { 
                          uid:'', 
                          name:'',
                           email: '' },

                      }
                    }}
                    className="defaultBtn"
                  >
                    {/* <img
                      width="20px"
                      alt="LG-Img"
                      className="mr-1"
                      src={tickSvg}
                    />{" "} */}
                        View Details
                      </Link>
                    </div>
                  </div>
                );
              } else {
                return <small>Loading...</small>;
              }
            })}
        </div>
      </div>
    );
  }
}
export default CoachSuggest;
