import React, { useState, useEffect } from 'react'
// import moment from "moment";
import moment from "moment-timezone";

import axios from '../../../config/axios'


import { TimePicker, DatePicker } from 'antd';
import LoadingOverlay from "react-loading-overlay";
import message from '../../../utils/mesaages';
import { getClientTimezone, timezoneUtc, UTCtoTimezone } from '../../../utils/timeZoneConvert';
import { Form, Col, Row } from "react-bootstrap";
import CustomImageLoader from 'react-custom-image-loader.'
import { LGspinner } from '../../../assets/images'


const _ = require("lodash");
const format = 'h:mm a';
let Weekwidget = ({ Day, PerticularDayArary, addItem, removeItem, handleInputChange }) => {



  return (
    <div className="row">

      <div className="col-md-3 col-4">
        <div className="form-group">

          <input type="text"
            style={{ color: "black" }}
            name="Day"
            value={Day}
            className="form-control" />
        </div>
      </div>
      <div className="col-md-9 col-8">
        {PerticularDayArary.map((x, i) => {
          let fromTime = moment(x.From, "HH:mm").isValid() ? moment(x.From, "HH:mm") : ''
          let toTime = moment(x.To, "HH:mm").isValid() ? moment(x.To, "HH:mm") : ''
          let errorMsg = x.errMsg//"Your start time should be less than end time";
          if (fromTime === '') {
            errorMsg = "Please select start time on " + Day;
          } else if (toTime === '') {
            errorMsg = "Please select end time on " + Day;
          }

          return (<div className="row" key={`week-${Day}-${i}`}>
            <div className="col-md-5 col-6 col-sm-5">
              <div className="form-group">
                <TimePicker size="large"
                  onChange={(e) => handleInputChange(e, Day, i, 'From')}
                  minuteStep={15} showNow={false} allowClear={true} defaultValue={moment()} use12Hours value={fromTime} format={format} />

              </div>
            </div>
            <div className="col-md-5 col-6 col-sm-5">
              <div className="form-group">
                <TimePicker size="large"
                  onChange={(e) => handleInputChange(e, Day, i, 'To')}
                  minuteStep={15} showNow={false} allowClear={true} defaultValue={moment()} use12Hours value={toTime} format={format} />

              </div>
            </div>
            <div className="col-md-2 col-sm-2  text-center">
              <p>
                {PerticularDayArary.length - 1 !== i ? (
                  <span className="addAvailabilityTime" onClick={(e) => removeItem(Day, i)} >
                    <i className="fa fa-minus" ></i>
                  </span>
                ) : (
                  <span className="addAvailabilityTime" onClick={(e) => addItem(Day)}>
                    <i className="fa fa-plus"></i>
                  </span>
                )}
              </p>
            </div>
            {x.hasOwnProperty('isValid') && !x.isValid && (<div className="col-md-12" style={{ color: 'red' }}>{errorMsg}</div>)}
          </div>

          )
        }



        )}
      </div>

      <div className="col-md-12">
        <hr />
      </div>
    </div>

  )

}


let weekArray = moment.weekdays()
let WeekArrayState = weekArray.map(Day => ({ Day, From: "", To: "" }))
class WeekList extends React.Component {
  constructor(props) {
    super(props)
    this.timezone = localStorage.getItem('timezone')
    // moment.tz.setDefault(this.timezone);



    this.state = {

      WeekArrayState,
      unavailableTime: [{}],

      showloader: false,
      availability_id: null,
    }
  }

  async componentDidMount() {

    this.handleGetAvailability()
    getClientTimezone()
  }

  handleGetAvailability() {
    this.setState({ showloader: true })
    const id = this.props.coachUid
    let self = this;
    axios.get(`/coach/setavailability/${id}`, {
      headers: { 'x-auth': this.props.token }
    })
      .then(res => {
        this.setState({ showloader: false })
        if (res.data.success && res.data.value.length > 0) {
          let dataset = res.data.value[0]
          self.setState({ availability_id: dataset.uid })
          let data = JSON.parse(dataset.schedule_details)
          let weekdata = data.availableDays, unavailableDays = [{}]
          let ResweekArray = []
          weekdata.forEach((wkObj, v) => {
            let wkVals = Object.values(wkObj)
            if (wkVals) {
              wkVals.forEach((wval, weekDayName) => {
                wval.forEach(d => {
                  ResweekArray.push(d)
                })
              })
            }
          })
          let daysFromDb = ResweekArray.map(robj => robj.Day)
          weekArray.forEach(wDay => {
            if (!daysFromDb.includes(wDay)) {
              ResweekArray.push(({ Day: wDay, From: "", To: "" }))
            }
          })

          if (data.unavailableDays && data.unavailableDays.length) {
            unavailableDays = data.unavailableDays
          }
          console.log("........ResweekArray", unavailableDays)
          self.setState({
            WeekArrayState: ResweekArray,
            unavailableTime: unavailableDays,
          })



        }
      })
      .catch(err => {
        this.setState({ showloader: false })
        console.log(err)
      })
  }

  handleInputChange = (e, weekday, index, name) => {
    const value = e ? e.format('HH:mm') : '';

    let PerticularDayArary = this.state.WeekArrayState.filter(objW => objW.Day === weekday)

    let WeekArrayState = this.state.WeekArrayState.filter(objW => objW.Day !== weekday)

    PerticularDayArary[index][name] = value
    let { From, To } = PerticularDayArary[index]

    console.log('PerticularDayArary', PerticularDayArary)
    // validate same slots
    let vvv = PerticularDayArary.filter((objW, indexo) => {
      if (indexo != index) {


        return (From <= objW.From && To > objW.From) || (From < objW.To && To >= objW.To) || (From > objW.From && To < objW.To)

      }
      else {
        return false
      }

    })

    console.log('vvvv', vvv)
    if (vvv.length > 0) {
      PerticularDayArary[index]['isValid'] = false
      //PerticularDayArary[index]['errMsg'] ="You have chosen the same time slot. Please chose other time slot for set the availability."
      PerticularDayArary[index]['errMsg'] = "You have chosen same Start time or End time. Please choose another time slot to set your availability."

    }
    else {
      var beginningTime = moment(From, 'HH:mm');
      var endTime = moment(To, 'HH:mm');
      PerticularDayArary[index]['isValid'] = beginningTime.isBefore(endTime)
      PerticularDayArary[index]['errMsg'] = "Your start time should be before end time"


    }

    // validate same slots


    PerticularDayArary.forEach(e => WeekArrayState.push(e))


    // ExcludePerticularDayArary.concat(PerticularDayArary)
    this.setState({ WeekArrayState })



  };



  handleSubmitSchedule = (e) => {
    if (localStorage.getItem('tourActive') == 'true') {
      this.props.hideTour('.tour-createpackages')
    }

    let availableDays = []
    weekArray.forEach(w => {
      let PerticularDayArary = this.state.WeekArrayState.filter(objW => {
        if (objW.hasOwnProperty('isValid') && !objW.isValid) return false
        let isValid = objW.Day === w && objW.From !== "" && objW.To !== ""
        return isValid
      })
      let i = {}
      i[`${w}s`] = PerticularDayArary
      availableDays.push(i)
    })
    let unavailableDays = this.state.unavailableTime || [{}]
    unavailableDays = unavailableDays.filter(objWunvail => {
      if (!objWunvail) return false
      if (objWunvail.hasOwnProperty('isValid') && !objWunvail.isValid) return false
      let isValid = objWunvail.From !== "" && objWunvail.To !== ""
      return isValid
    })

    this.setState({ showloader: true })

    let Schedule = {
      availableDays: availableDays,
      unavailableDays: unavailableDays
    };

    const formData = {

      schedule_details: Schedule,
      uid: this.state.availability_id,
      reminder: 2
    }

    axios.post('/coach/setavailability', formData, {
      headers: { 'x-auth': this.props.token }
    })
      .then(res => {
        this.setState({ showloader: false })
        if (res.data.success) {

          message.success('Your availability has been updated successfully');
          this.handleGetAvailability()
          getClientTimezone()
          if (localStorage.getItem('tourActive') == 'true') {
            console.log('reload')
            window.location.reload()
          }
          this.props.totalProfileUpdamomentatus()


        } else {
          message.error('Your availability  could not be updated. Please try again.');

        }
      })
      .catch(err => {
        this.setState({ showloader: false })
        console.log(err)
      })


  }

  handleUpdateweekset = () => {
    this.handleGetAvailability()
  }

  handleResetweekset = () => {

  }




  addItem(Day) {
    let WeekArrayState = [...this.state.WeekArrayState]
    WeekArrayState.push(({ Day, From: "", To: "" }))
    this.setState({ WeekArrayState })
  }

  removeItem(weekday, index) {
    let PerticularDayArary = this.state.WeekArrayState.filter(objW => objW.Day === weekday)
    PerticularDayArary = PerticularDayArary.filter((k, i) => i !== index)
    let WeekArrayState = this.state.WeekArrayState.filter(objW => objW.Day !== weekday)
    PerticularDayArary.forEach(e => WeekArrayState.push(e))
    this.setState({ WeekArrayState })
  }

  addUnavailableItem(Day) {
    let unavailableTime = [...this.state.unavailableTime]
    unavailableTime.push({})
    this.setState({ unavailableTime })
  }

  removeUnavailableItem(index) {
    let unavailableTime = [...this.state.unavailableTime]
    unavailableTime = unavailableTime.filter((k, i) => i !== index)
    this.setState({ unavailableTime })
  }

  handleInputChangeUnavailable = (e, index, name) => {
    let value = moment(e).format('YYYY-MM-DD HH:mm');
    console.log('UTC', timezoneUtc(this.timezone, value).format('YYYY-MM-DD HH:mm'))
    let utc = timezoneUtc(this.timezone, value)
    console.log('UTC', utc)
    console.log('UTC Asia', UTCtoTimezone(this.timezone, utc, 'YYYY-MM-DD HH:mm'))
    // console.log('UTC Asia',moment.tz(e,"America/Atka (GMT -10:00)").utc().tz("Asia/kolkata").format())



    if (name !== 'date') {
      value = e ? e.format('HH:mm') : '';
    } else {
      value = e ? e.format('YYYY-MM-DD') : '';
    }

    // console.log('timezone',getClientTimezone())



    let unavailableTime = [...this.state.unavailableTime]
    unavailableTime[index][name] = value
    let { From, To, date } = unavailableTime[index]

    var beginningTime = moment(From, 'HH:mm');
    var endTime = moment(To, 'HH:mm');
    var unvaildate = moment(date, 'YYYY-MM-DD')
    if (date == undefined) {
      unavailableTime[index]['date'] = moment().format('YYYY-MM-DD')
    }

    unavailableTime[index]['isValid'] = beginningTime.isBefore(endTime) && unvaildate.isValid()
    this.setState({ unavailableTime })
  }

  renderWeekDay(weekday) {

    let PerticularDayArary = this.state.WeekArrayState.filter(objW => objW.Day === weekday)
    console.log(".....weekday", weekday, this.state.WeekArrayState, PerticularDayArary)
    return (<Weekwidget Day={weekday} handleInputChange={this.handleInputChange.bind(this)}
      addItem={this.addItem.bind(this)} removeItem={this.removeItem.bind(this)} PerticularDayArary={PerticularDayArary} />)
  }

  disabledDate = (value) => {
    // const form = this.props.form;
    // Can not select days before today and today
    return value < moment().subtract(1, 'days');
  };

  render() {
    let self = this
    return (

      <LoadingOverlay
        active={this.state.showloader}
        //spinner
        spinner={<div><CustomImageLoader image={LGspinner} animationType={'pulse'} /><br /></div>}
        text='Loading your time slots...'
      >


        <div className="availabilityViewWrap availabilityWrap mb-5 tour-SetAvailability-setTime">

          <Form>
            <div className="row">

              <div className="col-md-3 col-4">
                <label>Weekly Hours</label>
              </div>
              <div className="col-md-9 col-8">
                <div className="row">
                  <div className="col-md-5 col-6 col-sm-5">
                    <label>Select Start Time</label>
                  </div>
                  <div className="col-md-5 col-6 col-sm-5">
                    <label>Select End Time</label>
                  </div>
                  <div className="col-md-2 col-sm-2  text-center">
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <hr />
              </div>
            </div>


            {weekArray.map(w => this.renderWeekDay(w))}









            <div className="row mt-4">
              <div className="col-md-12">
                <div className="row">
                  <h6 className="pl-3"><strong>Unavailable</strong></h6>

                </div>
              </div>
            </div>




            {

              this.state.unavailableTime.map((x, i) => {
                let fromTime = moment(x.From, "HH:mm").isValid() ? moment(x.From, "HH:mm") : ''
                let toTime = moment(x.To, "HH:mm").isValid() ? moment(x.To, "HH:mm") : ''
                let dateUnvail = moment(x.date, "YYYY-MM-DD").isValid() ? moment(x.date, "YYYY-MM-DD") : moment()
                let errorMsg = "Your start time should be before end time";
                if (fromTime === '') {
                  errorMsg = "Please select start time";
                } else if (toTime === '') {
                  errorMsg = "Please select end time";
                }

                return (
                  <div className="row tour-SetAvailability-unavailable-setTime">
                    <div className="col-md-3 col-4">
                      <div className="form-group">
                        <DatePicker
                          size="large"
                          disabledDate={this.disabledDate}
                          defaultValue={moment()} value={dateUnvail} format={"DD-MM-YYYY"}
                          onChange={(e) => self.handleInputChangeUnavailable(e, i, 'date')} />

                        {/* <input style={{color:"black"}} 
                                                className="form-control" 
                                                type="date" name="date" 
                                                placeholder="dd-mm-yyyy"
                                                value={x.date}
                                                onChange={(e) => self.handleInputChangeUnavailable(e, i, 'date')}
                                                /> */}
                      </div>
                    </div>
                    <div className="col-md-9 col-8">
                      <div className="row">
                        <div className="col-md-5 col-6 col-sm-5">

                          <TimePicker size="large"
                            onChange={(e) => this.handleInputChangeUnavailable(e, i, 'From')}
                            minuteStep={15} showNow={false} allowClear={true} defaultValue={moment()} use12Hours value={fromTime} format={format} />
                        </div>
                        <div className="col-md-5 col-6 col-sm-5">
                          <div className="form-group">
                            <TimePicker size="large"
                              onChange={(e) => this.handleInputChangeUnavailable(e, i, 'To')}
                              minuteStep={15} showNow={false} allowClear={true} defaultValue={moment()} use12Hours value={toTime} format={format} />
                          </div>
                        </div>
                        <div className="col-md-2 col-sm-2  text-center test">
                          <p>
                            {this.state.unavailableTime.length - 1 !== i ? (
                              <span className="addAvailabilityTime" onClick={(e) => this.removeUnavailableItem(i)}>
                                <i className="fa fa-minus"></i>
                              </span>
                            ) : (
                              <span className="addAvailabilityTime" onClick={(e) => this.addUnavailableItem()}>
                                <i className="fa fa-plus"></i>
                              </span>
                            )}
                          </p>



                        </div>
                      </div>
                    </div>
                    {x.hasOwnProperty('isValid') && !x.isValid && (<div className="col-md-12" style={{ color: 'red' }}>{errorMsg}</div>)}
                  </div>
                )
              }


              )}






            <div className="text-right mt-4 timeEntryAction tour-SetAvailability-submit">
              <button className="defaultBtn" type="button" onClick={this.handleSubmitSchedule.bind(this)} >Submit</button>
            </div>
          </Form>

        </div>
      </LoadingOverlay>
    )
  }
}
export default WeekList