import React from 'react'
import {Link} from 'react-router-dom'
import { quality } from '../../assets/images'

class Membership extends React.Component{
  constructor(props) {
    super(props);
   
  }
    render(){
        return(
            <div>
                <fieldset>
        <div className="heading text-center">
          <h3 className="fs-subtitle">Member of association only coaches </h3>
          <span className="R_divider"></span>
          <div className="text-center mb-3">
          <img width="45px" alt="LG-Img" src={quality}/>
          </div>
        </div>
        
        <div className="form-group">
            {/* <div className="custom-control custom-radio">
              <input type="radio"  className="custom-control-input" id="customCheck" name="MemberOfPropessionalBody" onChange={this.props.handleChange} value="1"/>
              <label style={{color:"black"}} className="custom-control-label" for="customCheck">
                Yes
              </label>
            </div>
            <div className="custom-control custom-radio">
              <input type="radio"  className="custom-control-input" id="customCheck4" name="MemberOfPropessionalBody" onChange={this.props.handleChange} value="0"/>
              <label style={{color:"black"}} className="custom-control-label" for="customCheck4">
                No
              </label>
            </div> */}
            <div className="areas d-flex mb-4 justify-content-center">
            <div className="custom-control custom-radio radio-inline mx-3">
              <input type="radio"  className="custom-control-input" id="customCheck" name="MemberOfPropessionalBody" onChange={this.props.handleChange} value="1"
              checked={this.props.data.MemberOfPropessionalBody === "1" ? true: false}/>
              <label style={{color:"black"}} className="custom-control-label" for="customCheck">
                Yes
              </label>
            </div>
            <div className="custom-control custom-radio radio-inline mx-3">
              <input type="radio"  className="custom-control-input" id="customCheck4" name="MemberOfPropessionalBody" onChange={this.props.handleChange} value="0"
              checked={this.props.data.MemberOfPropessionalBody === "0" ? true: false}/>
              <label style={{color:"black"}} className="custom-control-label" for="customCheck4">
                No
              </label>
            </div>
            </div>
            {/* <div className="custom-control custom-checkbox">
              <input type="checkbox"  className="custom-control-input" id="customCheck4" name="MemberOfPropessionalBody" onChange={this.props.handleChange} value="1"/>
              <label style={{color:"black"}} className="custom-control-label" for="customCheck4">
                Member of professional body <img width="25px" alt="LG-Img" src={quality}/>
              </label>
            </div> */}
          </div>
        <Link
        to="#"
          onClick={() =>
          this.props.handleField("Star")
          }
         className="defaultBtn">PREVIOUS
        </Link>{" "}

        <Link
        to="#"
          onClick={() =>
          this.props.handleField("MatchMyCoach")
          }
         className="defaultBtn">Next
        </Link>
       
      </fieldset>
            </div>
        )
    }
}
export default Membership