import React from "react";
import { FooterLink } from "../components/footers";
import { Header, HeaderLogout, HeaderTop } from "../components/headers";
import { dArrowblack, LGspinner } from "../assets/images";
import axios from "../config/axios";
import { ApplyFilter } from "../components/commonprofile/index";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import AsyncSelect from 'react-select/async';
import { Row } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import Autosuggest from 'react-autosuggest';
import TagsInput from "./TagsInput";
import message from '../utils/mesaages';
import CustomImageLoader from 'react-custom-image-loader.'

const customStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    console.log({ data, isDisabled, isFocused, isSelected });
    return {
      ...styles,
      backgroundColor: isFocused ? "#999999" : null,
      color: "#333333"
    };
  }
};


class FindMyCoach extends React.Component {
  constructor(props) {
    super();
    this.token = localStorage.getItem("authToken");
    this.state = {
      category: "",
      tags: [],
      selectedValue: [],
      listCoaches: [],
      language: "",
      timezone: "",
      CoachingStyle: "",
      CoachingExpHrs: "",
      Hourlyrate: "",
      rating: "",
      MemberOfPropessionalBody: "0",
      optionTags: [],
      searchloader: false,
      advancefilter: false,
      setinputtagg: [],
      setinputtaginitial: []

    };

    this.fetchTagsFromApi = this.fetchTagsFromApi.bind(this)
    this.handleChangeTags = this.handleChangeTags.bind(this)
  }

  componentWillMount() {
    window.scrollTo(0,0);
    this.fetchInitialtags()
    const data = this.props.location.state;
    if (data) {
      const formData = {
        category: this.props.location.state.Category,
        tags: this.props.location.state.Tags,
      };
      console.clear()
      console.log('formdata', this.props.location.state.Tags)
      this.setState({ searchloader: true, category: this.props.location.state.Category });
      if (this.props.location.state.Tags.length > 0) {
        // let t = []
        // this.props.location.state.Tags.forEach(element => {
        //   let e = {
        //     label: element,
        //     value: element
        //   }
        //   t.push(e)
        // });
        console.log('formdata1@@@@@@@@@@@@@@@@@@@', formData)

        this.setState({ setinputtagg: this.props.location.state.Tags })
      }


      console.log('formdata', formData)
      axios
        .post("/consumer/searchCoaches", formData, {
          headers: { "X-Auth": this.token },
        })
        .then((res) => {
          this.setState({ searchloader: false });

          if (res.data.success && res.data.value.length > 0) {
            console.log('coach res', res)
            let Coaches = res.data.value;
            this.setState({ listCoaches: Coaches });
          } else {
            this.setState({ listCoaches: [] });
            message.error("No results found. Please change your search criteria.");
          }
        })
        .catch((err) => {
          this.setState({ searchloader: false });
          message.error("No results found. Please change your search criteria.");

          console.log(err);
        });
    } else {
      this.fetchallcoaches()
    }
  }

  componentDidMount()
  {
    window.scrollTo(0,0);
  }






  fetchInitialtags = inputValue => {
    let self = this
    return new Promise(resolve => {
      axios.post("/consumer/filterSkills", { tags: 'a' })
        .then(res => {
          console.log("Res-", res)
          let tags = res.data.map(d => ({ value: d, label: d }))
          // return resolve(tags);
          self.setState({ optionTags: tags })
          //console.log(self.state.tags)
        })
        .catch(err => {
          console.log(err)
        })

    });

  }

  handleChange = (e) => {

    console.log("eee", e.target.name);
    this.setState({
      [e.target.name]: e.target.value,
    });
    
  };

  handleMembershipCheck = async(e) => {
    if(this.state.MemberOfPropessionalBody != "0") {
      await this.setState({MemberOfPropessionalBody: "0"})
    } else {
      await this.setState({MemberOfPropessionalBody: "1"})
    }
    //console.log("@@@@@@@@@@@@ membership checkbox value @@@@@@@@@@@@@@", this.state.MemberOfPropessionalBody)
  }

 

  // handleSubmit = (e) => {
  //   e.preventDefault();
  //   const formData = {
  //     category: this.state.category,
  //     tags: this.state.tags,
  //   };

  //   axios
  //     .post("/consumer/searchCoaches", formData, {
  //       headers: { "X-Auth": this.token },
  //     })
  //     .then((res) => {
  //       console.log("search", res);
  //       if (res.data.success) {
  //         let Coaches = res.data.value;
  //         this.setState({ listCoaches: Coaches });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };




  fetchallcoaches = () => {

    this.setState({ searchloader: true })

    axios.post("/consumer/fetchallcoaches", {}, {
    })
      .then((res) => {
        this.setState({ searchloader: false })
        if (res.data.success) {
          let Coaches = res.data.value
          console.log('tanmay', Coaches)
          this.setState({ listCoaches: Coaches })
        } else {
          this.setState({ listCoaches: [] })
        }
      })
      .catch(err => {
        this.setState({ searchloader: false })
        console.log(err)
      })
  }

  // handleSubmit = (e) => {
  //   console.log('wffff', this.state.selectedValue)

  //   this.setState({ searchloader: true })
  //   let tagValues = []
  //   // if (this.state.selectedValue !== undefined && this.state.selectedValue !== null && this.state.selectedValue.length > 0) {
  //   //   tagValues = this.state.selectedValue.map((each) => {
  //   //     return each.label
  //   //   })
  //   // }

  //   tagValues = this.state.setinputtagg;

  //   console.log("array value of tags", tagValues)
  //   e.preventDefault()
  //   const formData = {
  //     category: this.state.category,
  //     tags: tagValues
  //   }

  //   console.log('formdata', formData);

  //   axios.post("/consumer/searchCoaches", formData, {
  //     headers: { "X-Auth": this.props.token }
  //   })
  //     .then((res) => {
  //       this.setState({ searchloader: false })
  //       if (res.data.success) {
         
  //         let Coaches = res.data.value
  //         console.log('tanmay', Coaches)
  //         this.setState({ listCoaches: Coaches })
  //       }
  //        else {
          
         
  //         this.setState({ listCoaches: [] })
  //       }
  //       if (res.data.value.length <= 0) {
  //         message.error("No results found. Please change your search criteria.");
  //       }
  //     })
  //     .catch(err => {
  //       this.setState({ searchloader: false })
  //       console.log(err)
  //       message.error("No results found. Please change your search criteria.");
  //     })
  // }

  handleSubmit = (e) => {
    console.log('wffff', this.state.selectedValue)

    this.setState({ searchloader: true })
    let tagValues = []
    // if (this.state.selectedValue !== undefined && this.state.selectedValue !== null && this.state.selectedValue.length > 0) {
    //   tagValues = this.state.selectedValue.map((each) => {
    //     return each.label
    //   })
    // }

    tagValues = this.state.setinputtagg;

    console.log("array value of tags", tagValues)
    e.preventDefault()
    if(tagValues.length>0 || this.state.category.length>0){
      const formData = {
        category: this.state.category,
        tags: tagValues
      }
  
      console.log('formdata', formData);
  
      axios.post("/consumer/searchCoaches", formData, {
        headers: { "X-Auth": this.props.token }
      })
        .then((res) => {
          this.setState({ searchloader: false })
          if (res.data.success) {
           
            let Coaches = res.data.value
            console.log('tanmay', Coaches)
            this.setState({ listCoaches: Coaches })
          }
           else {
            
           
            this.setState({ listCoaches: [] })
          }
          if (res.data.value.length <= 0) {
            message.error("No results found. Please change your search criteria.");
          }
        })
        .catch(err => {
          this.setState({ searchloader: false })
          console.log(err)
          message.error("No results found. Please change your search criteria.");
        })
    } else {
      this.fetchallcoaches()
    }
  }




  handleApplyFilter = (e) => {
    this.setState({ searchloader: true })
    e.preventDefault();
    const formData = {
      language: this.state.language,
      timezone: this.state.timezone,
      CoachingStyle: this.state.CoachingStyle,
      CoachingExpHrs: this.state.CoachingExpHrs,
      Hourlyrate: this.state.Hourlyrate,
      rating: this.state.rating,
      MemberOfPropessionalBody: this.state.MemberOfPropessionalBody,
    };
    console.log("filter-form", formData);
    if(this.state.language==='' && this.state.timezone==='' && this.state.CoachingStyle==='' && this.state.CoachingExpHrs===''
    && this.state.Hourlyrate=='' && this.state.rating==='' && this.state.MemberOfPropessionalBody===0
    )
    {
      message.error("Please select at least one field.");
      this.setState({ searchloader: false })
    }else{
    axios
      .post("/consumer/findcoach-filterCoaches", formData, {
        headers: { "X-Auth": this.token },
      })
      .then((res) => {
        this.setState({ searchloader: false })

        console.log("searchfilter--", res);
        if (res.data.success) {

          if (res.data.value.length > 0) {
            let deepcopydata = JSON.parse(JSON.stringify(res.data.value));
            let filtererrorsdata = deepcopydata.filter((v) => v.coaching_category !== '');
            this.setState({ listCoaches: filtererrorsdata })
            return
          } else {
            message.error("No results found. Please change your search criteria.");
            this.setState({ listCoaches: [] })
          }

          this.setState({ listCoaches: res.data.value });
        } else {
          this.setState({ listCoaches: [] })
          message.error("No results found. Please change your search criteria.");
        }
      })
      .catch((err) => {
        this.setState({ searchloader: false })

        console.log(err);
        message.error("Internal error");
      });


    }


  };

  fetchTagsFromApi = inputValue => {
    console.log('input value', inputValue)
    let self = this
    return new Promise(resolve => {
      axios.post("/consumer/filterSkills", { tags: inputValue })
        .then(res => {
          console.log("Res-", res)
          let tags = res.data.map(d => ({ value: d, label: d }))
          return resolve(tags);
          //  self.setState({tags})
          //console.log(self.state.tags)
        })
        .catch(err => {
          console.log(err)
        })

    });

  }

  options = [

    { key: 'Career Coaching', label: 'Career Coaching' },
    { key: 'Executive Coaching', label: 'Executive Coaching' },
    { key: 'Life Coaching', label: 'Life Coaching' },
    { key: 'Transformational Coaching', label: 'Transformational Coaching' },
    { key: 'Health & Wellbeing Coaching', label: 'Health & Wellbeing Coaching' },
    { key: 'Business Coaching', label: 'Business Coaching' },

  ]

  fetchInitialtags

  // optionTags = [

  //   // { key: 'Career', label: 'Career' },
  //   // { key: 'Health', label: 'Health' },
  //   // { key: 'Oneness', label: 'Oneness' },
  //   // { key: 'Potential', label: 'Potential' },
  //   // { key: 'Power', label: 'Power' },
  //   // { key: 'Technique', label: 'Technique' },
  //   // { key: 'Stress', label: 'Stress' },
  //   // { key: 'Unwinding', label: 'Unwinding' },
  //   // { key: 'Wellbeing', label: 'Wellbeing' },

  // ]


  handleChangeTags(value, { action, removedValue }) {
    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.isFixed !== undefined) {
          return;
        }
        break;
      case 'clear':
        value = []
        break;
    }


    this.setState({ selectedValue: value });
    //console.log("ORIGINAL TAGS VALUE", this.state.tags)
    //this.props.data.skills = value //[...this.state.favorites, favorite.coach_uid]
  }

  setrating = (e) => {
    console.log('eee', e);
    this.setState({ rating: e })

  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };


  setinputtag = (t) => {
    this.setState({ setinputtagg: [...this.state.setinputtagg, t] })
  }

  deleteinputtag = (i) => {
    const { setinputtagg } = this.state;
    const newTags = setinputtagg.filter((tag, j) => i !== j);
    this.setState({
      setinputtagg: newTags
    });

  }

  render() {


    return (
      <div>
        {localStorage.getItem("authToken") == null ? (
          <HeaderTop />
        ) : (
            <HeaderLogout />
          )}
        <Header />
        <section className="searchWrap m-0 searchNewWrap">
          <div className="container">
            <div className="row mt-4">
              <div className="col-md-9">
                <div className="searchPart">

                  <div className="searchList">
                    <div className="selectBox w-100 mx-auto ">

                      <DropdownMultiselect placeholder="Select coaching categories" options={this.options} selected={this.state.category} name="category" className="Dip"
                        handleOnChange={(selected) => {
                          this.setState({ category: selected });
                        }}
                      />

                    </div>
                  </div>
                  <div className="searchList" style={{ color: 'black' }}>
                    <TagsInput
                      setinputtag={this.setinputtag}
                      deleteinputtag={this.deleteinputtag}
                      setinputtagg={this.state.setinputtagg}
                    >
                    </TagsInput>

                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <button className="defaultBtn w-100" type="submit" onClick={this.handleSubmit}>Search</button>
              </div>
            </div>
          </div>
          <Row>
          </Row>
        </section>

        <LoadingOverlay
          active={this.state.searchloader}
          //spinner
          spinner={<CustomImageLoader image={LGspinner} animationType={'none'}/>}
        >
          <ApplyFilter
            data={this.state}
            handleChange={this.handleChange}
            handleApplyFilter={this.handleApplyFilter}
            setrating={this.setrating}
            handleMembershipCheck={this.handleMembershipCheck}
           
          />
        </LoadingOverlay>
        <FooterLink />
      </div>
    );
  }
}
export default FindMyCoach;
