import React from "react";
import Select from "react-select";
import axios from "../../../config/axios";
import { toast } from "react-toastify";
import { Button, Modal, Form, Col, } from "react-bootstrap";
import { writingSvg, closeSvg, photograph, deleteSvg, documentSvg,upload, LGspinner } from "../../../assets/images";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { Link, Redirect  } from "react-router-dom";
import { avatar } from "../../../assets/img";
import UpdateImage from "./UpdateImage";
import { Header, HeaderLogout, HeaderTop } from "../../../components/headers";
import  Changepasswordmodal from "../../../components/commonmodal/Changepasswordmodal";
import { FooterLink } from "../../../components/footers";
import { ListModal } from "../../../components/resourcefilemodal";
import MyLoader from "../../../components/loader/loader"
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import { Tooltip } from 'antd';
import message,{getHelpText} from '../../../utils/mesaages';
import validation from '../../../Validate/validator';
import {getClientTimezone,timezoneUtc,UTCtoTimezone} from '../../../utils/timeZoneConvert';
import {curencyFilter,curencyRates} from '../../../utils/currencyConvert';
import ConfirmModal from "../view/AccDeleteConfirmModal";
import PhoneInput from 'react-phone-input-2'
import CustomImageLoader from 'react-custom-image-loader.'
import {MyAutosuggest} from "../../../components/commonprofile"
import TourSite from '../../../utils/TourSite'


import ConfirmModalProfilepic from '../../../components/commonmodal/ConfirmModalProfilepic'
import moment from "moment";

const _ = require("lodash");
// const ct = require("countries-and-timezones");


class UpdatePersonal extends React.Component {
  constructor() {
      super()
      this.token = localStorage.getItem("authToken");

      if (localStorage.getItem("user")) {
        this.userdata = JSON.parse(localStorage.getItem("user"));
      }
  
  
      else if (localStorage.getItem("userdata")) {
        this.userdata = JSON.parse(localStorage.getItem("userdata"));
      }
  
  
      else {
        // #temprary if page will refereshed by mistake
        this.userdata = {
          account_type: "user",
          uid: "44abb6bc-8361-4a56-a88a-3b4c8b10eea2",
        };
      }

      // https://lifeguru-prod.s3-us-east-2.amazonaws.com/s3-lambda-ffmpeg-mov/resource_1617970650695.mp4
      // https://lifeguru-prod.s3.us-east-2.amazonaws.com/s3-lambda-ffmpeg-mov/resource_1617970650695.mp4
      this.state = {
        freeTrialDaysleft: "",
        renderSubComponent: "Home",
        avatar: "",
        avatar_path: avatar,
        fname: "",
        lname: "",
        coach_title: "",
        user_title: "",
        // coach_category: "",
        // coaching_category: "",
        city: "",
        email: "",
        country: "",
        countryCode: "",
        location: "",
        userImage: null,
        coach_uid: "",
        short_bio: "",
        long_bio: "",
        videofile: "",
        tags: "",
        skills: "",
        isLoaded: "",
        coachprofile_id: "",
        check_account_type: "",
        // dounat chart value using this___start
        chartValue: [],
        labels_data: [],
        data_data: [],
        color_data: [],
        // dounat chart value using this____end
        // coaching_category_list: [],
        language: [{ value: "English", label: "English" }],
        timezone: "",
        HoursofExp: "",
        HourlyRate: "",
        membership: "",
        alertMsg: "",
        baseCurrency: "USD",
        convertToCurrency: "USD",
        baseAmount: 100,
        rates: [],
        currencies: [],
        findcurrency: 1,
        totalprofileupdate: "",
        tag_update_profile: 0,
        //avatar_path_update_profile: 0,
        //user_title_update_profile: 0,
        user_basic_update_profile: 0,             //10
        user_basicone_update_profile: 0,          //10
        user_certificate_update_profile: 0,       //20
        //user_social_update_profile: 0,          //10
        //user_private_update_profile: 0,
        //short_bio_update_profile: 0,
        //packages_update_profile: 0,
        coachingCategory: null,
        coachUid: "",
        coachEmail: "",
        Consumer_name: "",
        Consumer_email: "",
        SocialMediaInfo: [{ SocialMedia: "", URL: "" }],
        SocialMediaInfoErr: [{ SocialMediaErr: "", URLErr: "" }],
        //
        show: false,
        setShow: false,
        address: "",
        stateAddress: "",
        city: "",
        tzOptions: [],
        canwrite: false,
        check: 1,
        options: [],
        CertificateType:"",
        certificate_path: null,
        certificate_name:'',
        certificates: [],
        loader: false,
        public_profile_text:'',
        private_profile_text:'',
        insurance_certifications_text:'',
        profile_img_text:'',
        FromDataError: {
          fname: 'false',
          lname: 'false',
          user_title:'false',
          HourlyRate:'',
          social:'',
          phone:'false',
          coachingCategory:'false',
          language:'false',
          country:'false'
        },
        redirect: false,
        acc_delete_text: '',
        short_bio_text:'',
        hourly_rate_text:'',
        profile_back_button_text:'',
        coach_remove_acount:'',
        coach_city_text:'',
        city_public: 1,
        membership_helptext:"",
        hideTour:false,
        avatar_path_check:"",
        //For tour
        loaderTour:false,
        //
        countryName: "",
        rates:""
      };
  }
  //Tour section
  handaleLoader=(status)=>{
    this.setState({loaderTour:status})
  }
  //


  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {

        if (value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }

        valid = false;
      }
    }

    return valid;
  };

  async componentDidMount() {
    let public_profile_text = await getHelpText('public_profile_text')
    this.setState({public_profile_text})
    let private_profile_text = await getHelpText('private_profile_text')
    this.setState({private_profile_text})
    let insurance_certifications_text = await getHelpText('insurance_certifications')
    this.setState({insurance_certifications_text})    
    let profile_img_text = await getHelpText('profile_img_text')
    this.setState({profile_img_text})
    let acc_delete_text = await getHelpText('acc_delete_text')
    this.setState({acc_delete_text})
    let short_bio_text = await getHelpText('profile_short_bio')
    this.setState({short_bio_text})
    let hourly_rate_text = await getHelpText('hourly_rate_text')
    this.setState({hourly_rate_text})
    let profile_back_button_text = await getHelpText('profile_back_button_text')
    this.setState({profile_back_button_text})
    let coach_remove_acount = await getHelpText('coach_remove_acount')
    this.setState({coach_remove_acount})
    let coach_city_text = await getHelpText('coach_city_text')
    this.setState({coach_city_text})
    let membership_helptext = await getHelpText('membership_helptext')
    this.setState({membership_helptext})
    
    let rates = await curencyRates()
    this.setState({rates})
    this.profileUpdateStatus();
    this.fetchUserName();
    this.fetchCoachData();
    this.getBioData();
    this.getGeoInfo();
  }

  getGeoInfo = () => {
    axios.get('https://ipapi.co/json/').then((response) => {
        let data = response.data;
        this.setState({
            countryName: data.country,
        });
        console.log("country code", data.country)
    }).catch((error) => {
        console.log(error);
    });
};

//   componentDidUpdate(prevProps) {
//     if(prevProps.pdata !== this.props.pdata) {
//       this.setState({
//             SocialMediaInfo:this.props.pdata.SocialMediaInfo,
           
//       });

//     }
//   }

  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };

  handleCheck = () => {
    if (this.state.check === 1) {
      this.handleClose();
      this.setState({check:0})
    }
  };

  handleCheckbox = () => {
    if(this.state.check === 0) {
      this.setState({ check: 1 });
    } else {
      this.setState({ check: 0 });
    }
  };
  handleBack = () => {
    this.setState({ check: 1 });
  };

  handleCheckboxMembership = (e) => {
    if (this.state.membership == 0) {
      this.setState({ membership: 1 });
    } else if (this.state.membership == 1) {
      this.setState({ membership: 0 });
    }
  };

  handleCheckboxCity = (e) => {
    if (this.state.city_public == 0) {
      this.setState({ city_public: 1 });
    } else if (this.state.city_public == 1) {
      this.setState({ city_public: 0 });
    }
  };


  onCero = async (e) => {
    if (e.key === "Delete" || e.key === "Backspace") {
      await this.setState({ canwrite: true });
    } else {
      await this.setState({ canwrite: false });
    }
  };

  handleAddressChange = (address) => {
    this.setState({ address });

    if(address!='' && address!=null)
    {
      
    }
    else
    {
       //validate
       let {FromDataError} = this.state
       FromDataError['country'] = 'This field is required'
       this.setState({FromDataError})

       //validate

    }
  };
  handleSelect = async (address) => {
    this.setState({ address, stateAddress: "", city: "" });
    var that = this;
    await geocodeByAddress(address)
      .then((results) => {
        console.log("RESULT FO GEOCODEBYADDRESS", results)
        _.map(results[0].address_components, async (eachComp) => {
          //console.log("@@@@@@@@@@@@ upma @@@@@@@@@@", eachComp)
          if (_.includes(eachComp.types, "country")) {
            const timezones = moment.tz.zonesForCountry(eachComp.short_name, true);
            const timezoneLocal = moment().tz(timezones[0].name).format('Z');
            let tzOptionsFormatted = _.map(timezones, (eachTZ) => {
              const eachTZLocal = moment().tz(eachTZ.name).format('Z');
              return eachTZ.name + " (GMT " + eachTZLocal + ")";
            });

            await that.setState({
              country: eachComp.long_name,
              countryCode: eachComp.short_name === "GB" ? "UK" : eachComp.short_name,
              tzOptions: tzOptionsFormatted,
              timezone: timezones[0].name + " (GMT " + timezoneLocal+ ")",
            },()=>{

              if(eachComp.long_name!='')
              {
                 //validate
                 let {FromDataError} = this.state
                 FromDataError['country'] = ''
                 this.setState({FromDataError})

                 //validate

              }
              
            });
          }
          if (_.includes(eachComp.types, "administrative_area_level_2")) {
            //console.log("EACHCOMP.TYPES",timezones[0].name)
            await that.setState({
              stateAddress: eachComp.long_name,              
            });
          }

          if (_.includes(eachComp.types, "administrative_area_level_1")) {
            //console.log("EACHCOMP.TYPES",timezones[0].name)
            if(this.state.stateAddress === "")
            {
              await that.setState({
                stateAddress: eachComp.long_name,              
              });
            }
          }

          if (_.includes(eachComp.types, "locality") || _.includes(eachComp.types, "postal_town")) {
            console.log("setting city...");
            await that.setState({ city: eachComp.long_name });
          }

          if (_.includes(eachComp.types, "neighborhood")) {
            console.log("setting city...");
            if(this.state.city != "") {
              await that.setState({ city: eachComp.long_name });
            }
          }

          return eachComp;
        });
      })
      .catch((error) => console.error("Error", error));

    // this.handleAddressSelected(
    //   address,
    //   this.state.country,
    //   this.state.city,
    //   this.state.timezone
    // );
  };
  handleAddressSelected = (address, country, city, timezone) => {
    this.setState({ country: country, city: city, address: address, timezone: timezone });
  };

  handleLanguageChange = (e, option) => {
    if (option.removedValue && option.removedValue.isFixed) return;
    this.handleLanguageChangeF(e);
  };

  handleLanguageChangeF = (language) => {
    console.log('language',language)
    let {FromDataError} = this.state
    this.setState({ language: language });

    if(language!=null)
    {
      
      FromDataError['language'] = ''
      this.setState({ FromDataError });
    }
    else
    {
      FromDataError['language'] = 'Please select a language'
      this.setState({ FromDataError });
    }
    
  };

  handleSubmit=(e)=>{
    e.preventDefault()
    this.setState({hideTour:'.tour-SetAvailability'})
    if (this.validateForm(this.state.FromDataError)) {
      this.setState({certificate_name:''})
      let Data=this.state.SocialMediaInfo
      if(this.state.check) {
        this.handleSubmitPersonal(e,Data)
        setTimeout(() => {
        window.scrollTo(0, 0)
        if(localStorage.getItem('tourActive')=='true')
        {
          window.location.replace('/profile-coach')
        }
        }, 3000);
      }
      else {
        //message.warning("Please check this checkbox to proceed");
      }

    }
    else
    {
      console.log('errrrrrr',this.state.FromDataError)
      message.error("Please fill the form correctly");
    }
   
    //this.handleClose()
    
  }

  handleSubmitPersonal = (e, Data) => {
    //console.log("profile submit")
    this.setState({ loader: true });
    console.log("Data-----9", Data)
    e.preventDefault()
    if (this.state.short_bio && this.state.short_bio.split(" ").length > 50) {
      message.warning("Word limit exceeded for short bio");
    this.setState({ loader: false });

    } else {
      const fullName = this.state.fname + " " + this.state.lname
      const addressData = {
        country: this.state.country,
        state: this.state.stateAddress,
        city: this.state.city,
        location: this.state.address,
      };

      const formData = {
        user_title: this.state.user_title,
        //name: this.state.name,
        name: fullName,
        coaching_category: this.state.coachingCategory,
        Uid: this.state.coachprofile_id,

        address: JSON.stringify(addressData),
        short_bio: this.state.short_bio,
        coach_uid: this.state.coach_uid,
        long_bio: this.state.long_bio,
        skills: JSON.stringify(this.state.chartValue),
        language: this.state.language,
        timezone: this.state.timezone,
        HoursofExp: this.state.HoursofExp,
        HourlyRate: this.state.HourlyRate,
        hourly_rate_currency:localStorage.getItem('currency')!=undefined?localStorage.getItem('currency'):'USD',
        membership: this.state.membership,
        phone: this.state.phone,
        tags: this.state.tags,
        SocialMediaInfo: Data !== undefined ? Data : this.state.SocialMediaInfo,
        city_public:this.state.city_public,
        country_code: this.state.countryCode != "" ? this.state.countryCode : this.state.countryName
      }

      axios.post('/users/coachprofile-edit', formData, {
        headers: { 'x-auth': this.token }
      })
        .then(res => {
          if (res.data.success) {
            const rawdata = res.data.profilePost;
            this.setState({
              short_bio: rawdata.short_bio,
              SocialMedia: JSON.parse(rawdata.social_media)
            })

            message.success("Successfully updated profile details");
            this.setState({ loader: false });
            getClientTimezone()

          }
          // this.setState({
          //   short_bio_update_profile: 0,
          // });

          // if (this.state.short_bio.length > 5) {
          //   this.setState({
          //     short_bio_update_profile: 20,
          //   });
          // }

          this.setState({
            user_basic_update_profile: 0,
          });
          
          // console.log('HoursofExp',this.state.address)
          if (this.state.HourlyRate > 0 && this.state.HoursofExp!='' &&
          this.state.address!=undefined &&
            this.state.address.length > 0 &&
            this.state.avatar_path != avatar &&
            this.state.short_bio.length > 2) {
              //console.log("```````````````i am here````````````````")
            this.setState({
              user_basic_update_profile: 10,
            });
          }
        
          this.fetchUserName();
          this.profileUpdateStatus();
          console.log("---------personal--res------", res)
        })
        .catch((error) => {
          console.log(error);
          message.error("Error in updating details");
          this.setState({ loader: false });
        });
    }
  }

  //_______ Certificate upload & view & delete _________

  handleCertificatechange=(e)=>{
    e.persist()
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  onChangeHandler = (e) => {
    e.preventDefault()
    let inputType = e.target.files[0] != undefined ? e.target.files[0].type.split('/'): ''
    let filename = e.target.files[0] != undefined ? e.target.files[0].name.split('.'): ''
    let ext = filename !='' ? filename.pop().toLowerCase(): ''
    if(inputType[0] != undefined && (inputType[0] == 'image' || inputType[0] == 'application') && (ext == 'pdf'  || ext == 'jpeg' || ext == 'jpg' || ext == 'png')){
      if(e.target.files[0].size>4000000)
      {
        message.error('Please select a file upto 4MB')
      }
      else
      {
        this.setState({
          certificate_path: e.target.files[0],
          loaded: 0,
          certificate_name:e.target.files[0].name
        });
        console.log(e.target.files[0]);

      }
    } else {
      message.error("Please select correct format type upto 4 MB.")
    }
   
  };

  getBioData() {
    const id=this.userdata.uid
    axios
      .get(`/users/certificate/${id}`, {
        headers: { "x-auth": this.token },
      })
      .then((res) => {
        console.log("certificate", res);
        if (res.data.msg === "success") {
          const data = res.data.value;
          this.setState({ certificates: data });
          // console.log("~~~~~~~~~~~~~~~certificate~~~~~~~~~~~~~~~~",this.state.certificates.length > 0)
          this.setState({
            user_certificate_update_profile:0,
        });
          if (this.state.certificates.length > 0) {
            this.setState({
                user_certificate_update_profile:20,
            });
          }
          this.profileUpdateStatus();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onClickHandler = (e) => {
    let messageHide= message.info('Please wait...')
    this.setState({ loader: true });
    e.preventDefault();
    let formData = new FormData();
    formData.append("certificate_path", this.state.certificate_path);
    formData.append("CertificateType", this.state.CertificateType);

    console.log("certificate --formdata",formData)
    axios
      .post("/users/certificate", formData, {
        headers: { "x-auth": this.token },
      })
      .then((res) => {
        messageHide()
        if (res.data.success) {
          // toast("Successfully added file", { type: "success" });
          message.success("Successfully added file");
          this.getBioData();
          this.setState({ loader: false });
          this.setState({certificate_name:''})
        } else {
          this.setState({ loader: false });
          message.error("Certificate Upload failed");
        }
      })
      .catch((err) => {
        messageHide()
        console.log(err);
        message.error("Some error occured. Please try again later.");
        this.setState({ loader: false });
      });
  };

  handleDeleteCertificate = (id) => {
    this.setState({ loader: true });
    console.log("clicked certi--", id);
    axios
      .delete(`/users/certificate/${id}`, {
        headers: { "x-auth": this.token },
      })
      .then((res) => {
        if (res.data.success) {
          message.success("Successfully deleted file");
          this.setState({ loader: false });
        } else {
          message.error("Certificate delete failed");
          this.setState({ loader: false });
        }
        this.getBioData();
      })
      .catch((err) => {
        console.log(err);
        message.error("Some error occured. Please try again later.");
        this.setState({ loader: false });
      });
  };

  //_______ Profile Image upload _________

  // onChangeHandler = (e) => {
  //     console.log("I am here")
  //   this.setState({
  //     avatar_path_temp: e.target.files[0],
  //     loaded: 0,
  //   });
  // };

  handleSubmitProfileImage = (e) => {

    e.preventDefault();
    let inputType = e.target.files[0] != undefined ? e.target.files[0].type.split('/'): ''
    let filename = e.target.files[0] != undefined ? e.target.files[0].name.split('.'): ''
    let ext = filename !='' ? filename.pop().toLowerCase(): ''
    if(inputType[0] != undefined && inputType[0] == 'image' && (ext == 'jpeg' || ext == 'jpg' || ext == 'png')) {
      if(e.target.files[0].size>4000000)
    {
      message.error('Please select file upto 4MB size.')
    }
    else
    {
      
      let messageHide= message.info('Please wait...')
    
      console.log("I am here",e.target.files[0])
    this.setState({ loader: true });
   
    const formData = new FormData();
    formData.append("avatar_path", e.target.files[0]);

    let param;
    if (this.state.coachprofile_id !== "") {
      formData["uid"] = this.state.coachprofile_id;
      param = {
        url: "/users/profileimage",
        method: "PUT",
        data: formData,
        headers: {
          "X-Auth": this.token, //this.props.location.state.token,
          "Content-type": "multipart/form-data",
        },
      };
    } else {
      param = {
        url: "/users/profileimage",
        method: "POST",
        data: formData,
        headers: {
          "X-Auth": this.token, //this.props.location.state.token,
          "Content-type": "multipart/form-data",
        },
      };
    }

        axios(param)
      .then((response) => {
        messageHide()
        if (response.data.success) {
          this.setState({
            avatar_path: response.data.profileImagePost.avatar_path,
            loader: false,
            avatar_path_temp: null,
            //avatar_path_update_profile: 20,
          });
          message.success("Image updated");
          //this.profileUpdateStatus();
          this.fetchCoachData();
        }
      })
      .catch((error) => {
        messageHide()
        console.log("photo update error");
        console.log(error);
        message.error("Error in updaing profile image");
        this.setState({
          loader: false,
        });
      });
    }
    } else {
      message.error('Please select correct file format upto 4 MB size')
    }
  };

  handleInputChange = (e, index,state) => {

     console.log('eeee',state)
    const { name, value } = e.target;
    const list = [...state];
    let Err = [...this.state.SocialMediaInfoErr];
    let FromDataError = this.state.FromDataError;

    console.log('condition',name=='SocialMedia' && value=='0')
    console.log('condition2',Err)

    if(name=='SocialMedia' && value=='0')
    {
      FromDataError.social='false'

      this.setState({FromDataError})
      Err[index][`${name}Err`] ='This field is required'
      this.setState({SocialMediaInfoErr:Err},()=>{
        console.log(this.state.SocialMediaInfoErr)
      })

    }
    else if(name=='URL' && value=='')
    {
      FromDataError.social='false'

      this.setState({FromDataError})
      Err[index][`${name}Err`] ='This field is required'
      this.setState({SocialMediaInfoErr:Err})

    }else if(name=='SocialMedia' && value!='0' && list[index].URL=='')
    {
      FromDataError.social='false'

      this.setState({FromDataError})
      Err[index][`URLErr`] ='This field is required'
      Err[index][`SocialMediaErr`] =''
      this.setState({SocialMediaInfoErr:Err})

    }else if(name=='URL' && value!='' && list[index].SocialMedia=='0')
    {
      FromDataError.social='false'
      this.setState({FromDataError})
      Err[index][`SocialMediaErr`] ='This field is required'
      Err[index][`URLErr`] =''
      this.setState({SocialMediaInfoErr:Err})

    }
    else
    {
     
      Err[index] ={ SocialMediaErr: "", URLErr: "" }
      this.setState({SocialMediaInfoErr:Err},()=>{
        this.fromdataERR()
      })
      
    }
    console.log('condition3',Err)


    list[index][name] = value;
    this.setState({SocialMediaInfo: list });
  
  };

  fromdataERR=()=>
  {
    let FromDataError = this.state.FromDataError
    let Err = [...this.state.SocialMediaInfoErr];
    let social=''

    Err.map((data)=>{

      if(data.SocialMediaErr!='' ||  data.SocialMediaErr!='')
      {
        social='false'
      }

    })
    FromDataError.social=social
    this.setState({FromDataError},()=>{
      console.log('FromDataError',FromDataError)
    })




  }

  handleChange = (e) => {
    e.persist();
    if (e.target.name === "short_bio") {
      let limit = 50 - parseInt(e.target.value.split(" ").length);
      let ro = e.target.value.split(" ").length > 50 ? true : false;
      let msg =
        e.target.value.split(" ").length > 50
          ? "Short bio word limit exceeded"
          : "";
      this.setState({ wordcount: limit, alertMsg: msg, readonly: ro });
    }

    var valid_obj = {
      value: e.target.value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg")
    }


    let {FromDataError} =this.state


    validation(valid_obj).then((error) => {


      console.log('aaaaaaaaaaa', error)
      FromDataError[e.target.name] = error
      this.setState({ FromDataError })

      // setTimeout(() => {
      //   this.setState({
      //     [e.target.name]: e.target.value,
      //   });

      // }, 100);


    })




    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleCategoryChange = (data) => {
    let {FromDataError} =this.state
    // console.log('coachingCategory',data)
    
      this.setState({ coachingCategory: data });
    
    if(data!=null)
    {
      
      FromDataError['coachingCategory'] = ''
      this.setState({ FromDataError });
    }
    else
    {
      FromDataError['coachingCategory'] = 'Please select coaching category'
      this.setState({ FromDataError });
    }
    
  };

  handleChangephone = (e, data, event, formattedValue) => {
    //console.log("dialCode",e,data, event,e.slice(data.dialCode.length))
    console.log("phone input value", e)
   let FromDataError = this.state.FromDataError
   
  //  let totalLength=e.slice(data.dialCode.length)
  //  console.log('phone no',e.length,totalLength)
  //  let totalLength=data.format.replace(" ","")
  //  totalLength=totalLength.replace("-","")
  //  totalLength=totalLength.replace("+","")
  //  totalLength=totalLength.split(" ").join("")
  //  console.log('jjjgjg',totalLength,totalLength.length)
    if((e.length - data.dialCode.length) >= 7)
    {
      console.log('true')
      FromDataError.phone=''
    }else{
      console.log('false')
      FromDataError.phone='Enter valid phone number'
    }
  
    this.setState({
      FromDataError
    });
    
  
    
     setTimeout(() => {
       this.setState({
         ['phone']: e,
       });
       
     }, 100);
     console.log("phone input value state", this.state.phone)
     
    
  };

  //____________________________Fetch data_______________________________

  fetchUserName() {
    let self = this;
    let param = {
      url: "/users/username",
      method: "GET",
      headers: {
        "X-Auth": this.token,
      },
    };
    axios(param)
      .then(async (res) => {
        if (res.data.value) {
          const rawdata = res.data.value;
          const fullname = rawdata.name.split(" ")
          const fname = fullname[0]!=undefined? fullname[0] : ''
          fullname.shift();
          const lname = fullname[0]!=undefined? fullname.join(" ") : ''

          self.setState({
            fname: fname, 
            lname: lname,
            // coaching_category: rawdata.coaching_category,
            user_title: rawdata.user_title,
            coachingCategory: rawdata.coaching_category
              ? JSON.parse(rawdata.coaching_category)
              : "",
          },()=>{
                             //validating
                            
              
                             let {FromDataError} = this.state
                             FromDataError.fname = (this.state.fname!='' && this.state.fname!=undefined)? '' : 'false'
                             FromDataError.lname = (this.state.lname!='' && this.state.lname!=undefined)? '' : 'false'
                             FromDataError.coachingCategory = (this.state.coachingCategory!='' && this.state.coachingCategory!=undefined)? '' : 'false'
                             FromDataError.user_title = (this.state.user_title!='' && this.state.user_title!=undefined)? '' : 'false'
                            
                             this.setState({FromDataError})
             
                         //validating
          });
          this.setState({
            user_basicone_update_profile: 0,
          });
          // console.log("~~~~~~~~~~~~~~~title~~~~~~~~~~~~~~~~",this.state.user_title)
            
          //   console.log("~~~~~~~~~~~~~~~coachingcategory~~~~~~~~~~~~~~~~",this.state.coachingCategory)
            
          //   console.log("~~~~~~~~~~~~~~~fname~~~~~~~~~~~~~~~~",this.state.fname)
            
          //   console.log("~~~~~~~~~~~~~~~lname~~~~~~~~~~~~~~~~",this.state.lname)

          if (this.state.user_title != null && 
            this.state.coachingCategory != null &&
            this.state.fname.length > 0 && this.state.lname.length > 0) {
            this.setState({
              //user_title_update_profile: 20,
                user_basicone_update_profile:10,
            });
          }
          this.profileUpdateStatus();
        }
      })
      .catch((error) => {
        console.log(error);
        message.error("Error getting User data");
      });
  }

  fetchCoachData() {
    this.setState({loader: true});
    const id = this.userdata.uid;
    //console.log("I AM HERE HERE HERE HERE", id)

    let param = {
      url: `/users/coachprofile/${id}`,
      method: "GET",
      headers: {
        "X-Auth": this.token
      }
    }
    axios(param)
      .then(async (res) => {
        console.log("-------------------------------------------res-socail-------------", res.data.value)
        if (res.data.value) {
          const rawdata = res.data.value;
          // const rawaddress = rawdata.address ? JSON.parse(rawdata.address) : "";
          this.setState({email:rawdata.email,phone:rawdata.phone_number},()=>{
                //validating
                  let {FromDataError} = this.state
                  FromDataError.phone = (this.state.phone!='' && this.state.phone!=undefined)? '' : 'false'
                  FromDataError.language = (rawdata.language!='' && rawdata.language!=undefined && rawdata.language!=null)? '' : 'false'
                  
                  this.setState({FromDataError})
                //validating
          })
          const rawskills = rawdata.skills ? JSON.parse(rawdata.skills) : "";
          this.setState({ chartValue: rawskills });

          if (rawskills.length > 1) {
            await this.setState({
              chartValue: rawskills,
              isLoaded: true,
            });
          } else {
            this.setState({ isLoaded: false });
          }
          this.updateStateData(rawdata);
          this.setState({loader: false})

          if (this.state) {
            // if (this.state.avatar_path !== avatar) {
            //   this.setState({ avatar_path_update_profile: 20 });
            // }
            // if (this.state.short_bio.length > 2) {
            //   this.setState({ short_bio_update_profile: 20 });
            // }
            // console.log("~~~~~~~~~~~~~~~~~~~~PROFILE BASIC DETAILS VALUE~~~~~~~~~~~~~~~~~~~~~", this.state.user_title,
            // this.state.coachingCategory,
            // this.state.fname,
            // this.state.lname,
            // this.state.HourlyRate,
            // this.state.HoursofExp,
            // this.state.city,
            // this.state.avatar_path,
            // this.state.short_bio)
            
            // console.log("~~~~~~~~~~~~~~~hourlyrate~~~~~~~~~~~~~~~~",this.state.HourlyRate> 0)
            
            // console.log("~~~~~~~~~~~~~~~exp~~~~~~~~~~~~~~~~",this.state.HoursofExp)
            
            // console.log("~~~~~~~~~~~~~~~city~~~~~~~~~~~~~~~~",this.state.address)
            
            // console.log("~~~~~~~~~~~~~~~avatar path~~~~~~~~~~~~~~~~",this.state.avatar_path)
            
            // console.log("~~~~~~~~~~~~~~~shortbio~~~~~~~~~~~~~~~~",this.state.short_bio)
            this.setState({
              user_basic_update_profile: 0,
            });

            if (this.state.HourlyRate > 0 && this.state.HoursofExp.length > 0 &&
                this.state.address.length > 0 &&
                this.state.avatar_path != avatar &&
                this.state.short_bio.length > 2) {
                  console.log("```````````````i am here````````````````")
                this.setState({
                  user_basic_update_profile: 10,
                });
              }

            // if (this.state.short_bio.length > 2) {
            //   this.setState({ packages_update_profile: 20 });
            // }

            // if (this.state.tags.length > 2) { //******************** */
            //   this.setState({ tag_update_profile: 20 });
            // }
          }

          this.profileUpdateStatus();
          //this.dountChartUpdated();
        } else {
          this.setState({loader: false})
        }
      })
      .catch((error) => {
        console.log("fetch error");
        console.log(error);
        this.setState({loader: false});
        // toast("Error getting profile data", { type: "error" });
      });
  }

  updateStateData = async (rawdata) => {
    const rawaddress = rawdata.address ? JSON.parse(rawdata.address) : "";
    // const rawskills = rawdata.skills ? JSON.parse(rawdata.skills) : "";

    await this.setState({
      userImage: rawdata.avatar_path,
      coach_uid: rawdata.coach_uid,
      short_bio: rawdata.short_bio,
      // long_bio: rawdata.long_bio,
      long_bio:
        rawdata.long_bio !== undefined ? rawdata.long_bio : "loading...",

      videofile: rawdata.profile_video,
      avatar_path: rawdata.avatar_path ? rawdata.avatar_path : avatar,
      country: rawaddress.country,
      address: rawaddress.location,
      stateAddress: rawaddress.state,
      city: rawaddress.city,
      coachprofile_id: rawdata.uid,
      tags: rawdata.tags,
      skills: rawdata.skills ? JSON.parse(rawdata.skills) : "",
      // tag_update_profile: 0,
      // avatar_path_update_profile: 0,
      // short_bio_update_profile: 0,
      // packages_update_profile: 0,

      language: rawdata.language ? JSON.parse(rawdata.language) : "",
      timezone: rawdata.timezone,
      HoursofExp: rawdata.hours_of_experience,
      HourlyRate: curencyFilter(`${rawdata.hourly_rate_currency}##${rawdata.hourly_rate}`,'',this.state.rates).split(localStorage.getItem('currency')=='USD' || localStorage.getItem('currency')==undefined ?'$':localStorage.getItem('currency')=='GBP'?'£':'€')[1],
      membership: rawdata.has_membership,
      city_public: rawdata.city_public,
      wordcount:
        rawdata.short_bio && 50 - parseInt(rawdata.short_bio.split(" ").length),
      SocialMediaInfo: rawdata.social_media !== null ? (JSON.parse(rawdata.social_media)) : ([{ SocialMedia: "", URL: "" }])
    },()=>{
      //validating
      let {FromDataError} = this.state
      FromDataError.country = (this.state.country!='' && this.state.country!=undefined)? '' : 'false'
      
      this.setState({FromDataError})
    //validating
    });
    let SocialMediaInfoErr=[]
 
    if(rawdata.social_media!=null)
    {
      JSON.parse(rawdata.social_media).map((a)=>{
        SocialMediaInfoErr.push({ SocialMediaErr: "", URLErr: "" })
      })
      this.setState({SocialMediaInfoErr},()=>{
        console.log('echo',SocialMediaInfoErr)
      })

    }
   
  };

  profileUpdateStatus() {
    //this.setState({ totalprofileupdate: 0 });
    // console.log("````````````````Profie update status`````````````````````", this.state.user_certificate_update_profile)
    // console.log("````````````````basic second part```````````````````````", this.state.user_basic_update_profile)
    // console.log("````````````````basic first part`````````````````````", this.state.user_basicone_update_profile)
    const totalProfile =
      //parseInt(this.state.avatar_path_update_profile) +
      parseInt(this.state.user_basic_update_profile) +
      parseInt(this.state.user_basicone_update_profile) + 
      parseInt(this.state.user_certificate_update_profile)
      //parseInt(this.state.user_social_update_profile) +
      
      //parseInt(this.state.short_bio_update_profile) +
      //parseInt(this.state.tag_update_profile) +
      //parseInt(this.state.packages_update_profile);
      console.log("TOTAL PROFILE COMPLETE", totalProfile)
    this.setState({ totalprofileupdate: totalProfile });
    this.totalProfileUpdateStatus()
  }

  totalProfileUpdateStatus() {
    const id = this.userdata.uid;
    let formData = {percentData: this.state.totalprofileupdate}

    let param = {
      url: `/users/complete-status/${id}`,
      method: "POST",
      data: formData,
      headers: {
        "X-Auth": this.token
      }
    }
    axios(param)
    .then((response) => {
      //toast("Coaching style added successfully", { type: "success" });
      console.log("response")
    })
    .catch((error) => {
      console.log(error);
      //toast("Error in updating", { type: "error" });
    });
  }

  handleClick = (e) => {
    //console.log("Yes clicked", this.token)

    //e.preventDefault()
    axios.post(`/users/requestdeleteaccount`,{amount: "hello"},{
      headers:{"x-auth":this.token}
    }).then((res) => {
      console.log("### response",res)
      if(res.data.success) {
        localStorage.clear();
        this.setState({redirect: true})
        // this.props.history.push({
        //   pathname: "/login",
        //   token: "",
        // });
        message.success("Your request for removing account is in progress",5)
      }
    }).catch(() => {
      message.error("Some error occured")
    })
    
  }

  handleStateCity = async (id, newValue) => {
    console.log(`${id} changed to ${newValue}`);
    if(id == "state-input"){
      await this.setState({
        stateAddress: newValue
    });
    }
    else if(id == "city-input"){
      await this.setState({
        city: newValue
    });
    }
    console.log("state value", this.state.stateAddress)
    console.log("city value", this.state.city)
  }


  handleDeleteprofilePic = (e) => {
    //console.log("Yes clicked", this.token)
    let imageUrl = this.state.avatar_path

    //e.preventDefault()
    axios.post(`/users/delete-profilepic`,{imageUrl},{
      headers:{"x-auth":this.token}
    }).then((res) => {
      console.log("### response",res)
      if(res.data.success) {
      this.fetchCoachData()
      message.success("Your profile image deleted successfully");
      }
      else {
        message.error("Error while deleteing profile image")
      }
    }).catch((err) => {
      message.error("Some error occured")
    })
    
  }



  render() {
    //console.log("this.props.pdata.name-------",this.state.SocialMediaInfo)

    const Categoryoptions = [
      { value: 'Career Coaching', label: 'Career Coaching'},
      { value: 'Executive Coaching', label: 'Executive Coaching' },
      { value: 'Business Coaching', label: 'Business Coaching'  },
      { value: 'Life Coaching', label: 'Life Coaching'},
      { value: 'Transformational Coaching', label: 'Transformational Coaching' },
      { value: 'Health & Wellbeing Coaching', label: 'Health & Wellbeing Coaching'}
    ];

    const options = [
      { value: "English", label: "English", isFixed: true },
      { value: "Spanish", label: "Spanish", isFixed: false },
      { value: "French", label: "French", isFixed: false },
      { value: "Portuguese", label: "Portuguese", isFixed: false },
      { value: "Hindi", label: "Hindi", isFixed: false },
      { value: "Russian", label: "Russian", isFixed: false },
      { value: "German", label: "German", isFixed: false },
      { value: "Chinese", label: "Chinese", isFixed: false },
      { value: "Japanese", label: "Japanese", isFixed: false },
    ];
    // const { language } = this.props.pdata;
    const styles = {
      multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
      },
      multiValueLabel: (base, state) => {
        return state.data.isFixed
          ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
          : base;
      },
      multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: "none" } : base;
      },
      control: (base) => {
        return {
          ...base,
          background: "#fff"
        };
      },
    };
    const pdata = this.state;
    const { redirect } = this.state;

     if (redirect) {
       return <Redirect to='/login'/>;
     }
    //console.log("....................address..............", pdata.address)
    return (
      <React.Fragment>
         <TourSite 
         hideTour={this.state.hideTour}
         handaleLoader={this.handaleLoader}
        //  page="coach-profile-edit"
         />
            {localStorage.getItem("authToken") == null ? (
            <HeaderTop />
            ) : (
            <HeaderLogout
            currencies={this.state.currencies}
            convertToCurrency={this.state.convertToCurrency}
            changeConvertToCurrency={this.changeConvertToCurrency}
            />
            )}

            <Header />        
          <section className="coachEditWrap">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8 col-lg-7">
                {/* <MyLoader active={this.state.loaderforprofileimage}/> */}
                <LoadingOverlay        
                  active={this.state.loader}
                  // spinner={<BounceLoader />}
                  spinner={<div><CustomImageLoader image={LGspinner} animationType={'pulse'}/><br/></div>}
                  text='Loading your profile...'
                >
                  <div className="coachProfileFormEdit">
                    <form onSubmit={this.handleSubmit} >
                     <div className="row">
                      
                      <div className="row tour-public-profile">
                      <div className="col-12">
                        <div className="coachImg mb-4">
                          <img id="item-img-output" src={this.state.avatar_path} className="img-fluid"/>
                          <div className="picEdit">
                            {/* <a href="javascript:void(0);" data-toggle="tooltip" data-placement="top" title="Edit your Picture">
                              <img  width="35px" src={photograph} alt="LG-Img"/>
                              <input type="file" className="item-img file center-block" name="file_photo"/>
                            </a> */}
                            {/* <UpdateImage
                            avatar={this.state.avatar}
                            userImage2={this.state.avatar_path}
                            onChangeHandler={this.onChangeHandler}
                            handleSubmitProfileImage={this.handleSubmitProfileImage}/> */}
                             {/* <span className="addTag" style={{cursor: 'pointer'}}><Tooltip title={this.state.profile_img_text}  onClick={this.handleShow} placement="right" ><img src={photograph} width="25px"/></Tooltip></span> */}
                            <span  style={{cursor: 'pointer'}}><Tooltip title={this.state.profile_img_text} placement="bottom"> <img width="35px" alt="LG-Img" src={photograph} />
                            <input
                            className="item-img file center-block"
                            type="file"
                            label="Select Image file"
                            name="avatar_path"
                            onChange={this.handleSubmitProfileImage}
                            accept=".jpg,.jpeg,.png"
                            />
                            </Tooltip>
                             
                            </span>
                           
                            
                            {this.state.userImage !=null ? (
                            <ConfirmModalProfilepic
                              fun_name={"Delete"}
                              handleDeleteprofilePic={this.handleDeleteprofilePic}
                              infoText="Are you sure you want to delete your profile image?"
                              //helpText={this.state.coach_remove_acount}
                              />
                              ) : ''}

                          </div>
                        </div>
                      </div>
          
                      <div className="col-12">
                        <h4>1. Public Profile Information
            <Tooltip title={this.state.public_profile_text} placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>

                        </h4>
                        <hr/>
                      </div>
                     
                            {/* first name section */}
                      <div className="col-md-6 ">
                        <div className="form-group">
                          <label>First Name</label>
                        <input type="text" 
                        style={{color:"black"}}
                        name="fname" 
                        defaultValue={this.state.fname}
                        onChange={this.handleChange} 
                        placeholder="First name" 
                        className="form-control"
                        validaterule={['required']}
                        validatemsg={['Enter your First Name']}
                        />
                       
                        <p style={{ color: 'red' }}>{this.state.FromDataError.fname == 'false' ? '' : this.state.FromDataError.fname}</p>
                        </div>
                      </div>
                            {/* last name section */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Last Name</label>
                        <input type="text" 
                        style={{color:"black"}}
                        name="lname" 
                        defaultValue={this.state.lname}
                        onChange={this.handleChange} 
                        placeholder="Last name" 
                        className="form-control"
                        validaterule={['required']}
                        validatemsg={['Enter your Last Name']}
                        />
                        <p style={{ color: 'red' }}>{this.state.FromDataError.lname == 'false' ? '' : this.state.FromDataError.lname}</p>

                        </div>
                      </div>
          
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Coach Title</label>
                        <input type="text"
                          style={{color:"black"}}
                          placeholder="Add a title"
                          value={this.state.user_title}
                          onChange={this.handleChange}
                          name="user_title" 
                          className="form-control"
                          validaterule={['required']}
                          validatemsg={['Enter your Coach Title']}
                          />
                        <p style={{ color: 'red' }}>{this.state.FromDataError.user_title == 'false' ? '' : this.state.FromDataError.user_title}</p>

                        </div>
                      </div>
          
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Select coach categories</label>
                          <Select  className="languageSelect" placeholder='Select Category' isMulti 
                          value={this.state.coachingCategory} 
                          onChange={this.handleCategoryChange} 
                          options={Categoryoptions}
                          styles={styles} 
                          validaterule={['required']}
                          validatemsg={['Enter your Coach Title']}
                          />
                        <p style={{ color: 'red' }}>{this.state.FromDataError.coachingCategory == 'false' ? '' : this.state.FromDataError.coachingCategory}</p>

                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label>Last Name</label>
                        <input type="text" name="" value="Holmes" placeholder="Last Name" className="form-control"/>
                        </div>
                      </div> 
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Email</label>
                        <input type="email" name="" value="example@example.com" placeholder="Email" className="form-control"/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Password</label>
                        <input type="password" name="" value="*********" placeholder="Password" className="form-control"/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Phone No</label>
                        <input type="tel" name="" value="477-046-1827" placeholder="Phone No" className="form-control"/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Year of Birth</label>
                          <div className="selectBox searchFilter">
                            <select className="selectpicker"  data-live-search="true">
                              <option>Nothing Selected</option>
                              <option>2020</option>
                              <option>2019</option>
                              <option>2018</option>
                              <option>2017</option>
                              <option>2016</option>
                              <option>2015</option>
                              <option>2014</option>
                              <option>2013</option>
                              <option>2012</option>
                              <option>2011</option>
                              <option>2010</option>
                              <option>2009</option>
                              <option>2008</option>
                              <option>2007</option>
                              <option>2006</option>
                            </select>
                            <span>
                                <img src="images/dArrowblack.png" alt=""/>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                        <label>Gender</label>
                        <div className="selectBox searchFilter">
                            <select className="selectpicker"  data-live-search="true">
                              <option>Nothing Selected</option>
                              <option>Male</option>
                              <option>Female</option>
                              <option>Prefer not to answer</option>
                            </select>
                            <span>
                                <img src="images/dArrowblack.png" alt=""/>
                            </span>
                          </div>
                        </div>
                      </div> */}
                      </div>
                      <div className="row tour-profile-hourlyrate">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Language</label>
                          <div className="selectBox">
                          <Select
                            className="languageSelect"
                            placeholder="Select language"
                            isMulti
                            value={pdata.language}
                            onChange={this.handleLanguageChange}
                            isClearable={!this.state.options.some((opt) => opt.isFixed)}
                            options={options}
                            styles={styles}
                          />
                        <p style={{ color: 'red' }}>{this.state.FromDataError.language == 'false' ? '' : this.state.FromDataError.language}</p>

                            </div>
                          </div>
                      </div>
                  
                  
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Short Introductory Bio (Word limit {pdata.wordcount} ) <Tooltip title={this.state.short_bio_text} placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip></label>
                          <label style={{ color: "red" }}>
                            {this.state.alertMsg}
                          </label>
                          <Form.Control
                            style={{color:"black"}}
                            as="textarea"
                            rows="6"
                            value={pdata.short_bio}
                            onChange={this.handleChange}
                            name="short_bio"
                            // readOnly={pdata.readonly}
                            onKeyDown={(e) => this.onCero(e)}
                            // readOnly={
                            //   this.state.canwrite
                            //     ? false
                            //     : pdata.short_bio && pdata.short_bio.split(" ").length > 50
                            //     ? true
                            //     : false
                            // }
                          />
                          </div>
                      </div>
                      
                  
                      <div className="col-md-12">
                        <div className="form-group">
                          <label style={{ marginRight: "1rem" }}>
                            Membership of Professional Organizations <Tooltip title={this.state.membership_helptext} placement="top"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip></label>
                          <input
                              type="checkbox"
                              value={this.state.membership}
                              checked={this.state.membership == 1 ? true:false}
                              onChange={this.handleCheckboxMembership}
                              
                            />
                            
                          </div>
                      </div>
                
                      {/* <div className="col-md-6">
                        <div className="form-group">
                          <label>Hours of coaching experience </label>
                          <div className="selectBox searchFilter">
                          <select
                            style={{color:"black"}}
                            className="form-control"
                            value={pdata.HoursofExp}
                            onChange={this.handleChange}
                            name="HoursofExp"
                          >
                            <option
                              defaultValue={pdata.HoursofExp ? pdata.HoursofExp : 0}
                            >
                              {pdata.HoursofExp ? pdata.HoursofExp : 0}
                            </option>
                            <option value="0-100hrs">0-100hrs</option>
                            <option value="100hrs+">100hrs+</option>
                            <option value="500hrs+">500hrs+</option>
                            <option value="2,500hrs+">2,500hrs+</option>
                          </select>
                            </div>
                          </div>
                      </div>
          
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Hourly Rate ({localStorage.getItem('currency')=='USD' || localStorage.getItem('currency')==undefined ?'$':localStorage.getItem('currency')=='GBP'?'£':'€'}) <Tooltip title={this.state.hourly_rate_text} placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip></label>
                          <Form.Control
                            style={{color:"black"}}
                            type="text"
                            placeholder="Hourly Rate"
                            value={pdata.HourlyRate}
                            onChange={this.handleChange}
                            name="HourlyRate"
                            validaterule={['numeric','notZero']}
                            validatemsg={['This field accept digits only','Please provide value greater than zero']}
                          />
                        <p style={{ color: 'red' }}>{this.state.FromDataError.HourlyRate == 'false' ? '' : this.state.FromDataError.HourlyRate}</p>

                          </div>
                      </div> */}
                           
                </div>
                      






          
          
                      {/* <div className="col-md-12">
                        <div className="form-group">
                          <label>Address</label>
                          <textarea name="" placeholder="" rows="3" type="text" className="form-control">116 Jaskolski Stravenue Suito 883</textarea> 
                        </div>
                      </div> */}
          
          
                  <div className="row tour-profile-address">
                      <div className="col-12">
                        <h4>2. Private information <span>(Coach Master admin use only)</span>
                          <Tooltip title={this.state.private_profile_text} placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>
                        </h4>
                        <hr style={{color: "rgba(0,0,0,.1)"}}/> 
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Email</label>
                        
                          <Form.Control
                          style={{color:"black"}}
                          // placeholder="Add a title"
                          value={this.state.email}
                          // onChange={this.handleChange}
                          readOnly={true}
                          name="email" 
                      />
                      
                          </div>
                      </div>
          
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Phone</label>
                          {/* <Form.Control
                            style={{color:"black"}}
                            type="text"
                            placeholder="Phone No."
                            value={this.state.phone}
                            onChange={this.handleChange}
                            name="phone"
                            validaterule={['required','phone']}
                            validatemsg={['This field accept digits only','Phone no must be between 10 to 15 digit']}
                          /> */}
                          <PhoneInput
                         style={{color:'black'}}
                         name="phone"
                         value={this.state.phone}
                         required={true}
                         //country={this.state.countryName}
                         countryCodeEditable={false}
                         inputclassName="phone-reg"
                         placeholder="Phone Number"
                         onChange={this.handleChangephone}
                         validaterule={['required','phone']}
                         validatemsg={['Phone number is required','Please enter a valid phone number']}
                       />
                        <p style={{ color: 'red' }}>{this.state.FromDataError.phone == 'false' ? '' : this.state.FromDataError.phone}</p>

                          </div>
                      </div>
          

                   





                <div className="col-12">
                        <h4>Address Information <span>(Your address field value will remain private)</span></h4>
                        <hr/>
                      </div>
                      <div className="row ml-0 mr-0">
                      <div className="col-md-12 ">
                      <div className="form-group">
                      <PlacesAutocomplete
                          value={this.state.address}
                          onChange={this.handleAddressChange}
                          onSelect={this.handleSelect}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <Form.Group>
                              <Form.Label>Address</Form.Label>
                              <Form.Control
                                style={{color:"black"}}
                                type="text"
                                {...getInputProps({
                                  placeholder: this.state.address
                                    ? this.state.address
                                    // : pdata.location
                                    // ? pdata.location
                                    : "Search Location ...",
                                  className: "location-search-input",
                                })}
                              />
          
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
          
                                {suggestions.length > 0 && (
                                  <div className="autocomplete-suggestion-box">
                                    {suggestions.map((suggestion,i) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor: "#ccc",
                                            cursor: "pointer",
                                            borderBottom: "1px solid #abacad",
                                            paddingBottom: "2px",
                                          }
                                        : {
                                            cursor: "pointer",
                                            borderBottom: "1px solid #abacad",
                                            paddingBottom: "2px",
                                          };
                                      return (
                                        <div
                                        key={i}
                                          {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                          })}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            </Form.Group>
                          )}
                        </PlacesAutocomplete>
                        <p style={{ color: 'red' }}>{this.state.FromDataError.country == 'false' ? '' : this.state.FromDataError.country}</p>

                        
                      </div>
                      </div>
          
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Country</label>
                          <Form.Control
                              style={{color:"black"}}
                              type="text"
                              placeholder="Country"
                              value={
                                pdata.country
                              }
                              onChange={this.handleChange}
                              // disabled="true"
                              name="country"
                            />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>State/Province/Area</label>
                          {/* <Form.Control
                              style={{color:"black"}}
                              type="text"
                              placeholder="State"
                              value={this.state.stateAddress ? this.state.stateAddress : pdata.stateAddress}
                              name="city"
                              disabled="true"

                            /> */}
                            <MyAutosuggest
                              id="state-input"
                              placeholder="State"
                              handleStateCity={this.handleStateCity}
                              defaultFieldValue={this.state.stateAddress ? this.state.stateAddress : pdata.stateAddress}
                            />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>City <Tooltip title={this.state.coach_city_text} placement="top"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip></label>
                          {/* <span className="ml-2"><input
                              type="checkbox"
                              value={this.state.city_public}
                              checked={this.state.city_public == 1 ? true:false}
                              onClick={this.handleCheckboxCity}
                            /></span> */}
                          {/* <Form.Control
                              style={{color:"black"}}
                              type="text"
                              placeholder="City"
                              value={this.state.city ? this.state.city : pdata.city}
                              name="city"
                              disabled="true"

                            /> */}
                            <MyAutosuggest
                              id="city-input"
                              placeholder="City"
                              handleStateCity={this.handleStateCity}
                              defaultFieldValue={this.state.city ? this.state.city : pdata.city}
                            />
                        </div>
                      </div>
          
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Timezone</label>
                          <div className="selectBox">
                            <select
                              style={{color:"black"}}
                              className="form-control"
                              id="timezone-offset"
                              // style={{ color: "white" }}
                              value={pdata.timezone}
                              onChange={this.handleChange}
                              name="timezone"
                            >
                              <option defaultValue={pdata.timezone ? pdata.timezone : ""}>
                                {pdata.timezone ? pdata.timezone : ""}
                              </option>
          
                              {this.state.tzOptions &&
                                this.state.tzOptions.map((tz,i) => {
                                  return <option key={i} value={tz}>{tz}</option>;
                                })}
                            </select>
                            <span>
                              <img src="images/dArrowblack.png" alt="" />
                            </span>
                          </div>
                        </div>
                      </div>
                      </div>


        </div>







                      <div className="col-12">
                        <h4>Social Media
                        {this.state.SocialMediaInfo.map((x, i) => {
                            return (
                              <>
                                {this.state.SocialMediaInfo.length - 1 === i && (
                                  <button
                                  key={i}
                                    className="defaultBtn"
                                    style={{
                                      color: "green",
                                      fontSize: "15px",
                                      float: "right",
                                    }}
                                   
                                    onClick={() =>{
                                      this.setState(prevState => ({ 
                                        SocialMediaInfo: [...prevState.SocialMediaInfo,{SocialMedia:"",URL: ""}],
                                        SocialMediaInfoErr: [...prevState.SocialMediaInfoErr,{SocialMediaErr:"",URLErr: ""}]
                                          }))
                                    }}
                                  > Add </button>
                                )}
                              </>
                            );
                          })}

                        </h4>
                        <hr/>
                         {/* <label>Social Media</label> */}
                         <div className="availabilityTimeItem"></div>
                      </div>
                        {/* <div id="content"></div> */}
          
                      <div className="col-12">
                        {this.state.SocialMediaInfo.map((x,i) =>(
                         
                         <div className="row" key={i}>
          
                          <div className="col-md-3">
                            <div className="form-group">

                            <select
                              style={{color:"black"}}
                              className="form-control"
                              name="SocialMedia"
                              onChange={(e) => this.handleInputChange(e, i,this.state.SocialMediaInfo)}
                            >
                                <option value="0">--Select--</option>
                                <option selected={x.SocialMedia=='Facebook'?true : false}>Facebook</option>
                                <option selected={x.SocialMedia=='Twitter'?true : false} >Twitter</option>
                                <option selected={x.SocialMedia=='Linkedin'?true : false} >Linkedin</option>
                                <option selected={x.SocialMedia=='Instagram'?true : false} >Instagram</option>
                                <option selected={x.SocialMedia=='Pinterest'?true : false} >Pinterest</option>
                                <option selected={x.SocialMedia=='Youtube'?true : false} >Youtube</option>
                                <option selected={x.SocialMedia=='Vimeo'?true : false} >Vimeo</option>
                                <option selected={x.SocialMedia=='Blog'?true : false} >Blog</option>

                            </select>
                        <p style={{ color: 'red' }}>{this.state.FromDataError.social != '' ?  this.state.SocialMediaInfoErr[i].SocialMediaErr : ''}</p>

                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <input type="text"
                              style={{color:"black"}} 
                              placeholder="Social Media URL"
                              name="URL" 
                              value={x.URL}
                              onChange={(e) => this.handleInputChange(e, i,this.state.SocialMediaInfo)} 
                              className="form-control"/>
                        <p style={{ color: 'red' }}>{this.state.FromDataError.social != '' ? this.state.SocialMediaInfoErr[i].URLErr:''}</p>

                            </div>
                          </div>
                          <div className="col-md-3">
          
                             {this.state.SocialMediaInfo.length > 1 && (
                                  <button
                                    className="defaultBtn"
                                    style={{
                                      color:"green",
                                      fontSize:"15px",
                                      
                                    }}
                                    onClick={(e) =>{
                                      e.preventDefault()
                                     
                                        let SocialMediaInfo = [...this.state.SocialMediaInfo];
                                        console.log()
                                        SocialMediaInfo.splice(i, 1);
                                        this.setState({ SocialMediaInfo });

                                        let SocialMediaInfoErr = [...this.state.SocialMediaInfoErr];
                                        SocialMediaInfoErr.splice(i, 1);
                                        this.setState({ SocialMediaInfoErr },()=>{
                                          this.fromdataERR()
                                        });
                                    }}
                                  >Remove</button>
                                )}             
          
                            </div>
                        </div>
          
                        ))}
                        </div>

                  <div className="row tour-profile-certificates">
                      <div className="col-md-12">
                        <h4>Submit Training & Insurance Certifications 
                      <Tooltip title={this.state.insurance_certifications_text} placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>

                          </h4>
                        <hr style={{color: "rgba(0,0,0,.1)"}}/>
                      </div>

                      <div className="col-12">
                        <div className="editProfileForm text-center">
                        {/* <select
                           style={{color:"black",margin: "auto",
                            display: "block"}}
                 
                             value={this.state.CertificateType}
                             name="CertificateType"
                            placeholder="Enter duration of session"
                            onChange={this.handleCertificatechange}
                            >
                          <option value="">Select Certificate Type</option>
                          <option value="Professional Certificate">Professional Certificate</option>
                          <option value="Training Certificate">Training Certificate</option>
                          <option value="Membership association logos">Membership association logos</option>
                          <option value="Company logos">Company logos</option>
                       </select> */}
                            <div className="avatar">
                              <img src={upload} width="75px"/>
                              <p>Upload certifications & insurance proof</p>
                              {/* <p>Word File, PDF, Image</p> */}
                              {/* <input type="file" name="avatar"/> */}
                              <input
                              className="file-upload"
                              type="file"
                              name="certificate_path"
                              id="image"
                              onChange={this.onChangeHandler}
                              accept="application/msword,application/pdf,image/*"
                            />
                            </div>
                            {this.state.certificate_name!='' && (<>
                              <p>{this.state.certificate_name}</p>
                            <button className="defaultBtn" type="button" onClick={this.onClickHandler}>Upload</button>


                            </>)}
                        </div>

                        <div className="uploadFile">
                          <ul>
                            {this.state.certificates.map((certificate, i) => {
                              return (
                                <li key={i}>
                                  
                                  <a
                                    href={certificate.certificate_path}
                                    // data-toggle="lightbox"
                                    // data-gallery="example-gallery"
                                    // className="boxWrap"
                                    target="_blank"
                                  >
                                    {/* <img src="images/document.svg" width="70px"/> */}
                                    <img
                                      alt="LG-Img"
                                      src={documentSvg}
                                      className="img-fluid"
                                      width="70px"
                                    />
                                  </a>
                                      <Link
                                        className="removeLightbox"
                                        //  href="javascript:void(0)"
                                        to="/profile-coach-edit"
                                        onClick={() => {
                                          this.handleDeleteCertificate(certificate.uid);
                                        }}
                                      >
                                        {/* <img width="2" src={deleteSvg} alt="LG-Img" /> */}
                                        <i className="fa fa-minus-circle" aria-hidden="true"></i>
                                      </Link>

                                  {<b style={{textAlign: "center",color:"black"}}>{certificate.media_type}</b>}
                                  {/* {certificate.status==0 ?(<p style={{textAlign: "center",color:"red"}}>In Review</p>):("")} */}
                                  {certificate.status==0 ?(<p style={{textAlign: "center",color:"red"}}>In Review</p>): certificate.status==2 ? (<p style={{textAlign: "center",color:"red"}}>Rejected</p>) : ""}
                                </li>
                              )
                            })}
                          </ul>
                          {/* {this.state.certificates && this.state.certificates.length > 4 ? (
                            <div className="text-center col-12 mt-4">
                              <button
                                className="defaultBtn"
                                data-toggle="modal"
                                data-target="#CommonModal"
                              >
                                View More
                              </button>
                            </div>
                          ) : (
                            <div></div>
                          )}
                          <ListModal
                            dataSet={this.state.certificates}
                            handleDelete={this.handleDeleteCertificate}
                            viewMore="certificates"
                          /> */}
                        </div>
                      </div>
                  </div>
                  <div className="row tour-profile-submit">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label style={{ marginRight: "1.2rem" }}>
                            <input
                              onInvalid={e => e.target.setCustomValidity("Please tick this box if you want to proceed")}
                              type="checkbox"
                              required
                              checked={this.state.check==1? true : false}
                              value={this.state.check}
                              onChange={this.handleCheckbox}
                            /> I declare the information above is accurate
                            </label>
                          </div>
                      </div>
                      
                  
                      <div className="col-md-12">
                        <hr/>

                        <div className="mt-3 btn_set_left">

                        <button className="defaultBtn mr-2" type="submit">save</button>{" "}
                        <Link to="/profile-coach">
                        <Tooltip title={this.state.profile_back_button_text} placement="right">
                        <button className="defaultBtn" onClick={this.handleBack}>Exit</button></Tooltip>
                        </Link>
                        {" "}
                       
                        <Changepasswordmodal />

                        <ConfirmModal
                          fun_name={"Delete"}
                          icon={"fa fa-trash"}
                          //pack_id={this.props.packagedata.uid}
                          handleClick={this.handleClick}
                          infoText={this.state.acc_delete_text}
                          helpText={this.state.coach_remove_acount}
                        />
                      
                        </div>
                   </div>

                      </div>
                      <div className="col-md-12 mt-2">
                      
                      </div>
          
                     </div>
                   </form>
                  </div>
                  </LoadingOverlay>
                </div>
              </div>
            </div>
          </section>
          
          <FooterLink />
      </React.Fragment>
    );
  }
}

export default UpdatePersonal;
