import React from 'react'
import _ from "lodash";

var Calendar = require('tui-calendar'); /* CommonJS */
require("tui-calendar/dist/tui-calendar.css");

// If you use the default popups, use this.
require("tui-date-picker/dist/tui-date-picker.css");
require("tui-time-picker/dist/tui-time-picker.css");

//import {appendScript} from '../../utils/appendScript'



class UpcomingSessionCalender extends React.Component{
constructor(props) {
  super(props)
  this.calendar = Object
}
componentDidMount () {
    //appendScript("/app.js");
    //let app = require("./app.js");

    this.calendar = new Calendar('#calendar', {
      defaultView: 'month',
      taskView: true,
      useDetailPopup:true,
      isReadOnly:true,
      template: {
        monthDayname: function(dayname) {
          return `<span className="calendar-week-dayname-name">` + dayname.label + `</span>`;
        }
        
      }
    });

    this.calendar.on({
      'clickMore': function(e) {
            console.log('clickMore', e);
        },
        'clickSchedule': function(e) {
            console.log('clickSchedule', e);
        },
        'clickDayname': function(date) {
            console.log('clickDayname', date);
        },
        'beforeCreateSchedule': function(e) {
            console.log('beforeCreateSchedule', e);
            //saveNewSchedule(e);
        },
      'clickSchedule': function(e) {
          console.log('clickSchedule', e);
      },
      'beforeCreateSchedule': function(e) {
          console.log('beforeCreateSchedule', e);
          // open a creation popup

          // If you dont' want to show any popup, just use `e.guide.clearGuideElement()`

          // then close guide element(blue box from dragging or clicking days)
          e.guide.clearGuideElement();
      },
      'beforeUpdateSchedule': function(e) {
          console.log('beforeUpdateSchedule', e);
          e.schedule.start = e.start;
          e.schedule.end = e.end;
          this.calendar.updateSchedule(e.schedule.id, e.schedule.calendarId, e.schedule);
      },
      'beforeDeleteSchedule': function(e) {
          console.log('beforeDeleteSchedule', e);
          this.calendar.deleteSchedule(e.schedule.id, e.schedule.calendarId);
      }
  });

  
  //console.log(this.props.acceptedBooking)
    
}  


componentDidUpdate(prevProps) {
    if(prevProps.sessionBookingDetails !== this.props.sessionBookingDetails) {
      
      
      if(this.props.sessionBookingDetails.length > 0 ){
        let calID = this.calendar.id
        let arr = []
        _.each(this.props.sessionBookingDetails, (eachB)=>{
           let i = 0;

        let sessionDetails=JSON.parse(eachB.session_details)
        let sessionID=eachB.session_id
        let x=[]
           sessionDetails.filter(session =>{
              if(session.id==sessionID){
                x.push(session)
              }
           })
           
        
        
        let date=eachB.date.split("T")
           
           let s = {
            id: i++,
            calendarId: calID,
            // title:`${eachB.title} session with user ${eachB.name}`,

            title:eachB.account_type=="user" ?(`Session : ${x[0].sessionName}`):
            (`Session : ${x[0].sessionName}`),

            
            body:eachB.account_type=="user" ?(`<h6>User -  <i>${eachB.name}</i></h6>`):(`<h6>Coach - <i>${eachB.name}</i></h6>`),



            category: 'time',
            dueDateClass: '',
            start:date[0] +"T"+eachB.start_time,
            end:date[0] +"T"+eachB.end_time,
            color: eachB.status==1 ?'blue':'red',
            bgColor: eachB.status==1 ?'blue':'red',
            borderColor: eachB.status==1 ?'blue':'red'
            
           }
           arr.push(s)
           //return s
        })

        console.log("SCHEDULE ARRAY ")
        console.log(arr)
        this.calendar.createSchedules(arr)
      }
    }
}


render(){
  
  return(
<div>
                 

<div className="col-md-12">
<div className="schduleCalendar">
  
    <div className="booking__calendar">
      <div id="menu">
          <span className="dropdown">
              <button id="dropdownMenu-calendarType" className="btn btn-default btn-sm dropdown-toggle" type="button" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="true"
                  >
                  <i id="calendarTypeIcon" className="calendar-icon ic_view_month" style={{marginRight: "4px"}}></i>
                  
                  <span id="calendarTypeName">Dropdown</span>&nbsp;

                   {/* <i className="calendar-icon tui-full-calendar-dropdown-arrow"></i>  */}
                  
              </button>
              <ul className="dropdown-menu" role="menu" aria-labelledby="dropdownMenu-calendarType">
                  <li role="presentation">
                      <a className="dropdown-menu-title" role="menuitem" data-action="toggle-daily">
                          <i className="calendar-icon ic_view_day"></i>Daily
                      </a>
                  </li>
                  <li role="presentation">
                      <a className="dropdown-menu-title" role="menuitem" data-action="toggle-weekly">
                          <i className="calendar-icon ic_view_week"></i>Weekly
                      </a>
                  </li>
                  <li role="presentation">
                      <a className="dropdown-menu-title" role="menuitem" data-action="toggle-monthly">
                          <i className="calendar-icon ic_view_month"></i>Month
                      </a>
                  </li>
                  <li role="presentation">
                      <a className="dropdown-menu-title" role="menuitem" data-action="toggle-weeks2">
                          <i className="calendar-icon ic_view_week"></i>2 weeks
                      </a>
                  </li>
                  <li role="presentation">
                      <a className="dropdown-menu-title" role="menuitem" data-action="toggle-weeks3">
                          <i className="calendar-icon ic_view_week"></i>3 weeks
                      </a>
                  </li>
                  <li role="presentation" className="dropdown-divider"></li>
                  <li role="presentation">
                      <a role="menuitem" data-action="toggle-workweek">
                          <input type="checkbox" className="tui-full-calendar-checkbox-square" value="toggle-workweek" checked/>
                          <span className="checkbox-title"></span>Show weekends
                      </a>
                  </li>
                  <li role="presentation">
                      <a role="menuitem" data-action="toggle-start-day-1">
                          <input type="checkbox" className="tui-full-calendar-checkbox-square" value="toggle-start-day-1"/>
                          <span className="checkbox-title"></span>Start Week on Monday
                      </a>
                  </li>
                  <li role="presentation">
                      <a role="menuitem" data-action="toggle-narrow-weekend">
                          <input type="checkbox" className="tui-full-calendar-checkbox-square" value="toggle-narrow-weekend"/>
                          <span className="checkbox-title"></span>Narrower than weekdays
                      </a>
                  </li>
              </ul>
             
          </span> 
          {/* <span id="menu-navi">
              <button type="button" className="btn btn-default btn-sm move-today" data-action="move-today">Today</button>
              <button type="button" className="btn btn-default btn-sm move-day" data-action="move-prev">
                  <i className="calendar-icon ic-arrow-line-left" data-action="move-prev"></i>
              </button>
              <button  type="button" className="btn btn-default btn-sm move-day" data-action="move-next">
                  <i style={{color:"black"}} className="calendar-icon ic-arrow-line-right" data-action="move-next"></i>
              </button>
          </span> */}
          <span id="renderRange" className="render-range"></span>
      </div>
      <div id="calendar" className="mb-3">
        
      </div>
  </div>



{/* <div id="calendar" style={{height: "800px"}}></div> */}

</div>
</div> 


</div> 
        )
    }
}
export default UpcomingSessionCalender