import React from 'react'
import {Link} from 'react-router-dom'
import ReactStars from "react-stars";



class StarRating extends React.Component{
  constructor(props) {
    super(props);
   
  }
    // constructor() {
    //   super() 
    //   this.state= {
    //     hover: false
    //   }
    // }

    // ratingChanged = (newRating) => {
    //   console.log(newRating);
    // };
    render(){
        return(
            <div>
                 <fieldset>
        <div className="heading text-center">
          <h3 className="fs-subtitle">Minimum Star Rating</h3>
          <span className="R_divider"></span>
        </div>
        <div className="ratingWrap w-50 mx-auto mb-4 mt-2">
         
            {/* <div className="text-center" style={{fontSize:"18px", color:"#82bd51", marginBottom:"10px", fontWeight:"600"}}>2/3</div> */}
            {this.props.data.rating != undefined && this.props.data.rating != '' ? (
              <div className="text-center" style={{fontSize:"18px", color:"#00e1ba", marginBottom:"10px", fontWeight:"600"}}>{this.props.data.rating}/3</div>
            ) : ""}

            <div className='rating-stars text-center'>
              <ReactStars
              value={this.props.data.rating}
              onChange={this.props.handleRatingChange}
              count={3}
              size={25}
              half={false}
              color1={"#cccccc"}
              className=""
              />

              <div className="label">
                <span>Good</span>
                <span>Excellent</span>
                <span>Exceptional</span>
              </div>

              <div className='text-message'></div>
            </div>
        </div>

        <Link
        to="#"
          onClick={() =>
          this.props.handleField("Price")
          }
         className="defaultBtn">PREVIOUS
        </Link> {" "}
       
        <Link
        to="#"
          onClick={() =>
          this.props.handleField("Membership")
          }
         className="defaultBtn">Next
        </Link>
      </fieldset>
            </div>
        )
    }
}
export default StarRating