import React from "react";
import { FooterLink } from "../components/footers";
import { Header, HeaderLogout, HeaderTop } from "../components/headers";
import { dArrowblack } from "../assets/images";
import { toast } from "react-toastify";
import axios from "../config/axios";
import message from "../utils/mesaages";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import validator from "validator";
import { Link } from "react-router-dom";

import { logo } from "../assets/images";

class Template extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Header />
        <section>
          <div className="container">
            <div className="row align-items-center" style={{minHeight: "600px"}}>
              <div className="d-flex align-items-start justify-content-between w-100 flex-wrap">
                <div className="col-md-4">
                  <div className="bannerText">
                    <h1 className="textEffect">
                      <span>CREATE SUCCESS</span>
                      <p>Live a Life You Love</p>
                    </h1>
                    <Link className="defaultBtn" to="/registration">
                      Get Started for free
                    </Link>
                    <p className="button-underneath-text">
                      <span>#1 Online Coaching Platform</span>
                      <br />
                      Find your perfect coach today
                    </p>
                  </div>
                </div>
                <div className="col-md-7  d-flex justify-content-center" >
                  <div className="comming-soon banner d-flex justify-content-center flex-wrap flex-column text-center">
                    <span className="mb-2">
                      <img src={logo} />
                    </span>
                    <h1 className="comming-soon-text">Coming Soon!</h1>
                    <p className="comming-soon-text">
                      Thank you for joining LifeGuru we will notify you once
                      your account is ready to use.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterLink />
      </div>
    );
  }
}

export default Template;
