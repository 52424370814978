import React from 'react'
import { toast } from "react-toastify";
import _ from "lodash";
import { teamSvg } from '../../assets/images'
import { avatar } from '../../assets/img'
import MyCalender from '../../components/calender/calender'
import axios from '../../config/axios'
import AcceptModal from "./view/AcceptModal";
import message from '../../utils/mesaages';


class UserCalender extends React.Component{
    constructor(props){
        super(props)
        this.state={
            bookingDetails:[],
            awaitingBooking:[],
            acceptedBooking: [],
            sessionBooking: [],
            unavailableData: [],
            AcceptModal:false,
            BookingModal:''
        }
        this.acceptedBooking = []
      }
      componentDidMount(){

         this.getBookingRequests();

    }

    getBookingRequests = () => {

      var self=this;

      axios.get("/users/session/booking-request-for-consumer",{
      headers:{"x-auth":this.props.token}
      })
      .then( async(res) =>{
        console.log('booking-request',res.data);
      
        if(res.data.success){
          console.log("booking list")
          const bookingRequestInitiations=res.data.introBookings;
          const bookingRequestSessions=res.data.mainSessionBookings;

          self.setState({
            bookingDetails : _.filter(bookingRequestInitiations, { 'status': 'Awaiting Confirmation' }), 
            //acceptedBooking : _.filter(bookingRequestInitiations, { 'status': 'Confirmed' }),
            acceptedBooking : bookingRequestInitiations,
            sessionBooking: bookingRequestSessions
          },()=>{
            console.log("this booking status", self.state)
          })
          //self.acceptedBooking = _.filter(res.data.value, { 'status': 'Confirmed' })
          
        }
      })
      .catch(err =>{
        console.log(err)
      })

    }

  
    handleAddToSchedule = (data) =>{
     console.log('hndaleSchedule',data)
      this.refs.calendarcomponent.addToSchedule(data);

    }

    handleAcceptBooking = (data)=>{
      console.log("Booking acepted : ", data)
      let formdata = {"booking_uid":data.uid, "package_details":data.package_details }
      
      axios.post('/users/session/accept-booking',formdata,{
          headers:{'x-auth':this.props.token}
        })
        .then(res =>{
          if(res.data.success){

            message.success("Successfully accepted booking request");
            

            let da = _.filter(this.state.bookingDetails, function(o) { return o.uid !=data.uid; });


            this.setState({bookingDetails:da},()=>{
              console.log('what have',da)
              console.log('what have',this.state.bookingDetails)
            })
            
          }else{
            message.error(res.data.msg);
          }
        })
        .catch(err =>{
          console.log("Error")
          console.log(err)
        })
      }

      handaleModal=(BookingModal)=>{
        if(this.state.AcceptModal)
        {
           this.setState({AcceptModal: false})
        }
        else{
          this.setState({AcceptModal: true},()=>{})
        }
        this.setState({BookingModal})
      }
    

    render(){
        return(
        <div className="col-md-9">
            <div className="col-md-14">
        {/* <div className="searchHead">
          <div className="searchHeading">
            <h3>
              <img width="22px" src={teamSvg} alt="LG-Img" />Booking Requests
            </h3>
          </div>
          
        </div> */}

      {/* <div className="heading text-center">
          <h3>MY SCHEDULES</h3>
          <span className="R_divider"></span>
        </div> */}


        {/* <div className="userBookingList">
      {this.state.bookingDetails.length > 0 && this.state.bookingDetails.map(booking =>{
        let coachData=JSON.parse(booking.package_details)
     
        return(
          <div className="bookingCocachDetails">

          <ul>
            <li>
              {coachData.coachImage!==undefined ?(<div>
                <img
                alt="LG_Img"
                width="60px"
                className="userBookingCocachImg"
                src={avatar}
                
              />
              </div>):(<div>
                <img
                alt="LG_Img"
                width="60px"
                className="userBookingCocachImg"
                src={avatar}
                
              />
              </div>)}
             
            </li>
            <li>
              <b>Booking</b> <span>{booking.created.split("T")[0]}</span>
            </li>
            <li>
              <b>Scheduled</b> <span>{coachData!==null ?(coachData.sessionDate):("")}</span>
            </li>
            <li>
              <b>Slot Time</b> <span>{coachData!==null ?(coachData.slotTime):("")}</span>
            </li>
            <li>
              <b>User Name</b> <span>{coachData!==null ?(coachData.consumer_name):("")}</span>
            </li>
            <li>
              <b>Package</b> <span className="package">{coachData!==null ?(coachData.session_name):("")}</span>
            </li>
            <li>
              <b>Status</b><span className="package">{booking.status}</span>
            </li>
           
            <li className="defaultBtn" onClick={()=> {this.handaleModal(booking)}}>Accept</li>
          </ul>
          
          <div className="collapse" id={booking.uid}>
            <div className="BCDView">
              <p>
                <b>Package Details : </b>{" "}
                <span>{this.state.Description}</span>
              </p>
              <p>
                <b>Total Sessions : </b> <span>{this.state.TotalSession}</span>
              </p>
              <p>
                <b>Sessions left : </b> <span>{this.state.TotalSession}</span>
              </p>
              
            </div>
          </div>
        </div>
        )
      })}
      {(this.state.bookingDetails.length <= 0)?(<>
        <div className="bookingCocachDetails">
          <ul className=" text-center justify-content-center">
            <li>No Pending Requests</li>
          </ul>
          </div>

      </>):(<></>)}
      {(this.state.BookingModal != '')?(<>
        <AcceptModal booking={this.state.BookingModal} show={this.state.AcceptModal} confirm={this.handleAddToSchedule} handale={()=>this.handaleModal('')}/>
        </>):(<></>)}
        </div> */}
      </div>
      {/* <hr/> */}
      <MyCalender 
        ref="calendarcomponent"
        handleAcceptBooking = {this.handleAcceptBooking}
        introBookings = {this.state.acceptedBooking}
        sessionBooking = {this.state.sessionBooking}
        type="usercalender"
      />
</div>
        )
    }
}
export default UserCalender



