import React from "react";
import {
  Button,
  Modal,
  Form,
  Col,
  Container,
  Row,
  Image,
} from "react-bootstrap";
import { Tooltip } from 'antd';
import { photograph } from "../../../assets/images";
import axios from "../../../config/axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import message,{getHelpText} from '../../../utils/mesaages';

class UploadUserImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: "",
      setShow: false,
      avatar_path: null,
      profile_image_text: '',
      invalid: '',
      imagefile_name: '',
    };
  }

  async componentDidMount() {
    let user_profile_image_help = await getHelpText('user_profile_image_help')
    this.setState({user_profile_image_help})
  }

  handleShow = (e) => {
    this.setState({ setShow: !this.state.setShow });
  };

  onClose = (e) => {
    this.setState({ setShow: !this.state.setShow, invalid: "", imagefile_name: "", avatar_path: null });
  }

  handleChange = async(e) => {
    // this.setState({ avatar_path: e.target.files[0] });
    // console.log(e.target.files[0]);

    let inputType = e.target.files[0] != undefined ? e.target.files[0].type.split('/'): ''
    let filename = e.target.files[0].name.split('.')
    let ext = filename.pop().toLowerCase()
    if(inputType[0] != undefined && inputType[0] == 'image' && (ext == 'jpeg' || ext == 'jpg' || ext == 'png' || ext == 'gif')){
      if(e.target.files[0].size>4000000) {
        await this.setState({invalid: "Please select file upto 4MB size", imagefile_name: "", avatar_path: null})
      } else {
        await this.setState({
          avatar_path: e.target.files[0],
          imagefile_name: e.target.files[0] != undefined ? e.target.files[0].name : "",
          loaded: 0,
          invalid: '',
        });
      }
    } else {
      await this.setState({invalid: "Please select correct file format upto 4 MB size", imagefile_name: "", avatar_path: null})
      //message.error("Please choose a video file")
      //this.onClose()
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("avatar_path", this.state.avatar_path);
    let param;
    if (this.props.pdata.user_uid !== "") {
      param = {
        url: "/consumer/profileimage",
        method: "PUT",
        data: formData,
        headers: {
          "X-Auth": this.props.token,
        },
      };
    } else {
      param = {
        url: "/consumer/profileimage",
        method: "POST",
        data: formData,
        headers: { "X-Auth": this.props.token },
      };
    }
    const hideMessage = message.loading('Please wait while we are uploading image.', 0)
    axios(param)
      .then((res) => {
        hideMessage()
        if (res.data.success) {
          this.setState({invalid: "", imagefile_name: "", avatar_path: null });
          this.props.updatePersonal();
        }
      })
      .catch((error) => {
        hideMessage()
        console.log(error);
        message.error("Error in updating image");
      });
  };

  render() {
    return (
      <div className="picEdit">
        <Link
          to="/profile-consumer"
          className="popup-with-zoom-anim edit_item"
          data-toggle="tooltip"
          data-placement="top"
          title="Edit your Picture"
          onClick={this.handleShow}
        >
          <img width="35px" src={photograph} alt="LG-Img" />
        </Link>

        <Modal
          style={{color:"black"}}
          show={this.state.setShow}
          onHide={this.onClose}
          backdrop="static"
          keyboard={false}
        >
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Upload Profile Picture
              <Tooltip title={this.state.user_profile_image_help} placement="right"> <i style={{color:"purple"}} className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <Col xs={4} md={4}>
                    {this.props.userImage2 && (
                      <Image
                        src={this.props.userImage2}
                        style={{ width: "150px" }}
                        thumbnail
                      />
                    )}
                  </Col>
                  <Col>
                    <br />
                    <br />

                    <input
                      type="file"
                      label="Select Image file"
                      name="avatar_path"
                      onChange={this.handleChange}
                      accept=".jpg,.jpeg,.png,.gif"
                    />
                    <br/>
                    <br/>
                    <span style={{color: "red"}}>{this.state.invalid}</span>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>

            <Modal.Footer>
              {this.state.avatar_path != null ? (
                <Button
                variant="btn btn-success btn-block"
                type="submit"
                onClick={this.handleShow}
              >
                Upload
              </Button>
              ) : ''}
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
export default UploadUserImage;
