import React from "react";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import FacebookLogin from "react-facebook-login";
import axios,{AdminApi} from "../../../config/axios";
import message from '../../../utils/mesaages';
import { facebook } from "../../../assets/images";
class Facebook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      Uuid: "",
      accessToken: " ",
      loader: false,
    };
  }

  responseFacebook = (response) => {
    console.log("facebook", response);
    this.setState({
      // isLoggedIn: true,
      accessToken: response.accessToken,
    });
    const hideMessage = message.loading('Please wait, we are saving your data ...', 0)
    var tracking_link='';
    if(localStorage.getItem('tracking_link')!=undefined || localStorage.getItem('tracking_link')!=null)
    {
      // this.setState({tracking_link:localStorage.getItem('tracking_link')})
       tracking_link=localStorage.getItem('tracking_link')
    }
    const formData = {
      fullname: response.name,
      email: response.email,
      account_type: this.props.usertype,
      coaching_category: this.props.categorytype,
      status: 1,
      tracking_link:tracking_link
    };
    if (this.state.accessToken !== undefined) {
      //let token = this.state.accessToken
      axios
        .post("/signup", formData)
        .then((response) => {
          hideMessage()
          if (
            response.data.msg === "Registered Successfully" &&
            response.data.result === true
          ) {
            localStorage.removeItem('tracking_link')
            this.setState({ loader: false });
            this.setState({
              isLoggedIn: response.data.result,
              Uuid: response.data.userdata.uid,
            });
            message.success(" Registered Successfully");
             //Signup 
            let token = response.data.userdata.uid
             axios.get(`${AdminApi}/signup/${token}`, {})
             .then(function (response) {
              hideMessage()
             console.log(response);
             })
             .catch(function (error) {
              hideMessage()
             console.log(error);
             });
             //
          } else if (
            response.data.result === false ||
            response.data.msg === "User already exists!"
          ) {
            message.error(response.data.msg);
          
          }
        })
        .catch((err) => {
          hideMessage()
          message.error("Error signing up using facebook");
        });
    } else {
      hideMessage()
      message.error("Error signing up using facebook");
    }
  };

  render() {
    let fbContent;

    if (this.state.isLoggedIn && !_.isEmpty(this.state.accessToken)) {
      fbContent = (
        <div>
          return{" "}
          <Redirect
            to={{
              pathname: "/login",
              state: {
                user_t: this.state.Uuid,
              },
            }}
          />
        </div>
      );
    } else {
      if(this.props.buttonstatus===true || this.props.usertype == -1)
      {
        fbContent = (
        <button className="facebookbutton" onClick={this.props.termsandcondition}><img src={facebook} />sign up with facebook</button>
        );
      }else{

        fbContent = (
    
          <FacebookLogin
         
            appId="888466681645324"
            textButton="sign up with facebook"
            //isDisabled={this.props.buttonstatus}
            // autoLoad={true}
            disableMobileRedirect={true}
            fields="name,email,picture"
            callback={this.responseFacebook}
            cssClass="my-facebook-button-class"
            name="sign up with facebook"
            icon={<i className="fa fa-facebook fa-lg" style={{marginLeft:'5px', marginRight:'10px'}}></i>}
          />

             
          
        );

     }
   
    }
    return <div>{fbContent}</div>;
  }
}
export default Facebook;
