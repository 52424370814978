import every from 'async/every';

const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

function empty_custom(val) {
    let back = true;
    if (val === '') {
        back = false
    } else if (val === undefined) {
        back = false
    }
    else if (val === null) {
        back = false
    }

    return back

}

const validation = ({ value, rules, message }) => {

    return new Promise(function (resolve, reject) {

        if (rules !== null) {
            rules = rules.split(",");
            message = message !== null ? message.split(",") : [''];
            let match = '';
            let arr = [];

            var i = 0;
            var error = '';
            rules.forEach(element => {
                //for confirm pass

                arr = element.split("|");
                element = arr[0];
                match = arr[1];

                //end
                // console.log(i,element)
                // console.log(i,message[i])

                switch (element) {

                    case 'required':
                        if (value.length < 1 || value.length < 0) {
                            var msg = empty_custom(message[i]) ? message[i] : 'This field is required';
                            error = error.concat(msg + ', ')
                        }
                        break;

                    case 'isEmail':
                        if (!validEmailRegex.test(value)) {
                            var msg = empty_custom(message[i]) ? message[i] : 'Enter a valid email address';
                            error = error.concat(msg + ', ')
                        }
                        break;

                    case 'password':
                        if (value.length < 6) {
                            var msg = empty_custom(message[i]) ? message[i] : 'password should be greater than 6';
                            error = error.concat(msg + ', ')

                        }
                        break;

                    case 'same':
                        if (value !== match) {
                            var msg = empty_custom(message[i]) ? message[i] : 'Confirm Password should be same as password';
                            error = error.concat(msg + ', ')
                        }
                        break;

                    case 'phone':
                        if (value.length < 10 || value.length > 15 || value + 0 == 0) {
                            var msg = empty_custom(message[i]) ? message[i] : 'phone no must be between 10 to 15 digit';
                            error = error.concat(msg + ', ')
                        }
                        break;

                    case 'numeric':
                        if (isNaN(value)) {
                            var msg = empty_custom(message[i]) ? message[i] : 'This field should be a number value';
                            error = error.concat(msg + ', ')
                        }
                        break;

                    case 'priceValue':
                        if (parseInt(value) < parseInt(match)) {
                            var msg = empty_custom(message[i]) ? message[i] : 'Minimum ' + match + ' $ need ';
                            error = error.concat(msg + ', ')
                        }
                        break;

                    case 'notZero':
                        if (value <= 0) {
                            // console.log('parseInt(value)', parseInt(value))
                            var msg = empty_custom(message[i]) ? message[i] : 'Zero not allowed';
                            error = error.concat(msg + ', ')
                        }
                        break;

                    case 'lessThanOne':
                        if (value < 1) {
                            // console.log('parseInt(value)', parseInt(value))
                            var msg = empty_custom(message[i]) ? message[i] : 'Less than one not allowed';
                            error = error.concat(msg + ', ')
                        }
                        break;

                    case 'maxpriceValue':
                        if (parseInt(value) > parseInt(match)) {
                            var msg = empty_custom(message[i]) ? message[i] : 'Amount ' + match + ' $ too long ';
                            error = error.concat(msg + ', ')
                        }
                        break;

                    case 'maxSortingOrderValue':
                        if (parseInt(value) > parseInt(match) || parseInt(value) === 0 || value === '+' || value === '-') {
                            var msg = empty_custom(message[i]) ? message[i] : 'Amount ' + match + ' $ too long ';
                            error = error.concat(msg + ', ')
                        }
                        break;

                    case 'max':
                        if (value.length > match) {
                            var msg = empty_custom(message[i]) ? message[i] : 'Maximum ' + match + ' Character';
                            error = error.concat(msg + ', ')
                        }
                        break;

                    case 'min':
                        if (value.length < match) {
                            var msg = empty_custom(message[i]) ? message[i] : 'Maximum ' + match + ' Character';
                            error = error.concat(msg + ', ')
                        }
                        break;

                    case 'filesize':
                        if (value.size >= match) {
                            var msg = empty_custom(message[i]) ? message[i] : 'File too Big, please select a file less than 2mb';
                            error = error.concat(msg + ', ')
                        }
                        break;

                    case 'mime':
                        match = match.split("/")
                        let type = []

                        match.forEach(match => {
                            switch (match) {
                                case 'jpg': type.push('image/jpeg'); break;
                                case 'jpeg': type.push('image/jpeg'); break;
                                case 'png': type.push('image/png'); break;
                                case 'pdf': type.push('application/pdf'); break;
                                default: break;
                            }

                        })


                        if (!type.includes(value.type)) {
                            var msg = empty_custom(message[i]) ? message[i] : 'File type not valid';
                            error = error.concat(msg + ', ')
                        }

                        break;

                    case 'expire':
                        if (value.length === 4) {
                            let month = value.substring(0, 2)
                            let year = value.slice(-2)

                            if (month > 12 || month <= 0 || 20 + year < new Date().getFullYear()) {
                                var msg = empty_custom(message[i]) ? message[i] : 'Invalid Month';
                                error = error.concat(msg + ', ')
                            }
                        }
                        break;

                    case 'passpattern':
                        var decimal = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,15}$/;
                        if (!value.match(decimal)) {
                            // alert('Correct, try another...')
                            // return true;
                            var msg = empty_custom(message[i]) ? message[i] : 'Password must contain 1 special character, 1 digit';
                            error = error.concat(msg + ', ')
                        }
                        break;

                    default:
                        break;
                }

                i++;
            })

            if (error === '') {
                resolve("")
            }
            else {
                let msgARR = error.split(",")
                resolve(msgARR[0])
            }

        }
        else {
            resolve('')
        }
    })
}

export default validation;