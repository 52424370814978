import React, { Component } from "react";
import { Modal, Form, Col, Row, Button, Spinner } from "react-bootstrap";
import moment from "moment";
import axios from "../../../config/axios";
import message,{getHelpText} from "../../../utils/mesaages";

//import {  filter,closeSvg,quality, dArrowblack,mclose } from "../../../assets/images";
//import { avatar } from "../../../assets/img";
import PlacesAutocomplete, {
  geocodeByAddress,
  google
} from "react-places-autocomplete";
import { DatePicker, Alert } from "antd";
import isAlphanumeric from "validator/lib/isAlphanumeric";
import validator from 'validator';
import { Tooltip } from 'antd';
import parsePhoneNumber from 'libphonenumber-js'

const _ = require("lodash");

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

class BankDetailsModal extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef()
    this.state = {
      vendorName: "",
      vendorAddress: "",
      vendorCountry: "",
      vendorState: "",
      vendorCity: "",
      vendorZip: "",
      phoneNumber: "",
      vendorDOB: "",
      ssnLast4:"",
      identificationType: "",
      identificationNumber: "",
      accountNumber: "",
      accountName: "",
      accountBankname: "",
      bankId: "",
      vendorIBAN: "",
      accountEmail: "",
      bankAddress: "",
      bankCountry: "",
      bankState: "",
      bankCity: "",
      bankZip: "",
      bankAccType: "",
      bankAccClass: "",
      payoutType: "",
      payoutCurrency: "",
      //payoutFrequency: "",
      dataAccurate: false,
      showModal: false,
      saveLoader: false,
      alertVisible: false,
      alertMessage: "",
      homeVerify:'',
      idProf:'',
      bankerrors: {
        vendorName: "",
        phoneNumber: "",
        accountNumber: "",
        accountName: "",
        ssnLast4:"",
        bankId: "", // bank identifier / routing number / sort code / BSB / IFSC code
        accountBankname: "",
        accountEmail: "",
        vendorAddress: "",
        vendorCountry: "",
        vendorState: "",
        vendorZip: "",
        vendorDOB: "",
        identificationType: "",
        identificationNumber: "",
        bankAddress: "",
        bankCountry: "",
        bankState: "",
        bankCity: "",
        bankZip: "",
        bankAccType: "",
        bankAccClass: "",
        payoutType: "",
        vendorIBAN: "",
        vendorId:"",
        homeVerifyErr:'',
        idProfErr:''
      },
      kyc_address_document:'',
      kyc_id_document:''
    };
  }

  componentDidMount = async () => {
    this.fetchCoachInfo();
    this.getVendorId()

    let kyc_id_document = await getHelpText('kyc_id_document')
    this.setState({kyc_id_document})
    let kyc_address_document = await getHelpText('kyc_address_document')
    this.setState({kyc_address_document})
  };

  fetchCoachInfo = () => {
    const token = localStorage.getItem("authToken");
    const userdata = JSON.parse(localStorage.getItem("userdata"));
    const id = userdata.uid;
    axios
      .get(`/users/coachprofile/${id}`, {
        headers: { "x-auth": token },
      })
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          const coachData = res.data.value;
          let address = JSON.parse(coachData.address);
          this.setState({
            accountEmail: coachData.email,
            vendorName: coachData.name,
            // phoneNumber: coachData.phone_number,
            //vendorAddress: address.location,
            //vendorCountry: address.country,
            //vendorState: address.state,
            //vendorCity: address.city
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // bankmodal = async () => {
  //   console.log("enter1");
  //   let { bankerrors } = this.state;
  //   bankerrors["accountNumber"] = "";
  //   bankerrors["accountName"] = "";
  //   bankerrors["bankId"] = "";
  //   bankerrors["accountBankname"] = "";
  //   this.setState({ bankerrors });
  //   await this.setState({ dataAccurate: false, accountNumber: "" });
  //   //this.setState({ showModal: true})
  //   this.handleShowModal();
  // };

  handleShowModal = () => {
    this.setState({ showModal: !this.state.showModal },()=>{
      
      this.setState({
        vendorName :"",
        vendorDOB :"",
        phoneNumber :"",
        vendorAddress :"",
        vendorCountry :"",
        vendorCity :"",
        vendorState :"",
        vendorZip :"",
        accountEmail :"",
        homeVerify:'',
        idProf:'',
        bankerrors: {
          vendorName: "",
          phoneNumber: "",
          accountName: "",
          accountEmail: "",
          vendorAddress: "",
          vendorCountry: "",
          vendorState: "",
          vendorZip: "",
          vendorDOB: "",

          homeVerifyErr:'',
          idProfErr:'',
          kyc_address_document:'',
          kyc_id_document:''
        },
      },()=>{
        this.fetchCoachInfo();
      })
    });
  };

  handleAddressChange = (address) => {
    this.setState({ vendorAddress: address });

    if (address != "" && address != null) {
    } else {
      //validate
      let { bankerrors } = this.state;
      bankerrors["vendorAddress"] = "This field is required";
      this.setState({ bankerrors });

      //validate
    }
  };

  handleBankAddressChange = (address) => {
    this.setState({ bankAddress: address });

    if (address != "" && address != null) {
    } else {
      //validate
      let { bankerrors } = this.state;
      bankerrors["bankAddress"] = "This field is required";
      this.setState({ bankerrors });

      //validate
    }
  };

  handleSelect = async (address) => {
    this.setState({ vendorAddress: address, vendorZip: "", vendorCity: "", ssnLast4: "", identificationNumber: ""});
    var that = this;
    await geocodeByAddress(address)
      .then((results) => {
        console.log("RESULT FO GEOCODEBYADDRESS", results);
        _.map(results[0].address_components, async (eachComp) => {
          console.log("EACH COMPONENT", eachComp);
          // console.log("EACH COMPONENT state", this.state);
          if (_.includes(eachComp.types, "country")) {
            await that.setState(
              {
                vendorCountry:
                  eachComp.short_name === "GB" ? "UK" : eachComp.short_name,
              },
              () => {
                if (eachComp.long_name != "") {
                  //validate
                  let { bankerrors } = this.state;
                  bankerrors["vendorAddress"] = "";
                    bankerrors["vendorCountry"] = "";
                    bankerrors["vendorCity"] = "";
                    bankerrors["vendorState"] = "";
                    this.setState({ bankerrors });
                  // if (
                  //   this.state.payoutType === "ACH" &&
                  //   this.state.vendorCountry === "US"
                  // ) {
                  //   bankerrors["vendorAddress"] = "";
                  //   bankerrors["vendorCountry"] = "";
                  //   bankerrors["vendorState"] = "";
                  //   this.setState({ bankerrors });
                  // } else if (
                  //   (this.state.payoutType === "FAST" ||
                  //     this.state.payoutType === "CHAPS") &&
                  //   this.state.vendorCountry === "UK"
                  // ) {
                  //   bankerrors["vendorAddress"] = "";
                  //   bankerrors["vendorCountry"] = "";
                  //   this.setState({ bankerrors });
                  // } else if (this.state.payoutType === "WIRE") {
                  //   bankerrors["vendorAddress"] = "";
                  //   bankerrors["vendorCountry"] = "";
                  //   this.setState({ bankerrors });
                  // } else {
                  //   bankerrors[
                  //     "vendorAddress"
                  //   ] = `Payout type ${this.state.payoutType} is not supported in this Address`;
                  //   this.setState({ bankerrors });
                  // }

                  //validate
                }
              }
            );
          }
          if (_.includes(eachComp.types, "administrative_area_level_2")) {
            //console.log("EACHCOMP.TYPES",timezones[0].name)
            await that.setState({
              vendorState: eachComp.long_name,
            });
          }
          if (_.includes(eachComp.types, "administrative_area_level_1")) {
            //console.log("EACHCOMP.TYPES",timezones[0].name)
            if(this.state.vendorState === "")
            {
              await that.setState({
                vendorState: eachComp.long_name,
              });
            }
          }

          if (_.includes(eachComp.types, "locality")) {
            if (this.state.vendorCity == "") {
            await that.setState({ vendorCity: eachComp.long_name });
            }
          }

          if (_.includes(eachComp.types, "neighborhood")) {
            if (this.state.vendorCity == "") {
              await that.setState({ vendorCity: eachComp.long_name });
            }
          }
          
          if (_.includes(eachComp.types, "postal_town")) {
          
            if (this.state.vendorCity == "") {
              await that.setState({ vendorCity: eachComp.long_name });
            }
          }
          if (_.includes(eachComp.types, "postal_code")) {
            if (this.state.vendorZip == "") {
              await that.setState({ vendorZip: eachComp.long_name });
            }
          }

          return eachComp;
        });
      })
      .catch((error) => console.error("Error", error));
  };

  handleSelectBankAddress = async (address) => {
    this.setState({ bankAddress: address });
    var that = this;
    await geocodeByAddress(address)
      .then((results) => {
        console.log("RESULT FO GEOCODEBYADDRESS", results);
        _.map(results[0].address_components, async (eachComp) => {
          console.log("EACH COMPONENT", eachComp);
          if (_.includes(eachComp.types, "country")) {
            await that.setState(
              {
                bankCountry:
                  eachComp.short_name === "GB" ? "GB" : eachComp.short_name,
              },
              () => {
                if (eachComp.long_name != "") {
                  let { bankerrors } = this.state;
                  // if (
                  //   this.state.payoutType === "ACH" &&
                  //   this.state.bankCountry === "US"
                  // ) {
                  //   bankerrors["bankAddress"] = "";
                  //   bankerrors["bankCountry"] = "";
                  //   bankerrors["bankState"] = "";
                  //   this.setState({ bankerrors });
                  // } else if (
                  //   (this.state.payoutType === "FAST" ||
                  //     this.state.payoutType === "CHAPS") &&
                  //   this.state.bankCountry === "UK"
                  // ) {
                  //   bankerrors["bankAddress"] = "";
                  //   bankerrors["bankCountry"] = "";
                  //   this.setState({ bankerrors });
                  // } else if (this.state.payoutType === "WIRE") {
                  //   bankerrors["bankAddress"] = "";
                  //   bankerrors["bankCountry"] = "";
                  //   this.setState({ bankerrors });
                  // } else {
                  //   bankerrors[
                  //     "bankAddress"
                  //   ] = `Payout type ${this.state.payoutType} is not supported in this Address`;
                  //   this.setState({ bankerrors });
                  // }

                  //validate
                }
              }
            );
          }
          if (_.includes(eachComp.types, "administrative_area_level_1")) {
            //console.log("EACHCOMP.TYPES",timezones[0].name)
            await that.setState({
              bankState: eachComp.short_name,
            });
          }

          if (_.includes(eachComp.types, "locality")) {
            await that.setState({ bankCity: eachComp.long_name });
          }

          if (_.includes(eachComp.types, "neighborhood")) {
            if (this.state.bankCity != "") {
              await that.setState({ bankCity: eachComp.long_name });
            }
          }

          

          return eachComp;
        });
      })
      .catch((error) => console.error("Error", error));
  };

  //_______________________ ONCHANGE SET STATE VALUES _______________________ //

  setvalueBankDetails = async (value, field) => {
    let numbers = /^[0-9]*$/;
    let f = field;
    let { bankerrors } = this.state;
    this.handleBankError(field, value);
    switch (field) {
      case "payoutType":
        this.setState({
          payoutType: value,
          payoutCurrency:
            value === "ACH"
              ? "USD"
              : value === "CHAPS" || value === "FAST"
              ? "GBP"
              : this.state.payoutCurrency,
          vendorAddress: "",
          vendorCountry: "",
          vendorState: "",
          vendorCity: "",
          bankAddress: "",
          bankCountry: "",
          bankState: "",
          bankCity: "",
          vendorIBAN: "",
          identificationType: "",
          identificationNumber: "",
        });

        bankerrors.vendorAddress = "";
        bankerrors.bankAddress = "";
        bankerrors.payoutCurrency = "";
        this.setState({ bankerrors });

        break;

      case "payoutCurrency":
        this.setState({ payoutCurrency: value });
        break;

      case "vendorName":
        this.setState({ vendorName: value });
        break;

      case "accountEmail":
        this.setState({ accountEmail: value });
        break;

      // set phone number field
      case "phoneNumber":
        if (numbers.test(value)) {
          if (value.length > 15) {
            this.setState({ phoneNumber: this.state.phoneNumber });
          } else {
            this.setState({ phoneNumber: value });
          }
        } else {
          this.setState({ phoneNumber: this.state.phoneNumber });
        }
        break;

        case "ssnLast4":
          if (numbers.test(value)) {
            if (value.length > 4) {
              this.setState({ ssnLast4: this.state.ssnLast4 });
            } else {
              this.setState({ ssnLast4: value });
            }
          } else {
            this.setState({ ssnLast4: this.state.ssnLast4 });
          }
          break;

        

      case "vendorCity":
        this.setState({ vendorCity: value });
        break;

      case "bankCity":
        this.setState({ bankCity: value });
        break;

      // set zip code field
      case "vendorZip":
       
        if (validator.matches(value,/^[a-z0-9 ]+$/i) || value=='') {
          if (value.length > 10) {
            this.setState({ vendorZip: this.state.vendorZip });
          } else {
            this.setState({ vendorZip: value });
          }
        } else {
          this.setState({ vendorZip: this.state.vendorZip });
        }
        break;

      case "bankZip":
        if (validator.matches(value,/^[a-z0-9 ]+$/i) || value=='') {
          if (value.length > 10) {
            this.setState({ bankZip: this.state.bankZip });
          } else {
            this.setState({ bankZip: value });
          }
        } else {
          this.setState({ bankZip: this.state.bankZip });
        }
        break;

      case "vendorDOB":
        this.setState({ vendorDOB: value });
        break;

      case "identificationType":
        this.setState({ identificationType: value });
        break;
      case "identificationNumber":
        if (numbers.test(value)) {
          this.setState({ identificationNumber: value });
        } else {
          this.setState({
            identificationNumber: this.state.identificationNumber,
          });
        }
        break;

      case "accountNumber":
        if (numbers.test(value)) {
          this.setState({ accountNumber: value });
        } else {
          this.setState({ accountNumber: this.state.accountNumber });
        }
        break;

      case "vendorIBAN":
        if (isAlphanumeric(value) || value == "") {
          this.setState({ vendorIBAN: value });
        } else {
          this.setState({ vendorIBAN: this.state.vendorIBAN });
        }
        break;

      case "accountName":
        this.setState({ accountName: value });
        break;

      case "bankId":
        this.setState({ bankId: value });
        break;

      case "accountBankname":
        this.setState({ accountBankname: value });
        break;

      // case "payoutFrequency":
      //   this.setState({ payoutFrequency: value });
      //   break;

      case "bankAccType":
        
        this.setState({ bankAccType: value});

        break;

      case "bankAccClass":
        this.setState({ bankAccClass: value, accountNumber: "", vendorIBAN: "" });
        bankerrors.vendorIBAN = "";
        bankerrors.accountNumber = "";
        this.setState({ bankerrors });
        break;

      case "dataAccurate":
        this.setState({ dataAccurate: value });
        break;

      default:
        break;
    }
  };

  // ______________________________ ONCHANGE ERROR TEXT VALIDATION_____________________________//
  handleBankError = (name, value) => {
    console.log("name1", name);
    console.log("value1", value, value===null);

    let bankerrors = this.state.bankerrors;
    switch (name) {
      case "payoutType":
        if (value === "") {
          bankerrors.payoutType = "Payout type is required";
        } else {
          bankerrors.payoutType = "";
        }
        break;

      case "payoutCurrency":
        if (value === "") {
          bankerrors.payoutCurrency = "Payout Currency is required";
        } else {
          bankerrors.payoutCurrency = "";
        }
        break;

      case "vendorName":
        if (value === "") {
          bankerrors.vendorName = "Vendor name is required";
        } else {
          bankerrors.vendorName = "";
        }
        break;

      case "vendorDOB":
        if (value === "") {
          bankerrors.vendorDOB = "Date of Birth is required";
        } else {
          bankerrors.vendorDOB = "";
        }
        break;

      case "identificationType":
        if (value === "") {
          bankerrors.identificationType = "Identification type is required";
        } else {
          bankerrors.identificationType = "";
        }
        break;

      case "identificationNumber":
        if (value === "") {
          bankerrors.identificationNumber = "Identification number is required";
        } else {
          bankerrors.identificationNumber = "";
        }
        break;

      case "accountEmail":
        if (value === "") {
          bankerrors.accountEmail = "Email id required";
        } else if (!validEmailRegex.test(value)) {
          bankerrors.accountEmail = "Invalid email id";
        } else {
          bankerrors.accountEmail = "";
        }
        break;

      case "vendorAddress":
        if (value === "") {
          bankerrors.vendorAddress = "Address is required";
          bankerrors.vendorCountry = "Country is required";
          bankerrors.vendorState =  "State is required";
          bankerrors.vendorCity =  "City is required";
        } else {
          bankerrors.vendorAddress = "";
          bankerrors.vendorCountry = "";
          bankerrors.vendorState = "";
        }
        break;

      case "bankAddress":
        if (value === "") {
          bankerrors.bankAddress = "Bank address is required";
          bankerrors.bankCountry = "Bank country is required";
          bankerrors.bankState =
            this.state.payoutType === "ACH" ? "State is required" : "";
        } else {
          bankerrors.bankAddress = "";
          bankerrors.bankCountry = "";
          bankerrors.bankState = "";
        }
        break;

      case "phoneNumber":
        bankerrors.phoneNumber = value != null && value.length >= 7 ? "" : "Minimum 7 digits";
        if (value === "") {
          bankerrors.phoneNumber = "Phone number required";
        }
        break;
      case "ssnLast4":
          bankerrors.ssnLast4 = value != null && value.length >= 4 ? "" : "4 digits allowed";
          if (value === "") {
            bankerrors.ssnLast4 = "ssnLast4 number required";
          }
          break;

        

      case "vendorZip":
        if (value === "") {
          bankerrors.vendorZip = "zip code required";
        } else {
          bankerrors.vendorZip = "";
        }
        break;

      case "bankZip":
        if (value === "") {
          bankerrors.bankZip = "Bank zip code required";
        } else {
          bankerrors.bankZip = "";
        }
        break;

      case "accountNumber":
        if (value === "") {
          bankerrors.accountNumber = "Account number required";
        } else {
          bankerrors.accountNumber = "";
        }
        break;

      case "vendorIBAN":
        if (value === "") {
          bankerrors.vendorIBAN = "IBAN is required";
        } else {
          bankerrors.vendorIBAN = "";
        }
        break;

      case "accountName":
        if (value === "") {
          bankerrors.accountName = "Account holder name required";
        } else {
          bankerrors.accountName = "";
        }
        break;

      case "bankId":
        if (value === "") {
          bankerrors.bankId = "Bank id Required";
        } else {
          bankerrors.bankId = "";
        }
        break;

      case "accountBankname":
        if (value === "") {
          bankerrors.accountBankname = "Bank name is Required";
        } else {
          bankerrors.accountBankname = "";
        }
        break;

      case "bankAccClass":
        if (value === "") {
          bankerrors.bankAccClass = "Bank account class is Required";
        } else {
          bankerrors.bankAccClass = "";
        }
        break;

      case "bankAccType":
        if (value === "") {
          bankerrors.bankAccType = "Bank account type is Required";
        } else {
          bankerrors.bankAccType = "";
        }
        break;

      case "idProf":
        if (value === "") {
          bankerrors.idProfErr = "ID proof is Required";
        } else {
          bankerrors.idProfErr = "";
        }
        break;
      case "homeVerify":
        if (value === "") {
          bankerrors.homeVerifyErr = "Address proof is Required";
        } else {
          bankerrors.homeVerifyErr = "";
        }
        break;

      default:
        break;
    }

    this.setState({ bankerrors, [name]: value }, () => {
      console.log("checkRequiredFields",bankerrors);
    });
  };

  validateForm = (errors) => {
    console.log("I am here", errors);
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    console.log("inside validateForm", valid);
    return valid;
  };

  checkrequiredfields = () => {
    let valid = false;
    if (
      this.state.vendorName !== "" &&
      this.state.vendorDOB !== "" &&
      // this.state.identificationNumber !== "" &&
      this.state.phoneNumber !== "" &&
      this.state.vendorAddress !== "" &&
      this.state.vendorCountry !== "" &&
      this.state.vendorCity !== "" &&
      this.state.vendorState !== "" &&
      this.state.vendorZip !== "" &&
      this.state.accountEmail !== "" &&
      this.state.idProf !== "" &&
      this.state.homeVerify !== "" &&
      //&& this.state.country !== ''
      ((this.state.vendorCountry.toUpperCase()==='US' && this.state.ssnLast4!=="") || this.state.vendorCountry.toUpperCase() !=='US') &&
      ((this.state.vendorCountry.toUpperCase()==='US' && this.state.identificationNumber!=="") || this.state.vendorCountry.toUpperCase() !=='US')
      
    ) {
      valid = true;
      console.log("inside checkrequired field success", valid);
    } else {
      console.log("inside checkrequired fiedls else", this.state);
      this.handleBankError("vendorName", this.state.vendorName);
      this.handleBankError("vendorDOB", this.state.vendorDOB);
      this.handleBankError("phoneNumber", this.state.phoneNumber);
      if(this.state.vendorCountry.toUpperCase()=='US')
      {

      
      this.handleBankError("ssnLast4", this.state.ssnLast4);
      this.handleBankError(
        "identificationNumber",
        this.state.identificationNumber
      );
      }
      this.handleBankError("vendorAddress", this.state.vendorAddress);
      this.handleBankError("vendorZip", this.state.vendorZip);

      this.handleBankError("accountEmail", this.state.accountEmail);
      this.handleBankError("idProf", this.state.idProf);
      this.handleBankError("homeVerify", this.state.homeVerify);
      //this.handleBankError('country' ,this.state.country)
      
    
    }

    return valid;
  };

  // SEND CREATE VENDOR REQUEST
  checkBeforeCreateVendorRequest = (event) => {
    event.preventDefault();
    if (this.validateForm(this.state.bankerrors)) {
      console.log("success validateForm");
      if (this.checkrequiredfields()) {
        console.log("Now you can do create vendor request", this.state)
        this.createVendorRequest();
      }
    } else {
      console.error("Invalid Form");
      message.error("Please enter all required fields");
    }
  };
  getVendorId = () => {
    let userid = JSON.parse(localStorage.getItem("userdata")).uid;
    axios.get(`/users/getVendorId/${userid}`, {
      headers: {"x-auth": localStorage.getItem('authToken')}
    })
    .then(async (res) => {
      if(res.data.success) {
        console.log("vendorDetails",res.data.vendorDetails)
        let vendorDetails = JSON.parse(res.data.vendorDetails[0].all_details)
        this.setState({vendorId: res.data.value})
        
      }
    })
    .catch((err) => {
      console.log("error", err)
    })
  }

  createVendorRequest = async () => {
    let uID = JSON.parse(localStorage.getItem("userdata")).uid;

    let name = this.state.vendorName.split(" ")
        let fname = name.shift()
        let lname = name.join(" ")
        const formData = new FormData();
        
        formData.append("uid", uID);
        if(this.state.idProf!='')
          formData.append("idProf", this.state.idProf);

        if(this.state.homeVerify!='')
          formData.append("homeVerify", this.state.homeVerify);

          this.setState({ saveLoader: true });

          let countryCode = this.state.vendorCountry === "UK" ? "GB": this.state.vendorCountry;
          // try {
          //   // FETCHING COUNTRY CODE
          //   let getCountrycode = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCYqdiYLMRiHJVFUDXIppLA7lmNwU3k8Mc&address=${this.state.vendorCountry}`)
          //   if(getCountrycode) {
          //     countryCode = getCountrycode.data.results[0].address_components[0].short_name
          //     console.log('console four CC CODE',countryCode)
          //   }
          //   else {
          //     countryCode = "US"
          //   }
          // }
          // catch(err) {
          //   countryCode = "US"
          // }

        axios.post('/users/get-created-vendorid', {uid: uID, countryCode}).then((res) => {
          if(res.data.success) {
            let acct_id = res.data.vendorId;
            // this.setState({vendorId: res.data.vendorId})
            axios.post("/strip/fileUpload", formData).then((fileRet)=>{
              let parsePhoneNumberVal = parsePhoneNumber(this.state.phoneNumber, countryCode)
              let phonenumberWithCountryCode = parsePhoneNumberVal.number
              console.log("phonenumberWithCountryCode", phonenumberWithCountryCode)

              let form = {
                email:this.state.accountEmail,
                acct_no : acct_id,
                uid:uID,
                first_name:fname,
                last_name: lname,
                dob:this.state.vendorDOB,
                id_number:this.state.identificationNumber,
                // phone:this.state.phoneNumber,
                phone:phonenumberWithCountryCode,
                ssnLast4:this.state.ssnLast4,
                address:{
                    location:this.state.vendorAddress,
                    postal_code:this.state.vendorZip,
                    city:this.state.vendorCity,
                    state:this.state.vendorState,
                    country:countryCode
                },
                idProfID:fileRet.data.idProfID!= ''? fileRet.data.idProfID.id : '',
                homeVerifyID:fileRet.data.homeVerifyID.id!=''?fileRet.data.homeVerifyID.id:''
                
              };
              
              //console.log("after submit", form);
        
                  
    
                  axios.post("/strip/KYCCreatePerson", form)
                    .then(async (response) => {
                      //hideMessage();
                      //console.log(response);
    
                      if (response.data.success) {
                        this.setState({ saveLoader: false });
                        message.success("Customer Details(KYC) sent successfully.",5);
                        this.handleShowModal();
                        this.props.fetchVendorId();
                      } else {
                        this.deleteConnectedAcc(acct_id, uID)
                        this.setState({
                          saveLoader: false,
                          alertMessage: response.data.err,
                          alertVisible: true,
                        });
                        this.ref.current.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        })
                        //message.error("Error saving your account.");
                      }
                    })
                    .catch((err) => {
                      this.deleteConnectedAcc(acct_id, uID)
                      this.setState({
                        saveLoader: false,
                        alertMessage: "Something went wrong!",
                        alertVisible: true,
                      });
                      this.ref.current.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      })
                      //console.log(err);
                      //message.error("Invalid account Details");
                      //hideMessage();
                    });
    
            }).catch((err)=>{
              this.deleteConnectedAcc(acct_id, uID)
              console.log("file upload err",err)
              this.setState({ saveLoader: false });
              message.error("File upload failed!", 5)
    
            })
          }
          else {
            this.setState({
              saveLoader: false,
              alertMessage: res.data.message,
              alertVisible: true,
            });
            this.ref.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }
        }).catch((err) => {
          console.log("create vendor error",err)
          this.setState({ saveLoader: false });
          this.handleShowModal();
          this.props.fetchVendorId();
          message.error("Something went wrong!", 5)
        })

   

    
  };

  onChangeHandler = (e) => {
    e.preventDefault()
    console.log("e.target.files[0]",e.target.files[0])
    let bankerrors = this.state.bankerrors;
    let filename = e.target.files[0] != undefined ? e.target.files[0].name.split('.'): ''
    let ext = filename !='' ? filename.pop().toLowerCase(): ''
    if(ext == 'pdf'  ||  ext == 'jpg' || ext == 'png'){
      if(e.target.files[0].size>2000000)
      {
        e.target.value=''
        bankerrors['idProfErr'] = 'Please select a file upto 2MB'
        // message.error('Please select a file upto 2MB')
      }
      else
      {
        this.setState({idProf:e.target.files[0]})
        bankerrors['idProfErr'] = ''

      }
    } else {
      e.target.value=''
      bankerrors['idProfErr'] = 'Please select correct format type upto 2 MB.';
      // message.error("Please select correct format type upto 2 MB.")
    }

    this.setState({bankerrors})
   
     

  
  };

  onChangeHandler2 = (e) => {
    e.preventDefault()
    console.log("e.target.files[0]",e.target.files[0])
    let bankerrors = this.state.bankerrors;
   
      
      let filename = e.target.files[0] != undefined ? e.target.files[0].name.split('.'): ''
    let ext = filename !='' ? filename.pop().toLowerCase(): ''
    if(ext == 'pdf'  ||  ext == 'jpg' || ext == 'png'){
      if(e.target.files[0].size>2000000)
      {
        e.target.value=''
        bankerrors['homeVerifyErr'] = 'Please select a file upto 2MB';
        // message.error('Please select a file upto 2MB')
      }
      else
      {
        this.setState({homeVerify:e.target.files[0]})
        bankerrors['homeVerifyErr'] = '';

      }
    } else {
      e.target.value=''
      bankerrors['homeVerifyErr'] = 'Please select correct format type upto 2 MB.';
      // message.error("Please select correct format type upto 2 MB.")
    }

    this.setState({bankerrors})
   

  
  };

  deleteConnectedAcc = (acct_id, user_uid) => {
    axios.post(`/users/remove-connectedAcc`, {acct_id,user_uid}).then((res) => {
      if(res.data.deleted) {
        return true
      } 
      else {
        return false
      }
    }).catch((err) => {
      return false
    })
  }



  render() {
    const {
      showModal,
      payoutType,
      vendorAddress,
      vendorCountry,
      vendorState,
      vendorCity,
      bankAddress,
      bankCountry,
      bankState,
      bankCity,
      bankAccClass,
    } = this.state;
    return (
      <>
      
        <button onClick={this.handleShowModal} className="defaultBtn">
        Add Customer Details (KYC)
        </button>
        <Modal
          size="lg"
          show={showModal}
          onHide={this.handleShowModal}
          backdrop="static"
          // keyboard={false}
          style={{ color: "black" }}
        >
          <Form onSubmit={this.checkBeforeCreateVendorRequest}>
            <Modal.Header closeButton ref={this.ref}>
              <Modal.Title>
                <h3>Complete your KYC</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.alertVisible ? (
                <Alert
                  message={this.state.alertMessage}
                  type="error"
                  showIcon
                  closable
                  onClose={() => this.setState({ alertVisible: false })}
                />
              ) : null}

              {/* <Row>
                <Col xs={12} sm={6} md={6}>
                  <Form.Group>
                    <Form.Label>
                      Payout Type <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      size="lg"
                      value={payoutType}
                      onChange={(e) =>
                        this.setvalueBankDetails(e.target.value, "payoutType")
                      }
                      style={{ color: "black" }}
                    >
                      <option value="">Select payout type</option>
                      <option value="ACH">ACH/ECP</option>
                      <option value="CHAPS">CHAPS</option>
                      <option value="FAST">FAST BANK TRANSFER</option>
                      <option value="WIRE">WIRE</option>
                    </Form.Control>
                    <small style={{ color: "red" }}>
                      {this.state.bankerrors.payoutType === ""
                        ? ""
                        : this.state.bankerrors.payoutType}
                    </small>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={6}>
                  <Form.Group>
                    <Form.Label>
                      Payout Currency <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      style={{ color: "black" }}
                      onChange={(e) =>
                        this.setvalueBankDetails(
                          e.target.value,
                          "payoutCurrency"
                        )
                      }
                      value={this.state.payoutCurrency}
                      placeholder="Payout currency"
                      disabled={payoutType !== "WIRE"}
                    >
                      <option value="">Select payout Currency</option>
                      <option value="USD">USD</option>
                      <option value="EUR">EUR</option>
                      <option value="GBP">GBP</option>
                      <option value="CAD">CAD</option>
                    </Form.Control>
                    <small style={{ color: "red" }}>
                      {this.state.bankerrors.payoutCurrency === ""
                        ? ""
                        : this.state.bankerrors.payoutCurrency}
                    </small>
                  </Form.Group>
                </Col>
              </Row>
               */}
              {/* PERSONAL INFORMATION */}
              <Row>
                <Col sm={12} md={4}>
                  <Form.Group controlId="formBasicVendorName">
                    <Form.Label>
                      Full Name <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        this.setvalueBankDetails(e.target.value, "vendorName")
                      }
                      style={{ color: "black" }}
                      type="text"
                      value={this.state.vendorName}
                      placeholder="Profile Name"
                    />
                    <small style={{ color: "red" }}>
                      {this.state.bankerrors.vendorName === ""
                        ? ""
                        : this.state.bankerrors.vendorName}
                    </small>
                  </Form.Group>
                </Col>
                <Col sm={6} md={4}>
                  <Form.Group controlId="formBasicVendorEmail">
                    <Form.Label>
                      Email Address <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        this.setvalueBankDetails(e.target.value, "accountEmail")
                      }
                      style={{ color: "black" }}
                      type="text"
                      value={this.state.accountEmail}
                      placeholder="Email Id"
                    />
                    <small style={{ color: "red" }}>
                      {this.state.bankerrors.accountEmail === ""
                        ? ""
                        : this.state.bankerrors.accountEmail}
                    </small>
                  </Form.Group>
                </Col>
                <Col sm={6} md={4}>
                  <Form.Group controlId="formBasicVendorPhoneNumber">
                    <Form.Label>
                      Phone Number <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        this.setvalueBankDetails(e.target.value, "phoneNumber")
                      }
                      style={{ color: "black" }}
                      type="text"
                      value={this.state.phoneNumber}
                      placeholder="Phone number"
                    />
                    <small style={{ color: "red" }}>
                      {this.state.bankerrors.phoneNumber === ""
                        ? ""
                        : this.state.bankerrors.phoneNumber}
                    </small>
                  </Form.Group>
                </Col>
              </Row>

              {/* ADDRESS INFORMATION */}
              <Row>
                <Col xs={12} sm={8} md={8}>
                  {/* <Form.Group controlId="formGridAddress2">
                  <Form.Label>Address</Form.Label>
                  <Form.Control style={{ color: "black" }} value={this.state.vendorAddress} placeholder="Apartment, studio, or floor" />
                </Form.Group> */}
                  <PlacesAutocomplete
                    value={vendorAddress}
                    onChange={this.handleAddressChange}
                    onSelect={this.handleSelect}
                    // searchOptions={{componentRestrictions:{country:this.props.country.toUpperCase()}}}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <Form.Group>
                        <Form.Label>
                          Address <span style={{ color: "red" }}>*</span>
                          {/* {this.props.country.toUpperCase() === "US" ? (
                            <small style={{ color: "#9e9e9e" }}>
                              (Address must be from US)
                            </small>
                          ) : this.props.country.toUpperCase() === "GB" ? (
                            <small style={{ color: "#9e9e9e" }}>
                              (Address must be from UK)
                            </small>
                          ) : this.props.country.toUpperCase() === "IN" ? (
                            <small style={{ color: "#9e9e9e" }}>
                              (Address must be from INDIA)
                            </small>
                          ) : (
                            ""
                          )} */}
                        </Form.Label>
                        <Form.Control
                          style={{ color: "black" }}
                          type="text"
                          {...getInputProps({
                            placeholder: this.state.address
                              ? this.state.address
                              : // : pdata.location
                                // ? pdata.location
                                "Search Location ...",
                            className: "location-search-input",
                          })}
                        />

                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}

                          {suggestions.length > 0 && (
                            <div className="autocomplete-suggestion-box">
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#ccc",
                                      cursor: "pointer",
                                      borderBottom: "1px solid #abacad",
                                      paddingBottom: "2px",
                                    }
                                  : {
                                      cursor: "pointer",
                                      borderBottom: "1px solid #abacad",
                                      paddingBottom: "2px",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </Form.Group>
                    )}
                  </PlacesAutocomplete>
                  <small style={{ color: "red" }}>
                    {this.state.bankerrors.vendorAddress === ""
                      ? ""
                      : this.state.bankerrors.vendorAddress}
                  </small>
                </Col>
                {(this.props.country.toUpperCase() !== "GB") &&(
                <Col xs={12} sm={4} md={4}>
                  <Form.Group controlId="formBasicVendorCountry">
                    <Form.Label>
                      Country <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      style={{ color: "black" }}
                      type="text"
                      //value={this.state.country}
                      value={vendorCountry}
                      placeholder="Country code"
                      readOnly
                    />
                    <small style={{ color: "red" }}>
                      {this.state.bankerrors.vendorCountry === ""
                        ? ""
                        : this.state.bankerrors.vendorCountry}
                    </small>
                  </Form.Group>
                </Col>
              )}
              </Row>
              <Row>
               
                  <Col sm={4} md={4}>
                    <Form.Group controlId="formGridState">
                      <Form.Label>
                         {/* {this.props.country.toUpperCase() !== "GB"? 'State': 'Country'} */}
                         State/Province/Area/County
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        style={{ color: "black" }}
                        value={vendorState}
                        // placeholder= {this.props.country.toUpperCase() !== "GB"? 'State code': 'Country'}
                        placeholder= "State/Province/Area/County"
                        readOnly
                      />
                      <small style={{ color: "red" }}>
                        {this.state.bankerrors.vendorState === ""
                          ? ""
                          : this.state.bankerrors.vendorState}
                      </small>
                    </Form.Group>
                  </Col>
               
                <Col xs={12} sm={4} md={4}>
                  <Form.Group controlId="formGridCity">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      style={{ color: "black" }}
                      value={vendorCity}
                      onChange={(e) =>
                        this.setvalueBankDetails(e.target.value, "vendorCity")
                      }
                      placeholder="City"
                      //readOnly
                    />
                  </Form.Group>
                </Col>

                <Col xs={12} sm={4} md={4}>
                  <Form.Group controlId="formGridZip">
                    <Form.Label>
                    Zip Code/ Postal Code <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      style={{ color: "black" }}
                      onChange={(e) =>
                        this.setvalueBankDetails(e.target.value, "vendorZip")
                      }
                      type="text"
                      placeholder="Zip Code/Postal Code"
                      value={this.state.vendorZip}
                    />
                    <small style={{ color: "red" }}>
                      {this.state.bankerrors.vendorZip === ""
                        ? ""
                        : this.state.bankerrors.vendorZip}
                    </small>
                  </Form.Group>
                </Col>
              </Row>



              <Row>
                <Col sm={12} md={4}>
                  <Form.Group>
                    <Form.Label>
                      Date Of Birth <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <DatePicker
                      onChange={(date, dateString) =>
                        this.setvalueBankDetails(dateString, "vendorDOB")
                      }
                      value={
                        this.state.vendorDOB === ""
                          ? ""
                          : moment(this.state.vendorDOB, "DD-MM-YYYY")
                      }
                      format="DD-MM-YYYY"
                    />
                    <small style={{ color: "red" }}>
                      {this.state.bankerrors.vendorDOB === ""
                        ? ""
                        : this.state.bankerrors.vendorDOB}
                    </small>
                  </Form.Group>
                </Col>


                     <Col sm={12} md={4}>
                        <Form.Group>
                          <Form.Label>
                              ID Proof <span style={{ color: "red" }}>*</span>
                            <Tooltip title={this.state.kyc_id_document} placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>
                          </Form.Label>
                          <Form.Control
                            className="file-upload"
                            type="file"
                            name="idProf"
                            id="idProf"
                            onChange={this.onChangeHandler}
                            accept="application/pdf,image/*"
                          />
                        <small style={{ color: "red" }}>
                          {this.state.bankerrors.idProfErr === ""
                            ? ""
                            : this.state.bankerrors.idProfErr}
                        </small>
                        </Form.Group>
                  </Col>

                  <Col sm={12} md={4}>
                        <Form.Group>
                          <Form.Label>
                              Address Proof <span style={{ color: "red" }}>*</span>
                            <Tooltip title={this.state.kyc_address_document} placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>
                          </Form.Label>
                          <Form.Control
                            className="file-upload"
                            type="file"
                            name="homeVerify"
                            id="homeVerify"
                            onChange={this.onChangeHandler2}
                            accept="application/pdf,image/*"
                          />
                          <small style={{ color: "red" }}>
                            {this.state.bankerrors.homeVerifyErr === ""
                              ? ""
                              : this.state.bankerrors.homeVerifyErr}
                          </small>
                        </Form.Group>
                  </Col>

              </Row>
              {(vendorCountry.toUpperCase() === "US") &&(
                <Row>
                  <Col xs={12} sm={12} md={6}>
                  <Form.Group controlId="formBasicBankId">
                    <Form.Label>
                    SSN Last 4 Digit <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        this.setvalueBankDetails(e.target.value, "ssnLast4")
                      }
                      placeholder="SSN Last 4 Digit"
                      style={{ color: "black" }}
                      type="text"
                      value={this.state.ssnLast4}
                    />
                    <small style={{ color: "red" }}>
                      {this.state.bankerrors.ssnLast4 === ""
                        ? ""
                        : this.state.bankerrors.ssnLast4}
                    </small>
                  </Form.Group>
                      </Col>
                      <Col sm={12} md={6}>
                  <Form.Group>
                    <Form.Label>
                      Identification Number{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      style={{ color: "black" }}
                      onChange={(e) =>
                        this.setvalueBankDetails(
                          e.target.value,
                          "identificationNumber"
                        )
                      }
                      type="text"
                      value={this.state.identificationNumber}
                      
                    />
                    <small style={{ color: "red" }}>
                      {this.state.bankerrors.identificationNumber === ""
                        ? ""
                        : this.state.bankerrors.identificationNumber}
                    </small>
                  </Form.Group>
                </Col>

                </Row>
              )}
              {/* BANK DETAILS */}















              {/* <Row>
                <Col xs={12} sm={12} md={12}>
                  <Form.Group controlId="formBasicHolderName">
                    <Form.Label>
                      Account Holder Name{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        this.setvalueBankDetails(e.target.value, "accountName")
                      }
                      style={{ color: "black" }}
                      type="text"
                      placeholder="Name Of Account Holder"
                    />
                    <small style={{ color: "red" }}>
                      {this.state.bankerrors.accountName === ""
                        ? ""
                        : this.state.bankerrors.accountName}
                    </small>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} md={6}>
                  <Form.Group controlId="formBasicBankName">
                    <Form.Label>
                      Bank Name <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        this.setvalueBankDetails(
                          e.target.value,
                          "accountBankname"
                        )
                      }
                      style={{ color: "black" }}
                      type="text"
                      placeholder="Name Of Bank"
                      value={this.state.accountBankname}
                    />

                    <small style={{ color: "red" }}>
                      {this.state.bankerrors.accountBankname === ""
                        ? ""
                        : this.state.bankerrors.accountBankname}
                    </small>
                  </Form.Group>
                </Col>

                <Col xs={12} sm={6} md={6}>
                  <Form.Group controlId="formBasicBankId">
                    <Form.Label>
                    Bank Id/ Sort Code <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        this.setvalueBankDetails(e.target.value, "bankId")
                      }
                      placeholder="Enter Bank Id/Sort Code"
                      style={{ color: "black" }}
                      type="text"
                      value={this.state.bankId}
                    />
                    <small style={{ color: "red" }}>
                      {this.state.bankerrors.bankId === ""
                        ? ""
                        : this.state.bankerrors.bankId}
                    </small>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={6} md={6}>
                  <Form.Group>
                    <Form.Label>
                      Bank Account Class <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      size="lg"
                      style={{ color: "black" }}
                      value={this.state.bankAccClass}
                      onChange={(e) =>
                        this.setvalueBankDetails(e.target.value, "bankAccClass")
                      }
                    >
                      <option value="">Select bank account class</option>
                      <option value="PERSONAL">PERSONAL</option>
                      <option value="CORPORATE">CORPORATE</option>
                      <option value="INTERNATIONAL">INTERNATIONAL</option>
                    </Form.Control>
                    <small style={{ color: "red" }}>
                      {this.state.bankerrors.bankAccClass === ""
                        ? ""
                        : this.state.bankerrors.bankAccClass}
                    </small>
                  </Form.Group>
                </Col>
                {bankAccClass === "INTERNATIONAL" ? (
                  <Col xs={12} sm={6} md={6}>
                    <Form.Group controlId="formBasicIban">
                      <Form.Label>
                        International Bank Account Number{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        onChange={(e) =>
                          this.setvalueBankDetails(e.target.value, "vendorIBAN")
                        }
                        placeholder="Enter International bank acc number"
                        style={{ color: "black" }}
                        type="text"
                        value={this.state.vendorIBAN}
                      />
                      <small style={{ color: "red" }}>
                        {this.state.bankerrors.vendorIBAN === ""
                          ? ""
                          : this.state.bankerrors.vendorIBAN}
                      </small>
                    </Form.Group>
                  </Col>
                ) : (
                  <Col xs={12} sm={6} md={6}>
                    <Form.Group controlId="formBasicAccountNumber">
                      <Form.Label>
                        Account Number <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        onChange={(e) =>
                          this.setvalueBankDetails(
                            e.target.value,
                            "accountNumber"
                          )
                        }
                        style={{ color: "black" }}
                        type="text"
                        value={this.state.accountNumber}
                        placeholder="Account Number"
                      />
                      <small style={{ color: "red" }}>
                        {this.state.bankerrors.accountNumber === ""
                          ? ""
                          : this.state.bankerrors.accountNumber}
                      </small>
                    </Form.Group>
                  </Col>
                )}
              </Row>

              <Row>
                <Col xs={12} sm={12} md={12}>
                  <Form.Group>
                    <Form.Label>
                      Account Type <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      size="lg"
                      style={{ color: "black" }}
                      value={this.state.bankAccType}
                      onChange={(e) =>
                        this.setvalueBankDetails(e.target.value, "bankAccType")
                      }
                    >
                      <option value="">Select Account Type</option>
                      <option value="CHECKING">CHECKING</option>
                      <option value="SAVINGS">SAVINGS</option>
                      <option value="CURRENT">CURRENT</option>
                    </Form.Control>
                    <small style={{ color: "red" }}>
                      {this.state.bankerrors.bankAccType === ""
                        ? ""
                        : this.state.bankerrors.bankAccType}
                    </small>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={6} md={8}>
                  <PlacesAutocomplete
                    value={bankAddress}
                    onChange={this.handleBankAddressChange}
                    onSelect={this.handleSelectBankAddress}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <Form.Group>
                        <Form.Label>
                          Bank Address <span style={{ color: "red" }}>*</span>{" "}
                          {payoutType === "ACH" ? (
                            <small style={{ color: "#9e9e9e" }}>
                              (Address must be from US)
                            </small>
                          ) : payoutType === "FAST" ||
                            payoutType === "CHAPS" ? (
                            <small style={{ color: "#9e9e9e" }}>
                              (Address must be from UK)
                            </small>
                          ) : payoutType === "WIRE" ? (
                            <small style={{ color: "#9e9e9e" }}>
                              (Address can be from UK/US or outside of UK/US)
                            </small>
                          ) : (
                            ""
                          )}
                        </Form.Label>
                        <Form.Control
                          style={{ color: "black" }}
                          type="text"
                          {...getInputProps({
                            placeholder: this.state.address
                              ? this.state.address
                              : // : pdata.location
                                // ? pdata.location
                                "Search Location ...",
                            className: "location-search-input",
                          })}
                          readOnly={payoutType === ""}
                        />

                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}

                          {suggestions.length > 0 && (
                            <div className="autocomplete-suggestion-box">
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#ccc",
                                      cursor: "pointer",
                                      borderBottom: "1px solid #abacad",
                                      paddingBottom: "2px",
                                    }
                                  : {
                                      cursor: "pointer",
                                      borderBottom: "1px solid #abacad",
                                      paddingBottom: "2px",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </Form.Group>
                    )}
                  </PlacesAutocomplete>
                  <small style={{ color: "red" }}>
                    {this.state.bankerrors.bankAddress === ""
                      ? ""
                      : this.state.bankerrors.bankAddress}
                  </small>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <Form.Group controlId="formBasicBankCountry">
                    <Form.Label>
                      Bank Country <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      style={{ color: "black" }}
                      type="text"
                      //value={this.state.country}
                      value={bankCountry}
                      placeholder="Country code"
                      readOnly
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {payoutType === "ACH" ? (
                  <Col xs={12} sm={12} md={12}>
                    <Form.Group controlId="formGridStateTwo">
                      <Form.Label>
                        Bank State <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        style={{ color: "black" }}
                        value={bankState}
                        placeholder="State code"
                        readOnly
                      />
                      <small style={{ color: "red" }}>
                        {this.state.bankerrors.bankState === ""
                          ? ""
                          : this.state.bankerrors.bankState}
                      </small>
                    </Form.Group>
                  </Col>
                ) : (
                  ""
                )}

                <Col xs={12} sm={6} md={6}>
                  <Form.Group controlId="formGridCityTwo">
                    <Form.Label>Bank City</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        this.setvalueBankDetails(e.target.value, "bankCity")
                      }
                      style={{ color: "black" }}
                      value={bankCity}
                      placeholder="City / Street Name"
                      //readOnly
                    />
                  </Form.Group>
                </Col>

                <Col xs={12} sm={6} md={6}>
                  <Form.Group controlId="formGridZipTwo">
                    <Form.Label>
                    Bank Zip/ Postal Code <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      style={{ color: "black" }}
                      onChange={(e) =>
                        this.setvalueBankDetails(e.target.value, "bankZip")
                      }
                      type="text"
                      placeholder="Bank Zip/Postal Code"
                      value={this.state.bankZip}
                    />
                    <small style={{ color: "red" }}>
                      {this.state.bankerrors.bankZip === ""
                        ? ""
                        : this.state.bankerrors.bankZip}
                    </small>
                  </Form.Group>
                </Col>
              </Row>
              
               */}

              <Row>
                <Col xs={12} sm={12} md={12}>
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      checked={this.state.dataAccurate}
                      onChange={(e) =>
                        this.setvalueBankDetails(
                          e.target.checked,
                          "dataAccurate"
                        )
                      }
                      type="checkbox"
                      label="Above information is accurate"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              
            </Modal.Body>
            <br />

            <Modal.Footer>
              {/* {this.state.savecardloader === true ? (
              <Spinner animation="border" variant="light" disabled/>
            ) : (
              ""
            )} */}
              <Button
                className="defaultBtn"
                variant="default"
                type="submit"
                //onClick={this.checkBeforeCreateVendorRequest}
                disabled={this.state.saveLoader}
              >
                {this.state.saveLoader ? (
                  <>
                    <Spinner
                      animation="border"
                      variant="light"
                      as="span"
                      size="sm"
                      aria-hidden="true"
                    />{" "}
                    Loading...
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default BankDetailsModal;
