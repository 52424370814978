import React from "react";
import { sArrow, t1, quality, tickSvg } from "../../assets/images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Row, Col } from "react-bootstrap";
import { avatar_home, avatar } from "../../assets/img";
import { Link } from 'react-router-dom'
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

class Coachesarea extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ispublicmode:true
    }
  }
  componentDidMount = () => {
    let { coachesdata } = this.props;
    console.log("coaches data", coachesdata)
    this.handlepublicview()
    // if (coachesdata.length === 0) {
    //   settings.infinite = false;
    //   console.log("settings", settings.infinite);
    // }
  };

  componentDidUpdate = (prevProps) => {
    let { coachesdata } = this.props;
    if(prevProps !== this.props) {
      if(coachesdata.length > 0 && coachesdata.length <= 3) {
        settings.infinite = false;
      }
    }
  }

  handlepublicview = () =>{
    let userdata = JSON.parse(localStorage.getItem('userdata'));
    if (userdata == undefined) {
      this.setState({ispublicmode:true})
      // this.props.history.push('/login')
    } else {
      if (userdata !== null && userdata !== undefined) {

        if (userdata.account_type === 'user') {
          this.setState({ispublicmode:false})
        }
      }
    }

  }

  render() {
    // console.log("settings", settings.infinite);
    let { coachesdata } = this.props;

    return (
      <div className="carousell_part">
        <Container>
          <div className="heading text-center">
            <h3 style={{fontSize: "28px", fontWeight: "400", textTransform: "capitalize"}}>Coaches</h3>
            <span className="R_divider div_dark"></span>
          </div>
          {coachesdata.length > 0 && (
            <Slider {...settings} className="owl-theme">
              {coachesdata.map((coach, i) => {
                let rawaddress = JSON.parse(coach.address);
                let coachingCategories = coach.coaching_category
                coachingCategories = coachingCategories !== "" && coachingCategories !== null ? JSON.parse(coachingCategories): []

                let star = coach.avg_rating;
                let arr = [1, 2, 3];
                return (
                  // <div>
                  //     <div className="carousel_item">
                  //     <div className="carousel_inner_item">
                  //         <img src={each.avatar_path!=null?each.avatar_path:avatar_home} className="img-fluid"/>
                  //     </div>
                  //     <div className="car_caption">
                  //         <h3>{each.name}</h3>
                  //         <span className="R_divider"></span>
                  //         <p>{each.short_bio && each.short_bio.substring(0,120)}...</p>
                  //     </div>
                  //     </div>
                  // </div>

                  <div>
                    <div className="carousel_item">
                      <div className="searchPeopleList feature-coach">
                        <div className="sImgHolder">
                          <img
                            className="sUserImg"
                            alt="LG-Img"
                            src={
                              coach.avatar_path != null
                                ? coach.avatar_path
                                : avatar
                            }
                          />
                          {coach.has_membership ? (
                            <img
                              className="sbi"
                              alt="LG-Img"
                              width="35px"
                              src={quality}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <h4 style={{minHeight: "32px"}}>{coach.name}</h4>
                        {/* {coach.user_title != null && (
                          <h6 className="color-dark-grey">
                            {coach.user_title}
                          </h6>
                        )} */}
                        <div className="coaching-categories">
                        {coachingCategories.length > 0 &&
                          coachingCategories
                            .slice(0, 2)
                            .map((cat) => {
                              return (
                                <h6 className="category-bold">
                                  <span>{cat.value}</span>
                                </h6>
                              );
                            })}
                        </div>
                        <h5>
                          {coach.city_public === 1
                            ? rawaddress &&
                              rawaddress.city &&
                              rawaddress.city + ","
                            : ""}
                          {rawaddress && rawaddress.country}
                        </h5>

                        {console.log("rates", coach.name, star)}
                        <div className="ratingView">
                          {arr.map((a, index2) => {
                            return (
                              <i
                                key={index2}
                                className={
                                  star >= a
                                    ? "fa fa-star rActive"
                                    : "fa fa-star"
                                }
                                aria-hidden="true"
                              ></i>
                            );
                          })}
                          {/* <i className="fa fa-star" aria-hidden="true"></i>
              <i className="fa fa-star" aria-hidden="true"></i> */}
                        </div>
                        <p className="color-grey">
                          {coach.short_bio && coach.short_bio.substring(0, 120)}
                          ...
                        </p>
                        <Link
                        to={{
                          //  pathname: "/profile-coach-view",
                          pathname: this.state.ispublicmode === true ?  `/coach-profile/${coach.name}/${coach.coach_uid}`:`/profile-coach-view/${coach.coach_uid}`,

                          state: { 
                            coachData: coach,
                            ConsumerD: {uid:this.props.Consumer_uid, name:this.props.Consumer_name, email:this.props.Consumer_email },
                          
                          }
                        }}
                        className="defaultBtn"
                      >
                        {/* <img
                          style={{display: "inline"}}
                          width="20px"
                          alt="LG-Img"
                          className="mr-1"
                          src={tickSvg}
                        /> */}
                        {" "}
                        View Details
                      </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          )}
        </Container>
      </div>
    );
  }
}
export default Coachesarea;
