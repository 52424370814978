import React from 'react'
import { Link } from "react-router-dom";
import {Tab, Nav, Accordion, Card, Button} from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
//import BounceLoader from "react-spinners/BounceLoader";

import { FooterLink } from '../components/footers'
import { Header, HeaderLogout, HeaderTop } from '../components/headers'
import axios from "../config/axios";
import CustomImageLoader from 'react-custom-image-loader.'
import { LGspinner} from '../assets/images'
import HeaderSecondTo from '../components/corporate/Header/HeaderSecondTo';

class FAQ extends React.Component{
    constructor(props){
        super()
        this.state = {
          faqs: [],
          loader: false,
        }
    }

    componentDidMount() {
      //window.scrollTo(0,0);
      this.getfaqContent()
    }
  
    getfaqContent = () => {
      this.setState({loader: true});
      let self = this
      axios.get('/cmsfaqs/faqContent')
      .then(async (response) => {
        this.setState({loader: false});
        if(response.status === 200){
          self.setState({
            faqs: response.data.value.faqs,
          })
        }
      })
      .catch((e) => {
        this.setState({loader: false});
        console.log(e)
      })
    }

    render(){
        return(
            <div>
              <LoadingOverlay active={this.state.loader} 
              styles={{
                wrapper: {
                  overflow: this.state.loader ? 'hidden' : ''
                }
              }}
              //spinner={<BounceLoader />}
                //spinner
                spinner={<CustomImageLoader image={LGspinner} animationType={'none'}/>}
              >
              {
                !localStorage.getItem("subdomain") && (localStorage.getItem("authToken") == null ? (<HeaderTop />) : (<HeaderLogout />))
              }
              {
                localStorage.getItem("subdomain") ? (<HeaderSecondTo />) : (<Header />)
              }

            <section className="faqWrap">
          <div className="container aos-init aos-animate">
            <div className="heading text-center">
              <h3>FAQ</h3>
              <span className="R_divider div_dark"></span>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-9">
              <h4>General</h4>
              <Accordion>
                  {/* <h4>Free Trial</h4>
                  <div className="card">
                    <div className="card-header p-0" id="headingOne">
                      <button
                        type="button"
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="false"
                      >
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                        How does the free trial work?
                      </button>
                    </div>
                    <div
                      id="collapseOne"
                      className="collapse"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida. Risus commodo viverra maecenas accumsan lacus
                          vel facilisis. Quis ipsum suspendisse ultrices
                          gravida.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header p-0" id="headingOne">
                      <button
                        type="button"
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseOne1"
                      >
                        <i className="fa fa-angle-down" aria-hidden="true"></i>
                        Who is eligible to participate in gree trail?
                      </button>
                    </div>
                    <div
                      id="collapseOne1"
                      className="collapse"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Quis ipsum suspendisse ultrices
                          gravida. Risus commodo viverra maecenas accumsan lacus
                          vel facilisis. Quis ipsum suspendisse ultrices
                          gravida.
                        </p>
                      </div>
                    </div>
                  </div> */}

                  {/* <h4>General</h4> */}
                  {this.state.faqs === [] ? "" : 
                    this.state.faqs.map((each, index) => {
                      return (
                        
                          <Card >
                          <Card.Header className="p-0 faq_head">
                            <Accordion.Toggle as={Button} variant="link" eventKey={`collapseOne${each.id+1}`}>
                            <h2 className="mb-0">
                              {/* <button
                                type="button"
                                className="btn btn-link collapsed"
                                // data-toggle="collapse"
                                // data-target={`#collapseOne${each.id+1}`}
                              > */}
                                <i
                                  className="fa fa-angle-down"
                                  aria-hidden="true"
                                ></i>
                                {each.question}
                              {/* </button> */}
                            </h2>
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={`collapseOne${each.id+1}`}>
                            <Card.Body>
                            
                              <p>
                                {each.answer}
                              </p>
                            
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        
                      )
                    })
                  }
                </Accordion>
              </div>
            </div>
            
          </div>
        </section>
        <FooterLink/>
            
        </LoadingOverlay>
            </div>
        )
    }
}

export default FAQ