import React, { useState,useEffect } from 'react';
import { Container, Row, Col, Card, Pagination ,Button} from 'react-bootstrap';
import axios from "../../../config/axios";
import moment from "moment";
import { VideoPlayer } from "../../../components/media";
import RecordingModal from "../schedule/RecordingModal";
import { message } from "antd";

const WebinarLibrary = (props) => {
    const itemsPerPage = 9; // Number of recordings to display per page
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState("");
    const [category, setCategory] = useState("");
    const [currentRecordings, setCurrentRecordings] = useState([]);
    const [dummyRecordings, setDummyRecordings] = useState([]);
    const [list, setCategories] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const [show, setShow] = useState(false);
    const [modal, setModal] = useState(false);
    
    let date_today = new Date();
    let firstDay = new Date(date_today.getFullYear(), date_today.getMonth(), 1);
    firstDay = firstDay.toLocaleDateString("en-CA", { // you can use undefined as first argument
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    
    let lastDay = new Date(date_today.getFullYear(), date_today.getMonth() + 1, 0);
    lastDay = lastDay.toLocaleDateString("en-CA", { // you can use undefined as first argument
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    console.log("enddate",lastDay,firstDay)
    // const [start_date, setStartDate] = useState(`${firstDay.getFullYear()}-${firstDay.getMonth()}-${firstDay.getDate()}`);
    // const [end_date, setEndDate] = useState(`${lastDay.getMonth()}-${lastDay.getDate()}-${lastDay.getFullYear()}`);
    const [start_date, setStartDate] = useState(firstDay)
    const [end_date, setEndDate] = useState(lastDay)

    const getCategories = ()=>{
        let url = "webinar_categorie";
        if(props.type){
          url = "careermaster_categorie";
        }
        axios.get(`/${url}`, {}, {}).then((res) => {
            if(res.data.success){
                setCategories(res.data.deta);
            }else{
                setCategories([]);
            }
        }).catch((err) => {
            setCategories([]);
        });
    }
    
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        handleReords(dummyRecordings,pageNumber);
       
    }

    const handleReords = (data,pageNumber)=>{
        if(!pageNumber) pageNumber = activePage;  
        let indexOfLastRecording = pageNumber * itemsPerPage;
        let indexOfFirstRecording = indexOfLastRecording - itemsPerPage;
        setCurrentRecordings(data.slice(indexOfFirstRecording, indexOfLastRecording));
    }

    const getRecordings = ()=>{

       
        axios.post('/webinar/GetJoinWebiner', { 
            pageNumber: activePage,
            search : search,
            userID : JSON.parse(localStorage.getItem("userdata")).uid,
            api_det : "CUSTOM",
            category : category,
            start_date : start_date,
            end_date : end_date
        }, { headers: { "x-auth": localStorage.getItem("authToken")}, }).then((res) => {
            if(res.data.success){
                setDummyRecordings(res.data.data)
                handleReords(res.data.data,1);
            }else{
                setDummyRecordings({});
                setCurrentRecordings({});
            }
          }).catch((err) => {
            console.log(err)
          })
    }

  useEffect(()=>{
    getRecordings();
    if(isFirst){
        getCategories();
        setIsFirst(false);
    }
    
  },[search,category,start_date,end_date]);

  const handleClose = () => {
    setShow(false)
  };

  const handleShow = (meetingID) => {
   setShow(true)
    axios.post('/webinar/get-recording', { meetingID: meetingID }, { headers: { "x-auth": localStorage.getItem("authToken"), }, }).then((res) => {
      if (res.data.success) {
        setModal(<RecordingModal show={true} handleClose={handleClose} Recordings={res.data.data ? res.data.data : []} />)
      }
    }).catch((err) => {
      console.log(err)
      message.error("Something went wrong");
    })
  }

  return (

    

    <Container>
         <Row style={{"marginTop":"2rem"}}>
         <Col  md={3}>
            <label htmlFor="">Start Date</label>
            <input type="date"  onChange={(e)=>{
              setStartDate(e.target.value)
            }}  defaultValue={start_date}  className='form-control'/>
          </Col>
          <Col  md={3}>
            <label htmlFor="">End Date</label>
            <input type="date" onChange={(e)=>{
              setEndDate(e.target.value)
            }} defaultValue={end_date}  className='form-control'/>
          </Col>
      <Col  md={3}>
        <label>Search By Title</label>
        <input type="text" placeholder="Enter at least 2 characters to search" className="form-control" onChange={(e)=>{
            if(e.target.value.length >= 2 || e.target.value.length == 0){
                setSearch(e.target.value);
            }
        }}/>
        </Col>

      <Col  md={3}>
        <label>Select Category</label>
        <select className="form-control" defaultValue={category} onChange={(e)=>{
            setCategory(e.target.value);
        }}>
            <option value="">All</option>
            {list.length >= 1 && list?.map((item,i)=>(
                <option key={i} value={item.value}>{item.label}</option>
            ))}
        </select>
        </Col>
        </Row>
      <Row style={{"marginTop":"2rem"}}>

     

        {currentRecordings.length >= 1 && currentRecordings?.map((recording,i) => (
          <Col key={i} md={4} style={{"marginTop":"2rem"}}>
            <Card className="bg-dark text-white">
                {recording.video 
                ? 
                 <VideoPlayer
                    id={recording.i}
                    src={recording.video}
                    poster={
                        "https://markipo.com/Markipo_Reportages_Cyclisme_13_10/res/video.poster.jpg"
                    }
                    /> 
                : 
                <Card.Img src={recording.image} alt="Card image" />
                }
                
                
                    <Card.Body>
                        <Card.Title>{recording.title}</Card.Title>
                        <Card.Text>{recording.categrory_name}</Card.Text>
                        <Card.Text>{recording.description}</Card.Text>
                        <button type="button" className="join_button" style={{ "whiteSpace": "nowrap" }} onClick={() => handleShow(recording.bbb_meetingID)}>
                          Get Recordings
                        </button>
                    </Card.Body>
                <Card.Footer className="text-muted">{ moment(recording.created_at).format("YYYY-MM-DD")}</Card.Footer>
                </Card>
          </Col>
        ))}
      </Row>

      {(show === true) ? modal : ''}

      <Pagination style={{"marginTop":"2rem"}}>
        {Array.from({ length: Math.ceil(dummyRecordings?.length / itemsPerPage) }).map((_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === activePage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </Container>
  );
};

export default WebinarLibrary;
