import React from 'react'
import {Link} from 'react-router-dom'
import { dArrowblack } from '../../assets/images'

class Price extends React.Component{
  constructor(props) {
    super(props);
   
  }
    render(){
        return(
            <div>
                <fieldset>
        <div className="heading text-center">
          <h3 className="fs-subtitle">Hourly Rate ({localStorage.getItem('currency')=='USD' || localStorage.getItem('currency')==undefined?'$':localStorage.getItem('currency')=='GBP'?'£':'€'})</h3>
          <span className="R_divider"></span>
        </div>
        <div className="form-group">
            
            <div className="selectBox"  style={{color:'black'}}>
                          {/* <input
                            style={{color:"black"}}
                            type="number"
                            placeholder="Hourly Rate"
                            value={this.props.data.Hourlyrate}
                            onChange={this.props.handleChange}
                            name='Hourlyrate'
                          /> */}
             
              <select  className="form-control" value={this.props.data.Hourlyrate} onChange={this.props.handleChange} name='Hourlyrate'>
                 {/* <option value="" selected="selected">Select Hourly Rate</option>
                 <option value="0-50">$/£50 p/session</option>
                <option value="51-100">$/£51-100 p/hr</option>
                <option value="101-150">$/£101-150</option>
                <option value="150-20000">$/£150+</option> */}
                <option value="" selected="selected">Any</option>
                        <option value="0-50"> Up To 50</option>
                        <option value="51-100"> 51 to 100</option>
                        <option value="101-150"> 101 to 150</option>
                        <option value="151-200"> 151 to 200</option>
                        <option value="200-300"> 200 to 300</option>
                        <option value="300-20000"> 300+</option>
              </select>
              
              <span>
                  <img src={dArrowblack} alt="LG-Img"/>
              </span>
            </div>
          </div>
        <Link
        to="#"
          onClick={() =>
          this.props.handleField("Language")
          }
         className="defaultBtn">PREVIOUS
        </Link>{" "}

        <Link
        to="#"
          onClick={() =>
          this.props.handleField("Star")
          }
         className="defaultBtn">Next
        </Link>
       
      </fieldset>
            </div>
        )
    }
}
export default Price