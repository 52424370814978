import React, { Component } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";
import axios from '../../../config/axios';
import Calendar from 'react-calendar';
import BounceLoader from "react-spinners/BounceLoader";
import LoadingOverlay from "react-loading-overlay";
import message from '../../../utils/mesaages';
import CustomImageLoader from 'react-custom-image-loader.'
import { LGspinner } from '../../../assets/images'
import CommunicationUsers, { SessionBookedNotify } from "../../../layouts/Communication/CommunicationUsers";


import "../../../assets/css/style.css";
const _ = require("lodash");



class UpdateSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: "",
      setShow: true,
      avilDays: [],
      loader: false,
      checkedSlot: "",
      tags: "",
      // avatar_path: avatar,
      selectedSlot: '',
      sessionDate: "2021-01-28",
      slots: [],
      currMonth: moment().format('MMMM'),
      currDate: moment().format('DD-MM-YYYY'),
      date: new Date(),
      disableDate: []
    };

  }

  componentDidMount() {

    console.clear();
    console.log('this update edit props', this.props)
    //console.log('ddddddd ', moment(this.props.sessionDate).format('YYYY-MM-DD'))
    this.getAvailWeekdays()
    var currentSEtime = this.props.start_time + ' ' + this.props.end_time;
    this.setState({ checkedSlot: currentSEtime })

    //this.getAvailability()
  }

  // componentDidUpdate()
  // {
  //   this.setState({date:new Date(this.props.sessionDate),
  //     currDate:moment(this.props.sessionDate).format('YYYY-MM-DD')
  //   },()=>{
  //     setTimeout(this.getAvailability(), 1200);
  //   })

  // }

  getAvailWeekdays = () => {
    // let {coachValidate,ConsumerD, packageValidate,packageDetails,coachDetails,userDetails, userID, coachID,packageID} = this.state

    //let id=coachID
    let from = { coach_id: this.props.coachUid, user_id: this.props.user_uid }
    axios.post(`/coach/setavailability/getavailCoach`, from).then((res) => {
      if (res.data.success) {
        this.setState({ avilDays: res.data.value, disableDate: res.data.disableDate })
      }

    }).catch((err) => {

    })

  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  tileDisabled = ({ activeStartDate, date, view }) => {
    if (view == 'month') {
      let day = date.getDay();
      let arrayweekavail = this.state.avilDays
      let arrayDateUnavailble = this.state.disableDate
      // ...convert number to weekname or directly through moment
      return !arrayweekavail.includes(day) || _.find(arrayDateUnavailble, { 'date': this.formatDate(date) }) != undefined

    }
    else {
      return false

    }


  }

  tileClassName = ({ activeStartDate, date, view }) => {
    let today = date.getDate();

    if (today == new Date()) {
      return 'inactive'
    }
    else {
      return ''
    }


  }


  async getAvailability(callstate = null) {

    if (callstate !== 'change') {
      await this.setState({
        date: new Date(this.props.sessionDate),
        currDate: moment(this.props.sessionDate).format('DD-MM-YYYY')
      }, () => {
        // setTimeout(this.getAvailability(), 1200);
      })
    }

    console.log('hh')
    let id = this.props.coachUid
    let from = { coach_id: this.props.coachUid, pack_id: this.props.package_uid, date: this.state.currDate, sessionType: this.props.sessionType, duration: this.props.duration }
    console.log('form data ', from)
    axios.post(`/coach/setavailability/filteredCoach`, from)

      .then(res => {
        console.log("availabity---res--", res)
        if (res.data.success) {
          this.setState({ slots: res.data.value })
          //toast("Slots Found", { type: "success" });

        }
        if (res.data.value.length <= 0) {
          message.error(`No slots available on ${this.state.currDate}`);

        }
        // else
        // {
        //   message.success("Slots Found");
        // }
        // console.log("resss---vail",res)
      })
      .catch(err => {
        console.log(err)
        message.error("Failed! Something Went Wrong");

      })
  }





  dateChange = (date) => {

    this.setState({ date, currDate: moment(date).format('YYYY-MM-DD') },
      () => {
        console.log('date', date)
        console.log('formatdate', moment(date).format('YYYY-MM-DD'))
        this.getAvailability('change')
      })

  }

  handleClose() {
    //this.props.close=this.props.close;
  }

  chooseSlot = (e) => {
    console.log(e.target.value.from)
    let v = e.target.value
    let from = v.split(' ')[0]
    let to = v.split(' ')[1]
    console.log(v)
    console.log(from)
    console.log(to)
    if (from !== undefined && to !== undefined) {
      let selectedSlot = { date: this.state.currDate, from: from, to: to, checkedSlot: v }
      this.setState({ selectedSlot })
    }

  }


  handleSubmit = (e) => {
    e.preventDefault();
    console.log('save ', this.state.selectedSlot)
    let id = this.props.coachUid
    let user_id = this.props.user_uid
    let from = {
      coach_id: id, pack_id: this.props.package_uid, date: this.state.currDate, sessionType: this.props.sessionType,
      user_uid: this.props.user_uid, uid: this.props.uid, selectedSlot: this.state.selectedSlot
    }
    console.log(from)
    var self = this;
    self.setState({ loader: true });
    axios.post(`/users/session/update-booking`, from)

      .then(res => {
        self.setState({ loader: false });
        if (res.data.success) {

          message.success("Rescheduled successfully.");
          SessionBookedNotify(res.data.data, "rescheduleNotification")
          setTimeout(() => {
            window.location.replace(`${window.location.origin}/profile-coach#My-Schedule`)
            window.location.reload()


          }, 3000);

        }

        // console.log("resss---vail",res)
      })
      .catch(err => {
        self.setState({ loader: false });
        console.log(err)
        message.error("Failed! Something Went Wrong");

      })

  };

  render() {
    return (
      <>
        <h3
          className="popup-with-zoom-anim edit_item"
          data-toggle="tooltip"
          data-placement="top"
          title="Add/Update New Tag"
          onClick={this.handleShow}
          style={{ color: "#734d9d" }}
        >
          {/* MANAGE TAGS<img width="18px" src={writingSvg} alt="LG-Img" /> */}
        </h3>

        <Modal
          show={this.props.show}
          onHide={this.props.close}
          backdrop="static"
          keyboard={false}
          onShow={() => this.getAvailability()}
        >
          <Form onSubmit={this.props.handleSubmit} style={{ background: '#f5f5f5' }}>
            <LoadingOverlay
              active={this.state.loader}
              //spinner={<BounceLoader />}
              spinner={<CustomImageLoader image={LGspinner} animationType={'pulse'} />}
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ color: "black" }}>Change Date & Time Schedule <br />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group style={{ color: "black" }}>


                  <div className="md-form bookingCalendar" >
                    <div className="app__main">
                      <Calendar
                        onChange={this.dateChange} g
                        value={this.state.date}
                        className="calendar"
                        minDate={new Date()}
                        tileDisabled={this.tileDisabled}
                        tileClassName={this.tileClassName}

                      />
                    </div>
                  </div>

                  <div className="">
                    <div className="areas areasNew" style={{ justifyContent: "center" }}>
                      {this.state.slots.length > 0 ? (
                        this.state.slots.map((slot, index) => {

                          var sTime = slot.from.split(" ")[1] + ' ' + slot.to.split(" ")[1];
                          // checked={this.state.checkedSlot===sTime?true:false}
                          return (<>
                            {/* <div className="custom-control custom-checkbox checkbox-inline mx-1" >
                      <input type="radio" onChange={this.chooseSlot}  value={slot.from.split(" ")[1]+' '+slot.to.split(" ")[1]} disabled={slot.booked !== undefined? slot.booked == true ? true: false : false}  className="custom-control-input" id={index} name="Sundays"/>
                      <label className="custom-control-label" for={index}>{slot.from.split(" ")[1]} - {slot.to.split(" ")[1]}</label>
                    </div> */}
                            <div className={slot.booked !== undefined ? slot.booked == true ? "custom-control custom-checkbox checkbox-inline mx-1 disabled" : "custom-control custom-checkbox checkbox-inline mx-1" : "custom-control custom-checkbox checkbox-inline mx-1"}>
                              <input type="radio" onChange={this.chooseSlot} value={slot.from.split(" ")[1] + ' ' + slot.to.split(" ")[1]} disabled={slot.booked !== undefined ? slot.booked : false} className="custom-control-input" id={index} name="Sundays" />
                              <label className="custom-control-label" for={index}>{slot.from.split(" ")[1]} - {slot.to.split(" ")[1]}</label>
                            </div>
                          </>
                          )
                        })

                      ) : (<> <div className="unavailable_block text-center p-3 mt-4 w-30">

                        Please select date to view all available time slots
                      </div></>)}

                    </div>
                  </div>

                </Form.Group>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="primary" onClick={this.props.close}>
                  Close
                </Button>

                <Button
                  variant="success"
                  type="submit"
                  onClick={this.handleSubmit}
                >
                  Save
                </Button>
              </Modal.Footer>
            </LoadingOverlay>
          </Form>
        </Modal>

      </>
    );
  }
}
export default UpdateSchedule;
