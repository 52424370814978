import React, { Component,useState } from 'react';
import StripeCheckout from "react-stripe-checkout";
import { toast } from "react-toastify";
// import moment from "moment";
import moment from "moment-timezone";

import axios from '../../../config/axios';
// import OwlCarousel from "react-owl-carousel";
import "../../../assets/css/style.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import Calendar from 'react-calendar';
import CoachProfileWrap from './CoachProfileWrap';
import { Header, HeaderLogout, HeaderTop } from "../../../components/headers";
import { FooterLink } from "../../../components/footers";
import CommunicationUsers,{SessionBookedNotify} from "../../../layouts/Communication/CommunicationUsers";
import { avatar } from "../../../assets/img";
import { Link } from "react-router-dom";
import message,{getHelpText} from '../../../utils/mesaages';
import {getClientTimezone,timezoneUtc,UTCtoTimezone} from '../../../utils/timeZoneConvert';
import SessionBookConfirmModal from "../view/SessionBookConfirmModal"





const _ = require("lodash");  


class MainSession extends Component {

    constructor(props){
        super(props)
        this.token = localStorage.getItem("authToken");

        this.state={
          avatar_path: avatar,
          selectedSlot:'',
          sessionDate:"2021-01-28",
          slots:[],
          currMonth: moment().format('MMMM'),
          currDate: moment().format('YYYY-MM-DD'),
          selectedDate:new Date(),
          duration:0,
          date: new Date(),
          multiSesDates:[],
          multiSesDatesCalender:[],
          multiSessionNO:'',
          multiSlots:[],
          avilDays:[],
          multiselectedSlot:{},
          unavlableSlot:[],
          multiselectedSlotFlag:0,
          sessionLength:0,
          curent:0,
          sessionExist:'',
          sessionExistArr:'',

          coachValidate:false,
          packageValidate:false,
          packageDetails:[],
          coachDetails:[],
          userDetails:[],
          ConsumerD:{},
          packageID:'',
          coachID:'',
          userID:'',
          coachTimezone:'',
          timezone:localStorage.getItem('timezone'),
          autoFetchToday:0,
          disableDate:[],
          buttondisable:false,
          bookConfirmModal: false,
          introCard:'',
          slotFetcheLoad:true

        }
        
      }
      async componentWillMount()
      {
        if(this.props.match.params.hasOwnProperty('coach_uid') &&  this.props.match.params.hasOwnProperty('package_uid'))
        {
          let userdata = localStorage.getItem('userdata')
          userdata = JSON.parse(userdata)
           console.log('userdata',userdata)
          
          let coach_uid = this.props.match.params.coach_uid
          let package_uid = this.props.match.params.package_uid
          let user_uid = userdata.uid
          // if(user_uid != undefined)
          // {

          if(user_uid != undefined)
          {
            
           let ConsumerD = {name:userdata.name,email:userdata.email,uid:user_uid}
            this.setState({ConsumerD,userDetails:userdata})
          }
               let timezone = await getClientTimezone()

               this.setState({timezone})

            this.setState({ packageID:package_uid,
              coachID:coach_uid,
              userID:user_uid},()=>{

                // this.checkUserValid()
                this.getAvailWeekdays()
                this.packageDetailsFetch()
                this.coachDetailsFetch()
              })

          // }
          
          
          
          


        }
        let {coachValidate,ConsumerD, packageValidate,packageDetails,coachDetails,userDetails, userID, coachID,packageID} = this.state
        //console.log('abcdefghijkl')
        if(coachValidate && packageValidate)
        {

                this.checkSessionExist()
                this.setState({sessionType:'main'})
              
        }

        
  
        

      }

      handleBookConfirmModal = () => {
        this.setState({bookConfirmModal: !this.state.bookConfirmModal})
      }

      getAvailWeekdays=()=>{
            let {coachValidate,ConsumerD, packageValidate,packageDetails,coachDetails,userDetails, userID, coachID,packageID} = this.state

            let id=coachID
            let from={coach_id:id,user_id:userID}
            axios.post(`/coach/setavailability/getavail`,from).then((res)=>{
              if(res.data.success)
              {
                  this.setState({avilDays:res.data.value,disableDate:res.data.disableDate})
              }

            }).catch((err)=>{

            })

      }

      coachDetailsFetch(){
        console.log('coach details function')
       
          const id = this.state.coachID;
      
          let param = {
            url: `/users/coachprofile/${id}`,
            method: "GET",
            headers: {
              "X-Auth": this.token
            }
          }
          axios(param)
            .then(async (res) => {

              if(res.data.success)
              {
                if (res.data.value.hasOwnProperty('coach_uid')) {
                  this.setState({coachDetails:res.data.value},()=>{
                    this.setState({coachTimezone:res.data.value.timezone})
                    console.log("coach data", this.state.coachDetails)
                  })
                  this.setState({coachValidate:true})
                }
                else{
                  console.log('invalid Coach')
                 
                }

              }
              else{
                console.log(res.data.err)
              }
              
              
            })
            .catch((error) => {
              console.log("fetch error");
              console.log(error);
             
            });
        
      

      }

      packageDetailsFetch(){
        console.log('Package details function')
       
          const id = this.state.packageID;
          axios
            .get(`/users/packages/singlePackagedata/${id}`, {
              headers: {
                "X-Auth": this.token,
              },
            })
            .then((res) => {
              if (res.data.success) {
                let pack = res.data.value;
               
                if (pack.hasOwnProperty('package_type')) {
                
                  
                 this.setState({packageDetails:pack},()=>{

                  let session_details= JSON.parse(pack.session_details)
            
                  var  multiSlots = this.state.multiSlots
                  var multiSesDates = this.state.multiSesDates
                  var multiselectedSlot = this.state.multiselectedSlot
                  var multiSesDatesCalender = this.state.multiSesDatesCalender
                  session_details.length>0 && session_details.map((ss,index)=>
                  {
                    multiSlots[`session${index}`] = 0
                    multiSesDates[`session${index}`]=new Date()
                    multiSesDatesCalender[`session${index}`]=new Date()
                    multiselectedSlot[`session${index}`]=''
            
                  })
                  var sessionLength = session_details.length
          
                  this.setState({multiSlots,multiSesDates,multiSesDatesCalender,sessionLength,multiselectedSlot},()=>{
                      // console.log('deone')
                      this.checkSessionExist()
                  })

                  this.setState({packageValidate:true})
                  // this.setState({duration:pack.introDuration})

                   console.log('packageDetails',this.state.packageDetails)
                 })
                }
                else{
                  console.log('invalid package')
                    }
              }
              else{
                console.log(res.data.err)
                  }
            })
            .catch((error) => {
              console.error(error);
            });
        
      }
      
      async componentDidMount(){

     
      
       }

       checkIntroBooking()
       {
        
         let userdata =JSON.parse(localStorage.getItem('userdata'))
         if(userdata.hasOwnProperty('uid'))
         {
          let id=this.props.location.state.coachUid
          let userid=userdata.uid
          let from={coachUID:id,userUID:userid}
         
          axios.post(`/consumer/checkIntroBooking`,from)
                .then(res =>{
                  if(res.data.success)
                  {
                    if(res.data.value>0)
                    {
                      this.setState({sessionType:'main'})
                     
                    }
                  }
                 
                })
                .catch(err =>{
                
  
                })

         }
         else
         {
          this.props.history.push('/')
         }
       
       }

       checkSessionExist()
       {
        let {coachValidate,ConsumerD, packageValidate,packageDetails,coachDetails,userDetails, userID, coachID,packageID} = this.state

        console.log('hiiiiiiiiiiiii')

        let userdata =JSON.parse(localStorage.getItem('userdata'))
        let id=coachID
        let userid=userID
        let packageUid = packageID
        let from={coachUID:id,userUID:userid,packUID:packageUid}
       
        axios.post(`/consumer/sessionExist`,from)
              .then(res =>{
                if(res.data.success)
                {
                  let sessionExist = []
                  let sessionExistArr=[]
                  let introCard = ''
                  let session_details= JSON.parse(packageDetails.session_details)

               
                  // res.data.value.map((ss)=>{
                  //   if(ss.status!=0 && ss.status!=4)
                  //   {
                  //     sessionExist.push(ss.session_id)
                  //   }

                  // })

                  // session_details.map((sd)=>{

                  //   if(!sessionExist.includes(`session${sd.id}`))
                  //   {
                  //     sessionExistArr.push(sd.id)
                  //   }
                    

                  // })

                  ///alternetive way

                  res.data.value.map((ss)=>{
                      if(ss.status==0 || ss.status==4)
                      {
                        sessionExistArr.push(ss.session_id.replace("session", ""))///passing id ex- 0 or 1 or 2
                      }
                      else
                      {
                        sessionExist.push(ss.session_id) ///passing session no ex- session0 or session1 or session2
                      }
  
                    })

                  ///   sessionExist  booked session
                  ///   sessionExistArr NOT booked session

                  
                  introCard =  res.data.card_id.creditCardToken

                console.log('sessionExist',sessionExist,sessionExistArr)
                    this.setState({sessionExist,sessionExistArr,introCard})
                   
                  
                }
               
              })
              .catch(err =>{
              

              })

       }
    
    
       getAvailability(sessionNo=''){
        const hidemsg =message.loading('Please wait, we are finding availability',0)
        let {coachValidate,ConsumerD, packageValidate,packageDetails,coachDetails,userDetails, userID, coachID,packageID} = this.state
        // toast("Please wait , we are finding Availability", { type: "success" });
        this.setState({slotFetcheLoad:true})

        let id=coachID
        let from={coach_id:id,user_id:userID,pack_id:packageID,date:this.state.currDate,sessionType:'main',duration:this.state.duration}
        console.log(from)
        axios.post(`/coach/setavailability/filtered`,from)
              .then(res =>{
                hidemsg()
                console.log("availabity---res--",res)
                if(res.data.success){
                  if(sessionNo!='')
                  {
                    let slt = res.data.value
                    let {unavlableSlot} = this.state
                    
                    if(unavlableSlot.length>0)
                    {
                      unavlableSlot.map((usl)=>{
                        let indx = _.findIndex(slt, function(o) { return (usl.from <= o.from && usl.to > o.from) || (usl.from < o.to && usl.to >= o.to) || (usl.from > o.from && usl.to < o.to ); });
   
                        if(indx>=0) slt[indx]['booked'] = true

                         console.log('slt',slt)
   
   
                       })

                    }
                    

                    
                            var multiSlots= this.state.multiSlots
                            multiSlots[sessionNo] =slt
                              
                          this.setState({multiSlots},()=>{
                            console.log('multiSlots',multiSlots)
                          })

                  }
                  else
                  {
                 this.setState({slots:res.data.value})

                  }
                 

                }
                if(res.data.value.length<=0)
                {
                  message.loading(`No slots avalable on ${this.state.currDate}`)
                

                }
                else{
                  // message.loading(`Slots Found ${res.data.value.length}`)
                 
                }
                // console.log("resss---vail",res)
                this.setState({slotFetcheLoad:false})
              })
              .catch(err =>{
                hidemsg()
                console.log(err)
                toast("Failed! Something Went Wrong", { type: "error" });
                this.setState({slotFetcheLoad:false})

              })
      }
    
      autoFetchToday=(no,duration)=>{

       
       

        if(!this.state.sessionExist.includes(no) && typeof this.state.sessionExist=='object')
        {
         console.log('echop',this.state.sessionExist,no)

          if(this.state.autoFetchToday==0)
          {
            this.setState({autoFetchToday:1})
                this.seesionNo(no,duration)

                setTimeout(() => {
                  this.setState({autoFetchToday:1})
        
                var multiSesDates = this.state.multiSesDates
                var multiSessionNO = this.state.multiSessionNO
                var multiSesDatesCalender = this.state.multiSesDatesCalender
              
                if(this.state.avilDays!=undefined)
                {
                if(this.state.avilDays.includes(moment(this.state.currDate).day()))
                {
        
                  
                  multiSesDates[multiSessionNO] = this.state.currDate
                  multiSesDatesCalender[multiSessionNO] = this.state.date
              
                  this.setState({multiSesDates,multiSesDatesCalender})
          
                
                  this.getAvailability(multiSessionNO)
          
              }
                }
              }, 1000);

          console.log('echo',no,duration)

          }
        }

      


      
      
    }
      
  
    seesionNo=(no,duration)=>{
      console.log('sess',no,duration)
      this.setState({multiSessionNO:no,duration:duration})

    }


    multiDateChange=(date)=>{
      this.setState({selectedDate:date})
      if(moment(date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD'))
      {
        toast("Please Choose a Future date", { type: "error" });
      }
      else{
        setTimeout(() => {

          var multiSesDates = this.state.multiSesDates
          var multiSessionNO = this.state.multiSessionNO
          var multiSesDatesCalender = this.state.multiSesDatesCalender
          var inArray= Object.values(multiSesDates)
        console.log(inArray)
        console.log(inArray.includes(moment(date).format('YYYY-MM-DD')))
  
        // if(!inArray.includes(moment(date).format('YYYY-MM-DD')))
        // {
  
          multiSesDates[multiSessionNO] =moment(date).format('YYYY-MM-DD')
          multiSesDatesCalender[multiSessionNO] = date
       
           this.setState({multiSesDates,multiSesDatesCalender})
  
        
              console.log('multiSesDates1',multiSesDates)
            this.setState({date,currDate:moment(date).format('YYYY-MM-DD')},
            ()=>{

              let multiselectedSlot = this.state.multiselectedSlot
              multiselectedSlot[multiSessionNO] =''
              this.setState({multiselectedSlot})
              // console.log('date',date)
              console.log('formatdate',moment(date).format('YYYY-MM-DD'))
              this.getAvailability(multiSessionNO)
            })
  
          
        // }
        // else{
        //   message.loading(`Date Already selected`)

         
          
        // }
  
        
          
        }, 1000);

      }
      

     

      

    }
    multiChooseSlots=(e)=>{
      let {coachValidate,ConsumerD,unavlableSlot, packageValidate,packageDetails,coachDetails,userDetails, userID, coachID,packageID} = this.state


      let session_details= JSON.parse(packageDetails.session_details)
      console.log(e.target.value.from)
      let v = e.target.value
      let date = e.target.getAttribute("ddate")

      let from = v.split(' ')[0]
      let to = v.split(' ')[1]
      let sessionNo = e.target.getAttribute('sessionName')
      console.log(v)
      console.log(from)
      console.log(to)
      if(from != undefined && to != undefined)
      {
        let multiselectedSlot = this.state.multiselectedSlot
        console.log('aaaaaaaaaaaaaaa',sessionNo)
        console.log('bbbbbbbbbbbbbbb',typeof sessionNo)
        // multiselectedSlot[sessionNo] = {name:session_details[sessionNo.split('session')[1]].sessionName,date:this.state.multiSesDates[sessionNo],from:from,to:to}
        multiselectedSlot[sessionNo] = {name:session_details[sessionNo.split('session')[1]].sessionName,date,from:from,to:to}
        
        if(_.findIndex(unavlableSlot, function(o) { return o.sessionNo == sessionNo; })>=0)
        {
          let indx = _.findIndex(unavlableSlot, function(o) { return o.sessionNo == sessionNo; })
          let unvl = _.find(unavlableSlot, function(o) { return o.sessionNo == sessionNo; })
          console.log('unvl',unvl)
          let unvlFrom = unvl.from
          unavlableSlot[indx]['from'] = `${date} ${from}`;
          unavlableSlot[indx]['to'] = `${date} ${to}`;

          // Multi Slots
              let multiSlots = this.state.multiSlots
              let multiSlotsSSS = []
              console.log('multiSlotsA',multiSlots,unvl)

              for (const [key, value] of Object.entries(multiSlots)) {
                console.log('jjjjjj',key,value)
                let indxxxxx = _.findIndex(value, function(o) { return o.from == unvlFrom; });
                if(indxxxxx>=0) multiSlots[key][indxxxxx]['booked'] = undefined

                
               
              }

            
              
              
              this.setState({multiSlots:multiSlots},
                console.log('multiSlots',multiSlots))
          // Multi Slots

          this.setState({unavlableSlot},()=>{
            console.log('unavalable',this.state.unavlableSlot)
          })

        }
        else{
          this.setState({unavlableSlot: [...unavlableSlot, {sessionNo,from:`${date} ${from}`,to:`${date} ${to}`}]},()=>{
            console.log('unavalable',sessionNo)
          })

        }
        
        this.setState({multiselectedSlot,multiselectedSlotFlag:1},()=>{
          console.log('chossed',multiselectedSlot)
        })
      }

    }

    saveMultiSession=(cardData)=>{
      let {coachValidate,ConsumerD, packageValidate,packageDetails,coachDetails,userDetails, userID, coachID,packageID} = this.state

     
     const hidemsg = message.loading(`Please wait while we are booking session`,0)
      this.setState({buttondisable:true})
      // toast("Please wait , we are booking your session", { type: "success" });


      
      

      let fromData = {
        coach_uid:coachID,
        user_uid:userID,
        package_id:packageID,
        sessions:this.state.multiselectedSlot,
        cardData
      }

      console.log('fromDate',fromData)
  return new Promise(async(resolve, reject) => {
  

      axios
            .post("/payment/book-session",fromData)
                .then(response =>{
                  hidemsg()
                  this.setState({buttondisable:false})
                    const { success } = response.data;
                  
                  console.log("Response:", response.data);
                  if (success) {
                    message.loading(response.data.msg)
                    // toast(response.data.msg, { type: "success" });
                    // let notify = {

                                    
                    //              }
                     SessionBookedNotify(response.data.data)
                    // this.handleClose()
                    
                   setTimeout(() => {
                    this.props.history.push({
                      pathname: '/profile-consumer',
                      state: { tab: 'UserupcomingSession' }
                    })
                     
                   }, 4000);
                   resolve()
                    
                  } else {
                    // hidemsg()
                    this.setState({bookConfirmModal: false})

                    console.log(response.data.msg)
                   
                    message.error(response.data.msg)
                    reject()

                    // toast(response.data.msg, { type: "error" });
                  }
                })

                })


    }

    changeCalender=(toggle)=>{

        if(toggle=='next')
        {
          var curent =this.state.curent + 1
          this.setState({curent},()=>{


                  // Multi Slots

                    let multiSlots = this.state.multiSlots
                    let slt = multiSlots[`session${curent}`]
                    let {unavlableSlot} = this.state
                    
                    if(unavlableSlot.length>0)
                    {
                      unavlableSlot.map((usl)=>{
                        let indx = _.findIndex(slt, function(o) { return (usl.from <= o.from && usl.to > o.from) || (usl.from < o.to && usl.to >= o.to) || (usl.from > o.from && usl.to < o.to )});
   
                        if(indx>=0) slt[indx]['booked'] = true

                         console.log('slt',slt)
   
   
                       })

                    }
                    multiSlots[`session${curent}`] =slt
                    this.setState({multiSlots})
                  // Multi Slots

            console.log('check',this.state.sessionExist.includes(`session${curent}`))

            if(this.state.sessionExist.includes(`session${curent}`))
            {
              this.changeCalender(toggle)
            }


          })

         
         
            

        

        }
        else{

          var curent =this.state.curent - 1
          this.setState({curent},()=>{

            // Multi Slots

              let multiSlots = this.state.multiSlots
              let slt = multiSlots[`session${curent}`]
              let {unavlableSlot} = this.state
              
              if(unavlableSlot.length>0)
              {
                unavlableSlot.map((usl)=>{
                  let indx = _.findIndex(slt, function(o) { return (usl.from <= o.from && usl.to > o.from) || (usl.from < o.to && usl.to >= o.to) || (usl.from > o.from && usl.to < o.to ); });

                  if(indx>=0) slt[indx]['booked'] = true

                  console.log('slt',slt)


                })

              }
              multiSlots[`session${curent}`] =slt
              this.setState({multiSlots})
            // Multi Slots

            if(this.state.sessionExist.includes(`session${curent}`))
            {
              this.changeCalender(toggle)
            }


          })

         
         
           

       
        }
        
    }

    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return [year, month, day].join('-');
  }
    tileDisabled = ({activeStartDate, date, view }) => {
      if(view=='month')
      {
        let day = date.getDay(); 
        let arrayweekavail = this.state.avilDays
        let arrayDateUnavailble = this.state.disableDate
        // console.log(arrayDateUnavailble)
        // console.log('date',this.formatDate(date),_.find(arrayDateUnavailble, { 'date': this.formatDate(date)}) != undefined)
        // ...convert number to weekname or directly through moment
        //
        return  !arrayweekavail.includes(day) || _.find(arrayDateUnavailble, { 'date': this.formatDate(date)}) != undefined

      }
      else
      {
        return false
      }
      
   
   }

   tileClassName = ({activeStartDate, date, view }) => {
    let today = `${date.getMonth()}${date.getDate()}`
    let day = date.getDay(); 
    let arrayweekavail = this.state.avilDays
    
    let today1 = `${new Date().getMonth()}${new Date().getDate()}`
   

    // //console.log('ddddatattata',today)
    // console.log('xxxxxxxxxxxx',today1)

    if(today == today1)
    {

        if(arrayweekavail.includes(day))
        {
          return ''
        }
        else
        {
          return 'tilesBlock'
        }

      

     
    
     
    }
    else
    {
      

      return ''
    }
   
 
 }



    render() {
      let {coachValidate,ConsumerD, packageValidate,packageDetails,coachDetails,userDetails, userID, coachID,packageID} = this.state

     

     
     
      
      
     
      let kk =0

      let session_details = ''
      let index =0
      let ss =''
      let sessionLengthCheck =0
      let currentCheck = 0
      if(packageValidate && coachValidate )
      {

         sessionLengthCheck = this.state.sessionLength - 1
         currentCheck = this.state.curent
        

        let i=1
        var sessions =[]
        while(i<=packageDetails.total_sessions)
        {
          sessions.push(i)
          i++
        }
         session_details= JSON.parse(packageDetails.session_details)
         index = this.state.curent
         ss = session_details[this.state.curent]


      }

     
    

     


     
      // console.log('gggggggg',session_details)

      
        return (
           <React.Fragment>
              {/* { this.token  } */}
        {/* <HeaderTop /> */}

        {localStorage.getItem("authToken") == null ? (
          <HeaderTop />
        ) : (
            <HeaderLogout
              currencies={this.state.currencies}
              convertToCurrency={this.state.convertToCurrency}
              changeConvertToCurrency={this.changeConvertToCurrency}
            />
          )}

        <Header />
        <CommunicationUsers avatar_path={this.state.avatar_path}></CommunicationUsers>
        <section className="coacActionhWrap pt-5" >
          <div className="container " >
          <div className="row justify-content-center">

            <div className="col-md-12">
              <div className="heading text-center">
              <h3>Select Date & Time For Your  Call With Your Coach</h3>                      
                <span className="R_divider"></span>
              </div>
            </div>
            
            {(packageValidate && coachValidate)?(<>
          
           
            {/* for main session */}

          

                {session_details.length>0?(

                  <>
                  {!this.state.sessionExist.includes(`session${index}`)?(<>
                    {(session_details.hasOwnProperty([this.state.curent]))?(<>

                   <div className="col-md-12 d-flex justify-content-center" style={{color:'black'}}><h2>{ss.sessionName}</h2></div>
                   {/* auto fetch */}
                    {kk==0?(
                      console.warn( setTimeout(() => {
                        this.autoFetchToday(`session${index}`,ss.Length)
                      }, 800))
                     
                      
                      ):''} 
                    {console.warn(kk=kk+1)} 
                        {/* auto fetch */}

                   <div className="col-lg-5" onClick={()=>{this.seesionNo(`session${index}`,ss.Length)}}>
                      <div className="bookingCalendar">
                        <div className="app__main">
                        {this.state.avilDays.length>0 &&(
                            <Calendar
                            value={this.state.multiSesDatesCalender[`session${index}`]}
                            onChange={this.multiDateChange}
                            minDate={new Date()}
                            className="calendar"
                            tileDisabled={this.tileDisabled}
                            tileClassName={this.tileClassName}
                            // activeStartDate={this.state.selectedDate}
                            
                            
                            />
                        )}
                        </div>
                      </div>
                    </div>
                   
                    <div className="col-lg-9">
                <div className="areas areasNew">
                    
                  
                  {this.state.multiSlots[`session${index}`].length>0? (
                        this.state.multiSlots[`session${index}`].map((slot,index1)=>{

                            var fromSlot = slot.from.split(" ")[1]
                            var selectedSlot = this.state.multiselectedSlot[`session${index}`].from
                            // console.log('status',fromSlot==selectedSlot,'.'+selectedSlot+'.')
                            // console.log('status',fromSlot==selectedSlot,'.'+fromSlot+'.')


                            var check = (fromSlot==selectedSlot)? true : false
          
                          return(<>
                              <div class={slot.booked !== undefined? slot.booked==true? "custom-control custom-checkbox checkbox-inline mx-1 disabled" : "custom-control custom-checkbox checkbox-inline mx-1" : "custom-control custom-checkbox checkbox-inline mx-1"} >
                                  {console.log('zz',this.state.multiselectedSlot[`session${index}`].from)}
                                <input type="radio" onChange={this.multiChooseSlots} ddate={slot.from.split(" ")[0]} sessionName={`session${index}`} value={slot.from.split(" ")[1]+' '+slot.to.split(" ")[1]} disabled={slot.booked !== undefined? slot.booked : false} checked={check}  className="custom-control-input" id={`${index}${index1}`} name={`session${index}`}/>
                                <label className="custom-control-label" for={`${index}${index1}`}>
                                {UTCtoTimezone(this.state.timezone,timezoneUtc(this.state.coachTimezone,slot.from),'HH:mm')} - 
                                {UTCtoTimezone(this.state.timezone,timezoneUtc(this.state.coachTimezone,slot.to),'HH:mm')}
                                  </label>
                              </div>
                              </>
                          )
                        })
      
                    ):(<> 
                     <div className="unavailable_block text-center p-3 mt-4 w-50 ml-auto mr-auto">
                  
                     {/* Slots unavailable on {this.state.currDate} */}

                     Please select date to view all available time slots
                </div>
                    
                
                </>)}
                 
                </div>
              </div>
                  
              </>):(<>
                    {this.setState({curent:0})}
                    
                    </>)}
              
              </>):(<>
              {console.log('length',session_details.length,this.state.sessionExist.length)}
                {(session_details.length <= this.state.sessionExist.length)?(<>
             
                  <div className="col-lg-9">

                    <div className="areas areasNew">
                      <div className="unavailable_block text-center p-3 mt-4 w-50 ml-auto mr-auto">
                              Sessions Already Scheduled
                      </div>
                    </div>
                  </div>
                
                
                 
                
                </>):(<>
                  {this.setState({curent:this.state.curent+1})}
                </>)}
                  
              
              </>)}

                 </> 


                ):(<></>)}
            
            
            
            
            
            
            
         

            

                     <div className="col-md-12 mt-5">
        <div className="row justify-content-around booking_session_btn_group">
        
            <div className="col-md-3 d-flex justify-content-center mb-3">         
{console.log('conditions',this.state.sessionExistArr,_.find(this.state.sessionExistArr, function(o) { return o < 2?true:false; }) != undefined, this.state.sessionExistArr.length<=0)}
 
              {(0<currentCheck && this.state.slotFetcheLoad!=true)? (
                  <>
                    {(_.find(this.state.sessionExistArr, function(o) {  return o < currentCheck?true:false; })!=undefined || this.state.sessionExistArr.length<=0) && (<>
                      {console.log('render',this.state.multiSesDates)}
                      
                      <button className="defaultBtn" onClick={()=>{this.changeCalender('prev')}} >
                            prev
                      </button> 
                      </>

                    )}
                                                          
                  </>
              
              ):(<></>)}
            </div>


            <div className="col-md-3 d-flex justify-content-center mb-3">
              {(this.state.multiselectedSlotFlag!=0 && this.state.sessionType!='intro')? (
                
                    <>
                        {/* <button disabled={this.state.buttondisable} className={this.state.buttondisable? 'defaultBtn disablebutton' : 'defaultBtn' } onClick={()=>{this.saveMultiSession()}} >
                        BOOK SESSION
                        </button> */}
                        <button disabled={this.state.buttondisable} className={this.state.buttondisable? 'defaultBtn disablebutton' : 'defaultBtn' } onClick={()=>{this.handleBookConfirmModal()}} >
                        BOOK SESSION
                        </button>
                    </>
                
              
              ):(<></>)}
            </div>


            <div className="col-md-3 d-flex justify-content-center mb-3" style={{ justifyContent: "flex-end"}}>

              {(this.state.slotFetcheLoad!=true && sessionLengthCheck>currentCheck && session_details.length != this.state.sessionExist.length && this.state.sessionType!='intro')? (
                
                  <>
                  {(_.find(this.state.sessionExistArr, function(o) {  return o > currentCheck?true:false; })!=undefined || this.state.sessionExistArr.length<=0) && (<>

                    <button className="defaultBtn" onClick={()=>{this.changeCalender('next')}} >
                    BOOK NEXT SESSION
                    </button>

                    </>)}
                  </>                                                   
               
              
              ):(<></>)}

            </div>      
        
        </div>
        </div>

              </>):(<>

                  <div className="col-md-12">
                      <div className="areas ">
                          <div className="unavailable_block text-center p-3 mt-4 w-50 ml-auto mr-auto">
                            Please wait
                          </div>
                      </div>
                  </div>

                  </>)}



        
       
            
        


        

       
           
       </div>
            
          </div>

      
        </section>

             <FooterLink />
        <SessionBookConfirmModal 
          handleBookConfirmModal={this.handleBookConfirmModal} 
          show={this.state.bookConfirmModal}
          submitDetails={this.saveMultiSession}
          introCard = {this.state.introCard}
          multiselectedSlot={this.state.multiselectedSlot}
        />
      </React.Fragment>
        );
      
      
    }
}

export default MainSession;