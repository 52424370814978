import React from "react";
import { Link } from "react-router-dom";
import { c1, c2, c3, c4, c5 } from "../../assets/images";
import { Col, Container, Row } from "react-bootstrap";

class CoachingAreas extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Row>
        {this.props.coachingareadata.length === 0 ? "" : this.props.coachingareadata?.map((items,index) => (
          <Col md={(localStorage.getItem("subdomain") &&  (index == 3 || index == 4)) ? 6 : 4} className="pr_item" key={index}>
            <div className="flip-container">
              <div className='flipper'>
                <div className='front' style={{ background: 'url(' + JSON.parse(items).image_url + ')' }}>
                  <h3>{JSON.parse(items).title}</h3>
                </div>
                <div className="back">
                  <h4>{JSON.parse(items).title}</h4>
                  <span className="R_divider"></span>
                  <p>{JSON.parse(items).content}</p>
                  <Link className="btn defaultBtn" to={`/webinars-category?filter=${JSON.parse(items).title.toLowerCase()}`}>Explore</Link>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    );
  }
}

export default CoachingAreas;
