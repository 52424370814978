import React from "react";
import { Link} from "react-router-dom";
import ConfirmModal from "../view/ConfirmModal";
import UpdatePackage from "./UpdatePackage";
import {curencyFilter,curencyRates} from '../../../utils/currencyConvert';
import {Collapse, Button} from "react-bootstrap";
import { Tooltip } from 'antd';
import axios from "../../../config/axios";
import message from '../../../utils/mesaages'


class Package extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      session_details_list: [],
      open: false,
      rates:""
    };
  }

  async componentDidMount() {
    let rates = await curencyRates()
    this.setState({rates})
    if (this.props.packagedata.session_details !== "") {
      var list_data = JSON.parse(this.props.packagedata.session_details);
      this.setState({ session_details_list: list_data });
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.packagedata.session_details !== this.props.packagedata.session_details) {
      var list_data = JSON.parse(this.props.packagedata.session_details);
      this.setState({ session_details_list: list_data });
    }
  }

  handleClick = (id) => {
    this.props.handleDeletePackage(id);
  };

  handleActivateStatus = (id) => {
    this.props.handleActivatePackageStatus(id);
  };

  handleInactivateStatus = (id) => {
    this.props.handleInactivatePackageStatus(id);
  };

  handlebook=(data) =>{
    this.props.handlePackageBook(data)
  }

  incompleteKYCaction = () => {
    message.warning("Sessions are currently unavailable with this coach",5)
    axios.post(`/users/incomplete-kyc-action`, {coach_uid: this.props.coachUid}, {
      headers: {"X-Auth": this.props.token}
    }).then((res) => {
      
    }).catch((err) => {

    })
  }


  render() {
    // console.log("bbbbb-----,",Number.parseFloat(10 * 1
    // ).toFixed(3))
    console.log("bbbbb-----,",Number.parseFloat(10 * 1.19
      ).toFixed(3))
    console.log("SESSION DETAILS LIST :=====")
    console.log(this.props.packagedata)
    console.log(this.state.session_details_list)

    const duration = this.props.packagedata.duration;

    var hours = duration / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    const duration_time = rhours + (rhours > 1 ? " hours " : " hour ") + rminutes + " minutes";

    let statusButton;
    if (this.props.packagedata.isactive === 1) {
      statusButton = (
        <p
          className="dropdown-packgae"
          onClick={() => {
            this.handleInactivateStatus(this.props.packagedata.uid);
          }}
        >
          <i className="fa fa-times-circle"></i>{" "}Deactivate
          {/* <p style={{ color: "Red" }}> Inactive</p> */}
        </p>
      );
    } else if (this.props.packagedata.isactive === 0) {
      statusButton = (
        <p
          // href="/#"
          className="dropdown-packgae"
          onClick={() => {
            this.handleActivateStatus(this.props.packagedata.uid);
          }}
        >
          <i className="fa fa-check-circle"></i>{" "}Activate
          {/* <p style={{ color: "green" }}> Active</p> */}
        </p>
      );
    }

    let packPack = this.props.packagedata
    return (
      <React.Fragment>
         
        <div className={this.props.packagedata.isactive ? "packborder" : ""}>
          <div className="packageList">
            <div className="packageInfo">
              <h3>
                {this.props.packagedata.title}
                <br />
              </h3>
              <p>
                <i className="fa fa-certificate" aria-hidden="true"></i>{" "}
                {/* {this.props.packagedata.total_sessions}   */}
                { this.state.session_details_list.length}
                {this.props.packagedata.total_sessions > 1 ? ( " Sessions " ) : ( " Session ")} 
                
                <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                {duration_time}{" "}
              </p>
              {/* <Link
                to="profile-coach"
                data-toggle="collapse"
                data-target={`#package_collapse_${this.props.packagedata.uid}`}
              >
                Description <i className="fa fa-angle-down"></i>
              </Link> */}
              <Button
              variant="outline-info"
                onClick={() => this.setState({open: !this.state.open})}
                aria-controls={`package_collapse_${this.props.packagedata.uid}`}
                aria-expanded={this.state.open}
              >
                Description <i className="fa fa-angle-down"></i>
              </Button>
              <Collapse in={this.state.open}>
              <div
                id={`package_collapse_${this.props.packagedata.uid}`}
                className="collapse"
              >
                <div className="short__desc__caption">
                  <p>{this.props.packagedata.description}</p>

                  <div className="container_pk_item">
                    <div>
                      {this.state.session_details_list &&
                        this.state.session_details_list.map((item) => (
                          <div>
                            {item.sessionName && (
                              <div className="" style={{ margin: "10px" }}>
                                <div className="packageList">
                                  <div className="packageInfo">
                                    <h5>
                                      {item.sessionName && item.sessionName}
                                    </h5>
                                    <p>{item.sessionDescription}</p>

                                    <div className="packagedropdownbutton">
                                      <div className="dropdown dropleft testi_options">
                                        <span className="badge badge-Light">
                                          {" "}
                                          {item.Length && (
                                            <h6 style={{color: "black"}}>
                                              <i
                                                className="fa fa-clock-o"
                                                aria-hidden="true"
                                              ></i>
                                              &nbsp;
                                              {item.Length} minutes
                                            </h6>
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              </Collapse>
            </div>

            <div className="packageActon">
              <h4>
                &nbsp;
                {curencyFilter(this.props.packagedata.price,"",this.state.rates)}
                {/* {this.props.baseCurrency &&
                this.props.baseCurrency === this.props.convertToCurrency ? (
                  <>
                    {this.props.packagedata.price.split("##")[0] === "USD" ? (
                      <>
                        ${" "}
                        {Number.parseFloat(
                          this.props.packagedata.price.split("##")[1]
                        ).toFixed(2)}
                      </>
                    ) : (
                      
                      <>
                      {this.props.packagedata.price.split("##")[0] === "EUR" ?(
                        <>
                         ${" "}
                        {Number.parseFloat(
                          this.props.packagedata.price.split("##")[1] * 1.18
                        ).toFixed(2)}
                        </>
                      ) :(
                      <>
                         ${" "}
                        {Number.parseFloat(
                          this.props.packagedata.price.split("##")[1] * 1.32
                        ).toFixed(2)}
                      </>)}
                       
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {this.props.convertToCurrency === "USD" ? (
                      <>
                        {this.props.packagedata.price.split("##")[0] ===
                        "USD" ? (
                          <>
                            ${" "}
                            {Number.parseFloat(
                              this.props.packagedata.price.split("##")[1]
                            ).toFixed(2)}
                          </>
                        ) :(
                          <>
                          {this.props.packagedata.price.split("##")[0] ==="EUR" ? (
                          <>
                           ${" "}
                            {Number.parseFloat(
                              this.props.packagedata.price.split("##")[1] * 1.18
                            ).toFixed(2)}
                          </>):(
                          <>
                           ${" "}
                            {Number.parseFloat(
                              this.props.packagedata.price.split("##")[1] * 1.32
                            ).toFixed(2)}
                          </>)}
                           
                          </>
                        )}
                      </>
                    ) : (
                      <>
                      {this.props.convertToCurrency === "GBP" ?(
                      <>
                         {this.props.packagedata.price.split("##")[0] ===
                        "GBP" ? (
                          <>
                            £{" "}
                            {Number.parseFloat(
                              this.props.packagedata.price.split("##")[1]
                            ).toFixed(2)}
                          </>
                        ) : (
                          <>
                          {this.props.packagedata.price.split("##")[0] ==="EUR" ? (
                          <>
                           £{" "}
                            {Number.parseFloat(
                              this.props.packagedata.price.split("##")[1] / 1.12
                            ).toFixed(2)}
                          </>):(
                          <>
                           £{" "}
                            {Number.parseFloat(
                              this.props.packagedata.price.split("##")[1] / 1.31
                            ).toFixed(2)}
                          </>)}
                           
                          </>
                        )}
                      </>):(
                      <>
                       {Number.parseFloat(
                          this.props.packagedata.price.split("##")[1] *
                            this.props.findcurrency
                        ).toFixed(2)}{" "}
                        {this.props.convertToCurrency}
                      </>)}
                       
                      </>
                    )}
                  </>
                )}{" "} */}
              </h4>

              {this.props.account_type === "coach" ? (<div>
                {this.props.packagedata.isactive ?(<Link className="defaultBtn" to="/comming-soon">
                Book
              </Link>):(<Link className="defaultBtn" to="/comming-soon">
                Not Available
              </Link>)}
                </div>
                
              ):(<div></div>)}
              

            {this.props.account_type === "user" ?(<div>
                {this.props.packagedata.isactive ? this.props.coachKYCstatus ? (
                  // this.props.packagedata.isactive IS TRUE > this.props.coachKYCstatus IS TRUE
                  (
            
                    <div>
                      {localStorage.getItem("authToken") ==null ?(<div>
                       <Link className="defaultBtn" to="/registration" >
                       Book
                       </Link>
                      </div>
                      ):( <button className="package_button"
                     
                      onClick={()=>{
                       const data={
                         packname:this.props.packagedata.title,
                         packUid:this.props.packagedata.uid,
                         packprice:this.props.packagedata.price.split("##")[0] ==='USD'? (this.props.packagedata.price.split("##")[1])
                         :(this.props.packagedata.price.split("##")[0] ==='EUR' ? (this.props.packagedata.price.split("##")[1] * 1.19):(
                           this.props.packagedata.price.split("##")[1] * 1.32
                         ))
                       }
                        this.handlebook(data)
                      }}
                     //  data-toggle="modal"
                     //  data-target="#bookpackagemodal"
                      >
                        {console.log('packagePage',packPack)}
                       <Link className="defaultBtn"   to={{
                           pathname: `/intro-session/${this.props.coachUid}/${this.props.packagedata.uid}`,
                           state: { 
                             baseCurrency:this.props.baseCurrency,
                             findcurrency:this.props.findcurrency,
                             convertToCurrency:this.props.convertToCurrency,
                            
                           }
                         }}>
                            Book
                       </Link>
                       </button>)}
                      
                     </div>
                      )
                ): (
                  // this.props.packagedata.isactive IS TRUE > this.props.coachKYCstatus IS FALSE
                  // <Tooltip title="Coach customer details(KYC) is incomplete">
                  //   <span 
                  //   // style={{cursor: "not-allowed"}}
                  //   >
                    <Link className="defaultBtn" to="#"
                    onClick={() => this.incompleteKYCaction()} 
                    // style={{pointerEvents: "none"}}
                    >
                      Book
                 </Link>
                  //   </span>
                  // </Tooltip>
                  
                ) :(
                // this.props.packagedata.isactive IS FALSE
                <Link className="defaultBtn">
                       Not Available
                      </Link>)}
                    </div>):(<div></div>)}

            </div>

            {this.props.account_type === "coach" && (
            <div>
              <div className="packagedropdownbutton">
                <div className="dropdown dropleft testi_options">
                  <button
                    type="button"
                    className="btn dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                  </button>

                  <div
                    className="dropdown-menu"
                    // style={{ backgroundColor: "white" }}
                  >
                    <div className="col-sm-8">
                     
                   <li><UpdatePackage
                      
                        icon={"fa fa-edit"}
                        isEdit={true}
                        pacdata={this.props.packagedata}
                        handleEditPackage={this.props.handleEditPackage}
                        pack_id={this.props.packagedata.uid}
                      /></li> 
                    </div>
                    <br />

                   <li><ConfirmModal
                      fun_name={"Delete"}
                      icon={"fa fa-trash"}
                      pack_id={this.props.packagedata.uid}
                      handleClick={this.handleClick}
                    /></li> 

                    <li>{statusButton}</li>
                  </div>
                </div>

                {!this.props.packagedata.isactive ? (
                  <div className="packstatus">
                    <button type="button" className="btn btn-danger btn-circle">
                      Inactive
                    </button>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            )}



          </div>
        </div>
        <hr />
       
      </React.Fragment>
    );
  }
}
export default Package;
