import React, { Component } from "react";
import axios from "../../config/axios";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Tooltip } from 'antd';
import { CoachTransactionhistory,ConsumerPayment,JoinSession,PaymentReceived,SetAvailability,UpcomingSession, ReviewList } from "./sidebarComponents";
import MySchedule from "./sidebarComponents/MySchedule";
// import { TextEditor } from "../Edit";
import { plusSvg, deleteSvg, documentSvg, mclose, upload, docSvg, pdfSvg, xlsSvg, pptSvg, pictureSvg } from "../../assets/images";
import ReactHtmlParser from "react-html-parser";
import TextEditor from "../../components/media/TextEditor";

import { Link } from "react-router-dom";
import { ListModal } from "../../components/resourcefilemodal";
import message,{getHelpText} from '../../utils/mesaages';
import { size } from "lodash";
import { PDFDocument } from 'pdf-lib';
import CoachSideBar from "./coachSideBar";
import PDFViewer from 'mgr-pdf-viewer-react'
const dashBoardButtonStyles={
  overflow:"hidden"

}

class Bio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      certificate_path: null,
      long_bio: "",
      edit: 0,
      certificates: [],
      CertificateType:"",
      bio_text: "Text not available",
      certificate_name: "",
      invalid: '',
      show: false,
      viewMoreshow: false,
      bio_help_text: '',
      renderSideBarComponent:"",
    };
  }

  handleShow = () => {
    this.setState({show: true})
  }

  handleClose = () => {
    this.setState({show: false})
    this.onClickHandler()
  }

  async componentDidMount() {
    console.log('eneter bio')
    let bio_text = await getHelpText('bio_text')
    this.setState({bio_text})
    let bio_help_text = await getHelpText('bio_help_text')
    this.setState({bio_help_text})
    this.getBioData();
  }

  handleCertificatechange=(e)=>{
    e.persist()
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  getBioData() {
    const id=this.props.userdata.uid
    axios
      .get(`/users/biocertificate/${id}`, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        console.log("certificate", res);
        if (res.data.msg === "success") {
          const data = res.data.value;
          this.setState({ certificates: data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleEdit = (e) => {
    if (e.target.value === 0) {
      this.setState({ edit: 1 });
    } else if (e.target.value === 1) {
      this.setState({ edit: 0 });
    }
  };

  handlechange = (e) => {
    this.setState({
      long_bio: e.target.value,
    });
  };

  onChangeHandler = async(e) => {
    let inputType = e.target.files[0] != undefined ? e.target.files[0].type.split('/'): ''
    let filename = e.target.files[0] != undefined ? e.target.files[0].name.split('.'): ''
    let ext = filename !='' ? filename.pop().toLowerCase(): ''
    if(inputType[0] != undefined && inputType[0] == 'image' && (ext == 'jpeg' || ext == 'jpg' || ext == 'png')){
      if(e.target.files[0].size>4000000) {
        await this.setState({invalid: "Please select file upto 4MB size.", certificate_name: "", certificate_path: null})
      } else {
        await this.setState({
          certificate_path: e.target.files[0],
          certificate_name: e.target.files[0] != undefined ? e.target.files[0].name : "",
          loaded: 0,
          invalid: '',
        });
      }
      //console.log("@@@@@@@@@@ I am here @@@@@@@@@@@@")
    } else if(inputType[0] == 'application' && ext == 'pdf') {
      if(e.target.files[0].size>4000000) {
        await this.setState({invalid: "Please select file upto 4MB size.", certificate_name: "", certificate_path: null})
      } else {
        await this.setState({
          certificate_path: e.target.files[0],
          certificate_name: e.target.files[0] != undefined ? e.target.files[0].name : "",
          loaded: 0,
          invalid: '',
        });
        const numPages = await this.getNumPages(this.state.certificate_path);
        if(numPages.length > 1 || numPages.length === 0) {
          await this.setState({invalid: "Please upload single page PDF file only", certificate_name: "", certificate_path: null})
          
        } else {
          await this.setState({
            certificate_path: this.state.certificate_path,
            certificate_name: this.state.certificate_name,
            loaded: 0,
            invalid: '',
          });
        }
      }
    } 
    else {
      await this.setState({invalid: "Please select correct file format upto 4 MB size", certificate_name: "", certificate_path: null})
      //console.log("@@@@@@@@@@ I am here @@@@@@@@@@@@")
    }
    //console.log(e.target.files[0]);
  };

  readFile = (file) => {

    return new Promise((resolve, reject) => {
  
      const reader = new FileReader();
  
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
  
      reader.readAsArrayBuffer(file);
    });
  }
  
  getNumPages = async (file) => {
  
    const arrayBuffer = await this.readFile(file);
  
    const pdf = await PDFDocument.load(arrayBuffer);
  
    return pdf.getPages();
  }

  onClickHandler = () => {
    if(this.state.certificate_path != null) {
      if(this.state.certificate_path.size>=4000000)
    {
      message.error('File is too large. Please select file upto 4MB size.')
    } else {
      const hideMessage = message.loading('Please Wait while we are uploading the file.', 0)
    let formData = new FormData();
    formData.append("certificate_path", this.state.certificate_path);
    formData.append("CertificateType", this.state.CertificateType);

    console.log("certificate --formdata",formData)
    axios
      .post("/users/biocertificate", formData, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        hideMessage()
        if (res.data.success) {
          this.setState({certificate_name: "", certificate_path: null})
          // toast("Successfully added file", { type: "success" });
          message.success("Successfully added file");
          this.getBioData();
        } else {
          message.error("Certificate Upload failed");
        }
      })
      .catch((err) => {
        hideMessage()
        message.error("Some error occured");
        console.log(err);
      });
    }
    } else {
      message.error("No file choosen")
    }
    
  };

  handleDeleteCertificate = (id) => {
    const hideMessage = message.loading('Please Wait while we are deleting the file.', 0)
    console.log("clicked certi--", id);
    axios
      .delete(`/users/biocertificate/${id}`, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        hideMessage()
        if (res.data.success) {
          message.success("Successfully deleted file");
        } else {
          message.error("Certificate delete failed");
        }
        this.getBioData();
      })
      .catch((err) => {
        hideMessage()
        message.error("Some error occured, please try again later");
        console.log(err);
      });
  };

  onClose = async() => {
    await this.setState({certificate_name: "", certificate_path: null, invalid: '', show: false})
  }

  handleViewMoreModal = () => {
    this.setState({viewMoreshow: !this.state.viewMoreshow})
  }
  handleSideBarComponent = (cname) => {
   
    this.setState({ renderSideBarComponent: cname });
  };


  render() {
    const pdata = this.props.pdata;
    console.log('eneter bio render' )
    //const { account_type } = this.props;
   
    return (
      <React.Fragment>
       
        {/* <section className="biokWrap">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12"> */}
              
              {this.state.renderSideBarComponent==="" ?(<div className="">
               
             
                {/* <div className="heading text-center">
                  <h3>Full Bio</h3>
                  <span className="R_divider"></span>
                </div> */}

                {/* {this.props.check_account_type &&
                this.props.check_account_type === "coach" ? (
                  <div className="heading text-right">
                    <div className="bio__caption">
                      <div className="edit_personal_details">
sdfsdfsdf



                        <TextEditor
                          pdata={pdata.long_bio}
                          handleOnChange={this.props.handleOnChange}
                          handleSubmitPersonal={this.props.handleSubmitPersonal}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )} */}


      

                <div className="bioDetails">


{this.props.check_account_type &&
  this.props.check_account_type === "coach" ? (

            <TextEditor
              pdata={pdata.long_bio_fetch}
              handleOnChange={this.props.handleOnChange}
              handleSubmitPersonal={this.props.handleSubmitPersonal}
              bioHandleSubmitPersonal={this.props.bioHandleSubmitPersonal}
            />      

 
  ): (
                ""
)}







                  <p style={{ color: "black" }}>
                    {/* {ReactHtmlParser(pdata.long_bio)} */}
                    {ReactHtmlParser(pdata.long_bio_fetch)}
                  </p>
                </div>


                <div className="biokWrap row">
          <div className="col-md-12 pt-5">
          {this.props.check_account_type &&
                this.props.check_account_type === "coach" ? (
            <div className="heading text-center">
              <h3>Display content to showcase your services</h3>
              <span className="R_divider"></span>
            </div>
            ) : ("")}
                
            {this.props.check_account_type &&
                this.props.check_account_type === "coach" ? (
            <div className="heading text-right">
              <div className="add__certificate">
                <span
                  // data-toggle="modal"
                  // data-target="#exampleModalCenter"
                  onClick={this.handleShow}
                ><Tooltip title={this.state.bio_help_text} placement="right">
                  <img width="35px" src={plusSvg} alt="LG-Img" />
                </Tooltip>
                  {/* <img width="35px" src={plusSvg} alt="LG-Img" /> */}
                </span>

 
                  <Modal
                    show={this.state.show}
                    //onHide={this.onClose}
                    className="modal fade"
                    id="exampleModalCenter"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    {/* <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    > */}
                      <div className="modal-content">
                        {/* <div className="modal-header">
                          <h5
                            style={{color:"black"}}
                            className="modal-title"
                            id="exampleModalLongTitle"
                          >
                            Upload Certifications and Logos
                            <Tooltip title={this.state.bio_text} placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div> */}
                        
                        <div className="modal-body">

                          <button 
                          type="button" 
                          className="close" 
                          aria-label="Close"  
                          onClick={this.onClose}>
                              <img src={mclose} alt="" width="30px"/>
                          </button>

                          <div className="heading text-center">
                            <h3 className="text-capitalize">Upload Certifications and Logos</h3>                
                            <span className="R_divider"></span>
                          </div>

                          <div className="file_up">
                          <label for="image" className="custom-file-upload">
                            <img src={upload} width="60px"/>
                          </label>

                          <p>Click to Upload 
                          <Tooltip title={this.state.bio_text} placement="right"> <i style={{color:"purple"}} className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>
                          </p>
                          
                          <span>Image and PDF</span>
                          {/* <p style={{color: "#28a745"}}>{this.state.certificate_name != '' ? this.state.certificate_name : ""}</p> */}
                          {this.state.invalid == '' ? (
                            <p style={{color: "#28a745", wordBreak: 'break-word', fontSize:"15px"}}>{this.state.certificate_name}</p>
                          ) : (
                            <p style={{color: "red", fontSize:"15px"}}>{this.state.invalid}</p>
                          )}

                            <input
                              className="file-upload"
                              type="file"
                              name="certificate_path"
                              id="image"
                              onChange={this.onChangeHandler}
                              accept="application/msword,application/pdf,image/*"
                            />
                          </div>
                        </div>
                        <div className="modal-footer">
                          {/* <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button> */}
                          {this.state.certificate_path != null ? 
                            <button
                            type="button"
                            className="btn btn-success"
                            onClick={this.handleClose}
                            //data-dismiss="modal"
                          >
                            Upload
                          </button>
                          : ""}
                        </div> 
                      </div>
                    {/* </div> */}
                  </Modal>
             
              </div>
            </div>

                ):""}




            <div className="row justify-content-center lightBox">
              {this.state.certificates.reverse().slice(0,3).map((certificate, i) => {
                let array = certificate.certificate_path.split("/").pop()
                let filetype = array.split(".").pop().toLowerCase()
               
                return (
                  
                  <div className="col-lg-4 col-md-4 col-6 my-3" key={i}>
                    {/* <a
                       href={certificate.certificate_path}
                       target="_blank"
                      //  data-toggle="lightbox"
                      //  data-gallery="example-gallery"
                       className="boxWrap"
                     > */}
                       {filetype == "pdf" ? 
                      //   <img
                      //   alt="LG-Img"
                      //   src={pdfSvg}
                      //   className="img-fluid"
                      // />
                      (
                        <div className="iframepdf">
                          {/* <iframe
                        height="200px"
                        alt="LG-Img"
                        //src="https://lifeguru.s3-us-east-2.amazonaws.com/assets/doc_placeholder.png"
                        src={certificate.certificate_path}
                      /> */}
                      <PDFViewer document={{
                        url: `${certificate.certificate_path}`
                      }}
                      scale={0.25} 
                      hideNavbar={true}
                      />
                      <a
                       href={certificate.certificate_path}
                       target="_blank"
                       className="boxWrap"
                     ></a>
                        </div>
                      )
                    //    : filetype == "doc" || filetype == "docx" ? 
                    //    <img
                    //    alt="LG-Img"
                    //    src={docSvg}
                    //    className="img-fluid"
                    //  />
                    //   : filetype == "xls" ? 
                    //     <img
                    //     alt="LG-Img"
                    //     src={xlsSvg}
                    //     className="img-fluid"
                    //   />
                      
                    //   : filetype == "ppt" ? 
                    //     <img
                    //     alt="LG-Img"
                    //     src={pptSvg}
                    //     className="img-fluid"
                    //   />
                       : filetype == "jpg" || filetype == "jpeg" || filetype == "png" || filetype == "gif" ? 
                       (
                        <a
                        href={certificate.certificate_path}
                        target="_blank"
                       //  data-toggle="lightbox"
                       //  data-gallery="example-gallery"
                        className="boxWrap"
                      >
                          <img
                          alt="LG-Img"
                          src={certificate.certificate_path}
                          //src={pictureSvg}
                          className="img-fluid"
                        />
                        </a>
                       )
                       : 
                    //    <img
                    //    alt="LG-Img"
                    //    src={docSvg}
                    //    className="img-fluid"
                    //  />
                    ''
                       }
                     {/* </a> */}
                   
                       {/* <a
                       href={certificate.certificate_path}
                       target="_blank"
                      //  data-toggle="lightbox"
                      //  data-gallery="example-gallery"
                       className="boxWrap"
                     >
                       <img
                         alt="LG-Img"
                         src={documentSvg}
                         className="img-fluid"
                       />
                     </a> */}
                     {this.props.check_account_type === "coach" ? (
                      <Link
                        className="removeLightbox"
                        //  href="javascript:void(0)"
                        to="/profile-coach"
                        onClick={() => {
                          this.handleDeleteCertificate(certificate.uid);
                        }}
                      >
                        <img width="2" src={deleteSvg} alt="LG-Img" />
                      </Link>
                    ) : (
                      ""
                    )}
                  {<b style={{textAlign: "center",color:"black"}}>{certificate.media_type}</b>}


                    {/* {certificate.status==0 ?(<p style={{textAlign: "center",color:"red"}}>In Review</p>):("")} */}
                      
                  </div>
                );
              })}
            </div>

            {this.state.certificates && this.state.certificates.length > 3 ? (
              <div className="text-center col-12 mt-4">
                <button
                  className="defaultBtn"
                  // data-toggle="modal"
                  //data-target="#CommonModal"
                  onClick={this.handleViewMoreModal}
                >
                  View More
                </button>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>





                </div>):("")}
             
              {/* </div>
            </div>
          </div>
        </section> */}
      

      

        <ListModal
        accountType={this.props.check_account_type}
          dataSet={this.state.certificates}
          handleDelete={this.handleDeleteCertificate}
          viewMore="certificates"
          handleViewMoreModal={this.handleViewMoreModal}
          showState={this.state.viewMoreshow}
        />
      </React.Fragment>
    );
  }
}
export default Bio;
