import React, { useCallback, useEffect, useState } from "react";
import { Container, Modal, ModalDialog } from "react-bootstrap";
import axios from "../../../config/axios";
import {dateConfig} from "../../../config/dateFormats";
import moment from 'moment';
import message from '../../../utils/mesaages';
import { Tooltip } from 'antd';
import {curencyFilter,curencyRates} from '../../../utils/currencyConvert';
import Pagination from "../../../components/pagination/pagination";

const _ = require("lodash");  

function ManageSubscriptionModal(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showSubscriptions, setShowSubscriptions] = useState(false)
  // const [subscriptions, setSubscriptions] = useState([])
  const [upcomingSubscriptions, setUpcomingSubscriptions] = useState([])
  const [activeScriptions, setactiveScriptions] = useState([])
  const [activeScriptionsPurchase, setactiveScriptionsPurchase] = useState([])
  const [pastSubscriptions, setPastSubscriptions] = useState([])
  const [subscriptionDeleteButton, setsubscriptionDeleteButton] =  useState(false)
  const [currentPlanPrice, setcurrentPlanPrice] =  useState(0)
  const [listPerPage, setListPerPage] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [rates, setRates] = useState("")
  // const [pageNumber, setPageNumber] = useState(1)
  
  const indexOfLast = pageNumber * listPerPage;
  const indexOfFirst = indexOfLast - listPerPage;
  

  useEffect(() => {
    (async () => {
      let rates = await curencyRates()
      setRates(rates)
    })();
    

    

    const userdata = JSON.parse(localStorage.getItem("userdata"));

      axios.get(
        `/users/coach/${userdata.uid}/subscriptions`,{
        headers: {
            "X-Auth": props.token
        }
      }).then(res => {
        if(res.data.success === true) {
          // setSubscriptions(res.data.data);
          const subscriptions = res.data.data;

    let subscriptionDeleteButton =_.find(subscriptions, function(o) { return o.status == 'ongoing' });
    console.log('subscriptionDeleteButton',subscriptionDeleteButton)

    if(subscriptionDeleteButton!=undefined)
    {
      setcurrentPlanPrice(subscriptionDeleteButton.paid_amount.split('##')[1])
      setsubscriptionDeleteButton(true)
    }

    let upgradePackageCount =_.filter(subscriptions, function(o) { return o.status == 'upcoming' });
    console.log('upgradePackageCount',upgradePackageCount)
    if(upgradePackageCount.length>0)
    {
      props.upgradeBlock(true)

    }
    
  
    


          setUpcomingSubscriptions(
            subscriptions
              .filter(sub => sub.status === "upcoming")
          )
          setactiveScriptions(
            subscriptions
              .filter(sub => sub.status === "ongoing" || (moment().diff(sub.subscription_end, "days") <= 0 && sub.status === "cancelled"))
          )
          let active = subscriptions.filter(sub => sub.status === "ongoing")

         if(active.length>0)
          setactiveScriptionsPurchase(subscriptions
            .filter(sub => sub.renewal === 0 && sub.subscriptionId === active[0].subscriptionId))
         
          setPastSubscriptions(
            subscriptions
              // .filter(sub => sub.status !== "upcoming" && sub.status !== "ongoing" && sub.renewal!=1)
              .filter(sub => sub.status !== "upcoming" && sub.status !== "ongoing" && (
                (moment().diff(sub.subscription_end, "days") > 0 && sub.status === "cancelled") ||
                sub.status === "expired"
              ))
          ) 

        }
      }).catch(err => {
        console.log('errrrrr',err)
      })

  }, [])

  const paginate = (pagenumber) => {
    setPageNumber(pagenumber);
  };


  const handleClick = () => {
    const userdata = JSON.parse(localStorage.getItem("userdata"));
    const hidemsg = message.loading(`Please wait ..`,0)

    axios.post(`/users/coach/${userdata.uid}/cancelSubscription`, {DateTime:moment().format('YYYY-MM-DD HH:mm:ss')}, {
      headers: {
        "X-Auth": props.token
      }
    }).then((response)=>{
      if(response.data.success)
      {
        hidemsg()
        message.success('Your subscription has been successfully cancelled.')
        setsubscriptionDeleteButton(false)
      }
      else
      {
        hidemsg()
        message.error(response.data.message)
      }

    }).catch((err)=>{
      hidemsg()
      console.log('cancelSubscription Err',err)
      message.error('Something Went Wrong, Your subscription cancel request failed')

    })
    

  }

  let func_button;

  if (props.fun_name === "Delete") {
    func_button = (
      <li className="alert__confirm">
        <button
          className="btn btn-success"
          data-dismiss="modal"
          onClick={() => {
            handleClick();
          }}
        >
          Confirm
        </button>
      </li>
    );
  }

  const handleActivateSubscription = (subscription) => {
    const userdata = JSON.parse(localStorage.getItem("userdata"));
    const hidemsg = message.loading(`Please wait ..`,0)
    axios.post(`/users/coach/${userdata.uid}/activate/${subscription.id}`, {DateTime:moment().format('YYYY-MM-DD HH:mm:ss')}, {
      headers: {
        "X-Auth": props.token
      }
    }).then((res) => {
      hidemsg()
      if(res.data.status === 200 && res.data.success) {
        message.success('Your subscription is activated successfully')
        setsubscriptionDeleteButton(true)

        setUpcomingSubscriptions(subs => subs.filter(sub => sub.id !== subscription.id))
        subscription.status = "ongoing"
        setPastSubscriptions(subs => {
          return subs.concat(res.data.data)
        })
        props.onActivatingSubscription()
      } else {
        message.error(res.data.message)
      }
    }).catch(err => {
      message.error('Unable to activate subscription')
      hidemsg()
    })
  }



  const loadUpcomingSubscritionsList = () => upcomingSubscriptions
            .map(upcomingSubscription => (

              <div className="bookingCocachDetails subscription_row">
                <ul key={upcomingSubscription.id}>
                <li>
                  <b>Purchase Date</b> <span>{moment(upcomingSubscription.created).utc().format(dateConfig.dateFormat)}</span>
                </li>
                <li>
                  <b>Subscription Plan</b> <span>{upcomingSubscription.plan_name}</span>
                </li>
                <li>
                  <b>Subscription Amount</b>  <span> {upcomingSubscription.plan_price!=null? curencyFilter(`USD##${upcomingSubscription.plan_price}`,"",rates) : 0 }</span>
                </li>
                <li>
                  <b>Subscription Duration</b> <span>{upcomingSubscription.duration==1?'Monthly':'Yearly'}</span>
                </li>
                {/* <li>
                  <b>Balance Charged</b><span>{parseInt(currentPlanPrice)<parseInt(upcomingSubscription.paid_amount.split('##')[1])?
                (  <>{curencyFilter(`${upcomingSubscription.paid_amount.split('##')[0]}##${parseInt(upcomingSubscription.paid_amount.split('##')[1]) - parseInt(currentPlanPrice)}`)}</>)
                :
                (<>{curencyFilter(upcomingSubscription.paid_amount)}</>)}</span>
                
                </li> */}

                <li> 
                   <b>Balance Charged</b><span>{parseInt(upcomingSubscription.plan_price)!=parseInt(upcomingSubscription.paid_amount.split('##')[1])?
                 (  <>{curencyFilter(`${upcomingSubscription.paid_amount}`,"",rates)}</>)
                 :
                 (<>{curencyFilter(`USD##0.00`,"",rates)}</>)}</span>
                 
                 </li>
                </ul>
                </div>
             ))

  const loadActiveSubscritionsList = () => activeScriptions
             .map(upcomingSubscription => (
 
               <div className="bookingCocachDetails subscription_row">
                 <ul key={upcomingSubscription.id}>
                   {console.log('activeScriptionsPurchase',activeScriptionsPurchase)}
                 <li>
                   <b>Purchase Date</b> <span>{moment(activeScriptionsPurchase[0]?.created).utc().format(dateConfig.dateFormat)}</span>
                 </li>
                 <li>
                   <b>Subscription Plan</b> <span>{upcomingSubscription.plan_name}</span>
                 </li>
                 <li>
                   <b>Subscription Amount</b>  <span> {upcomingSubscription.plan_price!=null? curencyFilter(`USD##${upcomingSubscription.plan_price}`,"",rates) : 0 }</span>
                 </li>
                 <li>
                   <b>Subscription Duration</b> <span>{upcomingSubscription.duration==1?'Monthly':'Yearly'}</span>
                 </li>
                 {/* <li>
                   <b>Balance Charged</b><span>{parseInt(currentPlanPrice)<parseInt(upcomingSubscription.paid_amount.split('##')[1])?
                 (  <>{curencyFilter(`${upcomingSubscription.paid_amount.split('##')[0]}##${parseInt(upcomingSubscription.paid_amount.split('##')[1]) - parseInt(currentPlanPrice)}`)}</>)
                 :
                 (<>{curencyFilter(upcomingSubscription.paid_amount)}</>)}</span>
                 
                 </li> */}

                <li> 
                   <b>Balance Charged</b><span>{parseInt(upcomingSubscription.plan_price)!=parseInt(upcomingSubscription.paid_amount.split('##')[1])?
                 (  <>{curencyFilter(`${upcomingSubscription.paid_amount}`,"",rates)}</>)
                 :
                 (<>{curencyFilter(`USD##0.00`,"",rates)}</>)}</span>
                 
                 </li>
                 </ul>
                 </div>
              ))

  const loadPastSubscritionsList = () => pastSubscriptions
            .slice(indexOfFirst, indexOfLast)
            .map(pastSubscription => (



              <div className="bookingCocachDetails subscription_row">
                <ul key={pastSubscription.id}>
                <li>
                  <b>Purchase Date</b> <span>{moment(pastSubscription.created).utc().format(dateConfig.dateFormat)}</span>
                </li>
                <li>
                  <b>Subscription Plan</b> <span>{pastSubscription.plan_name}</span>
                </li>
                <li>
                  <b>Subscription Amount</b> <span>{pastSubscription.plan_price!=null? curencyFilter(`USD##${pastSubscription.plan_price}`,"",rates) : 0}</span>
                </li>
                <li>
                   <b>Subscription Duration</b> <span>{pastSubscription.duration==1?'Monthly':'Yearly'}</span>
                 </li>
                 {/* <li>
                   <b>Balance Charged</b><span>{parseInt(currentPlanPrice)<parseInt(pastSubscription.paid_amount.split('##')[1])?
                 (  <>{curencyFilter(`${pastSubscription.paid_amount.split('##')[0]}##${parseInt(pastSubscription.paid_amount.split('##')[1]) - parseInt(currentPlanPrice)}`)}</>)
                 :
                 (<>{curencyFilter(pastSubscription.paid_amount)}</>)}</span>
                 
                 </li> */}

                <li> 
                   <b>Balance Charged</b><span>{parseInt(pastSubscription.plan_price)!=parseInt(pastSubscription.paid_amount.split('##')[1])?
                 (  <>{curencyFilter(`${pastSubscription.paid_amount}`,"",rates)}</>)
                 :
                 (<>{curencyFilter(`USD##0.00`,"",rates)}</>)}</span>
                 
                 </li>
                </ul>
                </div>
             
             ))
  // const loadPastSubscritionsList = () =>{ 
  //   let subs = subscriptions
  //       .filter(sub => sub.status !== "upcoming")
  //       .map(subscription => (
  //         <tr key={subscription.id}>
  //           <th scope="row">{new Date(subscription.created).toLocaleDateString()}</th>
  //           <td>{subscription.plan_name}</td>
  //           <td>{subscription.amount_paid}<span className="ml-1">{subscription.currency}</span></td>
  //           <td>{new Date(subscription.subscription_start).toLocaleDateString()}</td>
  //           <td>{new Date(subscription.subscription_end).toLocaleDateString()}</td>
  //         </tr>
  //       ))

  //     if(subs) {
  //       console.log("inside pastSubs ======> ", subs, subs?.length);
  //       setPastSubscriptionLength(subs.length)
  //     }
  //   return subs;
  // }

  return (
    <>
      {/* <button className="btn btn-sm" onClick={handleShow}>
        <i className={props.icon}></i>{" "}
        {props.fun_name}
      </button> */}
      {/* <div className="d-flex justify-content-between manageSubscriptionModalButtonContainer"> */}
      <div className="d-flex justify-content-center col-lg-8 col-md-12 col-sm-12 col-12">
        <div className="buttonSetManageSubscription d-flex justify-content-between">
        {/* <button className="defaultBtn" ><a href="#subscription-plans" style={{textDecoration:'none',color: 'inherit'}}>Change Subscription</a></button> */}
        <button className="defaultBtn" onClick={() => setShowSubscriptions(true)} >Subscription Details</button>

      {!subscriptionDeleteButton?(
        <Tooltip title={"No subscription available"} placement="top">
            <button disabled={true} className="disablebutton">CANCEL SUBSCRIPTION</button>
        </Tooltip> 
        ):(

        <button className="defaultBtn"  onClick={handleShow}>CANCEL SUBSCRIPTION</button>
        )}
        </div>
        {/* <button className="defaultBtn" onClick={handleShow}>Dactive Account</button> */}
      </div>
      
      <Container className="my-3 py-3 justify-content-center text-center">
                                          
      </Container>

      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        className="modal  confirmation"
      >
        <div className="modal-content">
          <div className="alert__icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" style={{height: "50px", width: "50px"}}>
              <path
                d="M61.31 56.51A4.939 4.939 0 0156.99 59H7.01a5.006 5.006 0 01-4.35-7.47l24.99-44A4.946 4.946 0 0132 5a4.96 4.96 0 014.35 2.53l24.99 44a4.93 4.93 0 01-.03 4.98z"
                fill="#c1272d"
              />
              <path
                d="M7.01 55a.938.938 0 01-.854-.487.948.948 0 01-.022-1l25-44.008A.945.945 0 0132 9a.957.957 0 01.873.509l24.988 44a.933.933 0 010 .978.952.952 0 01-.87.516z"
                fill="#ffe184"
              />
              <path d="M32 44a2.373 2.373 0 01-2.363-2.158l-1.578-19.53A3.954 3.954 0 0132 18a3.954 3.954 0 013.938 4.312l-1.578 19.53A2.373 2.373 0 0132 44z" />
              <circle cx="32" cy="49" r="3" />
            </svg>
          </div>
          <p style={{color:"black",fontSize: "14px", textAlign: "left"}}>After clicking confirm your subscription will be cancelled at the end of your current billing cycle. No further payment will be taken and your subscription will not auto-renew.
<br/><br/>Please note there are no refunds on payments already made and you can continue to access your account until this billing cycle is complete.
</p>
          <ul>
            <li className="alert__cancel">
              <button
                className="btn btn-secondary"
                // href="javascript:void(0)"
                data-dismiss="modal"
                onClick={handleClose}
                style={{marginLeft: "20px", paddingLeft:"15px", paddingRight: "15px"}}
              >
                Exit
              </button>
            </li>
            {/* {func_button} */}
            <li onClick={handleClose}>{func_button}</li>

          </ul>
        </div>
      </Modal>



      <Modal
       
       aria-labelledby="example-custom-modal-styling-title"
        show={showSubscriptions}
        onHide={() => setShowSubscriptions(false)}
        dialogClassName="modal-60w"
      >
   
        <div className="modal-content" style={{background: "#f5f5f5",padding: "10px"}}>
          <div className="modal-header" style={{paddingLeft: '0px'}}>
            <h5 className="modal-title" style={{color: "black"}}>Subscription Plan</h5>
            <button type="button" onClick={() => setShowSubscriptions(false)} className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="row">
            <div className="col-md-8 pt-2">
                <h6 className="mt-2 acsub" style={{color:'black'}}>
                  Active Subscription
                </h6>
             
               {activeScriptions.length>0 && activeScriptions[0].renewal==1  &&(
                 <div className="bookingCocachDetails subscription_row">
                   <ul style={{width:'135px'}}>
                     <li style={{flex:'10'}}>
                       <b>Payment Date </b>
                       <span>{moment(activeScriptions[0].created).utc().format(dateConfig.dateFormat)}</span>
                     </li>
                   </ul>
                 </div>
                
               )} 
            </div>
            <div className="text-center mt-2 col-md-4 change_sub" >
                <button onClick={() => {
                  setShowSubscriptions(false)
                  setTimeout(() => {
                    document.getElementById('subscription-plans').scrollIntoView()
                  }, 1000)
                }} 
                className="defaultBtn"
                style={{
                  paddingInline: 15,
                  paddingTop: 5,
                  paddingBottom: 5,
                  fontSize: 15
                }}
                >
                  Change Subscription
                  {/* <a href="#subscription-plans" style={{textDecoration:'none',color: 'inherit'}}>Change Subscription</a> */}
                </button>
          </div>

          </div>

          
          
          
              {loadActiveSubscritionsList()}
          
           {(!activeScriptions || activeScriptions.length <= 0) &&<div className="bookingCocachDetails subscription_row mb-2">
              <ul className="d-flex justify-content-center"><li>No Active Subscription</li></ul>
            </div>}
            {(upcomingSubscriptions && upcomingSubscriptions.length > 0) && <div className="bookingCocachDetails subscription_row mb-2">

              <h6 className="mt-2" style={{color:'black'}}>
              New Subscription Plan  {`(on ${moment(props.membershipExpire).utc().add(1, 'days').format(dateConfig.dateFormat)})`}
              </h6>
          
              {loadUpcomingSubscritionsList()}
          
           
              
            </div>}


            <h6 className="mt-2" style={{color:'black'}}>
            Subscription History
          </h6>

            {loadPastSubscritionsList()}
           {(!pastSubscriptions || pastSubscriptions.length <= 0) && <div className="bookingCocachDetails subscription_row mb-2">
              <ul className="d-flex justify-content-center"><li>No Subscriptions Found</li></ul>
            </div>}

            {pastSubscriptions.length >
                  listPerPage ? (
                    <Pagination
                      postsPerPage={listPerPage}
                      totalPosts={pastSubscriptions.length}
                      paginate={paginate}
                      currentPage={pageNumber}
                    />
                  ) : (
                    ""
                  )}


          </div>
        
       

      </Modal>
    </>
  );
}
export default ManageSubscriptionModal;
