import React,{ useState, useEffect, createRef, ReactNode, SyntheticEvent } from 'react'
import _ from "lodash";
import {UpdateSchedules} from "../../views/coach/edit/";
import axios from '../../config/axios'
import { toast } from "react-toastify";
import message from '../../utils/mesaages';
import moment from "moment-timezone";
//import ApiCalendar from 'react-google-calendar-api';
import {getClientTimezone,timezoneUtc,convertToTimezone,getCochTimezone} from '../../utils/timeZoneConvert';
import { Dropdown, Modal,Button } from 'react-bootstrap';
import {dateConfig} from "../../config/dateFormats"
var Calendar = require('tui-calendar'); /* CommonJS */

require("tui-calendar/dist/tui-calendar.css");
require("tui-calendar/dist/tui-calendar.min.css");
require("tui-calendar/dist/tui-calendar.js");


// If you use the default popups, use this.
require("tui-date-picker/dist/tui-date-picker.css");
require("tui-time-picker/dist/tui-time-picker.css");

//import {appendScript} from '../../utils/appendScript'

var dcalender;
let prevPropsnew;
let newView='month';
class MyCalender extends React.Component{
constructor(props) {
  super(props)
  this.calendar = Object;
  
  this.state={
      view:'month',
      prevProps:'',
      editModal:false,

      coachUid: "",
      end_time: "",
      pack_id: "",
      package_uid: "",
      sessionDate: "",
      sessionType: "",
      start_time: "",
      uid: "",
      user_uid: "",
      duration:"",
      type:"",
      userRoomSessionDetails:"",
      currentDate:'',
      alertShow:false,
      deletedata:''
     
  }
  this.handlecloseAlert=this.handlecloseAlert.bind(this);
  this.handleShow=this.handleShow.bind(this);
}




handleClose()
{
    
this.setState({editModal: false})

}

componentDidMount () {
//appendScript("/app.js");
//let app = require("./app.js");

//this.displayCalendar()
this.displayToastCalender();
getClientTimezone()

    
} 



displayToastCalender = () => {
    var self=this;

    dcalender = new Calendar(document.getElementById('calendar'), {
        defaultView: 'month',
        taskView: true,    // Can be also ['milestone', 'task']
        scheduleView: true,  // Can be also ['allday', 'time']
        useCreationPopup: false,
        useDetailPopup: true,
        
        template: {
            milestone: function(schedule) {
                return '<span style="color:red;"><i className="fa fa-flag"></i> ' + schedule.title + '</span>';
            },
            milestoneTitle: function() {
                return 'Milestone';
            },
            task: function(schedule) {
                return '&nbsp;&nbsp;#' + schedule.title;
            },
            taskTitle: function() {
                return '<label></label>';
            },
            allday: function(schedule) {
                return schedule.title + ' <i className="fa fa-refresh"></i>';
            },
            alldayTitle: function() {
                return 'All Day';
            },
            time: function(schedule) {
                return schedule.title + ' <i className="fa fa-refresh"></i>';
                //return 'test';
            },
            
            monthDayname: function(dayname) {
                return '<span className="calendar-week-dayname-name">' + dayname.label + '</span>';
              },
              weekDayname: function(model) {
                return '<span className="tui-full-calendar-dayname-date">' + model.date + '</span>&nbsp;&nbsp;<span className="tui-full-calendar-dayname-name">' + model.dayName + '</span>';
            },

            popupDetailDate: function(isAllDay, start, end) {
                start = new Date(start)
                end = new Date(end)
                
                // console.log("test start date", moment(testStart).format("DD-MM-YYYY hh:mm a"))
                var endFormat = (start.toDateString() === end.toDateString() ? '' : `${dateConfig.dateFormat} `) + 'hh:mm a';

                if (isAllDay) {
                    return moment(start).format(dateConfig.dateFormat) + (start.toDateString() === end.toDateString() ? '' : ' - ' + moment(end).format(dateConfig.dateFormat));
                }

                return (moment(start).format(`${dateConfig.dateFormat} hh:mm a`) + ' - ' + moment(end).format(endFormat));
            }
            
            // popupEdit:  function(e) {
            //     // console.log('beforeUpdateSchedule', e);
            //     // var ev= e.data.root;
            //     // console.log('calender id', ev.schedule.start);
    
            //     // // e.schedule.start = e.schedule.start;
            //     // // e.schedule.end = e.schedule.end;
            //     // if(ev.schedule.raw.status==='Confirmed' || ev.schedule.raw.status===1)
            //     // {
            //     //     console.log('beforeUpdateSchedule 1', ev);
            //     //     self.handleEditSchedule(ev)
    
            //     // }else{
            //     //     message.error(`Schedule not change`);
            //     // }    
            //     //calendar.updateSchedule(e.schedule.id, e.schedule.calendarId, e.schedule);
            //     //return 'Edit';
                
            // },
            // popupDelete: function() {
            // return 'Delete';
            // }
        },
        // month: {
        //     daynames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        //     startDayOfWeek: 0,
        //     narrowWeekend: true,
            
        // },
        // week: {
        //     daynames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        //     startDayOfWeek: 0,
        //     narrowWeekend: true
        // }
    });

    dcalender.on({
            
        'clickMore': function(e) {
                console.log('clickMore', e);
            },
            'clickSchedule': function(e) {
                console.log('clickSchedule', e);
            },
            'clickDayname': function(date) {
                console.log('clickDayname', date);
            },
            'beforeCreateSchedule': function(e) {
                console.log('beforeCreateSchedule', e);
                //saveNewSchedule(e);
            },
        'clickSchedule': function(e) {
            console.log('clickSchedule', e);

            if(e.schedule.raw.sessionType==="intro")
            {
                // console.clear()
                if(e.schedule.raw.status=='Cancelled')
                {
                    const del = document.querySelector(".tui-full-calendar-popup-delete")
                    const del2 = document.querySelector(".tui-full-calendar-popup-edit")
                    if(del)
                        del.hidden=true;
                        del2.hidden=true;
                    const vertLine = document.querySelector(".tui-full-calendar-popup-vertical-line")
                    if(vertLine) vertLine.hidden=true;
                    const btn1 = document.querySelector(".tui-full-calendar-section-button") 
                    if(btn1) {
                        btn1.style.display="flex";
                        btn1.style.justifyContent="center";
                    }
                }
                else
                {
                    const deleteElement = document.querySelector(".tui-full-calendar-popup-delete .tui-full-calendar-content")
                    if(deleteElement) {
                        deleteElement.innerHTML = "Cancel"
                    }
                    
                }
                
            }
            else
            {
                if(e.schedule.raw.status==4)
                {
                    const del2 = document.querySelector(".tui-full-calendar-popup-edit")
                    if(del2)
                    del2.hidden=true;
                }
                const del = document.querySelector(".tui-full-calendar-popup-delete")
                if(del)
                    del.hidden=true;
                const vertLine = document.querySelector(".tui-full-calendar-popup-vertical-line")
                if(vertLine) vertLine.hidden=true;
                const btn1 = document.querySelector(".tui-full-calendar-section-button") 
                if(btn1) {
                    btn1.style.display="flex";
                    btn1.style.justifyContent="center";
                }
                // document.querySelector(".tui-full-calendar-section-button").style.justifyContent="center";
                
                
            }
            

        },
        'beforeCreateSchedule': function(e) {
            console.log('beforeCreateSchedule', e);
            // open a creation popup
    
            // If you dont' want to show any popup, just use `e.guide.clearGuideElement()`
    
            // then close guide element(blue box from dragging or clicking days)
            e.guide.clearGuideElement();
        },
        'beforeUpdateSchedule': function(e) {
            console.log('beforeUpdateSchedule', e);
            console.log('calender id', e.schedule.start);

            e.schedule.start = e.schedule.start;
            e.schedule.end = e.schedule.end;
            if(e.schedule.raw.status==='Confirmed' || e.schedule.raw.status==='Cancelled' || e.schedule.raw.status===1)
            {
                console.log('beforeUpdateSchedule 1', e);
                self.handleEditSchedule(e)

            }else{
                message.error(`Schedule not changed`);
            }    
            //calendar.updateSchedule(e.schedule.id, e.schedule.calendarId, e.schedule);
            
        },
        'beforeDeleteSchedule': function(e) {
            console.log('beforeDeleteSchedule', e);
            if(e.schedule.raw.sessionType==="intro")
            {
                // self.handleDelete(e);
                self.setState({alertShow:true,deletedata:e});
            }
            else
            {
                message.error(`This session can’t be deleted. Please contact admin.`);  
            }
            // this.handleDeleteunavailable(e)
            //calendar.deleteSchedule(e.schedule.id, e.schedule.calendarId);
        }
    });

     this.setState({currentDate:moment(dcalender.getDate()).format('MMMM-DD')})

    //  console.log('datetanmaychechk',moment(dcalender.getDate()).format('MMMM-YYYY'))

    

    document.getElementById("calendar").addEventListener("click", this.hideButtons());


    

}

handleDelete = (e) => {
    var doc="";
    // var result =  window.confirm("Are you sure that you want to delete?"); 
    // if (result === true) { 
    //     doc = "OK was pressed."; 
    //     var self=this;
    console.log('handledelete',e);
    var dData=e.schedule.raw;

    let from={coach_id:dData.coachUid,
              pack_id:dData.package_uid,
              sessionType:dData.sessionType,
              user_uid:dData.user_uid,
              uid:dData.uid
            }

        axios.post(`/users/session/delete-booking`,from)
        .then( async(res) =>{
            console.log('get-unavailable',res.data);
            if(res.data.success){
            
            
                if(dData.sessionType === "intro") {
                    message.success("Intro call has been cancelled successfully")
                } else{
                    message.success(res.data.msg);  
                }

                setTimeout(() => {
                window.location.reload()
                    
                }, 3000);
            
            }
            else
            {
                message.error(res.data.msg);  
            }
        })
        .catch(err =>{
            console.log(err)
            message.error(err.data.msg);   
        })

    // } else { 
    //     doc = "Cancel was pressed."; 
    // } 
    // console.log('doc',doc)

    

}

 hideButtons =() => {
    var buttons = document.getElementsByClassName("tui-full-calendar-popup-delete");
    // if(buttons){
    //     buttons.style.display = "none";
    // }
}

getTodayDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy+'-'+mm+' '+dd;

    return today;
}

differDatesHours = (date)=>{

var now = moment(); //todays date
var end = moment(date,'YYYY-MM-DD HH:ss'); // another date
// console.log('now',now,end);
var duration = moment.duration(end.diff(now));


var hours = duration.asHours();

console.log('hours',hours);
return hours
}

moveToNextOrPrevRange=(val) =>{
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    if (val === -1) {
        dcalender.prev();
        let date = dcalender.getDate().toDate()
        console.log('date',dcalender.getDate().toDate())
        
        this.setState({currentDate:`${months[date.getMonth()]}-${date.getFullYear()}`})
    } else if (val === 1) {
        dcalender.next();
        let date = dcalender.getDate().toDate()
        this.setState({currentDate:`${months[date.getMonth()]}-${date.getFullYear()}`})
    }
    }
    

  

createScheduleForintroBookings = async () =>{

    var scheduleArr= []; 
    var calID = dcalender.id
    console.log('introBookings ',this.props.introBookings);


    if(this.props.introBookings.length > 0 &&  Object.keys(this.props.introBookings[0]).length !== 0 && this.props.introBookings[0].constructor === Object )
    {
        // console.log('tanmaysanyal')
       
        await Promise.all(this.props.introBookings.map(async (item,index) => {
           

            let i =1;var slot=[];var slotDate="";
            let details = JSON.parse(item.package_details)
            console.log('introBookings details',details);

            if(details && Object.keys(details).length !== 0 && details.constructor === Object)
            {
                if(details.hasOwnProperty('slotTime'))
                {
                    slot = details.slotTime.split("-")
                }

                if(details.hasOwnProperty('sessionDate'))
                {
                    slotDate = details.sessionDate;
                }

                if(slot.length===2 && slotDate!=="")
                {
                    // let coachTimezone = await getCochTimezone('coach',item.coach_uid)
                    let coachTimezone = item.coachTimezone;
                    let userTimezone = localStorage.getItem('timezone')
                    let start = slotDate+' '+slot[0]
                    let end = slotDate+' '+slot[1];

                    if(this.props.type==="usercalender")
                    {

                       
                        start = convertToTimezone(coachTimezone,`${slotDate} ${slot[0]}`,userTimezone,'YYYY-MM-DD HH:mm')
                        end = convertToTimezone(coachTimezone,`${slotDate} ${slot[1]}`,userTimezone,'YYYY-MM-DD HH:mm')
       

                    }
                    else
                    {
                         start = slotDate+' '+slot[0]
                         end = slotDate+' '+slot[1];

                    }

                  
                    var statusColor='black';var readonly=false;
        
                    if(item.status==="joined")
                    {
                        statusColor="#0c9126";
                        readonly=true;
                    }
                    else if(item.status==="Confirmed")
                    {
                        statusColor="#9e5fff";
                        //statusColor="#F0CE38";
                    }
                    else if(item.status==="complete")
                    {
                        statusColor="#0c9126"
                        readonly=true;
                    }
                    else if(item.status==="Cancelled")//cancel
                    {
                        statusColor="#e3140c"
                    }
                    if(this.props.type==="usercalender")
                    {
                        readonly=true;
                    }
                    else
                    {
                        var toDate = this.getTodayDate();
                        //prev condition
                        // if(new Date(toDate)<new Date(slotDate))
                        // {
                            
                        //         readonly=false
                            
                        // }
                        // else
                        // {
                        //     readonly=true;
                        // }

                            if(!["Confirmed","Cancelled"].includes(item.status))//cancel
                            {
                                readonly=true
                            }
                            else
                            {
                                readonly=false
                            }
                    }


                    if(item.hasOwnProperty('userRoomSessionDetails') && this.props.type==="usercalender")
                    {
                        
                        if(item.userRoomSessionDetails!=="" && item.userRoomAccessCode!=="")
                        {
                            var userRoomSessionDetails=JSON.parse(item.userRoomSessionDetails);
                            console.log('booking ',item.uid,' userRoomSessionDetails ',userRoomSessionDetails)

                            var userBody = `<div><h6>Coach Name : <i>${item.coachName}</i></h6><br/>`
                            if(item.status === 'deleted' || item.status === 'Cancelled')//cancel
                                userBody += `<h6>Status : <i><span style="color: red;">Cancelled</span></i></h6></div>`
                            else
                                userBody += `<p>User Link : <a href="${userRoomSessionDetails.userLink}" type="_blank" >User Room Link</a><br/><span>Access code: ${item.userRoomAccessCode}</span></p></div>`;
                        }
                        else
                        {
                            var userBody = `<div><h6>Coach Name : <i>${item.coachName}</i></h6></div>`;
                        }
                        
                    }
                    else
                    {
                        if(typeof item.userRoomSessionDetails === 'string' && item.userRoomSessionDetails!=="")
                        {
                            var userRoomSessionDetails=JSON.parse(item.userRoomSessionDetails);
        
                            if(userRoomSessionDetails.hasOwnProperty('coachLink'))
                            {
                                var userBody = `<div><h6>Client : <i>${item.uname}</i></h6><br/>`
                                if(item.status === 'deleted' || item.status === 'Cancelled')//cancel
                                    userBody += `<h6>Status : <i><span style="color: red;">Cancelled</span></i></h6></div>`
                                else
                                    userBody += `<p>Coach Link : <a href="${userRoomSessionDetails.coachLink}" type="_blank" >Coach Room Link</a></p></div>`;
                            }
                            else
                            {
                                var userBody = `<div><h6>Client : <i>${item.uname}</i></h6></div>`;
                            }
                        }
                        else
                        {
                            var userBody = `<div><h6>Client : <i>${item.uname}</i></h6></div>`;
                        }
                        //var userBody = `<div><h6>Client : <i>${item.uname}</i></h6></div>`;
        
                    }
        
                    scheduleArr.push({
                        id: i,
                        calendarId: calID,
                        title: `Intro Session : ${details.session_name}`,
                        body: userBody,
                        category: 'time',
                        dueDateClass: '',
                        start: start.replace(' ', 'T'),
                        end: end.replace(' ', 'T'),
                        color:'white',
                        bgColor: statusColor,
                        borderColor:statusColor,
                        isReadOnly: readonly,
                        raw: {
                        
                          coachUid:item.coach_uid,
                          pack_id:item.id,
                          sessionType:'intro',
                          start_time:slot[0],
                          end_time:slot[1],
                          sessionDate:slotDate,
                          package_uid:item.package_uid,
                          uid:item.uid,
                          user_uid:item.user_uid,
                          status:item.status,
                          duration:item.introDuration,
                          userRoomSessionDetails:item.hasOwnProperty('userRoomSessionDetails')?item.userRoomSessionDetails:"",
                        },
            
                       })
        
                       i++;
        

            
                   

                }

            }

            
            
        })); 
        dcalender.createSchedules(scheduleArr);

    }


}

createScheduleForSessionBooking = async () =>{

    var scheduleArr= []; 
    var calID = dcalender.id
    if(this.props.sessionBooking.length > 0 && Object.keys(this.props.sessionBooking[0]).length !== 0 && this.props.sessionBooking[0].constructor === Object)
    {
       
        await Promise.all(this.props.sessionBooking.map(async (item,) => {
            
            var readonly=false;var statusColor='black';
            let i = 0;
            let session_details = JSON.parse(item.session_details)
            let sessionId = item.session_id.charAt(item.session_id.length - 1);
            console.log("session_booking_details", item.sessionName)
            // console.log('session_details ',session_details[0].Length)
            const packageName = item.sessionName
            const sessionName = session_details[sessionId].sessionName;
            let date = item.date.split('T')
            date = date[0]
            //console.log('date resl ', date)
            console.log('typeeeee',item)

            // let coachTimezone = await getCochTimezone('coach', item.coach_id);
            let coachTimezone = item.coachTimezone;
            let userTimezone = localStorage.getItem('timezone')

            // console.log('timezone',)
            // console.log('userTimezone',)
            let start = ''
            let end = ''
            
            if(this.props.type==="usercalender")
            {
               // console.log('start end',`${date} ${item.start_time}`,`${date} ${item.end_time}`)
                 start = convertToTimezone(coachTimezone,`${date} ${item.start_time}`,userTimezone,'YYYY-MM-DD HH:mm')
                 end = convertToTimezone(coachTimezone,`${date} ${item.end_time}`,userTimezone,'YYYY-MM-DD HH:mm')
                //console.log('convert ',start ,end)
            }
            else
            {
                 start = date+' '+item.start_time
                 end = date+' '+item.end_time

            }
            

            if(date!=="" && typeof date!=='undefined' && start!=="" && typeof start!=='undefined' && end!=="" && typeof end!=='undefined')
            {
                if(item.status===2)
                {
                    statusColor="#0c9126";
                    readonly=true
                }
                else if(item.status===1)
                {
                    statusColor="#9e5fff";
                }
                else if(item.status===3)
                {
                    statusColor="#0c9126"
                    readonly=true
                }
                else if(item.status===4)
                {
                    statusColor="#e3140c"
                   
                }
                console.log('statusColor',statusColor,item.status,item.id)
    
                if(this.props.type==="usercalender")
                {
                    readonly=true;
                }
                else
                {
                    var toDate = this.getTodayDate();
                   //prev condition
                    // if(new Date(toDate)<new Date(date))
                    // {
                    //     if(this.differDatesHours(start)<24)
                    //     {
                    //         readonly=true
                    //     }
                    //     else
                    //     {
                    //         readonly=false
                    //     }
                        
                    // }
                    // else
                    // {
                    //     readonly=true;
                    // }


                        if(![1,4].includes(item.status))
                        {
                            readonly=true
                        }
                        else
                        {
                            readonly=false
                        }
                }
    
                console.log('schedule no ',item.uid,' readonly ',readonly)
    
                if(item.hasOwnProperty('userRoomSessionDetails') && this.props.type==="usercalender")
                {
                    if(item.userRoomSessionDetails!=="" && item.userRoomAccessCode!=="")
                    {
                        var userRoomSessionDetails=JSON.parse(item.userRoomSessionDetails);
                        var userBody = `<div><h6>Coach Name : <i>${item.coachName}</i></h6><br/>`
                        // console.log('booking ',item.uid,' userRoomSessionDetails ',userRoomSessionDetails)
                        if(item.status === 4)//cancel
                                userBody += `<h6>Status : <i><span style="color: red;">Cancelled</span></i></h6></div>`
                            else
                                userBody += `<p>User Link : <a href="${userRoomSessionDetails.userLink}" type="_blank" >User Room Link</a><br/><span>Access code: ${item.userRoomAccessCode}</span></p></div>`;
                        
                        
                    }
                    else
                    {
                        var userBody = `<div><h6>Coach Name : <i>${item.coachName}</i></h6></div>`;
                    }
                    
                }
                else
                {
                    if(typeof item.userRoomSessionDetails === 'string' && item.userRoomSessionDetails!=="")
                    {
                        var userRoomSessionDetails=JSON.parse(item.userRoomSessionDetails);
    
                        if(userRoomSessionDetails.hasOwnProperty('coachLink'))
                        {
                            var userBody = `<div><h6>Client : <i>${item.uname}</i></h6><br/>`
                            if(item.status === 4)//cancel
                                userBody += `<h6>Status : <i><span style="color: red;">Cancelled</span></i></h6></div>`
                            else
                                userBody += `<p>Coach Link : <a href="${userRoomSessionDetails.coachLink}" type="_blank" >Coach Room Link</a></p></div>`;
                           
                        }
                        else
                        {
                            var userBody = `<div><h6>Client : <i>${item.uname}</i></h6></div>`;
                        }
                    }
                    else
                    {
                        var userBody = `<div><h6>Client : <i>${item.uname}</i></h6></div>`;
                    }
    
    
                }
                
    
                scheduleArr.push({
                    id: item.uid,
                    calendarId: calID,
                    title: `${packageName} - ${sessionName}`,
                    body: userBody,
                    category: 'time',
                    dueDateClass: '',
                    start: start.replace(' ', 'T'),
                    end: end.replace(' ', 'T'),
                    color: 'white',
                    bgColor: statusColor,
                    borderColor: statusColor,
                    isReadOnly: readonly,
                    raw: {
                      
                      coachUid:item.coach_id,
                      pack_id:item.package_id,
                      sessionType:'real',
                      start_time:item.start_time,
                      end_time:item.end_time,
                      sessionDate:item.date,
                      package_uid:item.package_id,
                      uid:item.uid,
                      user_uid:item.user_id,
                      status:item.status,
                      duration:session_details[0].Length,
                      userRoomSessionDetails:item.hasOwnProperty('userRoomSessionDetails')?item.userRoomSessionDetails:"",
                    }
                   })

            }

           

               i++;
        }));


        dcalender.createSchedules(scheduleArr);
    }
    
}

// createScheduleForUnavailableData = async () =>{

//     var scheduleArr= [];
//     var calID = dcalender.id 
//     if(this.props.unavailableData.length > 0 )
//     {
//         if(this.props.unavailableData[0].hasOwnProperty('schedule_details'))
//         {

//             let details = JSON.parse(this.props.unavailableData[0].schedule_details)
//             console.log('unavailableData details ',details);
            

//             if(details.unavailableDays.length > 0 &&  Object.keys(details.unavailableDays[0]).length !== 0 && details.unavailableDays[0].constructor === Object)
//             {
//                 console.log('details.unavailableDays[0] ',details.unavailableDays[0]);
//                 console.log('coming');

//                 await Promise.all(details.unavailableDays.map(async (each) => {
//                     //console.log('unavailableData each ',each);
//                     var i=1;
//                     let start = each.date+' '+each.From
//                     let end = each.date+' '+each.To
    
//                     scheduleArr.push({
//                         id: i,
//                         calendarId: calID,
//                         title: `Unavailable`,
//                         body: `<h6>Date : <i>${each.date}</i> </h6>`,
//                         category: 'time',
//                         dueDateClass: '',
//                         start: start.replace(' ', 'T'),
//                         end: end.replace(' ', 'T'),
//                         isReadOnly: true,
//                         color: 'white',
//                         bgColor: 'red',
//                         borderColor: 'red',
//                         dragBgColor:'red',
//                         raw: {
                      
//                             coachUid: "",
//                             end_time: "",
//                             pack_id: "",
//                             package_uid: "",
//                             sessionDate: "",
//                             sessionType: "",
//                             start_time: "",
//                             uid: "",
//                             status:"",
//                             user_uid: "",
//                             duration:"",
//                           }
//                        })
    
//                        i++;
                  
//                 }));

//                 dcalender.createSchedules(scheduleArr);

//             }

//         }
            
//     }

    
    
// }
createScheduleForUnavailableData = async () =>{

    var scheduleArr= [];
    var calID = dcalender.id 
    if(this.props.unavailableData.length > 0 )
    {
        if(this.props.unavailableData[0].hasOwnProperty('schedule_details'))
        {

            let details = JSON.parse(this.props.unavailableData[0].schedule_details)
            console.log('unavailableData details ',details);
            

            if(details.unavailableDays.length > 0 &&  Object.keys(details.unavailableDays[0]).length !== 0 && details.unavailableDays[0].constructor === Object)
            {
                console.log('details.unavailableDays[0] ',details.unavailableDays[0]);
                console.log('coming');

                await Promise.all(details.unavailableDays.map(async (each) => {
                    //console.log('unavailableData each ',each);
                    var i=1;
                    let start = each.From
                    let end = each.To

                    let dateStart = each.From.split(' ').shift() 
                    let dateEnd = each.To.split(' ').shift() 
    
                    scheduleArr.push({
                        id: i,
                        calendarId: calID,
                        title: `Unavailable`,
                        body: `<h6>Date : <i>${dateStart === dateEnd ? moment(dateEnd).format(dateConfig.dateFormat) : moment(dateStart).format(dateConfig.dateFormat)+' to '+moment(dateEnd).format(dateConfig.dateFormat) }</i> </h6>`,
                        category: 'time',
                        dueDateClass: '',
                        start: start.replace(' ', 'T'),
                        end: end.replace(' ', 'T'),
                        isReadOnly: true,
                        color: 'white',
                        bgColor: 'red',
                        borderColor: 'red',
                        dragBgColor:'red',
                        raw: {
                      
                            coachUid: "",
                            end_time: "",
                            pack_id: "",
                            package_uid: "",
                            sessionDate: "",
                            sessionType: "",
                            start_time: "",
                            uid: "",
                            status:"",
                            user_uid: "",
                            duration:"",
                          }
                       })
    
                       i++;
                  
                }));

                dcalender.createSchedules(scheduleArr);

            }

        }
            
    }

    
    
}


displayCalendar()
{
    let calendarOptions={}
    if(this.state.view=='twoweek')
    {
        calendarOptions={
        defaultView: 'month',
        // view:this.state.view,
            taskView: true,
            useDetailPopup:true,
            month: {
                visibleWeeksCount: 2 // visible week count in monthly
            },
        }

        
    }else if(this.state.view=='threeweek')
    {
        calendarOptions={
            defaultView: 'month',
            // view:this.state.view,
                taskView: true,
                useDetailPopup:true,
                month: {
                    visibleWeeksCount: 3 // visible week count in monthly
                },
            }
    }
    else{
            calendarOptions={
            defaultView: this.state.view,
            // view:this.state.view,
                taskView: true,
                useDetailPopup:true,
                //isReadOnly:true,
        }

    }
    
    document.getElementById('calendar').innerHTML = "";
    console.log('state value ' ,this.state.view)
    this.calendar = new Calendar('#calendar', {
        ...calendarOptions,
        template: {
            monthDayname: function(dayname) {
            return `<span className="calendar-week-dayname-name">` + dayname.label + `</span>`;
            }
            
        },
        
        }); 
    var calendar= this.calendar
    let self=this
        this.calendar.on({
            
        'clickMore': function(e) {
                console.log('clickMore', e);
            },
            'clickSchedule': function(e) {
                console.log('clickSchedule', e);
            },
            'clickDayname': function(date) {
                console.log('clickDayname', date);
            },
            'beforeCreateSchedule': function(e) {
                console.log('beforeCreateSchedule', e);
                //saveNewSchedule(e);
            },
        'clickSchedule': function(e) {
            console.log('clickSchedule', e);
        },
        'beforeCreateSchedule': function(e) {
            console.log('beforeCreateSchedule', e);
            // open a creation popup
    
            // If you dont' want to show any popup, just use `e.guide.clearGuideElement()`
    
            // then close guide element(blue box from dragging or clicking days)
            e.guide.clearGuideElement();
        },
        'beforeUpdateSchedule': function(e) {
            console.log('beforeUpdateSchedule', e);
            console.log('calender id', e.schedule.start);
            console.log('condition',e.schedule.raw.status=="Cancelled",e.schedule.raw.status)

            e.schedule.start = e.schedule.start;
            e.schedule.end = e.schedule.end;
            if(e.schedule.raw.status==='Confirmed' || e.schedule.raw.status=="Cancelled" || e.schedule.raw.status===1)
            {
                console.log('beforeUpdateSchedule 1', e);
                self.handleEditSchedule(e)

            }else{
                message.error(`Schedule not change`);
            }    
            //calendar.updateSchedule(e.schedule.id, e.schedule.calendarId, e.schedule);
            
        },
        'beforeDeleteSchedule': function(e) {
            console.log('beforeDeleteSchedule', e);
            message.error(`Schedule can not be deleted`);
            // this.handleDeleteunavailable(e)
            //calendar.deleteSchedule(e.schedule.id, e.schedule.calendarId);
        }
    });
    
    if(this.state.view !== newView)
    {
        
            this.customCreateSchedule()

        
    }
    //console.log(this.props.introBookings)
    
    
}

   


populateSchedule = (data)=>{
  if(data.length > 0 ){
    let calID = this.calendar.id
    let sched = _.each(data, (eachB)=>{
       let i = 0;
       let details = JSON.parse(eachB)
        let slot = details.slotTime.split("-")
        let start = details.sessionDate+' '+slot[0]
            let end = details.sessionDate+' '+slot[1]

       
       let s = {
        id: i++,
        calendarId: calID,
        title: details.session_name,
        category: 'time',
        dueDateClass: '',
        start: start.replace(' ', 'T'),
        end: end.replace(' ', 'T')
       }

       return s
    })

    console.log("SCHEDULE ARRAY ")
    console.log(sched)
  }
}

componentDidUpdate(prevProps) {

    console.log(prevProps)
//this.setState({prevProps:prevProps})
prevPropsnew=prevProps
    //this.customCreateSchedule()
    

    if (this.props.introBookings !== prevProps.introBookings) {
        // console.log('condition',this.props.introBookings !== prevProps.introBookings)
        // console.log('current',this.props.introBookings)
        // console.log('prevProps',prevProps.introBookings)
        this.createScheduleForintroBookings();
    } 

    if (this.props.sessionBooking !== prevProps.sessionBooking) {
    this.createScheduleForSessionBooking();
    } 

    if (this.props.unavailableData !== prevProps.unavailableData) {
        this.createScheduleForUnavailableData();
      } 

    // if (this.props.type !== prevProps.type) {
    //     this.setState({type:this.props.type})
    //   } 
  

}

customCreateSchedule(){
    let prevProps=prevPropsnew
    console.log('prestate ',prevProps ,'current state',this.props.introBookings)
//if(prevProps.introBookings !== this.props.introBookings) {
    //var list_data = JSON.parse(this.props.packagedata.session_details);
    //this.setState({ introBookings: this.props.introBookings });
    //this.populateSchedule(this.props.introBookings)

    console.log("intro ", this.props.introBookings)
    if(this.props.introBookings.length > 0 ){
      let calID = this.calendar.id
      let arr = []
      _.each(this.props.introBookings, (eachB)=>{
         let i = 1;
         let details = JSON.parse(eachB.package_details)
          let slot = details.slotTime.split("-")
          let start = details.sessionDate+' '+slot[0]
          let end = details.sessionDate+' '+slot[1]
         
         let s = {
          id: i++,
          calendarId: calID,
          title: `Intro Session : ${details.session_name}`,
          body: `<h6>Client : <i>${details.consumer_name}</i></h6>`,
          category: 'time',
          dueDateClass: '',
          start: start.replace(' ', 'T'),
          end: end.replace(' ', 'T'),
          color:'white',
          bgColor: 'blue',
          raw: {
            
            coachUid:eachB.coach_uid,
            pack_id:eachB.id,
            sessionType:'intro',
            start_time:slot[0],
            end_time:slot[1],
            sessionDate:details.sessionDate,
            package_uid:eachB.package_uid,
            uid:eachB.uid,
            user_uid:eachB.user_uid,
            status:eachB.status,
            duration:eachB.introDuration,
          },

         }
         arr.push(s)
         //return s
      })

      console.log("SCHEDULE ARRAY ")
      console.log(arr)
      this.calendar.createSchedules(arr)
    }

    //} 

   // if(prevProps.sessionBooking !== this.props.sessionBooking) {

        console.log("SBooking ", this.props.sessionBooking)

    if(this.props.sessionBooking.length > 0 ){
      let calID = this.calendar.id
      let arr = []
      _.each(this.props.sessionBooking, (eachB)=>{
         let i = 0;
          let session_details = JSON.parse(eachB.session_details)
        // console.log('session_details ',session_details[0].Length)
          let date = eachB.date.split('T')
          date = date[0]
        console.log('date resl ', date)
          let start = date+' '+eachB.start_time
          let end = date+' '+eachB.end_time

         
         
         let s = {
          id: eachB.coach_id,
          calendarId: calID,
          title: `Session : ${eachB.sessionName}`,
          body: `<h6>Client : <i>${eachB.uname}</i></h6>`,
          category: 'time',
          dueDateClass: '',
          start: start.replace(' ', 'T'),
          end: end.replace(' ', 'T'),
          color: 'white',
          bgColor: 'blue',
          borderColor: 'blue',
          raw: {
            
            coachUid:eachB.coach_id,
            pack_id:eachB.package_id,
            sessionType:'real',
            start_time:eachB.start_time,
            end_time:eachB.end_time,
            sessionDate:eachB.date,
            package_uid:eachB.package_id,
            uid:eachB.uid,
            user_uid:eachB.user_id,
            status:eachB.status,
            duration:session_details[0].Length,
          }
         }
         arr.push(s)
         //return s
      })

      console.log("SCHEDULE ARRAY ")
      console.log(arr)
      this.calendar.createSchedules(arr)
    }
   
   // }
    
    //if(prevProps.unavailableData !== this.props.unavailableData) {
  if(this.props.unavailableData.length > 0 ){
      
      let calID = this.calendar.id
      let arr = []
      let i = 0;
      _.each(this.props.unavailableData, (eachC)=>{
        
         let details = JSON.parse(eachC.schedule_details)
         console.log('details ',details.unavailableDays)
         details.unavailableDays.map( (items, key) => {

                   //let date = eachC.date.split('T')
              // date = date[0]
          
               let start = items.date+' '+items.From
               let end = items.date+' '+items.To

            console.log('key ',key)
          
             let s = {
              id: ++key,
              calendarId: calID,
              title: `Unavailable`,
              body: `<h6>Date : <i>${items.date} </i> </h6>`,
              category: 'time',
              dueDateClass: '',
              start: start.replace(' ', 'T'),
              end: end.replace(' ', 'T'),
              isReadOnly: true,
              color: 'white',
              bgColor: 'red',
              borderColor: 'red',
              dragBgColor:'red'
             }
             arr.push(s)
         

          })
         

      
      })

      console.log("SCHEDULE unavailable ")
      console.log(arr)
      this.calendar.createSchedules(arr)
    }

    //}


}

addToSchedule = (data) => {
console.log("Received booking data")
console.log(data)
let details = JSON.parse(data.package_details)
let slot = details.slotTime.split("-")
let start = details.sessionDate+' '+slot[0]
let end = details.sessionDate+' '+slot[1]
this.calendar.createSchedules([
      {
          id: '1',
          calendarId: this.calendar.id,
          title: `Session : ${details.session_name}`,
          body: `<h6>Client : <i>${details.consumer_name}</i></h6>`,
          category: 'time',
          dueDateClass: '',
          start: start.replace(' ', 'T'),
          end: end.replace(' ', 'T')
      },
      // {
      //     id: '2',
      //     calendarId: this.calendar.id,
      //     title: 'second schedule',
      //     category: 'time',
      //     dueDateClass: '',
      //     start: '2021-01-21T17:30:00+09:00',
      //     end: '2021-01-21T23:45:00+09:00',
      //     //isReadOnly: true    // schedule is read-only
      // },
      
      
  ]);

  this.props.handleAcceptBooking(data)
}

handleViewChange = async(data,e) => {
    //const name= e.target;
    // newView=this.state.view
    // console.log('dd',data)
    // await this.setState({view:data})
  
    //this.displayCalendar()
    console.log("## data ##", data)

    var viewType = data;
   
    if(viewType==='day')
    {
        // daily view
        dcalender.changeView('day', true);

    }
    else if(viewType==='week')
    {
        // weekly view
        dcalender.changeView('week', true);

    }
    else if(viewType==='month')
    {
        // monthly view(default 6 weeks view)
        dcalender.setOptions({month: {visibleWeeksCount: 6}}, true); // or null
        dcalender.changeView('month', true);

    }
    else if(viewType==='twoweek')
    {
        // 2 weeks monthly view
        dcalender.setOptions({month: {visibleWeeksCount: 2}}, true);
        dcalender.changeView('month', true);

    }
    else if(viewType==='threeweek')
    {
        // 3 weeks monthly view
        dcalender.setOptions({month: {visibleWeeksCount: 3}}, true);
        dcalender.changeView('month', true);

    }
    else if(viewType==='threeweek')
    {
        // 3 weeks monthly view
        dcalender.setOptions({month: {visibleWeeksCount: 3}}, true);
        dcalender.changeView('month', true);

    }
    else if(viewType==='narrowweekend' && e.target.checked===true)
    {
         // narrow weekend
        dcalender.setOptions({month: {narrowWeekend: true}}, true);
        dcalender.setOptions({week: {narrowWeekend: true}}, true);
        dcalender.changeView(dcalender.getViewName(), true);
    }
    else if(viewType==='changestartday')
    {
        // change start day of week(from monday)
        dcalender.setOptions({week: {startDayOfWeek: 1}}, true);
        dcalender.setOptions({month: {startDayOfWeek: 1}}, true);
        dcalender.changeView(dcalender.getViewName(), true);
    }
    else if(viewType==='workweek')
    {
        // work week
        dcalender.setOptions({week: {workweek: true}}, true);
        dcalender.setOptions({month: {workweek: true}}, true);
        dcalender.changeView(dcalender.getViewName(), true);
    }
    else 
    {
        // monthly view(default 6 weeks view)
        dcalender.setOptions({month: {visibleWeeksCount: 6}}, true); // or null
        dcalender.changeView('month', true);

    }

   






  
} 


handleEditSchedule = async(data) => {

    console.log('all data ',data.schedule.raw)
  

    await this.setState({
        coachUid: data.schedule.raw.coachUid,
        end_time: data.schedule.raw.end_time,
        pack_id: data.schedule.raw.pack_id,
        package_uid: data.schedule.raw.package_uid,
        sessionDate: data.schedule.raw.sessionDate,
        sessionType: data.schedule.raw.sessionType,
        start_time: data.schedule.raw.start_time,
        uid: data.schedule.raw.uid,
        user_uid: data.schedule.raw.user_uid,
        duration: data.schedule.raw.duration,
        userRoomSessionDetails:data.schedule.raw.hasOwnProperty('userRoomSessionDetails')?data.schedule.raw.userRoomSessionDetails:"",
    })
    this.setState({editModal:true})
    console.log('all data 1',this.state)
  
  
} 
handleShow()
{
    this.setState({alertShow:true})
}
handlecloseAlert()
{
    this.setState({alertShow:false})
}


render(){
  console.log("AB", this.props.introBookings)
  //this.populateSchedule(this.props.introBookings)
  //console.log('dddddd', this.state.coachUid);
  return(

<div>
   {this.state.sessionDate!==''?
    
   
    <UpdateSchedules show={this.state.editModal} close={()=>this.handleClose()} 
    {...this.state}
    // coachUid= {this.state.coachUid}
    // end_time= {this.state.end_time}
    // pack_id= {this.state.pack_id}
    // package_uid= {this.state.package_uid}
    // sessionDate= {this.state.sessionDate}
    // sessionType= {this.state.sessionType}
    // start_time= {this.state.start_time}
    // uid= {this.state.uid}
    // user_uid=  {this.state.user_uid}
    // duration= {this.state.duration}
    />       

   
   :''}

<div className="col-md-12 pl-0 pr-0">
<div className="schduleCalendar">
  
    <div className="booking__calendar">
      <div id="menu">
          <span style={{display: "inline-block"}}>
              {/* <button id="dropdownMenu-calendarType" className="btn btn-default btn-sm dropdown-toggle" type="button" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="true"
                  >
                  <i id="calendarTypeIcon" className="calendar-icon ic_view_month" style={{marginRight: "4px"}}></i>
                  
                  <span id="calendarTypeName">Dropdown</span>&nbsp;

                  
                  
              </button>
              <ul className="dropdown-menu" role="menu" aria-labelledby="dropdownMenu-calendarType" >
                  <li role="presentation">
                      <a className="dropdown-menu-title" role="menuitem" data-action="toggle-daily" onClick={(e) => this.handleViewChange('day',e)}>
                          <i className="calendar-icon ic_view_day"></i>Daily
                      </a>
                  </li>
                  <li role="presentation">
                      <a className="dropdown-menu-title" role="menuitem" data-action="toggle-weekly" onClick={(e) => this.handleViewChange('week',e)}>
                          <i className="calendar-icon ic_view_week"></i>Weekly
                      </a>
                  </li>
                  <li role="presentation">
                      <a className="dropdown-menu-title" role="menuitem" data-action="toggle-monthly" onClick={(e) => this.handleViewChange('month',e)}>
                          <i className="calendar-icon ic_view_month"></i>Month
                      </a>
                  </li>
                  <li role="presentation">
                      <a className="dropdown-menu-title" role="menuitem" data-action="toggle-weeks2" onClick={(e) => this.handleViewChange('twoweek',e)}>
                          <i className="calendar-icon ic_view_week"></i>2 weeks
                      </a>
                  </li>
                  <li role="presentation">
                      <a className="dropdown-menu-title" role="menuitem" data-action="toggle-weeks3" onClick={(e) => this.handleViewChange('threeweek',e)}>
                          <i className="calendar-icon ic_view_week"></i>3 weeks
                      </a>
                  </li>
                  <li role="presentation" className="dropdown-divider"></li>
                //   commented items
                  <li role="presentation">
                      <a role="menuitem" data-action="toggle-workweek">
                          <input type="checkbox" className="tui-full-calendar-checkbox-square" value="toggle-workweek" checked/>
                          <span className="checkbox-title"></span>Show weekends
                      </a>
                  </li>
                  <li role="presentation">
                      <a role="menuitem" data-action="toggle-start-day-1">
                          <input type="checkbox" className="tui-full-calendar-checkbox-square" value="toggle-start-day-1"/>
                          <span className="checkbox-title"></span>Start Week on Monday
                      </a>
                  </li>
                  <li role="presentation">
                      <a role="menuitem" data-action="toggle-narrow-weekend">
                          <input type="checkbox" className="tui-full-calendar-checkbox-square" value="toggle-narrow-weekend" onClick={(e) => this.handleViewChange('narrowweekend',e)} value="narrowweekend"/>
                          <span className="checkbox-title"></span>Narrower than weekdays
                      </a>
                  </li>
                //   commented items close
              </ul> */}
              <Dropdown>
              <Dropdown.Toggle variant="light" id="dropdownMenu-calendarType" className="btn btn-default btn-sm dropdown-toggle" type="button" 
                  aria-haspopup="true" aria-expanded="true"
                  >
                  <i id="calendarTypeIcon" className="calendar-icon ic_view_month" style={{marginRight: "4px"}}></i>
                  
                  <span id="calendarTypeName">Dropdown</span>&nbsp;

                  
                  
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu" role="menu" aria-labelledby="dropdownMenu-calendarType" >
                  <Dropdown.Item role="presentation">
                      <a className="dropdown-menu-title" role="menuitem"  onClick={(e) => this.handleViewChange('day',e)}>
                          <i className="calendar-icon ic_view_day"></i>Daily
                      </a>
                  </Dropdown.Item>
                  <Dropdown.Item role="presentation">
                      <a className="dropdown-menu-title" role="menuitem"  onClick={(e) => this.handleViewChange('week',e)}>
                          <i className="calendar-icon ic_view_week"></i>Weekly
                      </a>
                  </Dropdown.Item>
                  <Dropdown.Item role="presentation">
                      <a className="dropdown-menu-title" role="menuitem"  onClick={(e) => this.handleViewChange('month',e)}>
                          <i className="calendar-icon ic_view_month"></i>Month
                      </a>
                  </Dropdown.Item>
                  <Dropdown.Item role="presentation">
                      <a className="dropdown-menu-title" role="menuitem"  onClick={(e) => this.handleViewChange('twoweek',e)}>
                          <i className="calendar-icon ic_view_week"></i>2 weeks
                      </a>
                  </Dropdown.Item>
                  <Dropdown.Item role="presentation">
                      <a className="dropdown-menu-title" role="menuitem"  onClick={(e) => this.handleViewChange('threeweek',e)}>
                          <i className="calendar-icon ic_view_week"></i>3 weeks
                      </a>
                  </Dropdown.Item>
                  <Dropdown.Item role="presentation" className="dropdown-divider"></Dropdown.Item>
              </Dropdown.Menu>
              </Dropdown>
              
             
          </span> 
          <span id="menu-navi">
              <button type="button" className="btn btn-default btn-sm move-day" data-action="move-prev" onClick={()=>{this.moveToNextOrPrevRange(-1)}}>
                  <i className="calendar-icon ic-arrow-line-left" data-action="move-prev"></i>
              </button>
              <button  type="button" className="btn btn-default btn-sm move-day" data-action="move-next" onClick={()=>{this.moveToNextOrPrevRange(1)}}>
                  <i className="calendar-icon ic-arrow-line-right" data-action="move-next"></i>
              </button>
          </span>
          <span id="renderRange" className="render-range">{this.state.currentDate}</span>


      </div>
      
      <div className="table-responsive">
        <div id="calendar" className="mb-3">
      </div>
      
        
      </div>
  </div>





</div>
</div> 
<Modal show={this.state.alertShow} onHide={this.handlecloseAlert} className="">
    <Modal.Body>
{/* <div className="modal-content"> */}
          <div className="alert__icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
              <path
                d="M61.31 56.51A4.939 4.939 0 0156.99 59H7.01a5.006 5.006 0 01-4.35-7.47l24.99-44A4.946 4.946 0 0132 5a4.96 4.96 0 014.35 2.53l24.99 44a4.93 4.93 0 01-.03 4.98z"
                fill="#c1272d"
              />
              <path
                d="M7.01 55a.938.938 0 01-.854-.487.948.948 0 01-.022-1l25-44.008A.945.945 0 0132 9a.957.957 0 01.873.509l24.988 44a.933.933 0 010 .978.952.952 0 01-.87.516z"
                fill="#ffe184"
              />
              <path d="M32 44a2.373 2.373 0 01-2.363-2.158l-1.578-19.53A3.954 3.954 0 0132 18a3.954 3.954 0 013.938 4.312l-1.578 19.53A2.373 2.373 0 0132 44z" />
              <circle cx="32" cy="49" r="3" />
            </svg>
          </div>
          <p style={{color:"black"}} className="text-center">Are you sure you want to cancel this Intro call ?</p>
         
         
          <ul style={{display: "flex", justifyContent: "space-between"}}>
           
            <li style={{flex: "none"}} className="alert__cancel">
              <button
                className="btn btn-secondary"
                // href="javascript:void(0)"
                data-dismiss="modal"
                onClick={this.handlecloseAlert}
              >
                Exit
              </button>
            </li>


            {/* {func_button} */}
            <li style={{flex: "none"}} onClick={this.handlecloseAlert}>
                 <li className="alert__confirm">
                        <button
                        className="btn btn-success"
                        data-dismiss="modal"
                        onClick={() => {
                            this.handleDelete(this.state.deletedata);
                        }}
                        >
                        Confirm Cancellation
                        </button>
                </li>
            </li>
            
          </ul>
          {/* </div> */}
          </Modal.Body>
      </Modal>

</div> 
        )
    }
}
export default MyCalender