import React, { Component } from "react";
import axios from "../../config/axios";
import { toast } from "react-toastify";
import ClientFeedback from "./view/ClientFeedback";
import Donut from "../../components/charts/Dount";
import { SelectSkills } from "./edit";

class Testimonial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      avatar_path: "",
      designation: "",
      speech: "",
      is_featured: true,
      newskilllables: [],
      testimonialcollection: [],
      OwlC: "",
      skillValueCollection: [
        ["mango", 100, true],
        ["apple", 100, true],
        ["banana", 100, false],
      ],
      skillvalueOrange: ["skil1orange", "skill2orange"],
      skillvalueRed: ["skil1red", "skill2red"],
      skillvalueGreen: ["skil1green", "skill2green"],
      skillvalueBlue: ["skil1blre", "skill2blue"],
    };
  }

  componentDidMount() {
    console.log('getTestimonialpublic' ,this.props)
    setTimeout(this.getTestimonialpublic(), 1200);
  }

  handleChange = (e) => {
    e.persist();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleReset = (e) => {
    this.setState({
      name: "",
      avatar_path: "",
      designation: "",
      speech: "",
    });
  };

  getTestimonial() {
    axios
      .get("/users/testimonials", {
        headers: {
          "X-Auth": this.props.token,
        },
      })
      .then((res) => {
        const padata = res.data.value;
        this.setState({ OwlC: "" });
        this.setState({ testimonialcollection: padata });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getTestimonialpublic() {
    axios
      .post("/users/getpublictestimonials", {uid:this.props.publiccoachuid
      
      })
      .then((res) => {
        const padata = res.data.value;
        this.setState({ OwlC: "" });
        this.setState({ testimonialcollection: padata });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleSubmitTestimonial = (e) => {
    e.preventDefault();

    const formData = {
      name: this.state.name,
      avatar_path: this.state.avatar_path,
      designation: this.state.designation,
      speech: this.state.speech,
      is_featured: this.state.is_featured,
    };
    axios
      .post("users/testimonials", formData, {
        headers: {
          "X-Auth": this.props.token,
        },
      })
      .then((res) => {
        toast("Testimonial added", { type: "success" });
        this.getTestimonial();
      })
      .catch((error) => {
        toast("Error in adding testimonial", { type: "error" });
        console.error(error);
      });
  };

  handleDelete = (id) => {
    axios
      .delete(`/users/testimonials/updatetestimonial/${id}`, {
        headers: { "X-Auth": this.props.token },
      })
      .then((res) => {
        console.log("delete res", res);
        if (res.data.success) {
          toast(res.data.msg, { type: "success" });
          this.getTestimonial();
        } else {
          toast(res.data.msg, { type: "error" });
        }
      });
  };

  handleEdit = (formData) => {
    const id = formData.uid;
    axios
      .put(`/users/testimonials/updatetestimonial/${id}`, formData, {
        headers: {
          "X-Auth": this.props.token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          toast(res.data.msg, { type: "success" });
        } else {
          toast(res.data.msg, { type: "error" });
        }
        this.getTestimonial();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleSetInHome = (id) => {
    const formData = {
      is_featured: 1,
    };

    axios
      .put(`/users/testimonials/updatetestimonialStatus/${id}`, formData, {
        headers: { "X-Auth": this.props.token },
      })

      .then((res) => {
        if (res.data.success) {
          toast(res.data.msg, { type: "success" });
          this.getTestimonial();
        } else {
          toast(res.data.msg, { type: "error" });
        }
      });
  };

  handleRemoveInHome = (id) => {
    const formData = {
      is_featured: 0,
    };
    axios
      .put(`/users/testimonials/updatetestimonialStatus/${id}`, formData, {
        headers: { "X-Auth": this.props.token },
      })
      .then((res) => {
        if (res.data.success) {
          toast(res.data.msg, { type: "success" });
          this.getTestimonial();
        } else {
          toast(res.data.msg, { type: "error" });
        }
      });
  };

  handleChecked = (item) => {
    const newskilllist = [];
    newskilllist.push(item);
    this.setState({ newskilllables: newskilllist });
  };

  render() {
    return (
      <React.Fragment>
        <section className="feedbackWrap" style={{"display": "none"}}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="heading text-center">
                  <h3>Coaching style Approach</h3>
                  <span className="R_divider"></span>
                  <p>
                    A coaches lead or preferred coaching style is represented in
                    the chart below. This visual representation of a coaches
                    dominat four communication strengths is designed to help you
                    select a coach whos approach matches your preferences. A
                    coach may have elements of all communication styles within
                    them and use all in their coaching. However each coach will
                    lead most dominantly in one or two of the four approaches;
                    known as their lead, or preferred, style. Knowing a coaches
                    lead style and their main strengths within that approach can
                    help match your preferences on how you would like to be
                    coached. It can support a more effective and harmonious
                    coaching relationship - especially when working virtually.
                    It's important to note the although this represents the
                    coaches preferred approach most coaches will adapt their
                    coaching to suit individual requirements - please talk to
                    your selected coach about how you would like to be coached.
                    (Please note that coaches select their own preferences and
                    may not be representative of their coaching style)
                  </p>
                </div>
              </div>

              <div className="col-md-7">
                {this.props.check_account_type &&
                this.props.check_account_type === "coach" ? (
                  <div className="pf__right">
                    <div className="row no-gutters">
                      <div className="ml-auto">
                        <SelectSkills
                          chartValue={this.props.chartValue}
                          handleSubmitSkill={this.props.handleSubmitSkill}
                          handleChangeMultiSkills={
                            this.props.handleChangeMultiSkills
                          }
                          dountChartUpdated={this.props.dountChartUpdated}
                          labels_data={this.props.labels_data}
                          data_data={this.props.data_data}
                          color_data={this.props.color_data}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div id="canvas-holder" style={{ width: "100%" }}>
                  
                { this.props.chartValue && this.props.chartValue.length > 0  ? (
                  <Donut
                    chartValue={this.props.chartValue}
                    labels_data={this.props.labels_data}
                    data_data={this.props.data_data}
                    color_data={this.props.color_data}
                  />

                ) :

                (

                  <small>
                    <p className="text-center" style={{ color: "black" }}> 
                    {this.props.check_account_type &&
                      this.props.check_account_type === "coach" ? "Add Coaching Style" : ""}
                    </p>
                  </small>
                ) 
                }
                  
                </div>
              </div>
            </div>
          </div>
        </section>

        <ClientFeedback coachUid={this.props.coach_uid}/>
      </React.Fragment>
    );
  }
}
export default Testimonial;
