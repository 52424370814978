import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import axios from '../../../config/axios'
import { message } from 'antd'
import PhoneInput from 'react-phone-input-2'
import {showPhonecountry} from "../../../config/custom";

export default function AddUser(props) {
    const [firstname, setfirstname] = useState("")
    const [lastname, setlastname] = useState("")
    const [email, setemail] = useState("")
    const [contact_no, setcontact_no] = useState("")
    const [user_id, setuser_id] = useState("")
    const [Error, setError] = useState({ firstname: "", lastname: "", contact_no: "" })


    const HandleSubmit = (e) => {
        e.preventDefault()
        if (firstname.match(/\d+/)) {
            setError({ firstname: "Please Provide a valid name" })
        } else if (lastname.match(/\d+/)) {
            setError({ lastname: "Please Provide a valid name" })
        } else if (((contact_no.length > 11) || (contact_no.length < 7)) && contact_no.match(/[^A-Za-z]/g, '')) {
            setError({ contact_no: "Please Provide a vaild number" })
        } else {
            axios.post(`/api/corporate_add_user`, { firstname, lastname, email, contact_no, user_id, corporate_user_id: JSON.parse(localStorage.getItem("corporate_userdata")).uid }, { headers: { "X-Auth": localStorage.getItem("corporate_token"), }, }).then((responce) => {
                message.success(responce.data.msg)
                props.getAllGroup()
                props.handleClose()
            }).catch((error) => {
                message.error(error.response.msg || error.response.data.msg)
            })
        }

    }

    const reset = () => {
        setfirstname("")
        setlastname("")
        setemail("")
        setcontact_no("")
        setuser_id("")
    }

    return (
        <div>
            <Modal show={props.addUsershow} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Individual User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => HandleSubmit(e)}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>First Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" placeholder="Enter First Name" required name="firstname" value={firstname} onChange={(e) => { setError({}); setfirstname(e.target.value) }} />
                            {(Error.firstname !== "") && (<p style={{ "color": "red" }}>{Error.firstname}</p>)}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Last Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" placeholder="Enter Last Name" required name="lastname" value={lastname} onChange={(e) => { setError({}); setlastname(e.target.value) }} />
                            {(Error.lastname !== "") && (<p style={{ "color": "red" }}>{Error.lastname}</p>)}

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Contact No <span className="text-danger">*</span></Form.Label>
                            <div className='input-group-prepend'>
                                {/* <Form.Control as="select" style={{ "width": "25%" }}>
                                    <option>+1</option>
                                    <option>+91</option>
                                </Form.Control> */}
                                {/* <Form.Control type="text" placeholder="Enter Contact No" required name="contact_no" value={contact_no} onChange={(e) => { setError({}); setcontact_no(e.target.value) }} /> */}
                                <PhoneInput 
                                    country={"us"}
                                    onlyCountries = {showPhonecountry}
                                    name='contact_no' required={true}  
                                    countryCodeEditable={false} 
                                    inputClassName="form-control phone-reg" 
                                    placeholder="Phone Number" 
                                    onChange={(e) => { setError({}); setcontact_no(e)}}
                                    validaterule={['required', 'phone']} 
                                    validatemsg={['Phone number is required', 'Please enter a valid phone number']} />

                            </div>
                            {(Error.contact_no !== "") && (<p style={{ "color": "red" }}>{Error.contact_no}</p>)}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="email" placeholder="Enter Email" required name="email" value={email} onChange={(e) => setemail(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Employee ID </Form.Label>
                            <Form.Control type="text" placeholder="Enter Employee id"  name="user_id" value={user_id} onChange={(e) => setuser_id(e.target.value)} />
                        </Form.Group>
                        <Button variant="primary" id='userInsertSubmit' type="submit" hidden />
                        <Button variant="primary" id='userInsertReset' type="reset" onClick={() => reset()} hidden />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className='btn tabformbutton' onClick={() => document.getElementById('userInsertReset').click()}>
                        Reset
                    </Button>
                    <Button variant="primary" className="rounded-lg defaultBtn" onClick={() => document.getElementById('userInsertSubmit').click()}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
