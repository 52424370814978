import React, { Component,useState } from 'react';
import StripeCheckout from "react-stripe-checkout";
import { toast } from "react-toastify";
// import moment from "moment";
import moment from "moment-timezone";

import axios from '../../../config/axios';
// import OwlCarousel from "react-owl-carousel";
import "../../../assets/css/style.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import Calendar from 'react-calendar';
import CoachProfileWrap from './CoachProfileWrap';
import { Header, HeaderLogout, HeaderTop } from "../../../components/headers";
import { FooterLink } from "../../../components/footers";
import CommunicationUsers from "../../../layouts/Communication/CommunicationUsers";
import { avatar } from "../../../assets/img";
import { Link } from "react-router-dom";
import message,{getHelpText} from '../../../utils/mesaages';
import {getClientTimezone,timezoneUtc,UTCtoTimezone,convertToTimezone} from '../../../utils/timeZoneConvert';





const _ = require("lodash");  


class BookSession extends Component {

    constructor(props){
        super(props)
    this.token = localStorage.getItem("authToken");
        this.state={
          baseCurrency: "USD",
          convertToCurrency: "USD",
          findcurrency: 1,
          avatar_path: avatar,
          selectedSlot:'',
          sessionDate:"2021-01-28",
          slots:[],
          currMonth: moment().format('MMMM'),
          currDate: moment().format('YYYY-MM-DD'),
          duration:0,
          date: new Date(),
          userValidate:false,
          coachValidate:false,
          packageValidate:false,
          packageDetails:[],
          coachDetails:[],
          userDetails:[],
          ConsumerD:{},
          packageID:'',
          coachID:'',
          userID:'',
          coachTimezone:'',
          timezone:localStorage.getItem('timezone'),
          disableDate:[]


        }
      }
      changeConvertToCurrency = (e) => {
        this.setState({
          convertToCurrency: e.target.value,
        });
        const ps = this.state.rates[this.state.convertToCurrency];
        console.log("currency----", this.state.rates)
        console.log("currency----", this.state.rates[this.state.convertToCurrency])
        console.log("psssss---------", ps)
        this.setState({ findcurrency: ps });
      };
      

     async componentWillMount()
      {
        
        if(this.props.match.params.hasOwnProperty('coach_uid') &&  this.props.match.params.hasOwnProperty('package_uid'))
        {
          let userdata = localStorage.getItem('userdata')
          userdata = JSON.parse(userdata)
           console.log('userdata',userdata)
          
          let coach_uid = this.props.match.params.coach_uid
          let package_uid = this.props.match.params.package_uid
          let user_uid = userdata.uid
          // if(user_uid != undefined)
          // {

          if(user_uid != undefined)
          {
            
           let ConsumerD = {name:userdata.name,email:userdata.email,uid:user_uid}
            this.setState({ConsumerD,userDetails:userdata})
            let timezone = await getClientTimezone()

            this.setState({timezone})
          }
            this.setState({ packageID:package_uid,
              coachID:coach_uid,
              userID:user_uid},()=>{

                this.checkUserValid()
                this.packageDetailsFetch()
                this.coachDetailsFetch()
                this.getAvailWeekdays()
                this.checkIntroBooking()
              })

          // }
          
          
          
          


        }
      }

      getAvailWeekdays=()=>{
        let {coachValidate,ConsumerD, packageValidate,packageDetails,coachDetails,userDetails, userID, coachID,packageID} = this.state

        let id=coachID
        let from={coach_id:id,user_id:userID}
        axios.post(`/coach/setavailability/getavail`,from).then((res)=>{
          if(res.data.success)
          {
            
              this.setState({avilDays:res.data.value,disableDate:res.data.disableDate},()=>{this.autoFetchToday()})
          }

        }).catch((err)=>{

          console.log('getavail',err)

        })

  }


      coachDetailsFetch(){
        console.log('coach details function')
       
          const id = this.state.coachID;
      
          let param = {
            url: `/users/coachprofile/${id}`,
            method: "GET",
            headers: {
              "X-Auth": this.token
            }
          }
          axios(param)
            .then(async (res) => {

              if(res.data.success)
              {
                if (res.data.value.hasOwnProperty('coach_uid')) {
                  this.setState({coachDetails:res.data.value},()=>{
                    this.setState({coachTimezone:res.data.value.timezone})
                    console.log("coach data", this.state.coachDetails)
                  })
                  this.setState({coachValidate:true})
                }
                else{
                  console.log('invalid Coach')
                 
                }

              }
              else{
                console.log(res.data.err)
              }
              
              
            })
            .catch((error) => {
              console.log("fetch error");
              console.log(error);
             
            });
        
      

      }

      packageDetailsFetch(){
        console.log('Package details function')
       
          const id = this.state.packageID;
          axios
            .get(`/users/packages/singlePackagedata/${id}`, {
              headers: {
                "X-Auth": this.token,
              },
            })
            .then((res) => {
              if (res.data.success) {
                let pack = res.data.value;
               
                if (pack.hasOwnProperty('package_type')) {
                
                  
                 this.setState({packageDetails:pack},()=>{
                  this.setState({packageValidate:true})
                  this.setState({duration:pack.introDuration})

                   console.log('packageDetails',this.state.packageDetails)
                 })
                }
                else{
                  console.log('invalid package')
                    }
              }
              else{
                console.log(res.data.err)
                  }
            })
            .catch((error) => {
              console.error(error);
            });
        
      }
      
      
      componentDidMount(){
        let {coachValidate, packageValidate,packageDetails} = this.state
        this.checkIntroBooking()

        if(coachValidate && packageValidate)
        {
         
        //  console.log(packageDetails.introDuration)

        }

        
       

        
       
        // console.log('prpppp',this.props)
        // if(this.props.location.state==undefined)
        // {
        //   this.props.history.push('/')
        // }
        // else
        // {
        //   this.setState({sessionType:this.props.location.state.sessionType})
        // }
        
        
       
        
      
       }

       checkIntroBooking()
       {
        let {userID, coachID,packageID,packageDetails} = this.state

        
         
        let id=coachID
        let userid=userID
        let from={coachUID:id,userUID:userid}
       
        axios.post(`/consumer/checkIntroBooking`,from)
              .then(res =>{
                if(res.data.success)
                {
                  if(res.data.value>0)
                  {
                    // this.setState({sessionType:'main'})
                    this.props.history.push({
                      pathname: `/session-booking/${coachID}/${packageID}`,
                    })
                   
                  }
                }
               
              })
              .catch(err =>{
              

              })
       }

       checkUserValid()
       {
        let {coachValidate, packageValidate,packageDetails, userID} = this.state

         console.log('validateUser')
        let param = {
          url: "/consumer/userprofile1/"+userID,
          method: "GET",
          headers: {
            "X-Auth": this.token,
          },
        };
        axios(param)
          .then(async (res) => {
            if (res.data.success) {

              console.log('addresss',res.data.value)
              
              const rawdata = res.data.value;
              if(rawdata.address != null)
              {
                this.setState({userValidate:true})
              }
              const rawaddress = rawdata.address ? JSON.parse(rawdata.address) : "";
            }
            
          }).catch(()=>{

          })

       }
    
    
       getAvailability(sessionNo=''){
        const hidemsg =message.loading('Please wait, we are finding availability',0)
        let {coachValidate, packageValidate,packageDetails, userID, coachID,packageID} = this.state
        // console.log('ssssssss',this.state)

        // toast("Please wait , we are finding Availability", { type: "success" });
        let id=coachID
        let from={coach_id:id,user_id:userID,pack_id:packageID,date:this.state.currDate,sessionType:'intro',duration:this.state.duration}
        console.log(from)
        axios.post(`/coach/setavailability/filtered`,from)
              .then(res =>{
                hidemsg()
                console.log("availabity---res--",res)
                if(res.data.success){
                 
                 this.setState({slots:res.data.value})

                  
                 

                }
                if(res.data.value.length<=0)
                {
                  message.loading(`No slots avalable on ${this.state.currDate}`)
                
                }
                else{
                  // message.loading(`Slots Found ${res.data.value.length}`)
                  
                }
                // console.log("resss---vail",res)
              })
              .catch(err =>{
                hidemsg()
                console.log(err)
                message.error("Failed! Something Went Wrong")

               

              })
      }

    autoFetchToday=()=>{
      
        if(this.state.avilDays!=undefined)
        {
        if(this.state.avilDays.includes(moment(this.state.currDate).day()))
        {
          
            setTimeout(() => {
              this.getAvailability()
            }, 1000);
              

          
          
          
        }
        }

      
      
    }
    
   

    dateChange = (date)=>{

      if(moment(date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD'))
      {
        message.error("Please Choose a Future date");
      }
      else{

        this.setState({date,currDate:moment(date).format('YYYY-MM-DD')},
        ()=>{
          console.log('date',date)
          console.log('formatdate',moment(date).format('YYYY-MM-DD'))
          this.getAvailability()
        })

      }

      
      

     
      
     

    }
    chooseSlot = (e) =>{
      console.log(e.target.value.from)
      let v = e.target.value
      let date = e.target.getAttribute("ddate")
      let from = v.split(' ')[0]
      let to = v.split(' ')[1]
      console.log(v)
      console.log(from)
      console.log(to)
      if(from != undefined && to != undefined)
      {
        let selectedSlot = {date,from:from,to:to}
        this.setState({selectedSlot},
          ()=>{
            console.log('selectedSlot',selectedSlot)
          })
      }

    }
    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return [year, month, day].join('-');
  }

    tileDisabled = ({activeStartDate, date, view }) => {
      if(view=='month')
      {
        let day = date.getDay(); 
        let arrayweekavail = this.state.avilDays
        let arrayDateUnavailble = this.state.disableDate
        // console.log(arrayDateUnavailble)
        // console.log('date',this.formatDate(date),_.find(arrayDateUnavailble, { 'date': this.formatDate(date)}) != undefined)
        // ...convert number to weekname or directly through moment
        return  !arrayweekavail.includes(day) || _.find(arrayDateUnavailble, { 'date': this.formatDate(date)}) != undefined
   

      }
      else
      {
        return false
      }
      
   }
   tileClassName = ({activeStartDate, date, view }) => {
    let today = `${date.getMonth()}${date.getDate()}`
    let day = date.getDay(); 
    let arrayweekavail = this.state.avilDays
    
    let today1 = `${new Date().getMonth()}${new Date().getDate()}`
   

    // //console.log('ddddatattata',today)
    // console.log('xxxxxxxxxxxx',today1)

    if(today == today1)
    {

        if(arrayweekavail.includes(day))
        {
          return ''
        }
        else
        {
          return 'tilesBlock'
        }

      

     
    
     
    }
    else
    {
      

      return ''
    }
   
 
 }

  

    render() {

    
      let {coachValidate,ConsumerD, packageValidate,packageDetails,coachDetails,userDetails, userID, coachID,packageID} = this.state
        
     
     
                let session_details = ''
                if(packageValidate && coachValidate )
                {

                  
                  

                  let i=1
                  var sessions =[]
                  while(i<=packageDetails.total_sessions)
                  {
                    sessions.push(i)
                    i++
                  }
                  // console.log(JSON.parse(packageDetails.session_details))
                   session_details= JSON.parse(packageDetails.session_details)

                  


                  


                }
      
                     
                // console.log('gggggggg',session_details)
      
                      
                        return (
                          <React.Fragment>
                              {/* { this.token  } */}
                        {/* <HeaderTop /> */}
      
                        {localStorage.getItem("authToken") == null ? (
                          <HeaderTop />
                        ) : (
                            <HeaderLogout
                              currencies={this.state.currencies}
                              convertToCurrency={this.state.convertToCurrency}
                              changeConvertToCurrency={this.changeConvertToCurrency}
                            />
                          )}
      
                        <Header />
                        <CommunicationUsers avatar_path={this.state.avatar_path}></CommunicationUsers>
                        <section className="coacActionhWrap pt-5" >
                          <div className="container " >
                          <div className="row justify-content-center">

                            
      
                            <div className="col-md-12">
                              <div className="heading text-center">
                              <h3>Select Date & Time For Your Intro  Call With Your Coach</h3>
                              <p><small>You are selecting a time and date for free call with your coach prior to starting your coaching session, program or package.</small></p>                     
                                <span className="R_divider"></span>
                              </div>
                            </div>
                            {(packageValidate && coachValidate)?(<>
      
                              {(this.state.userValidate)?(<>
                            
                            
                          
                            
                              <div className="col-lg-5">
                              <div className="bookingCalendar">
                                <div className="app__main">

                                  {this.state.avilDays!=undefined &&(
                                     <Calendar
                                     onChange={this.dateChange}
                                     value={this.state.date}
                                     className="calendar"
                                     minDate={new Date()}
                                     tileDisabled={this.tileDisabled}
                                     tileClassName={this.tileClassName}
                                     />



                                  )}
                                    
                                   
                                </div>
                              </div>
                            </div>
                          <div className="col-lg-9">
                              <div className="areas areasNew">
                                {this.state.slots.length>0? (
                                  this.state.slots.map((slot,index)=>{
      
                                    return(<>
                                        <div class={slot.booked !== undefined? slot.booked==true? "custom-control custom-checkbox checkbox-inline mx-1 disabled" : "custom-control custom-checkbox checkbox-inline mx-1" : "custom-control custom-checkbox checkbox-inline mx-1"} >
                                          <input type="radio" onChange={this.chooseSlot} ddate={slot.from.split(" ")[0]} value={slot.from.split(" ")[1]+' '+slot.to.split(" ")[1]} disabled={slot.booked !== undefined? slot.booked : false}  className="custom-control-input" id={index} name="Sundays"/>
                                          <label className="custom-control-label" for={index}>

                                          {UTCtoTimezone(this.state.timezone,timezoneUtc(this.state.coachTimezone,slot.from),'HH:mm')} -  
                                          {UTCtoTimezone(this.state.timezone,timezoneUtc(this.state.coachTimezone,slot.to),'HH:mm')}
                                
                                          </label>
                                        </div>
                                        </>
                                    )
                                  })
      
                                ):(<> <div className="unavailable_block text-center p-3 mt-4 w-50 ml-auto mr-auto">
                                
                                {/* Slots unavailable on {this.state.currDate} */}
                                Please select date to view all available time slots
                              </div></>)}
                              
                              </div>
                            </div>
                            
      
      
                        {(this.state.selectedSlot!='')? (
                          <div className="my-5 text-center col-md-12">
                                        <Link className="defaultBtn"   to={{
                                          pathname: "/intro-booking",
                                          state: { 
                                            selectedSlot:this.state.selectedSlot,
                                            packageName:packageDetails.title,
                                            packageUid:packageID,
                                            packPrice:packageDetails.price,
                                            total_sessions:packageDetails.total_sessions,


                                            baseCurrency:this.state.baseCurrency,
                                            findcurrency:this.state.findcurrency,
                                            convertToCurrency:this.state.convertToCurrency,

                                            coachData:coachDetails,
                                            coachImage:coachDetails.avatar_path,
                                            coachName:coachDetails.name,

                                            ConsumerD:ConsumerD
                                            
                                          }
                                        }}>
                                        Book
                                        </Link>
                                        </div>
                        
                        ):(<></>)}
      
                        </>):(<>
                          <div className="col-md-12">
                              <div className="areas ">
                                  <div className="unavailable_block text-center p-3 mt-4 w-50 ml-auto mr-auto">
                                  Complete your personal details&ensp;
                                  <Link 
                                      to={{
                                        pathname: `/profile-user-edit`,
                                      
                                      }} 
                                      className="mt-2">
                                      Edit Profile
                                      </Link>
                                  </div>
                              </div>
                          </div>
                        
                        </>)}

                        </>):(<>

                          <div className="col-md-12">
                              <div className="areas ">
                                  <div className="unavailable_block text-center p-3 mt-4 w-50 ml-auto mr-auto">
                                  Please wait
                                  </div>
                              </div>
                          </div>
                        
                        </>)}
      
      
      
      
                      
      
                      
                          
                      </div>
                            
                          </div>
      
                      
                        </section>
      
                            <FooterLink />
                      </React.Fragment>
                        );
                    
                    
                   


        

     


      
    }
}

export default BookSession;




















// if(this.props.location.state.hasOwnProperty('package')===true)
// {



//                 let i=1
//                 var sessions =[]
//                 while(i<=this.props.location.state.total_sessions)
//                 {
//                   sessions.push(i)
//                   i++
//                 }
//                 let session_details= JSON.parse(this.props.location.state.package.session_details)
              

              


              
//                 // console.log('gggggggg',session_details)

                
//                   return (
//                     <React.Fragment>
//                         {/* { this.token  } */}
//                   {/* <HeaderTop /> */}

//                   {localStorage.getItem("authToken") == null ? (
//                     <HeaderTop />
//                   ) : (
//                       <HeaderLogout
//                         currencies={this.state.currencies}
//                         convertToCurrency={this.state.convertToCurrency}
//                         changeConvertToCurrency={this.changeConvertToCurrency}
//                       />
//                     )}

//                   <Header />
//                   <CommunicationUsers avatar_path={this.state.avatar_path}></CommunicationUsers>
//                   <section className="coacActionhWrap pt-5" >
//                     <div className="container " >
//                     <div className="row justify-content-center">

//                       {(this.state.userValidate)?(<>

//                       <div className="col-md-12">
//                         <div className="heading text-center">
//                         <h3>Select Date & Time For Your {this.state.sessionType=='intro'? 'Intro' : ''}  Call With Your Coach</h3>                      
//                           <span className="R_divider"></span>
//                         </div>
//                       </div>
                      
                      
                    
                      
//                         <div className="col-lg-5">
//                         <div className="bookingCalendar">
//                           <div className="app__main">
//                               <Calendar
//                               onChange={this.dateChange}
//                               value={this.state.date}
//                               className="calendar"
//                               />
//                           </div>
//                         </div>
//                       </div>
//                     <div className="col-lg-9">
//                         <div className="areas areasNew">
//                           {this.state.slots.length>0? (
//                             this.state.slots.map((slot,index)=>{

//                               return(<>
//                                   <div class={slot.booked !== undefined? slot.booked==true? "custom-control custom-checkbox checkbox-inline mx-1 disabled" : "custom-control custom-checkbox checkbox-inline mx-1" : "custom-control custom-checkbox checkbox-inline mx-1"} >
//                                     <input type="radio" onChange={this.chooseSlot} value={slot.from.split(" ")[1]+' '+slot.to.split(" ")[1]} disabled={slot.booked !== undefined? slot.booked : false}  className="custom-control-input" id={index} name="Sundays"/>
//                                     <label className="custom-control-label" for={index}>{slot.from.split(" ")[1]} - {slot.to.split(" ")[1]}</label>
//                                   </div>
//                                   </>
//                               )
//                             })

//                           ):(<> <div className="unavailable_block text-center p-3 mt-4 w-50 ml-auto mr-auto">
                          
//                           Unavalable
//                         </div></>)}
                        
//                         </div>
//                       </div>
                      


//                   {(this.state.selectedSlot!='' && this.state.sessionType=='intro')? (
//                     <div className="my-5 text-center col-md-12">
//                                   <Link className="defaultBtn"   to={{
//                                     pathname: "/intro-booking",
//                                     state: { 
//                                       selectedSlot:this.state.selectedSlot,
//                                       packageName:this.props.location.state.packageName,
//                                       packageUid:this.props.location.state.packUid,
//                                       packPrice:this.props.location.state.packPrice,
//                                       baseCurrency:this.props.location.state.baseCurrency,
//                                       findcurrency:this.props.location.state.findcurrency,
//                                       convertToCurrency:this.props.location.state.convertToCurrency,
//                                       coachData:this.props.location.state.coachData,
//                                       coachImage:this.props.location.state.coachImage,
//                                       coachName:this.props.location.state.coachName,
//                                       total_sessions:this.props.location.state.total_sessions,
//                                       ConsumerD:this.props.location.state.ConsumerD
//                                       // packPrice:this.props.location.state.packPrice,
//                                       // coachName:this.props.location.state.coachName,
//                                       // coachUid:this.props.location.state.coachUid,
//                                       // Consumer_uid:this.props.location.state.Consumer_uid,
//                                     }
//                                   }}>
//                                   Book a Session
//                                   </Link>
//                                   </div>
                  
//                   ):(<></>)}

//                   </>):(<>
//                     <div className="col-md-12">
            
//                         <div className="areas ">
                        
//                             <div className="unavailable_block text-center p-3 mt-4 w-50 ml-auto mr-auto">
                            
//                             Complete Your Profile First

                          
//                           </div>
                    
//                     </div>
//                   </div>
                  
//                   </>)}




                

                
                    
//                 </div>
                      
//                     </div>

                
//                   </section>

//                       <FooterLink />
//                 </React.Fragment>
//                   );
              
//               }
//               else
//               {
//                 return(<p>Please Wait</p>)

//               }