import React from 'react'
import { Link } from "react-router-dom";
class JoinSession extends React.Component{
  constructor() {
    super();
    this.state ={
      roomid: "",
      videotoken:"",
    }
  }
  componentDidMount() {
    this.getRoomDetails()
  }

  async getRoomDetails() {
    let userData = JSON.parse(localStorage.getItem('userdata'))
    let roomid = userData.roomid
    let videoToken = userData.video_token
    //let authToken = localStorage.getItem('authToken')
    await this.setState({roomid: roomid, videotoken: videoToken})
    console.log("VIDEO TOKEN AND VIDEO ID", this.state.roomid, this.state.videotoken)
  }



render(){
return(
<div>
<section className="coacActionhWrap">
  <div className="container" >
    <div className="row justify-content-center">
      <div className="col-md-8 col-lg-9">

        <div className="heading text-center">
          <h3>Coach Meeting Room</h3>
          <div>
          <p><strong>All sessions take place remotely on the LifeGuru platform using our in-house video conferencing system</strong></p>
          <p>Each coach is provided their own personal “meeting room” where all sessions take place remotely. A unique access code is generated for each session and sent to your client to join you in the room.
         (Coaches do not require an access code as the room is uniquely yours). Your clients can only enter the room once you admit them. Access codes are provided to your clients in booking confirmation emails and also found in their calendar with the booking details.</p>
          </div>
          <span className="R_divider"></span>
        </div>


        <div style={{textAlign:"center"}}>
        {/* <a href="https://video.lifeguru.io/b/adm-tqi-0uz-rsf" target="blank" className="defaultBtn mt-2"> */}
        {this.state.roomid !==null && this.state.videotoken !=="" ? <a href={`https://video.lifeguru.me/b/api/v1/join_as_moderator?room_uid=${this.state.roomid}&token=${this.state.videotoken}`}
        
        target="blank" className="defaultBtn mt-2">

              GO TO YOUR MEETING ROOM
                {/* Click here to join session */}
              </a> : ""} 
        </div>
      </div>

    </div>
  </div>
</section>
            </div>
        )
    }
}

export default JoinSession