import React from 'react'
import {Link} from 'react-router-dom'
import { dArrowblack } from '../../assets/images'
import timezones from '../commonprofile/timezones'
const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

class TimeZone extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      timezone: [],
    }
   
  }

  handleChange = async (e) => {
    await this.setState({
      timezone: [...this.state.timezone, e.target.value]
  });
  this.props.data.timezone = this.state.timezone

  }
  // handleChange = async (e) => {
  //   const index =
  //     this.state.timezone.length > 0 ? this.state.timezone.indexOf(e.target.id) : -1;
  //   if (e.currentTarget.checked) {
  //     if (index === -1) {
  //      await this.setState({ timezone: [...this.state.timezone, e.target.id] });
  //     }
  //   }
  //   if (!e.currentTarget.checked) {
  //     await this.setState({
  //       timezone: [...this.state.timezone.filter((element) => element !== e.target.id)],
  //     });
  //   }
  // };

    render(){
        return(
            <div>
                <fieldset>
        <div className="heading text-center">
          <h3 className="fs-subtitle">Location preference</h3>
          <span className="R_divider"></span>
        </div>
        {/* <div className="form-group">
            <div className="selectBox">
               <select className="form-control" id="timezone-offset" value={this.props.data.timezone} onChange={this.props.handleChange} name='timezone'>
                <option value="" selected="selected">Any Timezone:</option>
                {timezones.map(timezone =>{
                    return(
                    <option value={timezone.data}>{timezone.value}</option>
                    )
                })}
                </select>
               <span>
                  <img src={dArrowblack} alt="LG-Img"/>
               </span>
            </div>
          </div> */}
          <div className="areas coaching_area d-flex mb-4 flex-wrap">
          <div className="custom-control custom-checkbox checkbox-inline mx-2">
            <input type="checkbox" className="custom-control-input" id="customCheck4" name="timezone"
            //checked={this.props.data.timezone.includes(tz)}
            onChange={this.handleChange} value={tz}/>
            <label className="custom-control-label" for="customCheck4">My Timezone</label>
          </div>
          <div className="custom-control custom-checkbox checkbox-inline mx-2">
            <input type="checkbox" className="custom-control-input" id="customCheck5" name="timezone"
            //checked={this.props.data.timezone.includes("")}
            onChange={this.handleChange} value=""/>
            <label className="custom-control-label" for="customCheck5">Worldwide</label>
          </div>
        </div>

        <Link
        to="#"
          onClick={() =>
          this.props.handleField("Skills")
          }
         className="defaultBtn">PREVIOUS
        </Link> {" "}
       
        <Link
        to="#"
          onClick={() =>
          this.props.handleField("Language")
          //console.log("select box value", this.props.data.timezone)
          }
         className="defaultBtn">Next
        </Link>

       
      </fieldset>

            </div>
        )
    }
}
export default TimeZone