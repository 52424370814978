import React from 'react'
import { dArrowblack,downArrow,filter, quality, teamSvg} from '../../assets/images';
import { CoachProfileList } from './index'
import {timezones} from './index'

import { Container, Row, Col } from 'react-bootstrap'
import ReactStars from 'react-stars';

class ApplyFilter extends React.Component{
     constructor(props){
       super(props)
       this.state={
         userData:"",
         display:"none",
         rating:0
       }
     }


     componentDidMount(){
       let User=JSON.parse(localStorage.getItem('userdata'))
       this.setState({userData:User})
     }

     handleDisplay=()=>{
      //  this.setState({display:""})
      if (this.state.display === "none") {
        this.setState({display:""})
      } else if(this.state.display === ""){
        this.setState({display:"none"})
        
      }
     }

     onStarClick = (e,nextValue, prevValue)  =>{
      console.log('e' , e)
      this.setState({rating: e});
      this.props.setrating(e)
  }
  

    render(){
        return(
          
        
        <section className="SearchListWrap newSearchListWrap">
          <div className="container">
           <div className="searchFilter mb-4">
            <Row className="align-items-center">
              <Col md={9} xs={9} sm={9}>              
                  <h3><img width="18px" src={filter} alt="LG-Img"/> Advanced  Filters</h3>
              </Col>
              <Col md={3} xs={3} sm={3} className="text-right">
                <a className="viewAFilter"  onClick={this.handleDisplay} ><img width="20px" className=""src={downArrow} alt="LG-Img"/></a>
              </Col>
              
            </Row>
            <div className="row fResult"   style={{display:this.state.display,overflowx: "visible", overflowy: "visible"}}>
              <div className="col-md-12">
                <hr/>
              </div>
              <div className="col-md-4">
                  <div className="form-group">  
                    <label>Languages Talked :</label>
                    <div className="selectBox">
                      <select className="form-control"
                      value={this.props.data.language} onChange={this.props.handleChange} name='language'>
                  <option value="" selected="selected">Select Language</option>
                 <option value='ENGLISH'>ENGLISH</option>
                 <option value='SPANISH'>SPANISH</option>
                 <option value='FRENCH'>FRENCH</option>
                 <option value='PORTUGUESE'>PORTUGUESE</option>
                 <option value='HINDI'>HINDI</option>
                 <option value='RUSSIAN'>RUSSIAN</option>
                 <option value='GERMAN'>GERMAN</option>
                 <option value='CHINESE'>CHINESE</option>
                 <option value='JAPANESE'>JAPANESE</option>
                      </select>
                      <span>
                          <img src={dArrowblack} alt="LG-Img"/>
                      </span>
                    </div>
                  </div>
              </div>
              <div className="col-md-4">
                  <div className="form-group">
                    <label>Timezone:</label>
                    <div className="selectBox">
               <select className="form-control" id="timezone-offset" value={this.props.data.timezone} onChange={this.props.handleChange} name='timezone'>
                 <option value="" selected="selected">Select Timezone:</option>
                 {timezones.map(timezone =>{
                     return(
                     <option value={timezone.data}>{timezone.value}</option>
                     )
                 })}
              </select>
               <span>
                  <img src={dArrowblack} alt="LG-Img"/>
               </span>
            </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Coaching Experience (hrs):</label>
                    <div className="selectBox">
                    <select  className="form-control" value={this.props.data.CoachingExpHrs} onChange={this.props.handleChange} name='CoachingExpHrs'>
                    <option value="" selected="selected">Select the hours of experience </option>
                        <option value="0-100hrs">0-100hrs</option>
                        <option value="100hrs+">100hrs+</option>
                        <option value="500hrs+">500hrs+</option>
                        <option value="2,500hrs+">2,500hrs+</option>
                    </select>
                      <span>
                          <img src={dArrowblack} alt="LG-Img"/>
                       </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                    <label>Hourly Rate: </label>
        
                    <div className="selectBox">
                      <select className="form-control" onChange={this.props.handleChange}  name="Hourlyrate">
                        <option value="" selected="selected">Any</option>
                        <option value="0-50"> Up To 50</option>
                        <option value="51-100"> 51 to 100</option>
                        <option value="101-150"> 101 to 150</option>
                        <option value="151-200"> 151 to 200</option>
                        <option value="200-300"> 200 to 300</option>
                        <option value="300+"> 300+</option>
                      </select>
                      <span>
                          <img src={dArrowblack} alt="LG-Img"/>
                       </span>
                    </div>
        
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Rating: </label>
                    <div className="ratingWrap rating-stars text-center">
                     
                    <ReactStars
                        value={this.state.rating}
                        onChange={this.onStarClick}
                        count={3}
                        size={35}
                        half={false}
                        color1={"#cccccc"}
                        className=""
                        />                    
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                   <label>&nbsp;</label> 
                    <div className="custom-control custom-checkbox">
                    <input type="checkbox"  className="custom-control-input" id="customCheck4" name="MemberOfPropessionalBody" onChange={this.props.handleMembershipCheck} value="1"/>
                    <label className="custom-control-label" for="customCheck4">
                     Member of professional body <img width="25px" alt="LG-Img" src={quality}/>
                    </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group text-right mt-0">
                    <button className="defaultBtn" data-dismiss="modal" type="submit" onClick={this.props.handleApplyFilter} >Apply Filters 
                    </button>
                    </div>
                </div>
        
                </div>
              </div>
        
              <div>
                <div className="searchHead">
                  <div className="searchHeading">
                    <h3><img width="22px" src={teamSvg} alt="LG-Img"/> Search Results</h3>
                  </div>
                </div>
             
          <div>

        {localStorage.getItem("authToken") == null ? (
          <CoachProfileList
          ListCoaches={this.props.data.listCoaches}
          />
        ) : (
          <CoachProfileList
          ListCoaches={this.props.data.listCoaches}
          Consumer_uid={this.state.userData.uid}
          />
        )}
          
      
      </div>
                
                
              </div>
            </div>
         
        </section>

        )
    }
}

export default ApplyFilter