import React from 'react'
import { dArrowblack, teamSvg, sortSvg } from '../../../assets/images'

import axios from '../../../config/axios'
import Loader from 'react-loader-spinner'
class PaymentReceived extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      paymentDetails: [],
      loading: true
    }
  }
  componentDidMount() {

    

    axios.get("/payment/coachPaymenthistory", {
      headers: { "x-auth": this.props.token }
    })
      .then(res => {
        this.setState({ loading: false })
        // console.log("transaction---res",res)
        if (res.data.success) {
          const data = res.data.value
          this.setState({ paymentDetails: data })
        }
      })
      .catch(err => {
        this.setState({ loading: false })
        console.log(err)
      })
  }

  render() {
    console.log("this.state.-----", this.state.paymentDetails)



    return (
      <div className="col-md-12">

        {this.state.loading ?
          <Loader style={{ marginLeft: '30%' }}
            type="Oval"
            color="#00BFFF"
            height={100}
            width={100}
          //  timeout={3000} //3 secs

          />
          :
          <div>
            <div className="searchHead">
              <div className="searchHeading">
                <h3>
                  <img width="22px" src={teamSvg} alt="LG-Img" />Payments Received
              </h3>
              </div>
              <div className="searchShortBy">
                <img width="15px" src={sortSvg} alt="LG_Img" />
                <select>
                  <option>Sort by </option>
                  <option>A-Z</option>
                  <option>Date</option>
                </select>
                <span>
                  <img src={dArrowblack} alt="LG_Img" />
                </span>
              </div>
            </div>

            <div className="userBookingList">
              {this.state.paymentDetails.map(payment => {
let packagedetails =   payment.package_details === null ? null:JSON.parse(payment.package_details)
              return (
                  <div className="bookingCocachDetails">
                    <ul>
                      <li><b>Order Id</b> <span>#001</span></li>
              <li><b>Client Name</b> <span>{payment.name ? payment.name:''}</span></li>

              <li><b>Package name</b> <span>{packagedetails === null ? '':packagedetails.session_name}</span></li>


                  
                      <li><b>Amount Receieved</b> <span>${payment.user_commision_amount ?payment.user_commision_amount:'' }</span></li>

                      {payment.has_paid === "1" ? (
                        <li><b>Payment Status</b> <span className="badge text-white  badge-success">IN Wallet</span></li>
                      ) : (<li><b>Payment Status</b> <span className="badge text-white text-  badge-danger">Pending</span></li>)}

                    </ul>
                  </div>
                )
              })}


            </div>



          </div>

        }



      </div>


    )



  }
}

export default PaymentReceived