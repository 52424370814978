import React, { Component } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";
import "./profile.css";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-input-2'
import Select from "react-select";
import makeAnimated from "react-select/animated";

import country from "./country";

import { user, LGspinner ,writingSvg} from "../../../assets/images";
import axios from "../../../config/axios";
import { message } from "antd";

// import defaultImg from "../static/media/avatar.79bfd233.png";

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.token = localStorage.getItem("authToken");

    this.state = {
      loader: false,
      profile: {
        name: "",
        email: "",
        phone_no: "",
        gender: "",
        coaching: [],
        country: "",
        state: "",
        city: "",
        avataar: "",
      },
      imgLabel: "",
      profileImg: [],
      errors: [],
    };
  }

  handleChangephone = (e, data) => {
    let errors = [];
    if ((e.length - data.dialCode.length) >= 10) {
      console.log('true')
      errors['phone_no'] = ''
    } else {
      console.log('false')
      errors['phone_no'] = 'Enter valid phone number'
    }
    this.setState({ errors: errors });

    this.setState((prevState) => ({
      profile: {
        ...prevState.profile,
        ['phone_no']: e,
      },
    }));
  };

  getProfile = async () => {
    this.setState({ loader: true });
    axios
      .get("/webinar/get-profile-details", {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          this.setState({ loader: false });
          this.setState({ profile: res.data.data });
          this.setState({ profileImg: res.data.data.avataar });

          let userdata = localStorage.getItem("userdata");
          if (userdata) {
            userdata = JSON.parse(userdata);
            console.log("userdata", userdata);
            userdata = { ...userdata, avataar: res.data.data.avataar };
            localStorage.setItem("userdata", JSON.stringify(userdata));
          }
        } else {
          this.setState({ loader: false });
          message.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ loader: false });
        message.error("Something went wrong");
      });
  };

  handleCategories = (coaching) => {
    this.setState((prevState) => ({
      profile: {
        ...prevState.profile,
        coaching: coaching,
      },
    }));
  };

  handleInput = (e) => {
    e.persist();
    // console.log(this.state.profile)
    this.setState((prevState) => ({
      profile: {
        ...prevState.profile,
        [e.target.name]: e.target.value,
      },
    }));
  };

  updateDetails = () => {
    const formData = new FormData();
    // console.log(this.state.profile);

    let { phone_no, gender, coaching, country, state, city,name } =
      this.state.profile;
    console.log("coaching", coaching);
    formData.append("profileImg", this.state.profileImg);
    formData.append("phone_no", phone_no);
    formData.append("gender", gender);
    formData.append("coaching", JSON.stringify(coaching));
    formData.append("country", country);
    formData.append("state", state);
    formData.append("city", city);
    formData.append("name", name);

    axios
      .post("webinar/update-profile", formData, {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          message.success("Profile updated successfully");
          this.getProfile();
          this.setState({ errors: [] });
        } else if (res.data.errors && res.data.errors.length > 0) {
          let errors = [];
          console.log(res.data.errors);
          message.error("Failed to update profile");
          res.data.errors.map((item) => {
            errors[item.param] = item.msg;
          });
          this.setState({ errors: errors });
          console.log("errors", errors);
        } else {
          message.error("Failed to update profile");
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        console.log("err", err);
      });
  };

  componentDidMount() {
    this.getProfile();
  }

  
  render() {
    const { profile, errors } = this.state;
    const animatedComponents = makeAnimated();

    let options = [
      { value: "Career Coaching", label: "Career Coaching" },
      { value: "Executive Coaching", label: "Executive Coaching" },
      { value: "Business Coaching", label: "Business Coaching" },
      { value: "Life Coaching", label: "Life Coaching" },
      {
        value: "Transformational Coaching",
        label: "Transformational Coaching",
      },
      {
        value: "Health & Wellbeing Coaching",
        label: "Health & Wellbeing Coaching",
      },
    ];

    return (
      <LoadingOverlay
        active={this.state.loader}
        className="webiner_loader"
        spinner={<CustomImageLoader image={LGspinner} animationType={"none"} />}
      >
        <div className="content_wrap">
          <div className="profile_wrap">
            <Form>
              <Row className="justify-content-center">
                <Col className="coachProfileFormEdit cpe_sm" md={6}>
                  <div className="coachImg mb-4">
                    <img
                      id="item-img-output"
                      src={
                        this.state.profile?.avataar
                          ? this.state.profile.avataar
                          : user
                      }
                      className="img-fluid"
                    />
                    <div className="picEdit">
                      <label>
                      <img width="40px" alt="LG_Img" src={writingSvg} />
                        <input
                          className="item-img file center-block"
                          type="file"
                          label="Select Image file"
                          name="avatar_path"
                          accept=".jpg,.jpeg,.png"
                          onChange={(e) =>
                            this.setState({ profileImg: e.target.files[0] })
                          }
                          // onChange={(e)=>this.setState(e.target.files[0])}
                        />
                      </label>
                    </div>
                    <label className="d-flex justify-content-center">
                      {this.state.profileImg.name}
                    </label>
                  </div>
                  
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={profile.name}
                      placeholder="Enter name"
                      name="name"
                      onChange={(e) => this.handleInput(e)}
                    />
                    {/* <p style={{color:"red"}}>{errors["profile.name"]}</p> */}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      value={profile.email}
                      name="email"
                      placeholder="Enter email"
                      disabled
                    />
                    {/* <p style={{color:"red"}}>{errors["profile.email"]}</p> */}
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Phone number</Form.Label>
                        {/* <Form.Control
                          type="tel"
                          value={profile.phone_no}
                          placeholder="Enter phone"
                          name="phone_no"
                          onChange={(e) => this.handleInput(e)}
                        /> */}
                        <PhoneInput name="phone_no"  value={profile.phone_no} specialLabel={''} required={true} country={this.state.countryName} countryCodeEditable={false} inputClassName="form-control phone-reg" placeholder="Phone Number" onChange={this.handleChangephone} validaterule={['required', 'phone']} validatemsg={['Phone number is required', 'Please enter a valid phone number']} />

                        <p style={{ color: "red" }}>
                          {errors["phone_no"]}
                        </p>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Gender</Form.Label>
                        <select
                          className="form-control"
                          name="gender"
                          onChange={(e) => this.handleInput(e)}
                          value={profile.gender}
                        >
                          <option value="">Please select one</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="others">Other</option>
                        </select>
                        <p style={{ color: "red" }}>{errors["gender"]}</p>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group>
                    <Form.Label>
                      Select categories you would like coaching in
                    </Form.Label>
                    <Select
                      name="coaching"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      onChange={this.handleCategories}
                      value={profile.coaching}
                      options={options}
                      isMulti
                    />
                    <p style={{ color: "red" }}>{errors["coaching"]}</p>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Country</Form.Label>
                    <select
                      type="country"
                      value={profile.country}
                      name="country"
                      className="form-control"
                      onChange={(e) => this.handleInput(e)}
                    >
                      <option value="">Please select one</option>
                      {country.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                    <p style={{ color: "red" }}>{errors["country"]}</p>
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type="state"
                          value={profile.state}
                          placeholder="Enter state"
                          name="state"
                          onChange={(e) => this.handleInput(e)}
                        />
                        <p style={{ color: "red" }}>{errors["state"]}</p>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="city"
                          value={profile.city}
                          placeholder="Enter city"
                          name="city"
                          onChange={(e) => this.handleInput(e)}
                        />
                        <p style={{ color: "red" }}>{errors["city"]}</p>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* <Form.Group>
                    <Form.Label>Bio</Form.Label>
                    <Form.Control 
                      as="textarea" 
                      rows={3} 
                      name="bio"
                      value="profile.bio"
                      onChange={(e)=>this.handleInput(e)}/>
                  </Form.Group> */}
                  <Row>
                    <Col className="d-flex justify-content-center">
                      <Button
                        className="defaultBtn"
                        variant="default"
                        onClick={this.updateDetails}
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}
