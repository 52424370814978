import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { writingSvg, plusnewSvg, mclose  } from "../../../assets/images";
import { Tooltip } from 'antd';

class AddTag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: "",
      setShow: false,
      tags: "",
    };
  }

  handleShow = (e) => {
    this.setState({ setShow: !this.state.setShow });
  };

  handleChage = (e) => {
    e.persist();
    this.setState({ tags: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      tags: this.state.tags,
    };
    this.props.handleUpdateTags(formData);
    this.handleShow();
  };

  render() {
    return (
      <>
        <div className="cProfileEdit">
            {/* <span className="addTag" 
              data-toggle="tooltip"
              data-placement="top"
              title="Add/Update New Tag"
              onClick={this.handleShow} 
              ><img src={plusnewSvg} width="25px"/></span> */}
              <span className="addTag" style={{cursor: 'pointer'}}><Tooltip title={this.props.tagsHelpText}  onClick={this.handleShow} placement="right" ><img src={plusnewSvg} width="25px"/></Tooltip></span>
        </div>
        <h3 className="popup-with-zoom-anim edit_item">Skills & Experience</h3>
        <p>List coaching specialties & background</p>




        
        {/* <p
        data-toggle="tooltip"
        data-placement="top"
        title="Add/Update New Tag"
        onClick={this.handleShow}
        ><img width="40px" src={writingSvg} alt="LG-Img" /></p> */}

        <Modal
          show={this.state.setShow}
          onHide={this.handleShow}
          backdrop="static"
          keyboard={false}
        >
          <Form onSubmit={this.props.handleSubmit}>
            {/* <Modal.Header closeButton>
              <Modal.Title style={{color:"black"}}>Input your niche, specialties, experience & training etc<br/>
              <small>These tags will be used in search criteria</small></Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
              <button type="button" className="close" aria-label="Close" onClick={this.handleShow}>
                <img src={mclose} alt="" width="30px"/>
              </button>

              <div className="heading text-center">
                <h4 className="mt-5">Provide details on the types of coaching services you offer (Skills & Specialties)</h4>
                {/* <h5>These tags will be used in search criteria</h5> */}
                <span className="R_divider div_dark"></span>
              </div>  

              <Form.Group className="text-dark">
                <label for="form7">Tag Name</label>
                <br/>
                <small><i>*</i>Add multiple tags separated by comma (,)</small>
                <div className="md-form">
                  <textarea
                    // style={{color:"black"}}
                    id="form7"
                    className="md-textarea form-control text-dark"
                    rows="3"
                    placeholder="Enter tag name here...."
                    defaultValue={this.props.tags}
                    onChange={this.handleChage}
                    required={true}
                  ></textarea>
                </div>
              </Form.Group>
              <div className="text-center">
                <button className="defaultBtn" type="button" variant="success" onClick={this.handleSubmit}>Submit</button>
              </div>
            </Modal.Body>

            {/* <Modal.Footer>
              <Button variant="primary" onClick={this.handleShow}>
                Close
              </Button>
              <Button
                variant="success"
                type="submit"
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            </Modal.Footer> */}
          </Form>
        </Modal>
        <span className="R_divider mb-3"></span><br/><br/>
      </>
    );
  }
}
export default AddTag;
