import React, { Component } from "react";

import Loader from 'react-loader-spinner'


import {Container, Row, Col, Form} from "react-bootstrap"
import BounceLoader from "react-spinners/BounceLoader";
import LoadingOverlay from "react-loading-overlay";
import axios from "../config/axios";
import moment from "moment";
import { toast } from "react-toastify";
import message from '../utils/mesaages';
import {convertToTimezone,getCochTimezone} from '../utils/timeZoneConvert';
import { Header, HeaderLogout, HeaderTop } from "../components/headers";
import { FooterLink } from "../components/footers";
import CustomImageLoader from 'react-custom-image-loader.'
import { LGspinner} from '../assets/images'
import happy from '../assets/images/happy.png';
import sad from '../assets/images/sad.png';

import ReactStars from "react-rating-stars-component";




class Feedback extends Component {


  constructor(props) {
    super(props);
    this.state = {
        rating: 0,
        loader:true,
        coachDetails:undefined,
        packageDetails:undefined,
        userDetails:undefined,
        sessionDetails:undefined,
        userTimezone:undefined,
        coachTimezone:undefined,
        comment:'',
        commentErr:'',
        ratingPublicShow:'yes',
        questionAndRating:[],
        feedback_flag:0
    };
}

async componentDidMount()
{
    console.log("props value",this.props)
    let checkUserLogin = await this.checkUserLoginStatus()
    console.log('checkUserLogin', checkUserLogin)
    if(checkUserLogin === "true"){
      localStorage.removeItem("feedbackHref");
      this.fetchDate()
    }
    else if(checkUserLogin === "gotoLogin") {
      // localStorage.setItem("feedbackHref", `http://localhost:3000/feedback/${this.props.match.params.id}`);
      localStorage.setItem("feedbackHref", window.location.href);
      this.props.history.push({
        pathname: '/login'
      })
    }
    else if(checkUserLogin === "gotoSignup") {
      // localStorage.setItem("feedbackHref", `http://localhost:3000/feedback/${this.props.match.params.id}`);
      localStorage.setItem("feedbackHref", window.location.href);
      this.props.history.push({
        pathname: '/registration'
      })
    }
}

checkUserLoginStatus = () => {
  let videoId = this.props.match.params.id
  let authToken = localStorage.getItem('authToken')
  let dataReceived = axios.get(`/fetchUserId/${videoId}`).then((res) => {
    if(res.data.success) {
      const {fetchedUserid, isAccDeleted} = res.data
      // LOCALSTORAGE NULL
      if( authToken === null) {
        // ACCOUNT DELETED THEN GO TO SIGNUP
        if(fetchedUserid !== null && fetchedUserid === "deleted" && isAccDeleted) {
          return "gotoSignup"
        }
        // ELSE LOGIN
        else {
          return "gotoLogin"
        }
      }
      // LOCALSTORAGE NOT NULL AND FETCHED ID ALSO NOT NULL THEN COMPARE BOTH ID IF SAME THEN ACCESS FEEDBACK
      else if(authToken !== null && fetchedUserid !== null) {
        if(fetchedUserid === "deleted" && isAccDeleted) {
          return "gotoSignup"
        }
        else {
          let userData = JSON.parse(localStorage.getItem('userdata'))
          let uid = userData.uid
          if(uid !== fetchedUserid) {
            localStorage.clear();
            return "gotoLogin"
          }
          else {
            return "true"
          }
        }
      }
      // ELSE LOGIN
      else {
        localStorage.clear();
        return "gotoLogin"
      }
    }
    else {
      // REDIRECT TO LOGIN PAGE
      localStorage.clear();
      return "gotoLogin"
    }
  }).catch((err) => {
    return "gotoLogin"
  })
  return dataReceived
}

fetchDate(){
    this.setState({loader:true})
 
 
    axios.get(`/consumer/feedbackFetch/${this.props.match.params.id}`).then(async (res)=>{
      if(res.data.success)
      {
             this.setState({loader:false})

          let {coachDetails,packageDetails,userDetails,sessionDetails} = res.data.value


          let userTimezone = await getCochTimezone('user',userDetails.uid)
          let coachTimezone = await getCochTimezone('coach',coachDetails.uid)
       
          this.setState({coachDetails,packageDetails,userDetails,sessionDetails,userTimezone,coachTimezone})
          let questionAndRating = this.state.questionAndRating
          questionAndRating=[{
                              q:' How was the video and audio quality of your last session?',
                              r:''

                             },
                             {
                              q:`Based on your last session, were you satisfied with the coaching services ${coachDetails.name} provided?`,
                              r:''

                             },
                             {
                              q:`Based on your last session, do you feel that ${coachDetails.name} is helping you to achieve your goals?`,
                              r:''

                             },
                             {
                              q:`Based on your last session, how would you rate it overall?`,
                              r:''

                             },
                            ]
            this.setState({questionAndRating})


      }
      else
      {
        this.setState({feedback_flag:1})
        this.setState({loader:false})
        message.info("Feedback has already been provided - Thank you")

        setTimeout(() => {
            if(localStorage.getItem('authToken')==null)
            {
                this.props.history.push({
                    pathname: '/login',
                   
                  }) 

            }
            else
            {
                // this.props.history.push({
                //     pathname: '/profile-consumer',
                   
                //   }) 

            }
            
        }, 4000);
      
        
        
      }

    }).catch((err)=>{
        this.setState({loader:false})
    })

}

onStarClick(nextValue, prevValue, name) {
    this.setState({rating: nextValue});
    console.log(nextValue)
}
onchange(e)
{

    console.log(e.target.value.length)
    // if(parseInt(this.state.comment.split(" ").length)<10)
    // {
        
    // this.setState({commentErr:"Keyword must be 10 words"})

    // }
    // else{
    //     this.setState({commentErr:""})
    // }
    
    this.setState({comment:e.target.value})
}
ratingPrivate=(d)=>{
  

  this.setState({ratingPublicShow:d})

}


validateFeedback(feedback)  {

  let isValid = false;

  feedback.questionAndRating.forEach(item => {
    try {
      if(parseInt(item.r)) {
        isValid = true;
        return isValid;
      }
    } catch (error) {
      
    }
  })

  if(feedback.comment?.length > 1 || feedback.rating > 0) {
    isValid = true 
  }

  return isValid;

}


onSubmit(e){
  e.preventDefault()
 



  if(this.state.ratingPublicShow=='')
  {
    message.error("Choose your feedback public or private");
    return 0;
  }
    
 
    

    let fromData = {
      ratingPublicShow:this.state.ratingPublicShow,
      questionAndRating:this.state.questionAndRating,
      rating:this.state.rating,
      comment:this.state.comment,
      coach_uid:this.state.coachDetails.uid,
      user_uid:this.state.userDetails.uid,
      package_uid:this.state.packageDetails[0].uid,
      videoTokenId:this.props.match.params.id
    }

    const isVaildFeedback = this.validateFeedback(fromData)
    if(isVaildFeedback) {
     
      const hidemsg = message.loading(`Please wait`,0)
      axios.post(`/consumer/feedbackSend`,fromData).then((res)=>{
          hidemsg()
          if(res.data.success)
        {
            message.success("Thank you for your feedback");   

            if(localStorage.getItem('authToken')==null)
            {
                this.props.history.push({
                    pathname: '/login',
                    
                  }) 

            }
            else
            {
                this.props.history.push({
                    pathname: '/profile-consumer',
                    
                  }) 

            }

              
        }
        else
        {
            message.error(res.data.msg); 
        }

      }).catch((err)=>{
        hidemsg()
          this.setState({loader:false})
      })
    } else {
      message.error("Please complete your feedback")
    }

    
}

ratingChanged = (newRating,index) => {
  let questionAndRating = this.state.questionAndRating
  questionAndRating[index].r = newRating
  this.setState({questionAndRating},()=>{
    let one = 0
    let two = 0
    let three = 0
    let rating = ''
  
    this.state.questionAndRating.map((star,index)=>{
  
      let count = star.r
      if(count==1){
        one++
      }else if(count==2)
      {
        two++
      }
      else if(count==3)
      {
        three++
      }
  
      if(index==3)
      {
        rating =((3*three)+(2*two)+(1*one))/4 //base on question count
      }
      // console.log('star',one,two,three,index)
  
    })
    console.log('rating',rating,Math.round(rating))
    rating = Math.round(rating)
    this.setState({rating})
  })
};


  
   render() {

   

    const { rating } = this.state;
    return(

        <React.Fragment>
    
    {localStorage.getItem("authToken") == null ? (
      <HeaderTop />
    ) : (
      <HeaderLogout />
    )}
    <Header />

   

    <section className="userDashboardWrap">
    <LoadingOverlay        
              active={this.state.loader}
              //spinner={<BounceLoader />}
              spinner={<CustomImageLoader image={LGspinner} animationType={'pulse'}/>}
            >

      {(this.state.packageDetails!=undefined && this.state.coachDetails!=undefined)?(
        <section className="rating__section">

          <div className="rating__top">
            <Container>
                <Row className="text-light align-items-center">
                  <Col md={6} className="rating__top__text">
                    <h1>So how was it?</h1>
                  </Col>
                  <Col md={6} className="text-right rating__top__text" style={{fontSize: 'small'}}>
                    <h5>{this.state.packageDetails[0].title}</h5>
                    <div className="time">
                    {this.state.coachTimezone!=undefined && this.state.userTimezone!=undefined &&(
                            <b> {convertToTimezone(this.state.coachTimezone,`${moment(this.state.sessionDetails.date).format('YYYY-MM-DD')} ${this.state.sessionDetails.from}`,this.state.userTimezone,'HH:mm')}</b>

                            )}
                            - 
                            
                            {this.state.coachTimezone!=undefined && this.state.userTimezone!=undefined &&(
                            <b> {convertToTimezone(this.state.coachTimezone,`${moment(this.state.sessionDetails.date).format('YYYY-MM-DD')} ${this.state.sessionDetails.to}`,this.state.userTimezone,'HH:mm')}</b>

                            )}
                            </div>
                    <div className="date"> {this.state.coachTimezone!=undefined && this.state.userTimezone!=undefined &&(
                            <b> {convertToTimezone(this.state.coachTimezone,`${moment(this.state.sessionDetails.date).format('YYYY-MM-DD')} ${this.state.sessionDetails.from}`,this.state.userTimezone,'DD-MM-YYYY')}</b>

                            )}  </div>
                    <div className="user__name">{this.state.coachDetails.name}</div>
                  </Col>
                </Row>
            </Container>
          </div>

          <div className="rating__opt text-center mt-5">

            <h6 className="mb-5 reating_head">Your feedback is very important. Help us make <span>LifeGuru</span> the best it can be</h6>

                    
                    <div className="d-flex justify-content-center align-items-center flex-column top_rating">
                      <ReactStars 
                        className="coachRating"
                        name="rate1" 
                        // onChange={(e)=> {this.setState({rating: e}); console.log(this.state.rating);}} 
                        count={3} 
                        size={60} 
                        value={3}
                        activeColor="#fec107" 
                        color="#fec107"
                      />
                      <div className="row rating_label justify-content-center w-100">
                          <span>Good</span>
                          <span>Excellent</span>
                          <span>Exceptional</span>
                        </div>  
                    </div>

                         
          </div>

          <form >
            <div className="rating__wraper">
              <Container>                
                <div className="qa__area">

                  {this.state.questionAndRating.map((QA,index)=>{
                  return(
                      <div className="qa__item">
                    <div className="qa__title">
                      <h4>Q. {QA.q}</h4>
                    </div>
                    <div className="rating__area">
                      {/* https://github.com/ertanhasani/react-stars#readme */}
                      <ReactStars onChange={(e)=>this.ratingChanged(e,index)} count={3} size={32} activeColor="#fec107" />
                    </div>
                  </div>
                    )
                  })}
                  
                  <div className="rating__input mt-3">
                    <h5>Anything else ?  Feedback helps our coaches to make your session as useful as possible {this.state.commentErr!=''?(<>Word Count ({this.state.comment.split(" ").length}) <span style={{color:"red"}}>{this.state.commentErr}</span></>):(<></>)}</h5>
                    <div className="form-group">
                      <textarea className="form-control" rows="4" onChange={this.onchange.bind(this)} placeholder="Type here.."></textarea>
                    </div>

                    <div className="rating__opt_main text-center mt-5 d-flex justify-content-center">
                      <label className="opt__radio col-sm-12 col-md-4">
                        <input name="rating__opt" type="radio" onChange={()=>this.ratingPrivate('yes')} checked />
                        <img src={happy} alt="Yes" />
                        <span><b>Yes !</b>You can publish my feedback</span>
                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20"><path d="M497.36 69.995c-7.532-7.545-19.753-7.558-27.285-.032L238.582 300.845l-83.522-90.713c-7.217-7.834-19.419-8.342-27.266-1.126-7.841 7.217-8.343 19.425-1.126 27.266l97.126 105.481a19.273 19.273 0 0013.784 6.22c.141.006.277.006.412.006a19.317 19.317 0 0013.623-5.628L497.322 97.286c7.551-7.525 7.564-19.746.038-27.291z"/><path d="M492.703 236.703c-10.658 0-19.296 8.638-19.296 19.297 0 119.883-97.524 217.407-217.407 217.407-119.876 0-217.407-97.524-217.407-217.407 0-119.876 97.531-217.407 217.407-217.407 10.658 0 19.297-8.638 19.297-19.296C275.297 8.638 266.658 0 256 0 114.84 0 0 114.84 0 256c0 141.154 114.84 256 256 256 141.154 0 256-114.846 256-256 0-10.658-8.638-19.297-19.297-19.297z"/></svg>
                        <div className="radio__outline"></div>
                      </label>
                      <label className="opt__radio col-sm-12 col-md-4">
                        <input name="rating__opt" type="radio" onChange={()=>this.ratingPrivate('no')}/>
                        <img src={sad} alt="No" />
                        <span><b>No !</b>My feedback is to be kept private</span>
                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20"><path d="M497.36 69.995c-7.532-7.545-19.753-7.558-27.285-.032L238.582 300.845l-83.522-90.713c-7.217-7.834-19.419-8.342-27.266-1.126-7.841 7.217-8.343 19.425-1.126 27.266l97.126 105.481a19.273 19.273 0 0013.784 6.22c.141.006.277.006.412.006a19.317 19.317 0 0013.623-5.628L497.322 97.286c7.551-7.525 7.564-19.746.038-27.291z"/><path d="M492.703 236.703c-10.658 0-19.296 8.638-19.296 19.297 0 119.883-97.524 217.407-217.407 217.407-119.876 0-217.407-97.524-217.407-217.407 0-119.876 97.531-217.407 217.407-217.407 10.658 0 19.297-8.638 19.297-19.296C275.297 8.638 266.658 0 256 0 114.84 0 0 114.84 0 256c0 141.154 114.84 256 256 256 141.154 0 256-114.846 256-256 0-10.658-8.638-19.297-19.297-19.297z"/></svg>
                        <div className="radio__outline"></div>
                      </label>
                    </div>

                    <div className="text-center mt-5">
                    <button 
                      className="btn btn-main-green bg-secondary mr-2" 
                      onClick={ () => this.props.history.push({ pathname: '/profile-consumer' }) }
                    >
                      Cancel
                    </button>
                      <button className="btn btn-main-green" onClick={this.onSubmit.bind(this)}>Submit</button>
                    </div>

                  </div>
                </div>
              </Container>
            </div>

          </form>

        </section>
        ):(this.state.feedback_flag==1? <> 
        
          {/* <div className="text-center">
          Feedback has already been provided - Thank you
          </div> */}

            <section className="thankYouWrap">
                  <div className="container">
                      <div className="row justify-content-center">
                          <div className="col-md-12 text-center"><h1 style={{color: "#82bd51"}}>Thank you </h1></div>                            
                      </div>
                      <div className="row justify-content-center">
                          <div className="col-md-12 text-center"><p style={{color: "#5a5a5a"}}>Your feedback has already been provided.</p></div>
                      </div>
                      <div className="row justify-content-center">
                      <button 
                      className="btn btn-main-green mr-2 " 
                      style={{background: "#734d9d"}}
                      onClick={ () => this.props.history.push({ pathname: '/profile-consumer' }) }
                    >Go to my dashboard</button>
                    </div>
                  </div>
            </section>

          </>:<></>)}
        </LoadingOverlay>
        </section>
      
           
            <footer>
          <FooterLink />
        </footer>

        </React.Fragment>

        )
  }
}

export default Feedback;