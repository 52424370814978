import React, { Component } from "react";
import Select from 'react-select'
import { Link } from "react-router-dom";
import { Form} from "react-bootstrap";

import { Header, HeaderTop } from "../components/headers";
import { FooterLink } from "../components/footers";

import { coachicongray, coachicon } from "../assets/img";

class RegistrationStep extends Component {
  constructor(props, context) {
    super(props, context);
    this.coachingType = [];
    this.state = {
      user_type: "",
      coach:null,
    };
  }

  handleUser = (user_t) => {
    this.setState({ user_type: user_t });
  };

  handleChange = data => {
    
    this.setState({ coach: data });
   
    };

  render() {
    const options = [
      { value: 'Career Coaching', label: 'Career Coaching'},
      { value: 'Executive Coaching', label: 'Executive Coaching' },
      { value: 'Business Coaching', label: 'Business Coaching'  },
      { value: 'Life Coaching', label: 'Life Coaching'},
      { value: 'Transformational Coaching', label: 'Transformational Coaching' },
      { value: 'Health & Wellbeing Coaching', label: 'Health & Wellbeing Coaching'}
    ];
    return (
      <div>
        <HeaderTop />
        <Header />

        <section className="packagesWrap">
          <div className="container">
            <div className="heading text-center">
              <h3>What is relevant for you?</h3>

              <span className="R_divider"></span>

              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-4">
                    <div
                      className={`boxBorderPS  ${
                        this.state.user_type === "coach"
                          ? "border border-primary rounded"
                          : " "
                      }`}
                      onClick={() => this.handleUser("coach")}
                    >
                      {this.state.user_type === "coach" ? (
                        <img
                          alt="lifeguru-img"
                          src={coachicon}
                          className="img-fluid ImgPS"
                        />
                      ) : (
                        <img
                          alt="lifeguru-img"
                          src={coachicongray}
                          className="img-fluid ImgPS"
                        />
                      )}

                      <hr />
                      <div className="subText">I am here for give coaching</div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div
                      className={`boxBorderPS  ${
                        this.state.user_type === "user"
                          ? "border border-primary rounded"
                          : " "
                      }`}
                      onClick={() => this.handleUser("user")}
                    >
                      {this.state.user_type === "user" ? (
                        <img
                          alt="lifeguru-img"
                          src={coachicon}
                          className="img-fluid ImgPS"
                        />
                      ) : (
                        <img
                          alt="lifeguru-img"
                          src={coachicongray}
                          className="img-fluid ImgPS"
                        />
                      )}

                      <hr />
                      <div className="subText">
                        I am here for find my coaches
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
 
        <section className="packagesWrap">
          <div className="container">
            <div className="heading text-center">
              <div className="row justify-content-center">
                <div className="col-8">
                  {this.state.user_type && (
                    <Form>
                      {" "}
                      <h3 className="mb-4 wr">Type of coach related to</h3>
              <Form.Group>
                <Select  className="languageSelect" placeholder='Select Category' isMulti 
                value={this.state.coach}
                onChange={this.handleChange} 
                options={options}
                maxMenuHeight={160}
                menuPlacement="auto"
               />
                
              </Form.Group>
                    </Form>
                  )}
                </div>
              </div>
            </div>

            <div className="text-center mt-5">
              <Link
                className="defaultBtn"
                to={{
                  pathname: "/coach_registration",
                  state: {
                    user_t: this.state.user_type,
                    category: this.state.coach,
                  },
                }}
              >
                Next
              </Link>
            </div>
          </div>
        </section>

        <FooterLink />
      </div>
    );
  }
}
export default RegistrationStep;
