import React, { Component } from "react";
import axios from "../../../config/axios";
import { Header, HeaderTop } from "../../../components/headers";
import { FooterLink } from "../../../components/footers";

import { toast } from "react-toastify";

class LogOut extends Component {
  constructor() {
    super()
    this.state = {
      activation_status: "Logging out...",
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  componentDidMount() {
    const token = localStorage.getItem("authToken");

    if (token!=undefined) {
      let param = {
        url: "/users/logout",
        method: "POST",
        data: { token: token },
        headers: { "X-Auth": token },
      };
      axios(param)
        .then((response) => {
          if (response.data.result) {
            this.setState({ token: "" });
            localStorage.clear();
            toast("Logged out successfully!", { type: "success" });
            this.props.history.push({
              pathname: "/login",
              token: "",
            });
          } else {
            toast("Already Logout", { type: "error" });
            localStorage.clear();
          }
        })
        .catch((err) => {
          toast("Error in logging account", { type: "error" });
        });
    }
  }

  render() {
    return (
      <div>
        <HeaderTop />
        <Header />
        <div className="container padding-bottom-3x mb-2 mt-5">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              {this.state.activation_status}
            </div>
          </div>
        </div>

        <FooterLink />
      </div>
    );
  }
}
export default LogOut;
