import React, { Component } from "react";
import axios from "../../config/axios";
import { toast } from "react-toastify";
// import { TextEditor } from "../Edit";
import { plusSvg, deleteSvg, documentSvg, docSvg, pdfSvg, xlsSvg, pptSvg } from "../../assets/images";
import ReactHtmlParser from "react-html-parser";
import TextEditor from "../../components/media/TextEditor";

import { Link } from "react-router-dom";
import { ListModal } from "../../components/resourcefilemodal";
import PDFViewer from 'mgr-pdf-viewer-react'

class Bio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      certificate_path: null,
      long_bio: "",
      edit: 0,
      certificates: [],
      CertificateType:"",
      viewMoreshow: false,
    };
  }
  componentDidMount() {
    console.log('eneter bio')
    this.getBioData();
  }

  handleCertificatechange=(e)=>{
    e.persist()
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  getBioData() {
    const id=this.props.userdata.uid
    axios
      .get(`/users/biocertificate/${id}`, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        console.log("certificate", res);
        if (res.data.msg === "success") {
          const data = res.data.value;
          this.setState({ certificates: data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleEdit = (e) => {
    if (e.target.value === 0) {
      this.setState({ edit: 1 });
    } else if (e.target.value === 1) {
      this.setState({ edit: 0 });
    }
  };

  handlechange = (e) => {
    this.setState({
      long_bio: e.target.value,
    });
  };

  onChangeHandler = (e) => {
    this.setState({
      certificate_path: e.target.files[0],
      loaded: 0,
    });
    console.log(e.target.files[0]);
  };

  onClickHandler = () => {
    let formData = new FormData();
    formData.append("certificate_path", this.state.certificate_path);
    formData.append("CertificateType", this.state.CertificateType);

    console.log("certificate --formdata",formData)
    axios
      .post("/users/certificate", formData, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        if (res.data.success) {
          // toast("Successfully added file", { type: "success" });
          toast("Successfully added file, Waiting for admin approval", { type: "success" });
          this.getBioData();
        } else {
          toast("Certificate Upload failed", { type: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleDeleteCertificate = (id) => {
    console.log("clicked certi--", id);
    axios
      .delete(`/users/certificate/${id}`, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        if (res.data.success) {
          toast("Successfully deleted file", { type: "success" });
        } else {
          toast("Certificate delete failed", { type: "error" });
        }
        this.getBioData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleViewMoreModal = () => {
    this.setState({viewMoreshow: !this.state.viewMoreshow})
  }

  render() {
    const pdata = this.props.pdata;
    console.log('eneter bio render' )

    return (
      <React.Fragment>
        <section className="biokWrap">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="heading text-center">
                  {/* <h3>Full Bio</h3>
                  <span className="R_divider"></span> */}
                </div>

                {this.props.check_account_type &&
                this.props.check_account_type === "coach" ? (
                  <div className="heading text-right">
                    <div className="bio__caption">
                      <div className="edit_personal_details">
                        <TextEditor
                          pdata={pdata.long_bio}
                          handleOnChange={this.props.handleOnChange}
                          handleSubmitPersonal={this.props.handleSubmitPersonal}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="bioDetails">
                  <p style={{ color: "black" }}>
                    {ReactHtmlParser(pdata.long_bio)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="biokWrap">
          <div className="container" style={{paddingTop : "35px"}}>
          {this.props.check_account_type &&
                this.props.check_account_type === "coach" ? (
            <div className="heading text-center">
              <h3>Upload your professional and training certifications, membership association logos, company logos etc</h3>
              <span className="R_divider"></span>
            </div>
            ) : ("")}
                
            {this.props.check_account_type &&
                this.props.check_account_type === "coach" ? (
            <div className="heading text-right">
              <div className="add__certificate">
                <p
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                >
                  <img width="35px" src={plusSvg} alt="LG-Img" />
                </p>

 
                  <div
                    className="modal fade"
                    id="exampleModalCenter"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5
                            style={{color:"black"}}
                            className="modal-title"
                            id="exampleModalLongTitle"
                          >
                            Upload Image
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        
                        <div className="modal-body">
                          <div>
                        <select
                           style={{color:"black",margin: "auto",
                            display: "block"}}
                 
                             value={this.state.CertificateType}
                             name="CertificateType"
                            placeholder="Enter duration of session"
                            onChange={this.handleCertificatechange}
                            >
                          <option value="">Select Certificate Type</option>
                          <option value="Professional Certificate">Professional Certificate</option>
                          <option value="Training Certificate">Training Certificate</option>
                          <option value="Membership association logos">Membership association logos</option>
                          <option value="Company logos">Company logos</option>
                       </select>
                       </div><br/>
                          <div>
                            <input
                              className="file-upload"
                              type="file"
                              name="certificate_path"
                              id="image"
                              onChange={this.onChangeHandler}
                            />
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={this.onClickHandler}
                            data-dismiss="modal"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
             
              </div>
            </div>

                ):""}




            <div className="row justify-content-center lightBox">
              {this.state.certificates.reverse().slice(0,4).map((certificate, i) => {
                let array = certificate.certificate_path.split("/").pop()
                let filetype = array.split(".").pop().toLowerCase()
               
                return (
                  
                  <div className="col-lg-3 col-md-4 col-6 my-3" key={i}>
                   
                       
                       {/* <a
                       href={certificate.certificate_path}
                       target="_blank"
                      //  data-toggle="lightbox"
                      //  data-gallery="example-gallery"
                       className="boxWrap"
                     > */}
                       {filetype == "pdf" ? 
                      //   <img
                      //   alt="LG-Img"
                      //   src={pdfSvg}
                      //   className="img-fluid"
                      // />
                      (
                        <div className="iframepdf">
                          {/* <iframe
                        height="200px"
                        alt="LG-Img"
                        //src="https://lifeguru.s3-us-east-2.amazonaws.com/assets/doc_placeholder.png"
                        src={certificate.certificate_path}
                      /> */}
                      <PDFViewer document={{
                        url: `${certificate.certificate_path}`
                      }}
                      scale={0.25} 
                      hideNavbar={true}
                      />
                      <a
                       href={certificate.certificate_path}
                       target="_blank"
                       className="boxWrap"
                     ></a>
                        </div>
                      )
                  //      : filetype == "doc" || filetype == "docx" ? 
                  //      <img
                  //      alt="LG-Img"
                  //      src={docSvg}
                  //      className="img-fluid"
                  //    />
                  //    : filetype == "xls" ? 
                  //    <img
                  //    alt="LG-Img"
                  //    src={xlsSvg}
                  //    className="img-fluid"
                  //    width="35px"
                  //  />
                  //  : filetype == "ppt" ? 
                  //    <img
                  //    alt="LG-Img"
                  //    src={pptSvg}
                  //    className="img-fluid"
                  //    width="35px"
                  //  />
                       : filetype == "jpg" || filetype == "jpeg" || filetype == "png" ?
                       (
                        <a
                        href={certificate.certificate_path}
                        target="_blank"
                       //  data-toggle="lightbox"
                       //  data-gallery="example-gallery"
                        className="boxWrap"
                      > 
                        <img
                        alt="LG-Img"
                        src={certificate.certificate_path}
                        className="img-fluid"
                      />
                      </a>
                       )
                       : 
                    //    <img
                    //    alt="LG-Img"
                    //    src={docSvg}
                    //    className="img-fluid"
                    //  />
                    ''
                       }
                     {/* </a> */}
                     
                     {this.props.check_account_type === "coach" ? (
                      <Link
                        className="removeLightbox"
                        //  href="javascript:void(0)"
                        to="/profile-coach"
                        onClick={() => {
                          this.handleDeleteCertificate(certificate.uid);
                        }}
                      >
                        <img width="2" src={deleteSvg} alt="LG-Img" />
                      </Link>
                    ) : (
                      ""
                    )}
                  {<b style={{textAlign: "center",color:"black"}}>{certificate.media_type}</b>}


                    {/* {certificate.status==0 ?(<p style={{textAlign: "center",color:"red"}}>In Review</p>):("")} */}
                      
                  </div>
                );
              })}
            </div>

            {this.state.certificates && this.state.certificates.length > 4 ? (
              <div className="text-center col-12 mt-4">
                <button
                  className="defaultBtn"
                  // data-toggle="modal"
                  // data-target="#CommonModal"
                  onClick={this.handleViewMoreModal}
                >
                  View More
                </button>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </section>

        <ListModal
          accountType={this.props.check_account_type}
          dataSet={this.state.certificates}
          handleDelete={this.handleDeleteCertificate}
          viewMore="certificates"
          handleViewMoreModal={this.handleViewMoreModal}
          showState={this.state.viewMoreshow}
        />
      </React.Fragment>
    );
  }
}
export default Bio;
