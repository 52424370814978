import React, { Component } from "react";
import axios from "../config/axios";
import message from "../utils/mesaages";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";
import Slider from "react-slick";
import  "../components/corporate/WebinarDetails/WebinarDetails.css";
import ReactHtmlParser from "react-html-parser";
import {
  single_webiner,
  sw_clock,
  pc_facebook,
  pc_twitter,
  pc_linkedin,
  webiner1,
  Webiner_coach1,
  LGspinner
} from "../assets/images";
import  presenter1 from  "../assets/images/presenter1.jpg"
import { Practitioner } from "../assets/images";
import { Link } from "react-router-dom";
import moment from "moment";
import { FooterLink } from "../components/footers";
import HeaderSecondTo from '../components/corporate/Header/HeaderSecondTo';
import { Header, HeaderLogout, HeaderTop } from "../components/headers";


const timeFormat = "DD-MMMM-YYYY, hh:mm:ss a";

const settings = {
  dots: false,
  nav: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 820,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default class WebinerDetailsPublic extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("authToken") || localStorage.getItem("corporate_user_token");

    this.state = {
      showPaymentModal :  false,
      loader: false,
      coach_uid: "",
      id: "",
      webinerDetails: null,
      coachDetails: null,

      // SIMILAR WEBINAR
      similarWebinarLoader: false,
      similarWebinars: [],
    };
  }

  componentDidMount = async () => {
    let { coach_uid, id } = this.props.match.params;
    if (coach_uid && id) {
      await this.setState({ coach_uid, id });
      this.getWebinerDetails();
    }
  };

  getWebinerDetails = () => {
    this.setState({ loader: true });
    axios
      .get(
        `webinar/get-webiners-details-new?id=${this.state.id}&coach_uid=${this.state.coach_uid}`
      )
      .then((res) => {
        this.setState({ loader: false });

        if (res.data.success) {
          let { webinerDetails, coachDetails } = res.data.data;
          if (webinerDetails) {
           
            let deadline = moment.utc(webinerDetails.date_string).tz("America/New_York").format(timeFormat);

            let times = this.getTime(deadline);
            let isStart =
              times &&
                times.days <= 0 &&
                times.hours <= 0 &&
                times.minutes <= 0 &&
                times.seconds <= 0
                ? true
                : false;
            webinerDetails.deadline = deadline.split(",");
            webinerDetails.isStart = isStart;
          }
          if (coachDetails) {
            let location = coachDetails.address
              ? JSON.parse(coachDetails.address)
              : null;
            let social_media = coachDetails.social_media
              ? JSON.parse(coachDetails.social_media)
              : null;
            let coaching_category = coachDetails.coaching_category
              ? JSON.parse(coachDetails.coaching_category)
              : null;

            coachDetails.location = location;
            coachDetails.social_media = social_media;
            coachDetails.coaching_category = coaching_category;
          }
          this.setState({ webinerDetails, coachDetails });
          if (!webinerDetails) {
            message.warning(res.data.msg);
          }
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
        message.error("Something Went Wrong");
      });
  };

  getTime = (deadline) => {
    const time = Date.parse(moment(deadline, timeFormat)._d) - Date.now();
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const seconds = Math.floor((time / 1000) % 60);
    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  render() {
    let { webinerDetails, coachDetails, similarWebinars } = this.state;
    return (
      <>

        {
          !localStorage.getItem("subdomain") && (localStorage.getItem("authToken") == null ? (<HeaderTop />) : (<HeaderLogout />))
        }
        {
          localStorage.getItem("subdomain") ? (<HeaderSecondTo />) : (<Header />)
        }
      <LoadingOverlay
        active={this.state.loader}
        //spinner
        spinner={
          <CustomImageLoader image={LGspinner} animationType={"pulse"} />
        }
        className="webiner_loader"
      >
       
        {webinerDetails && (
          <div className="content_wrap p-0">
            <div className="sw_banner">
              <div className="row">
                <div className="col-md-12 col-lg-6 sw_image">
                  <img
                    src={webinerDetails.image || single_webiner}
                    className="img-fluid w-100 h-100"
                  />
                </div>
                <div className="col-md-12 col-lg-6 sw_details">
                  <h2 className="sw_title">{webinerDetails.webinar_topic}</h2>
                  <p>{webinerDetails.script}</p>
                  <ul className="list-unstyled inc_list mb-4">
                    {!webinerDetails.isStart && (
                      <li>
                        <div className="inc_list_item d-flex">
                          <div className="inc_list_img">
                            <img width={20} src={sw_clock} />
                          </div>
                          <div className="inc_list_text col">
                            <strong>Upcoming Webinar :</strong>
                            <div className="text-uppercase">
                            <small style={{ fontSize: '0.7rem' }}> {moment.utc(webinerDetails.date_string).tz("America/New_York").format("D MMM, YYYY - h:mm A")}  <b>EST</b> </small>
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>

            {coachDetails && (
              <div className="practitioner">
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-md-12">
                    <h3 className="text-uppercase">
                      <strong>PRESENTER</strong>
                    </h3>
                    <div className="pc_card">
                      <div className="row">
                        <div className="col-md-4 pc_image">
                          <img
                            src={coachDetails.avatar_path || presenter1}
                            className="img-fluid rounded"
                            alt = "No Image"
                          />
                        </div>
                        <div className="col-md-8 pc_text">
                          <h2 className="pc_name">{coachDetails.name}</h2>
                          <div className="row mb-4">
                            {coachDetails.coaching_category?.length > 0 && (
                              <dl className="col-md-8 mb-0">
                                <dd className="mb-0">Areas of expertise</dd>
                                <dt>
                                  {coachDetails.coaching_category?.map(
                                    (category, key) => {
                                      return (
                                        <span
                                          className="badge badge_green"
                                          key={key}
                                        >
                                          {category.label}
                                        </span>
                                      );
                                    }
                                  )}
                                </dt>
                              </dl>
                            )}

                            {coachDetails.location?.country && (
                              <dl className="col-md-4 mb-0">
                                <dd className="mb-0">Location</dd>
                                <dt>{coachDetails.location?.country}</dt>
                              </dl>
                            )}
                          </div>
                          <p>{ReactHtmlParser(coachDetails.long_bio || coachDetails.short_bio)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            
          </div>
        )}
      </LoadingOverlay>
      <FooterLink />
      </>
    );
  }
}
