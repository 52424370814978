import React, { Component } from "react";
import { Button, Modal, Form, Container, Row } from "react-bootstrap";
import axios from "../../../config/axios";
import "../../../../node_modules/video-react/dist/video-react.css"; // import css

import { Player } from "video-react";
import { upload } from "../../../assets/images";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

class UpdateVideo extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("authToken");
    this.state = {
      show: false,
      videofile: "",
      UserId: ""
    };
  }
  

  componentDidMount(){
    let User=JSON.parse(localStorage.getItem('userdata'))
    this.setState({UserId: User!= null ? User.uid : ''})
  }
 
  // componentWillReceiveProps(){
  //   let User=JSON.parse(localStorage.getItem('userdata'))
  //   console.log(".....props", this.props.coachUid, User.uid)
  // }


  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };

  onChangeHandlerVideo = (e) => {
    this.setState({
      videofile: e.target.files[0],
      loaded: 0,
    });
  };

  handleSubmitProfileVideo = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("profileVideo_path", this.state.videofile);

    let param;
    if (this.props.coachprofile_id !== "") {
      formData["uid"] = this.props.coachprofile_id;
      param = {
        url: "/users/profilevideo",
        method: "PUT",
        data: formData,
        headers: { "X-Auth": this.token },
      };
    } else {
      param = {
        url: "/users/profilevideo",
        method: "POST",
        data: formData,
        headers: { "X-Auth": this.token },
      };
    }

    axios(param)
      .then((response) => {
        if (response.data.success) {
          this.props.handleSubmitProfileVideo(response);
        }
      })
      .catch((error) => {
        toast("Error in updaing profile video", { type: "error" });
      });
  };

  render() {
    return (
      <>

{this.props.coachUid === this.state.UserId &&
         
         (  <Link
          to="profile-coach"
          //   href="javascript:void(0);"
          data-toggle="tooltip"
          data-placement="top"
          title="Upload Personal Introductory Video"
          onClick={this.handleShow}
        >
          <img width="35px" src={upload} alt="LG-Img" />
        </Link>
        )

        }
       

        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Form onSubmit={this.handleSubmitProfileVideo}>
            <Modal.Header closeButton>
              <Modal.Title style={{color:"black"}}> Upload Personal Introductory Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <Player
                    id="player1"
                    width="100%"
                    height="350px"
                    playsInline
                    src={this.props.video}
                  />
                </Row>
                <br />
                <Row>
                  <br />
                  <input
                    type="file"
                    label="Select Video file"
                    name="avatar_path"
                    onChange={this.onChangeHandlerVideo}
                  />
                </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="btn btn-success btn-block"
                type="submit"
                onClick={this.handleClose}
              >
                Upload
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default UpdateVideo;
