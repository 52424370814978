import React from 'react'
import { Link } from "react-router-dom";
import { user, Dashboard, Profile, Video, Webiner, Logout, user_plus, transaction } from "../../../assets/images";
import './Sidebar.css'
import { message } from 'antd';
import axios from '../../../config/axios';

class Sidebar extends React.Component {
    constructor(props) {
        super(props)

        this.token = localStorage.getItem("corporate_token")
        this.userdeta = localStorage.getItem("corporate_userdata")
        this.usertoken = localStorage.getItem("corporate_user_token")
        this.userDeta = localStorage.getItem("corporate_user_userdata")
        this.location = window.location.pathname

        this.state = {
            loader: false,
            userdeta: {}
        }
    }

    componentDidMount() {
        if ((!this.token && !this.userdeta) && (!this.userDeta && !this.usertoken)) {
            window.location = "/"
        }
        this.updateActiveClass(`${this.location.split("/")[this.location.split("/").length - 1]}`)
        this.getProfile()
    }

    getProfile = async () => {
        this.setState({ loader: true });
        axios.get("/api/corprate_user_profile", { headers: { "X-Auth": localStorage.getItem("corporate_token") || localStorage.getItem("corporate_user_token"), }, }).then((res) => {
            if (res.data.success) {
                this.setState({ loader: false });
                localStorage.setItem("corporate_userdata", JSON.stringify(res.data.data))
                this.setState({ userdeta: JSON.parse(localStorage.getItem("corporate_userdata")) });
            }
        }).catch((err) => {
            console.log("err", err);
            this.setState({ loader: false });
            message.error("Something went wrong");
        });
    };

    logoutHandaler = (e) => {
        const hideMessage = message.loading("Please wait while logout...", 0);
        this.setState({ loader: true });
        e.preventDefault();

        axios.get(`/api/corporate_logout/${this.token || this.usertoken}`).then(async (response) => {
            hideMessage();
            if (response.data.success) {
                localStorage.clear()
                message.success(response.data.msg)
                this.setState({ loader: false });
                window.location = "/"
            }
        }).catch((error) => {
            hideMessage();
            message.error(error.response.data.msg);
            this.setState({ loader: false });
        });
    }

    updateActiveClass = (id) => {
        if (document.getElementById(id)) {
            let elements = document.querySelectorAll(".nav-item")
            elements.forEach((element) => {
                element.classList.remove('active');
            });
            document.getElementById(id).classList.add('active');
        }
    }

    render() {
        return (
            <>
                <aside className="cm_sidebar">
                    <div className="sidebar_top text-center mb-4 rounded">
                        <div className="sidebar_top_content">
                            <img width={100} height={100} src={this.state.userdeta.company_logo || user} alt='userImage' className="img-fluid rounded-circle" />
                            <h4 className="cm_username">{this.state.userdeta.firstname ? this.state.userdeta.firstname : ""} {" "}{this.state.userdeta.lastname ? this.state.userdeta.lastname : ""}</h4>
                            <div className="cm_usertype">{this.state.userdeta.jobtitle ? this.state.userdeta.jobtitle : ""}</div>
                        </div>
                        <svg width="185" height="260" viewBox="0 0 185 260" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.63" fillRule="evenodd" clipRule="evenodd" d="M16.7586 336.882C16.7586 336.882 52.6484 -13.3494 362.474 1.89693C362.474 1.89693 341.965 338.875 16.7586 336.882Z" stroke="#99F3E3" strokeWidth="30" />
                        </svg>
                    </div>
                    <nav className="cm_nav">
                        <ul className="navbar-nav">
                            {
                                (this.userdeta) ?
                                    (JSON.parse(this.userdeta).user_type === "1") && (
                                        <li className="nav-item active" id="dashboard" onClick={() => this.updateActiveClass("dashboard")}>
                                            <Link to="/dashboard">
                                                <img width={20} height={20} src={Dashboard} alt="" /> Dashboard
                                            </Link>
                                        </li>
                                    )
                                    : (
                                        <li className="nav-item active" id="dashboard" onClick={() => this.updateActiveClass("dashboard")}>
                                            <Link to="/employee_dashboard">
                                                <img width={20} height={20} src={Dashboard} alt="" /> Dashboard
                                            </Link>
                                        </li>
                                    )
                            }

                            <li className="nav-item" id="corporate_profile" onClick={() => this.updateActiveClass("profile")}>
                                <Link to="/corporate_profile">
                                    <img width={20} height={20} src={Profile} alt="" /> Profile
                                </Link>
                            </li>
                            <li className="nav-item" id="corporate_profile" onClick={() => this.updateActiveClass("change-password")}>
                                <Link to="/change-password">
                                    <img width={20} height={20} src={Profile} alt="" /> Change Password
                                </Link>
                            </li>
                            {
                                (this.userdeta) ?
                                    (JSON.parse(this.userdeta).user_type === "1") && (
                                        <>
                                            {/* <li className="nav-item" id="Assign_Sessions" onClick={() => this.updateActiveClass("Assign_Sessions")}>
                                                <Link to="#">
                                                    <img width={20} height={20} src={Webiner} alt="" /> Assign Sessions
                                                </Link>
                                            </li> */}
                                            <li className="nav-item" id="my-transactions" onClick={() => this.updateActiveClass("My_Transactions")}>
                                                <Link to="/my-transactions">
                                                    <img width={20} height={20} src={transaction} alt="" />
                                                    My Transactions
                                                </Link>
                                            </li>
                                            <li className="nav-item" id="invite-employee" onClick={() => this.updateActiveClass("invite-employee")}>
                                                <Link to="invite-employee">
                                                    <img width={20} height={20} src={user_plus} alt="" />
                                                    Invite Employees
                                                </Link>
                                            </li>
                                            <li className="nav-item" id="setting">
                                                <Link to="setting">
                                                    <img width={20} height={20} src={user_plus} alt="" />
                                                    Setting
                                                </Link>
                                            </li>
                                        </>
                                    ) : (
                                        <>
                                            <li className="nav-item" id="sessions" onClick={() => this.updateActiveClass("sessions")}>
                                                <Link to="/sessions">
                                                    <img width={20} height={20} src={Video} alt="" />
                                                    My Sessions
                                                </Link>
                                            </li><li className="nav-item" id="webinar" onClick={() => this.updateActiveClass("webinars-schedule")}>
                                                <Link to="/webinar">
                                                    <img width={20} height={20} src={Webiner} alt="" /> My Webinars
                                                </Link>
                                            </li>
                                        </>
                                    )
                            }
                            <li className="nav-item" id="logout" onClick={() => this.updateActiveClass("logout")}>
                                <Link to="/" onClick={this.logoutHandaler}>
                                    <img width={20} height={20} src={Logout} alt="" />
                                    Logout
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </aside>
            </>
        )
    }
}

export default Sidebar