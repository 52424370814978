import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import axios from '../../../config/axios'
import { message } from 'antd'

export default function EditModal(props) {
    const [ListName, setListName] = useState(props.details.group_name)

    const HandleSubmit = (e) => {
        e.preventDefault()
        axios.post(`/api/corporate_edit_group`, { id: props.details.id, group_name: ListName, user_id: JSON.parse(localStorage.getItem("corporate_userdata")).uid }, { headers: { "X-Auth": localStorage.getItem("corporate_token"), }, }).then((responce) => {
            message.success(responce.data.msg)
            props.getAllGroup()
            props.handleClose()
        }).catch((error) => {
            message.error(error.response.msg || error.response.data.msg)
        })
    }

    return (
        <Modal className='cModal' centered show={props.editshow} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update List Name</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={(e) => HandleSubmit(e)}>
                    <Form.Group className="mb-3">
                        <Form.Label>List Name <span className="text-danger">*</span></Form.Label>
                        <Form.Control placeholder="List name" required value={ListName} onChange={(e) => setListName(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>List Type</Form.Label>
                        <Form.Control as="select">
                            <option value={props.details.group_type}>{props.details.group_type}</option>
                        </Form.Control>
                    </Form.Group>
                    <Button type="submit" id='editsubmitbutton' hidden />
                    <button type="reset" id='editresetbutton' onClick={() => setListName(props.details.group_name)} hidden />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className='btn tabformbutton' onClick={() => document.getElementById("editresetbutton").click()}>Reset</Button>
                <Button variant="default" className="defaultBtn rounded-lg" onClick={() => document.getElementById("editsubmitbutton").click()}>Submit</Button>
            </Modal.Footer>
        </Modal>
    )
}
