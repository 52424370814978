import React from 'react'

import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap'
import {
    check,
    join_logo,
    reg_left,
    RegImg
} from './../../../assets/images/index'



import './Signup.css'
import axios from '../../../config/axios'
import message from "../../../utils/mesaages";
import { getClientTimezone } from '../../../utils/timeZoneConvert'
import validation from '../../../Validate/validator';

import FooterLink from '../../footers/FooterLink';
import Header from './../../headers/Header'
import HeaderSecondTo from '../Header/HeaderSecondTo'
import PhoneInput from 'react-phone-input-2'
import {showPhonecountry} from "../../../config/custom";


class Signup extends React.Component {
    constructor(props) {
        super(props)

        this.token = localStorage.getItem("corporate_token")
        this.userdeta = localStorage.getItem("corporate_userdata")
        this.usertoken = localStorage.getItem("corporate_user_token")
        this.userDeta = localStorage.getItem("corporate_user_userdata")

        this.state = {
            loader: false,
            firstname: "",
            lastname: "",
            email: "",
            conirmemail: "",
            phone: "",
            password: "",
            confirmpassword: "",
            jobtitle: "",
            companyname: "",
            websiteurl: "",
            companylogo: [],
            about_company: "",
            country: [],
            errors: [],
            countryName: 'us',
            FromDataError: {
                firstname: "false",
                lastname: "false",
                email: "false",
                conirmemail: "false",
                phone: "false",
                password: "false",
                confirmpassword: "false",
                companyname: "false",
                websiteurl: "false",
                about_company : "false"
            },
            error: [],
            emailError: "",
            passwordError: "",
            icon: "",
            Emailicon: "",
            companyEmailError: ""
        }
    }

    componentDidMount() {
        if ((this.token && this.userdeta) || (this.userDeta && this.usertoken)) {
            this.props.history.push('/dashboard')
        }
    }

    handleChangephone = (e, data, event, formattedValue) => {
        
        let FromDataError = this.state.FromDataError
        if ((e.length - data.dialCode.length) >= 7) {
            // console.log('true')
            FromDataError.phone = ''
        } else {
            // console.log('false')
            FromDataError.phone = 'Enter valid phone number'
        }
        this.setState({ FromDataError });

        setTimeout(() => {
            this.setState({ ['phone']: e, });
        }, 100);

    };

    validateForm(errors) {
        let valid = true;
        let sta = this;
        let FromDataError = this.state.FromDataError;

        for (const [key, value] of Object.entries(errors)) {
            if (value.length > 0) {
                if (value === "false") {
                    FromDataError[key] = "This field is required";
                    sta.setState({ FromDataError });
                }
                valid = false;
            }
        }
        return valid;
    }

    handleChange = (e) => {
        e.persist();
        if (this.state.passwordError !== "") {
            if (e.target.name === "confirmpassword") {
                this.setState({ passwordError: "" })
            }
        }
        if (this.state.emailError !== "") {
            if (e.target.name === "conirmemail") {
                this.setState({ emailError: "" })
            }
        }

        if (this.state.companyEmailError !== "") {
            if (e.target.name === "conirmemail") {
                this.setState({ companyEmailError: "" })
            }
        }

        let { FromDataError } = this.state;
        var valid_obj = {
            value: e.target.value,
            rules: e.target.getAttribute("validaterule"),
            message: e.target.getAttribute("validatemsg"),
        };

        validation(valid_obj).then((error) => {
            FromDataError[e.target.name] = error;
            this.setState({ FromDataError });
            setTimeout(() => {
                this.setState({
                    [e.target.name]: e.target.value,
                });
            }, 100);
            if (e.target.name === "confirmpassword") {
                if (e.target.value === this.state.password) {
                    this.setState({ icon: "fa-check" })
                } else if (e.target.value !== this.state.password) {
                    this.setState({ icon: "fa-times" })
                }
            }
            if (e.target.name === "password") {
                if (e.target.value === this.state.confirmpassword) {
                    this.setState({ icon: "fa-check" })
                } else if (e.target.value !== this.state.confirmpassword) {
                    this.setState({ icon: "fa-times" })
                }
            }
            if (e.target.name === "conirmemail") {
                if (e.target.value === this.state.email) {
                    this.setState({ Emailicon: "fa-check" })
                } else if (e.target.value !== this.state.email) {
                    this.setState({ Emailicon: "fa-times" })
                }
            }
            if (e.target.name === "email") {
                if (e.target.value === this.state.conirmemail) {
                    this.setState({ Emailicon: "fa-check" })
                } else if (e.target.value !== this.state.conirmemail) {
                    this.setState({ Emailicon: "fa-times" })
                }
            }
            if (e.target.name === "websiteurl") {
                var my_email = this.state.conirmemail
                var ind = my_email.indexOf("@");
                let value = e.target.value
                if (value.search(my_email.slice((ind + 1), my_email.length).split(".")[0].toLowerCase()) > 0) {
                    this.setState({ Emailicon: "fa-check" })
                } else if (value.search(my_email.slice((ind + 1), my_email.length).split(".")[0].toLowerCase()) < 0) {
                    this.setState({ Emailicon: "fa-times" })
                }
            }
        });
    };

    onHandleSubmit = (e) => {
        e.preventDefault();
        var my_email = this.state.conirmemail
        var ind = my_email.indexOf("@");
        if (this.state.email !== this.state.conirmemail) {
            this.setState({ emailError: "Emails are not same" })
        } else if (this.state.password !== this.state.confirmpassword) {
            this.setState({ passwordError: "Password are not same" })
        } else if (this.state.websiteurl.search(my_email.slice((ind + 1), my_email.length).split(".")[0].toLowerCase()) < 0) {
            this.setState({ companyEmailError: "Please provide your company email id" })
        } else {
            if (this.validateForm(this.state.FromDataError)) {
                const hideMessage = message.loading("Please wait while register in ...", 0);
                this.setState({ loader: true });
                e.preventDefault();
                const formDeta = new FormData()
                formDeta.append("firstname", this.state.firstname)
                formDeta.append("lastname", this.state.lastname)
                formDeta.append("email", this.state.email)
                formDeta.append("jobtitle", this.state.jobtitle)
                formDeta.append("companyname", this.state.companyname)
                formDeta.append("password", this.state.password)
                formDeta.append("phone", this.state.phone)
                formDeta.append("websiteurl", this.state.websiteurl)
                formDeta.append("about_company", this.state.about_company)
                formDeta.append("companylogo", this.state.companylogo)
                axios.post(`/api/corporate_signup`, formDeta, { headers: { "Content-Type": "multipart/form-data" } }).then(async (response) => {
                    hideMessage();
                    if (response.data.success) {
                        message.success(response.data.msg)
                        // document.getElementById("abc").innerHTML = ""
                        document.getElementById("registractionform").innerHTML = `<div class='text-center signup_success d-flex align-items-center justify-content-center h-100'><div><img src='${check}' className="mb-4"/><h4 class='mb-3'>Your are successfully registered.</h4><p> Please check your inbox and spam for the  activation link sent to your mail.</p></div></div>`
                        getClientTimezone();
                        this.setState({ loader: false });
                    }
                }).catch((error) => {
                    hideMessage();
                    console.log(error);
                    message.error(error.response.data.msg);
                    this.setState({ loader: false });
                });
            }
        }
    }

    render() {
        return (
            <>
                <HeaderSecondTo />
                <div className="reg">
                    <Container>
                        <div className='reg_head text-center'>
                            <h2>Company Registration</h2>
                        </div>
                        <Card className='reg_card mt-4 border-0'>
                            <Row className='mx-0'>
                                <Col md={5} className="reg_image d-flex align-items-center" style={{ background: 'url(' + RegImg + ') center center no-repeat', backgroundSize: 'cover' }}>
                                    <div>
                                        <ul className='list-unstyled'>
                                            <li>Company personnel must register using their company email that matches the domain of their company’s website</li>
                                            <li>Company personnel can select from a wide range of webinars to offer to their employees</li>
                                            <li>Company personnel can invite their employees to join and participate in webinars that will support and enhance their workplace experience</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={7} className="reg_form " id="registractionform">
                                    <Form onSubmit={this.onHandleSubmit} encType='multipart/form-data'>
                                        <div className='form_item mb-4'>
                                            <h4 className="regf_title">Personal Info</h4>
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>First Name <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" name='firstname' validaterule={["required"]} validatemsg={["Enter your Name"]} required onChange={(e) => this.handleChange(e)} />
                                                        <div style={{ color: "red" }}>
                                                            {this.state.FromDataError.firstname === "false" ? "" : this.state.FromDataError.firstname}
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Last Name <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" name='lastname' validaterule={["required"]} validatemsg={["Enter your Name"]} required onChange={(e) => this.handleChange(e)} />
                                                        <div style={{ color: "red" }}>
                                                            {this.state.FromDataError.lastname === "false" ? "" : this.state.FromDataError.lastname}
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="email" name='email' required validaterule={["required", "isEmail"]} validatemsg={["Enter your email address", "Enter a valid email address",]} onChange={(e) => this.handleChange(e)} />
                                                        <div style={{ color: "red" }}>
                                                            {this.state.FromDataError.email === "false" ? "" : this.state.FromDataError.email}
                                                            {this.state.companyEmailError !== "" && this.state.companyEmailError}
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Confirm Email <span className="text-danger">*</span></Form.Label>
                                                        <div className='confirmpassword'>
                                                            <Form.Control type="email" name='conirmemail' required validaterule={["required", "isEmail"]} validatemsg={["Enter your email address", "Enter a valid email address",]} onChange={(e) => this.handleChange(e)} />
                                                            <i class={`fa ${this.state.Emailicon}`} aria-hidden="true"></i>
                                                        </div>
                                                        <div style={{ color: "red" }}>
                                                            {this.state.FromDataError.conirmemail === "false" ? "" : this.state.FromDataError.conirmemail}
                                                            {this.state.emailError !== "" && this.state.emailError}
                                                            {this.state.companyEmailError !== "" && this.state.companyEmailError}
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <Form.Label>Phone <span className="text-danger">*</span></Form.Label>
                                                        <div className="input-group">
                                                            <PhoneInput 
                                                            country={"us"}
                                                            onlyCountries = {showPhonecountry}
                                                            name='phone' required={true}  countryCodeEditable={false} inputClassName="form-control phone-reg" placeholder="Phone Number" onChange={this.handleChangephone} validaterule={['required', 'phone']} validatemsg={['Phone number is required', 'Please enter a valid phone number']} />
                                                        </div>
                                                        <div style={{ color: "red" }}>
                                                            {this.state.FromDataError.phone === "false" ? "" : this.state.FromDataError.phone}
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Password <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="Password" name='password' validaterule={["required", "password"]} validatemsg={["Enter your password "]} required onChange={(e) => this.handleChange(e)} />
                                                        <div style={{ color: "red" }}>
                                                            {this.state.FromDataError.password === "false" ? "" : this.state.FromDataError.password}
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Confirm Password <span className="text-danger">*</span></Form.Label>
                                                        <div className='confirmpassword'>
                                                            <Form.Control type="Password" name='confirmpassword' validaterule={["required", "password"]} validatemsg={["Enter your password "]} required onChange={(e) => this.handleChange(e)} />
                                                            <i class={`fa ${this.state.icon}`} aria-hidden="true"></i>
                                                        </div>
                                                        <div style={{ color: "red" }}>
                                                            {this.state.FromDataError.confirmpassword === "false" ? "" : this.state.FromDataError.confirmpassword}
                                                            {this.state.passwordError !== "" && this.state.passwordError}
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Employee Job Title<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" name='jobtitle' required onChange={(e) => this.handleChange(e)} />
                                                        <div style={{ color: "red" }}>
                                                            {this.state.FromDataError.jobtitle === "false" ? "" : this.state.FromDataError.jobtitle}
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className='form_item'>
                                            <h4 className="regf_title">Company Info</h4>
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Company Name <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" name='companyname' required onChange={(e) => this.handleChange(e)} />
                                                        <div style={{ color: "red" }}>
                                                            {this.state.FromDataError.companyname === "false" ? "" : this.state.FromDataError.companyname}
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Website URL <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" name='websiteurl' required onChange={(e) => this.handleChange(e)} />
                                                        <div style={{ color: "red" }}>
                                                            {this.state.FromDataError.websiteurl === "false" ? "" : this.state.FromDataError.websiteurl}
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <Form.Label>Additional Comments </Form.Label>
                                                        <Form.Control type="text" name='about_company' required onChange={(e) => this.handleChange(e)} />
                                                        <div style={{ color: "red" }}>
                                                            {this.state.FromDataError.about_company === "false" ? "" : this.state.FromDataError.about_company}
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12} style={{"display":"none"}}>
                                                    <Form.Group>
                                                        <Form.Label>Company Logo </Form.Label>
                                                        <div className='custom-file'>
                                                            <Form.Control className='custom-file-input' id="customFile" type='file' label="Select Image file"  lang="en" custom name='companylogo' accept=".jpg,.jpeg,.png" onChange={(e) => this.setState({ companylogo: e.target.files[0] })} />
                                                            <label className="custom-file-label" for="customFile">{(this.state.companylogo.length !== 0) ? this.state.companylogo.name : ("Choose Image File")}</label>
                                                        </div>
                                                        {/* <div style={{ color: "red" }}>
                                                            {this.state.FromDataError.companylogo === "false" ? "" : this.state.FromDataError.companylogo}
                                                        </div> */}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Button variant='default' className="defaultBtn" type='submit'>Submit</Button>
                                        <p className="loginLink mt-3">Have an account <a href="/signin"><strong>Sign In</strong></a></p>
                                    </Form>
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </div>
                <FooterLink />
            </>
        )
    }
}

export default Signup