import axios from "../config/axios";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { render } from "@testing-library/react";

let getClientTimezone = async () => {
  let userdata = await localStorage.getItem("userdata");
  let account_type = JSON.parse(userdata).account_type;
  let id = JSON.parse(userdata).uid;
  // console.log('userdata',JSON.parse(userdata))

  return new Promise((resolve, reject) => {
    axios
      .post("/consumer/getTimezone", {
        id,
        account_type,
      })
      .then((res) => {
        localStorage.setItem("timezone", res.data.timezone);
        resolve(res.data.timezone);
      })
      .catch((e) => resolve("No TimeZone available."));
  });
};

let timezoneUtc = (timezone, value) => {
  timezone = timezone.split(" ")[0];

  // console.log('value',value,timezone)

  return momentTimezone.tz(value, timezone).utc();
};
let UTCtoTimezone = (timezone, utc, format) => {
  //
  timezone = timezone.split(" ")[0];
  // console.log(timezone)
  return utc.clone().tz(timezone).format(format);
};

let convertToTimezone = (currentZone, time, convertZone, format) => {
  console.log(
    "requirements in converter",
    currentZone,
    time,
    convertZone,
    format
  );

  try {
    if (currentZone == undefined || currentZone == null) {
      console.log("unfortunately in if");
      return momentTimezone(time).format(format);
    }
    console.log("currentZone", currentZone);
    currentZone = currentZone.split(" ")[0];
    convertZone = convertZone.split(" ")[0];

    return momentTimezone
      .tz(time, currentZone)
      .utc()
      .clone()
      .tz(convertZone)
      .format(format);
  } catch (error) {
    console.log(error);
  }
};

let getCochTimezone = async (account_type, id) => {
  // console.log('userdata',JSON.parse(userdata))

  return new Promise((resolve, reject) => {
    axios
      .post("/consumer/getTimezone", {
        id,
        account_type,
      })
      .then((res) => {
        // localStorage.setItem('timezone',res.data.timezone)
        resolve(res.data.timezone);
      })
      .catch((e) => resolve("No TimeZone available."));
  });
};

let convertUTCToLocal = async (utc_time) => {
  // https://www.cluemediator.com/convert-local-time-to-another-timezone-using-moment-js

  // https://jsfiddle.net/FLhpq/4/light/

  const timeFormat = "YYYY-MM-DD, hh:mm:ss a";

  const toDt = moment.utc(utc_time);
  return moment(toDt).format(timeFormat);
};

// let test = ()=>{

//     return(<div>
//       Hello

//     </div>

//     )

// }
export {
  getClientTimezone,
  timezoneUtc,
  UTCtoTimezone,
  convertToTimezone,
  getCochTimezone,
  convertUTCToLocal,
};

// export default test
