import React, { Component } from "react";
import axios from "../../../config/axios";
import message from '../../../utils/mesaages';
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import ReactPaginate from 'react-paginate';
import { Pie } from "react-chartjs-2";

import "./Webinar.css"
import RecordingModal from "../../../components/webinars/schedule/RecordingModal";
import { SSO_LOGOUT } from "../../../utils/Sso";

const data = {
    labels: ["Completed", "Upcoming"],
    datasets: [
        {
            label: "Sessions",
            backgroundColor: ["#1bbc9b", "#d9a81d"],
            data: [45, 80],
        }
    ],
};

const options = {
    legend: {
        display: true,
        labels: {
            boxWidth: 15,
            fontSize: 16
        }
    }
};



class Webinar extends Component {
    constructor(props) {
        super(props);

        this.token = localStorage.getItem("authToken");

        //states...
        this.state = {
            loading: false,
            limit: 4,
            page: 1,
            totalPages: "",
            upcoming: [],
            completed: [],
            curr_date: moment().format(),
            off_set: moment().local().utcOffset(),
            show: false,
            modal: ''
        }
    }

    getWebinars = () => {
        this.setState({ loading: true })
        axios
            .get(`/webinar/webinar-list`,
                {
                    headers: {
                        "x-auth": this.props.token
                    }
                })
            .then(res => {
                if (res.data.success) {
                    this.setState({
                        upcoming: res.data.upcoming,
                        loading: false
                    })
                } else if (parseInt(res.data.status) === 600) {
                    this.logOutUser()
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                console.log("error", err);
                message.error("Server error")
            })
    }

    getCompletedWebinars = () => {
        this.setState({ loading: true })
        axios
            .get(`/webinar/completed-webinar-list?limit=${this.state.limit}&page=${this.state.page}`,
                {
                    headers: {
                        "x-auth": this.props.token
                    }
                })
            .then(res => {
                if (res.data.success) {
                    this.setState({
                        completed: res.data.completed_webinars,
                        totalPages: res.data.total_pages,
                        page: res.data.current_page,
                        loading: false
                    })
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                console.log("error", err);
                message.error("Server error")
            })
    }
    onPageChange = (page) => {
        let page1 = page.selected + 1;
        this.setState({ page: page1 }, () => this.getCompletedWebinars());
    }

    joinWebinar = (e) => {
        // let webinar_id = e.target.id;console.log(e.target.id)
        this.setState({ loading: true })
        axios
            .post(`/webinar/start-webinar`,
                {
                    webinar_id: e.target.id
                },
                {
                    headers: {
                        "x-auth": this.props.token
                    }
                })
            .then(res => {
                if (res.data.success) {
                    // console.log("bbb response", res)
                    let url = res.data.bbb_joining_link;
                    this.setState({ loading: false })
                    window.open(url, '_blank');

                } else {
                    this.setState({ loading: false })
                    message.error(res.data.msg)

                }


            })
            .catch(err => {
                this.setState({ loading: false })
                console.log("err", err)
            })
    }

    showMessage() {
        message.success("Meeting to start after bbb completes")
    }

    handleShow = (meetingID) => {
        this.setState({ show: true })
        axios.post('/webinar/get-recording', { meetingID: meetingID }, { headers: { "x-auth": this.token, }, }).then((res) => {
            if (res.data.success) {
                this.setState({ modal: <RecordingModal show={true} handleClose={this.handleClose} Recordings={res.data.data ? res.data.data : []} /> })
            }
        }).catch((err) => {
            console.log(err)
            message.error("Something went wrong");
        })
    }

    handleClose = () => {
        this.setState({ show: false });
    };

    logOutUser = async () => {
        const hideMessage = message.error("Your account has been deactivated")

        const token = localStorage.getItem("authToken");
        await SSO_LOGOUT()
        localStorage.clear();
        if (token !== undefined) {
            let param = {
                url: "/users/logout",
                method: "POST",
                data: { token: token },
                headers: { "X-Auth": token },
            };
            await axios(param)
        }
        hideMessage()
        window.location = '/login#logout'
    }

    getTime2 = (deadline) => {
        deadline = moment.utc(deadline).tz("America/New_York");
       let currentDate = moment.tz("America/New_York");
       const startDate = new Date(currentDate); 
       const endDate = new Date(deadline); 
       const timeDifference = endDate - startDate;
       const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
       const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
       const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
       const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
       
       if(days <= 0 && hours <= 0 && (minutes <= 10 && minutes >= -15)){
        return true;
       }
       return false;
   
       return {
         days,
         hours,
         minutes,
         seconds,
       };
     }
    componentDidMount() {
        this.getWebinars();
        this.getCompletedWebinars();
    }

    render() {
        const { upcoming, completed, curr_date, off_set, totalPages, loading } = this.state;
        return (
            <>
                <LoadingOverlay active={loading} spinner>
                    <div className="heading text-center">
                        <h3>Webinars List</h3>
                        <span className="R_divider div_dark"></span>
                    </div>

                    {/* <div className="row justify-content-center">
                    <div className="col-md-5">
                        <Pie data={data} options={options} />
                    </div>
                </div> */}

                    {upcoming.length === 0 && completed.length === 0 &&
                        <div className="text-center">
                            <div className="card border-0 d-inline-block px-5 py-3">
                                <p className="mb-0 text-danger">There is no webinar present!</p>
                            </div>
                        </div>
                    }

                    {upcoming.length > 0 && <div className="session_type_title">Upcoming</div>}

                    <div className='webinar_partts'>
                        {
                            upcoming?.map((item, i) => {
                                // console.log(`id-${item.id}`)
                                // console.log('time', item.date_string)
                                // let off_set = moment(item.date_string).local().utcOffset();
                                // console.log('time in local', moment(item.date_string).add(off_set, "m").format())
                                return (
                                    <>
                                        <div className='webinar_listinggg' key={i}>
                                            <div className='webinar_listing_inner_parttts'>
                                                <div className='webinar_inner'>
                                                    <span>Webinar Name</span>
                                                    <label>{item.webinar_topic}</label>
                                                </div>
                                                <div className='webinar_inner'>
                                                    <span>Webinar Category</span>
                                                    <label>{item.webinar_category}</label>
                                                </div>
                                                <div className='webinar_inner'>
                                                    <span>Date</span>
                                                    {/* {this.props.timezone ? 
                                                        <span>{moment.parseZone(item.date_string).tz(this.props.timezone).format("DD-MM-YYYY")}</span>
                                                    : 
                                                        <span>{moment.parseZone(item.date_string).local().format("DD-MM-YYYY")}</span>
                                                    }                              */}
                                                    <small style={{ fontSize: '0.7rem' }}> {moment.utc(item.date_string).tz("America/New_York").format("DD-MM-YYYY")}  </small>
                                                    {/* <span>{moment(item.date_string).add(off_set, "m").format('DD-MM-YYYY')}</span> */}
                                                </div>
                                                <div className='webinar_inner'>
                                                    <span>Time</span>
                                                    <small style={{ fontSize: '0.7rem' }}> {moment.utc(item.date_string).tz("America/New_York").format("hh:mm a")}  <b>EST</b> </small>
                                                    {/* {this.props.timezone ? 
                                                        <span>{moment.parseZone(item.date_string).tz("America/Los_Angeles (GMT -07:00)").format("hh:mm a")}</span>
                                                        : 
                                                        <span>{moment.parseZone(item.date_string).local().format("hh:mm a")}</span> 
                                                    } */}
                                                    {/* <span>{moment(item.date_string).add(off_set, "m").format('hh:mm a')}</span> */}
                                                </div>
                                                <div className='webinar_inner'>
                                                    <span>Registered</span>
                                                    <span>{item.registrationCount}</span>
                                                </div>
                                                {
                                                    (!this.getTime2(item.date_string)) ?
                                                        <div className='webinar_inner'>
                                                            <button
                                                                type='button'
                                                                className='btn btn-primary'
                                                                id={item.id}
                                                                // onClick={(e)=>this.joinWebinar(e)}
                                                                disabled
                                                            >
                                                                Upcoming
                                                            </button>
                                                        </div> :
                                                        <div className='webinar_inner'>
                                                            <button
                                                                type='button'
                                                                className='join_button'
                                                                id={item.id}
                                                                // onClick={this.showMessage}
                                                                onClick={(e) => this.joinWebinar(e)}
                                                            >
                                                                Start
                                                            </button>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }

                    </div>

                    {completed.length > 0 && <div className="session_type_title">Completed</div>}
                    <div className='webinar_partts'>
                        {
                            completed?.map((item, i) => {
                                console.log("CompleteItem :", item);
                                return (
                                    <div className='webinar_listinggg' key={i}>
                                        <div className='webinar_listing_inner_parttts'>
                                            <div className='webinar_inner col-3'>
                                                <span>Webinar Name</span>
                                                <label>{item.webinar_topic}</label>
                                            </div>
                                            <div className='webinar_inner col-3'>
                                                <span>Category</span>
                                                <label>{item.webinar_category}</label>
                                            </div>
                                            <div className='webinar_inner'>
                                                <span>Date</span> 
                                                <small style={{ fontSize: '0.7rem' }}> {moment.utc(item.date_string).tz("America/New_York").format("DD-MM-YYYY")}  </small> 
                                                {/* {this.props.timezone ? 
                                                    <span>{moment.parseZone(item.date_string).tz(this.props.timezone).format("DD-MM-YYYY")}</span>
                                                : 
                                                    <span>{moment.parseZone(item.date_string).local().format("DD-MM-YYYY")}</span>
                                                } */}
                                                {/* <span>{moment(item.date_string).add(off_set, "m").format('DD-MM-YYYY')}</span> */}
                                            </div>
                                            <div className='webinar_inner'>
                                                <span>Time</span>
                                                <small style={{ fontSize: '0.7rem' }}> {moment.utc(item.date_string).tz("America/New_York").format("hh:mm a")}  <b>EST</b> </small>
                                                {/* {this.props.timezone ? 
                                                    <span>{moment.parseZone(item.date_string).tz("America/Los_Angeles (GMT -07:00)").format("hh:mm a")}</span>
                                                    : 
                                                    <span>{moment.parseZone(item.date_string).local().format("hh:mm a")}</span> 
                                                } */}
                                                {/* <span>{moment(item.date_string).add(off_set, "m").format('hh:mm:ss a')}</span> */}
                                            </div>
                                            <div className='webinar_inner'>
                                                <span>Registered</span>
                                                <span>{item.registrationCount}</span>
                                            </div>
                                            <div className='webinar_inner'>
                                                <span>Joined</span>
                                                <span>{item.joinCount}</span>
                                            </div>
                                            <div className="session_type">
                                                <div className="webinar_inner p-0">
                                                    <span>
                                                        <button type="button" className="join_button" id={item.id} style={{ "whiteSpace": "nowrap" }} onClick={() => this.handleShow(item.bbb_meetingID)}>
                                                            <i className="fa fa-video-camera" aria-hidden="true"></i>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                            {/* <div className='webinar_inner'>
                                            <label>Joined</label>
                                            <span>50</span>
                                        </div> */}
                                            <div className='webinar_details col-12'>
                                                <p className="mb-0 d-flex justify-content-between">{item.script}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    {completed.length > 0 &&
                        <div className="result_pagination mt-4">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={this.onPageChange}
                                pageRangeDisplayed={6}
                                pageCount={totalPages}
                                previousLabel="<"
                                activeClassName="active"
                                disabledClassName="disable"
                                renderOnZeroPageCount={null}
                            />
                        </div>}
                    {
                        (this.state.show === true) ? this.state.modal : ''
                    }
                </LoadingOverlay>
            </>
        )
    }
}

export default Webinar;