import React from "react";
import {Container} from "react-bootstrap";
import Slider from "react-slick";


const settings = {
    dots: false,
    nav: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
        {
            breakpoint: 820,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const ourPartner = (props)=>{
    return true;
    return (
    <>
        <section className="b_partners">
          <Container>

          {
            props.firstRow.filter(function (el) { return el.id === 9 })[0] && (
                <div className='cs_heading text-center mb-5'>
                    <h2 className="cs_title">{JSON.parse(props.firstRow.filter(function (el) { return el.id === 9 })[0]?.content).title}</h2>
                </div>
            )
        }
        

        <Slider {...settings} className="cpSlider">
            {
                props.firstRow.map((items) => (
                    (parseInt(items.id) === 9) && (
                        <div className="carousel-item ">
                            <img src={items.multiple_image} alt='' />
                        </div>
                    )
                ))
            }
            {
                props.firstRow.map((items) => (
                    (parseInt(items.id) === 9) && (
                        <div className="carousel-item ">
                            <img src={items.multiple_image} alt='' />
                        </div>
                    )
                ))
            }
        </Slider>

          </Container>
        </section>
      </>
    )
}

export default ourPartner;