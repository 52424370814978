import React from "react";
//import "../../../node_modules/video-react/dist/video-react.css"; // import css

//import { Player, ControlBar, PlaybackRateMenuButton, VolumeMenuButton  } from "video-react";
import ReactPlayer from "react-player";

const VideoView = (props) => {
  console.log("props flag", props.flag);
  return (
    <div>
      {/* {//console.log('ddddddddddddddddd',props.video)}
   {props.video!=null || props.video!=''?  */}

      {/* <Player
        id="p1"
        playsInline
        fluid={false}         
        height={500}
        src={props.video} 
        className="m-auto"
    /> */}
      {/* <ReactPlayer 
      url={`${props.video}`} 
      controls={props.flag == '1' ? true : false} 
      muted={props.flag == '1' ? true : false} 
      playing={props.flag == '1' ? true : false} 
      loop={props.flag == '1' ? true : false}
      height="500px"
      width="100%"
      playsinline
      //light={this.state.banner.thumbnail_url} 
    /> */}

      <ReactPlayer
        url={`${props.video}`}
        controls={true}
        muted={true}
        playing={true}
        loop={true}
        height="500px"
        width="100%"
        playsinline
        //light={this.state.banner.thumbnail_url}
      />
    </div>
  );
};
export default VideoView;
