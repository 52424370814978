import { data } from "jquery";
import React from "react";
import { Link } from "react-router-dom";
import { sortSvg, dArrowblack, teamSvg, changeCard } from "../../assets/images";
import { avatar } from "../../assets/img";
import axios from "../../config/axios";
import {dateConfig, convertDateFormat} from "../../config/dateFormats";

import message,{getHelpText} from '../../utils/mesaages';
import {curencyFilter,curencyRates} from '../../utils/currencyConvert';

import { Tooltip } from 'antd';
import {Tab, Nav, Accordion, Card, Button, Row, Col,} from "react-bootstrap";
import {getClientTimezone,UTCtoTimezone,convertToTimezone,getCochTimezone} from '../../utils/timeZoneConvert';
import CancelConfirmModal from '../../components/commonmodal/CancelConfirmModal'
import moment from "moment";
import ReplaceCardModal from "./view/ReplaceCardModal";
import Pagination from "../../components/pagination/pagination";

var _ = require('lodash');
class UserBooking extends React.Component {
  constructor(props){
    super(props)
    this.state={
        bookingDetails:[],
        bookingDetailsMain:[],
        bookingDetailsMainTimes:[],
        Description:"",
        TotalSession:"",
        leftSession:'',
        sessionSceduled:'',
        packDetails:'',
        sessionDetails:'',
  
        currentPage:1,
        todosPerPage:4,
        currentPage1:1,
        todosPerPage1:4,
        key: 'intro',
        apiCallI:0,
        apiCallM:0,
        sort:'desc',
        book_coaching_text:'',
        booked_coaching_text:'',
        currentEventKey: '',
        book_cancel_text: '',
        id:0,
        CancelModal:false,
        package_title:'',
        IntroDate:'',
        IntroTime:'',
        introbookingDetails:'',
        rates:"",
        // INTRO SESSION PAGINATION STATES
        introLimit: 4,
        introOffset: 0,
        introPageNumber: 1,
        // MAIN SESSION PAGINATION STATES
        mainLimit: 4,
        mainOffset: 0,
        mainPageNumber: 1,
    }
  }



  tabsctive(j) {
    this.setState(
      { key: j },()=>{
          this.getBooking()
        
      }
    )
  }


  handleCloseCancel(id=0,package_title='',IntroDate='',IntroTime='' ,introbookingDetails='')
    {
      console.log(id)
     
      if(this.state.CancelModal)
      {
         this.setState({CancelModal: false})
      }
      else{
        this.setState({
          id: id,
          package_title:package_title,
          IntroDate:IntroDate,
          IntroTime:IntroTime,
          introbookingDetails:introbookingDetails,
          CancelModal: true})
       
       
      }
    }

  async componentDidMount(){
    getClientTimezone()
    let book_coaching_text = await getHelpText('book_coaching_session')
    this.setState({book_coaching_text})

    let booked_coaching_text = await getHelpText('booked_coaching_session')
    this.setState({booked_coaching_text})

    let book_cancel_text = await getHelpText('intro_booking_cancel')
    this.setState({book_cancel_text})

    let rates = await curencyRates()
    this.setState({rates})
    
    await this.getMainSessionCount()
    this.getBooking()
    
   
}

getMainSessionCount = async () => {
  try {
    let dataReceived = await axios.get("/users/session/get-main-session-count", {
      headers:{"x-auth":this.props.token}
    })
    .then(async (res) => {
      if(res.data.success) {
        console.log("res", res.data)
        let key = res.data.value.rowCount > 0 ? "main":"intro"
        await this.setState({key})
        return true
      }
    })
    return dataReceived
  }
  catch(err) {
    console.log(err)
    return false
  }
}

getBooking=()=>
{
  let rates = this.state.rates
  let {introLimit, introOffset, mainLimit, mainOffset} = this.state
  let limit, offset;
  if(this.state.key=='intro'){
    limit=introLimit
    offset=introOffset
  }
  else {
    limit=mainLimit
    offset=mainOffset
  }
  if(this.state.key=='intro'){this.setState({apiCallI:0})}else(this.setState({apiCallM:0}))
 
  // axios.get("/users/session/booking2/"+this.state.key+"?sort="+this.state.sort,{
  axios.get("/users/session/booking2/"+this.state.key+"?sort="+this.state.sort+"&limit="+limit+"&offset="+offset,{
    headers:{"x-auth":this.props.token}
  })
  .then(async(res) =>{
    // console.log("booking---res",res)
    if(res.data.success){
      const data=res.data.value[0]

      if(res.data.type == 'intro')
      {
        console.log('data',res.data.value)
        this.setState({bookingDetails:res.data.value, bookingDataCount: res.data.bookingDataCount},()=>{
          this.setState({apiCallI:1})
        })

      }
      else{
        let value = res.data.value
        value = value.map((collection)=>{
          collection["filterAmount"] = curencyFilter(collection.packagePrice,'',rates)
          return collection;
        })
        this.setState({bookingDetailsMain:value, sessionOrdersCount: res.data.sessionOrdersCount},()=>{
          this.setState({apiCallM:1})
        })
      }
      
    

    }
    else{
      this.setState({apiCallI:1,apiCallM:1})
       if(this.state.key == 'intro')
      {
        
        this.setState({bookingDetails:[]},()=>{
         
        })

      }
      else{
        this.setState({bookingDetailsMain:[]},()=>{
        
        })
      }

    }
  })
  .catch(err =>{
    console.log(err)
  })
}

 handleGetPackage=(packId) =>{


    const id=packId
    axios.get(`users/packages/packagecontent2/${id}/${this.state.key}`,{
      headers:{'x-auth':this.props.token}
    })
    .then(res =>{
      if(res.data.success){

        if(this.state.key=='intro')
        {
          let data=res.data.value
        this.setState({TotalSession:data.total_sessions,
          leftSession:res.data.s_left,Description:data.description,packDetails:data})

        }
        else{
          let data=res.data.value
        this.setState({TotalSession:res.data.total_s,
                        leftSession:res.data.s_left,
                        sessionSceduled:res.data.s_scedued,
                        Description:data.description,packDetails:data,
                        bookingDetailsMainTimes:res.data.SessionBooked},
                        ()=>{
                          console.log('sessionnnnnnnnnnn ',this.state.bookingDetailsMainTimes)
                        })

        }
        
      }
    })

  
  
 }

handaleChange=(e)=>{

  this.setState({sort:e.target.value},()=>{
    this.getBooking()
  })

}

handleSelect = async(eventKey,e) => {
  e.preventDefault()
  if(eventKey != null){
   await this.setState({currentEventKey: eventKey})
  } 
  else {
    await this.setState({currentEventKey: ''})
  }
  console.log("@@ tab clicked", this.state.currentEventKey)
}

incompleteKYCaction = (coach_uid) => {
  message.warning("Sessions are currently unavailable with this coach", 5)
  axios.post(`/users/incomplete-kyc-action`, {coach_uid}, {
    headers: {"x-auth": this.props.token}
  }).then((res) => {
    
  }).catch((err) => {

  })
}

paginateIntro = async (pagenumber) => {
  const indexOfLast = pagenumber * this.state.introLimit;
  const indexOfFirst = indexOfLast - this.state.introLimit;
  await this.setState({ introPageNumber: pagenumber, introOffset: indexOfFirst});
  this.getBooking()
};

paginate = async (pagenumber) => {
  const indexOfLast = pagenumber * this.state.mainLimit;
  const indexOfFirst = indexOfLast - this.state.mainLimit;
  await this.setState({ mainPageNumber: pagenumber, mainOffset: indexOfFirst});
  this.getBooking()
};

 

  render() {
    

    const { bookingDetails, currentPage, todosPerPage } = this.state;
    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = bookingDetails.slice(indexOfFirstTodo, indexOfLastTodo);
    console.log('current to do', currentTodos)

    const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(this.state.bookingDetails.length / this.state.todosPerPage); i++) {
          pageNumbers.push(i);
        }

        const { bookingDetailsMain, currentPage1, todosPerPage1 } = this.state;
    // Logic for displaying current todos
    const indexOfLastTodo1 = currentPage1 * todosPerPage1;
    const indexOfFirstTodo1 = indexOfLastTodo1 - todosPerPage1;
    const currentTodos1 = bookingDetailsMain.slice(indexOfFirstTodo1, indexOfLastTodo1);

    const pageNumbers1 = [];
        for (let i = 1; i <= Math.ceil(this.state.bookingDetailsMain.length / this.state.todosPerPage1); i++) {
          pageNumbers1.push(i);
        }
   

    // console.log("page---number---",pageNumbers)
    // console.log("state-------",this.state.bookinglengthFrom,this.state.bookinglengthTo)
    return (
      <div className="col-md-8 col-lg-9">
        <div className="searchHead">
          <div className="searchHeading">
            <h3>
              <img width="22px" src={teamSvg} alt="LG-Img" /> My Bookings
            </h3>
          </div>
          <div className="searchShortBy" style={{cursor: "pointer"}}>
            <img width="15px" src={sortSvg} alt="LG_Img" />
            <select onChange={this.handaleChange} style={{cursor: "pointer"}}>
            <option>Sort by </option>
            
                <option value="date_asc">Date Asc</option>
                <option value="date_dsc">Date Dsc</option>
            </select>
            <span>
              <img src={dArrowblack} alt="LG_Img" style={{cursor: "pointer"}}/>
            </span>
          </div>
        </div>

        {/* <div className="row mt-4">
          <div className="col-md-9">
            <div className="userBookingSearch">
              <input
                type="text"
                placeholder="Search By Coach Name..."
                className="form-control"
                name=""
              />
            </div>
          </div>
          <div className="col-md-3">
            <button className="defaultBtn w-100" type="button">
              Search{" "}
            </button>
          </div>
        </div> */}

        <div className="userBookingList pt-3 pb-3">
        {(this.state.id != 0)? (<>
                <CancelConfirmModal {...this.props} 
                id={this.state.id} 
                package_title={this.state.package_title}
                IntroDate={this.state.IntroDate}
                IntroTime={this.state.IntroTime}
                introbookingDetails={this.state.introbookingDetails}
                show={this.state.CancelModal} 
                getBooking={this.getBooking}
                close={()=>this.handleCloseCancel()}/>
        </>) : (<></>) } 
                  





          <Tab.Container  className="userbooking_tabs" id="left-tabs-example" defaultActiveKey="first" transition={false} id="noanim-tab-example" activeKey={this.state.key} onSelect={(k) => {this.tabsctive(k); this.setState({currentEventKey: ''})}}>

              <Nav variant="tabs" defaultActiveKey="intro" className='navTab'>
                <Nav.Item>
                  <Nav.Link eventKey="intro">
                  Intro Session(s)
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="main">
                  Session(s) Details
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="intro">

                  {(this.state.key=='intro')?(<>

                        {this.state.bookingDetails.length > 0 && this.state.apiCallI == 1 && currentTodos.map(booking =>{
                          let cardData = booking.cardDetails
                          let cardUid = booking.card_uid
                          let cardMode = booking.cardMode
                          let gateway_mode = booking.gateway_mode
                          let coachData=JSON.parse(booking.package_details)
                          let coachKYCstatus = booking.coachKYCstatus
                         
                          console.log("coach-----detail s",this.state.bookingDetails)

                         let coachTimezone= booking.coachtimezone
                         let userTimezone=localStorage.getItem('timezone')
                         console.log('hhsddddddddd',coachData)
                        let start = convertToTimezone(coachTimezone,`${coachData.sessionDate} ${coachData.slotTime.split('-')[0]}`,userTimezone,'HH:mm')
                        let end = convertToTimezone(coachTimezone,`${coachData.sessionDate} ${coachData.slotTime.split('-')[1]}`,userTimezone,'HH:mm')
                        let date=convertToTimezone(coachTimezone,`${coachData.sessionDate} ${coachData.slotTime.split('-')[0]}`,userTimezone,dateConfig.dateFormat)
                       let introTime=start+'-'+end
                     let disableBook=  booking.session_booked_status>0 ?this.state.booked_coaching_text:  booking.status=="Cancelled"?"Your purchase has been cancelled.":"This package has been deleted by the coach. You can not book sessions to this package"
                     let disableCancel=  booking.session_booked_status>0 ?this.state.booked_coaching_text:  booking.status=="Cancelled"?"Your purchase has been cancelled.":"This package has been deleted by the coach. You can not book sessions to this package"

                          return(
                           
                                    <div className="bookingCocachDetails booking_list payment_details_coach">
                                            <ul>
                                              <li>
                                                {coachData.coachImage!==undefined && coachData.coachImage!==null ?(<div>
                                                  <img
                                                  alt="LG_Img"
                                                  width="60px"
                                                  className="userBookingCocachImg"
                                                  src={coachData.coachImage}
                                                  // className="img-fluid"
                                                />
                                                </div>):(<div>
                                                  <img
                                                  alt="LG_Img"
                                                  width="60px"
                                                  className="userBookingCocachImg"
                                                  src={avatar}
                                                  // className="img-fluid"
                                                />
                                                </div>)}
                                              
                                              </li>
                                              {/* <li>
                                                <b>Order N.o</b> <span>{booking.id+'00'}</span>
                                              </li> */}
                                              {/* <li>
                                                <b>Booking Date</b> <span>{booking.created.split("T")[0]}</span>
                                              </li> */}
                                              <li>
                                                <b>Booking Date</b> <span>{moment(booking.created).format(dateConfig.dateFormat)}</span>
                                              </li>
                                             
                                              <li>
                                                <b>Coach Name</b> <span>{booking.ispublic!=0?(coachData!==null ?(<a href={`/profile-coach-view/${booking.coach_uid}`}>{coachData.coach_name}</a>):("")):(<a href={`/`}>{coachData.coach_name}</a>)}</span>
                                              </li>
                                              <li>
                                                <b>Intro Session Date</b> <span>{coachData!==null ?(date):("")}</span>
                                              </li>
                                              <li>
                                                <b>Intro Session Time</b> <span>{coachData!==null ?(`${start}-${end}`):("")}</span>
                                              </li>
                                              <li>
                                                <b>Package Name</b> <span className="package">{booking!==null ?(booking.package_title):("")}</span>
                                              </li>
                                             
                                             
                                              <li>
                                                <b>Status</b><span className="package">{booking.status==='end'?'Complete':(booking.status === "deleted"? "Cancelled":booking.status)}</span>
                                              </li>
                                              <li className="userCoachMore">
                                                {/* <a
                                                  data-toggle="collapse"
                                                  href={`#${booking.uid}`}
                                                  aria-expanded="false"
                                                  aria-controls="collapseExample"
                                                  onClick={()=>{
                                                    // this.handleGetPackage(booking.package_uid)
                                                  }
                                                  }
                                                >
                                                  <i className="fa fa-plus" aria-hidden="true"></i>
                                                </a> */}
                                                
                                              </li>
                                            

                                              <Row className="w-100 mt-3"> 
                                                  {/* <Col md="6">
                                                    <div className="card_number"><strong>CARD NUMBER</strong><span className="nbox">
                                                      {cardMode==gateway_mode? cardData.cardnumber : `Card mode ${cardMode}`}
                                                      </span> 
                                                    {cardMode==gateway_mode &&(
                                                        <ReplaceCardModal
                                                        booking_card_uid={cardUid}
                                                        getBooking={this.getBooking}
                                                        type={this.state.key}
                                                        />
                                                    )}
                                                    </div>
                                                  </Col> */}
                                                  <Col md="12" className="d-flex flex-wrap justify-content-center">

                                                          {booking.session_booked_status>0 || booking.isdeleted=='1' || booking.status=="Cancelled" || booking.status === "deleted"?
                                                            <>
                                                                { booking.status === "deleted" ?
                                                                  <>
                                                                    <Tooltip title={"Session has been cancelled."} placement="top">
                                                                      
                                                                      <button
                                                                        disabled={true}
                                                                        className="disablebutton abcd mb-2"
                                                                        style={{
                                                                          paddingInline: 20
                                                                        }}
                                                                      >
                                                                        Book Coaching Session
                                                                      </button>
                                                                  
                                                                    </Tooltip>

                                                                    <Tooltip title={"Session has been cancelled. If you need to change or reschedule your Intro call please contact your coach via chat box."} placement="top">
                                                                        <span>
                                                                        <button
                                                                        disabled={true}
                                                                        className="defaultBtndng ml-2" 
                                                                        style={{width:'150px', cursor: "not-allowed"}} 
                                                                        //  onClick={()=>this.handleCloseCancel(booking.id,booking.package_title,date,introTime,booking)} 
                                                                        type="button" variant="danger">Cancel
                                                                        </button>
                                                                        </span>
                                                                    </Tooltip>

                                                                  </>
                                                                  :
                                                                  <>
                                                                    <Tooltip title={disableBook} placement="top">
                                                                      
                                                                      <button
                                                                        disabled={true}
                                                                        className="disablebutton abcd mb-2"
                                                                        style={{
                                                                          paddingInline: 20
                                                                        }}
                                                                      >
                                                                        Book Coaching Session
                                                                      </button>
                                                                  
                                                                    </Tooltip> 
                                                                      {/* {booking.status=="Cancelled"?
                                                                      <Tooltip title="Request coach for rescheduling your intro session" placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>
                                                                      :""
                                                                      } */}
                                                                      {booking.session_booked_status<=0 && (booking.status=="Confirmed" || booking.status=="end")?
                                                                        <Tooltip title={this.state.book_cancel_text} placement="top">
                                                                        <span>
                                                                        <button className="defaultBtndng ml-2" style={{width:'150px'}} onClick={()=>this.handleCloseCancel(booking.id,booking.package_title,date,introTime,booking)} type="button" variant="danger">Cancel</button>
                                                                        </span>
                                                                        </Tooltip>
                                                                        :""
                                                                      }
                                                                    </>
                                                                }
                                                              </> 
                                                            :
                                                            <>
                                                                  {coachKYCstatus ? (
                                                                    // coachKYCstatus IS TRUE
                                                                    <Tooltip title={this.state.book_coaching_text}>
                                                                    <span>

                                                                <Link 
                                                                  to={{
                                                                    pathname: `/session-booking/${booking.coach_uid}/${booking.package_uid}`,
                                                                  
                                                                  }} 
                                                                  className="defaultBtn mb-2">
                                                                Book Coaching Session
                                                                  </Link>
                                                            </span>    
                                                                  </Tooltip>
                                                                  ): (
                                                                    // coachKYCstatus IS FALSE
                                                                    // <Tooltip title="Coach customer details(KYC) is incomplete">
                                                                    // <span 
                                                                    // // style={{cursor: "not-allowed"}}
                                                                    // >

                                                                <Link 
                                                                  to={{pathname: "#"}} 
                                                                  className="defaultBtn mb-2"
                                                                  // style={{pointerEvents: "none"}}
                                                                  onClick={() => this.incompleteKYCaction(booking.coach_uid)}
                                                                  >
                                                                Book Coaching Session
                                                                  </Link>
                                                            // </span>    
                                                            //       </Tooltip>
                                                                  )}
                                                                  {booking.status=="Cancelled"?
                                                                  <Tooltip title="Request coach for rescheduling your intro session" placement="top"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>
                                                                  :""
                                                                  }
                                                                  {booking.status=="Confirmed" || booking.status=="end"?
                                                                  <Tooltip title={this.state.book_cancel_text} placement="top">
                                                                  <span>
                                                                  <button className="defaultBtndng ml-2" style={{width:'150px'}} onClick={()=>this.handleCloseCancel(booking.id,booking.package_title,date,introTime,booking)} type="button" variant="danger">Cancel</button>
                                                                  </span>
                                                                  </Tooltip>
                                                                  :""
                                                                  }
                                                            </>
                                                                
                                                          }

                                                  </Col>
                                              </Row>

                                            {/* <li style={{flex:'0 0 100%'}}>
                                            <hr/>
                                            
                                            </li> */}
                                            
                                            </ul>
                                            {/* <div className="collapse" id={booking.uid}>
                                              <div className="BCDView">
                                                <p>
                                                  <b>Package Details : </b>{" "}
                                                  <span>{booking.package_des}</span>
                                                </p> */}
                                                {/* <p>
                                                  <b>Total Sessions : </b> <span>{booking.totalSession}</span>
                                                </p>
                                                <p>
                                                  <b>Sessions left : </b> <span>{booking.sessionLeft}</span>
                                                </p> */}
                                                {/* <Link onClick={()=>{this.props.handleSubComponent('UserCalender')}} className="defaultBtn mt-2">
                                                  Go to Calendar
                                                </Link>
                                                <Link 
                                                  to={{
                                                    pathname: `/session-booking/${booking.coach_uid}/${booking.package_uid}`,
                                                   
                                                  }} 
                                                  className="defaultBtn mt-2">
                                                  Book
                                                  </Link>
                                              </div>
                                            </div> */}
                                    </div>
                             

                        )
                        })}

                        {(this.state.bookingDetails.length<=0 && this.state.apiCallI == 1)?(<>
                          <div className="bookingCocachDetails">
                            <ul>
                              <li style={{width: "100%", textAlign: "center"}}><b>No Records Found</b></li>
                            </ul>
                        </div>
                        
                        </>):(<></>)}

                        {(this.state.apiCallI == 0)?(<>
                          <div className="bookingCocachDetails">
                            <ul>
                              <li style={{width: "100%", textAlign: "center"}}><b>Please Wait..</b></li>
                            </ul>
                        </div>
                        
                        </>):(<></>)}


                        {this.state.bookingDataCount && this.state.bookingDataCount >
                          this.state.introLimit ? (
                            <Pagination
                              postsPerPage={this.state.introLimit}
                              totalPosts={this.state.bookingDataCount}
                              paginate={this.paginateIntro}
                              currentPage={this.state.introPageNumber}
                            />
                          ) : (
                            ""
                          )}



                        </>):(<></>)}








                </Tab.Pane>
                <Tab.Pane eventKey="main">
                {(this.state.key=='main')?(<>
                  <Accordion onSelect={this.handleSelect}>

                  {this.state.bookingDetailsMain.length > 0 && this.state.apiCallM == 1 && currentTodos1.map((order, i) =>{
                   
                          let coachData=order
                          let sessionObj=order.sessionObj
                          let bookings = order.bookings
                          let cardData = order.cardDetails
                          let cardUid = order.card_uid
                          let cardMode = order.cardMode
                          let gateway_mode = order.gateway_mode
                          let coachKYCstatus = order.coachKYCstatus
                         
                          let coachTimezone= order.coachtimezone
                          let userTimezone=localStorage.getItem('timezone')
                          console.log('is_public2',order.ispublic2)
                          let is_public2=order.ispublic2
                         
                       
                         
                         let sessionDetails=''
                         if(coachData!=null)
                         {
                          return(
                                    <div className="bookingCocachDetails personalTransaction payment_details_coach">
                                        
                                          <Card>
                                            
                                              <Accordion.Toggle as={Button} variant="link" eventKey={order.id} style={{textDecoration: "none"}}>
                                              <ul>
                                              <li>
                                                {coachData.coachImage!==null  && coachData.coachImage!==undefined ?(<div>
                                                  <img
                                                  alt="LG_Img"
                                                  width="60px"
                                                  className="userBookingCocachImg"
                                                  src={coachData.coachImage}
                                                  // className="img-fluid"
                                                />
                                                </div>):(<div>
                                                  <img
                                                  alt="LG_Img"
                                                  width="60px"
                                                  className="userBookingCocachImg"
                                                  src={avatar}
                                                  // className="img-fluid"
                                                />
                                                </div>)}
                                              
                                              </li>
                                             
                                              <li>
                                                <b>Order No.{order.order_id==0 ? (
                                                  <Tooltip title="Order number will display once card is charged."> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>
                                                ) : ""}</b> <span>{order.order_id==0? '' :`${order.order_id}`.padStart(5, "0")}</span>
                                              </li>
                                              {/* <li>
                                                
                                                <b>Purchase Date</b> <span>{convertDateFormat(booking.introbookingdate)}</span>
                                              </li> */}
                                              <li>
                                                <b>Amount</b><span className="package">{order.filterAmount}</span>
                                              </li>
                                              <li>
                                                <b>Package Name</b> <span className="package">{coachData!==null ?(coachData.package_name):("")}</span>
                                              </li>
                                              <li>
                                                <b>Coach Name</b> <span>{is_public2!=0?(coachData!==null ?(<Link to={`/profile-coach-view/${order.coach_id}`}>{order.coach_name}</Link>):("")):(<Link to={`/`}>{order.coach_name}</Link>)}</span>
                                              </li>
                                         
                                              <li>
                                                <b>Total Session</b><span className="package">{sessionObj.length}</span>
                                              </li>
                                              <li className="userCoachMore">
                                                <a
                                                  data-toggle="collapse"
                                                  href={`#${order.id}`}
                                                  aria-expanded="false"
                                                  aria-controls="collapseExample"
                                                  onClick={()=>{
                                                    // this.handleGetPackage(order.package_id)
                                                  }
                                                  }
                                                >
                                                  <i className={this.state.currentEventKey !='' && this.state.currentEventKey === order.id ? 'fa fa-minus': 'fa fa-plus'} aria-hidden="true"></i>
                                                </a>
                                              </li>

                                            </ul>

                                              

                                              </Accordion.Toggle>
                                              <Row className="w-100 mt-1"> 
                                                  {/* <Col md="12" className="d-flex justify-content-start">
                                                    <div className="card_number"><strong>CARD NUMBER</strong><span className="nbox">{cardData !== null ?

                                                  cardMode==gateway_mode? cardData.cardnumber : `Card mode ${cardMode}`
                                                    : "No details available"}</span> 
                                                    {cardData !== null && cardMode==gateway_mode ? (
                                                      <ReplaceCardModal
                                                      booking_card_uid={cardUid}
                                                      getBooking={this.getBooking}
                                                      type={this.state.key}
                                                      />
                                                    ): ""}</div>
                                                  </Col> */}
                                              </Row>
                                            
                                            <Accordion.Collapse eventKey={order.id}>
                                              <Card.Body className="pl-3 pr-3">
                                              <div className="BCDView">
                                                {/* <p>
                                                  <b>Package Details : </b>{" "}
                                                  <span>{booking.session_des}</span>
                                                </p>
                                                <p>
                                                  <b>Total Sessions : </b> <span>{booking.totalSession}</span>
                                                </p>
                                                <p>
                                                  <b>Sessions left : </b> <span>{booking.sessionLeft}</span>
                                                </p>
                                                <p>
                                                  <b>Sceduled Session : </b> <span>{booking.sessionBookedCount}</span>
                                                </p>
                                                <Link onClick={()=>{this.props.handleSubComponent('UserCalender')}} className="defaultBtn mt-2">
                                                  Go to Calendar
                                                </Link> */}
                                                {/* <Link 
                                                  to={{
                                                    pathname: `/session-booking/${booking.coach_id}/${booking.package_id}`,
                                                   
                                                  }} 
                                                  className="defaultBtn mt-2">
                                                  Book
                                                  </Link> */}
                                              {/* <table className="table session-book">
                                              <thead className="thead-green">
                                                <tr>
                                                  <th scope="col">#</th>
                                                  <th scope="col">First</th>
                                                  <th scope="col">Last</th>
                                                  <th scope="col">Handle</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <th scope="row">1</th>
                                                  <td>Mark</td>
                                                  <td>Otto</td>
                                                  <td>@mdo</td>
                                                </tr>
                                                
                                              </tbody>
                                            </table> */}
                                            <table className="table session-book">
                                              <thead className="thead-green">
                                                {/* <tr>
                                                  <th scope="col">#</th>
                                                  <th scope="col">First</th>
                                                  <th scope="col">Last</th>
                                                  <th scope="col">Handle</th>
                                                </tr> */}
                                              </thead>
                                              <tbody>
                                             
                                                  { sessionObj.map((bookingDetailsMainTimes)=>{
                                                      {console.log('booking.....',bookings)
                                                      console.log('booking details main.....',bookingDetailsMainTimes)
                                                      sessionDetails= _.find(bookings, {session_id: "session"+bookingDetailsMainTimes.id})
                                                     console.log('tanmaySanyal',sessionDetails)
                                                    }
                                                    
                                                    return(
                                                   
                                                    <tr >
                                                      {/* <th scope="row">{sessionObj[bookingDetailsMainTimes.session_id.split('session')[1]]!==undefined ?(sessionObj[bookingDetailsMainTimes.session_id.split('session')[1]].sessionName +' ('+sessionObj[bookingDetailsMainTimes.session_id.split('session')[1]].Length+' minutes)'):("")} </th> */}
                                                      <th style={{padding:"3px", wordBreak:"break-word", whiteSpace:"normal"}} scope="row">
                                                        <div>
                                                            {bookingDetailsMainTimes.sessionName+' ('+bookingDetailsMainTimes.Length+' minutes)'}
                                                        </div>
                                                          {/* {sessionDetails?.status==4 && (
                                                          <div className={'cancelSession'}>
                                                            <span>Canceled</span>
                                                          </div>
                                                          )} */}
                                                      </th>
                                                      
                                                     {/* <td>{sessionObj[bookingDetailsMainTimes.session_id.split('session')[1]].Length}</td> */}
                                                     <td style={{padding:"2px", textAlign:"center"}}>
                                                     {/* {sessionDetails?.status==4 && (
                                                           <Link 
                                                           to={{
                                                             pathname: `/session-booking/${booking.coach_id}/${booking.package_id}`,
                                                           
                                                           }} 
                                                           className="defaultBtn mt-2">
                                                           Re Booking
                                                           </Link>
                                                      )} */}
                                                     
                                                     
                                                     {sessionDetails===undefined || sessionDetails?.status==4 || sessionDetails?.status==0? 
                                                            coachKYCstatus ? (
                                                              // sessionDetails===undefined || sessionDetails?.status==4 IS TRUE > coachKYCstatus IS TRUE
                                                              <Link 
                                                            to={{
                                                              pathname: `/session-booking/${order.coach_id}/${order.package_id}`,
                                                            
                                                            }} 
                                                            className="defaultBtn mt-2">
                                                            Book
                                                            </Link>
                                                            ) : (
                                                              // sessionDetails===undefined || sessionDetails?.status==4 IS TRUE > coachKYCstatus IS FALSE
                                                              // <Tooltip title="Coach customer details(KYC) is incomplete" placement="left">
                                                              //   <span 
                                                              //   // style={{cursor: "not-allowed"}}
                                                              //   >
                                                                <Link className="defaultBtn mt-2" to="#" 
                                                                // style={{pointerEvents: "none"}}
                                                                onClick={() => this.incompleteKYCaction(order.coach_id)}
                                                                >
                                                                  Book
                                                                </Link>
                                                              //   </span>
                                                              // </Tooltip>
                                                            )
                                                        :
                                                        // sessionDetails===undefined || sessionDetails?.status==4 IS FALSE
                                                        sessionDetails!==undefined  ?(<>
                                                        <div className="slots">
                                                          <span>Session Date:</span>
                                                          <span className="slotsDate">
                                                          {` ${convertToTimezone(coachTimezone,`${sessionDetails.date.split('T')[0]} ${sessionDetails.start_time}`,userTimezone,'DD-MM-YYYY')} 
                                                    ${convertToTimezone(coachTimezone,`${sessionDetails.date.split('T')[0]} ${sessionDetails.start_time}`,userTimezone,'HH:mm')} -
                                                    ${convertToTimezone(coachTimezone,`${sessionDetails.date.split('T')[0]} ${sessionDetails.end_time}`,userTimezone,'HH:mm')}`}
                                                        </span>
                                                        </div>
                                                        </>):(" ")
                                                        
                                                        
                                                    }
                                                    </td>  
                                                    </tr>
                                                        //   <ul style={{background:"#f5eeee"}}>
                                                   
                                                        //   {/* <li>
                                                        //     <div>
                                                        //       <img
                                                        //       alt="LG_Img"
                                                        //       width="60px"
                                                        //       className="userBookingCocachImg"
                                                        //       src={avatar}
                                                        //       // className="img-fluid"
                                                        //     />
                                                        //     </div>

                                                        //   </li> */}

                                                        //   <li>
                                                        //     <b>Scheduled</b> <span>{bookingDetailsMainTimes!==null ?(bookingDetailsMainTimes.date.split("T")[0]):("")}</span>
                                                        //   </li>
                                                        //   <li>
                                                        //     <b>Slot Time</b> <span>{bookingDetailsMainTimes!==null ?(`${bookingDetailsMainTimes.start_time} - ${bookingDetailsMainTimes.end_time}`):("")}</span>
                                                        //   </li>
                                                        //   {/* <li>
                                                        //     <b>Coach Name</b> <span>{this.state.bookingDetailsMainTimes!==null ?(coachData.coach_name):("")}</span>
                                                        //   </li> */}
                                                        //   <li>
                                                        //     {/* {console.log('sssssssssssssssss',sessionObj,sessionObj[bookingDetailsMainTimes.session_id.split('session')[1]])} */}
                                                        //     <b>Package</b> <span className="package">{sessionObj[bookingDetailsMainTimes.session_id.split('session')[1]]!==undefined ?(sessionObj[bookingDetailsMainTimes.session_id.split('session')[1]].sessionName):("")}</span>
                                                        //   </li>
                                                        //   <li>
                                                        //     <b>Status</b><span className="package">{bookingDetailsMainTimes.status==1? 'Confirmed':bookingDetailsMainTimes.status==2? 'Joined': bookingDetailsMainTimes.status==3? 'Complete':''}</span>
                                                        //   </li>
                                                        //  </ul>

                                                    )

                                                  })}
                                                
                                                </tbody>
                                            </table>
                                            
                                              </div>
                                              </Card.Body>
                                            </Accordion.Collapse>
                                          </Card>
                                          
                                        
                                    </div>
                                    
                        )
                        }
                        })}
                        </Accordion>
                         {(this.state.bookingDetailsMain.length<=0 && this.state.apiCallM == 1)?(<>
                          <div className="bookingCocachDetails">
                            <ul>
                              <li style={{width: "100%", textAlign: "center"}}><b>No Records Found</b></li>
                            </ul>
                        </div>
                        
                        </>):(<></>)}

                        {(this.state.apiCallM == 0)?(<>
                          <div className="bookingCocachDetails">
                            <ul>
                              <li style={{width: "100%", textAlign: "center"}}><b>Please Wait..</b></li>
                            </ul>
                        </div>
                        
                        </>):(<></>)}


                        {this.state.sessionOrdersCount && this.state.sessionOrdersCount >
                          this.state.mainLimit ? (
                            <Pagination
                              postsPerPage={this.state.mainLimit}
                              totalPosts={this.state.sessionOrdersCount}
                              paginate={this.paginate}
                              currentPage={this.state.mainPageNumber}
                            />
                          ) : (
                            ""
                          )}


                  </>):(<></>)}
                  
                </Tab.Pane>
              </Tab.Content>

          </Tab.Container>












      



        {/* {currentTodos.map((booking, index) => {
          return <li key={index}>{booking.status}</li>;
        })
      }

        {pageNumbers.map(number =>{
          return(
            <li
            style={{color:"black"}}
              key={number}
              id={number}
              onClick={this.handleClick}
            >
              {number}
            </li>
          )
        })} */}
         

        </div>
      </div>
    );
  }
}

export default UserBooking;
