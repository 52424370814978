import React from "react";
import _ from "lodash";
import { Redirect } from "react-router-dom";
import GoogleLogin from "react-google-login";
import axios from "../../../config/axios";
import { toast } from "react-toastify";
import message from '../../../utils/mesaages';

class Glogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      userType: "",
      userdata: "",
      token: "",
      accessToken: " ",
    };
  }

  responseGoogle = (response) => {
    console.log('g response' ,response)
    if (response && response.profileObj) {
      this.setState({
        accessToken: response.accessToken,
      });
      const hideMessage = message.loading('Please wait ...', 0)
      const formData = {
        username: response.profileObj.email,
        password: "",
      };
      axios
        .post("/logingoogle", formData)
        .then((response) => {
          hideMessage()
          if (
            response.data.token !== undefined &&
            response.data.result === true
          ) {
            localStorage.setItem("authToken", response.data.token);
            localStorage.setItem(
              "coachingCategory",
              response.data.userdata.coaching_category
            );
            localStorage.setItem(
              "userdata",
              JSON.stringify(response.data.userdata)
            );

            this.setState({
              isLoggedIn: response.data.result,
              userType: response.data.userdata.account_type,
              userdata: response.data.userdata,
              token: response.data.token,
            });
          } else if (response.data.result === false) {
            hideMessage()
            message.error(response.data.msg);
          }
        })
        .catch((error) => {
          hideMessage()
          message.error("Error logging in using google.");
        });
    } else {
            if (response.error === 'popup_closed_by_user') {
                let tooltipTitle = React.createElement("p", null, "Please enable cookies in your browser. You can check this document for ", React.createElement("a", { href: 'https://support.google.com/accounts/answer/61416?hl=en&co=GENIE.Platform%3DDesktop', target: '_blank' }, "more information"));
                message.info(tooltipTitle);
              }
    }
  };

  render() {
    let googleContent;

    if (this.state.isLoggedIn && !_.isEmpty(this.state.accessToken)) {
      googleContent = (
        <div>
          {this.state.userType === "coach" ? (
            <div>
              return
              <Redirect
                to={{
                  pathname: "/profile-coach",
                  state: {
                    user: this.state.userdata,
                    token: this.state.token,
                  },
                }}
              />
            </div>
          ) : (
            <div>
              return{" "}
              <Redirect
                to={{
                  pathname: "/profile-consumer",
                  state: {
                    user: this.state.userdata,
                    token: this.state.token,
                  },
                }}
              />
            </div>
          )}
        </div>
      );
    } else {
      googleContent = (
        <GoogleLogin
          clientId="839591354107-ut5lnt2j9oqa0phboum3u7i0jk410ine.apps.googleusercontent.com"
          buttonText=" Log in with Google"
          onSuccess={this.responseGoogle}
          onFailure={this.responseGoogle}
          cookiePolicy={"single_host_origin"}
        />
      );
    }

    return (
      <div>
        <li className="google-button">{googleContent}</li>
      </div>
    );
  }
}
export default Glogin;
