import React, { Component } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";
import "./profile.css";

import Select from "react-select";
import makeAnimated from "react-select/animated";

// import country from "./country";

import { user, LGspinner } from "../../../assets/images";
import axios from "../../../config/axios";
import { message } from "antd";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import { writingSvg, closeSvg } from "../../../assets/images";
import PhoneInput from 'react-phone-input-2'
import {showPhonecountry} from "../../../config/custom";

export default class CorporateProfile extends Component {
  constructor(props) {
    super(props);

    this.token = localStorage.getItem("corporate_user_token") ? localStorage.getItem("corporate_user_token") : localStorage.getItem("corporate_token");

    this.state = {
      loader: false,
      countries : {},
      city : {},
      state : {},
      country_id : "",
      state_id : "",
      profile: {
        name: "",
        email: "",
        phone: "",
        gender: "",
        country: "",
        state: "",
        city: "",
        company_logo: "",
        company_id: ""
      },
      imgLabel: "",
      profileImg: [],
      errors: [],
    };
  }

  getProfile = async () => {
    this.setState({ loader: true });
    axios.get("/api/corprate_user_profile", { headers: { "X-Auth": this.token, }, }).then((res) => {
      if (res.data.success) {
        this.setState({ loader: false });
        this.setState({ profile: res.data.data });
      }
    }).catch((err) => {
      console.log("err", err);
      this.setState({ loader: false });
      message.error("Something went wrong");
    });
  };

  handleCategories = (coaching) => {
    this.setState((prevState) => ({
      profile: {
        ...prevState.profile,
        coaching: coaching,
      },
    }));
  };


  handleChangephone = (e, data, event, formattedValue) => {
    let errors = [];
    if ((e.length - data.dialCode.length) >= 7) {
      errors["phone_no"] = "";
    } else {
      errors["phone_no"] = "Enter valid phone number";
    }
    // console.log("ajay",e);
    this.setState({ errors: errors });

    this.setState((prevState) => ({
      profile: {
        ...prevState.profile,
        ["phone"]: e,
      },
    }));
};


  handleInput = (e) => {
    e.persist();
    this.setState((prevState) => ({
      profile: {
        ...prevState.profile,
        [e.target.name]: e.target.value,
      },
    }));
  };

  updateDetails = () => {
    if(this.state.errors.length > 0) return false;
    const formData = new FormData();
  
    let { phone, gender, country, state, city, company_id } = this.state.profile;
    localStorage.getItem("corporate_userdata") && formData.append("companylogo", this.state.profileImg);
    formData.append("phone_no", phone);
    formData.append("gender", gender);
    formData.append("country", country);
    formData.append("state", state);
    formData.append("city", city);
    formData.append("company_id", company_id);
    axios.post("/api/corporate_update_profile", formData, { headers: { "X-Auth": this.token, }, }).then((res) => {
      if (res.data.success) {
        message.success("Profile updated successfully");
        this.getProfile();
        this.setState({ errors: [] });
      } else if (res.data.errors && res.data.errors.length > 0) {
        let errors = [];
        console.log(res.data.errors);
        message.error("Failed to update profile");
        res.data.errors.map((item) => {
          errors[item.param] = item.msg;
        });
        this.setState({ errors: errors });
        console.log("errors", errors);
      } else {
        message.error("Failed to update profile");
      }
    }).catch((err) => {
      message.error("Something went wrong");
      console.log("err", err);
    });
  };

  getCountryList = function(){
    this.setState({loader : true})
    axios.get("/contries").then((res) => {
      this.setState({loader : false})
      // console.log("ajay",res);
      if (res.data.success) {
        this.setState({ countries: res.data.value });
      }else {
        message.error("Failed to get countries");
        this.setState({ countries: {} });
      }
    }).catch((err) => {
      this.setState({loader : false})
      message.error("Failed to get countries");
      console.log("err", err);
    });
  }

  handleStateCity(e,key){
    this.handleInput(e);
    let currentState = this;
    setTimeout(function(){
      let val = "";
      let country_id = "";
      let url = "";
      if(key === "state"){
        val = currentState.state.profile.state;
        country_id = currentState.state.country_id ?? 223;
        url = `${key}/${val}/${country_id}`;
        let newVal = document.querySelectorAll('#stateData [value="' + val + '"]');
        if(typeof newVal[0]?.dataset?.id != "undefined"){
          console.log("newVal[0]?.dataset?.id ",newVal[0]?.dataset?.id )
          currentState.setState({ state_id: newVal[0]?.dataset?.id });
        }

      }else{
        val = currentState.state.profile.city;
        let state_id = currentState.state.state_id ?? null;
        console.log("state_id",state_id)
        url = `${key}/${val}/${null}/${state_id}`;
      }

      if(val.length < 3) {
        return false;
      }
      
      currentState.setState({loader : true})
      axios.get(`/admin/stateCityList/${url}`).then((res) => {
        currentState.setState({loader : false})
        if (res.data) {
          if(key == "state"){
            currentState.setState({ state: res.data });
          }else{
            currentState.setState({ city: res.data });
          }
          
        }else {
          // message.error("Failed to get countries");
          if(key == "state"){
            currentState.setState({ state: {} });
          }else{
            currentState.setState({ city: {} });
          }
        }
      }).catch((err) => {
        currentState.setState({loader : false})
        // message.error("Failed to get countries");
        console.log("err",err)
        if(key == "state"){
          currentState.setState({ state: {} });
        }else{
          currentState.setState({ city: {} });
        }
      });
    },100);
    
  }

  handleInputCountry(e){
    this.handleInput(e);
    this.setState((prevState) => ({
      country_id : e.target.selectedOptions[0].getAttribute('data-id')
    }));
  }

  componentDidMount() {
    this.getProfile();
    this.getCountryList();
  }

  render() {
    const { profile, errors,countries,state,city } = this.state;
    const animatedComponents = makeAnimated();

    return (
      <LoadingOverlay active={this.state.loader} className="webiner_loader" spinner={<CustomImageLoader image={LGspinner} animationType={"none"} />}>
        <div className="content_wrap">
          <div className="profile_wrap">
            <Form>
              <Row className="justify-content-center">
                <Col className="coachProfileFormEdit cpe_sm" md={6}>
                  <div className="coachImg mb-4">
                    <img id="item-img-output" src={this.state.profile?.company_logo ? this.state.profile.company_logo : user} className="img-fluid" alt="profile_image" />
                    {
                      localStorage.getItem("corporate_userdata") && (
                        <div className="picEdit">
                          <label>
                          <img width="40px" alt="LG_Img" src={writingSvg} />
                            <input className="item-img file center-block" type="file" label="Select Image file" name="avatar_path" accept=".jpg,.jpeg,.png" onChange={(e) => this.setState({ profileImg: e.target.files[0] })} />
                          </label>
                        </div>
                      )
                    }
                  </div>
                  <label className="d-flex justify-content-center">
                    {this.state.profileImg.name}
                  </label>

                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" value={(profile.firstname && profile?.lastname) ? profile?.firstname + " " + profile?.lastname : ""} placeholder="Enter name" name="name" disabled />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" value={profile?.email ? profile.email : ""} name="email" placeholder="Enter email" disabled />
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Phone number <span className="text-danger">*</span></Form.Label>
                        <div className='input-group-prepend'>

                        <PhoneInput 
                        country={"us"} 
                        onlyCountries = {showPhonecountry}
                        name='phone' 
                        required={true}  
                        countryCodeEditable={false} 
                        inputClassName="form-control phone-reg" 
                        placeholder="Phone Number" 
                        onChange={this.handleChangephone} 
                        validaterule={['required', 'phone']}
                        value = {profile?.phone} 
                        validatemsg={['Phone number is required', 'Please enter a valid phone number']} />

                          {/* <Form.Control as="select" style={{ "width": "26%" }}>
                            <option>+1</option>
                            <option>+91</option>
                          </Form.Control>
                          
                          <Form.Control type="tel" value={profile?.phone ? profile.phone : ""} required placeholder="Enter phone" name="phone" onChange={(e) => this.handleInput(e)} /> */}
                        </div>
                        <p style={{ color: "red" }}>
                          {errors["phone_no"]}
                        </p>
                      </Form.Group>
                    </Col>
                   
                  </Row>
                  <Row>
                  {
                      localStorage.getItem("corporate_user_userdata") && 
                    <Col>
                      <Form.Group>
                        <Form.Label>Gender<span className="text-danger">*</span></Form.Label>
                        <select className="form-control" name="gender" required onChange={(e) => this.handleInput(e)} value={profile.gender}>
                          <option value="">Please select one</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="others">Other</option>
                        </select>
                        <p style={{ color: "red" }}>{errors["gender"]}</p>
                      </Form.Group>
                    </Col>
                    }
                  </Row>
                  <Form.Group>
                    <Form.Label>Country <span className="text-danger">*</span></Form.Label>
                    <select type="country" value={profile.country} required name="country" className="form-control" onChange={(e) => this.handleInputCountry(e)}>
                      <option value="">Please select one</option>
                      {
                        countries.length > 0 && 
                      countries.map((item) => (
                        <option data-id={item.countries_id} key={item.countries_id} value={item.countries_name}>{item.countries_name}</option>
                        ))
                      }
                    </select>
                    <p style={{ color: "red" }}>{errors["country"]}</p>
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>State <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                          type="state" required
                          value={profile?.state !== "null" ? profile.state : ""}
                          placeholder="Enter state"
                          name="state"
                          list="stateData"
                          // onChange={(e) => this.handleInput(e)}
                          onChange = {(e)=>{
                            this.handleStateCity(e,'state');
                          }}
                        />
                        <datalist id="stateData">
                        {
                        state.length > 0 && 
                      state.map((item) => (
                        <option data-id={item.id} key={item.id} value={item.name}/>
                        ))
                      }
                        </datalist>
                        <p style={{ color: "red" }}>{errors["state"]}</p>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>City <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                          type="city" required
                          value={profile?.city !== "null" ? profile.city : ""}
                          placeholder="Enter city"
                          name="city"
                          list="cityData"
                          // onChange={(e) => this.handleInput(e)}
                          onChange = {(e)=>{
                            this.handleStateCity(e,'city');
                          }}
                        />
                        <datalist id="cityData">
                          {
                          city.length > 0 && 
                        city.map((item) => (
                          <option key={item.id} value={item.name}/>
                          ))
                        }
                        </datalist>
                        <p style={{ color: "red" }}>{errors["city"]}</p>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-center">
                      <Button className="defaultBtn" variant="default" onClick={this.updateDetails}>
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}
