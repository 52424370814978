import axios from '../config/axios'
import momentTimezone from "moment-timezone";


let curencyFilter=(price, convertTo = '',rates='')=>{
 

        let currency = price.split('##')[0]
        let amount = price.split('##')[1]
        let ConvertCurrency;

    if(convertTo != '') {
        ConvertCurrency = convertTo
    }
    else {
        ConvertCurrency = localStorage.getItem('currency')!=undefined? localStorage.getItem('currency') : 'USD'
    }
    
    
    if(rates)
    {
        rates = JSON.parse(localStorage.getItem('rates'))
    }


    

        if(currency=='USD')
         {
             if(currency==ConvertCurrency)
             {
                 return `$${Number.parseFloat(amount).toFixed(2)}`
             }
             else  if(ConvertCurrency=='GBP')
             {
                return `£${Number.parseFloat(amount/rates.USD_GBP).toFixed(2)}`
             }
             else
             {
                return `€${Number.parseFloat(amount/rates.USD_EUR).toFixed(2)}`
             }
     
         }
         else if(currency=='GBP')
         {
             if(currency==ConvertCurrency)
             {
                return `£${Number.parseFloat(amount).toFixed(2)}`
             }
             else  if(ConvertCurrency=='USD')
             {
                return `$${Number.parseFloat(amount*rates.GBP_USD).toFixed(2)}`
             }
             else
             {
                return `€${Number.parseFloat(amount*rates.GBP_EUR).toFixed(2)}`
             }
     
         }
         else 
         {
     
             if(currency==ConvertCurrency)
             {
                return `€${Number.parseFloat(amount).toFixed(2)}`
             }
             else  if(ConvertCurrency=='USD')
             {
                return `$${Number.parseFloat(amount*rates.EUR_USD).toFixed(2)}`
             }
             else
             {
                return `£${Number.parseFloat(amount/rates.EUR_GBP).toFixed(2)}`
             }
     
         }

   

}

let curencyRates=()=>{
    let rates =''
    return new Promise(async(resolve, reject) => {

            if(momentTimezone().format('YYYY-MM-DD HH')!=localStorage.getItem('ratesDate'))
            {
                axios.get('/admin/settings/currency_rates').then((res)=>{

                    if(res.data!=undefined)
            
                    {
                        localStorage.setItem('rates',JSON.stringify(res.data))
                        localStorage.setItem('ratesDate',momentTimezone().format('YYYY-MM-DD HH'))
                        resolve(res.data)
            
                    }
            
                })
                .catch(()=>{
                    
                })

            }

            if(localStorage.getItem('rates')!='' && localStorage.getItem('rates')!=undefined)
            {
                rates = JSON.parse(localStorage.getItem('rates'))

                resolve(rates)
                

            }
            else
            {
                        let res = await axios.get('/admin/settings/currency_rates')
                        if(res.data!=undefined)
                        {
                            rates = res.data
                            localStorage.setItem('rates',JSON.stringify(res.data))
                            localStorage.setItem('ratesDate',momentTimezone().format('YYYY-MM-DD HH'))
                            resolve(rates)
                        }
                        

                //  rates = {"USD_GBP":1.322096,"USD_EUR":1.127701,"GBP_USD":1.322096,"GBP_EUR":1.172381,"EUR_USD":1.127701,"EUR_GBP":1.172381}



            }

    })

    
    

}

let curencyFilterRates=(price,rates)=>{

    let currency = price.split('##')[0]
    let amount = price.split('##')[1]

    let ConvertCurrency = localStorage.getItem('currency')!=undefined? localStorage.getItem('currency') : 'USD'
    
    rates = JSON.parse(rates)

  

    if(currency=='USD')
    {
        if(currency==ConvertCurrency)
        {
            return `$${Number.parseFloat(amount).toFixed(2)}`
        }
        else  if(ConvertCurrency=='GBP')
        {
            return `£${Number.parseFloat(amount/rates.USD_GBP).toFixed(2)}`
        }
        else
        {
            return `€${Number.parseFloat(amount/rates.USD_EUR).toFixed(2)}`
        }

    }
    else if(currency=='GBP')
    {
        if(currency==ConvertCurrency)
        {
            return `£${Number.parseFloat(amount).toFixed(2)}`
        }
        else  if(ConvertCurrency=='USD')
        {
            return `$${Number.parseFloat(amount*rates.GBP_USD).toFixed(2)}`
        }
        else
        {
            return `€${Number.parseFloat(amount*rates.GBP_EUR).toFixed(2)}`
        }

    }
    else 
    {

        if(currency==ConvertCurrency)
        {
            return `€${Number.parseFloat(amount).toFixed(2)}`
        }
        else  if(ConvertCurrency=='USD')
        {
            return `$${Number.parseFloat(amount*rates.EUR_USD).toFixed(2)}`
        }
        else
        {
            return `£${Number.parseFloat(amount/rates.EUR_GBP).toFixed(2)}`
        }

    }

}

let currencySign=(curr)=>{

    if(curr=='USD')
    {
        return '$'
    }
    else if(curr=='GBP')
    {
        return '£'
    }
    else if(curr=='EUR')
    {
        return '€'
    }
    else{
        return ''
    }

}

export{
    curencyFilter,
    curencyFilterRates,
    currencySign,
    curencyRates

}