import React from "react";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import FacebookLogin from "react-facebook-login";
import axios from "../../../config/axios";

import message from '../../../utils/mesaages';

class Facebooklogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      userType: "",
      userdata: "",
      token: "",
      accessToken: " ",
    };
  }

  responseFacebook = (response) => {
    console.log("facebook", response);
    this.setState({
      // isLoggedIn: true,
      accessToken: response.accessToken,
    });
    const hideMessage = message.loading('Please wait ...', 0)
    const formData = {
      username: response.email,
      password: "",
    };
    console.log("facebook formData", formData);
    axios
      .post("/logingoogle", formData)
      .then((response) => {
        hideMessage()
        if (response.data.token !== undefined && response.data.result === true) {
          localStorage.setItem("authToken", response.data.token);
          localStorage.setItem(
            "coachingCategory",
            response.data.userdata.coaching_category
          );
          localStorage.setItem(
            "userdata",
            JSON.stringify(response.data.userdata)
          );

          this.setState({
            isLoggedIn: response.data.result,
            userType: response.data.userdata.account_type,
            userdata: response.data.userdata,
            token: response.data.token,
          });
        } else if (response.data.result === false) {
          hideMessage()
          message.error(response.data.msg);
        }
      })
      .catch((error) => {
        hideMessage()
        console.log("g error",error);
        message.error("Error logging in using facebook.");

      });
  };

  render() {
    let fbContent;

    if (this.state.isLoggedIn && !_.isEmpty(this.state.accessToken)) {
      fbContent = (
        <div>
          {this.state.userType === "coach" ? (
            <div>
              return{" "}
              <Redirect
                to={{
                  pathname: "/profile-coach",
                  state: {
                    user: this.state.userdata,
                    token: this.state.token,
                  },
                }}
              />
            </div>
          ) : (
            <div>
              return{" "}
              <Redirect
                to={{
                  pathname: "/profile-consumer",
                  state: {
                    user: this.state.userdata,
                    token: this.state.token,
                  },
                }}
              />
            </div>
          )}
        </div>
      );
    } else {
      fbContent = (
        <FacebookLogin
          appId="888466681645324"
          // autoLoad={true}
          disableMobileRedirect={true}
          fields="name,email,picture"
          callback={this.responseFacebook}
          cssClass="my-facebook-button-class"
          //icon="fa-facebook"
          icon={<i className="fa fa-facebook fa-lg" style={{marginLeft:'5px', marginRight:'10px'}}></i>}
        />
      );
    }
    return <div>{fbContent}</div>;
  }
}
export default Facebooklogin;
