import React, { Component } from "react";
import axios from "../../../config/axios";
import { Header, HeaderTop } from "../../../components/headers";
import { FooterLink } from "../../../components/footers";
import { Redirect } from "react-router-dom";
import validation from "../../../Validate/validator";

import { toast } from "react-toastify";
import message from "../../../utils/mesaages";

class SetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPass: "",
      conPass: "",
      FromDataError: {
        conPass: "false",
        newPass: "false",
      },
      redirect: false,
    };
  }

  handleChange = (e) => {
    let { FromDataError } = this.state;
    e.persist();
    var valid_obj = {
      value: e.target.value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg"),
    };
    validation(valid_obj).then((error) => {
      console.log("aaaaaaaaaaa", error);
      FromDataError[e.target.name] = error;
      this.setState({ FromDataError });

      if (
        e.target.name === "newPass" &&
        e.target.value !== this.state.conPass &&
        this.state.conPass != ""
      ) {
        FromDataError["conPass"] = "Password and confirm password do not match";
        this.setState({ FromDataError });
      } else if (
        e.target.name === "newPass" &&
        e.target.value === this.state.conPass &&
        this.state.conPass != ""
      ) {
        FromDataError["conPass"] = "";
        this.setState({ FromDataError });
      } else if (
        e.target.name === "conPass" &&
        e.target.value !== this.state.newPass &&
        this.state.newPass != ""
      ) {
        FromDataError["conPass"] = "Password and confirm password do not match";
        this.setState({ FromDataError });
      }

      setTimeout(() => {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }, 100);
    });
  };

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;
    

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {
    

        if (value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }

        valid = false;
      }
    }

    return valid;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      password: this.state.newPass,
      confirmPassword: this.state.conPass,
    };
    if (this.validateForm(this.state.FromDataError)) {
      const token = this.props.match.params.token;
      axios
        .put(`/resetpassword/${token}`, formData)
        .then((response) => {
          if (response.data.msg === "Password updated") {
            message.success("Your password has been successfully updated");
            this.setState({ conPass: '', newPass: '', redirect: true, });
            let FromDataError = this.state.FromDataError;
            FromDataError.conPass = 'false';
            FromDataError.newPass = 'false';
            this.setState({ FromDataError })
          } else if (response.data.err === "Invalid Id") {
            message.error("No account found!");
          }
        })
        .catch((err) => {
          message.error("There is some problem, pleae contact admin");
        });
    }
  };

  render() {
    const { redirect } = this.state;

     if (redirect) {
       return <Redirect to='/login'/>;
     }
    return (
      <div>
        <HeaderTop />
        <Header />
        <div className="container padding-bottom-3x mb-2 mt-5">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              <form onSubmit={this.handleSubmit}>
                <div className="forgot">
                  <h2 className="text-center">Set New Password</h2>
                  <br />
                  <br />
                  <div className="form-group">
                    <label htmlFor="password1">New Password</label>
                    <input
                      type="password"
                      style={{ color: "black" }}
                      name="newPass"
                      defaultValue={this.state.newPass}
                      onChange={this.handleChange}
                      placeholder="New Password"
                      className="form-control"
                      validaterule={["required", "password", "passpattern"]}
                      validatemsg={[
                        "Password field is required",
                        "Password length must be at least 6 characters",
                        "Password must contain at least one special character and one numeric digit",
                      ]}
                    />

                    <p style={{ color: "red" }}>
                      {this.state.FromDataError.newPass == "false"
                        ? ""
                        : this.state.FromDataError.newPass}
                    </p>
                  </div>
                  <br />
                  <div className="form-group">
                    <label htmlFor="password2">Confirm Password</label>
                    <input
                      type="password"
                      name="conPass"
                      defaultValue={this.state.conPass}
                      onChange={this.handleChange}
                      placeholder="Confirm Pasword"
                      className="form-control"
                      validaterule={[
                        "required",
                        "password",
                        "same|" + this.state.newPass,
                      ]}
                      validatemsg={[
                        "Confirm your password field",
                        "Password length must be at least 6 characters",
                        "Password and confirm password do not match",
                      ]}
                    />
                    <p style={{ color: "red" }}>
                      {this.state.FromDataError.conPass == "false"
                        ? ""
                        : this.state.FromDataError.conPass}
                    </p>
                  </div>
                  <br />
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary mb-2 text-center"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <FooterLink />
      </div>
    );
  }
}
export default SetPassword;