import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {social_link} from "../../config/custom";
import axios from "../../config/axios";

function HeaderTop(props) {
  const [showStrip,setShowStrip] = useState(false);
  const [strip,setStrip] = useState({});

  const getPromotionText = ()=>{
    axios.get('/get-promotion', {}, { headers: {} }).then((res) => {
    if(res.data.success){
                setStrip(res.data.data);
                if(res.data.data.show_top_bar_or_not == 'Y' && !localStorage.getItem("authToken")){
                  setShowStrip(true);
                }else{
                  setShowStrip(false);
                }
                
            }else{
              setStrip({});
            }
        }).catch((err) => {
          setStrip({});
            console.log("Error while fetching the plans",err)
        })
};

useEffect(()=>{
    getPromotionText();
},[])

  return (
    <section className="headerTopWrap">
      {showStrip &&
        <div className="row">
          <div className="col-12">
              <div className="bg-dark text-light text-center"  style={{"padding":"15px 61px 13px 10px","fontSize":"17px"}}>{strip?.top_bar_tagline} &nbsp;&nbsp; <span className="btn btn-info" style = {{
    "position": "absolute",
    "right": "38px",
    "top": "6px"
}} onClick={()=>{setShowStrip(!showStrip)}}>X</span></div> 
          </div>
        </div>
      }
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <ul className="socialIcon">
            {social_link.map((item,i)=>{
             return (<li key={i}>
              <a href={item.link} target="_blank">
                <i className={item.class_name} aria-hidden="true"></i>
              </a>
            </li>)
            })}
            </ul>
          </div>
          <div className="col-sm-6">
            <div className="topbarAction text-right">
            <Link to="/registration"><i className="fa fa-id-badge"></i> Join As Coach</Link>
            <Link to="/login"><i className="fa fa-user"></i> &nbsp;Login</Link>
            <Link className="btn btn-default defaultBtn" style={{"padding":"10px 10px 10px 0px"}} to="/user_registration"><span className="fa fa-id-badge"></span> Sign Up</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default HeaderTop;
