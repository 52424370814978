import React from 'react'
import { Row, Col, Card, Button, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { Header, HeaderLogout, HeaderTop } from "../components/headers";
import { FooterLink } from "../components/footers";
import HeaderSecondTo from '../components/corporate/Header/HeaderSecondTo';
import CoachingAreas from '../views/landing/Coachingarea';
import axios from '../config/axios';
import { message } from 'antd';

import CustomImageLoader from "react-custom-image-loader.";
import LoadingOverlay from 'react-loading-overlay';
import { LGspinner } from '../assets/images';


class Categories extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            coaches_item: [],
            loader: false,
            prevScrollpos: 500,
            visible: true,
            category_content: "",
            height: 0,
            coaches: [],
        };
    }

    getHomeContent = () => {
        this.setState({ loader: true });
        let self = this;
        axios
            .get("/cmshome/homeContent")
            .then(async (response) => {
                if (response.status === 200) {
                    self.setState({ coaches_item: response.data.coaches_item, loader: false, category_content: response.data.category_content });
                } else {
                    self.setState({ loader: false });
                    message.error("Error response");
                    console.log("error response", response);
                }
            })
            .catch((e) => {
                message.error("Error while fetching data");
                console.log(e);
                self.setState({ loader: false });
            });
    };

    componentDidMount() {
        this.getHomeContent();
    }

    render() {
        return (
            <>
                {
                    !localStorage.getItem("subdomain") && (localStorage.getItem("authToken") == null ? (<HeaderTop />) : (<HeaderLogout />))
                }
                {
                    localStorage.getItem("subdomain") ? (<HeaderSecondTo />) : (<Header />)
                }
                <LoadingOverlay active={this.state.loader} className="webiner_loader" spinner={<CustomImageLoader image={LGspinner} animationType={"none"} />}>
                    <div className="programs">
                        <Container>
                            <Row>
                                <Col md={8} className="mb-4">
                                    <div className='cs_heading'>
                                        <h2 className="cs_title">Categories</h2>
                                        <p>{this.state.category_content}</p>
                                    </div>
                                </Col>
                                <Col md={4} className='text-right'>
                                    {/* <Link className="btn defaultBtn" to="/webinars-category">Explore</Link> */}
                                </Col>
                            </Row>
                            <CoachingAreas coachingareadata={this.state.coaches_item} />
                        </Container>
                    </div>
                    <FooterLink />
                </LoadingOverlay>
            </>
        )
    }
}

export default Categories