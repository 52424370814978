import React from "react";
import moment from 'moment';
import { logo, LGspinner } from "../../assets/images";
import { Row, Col, Container } from 'react-bootstrap'
import axios from "../../config/axios";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";
import { Link } from "react-router-dom";


const timeFormat = "DD-MM-YYYY, hh:mm:ss a";

class NextMasterCoachWebinars extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      upcomaingwebinarcategory: "",
      upcomingwebinarDeta: [],
      loader: false,
      // TIMER
      timerOne: {},
    }

  }

  componentDidMount() {
    this.getUpcomingWebinar()
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    if (this.timerOne) {
      clearInterval(this.timerOne);
    }
  }

  getTime = (deadline) => {
    let currentDate = moment.tz("America/New_York");
    const startDate = new Date(currentDate); 
    const endDate = new Date(deadline); 
    const timeDifference = endDate - startDate;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  saveTimer = (deadline, position, isFirst = true) => {
    let { days, hours, minutes, seconds } = this.getTime(deadline);
    let { current_webinars } = this.state;

    if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
      current_webinars[position].isStart = true;
      if (isFirst) {
        if (this.timerOne) {
          clearInterval(this.timerOne);
        }
      } else {
        if (this.timerTwo) {
          clearInterval(this.timerTwo);
        }
      }
      this.setState({ current_webinars });
    }

    if (isFirst) {
      let timerOne = {
        days,
        hours,
        minutes,
        seconds,
      };
      this.setState({ timerOne });
    }
  };

  getUpcomingWebinar = (e) => {
    this.setState({ loader: true })
    let url = `/webinar/all-upcoming-webinars?page=1&limit=1&category=${this.state.upcomaingwebinarcategory}`;
    if(localStorage.getItem("ML_TOKEN")){
      url = `${url}&type=2`;
    }else{
        url = `${url}&type=1`;
    }
    axios.get(`${url}`).then(async (responce) => {
      if (responce.data.success) {
        let webinar = responce.data.webinars[0];
        if(!webinar) {
          this.setState({ loader: false, upcomingmsg: responce.data.msg, upcomingwebinarDeta: [] })
          return ;
        } 
        let deadline = moment.utc(webinar.date_string).tz("America/New_York");

        let times = this.getTime(deadline);
        let isStart = times && times.days <= 0 && times.hours <= 0 && times.minutes <= 0 && times.seconds <= 0 ? true : false;
        this.timerOne = setInterval(() => {
          this.saveTimer(deadline, 0);
        }, 1000);
        webinar.isStart = isStart;
        
        this.setState({ loader: false, upcomingmsg: responce.data.msg, upcomingwebinarDeta: [webinar] })
      }
    }).catch((e) => {
      this.setState({ loader: false })
      console.log(e)
    })
  }

  render() {
    const { days, hours, minutes, seconds, loader, timerOne, upcomingwebinarDeta } = this.state;
    return (
      <>
        <LoadingOverlay active={loader} spinner={<CustomImageLoader image={LGspinner} animationType={"pulse"} />} className="webiner_loader" >
          <section className="webinnerWrap">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <div className="webinnerContent">
                    <div className="heading mb-4 text-center">
                      <h3>NEXT <span>LIVE WEBINAR</span></h3>
                      {
                        localStorage.getItem("subdomain") ? (
                          <p>Employees have exclusive access to world-class coaches who share their expertise through their webinars</p>
                        ) : (
                          <p>Members have exclusive access to world-class coaches. Get real-time help to change and improve your life today</p>
                        )
                      }
                    </div>
                    <div className="webinerStart text-dark">
                      <Row className="align-items-center">
                      <Col md={6}>
                        {upcomingwebinarDeta.length > 0  && upcomingwebinarDeta[0].image ? (
                          <div className="webiner_card" style={{ background: `url(${upcomingwebinarDeta[0].image})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundAttachment: 'local' }}>
                            <div className="webiner_card_content text-light">
                              {upcomingwebinarDeta.length > 0 ? (
                                <>
                                  <img width="60" height="60" src={upcomingwebinarDeta[0]?.coach_avatar || logo} className="img-fluid rounded-circle" alt={upcomingwebinarDeta[0].name} />
                                  <h4 className="webiner_coach_name mt-2">{upcomingwebinarDeta[0].name}</h4>
                                  <p className="webiner_coach_details">{upcomingwebinarDeta[0].coach_bio?.substring(0, 60)}</p>
                                  <h3 className="webiner_name" title="Healing your mind">{upcomingwebinarDeta[0].webinar_topic}</h3>
                                  <p className="webinar_description">
  <span>
      {upcomingwebinarDeta[0].script.substring(0, 90)}
      <a href={`/webinars-details/${upcomingwebinarDeta[0].coach_uid}/${upcomingwebinarDeta[0].id}`} style={{ color: "#00e1ba" }}>[...]</a>
    </span>
</p>
                                  <div className="text-uppercase">
                                    {/* <small style={{ fontSize: '0.7rem' }}>
                                      {moment(upcomingwebinarDeta[0].date_time).format("D MMM, YYYY - h:mm A")}</small> */}
                                      <small style={{ fontSize: '0.7rem' }}> {moment.utc(upcomingwebinarDeta[0].date_string).tz("America/New_York").format("D MMM, YYYY - h:mm A")}  <b>EST</b> </small>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="webiner_card" style={{ background: 'url(https://lifeguru-prod1.s3.us-east-2.amazonaws.com/lifeguruadmin/poKK7JaRDPxfBW11Yrj4yeg8Jx6epJxFBymnbuRe.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundAttachment: 'local' }}>
                            <div className="webiner_card_content text-light">
                              { upcomingwebinarDeta.length > 0 ? <> 
                              <img width="60" height="60" src={upcomingwebinarDeta[0]?.coach_avatar || logo} className="img-fluid rounded-circle" alt={upcomingwebinarDeta[0].name} />
                              <h4 className="webiner_coach_name mt-2"> {upcomingwebinarDeta[0].name}</h4>
                              <p className="webiner_coach_details"> {upcomingwebinarDeta[0].coach_bio?.substring(0, 60)} </p>
                              <h3 className="webiner_name" title="Healing your mind"> {upcomingwebinarDeta[0].webinar_topic} </h3>
                              <p className="webinar_description"> {upcomingwebinarDeta[0].script} 
                              <br />
                              <a href={`/webinars/webinars-details/${upcomingwebinarDeta[0].coach_uid}/${upcomingwebinarDeta[0].id}`} 
                              className="btn defaultBtn btn-bordered"
                              >View More</a>
                              </p>
                              <div className="text-uppercase">
                                <small style={{ fontSize: '0.7rem' }}> {moment.utc(upcomingwebinarDeta[0].date_string).tz("America/New_York").format("D MMM, YYYY - h:mm A")}  <b>EST</b> </small>
                              </div>
                              </>
                            : "" }
                            </div>
                          </div>
                        )}
                      </Col>
                      <Col md={6}>
                        {upcomingwebinarDeta.length > 0 ? (
                          <div className='text-center'>
                            <div className="heading">
                              <h3>Webinar Starts In</h3>
                            </div>
                            <div className="countdown-container d-flex justify-content-center">
                            <div className="countdown_wrap d-flex justify-content-center">
                                <div className="countdown_number">
                                  <span className="count_up">{timerOne.days}</span>
                                  <small className="text-uppercase">DAYS</small>
                                </div>
                                <div className="countdown_number">
                                  <span className="count_up">{timerOne.hours}</span>
                                  <small className="text-uppercase">HRS</small>
                                </div>
                                <div className="countdown_number">
                                  <span className="count_up">{timerOne.minutes}</span>
                                  <small className="text-uppercase">MINS</small>
                                </div>
                                <div className="countdown_number">
                                  <span className="count_up">{timerOne.seconds}</span>
                                  <small className="text-uppercase">SECS</small>
                                </div>
                              </div>
                            </div>
                            <Link to="/user_registration" className='btn defaultBtn'>Join Now</Link>
                          </div>
                        ) : (
                          <div className='text-center'>
                            <div className="heading">
                              <h3>Next Live Webinars To Be Announced Shortly</h3>
                              <br />
                              <h3>Please stay tuned...</h3>
                            </div>
                          </div>
                        )}
                      </Col>

                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </LoadingOverlay>
      </>
    );
  }
}
export default NextMasterCoachWebinars;
