import React from "react";
import {
  dArrowblack,
  teamSvg,
  sortSvg,
  LGspinner,
} from "../../../assets/images";

import axios from "../../../config/axios";
import { curencyFilter,curencyFilterRates,currencySign,curencyRates } from "../../../utils/currencyConvert";
import Loader from "react-loader-spinner";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";



var _ = require("lodash");
//import { json } from 'express';
class PaymentReceived extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentDetails: [],
      loading: true,
      //limit: 10,
      limit: 10,
      maxPageNumberLimit:4,
      minPageNumberLimit:0,
      pageNumberLimit:4,
      offset: 1,
      totalrow: 0,
      sort: "order_desc",
      rates:''
    };
  }
  componentDidMount() {
    this.ApiCall();
  }

  ApiCall = async () => {
    let { limit, offset, sort } = this.state;
    let rates = await curencyRates()
    this.setState({rates})
    this.setState({ loading: true });
    axios
      .get(
        `/payment/coachPaymenthistory?limit=${limit}&offset=${offset}&sort=${sort}`,
        {
          headers: { "x-auth": this.props.token },
        }
      )
      .then((res) => {
        this.setState({ loading: false });
        console.log("transaction---res", res);
        if (res.data.success) {
          const data = res.data.value;
          
          this.setState({ paymentDetails: data })
          this.setState({ totalrow: res.data.totalrow });

          
          // this.setState({ paymentDetails: data.sort((item1, item2) => item1.pid > item2.pid? -1: 1 ) });
          
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  // handlePrevious = () => {
  //   console.log("clickesss");
  //   if (this.state.offset > 0) {
  //     this.setState({ offset: this.state.offset - 1 }, () => {
  //       this.ApiCall();
  //     });
  //   }
  // };

  handleNext = async() => {
    // this.setState({ offset: this.state.offset + 1 }, () => {
    //   this.ApiCall();

    // });
    let {offset,maxPageNumberLimit,minPageNumberLimit,limit,pageNumberLimit}=this.state
    let offsetChange=parseInt(this.state.offset)+1
    await this.setState({offset: offsetChange});
   
    if (parseInt(this.state.offset) + 1 > this.state.maxPageNumberLimit) {
        

        if(this.state.offset<Math.ceil(this.state.totalrow/this.state.limit)){
         
        this.setState({maxPageNumberLimit:maxPageNumberLimit + pageNumberLimit});
        this.setState({minPageNumberLimit:minPageNumberLimit + pageNumberLimit});
        }
      
    }
    this.ApiCall();
  };

  handlePageChange(pageNumber) {
    this.setState({ offset: pageNumber }, () => {
       this.ApiCall();
      
     });
    }
  handlePrevious = async() => {
    
    let {offset,maxPageNumberLimit,minPageNumberLimit,limit,pageNumberLimit}=this.state
    if (this.state.offset > 0) {
          this.setState({ offset: parseInt(this.state.offset) - 1 }, () => {
            this.ApiCall();
          });
        }
    //await this.setState({offset:offset - 1});

    if ((parseInt(offset) - 1) % pageNumberLimit == 0) {
     
      this.setState({maxPageNumberLimit:maxPageNumberLimit - pageNumberLimit});
      this.setState({minPageNumberLimit:minPageNumberLimit - pageNumberLimit});
    }
   
  }

  handleClick = (e) => {
    console.log("e", e.target.getAttribute("id"));
    this.setState({ offset: e.target.getAttribute("id") }, () => {
      this.ApiCall();
    });
  };
  handaleChange = (e) => {
    this.setState({ sort: e.target.value }, () => {
      this.ApiCall();
    });
  };

  paginate = (pagenumber) => {
    this.setState({ pageNumber: pagenumber });
  };

  render() {
    console.log("this.state.-----", this.state.paymentDetails);
    const pageNumbers = [];
    const showpageNumbers = Math.ceil(this.state.totalrow/3);
    
    for (
      let i = 1;
      i <= Math.ceil(this.state.totalrow / this.state.limit);
      i++
    ) {
      pageNumbers.push(i);
     
    }
   
    return (
      <div className="col-md-12">
        <LoadingOverlay
          active={this.state.loading}
          // spinner={<BounceLoader />}
          spinner={
            <div>
              <CustomImageLoader image={LGspinner} animationType={"pulse"} />
              <br />
            </div>
          }
          text="Loading your Payment Received..."
        >
          <div>
            <div className="searchHead">
              <div className="searchHeading">
                <h3>
                  <img width="22px" src={teamSvg} alt="LG-Img" />
                  Payments Received
                </h3>
              </div>

              <div className="searchShortBy" style={{ cursor: "pointer" }}>
                <img width="15px" src={sortSvg} alt="LG_Img" />
                <select
                  onChange={this.handaleChange}
                  style={{ cursor: "pointer" }}
                >
                  <option>--- Sort by ---</option>
                  <option
                    selected={this.state.sort == "asc" ? true : false}
                    value="asc"
                  >
                    A-Z
                  </option>
                  <option
                    selected={this.state.sort == "desc" ? true : false}
                    value="desc"
                  >
                    Z-A
                  </option>
                  <option
                    selected={this.state.sort == "order_asc" ? true : false}
                    value="order_asc"
                  >
                    Order No.(asc)
                  </option>
                  <option
                    selected={this.state.sort == "order_desc" ? true : false}
                    value="order_desc"
                  >
                    Order No.(desc)
                  </option>
                </select>
                <span>
                  <img
                    src={dArrowblack}
                    alt="LG_Img"
                    style={{ cursor: "pointer" }}
                  />
                </span>
              </div>
            </div>

            <div className="userBookingList ">
              {this.state.paymentDetails.map((payment) => {
                let longDescription = payment.long_description!= null ? JSON.parse(`${payment.long_description}`) :"";
                let rateOfExchange = longDescription != "" && longDescription.exchange_rate !== null ? longDescription.exchange_rate : 0.00;

                // COACH RECEIVED AMOUNT IN COACH PAYOUT CURRENCY
                let coachCommissionAmount = Number(payment.user_commision_amount)
                let inWalletAmount = rateOfExchange !== 0.00 ? (coachCommissionAmount * rateOfExchange) : coachCommissionAmount;
                // inWalletAmount = `${currencySign(longDescription.currency.toUpperCase())}${parseFloat(inWalletAmount).toFixed(2)}`;
                inWalletAmount = longDescription != "" ? 
                          `${currencySign(longDescription.currency.toUpperCase())}${parseFloat(inWalletAmount).toFixed(2)}` 
                          :
                          `${currencySign(payment.currency.toUpperCase())}${parseFloat(inWalletAmount).toFixed(2)}`;

                // CURRENCY EXCHANGE RATE
                let exchangeRate = rateOfExchange !== 0.00 ?
                `${currencySign(longDescription.currency.toUpperCase())}${longDescription.exchange_rate}`                        
                :
                "0.00";

                // let packagedetails =   payment.package_details === null ? null:JSON.parse(payment.package_details)
                let transactionFeeDetails = payment.long_description !== undefined && payment.long_description !== null ? JSON.parse(payment.long_description) : "";
                let transactionFee = transactionFeeDetails !== "" ? transactionFeeDetails.fee/100 : 0.00;
                let transactionFeeCurrency = transactionFeeDetails !== "" ? transactionFeeDetails.currency : "usd";
                let refDetails=payment.description!=''?payment.description.split("."):''
                let Coach = payment.coachDetails;
                let User = payment.userDetails;
                let packDetails = null;
                let SessionName = "";
                let packageDetails = null;
                let purchaSession = null;
                let sessionDetails = "";
                let sessionDisplayName = "";
                if (
                  payment.packDetails !== null ||
                  payment.packDetails !== undefined
                ) {
                  packDetails = payment.packDetails[0];
                  SessionName = packDetails.title;
                } else {
                  packDetails = JSON.parse(payment.package_details);
                  SessionName = packDetails.session_name;
                }

                if (
                  payment.packageDetails !== null ||
                  payment.packageDetails !== undefined
                ) {
                  packageDetails = JSON.parse(payment.packageDetails);
                  purchaSession = JSON.parse(packageDetails[0].session_details);
                  console.log("hiiiiiii", purchaSession);
                  let sessionfind =
                    payment.sessionNo !== null
                      ? payment.sessionNo.slice(7)
                      : null;

                  sessionDetails = purchaSession.filter((each, i) => {
                    return each.id == sessionfind;
                  });
                  sessionDisplayName =
                    sessionDetails != undefined && sessionDetails.length > 0
                      ? sessionDetails[0].sessionName
                      : "";

                  sessionDisplayName =
                    sessionDisplayName == SessionName ? "" : sessionDisplayName;
                  console.log("hiiiiiii nn", sessionDetails);
                }
                return (
                  <>
                    {User !== null ? (
                      <div className="bookingCocachDetails payment_details_tab paymentrecieved_coach payment_details_coach">
                        <ul>
                          <li>
                            <b>Order No.</b>{" "}
                            <span>{`${payment.order_id}`.padStart(5, "0")}</span>
                            {/* {refDetails!='' && (<>
                            <p style={{fontSize:"10px" , fontWeight:"bold" ,marginBottom:"4px", whiteSpace: "nowrap"}}>({refDetails[0].trim()} :
                         {refDetails[1].trim()})</p>
                            </>)} */}
                          </li>
                          <li>
                            <b>Session Fee</b>{" "}
                            <span>
                            {payment.total_amount ?
                                // ? curencyFilterRates(
                                //     `${payment.currency}##${payment.total_amount}`,
                                //       payment.currency_rates
                                //   )
                                  `${currencySign(payment.paid_currency)}${parseFloat(payment.total_amount).toFixed(2)}`
                                : ""}
                            </span>
                          </li>

                          {/* <li>
                            <b>Package and Session details</b>{" "}
                            <span>
                              {SessionName === "" ? "" : SessionName}{" "}
                              {sessionDisplayName != ""
                                ? "- " + sessionDisplayName
                                : ""}
                            </span>
                          </li> */}
                          <li>
                            <b>LifeGuru Commission</b> 
                            <span>
                              {payment.user_commision_amount ?
                                //  curencyFilterRates(
                                //     `${payment.currency}##${payment.admin_commission_amount}`,
                                //       payment.currency_rates
                                //   )
                                  // `${currencySign(payment.paid_currency)}${payment.admin_commission_amount}`
                                  curencyFilter(
                                    `${payment.paid_currency.toUpperCase()}##${payment.admin_commission_amount}`, "USD",this.state.rates
                                  )
                                : ""}
                            </span>
                            </li>
                            {/* <li>
                            <b>Transaction Fee</b> 
                            <span>
                              {payment.user_commision_amount ?
                                //  curencyFilterRates(
                                //     `${payment.currency}##${payment.admin_commission_amount}`,
                                //       payment.currency_rates
                                //   )
                                `${curencyFilter(
                                  `${transactionFeeCurrency.toUpperCase()}##${transactionFee}`, "USD"
                                  )}`
                                : ""}
                            </span>
                            </li> */}
                            <li>
                            <b>Exchange Rate</b>{" "}
                            <span>
                              {exchangeRate}
                            </span>
                          </li>
                          <li>
                            <b>Total Received</b>{" "}
                            <span>
                              {payment.user_commision_amount ?
                                //  curencyFilterRates(
                                //     `${payment.currency}##${payment.user_commision_amount}`,
                                //       payment.currency_rates
                                //   )
                                  // `${currencySign(payment.paid_currency)}${payment.user_commision_amount}`
                                  inWalletAmount
                                : ""}
                            </span>
                          </li>

                          {/* {payment.has_paid === "1" ? (
                            <li>
                              <b>Payment Status</b>{" "}
                              <span className="badge text-white  badge-success">
                                IN Wallet
                              </span>
                            </li>
                          ) : (
                            <li style={{flex:"0 0 15%"}}>
                              <b>Payment Status</b>{" "}
                              <span className="badge text-white text-  badge-danger">
                                Pending
                              </span>
                            </li>
                          )} */}
                          {payment.payment_status === "succeeded" ||
                    payment.payment_status.search("Approved") >= 0 ? (
                            <li style={{flex:"0 0 15%"}}>
                              <b>Payment Status</b>{" "}
                              <span className="badge text-white  badge-success">
                                Success
                              </span>
                            </li>
                          ) : (
                            <li style={{flex:"0 0 15%"}}>
                              <b>Payment Status</b>{" "}
                              <span className="badge text-white text-  badge-danger">
                                Cancelled
                              </span>
                            </li>
                          )}
                              <li className="user-transaction-refNo">
                                          {refDetails!='' && (<>
                                              <p style={{fontSize:"10px" , fontWeight:"bold" ,marginBottom:"4px",whiteSpace: "nowrap"}}>({refDetails[0].trim()} :{refDetails[1].trim()})</p>
                                            </>)}
                            </li>
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
              {this.state.paymentDetails.length <= 0  ? (
                <>
                  <div className="bookingCocachDetails">
                    <ul>
                      <li style={{ flex: "0 0 100%", textAlign: "center" }}>
                        <b>No Records Found</b>
                      </li>
                    </ul>
                  </div>
                </>
              ) : (
                <></>
              )}
           
              <div
                className="text-center"
                style={{ color: "#776969", cursor: "pointer" }}
              >
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center my-5">
                    {pageNumbers.length > 1 && this.state.offset > 1 && (
                      <>
                        <li className="page-item">
                          <a
                            className="page-link"
                            aria-label="Previous"
                            onClick={this.handlePrevious}
                          >
                            <i
                              className="fa fa-angle-left"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                      </>
                    )}
                    {pageNumbers.map((number) => {
                     
                      if (number < this.state.maxPageNumberLimit + 1 && number > this.state.minPageNumberLimit) {
                      
                      return (
                        <li className="page-item">
                          <a
                            className={
                              this.state.offset == number
                                ? "page-link  active"
                                : "page-link "
                            }
                            key={number}
                            id={number}
                            onClick={this.handleClick}
                          >
                            {number}
                           
                          </a>
                        </li>
                      );
                    } else {
                      return null;
                    }

                    })}
                    

                    {pageNumbers.length > 1 &&
                      this.state.offset < pageNumbers.length && (
                        <>
                         <div>{pageNumbers.length>this.state.limit?"..":""}</div>
                          <li className="page-item">
                            <a
                              className="page-link"
                              aria-label="Next"
                              onClick={this.handleNext}
                             
                            >
                              <i
                                className="fa fa-angle-right"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </>
                      )}

                   
                  </ul>
                </nav>
              </div>

            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default PaymentReceived;
