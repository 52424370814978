import React, { Component } from "react";
import axios from "../../config/axios";
import { toast } from "react-toastify";
import { contactSvg } from "../../assets/images";
import message from '../../utils/mesaages';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      user_email: "",
      phone_number: "",
      message: "",
      coach_email: this.props.email,
      loading: false,
      url: "",
      countryCode: "971",
      country: [],
    };
  }

  componentDidMount() {
    axios.get("/contries").then((response) => {
      if (response.data.value && response.data.value.length > 0) {
        this.setState({
          country: response.data.value,
        });
      }
    });
  }

  resetField() {
    this.setState({
      // name: "",
      // user_email: "",
      // phone_number: "",
      message: "",
      loading: false,
    });
  }

  handleChange = (e) => {
    e.persist();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmitContact = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    let phoneData =
      "+" + this.state.countryCode + "##" + this.state.phone_number;
    let phoneNumber = phoneData.replace(/##/g, " ");
    const formData = {
      name: this.state.name,
      user_email: this.state.user_email,
      phone_number: phoneNumber,
      message: this.state.message,
      coach_email: this.state.coach_email,
    };

    if (
      this.state.name.length > 0 &&
      this.state.message.length > 0 &&
      this.state.user_email.length > 0
    ) {
      if (
        this.state.user_email.split("").filter((x) => x === "@").length !== 1
      ) {
        message.warning("Email should contain a @");
      }

      axios
        .post("users/contact", formData, {
          headers: {
            "X-Auth": this.props.token,
          },
        })
        .then((res) => {
          if (res.data.result) {
            this.resetField();
            message.success("Your mail has been sent successfully");
          } else {
            message.warning("Not able to send your mail !");
          }
        })
        .catch((error) => {
          message.error("there was an error !!!");
        });
    }
  };

  handleClick = (name) => {
    const formData = {
      medium_name: name,
      url: this.state.url,
    };
    axios
      .post("/users/social/socialDetails", formData, {
        headers: { "x-auth": this.props.token },
      })
      .then((response) => {
        if (response.data.success) {
          message.success(response.data.msg);
        } else {
          message.error(response.data.msg);
        }
        this.handleReset();
      })
      .catch((error) => {
        message.error("there was an error !!!");
      });
  };

  handleReset() {
    this.setState({ url: "" });
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <section className="contactWrap profileContact">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-4 p-0 ">
                  <img
                    className="contact-icon"
                    src={contactSvg}
                    alt="LG-Img"
                    width="100%"
                  />
                </div>
                <div className="col-md-5">
                  <div className="heading text-center">
                    <h3>Contact Me</h3>
                    <span className="R_divider"></span>
                  </div>

                  <form
                    className="pr-4 mb-5"
                    action=""
                    method="get"
                    accept-charset="utf-8"
                    onSubmit={this.handleSubmitContact}
                  >
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Full Name"
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.name}
                        required={true}
                        name="name"
                      />
                    </div>

                    <div className="form-group">
                      {/* <input
                        type="tel"
                        placeholder="Phone Number"
                        pattern="[0123456789][0-9]{9}"
                        className="form-control"
                        name="phone_number"
                        onChange={this.handleChange}
                        value={this.state.phone_number}
                      /> */}
                      <PhoneInput
                        country={this.state.countryName}
                        countryCodeEditable={true}
                        inputClass="form-control"
                        placeholder="Enter Your Phone Number(Optional)..."
                        value={this.state.phone_number}
                        onChange={phone => this.setState({ phone_number:phone })}
                      />
                    </div>

                    <div className="form-group">
                      <input
                        type="email"
                        placeholder="Email Address"
                        className="form-control"
                        name="user_email"
                        onChange={this.handleChange}
                        value={this.state.user_email}
                        required={true}
                      />

                      {this.state.user_email.length > 1 &&
                        this.state.user_email.split("").filter((x) => x === "@")
                          .length !== 1 && (
                          <span style={{ color: "red" }}>
                            Email should contain a @ and at least one dot.{" "}
                          </span>
                        )}
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        placeholder="Enter your message"
                        name="message"
                        onChange={this.handleChange}
                        value={this.state.message}
                        required={true}
                      ></textarea>
                    </div>
                    <button className="defaultBtn" type="submit">
                      SEND
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}
export default Contact;
