import React from "react";
import Autosuggest from 'react-autosuggest';
import axios from "../../config/axios";


// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}



function getSuggestionValue(suggestion) {
  return suggestion.name;
}

function renderSuggestion(suggestion) {
  return (
    <span>{suggestion.name}</span>
  );
}

class MyAutosuggest extends React.Component {
  constructor() {
    super();

    this.state = {
      value: '',
      suggestions: [],
      currentField: '',
    };    
  }


getSuggestions(value) {

  let promise = new Promise((resolve, reject) => {
    const escapedValue = escapeRegexCharacters(value.trim());
  
      if (escapedValue === '') {
        return [];
      }

      const regex = new RegExp('^' + escapedValue, 'i');

      if(this.state.currentField == 'state-input'){
        axios.get('/admin/stateCityList/state/'+value).then((ret)=>{
          
                let stateSuggestions = ret.data;
                resolve(stateSuggestions.slice(0,5));

        }).catch((err)=>{
          console.log('stateFetch Err',err)
        })
        // let stateSuggestions = allStateList.filter(language => regex.test(language.name));
        // return stateSuggestions.slice(0,5)
      } else {
        // let citySuggestion = allCityList.filter(language => regex.test(language.name));
        // return citySuggestion.slice(0,5)
        axios.get('/admin/stateCityList/city/'+value).then((ret)=>{
          let citySuggestion = ret.data
          return  resolve(citySuggestion.slice(0,5));
        }).catch((err)=>{
          console.log('cityFetch Err',err)
        })
        
      }


  })
  return promise;
  
}

componentDidUpdate(prevProps) {
  // Typical usage (don't forget to compare props):
  if (this.props.defaultFieldValue !== prevProps.defaultFieldValue) {
    this.setState({value: this.props.defaultFieldValue})
    //this.mergedCoachList(this.props.ListCoaches);
  }
}

  onChange = (_, { newValue }) => {
    const { id, handleStateCity } = this.props;
    this.setState({
      value: newValue,
      currentField: id
    });
    
    handleStateCity(id, newValue);
  };
  
  onSuggestionsFetchRequested = async ({ value }) => {
    this.setState({
      suggestions: await this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { id, placeholder } = this.props;
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder,
      value: value !== undefined ? value : '',
      onChange: this.onChange,
      style: {color:"black"},
      className:"form-control",
    };

    const containerProps = {
      style: {color:"black", border:'black'}
    }
    
    return (
      <Autosuggest 
        id={id}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        containerProps={containerProps} 
      />
    );
  }
}

export default MyAutosuggest;