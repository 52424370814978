import React, { Component } from "react";
import { Link } from "react-router-dom";
import { img01, blog1, blog2, dIcon, LGspinner } from "../../assets/images";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from 'react-custom-image-loader.'
import {blogUrl} from "../../config/axios";
class Blog extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      articlesData: [],
      loader: false,
    }
  }

  createMarkup = (data) => {
    return {__html: data};
  }
  componentDidMount() {
    console.log('blog_id ',this.props.blog_id)

    this.setState({loader: true})
    // fetch('https://blog.lifeguru.me/wp-json/wp/v2/posts?order=desc&orderby=date&_embed')
    fetch(blogUrl+'/wp-json/wp/v2/posts?order=desc&orderby=date&per_page=3&_embed&author='+this.props.blog_id)
    .then(res => res.json())
    .then((data) => {
      this.setState({ articlesData: data, loader: false })
      console.log(this.state.articlesData)
    })
    .catch((e) => {
      console.log("ARTICLES FETCH ERROR",e)
      this.setState({loader: false})
    })
  }

  render() {
    return (
      
        <section className="blogWrap coachBlog">
        <LoadingOverlay active={this.state.loader}
        spinner={<CustomImageLoader image={LGspinner} animationType={'none'}/>} 
        //spinner
        >
          <div className="container text-center">
            {/* <h3>NEWS / ARTICLES</h3>
            <span className="R_divider"></span> */}

            <div className="row">
            {this.state.articlesData.slice(0,3).map(article =>{

          return(
              <div className="col-lg-4 col-sm-6">
                <a href={article.link} style={{textDecoration: "none"}} target="_blank">
                <div className="blogContent">
                  {/* <img src={blog1} className="img-fluid" alt="LG-Img"/> */}
                  <img alt="LG-Img" style={{height:"232px"}} className="my-2" src={article._embedded["wp:featuredmedia"] === undefined ? blog1 : article._embedded["wp:featuredmedia"][0].source_url} />
                  <div className="bInfo">
                  <h5 style={{color:"black"}}>{`${article.title.rendered.substring(0, 40)}...`}</h5>
                 
                  {/* <p>{article.excerpt.rendered}</p> */}
                  <div dangerouslySetInnerHTML={this.createMarkup(`${article.excerpt.rendered.substring(0,85)}...`)}/>
                  </div>
                  <div className="bFooter">
                    <p>
                      <i className="color-main fa fa-user "></i>
                      <span>{article._embedded.author[0].name}</span>
                    </p>
                    <p>
                      <i className="color-main fa fa-calendar"></i>
                      <span>{article.date.slice(0,article.date.indexOf('T'))}</span>
                    </p>
                    <p>
                      <i className="color-main fa fa-tag"></i>
                      <span>Post</span>
                    </p>
                  </div>
                </div>
                </a>
              </div>
              
              )
            })}

                    {(this.state.articlesData.length<=0 && this.state.loader == false)?(<>
                                      {/* <div className="bookingCocachDetails ml-5">
                                        <ul>
                                          <li><b>No blog posts uploaded by coach</b></li>
                                        </ul>
                                    </div> */}
                                    <div className="col-md-4 card p-3 m-auto">
                                          <h4 style={{color:'#000'}}>No blog posts uploaded by coach</h4>
                                       </div>
                                    
                                    </>):(<></>)}
    

              <div
                className="text-center col-12 mt-5"
                style={{ display: "none" }}
              >
                <Link className="defaultBtn" to="comming-soon">
                  View more Articles
                </Link>
              </div>
            </div>
          </div>
          </LoadingOverlay>
        </section>
      
    );
  }
}

export default Blog;
