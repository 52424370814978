import React from "react";
import { Modal, Form, Col, Row, Button } from "react-bootstrap";
import { Tooltip } from 'antd';
import validation from '../../../Validate/validator';
import message from '../../../utils/mesaages';
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from 'react-custom-image-loader.'
import { LGspinner } from "../../../assets/images";
import axios from '../../../config/axios'

class EditPackageRequest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      subject: '',
      description: '',
      loader: false,

      FromDataError: {
        subject: 'false',
        description:'false',
      },
    }
  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;
    //valid = this.validateForm2(this.state.session_details_listValid)

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {

        if (value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }

        valid = false;
      }
    }

    return valid;
  };

  handleClose = async() => {
    this.setState({
      subject: '',
      description: '',
      FromDataError: {
        subject: 'false',
        description:'false',
      },
    })
    this.props.handleEditRequestShow()
  }

  handleChange = (e) => {
    e.persist();
    this.setState({
      [e.target.name]: e.target.value,
    });

    var valid_obj = {
      value: e.target.value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg")
    }
    let {FromDataError} =this.state


    validation(valid_obj).then((error) => {


    
      FromDataError[e.target.name] = error
      this.setState({ FromDataError })

    })
  };

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.validateForm(this.state.FromDataError)){
      this.setState({loader: true})
      const formData = {
        subject: this.state.subject,
        description: this.state.description,
        packageId: this.props.pack_id
      };
      console.log("request form data", formData)
      console.log("token", this.props.token)
      axios.post(`/users/packages/package-edit-request`, formData, {
        headers: { "x-auth": this.props.token }
      }).then((res) => {
        this.setState({loader: false})
        if(res.data.success){
          message.success("Request sent successfully")
          this.handleClose()
        }
        else {
        this.setState({loader: false})
        message.error("Error in submitting request")
        this.handleClose()
        }
      }).catch((err) => {
        this.setState({loader: false})
        message.error("Some error occured")
        this.handleClose()
        console.log("error", err)
      })
      // //message.success("Success")
      //this.props.handleEditRequestShow()
      //this.props.handleSubmitEditRequest(formData)
    }
    else {
      console.log("err", this.state.FromDataError)
    }
    
  }

  render() {
    return (
      <>
        <Modal
          show={this.props.editRequestShow}
          onHide={this.props.handleEditRequestShow}
          backdrop="static"
          keyboard={false}
        >
          <LoadingOverlay active={this.state.loader} 
          spinner={<CustomImageLoader image={LGspinner} animationType={'pulse'}/>}
          >

          <Form>

            <Modal.Header>
              <Modal.Title style={{color:"black"}}>Edit Package Request<Tooltip title={this.props.editPackageRequestHelpText} placement="rightBottom"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip></Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form.Group>
                  <Form.Label style={{color:"black"}}> Subject</Form.Label>
                  <Form.Control
                    style={{color:"black"}}
                    type="text"
                    placeholder="Enter your subject for package"
                    name="subject"
                    value={this.state.subject}
                    onChange={this.handleChange}
                    required={true}
                    validaterule={['required']}
                    validatemsg={['This field is required']}
                  />
                  <p style={{ color: 'red' }}>{this.state.FromDataError.subject == 'false' ? '' :this.state.FromDataError.subject }</p>
                </Form.Group>

                <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label style={{color:"black"}}>Description</Form.Label>
                <Form.Control
                  style={{color:"black"}}
                  as="textarea"
                  rows="4"
                  placeholder="Enter your description for package"
                  value={this.state.description}
                  onChange={this.handleChange}
                  name="description"
                  validaterule={['required']}
                  validatemsg={['Enter edit Description']}
                />
                <p style={{ color: 'red' }}>{this.state.FromDataError.description == 'false' ? '' :this.state.FromDataError.description }</p>

              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={this.handleClose}>
                Close
              </Button>
              <Button variant="success" type="submit" onClick={this.handleSubmit}>
                Submit
              </Button>
            </Modal.Footer>

          </Form>
          </LoadingOverlay>
        </Modal>
      </>
    )
  }
}

export default EditPackageRequest