import React from "react";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import axios from '../../config/axios';
import message from '../../utils/mesaages';
import CustomImageLoader from 'react-custom-image-loader.'
import { LGspinner} from '../../assets/images'
import isEmail from 'validator/es/lib/isEmail';
class Subscribe extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
     
         email:'',
         loader: false,
    };
  }

  handleInputChange=(e)=> {
    this.setState({
        [e.target.name]: e.target.value
    },);


}
resetField() {
  this.setState({
    email: "",
  });
}

  // handleSubmit = (e) => {
  //   this.setState({loader: true})
  //   e.preventDefault();

  //   var self=this;
      
        
  //   if(this.state.email==='')
  //   {
     
  //       message.error(`Please enter email`);
  //       this.setState({loader: false})
  //       return false;
  //   }
  //   var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  //   if(!this.state.email.match(mailformat) || this.state.email==='')
  //     {
          
  //         message.error(`Please enter proper email`);
  //         this.setState({loader: false})
  //         return false;
          
  //     }
      
  //   var dataObj ={
  //     email:this.state.email,
  //   }
  //   //toast("Please wait", { type: "success" });
    
  //  axios.post(`/save-subscriber`,dataObj)
   
  //        .then(res =>{
         
  //          if(res.data.success){
  //           //this.resetField();
  //           message.success(res.data.msg);
  //           this.setState({email: "", loader: false})
            
           
  //           // setTimeout(() => {
  //           //   window.location.reload()
              
  //           // }, 3000);

  //          }else
  //          {
  //             message.error(`Already Subscribed`);
  //             this.setState({loader: false})
  //          }
           
  //          // console.log("resss---vail",res)
  //        })
  //        .catch(err =>{
  //          console.log(err)
  //          message.error("Failed! Something Went Wrong");
  //          this.setState({loader: false})

  //        })
    
  // };

  handleSubmit = (e) => {
    this.setState({loader: true})
    e.preventDefault();

    var self=this;

    if(isEmail(this.state.email)) {
      var dataObj ={
        email:this.state.email,
      }
      //toast("Please wait", { type: "success" });
      
     axios.post(`/save-subscriber`,dataObj)
     
           .then(res =>{
           
             if(res.data.success){
              //this.resetField();
              message.success(res.data.msg);
              this.setState({email: "", loader: false})
              
             
              // setTimeout(() => {
              //   window.location.reload()
                
              // }, 3000);
  
             }else
             {
                message.error(`Already Subscribed`);
                this.setState({loader: false})
             }
             
             // console.log("resss---vail",res)
           })
           .catch(err =>{
             console.log(err)
             message.error("Failed! Something Went Wrong");
             this.setState({loader: false})
  
           })
    }
    else {
      message.error(`Please enter proper email`);
      this.setState({loader: false})
    }
    
    
    
  };


  render() {
    return (
      <div>
        <LoadingOverlay
        
        // style={{ width: 200, height: 200, backgroundColor: 'papayawhip' }}
          active={this.state.loader}
          //spinner
          spinner={<CustomImageLoader image={LGspinner} animationType={'pulse'}/>}
        >
        <section className="newsletterWrap">
          <div className="container">
            <div className="heading text-center">
              <h3>Subscribe To Stay Informed</h3>
              <p>Enter your email address to subscribe to receive Coach Master news, updates, offers and more</p>
              <span className="R_divider"></span>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-5 text-center newsletterForm mt-3">
                <form>
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Enter your email address"
                      onChange={this.handleInputChange}
                      value={this.state.email}
                    />
                  </div>
                  <button className="defaultBtn" type="button" onClick={this.handleSubmit}>
                    Subscribe!
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
            </LoadingOverlay>
      </div>
    );
  }
}
export default Subscribe;
