import React from 'react'
import { sArrow ,t1} from '../../assets/images'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

class Testimonials extends React.Component{
  constructor(props, context) {
    super(props, context);
  }
    render(){
     
        return(                
              <section className="testimonialWrap" >
                <div className="container">  
               
                    {this.props.testimonialdata.length && (
                    <Slider {...settings} className="owl-theme">
                      {this.props.testimonialdata.map((each, index) => {
                        return (
                          <div className="carousel-item active" >
                              <div className="row align-items-center justify-content-center">
                                <div className="col-md-5">
                                <div className="heading text-lef">
                                  <h2>Testimonials</h2>
                                  <span className="R_divider div_dark"></span>
                                </div>
                                  <div className="aboutText">
                                    
                                  <p>{each.speech}</p>
                                  <p>{each.name}</p>
                                  <p>{each.designation}</p>
                                 
                                  </div>
                                </div>
                                <div className="col-md-5 text-center" >
                                  <img src={each.avatar_path != undefined ? each.avatar_path : t1 } alt="img" className="img-fluid"/>
                                
                                </div>
                              </div>
                    </div>


                        );
                      })}
                    </Slider>
                    )}                  


                  
                
                </div>
              </section>
        )
    }
}
export default Testimonials