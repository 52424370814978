import React from "react";
import { Link } from "react-router-dom";
import axios from "../../config/axios";
import message from '../../utils/mesaages';
import isEmail from 'validator/es/lib/isEmail';
import OwlCarousel from 'react-owl-carousel';

import { LGspinner, logo } from "../../assets/images";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";
import {COPYRIGHT_TEXT} from "../../config/custom";
import { Col,Row } from "react-bootstrap";

class FooterLink extends React.Component {
  constructor(props) {
    super()
    this.state = {
      siteDetails: [],
      name: '',
      email: '',
      number: '',
      loader: false,
    }
    this.handlePhoneNumber = this.handlePhoneNumber.bind(this)
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    },);


  }

  handlePhoneNumber(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ number: e.target.value })
    }
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    },);
  }

  componentDidMount() {
    this.getSiteDetails()
  }
  getSiteDetails = () => {
    let self = this
    axios.get('/cmsfooter/siteDetails')
      .then(async (response) => {
        if (response.status === 200) {
          self.setState({
            siteDetails: JSON.parse(response.data.value.company[0].content),
          })
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  handleSubmitContact = (e) => {
    e.preventDefault();
    // if(this.state.name == '' || this.state.email == '' || this.state.number == '') {
    //   message.warning("Please fill out all fields");
    // }
    if (this.state.number.length > 0 && this.state.number.length < 10) {
      message.warning("Phone number atleast 10 characters");
      return
    }
    // this.setState({ loading: true });

    const formData = {
      name: this.state.name,
      email: this.state.email,
      phone_number: this.state.number,
      //message: this.state.message,
      //category:this.state.category
    };
    console.log("formdata---", formData)

    if (
      this.state.name.length > 0 &&
      this.state.number.length > 0 &&
      this.state.email.length > 0
    ) {
      let self = this
      axios.post("/admin/contactUs", formData)
        .then((res) => {
          if (res.data.result) {
            self.resetField();
            //await self.setState({name:'',email:'',number:''})
            message.success("Your mail has been sent successfully!");
          } else {
            message.warning("Not able to send your mail !");
          }
        })
        .catch((error) => {
          message.error("there was an error !!!");
        });
    }
    else {
      message.error("Please fill out all fields");
    }
  };

  resetField = async () => {
    await this.setState({
      name: "",
      email: "",
      number: "",
      //category:"",
      //message: "",
      // loading: false,
    });
  }

  handleSubmit = (e) => {
    this.setState({ loader: true })
    e.preventDefault();
    if (isEmail(this.state.email)) {

      axios.post(`/save-subscriber`, { email: this.state.email, }).then(res => {
        if (res.data.success) {
          message.success(res.data.msg);
          this.setState({ email: "", loader: false })
        }
      }).catch(err => {
        message.error(err.response.data.msg);
        this.setState({ loader: false })
      })
    }
    else {

      message.error(`Please enter proper email`);
      this.setState({ loader: false })
    }
  };

  render() {
    const options = { loop: true, items: 6, autoplay: true, dots: false, autoplayTimeout: 8500, smartSpeed: 450, nav: true, responsiveClass: true };
    return (
      <div className="footer">
        <LoadingOverlay active={this.state.loader} spinner={<CustomImageLoader image={LGspinner} animationType={"none"} />}>
          <div className="container">
            <div className="ft_fst text-center">
              <div className="ft_logo">
                <img src={logo} alt="Coach Master" className="img-fluid" />
              </div>
              <p>We create life empowering remote live events. <br />World-class coaches and speakers share their expertise providing you all the tools you need to live your best life.</p>
            </div>
            <div className="ft_secnd">
              <div className="row align-items-center text-center pt-3 pb-3">
                <div className="col-lg-6 col-12">
                  <div className="fts_left">
                    <h2 className="fts_heading">Contact Info</h2>
                    <p>
                      Reach out to us with any questions.
                      <br />
                      We’d love to hear from you!
                    </p>
                    <ul className="aboutF">
                      <li>
                        <i className="fa fa-envelope" /> <Link to={"mailto:support@coachmaster.io"} id="emailhover">support@coachmaster.io</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="fts_right">
                    <h2 className="fts_heading">Subscribe Now</h2>
                    <p>Sign up to receive all the latest news and special offers</p>
                    <div id="fts_div" />
                    <Row>
                      
                   
                    <form action="https://resumecertified.org/newsletter-submit1" onSubmit={this.handleSubmit} className="d-flex align-items-center justify-content-center">
                    <Col md={6}>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter your email address"
                        onChange={this.handleInputChange}
                        value={this.state.email}
                      />
                      </Col>
                      <Col md={6}>
                      
                      <button type="submit" className="defaultBtn">
                        Subscribe
                      </button>
                      </Col>
                    </form>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
        <div className="container-fluid ft_logo_sec">
          <div className="logo_cars">
            <div className="container">
              <OwlCarousel className='owl-theme' {...options}>
                <div className="item">
                  <div className="logo_in_cars">
                    <img src="https://resumecertified.org/media/logo7.png" alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="logo_in_cars">
                    <img src="https://resumecertified.org/media/logo8.png" alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="logo_in_cars">
                    <img src="https://resumecertified.org/media/logo9.png" alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="logo_in_cars">
                    <img src="https://resumecertified.org/media/logo10.png" alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="logo_in_cars">
                    <img src="https://resumecertified.org/media/logo11.png" alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="logo_in_cars">
                    <img src="https://resumecertified.org/media/logo12.png" alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="logo_in_cars">
                    <img src="https://resumecertified.org/media/logo1.png" alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="logo_in_cars">
                    <img src="https://resumecertified.org/media/logo2.png" alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="logo_in_cars">
                    <img src="https://resumecertified.org/media/logo3.png" alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="logo_in_cars">
                    <img src="https://resumecertified.org/media/logo4.png" alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="logo_in_cars">
                    <img src="https://resumecertified.org/media/logo5.png" alt="" />
                  </div>
                </div>
                <div className="item">
                  <div className="logo_in_cars">
                    <img src="https://resumecertified.org/media/logo6.png" alt="" />
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>

        <div className="container-fluid pl-5 pr-5">
          <div className="ft_btm pt-3 pb-3">
            <div className="row">
              <div className="col-md-6 col-12">
                <p>
                  {COPYRIGHT_TEXT}
                </p>
              </div>
              <div className="col-md-6 col-12">
                <ul className="ftb_right text-right list-unstyled mb-0">
                  <li>
                    <Link to={"/terms"}>
                      Terms &amp; Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to={"/privacy-policy"}>
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={"/california-privacy-policy"}>
                      California Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default FooterLink;
