import React, { useState, useEffect } from "react";
// import moment from "moment";
import moment from "moment-timezone";

import axios from "../../../config/axios";
import { dateConfig, testFun } from "../../../config/dateFormats"

import { TimePicker, DatePicker } from "antd";
import LoadingOverlay from "react-loading-overlay";
import message from "../../../utils/mesaages";
import {
  getClientTimezone,
  timezoneUtc,
  UTCtoTimezone,
} from "../../../utils/timeZoneConvert";
import { Form, Col, Row } from "react-bootstrap";
import CustomImageLoader from "react-custom-image-loader.";
import { LGspinner } from "../../../assets/images";
import { TextAlignment } from "pdf-lib";

const _ = require("lodash");
const format = "h:mm a";
const { RangePicker } = DatePicker;
let Weekwidget = ({
  Day,
  PerticularDayArary,
  addItem,
  removeItem,
  handleInputChange,
}) => {
  return (
    <div className="row">
      <div className="col-md-3 col-4">
        <div className="form-group">
          <input
            type="text"
            style={{ color: "black" }}
            name="Day"
            value={Day}
            className="form-control"
          />
        </div>
      </div>
      <div className="col-md-9 col-8">
        {PerticularDayArary.map((x, i) => {
          let fromTime = moment(x.From, "HH:mm").isValid()
            ? moment(x.From, "HH:mm")
            : "";
          let toTime = moment(x.To, "HH:mm").isValid()
            ? moment(x.To, "HH:mm")
            : "";
          let errorMsg = x.errMsg; //"Your start time should be less than end time";
          if (fromTime === "") {
            errorMsg = "Please select start time on " + Day;
          } else if (toTime === "") {
            errorMsg = "Please select end time on " + Day;
          }

          return (
            <div className="row" key={`week-${Day}-${i}`}>
              <div className="col-md-5 col-6 col-sm-5">
                <div className="form-group">
                  <TimePicker
                    size="large"
                    onChange={(e) => handleInputChange(e, Day, i, "From")}
                    minuteStep={15}
                    showNow={false}
                    allowClear={true}
                    defaultValue={moment()}
                    use12Hours
                    value={fromTime}
                    format={format}
                  />
                </div>
              </div>
              <div className="col-md-5 col-6 col-sm-5">
                <div className="form-group">
                  <TimePicker
                    size="large"
                    onChange={(e) => handleInputChange(e, Day, i, "To")}
                    minuteStep={15}
                    showNow={false}
                    allowClear={true}
                    defaultValue={moment()}
                    use12Hours
                    value={toTime}
                    format={format}
                  />
                </div>
              </div>
              {x.hasOwnProperty("isValid") && !x.isValid && (
                <div className="col-md-12 small-device-show" style={{ color: "red" }}>
                  {errorMsg}
                </div>
              )}
              <div className="col-md-2 col-sm-2  text-center">
                <p>
                  {PerticularDayArary.length - 1 !== i ? (
                    <span
                      className="addAvailabilityTime" style={{ marginTop: "5px" }}
                      onClick={(e) => removeItem(Day, i)}
                    >
                      <i className="fa fa-minus"></i>
                    </span>
                  ) : (
                    <span
                      className="addAvailabilityTime" style={{ marginTop: "5px" }}
                      onClick={(e) => addItem(Day)}
                    >
                      <i className="fa fa-plus"></i>
                    </span>
                  )}
                </p>
              </div>
              {x.hasOwnProperty("isValid") && !x.isValid && (
                <div className="col-md-12 large-device-show" style={{ color: "red" }}>
                  {errorMsg}
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div className="col-md-12">
        <hr />
      </div>
    </div>
  );
};

let weekArray = moment.weekdays();
let WeekArrayState = weekArray.map((Day) => ({ Day, From: "", To: "" }));
class WeekList extends React.Component {
  constructor(props) {
    super(props);
    this.timezone = localStorage.getItem("timezone");
    // moment.tz.setDefault(this.timezone);

    this.state = {
      WeekArrayState,
      unavailableDateRange: [{}],

      showloader: false,
      availability_id: null,
    };
  }

  async componentDidMount() {
    this.handleGetAvailability();
    getClientTimezone();
  }

  handleGetAvailability() {
    this.setState({ showloader: true });
    const id = this.props.coachUid;
    let self = this;
    axios
      .get(`/coach/setavailability/${id}`, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        this.setState({ showloader: false });
        if (res.data.success && res.data.value.length > 0) {
          let dataset = res.data.value[0];
          self.setState({ availability_id: dataset.uid });
          let data = JSON.parse(dataset.schedule_details);
          let weekdata = data.availableDays,
            unavailableDays = [{}];
          let ResweekArray = [];
          weekdata.forEach((wkObj, v) => {
            let wkVals = Object.values(wkObj);
            if (wkVals) {
              wkVals.forEach((wval, weekDayName) => {
                wval.forEach((d) => {
                  ResweekArray.push(d);
                });
              });
            }
          });
          let daysFromDb = ResweekArray.map((robj) => robj.Day);
          weekArray.forEach((wDay) => {
            if (!daysFromDb.includes(wDay)) {
              ResweekArray.push({ Day: wDay, From: "", To: "" });
            }
          });

          if (data.unavailableDays && data.unavailableDays.length) {
            unavailableDays = data.unavailableDays;
          }
          console.log("........ResweekArray", unavailableDays);
          self.setState({
            WeekArrayState: ResweekArray,
            unavailableDateRange: unavailableDays,
          });
        }
      })
      .catch((err) => {
        this.setState({ showloader: false });
        console.log(err);
      });
  }

  handleInputChange = (e, weekday, index, name) => {
    console.log('e', e)
    const value = e ? e.format("HH:mm") : "";

    let PerticularDayArary = this.state.WeekArrayState.filter(
      (objW) => objW.Day === weekday
    );

    let WeekArrayState = this.state.WeekArrayState.filter(
      (objW) => objW.Day !== weekday
    );

    PerticularDayArary[index][name] = value;
    let { From, To } = PerticularDayArary[index];

    console.log("PerticularDayArary", PerticularDayArary);
    // validate same slots
    let vvv = PerticularDayArary.filter((objW, indexo) => {
      if (indexo != index) {
        return (
          (From <= objW.From && To > objW.From) ||
          (From < objW.To && To >= objW.To) ||
          (From > objW.From && To < objW.To)
        );
      } else {
        return false;
      }
    });

    console.log("vvvv", vvv);
    if (vvv.length > 0) {
      PerticularDayArary[index]["isValid"] = false;
      //PerticularDayArary[index]['errMsg'] ="You have chosen the same time slot. Please chose other time slot for set the availability."
      PerticularDayArary[index]["errMsg"] =
        "You have chosen same Start time or End time. Please choose another time slot to set your availability.";
    } else {
      var beginningTime = moment(From, "HH:mm");
      var endTime = moment(To, "HH:mm");
      PerticularDayArary[index]["isValid"] = beginningTime.isBefore(endTime);
      PerticularDayArary[index]["errMsg"] =
        "Your start time should be before end time";
    }

    // validate same slots

    PerticularDayArary.forEach((e) => WeekArrayState.push(e));

    // ExcludePerticularDayArary.concat(PerticularDayArary)
    this.setState({ WeekArrayState });
  };

  // handleSubmitSchedule=(e)=>{
  //   if(localStorage.getItem('tourActive')=='true')
  //   {
  //     this.props.hideTour('.tour-createpackages')
  //   }

  //   let availableDays = []
  //   weekArray.forEach(w=>{
  //     let PerticularDayArary = this.state.WeekArrayState.filter(objW=>{
  //        if(objW.hasOwnProperty('isValid') && !objW.isValid) return false
  //        let isValid = objW.Day===w && objW.From !=="" && objW.To !==""
  //        return isValid
  //     })
  //     let i = {}
  //     i[`${w}s`] = PerticularDayArary
  //     availableDays.push(i)
  //   })
  //   let unavailableDays = this.state.unavailableTime || [{}]
  //   unavailableDays = unavailableDays.filter(objWunvail=>{
  //     if(!objWunvail) return false
  //     if(objWunvail.hasOwnProperty('isValid') && !objWunvail.isValid) return false
  //     let isValid =  objWunvail.From !=="" && objWunvail.To !==""
  //     return isValid
  //   })

  //   this.setState({showloader: true})

  //   let  Schedule={
  //     availableDays:availableDays,
  //     unavailableDays:unavailableDays
  //    };

  //    const formData={

  //     schedule_details:Schedule,
  //     uid:this.state.availability_id,
  //     reminder: 2
  //   }

  //   axios.post('/coach/setavailability',formData,{
  //     headers:{'x-auth':this.props.token}
  //   })
  //   .then(res =>{
  //     this.setState({showloader: false})
  //     if(res.data.success){

  //       message.success('Your availability has been updated successfully');
  //       this.handleGetAvailability()
  //       getClientTimezone()
  //       if(localStorage.getItem('tourActive')=='true')
  //       {
  //         console.log('reload')
  //         window.location.reload()
  //       }
  //       this.props.totalProfileUpdamomentatus()

  //     }else{
  //       message.error('Your availability  could not be updated. Please try again.');

  //     }
  //   })
  //   .catch(err =>{
  //     this.setState({showloader: false})
  //     console.log(err)
  //   })

  // }

  handleSubmitSchedule = (e) => {
    if (localStorage.getItem("tourActive") == "true") {
      this.props.hideTour(".tour-createpackages");
    }

    // if(this.state.WeekArrayState.filter((objW) => { return objW.isValid==false}).length>0 || 
    // this.state.unavailableDateRange.filter((objW) => {return objW.isValid==false}).length>0)
    // {
    //   message.error('Fill the from correctly');
    // }
    // else
    // {



    let availableDays = [];
    weekArray.forEach((w) => {
      let PerticularDayArary = this.state.WeekArrayState.filter((objW) => {
        if (objW.hasOwnProperty("isValid") && !objW.isValid) return false;
        let isValid = objW.Day === w && objW.From !== "" && objW.To !== "";
        return isValid;
      });
      let i = {};
      i[`${w}s`] = PerticularDayArary;
      availableDays.push(i);
    });

    let unavailableDays = this.state.unavailableDateRange || [{}];

    unavailableDays = unavailableDays.filter((objWunvail) => {
      // console.log("here I am",objWunvail)
      // console.log("objec to",objWunvail.To)
      if (!objWunvail) return false;

      if (objWunvail.hasOwnProperty("isValid") && !objWunvail.isValid)
        return false;

      if (objWunvail.From === undefined || objWunvail.To === undefined)
        return false;

      let isValid = objWunvail.From !== "" && objWunvail.To !== "";
      return isValid;
    });

    this.setState({ showloader: true });

    let Schedule = {
      availableDays: availableDays,
      unavailableDays: unavailableDays,
    };

    const formData = {
      schedule_details: Schedule,
      uid: this.state.availability_id,
      reminder: 2,
    };

    console.log("after submit", formData);

    axios
      .post("/coach/setavailability", formData, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        this.setState({ showloader: false });
        if (res.data.success) {
          message.success("Your availability has been updated successfully");
          this.handleGetAvailability();
          getClientTimezone();
          if (localStorage.getItem("tourActive") == "true") {
            console.log("reload");
            window.location.reload();
          }
          this.props.totalProfileUpdamomentatus();
        } else {
          message.error(
            "Your availability  could not be updated. Please try again."
          );
        }
      })
      .catch((err) => {
        this.setState({ showloader: false });
        console.log(err);
      });
    // }//else
  };

  handleUpdateweekset = () => {
    this.handleGetAvailability();
  };

  handleResetweekset = () => { };

  addItem(Day) {
    let WeekArrayState = [...this.state.WeekArrayState];
    WeekArrayState.push({ Day, From: "", To: "" });
    this.setState({ WeekArrayState });
  }

  removeItem(weekday, index) {
    let PerticularDayArary = this.state.WeekArrayState.filter(
      (objW) => objW.Day === weekday
    );
    PerticularDayArary = PerticularDayArary.filter((k, i) => i !== index);
    let WeekArrayState = this.state.WeekArrayState.filter(
      (objW) => objW.Day !== weekday
    );
    PerticularDayArary.forEach((e) => WeekArrayState.push(e));
    this.setState({ WeekArrayState }, () => {
      this.recheckAvail(PerticularDayArary)
    });
  }
  recheckAvail = (avail) => {
    avail.map((v, index) => {

      if (v.From != null && v.From != '') this.handleInputChange(moment(`2021-08-15 ${v.From}`), v.Day, index, 'From')
      if (v.To != null && v.To != '') this.handleInputChange(moment(`2021-08-15 ${v.To}`), v.Day, index, 'To')
    })

  }

  addUnavailableItem(Day) {
    let unavailableDateRange = [...this.state.unavailableDateRange];
    unavailableDateRange.push({});
    this.setState({ unavailableDateRange });
  }

  async removeUnavailableItem(index) {
    let unavailableDateRange = [...this.state.unavailableDateRange];
    unavailableDateRange = unavailableDateRange.filter((k, i) => i !== index);
    await this.setState({ unavailableDateRange }, () => {
      this.recheckUnavailble(unavailableDateRange)
    });
    console.log("after remove", this.state.unavailableDateRange);
  }

  recheckUnavailble = (unavailbe) => {
    unavailbe.map((v, index) => {

      this.handleInputChangeUnavailable(v.From, '', index, 'From')
      this.handleInputChangeUnavailable(v.To, '', index, 'To')
    })

  }

  handleInputChangeUnavailable = async (date, dateString, index, name) => {
    console.log("onChange", date, dateString, index, name);

    let defaultDateString = moment(date).format("YYYY-MM-DD HH:mm")

    let unavailableDateRange = [...this.state.unavailableDateRange];


    unavailableDateRange[index][name] = date == null || date == '' ? "" : defaultDateString;
    let { From, To } = unavailableDateRange[index];
    From = From === "" ? null : From;
    To = To === "" ? null : To;

    let vvv = unavailableDateRange.filter((objW, indexo) => {
      if (indexo != index) {
        return (
          (From <= objW.From && To > objW.From) ||
          (From < objW.To && To >= objW.To) ||
          (From > objW.From && To < objW.To)
        );
      } else {
        return false;
      }
    });

    if (vvv.length > 0) {
      unavailableDateRange[index]["isValid"] = false;
      unavailableDateRange[index]["errMsg"] =
        "Your unavailability already set between that date range!";
    } else if (From === undefined || From === null) {
      unavailableDateRange[index]["isValid"] = false;
      unavailableDateRange[index]["errMsg"] =
        "Please select start date and time";
    } else if (To === undefined || To === null) {
      unavailableDateRange[index]["isValid"] = false;
      unavailableDateRange[index]["errMsg"] = "Please select end date and time";
    } else {
      unavailableDateRange[index]["isValid"] = true;
      unavailableDateRange[index]["errMsg"] = "";
    }

    await this.setState({ unavailableDateRange });
    console.log("after onchange", this.state.unavailableDateRange);
  };

  renderWeekDay(weekday) {
    let PerticularDayArary = this.state.WeekArrayState.filter(
      (objW) => objW.Day === weekday
    );
    // console.log(
    //   ".....weekday",
    //   weekday,
    //   this.state.WeekArrayState,
    //   PerticularDayArary
    // );
    return (
      <Weekwidget
        Day={weekday}
        handleInputChange={this.handleInputChange.bind(this)}
        addItem={this.addItem.bind(this)}
        removeItem={this.removeItem.bind(this)}
        PerticularDayArary={PerticularDayArary}
      />
    );
  }

  disabledDate = (current) => {
    // const form = this.props.form;
    // Can not select days before today and today
    return current < moment().subtract(1, "days");
  };

  // onOk = (value) => {
  //   console.log("onOk: ", value);
  // };

  range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  disabledStartDate = (startValue, index) => {
    if (startValue < moment().subtract(1, "days")) {
      return true
    }
    else {
      let unavailableDateRange = [...this.state.unavailableDateRange];
      let endValue = unavailableDateRange.filter((s, i) => i === index);
      endValue = endValue[0].To;
      let convertedValue = endValue !== undefined ? moment(endValue) : null;

      if (!startValue || !convertedValue) {
        return false;
      }
      return startValue.valueOf() > convertedValue.valueOf();
    }
  };

  disabledEndDate = (endValue, index) => {
    if (endValue < moment()) {
      return true
    }
    else {
      let unavailableDateRange = [...this.state.unavailableDateRange];
      let startValue = unavailableDateRange.filter((s, i) => i === index);
      startValue = startValue[0].From;
      let convertedValue = startValue !== undefined ? moment(startValue) : null;

      if (!endValue || !convertedValue) {
        return false;
      }
      return endValue.valueOf() <= convertedValue.valueOf();
    }
  };

  render() {
    const { unavailableDateRange } = this.state;
    return (
      <LoadingOverlay
        active={this.state.showloader}
        //spinner
        spinner={
          <div>
            <CustomImageLoader image={LGspinner} animationType={"pulse"} />
            <br />
          </div>
        }
        text="Loading your time slots..."
      >
        <div className="availabilityViewWrap availabilityWrap mb-5 tour-SetAvailability-setTime">
          <Form>
            <div className="row">
              <div className="col-md-3 col-4">
                <label>Weekly Hours</label>
              </div>
              <div className="col-md-9 col-8">
                <div className="row">
                  <div className="col-md-5 col-6 col-sm-5">
                    <label>Select Start Time</label>
                  </div>
                  <div className="col-md-5 col-6 col-sm-5">
                    <label>Select End Time</label>
                  </div>
                  <div className="col-md-2 col-sm-2  text-center"></div>
                </div>
              </div>

              <div className="col-md-12">
                <hr />
              </div>
            </div>

            {weekArray.map((w) => this.renderWeekDay(w))}

            <div className="row mt-4">
              <div className="col-md-12">
                <div className="row">
                  <h6 className="pl-3">
                    <strong>Unavailable</strong>
                  </h6>
                </div>
              </div>
            </div>

            {unavailableDateRange.map((x, i) => {
              let fromTime = moment(x.From, "YYYY-MM-DD HH:mm").isValid()
                ? moment(x.From, "YYYY-MM-DD HH:mm")
                : "";
              let toTime = moment(x.To, "YYYY-MM-DD HH:mm").isValid()
                ? moment(x.To, "YYYY-MM-DD HH:mm")
                : "";
              let errorMsg = x.errMsg;

              return (
                <div className="row start_end_row tour-SetAvailability-unavailable-setTime">
                  <div className="start_end">
                    <div className="d-flex mr-0">
                      <div className="col-6">
                        <DatePicker
                          disabledDate={(dateValue) =>
                            this.disabledStartDate(dateValue, i)
                          }
                          showTime={{
                            format: "HH:mm",
                            minuteStep: 15,
                            defaultValue: moment("00:00", "HH:mm"),
                          }}
                          showNow={false}
                          format={dateConfig.dateTimeFormat}
                          value={fromTime}
                          placeholder="Start"
                          onChange={(date, dateString) =>
                            this.handleInputChangeUnavailable(
                              date,
                              dateString,
                              i,
                              "From"
                            )
                          }
                        //onOpenChange={this.handleStartOpenChange}
                        />
                      </div>
                      <div className="col-6">
                        <DatePicker
                          disabledDate={(dateValue) =>
                            this.disabledEndDate(dateValue, i)
                          }
                          showTime={{
                            format: "HH:mm",
                            minuteStep: 15,
                            defaultValue: moment("00:00", "HH:mm"),
                          }}
                          showNow={false}
                          format={dateConfig.dateTimeFormat}
                          value={toTime}
                          placeholder="End"
                          onChange={(date, dateString) =>
                            this.handleInputChangeUnavailable(
                              date,
                              dateString,
                              i,
                              "To"
                            )
                          }
                        //open={endOpen}
                        //onOpenChange={this.handleEndOpenChange}
                        />
                      </div>
                    </div>
                    {x.hasOwnProperty("isValid") && !x.isValid && (
                      <div className="col-md-12" style={{ color: "red", textAlign: fromTime == "" ? "left" : toTime == "" ? "right" : "center", paddingRight: toTime == "" ? "var(--unavailable-ad);" : "0px" }}>
                        {errorMsg}
                      </div>
                    )}
                  </div>
                  <div className="start_end_button col-md-1  text-center pl-2">
                    <p>
                      {this.state.unavailableDateRange.length - 1 !== i ? (
                        <span
                          className="addAvailabilityTime"
                          onClick={(e) => this.removeUnavailableItem(i)}
                        >
                          <i className="fa fa-minus"></i>
                        </span>
                      ) : (
                        <span
                          className="addAvailabilityTime"
                          onClick={(e) => this.addUnavailableItem()}
                        >
                          <i className="fa fa-plus"></i>
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              );
            })}

            <div className="text-right mt-4 timeEntryAction tour-SetAvailability-submit">
              <button
                className="defaultBtn"
                type="button"
                onClick={this.handleSubmitSchedule.bind(this)}
              >
                Submit
              </button>
            </div>
          </Form>
        </div>
      </LoadingOverlay>
    );
  }
}
export default WeekList;
