import React, { Component } from "react";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from '../config/axios';
import message from '../utils/mesaages';
import { Accordion, Card, Button, Container, Row, Col } from "react-bootstrap";

import { Player } from "video-react";
import { PriceTableNew } from "../components/pricetable";


import {
  coachesVideo,
  b1,
  b2,
  dot,
  // KF1,
  KF2,
  // KF3,
  KF4,
  LGspinner,
  Coach_banner,
  Coach_banner_bg,
  WJU1,
  WJU2,
  WJU3,
  WJU4,
  WJU5
} from "../assets/images";
import { Header, HeaderLogout, HeaderTop } from "../components/headers";
import { FooterLink } from "../components/footers";
import KF1 from "../assets/images/marketing.png";
import KF3 from "../assets/images/worldwide.png";
// import {
//   Subscribe
// } from "../views/landing/Index";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from 'react-custom-image-loader.';
import HeaderSecondTo from "../components/corporate/Header/HeaderSecondTo";


const _ = require("lodash");
class ForCoaches extends Component {
  constructor(props, context) {
    super(props, context);
    this.token = localStorage.getItem("authToken");
    this.userdata = JSON.parse(localStorage.getItem("userdata"));
    this.state = {
      name: "",
      phone_number: "",
      email: "",
      category: "",
      message: "",
      choose_lifeguru: [],
      video: [],
      feature_list: [],
      faq_cs: [],
      header_heading: "",
      header_description: "",
      benefits_heading: "",
      benefits_description: "",
      allplan: [],
      membershipID: 0,
      has_membership: 0,
      membershipExpire: '',
      currentPlan: '',
      loader: false,
    }
  }

  handleChange = (e) => {
    e.persist();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  resetField() {
    this.setState({
      name: "",
      email: "",
      phone_number: "",
      category: "",
      message: "",
      // loading: false,
    });
  }

  handleSubmitContact = (e) => {
    e.preventDefault();
    // this.setState({ loading: true });

    const formData = {
      name: this.state.name,
      email: this.state.email,
      phone_number: this.state.phone_number,
      message: this.state.message,
      category: this.state.category
    };
    console.log("formdata---", formData)

    if (
      this.state.name.length > 0 &&
      this.state.message.length > 0 &&
      this.state.email.length > 0
    ) {
      axios.post("/admin/contactUs", formData)
        .then((res) => {
          if (res.data.result) {
            this.resetField();
            message.success("Your mail has been sent successfully");
          } else {
            message.warning("Not able to send your mail !");
          }
        })
        .catch((error) => {
          message.error("there was an error !!!");
        });
    }
  };

  createMarkup = (data) => {
    return { __html: data };
  }

  componentDidMount() {
    this.getForCoachContent()
    if (this.userdata != null) {
      this.fetchCoachData()
    }
  }

  fetchCoachData = () => {
    this.setState({ loader: true })
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    const id = userdata.uid;

    let param = {
      url: `/users/coachdatajoin/${id}`,
      method: "GET",
      headers: {
        "X-Auth": this.token
      }
    }
    axios(param)
      .then(async (res) => {
        this.setState({ loader: false })

        if (res.data.value) {

          //console.clear();
          console.log("coach data", res.data.value)

          const rawdata = res.data.value;
          this.setState({ has_membership: rawdata.has_membership })
          this.setState({ membershipID: rawdata.membership_id })
          this.setState({ membershipExpire: rawdata.membership_exp_date }, () => {
            this.getPlan()
          })



        }
      })
      .catch((error) => {
        console.log("fetch error");
        console.log(error);
        this.setState({ loader: false })
        // toast("Error getting profile data", { type: "error" });
      });
  }

  getPlan = () => {

    axios
      .get(`/users/get-allcoach-plan`, {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          console.log('plan.....', res.data.value)
          let plan = res.data.value.coachPlan;
          if (plan.length > 0) {
            this.setState({
              allplan: plan,
              // planDesc:res.data.value.coachDesc,
              // yearlydiscount:res.data.value.discount.plan_rate 
            }, () => {
              this.currentPlan()
            });

            //  let yearly= plan.map((descVal)=>{
            //     let totalYearly=descVal.plan_price*12
            //     return this.calculatePercent(res.data.value.discount.plan_rate, totalYearly)

            //     } )

            //     console.log('yearly',yearly)

          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  currentPlan = () => {
    //   console.log('sssssssss',this.state)
    let currentId = this.state.membershipID;
    let subscription = _.find(this.state.allplan, function (o) { return o.id == currentId });

    this.setState({ currentPlan: subscription },
      () => {
        console.log('ssss', subscription)
      })

  }

  getForCoachContent = () => {
    let self = this
    axios.get('/cmsforcoach/forcoachContent')
      .then(async (response) => {
        if (response.status === 200) {
          //console.log('cms ',response.data)
          let headContent = response.data.value.header_content[0].content_json
          let benefitsContent = response.data.value.feature_benefit[0].content_json
          console.log('cms perse', headContent.heading)
          self.setState({
            choose_lifeguru: response.data.value.choose_lifeguru,
            video: response.data.value.video[0].content_json,
            feature_list: response.data.value.feature_list,
            faq_cs: response.data.value.faq_cs,
            header_heading: headContent.heading,
            header_description: headContent.description,
            benefits_heading: benefitsContent.heading,
            benefits_description: benefitsContent.description,
          })
        }
      })
      .catch((e) => {
        console.log("error wile state saving", e)
      })
  }

  splittingUlLi(textHtml) {
    let reText = textHtml.replace(/(<ul>\r\n\t|\r\n\t|\r\n<\/ul>)/gm, "")
    let only = reText.split("</li>")
    only.pop()
    return only
  }

  getBenefitsContentLeft(eachData) {
    let stringArray = this.splittingUlLi(eachData.content)
    return (<div
      className="benefitsContent benefitsContentLeft"

    >
      <div className="row">
        <div className="col-md-5">
          <img src={eachData.image_url} className="img-fluid" alt="LG-Img" />
        </div>
        <div className="col-md-7">
          <h3>{eachData.title}</h3>
          <span className="R_divider div_dark"></span>
          <ul>
            {stringArray.map((each) => {
              let theValue = each.replace(/(<li>)/gm, "")
              return (
                <li>
                  <img width="15px" src={dot} alt="LG-Img" /><div dangerouslySetInnerHTML={this.createMarkup(theValue)}></div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>)
  }

  getBenefitsContentRight(eachData) {
    let stringArray = this.splittingUlLi(eachData.content)
    return (<div
      className="benefitsContent benefitsContentRight"

    >
      <div className="row">
        <div className="col-md-7 pl-5">
          <h3>{eachData.title}</h3>
          <span className="R_divider div_dark"></span>
          <ul>
            {stringArray.map((each) => {
              let theValue = each.replace(/(<li>)/gm, "")
              return (
                <li>
                  <img width="15px" src={dot} alt="LG-Img" /><div dangerouslySetInnerHTML={this.createMarkup(theValue)}></div>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="col-md-5">
          <img src={eachData.image_url} alt="LG-Img" className="img-fluid" />
        </div>
      </div>
    </div>)
  }

  render() {
    return (
      <React.Fragment>
        <LoadingOverlay active={this.state.loader}
          spinner={<CustomImageLoader image={LGspinner} animationType={'pulse'} />}
          styles={{
            wrapper: {
              overflow: this.state.loader ? 'hidden' : ''
            }
          }}
        >

          {
            !localStorage.getItem("subdomain") && (localStorage.getItem("authToken") == null ? (<HeaderTop />) : (<HeaderLogout />))
          }
          {
            localStorage.getItem("subdomain") ? (<HeaderSecondTo />) : (<Header />)
          }

          <section className="tc_banner">
            <div className="tcb_back d-flex">
              <div className="tcb_img col" style={{ background: 'url(' + Coach_banner + ')' }}></div>
              <div className="tcb_img col" style={{ background: 'url(' + Coach_banner_bg + ')' }}></div>
            </div>
            <Container>
              <Row>
                <Col md={6}></Col>
                <Col md={6}>
                  <div className="tcb_text">
                    <div className="heading mb-4">
                      <h3 className="text-uppercase">join Coach Master as a Coach</h3>
                    </div>
                    <p>Elevate your career and your clients’ successes. Coach master welcomes applications from great coaches, speakers and practitioners who want to make a difference in the lives of individuals all around the world. </p>
                    <Link className="btn btn-default defaultBtn mt-4" to={"/registration"}>Sign Up</Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>


          <section className="abfeaturesWrap">
          <Container>
            <h2>Why Choose us</h2>
            <Row className="feature_bottom">
                <Col md={3}>
                  <div className="featuresItem featuresItem2" style={{backgroundImage: 'url('+KF1+')'}}>
                    <div className='fi_content'>
                      <div className="featuresImg">
                        <img src={WJU1} />
                      </div>
                      <h4>Benefit from our marketing investment </h4>
                      <p>Our investment in extensive digital marketing helps you increase visibility and grow your business</p>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="featuresItem" style={{background: 'url('+KF2+')'}}>
                    <div className='fi_content'>
                      <div className="featuresImg">
                        <img src={WJU2} />
                      </div>
                      <h4>Expand your customer reach </h4>
                      <p>Your profile will be promoted to a growing global community of people seeking your services</p>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="featuresItem" style={{background: 'url('+KF3+')'}}>
                    <div className='fi_content'>
                      <div className="featuresImg">
                        <img src={WJU3} />
                      </div>
                      <h4>Share your expertise to a worldwide audience</h4>
                      <p>We invite our top coaches to speak at our weekly live webinar events</p>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="featuresItem" style={{background: 'url('+KF4+')'}}>
                    <div className='fi_content'>
                      <div className="featuresImg">
                        <img src={WJU4} />
                      </div>
                      <h4>Custom-built in-house video conferencing</h4>
                      <p>Client sessions and webinars are hosted via our platform - no paid Zoom account required</p>
                    </div>
                  </div>
                </Col>
            </Row>
          </Container>
        </section>

          <section className="abfeaturesWrap" style={{"display" : "none"}}>
            <Container>
              <h2>Why Choose us</h2>
              <div className="feature_bottom sb_static">
                <Col md={6}>
                  <div className="featuresItem" style={{ background: 'url(' + KF1 + ')' }}>
                    <div className='fi_content'>
                      <h4>We work with experienced, qualified health & wellbeing and personal & professional development, coaches, practitioners & speakers</h4>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="featuresItem" style={{ background: 'url(' + KF2 + ')' }}>
                    <div className='fi_content'>
                      <h4>  Access to our corporate clients through our platform</h4>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="featuresItem" style={{ background: 'url(' + KF3 + ')' }}>
                    <div className='fi_content'>
                      <h4>Share your expertise through our remote live webinar events </h4>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="featuresItem" style={{ background: 'url(' + WJU5 + ')' }}>
                    <div className='fi_content'>
                      <h4>Curate your own programs and receive bookings from employees</h4>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="featuresItem" style={{ background: 'url(' + KF4 + ')' }}>
                    <div className='fi_content'>
                      <h4>Deliver your services through our in-house remote video conferencing</h4>
                    </div>
                  </div>
                </Col>
              </div>
            </Container>
          </section>

          <section className="chooseWrap">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8" >
                  <div className="chooseVideo">
                    <iframe
                      id="player1"
                      width="100%"
                      height="430px"
                      frameBorder="0"
                      playsInline
                      poster={this.state.video.thumbnail_url}
                      src={this.state.video.video_url}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="tot">
            <Container>
              <Row className="align-items-center">
                <Col md={8}>
                  <div className="heading">
                    <h6 className="text-uppercase">Do you want to make a difference in the lives of individuals all around the world?</h6>
                    <h3 className="text-uppercase">JOIN US AS A COACH</h3>
                  </div>
                </Col>
                <Col md={4} className="text-right">
                  <Link className="btn defaultBtn" to="/registration">Join us</Link>
                </Col>
              </Row>
            </Container>
          </section>

          {/* <Subscribe /> */}

          <FooterLink />
        </LoadingOverlay>

      </React.Fragment>
    );
  }
}

export default ForCoaches;
