import React from 'react'
import {Link} from 'react-router-dom'
import Select from "react-select";
import { dArrowblack } from '../../assets/images'

class Language extends React.Component{
  constructor() {
    super()
    this.state={
      language: [],
    }
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(value, { action, removedValue }) {
    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.isFixed !== undefined) {
          return;
        }
        break;
      case 'clear':
        value = []
        break;
    }


    this.setState({ language: value });
    this.props.data.language = value
  }
    render(){
      const options = [
        { value: "English", label: "English" },
        { value: "Spanish", label: "Spanish" },
        { value: "French", label: "French" },
        { value: "Portuguese", label: "Portuguese" },
        { value: "Hindi", label: "Hindi"},
        { value: "Russian", label: "Russian" },
        { value: "German", label: "German" },
        { value: "Chinese", label: "Chinese" },
        { value: "Japanese", label: "Japanese" },
      ];
        return(
            <div>
                <fieldset>
        <div className="heading text-center">
          <h3 className="fs-subtitle">Language Spoken</h3>
          <span className="R_divider"></span>
        </div>
        <div className="form-group">
            
            <div className="selectBox"  style={{color:'black'}}>
             
              {/* <select  className="form-control" value={this.props.data.language} onChange={this.props.handleChange} name='language'>
                 <option value="" selected="selected">Any Language</option>
                 <option value='ENGLISH'>ENGLISH</option>
                 <option value='SPANISH'>SPANISH</option>
                 <option value='HINDI'>HINDI</option>
                 <option value='RUSSIAN'>RUSSIAN</option>
                 <option value='CHINESE'>CHINESE</option>
                 <option value='JAPANESE'>JAPANESE</option>
              </select> */}
              <Select
                            className="languageSelect"
                            placeholder="Select language"
                            isMulti
                            value={this.props.data.language}
                            onChange={this.handleChange}
                            //isClearable={!options.some((opt) => opt.isFixed)}
                            isClearable={true}
                            options={options}
                            defaultOptions={this.state.language}
                            //styles={styles}
                          />
              
              {/* <span>
                  <img src={dArrowblack} alt="LG-Img"/>
              </span> */}
            </div>
          </div>

        <Link
        to="#"
          onClick={() =>
          this.props.handleField("TimeZone")
          }
         className="defaultBtn">PREVIOUS
        </Link>{" "}
      
        <Link
        to="#"
          onClick={() =>
          this.props.handleField("Price")
          }
         className="defaultBtn">Next
        </Link>

       
      </fieldset>
            </div>
        )
    }
}
export default Language