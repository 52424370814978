



import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";
import {  filter,closeSvg,quality, dArrowblack,mclose } from "../../../assets/images";
import { avatar } from "../../../assets/img";
// import { writingSvg, closeSvg, plusnewSvg, mclose } from "../../../assets/images";

 
const _ = require("lodash");  

class FeedbackModal extends Component {
    constructor(props)
    {
        super(props)
        this.state ={
            selectedSlotTime:''
        }

    }

  
    render() {

        let star = this.props.details.rating
        let arr = [1,2,3]
      
        
        return (
            <div>
                 <Modal
        show={this.props.show}
        onHide={this.props.handale}
        keyboard={false}
        className="modal  reviewModalWrap"        
      >
        <div className="modal-content accept_modal">

        {/* <Modal.Header closeButton>
          <Modal.Title>Review Details</Modal.Title>
        </Modal.Header> */}


        

        <div className="modal-body">
        {/* <div className="row justify-content-center"> */}
                <button
                  type="button"
                  className="close"
                  onClick={this.props.handale}
                >
                  <img width="30px" src={mclose} alt="LG-Img" />
                </button>
                {/* <div className="heading text-center">
                  <h3 className="text-capitalize">Review Details</h3>
                  <span className="R_divider"></span>
                </div> */}
                <div className="feedbackContent">
                  <div className="fUser">
                    <img src={this.props.details.avatar_path!=null?(this.props.details.avatar_path):(avatar)} alt="LG-Img" />
                    <h4>
                      {this.props.details.name}
                    </h4>
                  </div>
                  <div className="fRating my-3">
                  {arr.map((a)=>{

                            return(
                            <i className={star>=a?('fa fa-star active'):('fa fa-star')} aria-hidden="true"></i>

                            )

                            
                            })}
                  </div>
                  <p>
                  {this.props.details.comment}
                  </p>
                </div>
              </div>
            
           
        {/* </div> */}
        </div>
      </Modal>
    
                
            </div>
        );
    }
}

export default FeedbackModal;

