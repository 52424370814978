import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import axios from '../../../config/axios'
import { message } from 'antd'

export default function EditGroupUser(props) {
    const [firstname, setfirstname] = useState(props.userDetails.firstname)
    const [lastname, setlastname] = useState(props.userDetails.lastname)
    const [email, setemail] = useState(props.userDetails.email)
    const [contact_no, setcontact_no] = useState(props.userDetails.contact_no)

    const OnSubmit = (e) => {
        e.preventDefault()
        if (props.isSubmit) {
            let deta = { firstname, lastname, email, contact_no, group_id: props.userDetails.group_id, id: props.userDetails.id, user_id: JSON.parse(localStorage.getItem("corporate_userdata")).uid }
            axios.put(`/api/corporate_edit_group_user`, deta, { headers: { "X-Auth": localStorage.getItem("corporate_token"), }, }).then((responce) => {
                message.success(responce.data.msg)
                props.getGroupUser()
                props.handleClose()
            }).catch((error) => {
                message.error(error.response.msg || error.response.data.msg)
            })
        } else {
            let deta = { firstname, lastname, email, contact_no, id: props.userDetails.id, user_id: JSON.parse(localStorage.getItem("corporate_userdata")).uid }
            axios.put(`/api/corporate_edit_group_user`, deta, { headers: { "X-Auth": localStorage.getItem("corporate_token"), }, }).then((responce) => {
                message.success(responce.data.msg)
                props.getGroupUser()
                props.handleClose()
            }).catch((error) => {
                message.error(error.response.msg || error.response.data.msg)
            })
        }
    }

    const reset = () => {
        setfirstname(props.userDetails.firstname)
        setlastname(props.userDetails.lastname)
        setemail(props.userDetails.email)
        setcontact_no(props.userDetails.contact_no)
    }

    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Employee Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => OnSubmit(e)}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>First Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" required placeholder="Enter Your First Name" name="firstname" value={firstname} onChange={(e) => setfirstname(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Last Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" required placeholder="Enter Your Last Name" name="lastname" value={lastname} onChange={(e) => setlastname(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Contact No <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" required placeholder="Enter Your Contact No" name="contact_no" value={contact_no} onChange={(e) => setcontact_no(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" required placeholder="Enter Your Email" name="email" value={email} onChange={(e) => setemail(e.target.value)} />
                        </Form.Group>
                        <Button variant="primary" id='userupdateSubmit' type="submit" hidden />
                        <Button variant="primary" id='userupdateReset' type="reset" onClick={()=> reset()} hidden />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className='btn tabformbutton' onClick={() => document.getElementById('userupdateReset').click()}>
                        Reset
                    </Button>
                    <Button variant="primary" className="defaultBtn rounded-lg" onClick={() => document.getElementById('userupdateSubmit').click()}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
