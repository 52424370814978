import React from 'react'
import { teamSvg } from '../../../assets/images'
import UpcomingSessionCalender from '../../../components/calender/upcomingsessionCalender'
import axios from '../../../config/axios'


class UpcomingSession extends React.Component{
  constructor(props){
    super(props)
    this.state={
      sessionBookingDetails:[]
    }
  }

  componentDidMount(){
    this.getUpComingSession();
      
  }

  getUpComingSession = () => {
    var self=this;

    axios.get('/upcoming/session',{
      headers:{"x-auth":self.props.token}
    })
    .then(res =>{
      //console.log("seesion--booking--res",res.data)
      if(res.data.success){
        self.setState({sessionBookingDetails:res.data.value})
      }
    })
    .catch(err =>{
      console.log(err)
    })

  }

    render(){
      //console.log("sessionBooking----",this.state.sessionBookingDetails)
        return(
  <div>
     {/* <div className="searchHead">
          <div className="searchHeading">
            <h3>
              <img width="22px" src={teamSvg} alt="LG-Img" />Upcoming session
            </h3>
          </div>
          
        </div>

        <UpcomingSessionCalender
        sessionBookingDetails={this.state.sessionBookingDetails}
        /><hr/>

 <div className="col-md-14">
       <div className="userBookingList">
      
      {this.state.sessionBookingDetails.length > 0 && this.state.sessionBookingDetails.map(sessionBooking =>{
       let sessionDetails=JSON.parse(sessionBooking.session_details)
       let sessionID=sessionBooking.session_id
       let x=[]
       if(sessionDetails!==null || sessionDetails!==undefined)
       {
            sessionDetails.filter(session =>{
              if(session.id==sessionID){
                x.push(session)
              }
          })
       }
          
       console.log("sesssionDetails",sessionDetails)
       console.log("xxx---",x)
       return(
          <div className="bookingCocachDetails">
          {/* <ul>
            <li>
              <b>Package Name</b> <span>{sessionBooking.title}</span>
            </li>
            <li>
              <b>Session Name</b> <span>{x[0].sessionName}</span>
            </li>
            <li>
              <b>User Name</b> <span>{sessionBooking.name}</span>
            </li>
            <li>
              <b>Scheduled</b> <span>{sessionBooking.date.split("T")[0]}<br/>
              {sessionBooking.start_time}-{sessionBooking.end_time}</span>
            </li>
            <li>
              <b>Status</b> <span>{sessionBooking.status==0 ?("Schedule Not Confirmed"):("Confirmed")}</span>
            </li> */}

  
            {/* <li className="userCoachMore">
              <a
                data-toggle="collapse"
                href={`#${booking.uid}`}
                aria-expanded="false"
                aria-controls="collapseExample"
                onClick={()=>{
                  this.handleGetPackage(booking.package_uid)}
                }
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
              </a>
            </li> */}
            {/* <li className="defaultBtn">Go To Room</li> */}
          {/* </ul> */}
          {/* <div className="collapse" id={booking.uid}>
            <div className="BCDView">
              <p>
                <b>Package Details : </b>{" "}
                <span>{this.state.Description}</span>
              </p>
              <p>
                <b>Total Sessions : </b> <span>{this.state.TotalSession}</span>
              </p>
              <p>
                <b>Sessions left : </b> <span>{this.state.TotalSession}</span>
              </p>
              
            </div>
          </div> */} 
        {/* </div> */}
      {/* //   )
      // })}
         
        
         

      //   </div> */}
      {/* // </div> */}
            </div>
        )
    }
}

export default UpcomingSession