import React, { Component } from "react";
import { Button, Modal, Form, Container, Row } from "react-bootstrap";
import { Tooltip } from 'antd';
import axios from "../../../config/axios";
import Axios from "axios";
import "../../../../node_modules/video-react/dist/video-react.css"; // import css

import { Player } from "video-react";
import { upload } from "../../../assets/images";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import message,{getHelpText} from '../../../utils/mesaages';

const baseUrl = axios.defaults.baseURL

class UpdateVideo extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("authToken");
  }
  state = {
    show: false,
    videofile: null,
    videofile_name: "",
    intro_video_text: "",
    invalid: '',
  };

  async componentDidMount() {
    let intro_video_text = await getHelpText('intro_video_text')
    this.setState({intro_video_text})
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  onClose = async() => {
    await this.setState({videofile_name: "", videofile: null, show: false, invalid: ''})
  }

  handleShow = () => {
    this.setState({ show: true });
  };

  onChangeHandlerVideo = async(e) => {
    let inputType = e.target.files[0] != undefined ? e.target.files[0].type.split('/'): ''
    let filename = e.target.files[0] != undefined ? e.target.files[0].name.split('.'): ''
    let ext = filename !='' ? filename.pop().toLowerCase(): ''
    console.log("@@@@@@@@ extension" , ext)
    if(inputType[0] != undefined && inputType[0] == 'video' && (ext == 'mp4' || ext == 'webm' || ext == 'ogv' || ext == 'mov')){
      if(e.target.files[0].size>300000000) {
        await this.setState({invalid: "Please select file upto 300MB size", videofile_name: "", videofile: null})
      } else {
        await this.setState({
          videofile: e.target.files[0],
          videofile_name: e.target.files[0] != undefined ? e.target.files[0].name : "",
          loaded: 0,
          invalid: '',
        });
      }
    } else {
      await this.setState({invalid: "Please select correct file format upto 300 MB size", videofile_name: "", videofile: null})
      //message.error("Please choose a video file")
      //this.onClose()
    }
    
    //console.log("I am here", this.state.videofile)
  };

  handleSubmitProfileVideo = (e) => {
    e.preventDefault();
   if(this.state.videofile != null) {
    let filename = this.state.videofile_name.split('.')
    let filetype = filename.pop().toLowerCase()

    const formData = new FormData();
    formData.append("profileVideo_path", this.state.videofile);

    let param;
    if (this.props.coachprofile_id !== "") {
      formData["uid"] = this.props.coachprofile_id;
      
        param = {
          
          url: filetype !== "mov" ? baseUrl+"/users/profilevideo" : baseUrl+"/movConvert/convert-file-profilevideo",
          method: filetype !== "mov" ? "PUT" : "POST",
          data: formData,
          headers: { "X-Auth": this.token },
        };
      
    } else {
      param = {
        url: "/users/profilevideo",
        method: "POST",
        data: formData,
        headers: { "X-Auth": this.token },
      };
    }
    this.setState({disableButton: true}, () => {
      this.props.handleDisableButtons(this.state.disableButton)
    })
    const hideMessage = message.loading('Please wait while we are updating video. Do not refresh the page.', 0)

    Axios(param)
      .then((response) => {
        this.setState({disableButton: false})
        hideMessage()
        if (response.data.success) {
          //this.setState({videofile_name: "", videofile: null})
          this.onClose()
          this.props.handleSubmitProfileVideo(response, filetype);
        }
      })
      .catch((error) => {
        this.setState({disableButton: false})
        hideMessage()
        message.error("Error in updaing profile video");
      });

   } else {
     message.error("No file selected")
   }
  };

  render() {
    return (
      <>
        {!this.state.disableButton && (
          <Link
          to="profile-coach"
          //   href="javascript:void(0);"
          data-toggle="tooltip"
          data-placement="top"
          title="Upload Personal Introductory Video"
          onClick={() => this.props.processed === "1" ? this.handleShow() : message.warning("Please wait. Your previously uploaded video is still processing.")}
          // style={{pointerEvents: this.props.processed === "0" ? "none" : ""}}
        >
          <img width="35px" src={upload} alt="LG-Img" />
        </Link>
        )}

        <Modal
          show={this.state.show}
          onHide={this.onClose}
          backdrop="static"
          keyboard={false}
        >
          <Form onSubmit={this.handleSubmitProfileVideo}>
            <Modal.Header closeButton>
              <Modal.Title style={{color:"black"}}> Upload Personal Introductory Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <Container>
                <Row>
                  <Player
                    id="player1"
                    width="100%"
                    height="350"
                    playsInline
                    src={this.props.video}
                  />
                </Row>
                <br />
                <Row>
                  <br />
                  
                </Row>
                
              </Container> */}
              <div className="file_up">
                  <label for="image" className="custom-file-upload">
                    <img src={upload} width="60px"/>
                  </label>
                  <p>Click to Upload Video
                          <Tooltip title={this.state.intro_video_text} placement="right"> <i style={{color:"purple"}} className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>
                          </p>
                          {this.state.invalid == '' ? (
                            // <span style={{color: "#28a745"}}>{this.state.videofile_name != '' ? this.state.videofile_name : ""}</span>
                            <span style={{color: "#28a745"}}>{this.state.videofile_name}</span>
                          ) : (
                            <span style={{color: "red"}}>{this.state.invalid}</span>
                          )}
                  <input
                    id="image"
                    type="file"
                    //label="Select Video file"
                    name="avatar_path"
                    onChange={this.onChangeHandlerVideo}
                    accept="video/*"                    
                  />
                  </div>
            </Modal.Body>
            <Modal.Footer>
              {this.state.videofile != null ? (
                <Button
                variant="btn btn-success btn-block"
                type="submit"
                onClick={this.handleClose}
              >
                Upload
              </Button>
              ) : ""}
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default UpdateVideo;
