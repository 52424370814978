import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { writingSvg, closeSvg, plusnewSvg, mclose } from "../../../assets/images";
import { Tooltip } from 'antd';
function UpdatePackageContent(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

        <div className="cProfileEdit">
            {/* <span className="addTag" 
              data-toggle="tooltip"
              data-placement="top"
              title={props.packageshelptext}
              onClick={handleShow}
              ><img src={plusnewSvg} width="25px"/></span> */}
             <span className="addTag" style={{cursor:"pointer"}}>
             <Tooltip title={props.packageshelptext}  onClick={handleShow} placement="bottom"><img src={plusnewSvg} width="25px"/></Tooltip>
             </span>

        </div>
      {/* <p
        className="popup-with-zoom-anim edit_item"
        data-toggle="tooltip"
        data-placement="top"
        title="Update Package Subtitle Content"
        onClick={handleShow}
      >
        <img width="18px" src={writingSvg} alt="LG-Img" />sdfsdf
      </p> */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Form onSubmit={props.handlePackageContentSubmit}>
          <Modal.Header>
            <Modal.Title>Update Package Subtitle Content</Modal.Title>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <img width="30px" src={mclose} alt="ss" />
            </button>
          </Modal.Header>

          <Modal.Body>
            <Form.Group >
              <Form.Label>Package Subtitle</Form.Label>

              <Form.Control
                as="textarea"
                rows="3"
                placeholder="Package Summary"
                value={props.packageContent}
                onChange={props.handleChange}
                name="packageContent"
              />
            </Form.Group>

              <div className="text-center">
                <button className="defaultBtn" variant="success" type="submit" onClick={handleClose}>Save</button>
              </div>

          </Modal.Body>

          {/* <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="success" type="submit" onClick={handleClose}>
              Save
            </Button>
          </Modal.Footer> */}
        </Form>
      </Modal>
    </>
  );
}

export default UpdatePackageContent;
