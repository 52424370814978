import React from "react";
import { Modal, Form, Col, Row } from "react-bootstrap";
// import { Link } from "react-router-dom";
import validation from '../../../Validate/validator';
import message,{getHelpText} from '../../../utils/mesaages';
import { Tooltip } from 'antd';
import axios from '../../../config/axios'
const _ = require("lodash");

class UpdatePackage extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      show: "",
      setShow: false,
      title: props.isEdit ? props.pacdata.title : "",
      description: props.isEdit ? props.pacdata.description : "",
      price: props.isEdit ? props.pacdata.price : "",
      isactive: "1",
      introDuration: "0",
      duration: "0",
      session_HH: props.isEdit ? Math.floor(props.pacdata.duration / 60) : "0",
      session_MM: props.isEdit
        ? Math.round(
            (props.pacdata.duration / 60 -
              Math.floor(props.pacdata.duration / 60)) *
              60
          )
        : "0",
      package_type: props.isEdit ? props.pacdata.package_type : "",
      total_sessions: props.isEdit ? props.pacdata.total_sessions : "",
      session_details: props.isEdit ? props.pacdata.session_details : "",

      session_details_list: [],
      session_details_listValid: [],
      cost: "",
      rate: "",
      Intromessage:'',
      requestChangDes:'',
      sortingOrder: null,
      FromDataError: {
        // package_type: 'false',
        title: 'false',
        description:'false',
        introDuration:'false',
        duration:'',
        cost:'false',
        rate:'false',
        sortingOrder: 'false',
      },
    };
  }

  validateForm(errors) {
    let valid = true;
    let sta = this;
    let FromDataError = this.state.FromDataError;
    valid = this.validateForm2(this.state.session_details_listValid)

    for (const [key, value] of Object.entries(errors)) {
      if (value.length > 0) {

        if (value == 'false') {
          FromDataError[key] = 'This field is required';
          sta.setState({ FromDataError })
        }

        valid = false;
      }
    }

    return valid;
  };

  validateForm2=(errors) =>{
    let valid = true;
    let sta = this;
    let ListVal = [...this.state.session_details_listValid];

    for (const [key, value] of Object.entries(errors)) {
      for (const [k, v] of Object.entries(value)) {
        if (v.length > 0 && k!='id') {
          console.log('valid',v,k)
          if (v == 'false') {
            ListVal[key][k] = 'This field is required';
            sta.setState({session_details_listValid:ListVal})
          }
  
          valid = false;
        }
      }
      
    }
    
    return valid;
  };

  componentDidUpdate = (prevProps) => {
    if(prevProps.pacdata.so !== this.props.pacdata.so) {
      this.setState({sortingOrder: this.props.pacdata.so})
    }
  }


  async componentDidMount() {
    // console.log(this.props.pacdata)
    var price = this.props.pacdata.price;
    var cost_type = price.split("##")[0];
    var rate = price.split("##")[1];

    if (this.props.pacdata.session_details !== "") {
      var list_data = JSON.parse(this.props.pacdata.session_details);
      this.setState({ session_details_list: list_data });

      var session_details_listValid =[]

      list_data.map((dd)=>{

        session_details_listValid = [...session_details_listValid, { id:dd.id, 
          sessionName:  (dd.sessionName!='' && dd.sessionName!=undefined)? '' : 'false', 
          sessionDescription: (dd.sessionDescription!='' && dd.sessionDescription!=undefined)? '' : 'false', 
          Length: (dd.Length!='' && dd.Length!=undefined)? '' : 'false',  }]

      })
      this.setState({session_details_listValid})

    }

    this.setState({
      // session_details_list: list_data,
      cost: cost_type,
      rate: rate,
      duration: this.props.pacdata.duration,
      introDuration: this.props.pacdata.introDuration,
      sortingOrder: this.props.pacdata.so
    },()=>{

      //Validation
      let {FromDataError} = this.state
      FromDataError.title = (this.state.title!='' && this.state.title!=undefined)? '' : 'false'
      FromDataError.description = (this.state.description!='' && this.state.description!=undefined)? '' : 'false'
      FromDataError.cost = (this.state.cost!='' && this.state.cost!=undefined)? '' : 'false'
      FromDataError.rate = (this.state.rate!='' && this.state.rate!=undefined)? '' : 'false'
      if(this.state.package_type !== "Multi Session")
      {
        FromDataError.duration = (this.state.duration!='' && this.state.duration!=undefined)? '' : 'false'
      }
      
      FromDataError.introDuration = (this.state.introDuration!='' && this.state.introDuration!=undefined)? '' : 'false'
      FromDataError.sortingOrder = (this.state.sortingOrder!='' && this.state.sortingOrder!=undefined)? '' : 'false'
      
      this.setState({FromDataError})

      //Validation
    });


    
    let Intromessage = await getHelpText('intro_field_text')
    this.setState({Intromessage})
    let requestChangDes =  await getHelpText('request_edit_help_text')
    this.setState({requestChangDes})



  }

  handleShow = (e) => {
    this.setState({ setShow: !this.state.setShow });
  };

  handleChange = (e) => {
    e.persist();
    
    if(e.target.name == 'sortingOrder'){

      var numbers = /^[1-9][0-9]*$/;

      console.log("Yes I am here", e.target.value)
      console.log("returning", numbers.test(e.target.value))
      if(numbers.test(e.target.value)){
        console.log("Yes correct")
        this.setState({sortingOrder: e.target.value})
      } else {
        console.log("not a number")
        this.setState({sortingOrder: ''})
      }

    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }

    var valid_obj = {
      value: e.target.value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg")
    }
    let {FromDataError} =this.state


    validation(valid_obj).then((error) => {


    
      FromDataError[e.target.name] = error
      this.setState({ FromDataError })

    })
  };

  handleSubmit = async(e, data) => {
    e.preventDefault();
    console.log("in update package", this.state.sortingOrder, this.props.pacdata.so)
    if(this.state.sortingOrder == null){
      await this.setState({sortingOrder: this.props.pacdata.so})
    }
    console.log("in update package after set state", this.state.sortingOrder, this.props.pacdata.so)

    if(this.validateForm(this.state.FromDataError))
    {
      var total_price = this.state.cost + "##" + this.state.rate;

    // console.log('session----detils before -----------', this.state.session_details_list)

    var raw_session = JSON.stringify(this.state.session_details_list);
    var list_data = JSON.parse(raw_session);
    var total_session_time = 1;
    var introDuration = this.state.introDuration


    if (this.state.package_type === "Single Session") {
      var total_session_time = this.state.duration;
    } else if (this.state.package_type === "Multi Session") {
      var total_session_time = 0;

      if (list_data) {
        list_data.map(
          (time) =>
            (total_session_time = total_session_time + parseInt(time.Length))
        );
      }
    } else {
      var total_session_time = this.state.duration;
    }

    let singleSessionObject=[{
      id:"0",
      sessionName:this.state.title,
      sessionDescription:this.state.description,
      Length:total_session_time
    }]

    const formData = {
      title: this.state.title,
      description: this.state.description,
      price: total_price,
      introDuration: introDuration,
      duration: total_session_time, //this.state.duration,
      isactive: this.state.isactive,
      package_type: this.state.package_type,
      total_sessions: this.state.package_type === "Single Session" ? 1 : this.state.total_sessions,
      // session_details: this.state.session_details,
      id: this.props.pack_id,
      session_details: this.state.package_type === "Single Session" ? JSON.stringify(singleSessionObject) : JSON.stringify(this.state.session_details_list),
      sortingOrder: this.state.sortingOrder,
    };
    
    if(this.props.pacdata.packDetails)
    {
      formData.uid = formData.id
      formData.so = formData.sortingOrder
       


      this.handleEditRequest(formData)
    }
    else
    {
      this.props.handleEditPackage(formData);
    }

    
    
    this.props.handleUpdateShow()

    }
    else
    {
      console.log('er',this.state.FromDataError)
      console.log('er',this.state.session_details_listValid)
      message.error("Please fill the form correctly");

    }
    
  };

  handleEditRequest = (data) => {
   
    
    
      const formData = {
        request_json:JSON.stringify(data),
        packageId: this.props.pack_id
      };
     
      axios.post(`/users/packages/package-edit-request`, formData, {
        headers: { "x-auth": this.props.token }
      }).then((res) => {
       
        if(res.data.success){
          message.success("Request sent successfully")
          
        }
        else {
     
        message.error("Error in submitting request")
      
        }
      }).catch((err) => {
       
        message.error("Some error occured")
      
      })
   
    
    
  }

  handleSessionChange = (e) => {
    alert(e);
  };

  handleAddClick = (i) => {
    console.log("prevv---state",this.state.session_details_list,this.state.session_details_list.length-1)
    let element=this.state.session_details_list.length-1
    let ID=Number(this.state.session_details_list[element].id)
    console.log("iddd---",ID)

    this.setState(prevState => ({ 
    	session_details_list: [...prevState.session_details_list, { id:ID+1, sessionName: "", sessionDescription: "", Length: "" }]
    }))

    this.setState(prevState => ({ 
    	session_details_listValid: [...prevState.session_details_listValid, { id:ID+1, sessionName: "false", sessionDescription: "false", Length: "false" }]
    }))

    
    this.ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  handleRemove = (i) => {
    let session_details_list = [...this.state.session_details_list];
     session_details_list.splice(i, 1);
     this.setState({ session_details_list });

     let session_details_listValid = [...this.state.session_details_listValid];
     session_details_listValid.splice(i, 1);
     this.setState({ session_details_listValid });

     
  };

  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.session_details_list];
    const listVal = [...this.state.session_details_listValid];
    list[index][name] = value;
    this.setState({ session_details_list: list });

    if(value=='')
    {
      listVal[index][name] = 'This field is required'
      this.setState({ session_details_listValid: listVal });
    }
    else
    {
      listVal[index][name] = ''
      this.setState({ session_details_listValid: listVal });


    }

    // console.log(this.state.session_details_list, "44444444444444444444");
  };

  render() {
    const sessionLength = this.props.sessionlength != undefined ? this.props.sessionlength : ''
    return (
      <>
        {/* <p
          className="dropdown-packgae"
          data-toggle="tooltip"
          data-placement="top"
          onClick={this.handleShow}
        >
          <i className="fa fa-edit"></i>{" "}Edit          
        </p> */}

        <Modal
          show={this.props.updateShow}
          onHide={this.props.handleUpdateShow}
          backdrop="static"
          keyboard={false}
        >
          <Form onSubmit={this.handleSubmit}>

            
            <Modal.Header closeButton>
              <Modal.Title style={{color:"black"}}>{this.props.pacdata.packDetails?'Request Edit':'Edit Session'}</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{color:"black"}}>
              <Form.Group>
                <Form.Label>Session Type</Form.Label>
                <input
                  style={{color:"black"}}
                  type="text"
                  className="form-control"
                  placeholder="Enter package type"
                  value={this.state.package_type}
                  onChange={this.handleChange}
                  name="package_type"
                  disabled={true}
                  
                />
              </Form.Group>

              <Form.Group>
                <Form.Label> Name</Form.Label>
                <Form.Control
                  style={{color:"black"}}
                  type="text"
                  placeholder="Enter name of package or program or multi sessions"
                  name="title"
                  value={this.state.title}
                  onChange={this.handleChange}
                  required={true}
                  validaterule={['required']}
                  validatemsg={['Enter Session Name']}
                  disabled={this.props.pacdata.packDetails}
                />
                <p style={{ color: 'red' }}>{this.state.FromDataError.title == 'false' ? '' :this.state.FromDataError.title }</p>
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Description
                  {this.props.pacdata.packDetails?(
                <Tooltip title={this.state.requestChangDes} placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>
                ):''}
                </Form.Label>
                <Form.Control
                  style={{color:"black"}}
                  as="textarea"
                  rows="4"
                  placeholder="Enter full description of package or program or multi sessions"
                  value={this.state.description}
                  onChange={this.handleChange}
                  name="description"
                  required={true}
                  validaterule={['required']}
                  validatemsg={['Enter Session Description']}
                  // disabled={this.props.pacdata.packDetails}
                />
                <p style={{ color: 'red' }}>{this.state.FromDataError.description == 'false' ? '' :this.state.FromDataError.description }</p>

              </Form.Group>

              {this.state.package_type === "Multi Session" ? (

                      <Form.Group>
                      <Form.Label>Introductory Session <small>(session time: length in minutes)<Tooltip title={this.state.Intromessage} placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip></small></Form.Label>
                      <select
                      style={{color:"black"}}
                      className="form-control"
                      value={this.state.introDuration}
                      name="introDuration"
                      placeholder="Enter intro duration of session"
                      onChange={this.handleChange}
                      validaterule={['required']}
                      validatemsg={['Select Intro Duration']}
                      disabled={this.props.pacdata.packDetails}
                      >
                      <option value="">Select duration</option>
                      {/* <option value="15">15 Minute</option>
                      <option value="30">30 Minute</option>
                      <option value="45">45 Minute</option>
                      <option value="60">60 Minute</option> */}
                      {sessionLength && sessionLength.split(",").map((each, i) => {
                    return (
                      <option value={each.trim()}>{each.trim()} Minute</option>
                    )
                  })}
                      </select>
                <p style={{ color: 'red' }}>{this.state.FromDataError.introDuration == 'false' ? '' : this.state.FromDataError.introDuration }</p>

                      </Form.Group>

                
              // <Form.Group>
              //   <Form.Label>Total Sessions</Form.Label>
              //   <Form.Control
              //     style={{color:"black"}}
              //     type="number"
              //     placeholder="Enter total number of Sessions"
              //     name="total_sessions"
              //     value={this.state.total_sessions}
              //     onChange={this.handleChange}
              //     required={true}
              //   />
              // </Form.Group>
              ) : (
                ""
              )}
              {this.state.package_type === "Single Session" ? (
                <div>

                  <Form.Group>
                    <Form.Label>Introductory Session <small>(session time: length in minutes)<Tooltip title={this.state.Intromessage} placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip></small></Form.Label>
                    <select
                  style={{color:"black"}}
                  className="form-control"
                  value={this.state.introDuration}
                  name="introDuration"
                  placeholder="Enter intro duration of session"
                  onChange={this.handleChange}
                  validaterule={['required']}
                  validatemsg={['Select Intro Duration']}
                  disabled={this.props.pacdata.packDetails}
                >
                  <option value="">Select duration</option>
                  {/* <option value="15">15 Minute</option>
                  <option value="30">30 Minute</option>
                  <option value="45">45 Minute</option>
                  <option value="60">60 Minute</option> */}
                  {sessionLength && sessionLength.split(",").map((each, i) => {
                    return (
                      <option value={each.trim()}>{each.trim()} Minute</option>
                    )
                  })}
                </select>
                <p style={{ color: 'red' }}>{this.state.FromDataError.introDuration == 'false' ? '' : this.state.FromDataError.introDuration }</p>

                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Length (in Minute)</Form.Label>
                    {/* <Form.Control
                      style={{color:"black"}}
                      placeholder="Enter duration of session"
                      name="duration"
                      value={this.state.duration}
                      onChange={this.handleChange}
                      required={true}
                    /> */}
                    <select
                  style={{color:"black"}}
                  className="form-control"
                  value={this.state.duration}
                  name="duration"
                  placeholder="Enter duration of session"
                  onChange={this.handleChange}
                  validaterule={['required']}
                  validatemsg={['Select Duration']}
                  disabled={this.props.pacdata.packDetails}
                >
                  <option value="">Select duration</option>
                  {/* <option value="15">15 Minute</option>
                  <option value="30">30 Minute</option>
                  <option value="45">45 Minute</option>
                  <option value="60">60 Minute</option> */}
                  {sessionLength && sessionLength.split(",").map((each, i) => {
                    return (
                      <option value={each.trim()}>{each.trim()} Minute</option>
                    )
                  })}
                </select>
                <p style={{ color: 'red' }}>{this.state.FromDataError.duration == 'false' ? '' : this.state.FromDataError.duration }</p>

                  </Form.Group>
                </div>
              ) : (
                ""
              )}

              <Form.Group>
                <Form.Label>Cost</Form.Label>
                <Row>
                  {}
                  <Col>
                    <div className="selectBox">
                      <select
                        style={{color:"black"}}
                        className="form-control"
                        // style={{ backgroundColor: "#8d8d8d"}}
                        name="cost"
                        value={this.state.cost}
                        onChange={this.handleChange}
                        validaterule={['required']}
                        validatemsg={['Select Any Currency']}
                        disabled={this.props.pacdata.packDetails}
                      >
                        <option value="">Select Currency</option>
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                        <option value="GBP">GBP</option>
                      </select>
                <p style={{ color: 'red' }}>{this.state.FromDataError.cost == 'false' ? '' : this.state.FromDataError.cost }</p>

                    </div>
                  </Col>
                  <Col>
                    <Form.Control
                      style={{color:"black"}}
                      type="number"
                      placeholder="Enter amount"
                      name="rate"
                      value={this.state.rate}
                      onChange={this.handleChange}
                      required={true}
                      validaterule={['required','notZero']}
                      validatemsg={['Enter a Amount','Please provide value greater than zero']}
                      disabled={this.props.pacdata.packDetails}
                    />
                <p style={{ color: 'red' }}>{this.state.FromDataError.rate == 'false' ? '' : this.state.FromDataError.rate }</p>

                  </Col>
                </Row>
              </Form.Group>

              <Form.Group>
                <Form.Label> Sorting Order<Tooltip title={this.props.sortingHelpText}> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip></Form.Label>
                <Form.Control
                  style={{color:"black"}}
                  type="text"
                  placeholder="Enter your sorting order"
                  name="sortingOrder"
                  //defaultValue={this.props.pacdata.so}
                  value={this.state.sortingOrder}
                  onChange={this.handleChange}
                  required={true}
                  validaterule={['required', `maxSortingOrderValue|${this.props.totalPackageCount}`]}
                  validatemsg={['This field is required', `maximum order limit ${this.props.totalPackageCount}`]}
                />
                <p style={{ color: 'red' }}>{this.state.FromDataError.sortingOrder == 'false' ? '' :this.state.FromDataError.sortingOrder }</p>
              </Form.Group>

              {this.state.package_type === "Multi Session" ? (
                <div>
                   <hr />
                  <h4 style={{ textAlign: "center" }}>Session Details</h4>
                   {this.state.session_details_list.map((x, i) => {
                  return (
                    <>
                      {this.state.session_details_list.length - 1 === i && !this.props.pacdata.packDetails && (
                        <i
                          className="fa fa-plus-square"
                          style={{
                            color: "green",
                            fontSize: "25px",
                            float: "right",
                            cursor:'pointer'
                          }}
                          onClick={() => this.handleAddClick(i)}
                        />
                      )}
                    </>
                  );
                })}
                 <br />

               
                  <hr />
                {/**/}
                  {this.state.session_details_list &&
                    this.state.session_details_list.map((x, i) =>
                    {
                      let validate=  this.state.session_details_listValid.filter((item) => item.id == x.id);
                      console.log('validate',validate)
                      validate = validate[0]
                      return(
                        <div ref={this.ref}>
                          
                          {/* <h6 style={{ textAlign: "center" }}>Edit Session Details</h6> */}
                          {this.props.pacdata.packDetails == false ? (
                            <h6 style={{ textAlign: "center" }}>Edit Session Details</h6>
                          ): ''}
  
                          {this.state.session_details_list.length > 1 && !this.props.pacdata.packDetails && (
                          <i
                            className="fa fa-minus-square"
                            style={{
                              color: "green",
                              fontSize: "25px",
                              float: "right",
                              cursor:'pointer'
                            }}
                            onClick={() => this.handleRemove(i)}
                          ></i>
                        )}
                          
                          <Form.Group>
                            <Form.Label>Session Name</Form.Label>
                            <Form.Control
                              style={{color:"black"}}
                              // id="inputID"
                              type="text"
                              placeholder="Enter session name"
                              name="sessionName"
                              value={x.sessionName}
                              onChange={(e) => this.handleInputChange(e, i)}
                              required={true}
                              disabled={this.props.pacdata.packDetails}
                            />
                             <p style={{ color: 'red' }}>{validate.sessionName == 'false' ? '' : validate.sessionName }</p>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Description
                            {this.props.pacdata.packDetails?(
                            <Tooltip title={this.state.requestChangDes} placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>
                            ):''}
                            </Form.Label>
                            <Form.Control
                              style={{color:"black"}}
                              // id="inputID"
                              as="textarea"
                              rows="4"
                              placeholder="Enter session description"
                              value={x.sessionDescription}
                              onChange={(e) => this.handleInputChange(e, i)}
                              name="sessionDescription"
                              required={true}
                              // disabled={this.props.pacdata.packDetails}
                            />
                           <p style={{ color: 'red' }}>{validate.sessionDescription == 'false' ? '' : validate.sessionDescription }</p>

                          </Form.Group>
  
                          <Form.Group>
                            <Form.Label>Length (in Minute)</Form.Label>
                            {/* <Form.Control
                              style={{color:"black"}}
                              
                              type="number"
                              placeholder="Enter session duration"
                              name="Length"
                              value={x.Length}
                              onChange={(e) => this.handleInputChange(e, i)}
                              required={true}
                            /> */}
                            <select
                              style={{color:"black"}}
                              className="form-control"
                              value={x.Length}
                              name="Length"
                              placeholder="Enter session duration"
                              onChange={(e) => this.handleInputChange(e, i)}
                              // required={true}
                              disabled={this.props.pacdata.packDetails}
                            >
                              <option value="">Select duration</option>
                              {/* <option value="15">15 Minute</option>
                              <option value="30">30 Minute</option>
                              <option value="45">45 Minute</option>
                              <option value="60">60 Minute</option> */}
                              {sessionLength && sessionLength.split(",").map((each, i) => {
                                return (
                                  <option value={each.trim()}>{each.trim()} Minute</option>
                                )
                              })}
                              
                            </select>
                         <p style={{ color: 'red' }}>{validate.Length == 'false' ? '' : validate.Length }</p>

                          </Form.Group>
  
                          <hr />
                        </div>
                      )
                    }) }
                </div>
              ) : (
                ""
              )}
              {/* <Form.Group>
                <Form.Label> Sorting Order<Tooltip title={this.props.sortingHelpText}> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip></Form.Label>
                <Form.Control
                  style={{color:"black"}}
                  type="text"
                  placeholder="Enter your sorting order"
                  name="sortingOrder"
                  //defaultValue={this.props.pacdata.so}
                  value={this.state.sortingOrder}
                  onChange={this.handleChange}
                  required={true}
                  validaterule={['required', `maxSortingOrderValue|${this.props.totalPackageCount}`]}
                  validatemsg={['This field is required', `maximum order limit ${this.props.totalPackageCount}`]}
                />
                <p style={{ color: 'red' }}>{this.state.FromDataError.sortingOrder == 'false' ? '' :this.state.FromDataError.sortingOrder }</p>
              </Form.Group> */}

              <hr />
            </Modal.Body>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={this.props.handleUpdateShow}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={this.handleSubmit}
              >
                {this.props.pacdata.packDetails? 'Request changes' : 'Save changes'}
                
              </button>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}
export default UpdatePackage;
