import React from 'react'
import {  Form, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from '../../../config/axios';
import { WeekList } from '../sidebarComponents';
import UpdateWeekList from './updateWeekList';
import message from '../../../utils/mesaages';
const _ = require("lodash");

class UpdateSetAvailability extends React.Component{
    constructor(props){
        super(props)
        this.state={
      Days:[],
      availableDays: [],
      FirstWeek:"",
      note:"",
      datepicker:"0000-00-00",
      ChangedForWeeks:"1"
        }
    }

    componentDidMount() {
      if (!_.isEmpty(this.props.dataset)) {
        this.setState({
              availableDays:this.props.dataset.availableDays,
              note:this.props.dataset.note
        });
       
      }
    }


    componentDidUpdate(prevProps) {
      if(prevProps.dataset !== this.props.dataset) {
        this.setState({
              availableDays:this.props.dataset.availableDays,
              note:this.props.dataset.note
        });

      }
    }

    handleChange=(e) =>{
        e.persist()
        this.setState({
          [e.target.name]:e.target.value
        })
      }
    
      HandleGetDays = (e) => {
        const index =
          this.state.Days.length > 0 ? this.state.Days.indexOf(e.target.id) : -1;
        if (e.currentTarget.checked) {
          if (index === -1) {
            this.setState({ Days: [...this.state.Days, e.target.id] });
          }
        }
        if (!e.currentTarget.checked) {
          this.setState({
            Days: [...this.state.Days.filter((element) => element !== e.target.id)],
          });
        }
      };

      handleWeekSchedule=(data) =>{
        //   console.log("update--data---",data)
        
         this.setState({FirstWeek:data[0]})
        
      }

      handleSubmit=(e) =>{
        let sameschedule
      
         sameschedule=[
          {Sundays:this.state.FirstWeek[0][0].From !=="" ? this.state.FirstWeek[0]:[]},
          {Mondays:this.state.FirstWeek[1][0].From !==""  ? this.state.FirstWeek[1] :[] },
          {Tuesdays:this.state.FirstWeek[2][0].From !==""  ? this.state.FirstWeek[2] :[] },
          {Wednesdays:this.state.FirstWeek[3][0].From !==""  ? this.state.FirstWeek[3] :[] },
          {Thursdays:this.state.FirstWeek[4][0].From !==""  ? this.state.FirstWeek[4] :[] },
          {Fridays:this.state.FirstWeek[5][0].From !==""  ? this.state.FirstWeek[5] :[] },
          {Saturdays:this.state.FirstWeek[6][0].From !==""  ? this.state.FirstWeek[6] :[] }
          ]
        
    
        let Schedule 
       
          Schedule={
            availableDays:sameschedule,
            note:this.state.note
           
          };
          
        e.preventDefault()
        const formData={
          
          schedule_details:Schedule,
          uid:this.props.availability_id,
          change_schedule_date:this.state.datepicker,
          changed_for_weeks:this.state.ChangedForWeeks
        }
    
        // console.log("update--formdata--",formData)
        axios.post('/coach/setavailability',formData,{
          headers:{'x-auth':this.props.token}
        })
        .then(res =>{
        //   console.log('ress------',res)
          if(res.data.success){
            message.success("Successfully updated availabilty");
            let data=JSON.parse(res.data.addAvailableData.schedule_details)
            this.props.handleUpdateAvailability()
          }else{
            message.error("Error in updating availabilty");
          }
        })
        .catch(err =>{
          console.log(err)
        })
      }

    render(){
      // console.log("----WEEsetDATA--------1",this.props.WeekSetdata)
        return(
            <div>
                <div className="modal fade" id="updateavailability" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-availability" role="document">
    <div className="modal-content ">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle" style={{color:"black"}}>Edit Availability</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">


      <form style={{color:"black"}}> 
          <div className="row">
             <div className="col-12">
              <div className="form-group" style={{ paddingLeft: "20px"}}>
              
              <Form.Label>Available Days</Form.Label>
              <Form.Row>
              <div className="areas d-flex flex-wrap mb-4">
              <Row>
              {_.find(this.props.daysAvailable,"Sundays")!==undefined && this.props.daysAvailable[0].Sundays.length > 0?(
                         <Col>
                         <Form.Check
                          style={{color:"black"}}
                           type="checkbox"
                           id="Sundays"
                           label="Sundays"
                           name="Sundays"
                           onChange={this.HandleGetDays}
                           defaultChecked
                           
                         />
                       </Col>
                ):(
                  <Col>
                  <Form.Check
                    style={{color:"black"}}
                    type="checkbox"
                    id="Sundays"
                    label="Sundays"
                    name="Sundays"
                    onChange={this.HandleGetDays}
                  />
                </Col>
                )}
                {_.find(this.props.daysAvailable,"Mondays")!==undefined && this.props.daysAvailable[1].Mondays.length > 0 ?(
                         <Col>
                         <Form.Check
                           type="checkbox"
                           id="Mondays"
                           label="Mondays"
                           name="Mondays"
                           onChange={this.HandleGetDays}
                           defaultChecked
                           style={{color:"black"}}
                           
                         />
                       </Col>
                ):(
                  <Col>
                  <Form.Check
                    type="checkbox"
                    id="Mondays"
                    label="Mondays"
                    name="Mondays"
                    onChange={this.HandleGetDays}
                    style={{color:"black"}}
                  />
                </Col>
                )}
         
         {_.find(this.props.daysAvailable,"Tuesdays")!==undefined && this.props.daysAvailable[2].Tuesdays.length > 0 ?(
                         <Col>
                         <Form.Check
                           type="checkbox"
                           id="Tuesdays"
                           label="Tuesdays"
                           name="Tuesdays"
                           onChange={this.HandleGetDays}
                           defaultChecked
                           style={{color:"black"}}
                           
                         />
                       </Col>
                ):(
                  <Col>
                  <Form.Check
                    type="checkbox"
                    id="Tuesdays"
                    label="Tuesdays"
                    name="Tuesdays"
                    onChange={this.HandleGetDays}
                    style={{color:"black"}}
                  />
                </Col>
                )}
                {_.find(this.props.daysAvailable,"Wednesdays")!==undefined && this.props.daysAvailable[3].Wednesdays.length > 0?(
                         <Col>
                         <Form.Check
                           type="checkbox"
                           id="Wednesdays"
                           label="Wednesdays"
                           name="Wednesdays"
                           onChange={this.HandleGetDays}
                           defaultChecked
                           style={{color:"black"}}
                         />
                       </Col>
                ):(
                  <Col>
                  <Form.Check
                    type="checkbox"
                    id="Wednesdays"
                    label="Wednesdays"
                    name="Wednesdays"
                    onChange={this.HandleGetDays}
                    style={{color:"black"}}
                  />
                </Col>
                )}
                {_.find(this.props.daysAvailable,"Thursdays")!==undefined && this.props.daysAvailable[4].Thursdays.length > 0?(
                         <Col>
                         <Form.Check
                           type="checkbox"
                           id="Thursdays"
                           label="Thursdays"
                           name="Thursdays"
                           onChange={this.HandleGetDays}
                           defaultChecked
                           style={{color:"black"}}
                         />
                       </Col>
                ):(
                  <Col>
                  <Form.Check
                    type="checkbox"
                    id="Thursdays"
                    label="Thursdays"
                    name="Thursdays"
                    onChange={this.HandleGetDays}
                    style={{color:"black"}}
                  />
                </Col>
                )}
              
              {_.find(this.props.daysAvailable,"Fridays")!==undefined && this.props.daysAvailable[5].Fridays.length > 0?(
                         <Col>
                         <Form.Check
                           type="checkbox"
                           id="Fridays"
                           label="Fridays"
                           name="Fridays"
                           onChange={this.HandleGetDays}
                           defaultChecked
                           style={{color:"black"}}
                         />
                       </Col>
                ):(
                  <Col>
                  <Form.Check
                    type="checkbox"
                    id="Fridays"
                    label="Fridays"
                    name="Fridays"
                    onChange={this.HandleGetDays}
                    style={{color:"black"}}
                  />
                </Col>
                )}
                {_.find(this.props.daysAvailable,"Saturdays")!==undefined && this.props.daysAvailable[6].Saturdays.length > 0?(
                         <Col>
                         <Form.Check
                           type="checkbox"
                           id="Saturdays"
                           label="Saturdays"
                           name="Saturdays"
                           onChange={this.HandleGetDays}
                           defaultChecked
                           style={{color:"black"}}
                         />
                       </Col>
                ):(
                  <Col>
                  <Form.Check
                    type="checkbox"
                    id="Saturdays"
                    label="Saturdays"
                    name="Saturdays"
                    onChange={this.HandleGetDays}
                    style={{color:"black"}}
                  />
                </Col>
                )}
              </Row>
              </div>
            </Form.Row>   
          </div>

          <div className="col-sm-4">
              <div className="form-group">
                <label>Change Schedule From</label>
                  <input style={{color:"black"}} 
                  className="form-control" 
                  type="date" name="datepicker" 
                  value={this.state.datepicker}
                  onChange={this.handleChange}/>
            </div>
          </div>
          <div className="col-sm-4">
              <div className="form-group">
                <label>Change Schedule For Weeks</label>
                  <select className="form-control" 
                  placeholder="Change Schedule For How Many Weeks"
                  value={this.state.ChangedForWeeks} 
                  name="ChangedForWeeks" 
                  onChange={this.handleChange}
                  style={{color:"black"}}>
                    <option defaultValue="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
            </div>
          </div>


        </div>

      </div>


   
          <UpdateWeekList
            handleWeekSchedule={this.handleWeekSchedule}
            handleChange={this.handleChange}
            statedata={this.state}
            daysAvailable={this.props.daysAvailable}
            token={this.props.token}
            handleSubmit={this.handleSubmit}
            coachUid={this.props.coachUid}

            WeekSetdata={this.props.WeekSetdata}
            />



       </form>


        
      </div>
     
    </div>
  </div>
</div>
            </div>
        )
    }
}

export default UpdateSetAvailability