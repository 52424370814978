import React from "react";
import { Modal, Form, Col, Row } from "react-bootstrap";
// import { Link } from "react-router-dom";

class UpdatePackage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: "",
      setShow: false,
      title: props.isEdit ? props.pacdata.title : "",
      description: props.isEdit ? props.pacdata.description : "",
      price: props.isEdit ? props.pacdata.price : "",
      isactive: "1",
      introDuration: "0",
      duration: "0",
      session_HH: props.isEdit ? Math.floor(props.pacdata.duration / 60) : "0",
      session_MM: props.isEdit
        ? Math.round(
            (props.pacdata.duration / 60 -
              Math.floor(props.pacdata.duration / 60)) *
              60
          )
        : "0",
      package_type: props.isEdit ? props.pacdata.package_type : "",
      total_sessions: props.isEdit ? props.pacdata.total_sessions : "",
      session_details: props.isEdit ? props.pacdata.session_details : "",

      session_details_list: [],
      cost: "",
      rate: "",
    };
  }

  componentDidMount() {
    // console.log(this.props.pacdata)
    var price = this.props.pacdata.price;
    var cost_type = price.split("##")[0];
    var rate = price.split("##")[1];

    if (this.props.pacdata.session_details !== "") {
      var list_data = JSON.parse(this.props.pacdata.session_details);
      this.setState({ session_details_list: list_data });
    }

    this.setState({
      // session_details_list: list_data,
      cost: cost_type,
      rate: rate,
      duration: this.props.pacdata.duration,
      introDuration: this.props.pacdata.introDuration
    });
  }

  handleShow = (e) => {
    this.setState({ setShow: !this.state.setShow });
  };

  handleChange = (e) => {
    e.persist();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e, data) => {
    e.preventDefault();
    var total_price = this.state.cost + "##" + this.state.rate;

    // console.log('session----detils before -----------', this.state.session_details_list)

    var raw_session = JSON.stringify(this.state.session_details_list);
    var list_data = JSON.parse(raw_session);
    var total_session_time = 1;
    var introDuration = this.state.introDuration


    if (this.state.package_type === "Single Session") {
      var total_session_time = this.state.duration;
    } else if (this.state.package_type === "Multi Session") {
      var total_session_time = 0;

      if (list_data) {
        list_data.map(
          (time) =>
            (total_session_time = total_session_time + parseInt(time.Length))
        );
      }
    } else {
      var total_session_time = this.state.duration;
    }

    const formData = {
      title: this.state.title,
      description: this.state.description,
      price: total_price,
      introDuration: introDuration,
      duration: total_session_time, //this.state.duration,
      isactive: this.state.isactive,
      package_type: this.state.package_type,
      total_sessions: this.state.package_type === "Single Session" ? 1 : this.state.total_sessions,
      // session_details: this.state.session_details,
      id: this.props.pack_id,
      session_details: JSON.stringify(this.state.session_details_list),
    };

    this.props.handleEditPackage(formData);
    this.handleShow();
  };

  handleSessionChange = (e) => {
    alert(e);
  };

  handleAddClick = (i) => {
    console.log("prevv---state",this.state.session_details_list,this.state.session_details_list.length-1)
    let element=this.state.session_details_list.length-1
    let ID=Number(this.state.session_details_list[element].id)
    console.log("iddd---",ID)

    this.setState(prevState => ({ 
    	session_details_list: [...prevState.session_details_list, { id:ID+1, sessionName: "", sessionDescription: "", Length: "" }]
    }))
  };

  handleRemove = (i) => {
    let session_details_list = [...this.state.session_details_list];
     session_details_list.splice(i, 1);
     this.setState({ session_details_list });
  };

  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.session_details_list];
    list[index][name] = value;
    this.setState({ session_details_list: list });

    // console.log(this.state.session_details_list, "44444444444444444444");
  };

  render() {
    return (
      <>
        <li
          className="popup-with-zoom-anim edit_item"
          data-toggle="tooltip"
          data-placement="top"
          onClick={this.handleShow}
        >
          <li className="fa fa-edit" style={{color:"black",position:"relative", right:"5px"}}>Edit
          </li>
        </li>

        <Modal
          show={this.state.setShow}
          onHide={this.handleShow}
          backdrop="static"
          keyboard={false}
        >
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title style={{color:"black"}}>Edit Session</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{color:"black"}}>
              <Form.Group>
                <Form.Label>Session Type</Form.Label>
                <input
                  style={{color:"black"}}
                  type="text"
                  className="form-control"
                  placeholder="Enter package type"
                  value={this.state.package_type}
                  onChange={this.handleChange}
                  name="package_type"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label> Name</Form.Label>
                <Form.Control
                  style={{color:"black"}}
                  type="text"
                  placeholder="Enter name of package or program or multi sessions"
                  name="title"
                  value={this.state.title}
                  onChange={this.handleChange}
                  required={true}
                />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  style={{color:"black"}}
                  as="textarea"
                  rows="4"
                  placeholder="Enter full description of package or program or multi sessions"
                  value={this.state.description}
                  onChange={this.handleChange}
                  name="description"
                  required={true}
                />
              </Form.Group>

              {this.state.package_type === "Multi Session" ? (

                      <Form.Group>
                      <Form.Label>Intro Length (in Minute)</Form.Label>
                      <select
                      style={{color:"black"}}
                      className="form-control"
                      value={this.state.introDuration}
                      name="introDuration"
                      placeholder="Enter intro duration of session"
                      onChange={this.handleChange}
                      >
                      <option value="">Select duration</option>
                      <option value="15">15 Minute</option>
                      <option value="30">30 Minute</option>
                      <option value="45">45 Minute</option>
                      <option value="60">60 Minute</option>
                      </select>
                      </Form.Group>

                
              // <Form.Group>
              //   <Form.Label>Total Sessions</Form.Label>
              //   <Form.Control
              //     style={{color:"black"}}
              //     type="number"
              //     placeholder="Enter total number of Sessions"
              //     name="total_sessions"
              //     value={this.state.total_sessions}
              //     onChange={this.handleChange}
              //     required={true}
              //   />
              // </Form.Group>
              ) : (
                ""
              )}
              {this.state.package_type === "Single Session" ? (
                <div>

                  <Form.Group>
                    <Form.Label>Intro Length (in Minute)</Form.Label>
                    <select
                  style={{color:"black"}}
                  className="form-control"
                  value={this.state.introDuration}
                  name="introDuration"
                  placeholder="Enter intro duration of session"
                  onChange={this.handleChange}
                >
                  <option value="">Select duration</option>
                  <option value="15">15 Minute</option>
                  <option value="30">30 Minute</option>
                  <option value="45">45 Minute</option>
                  <option value="60">60 Minute</option>
                </select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Length (in Minute)</Form.Label>
                    {/* <Form.Control
                      style={{color:"black"}}
                      placeholder="Enter duration of session"
                      name="duration"
                      value={this.state.duration}
                      onChange={this.handleChange}
                      required={true}
                    /> */}
                    <select
                  style={{color:"black"}}
                  className="form-control"
                  value={this.state.duration}
                  name="duration"
                  placeholder="Enter duration of session"
                  onChange={this.handleChange}
                >
                  <option value="">Select duration</option>
                  <option value="15">15 Minute</option>
                  <option value="30">30 Minute</option>
                  <option value="45">45 Minute</option>
                  <option value="60">60 Minute</option>
                </select>
                  </Form.Group>
                </div>
              ) : (
                ""
              )}

              <Form.Group>
                <Form.Label>Cost</Form.Label>
                <Row>
                  {}
                  <Col>
                    <div className="selectBox">
                      <select
                        style={{color:"black"}}
                        className="form-control"
                        // style={{ backgroundColor: "#8d8d8d"}}
                        name="cost"
                        value={this.state.cost}
                        onChange={this.handleChange}
                      >
                        <option value="Select cost">Select Currency</option>
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                        <option value="GBP">GBP</option>
                      </select>
                    </div>
                  </Col>
                  <Col>
                    <Form.Control
                      style={{color:"black"}}
                      placeholder="Enter amount"
                      name="rate"
                      value={this.state.rate}
                      onChange={this.handleChange}
                      required={true}
                    />
                  </Col>
                </Row>
              </Form.Group>

              {this.state.package_type === "Multi Session" ? (
                <div>
                   <hr />
                  <h4 style={{ textAlign: "center" }}>Session Details</h4>
                   {this.state.session_details_list.map((x, i) => {
                  return (
                    <>
                      {this.state.session_details_list.length - 1 === i && (
                        <i
                          className="fa fa-plus-square"
                          style={{
                            color: "black",
                            fontSize: "25px",
                            float: "right",
                          }}
                          onClick={() => this.handleAddClick(i)}
                        />
                      )}
                    </>
                  );
                })}
                 <br />

               
                  <hr />
                {/**/}
                  {this.state.session_details_list &&
                    this.state.session_details_list.map((x, i) => (
                      <div>
                        
                        <h6 style={{ textAlign: "center" }}>Edit Session Details</h6>

                        {this.state.session_details_list.length > 1 && (
                        <i
                          className="fa fa-minus-square"
                          style={{
                            color: "black",
                            fontSize: "25px",
                            float: "right",
                          }}
                          onClick={() => this.handleRemove(i)}
                        ></i>
                      )}
                        
                        <Form.Group>
                          <Form.Label>Session Name</Form.Label>
                          <Form.Control
                            style={{color:"black"}}
                            // id="inputID"
                            type="text"
                            placeholder="Enter session name"
                            name="sessionName"
                            value={x.sessionName}
                            onChange={(e) => this.handleInputChange(e, i)}
                            required={true}
                          />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                          <Form.Label>Description</Form.Label>
                          <Form.Control
                            style={{color:"black"}}
                            // id="inputID"
                            as="textarea"
                            rows="4"
                            placeholder="Enter session description"
                            value={x.sessionDescription}
                            onChange={(e) => this.handleInputChange(e, i)}
                            name="sessionDescription"
                            required={true}
                          />
                        </Form.Group>

                        <Form.Group>
                          <Form.Label>Length (in Minute)</Form.Label>
                          {/* <Form.Control
                            style={{color:"black"}}
                            
                            type="number"
                            placeholder="Enter session duration"
                            name="Length"
                            value={x.Length}
                            onChange={(e) => this.handleInputChange(e, i)}
                            required={true}
                          /> */}
                          <select
                  style={{color:"black"}}
                  className="form-control"
                  value={x.Length}
                  name="Length"
                  placeholder="Enter session duration"
                  onChange={(e) => this.handleInputChange(e, i)}
                  required={true}
                >
                  <option value="">Select duration</option>
                  <option value="15">15 Minute</option>
                  <option value="30">30 Minute</option>
                  <option value="45">45 Minute</option>
                  <option value="60">60 Minute</option>
                  
                </select>
                        </Form.Group>

                        <hr />
                      </div>
                    ))}
                </div>
              ) : (
                ""
              )}

              <hr />
            </Modal.Body>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={this.handleShow}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={this.handleSubmit}
              >
                Save changes
              </button>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}
export default UpdatePackage;
