import React, { Component } from "react";
import axios from "../../../config/axios";
import message from "../../../utils/mesaages";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";
import Slider from "react-slick";
import "./webinerDetails.css";
import ReactHtmlParser from "react-html-parser";
import {
  single_webiner,
  sw_clock,
  
  pc_facebook,
  pc_twitter,
  pc_linkedin,
  webiner1,
  Webiner_coach1,
  LGspinner,
} from "../../../assets/images";
import Practitioner from "../../../assets/images/practitioner.png";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button } from "react-bootstrap";
import TrialActivationModal from "../TrialActivationModal";

const timeFormat = "DD-MMMM-YYYY, hh:mm:ss a";

const settings = {
  dots: false,
  nav: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 820,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default class WebinerDetails extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("authToken") || localStorage.getItem("corporate_user_token");

    this.state = {
      showPaymentModal :  false,
      loader: false,
      coach_uid: "",
      id: "",
      webinerDetails: null,
      coachDetails: null,

      // SIMILAR WEBINAR
      similarWebinarLoader: false,
      similarWebinars: [],
    };
  }

  componentDidMount = async () => {
    let { coach_uid, id } = this.props.match.params;
    if (coach_uid && id) {
      await this.setState({ coach_uid, id });
      this.getWebinerDetails();
    }
  };

  getWebinerDetails = () => {
    this.setState({ loader: true });
    axios
      .get(
        `webinar/get-webiners-details?id=${this.state.id}&coach_uid=${this.state.coach_uid}`,
        {
          headers: {
            "X-Auth": this.token,
          },
        }
      )
      .then((res) => {
        this.setState({ loader: false });

        if (res.data.success) {
          let { webinerDetails, coachDetails } = res.data.data;
          if (webinerDetails) {
            this.getSimilarWebinae(webinerDetails.webinar_category_id);
            let deadline = moment.utc(webinerDetails.date_string).tz("America/New_York").format(timeFormat);

            let times = this.getTime(deadline);
            let isStart =
              times &&
                times.days <= 0 &&
                times.hours <= 0 &&
                times.minutes <= 0 &&
                times.seconds <= 0
                ? true
                : false;
            webinerDetails.deadline = deadline.split(",");
            webinerDetails.isStart = isStart;
          }
          if (coachDetails) {
            let location = coachDetails.address
              ? JSON.parse(coachDetails.address)
              : null;
            let social_media = coachDetails.social_media
              ? JSON.parse(coachDetails.social_media)
              : null;
            let coaching_category = coachDetails.coaching_category
              ? JSON.parse(coachDetails.coaching_category)
              : null;

            coachDetails.location = location;
            coachDetails.social_media = social_media;
            coachDetails.coaching_category = coaching_category;
          }
          this.setState({ webinerDetails, coachDetails });
          if (!webinerDetails) {
            message.warning(res.data.msg);
          }
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
        message.error("Something Went Wrong");
      });
  };

  getTime = (deadline) => {
    const time = Date.parse(moment(deadline, timeFormat)._d) - Date.now();
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const seconds = Math.floor((time / 1000) % 60);
    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  getSimilarWebinae = (topic) => {
    this.setState({ similarWebinarLoader: true });
    axios
      .get(`/webinar/get-similar-webiners?topic=${topic}&id=${this.state.id}`, {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        this.setState({ similarWebinarLoader: false });
        if (res.data.success) {
          this.setState({ similarWebinars: res.data.data.webiners });
        }
      })
      .catch((err) => {
        this.setState({ similarWebinarLoader: false });
        console.log(err);
        message.error("Something Went Wrong");
      });
  };

  scheduleWebinar = (webinar_id, coach_uid) => {
    if(this.props.isExpired){
      this.setState({showPaymentModal : true});
      return;
    }
    this.setState({ loader: true });
    axios
      .post(
        "/webinar/scheduled-webinars",
        { webinar_id, coach_uid },
        {
          headers: {
            "X-Auth": this.token,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.msg);
          this.getWebinerDetails();
        } else {
          this.setState({ loader: false });
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
        message.error("Something Went Wrong");
      });
  };

  joinWebinar = () => {
    this.setState({ loader: true });
    let webinar_id = this.state.id;
    axios
      .post(
        `/webinar/join-webinar`,
        {
          webinar_id,
        },
        {
          headers: {
            "x-auth": this.token,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          let url = res.data.bbb_joining_link;
          this.setState({ loader: false });
          window.open(url, "_blank");
        } else {
          this.setState({ loader: false });
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
        message.error("Something Went Wrong");
      });
  };

  render() {
    let { webinerDetails, coachDetails, similarWebinars } = this.state;
    return (
      <LoadingOverlay
        active={this.state.loader}
        //spinner
        spinner={
          <CustomImageLoader image={LGspinner} animationType={"pulse"} />
        }
        className="webiner_loader"
      >
        { this.state.showPaymentModal && <TrialActivationModal  close = {()=>{this.setState({showPaymentModal : false})}} show={Math.ceil()} /> }
        {webinerDetails && (
          <div className="content_wrap p-0">
            <div className="sw_banner">
              <div className="row">
                <div className="col-md-12 col-lg-6 sw_image">
                  <img
                    src={webinerDetails.image || single_webiner}
                    className="img-fluid w-100 h-100"
                  />
                </div>
                <div className="col-md-12 col-lg-6 sw_details">
                  <h2 className="sw_title">{webinerDetails.webinar_topic}</h2>
                  <p>{webinerDetails.script}</p>
                  <ul className="list-unstyled inc_list mb-4">
                    {!webinerDetails.isStart && (
                      <li>
                        <div className="inc_list_item d-flex">
                          <div className="inc_list_img">
                            <img width={20} src={sw_clock} />
                          </div>
                          <div className="inc_list_text col">
                            <strong>Upcoming Webinar :</strong>
                            {/* <div><small>({webinerDetails.deadline[0]})</small></div> */}
                            <div className="text-uppercase">
                              {/* <small>{webinerDetails.deadline[1]} 
                            {new Date().toLocaleString(Intl.LocalesArgument, { day: '2-digit', timeZoneName: 'short' }).slice(4)}
                            
                            </small> */}
                            <small style={{ fontSize: '0.7rem' }}> {moment.utc(webinerDetails.date_string).tz("America/New_York").format("D MMM, YYYY - h:mm A")}  <b>EST</b> </small>
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>

                  <div className="swb_btns">
                    {/* {webinerDetails.isStart ? (
                      <Button variant="default" className="defaultBtn mr-3" onClick={this.joinWebinar}>JOIN NOW</Button>
                    ) : (
                      <Button variant="secondary" className="mr-3" disabled> UPCOMING</Button>
                    )} */}

{webinerDetails.isSchedule ? (
  <Button variant="secondary" className="mr-3" disabled>
    SCHEDULED
  </Button>
) : (
  localStorage.getItem("authToken") === "undefined" && (
    <Button
      variant="default"
      className="mr-3 defaultBtn"
      onClick={() => {
        this.scheduleWebinar(this.state.id, this.state.coach_uid);
      }}
    >
      SIGN UP
    </Button>
  )
)}
                  </div>

                </div>
              </div>
            </div>

            {coachDetails && (
              <div className="practitioner">
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-md-12">
                    <h3 className="text-uppercase">
                      <strong>Practitioner</strong>
                    </h3>
                    <div className="pc_card">
                      <div className="row">
                        <div className="col-md-4 pc_image">
                          <img
                            src={coachDetails.avatar_path || Practitioner}
                            className="img-fluid rounded"
                            alt = "No Image"
                          />
                        </div>
                        <div className="col-md-8 pc_text">
                          <h2 className="pc_name">{coachDetails.name}</h2>
                          <div className="row mb-4">
                            {coachDetails.coaching_category?.length > 0 && (
                              <dl className="col-md-8 mb-0">
                                <dd className="mb-0">Areas of expertise</dd>
                                <dt>
                                  {coachDetails.coaching_category?.map(
                                    (category, key) => {
                                      return (
                                        <span
                                          className="badge badge_green"
                                          key={key}
                                        >
                                          {category.label}
                                        </span>
                                      );
                                    }
                                  )}
                                </dt>
                              </dl>
                            )}

                            {coachDetails.location?.country && (
                              <dl className="col-md-4 mb-0">
                                <dd className="mb-0">Location</dd>
                                <dt>{coachDetails.location?.country}</dt>
                              </dl>
                            )}
                          </div>
                          <p>
                          {ReactHtmlParser(coachDetails.long_bio || coachDetails.short_bio)}
                          </p>
                          <ul className="list-inline pc_share">
                            {coachDetails.social_media?.map((media, key) => {
                              return (
                                <>
                                  {media.URL && (
                                    <li className="list-inline-item" key={key}>
                                      <a href={media.URL} target="_blank">
                                        <img
                                          src={
                                            media.URL?.includes("facebook")
                                              ? pc_facebook
                                              : media.URL?.includes("linkedin")
                                                ? pc_linkedin
                                                : pc_twitter
                                          }
                                        />
                                      </a>
                                    </li>
                                  )}
                                </>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {
              !localStorage.getItem("corporate_user_token") && (
                <>
                  {/* <div>This is Scheduled Section</div> */}
                  <div className="similar_wbnr">
                    {similarWebinars.length > 0 && (
                      <h3>
                        <strong>Similar Webinars</strong>
                      </h3>
                    )}
                    <LoadingOverlay
                      active={this.state.similarWebinarLoader}
                      //spinner
                      spinner={<CustomImageLoader
                        image={LGspinner}
                        animationType={"pulse"} />}
                    >
                      <Slider {...settings} className="">
                        {similarWebinars.map((webinar, key) => {
                          return (
                            <div className="carousel-item " key={key}>
                              <Link
                                to={`/webinars/webinars-details/${webinar.coach_uid}/${webinar.id}`}
                              >
                                <div className="webiner_card">
                                  <img
                                    src={webinar.image || webiner1}
                                    className="img-fluid" />
                                  <div className="webiner_card_content text-light">
                                    <img
                                      width={60}
                                      height={60}
                                      src={webinar.coach_image || Webiner_coach1}
                                      className="img-fluid rounded-circle" />
                                    <h4 className="webiner_coach_name mt-2">
                                      {webinar.coach_name}
                                    </h4>
                                    <p className="webiner_coach_details">
                                      {webinar.coach_bio}
                                    </p>
                                    <h3
                                      className="text-uppercase webiner_name mb-0"
                                      style={{ color: "#fff" }}
                                      title={webinar.webinar_topic}
                                    >
                                      {webinar.webinar_topic}
                                    </h3>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          );
                        })}
                      </Slider>
                    </LoadingOverlay>
                  </div>
                </>
              )
            }
          </div>
        )}
      </LoadingOverlay>
    );
  }
}
