import React, { Component } from "react";
import "./webiner.css";
// import Calendar from "@toast-ui/react-calendar";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";
import { message } from "antd";
import axios from "../../../config/axios";
import { LGspinner, user } from "../../../assets/images";
import moment from "moment";

import Calendar from "tui-calendar";
import "tui-calendar/dist/tui-calendar.css";
import ReactPaginate from "react-paginate";
import RecordingModal from "./RecordingModal";
import { Tab, Tabs } from "react-bootstrap";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";

let dcalender;
export default class CorporateWebinar extends Component {
  constructor(props) {
    super(props);

    this.token = localStorage.getItem("authToken");
    this.calendar = Object;

    this.state = {
      limit: 4,
      page: 1,
      totalPages: "",

      session_limit: 4,
      session_page: 1,
      session_totalPages: "",

      coaches: [],
      loading: false,
      pagination: [],
      upcoming: [],
      completed: [],
      schedules: [],
      offset: moment().local().utcOffset(),
      curr_date: moment().format(),
      currentDate: "",
      grand_total: "",
      meetingID: "",
      show: false,
      modal: ''
    };
  }

  getScheduledWebinars = (date) => {
    this.setState({ loader: true });
    axios
      .get(`/webinar/get-scheduled-webinars?date=${date}`, {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        this.setState({ loader: false });
        if (res.data.success) {
          let calendarId = "1";
          let { webinars } = res.data.data;
          let schedules = webinars?.map((value) => {
            let date = new Date();
            if (new Date(value.date_time) > date) {
              calendarId = "2";
            } else {
              calendarId = "1";
            }
            return {
              id: value.id,
              calendarId: calendarId,
              category: "time",
              title: value.webinar_topic,
              start: new Date(value.date_time),
              isReadOnly: true,
            };
          });

          this.setState({ schedules });
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        message.error("Something went wrong");
      });
  };

  getCompletedWebinars = () => {
    this.setState({ loading: true })

    axios.get(`/webinar/completed-webinar-list-user?limit=${this.state.session_limit}&page=${this.state.session_page}`, { headers: { "x-auth": this.token } }).then(res => {
      this.setState({ loading: false });
      if (res.data.success) {
        this.setState({
          completed: res.data.completed_webinars,
          session_totalPages: res.data.total_pages,
          sessions_page: res.data.current_page,
          grand_total: res.data.grand_total
        });
      } else {
        message.error("Sessions fetch failed");
      }
    }).catch((error) => {
      this.setState({ loading: false });
      message.error("Webiner fetch failed");
    })
  }

  getUpcomingWebiners = () => {
    this.setState({ loading: true })
    axios.get(`/webinar/upcomeing-webinar-list-user`, { headers: { "x-auth": this.token } }).then(res => {
      this.setState({ loading: false });
      if (res.data.success) {
        this.setState({
          upcoming: res.data.upcoming,
        });
      } else {
        message.error("Sessions fetch failed");
      }
    }).catch((err) => {
      console.log("Fetch Error", err);
      this.setState({ loading: false });
      message.error("Sessions fetch failed");
    });
  }

  onSessionsPageChange = (page) => {
    let page1 = page.selected + 1;
    this.setState({ session_page: page1 }, () =>
      this.getCompletedWebinars()
    );
  };

  displayCalendar = () => {
    let div = document.getElementById("calendar");

    dcalender = new Calendar(div, {
      defaultView: "month",
      taskView: true, // Can be also ['milestone', 'task']
      scheduleView: true, // Can be also ['allday', 'time']
      useCreationPopup: false,
      useDetailPopup: true,
      isReadOnly: true,
      calendars: [
        {
          id: "1",
          name: "Archived",
          bgColor: "#9e5fff",
          dragBgColor: "#9e5fff",
          borderColor: "#9e5fff",
        },
        {
          id: "2",
          name: "Upcoming",
          bgColor: "#00e1ba",
          borderColor: "#00e1ba",
          dragBgColor: "#00e1ba",
        },
      ],
    });

    this.setState({
      currentDate: moment(dcalender.getDate()).format("MMMM-DD"),
    });
  };

  moveToNextOrPrevRange = async (val) => {
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    if (val === -1) {
      dcalender.prev();
      let date = dcalender.getDate().toDate();
      const qDate = moment(date).format("YYYY-MM-DD");

      await this.setState({
        currentDate: `${months[date.getMonth()]}-${date.getFullYear()}`,
        date: qDate,
      });
      this.getScheduledWebinars(qDate);
    } else if (val === 1) {
      dcalender.next();
      let date = dcalender.getDate().toDate();
      const qDate = moment(date).format("YYYY-MM-DD");
      await this.setState({
        currentDate: `${months[date.getMonth()]}-${date.getFullYear()}`,
        date: qDate,
      });
      this.getScheduledWebinars(qDate);
    }
  };

  joinWebinar = (webinar_id) => {
    this.setState({ loader: true });

    axios
      .post(
        `/webinar/join-webinar`,
        {
          webinar_id,
        },
        {
          headers: {
            "x-auth": this.token,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          let url = res.data.bbb_joining_link;
          this.setState({ loader: false });
          window.open(url, "_blank");
        } else {
          this.setState({ loader: false });
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
        message.error("Something Went Wrong");
      });
  };

  componentDidMount() {
    // this.getScheduledWebinars(moment().format("YYYY-MM-DD"));
    // this.displayCalendar();
    // this.getCompletedWebinars();
    // this.getUpcomingWebiners();
  }

  componentDidUpdate = () => {
    if (this.state.schedules.length > 0) {
      dcalender.clear();
      dcalender.createSchedules(this.state.schedules);
    }
  };

  handleShow = (meetingID) => {
    this.setState({ show: true })
    // this.setState({ modal: <RecordingModal show={true} handleClose={this.handleClose} Recordings={[]} /> })
    axios.post('/webinar/get-recording', { meetingID: meetingID }, { headers: { "x-auth": this.token, }, }).then((res) => {
      if (res.data.success) {
        this.setState({ modal: <RecordingModal show={true} handleClose={this.handleClose} Recordings={res.data.data ? res.data.data : []} /> })
      }
    }).catch((err) => {
      console.log(err)
      message.error("Something went wrong");
    })
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleSelect = (key) => {
    if (key === "Calendar") {
      document.getElementById("calendar").innerHTML = ""
      this.getScheduledWebinars(moment().format("YYYY-MM-DD"));
      this.displayCalendar()
    } else {
      document.getElementById("calendar").innerHTML = ""
      dcalender.clear();
      dcalender.createSchedules(this.state.schedules);
    }
  }

  render() {
    let { coaches, totalPages, page, upcoming, completed, offset, curr_date, session_page, session_totalPages, grand_total, Recordings } = this.state;
    return (
      <>
        <div className="content_main">
          <Header />

          <LoadingOverlay active={this.state.loader} className="webiner_loader" spinner={<CustomImageLoader image={LGspinner} animationType={"none"} />}>
            <div className="content_wrap">
              <div className="profile_wrap webiner_tab">
                <Tabs defaultActiveKey="Webinar_list" id="uncontrolled-tab-example" className="mb-3" onSelect={this.handleSelect}>
                  <Tab eventKey="Webinar_list" title="Webinar List">
                    <h4 className="page-title mt-5">Webinars</h4>

                    <div className="session_wrap upcoming">
                      {((upcoming?.length === 0) && (completed?.length === 0)) && (
                        <p> You have not registered to any webinars yet. Please go to the dashboard to sign up to a webinar </p>
                      )}
                      {upcoming?.length > 0 && (
                        <div className="session_type_title mb-3">Upcoming ({upcoming?.length})</div>
                      )}
                      {upcoming?.map((item, i) => (
                        <div to="/webinars/webinars-details">
                          <div className="session_item d-flex flex-wrap align-items-center">
                            <div className="session_user_image">
                              <img width={55} height={55} alt="" src={item.image ? item.image : user} className="img-fluid rounded-circle" />
                            </div>
                            <div className="session_user">
                              <div className="session_label">Presenter</div>
                              <div className="session_text">{item.coach_name}</div>
                            </div>
                            <div className="session_name">
                              <div className="session_label">Webinar name</div>
                              <div className="session_text">{item.webinar_topic}</div>
                            </div>
                            <div className="session_date">
                              <div className="session_label">Date</div>
                              <div className="session_text">
                                {/* {moment(item.date_time).add(offset, "m").format("DD-MM-YYYY")} */}
                                {moment(item.date_time).local().format("DD-MM-YYYY")}
                              </div>
                            </div>
                            <div className="session_time">
                              <div className="session_label">Time</div>
                              <div className="session_text">
                                {/* {moment(item.date_time).add(offset, "m").format("hh:mm a")} */}
                                {moment(item.date_time).local().format("hh:mm a")}
                              </div>
                            </div>
                            <div className="session_type">
                              {item.date_time > curr_date ? (
                                <div className="webinar_inner">
                                  <span>
                                    <button type="button" className="btn btn-primary" id={item.id} disabled> Upcoming </button>
                                  </span>
                                </div>
                              ) : (
                                <div className="webinar_inner">
                                  <span>
                                    <button type="button" className="join_button" id={item.id} onClick={() => this.joinWebinar(item.id)}> Start </button>
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="session_wrap completed">
                      {completed?.length > 0 && (
                        <div className="session_type_title mb-3">Completed ({grand_total}) </div>
                      )}

                      {completed?.map((item, i) => (
                        <div to="/webinars/webinars-details">
                          <div className="session_item d-flex flex-wrap align-items-center">
                            <div className="session_user_image">
                              <img width={55} alt="" height={55} src={item.image ? item.image : user} className="img-fluid rounded-circle" />
                            </div>
                            <div className="session_user">
                              <div className="session_label">Presenter</div>
                              <div className="session_text">{item.coach_name}</div>
                            </div>
                            <div className="session_name">
                              <div className="session_label">Webinar name</div>
                              <div className="session_text">{item.webinar_topic}</div>
                            </div>
                            <div className="session_date">
                              <div className="session_label">Date</div>
                              <div className="session_text">
                                {/* {moment(item.date_time).add(offset, "m").format("DD-MM-YYYY")} */}
                                {moment(item.date_time).local().format("DD-MM-YYYY")}
                              </div>
                            </div>
                            <div className="session_time">
                              <div className="session_label">Time</div>
                              <div className="session_text">
                                {/* {moment(item.date_time).add(offset, "m").format("hh:mm a")} */}
                                {moment(item.date_time).local().format("hh:mm a")}
                              </div>
                            </div>
                            <div className="session_type">
                              <div className="webinar_inner p-0">
                                <span>
                                  <button type="button" className="join_button" id={item.id} style={{ "whiteSpace": "nowrap" }} onClick={() => this.handleShow(item.bbb_meetingID)}>
                                    Get Recordings
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    {completed?.length > 0 && (
                      <div className="result_pagination mt-4">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel=">"
                          onPageChange={this.onSessionsPageChange}
                          pageRangeDisplayed={6}
                          pageCount={session_totalPages}
                          previousLabel="<"
                          activeClassName="active"
                          disabledClassName="disable"
                          renderOnZeroPageCount={null} />
                      </div>
                    )}
                  </Tab>
                  <Tab eventKey="Calendar" title="Calendar">
                    <div className="card_style_1">
                      <div className="card-header">
                        <h4 className="card-title">Webinar Schedule</h4>
                      </div>
                      <div className="booking__calendar">
                        <div className="ml-3 pt-3">
                          <span id="menu-navi">
                            <button type="button" className="btn btn-default btn-sm move-day" data-action="move-prev" onClick={() => { this.moveToNextOrPrevRange(-1); }}>
                              <i className="calendar-icon ic-arrow-line-left" data-action="move-prev"></i>
                            </button>
                            <button type="button" className="btn btn-default btn-sm move-day" data-action="move-next" onClick={() => { this.moveToNextOrPrevRange(1); }}>
                              <i className="calendar-icon ic-arrow-line-right" data-action="move-next"></i>
                            </button>
                          </span>
                          <span id="renderRange" className="render-range">
                            {this.state.currentDate}
                          </span>
                        </div>

                        <div id="calendar" className="mb-3"></div>
                      </div>
                    </div>
                  </Tab>

                </Tabs>
                {/* <div className="card_style_1"> */}


                {/*
      <div className="card-body">
        <Calendar
          height="600px"
          view="month"
          schedules={this.state.schedules}
          calendars={calendars}
          // scheduleView
          // taskView
          useDetailPopup
          isReadOnly={true}
        />
      </div> */}
                {/* </div> */}


              </div>
            </div>
            {(this.state.show === true) ? this.state.modal : ''}
          </LoadingOverlay>
          <Sidebar />
        </div>
      </>
    );
  }
}
