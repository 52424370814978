import React from 'react'
import { Row, Col, Card, Button, Form } from 'react-bootstrap'
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
// import LoadingOverlay from "react-loading-overlay";
// import CustomImageLoader from "react-custom-image-loader.";
import './Setting.css'
import axios from '../../../config/axios';
import { message } from 'antd';
import LoadingOverlay from 'react-loading-overlay';
import { LGspinner } from '../../../assets/images';
import CustomImageLoader from "react-custom-image-loader.";
import validation from '../../../Validate/validator';

class Setting extends React.Component {
    constructor(props) {
        super(props)
        this.token = localStorage.getItem("corporate_token")
        this.userDeta = JSON.parse(localStorage.getItem("corporate_userdata"))

        this.state = {
            loader: false,
            settings: {},
            revarsalDay: 0,
            FromDataError: {
                revarsalDay: "false",
            },
        }
    }

    GetRevalsalDayAndAmoutnt = async () => {
        this.setState({ loader: true })
        await axios.get(`/api/corporate_payment_setting_hr/?user_uid=${this.userDeta.uid}&user_id=${this.userDeta.id}`, { headers: { "x-Auth": this.token } }).then((response) => {
            if (response.data.success) {
                document.getElementById("revarsalDay").setAttribute('value', response.data.data.pending_amount_reversal_days)
                this.setState({ settings: response.data.data, revarsalDay: response.data.data.pending_amount_reversal_days, loader: false })
            }
        }).catch((error) => {
            this.setState({ loader: false })
            message.error(error.response.data.msg)
        })
    }

    validateForm(errors) {
        let valid = true;
        let sta = this;
        let FromDataError = this.state.FromDataError;

        for (const [key, value] of Object.entries(errors)) {
            if (value.length > 0) {
                if (value === "false") {
                    FromDataError[key] = "This field is required";
                    sta.setState({ FromDataError });
                }
                valid = false;
            }
        }
        return valid;
    }

    onHandleChange = (e) => {
        e.persist();
        let { FromDataError } = this.state;
        var valid_obj = {
            value: e.target.value,
            rules: e.target.getAttribute("validaterule"),
            message: e.target.getAttribute("validatemsg"),
        };

        validation(valid_obj).then((error) => {
            FromDataError[e.target.name] = error;
            this.setState({ FromDataError });

            setTimeout(() => {
                this.setState({
                    [e.target.name]: e.target.value,
                });
            }, 100);
        });
    }

    handleSubmit = (e) => {
        const hideMessage = message.loading("Please wait while update reversal day ...", 0);
        if (this.validateForm(this.state.FromDataError)) {
            this.setState({ loader: true });
            e.preventDefault();

            axios.post("/api/corporate_payment_setting_hr", { user_uid: this.userDeta.uid, user_id: this.userDeta.id, pending_amount_reversal_days: this.state.revarsalDay }, { headers: { "x-Auth": this.token } }).then(async (response) => {
                hideMessage();
                if (response.data.success) {
                    message.success(response.data.msg)
                    this.GetRevalsalDayAndAmoutnt()
                }
            }).catch((error) => {
                hideMessage();
                message.error(error.response.data.msg);
                this.setState({ loader: false });
            });
        }
    };

    componentDidMount() {
        this.GetRevalsalDayAndAmoutnt()
    }

    render() {
        const { loader, settings, FromDataError } = this.state
        return (
            <div className="content_main">
                <Header />
                <LoadingOverlay active={loader} className="webiner_loader" spinner={<CustomImageLoader image={LGspinner} animationType={"none"} />}>
                    <div className="content_wrap">
                        <h4 className="page-title mb-3">Transaction Setting</h4>

                        <Row className='justify-content-center'>
                            <Col md={6}>
                                <Card className="crCard border-0 my-4">
                                    <Card.Body>
                                        <Form onSubmit={this.handleSubmit}>
                                            <Form.Group>
                                                <Form.Label>Pending Amount Reversal days</Form.Label>
                                                <Form.Control id='revarsalDay' type='number' name='revarsalDay' required validaterule={["required", "numeric", "notZero", "lessThanOne"]} validatemsg={[]} onChange={this.onHandleChange} />
                                                <div style={{ color: "red" }}>
                                                    {FromDataError.revarsalDay === "false" ? "" : this.state.FromDataError.revarsalDay}
                                                </div>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Amount Per Employee</Form.Label>
                                                <Form.Control type='number' value={settings.amount_per_employee} disabled />
                                            </Form.Group>
                                            <Button variant='default' type="submit" className='defaultBtn'>Update</Button>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </LoadingOverlay>
                <Sidebar />
            </div>
        )
    }
}

export default Setting