import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
// import axios from 'axios';
import axios from '../../config/axios';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Badge, message, notification } from 'antd';
import { useHistory } from "react-router-dom";
import io from "socket.io-client";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

/* if you using webpack, should not apply identity to this css */
import Chatboxes from "../Communication/Chatboxes";
import './CommunicationUsers.css';
import { socketvar } from '../../config/socket'
import useSound from 'use-sound';
import mp3File from './beep-07.mp3'
import * as tt from './sound'
import { forEach } from '../../components/commonprofile/timezones';
import SessionRow from './Sessionrow';
import Loader from 'react-loader-spinner'
import { angelina } from '../../assets/img'
import { logo } from '../../assets/img'
// import message from '../../utils/mesaages';





import { avatarlifeguru, avatar } from "../../assets/img";
let Uuid = require("uuid");
const alertMSG = message

let socket = socketvar.socket;

// const beep = new UIFx({asset: mp3File});
// socket = io('http://localhost:3100');
let initailuseeffect = 0;
let cb = []
let cbnew = []

let usercopy = []
let oldinputs = [{ inputs: ['input-0'] }]
const customStyles = {
  content: {
    top: '10%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

let setschedulerconfirmationdata = null;
let sbid = null;
let schedulerreceiverid = null;
let schedulermessage = null;
let receivebookingnotification = null;
let openedboxesLocal = []
// export default function CommunicationUsers({props}) {
const CommunicationUsers = (props) => {
  const [users, setUsers] = useState([]);
  const [room, setRoom] = useState('');
  const history = useHistory();
  const [open, setopen] = useState(false);
  const [position, setposition] = useState('bottom');
  const [noOverlay, setnoOverlay] = useState(true);
  const [changed, setchanged] = useState(true);
  const [lt, setlt] = useState(false);
  const [chatboxusers, setchatboxusers] = useState([]);
  const [ismodelopen, setismodelopen] = useState(false);
  const [inputs, setinputs] = useState({ inputs: ['input-0'] });
  const [inputsvalues, setinputsvalues] = useState([]);
  const [scheduleruser, setscheduleruser] = useState(null);
  const [imageuser, setimageuser] = useState(null);

  const [Schedulertitle, setSchedulertitle] = useState('');
  const [showsetschedulerconfirmationdata, setshowsetschedulerconfirmationdata] = useState(false);
  const [packagedetails, setpackagedetails] = useState(null);
  const [packagedetailsloading, setpackagedetailsloading] = useState(null);
  const [starttime, setstarttime] = useState('23:00');
  const [endtime, setendtime] = useState('23:30');
  const [checkedsession, setcheckedsession] = useState(null);
  const [showmoadlloader, setshowmoadlloader] = useState(false);
  const [showcommunication, setshowcommunication] = useState(false);
  const [showcommunicationdataloaded, setshowcommunicationdataloaded] = useState(false);


  const [ischooseimagemodelopen, setischooseimagemodelopen] = useState(false);
  const [chatimagepreview, setchatimagepreview] = useState(null);
  const [chatimagefile, setchatimagefile] = useState(null);
  const [chatbyapi, setchatbyapi] = useState(false);
  const [openedboxes, setopenedboxes] = useState([]);
  const [getavatar, setavatar] = useState(avatar);
  const [totalunseen, settotalunseen] = useState(0);






  // console.clear()

  const [play, { stop }] = useSound(mp3File);
  const [playOn] = useSound(
    './beep-07.mp3',
    { volume: 10 }
  );


  let uu = [
    {
      createdD: "2020-12-30T10:02:00.000Z",
      createdT: "1609322575",
      email: "sunil.wgt@gmail.com",
      id: 1,
      name: "sunil1",
      password: "1234"
    },
    {
      createdD: "2020-12-30T10:02:00.000Z",
      createdT: "1609322575",
      email: "sunil.wgt@gmail.com",
      id: 1,
      name: "sunil2",
      password: "1234"
    }, {
      createdD: "2020-12-30T10:02:00.000Z",
      createdT: "1609322575",
      email: "sunil.wgt@gmail.com",
      id: 1,
      name: "sunil3",
      password: "1234"
    }, {
      createdD: "2020-12-30T10:02:00.000Z",
      createdT: "1609322575",
      email: "sunil.wgt@gmail.com",
      id: 1,
      name: "sunil4",
      password: "1234"
    }, {
      createdD: "2020-12-30T10:02:00.000Z",
      createdT: "1609322575",
      email: "sunil.wgt@gmail.com",
      id: 1,
      name: "sunil5",
      password: "1234"
    }, {
      createdD: "2020-12-30T10:02:00.000Z",
      createdT: "1609322575",
      email: "sunil.wgt@gmail.com",
      id: 1,
      name: "sunil6",
      password: "1234"
    }, {
      createdD: "2020-12-30T10:02:00.000Z",
      createdT: "1609322575",
      email: "sunil.wgt@gmail.com",
      id: 1,
      name: "sunil7",
      password: "1234"
    }, {
      createdD: "2020-12-30T10:02:00.000Z",
      createdT: "1609322575",
      email: "sunil.wgt@gmail.com",
      id: 1,
      name: "sunil8",
      password: "1234"
    }, {
      createdD: "2020-12-30T10:02:00.000Z",
      createdT: "1609322575",
      email: "sunil.wgt@gmail.com",
      id: 1,
      name: "sunil9",
      password: "1234"
    }, {
      createdD: "2020-12-30T10:02:00.000Z",
      createdT: "1609322575",
      email: "sunil.wgt@gmail.com",
      id: 1,
      name: "sunil10",
      password: "1234"
    }, {
      createdD: "2020-12-30T10:02:00.000Z",
      createdT: "1609322575",
      email: "sunil.wgt@gmail.com",
      id: 1,
      name: "sunil11",
      password: "1234"
    }, {
      createdD: "2020-12-30T10:02:00.000Z",
      createdT: "1609322575",
      email: "sunil.wgt@gmail.com",
      id: 1,
      name: "sunil",
      password: "1234"
    },
  ]




  socket.on('receivebookingnotification', function (data) {
    // you can show a progress bar with this percentage 
    receivebookingnotification = data.created_at;
    if (receivebookingnotification !== data.created_at) {
      let userdata = JSON.parse(localStorage.getItem('userdata'));
      if (userdata !== undefined || userdata !== null) {
        if (userdata.account_type === 'coach') {
          if (userdata.uid === data.receiver) {
            let user = cb.filter(el => el.uniqueroom === data.roomid);
            if (user.length > 0) {
              cb[cb.findIndex(check => check.uniqueroom === user[0].uniqueroom)].messages.push(data);
              setchatboxusers([...cb]);
            }
          }
        }

        if (userdata.account_type === 'user') {
          if (userdata.uid === data.sender) {
            let user = cb.filter(el => el.uniqueroom === data.roomid);
            if (user.length > 0) {
              cb[cb.findIndex(check => check.uniqueroom === user[0].uniqueroom)].messages.push(data);
              setchatboxusers([...cb]);
            }

          }
        }

      }
    }
  });


  socket.on('onjoinvisibleonline', email => {
    let user = usercopy.findIndex(el => el.email === email);
    if (user != -1) {
      usercopy[user].is_online = 1
      setUsers(usercopy);
      setchanged(!changed)
    }
  });


  socket.on('onjoinvisibleremoveonline', email => {
    let user = usercopy.findIndex(el => el.email === email);
    if (user != -1) {
      usercopy[user].is_online = 0
      setUsers(usercopy);
      setchanged(!changed)
    }
  });

  socket.on('uploadProgress', function (data) {
    // you can show a progress bar with this percentage 
    var pct = data.loaded / 1000;
  });

  useEffect(() => {

    let dd = JSON.parse(localStorage.getItem('userdata'));
    if (dd == undefined) {
      setshowcommunication(false)
      return
    } else {
      setshowcommunication(true);
      getprofiledetails(JSON.parse(localStorage.getItem('userdata')))

    }

    fetchallusers()
    socket.emit('onjoinvisible', JSON.parse(localStorage.getItem('userdata')));

    socket.on('message', message => {

      let user = cbnew.filter(el => el.uniqueroom === message.roomid);
      if (user.length > 0) {
        toggleDrawer()
        if (JSON.parse(localStorage.getItem('userdata')).uid !== message.sender) {
          openNotificationWithIcon(message)
          const audioEl = document.getElementsByClassName("audio-element")[0]
          if(audioEl === undefined) return
          audioEl.play()
        }
        let userroomid = user[0].uniqueroom;
        if (message.type === 'image' && message.sender === JSON.parse(localStorage.getItem('userdata')).uid) {
          let ums = cb[cb.findIndex(check => check.uniqueroom === user[0].uniqueroom)].messages

          let particularmessage = ums.findIndex(check2 => check2.key === message.sendimagekey)
          ums[particularmessage].showprogress = false
          setchatboxusers([...cb]);
        } else {
          handleopenchatboxonmessagereceived(message.sender)
          setlastmessage(message)
          if (cb[cb.findIndex(check => check.uniqueroom === user[0].uniqueroom)] != undefined) {
            cb[cb.findIndex(check => check.uniqueroom === user[0].uniqueroom)].messages.push(message);
            setchatboxusers([...cb]);
          }

        }


        if (message.type === 'scheduler') {
          if (message.receiver === JSON.parse(localStorage.getItem('userdata')).uid) {
            schedulerreceiverid = message.sender
            schedulermessage = message
          } else {
            schedulerreceiverid = null
          }
          sbid = message.sb_id !== undefined ? message.sb_id : null

        }

      }
    });
    socket.on('imageprogress', imageprogress => {
      let user = cb.filter(el => el.uniqueroom === imageprogress.roomid);
      if (user.length > 0 && imageprogress.sender === JSON.parse(localStorage.getItem('userdata')).uid) {
        let ums = cb[cb.findIndex(check => check.uniqueroom === user[0].uniqueroom)].messages

        let particularmessage = ums.findIndex(check2 => check2.key === imageprogress.key)
        ums[particularmessage].progress = imageprogress.progress
        setchatboxusers([...cb]);
      }

    })
    setupBeforeUnloadListener();

    return () => {
      socket.emit('onjoinvisibleremove', JSON.parse(localStorage.getItem('userdata')));
    };

  }, []);

  const openNotificationWithIcon = (m) => {
    let MSG = ''
    if (m.message) {
      MSG = m.message
    }
    else {
      MSG = m.text
    }
    if (MSG.search('"accesscode"') >= 0) {
      MSG = 'Meeting Scheduled'
    }

    notification['warning']({
      message: m.sendername ? m.sendername : '',
      description: MSG
    });
  };



  const handleopenchatboxonmessagereceived = (receiverid) => {
    let usercopynew = JSON.parse(JSON.stringify(usercopy))
    let index = usercopynew.findIndex((ele) => ele.uid == receiverid)
    if (index >= 0) {
      addmoremodal(usercopynew[index]);
    }
  }

  const getprofiledetails = (user) => {
    let data = {
      type: user.account_type,
      id: user.uid
    }

    axios.post('/communication/getprofiledetails', data, {
      headers: { 'x-auth': localStorage.getItem('authToken') }
    })
      .then(res => {

        if (res.data.success) {
          if (res.data.value[0].avatar_path != null) {
            setavatar(res.data.value[0].avatar_path)
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }

  const setlastmessage = (message) => {
    let usercopynew = JSON.parse(JSON.stringify(usercopy))
    let index = null
    usercopynew.forEach((ele, i) => {
      if (ele.coach_uid === message.receiver || ele.user_uid === message.receiver) {
        index = i
      }
    })
    if (index != null) {
      if (message.text !== undefined) {
        usercopynew[index].lastmessage = message.text
        usercopynew[index].messageseenstatus = "1"
        setUsers(usercopynew);
        usercopy = usercopynew
      }
    }
  };

  const setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      return doSomethingBeforeUnload();
    });
  };

  const doSomethingBeforeUnload = () => {
    socket.emit('onjoinvisibleremove', JSON.parse(localStorage.getItem('userdata')));
  }



  const fetchallusers = async () => {
    axios.post('/users/coach/paidmembers', { user: localStorage.getItem('userdata') }, {
      headers: { 'x-auth': localStorage.getItem('authToken') }
    })
      .then(res => {
        setshowcommunicationdataloaded(true)

        if (res.data.success) {
          for (let d of res.data.myusers) {
            d.online = false;
            d.profile_avatar = null
          }

          res.data.myusers.forEach((ele1, ind1) => {
            let compval = ele1.account_type === 'coach' ? 'coach_uid' : 'user_uid'
            if (res.data.profiledatas[0] !== null) {
              res.data.profiledatas.forEach((ele2, ind2) => {
                if (ele1.uid === ele2[compval]) {
                  ele1.profile_avatar = ele2.avatar_path
                }
              })
            }


          })

          if (res.data.myusers.length > 0) {

            setTimeout(() => {
              res.data.myusers.map((u) => {
                addalltosocket(u)
              })
            }, 2000);

          }
          setUsers(res.data.myusers);
          unseenmessages(res.data.myusers);
          usercopy = res.data.myusers
          if (usercopy.length > 0 && usercopy != null && usercopy != undefined) {
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }


  const unseenmessages = (pm) => {
    let unseen = 0;
    let cuid = JSON.parse(localStorage.getItem('userdata')).uid
    if (cuid === undefined) return
    pm.forEach((ele) => {
      if (ele.hasOwnProperty('allhistory')) {
        if (ele.allhistory[0].length > 0) {
          ele.allhistory[0].forEach((ele2) => {
            if (ele2.sender !== cuid && ele2.seen === '0') {
              unseen = Number(unseen) + 1
            }
          })
        }
      }
    })
    settotalunseen(unseen)
  }

  const startchat = (user) => {
    let myname = localStorage.getItem('chatusername')
    let myemail = localStorage.getItem('chatuseremail')
    let mychatusercreatedD = localStorage.getItem('chatusercreatedD')
    if (!mychatusercreatedD || mychatusercreatedD === null || mychatusercreatedD === undefined) return;
    let uniqueroom;
    let olduser = new Date(mychatusercreatedD) < new Date(user.createdD);
    if (olduser === true) {
      uniqueroom = `${myemail}-${user.email}`
    } else {
      uniqueroom = `${user.email}-${myemail}`

    }
    if (!uniqueroom) return

  }

  const setPosition = (e) => {
    setposition(e.target.value)
  }
  const setNoOverlay = (e) => {
    setnoOverlay(e.target.value)
  }

  const toggleDrawer = () => {
    setopen(!open)
  }

  const closeDrawer = () => {
    setopen(false)
  }
  const onDrawerClose = () => {
    setopen(false)
  }
  const fetchmessageforachatbox = async (chatroom, skip, limit) => {
    return await axios.post('/communication/chatsByRoomid', { room: chatroom, skip: skip, limit: limit }).catch((ex) => {
    })
  }

  const setallseen = async (data) => {
    let resp = await axios.post('/communication/setallseen', { data })
    if (resp && resp.data.success === true) {
      let usercopynew = JSON.parse(JSON.stringify(usercopy));
      usercopynew.forEach((ele) => {
        if (ele.coach_uid === data.id || ele.user_uid === data.id) {
          ele.messageseenstatus = "1"
        }
      })
      setUsers(usercopynew)
    }
  }

  const getuniqueroom = (user) => {
    let myemail = JSON.parse(localStorage.getItem('userdata')).email
    let mychatusercreatedD = JSON.parse(localStorage.getItem('userdata')).created
    if (!mychatusercreatedD || mychatusercreatedD === null || mychatusercreatedD === undefined) return;

    let uniqueroom;
    let olduser = new Date(mychatusercreatedD) < new Date(user.created);
    if (olduser === true) {
      return uniqueroom = `${myemail}-${user.email}`
    } else {
      return uniqueroom = `${user.email}-${myemail}`
    }
  }


  let addalltosocket = async (user) => {
    let isExist = false;
    let uniqueroom = getuniqueroom(user);
    let messages = []
    Object.assign(user, { inputvalue: '' })
    Object.assign(user, { uniqueroom: uniqueroom })
    Object.assign(user, { currentmail: JSON.parse(localStorage.getItem('userdata')).email })
    if (uniqueroom !== undefined) {
      let name = user.name;
      let room = uniqueroom;
      let email = user.email;
      cbnew.push(user)
      socket.emit('join', { name, room, email }, (error) => {
        if (error) {
          alert(error);
        }
      });
    }
  }


  let addmoremodal = async (user) => {
    let isExist = false;
    let uniqueroom = getuniqueroom(user);
    let messages = []
    Object.assign(user, { inputvalue: '' })
    Object.assign(user, { uniqueroom: uniqueroom })
    Object.assign(user, { currentmail: JSON.parse(localStorage.getItem('userdata')).email })
    if (uniqueroom !== undefined) {
      if (cb.length > 0) {
        cb.forEach((el) => {
          if (el.uniqueroom === uniqueroom) {
            isExist = true;
            return
          }
        })
      }
    }
    if (isExist) {
      return
    }
    if (uniqueroom !== undefined) {
      let name = user.name;
      let room = uniqueroom;
      let email = user.email;
      let uid = user.account_type == 'coach' ? user.coach_uid : user.user_uid

      setopenedboxes(openedboxes => [...openedboxes, { email, uid }])
      openedboxesLocal = [...openedboxes, { email, uid }]
      // socket.emit('join', { name, room, email }, (error) => {
      //   if (error) {
      //     alert(error);
      //   }
      // });
    }

    if (uniqueroom !== undefined) {
      messages = []
      setallseen({ uniqueroom: uniqueroom, id: user.uid })
      let resp = await fetchmessageforachatbox(uniqueroom, 0, 10);
      if (resp.status === 200 && resp.data.value !== undefined) {
        messages = resp.data.value.reverse()
      }
    }
    Object.assign(user, { messages: messages });
    cb.push(user)
    setchatboxusers(chatboxusers => [...chatboxusers, user])

  }


  let removechatbox = (user1) => {
    let allusers = []
    chatboxusers.forEach((el, index) => {
      if (el.email === user1.email) {
        setopenedboxes(openedboxes.filter((el) => el.email !== user1.email))
        openedboxesLocal = openedboxes.filter((el) => el.email !== user1.email)
      } else {
        allusers.push(el)
      }
    })
    cb = allusers
    setchatboxusers(allusers);
    socket.emit('joinremove', JSON.parse(localStorage.getItem('userdata')).email)
  }



  let setMessage = (value, user) => {
    chatboxusers[chatboxusers.findIndex(check => check.email === user.email)].inputvalue = value
    setchatboxusers(chatboxusers => [...chatboxusers])
  }


  let sendMessage = (user) => {
    let message = chatboxusers[chatboxusers.findIndex(check => check.email === user.email)].inputvalue
    if (message === '') return

    let data = {
      message: message,
      senderemail: JSON.parse(localStorage.getItem('userdata')).email,
      receiveremail: user.email,
      sender: JSON.parse(localStorage.getItem('userdata')).uid,
      sendername: JSON.parse(localStorage.getItem('userdata')).name,
      receiver: user.uid,
      uniqueroom: user.uniqueroom,
      created_at: sendmessgaedate(),
      updated_at: sendmessgaedate(),
      type: 'text'
    }
    socket.emit('sendMessage', data);
    chatboxusers[chatboxusers.findIndex(check => check.email === user.email)].inputvalue = ''
    setchatboxusers(chatboxusers => [...chatboxusers])
  }

  let onchatboxkeypress = (e, user) => {
    if (e.key === 'Enter') {
      sendMessage(user)
    }
  }

  let getuniversaldateforupload = function date(dd, stime) {
    let ssss = `${dd}T${stime}:00Z`
    let date = new Date(ssss);
    var ds = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    let y = ds.getFullYear();
    let m = ds.getMonth() + 1;
    if (m < 10) {
      m = '0' + m
    }
    let da = ds.getDate();
    if (da < 10) {
      da = '0' + da
    }
    let h = date.getHours();
    if (h < 10) {
      h = '0' + h
    }
    let mi = date.getMinutes();
    if (mi < 10) {
      mi = '0' + mi
    }
    let s = date.getSeconds();
    if (s < 10) {
      s = '0' + s
    }
    return `${y}-${m}-${da} ${h}:${mi}:${s}`
  }

  let sendmessgaedate = () => {
    let date = new Date();
    var ds = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    let y = ds.getFullYear();
    let m = ds.getMonth() + 1;
    if (m < 10) {
      m = '0' + m
    }
    let da = ds.getDate();
    if (da < 10) {
      da = '0' + da
    }
    let h = ds.getHours();
    if (h < 10) {
      h = '0' + h
    }
    let mi = ds.getMinutes();
    if (mi < 10) {
      mi = '0' + mi
    }
    let s = ds.getSeconds();
    if (s < 10) {
      s = '0' + s
    }
    return `${y}-${m}-${da} ${h}:${mi}:${s}`
  }
  const onfetchmorechats = async (u) => {
    setchatbyapi(true)

    let skip = u.messages.length;
    let room = u.uniqueroom;
    let resp = await fetchmessageforachatbox(room, skip, 10)
    if (resp.status === 200) {
      let index = cb.findIndex(check => check.uniqueroom === room)
      if (index != undefined && index != null) {
        let reversed = resp.data.value.reverse()
        cb[0].messages.unshift(...reversed);
        setchatboxusers([...cb]);
      }
      setchatboxusers([...cb]);
    }
    setTimeout(() => {
      setchatbyapi(false)

    }, 500);
  }


  let closeschedulemodal = () => {
    setismodelopen(false)
    setschedulerconfirmationdata = null
    setinputsvalues([])
  }


  let closeimagechoosemodal = () => {
    setischooseimagemodelopen(false)
  }
  const appendInput = (event) => {

    var newInput = `input-${inputs.inputs.length}`;
    oldinputs[0].inputs.push(newInput)
    setinputs(prevState => ({ inputs: prevState.inputs.concat([newInput]) }));
  }

  const removeinput = (key) => {
    let index = oldinputs[0].inputs.findIndex((el) => el === key);
    delete oldinputs[0].inputs[index];
    setinputs({ inputs: oldinputs[0].inputs })
  }

  const setinputdate = (e, key) => {
    let value = e.target.value;
    // setinputsvalues(oldArray => [...oldArray, { date: value, key: key }]);
    setinputsvalues([{ date: value, key: key }]);


  }
  // const sendscheduler = () => {


  //   packagedetails.parsedsession.forEach((el, i) => {
  //     if (el.checked === true) {
  //       let alldates = inputsvalues;
  //       let newmessgae = {
  //         selected: false,
  //         dates: inputsvalues,
  //         starttime: starttime,
  //         endtime: endtime,
  //         title: packagedetails.title,
  //         session: el,
  //         packagedetails: packagedetails,
  //         pack: packagedetails,
  //         sender: JSON.parse(localStorage.getItem('userdata')).uid,
  //         receiver: scheduleruser.uid
  //       }
  //       let data = {
  //         message: JSON.stringify(newmessgae),
  //         senderemail: JSON.parse(localStorage.getItem('userdata')).email,
  //         receiveremail: scheduleruser.email,
  //         sender: JSON.parse(localStorage.getItem('userdata')).uid,
  //         sendername: JSON.parse(localStorage.getItem('userdata')).name,
  //         receiver: scheduleruser.uid,
  //         uniqueroom: scheduleruser.uniqueroom,
  //         created_at: getuniversaldateforupload(newmessgae.dates[0].date, newmessgae.starttime),
  //         updated_at: getuniversaldateforupload(newmessgae.dates[0].date, newmessgae.starttime),
  //         type: 'scheduler',
  //         account_type: JSON.parse(localStorage.getItem('userdata')).account_type
  //       }


  //       // socket.emit('sendMessage', data);

  //       socket.emit('sendSchedule', data);

  //       // setismodelopen(false)
  //       closeschedulemodal()
  //     }
  //   })


  //   return



  // }

  const setSchedulertitles = (e) => {
    setSchedulertitle(e.target.value)
  }

  const onschedulerdateselect = (user, message, choosendate) => {
    if (schedulerreceiverid === null) {
      if (JSON.parse(message).sender === JSON.parse(localStorage.getItem('userdata')).uid) return
    } else {
      if (schedulerreceiverid === JSON.parse(localStorage.getItem('userdata')).uid) return
    }
    // if (JSON.parse(localStorage.getItem('userdata')).account_type === 'coach') return
    // if (JSON.parse(localStorage.getItem('userdata')).uid === user.uid) return
    if (user && user.account_type)
      setschedulerconfirmationdata = { user: user, message: message !== undefined ? JSON.parse(message) : '', choosendate: choosendate }
    setshowsetschedulerconfirmationdata(true)
  }

  const proceedschedulerconfirmation = () => {
    let data = {
      message: `Meeting Has been scheduled`,
      senderemail: JSON.parse(localStorage.getItem('userdata')).email,
      receiveremail: setschedulerconfirmationdata.user.email,
      sender: JSON.parse(localStorage.getItem('userdata')).uid,
      sendername: JSON.parse(localStorage.getItem('userdata')).name,
      receiver: setschedulerconfirmationdata.user.uid,
      uniqueroom: setschedulerconfirmationdata.user.uniqueroom,
      created_at: getuniversaldateforupload(),
      updated_at: getuniversaldateforupload(),
      type: 'text',
    }
    data.confirmationdata = setschedulerconfirmationdata;
    if (data.confirmationdata.message === '') {

      data.confirmationdata.message = JSON.parse(schedulermessage.text)
    }
    data.session_book_uid = setschedulerconfirmationdata.message.session_book_Uid
    if (data.session_book_uid === undefined) {
      data.session_book_uid = sbid
    }

    socket.emit('sendConfirmation', data);
    sendmeetingschedulemail(data)
    setschedulerconfirmationdata = null
    setshowsetschedulerconfirmationdata(false);

  }

  const sendmeetingschedulemail = async (data) => {
    // const url = 'http://localhost:3100/communication/sendmeetingschedulmail';
    // let url = 'https://apistaging.coachmentor.io/communication/sendmeetingschedulmail'
    // let url = 'https://api.coachmentor.io/communication/sendmeetingschedulmail'

    let d = {
      data: data,
      meetinginfo: setschedulerconfirmationdata,
      userinfo: JSON.parse(localStorage.getItem('userdata'))
    }
    await axios.post('/communication/sendmeetingschedulmail', d).then((res) => {
    }).catch((err) => {
      console.log('err', err)
    })
      ;
  }


  const test = () => {
  }

  let openschedulemodal = (user) => {
    setismodelopen(true)
    setscheduleruser(user);
    fetchactivepackageofuser(user)
  }

  let openimagechoosemodal = (user) => {
    setischooseimagemodelopen(true)
    setimageuser(user)
    // fetchactivepackageofuser(user)
  }
  let openbase64 = (base) => {
    var newTab = window.open();
    newTab.document.body.innerHTML = `'<img src="${base}" width="100%" height="100%">'`;
  }

  const fetchactivepackageofuser = async (user) => {
    // let url = 'https://apistaging.coachmentor.io/communication/fetchactivepackage'
    // let url = 'https://api.coachmentor.io/communication/fetchactivepackage'
    // const url = 'http://localhost:3100/communication/fetchactivepackage';

    setshowmoadlloader(true)

    let data = {
      user_uid: user.uid,
      coach_uid: JSON.parse(localStorage.getItem('userdata')).uid
    }
    if (JSON.parse(localStorage.getItem('userdata')).account_type === 'user') {
      data = {
        user_uid: JSON.parse(localStorage.getItem('userdata')).uid,
        coach_uid: user.uid,
      }
    }



    return await axios.post('/communication/fetchactivepackage', data).then((res) => {
      setshowmoadlloader(false)
      if (res.data.success === true && res.data.value.length > 0) {
        let packages = res.data.value[0];
        let ses = res.data.val;
        if (packages.session_details != undefined) {
          let session = JSON.parse(packages.session_details);
          packages['parsedsession'] = session
          session.forEach((el, i) => {
            if (ses.length > 0) {
              ses.forEach((l, n) => {
                let elid = el.id.toString()
                let lid = l.session_id.toString()
                if (elid === lid) {
                  if (l.status === 0) {
                    el['checked'] = false
                    el['disabled'] = true
                    el['statuss'] = 'Awaiting'
                    el['visible'] = true

                  }
                  if (l.status === 1) {
                    el['checked'] = false
                    el['disabled'] = false
                    el['statuss'] = 'Confirmed'
                    el['visible'] = false
                  }

                }
              })
            } else {
              if (i === 0) {
                el['checked'] = false
                el['disabled'] = false
                el['statuss'] = 'Not Scheduled'
                el['visible'] = true
              } else {
                el['checked'] = false
                el['disabled'] = false
                el['statuss'] = 'Not Scheduled'
                el['visible'] = false
              }

            }
          })


          let confirmedindex = null;

          session.forEach((p, i) => {
            if (p.statuss === 'Confirmed') {
              confirmedindex = i
            }
          })
          if (confirmedindex != null) {
            session.forEach((p, i) => {
              if (i === confirmedindex + 1) {
                session[i]['visible'] = true
              } else {
                session[i]['visible'] = false
              }
            })
          }

        } else {
          packages['parsedsession'] = null
        }

        setpackagedetails(packages)
      }
    })
      .catch((ex) => {
        setshowmoadlloader(false)
        // throw new Error
      })
  }


  const handlesessioncheck = (e, ele) => {
    let pp = JSON.parse(JSON.stringify(packagedetails));
    /////////////-----------------------////////////////////
    pp.parsedsession.forEach((el, i) => {
      if (el.sessionName === ele.sessionName) {
        pp.parsedsession[i].checked = e.target.checked
        setcheckedsession(el)
      } else {
        pp.parsedsession[i].checked = false
      }
    })
    setpackagedetails(pp)

  }

  const setstarttimetest = (e) => {
    setstarttime(e);
    let s = e.split(':')
    let h = Number(s[0])
    let m = Number(s[1])
    var today = new Date();

    var myToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), h, m, 0);
    if (checkedsession !== null) {
      myToday.setMinutes(myToday.getMinutes() + Number(checkedsession.Length));
      let H = myToday.getHours()
      let M = myToday.getMinutes()
      setendtime(H + ':' + M)
    }
  }




  const onchatimageselect = (e) => {
    if (e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png'
      || e.target.files[0].type === 'image/gif'
      || e.target.files[0].type === 'image/x-png'
    ) {
      setchatimagefile(e.target.files[0])
      var reader = new FileReader();
      var url = reader.readAsDataURL(e.target.files[0]);
      reader.onloadend = function (e) {
        setchatimagepreview(reader.result)
      }
    } else {
      setchatimagefile(null)
    }



  }
  const sendimage = (e) => {
    setischooseimagemodelopen(false)
    if (chatimagefile === null) return
    let key = Uuid.v4()
    let data = {
      message: chatimagefile,
      message64: chatimagepreview,
      key: key,
      progress: 5,
      showprogress: true,
      senderemail: JSON.parse(localStorage.getItem('userdata')).email,
      receiveremail: imageuser.email,
      sender: JSON.parse(localStorage.getItem('userdata')).uid,
      sendername: JSON.parse(localStorage.getItem('userdata')).name,
      receiver: imageuser.uid,
      uniqueroom: imageuser.uniqueroom,
      roomid: imageuser.uniqueroom,
      created_at: sendmessgaedate(),
      updated_at: sendmessgaedate(),
      type: 'sendimage',
      account_type: JSON.parse(localStorage.getItem('userdata')).account_type
    }

    let emitdata = {
      message: chatimagefile,
      key: key,
      senderemail: JSON.parse(localStorage.getItem('userdata')).email,
      receiveremail: imageuser.email,
      sender: JSON.parse(localStorage.getItem('userdata')).uid,
      sendername: JSON.parse(localStorage.getItem('userdata')).name,
      receiver: imageuser.uid,
      uniqueroom: imageuser.uniqueroom,
      roomid: imageuser.uniqueroom,
      created_at: sendmessgaedate(),
      updated_at: sendmessgaedate(),
      type: 'sendimage',
      account_type: JSON.parse(localStorage.getItem('userdata')).account_type
    }


    socket.emit('sendImage', emitdata);
    cb[cb.findIndex(check => check.uniqueroom === imageuser.uniqueroom)].messages.push(data);
    setchatboxusers([...cb]);
  }

  const upload = (file, onUploadProgress) => {
    let formData = new FormData();

    formData.append("chatimage", file);

    axios({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
      url: 'http://localhost:3100/communication/uploadchatimage',
    })
  };


  const redirectbookschedule = (bookD) => {
    let url = `${window.location.origin}/session-booking/${bookD.coach_uid}/${bookD.uid}`
    window.open(url, '_blank')
  }


  const testw = () => {
  }
 return <></>;
  return (


    <div>
      {showcommunication && showcommunicationdataloaded ?






        <div style={{height: 0}}>



          <Chatboxes
            users={chatboxusers}
            removechatbox={removechatbox}
            setmessage={setMessage}
            sendmessage={sendMessage}
            keypressed={onchatboxkeypress}
            fetchmorechats={onfetchmorechats}
            openschedulemodal={openschedulemodal}
            closeschedulemodal={closeschedulemodal}
            modalshow={ismodelopen}
            onschedulerdateselect={onschedulerdateselect}
            openimagechoosemodal={openimagechoosemodal}
            openbase64={openbase64}
            ischatbyapi={chatbyapi}
          >
          </Chatboxes>

          <div>


            <Modal
              size="xl"

              show={ismodelopen}
              onHide={closeschedulemodal}
              // backdrop="static"
              // keyboard={false}
              style={{ color: 'black' }}
            >
              <Modal.Header closeButton>
                <Modal.Title><h3>Schedule Meeting</h3></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* <h6>My Schedule Timings:</h6> */}


                {showmoadlloader === true ?
                  <Loader style={{ marginLeft: '30%' }}
                    type="Oval"
                    color="#00BFFF"
                    height={100}
                    width={100}
                  //  timeout={3000} //3 secs

                  />
                  :
                  <div>
                    <h4>Package Details</h4>
                    {packagedetails !== null ?
                      <div>
                        <div>Package Title: {packagedetails.title}</div>
                        <div>Package Type: {packagedetails.package_type}</div>


                        <div style={{ marginLeft: '45%' }}>
                          <Button variant="primary" onClick={() => redirectbookschedule(packagedetails)}>
                            Schedule
          </Button>
                        </div>

                      </div>


                      : ''
                    }
                    <br />
                    {/* <h5>Session Details</h5>
                    {packagedetails !== null ?
                      <div>
                        {packagedetails.parsedsession !== null ?
                          <div>
                            {packagedetails.parsedsession.map((el) => {
                              return <div><SessionRow session={el} handlesessioncheck={handlesessioncheck}></SessionRow><hr /></div>
                            })}
                          </div>
                          : ''}
                      </div>

                      : ''
                    } */}
                  </div>
                }






                {/* <Form>
                  <Row>
                    <Col>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Date</Form.Label>

                        {inputs !== undefined ?
                          '' : ''}
                        {inputs.inputs.map(input =>

                          <div className="d-flex justify-content-between align-items-center added_date">
                            <Form.Control style={{ color: 'black' }} type="date" key={input} onChange={(e) => setinputdate(e, input)} />
                          </div>

                        )}
                        <br></br>
                      </Form.Group>
                    </Col>
                    <Col style={{ marginTop: '3%' }}>
                      <Form.Label>Start Time:</Form.Label>
                      <TimePicker
                        onChange={setstarttimetest}

                        value={starttime}
                      />
                    </Col>
                    <Col style={{ marginTop: '3%' }}>
                      <Form.Label>End Time:</Form.Label>

                      <TimePicker
                        onChange={setendtime}
                        value={endtime}
                        disabled={true}
                      />
                    </Col>
                  </Row>
                </Form> */}



              </Modal.Body>
              <br />

              <Modal.Footer>
                <Button variant="secondary" onClick={closeschedulemodal}>
                  Close
          </Button>
                {/* <Button variant="primary" onClick={sendscheduler}>Send</Button> */}
              </Modal.Footer>
            </Modal>





            <Modal
              // size="sm"
              show={ischooseimagemodelopen}
              onHide={closeimagechoosemodal}
              style={{ color: 'black' }}
            >
              <Modal.Header closeButton>
                <Modal.Title><h3>Choose Image File</h3></Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <Form>
                  <Row>
                    <Col>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>Please select image File</Form.Label>
                        <input
                          type="file"
                          label="Select Image file"
                          name="avatar_path"
                          onChange={onchatimageselect}
                          accept="image/x-png,image/gif,image/jpeg"
                        />
                        {/* <Form.Control style={{ color: 'black' }} type="file" onChange={} /> */}
                      </Form.Group>
                    </Col>
                    <Col id="for-image-preview">
                      {chatimagepreview !== null ? <div><img alt='Preview' style={{ width: '100px', height: '100px' }} src={chatimagepreview}></img></div> : ''}
                    </Col>

                  </Row>

                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeimagechoosemodal}>
                  Close
          </Button>
                <Button variant="primary" onClick={sendimage}>Send Image</Button>
              </Modal.Footer>
            </Modal>




          </div>
          <div>
            <Modal
              show={showsetschedulerconfirmationdata}
              // onHide={handleClose}
              // backdrop="static"
              // keyboard={false}
              style={{ color: 'black' }}
            >
              <Modal.Header closeButton>
                <Modal.Title><h3>Confirmation</h3></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h6>Are you sure you want to continue with this date &nbsp;
                   {setschedulerconfirmationdata !== null ? setschedulerconfirmationdata.choosendate.date : ''} ?</h6>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={() => { proceedschedulerconfirmation() }}>Yes</Button>
                <Button variant="secondary" onClick={() => { setshowsetschedulerconfirmationdata(false) }}>
                  NO
          </Button>

              </Modal.Footer>
            </Modal>
          </div>
          <div>
            <audio className="audio-element">
              {/* <source src="https://assets.coderrocketfuel.com/pomodoro-times-up.mp3"></source> */}
              <source src={mp3File}></source>
            </audio>
          </div>
          <br></br>

          <div id='main-list-div' className={open ? 'openclassname tour-chatBox' : 'closeclassname tour-chatBox'} >
            <div className="top_bar">
              <Row className="m-0">
                <Col xs="10" md="10" sm="10" className="d-flex align-items-center p-0">
                  <div className="image_block">


                    <img src={getavatar} alt='Image' className="img-fluid rounded" />

                    {/* {props.avatar_path === '/static/media/avatar.b15acc02.png' ?
                      <img src={avatar} alt='Image' className="img-fluid rounded" />
                      : <div>
                        {props.avatar_path === null ?
                          <img src={avatar} alt='Image' className="img-fluid rounded" />
                          :
                          <img src={props.avatar_path} alt='Image' className="img-fluid rounded" />}


                      </div>
                    } */}


                    {/* <img src='https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg' className="img-fluid rounded" /> */}
                  </div>
                  <span className="ml-2">Messaging </span><span className="ml-2" style={{ color: '#00e1ba' }}>{totalunseen}</span>
                  {/* <button onClick={testw}>test</button> */}

                  {/* <Badge count={2}>
                    <span className="head-example" />
                  </Badge> */}
                </Col>
                <Col xs="2" md="2" sm="2" className="p-0 d-flex align-items-center justify-content-center">
                  <span onClick={toggleDrawer} className="drop_icon"><i className="fa fa-angle-double-up"></i></span>
                </Col>
                <Col className="p-0 search_form">
                  <Form>
                    <Form.Group controlId="formBasicEmail" className="mt-3 mb-0">
                      <Form.Control type="email" placeholder="Search Message" />
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </div>

            <div className="item_list">
              {users.map((u) => {
                return <div className="item" key={u.id} onClick={() => addmoremodal(u)}>
                  <Row className="m-0" >
                    <Col xs="10" md="10" sm="10" className="d-flex align-items-center p-0">
                      <div className="image_block_items">

                        <div >
                          {u.profile_avatar === null ?
                            <img src={avatar} />
                            :
                            // <img src={u.avatar_path} alt="profile image" />
                            <img src={u.profile_avatar} alt="profile image" />
                          }
                        </div>
                      </div>
                      {u.name ? (<>
                        <div className="d-flex flex-column ml-1">
                          <span>{u.name}</span>
                          {/* <p className="sp_info m-0">{u.lastmessage ? u.lastmessage :'No message...'}<span>1w</span></p> */}
                          {u.lastmessage != undefined && (<>
                            {u.lastmessage.search('"accesscode"') >= 0 ? (<>
                              {u.messageseenstatus === "0" ?
                                <p style={{ fontWeight: 'bold' }} className="sp_info m-0">Meeting Schedule<span>1w</span></p>
                                :
                                <p className="sp_info m-0">Meeting Schedule<span>1w</span></p>
                              }



                            </>) : (<>
                              {u.messageseenstatus === "0" ?
                                <p className="sp_info m-0" style={{ fontWeight: 'bold' }}>{u.lastmessage.substring(0, 10)}{u.lastmessage.length > 10 ? '...' : ''}<span>1w</span></p>
                                : <p className="sp_info m-0">{u.lastmessage.substring(0, 10)}{u.lastmessage.length > 10 ? '...' : ''}<span>1w</span></p>
                              }

                            </>)}
                          </>)}
                        </div>
                      </>) : (<></>)}
                    </Col>
                    <Col xs="2" md="2" sm="2" className="p-0 d-flex align-items-center justify-content-between">
                      <div className="list_item_option">
                        <svg version="1.1" id="Capa_1" width="1em" height="1em" x="0px" y="0px" viewBox="0 0 426.667 426.667" ><g><g><circle cx="42.667" cy="213.333" r="42.667" /></g></g><g><g><circle cx="213.333" cy="213.333" r="42.667" /></g></g><g><g><circle cx="384" cy="213.333" r="42.667" /></g></g></svg>
                      </div>

                      {u.is_online == 1 ?
                        <span className="items_online"></span>
                        :
                        <span className="items_offline"></span>
                      }
                    </Col>
                  </Row>
                </div>
              })}
            </div>

          </div>

        </div>
        :
        ''
      }








    </div>

  );

}






const SessionBookedNotify = (props,meeting="bookingnotification") => {
  console.log('session',props,meeting)
  let coach = props.coachDetails;
  let links = props.links;
  let packag = props.packageDetails;
  let sessions = props.sessions;
  let user = props.userDetails;
  let sessionType = props.sessionType
  let coachTimezone = props.coachTimezone
  let userTimezone = props.userTimezone
  let uniqueroom;
  let d1 = new Date(coach.created)
  let d2 = new Date(user.created)
  let olduser = new Date(coach.created) < new Date(user.created);

  if (olduser === true) {
    uniqueroom = `${coach.email}-${user.email}`
  } else {
    uniqueroom = `${user.email}-${coach.email}`
  }


  let getuniversaldateforupload = () => {

    var ds = new Date();
    let y = ds.getFullYear();
    let m = ds.getMonth() + 1;
    if (m < 10) {
      m = '0' + m
    }
    let da = ds.getDate();
    if (da < 10) {
      da = '0' + da
    }
    let h = ds.getHours();
    if (h < 10) {
      h = '0' + h
    }
    let mi = ds.getMinutes();
    if (mi < 10) {
      mi = '0' + mi
    }
    let s = ds.getSeconds();
    if (s < 10) {
      s = '0' + s
    }
    return `${y}-${m}-${da} ${h}:${mi}:${s}`
  }
  socket.emit('sendbookingnotification', { uniqueroom: uniqueroom, coach: coach, links: links, package: packag, sessions: sessions, user: user, sessionType: sessionType, coachTimezone: coachTimezone, userTimezone: userTimezone,meeting, created: getuniversaldateforupload() })
}


const sendcontactmessage = (user, coach, message) => {
  let uniqueroom;
  let d1 = new Date(coach.created)
  let d2 = new Date(user.created)



  let olduser = new Date(coach.created) < new Date(user.created);

  if (olduser === true) {
    uniqueroom = `${coach.email}-${user.email}`
  } else {
    uniqueroom = `${user.email}-${coach.email}`
  }


  let getuniversaldateforupload = () => {

    var ds = new Date();
    let y = ds.getFullYear();
    let m = ds.getMonth() + 1;
    if (m < 10) {
      m = '0' + m
    }
    let da = ds.getDate();
    if (da < 10) {
      da = '0' + da
    }
    let h = ds.getHours();
    if (h < 10) {
      h = '0' + h
    }
    let mi = ds.getMinutes();
    if (mi < 10) {
      mi = '0' + mi
    }
    let s = ds.getSeconds();
    if (s < 10) {
      s = '0' + s
    }
    return `${y}-${m}-${da} ${h}:${mi}:${s}`
  }

  let data = {
    message: message.message,
    senderemail: user.email,
    receiveremail: coach.email,
    sender: user.uid,
    sendername: user.name,
    receiver: coach.uid,
    uniqueroom: uniqueroom,
    created_at: getuniversaldateforupload(),
    updated_at: getuniversaldateforupload(),
    type: 'text'
  }
  socket.emit('sendMessage', data);
}


export { SessionBookedNotify }
export { sendcontactmessage }







export default CommunicationUsers
