import React from 'react'
import {
  teamSvg,
  dArrowblack,
  LGspinner
} from "../../assets/images"
// import { CoachProfileList } from '../../components/commonprofile';
import { CoachProfileListPrivate } from '../../components/commonprofile';


import axios from '../../config/axios'
import CoachSuggest from './CoachSuggest';
import CoachFilter from './edit/CoachFilter'
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import AsyncSelect from 'react-select/async';
import LoadingOverlay from "react-loading-overlay";
import TagsInput from "../../layouts/TagsInput";
import message from '../../utils/mesaages';
import CustomImageLoader from 'react-custom-image-loader.'

const customStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    console.log({ data, isDisabled, isFocused, isSelected });
    return {
      ...styles,
      backgroundColor: isFocused ? "#999999" : null,
      color: "#333333"
    };
  }
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      category: [],
      tags: [],
      listCoaches: [],
      selectedValue: [],
      optionTags: [],
      searchloader: false,
      setinputtagg:[]
    }


    this.handleFilter = this.handleFilter.bind(this)
    this.fetchTagsFromApi = this.fetchTagsFromApi.bind(this)
    this.handleChangeTags = this.handleChangeTags.bind(this)
  }

  componentDidMount() {


  }

  componentWillMount() {
    this.fetchInitialtags();
    this.fetchallcoaches()
  }
componentDidUpdate(prevProps){
  if (this.props.suggestedCoaches !== prevProps.suggestedCoaches) {
    this.fetchInitialtags();
    this.fetchallcoaches()
  }
}

  fetchallcoaches = () => {

    this.setState({ searchloader: true })
    //this.setState({ listCoaches: [] })

    axios.post("/consumer/fetchallcoaches", {}, {
    })
      .then((res) => {
        this.setState({ searchloader: false })
        if (res.data.success) {
          let Coaches = res.data.value
          console.log('tanmay', Coaches)
          this.setState({ listCoaches: Coaches })
        } else {
          this.setState({ listCoaches: [] })
        }
      })
      .catch(err => {
        this.setState({ searchloader: false })
        console.log(err)
      })
  }
  fetchInitialtags = inputValue => {
    let self = this
    return new Promise(resolve => {
      axios.post("/consumer/filterSkills", { tags: 'a' })
        .then(res => {
          console.log("Res-", res)
          let tags = res.data.map(d => ({ value: d, label: d }))
          // return resolve(tags);
          self.setState({ optionTags: tags })
          //console.log(self.state.tags)
        })
        .catch(err => {
          console.log(err)
        })

    });

  }

  handleChange = (e) => {
    console.log("eee", e)
    if (e.target.name == 'category') {

      this.setState({ category: Array.from(e.target.selectedOptions, (item) => item.value) }, () => {
        console.log(this.state.category)
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value
      })

    }

  }
  handaleCategory = (value) => {
    console.log(value)
    if (value != null) {
      let cate = []
      let i = 0
      value.map((element) => {

        cate[i] = element.value


        i++

      })
      console.log(cate)
      this.setState({ category: cate })

    }

  }

  handleFilter = (data) => {
    if (data === undefined || data === null) {
      this.setState({ listCoaches: [] })
      return
    }
    //console.log('ddd', data)
    if (data.length > 0) {
      let deepcopydata = JSON.parse(JSON.stringify(data));
      let filtererrorsdata = deepcopydata.filter((v) => v.coaching_category !== '');
      this.setState({ listCoaches: filtererrorsdata })
      return
    }

    console.log('tanmay', data)

    this.setState({ listCoaches: data })

  }




  handleSubmit = (e) => {

    let tagValues = []
    // if (this.state.selectedValue !== undefined && this.state.selectedValue !== null && this.state.selectedValue.length > 0) {
    //   tagValues = this.state.selectedValue.map((each) => {
    //     return each.label
    //   })
    // }

    tagValues = this.state.setinputtagg;

    console.log("array value of tags", tagValues)
    e.preventDefault()
    if(tagValues.length>0 || this.state.category.length>0){
      console.log('jfjfjjf',tagValues)
    const formData = {
      category: this.state.category,
      tags: tagValues
    }
    this.setState({ searchloader: true })
    axios.post("/consumer/searchCoaches", formData, {
      headers: { "X-Auth": this.props.token }
    })
      .then((res) => {
        this.setState({ searchloader: false })

        if (res.data.success) {
          let Coaches = res.data.value
          console.log('tanmay', Coaches)
          this.setState({ listCoaches: Coaches })
        } else {
          
          this.setState({ listCoaches: [] })
        }
        if(res.data.value.length===0)
        {
          message.error("No results found. Please change your search criteria.");
        }
      })
      .catch(err => {
        console.log('errorsun', err)
        this.setState({ searchloader: false })

        console.log(err)
      })

    } else {
      console.log('hhhh')
      this.fetchallcoaches()
    }
    
  }




  handleChangeTags(value, { action, removedValue }) {
    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.isFixed !== undefined) {
          return;
        }
        break;
      case 'clear':
        value = []
        break;
    }


    this.setState({ selectedValue: value });
    //console.log("ORIGINAL TAGS VALUE", this.state.tags)
    //this.props.data.skills = value //[...this.state.favorites, favorite.coach_uid]
  }

  fetchTagsFromApi = inputValue => {
    let self = this
    return new Promise(resolve => {
      axios.post("/consumer/filterSkills", { tags: inputValue })
        .then(res => {
          console.log("Res-", res)
          let tags = res.data.map(d => ({ value: d, label: d }))
          return resolve(tags);
          //  self.setState({tags})
          //console.log(self.state.tags)
        })
        .catch(err => {
          console.log(err)
        })

    });

  }


  options = [

    { key: 'Career Coaching', label: 'Career Coaching' },
    { key: 'Executive Coaching', label: 'Executive Coaching' },
    { key: 'Life Coaching', label: 'Life Coaching' },
    { key: 'Transformational Coaching', label: 'Transformational Coaching' },
    { key: 'Health & Wellbeing Coaching', label: 'Health & Wellbeing Coaching' },
    { key: 'Business Coaching', label: 'Business Coaching' },

  ]

  // optionTags = [

  //   { key: 'Career', label: 'Career' },
  //   { key: 'Health', label: 'Health' },
  //   { key: 'Oneness', label: 'Oneness' },
  //   { key: 'Potential', label: 'Potential' },
  //   { key: 'Power', label: 'Power' },
  //   { key: 'Technique', label: 'Technique' },
  //   { key: 'Stress', label: 'Stress' },
  //   { key: 'Unwinding', label: 'Unwinding' },
  //   { key: 'Wellbeing', label: 'Wellbeing' },

  // ]
  setinputtag = (t) =>{
    this.setState({setinputtagg:[...this.state.setinputtagg, t]})
      }
    
      deleteinputtag =(i) =>{
        const { setinputtagg } = this.state;
        const newTags = setinputtagg.filter((tag, j) => i !== j);
        this.setState({
          setinputtagg: newTags
        });
    
      }

      addAndRemoveFav = async(coachid, userid, type) => {
        if(type === 'add'){
         await this.props.mainFav(coachid, userid, type)
          this.fetchallcoaches()
        } else if(type === 'remove') {
          await this.props.mainFav(coachid, userid, type)
          this.fetchallcoaches()
        }
      }
     

  render() {

    return (


      <div className="col-md-8 col-lg-9">


        <div className="searchHead">
          <div className="searchHeading">
            <h3><img width="22px" src={teamSvg} alt="LG-Img" /> Search Coaches</h3>
          </div>

          <CoachFilter
            pdata={this.props.pdata}
            token={this.props.token}
            handle={this.handleFilter}
            category={this.state.category}
            tags={this.state.tags} />
        </div>

        <div className="row mt-4">
          <div className="col-md-9">
            <div className="searchPart">

              <div className="searchList">
                <div className="selectBox w-100 mx-auto ">

                  <DropdownMultiselect placeholder="Select coaching categories" options={this.options} name="category" className="Dip"
                    handleOnChange={(selected) => {
                      this.setState({ category: selected });
                    }}
                  />

                </div>
              </div>
              {/* <div className="searchList">
                <input type="text" placeholder="Select Coaching type..."required value={this.state.category}  className="form-control"  onChange={this.handleChange} name="category"/>
                <img src={dArrowblack} alt="LG-Img"/>
              </div> */}
              <div className="searchList">
                <TagsInput
                  setinputtag={this.setinputtag}
                  deleteinputtag={this.deleteinputtag}
                  setinputtagg = {this.state.setinputtagg}
                >
                </TagsInput>


              </div>


              {/* <input type="text" placeholder="Select Keyword..." className="form-control" value={this.state.tags}  onChange={this.handleChange} name="tags"/> */}
              {/* <img src={dArrowblack} alt="LG-Img"/> */}

            </div>







          </div>


          <div className="col-md-3">
            <button className="defaultBtn w-100" type="submit" onClick={this.handleSubmit}>Search</button>
          </div>
        </div>
        <div >
          <div className="row mt-4">
            <div className="col-md-9">
              {this.state.tags.map((item, index) => (
                <div
                  className="d-inline mx-2 p-2 bg-primary text-white"
                  key={item + index}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>

        </div>

        <LoadingOverlay
          active={this.state.searchloader}
          //spinner
          spinner={<CustomImageLoader image={LGspinner} animationType={'pulse'}/>}
        >
          {/* <div>
            <CoachProfileList
              ListCoaches={this.state.listCoaches}
              Consumer_uid={this.props.Consumer_uid}
              Consumer_name={this.props.Consumer_name}
              Consumer_email={this.props.Consumer_email}
              Consumer_token={this.props.token} />
          </div> */}

          <div>
            <CoachProfileListPrivate
              ListCoaches={this.state.listCoaches}
              Consumer_uid={this.props.Consumer_uid}
              Consumer_name={this.props.Consumer_name}
              Consumer_email={this.props.Consumer_email}
              Consumer_token={this.props.token}
              addToFavorites={this.addAndRemoveFav} />
          </div>


          <hr />
          <CoachSuggest
            pdata={this.props.pdata}
            token={this.props.token}
            suggestedCoaches={this.props.suggestedCoaches}
            Consumer_uid={this.props.Consumer_uid}
            Consumer_name={this.props.Consumer_name}
            Consumer_email={this.props.Consumer_email}
            addToFavorites={this.addAndRemoveFav} />
        </LoadingOverlay>
      </div>
    )
  }
}
export default Dashboard