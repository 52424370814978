import React from 'react'
import StripeCheckout from "react-stripe-checkout";
import { toast } from "react-toastify";
import moment from "moment";
import axios from '../../config/axios';
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import {watchPng} from '../../assets/images/index'
import message from '../../utils/mesaages';
import {STRIPE_KEY} from "../../config/custom";
const _ = require("lodash");  


class BookPackage extends React.Component{
  constructor(props){
    super(props)
    this.state={
      selectedSlotTime:"",
      sessionDate:"2021-01-28",
      FirstWeek:[],
      currMonth: moment().format('MMMM'),
      currDate: moment().format('DD-MM-YYYY')
    }
  }
  
  componentDidMount(){
    
   setTimeout(this.getAvailability(), 1200);
  this.getAvailability()
   }

  handleChange = (e) => {
    e.preventDefault()
    this.setState({
      [e.target.name]: e.target.value,
    });

    // ////console.log('ccc',e.target)
   let sessionDate = e.target.getAttribute("date")
   this.setState({sessionDate},()=>{
    //  ////console.log(this.state)
   });
  };

   getAvailability(){
    let id=this.props.coachUid
    axios.get(`/coach/setavailability/${id}`)
          .then(res =>{
            //console.log("availabity---res--",res)
            if(res.data.success){
              let dataset=res.data.value[0]
              let data=JSON.parse(dataset.schedule_details)
              // let firstweekdata=data.Weeks[0].firstweek
              let firstweekdata=data.availableDays
              this.setState({FirstWeek:firstweekdata,AvailableDays:data.availableDays})
              //console.log("dattta--999",firstweekdata)
            }
            // //console.log("resss---vail",res)
          })
          .catch(err =>{
            //console.log(err)
          })
  }


  handleConfirm=(token,addresses) =>{
    // //console.log(token,addresses)
      axios
      .post("/payment/checkout",{ token,Package:{ "type":"consumer", "name": `${this.props.packageName}`,
      "price": `${this.props.packPrice}`,consumer_uid:this.props.Consumer_uid,
        coach_uid:this.props.coachUid,package_uid:this.props.packageUid,coach_email:this.props.coachEmail,
      coachName:this.props.coachName,consumer_email:this.props.Consumer_email,consumer_name:this.props.Consumer_name,
      coachImage:this.props.coachImage,sessionDate:this.state.sessionDate,slotTime:this.state.selectedSlotTime}})
          .then(response =>{
            const { status } = response.data;
            ////console.log("Response:", response.data);
            if (status === "success") {
              message.success("Success! Your booking request has been sent");
              this.handleClose()
            } else {
              message.error("Something went wrong");
            }
          })
      
  }

  handleClose() {
    window.$('#bookpackagemodal').modal('hide');
}

 intervals(startString, endString) {
  var start = moment(startString, 'YYYY-MM-DD hh:mm a');
  var end = moment(endString, 'YYYY-MM-DD hh:mm a');

  
  start.minutes(Math.ceil(start.minutes() / 15) * 15);

  var result = [];



  var current = moment(start);
  result.push(current.format('YYYY-MM-DD HH:mm'));
  result.push(end.format('YYYY-MM-DD HH:mm'));
  // while (current <= end) {
  //     result.push(current.format('YYYY-MM-DD HH:mm'));
  //     current.add(15, 'minutes');
  // }
  //tanmay change
  ////console.log(result)

  return result;
}

    render(){
      // ////console.log(this.intervals('2016-08-10 4:35:00 PM', '2016-08-10 8:06:00 PM'));

      // ////console.log("selectedSlotTime----------------------------------------",this.state.selectedSlotTime)
           
          let days=["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
          let Months=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
           let date = new Date();
           let day= date.getDay()
           let month=date.getMonth()
           let year=date.getFullYear()
           let TodayDate=date.getDate()

           var getDaysInMonth = function(month,year) {
           return new Date(year, month, 0).getDate();
          };

          let startDate = moment().format('YYYY-MM-DD')
          let endDate = moment().add(14,'d').format('YYYY-MM-DD')
          // ////console.log(startDate)
          // ////console.log(endDate)
          let daysss = []
          let tempdate = startDate
          for (let j = 0; tempdate<=endDate; j++)
          {
            daysss[j] = tempdate
            tempdate = moment(tempdate).add(1,'d').format('YYYY-MM-DD')
           
           
          }
          // ////console.log(daysss)
         
          let i=0
          let weekdays = this.state.FirstWeek
        return(
            <div>
              
<div className="modal fade" id="bookpackagemodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-bookSession" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Book Session</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        
<section>
  
  <div className="container" >
    <div className="row justify-content-center">

        <div className="col-md-12">
          <div className="heading text-center">
            <h3>Book A Session </h3>
            <span className="R_divider"></span>
            <h5 style={{color:"black"}}> {this.props.packageName} with {this.props.coachName} </h5><br/>

          </div>
        </div>
<div className="col-lg-10">
    {/* <OwlCarousel className="owl owl-carousel session-carousel owl-theme">
   
          {weekdays.length>0 && daysss.map(firstWeek =>{

            
            
     

       let weekdayname = moment(firstWeek).format('dddd')+'s';
       let objectweek= Object.values(weekdays).filter((a,b)=>{
        
          let keys = Object.keys(a)[0]

          return keys==weekdayname
         
       })
       let values = Object.values(objectweek)[0]
      
       let values2 = Object.values(values)[0]
       values2.forEach((a,b)=>{

        

       })

     
      
     
        return(
          <div className="item">
            <div className="teamMember">
               <div className="memberContent">
               <div><div>
                  <img src={watchPng} alt="LG-Img"/>
                  {(firstWeek==startDate)?
                  (<><h3>Today,{moment(startDate).format('DD')} {moment(startDate).format('MMM')}</h3></>)
                  :
                  (<><h3>{moment(firstWeek).format('dddd')},{moment(firstWeek).format('DD')} {moment(firstWeek).format('MMM')}</h3></>)
                }
               
                  
               <p>{values2.length} Slots Avilable</p>
                <p>{values2.length>0 && (<> { values2.map((timeslot) =>{
                 
                  
                   let FromTime =`2016-08-10 ${timeslot.From}`
                   let toTime =`2016-08-10 ${timeslot.To}`
                   let FTIntervel=this.intervals(FromTime,toTime)
                   
                   return(

                  FTIntervel.map((FT,index)=>{
                    let uniqueId = _.uniqueId();
                     const endTime=FTIntervel[index + 1]!==undefined ? FTIntervel[index + 1].split(" "):("")

                     return(
                       <div >
                         {FTIntervel[index + 1]!==undefined ? (
                           <div className="custom-control custom-checkbox checkbox-inline mx-1">
                              <input date={firstWeek}  type="radio" className="custom-control-input" id={uniqueId} onChange={this.handleChange} 
                     value={`${FT.split(" ")[1]}-${endTime!=="" ?(endTime[1]):("")}`} name="selectedSlotTime"/>
                     <label className="custom-control-label" for={uniqueId}>{FT.split(" ")[1]} - {endTime!=="" ?(endTime[1]):("")}</label>
                            </div>
                         ):("")}
                    
                   </div>
                     )
                     
                   })
                   )

                 })}</>) }</p></div></div>
                
               
                </div>
            </div>
          </div>
        )
        {i++}
      })}
              
      </OwlCarousel> */}
    </div>
  </div>
</div>
  
</section>
    <div style={{textAlign:"center"}}>
          <StripeCheckout
              stripeKey={STRIPE_KEY}
              token={this.handleConfirm}
              name={this.props.packageName}
              className="stripe-button-el"
              label="confirm booking"
              panelLabel="check card validation"
              email={this.props.Consumer_email}/>
        </div>
    
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
        {/* <button type="button" className="btn btn-success">Book</button> */}
      </div>
    </div>
  </div>
</div>
            </div>
        )
    }
}

export default BookPackage