import React from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

import CardSection from './CardSection';
import axios from '../../../config/axios';
import { message } from 'antd';

export default function CheckoutForm({ show, cardDetails }) {
    const stripe = useStripe();
    const elements = useElements();

    let userdeta = JSON.parse(localStorage.getItem("corporate_userdata"))
    let token = localStorage.getItem("corporate_token")

    const getProfile = async () => {
        axios.get("/api/corprate_user_profile", { headers: { "X-Auth": localStorage.getItem("corporate_token") || localStorage.getItem("corporate_user_token"), }, }).then((res) => {
            if (res.data.success) {
                localStorage.setItem("corporate_userdata", JSON.stringify(res.data.data))
            }
        }).catch((err) => {
            console.log("err", err);
            message.error("Something went wrong");
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        
        if (!stripe || !elements) {
            return;
        }

        // if (!document.querySelector(".StripeElement").classList.contains("StripeElement--empty") || !document.querySelector(".StripeElement").classList.contains("StripeElement--invalid")) {
        //     document.getElementById("submit_button").setAttribute("disabled", true)
        // }

        await stripe.createToken(elements.getElement(CardElement)).then(async (result) => {
            if (result) {
                if (!document.querySelector(".StripeElement").classList.contains("StripeElement--empty") || !document.querySelector(".StripeElement").classList.contains("StripeElement--invalid")) {
                    document.getElementById("submit_button").setAttribute("disabled", true)
                    await axios.post(`/api/corporate_payment`, { email: userdeta.email, name: userdeta.firstname + " " + userdeta.lastname, uid: userdeta.uid, id: userdeta.id, cardDetails: result, customer_id: userdeta.customer_id }, { headers: { "X-Auth": token, } }).then((response) => {
                        if (response.data.success) {
                            message.success(response.data.msg)
                            show()
                            document.getElementById("submit_button").removeAttribute("disabled")
                            cardDetails()
                            getProfile()
                        }
                    }).catch((error) => {
                        console.log(error)
                        message.error("Something Wrong")
                    })
                }
            }
        }).catch((error) => {
            console.log(error)
            message.error("Something Wrong")
        })
    };

    return (
        <form onSubmit={handleSubmit}>
            <CardSection />
            <button disabled={!stripe} id='add_card' hidden>Confirm order</button>
        </form>
    );
}