import React, { Component } from "react";
import Form from 'react-bootstrap/Form'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from "../../../config/axios";
import { message } from "antd";


export default class sessionModal extends Component {

    constructor(props) {
        super(props);

        this.token = localStorage.getItem("authToken") || localStorage.getItem("corporate_user_token");

        this.state = {
            session: "Career Coaching"
        };
    }

    submit = () => {
        let data = this.props.data;
        data = { ...data, session: this.state.session };
        axios.post(`/webinar/book-my-session`, data, { headers: { "x-auth": this.token } }).then(res => {
            if (res.data.success) {
                message.success("Session Booked")
                this.props.handleBook();
                this.props.handleClose();
            } else {
                message.error("Session booking failed")
                this.props.handleClose();
            }
        }).catch(err => {
            console.log("err", err)
            message.error("Session booking failed")
        })
    }

    render() {

        // let options = ["Career Coaching", "Executive Coaching", "Business Coaching", "Life Coaching",
        //     "Transformational Coaching", "Health & Wellbeing Coaching"]

        return (
            <>
                <Modal show={this.props.show} onHide={this.props.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Session booking</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Please choose session category</p>
                        <Form.Control as="select" size="lg" onChange={(e) => this.setState({ session: e.target.value })}>
                            {
                                this.props.categoty && JSON.parse(this.props.categoty).map((items) => (
                                    <option value={items.value}>{items.value}</option>
                                ))
                            }
                        </Form.Control>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-center">
                        <Button variant="primary" className="defaultBtn" onClick={this.submit}>
                            Confirm Booking
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}