import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "./assets/css/main.css";


import App from "./App";
import SimpleReactLightbox from 'simple-react-lightbox'
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://4c3cec7e3da146a1af1f906f06bfb488@o646671.ingest.sentry.io/5763469",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
    <App />
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
