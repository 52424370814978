import React, { useState,useEffect } from 'react';
import "./PopupComponent.css"
import axios from '../../config/axios';

const PopupComponent = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});

    //if(moment().month() <= 3 && !localStorage.getItem("authToken") && !localStorage.getItem("is_closed_n",'Y')){
    
    const toggleModal = () => {
        setShowModal(!showModal);
        localStorage.setItem("is_popup_closed",'Y');
        setTimeout(function(){
            localStorage.removeItem("is_popup_closed");
        },12 * 60 * 60 * 1000)
    };

    const getPromotionText = ()=>{
        axios.get('/get-promotion', {}, { headers: {} }).then((res) => {
		    if(res.data.success){
                    setModalContent(res.data.data);
                    if(res.data.data.show_popup_or_not == 'Y' && !localStorage.getItem("authToken") && !localStorage.getItem("is_popup_closed",'Y')){
                        setShowModal(true);
                    }else{
                        setShowModal(false);
                    }
                    
                }else{
                    setModalContent({});
                }
            }).catch((err) => {
                setModalContent({});
                console.log("Error while fetching the plans",err)
            })
    };

    useEffect(()=>{
        getPromotionText();
    },[])

    return (
        <>
            {showModal && (

<div className="modal fade show" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style={{ display: 'block',"z-index":"9999"}}>
<div className="modal-dialog modal-lg">
    <div className="modal-content">
        <div className="modal-body">
            <div className="text-right"> <i className="fa fa-close close" onClick={toggleModal} data-dismiss="modal"></i> </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="text-center mt-2">
                        <img src="https://i.imgur.com/3dG6dJo.jpg" width="200" alt="Premium" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="text-white mt-5">
                        <h1 className="mb-0">{modalContent?.main_heading}</h1>
                        <span className="intro-1">{modalContent?.tagline}</span>
                        <div className="mt-4">
                            <span className="intro-2">{modalContent?.description}</span>
                        </div>
                        <div className="mt-4 mb-5">
                            <a href={modalContent?.button_link} className="btn btn-primary" style={{"backgroundColor":"#00e1ba"}}>{modalContent?.button_text} <i className="fa fa-long-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

            )}
            {showModal && <div className="modal-backdrop fade show"></div>}
        </>
    );
};

export default PopupComponent;