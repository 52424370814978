import React from 'react'
import { Link } from "react-router-dom";
class JoinSession extends React.Component{
  constructor() {
    super();
    this.state ={
      roomid: "",
      videotoken:"",
    }
  }
  componentDidMount =() => {
    this.getRoomDetails()
  }

  getRoomDetails = async() => {
    let userData = JSON.parse(localStorage.getItem('userdata'))
    let roomid = userData.roomid
    let videoToken = userData.video_token
    //let authToken = localStorage.getItem('authToken')
    await this.setState({roomid: roomid, videotoken: videoToken})
    console.log("VIDEO TOKEN AND VIDEO ID", this.state.roomid, this.state.videotoken)
  }



render(){
return(
<div>
<section className="coacActionhWrap">
  <div className="container" >
    <div className="row justify-content-center">
      <div className="col-md-8 col-lg-9">

        <div className="heading text-center">
          <h3>Join Session</h3>
          <span className="R_divider"></span>
        </div>


        <div style={{textAlign:"center"}}>
        {/* <a href="https://video.lifeguru.io/b/adm-tqi-0uz-rsf" target="blank" className="defaultBtn mt-2"> */}
        {this.state.roomid !==null && this.state.videotoken !=="" ? <a href={`https://video.lifeguru.me/b/api/v1/join_as_moderator?room_uid=${this.state.roomid}&token=${this.state.videotoken}`}
        
        target="blank" className="defaultBtn mt-2">

        
                Click here to join session
              </a> : ""} 
        </div>
      </div>

    </div>
  </div>
</section>
            </div>
        )
    }
}

export default JoinSession