import React from "react";
import { deleteSvg, favoriteSvg } from "../../assets/images";

class UserResources extends React.Component {
  constructor(props) {
    super(props);
   
  }
  render() {
    return (
      <div className="col-md-8 col-lg-9">
        <div className="heading text-center">
          <h3>My Resources </h3>
          <span className="R_divider"></span>
        </div>

        <div className="myFavorite">
          <div className="searchHead">
            <div className="searchHeading">
              <h3>
                <img width="22px" src={favoriteSvg} alt="IMG_LG"/> My Favorite Videos
              </h3>
            </div>
          </div>

          <div className="row justify-content-center lightBox">
            <div className="col-lg-4 col-md-4 col-12 my-3">
              <div className="pf__video">
                <video
                  id="player1"
                  width="100%"
                  height="auto"
                  style={{ width: "100%;" }}
                  poster="http://www.mediaelementjs.com/images/big_buck_bunny.jpg"
                  preload="none"
                  controls
                  playsInline
                  webkit-playsinline
                >
                  <source
                    src="https://commondatastorage.googleapis.com/gtv-videos-bucket/CastVideos/mp4/BigBuckBunny.mp4"
                    type="video/mp4"
                  />
                  <track srclang="en" kind="subtitles" src="mediaelement.vtt" />
                  <track srclang="en" kind="chapters" src="chapters.vtt" />
                </video>
              </div>
              <a className="removeLightbox" href="/#">
                <img width="25" src={deleteSvg}  alt="LG-Img"/>
              </a>
              <div className="icon-wishlist in-wishlist"></div>
            </div>
            <div className="col-lg-4 col-md-4 col-12 my-3">
              <div className="pf__video">
                <video
                  id="player1"
                  width="100%"
                  height="auto"
                  style={{ width: "100%;" }}
                  poster="http://www.mediaelementjs.com/images/big_buck_bunny.jpg"
                  preload="none"
                  controls
                  playsinline
                  webkit-playsinline
                >
                  <source
                    src="https://commondatastorage.googleapis.com/gtv-videos-bucket/CastVideos/mp4/BigBuckBunny.mp4"
                    type="video/mp4"
                  />
                  <track srclang="en" kind="subtitles" src="mediaelement.vtt" />
                  <track srclang="en" kind="chapters" src="chapters.vtt" />
                </video>
              </div>
              <a className="removeLightbox" href="/#">
                <img width="25" src={deleteSvg}  alt="LG-Img"/>
              </a>
              <div className="icon-wishlist in-wishlist"></div>
            </div>
            <div className="col-lg-4 col-md-4 col-12 my-3">
              <div className="pf__video">
                <video
                  id="player1"
                  width="100%"
                  height="auto"
                  style={{ width: "100%;" }}
                  poster="http://www.mediaelementjs.com/images/big_buck_bunny.jpg"
                  preload="none"
                  controls
                  playsinline
                  webkit-playsinline
                >
                  <source
                    src="https://commondatastorage.googleapis.com/gtv-videos-bucket/CastVideos/mp4/BigBuckBunny.mp4"
                    type="video/mp4"
                  />
                  <track srclang="en" kind="subtitles" src="mediaelement.vtt" />
                  <track srclang="en" kind="chapters" src="chapters.vtt" />
                </video>
              </div>
              <a className="removeLightbox" href="/#">
                <img width="25" src={deleteSvg}  alt="LG-Img"/>
              </a>
              <div className="icon-wishlist in-wishlist"></div>
            </div>
          </div>

          <div className="text-center col-12 mt-2  mb-5">
            <a className="defaultBtn" href="/#">
              View more Videos
            </a>
          </div>

          <div className="searchHead">
            <div className="searchHeading">
              <h3>
                <img width="22px" src={favoriteSvg} alt="IMG_LG/" /> My Favorite Podcasts
              </h3>
            </div>
          </div>

          <div className="row justify-content-center lightBox">
            <div className="col-lg-4 col-md-4 col-12 my-3">
              <div className="pf__video">
                <video
                  id="player1"
                  width="100%"
                  height="auto"
                  style={{ width: "100%;" }}
                  poster="http://www.mediaelementjs.com/images/big_buck_bunny.jpg"
                  preload="none"
                  controls
                  playsinline
                  webkit-playsinline
                >
                  <source
                    src="https://commondatastorage.googleapis.com/gtv-videos-bucket/CastVideos/mp4/BigBuckBunny.mp4"
                    type="video/mp4"
                  />
                  <track srclang="en" kind="subtitles" src="mediaelement.vtt" />
                  <track srclang="en" kind="chapters" src="chapters.vtt" />
                </video>
              </div>
              <a className="removeLightbox" href="/#">
                <img width="25" src={deleteSvg}  alt="LG-Img"/>
              </a>
              <div className="icon-wishlist in-wishlist"></div>
            </div>
            <div className="col-lg-4 col-md-4 col-12 my-3">
              <div className="pf__video">
                <video
                  id="player1"
                  width="100%"
                  height="auto"
                  style={{ width: "100%;" }}
                  poster="http://www.mediaelementjs.com/images/big_buck_bunny.jpg"
                  preload="none"
                  controls
                  playsinline
                  webkit-playsinline
                >
                  <source
                    src="https://commondatastorage.googleapis.com/gtv-videos-bucket/CastVideos/mp4/BigBuckBunny.mp4"
                    type="video/mp4"
                  />
                  <track srclang="en" kind="subtitles" src="mediaelement.vtt" />
                  <track srclang="en" kind="chapters" src="chapters.vtt" />
                </video>
              </div>
              <a className="removeLightbox" href="/#">
                <img width="25" src={deleteSvg}  alt="LG-Img"/>
              </a>
              <div className="icon-wishlist in-wishlist"></div>
            </div>
            <div className="col-lg-4 col-md-4 col-12 my-3">
              <div className="pf__video">
                <video
                  id="player1"
                  width="100%"
                  height="auto"
                  style={{ width: "100%;" }}
                  poster="http://www.mediaelementjs.com/images/big_buck_bunny.jpg"
                  preload="none"
                  controls
                  playsinline
                  webkit-playsinline
                >
                  <source src="https://commondatastorage.googleapis.com/gtv-videos-bucket/CastVideos/mp4/BigBuckBunny.mp4" />
                  <track srclang="en" kind="subtitles" src="mediaelement.vtt" />
                  <track srclang="en" kind="chapters" src="chapters.vtt" />
                </video>
              </div>
              <a className="removeLightbox" href="/#">
                <img width="25"  alt="IMG_LG" src={deleteSvg} />
              </a>
              <div className="icon-wishlist in-wishlist"></div>
            </div>
          </div>

          <div className="text-center col-12 mt-2 mb-5">
            <a className="defaultBtn" href="/#">
              View more Podcasts
            </a>
          </div>

          <div className="searchHead">
            <div className="searchHeading">
              <h3>
                <img width="22px" src={favoriteSvg}  alt="IMG_LG"/> My Favorite files
              </h3>
            </div>
          </div>

          <div className="row justify-content-center lightBox">
            <div className="col-lg-4 col-md-6 col-12 my-3">
              <a
                href="http://lifeguru247.com/lifeguru/images/professional-certificate-template-diploma-award-design_1017-17602.jpg"
                data-toggle="lightbox"
                data-gallery="example-gallery"
                className="boxWrap"
              >
                <img alt="IMG_LG/"
                  src="http://lifeguru247.com/lifeguru/images/professional-certificate-template-diploma-award-design_1017-17602.jpg"
                  className="img-fluid"
                />
              </a>
              <a className="removeLightbox" href="/#">
                <img width="25" src={deleteSvg}  alt="LG-Img"/>
              </a>
              <div className="icon-wishlist in-wishlist"></div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 my-3">
              <a
                href="http://lifeguru247.com/lifeguru/images/professional-certificate-template-diploma-award-design_1017-17602.jpg"
                data-toggle="lightbox"
                data-gallery="example-gallery"
                className="boxWrap"
              >
                <img alt="IMG_LG/"
                  src="http://lifeguru247.com/lifeguru/images/professional-certificate-template-diploma-award-design_1017-17602.jpg"
                  className="img-fluid"
                />
              </a>
              <a className="removeLightbox" href="/#">
                <img width="25" src={deleteSvg}  alt="LG-Img"/>
              </a>
              <div className="icon-wishlist in-wishlist"></div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 my-3">
              <a
                href="http://lifeguru247.com/lifeguru/images/professional-certificate-template-diploma-award-design_1017-17602.jpg"
                data-toggle="lightbox"
                data-gallery="example-gallery"
                className="boxWrap"
              >
                <img alt="IMG_LG/"
                  src="http://lifeguru247.com/lifeguru/images/professional-certificate-template-diploma-award-design_1017-17602.jpg"
                  className="img-fluid"
                />
              </a>
              <a className="removeLightbox" href="/#">
                <img width="25" src={deleteSvg}  alt="LG-Img"/>
              </a>
              <div className="icon-wishlist in-wishlist"></div>
            </div>
          </div>

          <div className="text-center col-12 mt-2 mb-5">
            <a className="defaultBtn" href="/#">
              View more files{" "}
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default UserResources;
