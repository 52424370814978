import React from "react";
import Select from 'react-select'
import axios from "../../../config/axios";
import { toast } from "react-toastify";
import { Button, Modal, Form } from "react-bootstrap";
import { writingSvg, closeSvg } from "../../../assets/images";
import { Link } from "react-router-dom";
import message from '../../../utils/mesaages';

class UpdateCategory extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      show: false,
      setShow: false,
      user_title: "",
      UserId: {},
      Coaching_category: null,
      //oldCoachingCategory: null,
    };
  }

  componentDidMount(){
    let User=JSON.parse(localStorage.getItem('userdata'))
    this.setState({UserId: User != null ? User.uid : ''})
  }
 


  handleCategoryChange = (data) => {
    this.setState({ Coaching_category: data });
  };

  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

 

  handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      name: this.props.pdata.name,
      user_title:
        this.state.user_title !== ""
          ? this.state.user_title
          : this.props.pdata.user_title,
          coaching_category: this.state.Coaching_category,
    //coaching_category: this.state.coachingCategory
    };
    let param;
    param = {
      url: "/users/username",
      method: "PUT",
      data: formData,
      headers: {
        "x-Auth": this.props.token,
      },
    };
    const hideMessage = message.loading('Please Wait while we are updating.', 0)

    axios(param)
      .then((res) => {
        hideMessage()
        console.log("ffffff-",res.data.data)
        if (res.data.success) {
          message.success("Coach Category updated");
          this.props.handleSetCategory(this.state.Coaching_category);
        }
      })
      .catch((error) => {
        hideMessage()
        console.log("update error");
        console.log(error);
        message.error("Error in updating details");
      });
  };

  render() {
    const options = [
      { value: 'Career Coaching', label: 'Career Coaching'},
      { value: 'Executive Coaching', label: 'Executive Coaching' },
      { value: 'Business Coaching', label: 'Business Coaching'  },
      { value: 'Life Coaching', label: 'Life Coaching'},
      { value: 'Transformational Coaching', label: 'Transformational Coaching' },
      { value: 'Health & Wellbeing Coaching', label: 'Health & Wellbeing Coaching'}
    ];
   
    const styles = {
      
      control: (base)=>{
        return { ...base,  background: "#fff"}
      }
    };

    return (
      <>
        {this.props.pdata.coach_uid === this.state.UserId &&
         
         ( <Link
          to="profile-coach"
          className="popup-with-zoom-anim edit_item"
          data-toggle="tooltip"
          data-placement="top"
          title="Update Category Options "
          onClick={this.handleShow}
        >
         <i className="fa fa-plus" aria-hidden="true"></i>
        </Link>
        )

        }
       

        <Modal
          style={{color:"black"}}
          show={this.state.show}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
        >
          {/* <Form onSubmit={this.props.handleSubmitPersonal}> */}
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header
            // closeButton
            >
              <Modal.Title>Update Category Options</Modal.Title>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.handleClose}
              >
                <img width="30px" src={closeSvg} alt="ss" />
              </button>
            </Modal.Header>
            <Modal.Body>
              {/* <Form.Group>
                <Form.Label>User Title</Form.Label>
                <Form.Control
                  style={{color:"black"}}
                  type="name"
                  placeholder="Title"
                  defaultValue={
                    this.state.user_title !== ""
                      ? this.state.user_title
                      : this.props.pdata.user_title
                  }
                  onChange={this.handleChange}
                  name="user_title"
                />
              </Form.Group> */}

              <Form.Group>
                <Form.Label>Select Coach categories</Form.Label>
                <Select   style={{color:"black"}} className="languageSelect" placeholder='Select Category' isMulti 
                //defaultValue={this.state.coaching_category!==null ? this.state.coaching_category : this.props.pdata.coaching_category} 
                defaultValue={this.state.Coaching_category !== null ? this.state.Coaching_category : this.props.pdata.coachingCategory} 
                //value={this.state.coachingCategory}

                //onChange={this.props.handleCategoryChange} 
                onChange={this.handleCategoryChange} 
                options={options}
                styles={styles} />
              </Form.Group>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={this.handleClose}>
                Close
              </Button>
              <Button
                variant="success"
                type="submit"
                onClick={this.handleClose}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default UpdateCategory;
