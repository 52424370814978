import React from 'react'
import { Redirect } from "react-router-dom";
import { Header, HeaderTop } from "../components/headers";
import { FooterLink } from "../components/footers";
import axios from '../config/axios';
import message from '../utils/mesaages';
class Unsubscribe extends React.Component{
    constructor() {
        super()

        this.state= {
            redirect: false,
            unsubscribe_status: "Plese wait ..."
        }
    }

    componentDidMount() {
        this.handleUnsubscribe()
    }

    handleUnsubscribe() {
        console.log('enter ');
        let path = window.location.href.split('/');
        //console.log("@@@@@@@@@@@@@@@@@@ path @@@@@@@@@@@@@@@@", path)
        let mail_id = path.pop()
        //console.log("@@@@@@@@@@@@@@@@@@ path @@@@@@@@@@@@@@@@", mail_id)
        axios.post('/remove-subscriber',{maildata: mail_id} ).then((res)=> {
            if(res.data.success) {
                message.success(res.data.message)
                this.setState({redirect: true})
            } else {
                message.error(res.data.message)
                this.setState({redirect: true})
            }
            
            //console.log("@@@@@@@@@ I am here", res)
        }).catch((e) => {
            console.log(e)
            message.error("Error while unsubscribing")
        })
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to='/'/>;
          }
        return(
            <div>
                <HeaderTop />
                <Header />
                    <div className="container padding-bottom-3x mb-2 mt-5">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-8">
                            {this.state.unsubscribe_status}
                            </div>
                        </div>
                    </div>

                <FooterLink />
            </div>
        )

    }
}

export default Unsubscribe