// import { Button } from 'bootstrap'
import React from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import moment from 'moment';
import {curencyFilter,curencyRates} from '../../utils/currencyConvert';
import {dateConfig} from "../../config/dateFormats"







export default class UpgradeDowngradeModal extends React.Component {
    constructor(props)
    {
        super(props)
        this.state ={
            subscriptionDetails:[],
            isUpgradeBoolean:'',
            currentPlanPrice:0,
            rates:''
        }
    }

    componentWillReceiveProps (newProps,prevProps) {
        console.log('subscriptionDetails',newProps,newProps.isUpgradeBoolean != prevProps.isUpgradeBoolean)
        if(newProps.isUpgradeBoolean != prevProps.isUpgradeBoolean ) /* do stuff */
        {
           
            this.setState({subscriptionDetails:newProps.subscriptionDetails,
                           isUpgradeBoolean:newProps.isUpgradeBoolean,
                           currentPlanPrice:newProps.currentPlanPrice
            })
        }
      }

      async componentWillMount() {

        let rates = await curencyRates()
        this.setState({rates})
      }
    
    
    render() {
        let {subscriptionDetails,isUpgradeBoolean,currentPlanPrice} = this.state
        console.log('this.state',this.state)
        return (
            <div>
                    <Modal
                size="md"
                show={this.props.show}
                onHide={this.props.handleClose}
                style={{color: "black"}}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Subscription Details</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <div className="horizontalTable subscription_row">
                        <ul>
                            <li>
                                <b>Subscription Plan</b> 
                                <span>{subscriptionDetails?.plan_name}</span>
                            </li>
                            <li>
                                <b>Date</b> 
                                <span>{moment().utc().format(dateConfig.dateFormat)}</span>
                            </li>
                            <li>
                                <b>Subscription Amount</b> 
                                <span>{ curencyFilter(`USD##${subscriptionDetails?.plan_price}`,'',this.state.rates)}</span>
                            </li>
                            <li>
                                <b>Subscription Duration</b> 
                                <span>{ `${subscriptionDetails?.plan_chargefrequency}` }</span>
                            </li>
                            {isUpgradeBoolean && (
                            <li>
                                <b>Balance Charged</b> 
                                <span>{parseInt(currentPlanPrice)<parseInt(subscriptionDetails.plan_price)?
                                    (  <>{curencyFilter(`USD##${parseInt(subscriptionDetails.plan_price) - parseInt(currentPlanPrice)}`,'',this.state.rates)}</>)
                                    :
                                    (<>{curencyFilter(`USD##${subscriptionDetails.plan_price}`,'',this.state.rates)}</>)}</span>
                               
                            </li>
                            )}

                           


                        </ul>
                        
                        <div className="col-md-12">
                            {isUpgradeBoolean?(
                                <li className="smallLI">
                                    Once you click on confirm your subscription plan will upgrade immediately.
                                    <br/>Please note that your current Billing Date may change.
                                </li>
                                    
                                ):(
                                    <li className="smallLI">
                                        Your subscription plan will automatically change at the end of this billing cycle.
                                    </li>
                                )}
                        </div>
                    </div>

                  
                </Modal.Body>
                
                <Modal.Footer>
                    
                       
                            <Button variant="secondary" onClick={this.props.handleClose}>
                                Exit
                            </Button>
                            <Button variant="success" onClick={()=>{this.props.getSubscription(subscriptionDetails?.id,isUpgradeBoolean)}}>
                                Confirm
                            </Button>
                      
                </Modal.Footer>

            </Modal>
            
                
            </div>
        )
    }
}

