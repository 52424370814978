import React from "react";
import axios from "../../../config/axios";
import { toast } from "react-toastify";
import { Button, Modal, Form, Col } from "react-bootstrap";
import { writingSvg, closeSvg, editnew, mclose } from "../../../assets/images";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { Link } from "react-router-dom";
const _ = require("lodash");
const ct = require("countries-and-timezones");

class UpdateUserPersonal1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      setShow: false,
      address: "",
      location:"",
      country: "",
      city: "",
      timezone:"",
      tzOptions: [],
      stateAddress: "",
    };
  }
    handleClose = () => {
      this.setState({ show: false });
    };
    handleShow = () => {
      this.setState({ show: true });
    };
  
    handleChange = (e) => {
      e.persist();
      this.setState({
        [e.target.name]: e.target.value,
      });
    };

    handleAddressChange = (address) => {
        this.setState({ address });
      };

      handleSelect = async (address) => {
        this.setState({ address });
        var that = this;
        await geocodeByAddress(address)
          .then((results) => {
            _.map(results[0].address_components, async (eachComp) => {
              if (_.includes(eachComp.types, "country")) {
                const timezones = ct.getTimezonesForCountry(eachComp.short_name);
                console.log("EACHCOMP.TYPES",timezones[0].name)
                const timezoneLocal = ct.getTimezone(timezones[0].name);
    
                let tzOptionsFormatted = _.map(timezones, (eachTZ) => {
                  return eachTZ.name + " (GMT " + eachTZ.utcOffsetStr + ")";
                });
    
                await that.setState({
                  country: eachComp.long_name,
                  tzOptions: tzOptionsFormatted,
                  timezone: timezoneLocal.name + " (GMT " + timezoneLocal.utcOffsetStr + ")",
                });
              }

              if (_.includes(eachComp.types, "administrative_area_level_1")) {
                //console.log("EACHCOMP.TYPES",timezones[0].name)
                await that.setState({
                  stateAddress: eachComp.long_name,              
                });
              }
    
              if (_.includes(eachComp.types, "locality")) {
                console.log("setting city...");
                await that.setState({ city: eachComp.long_name });
              }
    
              return eachComp;
            });
          })
          .catch((error) => console.error("Error", error));
    
          this.handleAddressSelected(address, this.state.country, this.state.city);
      };
  
    handleAddressSelected = (address, country, city) => {
      this.setState({ country: country, city: city, location: address });
    };
  
    handleSubmit = (e) => {
      e.preventDefault();
      const addressData = {
        country: this.state.country
          ? this.state.country
          : this.props.pdata.country,
          state: this.state.stateAddress
          ? this.state.stateAddress
          : this.props.pdata.stateAddress,
        city: this.state.city ? this.state.city : this.props.pdata.city,
        location: this.state.location
          ? this.state.location
          : this.props.pdata.location,
      };
      const formData = {
        name: this.props.pdata.name,
        address: JSON.stringify(addressData),
        uid: this.props.pdata.user_uid ? this.props.pdata.user_uid : "",
        timezone: this.state.timezone
          ? this.state.timezone
          : this.props.pdata.timezone,
      };
      console.log("formdata----user--",formData)
      let param;
      param = {
        url: "/consumer/userprofile",
        method: "POST",
        data: formData,
        headers: {
          "X-Auth": this.props.token,
        },
      };
  
      axios(param)
        .then((res) => {
          if (res.statusText) {
            this.props.updatePersonal();
          }
        })
        .catch((error) => {
          console.log("update error");
          console.log(error);
          toast("Error in updating details", { type: "error" });
        });
    };
  
    render() {
     
      const pdata = this.props.pdata;
      return (
        <>
          <div style={{ position: "absolute", right: "20px", top: "0" }}>
            <span
              to="profile-consumer"
              className="addTag"
              data-toggle="tooltip"
              data-placement="top"
              title="Update you Profile"
              onClick={this.handleShow}
            >
              <img width="25px" alt="LG-Img" src={editnew} />
            </span>
          </div>
  
          <Modal
            style={{color:"black"}}
            show={this.state.show}
            onHide={this.handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Form onSubmit={this.handleSubmit}>
              {/* <Modal.Header
              >
                <Modal.Title>Update Personal Details</Modal.Title>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.handleClose}
                >
                  <img width="30px" src={closeSvg} alt="ss" />
                </button>
              </Modal.Header> */}
              <Modal.Body>

              <button type="button" className="close" aria-label="Close" onClick={this.handleClose}>
                <img src={mclose} alt="" width="30px"/>
              </button>

              <div className="heading text-center">
                <h3 className="text-capitalize">Update Personal Details</h3>                
                <span className="R_divider"></span>
              </div>


                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    style={{color:"black"}}
                    type="name"
                    placeholder="Name"
                    defaultValue={pdata.name}
                    onChange={this.props.handleChange}
                    name="name"
                  />
                </Form.Group>
  
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleAddressChange}
                onSelect={this.handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <Form.Group>
                    <Form.Label>Location</Form.Label>
                    <Form.Control
                      style={{color:"black"}}
                      type="text"
                      {...getInputProps({
                        placeholder: this.state.address
                          ? this.state.address
                          : pdata.location
                          ? pdata.location
                          : "Search Location ...",
                        className: "location-search-input",
                      })}
                    />

                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}

                      {suggestions.length > 0 && (
                        <div className="autocomplete-suggestion-box">
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#ccc",
                                  cursor: "pointer",
                                  borderBottom: "1px solid #abacad",
                                  paddingBottom: "2px",
                                }
                              : {
                                  cursor: "pointer",
                                  borderBottom: "1px solid #abacad",
                                  paddingBottom: "2px",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </Form.Group>
                )}
              </PlacesAutocomplete>
              
  
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Country</Form.Label>
                  <Form.Control 
                   style={{color:"black"}}
                   type="text" placeholder="Country" 
                   value={this.state.country ? this.state.country : pdata.country}
                   onChange={this.handleChange}
                   disabled="true"
                     name="country" 
                   />

                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    style={{color:"black"}}
                    type="text"
                    placeholder="State"
                    value={this.state.city ? this.state.stateAddress : pdata.stateAddress}
                    onChange={this.handleChange}
                    name="city"
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    style={{color:"black"}}
                    type="text"
                    placeholder="City"
                    value={this.state.city ? this.state.city : pdata.city}
                    onChange={this.handleChange}
                    name="city"
                  />
                </Form.Group>

                
              </Form.Row>              

              <Form.Group>
                  <Form.Label></Form.Label>
                  <Form.Control as="select"
                    style={{color:"black"}}
                    className="form-control"
                    id="timezone-offset"
                    value={this.state.timezone}
                    onChange={this.handleChange}
                    name="timezone"
                  > 
                        <option value={pdata.timezone ? pdata.timezone : ""}>
                        {pdata.timezone ? pdata.timezone : ""}
                        </option>

                        {this.state.tzOptions &&
                        this.state.tzOptions.map((tz) => {
                            return <option value={tz}>{tz}</option>;
                        })}
                    
                    <span>
                        <img src="images/dArrowblack.png" alt="" />
                    </span>
                    
                  </Form.Control>
              </Form.Group>
  
  
              <div className="text-center mt-3">
                <button className="defaultBtn" type="button" variant="success" onClick={this.handleClose}>Submit</button>
              </div>



  
              </Modal.Body>
              {/* <Modal.Footer>
                <Button variant="primary" onClick={this.handleClose}>
                  Close
                </Button>
                <Button
                  variant="success"
                  type="submit"
                  onClick={this.handleClose}
                >
                  Submit
                </Button>
              </Modal.Footer> */}
            </Form>
          </Modal>
        </>
      );
    }
  }
  
  export default UpdateUserPersonal1;