import React from "react";
import { Link } from "react-router-dom";
import { FooterLink } from "../components/footers";
import { Header, HeaderLogout, HeaderTop } from "../components/headers";
import axios from "../config/axios";
import { Subscribe } from "../views/landing/Index";


class Gdpr extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
        privacydata: [],
        content:""

    }
  }

  createMarkup = (data) => {
    return {__html: data};
  }

  componentDidMount() {
    //window.scrollTo(0,0);
    this.getGdprContent()
  }

  getGdprContent = () => {
    let self = this
    axios.get('/cmsfooter/footerContent')
    .then(async (response) => {
      console.log('privacy polici',response.data.value)
      if(response.status === 200){
        self.setState({
         // privacydata: response.data.value.privacy_policy[0],
          content:response.data.value.gdpr[0].content_json,
          cont:response.data.gdprcont
        })
        
      }
    })
    .catch((e) => {
      console.log(e)
    })
  }

  render() {
    return (
        
      <div>
        {localStorage.getItem('authToken')==null ?(<HeaderTop/>):(<HeaderLogout/>)}
        <Header />
        
        <section className="upcomingWrap">
          <div className="container">
            <div className="webinnerContent text-center">
              <div
                className="heading text-center"
              >
                <h3>GDPR</h3>
                <br />                
                <span className="R_divider"></span>
              </div>
                <div className="text-left" dangerouslySetInnerHTML={{__html: this.state.cont}}/>
              <br />
              <br />
              <br />
              <br />
              <br />
              
            </div>
          </div>
        </section>
        <FooterLink/>
      </div>
    );
  }
}

export default Gdpr;
