import React from "react";
import axios from "../../../config/axios";
import { toast } from "react-toastify";
import {UpdatePackage, EditPackageRequest}  from "../edit/index"
import { Header, HeaderLogout, HeaderTop } from "../../../components/headers";
import { FooterLink } from "../../../components/footers";
import ConfirmModal from "./ConfirmModal";
import { Link } from "react-router-dom";
import BookPackage from "../../../components/commonmodal/bookPackageModal";
import BookSession from "./BookSession";
import message, {getHelpText} from '../../../utils/mesaages';
import {curencyFilter,curencyRates} from '../../../utils/currencyConvert';
import { Accordion, Card, Button, Dropdown } from "react-bootstrap";
import Pagination from '../../../components/pagination/pagination';
import  {Tooltip}  from 'antd';
import CustomImageLoader from "react-custom-image-loader.";
import LoadingOverlay from "react-loading-overlay";
import {LGspinner} from "../../../assets/images";
// import   'antd/lib/tooltip/style/css';


class ViewPackageList extends React.Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("authToken");
    this.state = {
      packages: [],
      currencies: [],
      baseCurrency: "EUR",
      convertToCurrency: "EUR",
      rates: [],
      findcurrency: 1,
      packageName:"",
      packageUid:"",
      packPrice:"",
      pageNumber: 1,
      listPerPage: 10,
      filteredPack: [],
      updateShow: false,
      ActivePack:[],
      editRequestShow: false,
      coachBookTooltip:'',
      packageLoader: false,
      rates:""
    };

    this.acType =
      localStorage.getItem("userdata") != null
        ? JSON.parse(localStorage.getItem("userdata")).account_type
        : "user";
  }

  handleUpdateShow = (e,pack) => {
    this.setState({ updateShow: !this.state.updateShow, ActivePack:pack});
  };

  handleEditRequestShow = async (e, pack) => {
    await this.setState({ editRequestShow: !this.state.editRequestShow, ActivePack:pack });
  };

 async componentDidMount() {
    this.getPackagesList();
    let coachBookTooltip = await getHelpText('coach_book_package')
    this.setState({coachBookTooltip})
  }

 
 async getPackagesList() {
   let rates = await curencyRates()
   this.setState({rates})
    this.setState({packageLoader: true})
    const id = this.props.location.state.uid;
    axios
      .get(`/users/all-packages/${id}`, {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then(async(res) => {
        let value = res.data.value
        value = value.map((collection)=>{
          collection["filterCurrency"] = curencyFilter(collection.price,'',rates)
          return collection;
        })
        await this.setState({ packages: value });
        this.filterPackage()
        console.log('all package list',res.data.value)
      })
      .catch((error) => {
        this.setState({packageLoader: false})
        console.error(error);
      });
  }

  filterPackage = async() => {
    if(this.props.location.state.account_type === "user" || localStorage.getItem("authToken") ==null) {
      await this.setState({ filteredPack: this.state.packages.filter((e) => { return e.isactive == 1 }) });
    } else {
      await this.setState({filteredPack: this.state.packages})
      console.log("all packages list", this.state.filteredPack)
    }
    this.setState({packageLoader: false})
  }


  handleActivateStatus = (id) => {
    const formData = {
      isactive: 1,
    };
    axios
      .put(`/users/packages/updatepackageStatus/${id}`, formData, {
        headers: { "X-Auth": this.token },
      })
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
        this.getPackagesList();
      });
  };

  handleInactivateStatus = (id) => {
    const formData = {
      isactive: 0,
    };

    axios
      .put(`/users/packages/updatepackageStatus/${id}`, formData, {
        headers: { "X-Auth": this.token },
      })
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
        this.getPackagesList();
      });
  };

  handleClick = (id) => {
    axios
      .post(`/users/packages/updatepackage/${id}`,{}, {
        headers: { "X-Auth": localStorage.getItem("authToken") },
      })
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
        this.getPackagesList();
      });
  };

  changeConvertToCurrency = (e) => {
    this.setState({
      convertToCurrency: e.target.value,
    });
    const ps = this.state.rates[this.state.convertToCurrency];
    this.setState({ findcurrency: ps });
  };

  handleEditPackage = (formData) => {
    const id = formData.id;
    axios
      .put(`/users/packages/updatepackage/${id}`, formData, {
        headers: {
          "X-Auth":this.token,
        },
      })
      .then((res) => {
        console.log("ress----,",res)
        if (res.data.success) {
          message.success(res.data.msg);
        } else {
          message.error(res.data.msg);
        }
        this.getPackagesList();
      })
      .catch((error) => {});
  };

  handlePackageBook=(data)=>{
    this.setState({ packageName:data.packname,
      packPrice:data.packprice,
      packageUid:data.packUid})
      
  }

  paginate = (pagenumber) =>{
    this.setState({pageNumber: pagenumber})
  }

  incompleteKYCaction = () => {
    message.warning("Sessions are currently unavailable with this coach",5)
    axios.post(`/users/incomplete-kyc-action`, {coach_uid: this.props.location.state.coachUid}, {
      headers: {"X-Auth": this.token}
    }).then((res) => {
      
    }).catch((err) => {

    })
  }

  render() {
    console.log("this.props.location.state.Consumer_uid==================",this.props.location.state.Consumer_uid)
    console.log("filtered packages==================", this.state.filteredPack)
    const indexOfLast = this.state.pageNumber * this.state.listPerPage
    const indexOfFirst = indexOfLast - this.state.listPerPage
    return (
      <div>
        {/* <HeaderLogout
        currencies={this.state.currencies}
        convertToCurrency={this.state.convertToCurrency}
        changeConvertToCurrency={this.changeConvertToCurrency} /> */}
        {localStorage.getItem("authToken") == null ? (
          <HeaderTop />
        ) : (
            <HeaderLogout
              currencies={this.state.currencies}
              convertToCurrency={this.state.convertToCurrency}
              changeConvertToCurrency={this.changeConvertToCurrency}
            />
          )}
        <Header />
        {this.props.location.state.account_type === "coach" && this.state.updateShow && (  <UpdatePackage                        
                        // icon={"fa fa-edit"}
                        isEdit={true}
                        pacdata={this.state.ActivePack}
                        pack_id={this.state.ActivePack.uid}
                        handleEditPackage={this.handleEditPackage}
                        sessionlength={this.props.location.state.sessionlength}
                        updateShow={this.state.updateShow}
                        handleUpdateShow={this.handleUpdateShow}
                        sortingHelpText={this.props.location.state.sortingHelpText}
                        totalPackageCount={this.props.location.state.totalPackageCount}
                        token={this.token}
                        
                      />

                
                  
)}

                          {this.props.location.state.account_type === "coach" && this.state.editRequestShow && (  
                            <EditPackageRequest                        
                            // icon={"fa fa-edit"}
                            //isEdit={true}
                            pacdata={this.state.ActivePack}
                            pack_id={this.state.ActivePack.uid}
                            sessionlength={this.props.location.state.sessionlength}
                            editRequestShow={this.state.editRequestShow}
                            handleEditRequestShow={this.handleEditRequestShow}
                            editPackageRequestHelpText={this.props.location.state.editPackageRequestHelpText}
                            totalPackageCount={this.props.location.state.totalPackageCount}
                            token={this.props.location.state.token}
                            
                            />
                          )}

        <section className="packagesWrap">
          <div className="container ">
          <LoadingOverlay
                // spinner={<BounceLoader />}
                active={this.state.packageLoader}
                spinner={
                  <div>
                    <CustomImageLoader image={LGspinner} animationType={"none"} />
                    <br />
                  </div>
                }
                text="Loading your packages..."
              >
            <div className="heading text-center">
              <h3>PACKAGES</h3>

              <div className="row justify-content-md-center">
                {/* <Link to="/profile-coach">go back</Link> */}
              </div>
              <span className="R_divider"></span>
            </div>

            <div className="profilePakage">
              {this.state.filteredPack.length === 0 && (
                <div style={{ textAlign: "center" }}>
                  <h4 className="">No Packages found</h4>
                </div>
              )}
              <Accordion>
              {this.state.filteredPack.length>0 && this.state.filteredPack.slice(indexOfFirst,indexOfLast).map((pack) => {
                var sub_sessions = JSON.parse(pack.session_details);
                // const days = JSON.parse(pack.days);
                const duration = pack.duration;

                var hours = duration / 60;
                var rhours = Math.floor(hours);
                var minutes = (hours - rhours) * 60;
                var rminutes = Math.round(minutes);
                const duration_time =
                  rhours + " hours " + rminutes + " minutes";

                // const break_duration = pack.break_duration;

                // var breakhours = break_duration / 60;
                // var breakrhours = Math.floor(breakhours);
                // var breakminutes = (breakhours - breakrhours) * 60;
                // var breakrminutes = Math.round(breakminutes);
                // const break_duration_time =
                //   breakrhours + " hours " + breakrminutes + " minutes";
                // if(this.props.location.state.account_type === "user" && pack.isactive == 0) {
                //   return
                // } else if(localStorage.getItem("authToken") ==null && pack.isactive == 0) {
                //   return
                // } else {
                  
                // }
                return (
                  <div className={pack.isactive ? "packborder" : ""}>
                      <div className="packageList">
                        <div className="packageInfo">
                          <h3>
                            {pack.title} <br />
                          </h3>
                          <p>
                            <i
                              className="fa fa-certificate"
                              aria-hidden="true"
                            ></i>{" "}
                            {sub_sessions.length}
                            {sub_sessions.length > 1
                              ? " Sessions "
                              : " Session "}
                            {/* {pack.session}
                          Session,{" "} */}
                            <i
                              className="fa fa-clock-o"
                              aria-hidden="true"
                            ></i>{" "}
                            {duration_time}{" "}
                          </p>
                          <Accordion.Toggle
                            as={Button}
                            variant="default"
                            eventKey={pack.uid}
                            style={{ textDecoration: "none" }}
                            // href="/#"
                            // data-toggle="collapse"
                            // data-target={`#package_collapse_${pack.uid}`}
                          >
                            Description <i className="fa fa-angle-down"></i>
                          </Accordion.Toggle>
                          <Accordion.Collapse
                            eventKey={pack.uid}
                            // id={`package_collapse_${pack.uid}`}
                            // className="collapse"
                          >
                            <div className="short__desc__caption">
                              <p>{pack.description}</p>
                              <div className="container_pk_item">
                                  {sub_sessions &&
                                    sub_sessions.map((item) => (
                                      <>
                                        {item.sessionName && (
                                          <div className="packageListInner">
                                            <div className="packageList">
                                              <div className="packageInfo">
                                                <h5>
                                                  {item.sessionName &&
                                                    item.sessionName}
                                                </h5>
                                                <p>
                                                  {item.sessionDescription}
                                                </p>

                                                <div className="packagedropdownbutton">
                                                  <div className="dropdown dropleft testi_options">
                                                    <span className="badge badge-Light">
                                                      {" "}
                                                      {item.Length && (
                                                        <h6
                                                          style={{
                                                            color: "black",
                                                          }}
                                                        >
                                                          <i
                                                            className="fa fa-clock-o"
                                                            aria-hidden="true"
                                                          ></i>
                                                          &nbsp;
                                                          {item.Length}{" "}
                                                          minutes
                                                        </h6>
                                                      )}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    ))}
                              </div>
                            </div>
                          </Accordion.Collapse>
                        </div>
                        <div className="packageActon">
                          {/* <h4>€ {pack.price}</h4> */}
                          <h4>
                            &nbsp;
                            {pack.filterCurrency}
                            {/* {this.state.baseCurrency &&
              this.state.baseCurrency === this.state.convertToCurrency ? (
                <>
                  {pack.price.split("##")[0] === "EUR" ? (
                    <>
                      €{" "}
                      {Number.parseFloat(
                        pack.price.split("##")[1]
                      ).toFixed(3)}
                    </>
                  ) : (
                    
                    <>
                    {pack.price.split("##")[0] === "USD" ?(
                      <>
                        €{" "}
                      {Number.parseFloat(
                        pack.price.split("##")[1] / 1.18
                      ).toFixed(3)}
                      </>
                    ) :(
                    <>
                        €{" "}
                      {Number.parseFloat(
                        pack.price.split("##")[1] * 1.12
                      ).toFixed(3)}
                    </>)}
                      
                    </>
                  )}
                </>
              ) : (
                <>
                  {this.state.convertToCurrency === "USD" ? (
                    <>
                      {pack.price.split("##")[0] ===
                      "USD" ? (
                        <>
                          ${" "}
                          {Number.parseFloat(
                            pack.price.split("##")[1]
                          ).toFixed(3)}
                        </>
                      ) :(
                        <>
                        {pack.price.split("##")[0] ==="EUR" ? (
                        <>
                          ${" "}
                          {Number.parseFloat(
                            pack.price.split("##")[1] * 1.18
                          ).toFixed(3)}
                        </>):(
                        <>
                          ${" "}
                          {Number.parseFloat(
                            pack.price.split("##")[1] * 1.32
                          ).toFixed(3)}
                        </>)}
                          
                        </>
                      )}
                    </>
                  ) : (
                    <>
                    {this.state.convertToCurrency === "GBP" ?(
                    <>
                        {pack.price.split("##")[0] ===
                      "GBP" ? (
                        <>
                          £{" "}
                          {Number.parseFloat(
                            pack.price.split("##")[1]
                          ).toFixed(3)}
                        </>
                      ) : (
                        <>
                        {pack.price.split("##")[0] ==="EUR" ? (
                        <>
                          £{" "}
                          {Number.parseFloat(
                            pack.price.split("##")[1] / 1.12
                          ).toFixed(3)}
                        </>):(
                        <>
                          £{" "}
                          {Number.parseFloat(
                            pack.price.split("##")[1] / 1.31
                          ).toFixed(3)}
                        </>)}
                          
                        </>
                      )}
                    </>):(
                    <>
                      {Number.parseFloat(
                        pack.price.split("##")[1] *
                          this.props.location.state.findcurrency
                      ).toFixed(3)}{" "}
                      {this.state.convertToCurrency}
                    </>)}
                      
                    </>
                  )}
                </>
              )}{" "} */}
                          </h4>

                          {this.props.location.state.account_type ===
                          "coach" ? (
                            <div>
                              {/* {pack.isactive ?(<Link className="defaultBtn" to="/comming-soon">
                          Book
                          </Link>):(<Link className="defaultBtn" to="/comming-soon">
                        Not Available
                          </Link>)} */}
                              {!pack.isactive ? (
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  style={{ marginLeft: "10px" }}
                                >
                                  Inactive
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            <div></div>
                          )}
                          {this.props.location.state.account_type ===
                          "user" &&
                          this.acType == 'user' ? (
                            <div>
                              {pack.isactive ? this.props.location.state.coachKYCstatus ? 
                                (
                                  // pack.isactive IS TRUE > this.props.location.state.coachKYCstatus IS TRUE
                                  <div>
                                    {localStorage.getItem("authToken") ==
                                    null ? (
                                      <div>
                                        <Link
                                          className="defaultBtn"
                                          to="/registration"
                                        >
                                          Book
                                        </Link>
                                      </div>
                                    ) : (
                                      <button
                                        className="package_button"
                                        onClick={() => {
                                          const data = {
                                            packname: pack.title,
                                            packUid: pack.uid,
                                            packprice:
                                              pack.price.split("##")[0] ===
                                              "USD"
                                                ? pack.price.split("##")[1]
                                                : pack.price.split("##")[0] ===
                                                  "EUR"
                                                ? pack.price.split("##")[1] *
                                                  1.19
                                                : pack.price.split("##")[1] *
                                                  1.32,
                                          };
                                          this.handlePackageBook(data);
                                        }}
                                        //  data-toggle="modal"
                                        //  data-target="#bookpackagemodal"
                                      >
                                        {console.log(pack)}
                                        <Link
                                          className="defaultBtn"
                                          to={{
                                            pathname: `/intro-session/${this.props.location.state.coachUid}/${pack.uid}`,
                                            state: {},
                                          }}
                                        >
                                          Book
                                        </Link>
                                      </button>
                                    )}
                                  </div>
                                )
                                : (
                                  // pack.isactive IS TRUE > this.props.location.state.coachKYCstatus IS FALSE
                                //  <Tooltip title="Coach customer details(KYC) is incomplete">
                                //   <span 
                                //   // style={{cursor: "not-allowed"}}
                                //   >
                                    <button className="defaultBtn"
                                    onClick={() => this.incompleteKYCaction()} 
                                    // style={{pointerEvents: "none"}}
                                    >
                                      Book
                                    </button>
                                //   </span>
                                // </Tooltip>
                              ) : (
                                // pack.isactive IS FALSE
                                <button className="defaultBtn">
                                  Not Available
                                </button>
                              )}
                            </div>
                          ) : (
                            <div>
                              {
                                pack.isactive == true && (
                                  <Tooltip title={this.state.coachBookTooltip} placement="top">
                                  <span style={{cursor: "pointer"}}>
                                    <button disabled={true} className="defaultBtn" to="/registration" style={{pointerEvents: "none"}}>
                                    Book
                                    </button>
                                  </span>
                                </Tooltip>


                                )
                              }
                              
                            </div>
                          )}
                        </div>

                        {this.props.location.state.account_type ===
                        "coach" ? (
                          <div>
                            <div className="packagedropdownbutton">
                              {/* <div className="dropdown dropleft testi_options">
                            <button
                              type="button"
                              className="btn dropdown-toggle"
                              data-toggle="dropdown"
                            >
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              ></i>
                            </button>

                            <div
                              className="dropdown-menu"
                              // style={{ backgroundColor: "white" }}
                            >
                              <div className="col-sm-8">
                              <li><UpdatePackage
                                  icon={"fa fa-edit"}
                                  isEdit={true}
                                  pacdata={pack}
                                  handleEditPackage={this.handleEditPackage}
                                  pack_id={pack.uid}
                                /></li>
                              </div>
                              <br />

                              <ConfirmModal
                                fun_name={"Delete"}
                                icon={"fa fa-trash"}
                                pack_id={pack.uid}
                                handleClick={this.handleClick}
                              />
                              {pack.isactive === 1 ? (
                                <li>
                                  <p
                                    className="dropdown-packgae"
                                    onClick={() => {
                                      this.handleInactivateStatus(pack.uid);
                                    }}
                                  >
                                  <i className="fa fa-times-circle"></i>{" "}Deactivate
                                  </p>
                                </li>
                              ) : (
                                <li>
                                  <p
                                  className="dropdown-packgae"
                                    onClick={() => {
                                      this.handleActivateStatus(pack.uid);
                                    }}
                                  >
                                    <i className="fa fa-check-circle"></i>{" "}Activate
                                  </p>
                                </li>
                              )}
                            </div>

                          </div> */}
                              <Dropdown className="dropdown dropleft testi_options">
                                <Dropdown.Toggle variant="outline-success">
                                  <i
                                    className="fa fa-ellipsis-v"
                                    aria-hidden="true"
                                  ></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu">
                                  {pack.packDetails ? (
                                    <Dropdown.Item
                                      onClick={(e) =>
                                        this.handleUpdateShow(e, pack)
                                      }
                                    >
                                      <i
                                        className="fa fa-edit"
                                        style={{ padding: "0px 0px 4px 9px" }}
                                      ></i>{" "}
                                      Request Edit
                                    </Dropdown.Item>
                                  ) : (
                                    <Dropdown.Item
                                      onClick={(e) =>
                                        this.handleUpdateShow(e, pack)
                                      }
                                    >
                                      <i
                                        className="fa fa-edit"
                                        style={{ padding: "0px 0px 4px 9px" }}
                                      ></i>{" "}
                                      Edit
                                    </Dropdown.Item>
                                  )}
                                  {pack.dateExpired === null ||
                                  pack.dateExpired ? (
                                    <Dropdown.Item>
                                      <ConfirmModal
                                        fun_name={"Delete"}
                                        icon={"fa fa-trash"}
                                        pack_id={pack.uid}
                                        handleClick={this.handleClick}
                                      />
                                    </Dropdown.Item>
                                  ) : (
                                    ""
                                  )}
                                  {pack.isactive === 1 ? (
                                    <Dropdown.Item>
                                      <p
                                        className="dropdown-packgae"
                                        onClick={() => {
                                          this.handleInactivateStatus(
                                            pack.uid
                                          );
                                        }}
                                      >
                                        <i className="fa fa-times-circle"></i>{" "}
                                        Deactivate
                                      </p>
                                    </Dropdown.Item>
                                  ) : (
                                    <Dropdown.Item>
                                      <p
                                        className="dropdown-packgae"
                                        onClick={() => {
                                          this.handleActivateStatus(pack.uid);
                                        }}
                                      >
                                        <i className="fa fa-check-circle"></i>{" "}
                                        Activate
                                      </p>
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                              {/* {!pack.isactive ? (
                            <div className="packstatus">
                            <button type="button" className="btn btn-danger btn-circle">
                              Inactive
                            </button>
                          </div>
                          ) : (
                            <div></div>
                          )} */}
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                  </div>
                );
                
              })}
              </Accordion>
            </div>

            <div className="text-center mt-5"></div>
            {this.state.packages.length > this.state.listPerPage ? (
              <Pagination postsPerPage={this.state.listPerPage} totalPosts={this.state.packages.length} paginate={this.paginate} currentPage={this.state.pageNumber}/>
              ) : ''}
              </LoadingOverlay>
          </div>
          {/* <BookPackage
          packageName={this.state.packageName}
          packageUid={this.state.packageUid}
          packPrice={this.state.packPrice}
          coachName={this.props.location.state.coachName}
          coachUid={this.props.location.state.coachUid}
          Consumer_uid={this.props.location.state.Consumer_uid}/> */}

        </section>

        <FooterLink />
      </div>
    );
  }
}
export default ViewPackageList;
