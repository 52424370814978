import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Tooltip } from 'antd';
function ConfirmModal(props) {
  // const defaultBtn ={
  //   text-decoration: "none !important",
  //   background: "#82bd51",
  //   display: inline-block,
  //   padding: 10px 30px,
  //   text-transform: uppercase,
  //   border-radius: 25px,
  //   color: #fff !important,
  //   border: none,
  //   transition: 0.3s,
  //   box-shadow: 0px 0px 6px rgb(119 119 119 / 34%)
  // }
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (event) => {event.preventDefault(); setShow(true)};

  let func_button;

  if (props.fun_name === "Delete") {
    func_button = (
      <li className="alert__confirm">
        <button
          className="btn btn-success"
          data-dismiss="modal"
          onClick={() => {
            props.handleClick();
          }}
        >
          Confirm
        </button>
      </li>
    );
  }

  return (
    <>
      {/* <button className="btn btn-sm" onClick={handleShow}>
        <i className={props.icon}></i>{" "}
        {props.fun_name}
      </button> */}
     <Tooltip title={props.helpText} placement="right" className="float-right delete_ac"> <button className="btn btn-danger rc" onClick={handleShow}>Delete Account</button></Tooltip>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        className="modal  confirmation"
      >
        <div className="modal-content">
          <div className="alert__icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
              <path
                d="M61.31 56.51A4.939 4.939 0 0156.99 59H7.01a5.006 5.006 0 01-4.35-7.47l24.99-44A4.946 4.946 0 0132 5a4.96 4.96 0 014.35 2.53l24.99 44a4.93 4.93 0 01-.03 4.98z"
                fill="#c1272d"
              />
              <path
                d="M7.01 55a.938.938 0 01-.854-.487.948.948 0 01-.022-1l25-44.008A.945.945 0 0132 9a.957.957 0 01.873.509l24.988 44a.933.933 0 010 .978.952.952 0 01-.87.516z"
                fill="#ffe184"
              />
              <path d="M32 44a2.373 2.373 0 01-2.363-2.158l-1.578-19.53A3.954 3.954 0 0132 18a3.954 3.954 0 013.938 4.312l-1.578 19.53A2.373 2.373 0 0132 44z" />
              <circle cx="32" cy="49" r="3" />
            </svg>
          </div>
          <p style={{color:"black"}}>{props.infoText}</p>
          <ul>
            <li className="alert__cancel">
              <button
                className="btn btn-danger"
                // href="javascript:void(0)"
                data-dismiss="modal"
                onClick={handleClose}
              >
                Cancel
              </button>
            </li>
            {/* {func_button} */}
            <li onClick={handleClose}>{func_button}</li>

          </ul>
        </div>
      </Modal>
    </>
  );
}
export default ConfirmModal;
