import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import './Landing.css'
import moment from 'moment';
import FooterLink from '../../footers/FooterLink';
import HeaderSecondTo from '../Header/HeaderSecondTo';
import axios from '../../../config/axios';
import { message } from 'antd';
import CustomImageLoader from "react-custom-image-loader.";
import LoadingOverlay from 'react-loading-overlay';
import { LGspinner } from '../../../assets/images';
import CoachingAreas from '../../../views/landing/Coachingarea';
import NextMasterCoachWebinars from '../../../views/landing/NextWebinar';
import OurPartner from '../../../layouts/ourPartner';


class Landing extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loader: false,
            firstRow: [],
            coaches_item: [],
            category_content: "",
            days: '00',
            hours: '00',
            minutes: '00',
            seconds: '00',
        }
    }



    componentDidMount() {
        this.GetContaint();
        this.getHomeContent();
        this.interval = setInterval(() => {
            //const { timeTillDate, timeFormat } = this.props;
            // const timeTillDate = "03 19 2021, 6:00 am"
            const timeTillDate = moment().add(7, 'days').calendar()
            //console.log("time timeTillDate", timeTillDate)
            const timeFormat = 'MMMM DD YYYY, hh:mm:ss a'
            const then = moment(timeTillDate, timeFormat).subtract(8, 'days');
            const now = moment();
            const countdown = moment(then - now);
            const days = countdown.format('DD');
            const hours = countdown.format('HH');
            const minutes = countdown.format('mm');
            const seconds = countdown.format('ss');

            this.setState({ days, hours, minutes, seconds });
        }, 1000);
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    GetContaint = () => {
        this.setState({ loader: true });
        axios.post(`/api/corporate_row_one_contect/`, { "row_id": "1" }).then(async (response) => {
            if (response.data.success) {
                this.setState({ firstRow: response.data.deta }, () => {
                    console.log(this.state.firstRow)
                })
                this.setState({ loader: false });

            }
        }).catch((error) => {
            message.error(error.response.data.msg);
            this.setState({ loader: false });
        });
    }

    getHomeContent = () => {
        this.setState({ loader: true });
        let self = this;
        axios
            .get("/cmshome/homeContent")
            .then(async (response) => {
                console.log("response landing", response.data.value.webinar);
                if (response.status === 200) {
                    self.setState({ coaches_item: response.data.coaches_item, loader: false, category_content: response.data.category_content });
                } else {
                    self.setState({ loader: false });
                    message.error("Error response");
                    console.log("error response", response);
                }
            })
            .catch((e) => {
                message.error("Error while fetching data");
                console.log(e);
                self.setState({ loader: false });
            });
    };


    render() {
        const { days, hours, minutes, seconds, firstRow } = this.state;
        const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        return (
            <>
                <HeaderSecondTo />
                <LoadingOverlay active={this.state.loader} className="webiner_loader" spinner={<CustomImageLoader image={LGspinner} animationType={"none"} />}>
                    {
                        firstRow.map((items) => (
                            (parseInt(items.id) === 1) && (
                                <div className='c_banner d-flex align-items-center' style={{ background: `url(${items.image_link}) 0 0/ cover no-repeat` }}>
                                    <Container>
                                        <Row>
                                            <Col md={5} className="cb_text">
                                                <h2>{JSON.parse(items.content).title.split(" ")[0]}<br />{JSON.parse(items.content).title.split(" ")[1]}</h2>
                                                {renderHTML(JSON.parse(items.content).description)}
                                                <Link to="/contact_us" className="btn defaultBtn btn-default">{JSON.parse(items.content).buttonName}</Link>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            )
                        ))
                    }
                    <div className="access_coaches">
                        <Container>
                            <Row className="justify-content-center">
                                {
                                    firstRow.map((items) => (
                                        (parseInt(items.id) === 2) && (
                                            <Col md={8} className="ac_text text-center">
                                                <h2>{JSON.parse(items.content).title}</h2>
                                                {renderHTML(JSON.parse(items.content).description)}
                                                <Link to={"/how-it-works"} className="btn btn-default defaultBtn">{JSON.parse(items.content).buttonName}</Link>
                                            </Col>
                                        )
                                    ))
                                }
                            </Row>
                        </Container>
                    </div>


                    <div className="c_services">
                        <Container>
                            {
                                firstRow.filter(function (el) { return el.id === 3 })[0] && (
                                    <div className='cs_heading text-light mb-4'>
                                        {renderHTML(JSON.parse(firstRow.filter(function (el) { return el.id === 3 })[0]?.content).title)}
                                        {renderHTML(JSON.parse(firstRow.filter(function (el) { return el.id === 3 })[0]?.content).description)}                                        
                                    </div>
                                )
                            }

                            <Row>
                            <div className="feature_bottom row">
                                {
                                    firstRow.map((items) => (
                                        (parseInt(items.id) === 3) && (
                                            <div className="col-md-3">
                                                <div className="featuresItem" style={{"background-image" :
                                                'url(' + items.multiple_image + ')'}}>
                                                  <div className="fi_content">
                                                   
                                                    <div>
                                                      <p>{items.description}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                        )
                                    ))
                                }
                                </div>
                            </Row>
                        </Container>
                    </div>

                    {/* <div className="programs">
                        <Container>
                            <Row>
                                <Col md={8} className="mb-4">
                                    <div className='cs_heading'>
                                        <h2 className="cs_title">Webinars</h2>
                                        <p>We offer a range of employee health and wellbeing solutions across multiple disciplines.<br />Select a category to view programs.</p>
                                    </div>
                                </Col>
                                <Col md={4} className="text-right">
                                    <Link className="btn btn-default defaultBtn">View More</Link>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} className="pr_item">
                                    <div className="flip-container">
                                        <div className='flipper'>
                                            <div className='front'>
                                                <div className='CoachingItem'>
                                                    <img src="https://lifeguru-prod1.s3.us-east-2.amazonaws.com/lifeguruadmin/8FLltrN1SOgUFznEsPewAYvrFoTElWMQGBnh8Ghb.jpg" className="img-fluid w-100" alt='pri_image' />
                                                    <h3>CAREER</h3>
                                                </div>
                                            </div>
                                            <div className="back">
                                                <h4>CAREER</h4>
                                                <span className="R_divider"></span>
                                                <p>A meaningful career is critical to leading a fulfilling life. Our experienced career coaches &amp; speakers will guide and help you to discover your ideal career and grow. They share their expertise to help you make decisions, overcome challenges, build key skills, and progress in your career.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4} className="pr_item">
                                    <div className="flip-container">
                                        <div className='flipper'>
                                            <div className='front'>
                                                <div className='CoachingItem'>
                                                    <img src="https://lifeguru-prod1.s3.us-east-2.amazonaws.com/lifeguruadmin/Bk3Z8yt62XcXU4WwxUdppEuvHqsBvosmHzOIc25x.jpg" className="img-fluid w-100" alt='pri_image' />
                                                    <h3>Motivational</h3>
                                                </div>
                                            </div>
                                            <div className="back">
                                                <h4>Motivational</h4>
                                                <span className="R_divider"></span>
                                                <p>Our world-class Motivational Speakers provide inspirational and informative talks on topics such as achieving personal, financial or business success, creating a happy work/life balance, living a healthy lifestyle. Our experienced speakers will motivate and create a positive impact on your life.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4} className="pr_item">
                                    <div className="flip-container">
                                        <div className='flipper'>
                                            <div className='front'>
                                                <div className='CoachingItem'>
                                                    <img src="https://lifeguru-prod1.s3.us-east-2.amazonaws.com/lifeguruadmin/OWbSrUnnhdXcLEXL4DM2Tv2JW8tW1JoBABdZ7pJT.jpg" className="img-fluid w-100" alt='pri_image' />
                                                    <h3>BUSINESS & Leadership</h3>
                                                </div>
                                            </div>
                                            <div className="back">
                                                <h4>BUSINESS & Leadership</h4>
                                                <span className="R_divider"></span>
                                                <p>Our Business & Leadership coaches will inspire you to define your vision and achieve your goals. Business coaches are typically experienced business owners or entrepreneurs themselves and therefore understand business challenges. Bringing a wealth of experience knowledge, our team of experts will provide guidance to support you to make your business successful.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4} className="pr_item">
                                    <div className="flip-container">
                                        <div className='flipper'>
                                            <div className='front'>
                                                <div className='CoachingItem'>
                                                    <img src="https://lifeguru-prod1.s3.us-east-2.amazonaws.com/lifeguruadmin/5UtgW0oRkKkJAirbTREdyPXUzrhq6DmapYYgmXx9.jpg" className="img-fluid w-100" alt='pri_image' />
                                                    <h3>Personal Development</h3>
                                                </div>
                                            </div>
                                            <div className="back">
                                                <h4>Personal Development</h4>
                                                <span className="R_divider"></span>
                                                <p>Our team of Life Coaches will help you to maximise your full potential and achieve your desired results - in any area of life. Our personal development specialists will help you identify and reach your personal and life goals. Our speaker will help you increase your confidence, clarity and motivation to create the changes you desire.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4} className="pr_item">
                                    <div className="flip-container">
                                        <div className='flipper'>
                                            <div className='front'>
                                                <div className='CoachingItem'>
                                                    <img src="https://lifeguru-prod1.s3.us-east-2.amazonaws.com/lifeguruadmin/lrjFoJ8vSvwMLTNNHmJYzGinkyVKyKRHhgrDhdZp.jpg" className="img-fluid w-100" alt='pri_image' />
                                                    <h3>HEALTH & WELLBEING</h3>
                                                </div>
                                            </div>
                                            <div className="back">
                                                <h4>HEALTH & WELLBEING</h4>
                                                <span className="R_divider"></span>
                                                <p>Modern day living can be stressful. Our diverse team of professional Health & Wellness coaches can help to improve physical, mental and emotional wellbeing. With diverse backgrounds across health, mental & emotional wellbeing, nutrition, movement, our expert coaches and speakers can help you achieve your optimal health and create a happier, healthier life.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4} className="pr_item">
                                    <div className="flip-container">
                                        <div className='flipper'>
                                            <div className='front'>
                                                <div className='CoachingItem'>
                                                    <img src="https://lifeguru-prod1.s3.us-east-2.amazonaws.com/lifeguruadmin/AD77yIg6C63YR9D3HqqBW0mJzqQ79yqYs8dknIB7.jpg" className="img-fluid w-100" alt='pri_image' />
                                                    <h3>TRANSFORMATIONAL</h3>
                                                </div>
                                            </div>
                                            <div className="back">
                                                <h4>TRANSFORMATIONAL</h4>
                                                <span className="R_divider"></span>
                                                <p>Focused on enabling self-actualisation, transformational work acknowledges every aspect of a person’s being – mental, emotional, physical and spiritual. Our experienced Transformation Coaches & Speakers will take you on a deep dive into your psyche to raise self-awareness and create deep change within.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                    </div> */}
                    <section className="rl_event">
                        <Container>
                            <Row>
                                <Col md={6} className="mb-4">
                                    <div className='cs_heading'>
                                        <h2 className="cs_title">Categories</h2>
                                        <p>{this.state.category_content}</p>
                                    </div>
                                </Col>
                            </Row>
                            <CoachingAreas coachingareadata={this.state.coaches_item} />
                        </Container>
                    </section>

                    {/* <section className="webinnerWrap">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-10">
                                    <div className="webinnerContent">
                                        <div className="heading mb-4 text-center">
                                            <h3>Next <span>Live Webinar</span></h3>
                                            <p>Sponsored job seekers have exclusive access to world-class coaches who share their expertise through their webinars</p>
                                        </div>
                                        <div className="webinerStart text-dark">
                                            <Row className='align-items-center'>
                                                <Col md={6}>
                                                <div className="webiner_card" style={{background: 'url(https://lifeguru-prod1.s3.us-east-2.amazonaws.com/lifeguruadmin/poKK7JaRDPxfBW11Yrj4yeg8Jx6epJxFBymnbuRe.jpg)', backgroundRepeat: 'no-repeat',backgroundSize: 'cover',    backgroundAttachment: 'local'}}>
                                                    <div className="webiner_card_content text-light">
                                                        <img width="60" height="60" src="https://corporate.coachmaster.io/static/media/webiner_coach1.988ec364.jpg" className="img-fluid rounded-circle" alt="David Wisley"/>
                                                            <h4 className="webiner_coach_name mt-2"> David Wisley </h4>
                                                            <p className="webiner_coach_details">  </p>
                                                            <h3 className="webiner_name" title="Healing your mind"> Healing your mind </h3>
                                                            <p className="webinar_description"> Heal you mind for a wonderful life </p>
                                                            <div className="text-uppercase">
                                                                <small style={{fontSize: '0.7rem'}}>10 Apr, 2023 - 9:50 PM</small>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Col>
                                                <Col md={6}>
                                                    <div className='text-center'>
                                                    <div className="heading">
                                                        <h3>Webinar Starts In</h3>
                                                    </div>
                                                    <div className="countdown-container d-flex justify-content-center">
                                                        <div className="countdown_wrap d-flex justify-content-center">                  
                                                            <div className="countdown_number">
                                                                <span className="count_up">{days}</span>
                                                                <small className="text-uppercase">DAYS</small>
                                                            </div>
                                                            <div className="countdown_number">
                                                                <span className="count_up">{hours}</span>
                                                                <small className="text-uppercase">HRS</small>
                                                            </div>
                                                            <div className="countdown_number">
                                                                <span className="count_up">{minutes}</span>
                                                                <small className="text-uppercase">MINS</small>
                                                            </div>
                                                            <div className="countdown_number">
                                                                <span className="count_up">{seconds}</span>
                                                                <small className="text-uppercase">SECS</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Link to="/" className='btn defaultBtn'>Join Now</Link>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}

                    <NextMasterCoachWebinars />

                    {/* <div className="hww">
                        <Container>
                            <Row className='align-items-center'>
                                {
                                    firstRow.map((items) => (
                                        (parseInt(items.id) === 4) && (
                                            <>
                                                <Col md={6} className="hww_img">
                                                    <img src={items.image_link} className="img-fluid w-100" alt='hww_image' />
                                                </Col>
                                                <Col md={6} className="hww_text">
                                                    <div className='cs_heading mb-4'>
                                                        <h5 className="css_title text-uppercase">{JSON.parse(items.content).title}</h5>
                                                        <h2 className="cs_title">How we work</h2>
                                                    </div>
                                                    <ul className="list-unstyled listTypeBox mb-5">
                                                        {JSON.parse(items.content).description.map((item) => (
                                                            <li>{item}</li>
                                                        ))}
                                                    </ul>
                                                    <Link className="btn btn-default defaultBtn">{JSON.parse(items.content).buttonName}</Link>
                                                </Col>
                                            </>
                                        )
                                    ))
                                }
                            </Row>
                        </Container>
                    </div> */}

                    {/* <div className="llyl">
                        <Container>
                            <Row className="align-items-center">
                                {
                                    (this.state.sixthRow?.content) && (
                                        <>
                                            <Col md="7" className="llyl_video">
                                                <ReactPlayer className='react-player'
                                                    url={this.state.sixthRow.video_link}
                                                    controls={true}
                                                    muted={true}
                                                    loop={true}
                                                    playsinline
                                                    width="100%"
                                                    height="400px"
                                                    light={ll_poster} />
                                            </Col><Col md="5" className="llyl_text text-light">
                                                <div className='cs_heading mb-4'>
                                                    <h2 className="cs_title">Live a <br />{JSON.parse(this.state.sixthRow.content).title}</h2>
                                                </div>
                                                {renderHTML(JSON.parse(this.state.sixthRow.content).description)}
                                                <Link className="btn btn-default defaultBtn">{JSON.parse(this.state.sixthRow.content).buttonName}</Link>
                                            </Col>
                                        </>
                                    )
                                }
                            </Row>
                        </Container>
                    </div> */}

                    {/* <div className="flexible_programs">
                        <Container>
                            <Row className="align-items-center">
                                <Col md={5} className="fp_text">
                                    <div className='cs_heading mb-5'>
                                        <h4 className="css_title text-uppercase">Health & wellbeing programs that support personal & professional development</h4>
                                        <h2 className="cs_title">Personalised, flexible programs</h2>
                                    </div>
                                    <ul className="list-unstyled listTypeBox mb-5">
                                        <li>Each employee can be matched with a practitioner or coach</li>
                                        <li>Simply allocate budget and empower each employee to choose their program</li>
                                        <li>Track engagement and results through your dashboard insights</li>
                                    </ul>
                                    <Link className="btn btn-default defaultBtn">Learn More</Link>
                                </Col>
                                <Col md={7} className="fp_img">
                                    <img src={pfp} className="img-fluid w-100" />
                                </Col>
                            </Row>
                        </Container>
                    </div> */}

                    <div className="support_employe text-center text-light">
                        <Container>
                            <Row className="justify-content-center">
                                {
                                    firstRow.map((items) => (
                                        (parseInt(items.id) === 8) && (
                                            <Col md={8}>
                                                <div className='cs_heading mb-5'>
                                                    <h2 className="cs_title mb-3">Support your<br />employee wellbeing today</h2>
                                                    {renderHTML(JSON.parse(items.content).description)}
                                                </div>
                                                <Link to="/contact_us" className="btn btn-default defaultBtn">{JSON.parse(items.content).buttonName}</Link>
                                            </Col>
                                        )
                                    ))
                                }
                            </Row>
                        </Container>
                    </div>

                    <OurPartner firstRow = {this.state.firstRow} />
                </LoadingOverlay >
                <FooterLink />
            </>
        )
    }
}

export default Landing