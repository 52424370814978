import React from "react";
import Select from 'react-select'
import axios from "../../../config/axios";
import { toast } from "react-toastify";
import { Button, Modal, Form } from "react-bootstrap";
import { writingSvg, closeSvg, editnew, mclose } from "../../../assets/images";
import { Link } from "react-router-dom";

class UpdateBasicDetails extends React.Component {
  constructor(props, context) {
    super(props, context);
  this.state = {
    show: false,
    setShow: false,
    user_title: "",
    Coaching_category: null,
  };
}
  handleCategoryChange = (data) => {
    this.setState({ Coaching_category: data });
  };

  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

 

  handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      name: this.props.pdata.name,
      user_title:
        this.state.user_title !== ""
          ? this.state.user_title
          : this.props.pdata.user_title,
    //coaching_category: this.props.pdata.coaching_category
    coaching_category: this.state.Coaching_category
    };

   

    let param;
    param = {
      url: "/users/username",
      method: "PUT",
      data: formData,
      headers: {
        "X-Auth": this.props.token,
      },
    };

    axios(param)
      .then((res) => {
        console.log("ffffff-",res)
        if (res.data.success) {
          //this.props.updatePersonal();
          toast("Coach Category options updated", { type: "success" })
          this.props.handleSetCategory(this.state.Coaching_category);
        }
      })
      .catch((error) => {
        console.log("update error");
        console.log(error);
        toast("Error in updating details", { type: "error" });
      });
  };

  render() {
    const options = [
      { value: 'Career Coaching', label: 'Career Coaching'},
      { value: 'Executive Coaching', label: 'Executive Coaching' },
      { value: 'Business Coaching', label: 'Business Coaching'  },
      { value: 'Life Coaching', label: 'Life Coaching'},
      { value: 'Transformational Coaching', label: 'Transformational Coaching' },
      { value: 'Health & Wellbeing Coaching', label: 'Health & Wellbeing Coaching'}
    ];
   
    const styles = {
      
      control: (base)=>{
        return { ...base, background: "#fff"}
      }
    };

    return (
      <>
        <span
          to="profile-consumer"
          className="addTag"
          data-toggle="tooltip"
          data-placement="top"
          title="Update your basic details"
          onClick={this.handleShow}
        >          
            <img
              alt="LG-Img"
              width="25px"
              src={editnew}
              style={{ color: "red" }}
            />
          </span>
        

        <Modal
          style={{color:"black"}}
          show={this.state.show}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Form onSubmit={this.handleSubmit}>
            {/* <Modal.Header>
              <Modal.Title>Update Basic Details</Modal.Title>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.handleClose}
              >
                <img width="30px" src={closeSvg} alt="ss" />
              </button>
            </Modal.Header> */}
            <Modal.Body>

              <button type="button" className="close" aria-label="Close" onClick={this.handleClose}>
                <img src={mclose} alt="" width="30px"/>
              </button>

              <div className="heading text-center aos-init aos-animate">
                <h3 className="text-capitalize">Update Basic Details</h3>                
                <span className="R_divider"></span>
              </div>

              <Form.Group>
                <Form.Label>Select Coach categories</Form.Label>
                <Select   style={{color:"black"}} className="languageSelect" placeholder='Select Category' isMulti 
                // defaultValue={this.state.coaching_category!==null ? this.state.coaching_category : this.props.pdata.coaching_category} 
                defaultValue={this.state.Coaching_category !== null ? this.state.Coaching_category : this.props.pdata.coaching_category} 
                //value={this.props.pdata.coaching_category} 

                onChange={this.handleCategoryChange} 
                options={options}
                styles={styles} />
              </Form.Group>

              <div className="text-center mt-3">
                <button className="defaultBtn" variant="success"
                  type="submit"
                  onClick={this.handleClose}>Submit</button>
              </div>

            </Modal.Body>
            {/* <Modal.Footer>
              <Button variant="primary" onClick={this.handleClose}>
                Close
              </Button>
              <Button
                variant="success"
                type="submit"
                onClick={this.handleClose}
              >
                Submit
              </Button>
            </Modal.Footer> */}
          </Form>
        </Modal>
      </>
    );
  }
}

export default UpdateBasicDetails;
