import React, { Component } from "react";
import axios from "../config/axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { flagSvg, profileAddressSvg, clockSvg } from "../assets/images";
import { FooterLink } from "../components/footers";
import { Header, HeaderLogout } from "../components/headers";
import {
  Dashboard,
  TransactionHistory,
  UserPayment,
  UserResources,
  UserSubscription,
} from "../views/consumer";
// import UserBooking from "../views/consumer/UserBooking";
import UserBooking from "../views/consumer/UserBookingNew";
import { avatar } from "../assets/img";
import {
  UpdateBasicDetails,
  UpdateUserPersonal,
  UploadUserImage,
} from "../views/consumer/edit";
import CommunicationUsers from "./Communication/CommunicationUsers";
import UserupcomingSession from "../views/consumer/Upcomingsession";
import UserCalender from "../views/consumer/UserCalender";
import { Modal } from 'react-bootstrap';
import message from '../utils/mesaages';
import { wait } from "@testing-library/react";
import Async from "react-select/async";
import ConfirmModalProfilepicHome from '../components/commonmodal/ConfirmModalProfilepicHome'

class Consumer extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("authToken");

    this.state = {
      name: "",
      user_title: "",
      coaching_category: null,
      city: "",
      country: "",
      renderSubComponent: "UserDashboard",
      suggestedCoaches: [],
      user_uid:"",
      //avatar_path:avatar,
      avatar_path:null,
      Consumer_uid:"",
      email:"",
      location: "",
      timezone:"",
      stateAddress:"",
      searchloader: false,
    };
  }

  componentDidMount() {
    console.log('propsAA',window.location.hash)
    if(window.location.hash=='#My-Booking')
    {
      this.setState({renderSubComponent:"BookingManagement"})
    }
    this.fetchUserName();
    this.fetchUserData();
  }

  fetchUserName() {
    let self = this;
    let param = {
      url: "/users/username",
      method: "GET",
      headers: {
        "X-Auth": this.token,
      },
    };
    axios(param)
      .then(async (res) => {
        if (res.data.value) {
          const rawdata = res.data.value;

          self.setState({
            name: rawdata.name,
            email:rawdata.email,
            user_title: rawdata.user_title,
            coaching_category: rawdata.coaching_category ? JSON.parse(rawdata.coaching_category):"",
            Consumer_uid:rawdata.uid

          });
          this.getCoaches();
        }
      })
      .catch((error) => {
        console.log(error);
        message.error("Error getting User data");
      });
  }

  fetchUserData() {
    let self = this;
    let param = {
      url: "/consumer/userprofile",
      method: "GET",
      headers: {
        "X-Auth": this.token,
      },
    };
    axios(param)
      .then(async (res) => {
        if (res.data.success) {
          
          const rawdata = res.data.value;
          const rawaddress = rawdata.address ? JSON.parse(rawdata.address) : "";

          self.setState({
            user_uid: rawdata.user_uid,
            country: rawaddress.country,
            location: rawaddress.location,
            stateAddress: rawaddress.state,
            city: rawaddress.city,
            timezone: rawdata.timezone,
            avatar_path:rawdata.avatar_path
          });
        }
      })
      .catch((error) => {
        console.log("fetch error");
        console.log(error);
        //toast("Error getting profile data", { type: "error" });
      });
  }


  fetchFavoriteCoaches() {
   
    let coaching=[]
    this.state.coaching_category && this.state.coaching_category.map(cat =>{
      return(
      coaching.push(cat.value)
      )
    })
    const formData={
       coaching_category:coaching
     }

  let userdata = JSON.parse(localStorage.getItem('userdata'));
  if(userdata === undefined || userdata === null) return;
  let useruid = userdata.uid;
  console.log('useruid' , useruid);
  if(useruid === undefined || useruid === null) return;
  
    let self = this
    let url = `/consumer/fetch-content/${useruid}`
  console.log('url' , url);
  
    axios.get(url)
      .then((res) => {
        if (res.data.success) {
          console.log('ssf' ,res.data);
          console.log('ssf state ' ,this.state.suggestedCoaches);
          console.log('ssf db' ,res.data.value.favCoachDetails);
          let suggestions=[]
          const suggested = res.data.value.favCoachDetails;
          // suggested.filter((suggest)=>{
          //   if(suggest.coaching_category.includes(formData.coaching_category[0]) || 
          //   suggest.coaching_category.includes(formData.coaching_category[1]) ||
          //   suggest.coaching_category.includes(formData.coaching_category[2]) ||
          //   suggest.coaching_category.includes(formData.coaching_category[3]) ){
          //     suggestions.push(suggest)
          //   }
          // })

          //let allcoach = this.state.suggestedCoaches.concat(suggestions);
          let allcoach = this.state.suggestedCoaches.concat(suggested);
          console.log('suggestions', suggestions)
          console.log('alcoach', allcoach)
          console.log('state suggestedCoaches', this.state.suggestedCoaches)

          const unique = allcoach.filter((ele ,index) => allcoach.findIndex(obj => obj.email === ele.email) === index)
         console.log('tttt')
          console.log('unique' ,unique)

          self.setState({suggestedCoaches: unique},() => {
            console.log('suggestedCoachessuggestedCoaches',this.state.suggestedCoaches);
        })


        } else {
          console.log("Your fav coachlist is empty..")
        }
    
      })
      .catch((err) => {
        console.log(err);
      });
  };
  

 async getCoaches(){
  await this.setState({suggestedCoaches:[]})

    let coaching=[]
    this.state.coaching_category && this.state.coaching_category.map(cat =>{
      return(
      coaching.push(cat.value)
      )
    })
    const formData={
       coaching_category:coaching
     }
     //this.setState({ searchloader: true })
   await axios.get('/consumer/suggestCoaches',{
        headers:{'x-auth':this.token}
    })
    .then((res) =>{
     // this.setState({ searchloader: false })
        let suggestions=[]
       if(res.data.success){
        const suggested = res.data.value;
        suggested.filter((suggest)=>{
          if(suggest.coaching_category.includes(formData.coaching_category[0]) || 
          suggest.coaching_category.includes(formData.coaching_category[1]) ||
          suggest.coaching_category.includes(formData.coaching_category[2]) ||
          suggest.coaching_category.includes(formData.coaching_category[3]) ){
            suggestions.push(suggest)
          }
        })
      }

      console.log('getcoachsuggestions',suggestions.slice(0,5),formData.coaching_category[0])
        let allcoach = this.state.suggestedCoaches.concat(suggestions.slice(0,5))
        // self.setState({suggestedCoaches: allcoach})
        const unique = allcoach.filter((ele ,index) => allcoach.findIndex(obj => obj.email === ele.email) === index)
        this.setState({suggestedCoaches:unique},() => {
          console.log('get coaches suggestedCoaches',unique);
      })

        this.fetchFavoriteCoaches()
    })
    .catch(err =>{
        console.log(err)
    })
 }

  updatePersonal = () => {
    message.success("Profile Image Uploaded Successfully");
    this.fetchUserData();
    this.fetchUserName();
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleAddressSelected = (address, country, city) => {
    this.setState({ country: country, city: city, location: address });
  };

  handleCategoryChange = data => {
    this.setState({ coaching_category: data });
    };
    handleSetCategory = (value) => {
      this.setState({coaching_category: value})
      this.fetchUserName()
    }

  handleSubComponent = (componentname) => {
    this.setState({ renderSubComponent: componentname });
  };

  mainAddRemoveFav = async(coachid, userid, type) => {
   
        if(type === 'add') {
          console.log("@@@@@@@@@@@@@@@@@@@@type",coachid, userid, type)
          axios
      .post(`/consumer/favorite-coach/${userid}`, {
        headers: { "x-auth": this.token },
        data: coachid,
      })
      .then((res) => {
        if (res.data.success) {
          message.success("Successfully added to favorites");
        } else {
          message.error("failed to add in favorites");
        }
        //this.fetchFavoriteCoaches()
        this.getCoaches()
      })
      .catch((err) => {
        console.log(err);
      });
        } else if(type === 'remove') {
          console.log("@@@@@@@@@@@@@@@@@@@@remove",coachid, userid, type)
          axios
          .post(`/consumer/remove-favorite/${userid}`, {
            headers: { "x-auth": this.token },
            data: coachid,
          })
          .then((res) => {
            if (res.data.success) {
              message.success("Successfully removed from favorites");
            } else {
              message.error("failed to remove from favorites");
            }
            this.getCoaches()
          })
          .catch((err) => {
            console.log(err);
          });
        }
  }

  handleDeleteprofilePic = (e) => {
    //console.log("Yes clicked", this.token)
    let imageUrl = this.state.avatar_path

    //e.preventDefault()
    axios.post(`/users/delete-profilepicuser`,{imageUrl},{
      headers:{"x-auth":this.token}
    }).then((res) => {
      console.log("### response",res)
      if(res.data.success) {
      this.fetchUserData()
      message.success("Your profile image deleted successfully");
      }
      else {
        message.error("Error while deleteing profile image")
      }
    }).catch((err) => {
      message.error("Some error occured")
    })
    
  }


  render() {
    //console.clear()

    var UsersubComponentContent;
    if (this.state.renderSubComponent === "UserDashboard") {
      UsersubComponentContent = (
        <Dashboard
          pdata={this.state}
          token={this.token}
          suggestedCoaches={this.state.suggestedCoaches}
          Consumer_uid={this.state.Consumer_uid}
          Consumer_name={this.state.name}
          Consumer_email={this.state.email}
          mainFav={this.mainAddRemoveFav}
        />
      );
    } else if (this.state.renderSubComponent === "BookingManagement") {
      UsersubComponentContent = 
      <UserBooking 
      handleSubComponent={this.handleSubComponent}
      token={this.token}/>;
    } else if (this.state.renderSubComponent === "UserupcomingSession") {
      UsersubComponentContent = 
      <UserupcomingSession
      token={this.token}/>;
    }
    else if(this.state.renderSubComponent === "UserCalender")
    {
      UsersubComponentContent = 
      <UserCalender token={this.token}  />;
    }
    //  else if (this.state.renderSubComponent === "MyResources") {
    //   UsersubComponentContent = <UserResources />;
    // } 
    else if (this.state.renderSubComponent === "Subscription") {
      UsersubComponentContent = <UserSubscription />;
    } else if (this.state.renderSubComponent === "PaymentManagement") {
      UsersubComponentContent = <UserPayment />;
    }else if (this.state.renderSubComponent === "Transactionhistory") {
      UsersubComponentContent = <TransactionHistory
      token={this.token} />;
    }

    return (
      <React.Fragment>
        <HeaderLogout />
        <Header />

     
          <CommunicationUsers avatar_path={this.state.avatar_path}></CommunicationUsers>

        <section className="coachsProfileWrap userProfileWrap">
          <div className="container">
            <div className="row align-items-start userInfoWrap">
              <div className=" pro_img_block col-lg-3 col-md-5 col-sm-5 mb-3">
                <div className="f_sidebar">
                  <div className="coachImg">
                    <img
                      alt="LG-Img"
                      src={
                        this.state.avatar_path != null
                          ? this.state.avatar_path
                          : avatar
                      }
                      className="img-fluid"
                    />
                      {this.state.avatar_path !=null ? (
                        <ConfirmModalProfilepicHome
                            fun_name={"Delete"}
                            handleDeleteprofilePic={this.handleDeleteprofilePic}
                            infoText="Are you sure you want to delete your profile image?"
                            //helpText={this.state.coach_remove_acount}
                            
                        />

                        ) : ''}
                    
                    <UploadUserImage
                      pdata={this.state}
                      token={this.token}
                      updatePersonal={this.updatePersonal}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-9 col-md-7 col-sm-7">
                <div className="coachInfoWrap">
                  <div className="coachInfo">
                    <div className="cDetails">
                      <h3>
                        {this.state.name}{ " "}

                        {/* {this.state.renderSubComponent === "UserDashboard" ? (
                          <UpdateBasicDetails
                            pdata={this.state}
                            token={this.token}
                            handleCategoryChange={this.handleCategoryChange}
                            updatePersonal={this.updatePersonal}
                            handleSetCategory={this.handleSetCategory}
                          />
                        ) : (
                          ""
                        )} */}
                      </h3>
                      {/* <h4>{this.state.user_title}</h4> */}
                      {/* {this.state.coaching_category ? <div>Lookin For</div> :''} */}
                      {/* <h5 style={{color:'#734d9d'}}>{this.state.coaching_category ? "Looking For:": ""}{this.state.coaching_category && this.state.coaching_category.map(category =>{
                            return(
                              <span className="badge badge-light even-larger-badge"> {category.value}</span>
  
                            )
                          })}</h5> */}


                          {this.state.city && (
                            <p>
                              <img
                                alt="LG-Img"
                                width="20px"
                                src={profileAddressSvg}
                              />
                              {this.state.city}{" "}
                            </p>
                          )}
                          {this.state.city && (
                            <p>
                              <img width="20px" alt="LG-Img" src={flagSvg} />
                              {this.state.country}{" "}
                            </p>
                          )}
                          {this.state.timezone && (
                            <p>
                              <img width="20px" alt="LG-Img" src={clockSvg} />{" "}
                              {this.state.timezone}
                            </p>
                          )}



                    </div>
                    <div className="contactDetails mb-3">
                      {/* {this.state.city && (
                        <p>
                          <img
                            alt="LG-Img"
                            width="20px"
                            src={profileAddressSvg}
                          />
                          {this.state.city}{" "}
                        </p>
                      )}
                      {this.state.city && (
                        <p>
                          <img width="20px" alt="LG-Img" src={flagSvg} />
                          {this.state.country}{" "}
                        </p>
                      )}
                      {this.state.timezone && (
                        <p>
                          <img width="20px" alt="LG-Img" src={clockSvg} />{" "}
                          {this.state.timezone}
                        </p>
                      )} */}
                    </div>
                  </div>

                  {/* {this.state.renderSubComponent === "UserDashboard" ? (
                    <div>
                      <UpdateUserPersonal
                        pdata={this.state}
                        token={this.token}
                        handleChange={this.handleChange}
                        updatePersonal={this.updatePersonal}
                        handleAddressSelected={this.handleAddressSelected}
                      />
                    </div>
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="userDashboardWrap">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="userDashboardLeftPart coachInfoWrap ">
                  <ul>
                  <li>
                   <Link to="/profile-user-edit">
                        <i  className="fa fa-book" aria-hidden="true"></i>{" "}
                        {/* Profile & Private Details */}
                        Profile & Personal Details
                      </Link>
                </li>

                    {/* <li
                      className={`${
                        this.state.renderSubComponent === "UserDashboard"
                          ? "active"
                          : " "
                      }`}
                    >
                      <Link
                        onClick={() => this.handleSubComponent("UserDashboard")}
                      >
                        <i className="fa fa-tachometer" aria-hidden="true"></i>{" "}
                        Dashboard
                      </Link>
                    </li> */}

                    <li
                      className={`${
                        this.state.renderSubComponent === "BookingManagement"
                          ? "active"
                          : " "
                      }`}
                    >
                      <Link
                      to="#"
                        onClick={() =>
                          this.handleSubComponent("BookingManagement")
                        }
                      >
                        <i className="fa fa-book" aria-hidden="true"></i> My Bookings
                      </Link>
                    </li>

                    <li>
                    <Link
                    to="#"
                        onClick={() =>
                          this.handleSubComponent("UserCalender")
                        }
                      >
                        <i
                          className="fa fa-calendar-check-o"
                          aria-hidden="true"
                        ></i>{" "}
                          My Schedule
                      </Link>
                    </li>

                    {/* <li>
                    <Link
                        onClick={() =>
                          this.handleSubComponent("UserupcomingSession")
                        }
                      >
                        <i
                          className="fa fa-calendar-check-o"
                          aria-hidden="true"
                        ></i>{" "}
                        Upcoming Session
                      </Link>
                    </li> */}

                    {/* <li
                      className={`${
                        this.state.renderSubComponent === "MyResources"
                          ? "active"
                          : " "
                      }`}
                    >
                      <Link
                        onClick={() => this.handleSubComponent("MyResources")}
                      >
                        <i className="fa fa-book" aria-hidden="true"></i> My
                        Resources
                      </Link>
                    </li> */}

                    {/* <li
                      className={`${
                        this.state.renderSubComponent === "Subscription"
                          ? "active"
                          : " "
                      }`}
                    >
                      <Link
                        onClick={() => this.handleSubComponent("Subscription")}
                      >
                        <i className="fa fa-book" aria-hidden="true"></i>{" "}
                        Subscription
                      </Link>
                    </li> */}

                    <li
                      className={`${
                        this.state.renderSubComponent === "PaymentManagement"
                          ? "active"
                          : " "
                      }`}
                    >
                      <Link
                      to="#"
                        onClick={() =>
                          this.handleSubComponent("PaymentManagement")
                        }
                      >
                        <i className="fa fa-credit-card" aria-hidden="true"></i>{" "}
                        Payment Management
                      </Link>
                    </li>

                    <li>
                      <Link 
                      to="#"
                      onClick={() =>
                          this.handleSubComponent("Transactionhistory")
                        }>
                        <i className="fa fa-history" aria-hidden="true"></i>{" "}
                        Transaction history
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="comming-soon">
                        <i
                          className="fa fa-calendar-check-o"
                          aria-hidden="true"
                        ></i>{" "}
                        Coaching Schedule
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="comming-soon">
                        <i className="fa fa-headphones" aria-hidden="true"></i>{" "}
                        Contact Us
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>

              {UsersubComponentContent}
            </div>
          </div>
        </section>

        <FooterLink />
      </React.Fragment>
    );
  }
}

export default Consumer;
