import React from "react";
import { premiumSvg, starSvg } from "../../assets/images";

class UserSubscription extends React.Component {
  constructor(props) {
    super(props);
   
  }
  render() {
    return (
      <div className="col-md-8 col-lg-9">
        <div className="heading text-center">
          <h3>Subscription </h3>
          <span className="R_divider"></span>
        </div>

        <div className="row justify-content-center mt-3 mb-5">
          <div className="col-sm-4 mb-3 text-center">
            <div className="plan basic">
              <h3>
                <img alt="IMG_LG" width="40px" src={starSvg} />
                Basic
                <span>Your Current Membership Level</span>
              </h3>
              <ul className="text-left">
                <li>Benefit One</li>
                <li>Benefit One</li>
                <li>Benefit One</li>
              </ul>
              <a className="defaultBtn cancel mb-4" href="/#">
                Cancel
              </a>
            </div>
          </div>
          <div className="col-sm-4 mb-3 text-center">
            <div className="plan premium">
              <h3>
                <img width="40px" alt="IMG_LG" src={premiumSvg} /> Premium
              </h3>
              <ul className="text-left">
                <li>Benefit One</li>
                <li>Benefit One</li>
                <li>Benefit One</li>
              </ul>
              <a className="defaultBtn mb-4" href="/#">
                Upgrade
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default UserSubscription;
