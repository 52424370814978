import { Tooltip } from "antd";
import React, { Component } from "react";
import CustomImageLoader from 'react-custom-image-loader.';
import LoadingOverlay from "react-loading-overlay";
import { Link } from "react-router-dom";
import { blog1, LGspinner } from "../../assets/images";
import axios, {blogUrl} from "../../config/axios";
import message,{getHelpText} from '../../utils/mesaages';

const _ = require("lodash"); 
class Blog extends Component {
  constructor(props, context) {
    super(props, context);
    this.token = localStorage.getItem("authToken");
    this.state = {
      blogPostToBeLoaded: 6,
      articlesData: [],
      loader: false,
      blog_id:0,
      getFeaturedReqRows: [],
      coach_blog_feature_request:'',
      coach_blog_feature_alradyrequest:'',
      new_blog_id:0
    }
  }

  loadMoreArticles = () => {
    this.setState({blogPostToBeLoaded: this.state.blogPostToBeLoaded+3})

  } 

  createMarkup = (data) => {
    return {__html: data};
  }


  handleSendRequestToFeatureBlogPost = async (blog) => {
    console.clear()
    console.log(blog);
    const userData = JSON.parse(localStorage.getItem('userdata'))
    
    const blogPostRequest = {
      post_author_id: blog.author,
      coach_user_uid: userData.uid,
      blog_post_title: blog?.title?.rendered
    }

    axios.post(`/users/coach/blogpost/${blog.id}/request-feature`, blogPostRequest)
      .then(async res => {
       
        const data = res.data
        if(data.status === 200) {
          message.success("Request successfully sent")
          await this.getPostIsFeatured(blog.author)
        } else if(data.status === 424) {
          message.info("Request already made")
        } else {
          message.error("Something wrong... Try later!!")
        }

        console.log(userData);
        console.log(res);
      }).catch(e => {
        console.log(e);
        message.error("Something wrong... please try again!!")
      })

  }


  async componentDidMount() {
    let coach_blog_feature_request = await getHelpText('coach_blog_feature_request')
    this.setState({coach_blog_feature_request})
    let coach_blog_feature_alradyrequest = await getHelpText('coach_blog_feature_alradyrequest')
    this.setState({coach_blog_feature_alradyrequest})
    

    this.getBlog()

   
    // this.handleSendRequestToFeatureBlogPost()
   
     
  

    let visitorData = localStorage.getItem('userdata')
    let userId = JSON.parse(visitorData)
    await this.getPostIsFeatured(userId.blog_id)
    console.log('userId', userId.blog_id)

    // getting blog id
    await axios.get(`/blog/getBlogId/${userId.id}`, {
      headers: {"x-auth": this.token}
    }).then((res) => {
      console.log('blog id',res.data.blogId)
      this.setState({new_blog_id:res.data.blogId})
      console.log('inside axios',this.state.new_blog_id)
    })

    console.log('new blog id',this.state.new_blog_id)
    this.setState({loader: true})
    // fetch('https://blog.lifeguru.me/wp-json/wp/v2/posts?order=desc&orderby=date&_embed')
    fetch(blogUrl+'/wp-json/wp/v2/posts?order=desc&orderby=date&_embed&author='+this.state.new_blog_id)
    .then(res => res.json())
    .then((data) => {
      this.setState({ articlesData: data, loader: false })
      console.log(this.state.articlesData)
    })
    .catch((e) => {
      console.log("ARTICLES FETCH ERROR",e)
      this.setState({loader: false})
    })
  }

  getPostIsFeatured = async (blogid) => {
    try {
      await axios.get(`/users/get-fetured-requests/${blogid}`, {
        headers: {"x-auth": this.token}
      }).then((res) => {
        const receivedRows = res.data.getFeaturedReqRows
        console.log("res", res)
        this.setState({getFeaturedReqRows: receivedRows})
      })
    }
    catch(err) {
      console.log("err", err)
    }
  }


        getBlog = () => {
          let self=this;
          let visitorData = localStorage.getItem('userdata')
          let users = JSON.parse(visitorData)
          //console.log('hfhhfhf ',users)
          let formData=
          {
            uid:users.uid
          }
        
        let param = {
            url: "/getUserRecord",
            method: "POST",
            data: formData,
            headers: {
              "X-Auth": this.token,
            },
          };
          axios(param)
          .then((res) => {
          console.log('res dd',res.data.result[0])
            if (res.data.result[0]) {
             self.setState({blog_id:res.data.result[0].blog_id})

            
            }
          })
          .catch((error) => {
            console.log("getBlog error", error)
          });
          
        };


      generateBlog = () => {
        let self=this;
        let visitorData = localStorage.getItem('userdata')
        let users = JSON.parse(visitorData)
        //console.log('ddddddddcv', users)
        let formData=
        {
          fullname:users.name,
          email:users.email,
          uid:users.uid
        }
        const hideMessage = message.loading('Please Wait ...', 0)
       let param = {
          url: "/blogsignup",
          method: "POST",
          data: formData,
          headers: {
            "X-Auth": this.token,
          },
        };
        axios(param)
        .then((res) => {
          hideMessage()
          if (res.data.success) {
            self.setState({blog_id:res.data.blog_id})

           console.log('sucssssss',res.data)
            
           
            message.success(
              `Blog generated successfully. Login credentials for blog dashboard has been sent to your email id`, 15
              );
          }else
          {
            message.error(res.data.msg)
          }
        })
        .catch((error) => {
          console.log('testttttttt',error);
          hideMessage()
        
          message.error('Error in blog generate')
        });
        
      };

  render() {
    let {getFeaturedReqRows} = this.state
    return (
      
        // <section className="blogWrap coachBlog">
        <div className="">
        <LoadingOverlay 
        active={this.state.loader} 
        //spinner
        spinner={<CustomImageLoader image={LGspinner} animationType={'pulse'}/>}
        >
          <div className="container text-center">

            {this.state.blog_id==0?
              <button className="defaultBtn mb-2"  onClick={() => this.generateBlog()} type="button" variant="success"
              
             >Generate your blog credentials</button>
             :(
                <Tooltip title="Blog generated successfully. Login credentials for blog dashboard has been sent to your email id" placement="top">
                  <a target="_blank" href={`${blogUrl}/wp-admin`}> <button className="defaultBtn mb-2"   type="button" variant="success"
             
            >Go to your blog dashboard</button></a>
                </Tooltip>
             )

            
            
            }
              

            {/* <h3>NEWS / ARTICLES</h3>
            <span className="R_divider"></span> */}

            <div className="row mt-5">
              {this.state.articlesData.slice(0, this.state.blogPostToBeLoaded).map((article, index) =>{
                let indx = _.findIndex(getFeaturedReqRows, function(o) { return article.id <= o.post_id });
                //console.log("status", indx)
                return(
                  <div key={index} className="col-lg-4 col-sm-6 mb-3">
                    
                    <div className="blogContent h-100">

                      <a href={article.link} style={{textDecoration: "none"}} target="_blank">
                      {/* <img src={blog1} className="img-fluid" alt="LG-Img"/> */}
                        <img alt="LG-Img" style={{height:"232px"}} className="my-2" src={article._embedded["wp:featuredmedia"] === undefined ? blog1 : article._embedded["wp:featuredmedia"][0].source_url} />
                        <div className="bInfo">
                        <h5 style={{color:"black"}}>{`${article.title.rendered.substring(0, 40)}...`}</h5>
                      
                        {/* <p>{article.excerpt.rendered}</p> */}
                        <div style={{minHeight: 73}} dangerouslySetInnerHTML={this.createMarkup(`${article.excerpt.rendered.substring(0,80)}...`)}/>
                        </div>
                    

                        <div className="bFooter">
                          <p>
                            <i className="color-main fa fa-user "></i>
                            <span>{article._embedded.author[0].name}</span>
                          </p>
                          <p>
                            <i className="color-main fa fa-calendar"></i>
                            <span>{article.date.slice(0,article.date.indexOf('T'))}</span>
                          </p>
                          <p>
                            <i className="color-main fa fa-tag"></i>
                            <span>Post</span>
                          </p>
                        </div>

                      </a>

                      <div className="d-flex">
                        {!indx ? (
                          <>
                            <Tooltip title={this.state.coach_blog_feature_alradyrequest} placement="top">
                            <span style={{width: "96%", cursor: "pointer"}}>
                            <button onClick={() => this.handleSendRequestToFeatureBlogPost(article)} className="btn btn-success" style={{margin: 5, width: "100%"}} disabled>
                            Request to promote post
                            </button>
                            </span>
                          </Tooltip>
                          </>
                        ) : (
                          <Tooltip title={this.state.coach_blog_feature_request} placement="top">
                            <span style={{width: "96%"}}>
                            <button onClick={() => this.handleSendRequestToFeatureBlogPost(article)} className="btn btn-success" style={{margin: 5, width: "100%"}}>
                            Request to promote post
                            </button>
                            </span>
                          </Tooltip>
                        )}
                      </div>
                      
                    </div>
                    
                  </div>                    
                )
              })}

                  {(this.state.articlesData.length<=0 && this.state.loader == false)?(<>                    
                      <div className="col-md-12">
                        <div className="card d-inline-block border-0 py-3 px-5">
                          <p className="mb-0 text-danger">You have no post yet</p>
                        </div>
                      </div>                  
                  </>):(<></>)}
    

                  {!this.state.loader && this.state.articlesData.length > 0 && this.state.blogPostToBeLoaded < this.state.articlesData.length &&
                    <div
                      className="text-center col-12 mt-5"
                      // style={{ display: "none" }}
                    >
                      <button className="defaultBtn" onClick={this.loadMoreArticles} >
                        View more Articles
                      </button>
                    </div>
                  }
            </div>


          </div>
          </LoadingOverlay>
        {/* </section> */}
        </div>
      
    );
  }
}

export default Blog;
