import * as React from 'react'
import { Drawer, Button, Radio, Space } from 'antd';
import message,{getHelpText} from '../utils/mesaages';
import cookie from 'react-cookies'

export default class Coocki extends React.Component {

    constructor()
    {
        super()
        this.state = {
            visible:true,
            text:'',
            accessclass:"show"
        }
    }

    async componentDidMount()
    {
        if(cookie.load('acceptCoockie'))
        {
            this.setState({visible:false})
        }
        else{
            let text = await getHelpText('accept_cookies')
            this.setState({text})
        }
    }

    showDrawer = () => {
        this.setState({
          visible: true,
          accessclass:"show"
        });
      };
    
      onClose = () => {
        this.setState({
          visible: false,
          accessclass:"hide"
        });
      };

      acceptCoockie=()=>{
        //   localStorage.setItem('acceptCoockie',true)
          cookie.save('acceptCoockie', true, { path: '/' })
          this.setState({visible:false})
      }
  render() {
   

    return(<>

        {this.state.visible && (

    <Drawer
    title="This site uses cookies"
    placement="bottom"
    closable={true}
    onClose={this.onClose}
    visible={this.state.visible}
    key="bottom"
    keyboard={false}
    maskClosable={false}
    style={{color:"black"}}
    className={this.state.accessclass+" cookie-drawer"}
  >
        <div className="row">
          <div className="col-md-9">{this.state.text}</div>
          <div className="col-md-3"><button onClick={this.acceptCoockie}>Accept</button></div>
        </div>
        
        </Drawer>
        )}
    </>)
   
  }
}