import React, { Component } from 'react';
// import { withRouter } from "react-router";
import Tour from 'reactour'
import { disableBodyScroll, enableBodyScroll,clearAllBodyScrollLocks } from 'body-scroll-lock'
import axios from "../config/axios";
const _ = require("lodash");  

let interval 

class TourSite extends Component {
  constructor(props)
  {
    super()
    this.token = localStorage.getItem("authToken");
    this.state={
      resetTour:0,
      isTourOpen:false,
      steps:[],
      goToStep:'',
      tour:[],
      attempt:[],
      // showCloseButton:false,
      showCloseButton:true,
      resetTourRequest:false,
      disableButton:false,
      scrollOfset:-150,
      
    }

  }

 
  componentWillReceiveProps (newProps,prevProps) {
    
       if(newProps.hideTour!=prevProps.hideTour)
       {
         if(newProps.hideTour!=false && this.state.isTourOpen==true)
         {
           let hideTour = this.getStepKey(newProps.hideTour,true)
           console.log('newProps',hideTour)
          this.setState({isTourOpen:false})
          // localStorage.setItem('tourStep',this.state.goToStep+1)
          localStorage.setItem('tourStep',hideTour)
         }
         
       }

       if(newProps.increaseTour!=prevProps.increaseTour)
       {
        let increaseTour = this.getStepKey(newProps.increaseTour,true)

         if(['.tour-createpackages-session-details','.tour-intoVideo'].includes(newProps.increaseTour) && !this.state.attempt.includes(increaseTour))
         {
          // this.setState({isTourOpen:false})
          this.props.handaleLoader(true)
          this.setState({disableButton:true})
          //
           let attempt = this.state.attempt
           attempt.push(increaseTour)
           this.setState({attempt})
           console.log('newProps',increaseTour)
           console.log('prevProps',prevProps.increaseTour)
           localStorage.setItem('tourStep',increaseTour)

           if(newProps.increaseTour=='.tour-intoVideo')
           {
            window.location.replace('/profile-coach')
           }

           setTimeout(() => {
            // this.setState({isTourOpen:true})

            this.setState({goToStep:increaseTour},()=>{
              //
              this.props.handaleLoader(false)
              this.setState({disableButton:false})
            })
             
           }, 3000);
          
          
          
        
         }
         
       }
     
    }

  

  componentWillMount()
  {
    let timeout = 6000

    
      
    
    let userData = JSON.parse(localStorage.getItem('userdata'))
    if(localStorage.getItem('tourActive')==undefined && localStorage.getItem('tourStep')==undefined)
    {
      timeout = 1000
      localStorage.setItem('tourStep',0)
      this.setState({goToStep:0})
      if(userData.siteTour=='active')
      {
        this.props.handaleLoader(true)
        localStorage.setItem('tourActive','true')
        setTimeout(() => {
          this.setState({isTourOpen:true},()=>{
            this.props.handaleLoader(false)
          })
        }, timeout);
      }
      else{
        localStorage.setItem('tourActive','false')
        this.setState({isTourOpen:false})
      }
      
     
     
    }
    else if(localStorage.getItem('tourActive')=='true')
    {
      this.props.handaleLoader(true)
      console.log('propsTour',this.props)
      
      this.setState({goToStep:parseInt(localStorage.getItem('tourStep'))},()=>{
        console.log('step',this.state.goToStep)

        // if([1,2,3,4,5].includes(this.state.goToStep))
        // {
        //   window.location.replace('/profile-coach-edit')
        // }
        // else 
        if([7,8].includes(this.state.goToStep))
        {
          this.props.handleSideBarComponent("SetAvailability")
        }
        else if([10].includes(this.state.goToStep))
        {
          this.props.handelAddPackageModalTour(false)
          this.props.handleSideBarComponent("Packages")
        }
        else if([11].includes(this.state.goToStep))
        {
          this.props.handleSideBarComponent("Packages")
          this.props.handelAddPackageModalTour(true)
        }
        else if([17,19].includes(this.state.goToStep))
        {
          

          //  if(this.state.goToStep == 17) this.props.handleSubComponent("Bio")
          //  if(this.state.goToStep == 18) this.props.handleSubComponent("Resources")
           if(this.state.goToStep == 19) {
             this.props.handleSubComponent("Resources") 
             this.props.TourResourceModal(true)
             
            
             timeout=2000
           }
          //  if(this.state.goToStep == 20) this.props.handleSubComponent("Testimonial")
          //  if(this.state.goToStep == 22) this.props.handleSubComponent("Blog")
          
          timeout=2000
        }
        else if([24,25].includes(this.state.goToStep))
        {
          this.props.handleSideBarComponent("ManagePayments")
          this.props.handalPaymentManageTab()
        }
        else if([26,27].includes(this.state.goToStep))
        {
          this.props.handleSideBarComponent("TransactionHistory")
          
        }

        
        if(this.slowRender.includes(this.state.goToStep))
        {
          timeout=6000
        }
        else
        {
          timeout=1000
        }
        setTimeout(() => {
          this.setState({isTourOpen:true},()=>{
            this.props.handaleLoader(false)
          })
        }, timeout);
      })
    
    }
    else
    {
      this.setState({isTourOpen:false})
    }
  

  }
  getContent(key){
    let mobileView =/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

    if(this.state.tour.length<=0)
    {
      return "No content"
    }

    if(mobileView)
    {
      let r = _.find(this.state.tour,function(o){return o.section_key==key}).content
    
      if(r.length>100)
          // return r.substring(0, 100)+' ...'
          return r
      else
          return r
      
    }
    else
    {
      return _.find(this.state.tour,function(o){return o.section_key==key}).content
    }
    
  }
  getStepKey(key,reverse=false){
    if(reverse)
    {
      return _.findIndex(this.state.steps,function(o,k){return o.selector==key})
    }

    if(this.state.steps.length>0)
    {
      // this.getStepKey(key,reverse)
      return _.find(this.state.steps,function(o,k){return k==key}).selector
    }

    
  }
 async componentDidMount()
  {
    let mobileview = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    console.log('mobileview CDM',mobileview)
    window.addEventListener('scroll', this.handleScroll);
    interval = setInterval(() => {
            this.resetTour()
    }, 2000);
   let res = await axios.get('/admin/getTourContent')
   let steps= []
   let px =14

        if(res.data.success)
        {
          this.setState({tour:res.data.row})
          steps = [
            {
              selector: '.tour-profile',
              content: this.getContent('tour-profile'),
              position: 'right',
              action: node => {this.scrollToID(node)},
            },
            {
              selector: '.tour-public-profile',
              content: this.getContent('tour-public-profile'),
              position: 'right',
              action: node => {this.scrollToID(node)},
              style:mobileview? {margin: '-130px 0px',fontSize:px} : {},
             
            },{
              selector: '.tour-profile-hourlyrate',
              content: this.getContent('tour-profile-hourlyrate'),
              position: 'right',
              action: node => {this.scrollToID(node)},
              style:mobileview? {margin: '-147px 0px',fontSize:px} : {},
              
            },
           
            {
              selector: '.tour-profile-address',
              content: this.getContent('tour-profile-address'),
              position: 'right',
              style:mobileview? {margin: '-180px 0px'} : {},
              action: node => {this.scrollToID(node)},
            
            },
            {
              selector: '.tour-profile-certificates',
              content: this.getContent('tour-profile-certificates'),
              position: 'right',
              style:mobileview? {margin: '170px 0px',fontSize:px} : {},
              action: node => {this.scrollToID(node)},
            },
            {
              selector: '.tour-profile-submit',
              content: this.getContent('tour-profile-submit'),
              position: 'right',
              action: node => {this.scrollToID(node)},
            },
           
            {
              selector: '.tour-SetAvailability',
              content: this.getContent('tour-SetAvailability'),
              position: 'right',
              action: node => {this.scrollToID(node)},
            },
            {
              selector: '.tour-SetAvailability-setTime',
              content: this.getContent('tour-SetAvailability-setTime'),
              position: 'left',
              style:mobileview? {margin: '-180px 0px',fontSize:px} : {},
              action: node => {this.scrollToID(node)},
            },
            {
              selector: '.tour-SetAvailability-unavailable-setTime',
              content: this.getContent('tour-SetAvailability-unavailable-setTime'),
              position:'left',
              style:mobileview? {margin: '60px 0px',fontSize:px,transform: 'translate(15px,250px)'} : {margin: '0px -414px'},
              action: node => {this.scrollToID(node)},
            },
            // {
            //   selector: '.tour-SetAvailability-submit',
            //   content: this.getContent('tour-SetAvailability-submit'),
            // },
            {
              selector: '.tour-createpackages',
              content: this.getContent('tour-createpackages'),
              position: 'right',
              action: node => {this.scrollToID(node)},
            },
            {
              selector: '.tour-createpackages-addPackage',
              content: this.getContent('tour-createpackages-addPackage'),
              position: 'left',
              action: node => {this.scrollToID(node)},
            },
            {
              selector: '.tour-createpackages-session-details',
              content: this.getContent('tour-createpackages-session-details'),
              position: mobileview? 'bottom' : 'left',
              style:mobileview? {margin: '-175px 0px'} : {},
              // action: node => {this.scrollToID(node)},
            },
            // {
            //   selector: '.tour-createpackages-session-submit',
            //   content: this.getContent('tour-createpackages-session-submit'),
            // },
            {
              selector: '.tour-intoVideo',
              content: this.getContent('tour-intoVideo'),
              position: 'left',
              style:mobileview? {margin: '175px 0px',fontSize:px} : {},
              action: node => {this.scrollToID(node)},
            },
            {
              selector: '.tour-addTags',
              content: this.getContent('tour-addTags'),
              position: 'left',
              style:mobileview? {margin: '-175px 0px',fontSize:px} : {},
              action: node => {this.scrollToID(node)},
            },
            {
              selector: '.tour-approval',
              content: this.getContent('tour-approval'),
              position: 'right',
              action: node => {this.scrollToID(node)},
            },
            {
              selector: '.tour-mySchedule',
              content: this.getContent('tour-mySchedule'),
              position: 'right',
              action: node => {this.scrollToID(node)},
            },
            {
              selector: '.tour-chatBox',
              content: this.getContent('tour-chatBox'),
              position: 'top',
              action: node => {this.scrollToID(node)},
            },
            {
              selector: '.tour-bio',
              content: this.getContent('tour-bio'),
              position: 'bottom',
              action: node => {this.scrollToID(node,true,'right')},
            },
            {
              selector: '.tour-resource',
              content: this.getContent('tour-resource'),
              position: 'bottom',
              action: node => {this.scrollToID(node,true,'right')},
            },
            {
              selector: '.tour-resource-upload',
              content: this.getContent('tour-resource-upload'),
              position: 'left',
              style:mobileview? {margin: '170px 0px',fontSize:px} : {margin: '0px -386px'},
              action: node => {this.scrollToID(node)},
              
            },
            {
              selector: '.tour-testimonials',
              content: this.getContent('tour-testimonials'),
              position: 'bottom',
              action: node => {this.scrollToID(node,true,'left')},
            },
            {
              selector: '.tour-manage-testimonials',
              content: this.getContent('tour-manage-testimonials'),
              position: 'right',
              action: node => {this.scrollToID(node)},
            },
            {
              selector: '.tour-blog',
              content: this.getContent('tour-blog'),
              position: 'right',
              action: node => {this.scrollToID(node,true,'left')},
            },
            {
              selector: '.tour-payment-management',
              content: this.getContent('tour-payment-management'),
              position: 'right',
              action: node => {this.scrollToID(node)},
            },
            {
              selector: '.tour-bank-details',
              content: this.getContent('tour-bank-details'),
              position: 'top',
              action: node => {this.scrollToID(node)},
            },
            {
              selector: '.tour-kyc-add',
              content:this.getContent('tour-kyc-add'),
              position: 'bottom',
              // style:mobileview? {margin: '165px 0px'} : {},
              action: node => {this.scrollToID(node)},
            },
            {
              selector: '.tour-transaction-history',
              content:this.getContent('tour-transaction-history'),
              position: 'left',
              action: node => {this.scrollToID(node)},
            },
            {
              selector: '.tour-payout-request',
              content:this.getContent('tour-payout-request'),
              position: 'left',
              action: node => {this.scrollToID(node)},
            },
            
            
            // ...
          ];

        }
        else
        {
          this.setState({isTourOpen:false})
        }
        

   
  
    

    
      

    
    
    this.setState({steps})
  }
  setIsTourOpen=async (e)=>{
    this.setState({isTourOpen:!this.state.isTourOpen},async()=>{
      localStorage.setItem('tourActive',this.state.isTourOpen)
      if(this.state.isTourOpen==false)
      {
        let userData = JSON.parse(localStorage.getItem('userdata'))
        if(this.getStepKey(this.state.goToStep)=='.tour-payout-request')
          {
            await axios.post('/admin/tourStatusChange',{uid:userData.uid,status:'complete'})
          }
          else{
            await axios.post('/admin/tourStatusChange',{uid:userData.uid,status:'inactive'})
          }
          
          clearAllBodyScrollLocks();
        
        
      }
    })
  }



resetTour=()=>{
  let goToStep =this.state.goToStep
  let mobileView =/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  if(!mobileView) this.setState({resetTour:0})

  // if(this.state.resetTour == 3 && interval) {
  //   clearInterval(interval)
  // }
  if(this.state.isTourOpen==true && this.state.resetTour<3 && this.getStepKey(goToStep)!='.tour-createpackages-session-details')
  {
    console.log('this.state.resetTour',this.state.resetTour)
    this.setState({isTourOpen:false})
    this.setState({isTourOpen:true})
    this.setState({resetTour:this.state.resetTour+1})
    

    
    setTimeout(() => {
      console.log('tour re open')
      
      this.setState({resetTourRequest:false})
    }, 200);

  }
  

}
   disableBody = target => disableBodyScroll(target)
   enableBody = target => enableBodyScroll(target)




  curentStep=(curr)=>{
    if(curr>=this.getStepKey('.tour-intoVideo',true)) 
    {
      this.setState({showCloseButton:true})
    }
    
   
  }
  nextStep=()=>{
    this.setState({resetTour:0})
    this.setState({disableButton:true})
    this.props.handaleLoader(true)
    //
    let disableStep = this.disableSteps
    let timeout = 0
   
    if(disableStep.includes(this.state.goToStep)==false)
    {
      let goToStep =this.state.goToStep+1
      console.log('getStepKey',this.getStepKey(goToStep))
      localStorage.setItem('tourStep',goToStep)

      timeout =this.stepRedirection(this.getStepKey(goToStep))
        setTimeout(() => {
          this.setState({goToStep:this.state.goToStep+1},()=>{
            console.log('next',this.state.goToStep)
            //
            this.setState({disableButton:false})
            this.props.handaleLoader(false)
          })
          
        }, timeout);
     

    }
   
   
    

  }
  prevStep=()=>{
    this.setState({resetTour:0})
    this.props.handaleLoader(true)
    this.setState({disableButton:true})
///

    let disablePrevStep = this.disablePrevSteps
    let goToStep = this.state.goToStep-1
    let timeout = 0
    if(disablePrevStep.includes(this.state.goToStep)==false)
    {
        localStorage.setItem('tourStep',goToStep)
        timeout =this.stepRedirection(this.getStepKey(goToStep),'prev')
        
            setTimeout(() => {
              this.setState({goToStep:this.state.goToStep-1},()=>{
                console.log('prev',this.state.goToStep)
                //
                this.setState({disableButton:false})
               this.props.handaleLoader(false)
              })
              
            }, timeout);
      }
   
  }

  scrollToID=(element,xx=false,side='')=>{
         
     
        console.log('mobileview',/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
        let mobileView =/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

        if(mobileView)
        {
              const yOffset = -100; 
            

              if(element!=null)
              {
                      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});

                    if(xx)
                    {
                      // scroll x acees
                      let el =  document.getElementsByClassName('tour')[0]
                      let width = el.offsetWidth
                     
                      element = document.getElementsByClassName('coach_public_tabs')[0]

                      if(side=='right')
                          element.scrollLeft += -width*3;
                      else
                          element.scrollLeft += width*3;

                    }
                    

              }
              

        }
  }


  stepRedirection = (step,flow='next')=>{

   
            
           

    let timeout = 0;
    switch (step) {
      case '.tour-public-profile':
                                  if(flow=='next')
                                  {
                                    window.location.replace('/profile-coach-edit')
                                    timeout=  3000;
                                  }
                                  else{
                                    
                                    timeout=  0;
                                  }
                                  
                                  break;
      case '.tour-SetAvailability-setTime':
                                  if(flow=='next')
                                  {
                                    this.props.handleSideBarComponent("SetAvailability")
                                    timeout=  3000;
                                  }
                                  else
                                  {
                                    timeout=  0;
                                  }
                                  
                                  
                                   break;
      case '.tour-createpackages-addPackage':
                                  this.props.handelAddPackageModalTour(false)
                                  this.props.handleSideBarComponent("Packages")
                                  timeout=  3000;
                                  break;
      case '.tour-SetAvailability':
                                  if(flow=='next')
                                  {
                                    window.location.replace('/profile-coach')
                                    timeout=  3000;
                                  }
                                  else{
                                    
                                    timeout=  0;
                                  }
                                  
                                  break;
      case '.tour-intoVideo':
                                if(flow=='next')
                                {
                                  window.location.reload()                    
                                  timeout=  3000;
                                }
                                else
                                {
                                  timeout=  0;
                                }
                                  
                                  break;


      case '.tour-profile':
                                  window.location.replace('/profile-coach')
                                  timeout=  3000;
                                  break;
      case '.tour-profile-submit':
                                  if(flow=='prev')
                                  {
                                    window.location.replace('/profile-coach-edit')
                                    timeout=  3000;
                                  }
                                  else{
                                    timeout=  0;
                                  }
                                  
                                  
                                  break;
      case '.tour-SetAvailability-unavailable-setTime':
                                  this.props.handleSideBarComponent("SetAvailability")
                                  timeout=  3000;
                                  break;
      case '.tour-createpackages-session-details':
                                  this.props.handleSideBarComponent("Packages")
                                  this.props.handelAddPackageModalTour(true)
                                  timeout=  3000;
                                  break;

      case '.tour-bio':
                                  this.props.handleSubComponent("Home")
                                  break;
      case '.tour-resource':
                                  this.props.TourResourceModal(false)
                                  break;
      case '.tour-resource-upload':
                                  this.props.handleSubComponent("Resources")
                                  setTimeout(() => {
                                    this.props.TourResourceModal(true)
                                  }, 1000);
                                  
                                  break;
                                  
      case '.tour-testimonials': 
                                if(flow=='next')
                                {
                                  // window.location.replace('/profile-coach')
                                  this.props.TourResourceModal(false)
                                  this.props.handleSubComponent("Home")
                                }
                                else
                                {
                                  this.props.TourResourceModal(false)
                                  this.props.handleSubComponent("Home")
                                }
                                  
                                  
                                  break;
      case '.tour-blog':
                                  // this.props.handleSubComponent("Blog")
                                  break;
      case '.tour-bank-details':
                                this.props.handleSideBarComponent("ManagePayments")
                                timeout=  3000;
                                this.props.handalPaymentManageTab()
                                break;
      case '.tour-kyc-add':
                                this.props.handleSideBarComponent("ManagePayments")
                                timeout=  3000;
                                this.props.handalPaymentManageTab()
                                break;
      case '.tour-transaction-history':
                                this.props.handleSideBarComponent("TransactionHistory")
                                timeout=  3000;
                               
                                break;
      case '.tour-payout-request':
                                this.props.handleSideBarComponent("TransactionHistory")
                                timeout=  3000;
                                
                                break;
                                
                                  
      


    
      default:
        break;
    }

    return timeout

  }

  disableSteps = []//[5,8,10,11]
  disablePrevSteps = []//[0,11,12]
  disableInteraction = [0,6,9,15,16,17,18,20,21,22]
  slowRender = [0,6,9,15,16,18,21,22]//20

 
  
  


  render() {
    return (
      <div>
       
        {this.state.steps.length>0 && this.state.goToStep!=='' && (<>
        
         <Tour
         {...this.props}
          steps={this.state.steps}
          isOpen={this.state.isTourOpen}
          onRequestClose={this.setIsTourOpen}
          disableKeyboardNavigation={false}
          disableFocusLock ={this.state.goToStep==11? true : false}
          disableInteraction={true}//{this.disableInteraction.includes(this.state.goToStep)}
          disableDotsNavigation={false}
          closeWithMask={false}
          showCloseButton={this.state.showCloseButton}
          showNavigation={false}
          scrollDuration={10}
          scrollOffset={this.state.scrollOfset}//-150
          maskSpace={this.getStepKey(this.state.goToStep)=='.tour-SetAvailability-unavailable-setTime'? 250 : 10}
          // inViewThreshold={50}
          onAfterOpen={this.disableBody}
          onBeforeClose={this.enableBody}
          getCurrentStep={curr => this.curentStep(curr)}
          highlightedMaskClassName="highlightedpart"
          // maskClassName="TourMask"
          className="TourHelper"
          prevStep={this.prevStep}
          nextStep={this.nextStep}
          nextButton={<button className={this.state.disableButton? 'tourNextButton tourButtonDisable' : 'tourNextButton'}>Next</button>}
          prevButton={<button className={this.state.disableButton? "tourPrevButton tourButtonDisable" : "tourPrevButton"}>Prev</button>}
          lastStepNextButton={<button className="tourLastButton">Complete</button>}
          // onBeforeClose={this.tourComplete}
          // onRequestClose={this.closeTour}
          goToStep={this.state.goToStep}
          rounded={5}
      />
     
      </>)}
        
      </div>
    );
  }
}

export default TourSite;
// export default withRouter(TourSite);
