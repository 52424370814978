import React from 'react'

import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap'
import { join_logo, reg_left,Em_reg } from './../../../assets/images/index'

import './Signup.css'
import axios from '../../../config/axios'
import message from "../../../utils/mesaages";
import { getClientTimezone } from '../../../utils/timeZoneConvert'
import validation from '../../../Validate/validator';

import FooterLink from '../../footers/FooterLink';
import HeaderSecondTo from '../Header/HeaderSecondTo'
// import Header from './../../headers/Header'
import PhoneInput from 'react-phone-input-2'
import {showPhonecountry} from "../../../config/custom";

class UserSignup extends React.Component {
    constructor(props) {
        super(props)

        this.token = localStorage.getItem("corporate_token")
        this.userdeta = localStorage.getItem("corporate_userdata")
        this.usertoken = localStorage.getItem("corporate_user_token")
        this.userDeta = localStorage.getItem("corporate_user_userdata")

        this.state = {
            loader: false,
            firstname: "",
            lastname: "",
            email: "",
            conirmemail: "",
            phone: "",
            password: "",
            confirmpassword: "",
            jobtitle: "",
            id: "",
            company_id: "",
            company_details: [],
            user_details: [],
            errors: [],
            FromDataError: {
                firstname: "false",
                lastname: "false",
                email: "false",
                conirmemail: "false",
                phone: "false",
                password: "false",
                confirmpassword: "false",
                jobtitle: "false",
            },
            error: [],
            emailError: "",
            passwordError: "",
            icon: "",
            Emailicon: "",
            companyEmailError: ""
        }
    }

    componentDidMount() {
        if ((this.token && this.userdeta)) {
            this.props.history.push('/dashboard')
        } else if (this.userDeta && this.usertoken) {
            this.props.history.push('/webinars')
        }
        if (new URLSearchParams(this.props.location.search).get("token")) {
            axios.post(`/api/corporate_user_details`, { token: new URLSearchParams(this.props.location.search).get("token") }).then((response) => {
                if (response.data.success) {
                    // console.log(response.data.deta.companyDetails)
                    this.setState({ id: response.data.deta.userDetails.id, corporate_user_id: response.data.deta.userDetails.corporate_user_id, firstname: response.data.deta.userDetails.firstname, lastname: response.data.deta.userDetails.lastname, phone: response.data.deta.userDetails.contact_no, email: response.data.deta.userDetails.email, company_id: response.data.deta.companyDetails.company_id, company_details: response.data.deta.companyDetails, user_details: response.data.deta.userDetails })
                }
            }).catch((error) => {
                message.error(error.response.data.msg);
                this.props.history.push('/employee-login')
                this.setState({ loader: false });
            });
        }
    }

    validateForm(errors) {
        let valid = true;
        let sta = this;
        let FromDataError = this.state.FromDataError;

        for (const [key, value] of Object.entries(errors)) {
            if (value.length > 0) {
                if (value === "false") {
                    FromDataError[key] = "This field is required";
                    sta.setState({ FromDataError });
                }
                valid = false;
            }
        }
        return valid;
    }

    handleChangephone = (e, data, event, formattedValue) => {
        let FromDataError = this.state.FromDataError
    
        if ((e.length - data.dialCode.length) >= 10) {
          console.log('true')
          FromDataError.phone = ''
        } else {
          console.log('false')
          FromDataError.phone = 'Enter valid phone number'
        }
    
        this.setState({
          FromDataError
        });
    
        setTimeout(() => {
          this.setState({
            ['phone']: e,
          });
    
        }, 100);
      };
    handleChange = (e) => {
        e.persist();
        if (this.state.passwordError !== "") {
            if (e.target.name === "confirmpassword") {
                this.setState({ passwordError: "" })
            }
        }
        if (this.state.emailError !== "") {
            if (e.target.name === "conirmemail") {
                this.setState({ emailError: "" })
            }
        }

        if (this.state.companyEmailError !== "") {
            if (e.target.name === "conirmemail") {
                this.setState({ companyEmailError: "" })
            }
        }

        let { FromDataError } = this.state;
        var valid_obj = {
            value: e.target.value,
            rules: e.target.getAttribute("validaterule"),
            message: e.target.getAttribute("validatemsg"),
        };

        validation(valid_obj).then((error) => {
            console.log("aaaaaaaaaaa", error);
            FromDataError[e.target.name] = error;
            this.setState({ FromDataError });
            setTimeout(() => {
                this.setState({
                    [e.target.name]: e.target.value,
                });
            }, 100);
            if (e.target.name === "confirmpassword") {
                if (e.target.value === this.state.password) {
                    this.setState({ icon: "fa-check" })
                } else if (e.target.value !== this.state.password) {
                    this.setState({ icon: "fa-times" })
                }
            }
            if (e.target.name === "password") {
                if (e.target.value === this.state.confirmpassword) {
                    this.setState({ icon: "fa-check" })
                } else if (e.target.value !== this.state.confirmpassword) {
                    this.setState({ icon: "fa-times" })
                }
            }
            if (e.target.name === "conirmemail") {
                if (e.target.value === this.state.email) {
                    this.setState({ Emailicon: "fa-check" })
                } else if (e.target.value !== this.state.email) {
                    this.setState({ Emailicon: "fa-times" })
                }
            }
            if (e.target.name === "companyname") {
                var my_email = this.state.conirmemail
                var ind = my_email.indexOf("@");
                let value = e.target.value
                if (my_email.slice((ind + 1), my_email.length).split(".")[0].toLowerCase() === value.toLowerCase()) {
                    this.setState({ Emailicon: "fa-check" })
                } else if (my_email.slice((ind + 1), my_email.length).split(".")[0].toLowerCase() !== value.toLowerCase()) {
                    this.setState({ Emailicon: "fa-times" })
                }
            }
        });
    };

    onHandleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loader: true });
        const hideMessage = message.loading("Please wait while register in ...", 0);
        let formDeta = {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            phone: this.state.phone,
            password: this.state.password,
            jobtitle: this.state.jobtitle,
            company_id: this.state.company_id,
            id: this.state.id,
            corporate_user_id: this.state.corporate_user_id,
            company_details: this.state.company_details,
            user_details: this.state.user_details
        }
        axios.post(`/api/corporate_user_signup`, formDeta, { headers: { "Content-Type": "application/json" } }).then(async (response) => {
            hideMessage();
            if (response.data.token !== "" && response.data.success) {
                message.success(response.data.msg)
                document.getElementById("abc").innerHTML = ""
                document.getElementById("registractionform").innerHTML = "<div class='text-center'><h4 class='mb-4'> You are successfully registered. <br/> To activate your account click on the activation link sent to your mail </h4></div>"
                getClientTimezone();
                this.setState({ loader: false });
                this.props.history.push('/employee-login')
            }
        }).catch((error) => {
            hideMessage();
            console.log(error);
            message.error(error.response.data.msg);
            this.setState({ loader: false });
        });
    }

    render() {
        return (
            <>
                <HeaderSecondTo />
                <div className="reg">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className='reg_head text-center'>
                                    <h2>Employee Registration</h2>
                                    <h3 className='text-capitalize'>Invitation From <span className='ifc'><i className="fa fa-building" aria-hidden="true"></i> {this.state.company_details.company_name}</span></h3>
                                    <p className="mb-0" id="abc">Please fill in your details</p>
                                </div>
                            </Col>
                        </Row>

                        <Card className='reg_card mt-4 border-0'>
                            <Row className='px-0'>
                                <Col md={6} className="reg_image d-flex align-items-center" style={{background: 'url('+Em_reg+') center center no-repeat', backgroundSize: 'cover'}}>
                                    <div>
                                    {/* <img src={reg_left} className="img-fluid" alt='reg_left' /> */}
                                    <ul className='list-unstyled'>
                                        <li>Employees can register via the invitation link sent to them by the Company</li>
                                        <li>Employees should login using the email address where their invitation was sent</li>
                                        <li>Employees will have access to a wide range of webinars that will support and enhance their workplace experience</li>
                                    </ul>
                                    </div>
                                </Col>
                                <Col md={6} className="reg_form" id="registractionform">
                                    <Form onSubmit={this.onHandleSubmit}>
                                        <div className='form_item mb-4'>
                                            <h4 className="regf_title">Personal Info</h4>
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>First Name <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" name='firstname' disabled validaterule={["required"]} validatemsg={["Enter your Name"]} value={this.state.firstname} required onChange={(e) => this.handleChange(e)} />
                                                        <div style={{ color: "red" }}>
                                                            {this.state.FromDataError.firstname === "false" ? "" : this.state.FromDataError.firstname}
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Last Name <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" name='lastname' disabled validaterule={["required"]} validatemsg={["Enter your Name"]} value={this.state.lastname} required onChange={(e) => this.handleChange(e)} />
                                                        <div style={{ color: "red" }}>
                                                            {this.state.FromDataError.lastname === "false" ? "" : this.state.FromDataError.lastname}
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="email" name='email' required validaterule={["required", "isEmail"]} validatemsg={["Enter your email address", "Enter a valid email address",]} value={this.state.email} disabled />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Phone <span className="text-danger">*</span></Form.Label>
                                                        <div className="input-group">
                                                            {/* <div className='input-group-prepend'>
                                                                <Form.Control as="select">
                                                                    <option>+1</option>
                                                                    <option>+91</option>
                                                                </Form.Control>
                                                            </div>
                                                            <Form.Control type="tel" name='phone' validaterule={["required", "phone"]} validatemsg={["Enter your phone number ", "Enter a valid phone number"]} 
                                                            required onChange={(e) => this.handleChange(e)} /> */}

                                                        <PhoneInput 
                                                        country={"us"}
                                                        onlyCountries = {showPhonecountry}
                                                        name='phone' required={true}  
                                                        value={this.state.phone}
                                                        countryCodeEditable={false} 
                                                        inputClassName="form-control phone-reg" 
                                                        placeholder="Phone Number" 
                                                        onChange={this.handleChangephone} 
                                                        validaterule={['required', 'phone']} 
                                                        validatemsg={['Phone number is required', 'Please enter a valid phone number']} />
                                                        </div>
                                                        <div style={{ color: "red" }}>
                                                            {this.state.FromDataError.phone === "false" ? "" : this.state.FromDataError.phone}
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Password <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="Password" name='password' validaterule={["required", "password"]} validatemsg={["Enter your password "]} required onChange={(e) => this.handleChange(e)} />
                                                        <div style={{ color: "red" }}>
                                                            {this.state.FromDataError.password === "false" ? "" : this.state.FromDataError.password}
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Confirm Password <span className="text-danger">*</span></Form.Label>
                                                        <div className='confirmpassword'>
                                                            <Form.Control type="Password" name='confirmpassword' validaterule={["required", "password"]} validatemsg={["Enter your password "]} required onChange={(e) => this.handleChange(e)} />
                                                            <i class={`fa ${this.state.icon}`} aria-hidden="true"></i>
                                                        </div>
                                                        <div style={{ color: "red" }}>
                                                            {this.state.FromDataError.confirmpassword === "false" ? "" : this.state.FromDataError.confirmpassword}
                                                            {this.state.passwordError !== "" && this.state.passwordError}
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <Form.Label>Employee Job Title<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" name='jobtitle' required onChange={(e) => this.handleChange(e)} />
                                                        <div style={{ color: "red" }}>
                                                            {this.state.FromDataError.jobtitle === "false" ? "" : this.state.FromDataError.jobtitle}
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Button variant='default' className="defaultBtn" type='submit'>Submit</Button>
                                    </Form>
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </div>
                <FooterLink />
            </>
        )
    }
}

export default UserSignup