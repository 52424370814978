import React, { Component } from 'react'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import LoadingOverlay from 'react-loading-overlay'
import { LGspinner } from '../../../assets/images'
import CustomImageLoader from "react-custom-image-loader.";
import { Badge, Button, Card, Col, Form, FormControl, InputGroup, Row, Table } from 'react-bootstrap';
import Select from "react-select";
// import InputMask from "react-input-mask";
//https://github.com/sanniassin/react-input-mask

import './MyTransaction.css'
import TransactionModal from './TransactionModal'
import axios from '../../../config/axios'


import {
    Wallet
} from './../images/index'
import { message } from 'antd'
import ReactPaginate from 'react-paginate'
import moment from 'moment'


export default class MyTransaction extends Component {
    constructor(props) {
        super(props)

        this.token = localStorage.getItem("corporate_token")
        this.userdeta = JSON.parse(localStorage.getItem("corporate_userdata"))

        this.state = {
            loader: false,
            totalEmployee: "",
            amount: "",
            show: false,
            modal: "",
            error: [],
            transactionHistory: [],
            balance: "",
            creditBalance: "",
            pandingBalance: "",
            debitBalance: "",
            search: "",
            amountPerEmployee: [],
            total_pages: "",
            total_rows: "",
            offset: "",
            page: 1,
            limit: 10,
            employee: []
        }
    }

    onChange = async (e) => {
        let amount
        if (e.target.value === "") {
            await this.setState({ totalEmployee: "", amount: "", error: [] });
        } else {
            (e.target.value === "") ? amount = "" : amount = parseInt(e.target.value) * this.state.amountPerEmployee.amount_per_employee
            await this.setState({ totalEmployee: parseInt(e.target.value), amount: amount, error: [] });
        }
    }

    handleClose = () => {
        this.setState({ show: false })
    };

    handleShow = () => {
        if (this.state.amount !== "") {
            axios.post(`/api/corporate_card_details`, { user_id: this.userdeta.id }, { headers: { "X-Auth": this.token, } }).then((responce) => {
                if (responce.data.success) {
                    if (responce.data.cardDetails.length === 0) {
                        this.setState({ show: true }, () => {
                            this.setState({ modal: <TransactionModal show={this.state.show} flag={false} handleClose={this.handleClose} totalAmount={this.state.amount} totalEmployee={this.state.totalEmployee} getBalance={this.GetBalance} /> })
                        })
                    } else {
                        this.setState({ show: true }, () => {
                            this.setState({ modal: <TransactionModal show={this.state.show} flag={true} handleClose={this.handleClose} totalAmount={this.state.amount} totalEmployee={this.state.totalEmployee} getBalance={this.GetBalance} /> })
                        })
                    }
                }
            }).catch((error) => {
                message.error(error.response.msg)
            })
        } else {
            let error = []
            error["employee"] = "Please enter total employees"
            this.setState({ error: error })
        }
    };

    onSessionsPageChange = (page) => {
        let page1 = page.selected + 1;
        this.setState({ page: page1 }, () =>
            this.GetTransactionHistory()
        );
    };

    GetBalance = async () => {
        this.setState({ loader: true });
        await axios.post(`/api/corporate_get_wallat_balance`, { user_uid: this.userdeta.uid, id: this.userdeta.id }, { headers: { "X-Auth": this.token, } }).then((response) => {
            if (response.data.success) {
                this.setState({ creditBalance: response.data.creditBalance, balance: response.data.totalBalance, totalEmployee: "", amount: "", loader: false, pandingBalance: response.data.pandingBalance, debitBalance: response.data.debitBalance, employee: response.data.employees })
                this.GetTransactionHistory()
            }
        }).catch((error) => {
            console.log(error)
            this.setState({ loader: false });
            message.error(error.response.msg)
        })
    }

    GetTransactionHistory = async () => {
        await axios.get(`/api/corporate_transaction_history?limit=${this.state.limit}&page=${this.state.page}&user_uid=${this.userdeta.uid}&id=${this.userdeta.id}`, { headers: { "x-Auth": this.token } }).then((response) => {
            if (response.data.success) {
                this.setState({ transactionHistory: response.data.history, total_pages: response.data.total_pages, total_rows: response.data.total_rows, offset: response.data.offset })
            }
        }).catch((error) => {
            console.log(error.response)
            message.error(error.response.msg)
        })
    }

    onHandleChange = (e) => {
        this.setState({ search: e.target.value })
    }

    AmountPerEmployee = async () => {
        this.setState({ loader: true });
        await axios.get(`/api/corporate_payment_setting`, { headers: { "x-Auth": this.token } }).then((response) => {
            if (response.data.success) {
                this.setState({ amountPerEmployee: response.data.amount, loader: false }, () => {
                    console.log(response.data.amount)
                })
            }
        }).catch((error) => {
            this.setState({ loader: false });
            message.error(error.response.data.msg)
        })
    }

    componentDidMount() {
        this.GetBalance()
        this.AmountPerEmployee()
    }

    render() {

        const { loader, amount, modal, show, error, creditBalance, totalEmployee, balance, transactionHistory, pandingBalance, debitBalance, amountPerEmployee, employee } = this.state

        return (
            <div className='content_main'>
                <Header />
                <LoadingOverlay active={loader} spinner={<CustomImageLoader image={LGspinner} animationType={"pulse"} />} className="webiner_loader" >
                    <div className='content_wrap'>
                        <h4 className='page-title mb-5'>My Transactions</h4>
                        <Card className='crCard border-0 mb-4'>
                            <Card.Body>
                                <Row>
                                    <Col md={7}>
                                        <div className='wallet_card'>
                                            <div>
                                                <div className="mb-4">
                                                    <img src={Wallet} width={60} alt='' />
                                                </div>

                                                <Row>
                                                    <Col>
                                                        <Card className='crCard'>
                                                            <Card.Body>
                                                                <h4>Company Contribution</h4>
                                                                <h2>${creditBalance ? parseInt(creditBalance).toFixed(2) : parseInt(0).toFixed(2)}</h2>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <Card className='crCard'>
                                                            <Card.Body>
                                                                <h4>Funds available to invite</h4>
                                                                <h2>${balance ? parseInt(balance).toFixed(2) : parseInt(0).toFixed(2)}</h2>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                                <ul className="list-group list-group-flush mt-4">
                                                    <li className="list-group-item px-0">Funds To Be Applied When Invite Accepted : <strong>${pandingBalance ? parseInt(pandingBalance).toFixed(2) : parseInt(0).toFixed(2)}</strong></li>
                                                    <li className="list-group-item px-0">Funds Spent : <strong>${debitBalance ? parseInt(debitBalance).toFixed(2) : parseInt(0).toFixed(2)}</strong></li>
                                                    <li className="list-group-item px-0">Total number of invited employees: <strong>{employee.pandingEmployees?.pandingemployees}</strong></li>
                                                    <li className="list-group-item px-0">Total number of joined employees: <strong>{employee.joinEmployee?.joinemployee}</strong></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={5}>
                                        <Card.Title className='mb-4'>Please enter the number of employees you would like to invite.</Card.Title>
                                        <Badge variant="primary" className='mb-4 p-2'>Each invitation code is worth ${amountPerEmployee.amount_per_employee}. The amount you owe will be calculated based on the number of employees you invite.</Badge>
                                        <Form>
                                            <Form.Group>
                                                <Form.Label>Number of employees invited</Form.Label>
                                                <Form.Control value={totalEmployee} required onChange={this.onChange} />
                                                {error["employee"] && (<p className="text-danger"> {error["employee"]} </p>)}
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Amount ($)</Form.Label>
                                                <Form.Control value={(amount !== "") ? parseInt(amount).toFixed(2) : amount} disabled required />
                                            </Form.Group>
                                            <Button variant='default' className='defaultBtn btn-block' onClick={this.handleShow}>PAY</Button>
                                        </Form>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card className="crCard border-0 mb-4">
                            <Card.Header className="bg-white ch-tool">
                                <Row className="align-items-center">
                                    <Col md={8}>
                                        <Card.Title className="mb-0 h4">Transaction History</Card.Title>
                                    </Col>
                                    {/* <Col md={4}>
                                        <InputGroup className="table_search">
                                            <InputGroup.Prepend className="mr-0">
                                                <InputGroup.Text className="border-0 bg-white"><i className="fa fa-search"></i></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl type="number" className="border-0" placeholder="Search" onChange={this.onHandleChange} />
                                        </InputGroup>
                                    </Col> */}
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Table responsive className="mb-0 text-center">
                                    <thead>
                                        <tr>
                                            <th className="border-top-0">Description</th>
                                            <th className="border-top-0">Date</th>
                                            <th className="border-top-0">Time</th>
                                            <th className="border-top-0">Credit / Debit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (transactionHistory.length === 0) ? <p> {this.state.message} </p> :
                                                transactionHistory.map((items) => (
                                                    <tr>
                                                        <td>{items.note}</td>
                                                        <td>{moment(items.created_at).format("DD-MM-YYYY")}</td>
                                                        <td>{moment(items.created_at).format("HH:MM:SS")}</td>
                                                        <td className="d-flex justify-content-center align-items-center" style={{ "gap": "10px" }}>
                                                            {
                                                                (items.transaction_type === '0') ? (
                                                                    <i className="fa fa-plus text-success" aria-hidden="true" style={{ "fontSize": "10px" }}></i>
                                                                ) : (
                                                                    <i className="fa fa-minus text-danger" aria-hidden="true" style={{ "fontSize": "10px" }}></i>
                                                                )
                                                            }
                                                            <div style={{ "minWidth": "80px" }}>
                                                                <p className={(items.transaction_type === '0') ? "text-success p-0 m-0" : "text-danger p-0 m-0"}>${parseInt(items.amount).toFixed(2)}</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                            <Card.Footer className="bg-white">
                                <Row className="align-items-center">
                                    <Col md="4">
                                        <label className='mb-0'>{this.state.offset ? (this.state.offset + 1) : 1} - {this.state.total_rows} of {this.state.total_rows}</label>
                                    </Col>
                                    <Col md="8" className='result_pagination'>
                                        <ReactPaginate
                                            className="mb-0 justify-content-end"
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={this.onSessionsPageChange}
                                            pageRangeDisplayed={6}
                                            pageCount={this.state.total_pages}
                                            previousLabel="<"
                                            activeClassName="active"
                                            disabledClassName="disable"
                                            renderOnZeroPageCount={null}
                                        />
                                    </Col>
                                </Row>
                            </Card.Footer>
                        </Card>
                    </div>
                </LoadingOverlay>
                <Sidebar />
                {
                    (show === true) ? modal : ''
                }
            </div>
        )
    }
}
