import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";

export default class WebinerLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      userdata: {},
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({ isActive: !this.state.isActive });
  }

  render() {
    let userdata = localStorage.getItem("userdata");
    if (userdata && JSON.parse(userdata).account_type !== "jobseeker") {
      return <Redirect to="/login" />;
    }
    return (
      <div
        className={
          !this.state.isActive ? "content_main " : "active content_main"
        }
      >
        <Header handleClick={this.handleClick} />

        {this.props.children}

        <Sidebar />
      </div>
    );
  }
}
