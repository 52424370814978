import React, { Component } from "react";
import axios from "../config/axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import {
  CoachTransactionhistory, ConsumerPayment, JoinSession,
  PaymentReceived, ManageSubscription, SetAvailability, UpcomingSession,
  ReviewList, Packages, Webinars, Sessions
} from "../views/coach/sidebarComponents";

import MySchedule from "../views/coach/sidebarComponents/MySchedule";
import { Col, ProgressBar, Row } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import { Tooltip } from 'antd';
import message, { getHelpText } from '../utils/mesaages';
import TourSite from '../utils/TourSite'
import moment from "moment";




// import _ from "lodash";
import ToggleButton from 'react-toggle-button'
import { Header, HeaderLogout, HeaderTop } from "../components/headers";
import { FooterLink } from "../components/footers";
import {
  Bio,
  Blog,
  Contact,
  Home,
  Resources,
  Testimonial,
} from "../views/coach";

import BookSession from '../views/coach/view/BookSession'

import { avatar } from "../assets/img";
import {
  mailSvg,
  profileAddressSvg,
  flagSvg,
  clockSvg,
  writingSvg, quality, LGspinner
} from "../assets/images";
import {
  UpdateImage,
  UpdatePersonal,
  UpdateUserName,
  UpdateCategory,
} from "../views/coach/edit";
import CommunicationUsers from "./Communication/CommunicationUsers";
import { Redirect } from "react-router-dom";
import CoachSideBar from "../views/coach/coachSideBar";
import CustomImageLoader from 'react-custom-image-loader.'
import io from "socket.io-client";

import { getToken } from "firebase/messaging";
import { messaging } from "../firebase";

class Coach extends Component {

  constructor(props, context) {
    super(props, context);
    this.token = localStorage.getItem("authToken");

    // if (localStorage.getItem("userdata")) {
    //   this.userdata = JSON.parse(localStorage.getItem("userdata"));
    // } else {
    //   if (this.props.location.state !== "undefined") {
    //     this.userdata = {
    //       account_type: "user",
    //       uid: this.props.location.state.coachData.uid,
    //     };
    //   } else {
    //     alert("please Login for more details.");
    //   }
    // }

    if (localStorage.getItem("user")) {
      this.userdata = JSON.parse(localStorage.getItem("user"));
    }

    else if (this.props.location !== undefined) {
      this.userdata = {
        account_type: "user",
        uid: this.props.location.state.coachData.uid,
      };

    }

    else if (localStorage.getItem("userdata")) {
      this.userdata = JSON.parse(localStorage.getItem("userdata"));


    }


    else {
      // #temprary if page will refereshed by mistake
      this.userdata = {
        account_type: "user",
        uid: "44abb6bc-8361-4a56-a88a-3b4c8b10eea2",
      };
    }

    // this.coachingType = [];

    this.state = {
      phone_no : "",
      email_id : "",
      SocialMediaInfo : "",
      skills : "",
      area_of_work : "", 
      coach_type : "",
      freeTrialDaysleft: "",
      renderSubComponent: "Home",
      avatar: "",
      avatar_path: avatar,
      name: "",
      coach_title: "",
      user_title: "",
      // coach_category: "",
      // coaching_category: "",
      city: "",
      email: "",
      country: "",
      location: "",
      userImage: "",
      coach_uid: "",
      short_bio: "",
      long_bio: "",
      long_bio_fetch: "",
      videofile: "",
      pendingVideoFile: "",
      tags: "",
      skills: "",
      isLoaded: "",
      coachprofile_id: "",
      check_account_type: "",
      // dounat chart value using this___start
      chartValue: [],
      labels_data: [],
      data_data: [],
      color_data: [],
      // dounat chart value using this____end
      // coaching_category_list: [],
      language: [{ value: "English", label: "English" }],
      timezone: "",
      HoursofExp: "",
      HourlyRate: "",
      membership: 0,
      alertMsg: "",

      baseCurrency: "USD",
      convertToCurrency: "USD",
      baseAmount: 100,
      rates: [],
      currencies: [],
      findcurrency: 1,
      totalprofileupdate: 0,
      tag_update_profile: 0,                      //10 + intro video 10 
      // avatar_path_update_profile: 0,
      // user_title_update_profile: 0,
      // short_bio_update_profile: 0,
      user_basic_update_profile: 0,               //10
      user_basicone_update_profile: 0,            //10
      user_certificate_update_profile: 0,         //20
      packages_update_profile: 0,                 //20
      user_availability_update_profile: 0,        //20
      coachingCategory: null,
      coachUid: "",
      coachEmail: "",
      Consumer_name: "",
      Consumer_email: "",
      SocialMediaInfo: [{ SocialMedia: "", URL: "" }],
      public_profile_toggle: false,
      isApproved: 0,
      loader: false,
      coachSettings: "",
      Profile_help_text: '',
      profile_name_help_text: '',
      has_membership: 0,
      redirect: false,
      Public_view_uid: '',
      coachpublic_profile_help_text: '',
      city_public: 0,
      redirectHome: false,
      leftBar: 'load',
      renderSideBarComponent: '',
      processed: '',
      membership_id: 0,
      subscription_id: null,
      //for tour  section
      TourResourceModal: '',
      loaderTour: false,
      incomplete_kyc_help_text: "",
      coachKYCstatus: true,
      scrollToPayment: "",

      //
    };

    this.baseUrl = axios.defaults.baseURL //
    this.socket = io(this.baseUrl); //
  }

  TourResourceModal = (g) => {
    this.setState({ TourResourceModal: g })
  }
  handaleLoader = (status) => {
    this.setState({ loaderTour: status })
  }

  // componentWillUnmount = () => {
  //   this.socket.on("convertResponse", (data) => {
  //     console.log("received coachID", data.coachID)
  //     if(data.coachID === this.userdata.uid) {
  //       if(data.status === "success") {
  //         message.success("Your video has been processed and uploaded successfully!",5);
  //       }
  //       else {
  //         message.warning("Your video failed to processed please try again!",5);
  //       }
  //       window.location.reload()
  //     }
  //   })
  // }


  async componentDidMount() {
    this.socket.on("connect", () => {
      console.log("connect After Login", this.socket.id);
    });

    // this.socket.emit("sendCoachDetails", {coachID: this.userdata.uid})

    this.socket.on("convertResponse", (data) => {
      console.log("received coachID", data.coachID)
      if (data.coachID === this.userdata.uid) {
        if (data.status === "success") {
          message.success("Your video has been processed and uploaded successfully!", 5);
        }
        else {
          message.warning("Your video failed to processed please try again!", 5);
        }
        window.location.reload()
      }
    })

    if (window.location.hash == '#Manage-Subscription') {
      // this.setState({renderSideBarComponent:"ManageSubscription"})
      this.handleSideBarComponent('ManageSubscription')
    }
    else if (window.location.hash == '#My-Schedule') {
      this.handleSideBarComponent('MySchedule')
    }


    if (localStorage.getItem("membershipExpCheck") == 'true') {

      await this.setState({ renderSideBarComponent: "ManageSubscription" })
      localStorage.removeItem("membershipExpCheck");
    }

    console.log('window.location.hash', window.location.hash)

    if (window.location.hash == '#Manage-Subscription') {
      await this.setState({ renderSideBarComponent: "ManageSubscription" })
      console.log('this', this.state.renderSideBarComponent)
    }



    let Profile_help_text = await getHelpText('profile_completeness')
    this.setState({ Profile_help_text })
    let profile_name_help_text = await getHelpText('profile_name')
    this.setState({ profile_name_help_text })

    let coachpublic_profile_help_text = await getHelpText('view_public_profile_help')
    this.setState({ coachpublic_profile_help_text })

    let incomplete_kyc_help_text = await getHelpText('incomplete_kyc_help_text')
    this.setState({ incomplete_kyc_help_text })



    this.fetchKycDetails()
    this.getProfileDetails()
    this.callAPI(this.state.baseCurrency);
    this.getSettings();
    if (this.userdata.account_type === "coach") {
      this.setState({ check_account_type: "coach" });
      this.coachMode();
      this.handleFunction();
    } else if (this.props.location.state !== undefined) {
      if (this.props.location.state.coachData.account_type === "coach") {
        this.setState({ check_account_type: "consumer" });
        this.consumerMode();
      } else {
        console.log("visitor");
      }
    } else {
      console.log("visitor, localstorage data can not read");
    }


  }



  coachMode() {
    this.profileUpdateStatus();
    this.fetchUserName();
    this.fetchCoachData();
    this.handleFunction();
    this.getPackages();
    this.getBioData();
    this.getAvailability();
    // this.savefirebaseKey();
  }

  fetchKycDetails = () => {
    const coach_uid = this.userdata.uid;
    console.log("coachUid", coach_uid);
    axios.get(`/users/fetch-kyc-status/${coach_uid}`).then((res) => {
      const { flag } = res.data
      this.setState({ coachKYCstatus: flag })
    }).catch((err) => {

    })
  }

  savefirebaseKey = async () => {
    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      // Generate Token 
      const token = await getToken(messaging, { vapidKey: 'BGnNBnQVeR1r2jA2ZrHbYylENr4hGZVSnIc5BEszGO2iGtRCrJHgkGZQjihSZfkOTNo0JvVT3GKWlMMSFmW7YG4' })
      await axios.post("/webinar/insert-firebase-token", { firebase_token: token, uid: JSON.parse(localStorage.getItem('userdata')).uid, token: localStorage.getItem('authToken') })
      // Send this token to server (db)
    }
  }

  getProfileDetails = () => {
    var self = this;
    const params = { id: this.userdata.uid };
    //var params = this.props.location.state.coachData.uid;
    this.setState({ Public_view_uid: params.id })
    console.log('getting profile details of uid ', params)

    axios
      .post(`/users/getCoachProfileDetails`, {
        data: params
      })
      .then(async (res) => {
        if (res.data.success === true) {
          var profileCompleteBar = res.data.coachProfileDetails;
          if (profileCompleteBar) {
            self.setState({ totalprofileupdate: profileCompleteBar.profile_completed_percent.toString() })
          }
        }
        //console.log("found new data for coach settings--------------[][]][][][][][----", this.state.coachSettings)
      })
      .catch((error) => {
        console.error(error);
      });

  }

  consumerMode() {
    const rawdata = this.props.location.state.coachData;
    let Consumer_name = this.props.location.state.Consumer_name
    console.log(rawdata);
    this.setState({
      name: rawdata.name,
      coachEmail: rawdata.email,
      coaching_category: rawdata.coaching_category,
      coach_title: rawdata.coach_title,
      coachUid: rawdata.uid,
      Consumer_uid: this.props.location.state.Consumer_uid,
      Consumer_name: Consumer_name,
      Consumer_email: this.props.location.state.Consumer_email
    });
    this.updateStateData(rawdata);
  }

  updateStateData = (rawdata) => {
    const rawaddress = rawdata.address ? JSON.parse(rawdata.address) : "";
    // const rawskills = rawdata.skills ? JSON.parse(rawdata.skills) : "";

    this.setState({
      totalprofileupdate: rawdata.profile_completed_percent,
      userImage: rawdata.avatar_path,
      coach_uid: rawdata.coach_uid,
      short_bio: rawdata.short_bio,
      phone_no : rawdata.phone_number,
      email_id : rawdata.email,
      SocialMediaInfo: rawdata.social_media !== null ? (JSON.parse(rawdata.social_media)) : ([{ SocialMedia: "", URL: "" }]),
      skills: rawdata.skills ? JSON.parse(rawdata.skills) : "",
      area_of_work : rawdata.area_of_work,
      coach_type : rawdata.coach_type,
      // long_bio: rawdata.long_bio,
      // long_bio:
      //   rawdata.long_bio !== undefined ? rawdata.long_bio : "loading...",
      long_bio_fetch:
        (rawdata.long_bio && rawdata.long_bio !== undefined) ? rawdata.long_bio : rawdata.short_bio,

      videofile: rawdata.profile_video,
      pendingVideoFile: rawdata.pending_video_url,
      processed: rawdata.processed,
      avatar_path: rawdata.avatar_path ? rawdata.avatar_path : avatar,
      country: rawaddress.country,
      location: rawaddress.location,
      city: rawaddress.city,
      coachprofile_id: rawdata.uid,
      tags: rawdata.tags,
      // tag_update_profile: 0,
      // avatar_path_update_profile: 0,
      // short_bio_update_profile: 0,
      // packages_update_profile: 0,

      language: rawdata.language ? JSON.parse(rawdata.language) : "",
      timezone: rawdata.timezone,
      HoursofExp: rawdata.hours_of_experience,
      HourlyRate: rawdata.hourly_rate,
      membership: rawdata.has_membership,
      membership_id: rawdata.individual_gateway_mode === "sandbox" ? rawdata.membership_id : rawdata.membership_id_live,
      subscription_id: rawdata.individual_gateway_mode === "sandbox" ? rawdata.subscriptionId : rawdata.subscriptionId_live,
      city_public: rawdata.city_public,
      wordcount:
        rawdata.short_bio && parseInt(rawdata.short_bio.split(" ").length),
      SocialMediaInfo: rawdata.social_media !== null ? (JSON.parse(rawdata.social_media)) : ([{ SocialMedia: "", URL: "" }]),
      public_profile_toggle: rawdata.isPublic ? rawdata.isPublic : false
    });
    // console.log("AVIJIT SAMANTA STATE",this.state)
  };

  // ____________________________Fetch_data___________________________________

  getSettings() {
    console.log('fetch public package')

    axios
      .get("/users/settings-for-coach", {
        // headers: {
        //   "X-Auth": this.token,
        // },
      })
      .then(async (res) => {
        await this.setState({ coachSettings: JSON.parse(res.data.value.tagsAndTimes[0].content) });
        //console.log("found new data for coach settings--------------[][]][][][][][----", this.state.coachSettings)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  fetchUserName() {
    let self = this;
    let param = {
      url: "/users/username",
      method: "GET",
      headers: {
        "X-Auth": this.token,
      },
    };
    axios(param)
      .then(async (res) => {
        if (res.data.value) {
          const rawdata = res.data.value;
          self.setState({
            name: rawdata.name,
            // coaching_category: rawdata.coaching_category,
            user_title: rawdata.user_title,
            coachingCategory: rawdata.coaching_category
              ? JSON.parse(rawdata.coaching_category)
              : "",
          });
          // this.setState({
          //   user_title_update_profile: 0,
          // });
          // if (this.state.user_title && this.state.user_title.length > 0) {
          //   this.setState({
          //     user_title_update_profile: 20,
          //   });
          // }
          this.setState({
            user_basicone_update_profile: 0,
          });
          if (this.state.user_title != null &&
            this.state.coachingCategory != null &&
            this.state.name.length > 0) {
            this.setState({
              //user_title_update_profile: 20,
              user_basicone_update_profile: 10,
            });
          }
          this.profileUpdateStatus();
        }
      })
      .catch((error) => {
        console.log(error);
        // toast("Error getting User data", { type: "error" });
      });
  }

  fetchCoachData() {
    this.setState({ loader: true })
    const id = this.userdata.uid;

    let param = {
      url: `/users/coachdatajoin/${id}`,
      method: "GET",
      headers: {
        "X-Auth": this.token
      }
    }
    axios(param)
      .then(async (res) => {
        this.setState({ loader: false })
        console.log("-------------------------------------------res-socail-------------", res.data.value)
        if (res.data.value) {

          //console.clear();
          console.log("coach data", res.data.value)

          const rawdata = res.data.value;
          this.setState({ has_membership: rawdata.has_membership })
          this.setState({ membership_id: rawdata.membership_id, coachHasAnySubscriptionHistory: rawdata.coachHasAnySubscriptionHistory })

          // const rawaddress = rawdata.address ? JSON.parse(rawdata.address) : "";
          const rawskills = rawdata.skills ? JSON.parse(rawdata.skills) : "";
          this.setState({ chartValue: rawskills });
          this.setState({ isApproved: rawdata.is_approved })

          if (rawskills.length > 1) {
            await this.setState({
              chartValue: rawskills,
              isLoaded: true,
            });
          } else {
            this.setState({ isLoaded: false });
          }
          this.updateStateData(rawdata);

          if (this.state) {
            // if (this.state.avatar_path !== avatar) {
            //   this.setState({ avatar_path_update_profile: 20 });
            // }
            // if (this.state.short_bio.length > 2) {
            //   this.setState({ short_bio_update_profile: 20 });
            // }

            // if (this.state.short_bio.length > 2) {
            //   this.setState({ packages_update_profile: 20 });
            // }

            // if (this.state.tags.length > 2) {
            //   this.setState({ tag_update_profile: 20 });
            // }
            this.setState({
              user_basic_update_profile: 0,
            });
            console.log("~~~~~~~~~~~~~~~hourlyrate~~~~~~~~~~~~~~~~", this.state.HourlyRate > 0)

            console.log("~~~~~~~~~~~~~~~exp~~~~~~~~~~~~~~~~", this.state.HoursofExp)

            console.log("~~~~~~~~~~~~~~~city~~~~~~~~~~~~~~~~", this.state.location)

            console.log("~~~~~~~~~~~~~~~avatar path~~~~~~~~~~~~~~~~", this.state.avatar_path)

            console.log("~~~~~~~~~~~~~~~shortbio~~~~~~~~~~~~~~~~", this.state.short_bio)

            if (this.state.HourlyRate > 0 && this.state.HoursofExp.length > 0 &&
              this.state.location.length > 0 &&
              this.state.avatar_path != avatar &&
              this.state.short_bio.length > 2) {
              // console.log("```````````````i am here````````````````")
              this.setState({
                user_basic_update_profile: 10,
              });
            }
          }

          this.profileUpdateStatus();
          this.dountChartUpdated();
        }
      })
      .catch((error) => {
        console.log("fetch error");
        console.log(error);
        this.setState({ loader: false })
        // toast("Error getting profile data", { type: "error" });
      });
  }

  getBioData() {
    const id = this.userdata.uid
    axios
      .get(`/users/certificate/${id}`, {
        headers: { "x-auth": this.token },
      })
      .then((res) => {
        console.log("certificate", res);
        if (res.data.msg === "success") {
          const data = res.data.value;
          this.setState({ certificates: data });
          //console.log("~~~~~~~~~~~~~~~certificate~~~~~~~~~~~~~~~~",res.data.value)
          this.setState({
            user_certificate_update_profile: 0,
          });
          if (this.state.certificates.length > 0) {
            this.setState({
              user_certificate_update_profile: 20,
            });
          }
          this.profileUpdateStatus();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getAvailability = () => {
    const id = this.userdata.uid
    axios.get(`/coach/setavailability/${id}`, {
      headers: { 'x-auth': this.props.token }
    })
      .then(res => {
        //console.log("ress set availaibility------",res)
        if (res.data.success && res.data.value.length > 0) {
          this.setState({
            user_availability_update_profile: 20,
          });

        }
        this.profileUpdateStatus();
      })
      .catch(err => {
        console.log(err)
      })
  }

  getPackages() {
    const id = this.userdata.uid;
    axios
      .get(`/users/packages/${id}`, {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          let pack = res.data.value;
          if (pack.length > 0) {
            this.setState({ packages_update_profile: 20 });
            this.profileUpdateStatus();
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  packagefound = () => {
    this.setState({ packages_update_profile: 20 });
    this.profileUpdateStatus();
  };

  packageNotfound = async () => {
    await this.setState({ packages_update_profile: 0 });
    //console.log("PACKAGE NOT FOUND ~~~~~~~~~~~~~~~~~~~~~~~~~~!!!!!!!!!!!!!!!!!!!!!!!!!!!", this.state.packages_update_profile)
    this.profileUpdateStatus();
  };
  // ____________________________Handle_Function_______________________________

  handleChangeMultiSkills = (e) => {
    const index =
      this.state.chartValue.length > 0
        ? this.state.chartValue.indexOf(e.target.id)
        : -1;

    if (e.currentTarget.checked) {
      if (index === -1) {
        if (this.state.chartValue.length < 4) {
          this.setState({
            chartValue: [...this.state.chartValue, e.target.id],
          });
        } else {
          toast("You can add maximum 4 styles", { type: "error" });
        }
      }
    }
    if (!e.currentTarget.checked) {
      this.setState({
        chartValue: [
          ...this.state.chartValue.filter((element) => element !== e.target.id),
        ],
      });
    }
  };

  handleSubComponent = async (cname) => {
    console.log('panel test', cname)
    this.setState({ renderSubComponent: cname });
    if (cname === 'Home') {
      // window.location.reload();
    }
    if (cname === 'Bio') {
      await this.setState({ leftBar: '' });

      console.log('leftBar', this.state.leftBar)
    }

  };

  handleChange = (e) => {
    e.persist();
    if (e.target.name === "short_bio") {
      let limit = 50 - parseInt(e.target.value.split(" ").length);
      let ro = e.target.value.split(" ").length > 50 ? true : false;
      let msg =
        e.target.value.split(" ").length > 50
          ? "Short bio word limit exceeded"
          : "";
      this.setState({ wordcount: limit, alertMsg: msg, readonly: ro });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // this is for long bio text
  handleOnChange = (event, editor) => {
    const data = editor.getData();
    this.setState({
      long_bio: data,
    });
  };

  handlegetProfileVideo = () => {
    this.fetchCoachData();
  };
  handlegetProfileImage = () => {
    this.fetchCoachData();
  };
  handleSubmitProfileVideo = () => {
    this.fetchCoachData();
  };
  handleSetCategory = (value) => {
    this.setState({ coachingCategory: value })
    this.fetchUserName()
  }
  onChangeHandler = (e) => {
    this.setState({
      avatar_path_temp: e.target.files[0],
      loaded: 0,
    });
  };

  handleAddressSelected = (address, country, city) => {
    this.setState({ country: country, city: city, location: address });
  };

  handleLanguageChange = (language) => {
    this.setState({ language: language });
  };

  handleCategoryChange = (data) => {
    this.setState({ coachingCategory: data });
  };

  handleCheckbox = (e) => {
    if (e.target.value == 0) {
      this.setState({ membership: 1 });
    } else if (e.target.value == 1) {
      this.setState({ membership: 0 });
    }
  };

  changeConvertToCurrency = (e) => {
    this.setState({
      convertToCurrency: e.target.value,
    });
    const ps = this.state.rates[this.state.convertToCurrency];
    console.log("currency----", this.state.rates)
    console.log("currency----", this.state.rates[this.state.convertToCurrency])
    console.log("psssss---------", ps)
    this.setState({ findcurrency: ps });
  };

  handleFunction = async () => {

    const accontCreated = new Date(this.userdata.created);
    // console.log("AVIJIT CREATE DATE ", accontCreated)
    // const finalDate = new Date(accontCreated);
    const finalDate = moment(accontCreated).add(90, 'd').format("YYYY-MM-DD 00:00:00");
    // console.log("AVIJIT FINAL DATE ", finalDate)
    // finalDate.setDate(accontCreated.getDate() + 90);
    // var countDownDate = new Date(finalDate);
    // var now = new Date();
    var now = moment().format("YYYY-MM-DD 00:00:00");
    // var timeleft = countDownDate - now;
    var timeleft = new Date(finalDate) - new Date(now);
    var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    //var days = 0
    await this.setState({
      freeTrialDaysleft: days > 0 ? days : -1,
    });
    if (this.state.freeTrialDaysleft === -1 && this.state.membership_id == null) {
      this.setState({ redirect: true })
      return
    }

  };

  profileUpdateStatus() {

    this.totalProfileUpdateStatus()
  }

  totalProfileUpdateStatus(id = '') {
    if (id == '') {
      id = this.userdata.uid;
    }


    if (id != undefined) {
      let formData = { percentData: this.state.totalprofileupdate }

      let param = {
        url: `/users/complete-status/${id}`,
        method: "GET",
        // data: formData,
        headers: {
          "X-Auth": this.token
        }
      }
      axios(param)
        .then((response) => {
          //toast("Coaching style added successfully", { type: "success" });
          console.log("response", response)

          this.setState({ totalprofileupdate: response.data.value[0].profile_completed_percent.toString() })




        })
        .catch((error) => {
          console.log(error);
          //toast("Error in updating", { type: "error" });
        });

    }

  }

  callAPI(base) {
    const api = `https://api.exchangeratesapi.io/latest?base=${base}`;

    fetch(api)
      .then((results) => {
        return results.json();
      })
      .then((data) =>
        console.log('dd')
        //this.setState({
        //rates: data["rates"],
        //currencies: Object.keys(data["rates"]).sort(),
        //})
      );
  }

  dountChartUpdated() {
    var skillName = [];
    var skillvalue = [];
    var skillcolor = [];

    // ["red##25##redSkill2", "yellow##25##yelloSkill3"]

    if (this.state.chartValue.length > 0) {
      this.state.chartValue.map((item) => {
        skillcolor.push(item.split("##")[0]);
        skillvalue.push(parseInt(item.split("##")[1]));
        skillName.push(item.split("##")[2]);
      });
    }

    this.setState({
      labels_data: skillName,
      data_data: skillvalue,
      color_data: skillcolor,
    });
  }

  // ____________________________Handle_Function_submit______________________________

  // handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (this.state.short_bio && this.state.short_bio.split(" ").length > 50) {
  //     toast("Word limit exceeded for short bio", {
  //       type: "error",
  //     });
  //   } else {
  //     const addressData = {
  //       country: this.state.country,
  //       city: this.state.city,
  //       location: this.state.location,
  //     };
  //     const formData = {
  //       address: JSON.stringify(addressData),
  //       short_bio: this.state.short_bio,
  //       coach_uid: this.state.coach_uid,
  //       long_bio: this.state.long_bio,
  //       skills: JSON.stringify(this.state.chartValue),
  //       language: this.state.language,
  //       timezone: this.state.timezone,
  //       HoursofExp: this.state.HoursofExp,
  //       HourlyRate: this.state.HourlyRate,
  //       membership: this.state.membership,
  //     };
  //     let param;
  //     if (this.state.coachprofile_id !== "") {
  //       formData["uid"] = this.state.coachprofile_id;
  //       param = {
  //         url: "/users/coachprofile",
  //         method: "PUT",
  //         data: formData,
  //         headers: {
  //           "X-Auth": this.token,
  //         },
  //       };
  //     } else {
  //       param = {
  //         url: "/users/coachprofile",
  //         method: "POST",
  //         data: formData,
  //         headers: {
  //           "X-Auth": this.token,
  //         },
  //       };
  //     }

  //     axios(param)
  //       .then((response) => {
  //         toast("Personal details updated", {
  //           type: "success",
  //         });

  //         const rawdata = response.data.profilePost;
  //         const rawaddress = rawdata.address ? JSON.parse(rawdata.address) : "";
  //         const rawskills = rawdata.skills ? JSON.parse(rawdata.skills) : "";

  //         if (rawskills.length > 0) {
  //           this.setState({
  //             chartValue: rawskills,
  //             isLoaded: true,
  //           });
  //         } else {
  //           this.setState({
  //             isLoaded: false,
  //           });
  //         }

  //         this.setState({
  //           userImage: rawdata.avatar_path,
  //           coach_uid: rawdata.coach_uid,
  //           short_bio: rawdata.short_bio,

  //           long_bio:
  //             rawdata.long_bio !== undefined
  //               ? rawdata.long_bio
  //               : this.state.responseData.profilePost.long_bio,

  //           videofile: rawdata.profile_video,
  //           country: rawaddress.country,
  //           location: rawaddress.location,
  //           city: rawaddress.city,
  //           coachprofile_id: rawdata.uid,
  //           isUpdate: true,
  //           showLoader: false,

  //           language: rawdata.language,
  //           timezone: rawdata.timezone,
  //           HoursofExp: rawdata.hours_of_experience,
  //           HourlyRate: rawdata.hourly_rate,
  //           membership: rawdata.has_membership,
  //         });
  //         this.setState({
  //           short_bio_update_profile: 0,
  //         });

  //         if (this.state.short_bio.length > 5) {
  //           this.setState({
  //             short_bio_update_profile: 20,
  //           });
  //         }

  //         this.setState({
  //           user_title_update_profile: 0,
  //         });
  //         if (this.state.user_title && this.state.user_title.length > 0) {
  //           this.setState({
  //             user_title_update_profile: 20,
  //           });
  //         }

  //         this.profileUpdateStatus();

  //         toast("Personal details updated", {
  //           type: "success",
  //         });
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         toast("Error in updating details", {
  //           type: "error",
  //         });
  //       });
  //   }
  // };

  handleResourceSubmit = (flag) => {
    this.setState({ showLoader: flag });
  };

  handleSubmitSkill = (e) => {
    e.preventDefault();

    const addressData = {
      country: this.state.country,
      city: this.state.city,
      location: this.state.location,
    };

    const formData = {
      address: JSON.stringify(addressData),
      short_bio: this.state.short_bio,
      coach_uid: this.state.coach_uid,
      long_bio: this.state.long_bio,
      skills: JSON.stringify(this.state.chartValue),
      name: this.state.name,

      language: this.state.language,
      timezone: this.state.timezone,
      HoursofExp: this.state.HoursofExp,
      HourlyRate: this.state.HourlyRate,
      membership: this.state.membership,
    };

    let param;
    if (this.state.coachprofile_id !== "") {
      formData["uid"] = this.state.coachprofile_id;
      param = {
        url: "/users/coachprofile",
        method: "PUT",
        data: formData,
        headers: {
          "X-Auth": this.token,
        },
      };
    } else {
      param = {
        url: "/users/coachprofile",
        method: "POST",
        data: formData,
        headers: { "X-Auth": this.token },
      };
    }

    axios(param)
      .then((response) => {
        message.success("Coaching style added successfully");

        this.fetchCoachData();
        this.dountChartUpdated();
      })
      .catch((error) => {
        console.log(error);
        message.error("Error in updating");
      });
  };

  handleSubmitProfileImage = (e) => {
    this.setState({ showLoader: true });
    e.preventDefault();
    const formData = new FormData();
    formData.append("avatar_path", this.state.avatar_path_temp);

    let param;
    if (this.state.coachprofile_id !== "") {
      formData["uid"] = this.state.coachprofile_id;
      param = {
        url: "/users/profileimage",
        method: "PUT",
        data: formData,
        headers: {
          "X-Auth": this.token, //this.props.location.state.token,
          "Content-type": "multipart/form-data",
        },
      };
    } else {
      param = {
        url: "/users/profileimage",
        method: "POST",
        data: formData,
        headers: {
          "X-Auth": this.token, //this.props.location.state.token,
          "Content-type": "multipart/form-data",
        },
      };
    }

    axios(param)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            avatar_path: response.data.profileImagePost.avatar_path,
            showLoader: false,
            avatar_path_temp: null,
            avatar_path_update_profile: 20,
          });
          message.success("Image updated");
          this.profileUpdateStatus();
          this.fetchCoachData();
        }
      })
      .catch((error) => {
        console.log("photo update error");
        console.log(error);
        message.error("Error in updaing profile image");
        this.setState({
          showLoader: false,
        });
      });
  };

  handleUpdateTags = (formData) => {
    let param;
    if (this.state.coachprofile_id !== "") {
      param = {
        url: "/users/tags",
        method: "PUT",
        data: formData,
        headers: {
          "X-Auth": this.token,
        },
      };
    } else {
      param = {
        url: "/users/coachprofile-edit",
        method: "POST",
        data: formData,
        headers: { "X-Auth": this.token },
      };
    }
    axios(param)
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.msg);
        } else if (!res.data.success) {
          message.error(res.data.err);
        }

        this.setState({ tag_update_profile: 0 });
        if (this.state.tags.length > 2) {
          this.setState({ tag_update_profile: 20 });
        }
        this.fetchCoachData();
        this.profileUpdateStatus();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSubmitUserName = (e) => {
    e.preventDefault();
    const formData = {
      user_title: this.state.user_title,
      name: this.state.name,
      coaching_category: this.state.coachingCategory,
      // coaching_category: this.state.coaching_category_list,
    };

    let param;

    param = {
      url: "/users/username",
      method: "PUT",
      data: formData,
      headers: {
        "X-Auth": this.token,
      },
    };

    axios(param)
      .then((response) => {
        this.fetchUserName();
        message.success("Details updated");
      })
      .catch((error) => {
        console.log("update error");
        console.log(error);
        message.error("Error in updating details");
      });
  };

  handleSubmitPersonal = (e, Data) => {
    console.log("Data-----9", Data)
    e.preventDefault()
    if (this.state.short_bio && this.state.short_bio.split(" ").length > 50) {
      message.error("Word limit exceeded for short bio");
    } else {
      const addressData = {
        country: this.state.country,
        city: this.state.city,
        location: this.state.location,
      };

      const formData = {
        user_title: this.state.user_title,
        name: this.state.name,
        coaching_category: this.state.coachingCategory,
        Uid: this.state.coachprofile_id,

        address: JSON.stringify(addressData),
        short_bio: this.state.short_bio,
        coach_uid: this.state.coach_uid,
        long_bio: this.state.long_bio,
        skills: JSON.stringify(this.state.chartValue),
        language: this.state.language,
        timezone: this.state.timezone,
        HoursofExp: this.state.HoursofExp,
        HourlyRate: this.state.HourlyRate,
        membership: this.state.membership,
        tags: this.state.tags,
        SocialMediaInfo: Data !== undefined ? Data : this.state.SocialMediaInfo
      }

      axios.post('/users/coachprofile-edit', formData, {
        headers: { 'x-auth': this.token }
      })
        .then(res => {
          if (res.data.success) {
            const rawdata = res.data.profilePost;
            this.setState({
              short_bio: rawdata.short_bio,
              SocialMedia: JSON.parse(rawdata.social_media)
            })

            message.success("Successfully updated profile details");

          }
          // this.setState({
          //   short_bio_update_profile: 0,
          // });

          // if (this.state.short_bio.length > 5) {
          //   this.setState({
          //     short_bio_update_profile: 20,
          //   });
          // }

          // this.setState({
          //   user_title_update_profile: 0,
          // });
          // if (this.state.user_title && this.state.user_title.length > 0) {
          //   this.setState({
          //     user_title_update_profile: 20,
          //   });
          // }

          this.setState({
            user_basic_update_profile: 0,
          });
          if (this.state.HourlyRate > 0 && this.state.HoursofExp.length > 0 &&
            this.state.address.length > 0 &&
            this.state.avatar_path != avatar &&
            this.state.short_bio.length > 2) {
            //console.log("```````````````i am here````````````````")
            this.setState({
              user_basic_update_profile: 10,
            });
          }
          this.fetchUserName();
          //this.getBioData();

          this.profileUpdateStatus();
          console.log("---------personal--res------", res)
        })
        .catch((error) => {
          console.log(error);
          message.error("Error in updating details");
        });
    }
  }

  bioHandleSubmitPersonal = (e, Data) => {
    console.log("Data-----9", Data)
    e.preventDefault()
    if (this.state.short_bio && this.state.short_bio.split(" ").length > 50) {
      message.error("Word limit exceeded for short bio");
    } else {
      const addressData = {
        country: this.state.country,
        city: this.state.city,
        location: this.state.location,
      };

      const formData = {
        user_title: this.state.user_title,
        name: this.state.name,
        coaching_category: this.state.coachingCategory,
        Uid: this.state.coachprofile_id,

        address: JSON.stringify(addressData),
        short_bio: this.state.short_bio,
        coach_uid: this.state.coach_uid,
        long_bio: this.state.long_bio,
        skills: JSON.stringify(this.state.chartValue),
        language: this.state.language,
        timezone: this.state.timezone,
        HoursofExp: this.state.HoursofExp,
        HourlyRate: this.state.HourlyRate,
        membership: this.state.membership,
        tags: this.state.tags,
        SocialMediaInfo: Data !== undefined ? Data : this.state.SocialMediaInfo
      }

      axios.post('/users/coachprofile-edit', formData, {
        headers: { 'x-auth': this.token }
      })
        .then(res => {
          if (res.data.success) {
            const rawdata = res.data.profilePost;
            this.setState({
              short_bio: rawdata.short_bio,
              SocialMedia: JSON.parse(rawdata.social_media)
            })

            message.success("Bio Successfully updated");

          }
          this.fetchCoachData();
        })
        .catch((error) => {
          console.log(error);
          message.error("Error in updating Bio");
        });
    }
  }


  publicprofiletoggle = (value) => {
    console.log('value', value);

    let totalupdate = this.state.totalprofileupdate;
    console.log('totalupdate', totalupdate)
    if (totalupdate === null || totalupdate === undefined) return;
    if (totalupdate < 80) {
      message.error("Please complete your profile first");
      return
    }

    if ((value == false && this.state.freeTrialDaysleft < 0 && this.state.membership_id == null && !this.state.coachHasAnySubscriptionHistory) || (value == false && this.state.coachHasAnySubscriptionHistory && this.state.membership_id == null)) {
      console.log('totalupdate condition', totalupdate)
      message.info("Hi your subscription has expired but we would love to have you on board again. Please visit Manage Subscription tab and renew your subscription.", 5)
    }
    else {


      if (totalupdate >= 80) {
        //call api to toggle change public visibility;
        this.togglepublicprofile(value)
      }

      // if (totalupdate >= 80) {
      //   //call api to toggle change public visibility;
      //   this.togglepublicprofile(value)
      // }

      this.setState({ public_profile_toggle: !value })
    }
  }
  togglepublicprofile = (value) => {

    const formData = {
      value: value === false ? 1 : 0
    }



    axios.post('/users/togglepublicprofile', formData, {
      headers: { 'x-auth': this.token }
    })
      .then(res => {
        console.log('togglepublicprofile res', res)
        if (res.data.success) {
          message.success("Successfully updated public profile status");
        }
      })
      .catch((error) => {
        console.log(error);
        message.error("Error in updating public profile status");
      });
  }

  handleSideBarComponent = (cname, isScroll = '') => {
    console.log('lllllllllllllll', cname)
    if (isScroll !== '') {
      this.setState({ scrollToPayment: "scrollTrue" })
    }
    this.setState({ renderSideBarComponent: cname, renderSubComponent: '' });
  };

  render() {
    // console.clear()
    console.log('city.............', this.state.city_public)
    console.log("hash", window.location.hash)
    console.log("manage subscription", this.state.renderSideBarComponent)
    const { redirect } = this.state;



    if (redirect) {

      return <Redirect
        to={{
          pathname: "/price_table",
          state: {
            user_t: this.userdata.uid,
          }
        }}
      />
    }

    const {
      avatar_path,
      name,
      coaching_category,
      city,
      email,
      country,
      short_bio,
      tags,
      timezone,
      user_title,
    } = this.state;




    let sideBarSubComponent

    //  if(this.state.renderSideBarComponent==="UpcomingSessions"){
    //     sideBarSubComponent=(<UpcomingSession
    //       token={this.token}/>)
    // }else

    if (this.state.renderSideBarComponent === "SetAvailability" && this.state.renderSubComponent === '') {
      sideBarSubComponent = (<SetAvailability
        status={this.props.setAvailabilityStatus}
        token={this.token}
        coachUid={this.userdata.uid}
        totalProfileUpdateStatus={this.props.totalProfileUpdateStatus}
      />)

    } else if (this.state.renderSideBarComponent === "JoinSession" && this.state.renderSubComponent === '') {
      sideBarSubComponent = (<JoinSession />)

    } else if (this.state.renderSideBarComponent === "MySchedule" && this.state.renderSubComponent === '') {
      sideBarSubComponent = (
        <MySchedule
          token={this.token}
        />
      )

    } else if (this.state.renderSideBarComponent === "Webinars" && this.state.renderSubComponent === '') {
      sideBarSubComponent = (
        <Webinars
          timezone={this.state.timezone}
          token={this.token}
        />
      )
    } else if (this.state.renderSideBarComponent === "Sessions" && this.state.renderSubComponent === '') {
      sideBarSubComponent = (
        <Sessions
          token={this.token}
        />
      )

    } else if (this.state.renderSideBarComponent === "PaymentsReceived" && this.state.renderSubComponent === '') {
      sideBarSubComponent = (
        <PaymentReceived
          token={this.token}
        />
      )
    }
    // else if (this.state.renderSideBarComponent === "ManagePayments") {
    //   console.log("hello manage payment")
    //   sideBarSubComponent = (<ConsumerPayment
    //     tab=''
    //     token={this.token}
    //     scrollToPayment={this.state.scrollToPayment}
    //   />)
    // }
    // else if (this.state.renderSideBarComponent === "ManageSubscription") {
    //   console.log("hello manage subscription")
    //   sideBarSubComponent = (<ManageSubscription
    //     token={this.token} />)
    // }
    else if (this.state.renderSideBarComponent === "TransactionHistory" && this.state.renderSubComponent === '') {
      sideBarSubComponent = (<CoachTransactionhistory
        token={this.token} />)
    }
    else if (this.state.renderSideBarComponent === "Reviews" && this.state.renderSubComponent === '') {
      sideBarSubComponent = (<ReviewList token={this.token} coachUid={this.userdata.uid} />)
    }
    else if (this.state.renderSideBarComponent === "Packages" && this.state.renderSubComponent === '') {
      sideBarSubComponent = (<Packages
        token={this.token}
        coachUid={this.userdata.uid}
        uid={this.userdata.uid}
        coachImage={this.state.avatar_path}
        coachName={this.state.name}
        coachData={this.state}
        ConsumerD={this.props.hasOwnProperty('location') ? this.props.location.state.ConsumerD : ''}
        account_type={this.userdata.account_type}
        findcurrency={this.state.findcurrency}
        convertToCurrency={this.state.convertToCurrency}
        baseCurrency={this.state.baseCurrency}
        sessionLength={this.state.coachSettings.time}
        userdata={this.userdata}
        check_account_type={this.state.check_account_type}
        packagefound={this.packagefound}
        packageNotfound={this.packageNotfound}
      />)
    }



    var subComponentContent;
    if (this.state.renderSubComponent === "Home") {
      console.log('enter home section', this.state.renderSubComponent)
      subComponentContent = (
        <Home
          token={this.token}
          account_type={this.userdata.account_type}
          tags={tags === "" || tags === null ? this.state.coachSettings.tag : tags}
          handleUpdateTags={this.handleUpdateTags}
          packagefound={this.packagefound}
          packageNotfound={this.packageNotfound}
          setAvailabilityStatus={this.getAvailability}
          totalProfileUpdateStatus={() => { this.totalProfileUpdateStatus(this.state.coachUid) }}
          convertToCurrency={this.state.convertToCurrency}
          coaching_category={coaching_category}
          findcurrency={this.state.findcurrency}
          baseCurrency={this.state.baseCurrency}
          check_account_type={this.state.check_account_type}
          userdata={this.userdata}
          uid={this.userdata.uid}
          videofile={this.state.videofile}
          pendingVideoFile={this.state.pendingVideoFile}
          processed={this.state.processed}
          handlegetProfileVideo={this.handlegetProfileVideo}
          coachName={this.state.name}
          coachEmail={this.state.coachEmail}
          coachUid={this.state.coachUid}
          Consumer_uid={this.state.Consumer_uid}
          Consumer_name={this.state.Consumer_name}
          Consumer_email={this.state.Consumer_email}
          coachImage={this.state.avatar_path}
          coachData={this.state}
          ConsumerD={this.props.hasOwnProperty('location') ? this.props.location.state.ConsumerD : ''}

          publicView={false}
          sessionLength={this.state.coachSettings.time}
          // for tour section
          handleSubComponent={this.handleSubComponent}
          TourResourceModal={this.TourResourceModal}
          handaleLoader={this.handaleLoader}
        //


        />
      );
    } else if (this.state.renderSubComponent === "Bio") {
      subComponentContent = (
        <Bio
          logoImg={this.state.logoview}
          pdata={this.state}
          token={this.token}
          handleChange={this.handleChange}
          handleSubmitPersonal={this.handleSubmitPersonal}
          handleOnChange={this.handleOnChange}
          check_account_type={this.state.check_account_type}
          userdata={this.userdata}
          bioHandleSubmitPersonal={this.bioHandleSubmitPersonal}
          uid={this.userdata.uid}
          siteComponent={this.state.leftBar}
          handleSideBarComponent={this.handleSideBarComponent}
          // for tour section
          handleSubComponent={this.handleSubComponent}
        //
        />
      );
    } else if (this.state.renderSubComponent === "Resources") {
      subComponentContent = (
        <Resources
          token={this.token}
          handleSubmit={this.handleResourceSubmit}
          data={this.state}
          handlegetProfileVideo={this.handlegetProfileVideo}
          handlegetProfileImage={this.handlegetProfileImage}
          check_account_type={this.state.check_account_type}
          userdata={this.userdata}
          // for tour section
          showModal={this.state.TourResourceModal}
        //
        />
      );
    } else if (this.state.renderSubComponent === "Contacts") {
      subComponentContent = (
        <Contact
          email={email}
          token={this.token}
          check_account_type={this.state.check_account_type}
        />
      );
    } else if (this.state.renderSubComponent === "Testimonial") {
      subComponentContent = (
        <Testimonial
          token={this.token}
          chartValue={this.state.chartValue}
          handleChangeMultiSkills={this.handleChangeMultiSkills}
          labels_data={this.state.labels_data}
          data_data={this.state.data_data}
          color_data={this.state.color_data}
          handleSubmitSkill={this.handleSubmitSkill}
          check_account_type={this.state.check_account_type}
          // for tour section
          handleSubComponent={this.handleSubComponent}
          //
          coach_uid={this.state.coach_uid}
        />
      );
    } else if (this.state.renderSubComponent === "Blog") {
      subComponentContent = (
        <Blog check_account_type={this.state.check_account_type}
          // for tour section
          handleSubComponent={this.handleSubComponent}
        //
        />
      );
    }

    return (
      <React.Fragment>
        {this.state.renderSubComponent != 'Home' && (
          <TourSite
            handleSubComponent={this.handleSubComponent}
            TourResourceModal={this.TourResourceModal}
            handaleLoader={this.handaleLoader}
          />
        )}

        <LoadingOverlay active={this.state.loader}
          //spinner={<BounceLoader />}
          spinner={<CustomImageLoader image={LGspinner} animationType={'pulse'} />}
          styles={{
            wrapper: {
              overflow: this.state.loader ? 'hidden' : ''
            }
          }}
        //spinner
        >
          {/* { this.token  } */}
          {/* <HeaderTop /> */}

          {localStorage.getItem("authToken") == null ? (
            <HeaderTop />
          ) : (
            <HeaderLogout
              currencies={this.state.currencies}
              convertToCurrency={this.state.convertToCurrency}
              changeConvertToCurrency={this.changeConvertToCurrency}
            />
          )}

          <Header />
          <CommunicationUsers avatar_path={this.state.avatar_path}></CommunicationUsers>






          <section className="coachsProfileWrap">
            <div className="container">
              {/* <Alert showIcon={false} message="Please complete your KYC" banner /> */}
              {/* {this.state.coachKYCstatus ? ("") : (
                <div className="incomplete-kyc-alert mb-4">
                <span>
                 Your customer details is incomplete.{" "}
                <Link
                    to={{
                      pathname: "/profile-coach",
                    }}
                    onClick={() => this.handleSideBarComponent('ManagePayments')}
                  >
                    Click here{" "}                       
                  </Link>
                  to complete your customer details.
                  <Link
                    to={{
                      pathname: "/profile-coach",
                    }}
                    onClick={() => this.handleSideBarComponent('ManagePayments', 'true')}
                  >
                  {this.state.incomplete_kyc_help_text}
                  </Link>
                </span>                
              </div>
              )} */}

              <div className="row align-items-start">


                <Col sm={5} md={5} lg={3} className="pro_img_block">
                  <div className="f_sidebar">
                    <div className="coachImg">
                      {/* {console.log('ttttttttttttttttt',this.state.membership_id)} */}
                      {/* {this.state.check_account_type === "coach" && this.state.membership_id===null && !this.state.coachHasAnySubscriptionHistory ? (
                      <div className="freeTrialDays">
                        <Link
                            to={{
                              pathname: "/price_table",
                              state: {
                                user_t: this.userdata.uid,
                              },
                            }}
                          >
                      {this.state.freeTrialDaysleft >0?     
                        <button
                          type="button"
                          className={`btn ${this.state.freeTrialDaysleft < 30
                            ? "btn-danger"
                            : "btn-danger"
                            } btn-circle btn-xl`}
                        >
                          {this.state.freeTrialDaysleft < 0 ? (<span>Trial days expired</span>) : (<span>{this.state.freeTrialDaysleft} {" "}days of <br /> free trial remaining{" "}</span>) }
                          <span>
                        
                          
                          
                    </span>
                        </button>
                        :""
                    }
                        </Link>
                      </div>
                    ) : (
                        ""
                      )} */}
                      <img src={avatar_path} alt="LG-Img" className="img-fluid" />
                      {/* {this.state.check_account_type === "coach" ? (
                      this.state.renderSubComponent === "Home" ? (
                        <UpdateImage
                          avatar={avatar}
                          userImage2={avatar_path}
                          onChangeHandler={this.onChangeHandler}
                          handleSubmitProfileImage={this.handleSubmitProfileImage}
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}{" "} */}
                    </div>
                    <div className="fs_cont">
                      {this.state.check_account_type === "coach" ? (

                        <div>
                          <div>
                            <small>Profile Complete
                              <Tooltip title={this.state.Profile_help_text} placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>
                            </small>

                            <ProgressBar
                              variant={"custom ant-badge-status-purple"}
                              now={this.state.totalprofileupdate}
                              //label={`${this.state.totalprofileupdate}%`}
                              label={`${this.state.totalprofileupdate}%`}
                              // data-toggle="tooltip"
                              // data-placement="top"
                              title={`Your Profile is ${this.state.totalprofileupdate}% completed`}
                            />
                          </div>
                          {this.state.totalprofileupdate > 79 ? "" :
                            <p className="tour-approval">
                              Your profile is {this.state.totalprofileupdate}% completed.
                            </p>
                          }
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.check_account_type === "coach" ? (<>
                        {this.state.isApproved == 1 && this.state.totalprofileupdate > 79 ? (

                          <div>
                            <Row style={{ color: 'black', marginTop: '10px' }} className="justify-content-between ml-0 mr-0 tour-approval">
                              <small style={{ color: "black" }}>
                                Make your profile public
                              </small>

                              <ToggleButton
                                value={this.state.public_profile_toggle || false}
                                onToggle={(value) => this.publicprofiletoggle(value)}
                              // onToggle={(value) => {
                              //   this.setState({
                              //     public_profile_toggle: !value,
                              //   })
                              // }} 
                              />

                            </Row>
                            <Row style={{ color: 'black', marginTop: '10px' }} className="justify-content-between ml-0 mr-0">
                              {/* {this.state.public_profile_toggle?
                              <Tooltip title={this.state.coachpublic_profile_help_text}>
                              <Link className="defaultBtn mt-2"
                              to={
                                  
                                  `/coach-profile/${name}/${this.state.Public_view_uid}`
                              }
                            
                          >
                              View public profile
                              </Link>
                              </Tooltip>
                              
                              :''
                              
                              } */}
                            </Row>
                          </div>

                          // Different labels example...
                        ) : (
                          <div>
                            {/* {this.state.totalprofileupdate > 79 ?
                              <p className="tour-approval">Pending Approval</p>
                              : ""} */}
                          </div>

                        )}
                        {/* <Tooltip title={this.state.coachpublic_profile_help_text} placement="top">
                          <Link className="defaultBtn mt-2" target="_blank"
                            style={{ padding: "7px 15px", fontSize: 14 }}
                            to={

                              `/coach-profile/${name}/${this.state.Public_view_uid}`
                            }

                          >
                            PUBLIC PROFILE
                          </Link>
                        </Tooltip> */}
                      </>) : (<></>)}


                      <div className="userDashboardLeftPart coachInfoWrap ">
                        {console.log('sidebar', this.state.renderSideBarComponent)}
                        <CoachSideBar
                          renderSideBarComponent={this.state.renderSideBarComponent}
                          handleSideBarComponent={this.handleSideBarComponent}
                        />
                      </div>
                    </div>
                  </div>
                </Col>



                <Col sm={7} md={7} lg={9}>

                  {/* {this.state.check_account_type === "coach" &&
                  this.state.freeTrialDaysleft < 0 && this.state.membership_id===null && !this.state.coachHasAnySubscriptionHistory ? (
                    <div className="freetrial-over mb-4">
                      <span>
                        Your 90 days free trial is over and account has been
                      deactivated.{" "}
                      </span>
                      <br></br>
                      <span>
                        Please
                      <Link
                          to={{
                            pathname: "/price_table",
                            state: {
                              user_t: this.userdata.uid,
                            },
                          }}
                        >
                          {" "}
                        pay here{" "}
                        </Link>
                      to activate your account
                    </span>
                    </div>
                  ) : (
                    <div></div>
                  )} */}

                  <div className="coachInfoWrap">
                    <div className="coachInfo">
                      <div className="cDetails">
                        <h3>
                          {name} {""}
                          <Tooltip title={this.state.profile_name_help_text} placement="right"> <i className="fa fa-info-circle" aria-hidden="true"></i></Tooltip>

                          {/* {this.state.check_account_type === "coach" ? (
                          this.state.renderSubComponent === "Home" ? (
                            <UpdateUserName
                              name={this.state.name}
                              handleChange={this.handleChange}
                              handleSubmitUserName={this.handleSubmitUserName}
                              handleCategoryChange={this.handleCategoryChange}
                              pdata={this.state}
                            />
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )} */}
                        </h3>
                        <h4> {user_title}</h4>
                        <h5>
                          {this.state.coachingCategory &&
                            this.state.coachingCategory.map((category,index) => {
                              return (
                                <span key={index} className="badge badge-light even-larger-badge">
                                  {" "}
                                  {category.value}
                                </span>
                              );
                            })}

                          {this.state.check_account_type === "coach" ?
                            <UpdateCategory
                              pdata={this.state}
                              token={this.token}
                              handleSetCategory={this.handleSetCategory}
                              handleSubmitPersonal={this.handleSubmitPersonal}
                              handleCategoryChange={this.handleCategoryChange}
                            />
                            : ""}
                        </h5>
                      </div>
                      <div className="contactDetails">
                        {/* <p style={{ display: "none" }}>
                        <img width="20px" alt="LG-Img" src={mailSvg} />
                        {email}
                      </p> */}
                        {/* {this.state.membership && (
                        <p>
                        <img
                          width="25px"
                          alt="LG-Img"
                          src={quality}
                        />
                        Membership of Professional<br/>Association
                      </p>
                      )} */}
                        {this.state.membership && this.state.membership === "1" ? (
                          <p>
                            <img
                              width="25px"
                              alt="LG-Img"
                              src={quality}
                            />
                            Membership of Professional<br />Association
                          </p>
                        ) : (<p></p>)}

                        {/* {city && this.state.city_public == "1" ? (
                        <p>
                          <img
                            width="20px"
                            alt="LG-Img"
                            src={profileAddressSvg}
                          />
                          {city}
                        </p>
                     ): (<p></p>)} */}
                        {city && (
                          <p>
                            <img
                              width="20px"
                              alt="LG-Img"
                              src={profileAddressSvg}
                            />
                            {city}
                          </p>
                        )}
                        {country && (
                          <p>
                            <img width="20px" alt="LG-Img" src={flagSvg} />{" "}
                            {country}
                          </p>
                        )}
                        {timezone && (
                          <p>
                            <img width="20px" alt="LG-Img" src={clockSvg} />{" "}
                            {timezone}
                          </p>
                        )}
                        {this.state.phone_no && (
                          <p>
                            <i style={{color:"#00E1BA","margin-right":"1rem"}} className="fa fa-phone" aria-hidden="true"></i>
                            {"+"+this.state.phone_no}
                          </p>
                        )}
                        {this.state.email_id && (
                          <p>
                            <i style={{color:"#00E1BA","margin-right":"1rem"}} className="fa fa-envelope-o" aria-hidden="true"></i>
                            {this.state.email_id}
                          </p>
                        )}
                        {this.state.coach_type && (
                          <p>
                           <i style={{color:"#00E1BA","margin-right":"1rem"}} className="fa fa-file" aria-hidden="true"></i>
                            {(this.state.coach_type == 2) ? "Available For : Sessions (1:1)" : (this.state.coach_type == 3) ? "Available For : Webinars (1:Many) and Sessions (1:1)" : "Available For : Webinars (1:Many)"}
                          </p>
                        )}
                        {this.state.area_of_work && (
                          <p>
                            <i style={{color:"#00E1BA","margin-right":"1rem"}} className="fa fa-briefcase" aria-hidden="true"></i>
                            
                              {(this.state.area_of_work == 2) ? 
                              "Registered For : Coach Master (Individual Users) and Corporate Coach Master" : 
                              (this.state.area_of_work == 1) ? "Registered For : Corporate Coach Master" :
                               "Registered For : Coach Master (Individual Users)"}
                          </p>
                        )}
                      </div>
                      <p>{short_bio && short_bio}</p>
                    </div>

                    <ul className="coach_public_tabs justify-content-center">
                      <li
                        className={`${this.state.renderSubComponent === "Home"
                          ? "tour active"
                          : "tour "
                          }`}
                      >
                        <Link
                          to={
                            this.state.check_account_type === "coach"
                              ? "/profile-coach"
                              : "/profile-coach-view"
                          }
                          onClick={() => this.handleSubComponent("Home")}
                        >
                          <i className="fa fa-home" aria-hidden="true"></i> HOME
                        </Link>
                      </li>

                      <li
                        className={`${this.state.renderSubComponent === "Bio" ? "tour active" : "tour "
                          }`}
                      >
                        <Link
                          className="tour-bio"
                          to={
                            this.state.check_account_type === "coach"
                              ? "/profile-coach"
                              : "/profile-coach-view"
                          }
                          onClick={() => this.handleSubComponent("Bio")}
                        >
                          <i className="fa fa-info" aria-hidden="true"></i> BIO
                        </Link>
                      </li>

                      {/* <li className={`${this.state.renderSubComponent === "Resources" ? "tour active" : "tour "}`}>
                        <Link className="tour-resource" to={this.state.check_account_type === "coach" ? "/profile-coach" : "/profile-coach-view"} onClick={() => this.handleSubComponent("Resources")}>
                          <i className="fa fa-camera" aria-hidden="true"></i> RESOURCES
                        </Link>
                      </li> */}

                      {/* <li className={`${this.state.renderSubComponent === "Contacts" ? "active" : " "}`} style={{ cursor: "not-allowed",}}>
                      <Link to={ this.state.check_account_type === "coach" ? "/profile-coach" : "/profile-coach-view" }
                        onClick={(e) => {
                          try {
                            // Prevents Unnecessary loading of few dependent components
                            e.preventDefault()
                          } catch (error) {
                            console.log(error);  
                          }
                          // this.handleSubComponent("Contacts")} 
                        }}
                        style={{
                          cursor: "not-allowed",
                          pointerEvents: "none"
                        }}
                      >
                        <i className="fa fa-globe" aria-hidden="true"></i>
                        CONTACT
                      </Link>
                    </li> */}

                      {/* <li className={`${this.state.renderSubComponent === "Testimonial" ? "tour active" : "tour "}`}  >
                        <Link className="tour-testimonials" to={this.state.check_account_type === "coach" ? "/profile-coach" : "/profile-coach-view"} onClick={() => this.handleSubComponent("Testimonial")} >
                          <i className="fa fa-comments-o" aria-hidden="true"></i> TESTIMONIALS
                        </Link>
                      </li>
                      <li className={`${this.state.renderSubComponent === "Blog" ? "tour active" : "tour "}`} >
                        <Link className="tour-blog" to={this.state.check_account_type === "coach" ? "/profile-coach" : "/profile-coach-view"} onClick={() => this.handleSubComponent("Blog")} >
                          <i className="fa fa-th-large" aria-hidden="true"></i> BLOG
                        </Link>
                      </li> */}
                    </ul>

                  </div>
                  {/* ) : ( */}
                  {/* <Link to="profile-consumer">
                          <button className="btn btn-sm btn-info">Back</button>
                        </Link> */}
                  {/* )} */}

                  {this.state.renderSideBarComponent !== "" && this.state.renderSubComponent === '' ?
                    (<div className="">{sideBarSubComponent}</div>) : subComponentContent

                  }
                </Col>
              </div>
            </div>
          </section>









          <FooterLink />
        </LoadingOverlay>
      </React.Fragment>
    );
  }
}

export default Coach;
