import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  user,
  Dashboard,
  Profile,
  Video,
  Webiner,
  Letter,
  Return,
  Logout,
} from "../../assets/images";
import { SSO_LOGOUT } from "../../utils/Sso";
import axios from "../../config/axios";
import message from "../../utils/mesaages";
import './Sidebar.css'
import {GBTW_URL} from "../../config/custom";
import moment from 'moment';

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.location = window.location.pathname
    this.state = {
      userdata: {},
    };
  }

  componentDidMount = () => {
    // let userdata = localStorage.getItem("userdata");
    let token = localStorage.getItem("authToken");
    let url = "/webinar/get_user_profile";
    if (token == undefined) {
      token = localStorage.getItem("corporate_token") || localStorage.getItem("corporate_user_token");
      url = "/api/corprate_user_profile";
      // this.setState({ userdata: JSON.parse(userdata) });
    }
    this.getProfile(url,token)

    this.updateActiveClass(`${this.location.split("/")[this.location.split("/").length - 1]}`)
  };

  getProfile = async (url,token) => {
    this.setState({ loader: true });
    axios.get(url, { headers: { "X-Auth": token } }).then((res) => {
      if (res.data.success) {
        this.setState({ loader: false });
        this.setState({ userdata: res.data.data });
      }
    }).catch((err) => {
      console.log("err", err);
      this.setState({ loader: false });
      message.error("Something went wrong");
    });
  };

  logOutUser = async () => {
    const hideMessage = message.loading("Please wait while logging you out", 0);

    const token = localStorage.getItem("authToken");
    const usertoken = localStorage.getItem("corporate_user_token")

    if (usertoken) {
      axios.get(`/api/corporate_logout/${usertoken}`).then(async (response) => {
        hideMessage();
        if (response.data.success) {
          localStorage.clear()
          message.success(response.data.msg)
          this.setState({ loader: false });
          window.location = "/"
        }
      }).catch((error) => {
        hideMessage();
        message.error(error.response.data.msg);
        this.setState({ loader: false });
      });
    } else {
      await SSO_LOGOUT();
      localStorage.clear();
      if (token != undefined) {
        let param = {
          url: "/users/logout",
          method: "POST",
          data: { token: token },
          headers: { "X-Auth": token },
        };
        await axios(param);
      }
      hideMessage();
      window.location = "/login#logout";
    }

  };

  getUserType = (txt) => {
    let res = txt.substring(0, 1).toUpperCase();
    res += txt.substring(1);
    return res;
  };

  updateActiveClass = (id) => {
    if (document.getElementById(id)) {
      let elements = document.querySelectorAll(".nav-item")
      elements.forEach((element) => {
        element.classList.remove('active');
      });
      document.getElementById(id).classList.add('active');
    }
  }

  render() {
    let { userdata } = this.state;
    return (
      <>
        <aside className="cm_sidebar">
          <div className="sidebar_top text-center mb-4 rounded">
            <div className="sidebar_top_content">
              <img width={100} height={100} src={userdata.avataar || userdata.company_logo || user} className="img-fluid rounded-circle" alt={userdata && userdata.name ? userdata.name : userdata.firstname + " " + userdata.lastname} />
              <h4 className="cm_username">
                {userdata && userdata.name ? userdata.name : userdata.firstname + " " + userdata.lastname}
              </h4>
              <div className="cm_usertype">
                {userdata.company_name && userdata.company_name}
                { (localStorage.getItem("ML_TOKEN") || userdata.account_type == "coach" ) && userdata.account_type  && this.getUserType(userdata.account_type) }
              </div>
            </div>
            { 
              localStorage.getItem("ML_TOKEN") ? "" 
              : 
              ( 
                moment(userdata.trial_end).isAfter(moment(), 'day')) ?
                        <span style={{"font-size":".9rem"}}>Trial Days Remaining: {moment(userdata.trial_end).diff(moment(), 'days')}</span>
                        : <span>{userdata.plan_type} Subscription</span>
            }
            {/* <svg width="185" height="260" viewBox="0 0 185 260" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.63" fillRule="evenodd" clipRule="evenodd" d="M16.7586 336.882C16.7586 336.882 52.6484 -13.3494 362.474 1.89693C362.474 1.89693 341.965 338.875 16.7586 336.882Z" stroke="#99F3E3" strokeWidth="30" />
            </svg> */}
            
          </div>
          <nav className="cm_nav">
            <ul className="navbar-nav">
              { (localStorage.getItem("sessions") == undefined || localStorage.getItem("sessions") == "N" ) 
              ?
              <>
              <li className="nav-item" id="webinars" onClick={() => this.updateActiveClass("webinars")}>
                <Link to="/webinars">
                  <img width={20} height={20} src={Dashboard} alt="" /> Dashboard
                </Link>
              </li>
              <li className="nav-item" id="profile" onClick={() => this.updateActiveClass("profile")}>
                <Link to="/webinars/profile">
                  <img width={20} height={20} src={Profile} alt="" /> Profile
                </Link>
              </li>
              { userdata.account_type != "jobseeker" &&
              <li className="nav-item" id="corporate_profile" onClick={() => this.updateActiveClass("change-password")}>
                    <Link to="/change-password">
                        <img width={20} height={20} src={Profile} alt="" /> Change Password
                    </Link>
                </li> 
              } 

                <li className="nav-item" id="support_form" onClick={() => this.updateActiveClass("support_form")}>
                    <Link to="/webinars/support">
                        <img width={20} height={20} src={Profile} alt="" /> Support
                    </Link>
                </li> 




              {/* {
                localStorage.getItem("authToken") && (
                  <li className="nav-item" id="sessions" onClick={() => this.updateActiveClass("sessions")}>
                    <Link to="/webinars/sessions">
                      <img width={20} height={20} src={Video} alt="" />
                      Coaching Sessions
                    </Link>
                  </li>
                )
              } */}
              <li className="nav-item" id="webinars-schedule" onClick={() => this.updateActiveClass("webinars-schedule")}>
                <Link to="/webinars/webinars-schedule">
                  <img width={20} height={20} src={Webiner} alt="" /> My Webinars
                </Link>
              </li>
              {/* <li className="nav-item" id="webinars-library" onClick={() => this.updateActiveClass("webinars-library")}>
                <Link to="/webinars/webinars-library">
                  <img width={20} height={20} src={Webiner} alt="" /> Webinars Library
                </Link>
              </li> */}
              {/* <li className="nav-item" id="articles" onClick={() => this.updateActiveClass("articles")}>
                <Link to="/webinars/articles">
                  <img width={20} height={20} src={Letter} alt="" /> Articles
                </Link>
              </li> */}
              <li className="nav-item" id="recordings" onClick={() => this.updateActiveClass("recordings")}>
                <Link to="/webinars/recordings">
                  <img width={20} height={20} src={Profile} alt="" /> Pre-Recorded Webinars
                </Link>
              </li>
              <li className="nav-item" id="pay_now" onClick={() => this.updateActiveClass("pay_now")}>
                <Link to="/webinars/pay-now">
                  <img width={20} height={20} src={Profile} alt="" /> Upgrade Plan
                </Link>
              </li>

              { userdata.account_type == "jobseeker" && !localStorage.getItem("ML_TOKEN") &&
             <>
             <li className="nav-item" id="webinars_payment-history" onClick={() => this.updateActiveClass("webinars_payment-history")}>
              <Link to="/webinars/payment-history">
                  <img width={20} height={20} src={Profile} alt="" /> Payment History
              </Link>
              </li> 

             <li className="nav-item" id="webinars_change-password" onClick={() => this.updateActiveClass("webinars_change-password")}>
                    <Link to="/webinars/change-password">
                        <img width={20} height={20} src={Profile} alt="" /> Change Password
                    </Link>
                </li> 

              
              </>
              } 

              {
                localStorage.getItem("ML_TOKEN") && (
                  <li className="nav-item">
                    <a href={GBTW_URL+"#/jobseeker-dash"} target="_blank" rel="noopener noreferrer" >
                      <img width={20} height={20} src={Return} alt="" /> Return to GBTW
                    </a>
                  </li>
                )
              }
              <li className="nav-item">
                <a onClick={this.logOutUser} className="logout_link" href="javascript:void(0)" >
                  <img width={20} height={20} src={Logout} alt="" /> Logout
                </a>
              </li>
              </>
              : 
              <>
                <li className="nav-item" id="recordings" onClick={() => this.updateActiveClass("recordings")}>
                  <Link to="/webinars/recordings">
                    <img width={20} height={20} src={Profile} alt="" /> Pre-Recorded Webinars
                  </Link>
                </li>
                <li className="nav-item" id="careermaster-videos" onClick={() => this.updateActiveClass("careermaster-videos")}>
                  <Link to="/webinars/careermaster-videos">
                    <img width={20} height={20} src={Profile} alt="" /> Careermaster Videos
                  </Link>
                </li>
                {
                  localStorage.getItem("ML_TOKEN") && (
                    <li className="nav-item">
                      <a href={GBTW_URL+"#/jobseeker-dash"} target="_blank" rel="noopener noreferrer" >
                        <img width={20} height={20} src={Return} alt="" /> Return to GBTW
                      </a>
                    </li>
                  )
                }
                <li className="nav-item">
                <a onClick={this.logOutUser} className="logout_link" href="javascript:void(0)" >
                  <img width={20} height={20} src={Logout} alt="" /> Logout
                </a>
              </li>
            </>
        }
            </ul>
          </nav>
        </aside>
      </>
    );
  }
}
