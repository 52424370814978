import React, { Component, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Container, Row, Col, Form } from 'react-bootstrap';

import { Editor } from "@tinymce/tinymce-react";
import './CommunicationUsers.css';
import { Smile } from 'react-feather';
import { avatarlifeguru,avatar } from "../../assets/img";
import {convertToTimezone,getCochTimezone} from '../../utils/timeZoneConvert';

import { chatimagesvg } from "../../assets/img";
import { chatemojisvg } from "../../assets/img";
// import 'emoji-mart/css/emoji-mart.css'
// import { Picker } from 'emoji-mart'
// import Picker from 'emoji-picker-react';


// import  time_calendar  from "../../assets/images/time_calendar.svg";

import { calendersvg } from '../../assets/images'
import ProgressBar from 'react-bootstrap/ProgressBar'



const axios = require('axios');
const Chatboxes = (props) => {
    const [showmodal, setshowmodal] = useState(false)
    const [emailvalidation, setemailvalidation] = useState(false)
    const [chatboxusers, setchatboxusers] = useState([]);
    const [multiads, setmultiads] = useState([]);
    const messagesEnd = React.useRef();

    useEffect(() => {
        
        if (props.users.length > 0) {
            addmoremodal(props.users)
        }
if(props.ischatbyapi === true){
    setTimeout(() => {
        if (messagesEnd.current != undefined) {
            const scrollHeight = messagesEnd.current.scrollHeight;
            const height = messagesEnd.current.clientHeight;
            const maxScrollTop = scrollHeight - height;
            let nextheight = maxScrollTop > 0 ? 2 : 0;
            messagesEnd.current.scrollTop = nextheight;
        }
    }, 10);
}else{
    setTimeout(() => {
        if (messagesEnd.current != undefined) {
            const scrollHeight = messagesEnd.current.scrollHeight;
            const height = messagesEnd.current.clientHeight;
            const maxScrollTop = scrollHeight - height;
            messagesEnd.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
        }
    }, 10);
}
       


    }, [props.users])

    const removemodal = (user) => {
        props.removechatbox(user)
    }



    let addmoremodal = (user) => {
        let uniq = [...new Set(user)];
        var multiads = AdsAssembly(uniq);
        setmultiads(multiads)
    }

    let AdsAssembly = (chatboxusers) => {
        var jsxArray = [];
        chatboxusers.forEach(function (adObject, index) {
            jsxArray.push(chatboxTemplate(adObject));
        })
        return jsxArray;
    }


    let getlocaldatefromuniversal = function date(dt) {
        let d = new Date(dt)
        var ds = new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
        var offset = d.getTimezoneOffset() / 60;
        var hours = d.getHours();
        ds.setHours(hours - offset);
        let y = ds.getFullYear();
        let m = ds.getMonth() + 1;
        if (m < 10) {
            m = '0' + m
        }
        let da = ds.getDate();
        if (da < 10) {
            da = '0' + da
        }
        let h = ds.getHours();
        if (h < 10) {
            h = '0' + h
        }
        let mi = ds.getMinutes();
        if (mi < 10) {
            mi = '0' + mi
        }
        let s = ds.getSeconds();
        if (s < 10) {
            s = '0' + s
        }
        return `${y}-${m}-${da} ${h}:${mi}:${s}`
    }


    let onsc = (e, u) => {
             if (e.current !== undefined) {
            if(e.current.scrollTop !== null){
                if (e.current.scrollTop === 0) {
                    props.fetchmorechats(u);
                }
            }
           
        }
    }

    const scrollToBottom = () => {
        messagesEnd.scrollIntoView({ behavior: "smooth" });
    }

    const keypressed = (e, user) => {
        if (e.key === 'Enter') {
            alert('Enter pressed')
        }
    }

    const onEmojiClick = (event, emojiObject) => {
        // setChosenEmoji(emojiObject);
    };


    const addEmoji = (event, emojiObject, user) => {
        props.setmessage(emojiObject.emoji, user)
    };


    function chatboxTemplate(user) {
        let currentmail = user.uid;
        let coachTimezone ='' 
        let userdata = JSON.parse(localStorage.getItem('userdata'))

        return (
            <div id={user.id} className="chatbox" key={user.id}>
                <div className="top_bar">
                    <Row className="m-0">
                        <Col xs="10" md="10" sm="10" className="d-flex align-items-center p-0">
                            <div className="chat_parrent_img">
                                <div>
                                    {user.profile_avatar === null ?
                                        <img src={avatar} className="img-fluid" />
                                        :
                                        // <img src={u.avatar_path} alt="profile image" />
                                        <img src={user.profile_avatar} alt="profile image" className="img-fluid" />



                                    }
                                </div>

                                {/* <img src='https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg' className="img-fluid rounded" /> */}
                            </div>
                            <span className="ml-2">{user.name}</span>
                        </Col>
                        <Col xs="2" md="2" sm="2" className="p-0 d-flex align-items-center justify-content-center">
                            <span onClick={() => removemodal(user)} className="drop_icon"><i className="fa fa-angle-double-up"></i></span>
                        </Col>
                    </Row>
                </div>


                <div id='massagediv' style={{}} onScroll={(e) => onsc(messagesEnd, user)} ref={messagesEnd}  >
                    {user.messages.map((el, index) => {
                         let coachTimezone = 'Asia/Calcutta (GMT +05:30)'
                         let userTimezone = localStorage.getItem('timezone')
                      


                        // return     <div key={index}>
                        //     {el.sender !== currentmail ?

                        //         <div className="onestperson sender"><div><span>{el.message || el.text}</span> <span className={'time'}>{getlocaldatefromuniversal(el.created_at)}</span></div></div>
                        //         : <div className="thirdperson receiver"><div className="cImg"><img src='https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg' className="img-fluid rounded" /></div><div><span>{el.message || el.text}</span><span className={'time'}>{getlocaldatefromuniversal(el.created_at)}</span></div></div>
                        //     }

                        // </div>



                        return <div key={index}>

                            {el.type === 'text' ?
                                <div>
                                    {el.sender !== currentmail ?

                                        <div className="onestperson sender"><div><span>{el.message || el.text}</span> <span className={'time'}>{getlocaldatefromuniversal(el.created_at)}</span></div></div>
                                        : <div className="thirdperson receiver"><div className="cImg">

                                            {user.profile_avatar === null ?
                                                <img src={avatar} className="img-fluid" />
                                                :
                                                // <img src={u.avatar_path} alt="profile image" />
                                                <img src={user.profile_avatar} alt="profile image" className="img-fluid" />
                                            }

                                            {/* <img src='https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg' className="img-fluid rounded" /> */}
                                        </div><div className="text_con"><span>{el.message || el.text}</span><span className={'time'}>{getlocaldatefromuniversal(el.created_at)}</span></div></div>
                                    }
                                </div>
                                ///////////////---------------------------------for scheduler messages----------------------//////////////////////
                                :

                                <div>{el.type === 'image' ?
                                    <div className='send_image_block'>
                                        {el.sender === currentmail ? //for user
                                            <div className='send_image_left'>
                                                <div>
                                                    <img onClick={() => props.openbase64(el.text || el.message)} style={{ height: "auto", maxWidth: '65%', maxHeight: "200px" }} src={el.text || el.message}></img>
                                                    {/* <img style={{ height: '80px', width: '80px' }} src='https://lifeguru-prod.s3.us-east-2.amazonaws.com/ChatImages/47aa8a31-683f-474f-803f-c9c184c0d357.png'></img> */}
                                                </div>
                                                <span className={'time'}>{getlocaldatefromuniversal(el.created_at)}</span>
                                            </div>
                                            :   //for coach
                                            <div className='send_image_right'>
                                                <div>
                                                    {/* <img style={{ height: '80px', width: '80px' }} src='https://lifeguru-prod.s3.us-east-2.amazonaws.com/ChatImages/47aa8a31-683f-474f-803f-c9c184c0d357.png'></img> */}
                                                    <img onClick={() => props.openbase64(el.text || el.message)} style={{ height: "auto", maxWidth: '65%', maxHeight: "200px" }} src={el.text || el.message}></img>
                                                </div>
                                                <span className={'time'}>{getlocaldatefromuniversal(el.created_at)}</span>
                                            </div>
                                        }


                                    </div>



                                    :
                                    <div>
                                        {el.type === 'sendimage' ?
                                            ////for sendimage
                                            <div className='send_image_block' >
                                                {el.sender === currentmail ? //for user
                                                    <div className='send_image_left'>
                                                        <div className="onestperson sender_she d-flex flex-column">
                                                            <img onClick={() => props.openbase64(el.message64)} style={{ height: "auto", maxWidth: '65%', maxHeight: "200px", marginBottom: "5px" }} src={el.message64}></img>
                                                            {/* <img style={{ height: '80px', width: '80px' }} src='https://lifeguru-prod.s3.us-east-2.amazonaws.com/ChatImages/47aa8a31-683f-474f-803f-c9c184c0d357.png'></img> */}
                                                        </div>

                                                        {el.showprogress === true ?
                                                            <ProgressBar now={el.progress} label={`${el.progress}%`}></ProgressBar>

                                                            :
                                                            <span className={'time'}>{getlocaldatefromuniversal(el.created_at)}</span>}
                                                    </div>
                                                    :   //for coach
                                                    <div className='send_image_right'>
                                                        <div>
                                                            {/* <img style={{ height: '80px', width: '80px' }} src='https://lifeguru-prod.s3.us-east-2.amazonaws.com/ChatImages/47aa8a31-683f-474f-803f-c9c184c0d357.png'></img> */}

                                                            <img onClick={() => props.openbase64(el.message64)} style={{ height: "auto", maxWidth: '65%', maxHeight: "200px", marginBottom: "5px" }} src={el.message64}></img>

                                                        </div>
                                                        {el.showprogress === true ?
                                                            <ProgressBar now={el.progress} label={`${el.progress}%`}></ProgressBar>

                                                            :
                                                            <span className={'time'}>{getlocaldatefromuniversal(el.created_at)}</span>
                                                        }
                                                    </div>
                                                }


                                            </div>



                                            : /////////for scheduler

                                            <div>{el.type === 'bookingnotification' || el.type === 'rescheduleNotification' ?

                                                <div>
                                                    {/* {el.sender !== currentmail ? */}
                                                    {userdata.account_type == 'user' ? <>
                                                    
                                                        {/* //user */}
                                                           
                                                        
                                                        <div className={el.sender===userdata.uid? 'onestperson sender' : 'thirdperson receiver'}>
                                                            
                                                            
                                                            {el.sender!==userdata.uid &&(<>
                                                                            <div className="cImg">

                                                                            {user.profile_avatar === null ?
                                                                                <img src={avatar} className="img-fluid" />
                                                                                :
                                                                                <img src={user.profile_avatar} alt="profile image" className="img-fluid" />
                                                                            }
                                                                            </div>
                                                            </>)}

                                                            {el.message?(
                                                                
                                                                    console.warn(coachTimezone = JSON.parse(el.message).coachTimezone)
                                                            ):(
                                                                console.warn(coachTimezone = JSON.parse(el.text).coachTimezone)

                                                            )}
                                                            <div><span>
                                                                "Meeting has been {el.type === 'rescheduleNotification' ? 'rescheduled' : 'scheduled'}" <br />
                                                      
                                                                {JSON.parse(el.message ? el.message : el.text).sessiontype === 'intro' ?
                                                                    <div>
                                                                        INTRO BOOKING
                                                                        Session name:{JSON.parse(el.message ? el.message : el.text).session[0].name}<br />
                                                                        Date:{convertToTimezone(coachTimezone,
                                                                        `${JSON.parse(el.message ? el.message : el.text).session[0].date} ${JSON.parse(el.message ? el.message : el.text).session[0].from}`,
                                                                        userTimezone,
                                                                        'YYYY-MM-DD')
                                                                            }<br />
                                                                        Start Time:{convertToTimezone(coachTimezone,
                                                                        `${JSON.parse(el.message ? el.message : el.text).session[0].date} ${JSON.parse(el.message ? el.message : el.text).session[0].from}`,
                                                                        userTimezone,
                                                                        'HH:mm')
                                                                            }<br />
                                                                         End Time:{convertToTimezone(coachTimezone,
                                                                        `${JSON.parse(el.message ? el.message : el.text).session[0].date} ${JSON.parse(el.message ? el.message : el.text).session[0].to}`,
                                                                        userTimezone,
                                                                        'HH:mm')
                                                                            }<br />
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        MAIN BOOKING
                                                                        
                                                                                {JSON.parse(el.message ? el.message : el.text).session.map((ee) => {
                                                                                    if(ee.hasOwnProperty('date'))
                                                                                    {
                                                                                        return <div>
                                                                                            Session name:{ee.name}<br />
                                                                                        Date:{convertToTimezone(coachTimezone,`${ee.date} ${ee.from}`,userTimezone,'YYYY-MM-DD')}<br />
                                                                                        Start Time:{convertToTimezone(coachTimezone,`${ee.date} ${ee.from}`,userTimezone,'HH:mm')}<br />
                                                                                        End Time:{convertToTimezone(coachTimezone,`${ee.date} ${ee.to}`,userTimezone,'HH:mm')}<br />
                                                                                        </div>

                                                                                    }
                                                                        
                                                                    })}

                                                                    </div>
                                                                }
                                                                <span className="yourDetails">

                                                                Accesscode:{JSON.parse(el.message ? el.message : el.text).accesscode}<br />
                                                                UserLink: <a href={JSON.parse(el.message ? el.message : el.text).userLink}> 
                                                              Join Room</a> <br/> 
                                                              </span>

                                                            </span> <span className={'time'}>{getlocaldatefromuniversal(el.created_at)}

                                                                </span></div></div>
                                                    </>: 
                                                        <div className={el.sender===userdata.uid? 'onestperson sender' : 'thirdperson receiver'}>
                                                            {el.sender!==userdata.uid &&(<>
                                                                                <div className="cImg">

                                                                                {user.profile_avatar === null ?
                                                                                    <img src={avatar} className="img-fluid" />
                                                                                    :
                                                                                    <img src={user.profile_avatar} alt="profile image" className="img-fluid" />
                                                                                }
                                                                                </div>
                                                                </>)}

                                                            <div><span>
                                                                "Meeting has been {el.type === 'rescheduleNotification' ? 'rescheduled' : 'scheduled'}" <br />
                                                                
                                                                {JSON.parse(el.message ? el.message : el.text).sessiontype === 'intro' ?
                                                                    <div>
                                                                        INTRO BOOKING
                                                                        Session name:{JSON.parse(el.message ? el.message : el.text).session[0].name}<br />
                                                                        Date:{JSON.parse(el.message ? el.message : el.text).session[0].date}<br />
                                                                        Start Time:{JSON.parse(el.message ? el.message : el.text).session[0].from}<br />
                                                                        End Time:{JSON.parse(el.message ? el.message : el.text).session[0].to}<br />
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        MAIN BOOKING
                                                                        {JSON.parse(el.message ? el.message : el.text).session.map((ee) => {
                                                                            if(ee.hasOwnProperty('date'))
                                                                            {
                                                                                return <div>
                                                                                    Session name:{ee.name}<br />
                                                                                Start Time:{ee.date}<br />
                                                                                Start Time:{ee.from}<br />
                                                                                End Time:{ee.to}<br />
                                                                                </div>
                                                                            }
                                                                    })}
                                                                    </div>
                                                                }

                                                                <span className="yourDetails">
                                                                
                                                                CoachLink: <a style={{color:'black'}} href={JSON.parse(el.message ? el.message : el.text).coachLink}>
                                                                    Join Room</a> <br />
                                                                    </span>

                                                            </span><span className={'time'}>{getlocaldatefromuniversal(el.created_at)}</span></div>
                                                        </div>
                                                    }
                                                </div>

                                                :


                                                <div>


                                                    {el.sender === currentmail ? //for user
                                                        <div className="onestperson sender_she d-flex flex-column">
                                                            <div className="she_title">Title:{JSON.parse(el.message ? el.message : el.text).title}</div>
                                                            <div className="sch_body">
                                                                {JSON.parse(el.message ? el.message : el.text).dates.map((date, index) => {
                                                                    return <div onClick={() => { props.onschedulerdateselect(user, el.message, date) }}>{date.date}
                                                                        <br />Start Time : {JSON.parse(el.message ? el.message : el.text).starttime || ''}
                                                                        <br /> End Time : {JSON.parse(el.message ? el.message : el.text).endtime || ''}
                                                                        <br /> Session Name : {JSON.parse(el.message ? el.message : el.text).session.sessionName || ''}
                                                                        <br /> Session Description : {JSON.parse(el.message ? el.message : el.text).session.sessionDescription || ''}

                                                                    </div>

                                                                })}
                                                            </div>


                                                        </div>
                                                        :   //for coach
                                                        <div className="thirdperson receiver sender_she d-flex flex-column ml-auto">
                                                            <div className="she_title">Title:{JSON.parse(el.message ? el.message : el.text).title}</div>
                                                            <div className="sch_body">
                                                                {JSON.parse(el.message ? el.message : el.text).dates.map((date, index) => {
                                                                    return <div onClick={() => { props.onschedulerdateselect(user, el.message, date) }}>{date.date}
                                                                        <br />Start Time : {JSON.parse(el.message ? el.message : el.text).starttime || ''}
                                                                        <br /> End Time : {JSON.parse(el.message ? el.message : el.text).endtime || ''}
                                                                        <br /> Session Name : {JSON.parse(el.message ? el.message : el.text).session.sessionName || ''}
                                                                        <br /> Session Description : {JSON.parse(el.message ? el.message : el.text).session.sessionDescription || ''}

                                                                    </div>


                                                                })}
                                                            </div>
                                                            <div className="sch_body">


                                                            </div>

                                                        </div>
                                                    }




                                                </div>
                                            }
                                            </div>
                                        }
                                    </div>






                               


                                }






                                </div>




                            }













                        </div>



                    })}
                    {/* <div style={{ float:"left", clear: "both" }}
                    ref={(el) => {messagesEnd = el; }}>
        </div> */}
                </div>

                {/* <Picker onEmojiClick={(e ,emojiObject)=>addEmoji(e,emojiObject,user)} /> */}

                <div id='inputdiv'>
                    <Row className="p-1 m-0">
                        <Col className="message_input p-0 pr-2">
                            <input onKeyPress={(e) => props.keypressed(e, user)} onChange={(e) => props.setmessage(e.target.value, user)} value={user.inputvalue}></input>
                            <div className="schecalender">
                                <span className="mtype" onClick={() => props.openimagechoosemodal(user)}>
                                    <img style={{ height: '20px' }}  src={chatimagesvg}></img>
                                </span>
                                {/* <img style={{ height: '25px' }} src={chatemojisvg}></img> */}
                               &nbsp; 
                                {/* <span className="mtype" onClick={() => props.openschedulemodal(user)}>
                                    <img style={{ height: '20px' }}  src={calendersvg}></img>
                                </span> */}
                            </div>
                        </Col>
                        <Col className="msend_button p-0">
                            <div className="sendm" onClick={(e) => props.sendmessage(user)}>
                                <img
                                    style={{ height: '38px', width: '34px' }} src="https://cdn1.iconfinder.com/data/icons/data-communication-colorful/48/send-512.png">
                                </img>
                            </div>
                            {/* <Button className='btn btn-primary' onClick={(e) => props.sendmessage(user)}>SEND</Button> */}

                        </Col>
                    </Row>
                </div>
            </div>
        )
    }




    return (
        <div>

            <div className="chart_box_container">
                {props.users.length > 0 ? multiads : ''}
            </div>


        </div>

    )

}


export default Chatboxes