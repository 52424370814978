import React from 'react'
import CorporateProfile from './Profile'
import "./profile.css";
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';

export default function profileindex() {
    return (
        <>
            {
                localStorage.getItem("corporate_token") ? (
                    <div className='content_main'>
                        <Header />
                        <CorporateProfile />
                        <Sidebar />
                    </div>
                ) : (
                    <CorporateProfile />
                )
            }
        </>
    )
}
