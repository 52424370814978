import React from "react";
import { Link } from "react-router-dom";
import { sortSvg, dArrowblack, teamSvg, LGspinner } from "../../assets/images";
import { avatar } from "../../assets/img";
import axios from "../../config/axios";
import PaymentDetails from "../../layouts/PaymentDetails";
import {dateConfig} from "../../config/dateFormats";
import LoadingOverlay from "react-loading-overlay";
import {EyeOutlined} from '@ant-design/icons'
import BounceLoader from "react-spinners/BounceLoader";
import { curencyFilter,curencyFilterRates,currencySign,curencyRates } from "../../utils/currencyConvert";
import moment from "moment-timezone";
import {
  convertToTimezone,
  getCochTimezone,
} from "../../utils/timeZoneConvert";
import CustomImageLoader from "react-custom-image-loader.";

class TransactionHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      transactionDetails: [],
      limit: 10,
      maxPageNumberLimit:4,
      minPageNumberLimit:0,
      pageNumberLimit:4,
      offset: 1,
      totalrow: 0,
      sort: "order_desc",
      ShowTransactionDetails:'',
      showmodal:false,
      rates:""
    };
  }
  async componentDidMount() {
    let rates = await curencyRates()
    this.setState({rates})
    this.ApiCall();
  }
  modalShow = ()=>{
       
    this.setState({showmodal:true})
}
closemodal = () =>{
    this.setState({showmodal:false})

}
ShowTransactionDetails=(ShowTransactionDetails)=>{
  this.setState({ShowTransactionDetails},()=>{
    this.modalShow()
  })

}

  ApiCall = () => {
    this.setState({ loader: true });
    let { limit, offset, sort } = this.state;

    axios
      .get(`/payment/history?limit=${limit}&offset=${offset}&sort=${sort}`, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        console.log("transaction---res", res);
        if (res.data.success) {
          const data = res.data.value;
          this.setState({ transactionDetails: data });
          this.setState({ totalrow: res.data.totalrow });
          this.setState({ loader: false });
        } else {
          this.setState({ loader: false });
        }
      })
      .catch((err) => {
        this.setState({ loader: false });

        console.log(err);
      });
  };

  handlePrevious = () => {
    // console.log("clickesss");
    // if (this.state.offset > 0) {
    //   this.setState({ offset: this.state.offset - 1 }, () => {
    //     this.ApiCall();
    //   });
    // }
    let {offset,maxPageNumberLimit,minPageNumberLimit,limit,pageNumberLimit}=this.state
    if (this.state.offset > 0) {
          this.setState({ offset: parseInt(this.state.offset) - 1 }, () => {
            this.ApiCall();
          });
        }
    //await this.setState({offset:offset - 1});

    if ((parseInt(offset) - 1) % pageNumberLimit == 0) {
     
      this.setState({maxPageNumberLimit:maxPageNumberLimit - pageNumberLimit});
      this.setState({minPageNumberLimit:minPageNumberLimit - pageNumberLimit});
    }
  };

  handleNext = async() => {
    // this.setState({ offset: this.state.offset + 1 }, () => {
    //   this.ApiCall();
    // });

    let {offset,maxPageNumberLimit,minPageNumberLimit,limit,pageNumberLimit}=this.state
    let offsetChange=parseInt(this.state.offset)+1
    await this.setState({offset: offsetChange});
   
    if (parseInt(this.state.offset) + 1 > this.state.maxPageNumberLimit) {
        

        if(this.state.offset<Math.ceil(this.state.totalrow/this.state.limit)){
         
        this.setState({maxPageNumberLimit:maxPageNumberLimit + pageNumberLimit});
        this.setState({minPageNumberLimit:minPageNumberLimit + pageNumberLimit});
        }
      
    }
    this.ApiCall();
  };
  handleClick = (e) => {
    console.log("e", e.target.getAttribute("id"));
    this.setState({ offset: e.target.getAttribute("id") }, () => {
      this.ApiCall();
    });
  };
  handaleChange = (e) => {
    this.setState({ sort: e.target.value }, () => {
      this.ApiCall();
    });
  };

  render() {
    const pageNumbers = [];

    for (
      let i = 1;
      i <= Math.ceil(this.state.totalrow / this.state.limit);
      i++
    ) {
      pageNumbers.push(i);
    }

    return (
      <div className="col-md-9">
        {this.state.ShowTransactionDetails!='' &&
        (
          <PaymentDetails 
         closemodal={this.closemodal}
         showmodal={this.state.showmodal}
         detials={this.state.ShowTransactionDetails}/>

        )}
         
        <LoadingOverlay
          active={this.state.loader}
          // spinner={<BounceLoader />}
          spinner={
            <div>
              <CustomImageLoader image={LGspinner} animationType={"pulse"} />
              <br />
            </div>
          }
          text="Loading your Transactions..."
        >
          <div className="searchHead">
            <div className="searchHeading">
              <h3>
                <img width="22px" src={teamSvg} alt="LG-Img" />
                Transaction History
              </h3>
            </div>
            <div className="searchShortBy" style={{ cursor: "pointer" }}>
              <img width="15px" src={sortSvg} alt="LG_Img" />
              <select
                onChange={this.handaleChange}
                style={{ cursor: "pointer" }}
              >
                <option>--- Sort by ---</option>
                <option
                  selected={this.state.sort == "asc" ? true : false}
                  value="asc"
                >
                  A-Z
                </option>
                <option
                  selected={this.state.sort == "desc" ? true : false}
                  value="desc"
                >
                  Z-A
                </option>
                <option
                  selected={this.state.sort == "date_asc" ? true : false}
                  value="date_asc"
                >
                  Date(asc)
                </option>
                <option
                  selected={this.state.sort == "date_desc" ? true : false}
                  value="date_desc"
                >
                  Date(desc)
                </option>
                <option
                  selected={this.state.sort == "order_asc" ? true : false}
                  value="order_asc"
                >
                  Order No.(asc)
                </option>
                <option
                  selected={this.state.sort == "order_desc" ? true : false}
                  value="order_desc"
                >
                  Order No.(desc)
                </option>
              </select>
              <span>
                <img
                  src={dArrowblack}
                  alt="LG_Img"
                  style={{ cursor: "pointer" }}
                />
              </span>
            </div>
          </div>

          <div className="userBookingList trans_his">
            {this.state.transactionDetails.map((transaction) => {

              let refDetails=transaction.description.search("Ref No")!=-1? transaction.description.split(".") : ''
              // const coachDetails=JSON.parse(transaction.package_details)
              let UTCTimezone = "Europe/London (GMT +00:00)";
              let userTimezone = localStorage.getItem("timezone");
              let bookingDate = transaction.paymentCreated; //moment(transaction.created).format('YYYY-MM-DD HH:mm')

              let Coach = transaction.coachDetails;
              let User = transaction.userDetails;
              let packDetails = null;
              let SessionName = "";
              let packageDetails = null;
              let purchaSession = null;
              let sessionDetails = "";
              let sessionDisplayName = "";
              if (transaction.packDetails != null) {
                packDetails = transaction.packDetails[0];
                SessionName = packDetails.title;
              } else {
                packDetails = JSON.parse(transaction.package_details);
                SessionName = packDetails.session_name;
              }

              if (
                transaction.packageDetails !== null ||
                transaction.packageDetails !== undefined
              ) {
                packageDetails = JSON.parse(transaction.packageDetails);
                purchaSession = JSON.parse(packageDetails[0].session_details);
                console.log("hiiiiiii", purchaSession);
                let sessionfind =
                  transaction.sessionNo !== null
                    ? transaction.sessionNo.slice(7)
                    : null;

                sessionDetails = purchaSession.filter((each, i) => {
                  return each.id == sessionfind;
                });
                sessionDisplayName =
                  sessionDetails != undefined && sessionDetails.length > 0
                    ? sessionDetails[0].sessionName
                    : "";

                sessionDisplayName =
                  sessionDisplayName == SessionName ? "" : sessionDisplayName;
                console.log("hiiiiiii nn", sessionDetails);
              }

              return (
                <div className="bookingCocachDetails payment_details_coach">
                  <ul className="bkdt-l">
                    <li>
                      <b>Order No.</b>{" "}
                      <span>{`${transaction.order_id}`.padStart(5, "0")}</span>
                      {/* {refDetails!='' && (<>
                        <p style={{fontSize:"10px" , fontWeight:"bold" ,marginBottom:"4px",whiteSpace: "nowrap"}}>({refDetails[0].trim()} :{refDetails[1].trim()})</p>
                      </>)} */}
                      </li>
                    <li>
                      <b>Package & Session details</b>{" "}
                      <span>
                        {SessionName}{" "}
                        {sessionDisplayName != ""
                          ? "- " + sessionDisplayName
                          : ""}
                      </span>
                    </li>
                    <li>
                      <b>Card Charge Date</b>{" "}
                      <span>
                        {convertToTimezone(
                          UTCTimezone,
                          bookingDate,
                          userTimezone,
                          dateConfig.dateTimeFormat
                        )}
                      </span>
                    </li>
                    <li>
                      <b>Coach Name</b> <span>{Coach.name}</span>
                    </li>
                    {/* <li><b>Price</b> <span>${transaction.amount}</span></li> */}
                    <li>
                      <b>Amount</b>{" "}
                      <span>
                        {/* {curencyFilterRates(`USD##${transaction.amount}`,transaction.currency_rates)} */}
                        {transaction.paid_value!=null?(
                                          `${currencySign(transaction.paid_currency)}${transaction.paid_value}`
                                        ):(
                                          `${currencySign(transaction.currency)}${transaction.amount}`
                                        )}
                        </span>
                    </li>
                    {/* <li>
                      <b>In USD</b>{" "}
                      <span>
                      {currencySign(transaction.currency)}{transaction.amount}
                        </span>
                    </li> */}
                    
                    {/* <li>
                      <b>Card Type</b>{" "}
                      <span>{transaction.payment_type}</span>
                    </li> */}
                    <li>
                      <b>Last 4 digits of card</b>{" "}
                      <span>{transaction.cardLastFour !== "" ? `**** **** **** ${transaction.cardLastFour}` : ""}</span>
                    </li>
                    {transaction.payment_status === "succeeded" ||
                    transaction.payment_status.search("Approved") >= 0 ? (
                      <li>
                        <b>Status 
                          {/* {transaction.long_description!=null && (
                              <EyeOutlined className="eyeicon" onClick={()=>this.ShowTransactionDetails(transaction)}/>
                          )} */}
                          </b>{" "}
                        <span className="badge text-white  badge-success">
                          Success
                        </span>
                      </li>
                    ) : (
                      <li>
                        <b>Status 
                          {/* {transaction.long_description!=null && (
                             <EyeOutlined className="eyeicon" onClick={()=>this.ShowTransactionDetails(transaction)}/>
                          )} */}
                          </b>{" "}
                        <span className="badge text-white text-  badge-danger">
                          Cancelled
                        </span>
                      </li>
                    )}
                    <li className="user-transaction-refNo">
                    {refDetails!='' && (<>
                        <p style={{fontSize:"10px" , fontWeight:"bold" ,marginBottom:"4px",whiteSpace: "nowrap"}}>({refDetails[0].trim()} :{refDetails[1].trim()})</p>
                      </>)}
                    </li>
                  </ul>
                </div>
              );
            })}
            {this.state.transactionDetails.length <= 0 &&
            this.state.loader == false ? (
              <>
                <div className="bookingCocachDetails">
                  <ul className="no-records">
                    <li style={{ flex: "0 0 100%", textAlign: "center"}}>
                      <b>No Records Found</b>
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <></>
            )}

            <div
              className="text-center"
              style={{ cursor: "pointer", flex: "none !important" }}
            >
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center my-5">
                  {pageNumbers.length > 1 && this.state.offset > 1 && (
                    <>
                      <li className="page-item">
                        <a
                          className="page-link"
                          aria-label="Previous"
                          onClick={this.handlePrevious}
                        >
                          <i
                            className="fa fa-angle-left"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                    </>
                  )}
                  {pageNumbers.map((number) => {
                    
                    if (number < this.state.maxPageNumberLimit + 1 && number > this.state.minPageNumberLimit) {
                    return (
                      <li className="page-item">
                        <a
                          className={
                            this.state.offset == number
                              ? "page-link  active"
                              : "page-link "
                          }
                          key={number}
                          id={number}
                          onClick={this.handleClick}
                        >
                          {number}
                        </a>
                      </li>
                    );
                  } else {
                    return null;
                  }
                  })}

                  {pageNumbers.length > 1 &&
                    this.state.offset < pageNumbers.length && (
                      <>
                       <div>{pageNumbers.length>this.state.limit?"..":""}</div>
                        <li className="page-item">
                          <a
                            className="page-link"
                            aria-label="Next"
                            onClick={this.handleNext}
                          >
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                      </>
                    )}
                </ul>
              </nav>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default TransactionHistory;
