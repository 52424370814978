
import React, { useState } from "react";
import { Form, Row, Col, Button,Container } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";
import { useHistory } from 'react-router-dom';

import { user, LGspinner } from "../../assets/images";
import axios from "../../config/axios";
import { message } from "antd";
import OpenEye from "../../assets/openEye.jpeg";
import CloseEye from "../../assets/closeEye.png";

const JobseekerChangePassword = () => {
  const history = useHistory();
  const token = localStorage.getItem("authToken");
  if(token == "" || token == undefined){
    history.push('/webinars');
  }
  

  const [loader, setLoader] = useState(false);
  const [profile, setProfile] = useState({
    password: "",
    npassword: "",
    cpassword: "",
  });
  const [errors, setErrors] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleInput = (e) => {
    e.persist();
    setProfile((prevProfile) => ({
      ...prevProfile,
      [e.target.name]: e.target.value,
    }));
    validateForm(e);
  };

  const validateForm = (e) => {
    let newErrors = [];

    if (!e.target.value.trim()) {
      newErrors[e.target.name] = "Please enter password";
      setErrors(newErrors);
      return false;
    } else {
      newErrors[e.target.name] = "";
      setErrors(newErrors);
    }
  };

  const updateDetails = () => {
    const { password, cpassword, npassword } = profile;
    let newErrors = [];

    if (!password.trim()) {
      newErrors["password"] = "Please enter password";
      setErrors(newErrors);
      return false;
    }

    if (!npassword.trim()) {
      newErrors["npassword"] = "Please enter new password";
      setErrors(newErrors);
      return false;
    } else if (npassword.length < 6) {
      newErrors["npassword"] = "Password must be at least 6 characters";
      setErrors(newErrors);
      return false;
    }

    if (npassword !== cpassword) {
      newErrors["cpassword"] = "Confirm password and new password do not match";
      setErrors(newErrors);
      return false;
    }

    if (Object.keys(newErrors).length > 0) return false;

    const formData = new FormData();
    formData.append("password", password);
    formData.append("npassword", npassword);

    let data = {
      password: password,
      npassword: npassword,
    };

    axios
      .post("/webinar/update_profile_pwd", data, {
        headers: { "X-Auth": token },
      })
      .then((res) => {
        if (res.data.success) {
          message.success("Profile updated successfully");
          setProfile({
            password: "",
            npassword: "",
            cpassword: "",
          });
        } else if (res.data.errors && res.data.errors.length > 0) {
          let errors = [];
          message.error("Failed to update profile");
          res.data.errors.forEach((item) => {
            errors[item.param] = item.msg;
          });
          setErrors(errors);
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        message.error(err.message);
        console.log("err", err);
      });
  };

  return (
      <Container>
      <LoadingOverlay active={loader} className="webiner_loader" spinner={<CustomImageLoader image={LGspinner} animationType={"none"} />} >
        <div className="content_wrap">
          <div className="profile_wrap" style={{"width":"50%","margin":"auto"}} >
            <Form>
              <Row className="justify-content-center">
                <Col className="coachProfileFormEdit cpe_sm">
                  <div className="coachImg mb-4">
                    <img id="item-img-output" src={user} className="img-fluid" alt="profile_image" />
                  </div>

                  <Form.Group>
                    <Form.Label>Current Password</Form.Label>
                    <Form.Control onChange={(e) => handleInput(e)} value={profile.password} type={showPassword ? "text" : "password"} placeholder="Enter Current Password" name="password" />
                    <img
                      style={{
                        cursor: "pointer",
                        position: "relative",
                        top: "-36px",
                        width: "26px",
                        left: "19rem",
                      }}
                      className="passwordImg"
                      onClick={() => setShowPassword(!showPassword)}
                      src={showPassword ? CloseEye : OpenEye}
                    />


                    <p style={{ color: "red" }}>{errors["password"]}</p>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>New Password</Form.Label>
                    <Form.Control onChange={(e) => handleInput(e)} value={profile.npassword} type={showPassword1 ? "text" : "password"} name="npassword" placeholder="Enter New Password" />
                    <img
                      style={{
                        cursor: "pointer",
                        position: "relative",
                        top: "-36px",
                        width: "26px",
                        left: "19rem",
                      }}
                      className="passwordImg"
                      onClick={() => setShowPassword1(!showPassword1)}
                      src={showPassword1 ? CloseEye : OpenEye}
                    />
                    <p style={{ color: "red" }}>{errors["npassword"]}</p>
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>
                          Confirm New password <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control type={showPassword2 ? "text" : "password"} onChange={(e) => handleInput(e)} value={profile.cpassword} name="cpassword" placeholder="Enter Confirm Password" />
                        <img
                          style={{
                            cursor: "pointer",
                            position: "relative",
                            top: "-36px",
                            width: "26px",
                            left: "19rem",
                          }}
                          className="passwordImg"
                          onClick={() => setShowPassword2(!showPassword2)}
                          src={showPassword2 ? CloseEye : OpenEye}
                        />
                        <p style={{ color: "red" }}>{errors["cpassword"]}</p>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="d-flex justify-content-center">
                      <Button className="defaultBtn" variant="default" onClick={updateDetails}>
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </LoadingOverlay>
    
  </Container>
  );
};

export default JobseekerChangePassword;