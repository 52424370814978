import React from "react";
import axios from "../../config/axios";
import { toast } from "react-toastify";
import { SRLWrapper } from "simple-react-lightbox";
import message from '../../utils/mesaages';

import { deleteSvg, moreSvg, documentSvg, xlsSvg, pptSvg, pdfSvg, docSvg, videoBanner } from "../../assets/images";
import {
  EditFileName,
  ListModal,
  SocialMediaButtons,
} from "../../components/resourcefilemodal";
import { VideoPlayer } from "../../components/media";
import { Link } from "react-router-dom";
import moment from "moment"
let userId = 'secret';
try {
  let User=JSON.parse(localStorage.getItem('userdata'))
  userId = User.uid
} catch (error) {
  
}

const options = {
  buttons: {
    showDownloadButton: false,
  }
};

class Resources extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resource: null,
      is_featured: 0,
      videos: [],
      photos: [],
      pdfs: [],
      audios: [],
      isLoaded: false,
      visibility: "private",
      currentVideo: "",
      currentDocument: "",
      URL: "",
      shareType: "",
      fileName: "",
      viewMoreType: "",
      currentResourceDataSet: [],
      resourceId: "",
      resourcePath: "",
      resourceName: "",
      VideoLimit:8,
      PhotoLimit:8,
      DocumentLimit:8,
      AudioLimit:8,
      visitorId: "",
      valid: false,
      limit:4,
      offset:0,
      renderimage:0,
      limitAudio:4,
      offsetAudio:0,
      audioTotalcount:0,
      limitDocument:4,
      offsetDocument:0,
      DocumentTotalcount:0,
      limitVideo:3,
      offsetVideo:0,
      VideoTotalcount:0,
    };
  }

  async componentDidMount() {
    console.log("......pop")
    let User=JSON.parse(localStorage.getItem('userdata'))
    await this.setState({visitorId: User.uid})
    this.checkIntroBooking()
    this.getResource();
    this.getResourceImage()
   this.getResourceAudio()
   this.getResourceDocument()
   this.getResourceVideo()
 
  }

  getResourceVideo() {
    let {limitVideo,offsetVideo} = this.state;
    const id=this.props.userdata.uid
    axios
      .get(`/consumer/resourcesvideo/?limit=${limitVideo}&offset=${offsetVideo}&id=${id}`, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
       
       
        this.setState({
         
          videos: [],
         
        });
        const resourcetypes = res.data.value.rows;
        this.setState({VideoTotalcount: res.data.value.c[0].c})
        resourcetypes.filter((resourcetype) => {
         
          if (resourcetype.resource_type.includes("video") && (resourcetype.visibility != "private" ||this.state.valid)) {
            this.state.videos.push(resourcetype);
          
          } 
          
          
          return true;
        });
        
        this.setState({ isLoaded: true });
      })
      .catch((error) => {
        message.error("Error in fetching resources");
      });
  }

  getResourceImage() {
    let {limit,offset} = this.state;
    const id=this.props.userdata.uid
    axios
      .get(`/consumer/resourcesimage/?limit=${limit}&offset=${offset}&id=${id}`, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        console.log('resorces image',res.data.value)
        console.log('resorces count image',res.data.value.c[0].c)
       
        this.setState({
         
          photos: [],
         
        });
        const resourcetypes = res.data.value.rows;
        this.setState({imageTotalcount: res.data.value.c[0].c})
        resourcetypes.filter((resourcetype) => {
          if (resourcetype.resource_type.includes("image") && (resourcetype.visibility != "private" ||this.state.valid)) {
            this.state.photos.push(resourcetype);
          
          } 
          return true;
        });
        
        this.setState({ isLoaded: true });
      })
      .catch((error) => {
        message.error("Error in fetching resources");
      });
  }

  getResourceAudio() {
    let {limitAudio,offsetAudio} = this.state;
    const id=this.props.userdata.uid
    axios
      .get(`/consumer/resourcesaudio/?limit=${limitAudio}&offset=${offsetAudio}&id=${id}`, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        console.log('resorces audio',res.data.value)
        console.log('resorces count audio',res.data.value.c[0].c)
       
        this.setState({
         
          audios: [],
         
        });
        const resourcetypes = res.data.value.rows;
        this.setState({audioTotalcount: res.data.value.c[0].c})
        resourcetypes.filter((resourcetype) => {
          if (resourcetype.resource_type.includes("audio") && (resourcetype.visibility != "private" ||this.state.valid)) {
            this.state.audios.push(resourcetype);
          
          } 
          return true;
        });
        
        this.setState({ isLoaded: true });
      })
      .catch((error) => {
        message.error("Error in fetching resources");
      });
  }

  getResourceDocument() {
    let {limitDocument,offsetDocument} = this.state;
    const id=this.props.userdata.uid
    axios
      .get(`/consumer/resourcesdocument/?limit=${limitDocument}&offset=${offsetDocument}&id=${id}`, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
       
       
        this.setState({
         
          pdfs: [],
         
        });
        const resourcetypes = res.data.value.rows;
        this.setState({DocumentTotalcount: res.data.value.c[0].c})
        resourcetypes.filter((resourcetype) => {
         if(resourcetype.visibility != "private" || this.state.valid){
          this.state.pdfs.push(resourcetype);
        }
          
          
          return true;
        });
        
        this.setState({ isLoaded: true });
      })
      .catch((error) => {
        message.error("Error in fetching resources");
      });
  }

  checkIntroBooking()
       {       
         
        let id=this.props.userdata.uid
        let userid=this.state.visitorId
        let from={coachUID:id,userUID:userid}
       
        axios.post(`/consumer/checkIntroBooking`,from)
              .then(res =>{
                if(res.data.success)
                {
                  if(res.data.value>0)
                  {
                    //console.log("@@@@@@@@@@@@@@@@ DEBUG @@@@@@@@@@@@@@@@", res.data.value)
                    this.setState({valid: true})                   
                   
                  }
                }
               
              })
              .catch(err =>{
              

              })
       }

  

  getResource() {
    //console.log("@@@@@@@@@@@@@@@@@@@ valid value @@@@@@@@@@@@@",this.state.valid)
    const id=this.props.userdata.uid
    axios
      .get(`/users/resources/${id}`, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        this.setState({
          videos: [],
          photos: [],
          pdfs: [],
          audios: [],
        });
        const resourcetypes = res.data.value;
        resourcetypes.filter((resourcetype) => {
          //console.log("@@@@@@@@@@@@@@@@@@@ valid value @@@@@@@@@@@@@",this.state.valid)
          if (resourcetype.resource_type.includes("image") && (resourcetype.visibility != "private" || this.state.valid)) {
            // this.state.photos.push(resourcetype);
          } else if (resourcetype.resource_type.includes("video") && (resourcetype.visibility != "private" || this.state.valid)) {
            // this.state.videos.push(resourcetype);
          } else if (resourcetype.resource_type.includes("audio") && (resourcetype.visibility != "private" || this.state.valid)) {
            // this.state.audios.push(resourcetype);
          } else if(resourcetype.visibility != "private" || this.state.valid) {
            // this.state.pdfs.push(resourcetype);
          }
          return true;
        });
        this.setState({ isLoaded: true });
      })
      .catch((error) => {
        toast("Error in fetching resources", { type: "error" });
      });
  }

  handlegetResource = () => {
    this.getResource();
  };

  onChangeHandler = (e) => {
    this.setState({
      resource: e.target.files[0],
      loaded: 0,
    });
  };

  handleCheckbox = (e) => {
    if (e.target.value == 0) {
      this.setState({ is_featured: 1 });
    } else if (e.target.value == 1) {
      this.setState({ is_featured: 0 });
    }
  };

  handleChange = (e) => {
    e.persist();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleVisibiltyChange = (e) => {
    this.setState({ visibility: e.target.value });
  };
  onClickHandler = () => {
    this.props.handleSubmit(true);
    let formData = new FormData();
    formData.append("resource", this.state.resource);
    formData.append("is_featured", 1);
    formData.append("visibility", this.state.visibility);
    formData.append("fileName", this.state.fileName);

    axios
      .post("/users/resources", formData, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        this.props.handleSubmit(false);
        this.getResource();
        toast("Resource added", { type: "success" });
      })
      .catch((error) => {
        this.props.handleSubmit(false);
        toast("Error in uploading file", { type: "error" });
      });
  };

  handleOpenSettings = (e) => {
    let id = e.currentTarget.id;
    this.setState({ currentVideo: id });
  };

  handlePromotionalVideo = (data) => {
    const formData = {
      path: data.profileVideo_path,
      uuid: data.id,
    };
    axios
      .put("users/resources/promotionalvideo", formData, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        if (res.data.success) {
          toast(res.data.msg, { type: "success" });
        } else if (!res.data.success) {
          toast(res.data.err, { type: "error" });
        }
        this.props.handlegetProfileVideo();
      });
  };

  handlePromotionalImage = (data) => {
    const formData = {
      path: data.avatar_path,
      uuid: data.id,
    };

    axios
      .put("users/resources/promotionalImage", formData, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        if (res.data.success) {
          toast(res.data.msg, { type: "success" });
        } else if (!res.data.success) {
          toast(res.data.err, { type: "error" });
        }
        this.props.handlegetProfileImage();
        console.log("promotionalres", res);
      });
  };

  handleDeleteResource = (id) => {
    axios
      .delete(`/users/resources/deleteresource/${id}`, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        if (res.data.success) {
          toast(res.data.msg, { type: "success" });
        } else {
          toast(res.data.msg, { type: "error" });
        }
        this.getResource();
      });
  };

  handleStatus = (visibility, id) => {
    const formData = {
      visibility: visibility === "public" ? "private" : "public",
    };
    axios
      .put(`/users/resources/updateresourceStatus/${id}`, formData, {
        headers: { "x-auth": this.props.token },
      })
      .then((res) => {
        if (res.data.success) {
          toast(res.data.msg, { type: "success" });
        } else {
          toast(res.data.msg, { type: "error" });
        }
        this.getResource();
      });
  };

  handleShare = (data) => {
    this.setState({ URL: data.path, shareType: data.ResourceType });
  };

  // handleViewMore = (type) => {
  //   let resourceDataSet;
  //   if (type === "Video") {
  //     resourceDataSet = this.state.videos;
  //   }
  //   if (type === "Audio") {
  //     resourceDataSet = this.state.audios;
  //   }
  //   if (type === "Document") {
  //     resourceDataSet = this.state.pdfs;
  //   }
  //   if (type === "Photo") {
  //     resourceDataSet = this.state.photos;
  //   }
  //   this.setState({
  //     viewMoreType: type,
  //     currentResourceDataSet: resourceDataSet,
  //   });
  // };

  handleEditfileName = (data) => {
    this.setState({
      viewMoreType: data.type,
      resourceId: data.id,
      resourcePath: data.path,
      resourceName: data.filename,
    });
  };

  handleViewMore=async(type)=>{
    if (type === "Video") {
      //this.setState({VideoLimit:this.state.videos.length})
      // let limitVideo=this.state.limitVideo+4
      let limitVideo=this.state.limitVideo + (this.state.VideoTotalcount - 3)

      await this.setState({limitVideo:limitVideo})
        
       this.getResourceVideo()
    }
    if (type === "Photo") {
      //this.setState({PhotoLimit:this.state.photos.length})
      // let limit=this.state.limit+4
      let limit=this.state.limit + (this.state.imageTotalcount - 4)
 
     await this.setState({limit:limit})
       console.log('viewmore click')
 
      this.getResourceImage()
     
 
     }
     if (type === "Document") {
       //this.setState({DocumentLimit:this.state.pdfs.length})
      //  let limitDocument=this.state.limitDocument+4
      let limitDocument=this.state.limitDocument + (this.state.DocumentTotalcount - 4)
 
       await this.setState({limitDocument:limitDocument})
         
        this.getResourceDocument()
     }
     if (type === "Audio") {
       //this.setState({AudioLimit:this.state.audios.length})
      //  let limitAudio=this.state.limitAudio+4
      let limitAudio=this.state.limitAudio + (this.state.audioTotalcount - 4)
 
       await this.setState({limitAudio:limitAudio})
         
        this.getResourceAudio()
     }
  }

  renameFileName = (value, filetype, filename) => {
    if(filetype == 'pdf') {
      window.open(value);
    } else {
      console.log("Yes clicked", typeof(value))
    let timeIN = moment().format('MMMM-Do-YYYY-h:mm:ss-a')
    let fileName = filename !='' ? filename : timeIN
    const hidemsg =message.loading("Downloading file...",0)
    axios.get(value, {
      responseType: 'blob'
  }
).then(function(data) {
    console.log("get response", data)
    //var binaryData = [];
    //binaryData.push(data);

    var a = document.createElement('a');
    //var url = window.URL.createObjectURL(new Blob(binaryData, {type: "application/zip"}));
    var url = window.URL.createObjectURL(data.data);
    console.log("url after converting into blob", url)
    a.href = url;
    a.download = `${fileName}.${filetype}`;
    document.body.append(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
    hidemsg()
    message.success("Downloaded")
  }).catch(function(err) {
    hidemsg()
    message.error("Download failed")
    console.log(err)
  })
    }
  }

  renamedResourceDownload = (path, filetype, filename, resourceType) => {
    let postData = {path, filetype, filename, resourceType}
    //console.log("I am here in rename resource download")

    const hidemsg =message.loading("Downloading file...",0)
    axios.post('/users/resources/renamed-resource-download', postData, {
      headers: { "x-auth": this.props.token }
    }).then(function(res) {
      if(res.data.success) {

        console.log("get object response", res.data.value)

        let timeIN = moment().format('MMMM-Do-YYYY-h:mm:ss-a')
        let fileName = filename !='' ? filename : timeIN

        var a = document.createElement('a');
        //var url = window.URL.createObjectURL(new Blob(binaryData, {type: "image/jpeg"}));
        var url = window.URL.createObjectURL(new Blob([new Uint8Array(res.data.value.data)], {type: `${resourceType}`}));
        console.log("url after converting into blob", url)
        a.href = url;
        a.download = `${fileName}.${filetype}`;
        document.body.append(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);

        hidemsg()
        message.success("Downloaded")

      } 
      else {
        hidemsg()
        message.error("Download failed")
      }
    }).catch(function(err) {
      hidemsg()
      message.error("Download failed")
      console.log(err)
    })
  }

  render() {
    let {userdata} = this.props
    let actionClass = userId === userdata.uid ? 'show': 'hideaction'
    
    console.log("1-----------------",userId , userdata.uid, userdata)
    const pdfData =
      this.state.pdfs &&
      this.state.pdfs.map((pdf) => {
        let array = pdf.path.split("/").pop()
        let filetype = array.split(".").pop().toLowerCase()
        if (pdf.is_featured === 1)
          return (
            <div className="col-lg-3 col-md-4 col-6 my-3">
              <div className="pf__pdf">
              <div onClick={() => this.renameFileName(pdf.path, filetype, pdf.file_name)} style={{cursor: "pointer"}}>
                  {/* <iframe
                    alt="LG-Img"
                    //src="https://lifeguru.s3-us-east-2.amazonaws.com/assets/doc_placeholder.png"
                    src={pdf.path}
                  /> */}
                  {/* <img
                  alt="LG-Img"
                  src={documentSvg}
                  className="img-fluid"
                  width="35px"
                  /> */}
                  {filetype == "doc" || filetype == "docx" ? 
                        <img
                        alt="LG-Img"
                        src={docSvg}
                        className="img-fluid"
                        width="35px"
                      />
                       : filetype == "pdf" ? 
                       <img
                       alt="LG-Img"
                       src={pdfSvg}
                       className="img-fluid"
                       width="35px"
                     />
                      : filetype == "xls" || filetype == "xlsx"? 
                        <img
                        alt="LG-Img"
                        src={xlsSvg}
                        className="img-fluid"
                        width="35px"
                      />
                      : filetype == "ppt" || filetype == "pptx"? 
                        <img
                        alt="LG-Img"
                        src={pptSvg}
                        className="img-fluid"
                        width="35px"
                      />
                       : 
                       <img
                       alt="LG-Img"
                       src={docSvg}
                       className="img-fluid"
                     />
                       }
                </div>
               <Link
                  to="profile-coach"
                  className={`deletIcon2 ${actionClass}`}
                  onClick={() => {
                    this.handleDeleteResource(pdf.uid);
                  }}
                >
                  <img
                    
                    className="deletIcon"
                    alt="LG-Img"
                    src={deleteSvg}
                  />
                </Link>
                
                <p className="resource-title">{pdf.file_name}</p>
                <div className={`dropdown ${actionClass}`} >
                  <Link
                    to="profile-coach"
                    className="dropdown-toggle"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src={moreSvg} alt="LG-Img" width="25px" />
                  </Link>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li>
                      <Link
                        to="profile-coach"
                        className="dropdown-item"
                        onClick={() => {
                          this.handleDeleteResource(pdf.uid);
                        }}
                      >
                        Delete Document
                      </Link>
                    </li>

                    <li>
                      <button
                        className="dropdown-item"
                        data-toggle="modal"
                        data-target="#share"
                        onClick={() => {
                          const data = {
                            ResourceType: pdf.resource_type,
                            path: pdf.path,
                          };
                          this.handleShare(data);
                        }}
                      >
                        Share to Social Media
                      </button>
                    </li>
                    <li>
                      <div>
                        <button
                          className="dropdown-item"
                          onClick={() =>
                            this.handleStatus(pdf.visibility, pdf.uid)
                          }
                        >
                          {pdf.visibility === "public" ? (
                            <div>Client View Only</div>
                          ) : (
                            <div>Public View</div>
                          )}
                        </button>
                      </div>
                    </li>

                    <li >
                      <button

                        className="dropdown-item"
                        data-toggle="modal"
                        data-target="#editfileName"
                        onClick={() => {
                          const data = {
                            type: "Document",
                            id: pdf.uid,
                            path: pdf.path,
                            filename: pdf.file_name,
                          };
                          this.handleEditfileName(data);
                        }}
                      >
                        Edit Title
                      </button>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          );
        else {
          return <p>...</p>;
        }
      });

    const audiosData =
      this.state.audios &&
      this.state.audios.map((audio) => {
        let array = audio.path.split("/").pop()
        let filetype = array.split(".").pop().toLowerCase()
        if (audio.is_featured === 1)
          return (
            <div className="col-md-3 pdf_item photo_item">
              <div className="pf__pdf">
                <img
                  alt="LG-Img"
                  src="https://marketplace.bantu.my/assets/audio-placeholder-304b4c582a7bc94e6bfeefa1cde5582dd56ab86affa79b6cc9d70e3027926ee8.png"
                />

                <audio controls controlsList="nodownload">
                  <source src={audio.path} type="audio/ogg" />
                  <source src={audio.path} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>

                {/* <Link
                  to="profile-coach"
                  className={`deletIcon2 ${actionClass}`}
               
                  onClick={() => {
                    this.handleDeleteResource(audio.uid);
                  }}
                >
                  <img
                    className="deletIcon"
                    alt="LG-Img"
                    src={deleteSvg}
                  />
                </Link> */}
                <div
                  className='downLoadIcon-resource'
                  onClick={() => {
                    this.renameFileName(audio.path, filetype, audio.file_name);
                  }}
                >
                  <i className="fa fa-download" aria-hidden="true" style={{color:"black"}}></i>
                </div>
                <p className="resource-title">{audio.file_name}</p>
                <div   className={`dropdown ${actionClass}`} >
                  <Link
                    className="dropdown-toggle"
                    to="profile-coach"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img alt="LG-Img" src={moreSvg} width="25px" />
                  </Link>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li>
                      <Link
                        to="profile-coach"
                        className="dropdown-item"
                        // href="javascript:void(0)"
                        onClick={() => {
                          this.handleDeleteResource(audio.uid);
                        }}
                      >
                        Delete Audio
                      </Link>
                    </li>

                    <li>
                      <button
                        className="dropdown-item"
                        // href="javascript:void(0)"
                        data-toggle="modal"
                        data-target="#share"
                        onClick={() => {
                          const data = {
                            ResourceType: audio.resource_type,
                            path: audio.path,
                          };
                          this.handleShare(data);
                        }}
                      >
                        Share to Social Media{" "}
                      </button>
                    </li>
                    <li>
                      <div>
                        <button
                          className="dropdown-item"
                          // href="javascript:void(0)"
                          onClick={() =>
                            this.handleStatus(audio.visibility, audio.uid)
                          }
                        >
                          {audio.visibility === "public" ? (
                            <div>Client View Only</div>
                          ) : (
                            <div>Public View</div>
                          )}
                        </button>
                      </div>
                    </li>

                    <li >
                      <button
                        className="dropdown-item"
                        // href="javascript:void(0)"
                        data-toggle="modal"
                        data-target="#editfileName"
                        onClick={() => {
                          const data = {
                            type: "Audio",
                            id: audio.uid,
                            path: audio.path,
                            filename: audio.file_name,
                          };
                          this.handleEditfileName(data);
                        }}
                      >
                        Edit Title
                      </button>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          );
        else {
          return <p>...</p>;
        }
      });

    const videoData =
      this.state.videos &&
      this.state.videos.map((video) => {
        let array = video.path.split("/").pop()
        let filetype = array.split(".").pop().toLowerCase()
        let duration = Math.floor(video.video_duration)
        let hrs = ~~(duration / 3600)
        let mins = ~~((duration % 3600) / 60)
        let secs = (duration % 60)
        var ret = "";
        if (hrs > 0) {
          ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
  
        ret += "" + (mins < 10 ? "0" : "") + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        if (video.is_featured === 1)
          return (
            <div className="col-lg-4 col-md-4 col-6 my-3">
              <VideoPlayer
                id={video.id}
                src={video.path}
                // poster={
                //   "https://markipo.com/Markipo_Reportages_Cyclisme_13_10/res/video.poster.jpg"
                // }
                poster={videoBanner}
                flag={video.processed}
                time={ret}
              />

              <Link
                to="profile-coach"
                className={`deletIcon2 ${actionClass}`}
             
                onClick={() => {
                  this.handleDeleteResource(video.uid);
                }}
              >
                <img
                  className="deletIcon"
                  alt="LG-Img"
                  src={deleteSvg}
                />
              </Link>
              <div
                  className='downLoadIcon-resource'
                  onClick={() => {
                    this.renamedResourceDownload(video.path, filetype, video.file_name, video.resource_type);
                  }}
                >
                  <i className="fa fa-download" aria-hidden="true" style={{color:"black"}}></i>
                </div>
              <p className="resource-title">{video.file_name}</p>
              <div className={`dropdown ${actionClass}`} >
                <Link
                  to="profile-coach"
                  className="dropdown-toggle"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img alt="lg-img" src={moreSvg} width="25px" />
                </Link>

                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <li>
                    <Link
                      to="profile-coach"
                      className="dropdown-item"
                      // href="javascript:void(0)"
                      onClick={() => {
                        const data = {
                          id: video.uid,
                          profileVideo_path: video.path,
                        };
                        this.handlePromotionalVideo(data);
                      }}
                    >
                      Make Promotional Video
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="profile-coach"
                      className="dropdown-item"
                      // href="javascript:void(0)"
                      onClick={() => {
                        this.handleDeleteResource(video.uid);
                      }}
                    >
                      Delete video
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="profile-coach"
                      className="dropdown-item"
                      // href="javascript:void(0)"
                      data-toggle="modal"
                      data-target="#share"
                      onClick={() => {
                        const data = {
                          ResourceType: video.resource_type,
                          path: video.path,
                        };
                        this.handleShare(data);
                      }}
                    >
                      Share to Social Media{" "}
                    </Link>
                  </li>

                  <li>
                    <div>
                      <Link
                        to="profile-coach"
                        className="dropdown-item"
                        // href="javascript:void(0)"
                        onClick={() =>
                          this.handleStatus(video.visibility, video.uid)
                        }
                      >
                        {video.visibility === "public" ? (
                          <div>Client View Only</div>
                        ) : (
                          <div>Public View</div>
                        )}
                      </Link>
                    </div>
                  </li>

                  <li >
                    <Link
                      to="profile-coach"
                      className="dropdown-item"
                      // href="javascript:void(0)"
                      data-toggle="modal"
                      data-target="#editfileName"
                      onClick={() => {
                        const data = {
                          type: "Video",
                          id: video.uid,
                          path: video.path,
                          filename: video.file_name,
                        };
                        this.handleEditfileName(data);
                      }}
                    >
                      Edit Title
                    </Link>
                  </li>
                </div>
              </div>
            </div>
          );
        else {
          return <p>...</p>;
        }
      });

    const photoData =
      this.state.photos &&
      this.state.photos.map((photo) => {
        let resourceName = photo.path.split("/").pop()
        let filetype = resourceName.split(".").pop().toLowerCase()
        if (photo.is_featured === 1)
          return (
            <div className="col-lg-3 col-md-4 col-6 my-3">
              <a
                href={photo.path}
                // data-toggle="lightbox"
                // data-gallery="example-gallery"
                // className="boxWrap"
              >
                <img src={photo.path} alt="" className="img-fluid" />
              </a>
              {/* <Link
                to="profile-coach"
                className={`deletIcon2 ${actionClass}`}
             
                onClick={() => {
                  this.handleDeleteResource(photo.uid);
                }}
              >
                <img
                  className="deletIcon"
                  alt="Img-lg"
                  src={deleteSvg}
                />
              </Link> */}
              <div
                  className='downLoadIcon-resource'
                  onClick={() => {
                    this.renamedResourceDownload(photo.path, filetype, photo.file_name, photo.resource_type);
                  }}
                >
                  <i className="fa fa-download" aria-hidden="true" style={{color:"black"}}></i>
                </div>

              <p className="resource-title">{photo.file_name}</p>
              {/* <div className={`dropdown ${actionClass}`} >
                <Link
                  className="dropdown-toggle"
                  to="profile-coach"
                  // href="#"
                  // role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img alt="lg" src={moreSvg} width="25px" />
                </Link>

                
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <li>
                    <Link
                      to="profile-coach"
                      className="dropdown-item"
                      onClick={() => {
                        const data = {
                          id: photo.uid,
                          avatar_path: photo.path,
                        };
                        this.handlePromotionalImage(data);
                      }}
                    >
                     Make Main Profile Image
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="profile-coach"
                      className="dropdown-item"
                      onClick={() => {
                        this.handleDeleteResource(photo.uid);
                      }}
                    >
                      Delete Photo
                    </Link>
                  </li>

                  <li>
                    <button
                      className="dropdown-item"
                      data-toggle="modal"
                      data-target="#share"
                      onClick={() => {
                        const data = {
                          ResourceType: photo.resource_type,
                          path: photo.path,
                        };
                        this.handleShare(data);
                      }}
                    >
                      Share to Social Media{" "}
                    </button>
                  </li>
                  <li>
                    <div>
                      <button
                        className="dropdown-item"
                        onClick={() =>
                          this.handleStatus(photo.visibility, photo.uid)
                        }
                      >
                        {photo.visibility === "public" ? (
                          <div>Client View Only</div>
                        ) : (
                          <div>Public View</div>
                        )}
                      </button>
                    </div>
                  </li>

                  <li >
                    <button
                      className="dropdown-item"
                      data-toggle="modal"
                      data-target="#editfileName"
                      onClick={() => {
                        const data = {
                          type: "Photo",
                          id: photo.uid,
                          path: photo.path,
                          filename: photo.file_name,
                        };
                        this.handleEditfileName(data);
                      }}
                    >
                      Edit Title
                    </button>
                  </li>
                </div>
              </div> */}
            </div>
          );
        else {
          return <></>;
        }
      });

    return (
      <React.Fragment>
        <div>
          {this.props.check_account_type === "coach" ? (
            <div>
              <section className="resourceWrap">
                <div
                  className="container"
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                >
                  <div className="row justify-content-center">
                    <div className="col-5 resourceUpload">
                      <form className="text-center">
                        <div className="avatar">
                          <img src={documentSvg} alt="lg-img" width="95px" />
                          <p>
                            Click to Upload{" "}
                            <span>Video, Audio, Image, Documents</span>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </section>

              <div
                className="modal fade"
                id="exampleModalCenter"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 
                      style={{color:"black"}}
                      className="modal-title" id="exampleModalLongTitle">
                        Upload File
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body" style={{color:"black"}}>
                      <label style={{ marginBottom: ".5rem" }}>
                        *Accepted video types : mp4, webm, mkv, ogv, m4v, mov
                      </label>
                      <label style={{ marginBottom: "1rem" }}>
                        *Accepted audio types : mp3,aac,wav,opus,ogg,oga,m4a
                      </label>

                      <div
                        className="modal-item"
                        style={{ marginBottom: ".8rem" }}
                      >
                        <input
                          type="file"
                          name="resource"
                          id="imageUpload"
                          className="hide"
                          onChange={this.onChangeHandler}
                        />
                      </div>

                      <div className="modal-item">
                        <label style={{ marginRight: "1.2rem" }}>Title</label>
                        <input
                          type="text"
                          value={this.state.fileName}
                          onChange={this.handleChange}
                          name="fileName"
                        />
                      </div>

                      <div className="modal-item">
                        <label style={{ marginRight: "1.2rem" }}>
                        Who can see this file{" "}
                        </label>
                        <select
                          value={this.state.visibility}
                          onChange={this.handleVisibiltyChange}
                        >
                          <option value="public">Public View</option>
                          <option value="private">Client View only</option>
                        </select>
                      </div>

                      {/* <div className="modal-item">
                        <label style={{ marginRight: "1.2rem" }}>
                        Make this my Homepage Video{" "}
                        </label>
                        <input
                          type="checkbox"
                          value={this.state.is_featured}
                          onClick={this.handleCheckbox}
                        />
                      </div> */}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-success"
                        data-dismiss="modal"
                        onClick={this.onClickHandler}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

     {this.state.videos && this.state.videos.length > 0 ?(
        <section className="biokWrap">
        <div className="container">
          <div className="heading text-center">
            <h3>Videos</h3>
            <span className="R_divider"></span>
          </div>
          {/* {this.state.videos.length === 0 && (
            <div
              className="pf__video__photo"
              style={{ textAlign: "center", color: "black" }}
            >
              <h4 className="">No featured video found</h4>
            </div>
          )} */}
          <div className="row justify-content-center lightBox">
            {videoData}
          </div>
    {(this.state.VideoTotalcount && this.state.VideoTotalcount > 3 && this.state.videos.length<this.state.VideoTotalcount) ?(
      <div className="text-center col-12 mt-4">
      <button
        onClick={() => {
          this.handleViewMore("Video");
        }}
        className="defaultBtn"
      >
        View More
      </button>
    </div>
    ):(<div></div>)}
          
        </div><br/>
      </section>

     ):(<div></div>)}

     {this.state.photos && this.state.photos.length > 0 ?(
       <section className="biokWrap">
       <div className="container">
         <div className="heading text-center">
           <h3>Photos</h3>
           <span className="R_divider"></span>
         </div>
         {/* {this.state.photos.length === 0 && (
           <div
             className="pf__video__photo"
             style={{ textAlign: "center", color: "black" }}
           >
             <h4 className="">No featured photo found</h4>
           </div>
         )} */}
         {/* <div className="row justify-content-center lightBox">
           {photoData}
         </div> */}
         <SRLWrapper options={options}>
            <div className="row justify-content-center lightBox">
              {photoData}
            </div>
         </SRLWrapper>

    {(this.state.imageTotalcount && this.state.imageTotalcount > 4 && this.state.photos.length<this.state.imageTotalcount) ?(
      <div className="text-center col-12 mt-4">
      <button
        onClick={() => {
          this.handleViewMore("Photo");
        }}
        className="defaultBtn"
      >
        View More
      </button>
    </div>
    ):(<div></div>)}
         
         {/* ):(<div></div>)} */}
       </div><br/>
     </section>
     ):(<div></div>)}
         
          
   {this.state.pdfs && this.state.pdfs.length > 0 ?(
      <section className="biokWrap">
      <div className="container">
        <div className="heading text-center">
          <h3>Documents</h3>
          <span className="R_divider"></span>
        </div>
        {/* {this.state.pdfs.length === 0 && (
          <div
            className="pf__video__photo"
            style={{ textAlign: "center", color: "black" }}
          >
            <h4 className="">No featured documents found</h4>
          </div>
        )} */}
        <div className="row justify-content-center lightBox">
          {pdfData}
        </div>
      {(this.state.DocumentTotalcount && this.state.DocumentTotalcount > 4 && this.state.pdfs.length<this.state.DocumentTotalcount) ?(
        <div className="text-center col-12 mt-4">
        <button
          onClick={() => {
            this.handleViewMore("Document");
          }}
          className="defaultBtn"
        >
          View More
        </button>
      </div>
      ):(<div></div>)}
        
      </div><br/>
    </section>
   ):(<div></div>)}
         
      {this.state.audios && this.state.audios.length > 0 ?(
         <section className="biokWrap">
         <div className="container">
           <div className="heading text-center">
             <h3>Audios</h3>
             <span className="R_divider"></span>
           </div>
           {/* {this.state.audios.length === 0 && (
             <div
               className="pf__video__photo"
               style={{ textAlign: "center", color: "black" }}
             >
               <h4 className="">No featured audio found</h4>
             </div>
           )} */}
           <div className="row justify-content-center lightBox">
             {audiosData}
           </div>
      {(this.state.audioTotalcount && this.state.audioTotalcount > 4 && this.state.audios.length<this.state.audioTotalcount) ?(
        <div className="text-center col-12 mt-4">
        <button
          onClick={() => {
            this.handleViewMore("Audio");
          }}
          className="defaultBtn"
        >
          View More
        </button>
      </div>
      ):(<div></div>)}
           
         </div><br/>
       </section>
      ):(<div></div>)}
         

          <div className="modal fade" id="share">
            <div className="modal-dialog modal-sm modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="exampleModalCenterTitle">
                    <b>Share</b>
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <ul className="share__option">
                    <SocialMediaButtons data={this.state} />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ListModal
          viewMore={this.state.viewMoreType}
          dataSet={this.state.currentResourceDataSet}
          handlePromotionalVideo={this.handlePromotionalVideo}
          handlePromotionalImage={this.handlePromotionalImage}
          handleDelete={this.handleDeleteResource}
          handleStatus={this.handleStatus}
          handleShare={this.handleShare}
          handleEditfileName={this.handleEditfileName}
        />

        <EditFileName
          viewMore={this.state.viewMoreType}
          resourceId={this.state.resourceId}
          resourcePath={this.state.resourcePath}
          resourceName={this.state.resourceName}
          token={this.props.token}
          handlegetResource={this.handlegetResource}
        />
      </React.Fragment>
    );
  }
}
export default Resources;
