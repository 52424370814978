import React, { Component } from 'react'
import { Button, Col, Dropdown, Form, Modal, Row, Spinner } from 'react-bootstrap'
import axios,{AdminApi} from '../../../config/axios'
import message from '../../../utils/mesaages';
import moment from "moment";
import  {LGspinner}  from "../../../assets/images";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";
import { TimePicker, DatePicker } from 'antd';

import { Tooltip } from "antd";

export default class BankCardDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
          showmodal: false,
          country_full:'',
          bank:{
            country:'US',
            currency:'usd',
            account_number:'',
            account_holder_name:'',
            account_holder_type:'individual',
            routing_number:'',
            errors: {
                account_number: '',
                country: '',
                currency: '',
                account_holder_name: '',
                account_holder_type: '',
                routing_number: '',
              }
          },
          card:{
            cardnumber: '',
            name: '',
            date: '',
            month: '',
            year: '',
            expiryDate: moment().add(1,'months'),
            cvc: '',
            errors: {
                cardnumber: '',
                name: '',
                month: '',
                year: '',
                cvc: '',
                address: '',
                currency: '',
                expiryDate: ''
        
              }

          },
          gatewayDetails: {},
          savecardloader: false,
          paymentloader: true,
          carddetails: null,
          deleteloader: false,
          deletecarddata: '',
          deletcardconfirmmodal: false,
          primarycardconfirmmodal: false,
          primarycarddata: '',
          strip_cus:'',
          pfToken:'',
          loaderToken:false,
          vendorId:'',
          kyc_status:""
        }
        this.stripe =''
      }
      componentDidMount() {
        this.loadStripeJS()
        this.fetchVendorId()
      }
      modalShow = ()=>{
        this.loadStripeJS()
        this.fetchVendorId()
          this.setState({showmodal:true})
      }
      closemodal = () =>{
          this.setState({showmodal:false})

          let bank = {
            country:'US',
            currency:'usd',
            account_number:'',
            account_holder_name:'',
            account_holder_type:'individual',
            routing_number:'',
            errors: {
                account_number: '',
                country: '',
                currency: '',
                account_holder_name: '',
                account_holder_type: '',
                routing_number: '',
              }
          };
          let card = {
            cardnumber: '',
            name: '',
            date: '',
            month: '',
            year: '',
            expiryDate: moment().add(1,'months'),
            cvc: '',
            errors: {
                cardnumber: '',
                name: '',
                month: '',
                year: '',
                cvc: '',
                address: '',
                currency: '',
                expiryDate: ''
        
              }

          };

          this.setState({bank,card})

      }
       
  loadStripeJS(){
    let userdata = JSON.parse(localStorage.getItem('userdata'));
    const id = userdata.uid;

    let self = this;
    let param = {
      url: `/admin/settings/stripe/${id}`,
      method: "GET",
    };
    axios(param)
    .then(async (res) => {

        if(res.data.hasOwnProperty('stripe')){

          const script = document.createElement("script");
          script.src = "https://js.stripe.com/v3/";
          script.async = true;
          script.onload = async() => {
            if(res.data.stripe.mode=='live')
            this.stripe = window.Stripe(res.data.stripe.live.publishable_key);
            else
            this.stripe = window.Stripe(res.data.stripe.sandbox.publishable_key);
          }
          document.body.appendChild(script);

             self.setState({gatewayDetails: res.data.stripe})
        }

    })
   
  }
  fetchVendorId = () => {
    let userid = JSON.parse(localStorage.getItem("userdata")).uid;
    axios.get(`/users/getVendorId/${userid}`, {
      headers: {"x-auth": localStorage.getItem('authToken')}
    })
    .then(async (res) => {
      if(res.data.success) {
        console.log("vendorDetails",res.data.vendorDetails)
        let vendorDetails = JSON.parse(res.data.vendorDetails[0].all_details)
        this.setState({vendorId: res.data.value, kyc_status: res.data.vendorDetails[0].kyc_status,country_full:res.data.country_full})
        let bank = this.state.bank
        bank.country=res.data.vendorDetails[0].acc_country!=null? res.data.vendorDetails[0].acc_country : 'US';
        bank.currency=res.data.vendorDetails[0].acc_currency!=null? res.data.vendorDetails[0].acc_currency : 'USD';
        this.setState({bank})
       
        
      }
    })
    .catch((err) => {
      console.log("error", err)
    })
  }
  


  //card details hndaling functions
  loadCard=()=> {
    return new Promise((resolve, reject) => {
      let self = this
  
    let errors = this.state.card.errors
    let card = this.state.card
  
    window.Stripe.card.createToken({
                number: this.state.card.cardnumber,
                cvc: this.state.card.cvc,
                exp_month: moment(this.state.card.expiryDate).format('MM'),
                exp_year: moment(this.state.card.expiryDate).format('YYYY')
              },function(status, response) {
                if (response.error) { 
                  // Show appropriate error to user
                  console.log('stripe err',response)
                  if(response.error.param=='number')
                  {
                    errors.cardnumber = response.error.message
                  }
                  else if(response.error.param=='cvc')
                  {
                    errors.cvc = response.error.message
                  }
                  else if(response.error.param=='exp_month')
                  {
                    errors.expiryDate = response.error.message
                  }
                  else if(response.error.param=='exp_year')
                  {
                    errors.expiryDate = response.error.message
                  }

                  card.errors = errors
                  self.setState({card})
                  reject()
                  
                } else {
                  // Get the token ID:
                  var token = response.id;
                  console.log('stripe response',response)
                  resolve(response)
                  // Save token mapping it to customer for all future payment activities
                }
              });
      })
  };
  setvalue = (value, field) => {
    let numbers = /^[0-9]*$/;
    let f = field
    this.handleError(field, value);
    let card = this.state.card
    console.log('card',card)
  
    if(field === 'cardnumber' && value.length>16)
    {
      card.cardnumber= this.state.card.cardnumber
      this.setState({ card})
    }
    else
    {
      if (field === 'cardnumber' && numbers.test(value)) {
        card.cardnumber= value
        this.setState({ card})
       
      }
      else {
        card.cardnumber= this.state.card.cardnumber
        this.setState({ card})
       
      }
    }

    //if (field === 'cardnumber') this.setState({ cardnumber: value })
    if (field === 'name'){
        card.name = value
        this.setState({ card })
    } 
    if (field === 'expiryDate')
    {
        card.expiryDate = value
        this.setState({ card })
    } 
    //if (field === 'cvc') this.setState({ cvc: value })
    // if(field === 'cvc' && value.length>4)
    // {
      
    //   this.setState({ cvc: this.state.cvc })
    // }
    // else
    // {
    //   if (field === 'cvc') this.setState({ cvc: value })
    // }

    if(field === 'cvc' && this.isValidCVC(value))
    {
        card.cvc = value
        this.setState({ card })
         
    }
    else if(field === 'cvc')
    {
        card.cvc =  this.state.card.cvc
        this.setState({ card })
     
    }

   

  }
  isValidCVC = (value) => {
      
    let isValid = true;
    if(!value || value === "") return isValid;

    try {
      if(value.length > value.trim().length) isValid = false;
      else if(value.length > 4 || value.includes('.')) isValid = false;
      else {

        let n = Number(value)
        if(!n) isValid = false;
  
      }

    } catch (error) {
      console.log("Invalid CVC");
      isValid = false;
    }

    return isValid;
  }
  handleError = (name, value) => {
    console.log('name' , name)
    console.log('value' , value)

    let errors = this.state.card.errors;
    let card = this.state.card
    switch (name) {
      case 'cardnumber':
        errors.cardnumber =
          value.length >= 15
            ? ''
            : 'Card number minimum 15 digits!';
        if (value === '') {
          errors.cardnumber = 'Card number required'
        }
        break;
      case 'name':
        if (value === '') {
          errors.name = 'Card holder name required'
        } else {
          errors.name = ''
        }
        break;

      case 'expiryDate':
        if (value === null) {
          errors.expiryDate = 'Expiry date is required'
        } else {
          errors.expiryDate = ''
        }
        break;

      case 'cvc':
        errors.cvc =
          value.length >=3
            ? ''
            : 'CVC accepts minimum 3 digits!';

        if (value === '') {
          errors.cvc = 'CVC Required'
        }
        break;
        

      default:
        break;
    }
    card.errors = errors;
    // card[name] =value
    this.setState({card}, () => {
      console.log(errors)
    })
  }
  

  validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  }
  checkrequiredfields = () => {
    let valid = false;
    if (this.state.card.cardnumber !== '' &&
    this.state.card.name !== '' &&
    this.state.card.cvc !== '' 
    ) {
      valid = true
    } else {
     
      this.handleError('cardnumber' ,this.state.card.cardnumber)
      this.handleError('name' ,this.state.card.name)
      this.handleError('cvc' ,this.state.card.cvc)
      // this.handleError('expiryDate' ,this.state.expiryDate)

    }

    return valid
  }
  disabledDate = (value) =>{
    // const form = this.props.form;
   // Can not select days before today and today
    return  value < moment();
  }; 
    
  savecard=(event)=>{
        event.preventDefault();
    console.info('Valid Form',this.checkrequiredfields());
    if (this.validateForm(this.state.card.errors) && this.checkrequiredfields()) {
      
      
       this.loadCard().then((ret)=>{
           console.log('bank_tok',ret.id)

       })
       .catch((err)=>{
           console.log("err",err)

       })
      

    } else {
      console.error('Invalid Form');
      message.error("Please enter all required fields");

    }

    }
/// for bank details
    setvalue2 = (value, field) => {
        let numbers = /^[0-9]*$/;
        let f = field
    this.handleError2(field, value);
       
        let bank = this.state.bank
       if(field=='account_number')
       {
           bank.account_number = value
           this.setState({bank})
       }

       if(field=='routing_number')
       {
           bank.routing_number = value
           this.setState({bank})
       }

       if(field=='account_holder_name')
       {
           bank.account_holder_name = value
           this.setState({bank})
       }

       if(field=='country')
       {
           bank.country = value
           this.setState({bank})
       }
       if(field=='currency')
       {
           bank.currency = value
           this.setState({bank})
       }
       
    
    }
    handleError2 = (name, value) => {
      // console.log('name' , name)
      // console.log('value' , value)
  
      let errors = this.state.bank.errors;
      let bank = this.state.bank
      switch (name) {
        case 'routing_number':
          // errors.routing_number =
          //   value.length == 9
          //     ? ''
          //     : 'Routing number should be 9 digits!';
          // if (value === '') {
          //   errors.routing_number = 'Routing number required'
          // }
          errors.routing_number = ''
          break;
        case 'account_holder_name':
          if (value === '') {
            errors.account_holder_name = 'Account holder name required'
          } else {
            errors.account_holder_name = ''
          }
          break;
  
        case 'account_number':
          if (value === '') {
            errors.account_number = 'Account number  required'
          } else {
            errors.account_number = ''
          }
          break;
        case 'country':
            if (value === '') {
              errors.country = 'country is  required'
            } else {
              errors.country = ''
            }
            break;
        case 'currency':
              if (value === '') {
                errors.currency = 'currency  required'
              } else {
                errors.currency = ''
              }
              break;
  
        
          
  
        default:
          break;
      }
      bank.errors = errors;
      // card[name] =value
      this.setState({bank}, () => {
        console.log(errors)
      })
    }
    
  
    validateForm2 = (errors) => {
      let valid = true;
      Object.values(errors).forEach(
        // if we have an error string set valid to false
        (val) => val.length > 0 && (valid = false)
      );
      return valid;
    }
    checkrequiredfields2 = () => {
      let valid = false;
      if (
         this.state.bank.currency !== '' &&
        this.state.bank.country !== '' &&
        // this.state.bank.routing_number !== '' &&
      this.state.bank.account_number !== '' &&
      this.state.bank.account_holder_name !== '' 
      ) {
        valid = true
      } else {
       
        this.handleError2('routing_number' ,this.state.bank.routing_number)
        this.handleError2('account_number' ,this.state.bank.account_number)
        this.handleError2('currency' ,this.state.bank.currency)
        this.handleError2('country' ,this.state.bank.country)
        this.handleError2('account_holder_name' ,this.state.bank.account_holder_name)
  
      }
  
      return valid
    }
    saveBank=(event)=>{
      event.preventDefault();

        if (this.validateForm2(this.state.bank.errors) && this.checkrequiredfields2()) {
          let uID=JSON.parse(localStorage.getItem('userdata')).uid
          this.setState({loaderToken:true})
        

        this.loadBank().then((res)=>{
            let bk_token = res.token.id
                //addbank account
                axios.post("/consumer/addbank",
                {vendorId:this.state.vendorId,
                token:bk_token,
                uuid:uID
              }).then((res)=>{
          this.setState({loaderToken:false})

                    if(res.data.success)
                    {
                        this.closemodal()
                        this.props.fetchBanks()
                        message.success("Account added successfully")
                        
                    }
                    else
                    {
                        message.error(res.data.message)
                    }
                }).catch((err)=>{
          this.setState({loaderToken:false})

                    console.log(err)
                    message.error("Error in adding details")
                })
                    
          

        }).catch((err)=>{
          this.setState({loaderToken:false})
            console.log(err)
            message.error("Check your bank details")

        })
      }
      else
      {
        console.error('Invalid Form');
        message.error("Please enter all required fields");
      }

      }

      loadBank=()=> {
       
        return new Promise((resolve, reject) => {
          let self = this
      
        let errors = this.state.bank.errors
        let bank = this.state.bank
       
        let payload = {
          country:this.state.bank.country,
          currency:this.state.bank.currency,
          account_number:this.state.bank.account_number,
          account_holder_name:this.state.bank.account_holder_name,
          account_holder_type:this.state.bank.account_holder_type
         
        }
        if(this.state.bank.routing_number!='')
        payload.routing_number=this.state.bank.routing_number
      
        this.stripe.createToken("bank_account",payload).then((response)=> {
                    
                      console.log('a',response)
                      if (response.error) { 
                        // Show appropriate error to user
                        console.log('stripe err',response)
                        if(response.error.param==`bank_account[account_number]`)
                        {
                          errors.account_number = response.error.code=="parameter_missing"? 'This field is required' : response.error.message
                        }
                        else if(response.error.param==`bank_account[routing_number]`)
                        {
                          errors.routing_number = response.error.code=="parameter_missing"? 'This field is required' : response.error.message
                        }
                        else if(response.error.param==`bank_account[account_holder_type]`)
                        {
                          errors.account_holder_type = response.error.message
                        }
                        else if(response.error.param==`bank_account[account_holder_name]`)
                        {
                          errors.account_holder_name =  response.error.code=="parameter_missing"? 'This field is required' : response.error.message
                        }
      
                        bank.errors = errors
                        self.setState({bank})
                        reject()
                      }else
                      {
                        resolve(response)
                      }


                    }).catch((error)=>{
                      console.log('error',error)


                      
                    }) 
                 
                
          })
      };

  //card details hndaling functions--------------xxxxxxxxxxxxxxxxxxx---------------
    render() {
        return (
            <>
            {this.state.kyc_status!="1"? (<>
              <Tooltip placement="top" title="Please complete your customer details (KYC) in KYC Details tab">
            <span style={{cursor: "not-allowed"}}>
            <button className="defaultBtn" style={{pointerEvents: "none"}}>
            Add Bank/Debit Card
            </button>
            </span>
          </Tooltip>
            </>): this.props.bankDetails !== null && this.props.bankDetails.length > 0 ? ("") : (<>
              <button onClick={this.modalShow} className="defaultBtn">
                  Add Bank/Debit Card
              </button>
            </>)}
        
        <Modal
            //    size="xl"
              show={this.state.showmodal}
              onHide={this.closemodal}
              // backdrop="static"
              // keyboard={false}
              style={{ color: 'black' }}
            >
              <LoadingOverlay
                    active={this.state.loaderToken}
                    // spinner={<BounceLoader />}
                    spinner={
                      <div>
                        <CustomImageLoader image={LGspinner} animationType={"pulse"} />
                        <br />
                      </div>
                    }
                    text=""
                  >
              <Modal.Header closeButton>
                <Modal.Title>
                  <h3 >Add Payment Method</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* <Form>
                                
                  <Row>
                    <Col>
                      <Form.Group controlId="formBasicPassword">
                      <Form.Control onChange={(e) => this.setvalue(e.target.value, 'cardnumber')} style={{ color: 'black' }} type="text" value={this.state.card.cardnumber} placeholder='Card Number' />
                        <div style={{ color: 'red' }}>{this.state.card.errors.cardnumber == '' ? '' : this.state.card.errors.cardnumber}</div>
                      </Form.Group>
                     
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Control onChange={(e) => this.setvalue(e.target.value, 'name')} style={{ color: 'black' }} type="text" placeholder='Name Of Card Holder' />
                        <div style={{ color: 'red' }}>{this.state.card.errors.name == '' ? '' : this.state.card.errors.name}</div>

                      </Form.Group>
                    
                    </Col>
                  </Row>
                  <Row>
                   
                    <Col md={6} className="mb-2">
                    <DatePicker disabledDate={this.disabledDate}  onChange={(e) => this.setvalue(e, 'expiryDate')} value={this.state.card.expiryDate} picker="month"    size="large" format={"MM-YYYY"}   />

                      <p style={{ color: 'red' }}>{this.state.card.errors.expiryDate == '' ? '' : this.state.card.errors.expiryDate}</p>

                    </Col>
                   
                  
                    <Col md={6} className="mb-2">
                      <Form.Group controlId="formBasicPassword">
                      <Form.Control 
                        onChange={(e) => this.setvalue(e.target.value, 'cvc')} 
                        placeholder='CVC' style={{ color: 'black' }} 
                        type="text" 
                        value={this.state.card.cvc} 
                        />
                        <div style={{ color: 'red' }}>{this.state.card.errors.cvc == '' ? '' : this.state.card.errors.cvc}</div>
                      </Form.Group>
                    </Col>
                  </Row>
                 

                        
                </Form> */}


                   <Form>
                   <Row>
                    
                      {/* <Form.Group controlId="formBasicPassword">
                        <Form.Control onChange={(e) => this.setvalue2(e.target.value, 'currency')} style={{ color: 'black' }} type="hidden" placeholder='Currency ex-GBP,USD,EUR' value={this.state.bank.currency}/>
                        <div style={{ color: 'red' }}>{this.state.bank.errors.currency == '' ? '' : this.state.bank.errors.currency}</div>

                      </Form.Group> */}
                    
                    
                    <Col>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Control  style={{ color: 'black' }} type="text" value={this.state.country_full} readOnly={true}/>
                        {/* <div style={{ color: 'red' }}>{this.state.bank.errors.country == '' ? '' : this.state.bank.errors.country}</div> */}

                      </Form.Group>
                    
                    </Col>
                  </Row>
                                
                  
                  <Row>
                    <Col>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Control onChange={(e) => this.setvalue2(e.target.value, 'account_holder_name')} style={{ color: 'black' }} type="text" placeholder='Name Of Account Holder' />
                        <div style={{ color: 'red' }}>{this.state.bank.errors.account_holder_name == '' ? '' : this.state.bank.errors.account_holder_name}</div>

                      </Form.Group>
                    
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="formBasicPassword">
                      <Form.Control onChange={(e) => this.setvalue2(e.target.value, 'account_number')} style={{ color: 'black' }} type="text" value={this.state.bank.account_number} placeholder='Account Number' />
                        <div style={{ color: 'red' }}>{this.state.bank.errors.account_number == '' ? '' : this.state.bank.errors.account_number}</div>
                      </Form.Group>
                     
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="formBasicPassword">
                      <Form.Control onChange={(e) => this.setvalue2(e.target.value, 'routing_number')} style={{ color: 'black' }} type="text" value={this.state.bank.routing_number} placeholder='Routing Number / Sort Code' />
                        <div style={{ color: 'red' }}>{this.state.bank.errors.routing_number == '' ? '' : this.state.bank.errors.routing_number}</div>
                      </Form.Group>
                     
                    </Col>
                  </Row>
                  
                 

                        
                </Form>


              </Modal.Body>
              <br />

              <Modal.Footer>
                {this.state.savecardloader === true ? <Spinner animation="grow" variant="primary" /> : ''}
                <Button className="defaultBtn" variant="default" onClick={this.saveBank}>Save</Button>
              </Modal.Footer>
              </LoadingOverlay>
            </Modal>

 
                
            </>
        )
    }
}
